import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

const OPENED = 'true'
const CLOSED = 'false'

export class OpenToMarketOptions {
  get options(): InputOption[] {
    return [
      {
        value: OPENED,
        label: i18n.t(`trading.label.open`).toString(),
      },
      {
        value: CLOSED,
        label: i18n.t(`trading.label.close`).toString(),
      },
    ]
  }

  static valueOf(value?: string): boolean | undefined {
    return value ? value === CLOSED : undefined
  }
}
