
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import StatusToggleButton from '@/components/common/StatusToggleButton.vue'
import OrderBookHeader from '@/components/trading/OrderBookHeader.vue'

export default defineComponent({
  name: 'BrokingOrderBookListHeader',
  components: {
    BaseIcon,
    BaseTooltip,
    OrderBookHeader,
    StatusToggleButton,
  },
  props: {
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    allCollapsed: {
      type: Boolean,
      default: false,
    },
    withFuelSurcharge: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['mode-change', 'activate-all', 'deactivate-all'],
  methods: {
    onChangeEditMode() {
      this.$emit('mode-change')
    },
    onActivateAll() {
      this.$emit('activate-all')
    },
    onDeactivateAll() {
      this.$emit('deactivate-all')
    },
  },
})
