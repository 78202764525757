import { Type } from 'class-transformer'

import {
  NegotiationDetail as GeneratedINegotiationDetail,
  Price,
} from '@/api/generated'
import { Indication } from '@/models/trading/Indication'
import { Product } from '@/models/trading/Product'

// FIXME: openapi-generator で生成されるコードで配列内の nullable が無視されるため暫定対応として型をここで指定している
export interface INegotiationDetail
  extends Omit<
    GeneratedINegotiationDetail,
    | 'afterVolumes'
    | 'beforeVolumes'
    | 'beforeExchangeVolumes'
    | 'afterExchangeVolumes'
  > {
  afterVolumes: (number | null)[]
  beforeVolumes: (number | null)[]
  beforeExchangeVolumes?: (number | null)[]
  afterExchangeVolumes?: (number | null)[]
}

export class NegotiationDetail implements INegotiationDetail {
  id!: string

  @Type(() => Indication)
  indication!: Indication

  @Type(() => Product)
  product!: Product

  afterPrice!: Price
  beforeUnitPrices!: number[]
  beforeVolumes!: (number | null)[]
  beforeExchangeVolumes?: (number | null)[] | undefined
  beforePrice!: Price
  afterUnitPrices!: number[]
  afterVolumes!: (number | null)[]
  afterExchangeVolumes?: (number | null)[] | undefined
  brokingFee!: number
  exchangeBrokingFee?: number
}
