
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import InnerLabel from '@/components/common/Typography/InnerLabel.vue'

export default defineComponent({
  name: 'CreateMessageButton',
  components: {
    BaseIcon,
    InnerLabel,
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
