
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import { DeliveryPatternComponentFormProps } from '@/components/trading/interface/DeliveryPatternComponentFormProps'
import { TimeRangeOptions } from '@/models/trading/TimeRangeOptions'

export default defineComponent({
  name: 'DeliveryPatternComponentForm',
  components: {
    BaseIcon,
    BaseSingleSelect,
  },
  props: {
    nameSuffix: {
      type: String,
      default: '',
    },
    formValue: {
      type: Object as PropType<DeliveryPatternComponentFormProps>,
      required: true,
    },
    dayPatternOptions: {
      type: Array as PropType<InputOption[]>,
      default: () => [],
    },
    mode: {
      type: Number as PropType<InputMode>,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY].includes(value),
      default: InputMode.INPUT,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['form-input'],
  data(): {
    timeRangeStartOptions: InputOption[]
    timeRangeEndOptions: InputOption[]
    formInputEventName: 'form-input'
  } {
    return {
      timeRangeStartOptions: new TimeRangeOptions().options.slice(0, -1),
      timeRangeEndOptions: new TimeRangeOptions().options.slice(1),
      formInputEventName: 'form-input',
    }
  },
  computed: {
    availableTimeRangeStartOptions(): InputOption[] {
      const selectedTimeRangeEnd = this.formValue.timeRangeEnd
      if (!selectedTimeRangeEnd) {
        return this.timeRangeStartOptions
      }
      return this.timeRangeStartOptions.filter(
        option => Number(selectedTimeRangeEnd.value) > Number(option.value),
      )
    },
    availableTimeRangeEndOptions(): InputOption[] {
      const selectedTimeRangeStart = this.formValue.timeRangeStart
      if (!selectedTimeRangeStart) {
        return this.timeRangeEndOptions
      }
      return this.timeRangeEndOptions.filter(
        option => Number(option.value) > Number(selectedTimeRangeStart.value),
      )
    },
  },
})
