
import { plainToClass } from 'class-transformer'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { ClearingInformation } from '@/models/iam/ClearingInformation'
import { TocomClearingInformationItem } from '@/models/iam/TocomClearingInformationItem'
import { TocomBroker } from '@/models/trading/TocomBroker'
import { ClearingInformationModule } from '@/store/modules/iam/clearingInformation'

const { mapActions: clearingInformationActions } = createNamespacedHelpers(
  'clearingInformation',
) as ClearingInformationModule

export default defineComponent({
  name: 'TocomBrokerSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    tocomClearingInformationList: TocomClearingInformationItem[]
    uiStack: UiStack
    alertTypeInfo: AlertType
    alertTypeError: AlertType
  } {
    return {
      tocomClearingInformationList: [],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
    }
  },
  async created() {
    await this.fetchClearingInformation(this.organizationId)
      .then(clearingInformation => {
        this.tocomClearingInformationList =
          clearingInformation.tocomClearingInformation
        this.uiStack =
          this.tocomClearingInformationList.length > 0
            ? UiStack.Ideal
            : UiStack.Blank
      })
      .catch(() => {
        this.uiStack = UiStack.Error
      })
  },
  methods: {
    fetchClearingInformation: clearingInformationActions([
      'fetchClearingInformation',
    ]).fetchClearingInformation as (
      organizationId: string,
    ) => Promise<ClearingInformation>,
    onSelect(tocomClearingInformation: TocomClearingInformationItem) {
      const tocomBroker = tocomClearingInformation.tocomBroker
      this.$emit(
        'select',
        plainToClass(TocomBroker, {
          name: tocomBroker.name,
          offFloorTradingCode: tocomBroker.offFloorTradingCode,
        }),
      )
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
})
