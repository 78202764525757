
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale } from '@/api/generated'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'

export default defineComponent({
  name: 'OldDeliveryTerms',
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTerms>,
      required: true,
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  computed: {
    areaName(): string {
      return this.deliveryTerms.area.name.translationOf(this.locale)
    },
    deliveryStart(): string {
      return this.deliveryTerms.deliveryStart(this.deliveryUnit)
    },
    deliveryEnd(): string {
      return this.deliveryTerms.deliveryEnd(this.deliveryUnit)
    },
    hourTypeName(): string {
      if (this.deliveryUnit !== DeliveryUnit.NonStandard) {
        return this.deliveryTerms.hourTypeName() || ''
      }
      return '-'
    },
  },
})
