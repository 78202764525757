import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38107842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report localized-report" }
const _hoisted_2 = { class: "localized-report__published-at" }
const _hoisted_3 = ["datetime"]
const _hoisted_4 = { class: "localized-report__summary" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "localized-report__attachment" }
const _hoisted_7 = { class: "localized-report__attachment__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sentences = _resolveComponent("sentences")!
  const _component_heading = _resolveComponent("heading")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_inner_label = _resolveComponent("inner-label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sentences, { type: "text-medium" }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('reports.label.publishedAt')) + ":", 1),
          _createElementVNode("time", {
            datetime: _ctx.report.publishedAt
          }, _toDisplayString(_ctx.report.displayPublishedAt()), 9, _hoisted_3)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_heading, {
        type: "header2",
        class: "localized-report__title"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('reports.label.summary')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "localized-report__summary__content",
        innerHTML: _ctx.report.reportContent.descriptionHtml()
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_heading, {
        type: "header2",
        class: "localized-report__title"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('reports.label.attachment')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          class: "localized-report__attachment__button",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDownloadClick && _ctx.onDownloadClick(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_base_icon, {
              "icon-name": "file-download",
              class: "localized-report__attachment__icon"
            }),
            _createVNode(_component_inner_label, {
              type: "button",
              class: "localized-report__attachment__label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.report.reportContent.attachmentName), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}