import { Area as IArea } from '@/api/generated'
import { EntityAttribute } from '@/models/common/EntityAttribute'

export class Area extends EntityAttribute implements IArea {
  areaId!: string
  isSpread!: boolean
  defaultCalendarTypeId!: string
  code?: number

  get id(): string {
    return this.areaId
  }
}
