import { MyDeal } from '@/models/trading/MyDeal'
import { MyDealFilterConditions } from '@/store/modules/trading/myDeal/interface'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export class MyDealState {
  myDeals = [] as MyDeal[]
  isFetchingMyDeals = true

  filterCondition = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
  } as MyDealFilterConditions

  filterAttributesOfMyDeal: BaseFilterAttribute[] = [
    'area',
    'startDeliveryYearMonth',
    'endDeliveryYearMonth',
    'hourType',
    'productType',
  ]

  selectedFiltersOfMyDeal = [] as BaseFilterAttribute[]
}
