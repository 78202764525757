
// TODO: ListToggleButtonを統合する
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StatusToggleButton',
  props: {
    allInactive: {
      type: Boolean,
      required: true,
    },
    activateAllLabel: {
      type: String,
      required: true,
    },
    deactivateAllLabel: {
      type: String,
      required: true,
    },
  },
  emits: ['activate-all', 'deactivate-all'],
  methods: {
    onActivateAll() {
      this.$emit('activate-all')
    },
    onDeactivateAll() {
      this.$emit('deactivate-all')
    },
  },
})
