import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d8e897d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.includeChildAction || _ctx.isTargetBlank)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.href,
        class: _normalizeClass(["base-link", {
      'base-link__disabled': _ctx.disabled,
      'base-link__reset-color': _ctx.resetFontStyle,
    }]),
        onContextmenu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enableWithHref = true)),
        onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.enableWithHref = false)),
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.navigate($event)), ["stop","prevent"]))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 42, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        class: _normalizeClass(["base-link", {
      'base-link__disabled': _ctx.disabled,
      'base-link__reset-color': _ctx.resetFontStyle,
    }]),
        to: _ctx.path
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["class", "to"]))
}