import { plainToClass, Type } from 'class-transformer'

import {
  Locale,
  OrganizationProfile as IOrganizationProfile,
} from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class OrganizationProfile implements IOrganizationProfile {
  organizationId!: string

  @Type(() => MultiLocaleName)
  name!: MultiLocaleName

  @Type(() => MultiLocaleName)
  tradingCompanyName!: MultiLocaleName

  picture!: string
  locale!: Locale
  recapAddress!: string
  isEClearContracted!: boolean

  constructor() {
    this.organizationId = ''
    this.name = plainToClass(MultiLocaleName, { translations: [] })
    this.picture = ''
    this.locale = Locale.Ja
  }
}
