
import debounce from 'lodash/debounce'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import {
  DeliveryUnit,
  MyStandardIndicationSelectableInputsRequest,
  NewMyIndication,
  PositionType,
  SpreadTypeTypeEnum,
} from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import MyIndicationForm from '@/components/trading/MyIndicationForm.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { buildNewMyIndicationPayload } from '@/components/trading/services/myIndicationPayloadBuilder'
import {
  buildSelectableInputsRequest,
  buildProductFormValue,
} from '@/components/trading/services/myStandardIndicationSelectableInputsServices'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { OrderBook } from '@/models/trading/OrderBook'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { MyIndicationModule } from '@/store/modules/trading/myIndication'
import { ProductModule } from '@/store/modules/trading/product'
import { setNotification } from '@/utils/utils'

const { mapActions: myIndicationMapActions } = createNamespacedHelpers(
  'myIndication',
) as MyIndicationModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const {
  mapGetters: userProfileMapGetters,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule

export default defineComponent({
  name: 'MyIndicationNew',
  components: {
    BaseButton,
    CenteredLoadingContent,
    MyIndicationForm,
    ValidationForm,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: IndicationFormInputMode) => {
        return Object.values(IndicationFormInputMode).includes(value)
      },
    },
    orderBook: {
      type: Object as PropType<OrderBook>,
      default: undefined,
    },
    deliveryTermsId: {
      type: String,
      default: undefined,
    },
    position: {
      type: String as PropType<PositionType>,
      default: PositionType.Bid,
    },
  },
  emits: ['cancel'],
  data(): {
    formValue: ProductFormProps
    isEditing: boolean
    isSubmitting: boolean
    dayPatternOptions: InputOption[]
    selectableInputs: BaseStandardIndicationSelectableInputs | undefined
    selectableInputsRequest: MyStandardIndicationSelectableInputsRequest
    openedSpreadFormTypes: SpreadTypeTypeEnum[]
    currentAbortController: AbortController
    dateNames: ProductTypeDateName[]
  } {
    return {
      formValue: {
        position: PositionType.Bid,
        deliveryUnit: DeliveryUnit.Month,
        productTypeIds: [],
        fuelSurchargeTypeId: undefined,
        base: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        exchange: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        deliveryPeriods: [],
        price: {
          type: 'volume',
          energyUnitPrices: [],
          basicUnitPrice: undefined,
        },
        unitPrices: [],
        request: '',
      },
      isEditing: true,
      isSubmitting: false,
      dayPatternOptions: [],
      selectableInputs: undefined,
      selectableInputsRequest: {},
      openedSpreadFormTypes: [],
      currentAbortController: new AbortController(),
      dateNames: [],
    }
  },
  computed: {
    ...userProfileMapState(['services']),
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['productTypes']),
    isInvalid(): boolean {
      return !this.selectableInputs?.isValid
    },
  },
  async created() {
    this.selectableInputsRequest = {
      position: this.position,
      deliveryTermsId: this.deliveryTermsId || this.orderBook?.deliveryTermsId,
      exchangeDeliveryTermsId: this.orderBook?.exchangeDeliveryTermsId,
    }
    await Promise.all([
      this.fetchProductTypeDateNames() as Promise<ProductTypeDateName[]>,
      this.fetchProductTypes(),
      this.fetchSelectableInputs(new AbortController()),
    ]).then(([dateNames]) => {
      this.dateNames = dateNames
      this.openedSpreadFormTypes =
        this.selectableInputs?.openedSpreadFormTypes || []
    })
  },
  methods: {
    ...myIndicationMapActions(['createMyIndication']),
    fetchMyStandardIndicationSelectableInputs: myIndicationMapActions([
      'fetchMyStandardIndicationSelectableInputs',
    ]).fetchMyStandardIndicationSelectableInputs as (payload: {
      request: MyStandardIndicationSelectableInputsRequest
      abortController: AbortController
    }) => Promise<BaseStandardIndicationSelectableInputs>,
    ...productMapActions(['fetchProductTypes', 'fetchProductTypeDateNames']),
    buildPayload(): NewMyIndication | undefined {
      if (!this.formValue || !this.selectableInputs) {
        return
      }
      return buildNewMyIndicationPayload({
        formValue: this.formValue,
        selectableInputs: this.selectableInputs,
      })
    },
    debouncedFetchSelectableInputs: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.fetchSelectableInputs(new AbortController())
    }, 500),
    async fetchSelectableInputs(abortController: AbortController) {
      this.currentAbortController.abort()
      this.currentAbortController = abortController
      await this.fetchMyStandardIndicationSelectableInputs({
        request: this.selectableInputsRequest,
        abortController: this.currentAbortController,
      })
        .then((selectableInputs: BaseStandardIndicationSelectableInputs) => {
          this.selectableInputs = selectableInputs
          Object.assign(
            this.formValue,
            buildProductFormValue({
              formValue: this.formValue,
              selectableInputs,
            }),
          )
        })
        .catch(e => {
          if (e.message === 'canceled') {
            return
          }
          throw e
        })
    },
    async onFormInput(formValue: ProductFormProps) {
      if (!this.selectableInputs) {
        return
      }

      // 入力内容が即座に変更されたかのようにフィードバックするため一時的に反映する
      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })

      await this.fetchSelectableInputs(new AbortController())
    },
    async onTextInput(formValue: ProductFormProps) {
      if (!this.selectableInputs) {
        return
      }

      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })

      await this.debouncedFetchSelectableInputs()
    },
    onCancelClick() {
      this.$emit('cancel')
    },
    onConfirmClick() {
      this.isEditing = false
    },
    onConfirmCancelClick() {
      this.isEditing = true
    },
    async onCreateIndicationConfirmClick() {
      const payload = this.buildPayload()
      if (!payload) {
        return
      }
      this.isSubmitting = true
      await this.createMyIndication(payload)
        .then(() => {
          setNotification(
            this.$t('trading.message.successSubmitNewIndication').toString(),
          )
          this.$vfm.close('new-indication')
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failSubmitNewIndication').toString(),
            'is-danger',
          )
          throw e
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    async onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = Array.from(
        new Set([...this.openedSpreadFormTypes, type]),
      )
      // 入力省力化のため、交換側が空の場合は同じ値を入れる
      this.formValue.exchange.volumes[0] =
        this.formValue.exchange.volumes[0] || this.formValue.base.volumes[0]
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
    async onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = this.openedSpreadFormTypes.filter(
        t => t !== type,
      )
      switch (type) {
        case SpreadTypeTypeEnum.Area:
          this.formValue.exchange.areaId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPattern:
          this.formValue.exchange.hourTypeId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPeriod:
          this.formValue.exchange.startDeliveryYearMonthId = undefined
          this.formValue.exchange.endDeliveryYearMonthId = undefined
          this.formValue.exchange.startDeliveryDate = undefined
          this.formValue.exchange.endDeliveryDate = undefined
          break
      }
      if (this.openedSpreadFormTypes.length === 0) {
        this.formValue.exchange.volumes = []
      }
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
  },
})
