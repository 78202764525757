import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58fe5023"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input-file" }
const _hoisted_2 = {
  key: 0,
  class: "base-input-file__wrapper--not-selected"
}
const _hoisted_3 = {
  key: 1,
  class: "base-input-file__wrapper--selected"
}
const _hoisted_4 = { class: "base-input-file__uploaded-file-icon" }
const _hoisted_5 = { class: "base-input-file__uploaded-file-name" }
const _hoisted_6 = ["accept"]
const _hoisted_7 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_field = _resolveComponent("field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_label, {
      label: _ctx.labelString,
      help: _ctx.help,
      class: "base-input-file__label"
    }, null, 8, ["label", "help"]),
    _createVNode(_component_field, {
      name: _ctx.name,
      "model-value": _ctx.selectedFile,
      rules: _ctx.rules,
      type: "file",
      "validate-on-mount": ""
    }, {
      default: _withCtx(({ field, meta }) => [
        _createElementVNode("label", null, [
          (_ctx.isNotSelected)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createVNode(_component_base_icon, { "icon-name": "paperclip" })
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.label.fileSelect')), 1)
              ]))
            : (_ctx.isSelected)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_base_icon, { "icon-name": "paperclip" })
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.label.fileSelected', { name: _ctx.selectedFile?.name })), 1),
                  _createElementVNode("div", {
                    class: "base-input-file__remove-button",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRemoveClick && _ctx.onRemoveClick(...args)), ["prevent"]))
                  }, [
                    _createVNode(_component_base_icon, { "icon-name": "times-circle" })
                  ])
                ]))
              : (_ctx.isUploaded)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "base-input-file__wrapper--uploaded",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDownloadClick && _ctx.onDownloadClick(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_base_icon, { "icon-name": "file-download" })
                    ]),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.uploadedFileName), 1),
                    _createElementVNode("div", {
                      class: "base-input-file__remove-button",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRemoveClick && _ctx.onRemoveClick(...args)), ["prevent","stop"]))
                    }, [
                      _createVNode(_component_base_icon, { "icon-name": "times-circle" })
                    ])
                  ]))
                : _createCommentVNode("", true),
          _createElementVNode("input", _mergeProps({ ref: "fileInput" }, field, {
            class: "base-input-file__input",
            type: "file",
            accept: _ctx.accept,
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }), null, 16, _hoisted_6)
        ]),
        _createVNode(_component_error_message, { name: _ctx.name }, {
          default: _withCtx(({ message }) => [
            (meta.touched || meta.dirty)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(message), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["name"])
      ]),
      _: 1
    }, 8, ["name", "model-value", "rules"])
  ]))
}