import { defineRule, configure } from 'vee-validate'

import * as commonRules from '@/components/common/validation'
import { VALIDATION_RULES } from '@/components/iam/validation'
import { i18n } from '@/i18n'

for (const [rule, validation] of [
  ...Object.entries(commonRules),
  ...Object.entries(VALIDATION_RULES),
]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defineRule(rule, validation as any)
}

configure({
  generateMessage: ctx => {
    const [param1, param2] = ctx.rule?.params as string[]
    switch (ctx.rule?.name) {
      case 'between':
        return i18n
          .t('validations.between', { min: param1, max: param2 })
          .toString()
      case 'bgCode':
        if (!param1) {
          return i18n.t('iam.message.requiredArea').toString()
        }
        return i18n
          .t('iam.validations.bgCode', {
            code: param1,
          })
          .toString()
      case 'blacklist':
        return i18n.t('validations.invalid').toString()
      case 'companyPrefix':
        if (!param1) {
          return i18n.t('iam.message.requiredArea').toString()
        }
        return i18n
          .t('iam.validations.companyPrefix', {
            code: param1,
          })
          .toString()
      case 'department':
        return i18n.t('iam.validations.department').toString()
      case 'eclearKeyword':
        return i18n.t('iam.validations.eclearKeyword').toString()
      case 'email':
        return i18n.t('validations.email').toString()
      case 'integer':
        return i18n.t('validations.integer').toString()
      case 'numeric':
        return i18n.t('validations.numeric').toString()
      case 'max':
        return i18n.t('validations.max', { max: param1 }).toString()
      case 'maxFileSize':
        if (!param1) {
          return ''
        }
        return i18n
          .t(
            'validations.maxFileSize',
            (() => {
              const K = 1000
              const current = Math.ceil((ctx.value as File).size / K / K)
              return { max: param1, current }
            })(),
          )
          .toString()
      case 'min':
        return i18n.t('validations.min', { min: param1 }).toString()
      case 'name':
        return i18n.t('iam.validations.name').toString()
      case 'password':
        return i18n.t('iam.validations.password').toString()
      case 'requiredArray':
      case 'required':
        return i18n.t('validations.required').toString()
      case 'scale':
        return i18n
          .t('validations.scale', {
            precision: param1,
          })
          .toString()
      case 'spsCode':
        if (!param1) {
          return i18n.t('iam.message.requiredArea').toString()
        }
        return i18n
          .t('iam.validations.spcCode', {
            code: param1,
          })
          .toString()
      case 'whitelist':
      case 'whitelistForInputOption':
        return i18n.t('validations.invalid').toString()
      default:
        throw Error(`unknown rule: ${ctx.rule?.name}`)
    }
  },
})
