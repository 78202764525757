
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { SPREAD_SYMBOL } from '@/components/common/constants/Spread'

export default defineComponent({
  name: 'OldUnitPriceAndVolume',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    unitPrice: {
      type: Number,
      required: true,
    },
    volume: {
      type: Number,
      default: null,
    },
    position: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    colorDisabled: {
      type: Boolean,
      default: false,
    },
    createdAt: {
      type: String,
      default: undefined,
    },
    maskVolume: {
      type: Boolean,
      default: false,
    },
    lightFont: {
      type: Boolean,
      default: false,
    },
    exchangeVolume: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    UnitPriceAndVolumeTextClass(): string {
      if (this.lightFont) {
        return 'old-unit-price-and-volume__unit-price--light-font'
      }
      if (
        (!this.colorReversed &&
          this.position === 'ask' &&
          !this.colorDisabled) ||
        (this.colorReversed && this.position === 'bid' && !this.colorDisabled)
      ) {
        return 'old-unit-price-and-volume__unit-price--order-ask'
      }
      if (
        (!this.colorReversed &&
          this.position === 'bid' &&
          !this.colorDisabled) ||
        (this.colorReversed && this.position === 'ask' && !this.colorDisabled)
      ) {
        return 'old-unit-price-and-volume__unit-price--order-bid'
      }
      return ''
    },
    hasOnlyBase(): boolean {
      return (
        this.exchangeVolume === undefined ||
        this.volume.toString() === this.exchangeVolume
      )
    },
    spreadDisplay(): string {
      return `${this.volume} ${SPREAD_SYMBOL} ${this.exchangeVolume}`
    },
  },
  methods: {
    formatUnitPrice(unitPrice: number): string {
      return this.formatDisplayAmount(unitPrice, 2)
    },
    formatVolume(volumeOrNull: number | null): string {
      if (volumeOrNull) {
        const value = this.formatDisplayAmount(volumeOrNull, 1)
        return this.maskVolume ? `(${value})` : value
      }
      return this.position === '' ? '' : this.$t('trading.label.tbd').toString()
    },
    formatDisplayAmount(amount: number, minimumFractionDigits: number): string {
      const numberFormat = new Intl.NumberFormat(this.locale, {
        minimumFractionDigits,
        maximumFractionDigits: 3,
        useGrouping: false,
      })
      return numberFormat.format(amount)
    },
  },
})
