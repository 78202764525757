
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import { GlobalModule } from '@/store/modules/common/global'
const { mapState } = createNamespacedHelpers('global') as GlobalModule

export default defineComponent({
  name: 'App',
  components: {
    CenteredLoadingContent,
  },
  computed: {
    ...mapState(['isInitializing']),
  },
})
