
import { defineComponent, PropType } from 'vue'

import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { RecapReceiver } from '@/models/iam/RecapReceiver'

export default defineComponent({
  name: 'RecapReceiverListItem',
  components: {
    ActionDropdown,
    BaseBox,
    BaseLink,
  },
  props: {
    recapReceiver: {
      type: Object as PropType<RecapReceiver>,
      required: true,
    },
    parentPath: {
      type: String,
      required: true,
    },
  },
  emits: ['delete-click'],
  data(): {
    isDropdownActive: boolean
    itemPath: string
  } {
    return {
      isDropdownActive: false,
      itemPath: `${this.parentPath}/${this.recapReceiver.id}`,
    }
  },
  computed: {
    actionItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('iam.label.deleteRecapReceiver').toString(),
          eventName: 'delete-click',
        },
      ]
    },
    dropdownId(): string {
      return `recap-receiver-list-item__delete-${this.recapReceiver.id}`
    },
    department(): string {
      return this.recapReceiver.department || ''
    },
    phoneNumber(): string {
      return this.recapReceiver.phoneNumber || ''
    },
    emailAddress(): string {
      return this.recapReceiver.emailAddress || ''
    },
  },
  methods: {
    onDeleteClick() {
      this.$emit('delete-click')
    },
  },
})
