
import { defineComponent, PropType } from 'vue'

import ReportListItem from '@/components/reports/ReportListItem.vue'
import { Report as ReportModel } from '@/models/reports/Report'

export default defineComponent({
  name: 'ReportList',
  components: {
    ReportListItem,
  },
  props: {
    reports: {
      type: Array as PropType<ReportModel[]>,
      default: () => [],
    },
  },
  emits: ['download-click', 'last-item-visible'],
  methods: {
    onDownloadClick({
      reportId,
      reportContentId,
      attachmentName,
    }: {
      reportId: string
      reportContentId: string
      attachmentName: string
    }) {
      this.$emit('download-click', {
        reportId,
        reportContentId,
        attachmentName,
      })
    },
    visibilityChanged(isVisible: boolean, index: number) {
      if (!isVisible || index !== this.reports.length - 1) {
        return
      }
      this.$emit('last-item-visible')
    },
  },
})
