
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import DeliveryTermsWithPeriod from '@/components/trading/DeliveryTermsWithPeriod.vue'
import DeliveryUnitIcon from '@/components/trading/DeliveryUnitIcon.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { Indication } from '@/models/trading/Indication'

export default defineComponent({
  name: 'IndicationSelectorModalListItem',
  components: {
    BaseBox,
    DeliveryTermsWithPeriod,
    DeliveryUnitIcon,
    PositionIcon,
    ProductType,
  },
  props: {
    indication: {
      type: Object as PropType<Indication>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  emits: ['select'],
  computed: {
    productTypeNames(): string {
      return this.indication.products
        .map(product => product.productType.translation())
        .join(',')
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.indication.fuelSurchargeType?.name.translation()
    },
    deliveryTerms(): DeliveryTermsWithPeriodProps {
      return this.indication.deliveryTerms
    },
    unitPrice(): string {
      return (
        this.formatDisplayAmount(this.indication.unitPrices[0], 2) +
        (this.indication.unitPrices.length > 1 ? '他' : '')
      )
    },
    volume(): string {
      return (
        this.formatDisplayAmount(this.indication.volumes[0], 1) +
        (this.indication.volumes.length > 1 ? '他' : '')
      )
    },
  },
  methods: {
    onSelect(indication: Indication) {
      this.$emit('select', indication)
    },
    formatDisplayAmount(amount: number, minimumFractionDigits: number): string {
      const numberFormat = new Intl.NumberFormat(this.locale, {
        minimumFractionDigits,
        maximumFractionDigits: 3,
        useGrouping: false,
      })
      return numberFormat.format(amount)
    },
  },
})
