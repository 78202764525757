
import { defineComponent } from 'vue'

import BaseButton from '@/components/common/BaseButton.vue'

export default defineComponent({
  name: 'TheNewIndicationButton',
  components: {
    BaseButton,
  },
  emits: ['the-new-indication-button-click'],
  methods: {
    onClick() {
      this.$emit('the-new-indication-button-click')
    },
  },
})
