import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55480aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-period-form" }
const _hoisted_2 = { class: "delivery-period-form__delivery-pattern-component-forms" }
const _hoisted_3 = { class: "delivery-period-form__unit-and-volume" }
const _hoisted_4 = { class: "delivery-period-form__delete-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delivery_date_form = _resolveComponent("delivery-date-form")!
  const _component_delivery_pattern_component_form = _resolveComponent("delivery-pattern-component-form")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_field_unit_price = _resolveComponent("field-unit-price")!
  const _component_field_volume = _resolveComponent("field-volume")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, {
      type: "fill",
      class: "delivery-period-form__form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_delivery_date_form, {
          index: _ctx.index,
          locale: _ctx.locale,
          mode: _ctx.deliveryDateFormInputMode,
          "form-value": _ctx.deliveryDateFormValue,
          "is-shown-label": false,
          "start-delivery-min-date": _ctx.startDeliveryMinDate,
          "start-delivery-max-date": _ctx.startDeliveryMaxDate,
          "end-delivery-min-date": _ctx.endDeliveryMinDate,
          onFormInput: _cache[0] || (_cache[0] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              ...event,
            })
        )
        }, null, 8, ["index", "locale", "mode", "form-value", "start-delivery-min-date", "start-delivery-max-date", "end-delivery-min-date"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValue.deliveryPatternComponentFormValues, (deliveryPatternComponentFormValue, i) => {
            return (_openBlock(), _createBlock(_component_delivery_pattern_component_form, {
              key: i,
              "name-suffix": `${_ctx.index}-${i}`,
              class: "delivery-period-form__delivery-pattern-component-form",
              "form-value": deliveryPatternComponentFormValue,
              "day-pattern-options": _ctx.dayPatternOptions,
              "is-deletable": 
            _ctx.formValue.deliveryPatternComponentFormValues.length > 1
          ,
              mode: _ctx.deliveryPatternComponentFormInputMode,
              onFormInput: value => _ctx.onDeliveryPatternComponentFormInput(i, value),
              onDelete: ($event: any) => (_ctx.onDeleteDeliveryPatternComponentValue(i))
            }, null, 8, ["name-suffix", "form-value", "day-pattern-options", "is-deletable", "mode", "onFormInput", "onDelete"]))
          }), 128)),
          (_ctx.isShownButton)
            ? (_openBlock(), _createBlock(_component_base_button, {
                key: 0,
                class: "delivery-period-form__delivery-pattern-component-add-button",
                onClick: _ctx.addDeliveryPatternComponentValue
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_icon, {
                    "icon-name": "plus",
                    class: "delivery-period-form__delivery-pattern-component-add-icon"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.label.addDeliveryPatternComponent')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_field_unit_price, {
            name: `unitPrice-${_ctx.index}`,
            value: _ctx.formValue.unitPrice,
            mode: _ctx.unitPriceInputMode,
            "is-shown-label": false,
            onInput: _cache[1] || (_cache[1] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, unitPrice: event })
          )
          }, null, 8, ["name", "value", "mode"]),
          _createVNode(_component_field_volume, {
            name: `volumes-${_ctx.index}`,
            value: _ctx.formValue.volume,
            mode: _ctx.volumeInputMode,
            "is-shown-label": false,
            onInput: _ctx.onVolumeInput
          }, null, 8, ["name", "value", "mode", "onInput"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isDeletable && _ctx.isShownButton)
            ? (_openBlock(), _createBlock(_component_base_icon, {
                key: 0,
                class: "delivery-period-form__delete-button",
                "icon-name": "times-circle",
                onClick: _cache[2] || (_cache[2] = event => _ctx.$emit('delete', event))
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}