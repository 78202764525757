import { Getters } from 'vuex-smart-module'

import { ChannelContentProps } from '@/components/chat/interface/ChannelContentProps'
import { ChannelDMProps } from '@/components/chat/interface/ChannelDMProps'
import { ChannelListProps } from '@/components/chat/interface/ChannelListProps'
import { ChannelListWindowProps } from '@/components/chat/interface/ChannelListWindowProps'
import { ChannelMemberProps } from '@/components/chat/interface/ChannelMemberProps'
import { ChannelMessageWindowProps } from '@/components/chat/interface/ChannelMessageWindowProps'
import { ChannelOrganizationProps } from '@/components/chat/interface/ChannelOrganizationProps'
import { MessageProps } from '@/components/chat/interface/MessageProps'
import { ActiveChannel } from '@/models/chat/ActiveChannel'
import { ChannelList } from '@/models/chat/ChannelList'
import { DMChannel } from '@/models/chat/DMChannel'
import { Member } from '@/models/chat/Member'
import { Message } from '@/models/chat/Message'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'
import { StatusType } from '@/models/chat/StatusType'
import { PublicOrganizationProfile } from '@/models/iam/PublicOrganizationProfile'
import { PublicUserProfile } from '@/models/iam/PublicUserProfile'
import { store } from '@/store'
import { ChatState } from '@/store/modules/chat/chat/ChatState'

const getNameOfUserId = (
  userId: string,
  publicUserProfiles: PublicUserProfile[],
): string => {
  return (
    publicUserProfiles.find(profile => profile.userId === userId)?.name || ''
  )
}

const getNameOfOrganizationId = (
  organizationId: string,
  publicOrganizationProfiles: PublicOrganizationProfile[],
): string => {
  return (
    publicOrganizationProfiles
      .find(profile => profile.organizationId === organizationId)
      ?.name.translation() || ''
  )
}

const getOrganizationNameByUserId = (
  userId: string,
  publicUserProfiles: PublicUserProfile[],
  publicOrganizationProfiles: PublicOrganizationProfile[],
): string => {
  const organizationId = publicUserProfiles.find(
    profile => profile.userId === userId,
  )?.organizationId
  return organizationId
    ? getNameOfOrganizationId(organizationId, publicOrganizationProfiles)
    : ''
}

const getPictureOfUserId = (
  userId: string,
  publicUserProfiles: PublicUserProfile[],
): string => {
  return (
    publicUserProfiles.find(profile => profile.userId === userId)?.picture ||
    'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
  )
}

const getPictureOfOrganizationId = (
  organizationId: string,
  publicOrganizationProfiles: PublicOrganizationProfile[],
): string => {
  return (
    publicOrganizationProfiles.find(
      profile => profile.organizationId === organizationId,
    )?.picture ||
    'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
  )
}

const getPictureOfChannel = (
  channel: OrganizationChannel | DMChannel,
  publicOrganizationProfiles: PublicOrganizationProfile[],
  publicUserProfiles: PublicUserProfile[],
) => {
  if (channel.channelType === 'Organization') {
    return getPictureOfOrganizationId(
      channel.profileId,
      publicOrganizationProfiles,
    )
  }
  return getPictureOfUserId(channel.profileId, publicUserProfiles)
}

const getNameOfChannel = (
  channel: OrganizationChannel | DMChannel,
  publicOrganizationProfiles: PublicOrganizationProfile[],
  publicUserProfiles: PublicUserProfile[],
) => {
  if (channel.channelType === 'Organization') {
    return getNameOfOrganizationId(
      channel.profileId,
      publicOrganizationProfiles,
    )
  }
  return getNameOfUserId(channel.profileId, publicUserProfiles)
}

const getMessageProps = (
  messages: Message[],
  publicUserProfiles: PublicUserProfile[],
): MessageProps[] => {
  return messages.map(message => ({
    messageId: message.messageId,
    name: getNameOfUserId(message.userId, publicUserProfiles),
    dateTime: message.dateTime,
    message: message.message,
    picture: getPictureOfUserId(message.userId, publicUserProfiles),
    index: message.index,
  }))
}

const getMemberProps = (
  members: Member[],
  publicUserProfiles: PublicUserProfile[],
): ChannelMemberProps[] => {
  return members.map(member => ({
    name: getNameOfUserId(member.userId, publicUserProfiles),
    picture: getPictureOfUserId(member.userId, publicUserProfiles),
    status: member.status,
  }))
}

const getTypingUserNames = (
  typingUserIds: string[],
  publicUserProfiles: PublicUserProfile[],
) => {
  return typingUserIds.map(id => getNameOfUserId(id, publicUserProfiles))
}

const getChannelContentProps = (
  channelList: ChannelList,
  activeChannel: ActiveChannel,
  publicOrganizationProfiles: PublicOrganizationProfile[],
  publicUserProfiles: PublicUserProfile[],
) => {
  let organizationName = ''
  if (activeChannel.channel && activeChannel.channel.channelType === 'DM') {
    organizationName = getOrganizationNameByUserId(
      activeChannel.channel.profileId,
      store.state.publicUserProfile.publicUserProfiles,
      store.state.publicOrganizationProfile.publicOrganizationProfiles,
    )
  }
  return {
    channelName: activeChannel.channel
      ? getNameOfChannel(
          activeChannel.channel,
          publicOrganizationProfiles,
          publicUserProfiles,
        )
      : '',
    messages: getMessageProps(activeChannel.messages, publicUserProfiles),
    chatInputValue: activeChannel.chatInputValue,
    typingUserNames: getTypingUserNames(
      activeChannel.typingUserIds,
      publicUserProfiles,
    ),
    members: getMemberProps(activeChannel.members, publicUserProfiles),
    organizationName: organizationName,
  }
}

const getChannelOrganizationProps = (
  organizationChannel: OrganizationChannel,
  activeChannel: ActiveChannel,
  publicOrganizationProfiles: PublicOrganizationProfile[],
) => {
  return {
    name: getNameOfOrganizationId(
      organizationChannel.profileId,
      publicOrganizationProfiles,
    ),
    channelId: organizationChannel.channelId,
    picture: getPictureOfOrganizationId(
      organizationChannel.profileId,
      publicOrganizationProfiles,
    ),
    hasUnreadMessage: organizationChannel.hasUnreadMessage,
    selected: activeChannel.channelId === organizationChannel.channelId,
    dateUpdated: organizationChannel.conversationInstance.lastMessage
      ? organizationChannel.conversationInstance.lastMessage.dateCreated
      : undefined,
  }
}

const getChannelDMProps = (
  dMChannel: DMChannel,
  activeChannel: ActiveChannel,
  publicOrganizationProfiles: PublicOrganizationProfile[],
  publicUserProfiles: PublicUserProfile[],
) => {
  return {
    name: getNameOfUserId(dMChannel.profileId, publicUserProfiles),
    channelId: dMChannel.channelId,
    picture: getPictureOfUserId(dMChannel.profileId, publicUserProfiles),
    organizationName: getOrganizationNameByUserId(
      dMChannel.profileId,
      publicUserProfiles,
      publicOrganizationProfiles,
    ),
    hasUnreadMessage: dMChannel.hasUnreadMessage,
    selected: activeChannel.channelId === dMChannel.channelId,
    status: (dMChannel.userInstance.isOnline
      ? 'active'
      : 'inactive') as StatusType,
    dateUpdated: dMChannel.conversationInstance.lastMessage
      ? dMChannel.conversationInstance.lastMessage.dateCreated
      : undefined,
  }
}

const dateSorter = (dateA: Date | undefined, dateB: Date | undefined) => {
  if (dateA && dateB) {
    return dateA > dateB ? -1 : dateB > dateA ? 1 : 0
  }
  return dateB ? 1 : dateA ? -1 : 0
}

// 既読DMチャンネルの最大表示数
const numberOfDisplayedChannels = 15
/**
 * v4 からデフォルトで Getter の結果はキャッシュされるようになった
 * しかし state がオブジェクトの場合プロパティの変更は検知されないため、あえてキャッシュされないメソッドを利用する
 * そのためのメソッドは _xxxMethod という命名規則にしている
 */
export class ChatGetters extends Getters<ChatState> {
  _channelListMethod(): ChannelListProps {
    const organizationChannels = this.state.channelList?.organizationChannels.map(
      channel =>
        getChannelOrganizationProps(
          channel,
          this.state.activeChannel,
          store.state.publicOrganizationProfile.publicOrganizationProfiles,
        ),
    )
    const dMChannels = this.state.channelList?.dMChannels.map(channel =>
      getChannelDMProps(
        channel,
        this.state.activeChannel,
        store.state.publicOrganizationProfile.publicOrganizationProfiles,
        store.state.publicUserProfile.publicUserProfiles,
      ),
    )
    organizationChannels.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
    )
    dMChannels.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))

    return { organizationChannels, dMChannels }
  }

  // このGetterを変更したらchannelListRawStateも変更する必要があるかもしれないので、影響を確認してください
  get channelList(): ChannelListProps {
    const counter = [
      ...this.state.channelList.dMChannels,
      ...this.state.channelList.organizationChannels,
    ].reduce((acc, channel) => acc + channel.counter, 0)
    if (counter < 0) {
      // 再計算を促すために counter を参照しているだけなので特に何もしない
    }
    return this.getters._channelListMethod()
  }

  // このGetterを変更したらchannelListRawStateも変更する必要があるかもしれないので、影響を確認してください
  get channelListWindow(): ChannelListWindowProps {
    return {
      unreadChannelList: this.getters.sortedChannelList[0],
      readChannelList: this.getters.sortedChannelList[1],
      isOpened: this.state.channelListWindowIsOpened,
      hasUnreadMessage: this.state.channelList?.hasUnreadMessage,
      totalNumberOfUnreadMessage: this.state.channelList
        ?.getTotalNumberOfUnreadMessage,
    }
  }

  // channelListWindowと、channelListの処理コストがかかるため、これらの処理をdebounceするための変更検出に使用します。
  // channelListWindowと、channelListを変更したら、このGetterも変更する必要があるかもしれないので、影響を確認してください
  get channelListRawState() {
    return {
      isOpened: this.state.channelListWindowIsOpened,
      activeChannel: this.state.activeChannel,
      starredChannelIds: this.state.starredChannelIds,
      organizationChannels: this.state.channelList.organizationChannels?.map(
        (channel: OrganizationChannel) => {
          return {
            counter: channel.counter,
            lastMessageIndex: channel.lastMessageIndex,
            lastReadMessageIndex: channel.lastReadMessageIndex,
          }
        },
      ),
      dMChannels: this.state.channelList.dMChannels?.map(
        (channel: DMChannel) => {
          return {
            counter: channel.counter,
            lastMessageIndex: channel.lastMessageIndex,
            lastReadMessageIndex: channel.lastReadMessageIndex,
            isOnline: channel.userInstance.isOnline,
          }
        },
      ),
      publicOrganizationProfiles:
        store.state.publicOrganizationProfile.publicOrganizationProfiles,
      publicUserProfiles: store.state.publicUserProfile.publicUserProfiles,
    }
  }

  _sortedChannelListMethod(): [ChannelListProps, ChannelListProps] {
    const channelList = this.getters.channelList
    const starredChannelIds = this.state.starredChannelIds

    const sortedOrganizationChannelList = channelList.organizationChannels?.sort(
      (a, b) => dateSorter(a.dateUpdated, b.dateUpdated),
    )

    const sortedDMChannelList = channelList.dMChannels?.sort((a, b) =>
      dateSorter(a.dateUpdated, b.dateUpdated),
    )

    const unreadChannelFilter = (
      channel: ChannelOrganizationProps | ChannelDMProps,
    ) =>
      channel.hasUnreadMessage &&
      channel.channelId !== this.state.activeChannel.channelId
    const readChannelFilter = (
      channel: ChannelOrganizationProps | ChannelDMProps,
    ) =>
      !channel.hasUnreadMessage ||
      channel.channelId === this.state.activeChannel.channelId

    const unreadOrganizationChannelList =
      sortedOrganizationChannelList?.filter(unreadChannelFilter) || []

    const readOrganizationChannelList =
      sortedOrganizationChannelList?.filter(readChannelFilter) || []

    const unreadDMChannelList =
      sortedDMChannelList?.filter(unreadChannelFilter) || []

    const readDMChannelList =
      sortedDMChannelList?.filter(readChannelFilter) || []

    const starredChannelFilter = (
      channel: ChannelOrganizationProps | ChannelDMProps,
    ) => {
      return starredChannelIds.includes(channel.channelId)
    }
    const unreadStarredOrganizationChannelList = unreadOrganizationChannelList.filter(
      starredChannelFilter,
    )
    const unreadStarredDMChannelList = unreadDMChannelList.filter(
      starredChannelFilter,
    )
    const readStarredOrganizationChannelList = readOrganizationChannelList.filter(
      starredChannelFilter,
    )
    const readStarredDMChannelList = readDMChannelList.filter(
      starredChannelFilter,
    )

    const notStarredChannelFilter = (
      channel: ChannelOrganizationProps | ChannelDMProps,
    ) => {
      return !starredChannelIds.includes(channel.channelId)
    }
    const unreadNotStarredOrganizationChannelList = unreadOrganizationChannelList.filter(
      notStarredChannelFilter,
    )
    const unreadNotStarredDMChannelList = unreadDMChannelList.filter(
      notStarredChannelFilter,
    )
    const readNotStarredOrganizationChannelList = readOrganizationChannelList.filter(
      notStarredChannelFilter,
    )
    const readNotStarredDMChannelList = readDMChannelList
      .filter(notStarredChannelFilter)
      .filter((value, index) => index < numberOfDisplayedChannels)

    const isActiveChannelIncludedChannelListOfDM = (): boolean => {
      return [
        ...readStarredDMChannelList,
        ...readNotStarredDMChannelList,
        ...unreadStarredDMChannelList,
        ...unreadNotStarredDMChannelList,
      ].some(
        channel => channel.channelId === this.state.activeChannel.channelId,
      )
    }

    // チャンネルリストの既読のDMチャンネルは最大15件が表示される仕様だが、
    // DMチャンネル検索によって非表示のチャンネルがアクティブになる場合がある。
    // その場合はアクティブなDMチャンネルをチャンネルリストに追加する。
    if (
      this.state.activeChannel.channel?.channelType === 'DM' &&
      !isActiveChannelIncludedChannelListOfDM()
    ) {
      const activeDMChannel = getChannelDMProps(
        this.state.activeChannel.channel as DMChannel,
        this.state.activeChannel,
        store.state.publicOrganizationProfile.publicOrganizationProfiles,
        store.state.publicUserProfile.publicUserProfiles,
      )
      readNotStarredDMChannelList.push(activeDMChannel)
    }

    return [
      {
        starredOrganizationChannels: unreadStarredOrganizationChannelList,
        starredDMChannels: unreadStarredDMChannelList,
        organizationChannels: unreadNotStarredOrganizationChannelList,
        dMChannels: unreadNotStarredDMChannelList,
      },
      {
        starredOrganizationChannels: readStarredOrganizationChannelList,
        starredDMChannels: readStarredDMChannelList,
        organizationChannels: readNotStarredOrganizationChannelList,
        dMChannels: readNotStarredDMChannelList,
      },
    ]
  }

  get sortedChannelList(): [ChannelListProps, ChannelListProps] {
    const counter = [
      ...this.state.channelList.dMChannels,
      ...this.state.channelList.organizationChannels,
    ].reduce((acc, channel) => acc + channel.counter, 0)
    if (counter < 0) {
      // 再計算を促すために counter を参照しているだけなので特に何もしない
    }
    return this.getters._sortedChannelListMethod()
  }

  get isOpenDMListButtonDisplayed(): boolean {
    return this.state.channelList.dMChannels?.length > numberOfDisplayedChannels
  }

  get channelContent(): ChannelContentProps {
    return getChannelContentProps(
      this.state.channelList,
      this.state.activeChannel,
      store.state.publicOrganizationProfile.publicOrganizationProfiles,
      store.state.publicUserProfile.publicUserProfiles,
    )
  }

  get channelMessageWindowList(): ChannelMessageWindowProps[] {
    return this.state.activeChannelWindowList.activeChannelWindows.flatMap(
      window => {
        if (window.activeChannel.channel) {
          return [
            {
              ...getChannelContentProps(
                this.state.channelList,
                window.activeChannel,
                store.state.publicOrganizationProfile
                  .publicOrganizationProfiles,
                store.state.publicUserProfile.publicUserProfiles,
              ),
              channelId: window.activeChannel.channelId,
              channelPicture: getPictureOfChannel(
                window.activeChannel.channel,
                store.state.publicOrganizationProfile
                  .publicOrganizationProfiles,
                store.state.publicUserProfile.publicUserProfiles,
              ),
              isOpened: window.isOpened,
            },
          ]
        }
        return []
      },
    )
  }

  get isChannelListEmpty(): boolean {
    return (
      this.state.channelList.dMChannels.length === 0 &&
      this.state.channelList.organizationChannels.length === 0
    )
  }

  get isActiveChannelStarred(): boolean {
    if (!this.state.activeChannel.channel) {
      return false
    }
    return this.state.starredChannelIds.includes(
      this.state.activeChannel.channel.channelId,
    )
  }

  get activeChannelPictureData():
    | { picture: string; isOrganization: boolean }
    | undefined {
    const { dMChannels, organizationChannels } = this.getters.channelList
    const mergedChannelList = [
      ...(dMChannels ?? []),
      ...(organizationChannels ?? []),
    ]
    const activeChannel = mergedChannelList.find(list => list.selected)
    return activeChannel
      ? {
          picture: activeChannel.picture,
          isOrganization: !('organizationName' in activeChannel),
        }
      : undefined
  }
}
