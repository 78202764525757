import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c10d2c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "unauthorized" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_link = _resolveComponent("base-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('common.message.requireEsquareSubscription')), 1),
    _createVNode(_component_base_link, {
      path: _ctx.contactUrl,
      "is-external": ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.contactUrl), 1)
      ]),
      _: 1
    }, 8, ["path"])
  ]))
}