
import { defineComponent, PropType } from 'vue'

import { IndicationStatusType, Locale } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import DeliveryTermsWithPeriod from '@/components/trading/DeliveryTermsWithPeriod.vue'
import MyIndicationStatus from '@/components/trading/MyIndicationStatus.vue'
import OldUnitPriceAndVolume from '@/components/trading/OldUnitPriceAndVolume.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MyIndication } from '@/models/trading/MyIndication'
import { Product } from '@/models/trading/Product'

export default defineComponent({
  name: 'MyIndication',
  components: {
    ActionDropdown,
    BaseBox,
    DeliveryTermsWithPeriod,
    MyIndicationStatus,
    OldUnitPriceAndVolume,
    PositionIcon,
    ProductType,
  },
  props: {
    myIndication: {
      type: Object as PropType<MyIndication>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    memberProfiles: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
    isEclearEnabled: {
      type: Boolean,
      default: false,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'activate', 'deactivate', 'delete'],
  data() {
    return {
      isActive: false,
      isActionDropdownActive: false,
    }
  },
  computed: {
    myIndicationClass(): object {
      return { 'my-indication__inner--active': this.isActive }
    },
    productTypeNames(): string {
      return this.myIndication.products
        .map(product => product.productType.translation())
        .join(',')
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.myIndication.fuelSurchargeType?.name.translation()
    },
    product(): Product {
      return this.myIndication.products[0]
    },
    createdParticipantName(): string {
      return (
        this.memberProfiles.find(
          profile => profile.userId === this.myIndication.createdParticipantId,
        )?.name || this.$t('iam.label.enechainUserName').toString()
      )
    },
    actions(): ActionDropdownItem[] {
      const isIncludedEclear = this.myIndication.products.some(p =>
        p.productType.isEclear(),
      )
      const activateDisabled =
        IndicationStatusType.Inactive !== this.myIndication.status ||
        (isIncludedEclear && !this.isEclearEnabled)
      const activateAction = {
        label: this.$t('trading.label.activate').toString(),
        eventName: 'activate',
        disabled: activateDisabled,
      }

      const deactivateDisabled =
        !([
          IndicationStatusType.Unconfirmed,
          IndicationStatusType.Active,
        ] as IndicationStatusType[]).includes(this.myIndication.status) ||
        (isIncludedEclear && !this.isEclearEnabled)
      const deactivateAction = {
        label: this.$t('trading.label.deactivate').toString(),
        eventName: 'deactivate',
        disabled: deactivateDisabled,
      }

      const deleteDisabled =
        !([
          IndicationStatusType.Unconfirmed,
          IndicationStatusType.Active,
          IndicationStatusType.Inactive,
          IndicationStatusType.Expired,
        ] as IndicationStatusType[]).includes(this.myIndication.status) ||
        (isIncludedEclear && !this.isEclearEnabled)
      const deleteAction = {
        label: this.$t('trading.label.delete').toString(),
        eventName: 'delete',
        disabled: deleteDisabled,
      }

      return [activateAction, deactivateAction, deleteAction]
    },
    deliveryTerms(): DeliveryTermsWithPeriodProps {
      return this.product.deliveryTermsWithPeriod
    },
  },
  methods: {
    onClick() {
      this.$emit('select')
    },
    onMouseOver() {
      this.isActive = true
    },
    onMouseLeave() {
      this.isActive = false
    },
    onActionDropdownClick() {
      this.isActionDropdownActive = !this.isActionDropdownActive
    },
    onActivate() {
      this.$emit('activate')
    },
    onDeactivate() {
      this.$emit('deactivate')
    },
    onDelete() {
      this.$emit('delete')
    },
    onClickOutside() {
      this.isActionDropdownActive = false
    },
  },
})
