
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { PER_PAGE } from '@/components/common/constants/List'
import { LoadingType } from '@/components/common/constants/LoadingType'
import { UiStack } from '@/components/common/constants/UiStack'
import PostingList from '@/components/trading/EEXPostingList.vue'
import ListHeader from '@/components/trading/EEXPostingListHeader.vue'
import { EEXPostingFormInputMode } from '@/components/trading/constants/EEXPostingFormInputMode'
import EexPosting from '@/components/trading/container/EEXPosting.vue'
import { EEXPosting } from '@/models/trading/EEXPosting'
import { EEXPostingResponse } from '@/models/trading/EEXPostingResponse'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { EEXPostingModule } from '@/store/modules/trading/eexPosting'

const { mapActions: eexPostingMapActions } = createNamespacedHelpers(
  'eexPosting',
) as EEXPostingModule

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'EEXPostings',
  components: {
    BaseAlert,
    BaseButton,
    BaseIcon,
    BaseModal,
    BrokerPage,
    CenteredLoadingContent,
    EexPosting,
    ListHeader,
    PostingList,
    UiStackSelector,
  },
  data() {
    return {
      eexPostings: [] as EEXPosting[],
      nextPageToken: undefined as string | undefined,
      selectedEEXPosting: undefined as EEXPosting | undefined,
      eexPostingFormInputMode: EEXPostingFormInputMode.NEW as EEXPostingFormInputMode,
      isLoaded: false,
      uiStack: UiStack.Loading,
    }
  },
  computed: {
    modalName: () => 'eex-posting',
    alertTypeInfo: () => AlertType.Info,
    alertTypeError: () => AlertType.Error,
    grayscale: () => LoadingType.GRAYSCALE,
    layoutMode(): LayoutMode {
      return LayoutMode.Wide
    },
    ...userProfileMapGetters(['userProfile']),
  },
  async created() {
    await this.fetchList()
  },
  methods: {
    fetchEEXPostings: eexPostingMapActions(['fetchEEXPostings'])
      .fetchEEXPostings as ({
      pageSize,
      pageToken,
    }: {
      pageSize?: number
      pageToken?: string
    }) => Promise<EEXPostingResponse>,
    async fetchList() {
      this.eexPostings = []
      this.isLoaded = false
      await this.fetchEEXPostings({
        pageSize: PER_PAGE,
        pageToken: undefined,
      })
        .then(({ eexPostings, nextPageToken }: EEXPostingResponse) => {
          this.eexPostings = eexPostings
          this.nextPageToken = nextPageToken
          this.isLoaded = eexPostings.length === 0 || nextPageToken === null
          if (this.eexPostings.length === 0) {
            this.uiStack = UiStack.Blank
          } else {
            this.uiStack = UiStack.Ideal
          }
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    async fetchNextList() {
      const { eexPostings, nextPageToken } = await this.fetchEEXPostings({
        pageSize: PER_PAGE,
        pageToken: this.nextPageToken ?? undefined,
      }).catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
      this.eexPostings.push(...eexPostings)
      this.nextPageToken = nextPageToken
      this.isLoaded =
        this.isLoaded || nextPageToken === null || eexPostings.length === 0
    },
    onRepostClick(id: string) {
      this.selectedEEXPosting = this.eexPostings.find(
        eexPosting => eexPosting.id === id,
      )
      this.eexPostingFormInputMode = EEXPostingFormInputMode.REPOST
      this.$vfm.open(this.modalName)
    },
    onLastItemVisible() {
      if (!this.isLoaded) {
        this.fetchNextList()
      }
    },
    newEEXPostingClick() {
      this.selectedEEXPosting = undefined
      this.eexPostingFormInputMode = EEXPostingFormInputMode.NEW
      this.$vfm.open(this.modalName)
    },
  },
})
