import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b33f05"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input-password" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "base-input-password__input" }
const _hoisted_4 = ["type", "value"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_validation_form = _resolveComponent("validation-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_label, {
        label: _ctx.labelString,
        class: "base-input-password__label"
      }, null, 8, ["label"]),
      _createElementVNode("div", null, [
        _createVNode(_component_validation_form, { rules: _ctx.rules }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("input", {
                class: _normalizeClass(["input", { 'is-danger': !!errors[0] }]),
                type: _ctx.mode,
                value: _ctx.value,
                onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
              }, null, 42, _hoisted_4),
              _createElementVNode("button", {
                class: "base-input-password__icon",
                type: "button",
                "aria-label": 
                _ctx.visible
                  ? _ctx.$t('iam.label.hidePassword')
                  : _ctx.$t('iam.label.showPassword')
              ,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
              }, [
                (_ctx.visible)
                  ? (_openBlock(), _createBlock(_component_base_icon, {
                      key: 0,
                      "icon-name": "eye-slash"
                    }))
                  : (_openBlock(), _createBlock(_component_base_icon, {
                      key: 1,
                      "icon-name": "eye"
                    }))
              ], 8, _hoisted_5)
            ]),
            (errors[0])
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(errors[0]), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["rules"])
      ])
    ])
  ]))
}