import { Actions } from 'vuex-smart-module'

import { ProductTypeValueEnum } from '@/api/generated'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { DeliverySummaries as DeliverySummariesModel } from '@/models/trading/DeliverySummaries'
import { RecapGetters } from '@/store/modules/trading/recap/RecapGetters'
import { RecapMutations } from '@/store/modules/trading/recap/RecapMutations'
import { RecapState } from '@/store/modules/trading/recap/RecapState'
import { convertBgAndCfgData } from '@/store/modules/trading/recap/convertBg'
import { convertSwapEexData } from '@/store/modules/trading/recap/convertEex'
import { convertSwapBilateralData } from '@/store/modules/trading/recap/convertSwapBilateral'
import { convertSwapTocomData } from '@/store/modules/trading/recap/convertTocom'
import { getRecapTitle, SERVICE } from '@/store/modules/trading/recap/helper'
import {
  CreateRequest,
  RecapBulkResponse,
  RecapCreatedOption,
  RecapDetail,
  RecapInfo,
} from '@/store/modules/trading/recap/interface'

const getHeader = (token: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
}

// @see https://www.notion.so/enechain/Recap-b39d4f89e67e46dc9a87afbe7b29b140

type FetchRequest = {
  token: string
  identifier: string
}

type FetchBulkRequest = {
  token: string
  identifiers: string[]
}

type CreateRequestProps = {
  token: string
  organizationId: string
  userName: string
  askOrganizationMembers: MemberProfile[]
  bidOrganizationMembers: MemberProfile[]
  userOrganizationMembers: MemberProfile[]
  askOrganizationEmailAddresses: OrganizationEmailAddress[]
  bidOrganizationEmailAddresses: OrganizationEmailAddress[]
  askOrganization: Organization
  bidOrganization: Organization
  userOrganizationEmailAddresses: OrganizationEmailAddress[]
  dealWithOrganizationRecap: DealWithOrganizationRecap
  deliverySummaries: DeliverySummariesModel
  recapCreatedOptions: RecapCreatedOption
}

const convertRecapDetails = (
  userName: string,
  data: DealWithOrganizationRecap,
  summary: DeliverySummariesModel,
  askOrganizationMembers: MemberProfile[],
  bidOrganizationMembers: MemberProfile[],
  userOrganizationMembers: MemberProfile[],
  askOrganization: Organization,
  bidOrganization: Organization,
  askOrganizationEmailAddresses: OrganizationEmailAddress[],
  bidOrganizationEmailAddresses: OrganizationEmailAddress[],
  userOrganizationEmailAddresses: OrganizationEmailAddress[],
  recapCreatedOptions: RecapCreatedOption,
): RecapDetail[] => {
  const { deal } = data
  switch (deal.product.productType.value) {
    case ProductTypeValueEnum.BgNoFuelSurcharge:
    case ProductTypeValueEnum.BgWithFuelSurcharge:
    case ProductTypeValueEnum.Cfd:
    case ProductTypeValueEnum.CfdWithFuelSurcharge:
      return convertBgAndCfgData(
        userName,
        data,
        summary,
        askOrganizationMembers,
        bidOrganizationMembers,
        userOrganizationMembers,
        askOrganization,
        bidOrganization,
        askOrganizationEmailAddresses,
        bidOrganizationEmailAddresses,
        userOrganizationEmailAddresses,
        recapCreatedOptions,
      )
    case ProductTypeValueEnum.SwapBilateral:
      return convertSwapBilateralData(
        userName,
        data,
        summary,
        askOrganizationMembers,
        bidOrganizationMembers,
        userOrganizationMembers,
        askOrganization,
        bidOrganization,
        askOrganizationEmailAddresses,
        bidOrganizationEmailAddresses,
        userOrganizationEmailAddresses,
        recapCreatedOptions,
      )
    case ProductTypeValueEnum.SwapEex:
      return convertSwapEexData(
        userName,
        data,
        summary,
        askOrganizationMembers,
        bidOrganizationMembers,
        userOrganizationMembers,
        askOrganization,
        bidOrganization,
        askOrganizationEmailAddresses,
        bidOrganizationEmailAddresses,
        userOrganizationEmailAddresses,
        recapCreatedOptions,
      )
    case ProductTypeValueEnum.SwapTocom:
      return convertSwapTocomData(
        userName,
        data,
        summary,
        askOrganizationMembers,
        bidOrganizationMembers,
        userOrganizationMembers,
        askOrganization,
        bidOrganization,
        askOrganizationEmailAddresses,
        bidOrganizationEmailAddresses,
        userOrganizationEmailAddresses,
        recapCreatedOptions,
      )
  }
  return []
}

export class RecapActions extends Actions<
  RecapState,
  RecapGetters,
  RecapMutations,
  RecapActions
> {
  async fetchRecap(props: FetchRequest): Promise<RecapInfo> {
    try {
      const res = await fetch(
        `${process.env.VUE_APP_RECAP_API_URL}/bff_operator_api.recap.v1.RecapService/Get`,
        {
          method: 'POST',
          headers: getHeader(props.token),
          body: JSON.stringify({
            identifier: props.identifier,
            service: SERVICE,
          }),
        },
      )
      return (await res?.json()) as RecapInfo
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
      throw e
    }
  }

  async createRecap(props: CreateRequestProps): Promise<RecapInfo> {
    try {
      const body: CreateRequest = {
        name: getRecapTitle(props.dealWithOrganizationRecap),
        identifier: props.dealWithOrganizationRecap.deal.id,
        identifier2: props.dealWithOrganizationRecap.deal.negotiationId,
        organization: props.organizationId,
        service: SERVICE,
        items: convertRecapDetails(
          props.userName,
          props.dealWithOrganizationRecap,
          props.deliverySummaries,
          props.askOrganizationMembers,
          props.bidOrganizationMembers,
          props.userOrganizationMembers,
          props.askOrganization,
          props.bidOrganization,
          props.askOrganizationEmailAddresses,
          props.bidOrganizationEmailAddresses,
          props.userOrganizationEmailAddresses,
          props.recapCreatedOptions,
        ),
      }
      const res = await fetch(
        `${process.env.VUE_APP_RECAP_API_URL}/bff_operator_api.recap.v1.RecapService/Create`,
        {
          method: 'POST',
          headers: getHeader(props.token),
          body: JSON.stringify(body),
        },
      )
      return (await res?.json()) as RecapInfo
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
      throw e
    }
  }

  async fetchBulkRecap(props: FetchBulkRequest): Promise<RecapBulkResponse> {
    try {
      const res = await fetch(
        `${process.env.VUE_APP_RECAP_API_URL}/bff_operator_api.recap.v1.RecapService/BulkList`,
        {
          method: 'POST',
          headers: getHeader(props.token),
          body: JSON.stringify({
            Identifiers: props.identifiers,
          }),
        },
      )
      return (await res?.json()) as RecapBulkResponse
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
      throw e
    }
  }
}
