
import { defineComponent, PropType } from 'vue'

import ChannelMemberList from '@/components/chat/ChannelMemberList.vue'
import { ChannelMemberProps } from '@/components/chat/interface/ChannelMemberProps'
import BaseDropdown from '@/components/common/BaseDropdown.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import InnerLabel from '@/components/common/Typography/InnerLabel.vue'

export default defineComponent({
  name: 'ChannelMemberCount',
  components: {
    BaseDropdown,
    BaseIcon,
    ChannelMemberList,
    InnerLabel,
  },
  props: {
    members: {
      type: Array as PropType<ChannelMemberProps[]>,
      default: () => [],
    },
  },
  data() {
    return {
      isMemberListShown: false,
    }
  },
  computed: {
    memberCount(): number {
      return this.members.length
    },
  },
  methods: {
    showMemberList() {
      this.isMemberListShown = !this.isMemberListShown
    },
  },
})
