import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eaf711c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "old-unit-price-and-volume" }
const _hoisted_2 = { class: "old-unit-price-and-volume__wrapper" }
const _hoisted_3 = { class: "old-unit-price-and-volume__wrapper" }
const _hoisted_4 = { class: "old-unit-price-and-volume__column" }
const _hoisted_5 = {
  key: 0,
  class: "old-unit-price-and-volume__calendar-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["old-unit-price-and-volume__unit-price", _ctx.UnitPriceAndVolumeTextClass])
      }, _toDisplayString(_ctx.formatUnitPrice(_ctx.unitPrice)), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasOnlyBase)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["old-unit-price-and-volume__volume", _ctx.UnitPriceAndVolumeTextClass])
            }, _toDisplayString(_ctx.formatVolume(_ctx.volume)), 3))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["old-unit-price-and-volume__volume old-unit-price-and-volume__volume__text old-unit-price-and-volume__volume__text--spread", _ctx.UnitPriceAndVolumeTextClass])
            }, _toDisplayString(_ctx.spreadDisplay), 3))
      ]),
      (!!_ctx.createdAt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_base_tooltip, {
              label: `${_ctx.$t('trading.label.executedDate')} ${_ctx.createdAt}`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, { "icon-name": "calendar-alt" })
              ]),
              _: 1
            }, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}