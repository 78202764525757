
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { OrganizationType, PositionType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import FuelSurchargeTypeTab from '@/components/trading/FuelSurchargeTypeTab.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import PublicOrderBookList from '@/components/trading/PublicOrderBookList.vue'
import TheNewIndicationButton from '@/components/trading/TheNewIndicationButton.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import MyIndicationNew from '@/components/trading/container/MyIndicationNew.vue'
import { MyIndicationNewProps } from '@/components/trading/interface/MyIndicationNewProps'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { DayPattern } from '@/models/trading/DayPattern'
import { DeliveryUnitOptions } from '@/models/trading/DeliveryUnitOptions'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { SET_SERVICES } from '@/store/modules/iam/userProfile/UserProfileMutations'
import { MyIndicationModule } from '@/store/modules/trading/myIndication'
import { ProductModule } from '@/store/modules/trading/product'
import { PublicIndicationModule } from '@/store/modules/trading/publicIndication'
import {
  BaseFilterAttribute,
  TAB_INDEXES,
} from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'
import { setNotification } from '@/utils/utils'

type StateType = {
  isFilterActionDropdownActive: boolean
  intervalId: number
  isDownloadActionDropdownActive: boolean
  downloadActions: ActionDropdownItem[]
  activeTabIndex: number
  dayPatternOptions: InputOption[]
  baseProductTypeDateName: string | undefined
  exchangeProductTypeDateName: string | undefined
  indicationFormInputMode: IndicationFormInputMode
  fixed?: MyIndicationNewProps
  isBeforeUnmountCalled: boolean
  selectedDeliveryTermsId: string | undefined
  selectedPosition: PositionType | undefined
  layoutMode: LayoutMode
  uiStack: UiStack
  alertType: AlertType
}

type MappedArrayType<T> = {
  tabIndex: number
  data: Record<typeof TAB_INDEXES[keyof typeof TAB_INDEXES], T>
}

const getMappedArray = <T extends Array<unknown>>({
  tabIndex,
  data,
}: MappedArrayType<T>) => {
  if (tabIndex in data) {
    return data[tabIndex]
  }
  return []
}

const {
  mapMutations: userProfileMapMutations,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule
const {
  mapState: publicIndicationMapState,
  mapActions: publicIndicationMapActions,
  mapGetters: publicIndicationMapGetters,
} = createNamespacedHelpers('publicIndication') as PublicIndicationModule
const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule
const { mapActions: myIndicationMapActions } = createNamespacedHelpers(
  'myIndication',
) as MyIndicationModule

export default defineComponent({
  name: 'Order',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    BaseIcon,
    BaseModal,
    CenteredLoadingContent,
    FuelSurchargeTypeTab,
    MyIndicationNew,
    ProductFilter,
    PublicOrderBookList,
    TheNewIndicationButton,
    TraderPage,
    UiStackSelector,
  },
  data(): StateType {
    return {
      isFilterActionDropdownActive: false,
      intervalId: 0,
      isDownloadActionDropdownActive: false,
      downloadActions: [
        {
          label: this.$t('trading.message.orderCsvDownload').toString(),
          eventName: 'order-csv-download-click',
        },
        {
          label: this.$t('trading.message.recentDealCsvDownload').toString(),
          eventName: 'recent-deal-csv-download-click',
        },
      ],
      activeTabIndex: TAB_INDEXES.NO_FUEL_SURCHARGE,
      dayPatternOptions: [],
      baseProductTypeDateName: undefined,
      exchangeProductTypeDateName: undefined,
      indicationFormInputMode: IndicationFormInputMode.NEW,
      isBeforeUnmountCalled: false,
      selectedDeliveryTermsId: undefined,
      selectedPosition: undefined,
      layoutMode: LayoutMode.Wide,
      uiStack: UiStack.Loading,
      alertType: AlertType.Error,
    }
  },
  computed: {
    ...userProfileMapState(['services', 'userId']),
    ...userProfileMapGetters(['userProfile']),
    ...productMapState([
      'productTypes',
      'areas',
      'startDeliveryYearMonths',
      'endDeliveryYearMonths',
      'hourTypes',
      'fuelSurchargeTypes',
    ]),
    ...publicIndicationMapState([
      'filterCondition',
      'filterConditionWithFuelSurcharge',
      'filterAttributes',
      'selectedFilters',
      'selectedFiltersWithFuelSurcharge',
      'publicIndications',
      'publicDeals',
    ]),
    ...publicIndicationMapGetters([
      'filteredPublicIndications',
      'filteredPublicIndicationsWithFuelSurcharge',
      'filteredPublicDeals',
      'filteredPublicDealsWithFuelSurcharge',
      'productFilter',
      'productFilterWithFuelSurcharge',
    ]),
    withFuelSurcharge(): boolean {
      return this.activeTabIndex === TAB_INDEXES.WITH_FUEL_SURCHARGE
    },
    isEclearEnabled(): boolean {
      return (
        !!this.userProfile.services.eclear?.appSetting.enabled &&
        !!this.userProfile.services.eclear?.appSetting.isTrader
      )
    },
    filterActions(): ActionDropdownItem[] {
      return (this.filterAttributes as BaseFilterAttribute[]).map(
        attribute => ({
          label: this.$t('trading.label.' + attribute),
          eventName: attribute,
        }),
      ) as ActionDropdownItem[]
    },
    orderBookIndications(): PublicIndication[] {
      return getMappedArray<PublicIndication[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.filteredPublicIndications,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .filteredPublicIndicationsWithFuelSurcharge,
        },
      })
    },
    orderBookDeals(): PublicDeal[] {
      return getMappedArray<PublicDeal[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.filteredPublicDeals,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .filteredPublicDealsWithFuelSurcharge,
        },
      })
    },
    productTypeFilterCondition(): string[] {
      return getMappedArray<string[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.filterCondition.productType,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .filterConditionWithFuelSurcharge.productType,
        },
      })
    },
    isBgActiveTabIndex(): boolean {
      return [
        TAB_INDEXES.NO_FUEL_SURCHARGE,
        TAB_INDEXES.WITH_FUEL_SURCHARGE,
      ].includes(this.activeTabIndex)
    },
    etenderActiveTabIndex(): boolean {
      return this.activeTabIndex === TAB_INDEXES.ETENDER
    },
    productFilters(): AttributeFilter[] {
      return getMappedArray<AttributeFilter[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.productFilter({
            locale: this.userProfile.locale,
            areas: this.areas,
            hourTypes: this.hourTypes,
            productTypes: this.productTypes,
          }),
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this.productFilterWithFuelSurcharge(
            {
              locale: this.userProfile.locale,
              areas: this.areas,
              hourTypes: this.hourTypes,
              productTypes: this.productTypes,
            },
          ),
        },
      })
    },
    selectedProductFilters(): BaseFilterAttribute[] {
      return getMappedArray<BaseFilterAttribute[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.selectedFilters,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .selectedFiltersWithFuelSurcharge,
        },
      })
    },
    deliveryUnitOptions(): InputOption[] {
      return new DeliveryUnitOptions().getOptions(OrganizationType.Trader)
    },
  },
  async created() {
    if (this.$route.query.tabIndex) {
      this.activeTabIndex = Number(this.$route.query.tabIndex)
    }
    this.loadFiltersFromLocalStorage()
    Promise.all([
      this.fetchDayPatterns() as Promise<DayPattern[]>,
      this.fetchProductTypes(),
      this.fetchAreas(),
      this.fetchHourTypes(),
      this.fetchDeliveryYearMonths(),
      this.fetchFuelSurchargeTypes(),
    ])
      .then(([dayPatterns]) => {
        this.dayPatternOptions = dayPatterns.map(d =>
          d.toInputOption(this.userProfile.locale),
        )
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })

    const fetchOrderData = async () => {
      await Promise.all([
        this.fetchPublicIndications(),
        this.fetchPublicDeals(),
      ])
    }

    await fetchOrderData().catch(e => {
      this.uiStack = UiStack.Error
      throw e
    })
    this.uiStack = UiStack.Ideal

    if (this.isBeforeUnmountCalled) {
      return
    }
    this.intervalId = window.setInterval(fetchOrderData, 300000)
  },
  beforeUnmount() {
    this.isBeforeUnmountCalled = true
    clearInterval(this.intervalId)
  },
  methods: {
    ...productMapActions([
      'fetchProductTypes',
      'fetchAreas',
      'fetchHourTypes',
      'fetchDeliveryYearMonths',
      'fetchDayPatterns',
      'fetchFuelSurchargeTypes',
      'fetchProductTypeDateName',
    ]),
    fetchPublicLastDealsCsvString: publicIndicationMapActions([
      'fetchPublicLastDealsCsvString',
    ]).fetchPublicLastDealsCsvString as () => Promise<string>,
    fetchPublicIndicationsCsvString: publicIndicationMapActions([
      'fetchPublicIndicationsCsvString',
    ]).fetchPublicIndicationsCsvString as () => Promise<string>,
    ...publicIndicationMapActions([
      'fetchPublicIndications',
      'fetchPublicDeals',
      'fetchPublicRecentDeals',
      'addFilterConditionSelected',
      'removeFilterConditionSelected',
      'removeFilterCondition',
      'changeFilter',
      'loadFiltersFromLocalStorage',
    ]),
    ...myIndicationMapActions(['createMyIndication']),
    ...userProfileMapMutations([SET_SERVICES]),
    updateLocalStorageOfSelectedFilters(
      key: string,
      value: BaseFilterAttribute[],
    ) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    downloadOrderCsvData(
      indications: PublicIndication[] | BrokingIndication[],
      isBroking: boolean,
    ) {
      const csv = this.createOrderCsvData(indications, isBroking)
      this.downloadCsvData(csv, 'order-data')
      this.isDownloadActionDropdownActive = false
    },
    downloadRecentTradedCsvData(
      deals: PublicDeal[] | BrokingDeal[],
      isBroking: boolean,
    ) {
      const csv = this.createRecentTradedCsvData(deals, isBroking)
      this.downloadCsvData(csv, 'recent-traded-data')
      this.isDownloadActionDropdownActive = false
    },
    createOrderCsvData(
      indications: PublicIndication[] | BrokingIndication[],
      isBroking: boolean,
    ) {
      const locale = this.userProfile.locale
      const header = this.createCsvHeader(true, isBroking)
      const rows = [header.join(',')]

      for (const indication of indications) {
        const indicationElements = []
        if (isBroking && indication instanceof BrokingIndication) {
          indicationElements.push(
            indication.publicOrganizationProfile.name.translationOf(locale),
          )
        }
        indicationElements.push(
          indication.position === 'ask'
            ? this.$t('trading.label.ask')
            : this.$t('trading.label.bid'),
        )

        indicationElements.push(
          indication.fuelSurchargeType?.translationOf(locale),
        )
        indicationElements.push(indication.unitPrice)
        indicationElements.push(indication.volume)
        for (const product of indication.products) {
          const productElements = []
          productElements.push(product.area.translationOf(locale))
          productElements.push(product.formatStartDelivery)
          productElements.push(product.formatEndDelivery)
          productElements.push(product.hourType?.translationOf(locale) ?? '')
          productElements.push(product.productType.translationOf(locale))
          const row = [...productElements, ...indicationElements]

          rows.push(`"${row.join('","')}"`)
        }
      }
      return rows.join('\n')
    },
    createRecentTradedCsvData(
      deals: PublicDeal[] | BrokingDeal[],
      isBroking: boolean,
    ) {
      const locale = this.userProfile.locale
      const header = this.createCsvHeader(false, isBroking)
      const rows = [header.join(',')]

      for (const deal of deals) {
        const dealElements = []
        if (isBroking) {
          dealElements.push(
            (deal as BrokingDeal).sellerAccountName.translationOf(locale),
            (deal as BrokingDeal).buyerAccountName.translationOf(locale),
          )
        }

        dealElements.push(this.$t('trading.label.last'))

        dealElements.push(deal.fuelSurchargeType?.translationOf(locale))
        dealElements.push(deal.unitPrice)
        dealElements.push(deal.volume)
        dealElements.push(deal.displayCreatedAt())

        const productElements = []
        productElements.push(deal.product.area.translationOf(locale))
        productElements.push(deal.product.formatStartDelivery)
        productElements.push(deal.product.formatEndDelivery)
        productElements.push(deal.product.hourType?.translationOf(locale) ?? '')
        productElements.push(deal.product.productType.translationOf(locale))
        const row = [...productElements, ...dealElements]

        rows.push(`"${row.join('","')}"`)
      }
      return rows.join('\n')
    },
    createCsvHeader(isIndication: boolean, isBroking: boolean) {
      const header = [
        this.$t('trading.label.area'),
        this.$t('trading.label.startDelivery'),
        this.$t('trading.label.endDelivery'),
        this.$t('trading.label.hourType'),
        this.$t('trading.label.productType'),
        this.$t('trading.label.orderType'),
        this.$t('trading.label.fuelSurchargeType'),
        this.$t('trading.label.unitPrice'),
        this.$t('trading.label.volume'),
        this.$t('trading.label.executedDate'),
      ]
      if (isBroking) {
        if (isIndication) {
          header.splice(5, 0, this.$t('trading.label.accountName'))
        } else {
          header.splice(
            5,
            0,
            this.$t('trading.label.sellerName'),
            this.$t('trading.label.buyerName'),
          )
        }
      }
      return header
    },
    downloadCsvData(csv: string, prefixFileName: string) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const downloadLink = document.createElement('a')
      downloadLink.download = `${prefixFileName}-${moment().format(
        'YYYYMMDDTHHmmSS',
      )}.csv`
      downloadLink.href = URL.createObjectURL(
        new Blob([bom, csv], { type: 'text/csv' }),
      )
      downloadLink.dataset.downloadurl = [
        'text/csv',
        downloadLink.download,
        downloadLink.href,
      ].join(':')
      downloadLink.click()
    },
    onClickFilterDropdown() {
      this.isFilterActionDropdownActive = !this.isFilterActionDropdownActive
    },
    onClickFilterOutside() {
      this.isFilterActionDropdownActive = false
    },
    directDispatchEvent() {
      document.dispatchEvent(new Event('click'))
    },
    onDownloadClick() {
      this.isDownloadActionDropdownActive = true
      this.directDispatchEvent()
    },
    async onTabActive(index: number) {
      this.activeTabIndex = index
      switch (index) {
        case TAB_INDEXES.SPREAD: {
          await this.$router.push('/trading/spread')
          return
        }
        case TAB_INDEXES.ETENDER: {
          window.location.href = `${process.env.VUE_APP_ETENDER_URL}/tender`
          return
        }
        default: {
          await this.$router.push(`/?tabIndex=${index}`)
        }
      }
    },
    onFilterItemSelect(event: {
      attribute: BaseFilterAttribute
      value: InputOption
    }) {
      this.addFilterConditionSelected({ index: this.activeTabIndex, ...event })
    },
    onFilterItemRemove(event: {
      attribute: BaseFilterAttribute
      value: InputOption
    }) {
      this.removeFilterConditionSelected({
        index: this.activeTabIndex,
        ...event,
      })
    },
    onFilterRemove(event: { attribute: BaseFilterAttribute }) {
      this.removeFilterCondition({ index: this.activeTabIndex, ...event })
    },
    onChangeFilters(event: {
      selectedValues: BaseFilterAttribute[]
      changedValue: BaseFilterAttribute
    }) {
      this.changeFilter({
        index: this.activeTabIndex,
        ...event,
      })
    },
    onClickOrderCsvDownload() {
      this.fetchPublicIndicationsCsvString()
        .then(csvString => {
          this.downloadCsvData(csvString, 'order-data')
        })
        .catch(e => {
          setNotification(
            this.$t('common.message.failFetch', {
              name: this.$t('common.label.order').toString(),
            }).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.isDownloadActionDropdownActive = false
        })
    },
    show() {
      this.indicationFormInputMode = IndicationFormInputMode.NEW
      this.selectedDeliveryTermsId = undefined
      this.fixed = undefined
      this.$vfm.open('new-indication')
    },
    async newIndicationProductFixed({
      deliveryTermsId,
      position,
    }: MyIndicationNewProps) {
      this.indicationFormInputMode = IndicationFormInputMode.PRODUCT_FIXED
      this.selectedDeliveryTermsId = deliveryTermsId
      this.selectedPosition = position
      this.$vfm.open('new-indication')
    },
    async onRecentDealCsvDownloadClick() {
      this.fetchPublicLastDealsCsvString()
        .then(csvString => {
          this.downloadCsvData(csvString, 'recent-traded-data')
        })
        .catch(e => {
          setNotification(
            this.$t('common.message.failFetch', {
              name: this.$t('common.label.order').toString(),
            }).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.isDownloadActionDropdownActive = false
        })
    },
    async setupStoreState() {
      await Promise.all([
        this.fetchAreas(),
        this.fetchHourTypes(),
        this.fetchProductTypes(),
      ])
    },
  },
})
