import {
  DeliveryYearMonth as IDeliveryYearMonth,
  Locale,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'
import { EntityAttribute } from '@/models/common/EntityAttribute'
import { moment } from '@/utils/date'

export class DeliveryYearMonth extends EntityAttribute
  implements IDeliveryYearMonth {
  deliveryYearMonthId!: string

  year!: number
  month!: number

  startDate!: string
  endDate!: string

  yearMonth(): Date {
    return new Date(this.year, this.month - 1)
  }

  shortName(locale = i18n.locale as Locale): string {
    return moment(this.yearMonth()).format(
      i18n.t('common.format.shortFormatYearMonth', locale).toString(),
    )
  }

  onOrBefore(deliveryYearMonth: DeliveryYearMonth): boolean {
    return this.yearMonth() <= deliveryYearMonth.yearMonth()
  }

  onOrAfter(deliveryYearMonth: DeliveryYearMonth): boolean {
    return this.yearMonth() >= deliveryYearMonth.yearMonth()
  }

  get id(): string {
    return this.deliveryYearMonthId
  }

  toProductFiltersOption(): InputOption {
    return {
      value: `${this.year}-${String(this.month).padStart(2, '0')}`,
      label: this.translation(),
    }
  }
}
