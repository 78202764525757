
import { defineComponent, PropType } from 'vue'

import { IndicationStatusType } from '@/api/generated'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import MyIndicationStatus from '@/components/trading/MyIndicationStatus.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MyIndication } from '@/models/trading/MyIndication'

export default defineComponent({
  name: 'MyIndicationMetadata',
  components: {
    BaseLabel,
    BasePicture,
    MyIndicationStatus,
  },
  props: {
    myIndication: {
      type: Object as PropType<MyIndication>,
      required: true,
    },
    memberProfiles: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
  },
  data() {
    return {
      inputMode: InputMode.READONLY,
    }
  },
  computed: {
    statusText(): string {
      switch (this.myIndication.status) {
        case IndicationStatusType.Active:
          return this.$t('trading.label.statusType.active').toString()
        case IndicationStatusType.Negotiating:
          return this.$t('trading.label.statusType.negotiating').toString()
        case IndicationStatusType.Done:
          return this.$t('trading.label.statusType.done').toString()
        case IndicationStatusType.Inactive:
          return this.$t('trading.label.statusType.inactive').toString()
        case IndicationStatusType.Expired:
          return this.$t('trading.label.statusType.expired').toString()
        default:
          return this.$t('trading.label.statusType.unconfirmed').toString()
      }
    },
    createdParticipantName(): string {
      return (
        this.memberProfiles.find(
          profile => profile.userId === this.myIndication.createdParticipantId,
        )?.name || this.$t('iam.label.enechainUserName').toString()
      )
    },
    createdParticipantPicture(): string {
      return (
        this.memberProfiles.find(
          profile => profile.userId === this.myIndication.createdParticipantId,
        )?.picture ||
        'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
      )
    },
    lastUpdatedParticipantName(): string {
      return (
        this.memberProfiles.find(
          profile =>
            profile.userId === this.myIndication.lastUpdatedParticipantId,
        )?.name || this.$t('iam.label.enechainUserName').toString()
      )
    },
    lastUpdatedParticipantPicture(): string {
      return (
        this.memberProfiles.find(
          profile =>
            profile.userId === this.myIndication.lastUpdatedParticipantId,
        )?.picture ||
        'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
      )
    },
    createdAt(): string {
      return this.myIndication.displayCreatedAt(
        this.$t('common.format.formatDate').toString(),
      )
    },
    updatedAt(): string {
      return this.myIndication.displayUpdatedAt(
        this.$t('common.format.formatDate').toString(),
      )
    },
  },
})
