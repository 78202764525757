import { Type } from 'class-transformer'

import { CreditSleever as ICreditSleever } from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class CreditSleever implements ICreditSleever {
  id!: string
  organizationId!: string

  @Type(() => MultiLocaleName)
  organizationName!: MultiLocaleName

  sleeveSpread!: number
  askBrokingFee!: number
  bidBrokingFee!: number
}
