import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06b14cc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-filter" }
const _hoisted_2 = { class: "product-filter__filter" }
const _hoisted_3 = {
  key: 0,
  class: "product-filter__clear-all-filter-conditions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_multi_select_dropdown = _resolveComponent("multi-select-dropdown")!
  const _component_filter_input_text = _resolveComponent("filter-input-text")!
  const _component_filter_single_select = _resolveComponent("filter-single-select")!
  const _component_filter_multi_select = _resolveComponent("filter-multi-select")!
  const _component_filter_date_select = _resolveComponent("filter-date-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_multi_select_dropdown, {
      class: "product-filter__dropdown",
      actions: _ctx.actions,
      "is-active": _ctx.isActionDropdownActive,
      "selected-values": _ctx.selectedFilters,
      "tooltip-label": _ctx.$t('trading.message.addFilter'),
      onClickButton: _ctx.onClickDropdown,
      onClickOutside: _ctx.onClickOutside,
      onChangeList: _ctx.onChangeFilters
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_icon, {
          class: "product-filter__dropdown-button-icon",
          "icon-name": "filter"
        })
      ]),
      _: 1
    }, 8, ["actions", "is-active", "selected-values", "tooltip-label", "onClickButton", "onClickOutside", "onChangeList"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "product-filter__filter-item"
        }, [
          (item.type === 'text')
            ? (_openBlock(), _createBlock(_component_filter_input_text, {
                key: 0,
                "filter-attribute": item.attribute,
                value: item.value,
                size: "small",
                onInput: ($event: any) => (_ctx.onInput($event, item.attribute)),
                onBlur: ($event: any) => (_ctx.onBlur($event.target.value, item.attribute)),
                onKeydown: ($event: any) => (_ctx.onKeydown($event, item.attribute)),
                onFilterRemove: ($event: any) => (_ctx.onFilterRemove(item.attribute)),
                onCompositionstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isComposition = true)),
                onCompositionend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isComposition = false))
              }, null, 8, ["filter-attribute", "value", "onInput", "onBlur", "onKeydown", "onFilterRemove"]))
            : _createCommentVNode("", true),
          (item.type === 'single')
            ? (_openBlock(), _createBlock(_component_filter_single_select, {
                key: 1,
                "filter-attribute": item.attribute,
                value: item.filterSingleSelect.value,
                options: item.filterSingleSelect.options,
                size: "small",
                onSelect: ($event: any) => (_ctx.onSelect($event, item.attribute)),
                onRemove: ($event: any) => (_ctx.onRemove($event, item.attribute)),
                onFilterRemove: ($event: any) => (_ctx.onFilterRemove(item.attribute))
              }, null, 8, ["filter-attribute", "value", "options", "onSelect", "onRemove", "onFilterRemove"]))
            : _createCommentVNode("", true),
          (item.type === 'multi')
            ? (_openBlock(), _createBlock(_component_filter_multi_select, {
                key: 2,
                "filter-attribute": item.attribute,
                value: item.filterMultiSelect.value,
                options: item.filterMultiSelect.options,
                size: "small",
                onSelect: ($event: any) => (_ctx.onSelect($event, item.attribute)),
                onRemove: ($event: any) => (_ctx.onRemove($event, item.attribute)),
                onFilterRemove: ($event: any) => (_ctx.onFilterRemove(item.attribute))
              }, null, 8, ["filter-attribute", "value", "options", "onSelect", "onRemove", "onFilterRemove"]))
            : _createCommentVNode("", true),
          (item.type === 'date')
            ? (_openBlock(), _createBlock(_component_filter_date_select, {
                key: 3,
                "filter-attribute": item.attribute,
                locale: _ctx.locale,
                value: item.value,
                onSelect: ($event: any) => (_ctx.onDateSelect($event, item.attribute)),
                onFilterRemove: ($event: any) => (_ctx.onFilterRemove(item.attribute))
              }, null, 8, ["filter-attribute", "locale", "value", "onSelect", "onFilterRemove"]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.filters.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "product-filter__clear-all-filter-conditions-button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickAllClear && _ctx.onClickAllClear(...args)))
            }, _toDisplayString(_ctx.$t('trading.message.clearAllFilterConditions')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}