import { RouteRecordRaw } from 'vue-router'

import { brokerRoutes } from '@/router/routes/broker'
import { emailSettingRoute } from '@/router/routes/common/emailSetting'
import { recapConfigurationRoute } from '@/router/routes/common/recapConfiguration'
import { errorRoutes } from '@/router/routes/error'
import { traderRoutes } from '@/router/routes/trader'
import Chat from '@/views/Chat.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import ServiceInformation from '@/views/ServiceInformation.vue'
import Welcome from '@/views/Welcome.vue'
import UserProfile from '@/views/iam/UserProfile.vue'

export const getRoutes = (appType?: string) => {
  /**
   * MEMO: 単一パスは直接記入、ネストされた複数パスは別ファイルで管理
   */
  const routes: RouteRecordRaw[] = [
    {
      path: '/chat',
      name: 'Chat',
      component: Chat,
    },
    {
      path: '/user-profile',
      name: 'UserProfile',
      component: UserProfile,
    },
    recapConfigurationRoute,
    emailSettingRoute,
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome,
    },
    {
      path: '/service-information',
      name: 'ServiceInformation',
      component: ServiceInformation,
    },
    ...errorRoutes,
  ]

  switch (appType) {
    case 'trader':
      routes.push(...traderRoutes)
      break
    case 'broker':
      routes.push(...brokerRoutes)
      break
    case 'storybook':
      routes.push(...traderRoutes)
      routes.push(...brokerRoutes)
      break
  }

  return routes
}
