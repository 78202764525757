type DetailText = {
  text: string[]
}

type InputText = {
  text: string
}

type Footer = {
  footer: {
    from: string
    mail: string
    phone: string
  }
}

export type BodyBg = {
  publicationDate: InputText
  organizationName: InputText
  dealDetails: {
    items: {
      executionDate: DetailText
      transactionId: DetailText
      seller: DetailText // 4 line Text
      buyer: DetailText // 4 line Text
      product: DetailText
      deliveryPeriod: DetailText
      basicUnitPrice?: DetailText
      unitPrice: DetailText
      quantity: DetailText
      totalContractElectricity: DetailText
      transactionConditions: DetailText
      paymentDueDate: DetailText
      brokerageUnitPrice: DetailText
      note: DetailText
    }
  }
  footer: Footer
}

export type DeliveryTermsAnnexTableItem = {
  items: {
    deliveryPeriod: InputText
    hourTypeName: InputText
    unitPrice: InputText
    quantity: InputText
  }
}

export type DeliveryTermsAnnex = {
  transactionId: InputText
  deliveryPatternList: {
    tableItems: DeliveryTermsAnnexTableItem[]
  }
}

export type EexForSeller = {
  publicationDate: InputText
  organizationName: InputText
  dealDetails: {
    items: {
      executionDate: DetailText
      transactionId: DetailText
      seller: DetailText
      sellersClearingMember: DetailText
      buyer: DetailText
      product: DetailText
      deliveryPeriod: DetailText
      unitPrice: DetailText
      quantity: DetailText
      totalContractElectricity: DetailText
      brokerageUnitPrice: DetailText
      note: DetailText
    }
  }
  footer: Footer
}

export type EexForBuyer = {
  publicationDate: InputText
  organizationName: InputText
  dealDetails: {
    items: {
      executionDate: DetailText
      transactionId: DetailText
      buyer: DetailText
      buyersClearingMember: DetailText
      seller: DetailText
      product: DetailText
      deliveryPeriod: DetailText
      unitPrice: DetailText
      quantity: DetailText
      totalContractElectricity: DetailText
      brokerageUnitPrice: DetailText
      note: DetailText
    }
  }
  footer: Footer
}

export type TocomForSeller = {
  publicationDate: InputText
  organizationName: InputText
  dealDetails: {
    items: {
      executionDate: DetailText
      transactionId: DetailText
      seller: DetailText
      sellersClearingMember: DetailText
      buyersClearingMember: DetailText
      product: DetailText
      deliveryPeriod: DetailText
      unitPrice: DetailText
      quantity: DetailText
      totalContractElectricity: DetailText
      brokerageUnitPrice: DetailText
      note: DetailText
    }
  }
  footer: Footer
}

export type TocomForBuyer = {
  publicationDate: InputText
  organizationName: InputText
  dealDetails: {
    items: {
      executionDate: DetailText
      transactionId: DetailText
      buyer: DetailText
      buyersClearingMember: DetailText
      sellersClearingMember: DetailText
      product: DetailText
      deliveryPeriod: DetailText
      unitPrice: DetailText
      quantity: DetailText
      totalContractElectricity: DetailText
      brokerageUnitPrice: DetailText
      note: DetailText
    }
  }
  footer: Footer
}

export type SwapBilateral = {
  publicationDate: InputText
  organizationName: InputText
  dealDetails: {
    items: {
      executionDate: DetailText
      transactionId: DetailText
      seller: DetailText // 4 line Text
      buyer: DetailText // 4 line Text
      product: DetailText
      deliveryPeriod: DetailText
      unitPrice: DetailText
      quantity: DetailText
      totalContractElectricity: DetailText
      transactionConditions: DetailText
      paymentDueDate: DetailText
      brokerageUnitPrice: DetailText
      note: DetailText
    }
  }
  footer: Footer
}

export type DataDetail =
  | BodyBg
  | DeliveryTermsAnnex
  | EexForSeller
  | EexForBuyer
  | TocomForSeller
  | TocomForBuyer
  | SwapBilateral

export enum RecapStatus {
  NONE = 'NONE',
  IN_REVIEW = 'IN_REVIEW',
  REMAND = 'REMAND',
  FIXED = 'FIXED',
  REJECT = 'REJECT',
}

type RecapMail = {
  to: string[]
  cc: string[]
  bcc: string[]
}

type RecapPage = {
  data: DataDetail
}

export type DetailItem = {
  id?: string
  name: string
  templateEndpoint: string
  status: RecapStatus
  service: string
  identifier: string
  organization: string
  organizationName: string
  orderNumber?: number
  language: string
  userMail: RecapMail
  operatorMail: RecapMail
}

export type DetailPage = {
  pages: RecapPage[]
}

export type RecapDetail = DetailItem & DetailPage

export type RecapInfo = {
  id: string
  name?: string
  details?: RecapDetail[]
}

export type CreateRequest = {
  name: string
  identifier: string
  identifier2: string
  organization: string
  service: string
  items: RecapDetail[]
}

export type RecapBulkResponse = {
  items: Record<
    string,
    {
      items: RecapDetail[]
    }
  >
}

export type RecapStateDetail = Record<string, RecapStatus>

export type RecapState = Record<string, RecapStateDetail>

export type RecapCreatedOption = {
  forceNoteProduct: boolean
  forceNoteDeliveryPeriod: boolean
  forceNoteUnitPrice: boolean
  forceNoteVolume: boolean
  forceNoteTotalContractElectricity: boolean
  excludeAnnex: boolean
}
