
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseSkeleton',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
})
