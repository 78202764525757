import { RouteLocationNormalized } from 'vue-router'

import router from '@/router'
import { root, store } from '@/store'

const ctx = root.context(store)
const userProfileCtx = ctx.modules.userProfile
const UNAUTHORIZED_PAGE_NAME = 'Unauthorized'
const UNAUTHORIZED_PAGE_PATH = '/error/401'
// TODO: Name が変更されたときに検知しづらいので、こっちじゃなくて Route.meta の方に定義を持たせたい
// meta をすべてのルート定義に持たせる変更が終わったらやる
const notRestrictedPageNames: (string | null | undefined)[] = [
  // レポート
  'LocalizedReport',
  'LocalizedReports',
  // プロフィール
  'UserProfile',
  // ようこそ
  'Welcome',
  // エラーページ
  UNAUTHORIZED_PAGE_NAME,
  // サービス案内
  'ServiceInformation',
]

export const esquareGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: CallableFunction,
) => {
  if (to.meta?.isPublic) {
    return next()
  }
  const fn = () => {
    if (
      userProfileCtx.getters.isEsquareEnabled ||
      notRestrictedPageNames.includes(to.name as string)
    ) {
      return next()
    }
    if (from.name !== UNAUTHORIZED_PAGE_NAME) {
      router.replace(UNAUTHORIZED_PAGE_PATH)
    }
  }
  fn()
}
