import { EntityAttribute } from '@/models/common/EntityAttribute'
import { Area } from '@/models/trading/Area'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { HourType } from '@/models/trading/HourType'
import { MyDeal } from '@/models/trading/MyDeal'
import { MyIndication } from '@/models/trading/MyIndication'
import { Product } from '@/models/trading/Product'
import { ProductType } from '@/models/trading/ProductType'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { BrokingFilterCondition } from '@/store/modules/trading/broking/interface'
import { MyDealFilterConditions } from '@/store/modules/trading/myDeal/interface'
import {
  MyIndicationFilterConditions,
  MyIndicationFilterAttribute,
} from '@/store/modules/trading/myIndication/interface'
import { ProductFilterCondition } from '@/store/modules/trading/publicIndication/interface'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export const baseIndicationFilter = (
  filterConditions: MyIndicationFilterConditions | BrokingFilterCondition,
) => (indication: MyIndication | BrokingIndication): boolean => {
  return (
    (filterConditions.area.length === 0 ||
      filterConditions.area.includes(
        indication.products[0].filterSearchPayload.base.area.areaId,
      ) ||
      filterConditions.area.includes(
        indication.products[0].filterSearchPayload.exchange?.area.areaId ?? '',
      )) &&
    (filterConditions.startDeliveryYearMonth.length === 0 ||
      filterConditions.startDeliveryYearMonth.includes(
        indication.products[0].filterSearchPayload.base.startDeliveryYearMonth
          .deliveryYearMonthId,
      ) ||
      filterConditions.startDeliveryYearMonth.includes(
        indication.products[0].filterSearchPayload.exchange
          ?.startDeliveryYearMonth.deliveryYearMonthId ?? '',
      )) &&
    (filterConditions.endDeliveryYearMonth.length === 0 ||
      filterConditions.endDeliveryYearMonth.includes(
        indication.products[0].filterSearchPayload.base.endDeliveryYearMonth
          .deliveryYearMonthId,
      ) ||
      filterConditions.endDeliveryYearMonth.includes(
        indication.products[0].filterSearchPayload.exchange
          ?.endDeliveryYearMonth.deliveryYearMonthId ?? '',
      )) &&
    (filterConditions.hourType.length === 0 ||
      filterConditions.hourType.includes(
        indication.products[0].filterSearchPayload.base.hourType?.hourTypeId ??
          '',
      ) ||
      filterConditions.hourType.includes(
        indication.products[0].filterSearchPayload.exchange?.hourType
          ?.hourTypeId ?? '',
      )) &&
    (filterConditions.productType.length === 0 ||
      indication.products.some(product =>
        filterConditions.productType.includes(
          product.productType.productTypeId,
        ),
      )) &&
    (filterConditions.status.length === 0 ||
      filterConditions.status.includes(indication.status))
  )
}

export const baseDealFilter = (
  filterConditions:
    | MyDealFilterConditions
    | ProductFilterCondition
    | BrokingFilterCondition,
) => (deal: MyDeal | PublicDeal | BrokingDeal) => {
  return (
    (filterConditions.area.length === 0 ||
      filterConditions.area.includes(
        deal.product.filterSearchPayload.base.area.areaId,
      ) ||
      filterConditions.area.includes(
        deal.product.filterSearchPayload.exchange?.area.areaId ?? '',
      )) &&
    (filterConditions.startDeliveryYearMonth.length === 0 ||
      filterConditions.startDeliveryYearMonth.includes(
        deal.product.filterSearchPayload.base.startDeliveryYearMonth
          .deliveryYearMonthId,
      ) ||
      filterConditions.startDeliveryYearMonth.includes(
        deal.product.filterSearchPayload.exchange?.startDeliveryYearMonth
          .deliveryYearMonthId ?? '',
      )) &&
    (filterConditions.endDeliveryYearMonth.length === 0 ||
      filterConditions.endDeliveryYearMonth.includes(
        deal.product.filterSearchPayload.base.endDeliveryYearMonth
          .deliveryYearMonthId,
      ) ||
      filterConditions.endDeliveryYearMonth.includes(
        deal.product.filterSearchPayload.exchange?.endDeliveryYearMonth
          .deliveryYearMonthId ?? '',
      )) &&
    (filterConditions.hourType.length === 0 ||
      filterConditions.hourType.includes(
        deal.product.filterSearchPayload.base.hourType?.hourTypeId ?? '',
      ) ||
      filterConditions.hourType.includes(
        deal.product.filterSearchPayload.exchange?.hourType?.hourTypeId ?? '',
      )) &&
    (filterConditions.productType.length === 0 ||
      filterConditions.productType.includes(
        deal.product.productType.productTypeId,
      ))
  )
}

// https://github.com/microsoft/TypeScript/issues/36390
// Unionを使用したときに flatMap を使用すると、コンパイルエラーになるため、any[]で一時的な型付けをしている
// 解決策としては ts の バージョンを ^4.2 に上げる
export const getAttributeObjects = ({
  attribute,
  indications,
  areas,
  hourTypes,
  productTypes,
  deals,
}: {
  attribute: BaseFilterAttribute | MyIndicationFilterAttribute
  indications: MyIndication[] | PublicIndication[] | BrokingIndication[]
  areas?: Area[]
  hourTypes?: HourType[]
  productTypes?: ProductType[]
  deals?: MyDeal[] | PublicDeal[] | BrokingDeal[]
}): EntityAttribute[] => {
  if (attribute === 'status') {
    return []
  }

  if (areas !== undefined && attribute === 'area') {
    return areas
  }
  if (hourTypes !== undefined && attribute === 'hourType') {
    return hourTypes
  }
  if (productTypes !== undefined && attribute === 'productType') {
    return productTypes
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const products = (indications as any[])
    .flatMap(
      (
        indication: MyIndication | PublicIndication | BrokingIndication,
      ): Product[] => indication.products,
    )
    .concat(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((deals || []) as any[]).map(
        (deal: MyDeal | PublicDeal | BrokingDeal): Product => deal.product,
      ),
    )
  if (attribute === 'productType') {
    return Array.from(
      products
        .reduce(
          (map: Map<string, EntityAttribute>, product: Product) =>
            map.set(product[attribute].id, product[attribute]),
          new Map<string, EntityAttribute>(),
        )
        .values(),
    )
  }

  return Array.from(
    products
      .flatMap((product: Product): (EntityAttribute | undefined)[] => [
        product.filterSearchPayload.base[attribute],
        product.filterSearchPayload.exchange
          ? product.filterSearchPayload.exchange[attribute]
          : undefined,
      ])
      .filter(
        (
          attribute: EntityAttribute | undefined,
        ): attribute is Exclude<typeof attribute, undefined> =>
          attribute !== undefined,
      )
      .reduce(
        (map: Map<string, EntityAttribute>, attribute: EntityAttribute) =>
          map.set(attribute.id, attribute),
        new Map<string, EntityAttribute>(),
      )
      .values(),
  )
}
