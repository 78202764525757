import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83592042"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "spread-preset-buttons" }
const _hoisted_2 = { class: "spread-preset-buttons__head" }
const _hoisted_3 = { class: "spread-preset-buttons__head-note" }
const _hoisted_4 = ["onMousedown", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_heading, { type: "header5" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('trading.label.spreadPreset')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('trading.label.spreadPresetNote')), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.presets, (items, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "spread-preset-buttons__row"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (set, j) => {
          return (_openBlock(), _createElementBlock("span", {
            key: j,
            class: _normalizeClass(["spread-preset-buttons__button", {
          'spread-preset-buttons__button--selected': _ctx.isSelected(i, j),
          'spread-preset-buttons__button--disabled': set.disabled,
        }]),
            onMousedown: ($event: any) => (_ctx.onMouseDown(i, j, set)),
            onClick: ($event: any) => (_ctx.onClick(set))
          }, _toDisplayString(set.text), 43, _hoisted_4))
        }), 128))
      ]))
    }), 128))
  ]))
}