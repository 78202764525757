
import { defineComponent } from 'vue'

import BrokerPage from '@/components/common/BrokerPage.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import { isBrokerApp } from '@/utils/env'

const PageTemplate = isBrokerApp ? BrokerPage : TraderPage

export default defineComponent({
  name: 'Error',
  components: {
    PageTemplate,
  },
})
