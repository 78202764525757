import { z } from 'zod'

const productFilterAttribute = [
  'area',
  'startDeliveryYearMonth',
  'endDeliveryYearMonth',
  'hourType',
  'productType',
] as const

export const ProductFilterAttributeSchema = z.array(
  z.enum(productFilterAttribute),
)

export type ProductFilterAttribute = typeof productFilterAttribute[number]

export const ProductFilterConditionsSchema = z.object({
  area: z.array(z.string().uuid()),
  startDeliveryYearMonth: z.array(z.string().regex(/^\d{4}-\d{2}$/)),
  endDeliveryYearMonth: z.array(z.string().regex(/^\d{4}-\d{2}$/)),
  hourType: z.array(z.string().uuid()),
  productType: z.array(z.string().uuid()),
})

export type ProductFilterCondition = z.infer<
  typeof ProductFilterConditionsSchema
>

export const keyOfSelectedFilters = 'selectedFiltersOfOrder'
export const keyOfSelectedFiltersCondition = 'selectedFiltersConditionOfOrder'
export const keyOfSelectedFiltersWithFuelSurcharge =
  'selectedFiltersWithFuelSurchargeOfOrder'
export const keyOfSelectedFiltersConditionWithFuelSurcharge =
  'selectedFiltersConditionWithFuelSurchargeOfOrder'
