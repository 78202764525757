import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  MyIndicationsApi,
  MyStandardIndicationSelectableInputsRequest,
  NewMyIndication,
  UpdateMyIndication,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { MyIndication } from '@/models/trading/MyIndication'
import { MyIndicationGetters } from '@/store/modules/trading/myIndication/MyIndicationGetters'
import {
  MyIndicationMutations,
  SET_DELETE_SUBMITTING,
  SET_FILTER_CONDITION,
  SET_INDICATION_ID_TO_DELETE,
  SET_MY_INDICATIONS,
  SET_IS_FETCHING_MY_INDICATIONS,
} from '@/store/modules/trading/myIndication/MyIndicationMutations'
import { MyIndicationState } from '@/store/modules/trading/myIndication/MyIndicationState'
import { MyIndicationFilterAttribute } from '@/store/modules/trading/myIndication/interface'

export class MyIndicationActions extends Actions<
  MyIndicationState,
  MyIndicationGetters,
  MyIndicationMutations,
  MyIndicationActions
> {
  async createMyIndication(indication: NewMyIndication): Promise<MyIndication> {
    return plainToClass(
      MyIndication,
      (await new MyIndicationsApi(apiConfig).newMyIndication(indication)).data,
    )
  }

  async fetchMyIndications() {
    if (this.state.myIndications.length === 0) {
      this.commit(SET_IS_FETCHING_MY_INDICATIONS, {
        isFetchingMyIndications: true,
      })
    }

    const myIndications = plainToClass(
      MyIndication,
      (await new MyIndicationsApi(apiConfig).getMyIndications()).data,
    )

    this.commit(SET_MY_INDICATIONS, {
      myIndications,
    })

    this.commit(SET_IS_FETCHING_MY_INDICATIONS, {
      isFetchingMyIndications: false,
    })
  }

  async activate(payload: { indicationId: string }) {
    await new MyIndicationsApi(apiConfig).activateMyIndication(
      payload.indicationId,
    )
  }

  async deactivate(payload: { indicationId: string }) {
    await new MyIndicationsApi(apiConfig).deactivateMyIndication(
      payload.indicationId,
    )
  }

  async delete() {
    if (this.state.indicationIdToDelete) {
      this.commit(SET_DELETE_SUBMITTING, { submitting: true })
      await new MyIndicationsApi(apiConfig).deleteMyIndication(
        this.state.indicationIdToDelete,
      )
      this.commit(SET_INDICATION_ID_TO_DELETE, { indicationId: undefined })
      this.commit(SET_DELETE_SUBMITTING, { submitting: false })
    }
  }

  async addFilterConditionSelected(payload: {
    attribute: MyIndicationFilterAttribute
    value: InputOption
  }) {
    this.commit(SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: [
        ...this.state.filterCondition[payload.attribute],
        payload.value.value,
      ],
    })
  }

  async removeFilterConditionSelected(payload: {
    attribute: MyIndicationFilterAttribute
    value: InputOption
  }) {
    this.commit(SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: this.state.filterCondition[payload.attribute].filter(
        id => id !== payload.value.value,
      ),
    })
  }

  async updateMyIndication({
    id,
    indication,
  }: {
    id: string
    indication: UpdateMyIndication
  }): Promise<MyIndication> {
    return plainToClass(
      MyIndication,
      (await new MyIndicationsApi(apiConfig).updateMyIndication(id, indication))
        .data,
    )
  }

  async fetchMyStandardIndicationSelectableInputs({
    request,
    abortController,
  }: {
    request: MyStandardIndicationSelectableInputsRequest
    abortController: AbortController
  }): Promise<BaseStandardIndicationSelectableInputs> {
    return plainToClass(
      BaseStandardIndicationSelectableInputs,
      (
        await new MyIndicationsApi(
          apiConfig,
        ).generateMyStandardIndicationSelectableInputs(request, {
          signal: abortController.signal,
        })
      ).data,
    )
  }
}
