import { Module } from 'vuex-smart-module'

import { MemberActions } from '@/store/modules/iam/member/MemberActions'
import { MemberGetters } from '@/store/modules/iam/member/MemberGetters'
import { MemberMutations } from '@/store/modules/iam/member/MemberMutations'
import { MemberState } from '@/store/modules/iam/member/MemberState'

export const member = new Module({
  state: MemberState,
  getters: MemberGetters,
  mutations: MemberMutations,
  actions: MemberActions,
})

export type MemberModule = typeof member
