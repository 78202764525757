import { Locale } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { i18n } from '@/i18n'
import { EntityAttribute } from '@/models/common/EntityAttribute'
import { User } from '@/models/iam/User'
import { Area } from '@/models/trading/Area'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { HourType } from '@/models/trading/HourType'
import { IsDailyPowerMarketOptions } from '@/models/trading/IsDailyPowerMarketOptions'
import { OpenToMarketOptions } from '@/models/trading/OpenToMarketOptions'
import { ProductType } from '@/models/trading/ProductType'
import {
  SET_FILTER_CONDITION,
  SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE,
  SET_FILTER_CONDITION_WITH_SPREAD,
  SET_SELECTED_FILTERS,
  SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE,
  SET_SELECTED_FILTERS_WITH_SPREAD,
} from '@/store/modules/trading/broking/BrokingMutations'
import { BrokingState } from '@/store/modules/trading/broking/BrokingState'
import {
  BrokingFilterAttribute,
  BrokingFilterCondition,
  keyOfSelectedFilters,
  keyOfSelectedFiltersCondition,
  keyOfSelectedFiltersConditionWithFuelSurcharge,
  keyOfSelectedFiltersConditionWithSpread,
  keyOfSelectedFiltersWithFuelSurcharge,
  keyOfSelectedFiltersWithSpread,
} from '@/store/modules/trading/broking/interface'
import {
  baseDealFilter,
  baseIndicationFilter,
  getAttributeObjects,
} from '@/store/modules/trading/utils'
import { TAB_INDEXES } from '@/store/modules/trading/utils/interface'

export const brokingIndicationFilter = (
  filterConditions: BrokingFilterCondition,
  users: User[],
) => (indication: BrokingIndication) => {
  return (
    baseIndicationFilter(filterConditions)(indication) &&
    (filterConditions.organizationName.length === 0 ||
      indication.publicOrganizationProfile.name
        .translation()
        .includes(filterConditions.organizationName[0])) &&
    (filterConditions.createdParticipantName.length === 0 ||
      users
        .find(user => user.userId === indication.createdParticipantId)
        ?.name.includes(filterConditions.createdParticipantName[0])) &&
    (filterConditions.openToMarketOfFilter.length === 0 ||
      filterConditions.openToMarketOfFilter.includes(
        indication.openToMarket.toString(),
      )) &&
    (filterConditions.isDailyPowerMarket.length === 0
      ? !indication.isDailyPowerMarket // isDailyPowerMarketのフィルターがセットされていない場合は、isDailyPowerMarketがfalseのものを表示する
      : filterConditions.isDailyPowerMarket.includes(
          indication.isDailyPowerMarket.toString(),
        ))
  )
}

export const brokingDealFilter = (filterConditions: BrokingFilterCondition) => (
  deal: BrokingDeal,
) => {
  return (
    baseDealFilter(filterConditions)(deal) &&
    (filterConditions.organizationName.length === 0 ||
      deal.buyerAccountName
        .translation()
        .includes(filterConditions.organizationName[0]) ||
      deal.sellerAccountName
        .translation()
        .includes(filterConditions.organizationName[0]))
  )
}

export const getBrokingFilter = ({
  indications,
  filterAttributes,
  filterCondition,
  locale,
  deals,
  areas,
  hourTypes,
  productTypes,
}: {
  indications: BrokingIndication[]
  filterAttributes: BrokingFilterAttribute[]
  filterCondition: BrokingFilterCondition
  locale: Locale
  deals?: BrokingDeal[]
  areas: Area[]
  hourTypes: HourType[]
  productTypes: ProductType[]
}): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    if (
      attribute !== 'status' &&
      attribute !== 'organizationName' &&
      attribute !== 'createdParticipantName' &&
      attribute !== 'openToMarketOfFilter' &&
      attribute !== 'isDailyPowerMarket'
    ) {
      const attributeObjects: EntityAttribute[] = getAttributeObjects({
        attribute,
        indications,
        areas,
        hourTypes,
        productTypes,
        deals,
      })

      attributeObjects.sort((a, b) => b.displayPriority - a.displayPriority)

      const options = attributeObjects.map(value => value.toInputOption(locale))
      const value = options.filter((option: InputOption) =>
        filterCondition[attribute].includes(option.value),
      )

      return {
        attribute,
        filterMultiSelect: {
          value: value,
          options: options,
        },
        type: 'multi',
      }
    }
    if (attribute === 'openToMarketOfFilter') {
      const options = new OpenToMarketOptions().options
      const value = options.filter((option: InputOption) =>
        filterCondition[attribute].includes(option.value),
      )
      return {
        attribute,
        type: 'multi',
        filterMultiSelect: {
          value,
          options,
        },
      }
    }
    if (
      attribute === 'organizationName' ||
      attribute === 'createdParticipantName'
    ) {
      return {
        attribute,
        type: 'text',
        value:
          filterCondition[attribute].length > 0
            ? filterCondition[attribute][0]
            : '',
      }
    }
    if (attribute === 'isDailyPowerMarket') {
      const options = new IsDailyPowerMarketOptions().options
      const value = options.filter((option: InputOption) =>
        filterCondition[attribute].includes(option.value),
      )
      return {
        attribute,
        type: 'multi',
        filterMultiSelect: {
          value,
          options,
        },
      }
    }

    const options: InputOption[] = []
    for (const indication of indications) {
      const value = indication.status

      if (!options.some(el => el.value === value)) {
        options.push({
          value: value,
          label: i18n.t(
            'trading.label.brokingIndicationStatusType.' + value,
          ) as string,
        })
      }
    }
    const value = options.filter((option: InputOption) =>
      filterCondition[attribute].includes(option.value),
    )
    return {
      attribute: 'status',
      filterMultiSelect: {
        value: value,
        options: options,
      },
      type: 'multi',
    }
  })
}

export const findFilterState = (
  index: number,
  state: BrokingState,
): {
  state: {
    selectedFilters: () => BrokingFilterAttribute[]
    filterCondition: () => BrokingFilterCondition
    filterAttributes: () => BrokingFilterAttribute[]
  }
  keyOfSelectedFilters: string
  keyOfSelectedFiltersCondition: string
  SET_SELECTED_FILTERS:
    | typeof SET_SELECTED_FILTERS
    | typeof SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE
    | typeof SET_SELECTED_FILTERS_WITH_SPREAD
  SET_FILTER_CONDITION:
    | typeof SET_FILTER_CONDITION
    | typeof SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE
    | typeof SET_FILTER_CONDITION_WITH_SPREAD
} => {
  switch (index) {
    case TAB_INDEXES.NO_FUEL_SURCHARGE:
      return {
        state: {
          selectedFilters: () => state.selectedFilters,
          filterCondition: () => state.filterCondition,
          filterAttributes: () => state.filterAttributes,
        },
        keyOfSelectedFilters: keyOfSelectedFilters,
        keyOfSelectedFiltersCondition: keyOfSelectedFiltersCondition,
        SET_SELECTED_FILTERS: SET_SELECTED_FILTERS,
        SET_FILTER_CONDITION: SET_FILTER_CONDITION,
      }
    case TAB_INDEXES.WITH_FUEL_SURCHARGE:
      return {
        state: {
          selectedFilters: () => state.selectedFiltersWithFuelSurcharge,
          filterCondition: () => state.filterConditionWithFuelSurcharge,
          filterAttributes: () => state.filterAttributesWithFuelSurcharge,
        },
        keyOfSelectedFilters: keyOfSelectedFiltersWithFuelSurcharge,
        keyOfSelectedFiltersCondition: keyOfSelectedFiltersConditionWithFuelSurcharge,
        SET_SELECTED_FILTERS: SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE,
        SET_FILTER_CONDITION: SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE,
      }
    case TAB_INDEXES.SPREAD:
      return {
        state: {
          selectedFilters: () => state.selectedFiltersWithSpread,
          filterCondition: () => state.filterConditionWithSpread,
          filterAttributes: () => state.filterAttributesWithSpread,
        },
        keyOfSelectedFilters: keyOfSelectedFiltersWithSpread,
        keyOfSelectedFiltersCondition: keyOfSelectedFiltersConditionWithSpread,
        SET_SELECTED_FILTERS: SET_SELECTED_FILTERS_WITH_SPREAD,
        SET_FILTER_CONDITION: SET_FILTER_CONDITION_WITH_SPREAD,
      }
    default:
      throw new Error('Selected tab does not match tab data')
  }
}
