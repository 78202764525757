
import debounce from 'lodash/debounce'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { EEXClearingAccount } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LoadingType } from '@/components/common/constants/LoadingType'
import { UiStack } from '@/components/common/constants/UiStack'
import { EexClearingAccount } from '@/models/iam/EexClearingAccount'
import { EEXPostingModule } from '@/store/modules/trading/eexPosting'
import { isAbortError } from '@/utils/error/AbortError'

const { mapActions: eexPostingActions } = createNamespacedHelpers(
  'eexPosting',
) as EEXPostingModule

export default defineComponent({
  name: 'AccountSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    BaseInputText,
    BasePicture,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      default: undefined,
    },
    selectedAccount: {
      type: Object as PropType<EexClearingAccount>,
      default: undefined,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    value: string
    accounts: EexClearingAccount[]
    uiStack: UiStack
    currentAbortController: AbortController
  } {
    return {
      value: '',
      accounts: [],
      uiStack: UiStack.Loading,
      currentAbortController: new AbortController(),
    }
  },
  computed: {
    alertTypeInfo: () => AlertType.Info,
    alertTypeError: () => AlertType.Error,
    grayscale: () => LoadingType.GRAYSCALE,
  },
  async created() {
    await this.search()
  },
  methods: {
    buildDisplayAccountName: (account: EEXClearingAccount): string => {
      const referenceStr = `(${account.reference})`
      const extraStr = account.eexClearingAccountCode.includes(referenceStr)
        ? ''
        : referenceStr
      return `${account.clearingFirm}(${account.eexClearingAccountCode}${extraStr})`
    },
    searchAccounts: eexPostingActions(['searchEEXClearingAccounts'])
      .searchEEXClearingAccounts as (payload: {
      organizationId: string
      eexClearingAccountCode: string
      abortController?: AbortController
    }) => Promise<EexClearingAccount[]>,
    onSelect(account: EexClearingAccount) {
      this.$emit('select', account)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onInput(value: string) {
      this.value = value
      this.uiStack = UiStack.Loading
      this.debouncedSearch()
    },
    async search() {
      if (this.organizationId === undefined) {
        this.uiStack = UiStack.Blank
        this.accounts = []
        return
      }
      this.uiStack = UiStack.Loading
      this.currentAbortController.abort()
      this.currentAbortController = new AbortController()
      await this.searchAccounts({
        organizationId: this.organizationId,
        eexClearingAccountCode: this.value,
        abortController: this.currentAbortController,
      })
        .then(accounts => {
          this.accounts = accounts
          this.uiStack = accounts.length > 0 ? UiStack.Ideal : UiStack.Blank
        })
        .catch(e => {
          if (isAbortError(e)) {
            return
          }
          this.uiStack = UiStack.Error
        })
    },
    debouncedSearch: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.search()
    }, 750),
  },
})
