import { BrokerageFeePaymentTiming } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

export class BrokerageFeePaymentTimingOptions {
  get options(): InputOption[] {
    return [
      {
        value: BrokerageFeePaymentTiming.ContractMonth,
        label: i18n.t('iam.label.contractMonth'),
      },
      {
        value: BrokerageFeePaymentTiming.DeliveryMonth,
        label: i18n.t('iam.label.deliveryMonth'),
      },
    ]
  }
}
