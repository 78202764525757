import { Client, Conversation } from '@twilio/conversations'

import { UserProfile } from '@/api/generated'
import { DMChannel } from '@/models/chat/DMChannel'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'

export const isOrganizationChannel = (
  conversationDescriptor: Conversation,
): boolean => {
  return conversationDescriptor.attributes.channelType === 'Organization'
}

export const getMemberIds = (
  conversationDescriptor: Conversation,
): string[] => {
  return conversationDescriptor.attributes.memberIds
}

export const getMemberIdsExceptAnId = (
  conversationDescriptor: Conversation,
  id: string,
): string[] => {
  return getMemberIds(conversationDescriptor).filter(member => member !== id)
}

export const getChannel = async (
  channelId: string,
  client: Client,
  user: UserProfile,
): Promise<OrganizationChannel | DMChannel> => {
  const conversationInstance = await client.getConversationBySid(channelId)
  if (isOrganizationChannel(conversationInstance)) {
    const organizationIdsExceptAnId = getMemberIdsExceptAnId(
      conversationInstance,
      user.organizationId,
    )
    // 自ユーザーと同じorganizationIdを持つユーザーしか存在しないチャンネルの場合は、IDを除外せずに取得する
    const organizationIds =
      organizationIdsExceptAnId.length > 0
        ? organizationIdsExceptAnId
        : getMemberIds(conversationInstance)
    return new OrganizationChannel({
      channelId: conversationInstance.sid,
      conversationInstance,
      profileId: organizationIds[0],
    })
  }
  const userIds = getMemberIdsExceptAnId(conversationInstance, user.userId)
  const userInstance = await client.getUser(userIds[0])

  return new DMChannel({
    channelId: conversationInstance.sid,
    conversationInstance,
    userInstance,
    profileId: userIds[0],
  })
}

const channelCache: {
  [index: string]: OrganizationChannel | DMChannel
} = {}

export const getChannelWithCache = async (
  channelId: string,
  client: Client,
  user: UserProfile,
): Promise<OrganizationChannel | DMChannel> => {
  if (channelCache[channelId]) {
    return channelCache[channelId]
  }
  const channel = await getChannel(channelId, client, user)
  channelCache[channelId] = channel

  return channel
}
