import { AxiosResponse } from 'axios'
import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { ESquareAppSetting, MembersApi, UsersApi } from '@/api/generated'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MemberGetters } from '@/store/modules/iam/member/MemberGetters'
import {
  MemberMutations,
  SET_MEMBER_PROFILES,
  SET_MAIL_ADDRESS,
  SET_ROLE_IN_ORGANIZATION,
} from '@/store/modules/iam/member/MemberMutations'
import { MemberState } from '@/store/modules/iam/member/MemberState'
import {
  arrayBuffer2JpegDataUrl,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class MemberActions extends Actions<
  MemberState,
  MemberGetters,
  MemberMutations,
  MemberActions
> {
  async loadMemberProfiles(organizationId: string): Promise<void> {
    const memberProfiles = await (this.dispatch(
      'fetchMemberProfiles',
      organizationId,
    ) as ReturnType<typeof this.fetchMemberProfiles>)

    this.commit(SET_MEMBER_PROFILES, {
      memberProfiles,
    })
  }

  async fetchMemberProfiles(organizationId: string): Promise<MemberProfile[]> {
    const memberProfiles = plainToClass(
      MemberProfile,
      (await new MembersApi(apiConfig).getMembersOfOrganization(organizationId))
        .data,
    )

    const memberProfilePromises = memberProfiles.map(profile =>
      isRequiredAuthenticatedRequest(profile.picture)
        ? new UsersApi(apiConfig).getPictureOfUser(profile.userId, {
            responseType: 'arraybuffer',
          })
        : Promise.resolve({ data: '' } as AxiosResponse),
    )
    const memberPictures = (await Promise.all(memberProfilePromises)).map(
      response => response.data,
    )

    for (const [index, memberProfile] of memberProfiles.entries()) {
      if (isRequiredAuthenticatedRequest(memberProfile.picture)) {
        memberProfiles[index].picture = arrayBuffer2JpegDataUrl(
          (memberPictures[index] as unknown) as ArrayBuffer,
        )
      }
    }

    return memberProfiles
  }

  async fetchMemberProfile(payload: {
    organizationId: string
    userId: string
  }): Promise<MemberProfile> {
    const memberProfile = plainToClass(
      MemberProfile,
      (
        await new MembersApi(apiConfig).getMember(
          payload.organizationId,
          payload.userId,
        )
      ).data,
    )
    if (isRequiredAuthenticatedRequest(memberProfile.picture)) {
      memberProfile.picture = arrayBuffer2JpegDataUrl(
        ((
          await new UsersApi(apiConfig).getPictureOfUser(memberProfile.userId, {
            responseType: 'arraybuffer',
          })
        ).data as unknown) as ArrayBuffer,
      )
    }
    return memberProfile
  }

  async updateAppSetting(payload: {
    organizationId: string
    userId: string
    appSetting: ESquareAppSetting
  }): Promise<void> {
    await new MembersApi(apiConfig).updateMemberAppSetting(
      payload.organizationId,
      payload.userId,
      payload.appSetting,
    )
  }

  reset() {
    this.commit(SET_MAIL_ADDRESS, { mailAddress: undefined })
    this.commit(SET_ROLE_IN_ORGANIZATION, {
      roleInOrganization: undefined,
    })
  }

  async fetchMembers(payload: {
    organizationId: string
  }): Promise<MemberProfile[]> {
    return plainToClass(
      MemberProfile,
      (
        await new MembersApi(apiConfig).getMembersOfOrganization(
          payload.organizationId,
        )
      ).data,
    )
  }

  async fetchEclearMembers(payload: {
    organizationId: string
  }): Promise<MemberProfile[]> {
    const members: MemberProfile[] = await (this.dispatch(
      'fetchMembers',
      payload,
    ) as ReturnType<typeof this.fetchMembers>)
    return members.filter(member => member.isEclearTrader)
  }

  async fetchNonEclearMembers(payload: {
    organizationId: string
  }): Promise<MemberProfile[]> {
    const members: MemberProfile[] = await (this.dispatch(
      'fetchMembers',
      payload,
    ) as ReturnType<typeof this.fetchMembers>)
    return members.filter(member => !member.isEclearTrader)
  }
}
