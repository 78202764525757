
import { defineComponent, PropType } from 'vue'

import { IndicationStatusType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import {
  BrokingCommandType,
  BrokingCommandTypes,
} from '@/components/trading/constants/BrokingCommandType'

export default defineComponent({
  name: 'BrokingOrderBookCommand',
  components: {
    ActionDropdown,
    BaseIcon,
    BaseTooltip,
  },
  props: {
    commandType: {
      type: String,
      required: true,
      validator: (value: BrokingCommandType) =>
        BrokingCommandTypes.includes(value),
    },
    dropdownId: {
      type: String,
      required: true,
    },
    indicationId: {
      type: String,
      default: '',
    },
    dealId: {
      type: String,
      default: '',
    },
    openToMarket: {
      type: Boolean,
      default: true,
    },
    isVisibleToTraders: {
      type: Boolean,
      default: true,
    },
    isEclearIndication: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as PropType<IndicationStatusType>,
      default: undefined,
    },
  },
  emits: [
    'open-to-market-change',
    'visible-to-traders-change',
    'new-message',
    'copy-clipboard',
    'copy-indication',
    'update-indication',
  ],
  data(): {
    isActionDropdownActive: boolean
    emitting: {
      openToMarket: boolean
      visibleToTraders: boolean
    }
  } {
    return {
      isActionDropdownActive: false,
      emitting: {
        openToMarket: false,
        visibleToTraders: false,
      },
    }
  },
  computed: {
    actions(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('trading.message.updateIndication').toString(),
          eventName: 'update-indication',
        },
        {
          label: this.$t('trading.message.broadcastIndication').toString(),
          eventName: 'broadcast',
        },
        {
          label: this.$t('trading.message.copyClipboard').toString(),
          eventName: 'copy-clipboard',
        },
        {
          label: this.$t('trading.message.copyIndication').toString(),
          eventName: 'copy-indication',
        },
        {
          label: this.$t('trading.message.openDetail').toString(),
          eventName: 'to-detail',
        },
      ]
    },
    isOpenToMarketSubmitting(): boolean {
      return this.emitting.openToMarket
    },
    isVisibleToTradersSubmitting(): boolean {
      return this.emitting.visibleToTraders
    },
    isNegotiating(): boolean {
      return this.status === IndicationStatusType.Negotiating
    },
  },
  watch: {
    submitting() {
      if (!this.submitting) {
        this.emitting.openToMarket = false
        this.emitting.visibleToTraders = false
      }
    },
  },
  methods: {
    onOpenToMarketChange() {
      if (this.emitting.openToMarket) {
        return
      }
      this.$emit('open-to-market-change', {
        indicationId: this.indicationId,
        openToMarket: !this.openToMarket,
      })
      this.emitting.openToMarket = true
    },
    onVisibleToTradersChange() {
      if (this.emitting.visibleToTraders) {
        return
      }
      this.$emit('visible-to-traders-change', {
        indicationId: this.indicationId,
        isVisibleToTraders: !this.isVisibleToTraders,
      })
      this.emitting.visibleToTraders = true
    },
    onBroadcastClick() {
      this.$emit('new-message')
      this.isActionDropdownActive = false
    },
    onCopyClipboard() {
      this.$emit('copy-clipboard')
      this.isActionDropdownActive = false
    },
    onCopyIndication() {
      this.$emit('copy-indication')
      this.isActionDropdownActive = false
    },
    onOpenDetailClick() {
      const path =
        this.commandType === 'indication'
          ? `/indications/${this.indicationId}`
          : `/deals/${this.dealId}`
      const url = this.$router.resolve(path).href
      window.open(url, '_blank')
    },
    onUpdatingIndicationClick() {
      this.$emit('update-indication', this.indicationId)
      this.isActionDropdownActive = false
    },
  },
})
