
import { defineComponent, PropType } from 'vue'

import { MemberProfile } from '@/api/generated'
import BaseDataset from '@/components/common/BaseDataset.vue'

export default defineComponent({
  name: 'MemberInformation',
  components: { BaseDataset },
  props: {
    member: {
      type: Object as PropType<MemberProfile>,
      required: true,
    },
  },
  computed: {
    displayName(): string {
      return this.member.name || this.$t('common.label.blank').toString()
    },
    displayPhoneNumber(): string {
      return this.member.phoneNumber || this.$t('common.label.blank').toString()
    },
    displayMobilePhoneNumber(): string {
      return (
        this.member.mobilePhoneNumber ||
        this.$t('common.label.blank').toString()
      )
    },
  },
})
