
import { defineComponent } from 'vue'

import BaseDropdown from '@/components/common/BaseDropdown.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import { isBrokerApp } from '@/utils/env'

type NavItem = {
  name: string
  description: string
  logo: string
  href?: string
  checked: boolean
}

export default defineComponent({
  name: 'AppSwitcher',
  components: {
    BaseDropdown,
    BaseIcon,
  },
  data() {
    return {
      isDropdownNavigationActive: false,
    }
  },
  computed: {
    // eCloud, eTender, eCompass, eScan, JCEXの順番で表示
    items(): NavItem[] {
      if (isBrokerApp) {
        return [
          {
            name: this.$t('product.name.eCloud').toString(),
            description: this.$t('product.description.eCloud').toString(),
            logo: require('@/assets/images/product/ecloud.svg'),
            href: `${process.env.VUE_APP_ECLOUD_URL}`,
            checked: false,
          },
          {
            name: this.$t('product.name.eTender').toString(),
            description: this.$t('product.description.eTender').toString(),
            logo: require('@/assets/images/product/esquare.svg'),
            href: `${process.env.VUE_APP_ETENDER_URL}/operator/tender`,
            checked: false,
          },
          {
            name: this.$t('product.name.eCompass').toString(),
            description: this.$t('product.description.eCompass').toString(),
            logo: require('@/assets/images/product/ecompass.svg'),
            href: `${process.env.VUE_APP_ECOMPASS_URL}`,
            checked: false,
          },
          {
            name: this.$t('product.name.eScan').toString(),
            description: this.$t('product.description.eScan').toString(),
            logo: require('@/assets/images/product/escan.svg'),
            href: `${process.env.VUE_APP_ESCAN_URL}`,
            checked: false,
          },
          {
            name: this.$t('product.name.JCEX').toString(),
            description: this.$t('product.description.JCEX').toString(),
            logo: require('@/assets/images/product/jcex.svg'),
            href: `${process.env.VUE_APP_JCEX_URL}`,
            checked: false,
          },
        ]
      }

      return [
        {
          name: this.$t('product.name.eCloud').toString(),
          description: this.$t('product.description.eCloud').toString(),
          logo: require('@/assets/images/product/ecloud.svg'),
          href: `${process.env.VUE_APP_ECLOUD_URL}`,
          checked: false,
        },
        {
          name: this.$t('product.name.eTender').toString(),
          description: this.$t('product.description.eTender').toString(),
          logo: require('@/assets/images/product/esquare.svg'),
          href: `${process.env.VUE_APP_ETENDER_URL}/tender`,
          checked: false,
        },
        {
          name: this.$t('product.name.eCompass').toString(),
          description: this.$t('product.description.eCompass').toString(),
          logo: require('@/assets/images/product/ecompass.svg'),
          href: `${process.env.VUE_APP_ECOMPASS_URL}`,
          checked: false,
        },
        {
          name: this.$t('product.name.eScan').toString(),
          description: this.$t('product.description.eScan').toString(),
          logo: require('@/assets/images/product/escan.svg'),
          href: `${process.env.VUE_APP_ESCAN_URL}`,
          checked: false,
        },
        {
          name: this.$t('product.name.JCEX').toString(),
          description: this.$t('product.description.JCEX').toString(),
          logo: require('@/assets/images/product/jcex.svg'),
          href: `${process.env.VUE_APP_JCEX_URL}`,
          checked: false,
        },
      ]
    },
  },
})
