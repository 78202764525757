
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import OldUnitPriceAndVolume from '@/components/trading/OldUnitPriceAndVolume.vue'
import OrderBookUnitPriceAndVolume from '@/components/trading/OrderBookBestUnitPriceAndVolume.vue'
import UnitPriceAndVolumeForm from '@/components/trading/UnitPriceAndVolumeForm.vue'
import {
  OrderBookRecordType,
  OrderBookRecordTypes,
} from '@/components/trading/constants/OrderBookRecordType'
import { UnitPriceAndVolumeFormProps } from '@/components/trading/interface/UnitPriceAndVolumeFormProps'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'

export default defineComponent({
  name: 'OrderBookPrice',
  components: {
    OldUnitPriceAndVolume,
    OrderBookUnitPriceAndVolume,
    UnitPriceAndVolumeForm,
  },
  props: {
    recordType: {
      type: String,
      required: true,
      validator: (value: OrderBookRecordType) =>
        OrderBookRecordTypes.includes(value),
    },
    price: {
      type: Object as PropType<Price>,
      required: true,
    },
    unitPrice: { type: Number, required: true },
    volume: {
      type: Number,
      default: undefined,
    },
    createdAt: {
      type: String,
      default: undefined,
    },
    fuelSurchargeType: {
      type: Object as PropType<FuelSurchargeType>,
      default: undefined,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    maskVolume: {
      type: Boolean,
      default: false,
    },
    openToMarket: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['unit-price-and-volume-click', 'change', 'focus'],
  computed: {
    formValue(): UnitPriceAndVolumeFormProps {
      return {
        unitPrice: this.unitPrice,
        volume: this.volume || null,
      }
    },
    isLeftShown(): boolean {
      return (
        (this.positionReversed && this.recordType === 'ask-indication') ||
        (!this.positionReversed && this.recordType === 'bid-indication')
      )
    },
    isRightShown(): boolean {
      return (
        (!this.positionReversed && this.recordType === 'ask-indication') ||
        (this.positionReversed && this.recordType === 'bid-indication')
      )
    },
    leftPosition(): string {
      return this.recordType === 'bid-indication'
        ? 'bid'
        : this.recordType === 'ask-indication'
        ? 'ask'
        : ''
    },
    rightPosition(): string {
      return this.recordType === 'bid-indication'
        ? 'bid'
        : this.recordType === 'ask-indication'
        ? 'ask'
        : ''
    },
  },
})
