import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1efe4157"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "angle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_icon, {
      class: _normalizeClass(["angle__icon", { 'angle__icon--opened': _ctx.isOpened }]),
      "icon-name": "angle-right",
      onClick: _withModifiers(_ctx.onClick, ["stop"])
    }, null, 8, ["class", "onClick"])
  ]))
}