import { InputOption } from '@/components/common/interface/InputOption'
import { MultiLocaleMessageBody } from '@/models/chat/MultiLocaleMessageBody'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'

export class ChatBroadcastState {
  organizationChannels: OrganizationChannel[] = []
  recipientSearchInput = ''
  recipientSelectedLanguage: undefined | InputOption = undefined
  locked = false
  selectedOrganizationChannelIds: string[] = []
  message: string | MultiLocaleMessageBody = ''
}
