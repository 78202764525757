import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createElementBlock("div", {
    class: "message-header",
    style: _normalizeStyle(_ctx.styleVars)
  }, [
    _createVNode(_component_heading, {
      type: "header5",
      class: "message-header__name"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_sentences, {
      type: "text-small",
      class: "message-header__date-time"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.formatDateTime(_ctx.dateTime)), 1)
      ]),
      _: 1
    })
  ], 4))
}