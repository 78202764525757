
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { RoleType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import MemberForm from '@/components/iam/MemberForm.vue'
import { MemberFormProps } from '@/components/iam/interface/MemberFormProps'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { MemberModule } from '@/store/modules/iam/member'
import { OrganizationsModule } from '@/store/modules/iam/organizations'

const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

const { mapActions: organizationMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

export default defineComponent({
  name: 'EcloudMember',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    ConfirmationDialog,
    MemberForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    formValue: MemberFormProps
    isEclearContracted: boolean
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      formValue: {
        name: undefined,
        mail: undefined,
        roleInOrganization: RoleType.Member,
        phoneNumber: undefined,
        mobilePhoneNumber: undefined,
      },
      isEclearContracted: false,
    }
  },
  async created() {
    await this.fetchMemberProfile({
      organizationId: this.$attrs.organizationId,
      userId: this.$attrs.id,
    })
      .then(async (memberProfile: MemberProfile) => {
        this.formValue.name = memberProfile.name
        this.formValue.mail = memberProfile.mail
        this.formValue.roleInOrganization = memberProfile.roleInOrganization
        this.formValue.phoneNumber = memberProfile.phoneNumber
        this.formValue.mobilePhoneNumber = memberProfile.mobilePhoneNumber

        // todo: backend の res修正必要
        // this.isEclearContracted = !!memberProfile.services?.eclear.appSetting
        //   ?.enabled
        const organization = await this.fetchOrganization(
          this.$attrs.organizationId,
        )
        this.isEclearContracted = organization.isEClearContracted

        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    fetchMemberProfile: memberMapActions(['fetchMemberProfile'])
      .fetchMemberProfile as (payload: {
      organizationId: string
      userId: string
    }) => Promise<MemberProfile>,
    fetchOrganization: organizationMapActions(['fetchOrganization'])
      .fetchOrganization as (organizationId: string) => Promise<Organization>,
    backListPage() {
      this.$router.push('./')
    },
    onBackClick() {
      this.backListPage()
    },
  },
})
