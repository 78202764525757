import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-344af0b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-member" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channel_picture = _resolveComponent("channel-picture")!
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_channel_picture, {
      class: "channel-member__picture",
      picture: _ctx.picture,
      status: _ctx.status
    }, null, 8, ["picture", "status"]),
    _createVNode(_component_sentences, {
      type: "text-medium",
      class: "channel-member__name"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", { title: _ctx.name }, _toDisplayString(_ctx.name), 9, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}