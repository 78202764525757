import { Module } from 'vuex-smart-module'

import { RecapActions } from '@/store/modules/trading/recap/RecapActions'
import { RecapGetters } from '@/store/modules/trading/recap/RecapGetters'
import { RecapMutations } from '@/store/modules/trading/recap/RecapMutations'
import { RecapState } from '@/store/modules/trading/recap/RecapState'

export const recap = new Module({
  state: RecapState,
  getters: RecapGetters,
  mutations: RecapMutations,
  actions: RecapActions,
})

export type RecapModule = typeof recap
