
import cloneDeep from 'lodash/cloneDeep'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { NewEEXPosting, NewEEXPostingDetail } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LoadingType } from '@/components/common/constants/LoadingType'
import { UiStack } from '@/components/common/constants/UiStack'
import EexPostingForm from '@/components/trading/EEXPostingForm.vue'
import { EEXPostingFormInputMode } from '@/components/trading/constants/EEXPostingFormInputMode'
import { EEXPostingDetailProps } from '@/components/trading/interface/EEXPostingDetailProps'
import { EEXPostingProps } from '@/components/trading/interface/EEXPostingProps'
import { EexClearingAccount } from '@/models/iam/EexClearingAccount'
import { Organization } from '@/models/iam/Organization'
import { EEXPosting } from '@/models/trading/EEXPosting'
import { EEXPostingDetail } from '@/models/trading/EEXPostingDetail'
import { EexTrader } from '@/models/trading/EexTrader'
import { EexTradingFirm } from '@/models/trading/EexTradingFirm'
import { ClearingInformationModule } from '@/store/modules/iam/clearingInformation'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { EEXPostingModule } from '@/store/modules/trading/eexPosting'
import { setNotification } from '@/utils/utils'

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const { mapActions: eexPostingMapActions } = createNamespacedHelpers(
  'eexPosting',
) as EEXPostingModule

const { mapActions: organizationsMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

const { mapActions: clearingInformationMapActions } = createNamespacedHelpers(
  'clearingInformation',
) as ClearingInformationModule

const emptyFormValue: EEXPostingProps = {
  productCode: '',
  instrument: '',
  startDeliveryDate: undefined,
  endDeliveryDate: undefined,
  quantity: '',
  price: '',
  askEEXPostingDetail: {
    organization: undefined,
    eexTradingFirm: undefined,
    eexTrader: undefined,
    eexClearingAccount: undefined,
  },
  bidEEXPostingDetail: {
    organization: undefined,
    eexTradingFirm: undefined,
    eexTrader: undefined,
    eexClearingAccount: undefined,
  },
}

export default defineComponent({
  name: 'EEXPosting',
  components: {
    BaseAlert,
    BaseButton,
    BaseLabel,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    EexPostingForm,
    UiStackSelector,
    ValidationForm,
  },
  props: {
    eexPosting: {
      type: Object as PropType<EEXPosting>,
      required: false,
    },
    initialMode: {
      type: Number,
      required: true,
      validator: (value: EEXPostingFormInputMode) => {
        return [
          EEXPostingFormInputMode.NEW,
          EEXPostingFormInputMode.CONFIRM,
          EEXPostingFormInputMode.REPOST,
        ].includes(value)
      },
    },
  },
  emits: ['cancel'],
  data(): {
    formValue: EEXPostingProps
    mode: EEXPostingFormInputMode
    uiStack: UiStack
    submitting: boolean
  } {
    return {
      formValue: cloneDeep(emptyFormValue),
      mode: EEXPostingFormInputMode.NEW,
      uiStack: UiStack.Loading,
      submitting: false,
    }
  },
  computed: {
    modalName: () => 'eex-posting',
    errorType: () => AlertType.Error,
    grayscale: () => LoadingType.GRAYSCALE,
    description(): string | undefined {
      switch (this.mode) {
        case EEXPostingFormInputMode.NEW:
          return undefined
        case EEXPostingFormInputMode.CONFIRM:
          return this.$t('trading.label.eexPostingDescriptionConfirm')
        case EEXPostingFormInputMode.REPOST:
          return this.$t('trading.label.eexPostingDescriptionRepost')
      }
    },
    cancelOrBackButtonLabel(): string {
      switch (this.mode) {
        case EEXPostingFormInputMode.NEW:
        case EEXPostingFormInputMode.REPOST:
          return this.$t('common.label.cancel')
        case EEXPostingFormInputMode.CONFIRM:
          return this.$t('common.label.back')
      }
    },
    confirmButtonLabel(): string {
      switch (this.mode) {
        case EEXPostingFormInputMode.NEW:
          return this.$t('common.label.toConfirm')
        case EEXPostingFormInputMode.CONFIRM:
          return this.$t('trading.label.post')
        case EEXPostingFormInputMode.REPOST:
          return this.$t('trading.label.repost')
      }
    },
    formClassName(): string {
      const baseName = 'eex-posting__form'
      if (this.mode === EEXPostingFormInputMode.NEW) {
        return `${baseName}--input`
      }
      return `${baseName}--readonly`
    },
    ...userProfileMapGetters(['userProfile']),
  },
  async created() {
    const buildDetailFormValue = async (
      detail: EEXPostingDetail,
    ): Promise<EEXPostingDetailProps> => {
      const { organizationId, eexClearingAccountCode, eexTraderCode } = detail
      return {
        organization: await this.fetchOrganization(organizationId),
        eexTradingFirm: await this.fetchEexTradingFirmOfOrganization({
          organizationId,
        }),
        eexClearingAccount: await this.getEEXClearingAccountOfCode({
          organizationId,
          eexClearingAccountCode,
        }),
        eexTrader: await this.getEEXTraderOfCode({
          organizationId,
          eexTraderCode,
        }),
      }
    }
    const setInitialFormValue = async () => {
      this.formValue = cloneDeep(emptyFormValue)
      if (this.eexPosting) {
        this.formValue = {
          ...this.eexPosting,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          askEEXPostingDetail: await buildDetailFormValue(
            this.eexPosting.askEEXPostingDetail,
          ),
          bidEEXPostingDetail: await buildDetailFormValue(
            this.eexPosting.bidEEXPostingDetail,
          ),
          instrument:
            this.mode === EEXPostingFormInputMode.REPOST
              ? ''
              : this.eexPosting.instrument,
        }
      }
    }
    this.mode = this.initialMode
    await setInitialFormValue()
      .then(() => {
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...eexPostingMapActions(['createEEXPosting']),
    getEEXTraderOfCode: eexPostingMapActions(['getEEXTraderOfCode'])
      .getEEXTraderOfCode as (payload: {
      organizationId: string
      eexTraderCode: string
      abortController?: AbortController
    }) => Promise<EexTrader | undefined>,
    getEEXClearingAccountOfCode: eexPostingMapActions([
      'getEEXClearingAccountOfCode',
    ]).getEEXClearingAccountOfCode as (payload: {
      organizationId: string
      eexClearingAccountCode: string
      abortController?: AbortController
    }) => Promise<EexClearingAccount | undefined>,
    fetchOrganization: organizationsMapActions(['fetchOrganization'])
      .fetchOrganization as (id: string) => Promise<Organization>,
    fetchEexTradingFirmOfOrganization: clearingInformationMapActions([
      'fetchEexTradingFirmOfOrganization',
    ]).fetchEexTradingFirmOfOrganization as (payload: {
      organizationId: string
    }) => Promise<EexTradingFirm | undefined>,
    async getEexTradingFirmResolvedOrganization(
      beforeDetail: EEXPostingDetailProps,
      afterDetail: EEXPostingDetailProps,
    ): Promise<EexTradingFirm | undefined> {
      if (
        beforeDetail.organization?.organizationId ===
        afterDetail.organization?.organizationId
      ) {
        return beforeDetail.eexTradingFirm
      }
      if (afterDetail.organization?.organizationId === undefined) {
        return undefined
      }
      return await this.fetchEexTradingFirmOfOrganization({
        organizationId: afterDetail.organization?.organizationId,
      })
    },
    async onFormInput(formValue: EEXPostingProps): Promise<void> {
      const formValueLodedEexTradingFirm = {
        ...formValue,
        askEEXPostingDetail: {
          ...formValue.askEEXPostingDetail,
          eexTradingFirm: await this.getEexTradingFirmResolvedOrganization(
            this.formValue.askEEXPostingDetail,
            formValue.askEEXPostingDetail,
          ),
        },
        bidEEXPostingDetail: {
          ...formValue.bidEEXPostingDetail,
          eexTradingFirm: await this.getEexTradingFirmResolvedOrganization(
            this.formValue.bidEEXPostingDetail,
            formValue.bidEEXPostingDetail,
          ),
        },
      }
      Object.assign(this.formValue, formValueLodedEexTradingFirm)
    },
    isInvalid(veeValidateInvalid: boolean): boolean {
      const isDetailInvalid = (detail: EEXPostingDetailProps): boolean => {
        const { organization, eexTrader, eexClearingAccount } = detail
        return !organization || !eexTrader || !eexClearingAccount
      }
      return (
        isDetailInvalid(this.formValue.askEEXPostingDetail) ||
        isDetailInvalid(this.formValue.bidEEXPostingDetail) ||
        veeValidateInvalid
      )
    },
    onConfirmClick(): void {
      switch (this.mode) {
        case EEXPostingFormInputMode.NEW:
        case EEXPostingFormInputMode.REPOST:
          this.mode = EEXPostingFormInputMode.CONFIRM
          break
        case EEXPostingFormInputMode.CONFIRM:
          this.doCreateEEXPosting()
          break
      }
    },
    async backListPage(): Promise<void> {
      await this.$router.push(`/eex-postings`)
      await this.$router.go(0)
    },
    async onCancelOrBackClick(): Promise<void> {
      switch (this.mode) {
        case EEXPostingFormInputMode.NEW:
        case EEXPostingFormInputMode.REPOST:
          this.$emit('cancel')
          break
        case EEXPostingFormInputMode.CONFIRM:
          this.mode = this.initialMode
          break
      }
    },
    async doCreateEEXPosting(): Promise<void> {
      const buildDetailPayload = (
        detail: EEXPostingDetailProps,
      ): NewEEXPostingDetail | undefined => {
        const { organization, eexTrader, eexClearingAccount } = detail
        if (
          organization === undefined ||
          eexTrader === undefined ||
          eexClearingAccount === undefined
        ) {
          return undefined
        }
        return {
          organizationId: organization.organizationId,
          eexTraderCode: eexTrader.eexTraderCode,
          eexClearingAccountCode: eexClearingAccount.eexClearingAccountCode,
        }
      }
      const buildPayload = (): NewEEXPosting | undefined => {
        const {
          productCode,
          instrument,
          startDeliveryDate,
          endDeliveryDate,
          quantity,
          price,
        } = this.formValue
        const askEEXPostingDetail = buildDetailPayload(
          this.formValue.askEEXPostingDetail,
        )
        const bidEEXPostingDetail = buildDetailPayload(
          this.formValue.bidEEXPostingDetail,
        )
        const isLackDeliveryDate =
          startDeliveryDate === undefined || endDeliveryDate === undefined
        const isLackInstrument = instrument === undefined && isLackDeliveryDate
        if (
          productCode === undefined ||
          isLackInstrument ||
          quantity === undefined ||
          price === undefined ||
          askEEXPostingDetail === undefined ||
          bidEEXPostingDetail === undefined
        ) {
          return undefined
        }
        const payload = {
          productCode,
          instrument,
          deliveryStartDate: startDeliveryDate,
          deliveryEndDate: endDeliveryDate,
          quantity,
          price,
          askEEXPostingDetail,
          bidEEXPostingDetail,
        }
        return payload
      }
      const payload = buildPayload()
      if (!payload) {
        return
      }
      this.submitting = true
      await this.createEEXPosting(payload)
        .then(async () => {
          setNotification(
            this.$t('trading.message.successCreateEEXPosting').toString(),
          )
          await this.backListPage()
          await this.sleep(1)
          this.$vfm.close(this.modalName)
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failCreateEEXPosting').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.submitting = false
        })
    },
    sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
})
