
import { defineComponent } from 'vue'

import HorizontalTab from '@/components/common/HorizontalTab.vue'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'

const RECEIVE_EMAIL_TAB_INDEXES = {
  MEMBERS: 0,
  ORGANIZATION_EMAIL_ADDRESSES: 1,
}

export default defineComponent({
  name: 'EmailSetting',
  components: {
    HorizontalTab,
  },
  props: {
    basePath: {
      type: String,
      default: '/email-setting',
    },
  },
  computed: {
    tabList(): HorizontalTabItem[] {
      return [
        {
          label: this.$t('iam.label.eSquareMembers').toString(),
          to: `${this.basePath}/members`,
        },
        {
          label: this.$t('iam.label.organizationEmailAddresses').toString(),
          to: `${this.basePath}/organization-email-addresses`,
        },
      ]
    },
    currentTabIndex(): number {
      switch (this.$route.name) {
        case 'OrganizationEmailAddresses':
        case 'MyOrganizationEmailAddresses':
          return RECEIVE_EMAIL_TAB_INDEXES.ORGANIZATION_EMAIL_ADDRESSES
        case 'Members':
        default:
          return RECEIVE_EMAIL_TAB_INDEXES.MEMBERS
      }
    },
    isChild(): boolean {
      return this.$route.meta?.parentName === 'EmailSetting'
    },
  },
})
