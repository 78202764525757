import { Mutations } from 'vuex-smart-module'

import { DealState } from '@/store/modules/trading/deal/DealState'
import { DealFilterConditions } from '@/store/modules/trading/deal/interface'

export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION'

export class DealMutations extends Mutations<DealState> {
  [SET_FILTER_CONDITION](payload: { filterCondition: DealFilterConditions }) {
    this.state.filterCondition = payload.filterCondition
  }
}
