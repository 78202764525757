import { Module } from 'vuex-smart-module'

import { OrderBookActions } from '@/store/modules/trading/orderBook/OrderBookActions'
import { OrderBookGetters } from '@/store/modules/trading/orderBook/OrderBookGetters'
import { OrderBookMutations } from '@/store/modules/trading/orderBook/OrderBookMutations'
import { OrderBookState } from '@/store/modules/trading/orderBook/OrderBookState'

export const orderBook = new Module({
  actions: OrderBookActions,
  getters: OrderBookGetters,
  mutations: OrderBookMutations,
  state: OrderBookState,
})

export type OrderBookModule = typeof orderBook
