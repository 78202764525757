import { ActiveChannel } from '@/models/chat/ActiveChannel'
import { ActiveChannelWindow } from '@/models/chat/ActiveChannelWindow'

export class ActiveChannelWindowList {
  activeChannelWindows: ActiveChannelWindow[]
  maxLength: number

  constructor(params: {
    activeChannelWindows?: ActiveChannelWindow[]
    maxLength: number
  }) {
    this.activeChannelWindows = params.activeChannelWindows || []
    this.maxLength = params.maxLength
  }

  add(activeChannelWindow: ActiveChannelWindow): void {
    this.activeChannelWindows.unshift(activeChannelWindow)
    if (this.activeChannelWindows.length > this.maxLength) {
      this.activeChannelWindows.pop()
    }
  }

  remove(channelId: string): void {
    const index = this.indexOfChannelId(channelId)
    if (index !== -1) {
      this.activeChannelWindows.splice(index, 1)
    }
  }

  indexOfChannelId(channelId: string): number {
    return this.activeChannelWindows.findIndex(
      activeChannelWindow => activeChannelWindow.channelId === channelId,
    )
  }

  activeChannelWindowOfChannelId(
    channelId: string,
  ): ActiveChannelWindow | undefined {
    return this.activeChannelWindows[this.indexOfChannelId(channelId)]
  }

  activeChannelOfChannelId(channelId: string): ActiveChannel | undefined {
    return this.activeChannelWindowOfChannelId(channelId)?.activeChannel
  }

  get length() {
    return this.activeChannelWindows.length
  }
}
