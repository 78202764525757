import { Mutations } from 'vuex-smart-module'

import { OrganizationProfile } from '@/models/iam/OrganizationProfile'
import { OrganizationProfileState } from '@/store/modules/iam/organizationProfile/OrganizationProfileState'

export const SET_ORGANIZATION_PROFILE = 'SET_ORGANIZATION_PROFILE'
export const SET_CHANGE_PICTURE_SUBMITTING = 'SET_CHANGE_PICTURE_SUBMITTING'
export const SET_DELETE_PICTURE_SUBMITTING = 'SET_DELETE_PICTURE_SUBMITTING'

export class OrganizationProfileMutations extends Mutations<
  OrganizationProfileState
> {
  [SET_ORGANIZATION_PROFILE](payload: {
    organizationProfile: OrganizationProfile
  }) {
    this.state.organizationProfile = payload.organizationProfile
  }

  [SET_CHANGE_PICTURE_SUBMITTING](payload: { submitting: boolean }) {
    this.state.changePictureSubmitting = payload.submitting
  }

  [SET_DELETE_PICTURE_SUBMITTING](payload: { submitting: boolean }) {
    this.state.deletePictureSubmitting = payload.submitting
  }
}
