import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6f3da28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-new" }
const _hoisted_2 = { class: "report-new__report-type" }
const _hoisted_3 = { class: "report-new__content-list" }
const _hoisted_4 = { class: "report-new__buttons" }
const _hoisted_5 = { class: "report-content-modal" }
const _hoisted_6 = { class: "report-content-modal__action-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_checkbox = _resolveComponent("base-input-checkbox")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_report_content_list = _resolveComponent("report-content-list")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_report_content_form = _resolveComponent("report-content-form")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('reports.label.new'),
    "active-menu-item": "reports"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_validation_form, null, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_base_input_checkbox, {
                label: _ctx.$t('reports.label.reportType'),
                name: "reportType",
                required: true,
                options: _ctx.reportTypeOptions,
                rules: "requiredArray",
                onInput: _ctx.onInputReportTypes
              }, null, 8, ["label", "options", "onInput"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_label, {
                class: "report-content-form__summary-label",
                label: _ctx.$t('reports.label.reportContents')
              }, null, 8, ["label"]),
              _createVNode(_component_report_content_list, {
                "ja-content-title": _ctx.values.reportContents[_ctx.locale.Ja].title,
                "en-content-title": _ctx.values.reportContents[_ctx.locale.En].title,
                onListItemClick: _ctx.openReportContentModal
              }, null, 8, ["ja-content-title", "en-content-title", "onListItemClick"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_base_button, {
                class: "report-new__cancel-button",
                onClick: _ctx.onCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                class: "report-new__publish-button",
                disabled: !_ctx.hasValidContent || Object.keys(errors).length > 0,
                color: "primary",
                onClick: _ctx.onPublishReportClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.publish')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ])
          ]),
          _createVNode(_component_base_modal, {
            name: _ctx.reportContentModalName,
            title: _ctx.reportContentModalTitle,
            width: 850,
            "click-to-close": false
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_action_dropdown, {
                    id: "report-content-modal-action-dropdown",
                    actions: _ctx.actionDropdownItems,
                    "is-active": _ctx.actionDropdownIsActive,
                    "is-right": "",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                _ctx.actionDropdownIsActive = !_ctx.actionDropdownIsActive
              ), ["stop"])),
                    onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actionDropdownIsActive = false)),
                    onDeleteReportContentClick: _ctx.onDeleteReportContentClick
                  }, null, 8, ["actions", "is-active", "onDeleteReportContentClick"])
                ]),
                _createVNode(_component_report_content_form, {
                  title: _ctx.values.reportContents[_ctx.currentLocale].title,
                  summary: _ctx.values.reportContents[_ctx.currentLocale].summary,
                  "selected-file": _ctx.values.reportContents[_ctx.currentLocale].selectedFile,
                  "uploaded-file-name": 
              _ctx.values.reportContents[_ctx.currentLocale].uploadedFileName
            ,
                  onConfirmClick: _ctx.onConfirmReportContentClick,
                  onCancelClick: _ctx.hideReportContentModal
                }, null, 8, ["title", "summary", "selected-file", "uploaded-file-name", "onConfirmClick", "onCancelClick"])
              ])
            ]),
            _: 1
          }, 8, ["name", "title"])
        ]),
        _: 1
      }),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.publishReportModalName,
        title: _ctx.$t('reports.message.publishReportTitle'),
        "confirm-button-label": _ctx.$t('common.label.publish'),
        loading: _ctx.publishReportSubmitting,
        onConfirm: _ctx.onPublishReportConfirm,
        onCancel: _ctx.onPublishReportCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('reports.message.publishReportMessage')), 1)
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}