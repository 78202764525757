
import { defineComponent, PropType } from 'vue'

import { TooltipDirections } from '@/components/common/constants/TooltipDirections'

let counter = 0

export default defineComponent({
  name: 'BaseTooltip',
  props: {
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String as PropType<typeof TooltipDirections[number]>,
      default: 'upward',
      validator: (val: typeof TooltipDirections[number]) => {
        return TooltipDirections.includes(val)
      },
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTooltipShown: false,
      tooltipId: `tooltip-${(counter++).toString()}`,
    }
  },
  computed: {
    tooltipClass(): Array<string | Record<string, boolean>> {
      return [
        'base-tooltip',
        {
          'base-tooltip--full-width': this.fullWidth,
          'base-tooltip--shown': this.isTooltipShown,
        },
      ]
    },
    tooltipInnerClass(): Array<string | Record<string, boolean>> {
      return [
        'base-tooltip__contents',
        `base-tooltip__contents--${this.direction}`,
        { 'base-tooltip__contents--disable': !this.isActive },
      ]
    },
  },
  methods: {
    onKeydown(e: KeyboardEvent) {
      if (this.isTooltipShown && e.key === 'Escape') {
        this.isTooltipShown = false
      }
    },
    onClick() {
      this.isTooltipShown = !this.isTooltipShown
    },
    onEnterMouse() {
      this.isTooltipShown = true
    },
    onLeaveMouse() {
      this.isTooltipShown = false
    },
  },
})
