import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e699e276"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trading-steps" }
const _hoisted_2 = { class: "trading-steps__step" }
const _hoisted_3 = { class: "trading-steps__indications" }
const _hoisted_4 = { class: "trading-steps__label" }
const _hoisted_5 = { class: "trading-steps__organization-name" }
const _hoisted_6 = { class: "trading-steps__organization-name" }
const _hoisted_7 = { class: "trading-steps__step" }
const _hoisted_8 = { class: "trading-steps__label" }
const _hoisted_9 = { class: "trading-steps__organization-name" }
const _hoisted_10 = {
  key: 0,
  class: "trading-steps__organization-name"
}
const _hoisted_11 = { class: "trading-steps__organization-name" }
const _hoisted_12 = { class: "trading-steps__step" }
const _hoisted_13 = { class: "trading-steps__deals" }
const _hoisted_14 = { class: "trading-steps__label" }
const _hoisted_15 = { class: "trading-steps__organization-name" }
const _hoisted_16 = { class: "trading-steps__organization-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_position_icon = _resolveComponent("position-icon")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_credit_sleeve_icon = _resolveComponent("credit-sleeve-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { class: "trading-steps__box" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('common.label.indications')), 1),
            (_ctx.tradingSteps.askIndication)
              ? (_openBlock(), _createBlock(_component_base_link, {
                  key: 0,
                  class: _normalizeClass(["trading-steps__link", {
              'trading-steps__link--clickable': _ctx.askIndicationClickable,
            }]),
                  disabled: !_ctx.askIndicationClickable,
                  path: _ctx.askIndicationUrl,
                  "reset-font-style": "",
                  "is-target-blank": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_box, { type: "border" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_position_icon, {
                            position: _ctx.positionAsk,
                            "color-reversed": _ctx.colorReversed
                          }, null, 8, ["position", "color-reversed"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.tradingSteps.askIndication.organizationName), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class", "disabled", "path"]))
              : _createCommentVNode("", true),
            (_ctx.tradingSteps.bidIndication)
              ? (_openBlock(), _createBlock(_component_base_link, {
                  key: 1,
                  class: _normalizeClass(["trading-steps__link", {
              'trading-steps__link--clickable': _ctx.bidIndicationClickable,
            }]),
                  disabled: !_ctx.bidIndicationClickable,
                  path: _ctx.bidIndicationUrl,
                  "reset-font-style": "",
                  "is-target-blank": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_box, { type: "border" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_position_icon, {
                            position: _ctx.positionBid,
                            "color-reversed": _ctx.colorReversed
                          }, null, 8, ["position", "color-reversed"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.tradingSteps.bidIndication.organizationName), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class", "disabled", "path"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('common.label.negotiations')), 1),
          (_ctx.tradingSteps.negotiation)
            ? (_openBlock(), _createBlock(_component_base_link, {
                key: 0,
                class: _normalizeClass(["trading-steps__link", {
            'trading-steps__link--clickable': _ctx.negotiationClickable,
          }]),
                disabled: !_ctx.negotiationClickable,
                path: _ctx.negotiationUrl,
                "reset-font-style": "",
                "is-target-blank": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_box, { type: "border" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_position_icon, {
                          position: _ctx.positionAsk,
                          "color-reversed": _ctx.colorReversed
                        }, null, 8, ["position", "color-reversed"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.tradingSteps.negotiation.askOrganizationName), 1)
                      ]),
                      (_ctx.tradingSteps.negotiation.creditSleeverOrganizationName)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_credit_sleeve_icon),
                            _createTextVNode(" " + _toDisplayString(_ctx.tradingSteps.negotiation.creditSleeverOrganizationName), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_position_icon, {
                          position: _ctx.positionBid,
                          "color-reversed": _ctx.colorReversed
                        }, null, 8, ["position", "color-reversed"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.tradingSteps.negotiation.bidOrganizationName), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class", "disabled", "path"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('common.label.deals')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tradingSteps.deals, (deal, index) => {
              return (_openBlock(), _createBlock(_component_base_link, {
                key: index,
                class: _normalizeClass(["trading-steps__link", {
              'trading-steps__link--clickable': _ctx.dealClickableList[index],
            }]),
                disabled: !_ctx.dealClickableList[index],
                path: _ctx.dealUrlList[index],
                "reset-font-style": "",
                "is-target-blank": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_box, { type: "border" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_position_icon, {
                          position: _ctx.positionAsk,
                          "color-reversed": _ctx.colorReversed
                        }, null, 8, ["position", "color-reversed"]),
                        _createTextVNode(" " + _toDisplayString(deal.askOrganizationName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_position_icon, {
                          position: _ctx.positionBid,
                          "color-reversed": _ctx.colorReversed
                        }, null, 8, ["position", "color-reversed"]),
                        _createTextVNode(" " + _toDisplayString(deal.bidOrganizationName), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["class", "disabled", "path"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}