import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "base-multi-select__label" }
const _hoisted_3 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-multi-select",
    style: _normalizeStyle(_ctx.styleVars)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.labelString), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_multiselect, {
          "model-value": _ctx.value,
          options: _ctx.options,
          placeholder: _ctx.placeholder,
          "track-by": "value",
          label: "label",
          name: _ctx.name,
          multiple: true,
          "close-on-select": false,
          searchable: false,
          "select-label": "+",
          "deselect-label": "-",
          "selected-label": "",
          onSelect: _ctx.onSelect,
          onRemove: _ctx.onRemove
        }, null, 8, ["model-value", "options", "placeholder", "name", "onSelect", "onRemove"])
      ])
    ])
  ], 4))
}