
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import RecapReceiverForm from '@/components/iam/RecapReceiverForm.vue'
import { RecapReceiverFormProps } from '@/components/iam/interface/RecapReceiverFormProps'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import { RecapReceiver } from '@/models/iam/RecapReceiver'
import { RecapReceiverModule } from '@/store/modules/iam/recapReceiver'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapActions: recapReceiverMapActions } = createNamespacedHelpers(
  'recapReceiver',
) as RecapReceiverModule

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'RecapReceiver',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    ConfirmationDialog,
    ParticipantSummary,
    RecapReceiverForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    deleteModalName: string
    formValue: RecapReceiverFormProps
    actionDropdownIsActive: boolean
    originalName: string
    deleteSubmitting: boolean
    updateSubmitting: boolean
    createdParticipantName: string
    createdAt: string
    lastUpdatedParticipantName: string
    updatedAt: string
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      deleteModalName: 'deleteConfirmModal',
      formValue: {
        department: undefined,
        name: undefined,
        phoneNumber: undefined,
        emailAddress: undefined,
      },
      actionDropdownIsActive: false,
      originalName: '',
      deleteSubmitting: false,
      updateSubmitting: false,
      createdParticipantName: '',
      createdAt: '',
      lastUpdatedParticipantName: '',
      updatedAt: '',
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    actionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('iam.label.deleteRecapReceiver').toString(),
          eventName: 'delete-recap-receiver-click',
        },
      ]
    },
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
  },
  async created() {
    await this.fetchRecapReceiver({
      organizationId: this._organizationId,
      recapReceiverId: this.$attrs.id,
    })
      .then(recapReceiver => {
        this.formValue.department = recapReceiver.department
        this.formValue.name = recapReceiver.name
        this.formValue.phoneNumber = recapReceiver.phoneNumber
        this.formValue.emailAddress = recapReceiver.emailAddress
        this.createdParticipantName = recapReceiver.createdParticipantName || ''
        this.createdAt = recapReceiver.createdAt
        this.lastUpdatedParticipantName =
          recapReceiver.lastUpdatedParticipantName || ''
        this.updatedAt = recapReceiver.updatedAt
        this.originalName = recapReceiver.name
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    fetchRecapReceiver: recapReceiverMapActions(['fetchRecapReceiver'])
      .fetchRecapReceiver as (payload: {
      organizationId: string
      recapReceiverId: string
    }) => Promise<RecapReceiver>,
    ...recapReceiverMapActions(['updateRecapReceiver', 'deleteRecapReceiver']),
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    async onUpdateClick() {
      if (!this.formValue.name) {
        return
      }
      this.updateSubmitting = true
      await this.updateRecapReceiver({
        organizationId: this._organizationId,
        recapReceiverId: this.$attrs.id,
        recapReceiver: {
          department: this.formValue.department,
          name: this.formValue.name,
          phoneNumber: this.formValue.phoneNumber,
          emailAddress: this.formValue.emailAddress,
        },
      })
        .then(() => {
          setNotification(
            this.$t('iam.message.successUpdateRecapReceiver').toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          const message =
            e.response.status === HttpStatus.Conflict
              ? this.$t('iam.message.failUpdateRecapReceiverByDuplicate', {
                  name: this.formValue.name,
                }).toString()
              : this.$t('iam.message.failUpdateRecapReceiver').toString()
          setNotification(message, 'danger')
          throw e
        })
        .finally(() => {
          this.updateSubmitting = false
        })
    },
    onFormInput(value: RecapReceiverFormProps) {
      Object.assign(this.formValue, value)
    },
    async onDeleteRecapReceiverClick() {
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteRecapReceiverCancel() {
      this.$vfm.close(this.deleteModalName)
    },
    async onDeleteRecapReceiverConfirm() {
      this.deleteSubmitting = true
      await this.deleteRecapReceiver({
        organizationId: this._organizationId,
        recapReceiverId: this.$attrs.id,
      })
        .then(async () => {
          setNotification(
            this.$t('iam.message.successDeleteRecapReceiver').toString(),
          )
          this.$vfm.close(this.deleteModalName)
          this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t('iam.message.failDeleteRecapReceiver').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
  },
})
