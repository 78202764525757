import { ActiveChannel } from '@/models/chat/ActiveChannel'

export class ActiveChannelWindow {
  activeChannel: ActiveChannel
  isOpened: boolean

  constructor(params: { activeChannel: ActiveChannel; isOpened: boolean }) {
    this.activeChannel = params.activeChannel
    this.isOpened = params.isOpened
  }

  get channelId() {
    return this.activeChannel.channelId
  }
}
