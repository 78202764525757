
import moment from 'moment'
import { defineComponent, PropType } from 'vue'

import { Locale, PositionType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import BaseDatetimepicker from '@/components/common/BaseDatetimepicker.vue'
import BaseInputSingleCheckbox from '@/components/common/BaseInputSingleCheckbox.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTextArea from '@/components/common/BaseTextArea.vue'
import { MAX_LENGTH_OF_TEXT_AREA } from '@/components/common/constants/ValidationRules'
import { InputOption } from '@/components/common/interface/InputOption'
import DealRecapStatus from '@/components/trading/DealRecapStatus.vue'
import OrganizationRecapForm from '@/components/trading/OrganizationRecapForm.vue'
import { DealFormProps } from '@/components/trading/interface/DealFormProps'
import { Area } from '@/models/trading/Area'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { ProductType } from '@/models/trading/ProductType'
import { displayRecapStatusName } from '@/store/modules/trading/recap/helper'
import {
  RecapInfo,
  RecapCreatedOption,
  RecapStatus,
} from '@/store/modules/trading/recap/interface'

export default defineComponent({
  name: 'DealWithOrganizationRecapForm',
  components: {
    BaseBox,
    BaseButton,
    BaseDataset,
    BaseDatetimepicker,
    BaseInputSingleCheckbox,
    BaseLabel,
    BaseSingleSelect,
    BaseTextArea,
    DealRecapStatus,
    OrganizationRecapForm,
  },
  props: {
    dealWithOrganizationRecap: {
      type: Object as PropType<DealWithOrganizationRecap>,
      required: true,
    },
    areas: {
      type: Array as PropType<Area[]>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    formValue: {
      type: Object as PropType<DealFormProps>,
      required: true,
    },
    enableRecap: {
      type: Boolean,
      required: false,
    },
    recapInfo: {
      type: Object as PropType<RecapInfo>,
      required: false,
    },
    recapCreatedOptions: {
      type: Object as PropType<RecapCreatedOption>,
      required: false,
    },
  },
  emits: [
    'form-input',
    'create-recap',
    'confirm-recap',
    'recreate-recap',
    'change-recap-options',
  ],
  data(): {
    paymentDueInformationTemplateList: {
      label: string
      message: string
    }[]
    ask: PositionType
    bid: PositionType
    formInputEventName: 'form-input'
    createRecapEventName: 'create-recap'
    reCreateRecapEventName: 'recreate-recap'
    confirmRecapEventName: 'confirm-recap'
    recapCreateOptionsEventName: 'change-recap-options'
    maxLengthOfTextArea: string
  } {
    return {
      paymentDueInformationTemplateList: [
        {
          label: this.$t('trading.label.paymentForNextMonth'),
          message: this.$t('trading.message.paymentForNextMonth', Locale.Ja),
        },
        {
          label: this.$t('trading.label.paymentForSameMonth'),
          message: this.$t('trading.message.paymentForSameMonth', Locale.Ja),
        },
        {
          label: this.$t('trading.label.prepayment'),
          message: this.$t('trading.message.prepayment', Locale.Ja),
        },
      ],
      ask: PositionType.Ask,
      bid: PositionType.Bid,
      formInputEventName: 'form-input',
      createRecapEventName: 'create-recap',
      reCreateRecapEventName: 'recreate-recap',
      confirmRecapEventName: 'confirm-recap',
      recapCreateOptionsEventName: 'change-recap-options',
      maxLengthOfTextArea: MAX_LENGTH_OF_TEXT_AREA,
    }
  },
  computed: {
    RecapStatus() {
      return RecapStatus
    },
    noSpreadAreaInputOptions(): InputOption[] {
      return this.areas
        .filter(area => !area.isSpread)
        .map(area => area.toInputOption())
    },
    productType(): ProductType {
      return this.dealWithOrganizationRecap.deal.product.productType
    },
    executedAt(): Date | undefined {
      return this.formValue.executedAt
        ? new Date(this.formValue.executedAt)
        : undefined
    },
    notIncludingTocomOrEex(): boolean {
      return !(this.productType.isSwapEex() || this.productType.isSwapTocom())
    },
    isCfdAndIsNotAreaSpread(): boolean {
      return (
        this.productType.isCfd() &&
        !this.dealWithOrganizationRecap.deal.product.area.isSpread
      )
    },
    spreadBasePrice(): string | undefined {
      return this.dealWithOrganizationRecap.deal.spreadBasePrice?.toString()
    },
    displayUnitPrices(): string {
      return this.dealWithOrganizationRecap.deal.unitPrices.join(', ')
    },
  },
  methods: {
    displayRecapStatus(status?: RecapStatus): string {
      return displayRecapStatusName(status)
    },
    getRecapStatus(organizationId: string): RecapStatus | undefined {
      for (const item of this.recapInfo?.details || []) {
        if (item.organization === organizationId) {
          return item.status
        }
      }
      return undefined
    },
    hasRecapStatus(organizationId: string, ...status: RecapStatus[]): boolean {
      for (const item of this.recapInfo?.details || []) {
        if (item.organization === organizationId) {
          return status.some(s => s === item.status)
        }
      }
      return false
    },
    onExecutedAtClick(executedAt: Date | undefined) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        executedAt: executedAt
          ? moment(executedAt).format('YYYY-MM-DD HH:mm')
          : undefined,
      })
    },
    onSettlementUnitPriceAreaInput(
      settlementUnitPriceAreaInputOption: InputOption,
    ) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        settlementUnitPriceAreaInputOption,
      })
    },
    onRestrictionsInput(restrictions: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        restrictions,
      })
    },
    onNoteInput(note: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        note,
      })
    },
    onRemarksInput(remarks: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        remarks,
      })
    },
    onPaymentDueInformationInput(paymentDueInformation: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        paymentDueInformation,
      })
    },
  },
})
