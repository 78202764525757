
import { defineComponent, PropType } from 'vue'

import ChannelMember from '@/components/chat/ChannelMember.vue'
import { ChannelMemberProps } from '@/components/chat/interface/ChannelMemberProps'

export default defineComponent({
  name: 'ChannelMemberList',
  components: {
    ChannelMember,
  },
  props: {
    members: {
      type: Array as PropType<ChannelMemberProps[]>,
      default: () => [],
    },
  },
})
