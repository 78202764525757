import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22445370"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-delivery-period-list" }
const _hoisted_2 = { class: "deal-delivery-period-list__column" }
const _hoisted_3 = { class: "deal-delivery-period-list__column" }
const _hoisted_4 = { class: "deal-delivery-period-list__column" }
const _hoisted_5 = { class: "deal-delivery-period-list__column" }
const _hoisted_6 = { class: "deal-delivery-period-list__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deal_delivery_period_header = _resolveComponent("deal-delivery-period-header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_deal_delivery_period_header),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "deal-delivery-period-list__row"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(row.deliveryStartDate), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(row.deliveryEndDate), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(row.hourTypeNamesWithTimeRanges), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('trading.label.unitPriceWithUnit', {
            unitPrice: row.unitPrice,
          })), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('trading.label.volumeWithUnit', {
            volume: row.volume,
          })), 1)
      ]))
    }), 128))
  ]))
}