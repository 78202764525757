import { Getters } from 'vuex-smart-module'

import { NegotiationStatusType } from '@/api/generated'
import { NegotiationState } from '@/store/modules/trading/negotiation/NegotiationState'

export class NegotiationGetters extends Getters<NegotiationState> {
  get filterConditionParameter(): {
    startDeliveryYearMonth: string | undefined
    endDeliveryYearMonth: string | undefined
    areaId: string | undefined
    hourTypeId: string | undefined
    productTypeId: string | undefined
    organizationName: string | undefined
    publicNegotiationId: string | undefined
    status: NegotiationStatusType | undefined
  } {
    return {
      startDeliveryYearMonth: this.state.filterCondition.startDeliveryYearMonth,
      endDeliveryYearMonth: this.state.filterCondition.endDeliveryYearMonth,
      areaId: this.state.filterCondition.area,
      hourTypeId: this.state.filterCondition.hourType,
      productTypeId: this.state.filterCondition.productType,
      organizationName:
        this.state.filterCondition.organizationName || undefined,
      publicNegotiationId:
        this.state.filterCondition.negotiationId || undefined,
      status: this.state.filterCondition.status,
    }
  }
}
