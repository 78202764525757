
import { defineComponent, PropType } from 'vue'

import { LoadingType } from '@/components/common/constants/LoadingType'

export default defineComponent({
  name: 'LoadingIcon',
  props: {
    type: {
      type: String as PropType<LoadingType>,
      default: LoadingType.DEFAULT,
    },
  },
  computed: {
    imageSrc() {
      return require(`@/assets/images/loading/${this.type}-loading.png`)
    },
  },
})
