import { Getters } from 'vuex-smart-module'

import { PrivateConfidentialOptions } from '@/models/trading/PrivateConfidentialOptions'
import { DealState } from '@/store/modules/trading/deal/DealState'

export class DealGetters extends Getters<DealState> {
  get filterConditionParameter(): {
    startDeliveryYearMonth?: string
    endDeliveryYearMonth?: string
    organizationName?: string
    areaId?: string
    hourTypeId?: string
    productTypeId?: string
    privateConfidentialOfFilter?: boolean
    publicNegotiationId?: string
    executedDate?: string
  } {
    return {
      startDeliveryYearMonth: this.state.filterCondition.startDeliveryYearMonth,
      endDeliveryYearMonth: this.state.filterCondition.endDeliveryYearMonth,
      organizationName: this.state.filterCondition.organizationName,
      areaId: this.state.filterCondition.area,
      hourTypeId: this.state.filterCondition.hourType,
      productTypeId: this.state.filterCondition.productType,
      privateConfidentialOfFilter: PrivateConfidentialOptions.valueOf(
        this.state.filterCondition.privateConfidentialOfFilter,
      ),
      publicNegotiationId: this.state.filterCondition.negotiationId,
      executedDate: this.state.filterCondition.executedDate,
    }
  }
}
