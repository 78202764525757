import { Type } from 'class-transformer'

import { DeliveryPattern as IDeliveryPattern } from '@/api/generated'
import { i18n } from '@/i18n'
import { DeliveryPatternComponent } from '@/models/trading/DeliveryPatternComponent'
import { HourType } from '@/models/trading/HourType'

export class DeliveryPattern implements IDeliveryPattern {
  id!: string

  @Type(() => DeliveryPatternComponent)
  deliveryPatternComponents!: DeliveryPatternComponent[]

  @Type(() => HourType)
  hourType?: HourType

  displayHourTypeNamesWithTimeRanges(): string {
    // 標準商品
    if (this.hourType) {
      return this.hourType.name.translation()
    }
    // 非標準商品
    return this.deliveryPatternComponents
      .map(component => {
        const name = component.displayDayPatternName()
        const timeRange = component.displayTimeRange()
        return `${name} ${timeRange}`
      })
      .join(i18n.t('common.label.separator').toString())
  }
}
