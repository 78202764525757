
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseInputPassword from '@/components/iam/BaseInputPassword.vue'
import { EclearSettingFormProps } from '@/components/iam/interface/EclearSettingFormProps'

export default defineComponent({
  name: 'EclearSetting',
  components: {
    BaseBox,
    BaseDataset,
    BaseIcon,
    BaseInputPassword,
    BaseLabel,
  },
  props: {
    formValue: {
      type: Object as PropType<EclearSettingFormProps>,
      required: true,
    },
  },
  emits: ['form-input'],
})
