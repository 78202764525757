
import { defineComponent } from 'vue'

import BrokerPage from '@/components/common/BrokerPage.vue'
import { appEnv } from '@/utils/env'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const tableau: any

export default defineComponent({
  name: 'InternalDashboardPage',
  components: {
    BrokerPage,
  },
  props: {
    titleKey: { type: String, required: true },
    workbook: { type: String, required: true },
    view: { type: String, required: true },
    hasMultipleEnv: { type: Boolean, default: true },
    footnoteKey: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.initViz()
  },
  methods: {
    initViz() {
      const containerDiv = document.getElementById('vizContainer')

      let workbookName = this.workbook
      if (this.hasMultipleEnv) {
        const envSuffix = appEnv === 'prod' ? '-prod' : '-dev'
        workbookName += envSuffix
      }
      const url = `https://10ay.online.tableau.com/t/enechaindashboards/views/${workbookName}/${this.view}?:device=desktop`

      // eslint-disable-next-line no-new
      new tableau.Viz(containerDiv, url)
    },
  },
})
