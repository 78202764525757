
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderBookHeader',
  props: {
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bidClassName(): string {
      return this.colorReversed
        ? 'order-book-header__label--ask'
        : 'order-book-header__label--bid'
    },
    askClassName(): string {
      return this.colorReversed
        ? 'order-book-header__label--bid'
        : 'order-book-header__label--ask'
    },
  },
})
