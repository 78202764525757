import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-345bcf6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-content-form" }
const _hoisted_2 = { class: "report-content-form__title" }
const _hoisted_3 = { class: "report-content-form__summary" }
const _hoisted_4 = { class: "report-content-form__summary-container" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "report-content-form__attachment" }
const _hoisted_7 = { class: "report-content-form__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_text_area = _resolveComponent("base-text-area")!
  const _component_horizontal_tab = _resolveComponent("horizontal-tab")!
  const _component_base_input_file = _resolveComponent("base-input-file")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!

  return (_openBlock(), _createBlock(_component_validation_form, null, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_input_text, {
            value: _ctx.values.title,
            label: _ctx.$t('reports.label.title'),
            name: "title",
            required: true,
            rules: "required|max:100",
            onInput: _cache[0] || (_cache[0] = $event => (_ctx.values.title = $event))
          }, null, 8, ["value", "label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_label, {
              class: "report-content-form__summary-label",
              label: _ctx.$t('reports.label.summary') + '*'
            }, null, 8, ["label"]),
            _createVNode(_component_horizontal_tab, { "tab-list": _ctx.tabList }, {
              "tabPanel-1": _withCtx(() => [
                _createVNode(_component_base_text_area, {
                  value: _ctx.values.summary,
                  name: "summary",
                  class: "report-content-form__summary-edit",
                  rows: 20,
                  rules: "required|max:3000",
                  onInput: _cache[1] || (_cache[1] = $event => (_ctx.values.summary = $event))
                }, null, 8, ["value"])
              ]),
              "tabPanel-2": _withCtx(() => [
                _createElementVNode("div", {
                  class: "report-content-form__summary-preview",
                  innerHTML: _ctx.summaryHtml
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            }, 8, ["tab-list"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_base_input_file, {
            label: _ctx.$t('reports.label.attachment'),
            name: "attachment",
            "initial-selected-file": _ctx.selectedFile,
            "uploaded-file-name": _ctx.values.uploadedFileName,
            rules: _ctx.inputFileRules,
            help: _ctx.$t('reports.message.attachmentHelpText'),
            "accept-file-types": ['.pdf', '.zip'],
            onSelected: _ctx.onAttachmentSelected,
            onRemoved: _ctx.onAttachmentRemoved,
            onDownloadClick: _ctx.onAttachmentDownloadClick
          }, null, 8, ["label", "initial-selected-file", "uploaded-file-name", "rules", "help", "accept-file-types", "onSelected", "onRemoved", "onDownloadClick"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_base_button, {
            class: "report-content-form__cancel-button",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_base_button, {
            class: "report-content-form__confirm-button",
            disabled: Object.keys(errors).length > 0,
            color: "primary",
            onClick: _ctx.onConfirmClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}