import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eafbf91c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_member_list_item = _resolveComponent("member-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "member-list__row"
      }, [
        _createVNode(_component_member_list_item, {
          member: member,
          "parent-path": _ctx.parentPath
        }, null, 8, ["member", "parent-path"])
      ]))
    }), 128))
  ]))
}