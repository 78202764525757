
import sanitizeHtml from 'sanitize-html'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'

import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputFile from '@/components/common/BaseInputFile.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseTextArea from '@/components/common/BaseTextArea.vue'
import HorizontalTab from '@/components/common/HorizontalTab.vue'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'
import { ReportContentFormInput } from '@/components/reports/interface/ReportContentFormInput'
import marked from '@/plugins/marked'

export default defineComponent({
  name: 'ReportContentForm',
  components: {
    BaseButton,
    BaseInputFile,
    BaseInputText,
    BaseLabel,
    BaseTextArea,
    HorizontalTab,
    ValidationForm,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    summary: {
      type: String,
      default: undefined,
    },
    selectedFile: {
      type: File,
      default: undefined,
    },
    uploadedFileName: {
      type: String,
      default: undefined,
    },
  },
  emits: ['download-click', 'confirm-click', 'cancel-click'],
  data() {
    return {
      values: {
        title: '',
        summary: '',
        selectedFile: undefined as File | undefined,
        uploadedFileName: '',
      },
    }
  },
  computed: {
    tabList(): HorizontalTabItem[] {
      return [
        this.$t('reports.label.write').toString(),
        this.$t('reports.label.preview').toString(),
      ]
    },
    summaryHtml(): string {
      return this.values.summary
        ? sanitizeHtml(marked(this.values.summary))
        : this.$t('reports.message.nothingToPreview').toString()
    },
    inputFileRules(): string {
      return this.values.uploadedFileName ? '' : 'required|maxFileSize:30'
    },
  },
  created() {
    this.values.title = this.title || ''
    this.values.summary = this.summary || ''
    this.values.selectedFile = this.selectedFile
    this.values.uploadedFileName = this.uploadedFileName || ''
  },
  methods: {
    onAttachmentSelected(selectedFile: File) {
      this.values.selectedFile = selectedFile
    },
    onAttachmentRemoved() {
      this.values.selectedFile = undefined
      this.values.uploadedFileName = ''
    },
    onAttachmentDownloadClick() {
      this.$emit('download-click')
    },
    onConfirmClick() {
      const reportContentFormInput: ReportContentFormInput = {
        title: this.values.title,
        summary: this.values.summary,
        selectedFile: this.values.selectedFile,
      }
      this.$emit('confirm-click', reportContentFormInput)
    },
    onCancelClick() {
      this.$emit('cancel-click')
    },
  },
})
