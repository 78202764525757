import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-012a8a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createBlock(_component_sentences, {
    type: "text-medium",
    class: "message-body",
    style: _normalizeStyle(_ctx.styleVars)
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", { innerHTML: _ctx.compiledMessage }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["style"]))
}