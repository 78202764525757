import { Mutations } from 'vuex-smart-module'

import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import { ProductState } from '@/store/modules/trading/product/ProductState'

export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES'
export const SET_AREAS = 'SET_AREAS'
export const SET_HOUR_TYPES = 'SET_HOUR_TYPES'
export const SET_START_DELIVERY_YEAR_MONTHS = 'SET_START_DELIVERY_YEAR_MONTHS'
export const SET_END_DELIVERY_YEAR_MONTHS = 'SET_END_DELIVERY_YEAR_MONTHS'
export const SET_DELIVERY_YEAR_MONTHS = 'SET_DELIVERY_YEAR_MONTHS'
export const SET_FUEL_SURCHARGE_TYPES = 'SET_FUEL_SURCHARGE_TYPES'

export class ProductMutations extends Mutations<ProductState> {
  [SET_PRODUCT_TYPES](payload: { productTypes: ProductType[] }) {
    this.state.productTypes = payload.productTypes
  }

  [SET_AREAS](payload: { areas: Area[] }) {
    this.state.areas = payload.areas
  }

  [SET_HOUR_TYPES](payload: { hourTypes: HourType[] }) {
    this.state.hourTypes = payload.hourTypes
  }

  [SET_START_DELIVERY_YEAR_MONTHS](payload: {
    startDeliveryYearMonths: DeliveryYearMonth[]
  }) {
    this.state.startDeliveryYearMonths = payload.startDeliveryYearMonths
  }

  [SET_END_DELIVERY_YEAR_MONTHS](payload: {
    endDeliveryYearMonths: DeliveryYearMonth[]
  }) {
    this.state.endDeliveryYearMonths = payload.endDeliveryYearMonths
  }

  [SET_DELIVERY_YEAR_MONTHS](payload: {
    deliveryYearMonths: DeliveryYearMonth[]
  }) {
    this.state.deliveryYearMonths = payload.deliveryYearMonths
  }

  [SET_FUEL_SURCHARGE_TYPES](payload: {
    fuelSurchargeTypes: FuelSurchargeType[]
  }) {
    this.state.fuelSurchargeTypes = payload.fuelSurchargeTypes
  }
}
