
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseSkeleton from '@/components/common/BaseSkeleton.vue'
import BaseNavItem from '@/components/common/CollapsedNavigation/NavItem/BaseNavItem.vue'
import NavItemCategory from '@/components/common/CollapsedNavigation/NavItem/NavItemCategory.vue'
import OrganizationInfo from '@/components/common/CollapsedNavigation/OrganizationInfo.vue'
import { BaseCollapsedNavigationItem } from '@/components/common/interface/CollapsedNavigation/BaseCollapsedNavigationProps'

type StringOrUndefined = PropType<string | undefined>

export default defineComponent({
  name: 'BaseCollapsedNavigation',
  components: {
    BaseIcon,
    BaseNavItem,
    BaseSkeleton,
    NavItemCategory,
    OrganizationInfo,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    organizationName: {
      type: String as StringOrUndefined,
      default: undefined,
    },
    organizationPicture: {
      type: String as StringOrUndefined,
      default: undefined,
    },
    toggle: {
      type: Function,
      required: true,
    },
    navItems: {
      type: Array as PropType<BaseCollapsedNavigationItem[]>,
      required: true,
    },
  },
  computed: {
    isOrganizationDataFetched(): boolean {
      return !!this.organizationName && !!this.organizationPicture
    },
  },
})
