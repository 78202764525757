import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_channel = _resolveComponent("base-channel")!

  return (_openBlock(), _createBlock(_component_base_channel, {
    name: _ctx.name,
    "channel-id": _ctx.channelId,
    picture: _ctx.picture,
    "has-unread-message": _ctx.hasUnreadMessage,
    selected: _ctx.selected,
    "is-organization": "",
    onClick: _ctx.onClick
  }, null, 8, ["name", "channel-id", "picture", "has-unread-message", "selected", "onClick"]))
}