import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de13fafe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation-dialog" }
const _hoisted_2 = { class: "confirmation-dialog__message" }
const _hoisted_3 = { class: "confirmation-dialog__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    name: _ctx.name,
    title: _ctx.title,
    width: _ctx.width
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_base_button, {
            class: "confirmation-dialog__confirm-button",
            color: _ctx.confirmButtonColor,
            loading: _ctx.loading,
            onClick: _ctx.confirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmButtonLabel), 1)
            ]),
            _: 1
          }, 8, ["color", "loading", "onClick"]),
          _createVNode(_component_base_button, {
            class: "confirmation-dialog__cancel-button",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 3
  }, 8, ["name", "title", "width"]))
}