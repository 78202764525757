
import { defineComponent } from 'vue'

import { EEXPostingStatusType } from '@/api/generated'

export default defineComponent({
  name: 'PositionIcon',
  props: {
    status: {
      type: String,
      validator: (value: EEXPostingStatusType) =>
        [
          EEXPostingStatusType.Scheduled,
          EEXPostingStatusType.Processing,
          EEXPostingStatusType.Completed,
          EEXPostingStatusType.Canceled,
          EEXPostingStatusType.Rejected,
          EEXPostingStatusType.Invalid,
        ].includes(value),
      required: true,
    },
  },
  computed: {
    label() {
      switch (this.status) {
        case 'scheduled':
          return this.$t('trading.label.scheduled')
        case 'processing':
          return this.$t('trading.label.processing')
        case 'completed':
          return this.$t('trading.label.completed')
        case 'canceled':
          return this.$t('trading.label.canceled')
        case 'rejected':
          return this.$t('trading.label.rejected')
        case 'invalid':
          return this.$t('trading.label.invalid')
      }
    },
    iconClass() {
      const prefix = 'status-icon'
      switch (this.status) {
        case 'scheduled':
        case 'processing':
        case 'canceled':
          return `${prefix}--gray`
        case 'completed':
          return `${prefix}--green`
        case 'rejected':
        case 'invalid':
          return `${prefix}--red`
      }
    },
  },
})
