import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { UsersApi } from '@/api/generated'
import { WelcomeGetters } from '@/store/modules/iam/welcome/WelcomeGetters'
import {
  SET_SUBMITTING,
  WelcomeMutations,
} from '@/store/modules/iam/welcome/WelcomeMutations'
import { WelcomeState } from '@/store/modules/iam/welcome/WelcomeState'

export class WelcomeActions extends Actions<
  WelcomeState,
  WelcomeGetters,
  WelcomeMutations,
  WelcomeActions
> {
  async acceptUser(payload: { userId: string }) {
    if (this.state.submitting) {
      return
    }
    this.commit(SET_SUBMITTING, { submitting: true })

    await new UsersApi(apiConfig).activateUser(payload.userId)
    await new UsersApi(apiConfig).updateUserProfileById(payload.userId, {
      locale: this.state.locale,
    })
    this.commit(SET_SUBMITTING, { submitting: false })
  }
}
