import { Actions } from 'vuex-smart-module'

import { PublicOrganizationProfile } from '@/api/generated'
import { MultiLocaleMessageBody } from '@/models/chat/MultiLocaleMessageBody'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'
import { ChatBroadcastGetters } from '@/store/modules/chat/broadcast/ChatBroadcastGetters'
import {
  ChatBroadcastMutations,
  SET_LOCKED,
  SET_MESSAGE,
  SET_ORGANIZATION_CHANNELS,
  SET_RECIPIENT_SEARCH_INPUT,
  SET_RECIPIENT_SELECTED_LANGUAGE,
  SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
} from '@/store/modules/chat/broadcast/ChatBroadcastMutations'
import { ChatBroadcastState } from '@/store/modules/chat/broadcast/ChatBroadcastState'

export class ChatBroadcastActions extends Actions<
  ChatBroadcastState,
  ChatBroadcastGetters,
  ChatBroadcastMutations,
  ChatBroadcastActions
> {
  async loadOrganizationChannels() {
    const organizationChannels: OrganizationChannel[] = []

    // 不具合調査のため、一旦組織チャンネルの取得を行わないようにする

    this.commit(SET_ORGANIZATION_CHANNELS, { organizationChannels })
  }

  async sendNewMessage() {
    const messagePromises: Promise<number>[] = []
    this.state.organizationChannels.forEach(channel => {
      if (
        this.state.selectedOrganizationChannelIds.includes(channel.channelId)
      ) {
        const messagePromise = channel.sendMessage(
          this.state.message as string,
          true,
        )
        messagePromises.push(messagePromise)
      }
    })

    await Promise.all(messagePromises)
    if (messagePromises.length) {
      this.dispatch('resetNewMessage')
    }
  }

  async sendMultiLocaleNewMessage(
    publicOrganizationProfiles: PublicOrganizationProfile[],
  ) {
    const messagePromises: Promise<number>[] = []
    this.state.organizationChannels.forEach(channel => {
      if (
        this.state.selectedOrganizationChannelIds.includes(channel.channelId)
      ) {
        const publicOrganizationProfile = publicOrganizationProfiles.find(
          (profile: PublicOrganizationProfile) =>
            profile.organizationId === channel.profileId,
        )
        if (!publicOrganizationProfile) {
          return
        }

        const messageContent =
          this.state.message instanceof MultiLocaleMessageBody
            ? this.state.message.translationOf(publicOrganizationProfile.locale)
            : this.state.message
        const messagePromise = channel.sendMessage(messageContent, true)
        messagePromises.push(messagePromise)
      }
    })

    await Promise.all(messagePromises)
    if (messagePromises.length) {
      this.dispatch('resetNewMessage')
    }
  }

  async addSelectedOrganizationChannelIds(organizationIds: string[]) {
    const checkedValues = []
    for (const channel of this.state.organizationChannels) {
      if (!organizationIds.includes(channel.profileId)) {
        checkedValues.push(channel.channelId)
      }
    }
    this.commit(SET_SELECTED_ORGANIZATION_CHANNEL_IDS, {
      selectedOrganizationChannelIds: checkedValues,
    })
  }

  resetNewMessage() {
    this.commit(SET_MESSAGE, { message: '' })
    this.commit(SET_SELECTED_ORGANIZATION_CHANNEL_IDS, {
      selectedOrganizationChannelIds: [],
    })
    this.commit(SET_RECIPIENT_SEARCH_INPUT, {
      recipientSearchInput: '',
    })
    this.commit(SET_RECIPIENT_SELECTED_LANGUAGE, {
      recipientSelectedLanguage: undefined,
    })
    this.commit(SET_LOCKED, {
      locked: false,
    })
  }
}
