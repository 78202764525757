import { Type } from 'class-transformer'

import {
  DealWithOrganizationRecap as IDealWithOrganizationRecap,
  DealSubType,
  SpreadTypeTypeEnum,
} from '@/api/generated/api'
import { Area } from '@/models/trading/Area'
import { ContractCondition } from '@/models/trading/ContractCondition'
import { Deal } from '@/models/trading/Deal'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'

export class DealWithOrganizationRecap implements IDealWithOrganizationRecap {
  @Type(() => Deal)
  deal!: Deal

  @Type(() => ContractCondition)
  contractCondition!: ContractCondition

  @Type(() => OrganizationRecap)
  recapOfAskOrganization!: OrganizationRecap

  @Type(() => OrganizationRecap)
  recapOfBidOrganization!: OrganizationRecap

  @Type(() => OrganizationRecap)
  recapOfExchangeAskOrganization?: OrganizationRecap

  @Type(() => OrganizationRecap)
  recapOfExchangeBidOrganization?: OrganizationRecap

  createdParticipantName!: string

  createdAt!: string

  lastUpdatedParticipantName!: string

  updatedAt!: string

  get isSpread(): boolean {
    return this.deal.type === DealSubType.Spread
  }

  get isSwingOption(): boolean {
    return this.deal.price.type === 'basic-plus-volume'
  }

  get isAreaSpread(): boolean {
    return !!this.deal.spreadTypes?.find(
      spreadType => spreadType.type === SpreadTypeTypeEnum.Area,
    )
  }

  get originAreaId(): string | undefined {
    return this.recapOfAskOrganization.areaId
  }

  get destinationAreaId(): string | undefined {
    return this.recapOfExchangeAskOrganization?.areaId
  }

  get originAreaUnitPrices(): string[] {
    return (
      this.recapOfAskOrganization.unitPrices.map(unitPrice =>
        unitPrice.toString(),
      ) || []
    )
  }

  get destinationAreaUnitPrices(): string[] {
    return (
      this.recapOfAskOrganization.unitPrices.map(unitPrice =>
        unitPrice.toString(),
      ) || []
    )
  }

  originAreaName(areas: Area[]): string | undefined {
    return this.originAreaId
      ? areas
          .find(area => area.areaId === this.originAreaId)
          ?.name.translation()
      : undefined
  }

  destinationAreaName(areas: Area[]): string | undefined {
    return this.destinationAreaId
      ? areas
          .find(area => area.areaId === this.destinationAreaId)
          ?.name.translation()
      : undefined
  }
}
