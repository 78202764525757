import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67bf916f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ecloud-member" }
const _hoisted_2 = { class: "ecloud-member__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_member_form = _resolveComponent("member-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeMember')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createVNode(_component_validation_form, { class: "ecloud-member__form" }, {
          default: _withCtx(() => [
            _createVNode(_component_member_form, {
              "form-value": _ctx.formValue,
              "is-eclear-contracted": _ctx.isEclearContracted
            }, null, 8, ["form-value", "is-eclear-contracted"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_base_button, {
                class: "ecloud-member__back-button",
                onClick: _ctx.onBackClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.back')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["ui-stack"])
  ]))
}