import { z } from 'zod'

const brokingFilterAttribute = [
  'area',
  'startDeliveryYearMonth',
  'endDeliveryYearMonth',
  'hourType',
  'productType',
  'status',
  'organizationName',
  'createdParticipantName',
  'openToMarketOfFilter',
  'isDailyPowerMarket',
] as const

export const BrokingFilterAttributeSchema = z.array(
  z.enum(brokingFilterAttribute),
)
export type BrokingFilterAttribute = typeof brokingFilterAttribute[number]

export const BrokingFilterConditionSchema = z.object({
  area: z.array(z.string().uuid()),
  startDeliveryYearMonth: z.array(z.string().regex(/^\d{4}-\d{2}$/)),
  endDeliveryYearMonth: z.array(z.string().regex(/^\d{4}-\d{2}$/)),
  hourType: z.array(z.string().uuid()),
  productType: z.array(z.string().uuid()),
  status: z.array(z.string()),
  organizationName: z.array(z.string()),
  createdParticipantName: z.array(z.string()),
  openToMarketOfFilter: z.array(z.string()),
  isDailyPowerMarket: z.array(z.string()),
})

export type BrokingFilterCondition = z.infer<
  typeof BrokingFilterConditionSchema
>

export const keyOfSelectedFilters = 'selectedFiltersOfBroking'
export const keyOfSelectedFiltersCondition = 'selectedFiltersConditionOfBroking'
export const keyOfSelectedFiltersWithFuelSurcharge =
  'selectedFiltersWithFuelSurchargeOfBroking'
export const keyOfSelectedFiltersConditionWithFuelSurcharge =
  'selectedFiltersConditionWithFuelSurchargeOfBroking'

export const keyOfSelectedFiltersWithSpread =
  'selectedFiltersWithSpreadOfBroking'
export const keyOfSelectedFiltersConditionWithSpread =
  'selectedFiltersConditionWithSpreadOfBroking'
