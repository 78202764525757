import { RouteRecordRaw } from 'vue-router'

import { OcctoMasterType } from '@/api/generated'
import EsquareOrganization from '@/views/iam/EsquareOrganization.vue'
import Organizations from '@/views/iam/Organizations.vue'
import ClearingInformation from '@/views/iam/children/ClearingInformation.vue'
import EmailSetting from '@/views/iam/children/EmailSetting.vue'
import Member from '@/views/iam/children/Member.vue'
import Members from '@/views/iam/children/Members.vue'
import OcctoMaster from '@/views/iam/children/OcctoMaster.vue'
import OcctoMasterDetail from '@/views/iam/children/OcctoMasterDetail.vue'
import OcctoMasterNew from '@/views/iam/children/OcctoMasterNew.vue'
import Organization from '@/views/iam/children/Organization.vue'
import OrganizationEmailAddress from '@/views/iam/children/OrganizationEmailAddress.vue'
import OrganizationEmailAddressNew from '@/views/iam/children/OrganizationEmailAddressNew.vue'
import OrganizationEmailAddresses from '@/views/iam/children/OrganizationEmailAddresses.vue'
import RecapConfiguration from '@/views/iam/children/RecapConfiguration.vue'
import RecapReceiver from '@/views/iam/children/RecapReceiver.vue'
import RecapReceiverNew from '@/views/iam/children/RecapReceiverNew.vue'
import RecapReceivers from '@/views/iam/children/RecapReceivers.vue'

/**
 * パス: /organizations/...
 */
export const organizationsRoutes: RouteRecordRaw[] = [
  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
    meta: {
      activeMenuItem: 'organizations',
    },
  },
  {
    path: '/organizations/:organizationId',
    component: EsquareOrganization,
    props: route => ({
      organizationId: route.params.organizationId,
    }),
    children: [
      {
        path: '',
        name: 'Organization',
        component: Organization,
        props: route => ({
          organizationId: route.params.organizationId,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.detailedEsquareOrganizationProfile',
        },
      },
      {
        path: 'recap-configuration',
        component: RecapConfiguration,
        props: route => ({
          basePath: `/organizations/${route.params.organizationId}/recap-configuration`,
        }),
        children: [
          {
            path: '',
            redirect: 'recap-receivers',
          },
          {
            path: 'recap-receivers',
            name: 'RecapReceivers',
            component: RecapReceivers,
            props: route => ({
              organizationId: route.params.organizationId,
            }),
            meta: {
              activeMenuItem: 'organizations',
              titleKey: 'iam.label.recapConfiguration',
              parentName: 'RecapConfiguration',
            },
          },
          {
            path: 'clearing-information',
            name: 'ClearingInformation',
            component: ClearingInformation,
            props: route => ({
              organizationId: route.params.organizationId,
            }),
            meta: {
              activeMenuItem: 'organizations',
              titleKey: 'iam.label.recapConfiguration',
              parentName: 'RecapConfiguration',
            },
          },
          {
            path: 'occto-master',
            name: 'OcctoMaster',
            component: OcctoMaster,
            props: route => ({
              organizationId: route.params.organizationId,
            }),
            meta: {
              activeMenuItem: 'organizations',
              titleKey: 'iam.label.recapConfiguration',
              parentName: 'RecapConfiguration',
            },
          },
        ],
      },
      {
        path: 'recap-configuration/recap-receivers/new',
        name: 'RecapReceiverNew',
        component: RecapReceiverNew,
        props: route => ({
          organizationId: route.params.organizationId,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.newRecapReceiver',
          parentName: 'RecapConfiguration',
        },
      },
      {
        path: 'recap-configuration/recap-receivers/:id',
        name: 'RecapReceiver',
        component: RecapReceiver,
        props: route => ({
          organizationId: route.params.organizationId,
          id: route.params.id,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.detailedRecapReceiver',
          parentName: 'RecapConfiguration',
        },
      },
      {
        path: 'recap-configuration/occto-master/new',
        name: 'OcctoMasterNew',
        component: OcctoMasterNew,
        props: route => ({
          organizationId: route.params.organizationId,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.newOcctoMaster',
          parentName: 'RecapConfiguration',
        },
      },
      {
        path: 'recap-configuration/occto-master/balancing-groups/:id',
        name: 'OcctoMasterDemandDetail',
        component: OcctoMasterDetail,
        props: route => ({
          organizationId: route.params.organizationId,
          id: route.params.id,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.detailedOcctoMaster',
          type: OcctoMasterType.Demand,
          parentName: 'RecapConfiguration',
        },
      },
      {
        path: 'recap-configuration/occto-master/supply-plan-submitters/:id',
        name: 'OcctoMasterGenerationDetail',
        component: OcctoMasterDetail,
        props: route => ({
          organizationId: route.params.organizationId,
          id: route.params.id,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.detailedOcctoMaster',
          type: OcctoMasterType.Generation,
          parentName: 'RecapConfiguration',
        },
      },
      {
        path: 'email-setting',
        component: EmailSetting,
        props: route => ({
          basePath: `/organizations/${route.params.organizationId}/email-setting`,
        }),
        children: [
          {
            path: '',
            redirect: 'members',
          },
          {
            path: 'members',
            name: 'Members',
            component: Members,
            props: route => ({
              organizationId: route.params.organizationId,
            }),
            meta: {
              activeMenuItem: 'organizations',
              titleKey: 'iam.label.mailSetting',
              parentName: 'EmailSetting',
            },
          },
          {
            path: 'organization-email-addresses',
            name: 'OrganizationEmailAddresses',
            component: OrganizationEmailAddresses,
            props: route => ({
              organizationId: route.params.organizationId,
            }),
            meta: {
              activeMenuItem: 'organizations',
              titleKey: 'iam.label.mailSetting',
              parentName: 'EmailSetting',
            },
          },
        ],
      },
      {
        path: 'email-setting/members/:userId',
        name: 'Member',
        component: Member,
        props: route => ({
          organizationId: route.params.organizationId,
          userId: route.params.userId,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.detailedMember',
          parentName: 'EmailSetting',
        },
      },
      {
        path: 'email-setting/organization-email-addresses/new',
        name: 'OrganizationEmailAddressNew',
        component: OrganizationEmailAddressNew,
        props: route => ({
          organizationId: route.params.organizationId,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.newOrganizationEmailAddress',
          parentName: 'EmailSetting',
        },
      },
      {
        path:
          'email-setting/organization-email-addresses/:organizationEmailAddressId',
        name: 'OrganizationEmailAddress',
        component: OrganizationEmailAddress,
        props: route => ({
          organizationId: route.params.organizationId,
          organizationEmailAddressId: route.params.organizationEmailAddressId,
        }),
        meta: {
          activeMenuItem: 'organizations',
          titleKey: 'iam.label.detailedOrganizationEmailAddress',
          parentName: 'EmailSetting',
        },
      },
    ],
  },
]
