
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'

export default defineComponent({
  name: 'BaseInputPassword',
  components: {
    BaseIcon,
    BaseLabel,
    ValidationForm,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: undefined,
    },
    rules: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    mode() {
      if (this.visible) {
        return 'text'
      }
      return 'password'
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  methods: {
    onInput(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        this.$emit('input', event.target.value)
      }
    },
    onClick() {
      this.visible = !this.visible
    },
  },
})
