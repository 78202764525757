import 'reflect-metadata'
import { createGtm } from '@gtm-support/vue-gtm'
import Notification from '@kyvg/vue3-notification'
import { createApp, h } from 'vue'
import VueAxios from 'vue-axios'
import { createVfm } from 'vue-final-modal'
import VueObserveVisibility from 'vue-observe-visibility'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@vuepic/vue-datepicker/dist/main.css'

import { i18nPlugin } from '@/i18n'
import { Auth0Plugin } from '@/plugins/auth'
import axios from '@/plugins/axios'
import { TwilioChatPlugin, TwilioPluginOptions } from '@/plugins/chat'
import '@/plugins/vee-validate'
import router from '@/router'
import { store } from '@/store'
import { appEnv } from '@/utils/env'
import '@/plugins/datadog'

// eslint-disable-next-line
import App from './App.vue'

import '@/assets/sass/vendor.scss'
// eslint-disable-next-line import/no-unresolved
import 'vue-final-modal/style.css'

export const app = createApp({
  render: () => h(App),
})

app.use(i18nPlugin)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(Notification)

app.component('DynamicScroller', DynamicScroller)
// https://github.com/Akryum/vue-virtual-scroller/pull/666
app.component('DynamicScrollerItem', DynamicScrollerItem)

const vfm = createVfm()
app.use(vfm)
app.use(VueObserveVisibility)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const domain = process.env.VUE_APP_AUTH_DOMAIN!
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const clientId = process.env.VUE_APP_AUTH_CLIENT_ID!
const audience = process.env.VUE_APP_AUTH_AUDIENCE
app.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRedirectCallback: (appState: any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})

app.use<TwilioPluginOptions>(TwilioChatPlugin, {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appId: process.env.VUE_APP_FIREBASE_APP_ID!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID!,
})

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
  })
}

if (appEnv !== 'local') {
  const gtmId = process.env.VUE_APP_GTM_ID
  if (gtmId) {
    app.use(
      createGtm({
        id: gtmId,
        vueRouter: router,
      }),
    )
  }
}

app.mount('#app')
