import { i18n } from '@/i18n'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { ContractCondition } from '@/models/trading/ContractCondition'
import { Deal } from '@/models/trading/Deal'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import {
  DeliverySummaries as DeliverySummariesModel,
  DeliverySummaryWithCalendarTypeName,
} from '@/models/trading/DeliverySummaries'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'
import {
  convertDate,
  convertDeliveryTermsAnnex,
  convertDisplayDeliveryPeriod,
  convertDisplayFee,
  convertWithNone,
  convertProductName,
  convertRecapDetail,
  convertReceiverInfo,
  filterReceiveMemberEmails,
  filterReceiveOrganizationMemberEmails,
  FooterMail,
  FooterPhoneNumber,
  getRecapTitle,
  isNonStandard,
  RECAP_ORDER_ASK,
  RECAP_ORDER_ASK_SPREAD,
  RECAP_ORDER_BID,
  RECAP_ORDER_BID_SPREAD,
  RecapEndpointType,
  SERVICE,
  convertQuantity,
  MAX_DIGIT,
  forceShowNote,
} from '@/store/modules/trading/recap/helper'
import {
  DataDetail,
  RecapCreatedOption,
  RecapDetail,
  RecapStatus,
  SwapBilateral,
} from '@/store/modules/trading/recap/interface'

const convertUnitPrice = (prices: number[]): string => {
  if (!prices.length) {
    return ''
  }
  if (prices.length > 1) {
    return i18n.t('trading.label.recapSeeAppendix')
  }

  return `${prices[0]} ${i18n.t('trading.label.recapUnitPriceUnitWithoutTax')}`
}

const convertSwapBilateralDataDetail = (
  userName: string,
  sellerInfo: string[],
  buyerInfo: string[],
  deal: Deal,
  deliveryTerms: DeliveryTerms,
  contractCondition: ContractCondition,
  organizationRecap: OrganizationRecap,
  recapCreatedOptions: RecapCreatedOption,
  deliverySummaryWithCalendarTypeName?: DeliverySummaryWithCalendarTypeName,
): SwapBilateral => {
  return {
    publicationDate: {
      text: convertDate(new Date().toDateString()),
    },
    organizationName: {
      text: `${organizationRecap.organizationName.translation()} ${i18n.t(
        'common.label.dear',
      )}`,
    },
    dealDetails: {
      items: {
        executionDate: {
          text: [convertDate(deal.executedAt)],
        },
        transactionId: {
          text: [organizationRecap.publicRecapId],
        },
        seller: {
          text: sellerInfo,
        },
        buyer: {
          text: buyerInfo,
        },
        product: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteProduct,
              convertProductName(deal, deliveryTerms),
            ),
          ],
        },
        deliveryPeriod: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteDeliveryPeriod,
              convertDisplayDeliveryPeriod(
                deal.product,
                deal.product.deliveryTerms,
              ),
            ),
          ],
        },
        unitPrice: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteUnitPrice,
              convertUnitPrice(organizationRecap.unitPrices),
            ),
          ],
        },
        quantity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteVolume,
              convertQuantity(organizationRecap.volumes),
            ),
          ],
        },
        totalContractElectricity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteTotalContractElectricity,
              deliverySummaryWithCalendarTypeName?.deliverySummary
                .totalDeliveryVolumes
                ? `${(
                    deliverySummaryWithCalendarTypeName?.deliverySummary
                      .totalDeliveryVolumes * 1000
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: MAX_DIGIT,
                  })} ${i18n.t('trading.label.kwh')}`
                : '',
            ),
          ],
        },
        transactionConditions: {
          text: [contractCondition.remarks || ''],
        },
        paymentDueDate: {
          text: [contractCondition.paymentDueInformation || ''],
        },
        brokerageUnitPrice: {
          text: [convertDisplayFee(organizationRecap.brokingFee)],
        },
        note: {
          text: [convertWithNone(contractCondition.note)],
        },
      },
    },
    footer: {
      footer: {
        from: userName,
        mail: FooterMail.ENECHAIN,
        phone: FooterPhoneNumber.ENECHAIN,
      },
    },
  }
}

export const convertSwapBilateralData = (
  userName: string,
  data: DealWithOrganizationRecap,
  summary: DeliverySummariesModel,
  askOrganizationMembers: MemberProfile[],
  bidOrganizationMembers: MemberProfile[],
  userOrganizationMembers: MemberProfile[],
  askOrganization: Organization,
  bidOrganization: Organization,
  askOrganizationEmailAddresses: OrganizationEmailAddress[],
  bidOrganizationEmailAddresses: OrganizationEmailAddress[],
  userOrganizationEmailAddresses: OrganizationEmailAddress[],
  recapCreatedOptions: RecapCreatedOption,
): RecapDetail[] => {
  const {
    deal,
    contractCondition,
    recapOfAskOrganization,
    recapOfBidOrganization,
    recapOfExchangeAskOrganization,
    recapOfExchangeBidOrganization,
  } = data
  const base = {
    name: getRecapTitle(data),
    templateEndpoint: RecapEndpointType.SWAP_BILATERAL,
    status: RecapStatus.NONE,
    service: SERVICE,
  }

  const askDetails: DataDetail[] = [
    convertSwapBilateralDataDetail(
      userName,
      convertReceiverInfo(
        recapOfAskOrganization.organizationName.translation(),
        recapOfAskOrganization.recapReceiver,
      ),
      convertReceiverInfo(
        recapOfBidOrganization.organizationName.translation(),
        recapOfBidOrganization.recapReceiver,
      ),
      deal,
      deal.product.deliveryTerms,
      contractCondition,
      recapOfAskOrganization,
      recapCreatedOptions,
      summary.base,
    ),
  ]
  if (
    !recapCreatedOptions.excludeAnnex &&
    isNonStandard(recapOfAskOrganization.product) &&
    (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents.length > 1)
  ) {
    askDetails.push(convertDeliveryTermsAnnex(deal, recapOfAskOrganization))
  }

  const bidDetails: DataDetail[] = [
    convertSwapBilateralDataDetail(
      userName,
      convertReceiverInfo(
        recapOfAskOrganization.organizationName.translation(),
        recapOfAskOrganization.recapReceiver,
      ),
      convertReceiverInfo(
        recapOfBidOrganization.organizationName.translation(),
        recapOfBidOrganization.recapReceiver,
      ),
      deal,
      deal.product.deliveryTerms,
      contractCondition,
      recapOfBidOrganization,
      recapCreatedOptions,
      summary.base,
    ),
  ]
  if (
    !recapCreatedOptions.excludeAnnex &&
    isNonStandard(recapOfBidOrganization.product) &&
    (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents.length > 1)
  ) {
    bidDetails.push(convertDeliveryTermsAnnex(deal, recapOfBidOrganization))
  }

  const recapDetails: RecapDetail[] = [
    convertRecapDetail(
      {
        ...base,
        identifier: recapOfAskOrganization.publicRecapId,
        organization: recapOfAskOrganization.organizationId,
        organizationName: data.recapOfAskOrganization.organizationName.translationOf(
          askOrganization.locale || 'en',
        ),
        orderNumber: RECAP_ORDER_ASK,
        language: askOrganization.locale,
        userMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfAskOrganization.product.productType,
              askOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfAskOrganization.product.productType,
              askOrganizationEmailAddresses,
            ),
          ],
          cc: [FooterMail.ENECHAIN],
          bcc: [],
        },
        operatorMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfAskOrganization.product.productType,
              userOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfAskOrganization.product.productType,
              userOrganizationEmailAddresses,
            ),
          ],
          cc: [],
          bcc: [],
        },
      },
      ...askDetails,
    ),
    convertRecapDetail(
      {
        ...base,
        identifier: recapOfBidOrganization.publicRecapId,
        organization: recapOfBidOrganization.organizationId,
        organizationName: data.recapOfBidOrganization.organizationName.translationOf(
          bidOrganization.locale || 'en',
        ),
        orderNumber: RECAP_ORDER_BID,
        language: bidOrganization.locale,
        userMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfBidOrganization.product.productType,
              bidOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfBidOrganization.product.productType,
              bidOrganizationEmailAddresses,
            ),
          ],
          cc: [FooterMail.ENECHAIN],
          bcc: [],
        },
        operatorMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfBidOrganization.product.productType,
              userOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfBidOrganization.product.productType,
              userOrganizationEmailAddresses,
            ),
          ],
          cc: [],
          bcc: [],
        },
      },
      ...bidDetails,
    ),
  ]

  if (
    deal.product.exchangeDeliveryTerms &&
    recapOfExchangeAskOrganization &&
    recapOfExchangeBidOrganization
  ) {
    const exchangeAskDetails: DataDetail[] = [
      convertSwapBilateralDataDetail(
        userName,
        convertReceiverInfo(
          recapOfExchangeBidOrganization.organizationName.translation(),
          recapOfExchangeBidOrganization.recapReceiver,
        ),
        convertReceiverInfo(
          recapOfExchangeAskOrganization.organizationName.translation(),
          recapOfExchangeAskOrganization.recapReceiver,
        ),
        deal,
        deal.product.exchangeDeliveryTerms,
        contractCondition,
        recapOfExchangeAskOrganization,
        recapCreatedOptions,
        summary.exchange,
      ),
    ]
    if (
      !recapCreatedOptions.excludeAnnex &&
      isNonStandard(recapOfExchangeAskOrganization.product) &&
      (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
        deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
          .deliveryPatternComponents.length > 1)
    ) {
      exchangeAskDetails.push(
        convertDeliveryTermsAnnex(deal, recapOfExchangeAskOrganization),
      )
    }

    const exchangeBidDetails: DataDetail[] = [
      convertSwapBilateralDataDetail(
        userName,
        convertReceiverInfo(
          recapOfExchangeBidOrganization.organizationName.translation(),
          recapOfExchangeBidOrganization.recapReceiver,
        ),
        convertReceiverInfo(
          recapOfExchangeAskOrganization.organizationName.translation(),
          recapOfExchangeAskOrganization.recapReceiver,
        ),
        deal,
        deal.product.exchangeDeliveryTerms,
        contractCondition,
        recapOfExchangeBidOrganization,
        recapCreatedOptions,
        summary.exchange,
      ),
    ]
    if (
      !recapCreatedOptions.excludeAnnex &&
      isNonStandard(recapOfExchangeBidOrganization.product) &&
      (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
        deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
          .deliveryPatternComponents.length > 1)
    ) {
      exchangeBidDetails.push(
        convertDeliveryTermsAnnex(deal, recapOfExchangeBidOrganization),
      )
    }

    recapDetails.push(
      convertRecapDetail(
        {
          ...base,
          identifier: recapOfExchangeAskOrganization.publicRecapId,
          organization: recapOfExchangeAskOrganization.organizationId,
          organizationName: recapOfExchangeAskOrganization.organizationName.translationOf(
            askOrganization.locale || 'en',
          ),
          orderNumber: RECAP_ORDER_ASK_SPREAD,
          language: askOrganization.locale,
          userMail: {
            to: [],
            cc: [],
            bcc: [],
          },
          operatorMail: {
            to: [],
            cc: [],
            bcc: [],
          },
        },
        ...exchangeAskDetails,
      ),
    )

    recapDetails.push(
      convertRecapDetail(
        {
          ...base,
          identifier: recapOfExchangeBidOrganization.publicRecapId,
          organization: recapOfExchangeBidOrganization.organizationId,
          organizationName: recapOfExchangeBidOrganization.organizationName.translationOf(
            bidOrganization.locale || 'en',
          ),
          orderNumber: RECAP_ORDER_BID_SPREAD,
          language: bidOrganization.locale,
          userMail: {
            to: [],
            cc: [],
            bcc: [],
          },
          operatorMail: {
            to: [],
            cc: [],
            bcc: [],
          },
        },
        ...exchangeBidDetails,
      ),
    )
  }

  return recapDetails
}
