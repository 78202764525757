
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'

export default defineComponent({
  name: 'UserSetting',
  components: {
    BaseBox,
    BaseButton,
    BaseInputRadio,
    BaseLabel,
    ValidationForm,
  },
  props: {
    localeSubmitting: {
      type: Boolean,
      required: true,
    },
    localeUpdateButtonDisabled: {
      type: Boolean,
      required: true,
    },
    displaySettingUpdateButtonDisabled: {
      type: Boolean,
      required: true,
    },
    displaySettingSubmitting: {
      type: Boolean,
      required: true,
    },
    isEsquareEnabled: {
      type: Boolean,
      required: true,
    },
    locale: {
      type: String,
      default: undefined,
    },
    displaySetting: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: [
    'locale-input',
    'locale-update-click',
    'position-setting-input',
    'color-setting-input',
    'display-setting-update-click',
  ],
  computed: {
    positionSetting(): string {
      return this.displaySetting.includes('positionReversed')
        ? 'positionReversed'
        : 'positionNormal'
    },
    colorSetting(): string {
      return this.displaySetting.includes('colorReversed')
        ? 'colorReversed'
        : 'colorNormal'
    },
  },
  methods: {
    onLocaleInput(event: string) {
      this.$emit('locale-input', event)
    },
    onLocaleUpdateClick() {
      this.$emit('locale-update-click')
    },
    onPositionSettingInput(event: string) {
      this.$emit('position-setting-input', event)
    },
    onColorSettingInput(event: Event) {
      this.$emit('color-setting-input', event)
    },
    onDisplaySettingUpdateClick() {
      this.$emit('display-setting-update-click')
    },
  },
})
