import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b8df777"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eclear-member-list" }
const _hoisted_2 = { class: "eclear-member-list__header" }
const _hoisted_3 = { class: "eclear-member-list__center" }
const _hoisted_4 = { class: "eclear-member-list__center" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "eclear-member-list__center" }
const _hoisted_10 = { class: "eclear-member-list__center" }
const _hoisted_11 = ["title"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "eclear-member-list__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('iam.label.name')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('iam.label.mail')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('iam.label.phoneNumber')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('iam.label.mobilePhoneNumber')), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('iam.label.eclearTradingAuthority')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('iam.label.eclearDemandAndSupplyPlanner')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('iam.label.eclearTraderId')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('iam.label.eclearKeyword')), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, index) => {
      return (_openBlock(), _createBlock(_component_base_box, {
        key: index,
        spacing: "8px 16px",
        class: "eclear-member-list__row"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "eclear-member-list__column",
            title: member.name
          }, _toDisplayString(member.name), 9, _hoisted_5),
          _createElementVNode("div", {
            class: "eclear-member-list__column",
            title: member.mail
          }, _toDisplayString(member.mail), 9, _hoisted_6),
          _createElementVNode("div", {
            class: "eclear-member-list__column",
            title: member.phoneNumber
          }, _toDisplayString(member.phoneNumber), 9, _hoisted_7),
          _createElementVNode("div", {
            class: "eclear-member-list__column",
            title: member.mobilePhoneNumber
          }, _toDisplayString(member.mobilePhoneNumber), 9, _hoisted_8),
          _createElementVNode("div", _hoisted_9, [
            (member.isEclearTrader)
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 0,
                  "icon-name": "check"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (member.isEclearDemandAndSupplyPlanner)
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 0,
                  "icon-name": "check"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "eclear-member-list__column",
            title: member.eclearTraderId
          }, _toDisplayString(member.eclearTraderId), 9, _hoisted_11),
          _createElementVNode("div", {
            class: "eclear-member-list__keyword",
            onClick: ($event: any) => (_ctx.onKeywordClick(index))
          }, [
            (_ctx.maskedKeywordList[index])
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.maskKeyword(member.eclearKeyword)), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(member.eclearKeyword), 1)
                ], 64))
          ], 8, _hoisted_12),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_base_button, {
              color: "danger",
              onClick: ($event: any) => (_ctx.onDelete(index))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.label.delete')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}