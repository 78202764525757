
import moment from 'moment'
import { defineComponent, PropType } from 'vue'

import { IndicationStatusType } from '@/api/generated'
import BrokingOrderBookItem from '@/components/trading/BrokingOrderBookItem.vue'
import BrokingOrderBookListHeader from '@/components/trading/BrokingOrderBookListHeader.vue'
import { BrokingOrderBookItemProps } from '@/components/trading/interface/BrokingOrderBookItemProps'
import {
  generateMapsByDeliveryTerms,
  generateId,
} from '@/components/trading/services/generateMaps'
import { productSorter } from '@/components/trading/services/productSorter'
import { User } from '@/models/iam/User'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'

export default defineComponent({
  name: 'BrokingOrderBookList',
  components: {
    BrokingOrderBookItem,
    BrokingOrderBookListHeader,
  },
  props: {
    indications: {
      type: Array as PropType<BrokingIndication[]>,
      default: () => [],
    },
    selectedIndicationIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    targetIndicationId: {
      type: String,
      default: undefined,
    },
    deals: {
      type: Array as PropType<BrokingDeal[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    productTypeFilterCondition: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    statusTypes: {
      type: Array as PropType<IndicationStatusType[]>,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    withFuelSurcharge: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'change',
    'activate',
    'deactivate',
    'open-to-market-change',
    'visible-to-traders-change',
    'targeting',
    'update-indication',
    'record-select',
    'new-message',
    'copy-clipboard',
    'copy-indication',
    'mode-change',
  ],
  data(): {
    unexpandedIdsMap: { [key: string]: string[] }
  } {
    return {
      unexpandedIdsMap: {
        false: [], // 燃調なし
        true: [], // 燃調あり
      },
    }
  },
  computed: {
    items(): BrokingOrderBookItemProps[] {
      const { deliveryTermsMap, indicationMap } = generateMapsByDeliveryTerms({
        indications: this.indications,
        deals: this.deals,
        productTypeFilterCondition: this.productTypeFilterCondition,
      })

      const brokingOrderBookItems: BrokingOrderBookItemProps[] = Array.from(
        deliveryTermsMap,
      ).map(([deliveryTermsIdAndFuelSurchargeId, deliveryTerms]) => {
        const filterDeal = this.deals.filter(
          deal =>
            generateId(
              deal.product.deliveryTermsId,
              deal.fuelSurchargeType?.id,
            ) === deliveryTermsIdAndFuelSurchargeId,
        )
        const deal =
          filterDeal.length > 1
            ? filterDeal.reduce((deal, changeDeal) =>
                moment(changeDeal.createdAt).isAfter(deal.createdAt)
                  ? changeDeal
                  : deal,
              )
            : filterDeal[0]
        return {
          deliveryTerms: deliveryTerms.deliveryTerms,
          deliveryUnit: deliveryTerms.deliveryUnit,
          indications: indicationMap.get(deliveryTermsIdAndFuelSurchargeId),
          deal,
          users: this.users,
          isExpanded: !this.unexpandedIdsMap[
            this.withFuelSurcharge.toString()
          ].includes(deliveryTermsIdAndFuelSurchargeId),
        }
      })

      brokingOrderBookItems.sort(productSorter)

      return brokingOrderBookItems
    },
    allCollapsed(): boolean {
      return (
        this.unexpandedIdsMap[this.withFuelSurcharge.toString()].length ===
        this.items.length
      )
    },
  },
  methods: {
    onExpand(event: { id: string }) {
      const ids = this.unexpandedIdsMap[this.withFuelSurcharge.toString()]
      if (ids.includes(event.id)) {
        ids.splice(
          ids.findIndex(id => id === event.id),
          1,
        )
      } else {
        ids.push(event.id)
      }
    },
    onRecordSelect(event: Event) {
      this.$emit('record-select', event)
    },
    onNewMessage(event: Event) {
      this.$emit('new-message', event)
    },
    onCopyClipboard(event: Event) {
      this.$emit('copy-clipboard', event)
    },
    onCopyIndication(event: Event) {
      this.$emit('copy-indication', event)
    },
    onModeChange() {
      this.$emit('mode-change')
      if (
        !this.isEditMode &&
        this.unexpandedIdsMap[this.withFuelSurcharge.toString()].length === 0
      ) {
        this.onActivateAll()
      }
    },
    onActivateAll() {
      this.unexpandedIdsMap[this.withFuelSurcharge.toString()] = []
    },
    onDeactivateAll() {
      this.unexpandedIdsMap[
        this.withFuelSurcharge.toString()
      ] = this.items.map(item =>
        generateId(
          item.deliveryTerms.id,
          (item.indications || [])[0]?.fuelSurchargeType?.id ||
            item.deal?.fuelSurchargeType?.id,
        ),
      )
    },
  },
})
