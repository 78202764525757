import { createI18n, VueI18n } from 'vue-i18n'

export const i18nPlugin = createI18n({
  locale: 'ja',
  messages: {
    ja: require('./locales/ja.json'),
    en: require('./locales/en.json'),
  },
})

export const i18n = (i18nPlugin.global as unknown) as VueI18n
