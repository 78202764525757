
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'
import { LocalizedReport as LocalizedReportModel } from '@/models/reports/LocalizedReport'

export default defineComponent({
  name: 'LocalizedReportListItem',
  components: {
    BaseBox,
    BaseIcon,
    BaseTooltip,
    Heading,
    Sentences,
  },
  props: {
    report: {
      type: Object as PropType<LocalizedReportModel>,
      required: true,
    },
  },
  emits: ['item-click', 'download-click'],
  methods: {
    onItemClick() {
      this.$emit('item-click', this.report.id)
    },
    onDownloadClick() {
      this.$emit('download-click', {
        reportId: this.report.id,
        reportContentId: this.report.reportContent.id,
        attachmentName: this.report.reportContent.attachmentName,
      })
    },
  },
})
