
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { NavItemCategoryItem } from '@/components/common/interface/CollapsedNavigation/NavItemCategoryProps'

export default defineComponent({
  name: 'NavItemCategory',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    categories: {
      type: Array as PropType<NavItemCategoryItem[]>,
      default() {
        return []
      },
    },
  },
})
