
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DeliveryTerms',
  props: {
    areaName: {
      type: String,
      required: true,
    },
    deliveryStart: {
      type: String,
      required: true,
    },
    deliveryEnd: {
      type: String,
      required: true,
    },
    hourTypeName: {
      type: String,
      required: true,
    },
  },
})
