
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import EclearMemberList from '@/components/iam/EclearMemberList.vue'
import EclearTradingMemberSelectorModal from '@/components/iam/EclearTradingMemberSelectorModal.vue'
import OrganizationHeadline from '@/components/iam/OrganizationHeadline.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MemberModule } from '@/store/modules/iam/member'
import { OrganizationProfileModule } from '@/store/modules/iam/organizationProfile'
import { UsersModule } from '@/store/modules/iam/users'
import { setNotification } from '@/utils/utils'

const {
  mapState: organizationProfileMapState,
  mapActions: organizationProfileMapActions,
} = createNamespacedHelpers('organizationProfile') as OrganizationProfileModule

const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

const { mapActions: usersMapActions } = createNamespacedHelpers(
  'users',
) as UsersModule

export default defineComponent({
  name: 'EclearOrganization',
  components: {
    BaseAlert,
    BaseButton,
    BaseIcon,
    BaseLink,
    BaseModal,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    EclearMemberList,
    EclearTradingMemberSelectorModal,
    OrganizationHeadline,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    eclearMembers: MemberProfile[]
    nonEclearMembers: MemberProfile[]
    selectedMemberUserIdForDelete: string | undefined
    submitting: boolean
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      eclearMembers: [],
      nonEclearMembers: [],
      selectedMemberUserIdForDelete: undefined,
      submitting: false,
    }
  },
  computed: {
    ...organizationProfileMapState(['organizationProfile']),
    titleKey(): string {
      return (this.$route.meta?.titleKey as string | undefined) || ''
    },
    activeMenuItem(): string | undefined {
      return this.$route.meta?.activeMenuItem as string | undefined
    },
    ecloudOrganizationUrl(): string {
      return `/ecloud-organizations/${this.organizationId}`
    },
  },
  async created() {
    await Promise.all([
      this.fetchEclearMembersAndNonEclearMembers(),
      this.fetchOrganizationProfile({
        organizationId: this.organizationId,
      }),
    ]).catch(e => {
      this.uiStack = UiStack.Error
      throw e
    })
  },
  methods: {
    ...organizationProfileMapActions(['fetchOrganizationProfile']),
    ...memberMapActions(['fetchEclearMembers', 'fetchNonEclearMembers']),
    ...usersMapActions(['updateUserById']),
    async fetchEclearMembersAndNonEclearMembers() {
      await Promise.all([
        this.fetchEclearMembers({
          organizationId: this.organizationId,
        }) as Promise<MemberProfile[]>,
        this.fetchNonEclearMembers({
          organizationId: this.organizationId,
        }) as Promise<MemberProfile[]>,
      ])
        .then(([eclearMembers, nonEclearMembers]) => {
          this.eclearMembers = eclearMembers
          this.nonEclearMembers = nonEclearMembers
          this.uiStack = UiStack.Ideal
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    onAddMemberButtonClick() {
      this.$vfm.open('add-member')
    },
    async onAddMemberSelect(selectedUserId: string) {
      try {
        this.submitting = true
        await this.updateEclearSetting(selectedUserId, true)
        await this.fetchEclearMembersAndNonEclearMembers()
        setNotification(this.$t('iam.message.successUpdateMember').toString())
      } catch (e) {
        await this.fetchEclearMembersAndNonEclearMembers()
        setNotification(this.$t('iam.message.failUpdateMember').toString())
        throw e
      } finally {
        this.submitting = false
        this.$vfm.close('add-member')
      }
    },
    onAddMemberCancel() {
      this.$vfm.close('add-member')
    },
    onDeleteMember(userId: string) {
      this.selectedMemberUserIdForDelete = userId
      this.$vfm.open('delete-member')
    },
    async onConfirmDeleteMember() {
      try {
        this.submitting = true
        if (this.selectedMemberUserIdForDelete !== undefined) {
          await this.updateEclearSetting(
            this.selectedMemberUserIdForDelete,
            false,
          )
          await this.fetchEclearMembersAndNonEclearMembers()
          setNotification(this.$t('iam.message.successUpdateMember').toString())
        } else {
          setNotification(this.$t('iam.message.failUpdateMember').toString())
        }
      } catch (e) {
        await this.fetchEclearMembersAndNonEclearMembers()
        setNotification(this.$t('iam.message.failUpdateMember').toString())
        throw e
      } finally {
        this.selectedMemberUserIdForDelete = undefined
        this.submitting = false
        this.$vfm.close('delete-member')
      }
    },
    onCancelDeleteMember() {
      this.selectedMemberUserIdForDelete = undefined
      this.$vfm.close('delete-member')
    },
    async updateEclearSetting(userId: string, eclearEnabled: boolean) {
      await this.updateUserById({
        userId: userId,
        user: {
          services: {
            eclear: {
              appSetting: {
                isTrader: eclearEnabled,
              },
            },
          },
        },
      })
    },
  },
})
