
import { defineComponent, PropType } from 'vue'

import { Locale, SpreadTypeTypeEnum } from '@/api/generated'
import IndicationConfirmModalContent from '@/components/trading/IndicationConfirmModalContent.vue'
import ProductForm from '@/components/trading/ProductForm.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { ProductType } from '@/models/trading/ProductType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'

export default defineComponent({
  name: 'MyIndicationForm',
  components: {
    IndicationConfirmModalContent,
    ProductForm,
  },
  props: {
    formValue: {
      type: Object as PropType<ProductFormProps>,
      required: true,
    },
    mode: {
      type: Number,
      required: true,
      validator: (value: IndicationFormInputMode) => {
        return Object.values(IndicationFormInputMode).includes(value)
      },
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    selectableInputs: {
      type: Object as PropType<BaseStandardIndicationSelectableInputs>,
      required: true,
    },
    openedSpreadFormTypes: {
      type: Array as PropType<SpreadTypeTypeEnum[]>,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    productTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
    dateNames: {
      type: Array as PropType<ProductTypeDateName[]>,
      required: true,
    },
  },
  emits: [
    'form-input',
    'text-input',
    'standard-delivery-terms-form-open',
    'standard-delivery-terms-form-close',
  ],
  methods: {
    onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      this.$emit('standard-delivery-terms-form-open', type)
    },
    onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      this.$emit('standard-delivery-terms-form-close', type)
    },
  },
})
