import { Type } from 'class-transformer'

import {
  Locale,
  PublicOrganizationProfile as IPublicOrganizationProfile,
} from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class PublicOrganizationProfile implements IPublicOrganizationProfile {
  organizationId!: string
  locale!: Locale
  accountId?: string
  abbreviation?: string

  @Type(() => MultiLocaleName)
  name!: MultiLocaleName

  @Type(() => MultiLocaleName)
  tradingCompanyName!: MultiLocaleName

  picture!: string
}
