import { User as TwilioUser } from '@twilio/conversations'
import cloneDeep from 'lodash/cloneDeep'
import { Mutations } from 'vuex-smart-module'

import { ActiveChannel } from '@/models/chat/ActiveChannel'
import { ActiveChannelWindow } from '@/models/chat/ActiveChannelWindow'
import { ChannelList } from '@/models/chat/ChannelList'
import { Message } from '@/models/chat/Message'
import { ChatState } from '@/store/modules/chat/chat/ChatState'

export const SET_CHANNEL_LIST = 'SET_CHANNEL_LIST'
export const SET_ACTIVE_CHANNEL = 'SET_ACTIVE_CHANNEL'
export const ADD_ACTIVE_CHANNEL_WINDOW = 'ADD_ACTIVE_CHANNEL_WINDOW'
export const REMOVE_ACTIVE_CHANNEL_WINDOW = 'REMOVE_ACTIVE_CHANNEL_WINDOW'
export const ADD_ACTIVE_CHANNEL_MESSAGES = 'ADD_ACTIVE_CHANNEL_MESSAGES'
export const ADD_ACTIVE_CHANNEL_WINDOW_MESSAGES =
  'ADD_ACTIVE_CHANNEL_WINDOW_MESSAGES'
export const SET_ACTIVE_CHANNEL_ANCHOR = 'SET_ACTIVE_CHANEL_ANCHOR'
export const SET_ACTIVE_CHANNEL_WINDOW_ANCHOR =
  'SET_ACTIVE_CHANNEL_WINDOW_ANCHOR'
export const SET_CHAT_INPUT_VALUE = 'SET_CHAT_INPUT_VALUE'
export const SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE =
  'SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE'
export const SET_ACTIVE_CHANNEL_WINDOW_IS_OPENED =
  'SET_ACTIVE_CHANNEL_WINDOW_IS_OPENED'
export const SET_CHANNEL_LIST_WINDOW_IS_OPENED =
  'SET_CHANNEL_LIST_WINDOW_IS_OPENED'
export const SET_DM_CHANNEL_USER_INSTANCE = 'SET_DM_CHANNEL_USER_INSTANCE'
export const SET_TYPING_USER_IDS = 'SET_TYPING_USER_IDS'
export const SET_ACTIVE_CHANNEL_WINDOW_TYPING_USER_IDS =
  'SET_ACTIVE_CHANNEL_WINDOW_TYPING_USER_IDS'
export const SET_MESSAGE_LOADING = 'SET_MESSAGE_LOADING'
export const SET_ACTIVE_CHANNEL_WINDOW_MESSAGE_LOADING =
  'SET_ACTIVE_CHANNEL_WINDOW_MESSAGE_LOADING'
export const SET_IS_FETCHING_CHANNEL_LIST = 'SET_IS_FETCHING_CHANNEL_LIST'
export const SET_BROADCAST_INDEX = 'SET_BROADCAST_INDEX'
export const SET_STARRED_CHANNEL_IDS = 'SET_STARRED_CHANNEL_IDS'
export const INCREMENT_CHANNEL_COUNTER = 'INCREMENT_CHANNEL_COUNTER'

export const mergeMessages = (
  existingMessages: Message[],
  newMessages: Message[],
) => {
  if (!existingMessages) {
    return newMessages
  }

  const mergedMessages = cloneDeep(existingMessages)
  for (const message of newMessages) {
    if (
      existingMessages.every(
        existingMessage => existingMessage.messageId !== message.messageId,
      )
    ) {
      mergedMessages.push(message)
    }
  }

  mergedMessages.sort((a, b) => a.index - b.index)

  return mergedMessages
}

export class ChatMutations extends Mutations<ChatState> {
  [SET_CHANNEL_LIST](payload: { channelList: ChannelList }) {
    this.state.channelList = payload.channelList
  }

  [SET_ACTIVE_CHANNEL](payload: { activeChannel: ActiveChannel }) {
    this.state.activeChannel = payload.activeChannel
  }

  [ADD_ACTIVE_CHANNEL_WINDOW](payload: {
    activeChannelWindow: ActiveChannelWindow
  }) {
    this.state.activeChannelWindowList.add(payload.activeChannelWindow)
  }

  [REMOVE_ACTIVE_CHANNEL_WINDOW](payload: { channelId: string }) {
    this.state.activeChannelWindowList.remove(payload.channelId)
  }

  [ADD_ACTIVE_CHANNEL_MESSAGES](payload: { messages: Message[] }) {
    if (this.state.activeChannel) {
      this.state.activeChannel.messages = mergeMessages(
        this.state.activeChannel.messages || [],
        payload.messages,
      )
    }
  }

  [ADD_ACTIVE_CHANNEL_WINDOW_MESSAGES](payload: {
    channelId: string
    messages: Message[]
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      activeChannel.messages = mergeMessages(
        activeChannel.messages || [],
        payload.messages,
      )
    }
  }

  [SET_ACTIVE_CHANNEL_ANCHOR](payload: { anchor: number }) {
    if (this.state.activeChannel) {
      this.state.activeChannel.anchor = payload.anchor
    }
  }

  [SET_ACTIVE_CHANNEL_WINDOW_ANCHOR](payload: {
    channelId: string
    anchor: number
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      activeChannel.anchor = payload.anchor
    }
  }

  [SET_CHAT_INPUT_VALUE](payload: { chatInputValue: string }) {
    if (this.state.activeChannel) {
      this.state.activeChannel.chatInputValue = payload.chatInputValue
    }
  }

  [SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE](payload: {
    channelId: string
    chatInputValue: string
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      activeChannel.chatInputValue = payload.chatInputValue
    }
  }

  [SET_MESSAGE_LOADING](payload: { messageLoading: boolean }) {
    this.state.activeChannel.messageLoading = payload.messageLoading
  }

  [SET_ACTIVE_CHANNEL_WINDOW_MESSAGE_LOADING](payload: {
    channelId: string
    messageLoading: boolean
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      activeChannel.messageLoading = payload.messageLoading
    }
  }

  [SET_ACTIVE_CHANNEL_WINDOW_IS_OPENED](payload: {
    channelId: string
    isOpened: boolean
  }) {
    const activeChannelWindow = this.state.activeChannelWindowList.activeChannelWindowOfChannelId(
      payload.channelId,
    )
    if (activeChannelWindow) {
      activeChannelWindow.isOpened = payload.isOpened
    }
  }

  [SET_CHANNEL_LIST_WINDOW_IS_OPENED](payload: { isOpened: boolean }) {
    this.state.channelListWindowIsOpened = payload.isOpened
  }

  [SET_DM_CHANNEL_USER_INSTANCE](payload: { userInstance: TwilioUser }) {
    if (this.state.channelList) {
      const channel = this.state.channelList.dMChannels.find(
        channel =>
          channel.userInstance.identity === payload.userInstance.identity,
      )
      if (channel) {
        channel.userInstance = payload.userInstance
      }
    }
  }

  [SET_TYPING_USER_IDS](payload: { typingUserIds: string[] }) {
    if (this.state.activeChannel) {
      this.state.activeChannel.typingUserIds = payload.typingUserIds
      this.state.activeChannel.typingUserIds.splice(
        this.state.activeChannel.typingUserIds.length,
      )
    }
  }

  [SET_ACTIVE_CHANNEL_WINDOW_TYPING_USER_IDS](payload: {
    channelId: string
    typingUserIds: string[]
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      activeChannel.typingUserIds = payload.typingUserIds
    }
  }

  [SET_IS_FETCHING_CHANNEL_LIST](payload: { isFetchingChannelList: boolean }) {
    this.state.isFetchingChannelList = payload.isFetchingChannelList
  }

  [SET_BROADCAST_INDEX](payload: { channelId: string; index: number }) {
    const channel = this.state.channelList.organizationChannels.find(
      channel => {
        return channel.channelId === payload.channelId
      },
    )
    if (channel) {
      channel.appendBroadcastIndex(payload.index)
    }
  }

  [SET_STARRED_CHANNEL_IDS](payload: { starredChannelIds: string[] }) {
    this.state.starredChannelIds = payload.starredChannelIds
  }

  [INCREMENT_CHANNEL_COUNTER](payload: { channelId: string }) {
    const channel = this.state.channelList.organizationChannels.find(
      channel => {
        return channel.channelId === payload.channelId
      },
    )
    if (channel) {
      channel.counter += 1
    }
  }
}
