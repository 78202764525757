import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4babea6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-indication-metadata" }
const _hoisted_2 = { class: "my-indication-metadata__status" }
const _hoisted_3 = { class: "my-indication-metadata__status-text" }
const _hoisted_4 = { class: "my-indication-metadata__created-participant" }
const _hoisted_5 = { class: "my-indication-metadata__created-participant-name-text" }
const _hoisted_6 = { class: "my-indication-metadata__last-updated-participant" }
const _hoisted_7 = { class: "my-indication-metadata__last-updated-participant-name-text" }
const _hoisted_8 = { class: "my-indication-metadata__created" }
const _hoisted_9 = { class: "my-indication-metadata__created-at-text" }
const _hoisted_10 = { class: "my-indication-metadata__updated-at" }
const _hoisted_11 = { class: "my-indication-metadata__updated-at-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_my_indication_status = _resolveComponent("my-indication-status")!
  const _component_base_picture = _resolveComponent("base-picture")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_label, {
        class: "my-indication-metadata__status-label",
        label: _ctx.$t('trading.label.status')
      }, null, 8, ["label"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.statusText), 1),
      _createVNode(_component_my_indication_status, {
        class: "my-indication-metadata__status-icon",
        status: _ctx.myIndication.status
      }, null, 8, ["status"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_label, {
        class: "my-indication-metadata__created-participant-name-label",
        label: _ctx.$t('trading.label.createdParticipantName')
      }, null, 8, ["label"]),
      _createVNode(_component_base_picture, {
        class: "my-indication-metadata__created-participant-picture",
        url: _ctx.createdParticipantPicture,
        width: 25
      }, null, 8, ["url"]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.createdParticipantName), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_label, {
        class: "my-indication-metadata__last-updated-participant-name-label",
        label: _ctx.$t('trading.label.lastUpdatedParticipantName')
      }, null, 8, ["label"]),
      _createVNode(_component_base_picture, {
        class: "my-indication-metadata__last-updated-participant-picture",
        url: _ctx.lastUpdatedParticipantPicture,
        width: 25
      }, null, 8, ["url"]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.lastUpdatedParticipantName), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_base_label, {
        class: "my-indication-metadata__created-at-label",
        label: _ctx.$t('trading.label.createdAt')
      }, null, 8, ["label"]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.createdAt), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_base_label, {
        class: "my-indication-metadata__updated-at-label",
        label: _ctx.$t('trading.label.updatedAt')
      }, null, 8, ["label"]),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.updatedAt), 1)
    ])
  ]))
}