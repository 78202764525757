import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eb3ddc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-single-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_single_select, {
      class: "filter-single-select__selector",
      name: _ctx.name,
      value: _ctx.value,
      options: _ctx.options,
      placeholder: _ctx.$t('common.message.selectPlaceholder', { name: _ctx.name }),
      size: _ctx.size,
      onInput: _ctx.onSelect
    }, null, 8, ["name", "value", "options", "placeholder", "size", "onInput"]),
    _createVNode(_component_base_icon, {
      class: "filter-single-select__close",
      "icon-name": "times-circle",
      onClick: _ctx.onClick
    }, null, 8, ["onClick"])
  ]))
}