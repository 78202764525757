import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01fe3ad6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-deal-list" }
const _hoisted_2 = {
  key: 0,
  class: "my-deal-list__header-wrapper"
}
const _hoisted_3 = { class: "my-deal-list__body-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_deal_list_header = _resolveComponent("my-deal-list-header")!
  const _component_my_deal_component = _resolveComponent("my-deal-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_my_deal_list_header)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myDeals, (myDeal) => {
        return (_openBlock(), _createBlock(_component_my_deal_component, {
          key: myDeal.dealId,
          "my-deal": myDeal,
          locale: _ctx.locale,
          "color-reversed": _ctx.colorReversed
        }, null, 8, ["my-deal", "locale", "color-reversed"]))
      }), 128))
    ])
  ]))
}