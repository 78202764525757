import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19890ea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eclear-setting" }
const _hoisted_2 = { class: "eclear-setting__form" }
const _hoisted_3 = { class: "eclear-setting__row" }
const _hoisted_4 = {
  key: 0,
  class: "eclear-setting__checked"
}
const _hoisted_5 = { class: "eclear-setting__row" }
const _hoisted_6 = {
  key: 0,
  class: "eclear-setting__checked"
}
const _hoisted_7 = { class: "eclear-setting__row" }
const _hoisted_8 = { class: "eclear-setting__row" }
const _hoisted_9 = { class: "eclear-setting__keyword-caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_input_password = _resolveComponent("base-input-password")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_base_dataset, {
              label: _ctx.$t('iam.label.eclearTradingAuthority').toString()
            }, {
              default: _withCtx(() => [
                (_ctx.formValue.isEclearTrader)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _createVNode(_component_base_icon, { "icon-name": "check" })
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.blank')), 1)
                    ], 64))
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_base_dataset, {
              label: _ctx.$t('iam.label.eclearDemandAndSupplyPlanner').toString()
            }, {
              default: _withCtx(() => [
                (_ctx.formValue.isDemandAndSupplyPlanner)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createVNode(_component_base_icon, { "icon-name": "check" })
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.blank')), 1)
                    ], 64))
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          (_ctx.formValue.isEclearTrader)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_base_dataset, {
                    label: _ctx.$t('iam.label.eclearTraderId').toString(),
                    data: _ctx.formValue.eclearTraderId
                  }, null, 8, ["label", "data"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_base_label, {
                    label: _ctx.$t('iam.label.eclearKeyword').toString()
                  }, null, 8, ["label"]),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('iam.message.explanationOfEclearKeyword')), 1),
                  _createVNode(_component_base_input_password, {
                    name: "keyword",
                    value: _ctx.formValue.keyword,
                    rules: "required|eclearKeyword",
                    onInput: _cache[0] || (_cache[0] = 
                event => _ctx.$emit('form-input', { ..._ctx.formValue, keyword: event })
              )
                  }, null, 8, ["value"])
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}