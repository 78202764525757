<template>
  <span class="base-badge" @click="$emit('click')">
    <span :class="className" :style="style">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'BaseBadge',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    borderColor: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
  },
  emits: ['click'],
  computed: {
    className() {
      return `base-badge__badge--${this.type}`
    },
    style() {
      let style = {}
      if (this.borderColor !== undefined) {
        style = {
          ...style,
          borderColor: this.borderColor,
        }
      }
      if (this.color !== undefined) {
        style = {
          ...style,
          color: this.color,
        }
      }
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
%common {
  @extend %text-small;
  display: inline-block;
  line-height: 1.5;
  border-radius: 4px;
  padding: 2px 8px;
  border: 1px solid #7b7b7b;
}
.base-badge {
  &__badge--default {
    @extend %common;
    background-color: #7b7b7b;
    color: #ffffff;
  }
  &__badge--outline {
    @extend %common;
    background-color: transparent;
    color: #7b7b7b;
  }
}
</style>
