
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import RecapReceiverListItem from '@/components/iam/RecapReceiverListItem.vue'
import { RecapReceiver } from '@/models/iam/RecapReceiver'
import { RecapReceiverModule } from '@/store/modules/iam/recapReceiver'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapActions: recapReceiverMapActions } = createNamespacedHelpers(
  'recapReceiver',
) as RecapReceiverModule

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'RecapReceivers',
  components: {
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    ConfirmationDialog,
    RecapReceiverListItem,
    UiStackSelector,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType.Error
    infoType: AlertType.Info
    deleteModalName: string
    recapReceivers: RecapReceiver[]
    deleteTargetInformation?: {
      id: string
      name: string
    }
    isReceiverBlank: boolean
    deleteSubmitting: boolean
    updateSubmitting: boolean
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      infoType: AlertType.Info,
      deleteModalName: 'deleteConfirmModal',
      recapReceivers: [],
      deleteTargetInformation: undefined,
      isReceiverBlank: false,
      deleteSubmitting: false,
      updateSubmitting: false,
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    deleteTargetName(): string {
      return this.deleteTargetInformation?.name || ''
    },
    basePath(): string {
      return this.$route.path.replace(/\/[^/]+\/?$/, '')
    },
  },
  watch: {
    async '$route.params.organizationId'() {
      await this.initialize()
    },
  },
  async created() {
    await this.initialize()
  },
  methods: {
    fetchRecapReceivers: recapReceiverMapActions(['fetchRecapReceivers'])
      .fetchRecapReceivers as (
      organizationId: string,
    ) => Promise<RecapReceiver[]>,
    ...recapReceiverMapActions(['deleteRecapReceiver']),
    async initialize() {
      this.uiStack = UiStack.Loading
      await this.loadRecapReceivers()
        .then(() => {
          this.isReceiverBlank = this.recapReceivers.length === 0
          this.uiStack = UiStack.Ideal
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    async loadRecapReceivers() {
      await this.fetchRecapReceivers(this._organizationId).then(
        recapReceivers => {
          this.recapReceivers = recapReceivers
        },
      )
    },
    onAddRecapReceiverClick() {
      this.$router.push(`${this.basePath}/recap-receivers/new`)
    },
    onDeleteClick(target: RecapReceiver) {
      this.deleteTargetInformation = {
        id: target.id,
        name: target.name,
      }
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteRecapReceiverCancel() {
      this.$vfm.close(this.deleteModalName)
      this.deleteTargetInformation = undefined
    },
    async onDeleteRecapReceiverConfirm() {
      if (!this.deleteTargetInformation) {
        return
      }
      this.deleteSubmitting = true
      await this.deleteRecapReceiver({
        organizationId: this._organizationId,
        recapReceiverId: this.deleteTargetInformation.id,
      })
        .then(async () => {
          setNotification(
            this.$t('iam.message.successDeleteRecapReceiver').toString(),
          )
          this.$vfm.close(this.deleteModalName)
          this.deleteTargetInformation = undefined
          this.uiStack = UiStack.Loading
          await this.loadRecapReceivers()
            .then(() => {
              this.uiStack = UiStack.Ideal
            })
            .catch(() => {
              this.uiStack = UiStack.Error
            })
        })
        .catch(e => {
          setNotification(
            this.$t('iam.message.failDeleteRecapReceiver').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
  },
})
