import { Getters } from 'vuex-smart-module'

import { OrderBookState } from '@/store/modules/trading/orderBook/OrderBookState'
import { FetchOrderBooksParams } from '@/store/modules/trading/orderBook/interface'

export class OrderBookGetters extends Getters<OrderBookState> {
  get spreadFilterParams(): FetchOrderBooksParams {
    const separator = ','
    const {
      areaIds,
      startDeliveryYearMonths,
      endDeliveryYearMonths,
      hourTypeIds,
      productTypeIds,
    } = this.state.filterConditionWithSpread
    return {
      areaIds: areaIds.join(separator),
      startDeliveryYearMonths: startDeliveryYearMonths.join(separator),
      endDeliveryYearMonths: endDeliveryYearMonths.join(separator),
      hourTypeIds: hourTypeIds.join(separator),
      productTypeIds: productTypeIds.join(separator),
    }
  }
}
