import { AssignedBrokerOfOrganization as IAssignedBrokerOfOrganization } from '@/api/generated'

export class AssignedBrokerOfOrganization
  implements IAssignedBrokerOfOrganization {
  id!: string
  organizationId!: string
  brokerId!: string
  createdAt!: string
  updatedAt!: string
}
