
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { OcctoMasterType } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import { UiStack } from '@/components/common/constants/UiStack'
import OcctoMasterItemForm from '@/components/iam/OcctoMasterItemForm.vue'
import { OcctoMasterItemFormProps } from '@/components/iam/interface/OcctoMasterItemFormProps'
import { OcctoMasterModule } from '@/store/modules/iam/occtoMaster'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { ProductModule } from '@/store/modules/trading/product'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapActions: occtoMasterMapActions } = createNamespacedHelpers(
  'occtoMaster',
) as OcctoMasterModule

const {
  mapState: productMapState,
  mapActions: productMapActions,
} = createNamespacedHelpers('product') as ProductModule

const {
  mapGetters: userProfileMapGetters,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule

export default defineComponent({
  name: 'OcctoMasterNew',
  components: {
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    OcctoMasterItemForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    isLoading: boolean
    formValue: OcctoMasterItemFormProps
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      isLoading: false,
      formValue: {
        type: OcctoMasterType.Demand,
        companyPrefix: '',
        area: undefined,
        name: '',
        code: '',
      },
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['areas']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
  },
  async created() {
    await this.fetchAreas()
      .then(() => {
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...occtoMasterMapActions([
      'createBalancingGroup',
      'createSupplyPlanSubmitter',
    ]),
    ...productMapActions(['fetchAreas']),
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    onFormInput(newValue: OcctoMasterItemFormProps) {
      Object.assign(this.formValue, newValue)
    },
    async onClick() {
      if (
        !this.formValue.companyPrefix ||
        !this.formValue.area ||
        !this.formValue.name ||
        !this.formValue.code
      ) {
        return
      }
      this.isLoading = true
      const payload = {
        companyPrefix: this.formValue.companyPrefix,
        areaId: this.formValue.area.value,
        name: this.formValue.name,
        code: this.formValue.code,
      }
      const request =
        this.formValue.type === OcctoMasterType.Generation
          ? this.createSupplyPlanSubmitter({
              organizationId: this._organizationId,
              supplyPlanSubmitter: payload,
            })
          : this.createBalancingGroup({
              organizationId: this._organizationId,
              balancingGroup: payload,
            })
      await request
        .then(() => {
          setNotification(
            this.$t('iam.message.successCreateOcctoMaster').toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          const message =
            e.response.status === HttpStatus.Conflict
              ? this.formValue.type === OcctoMasterType.Generation
                ? this.$t(
                    'iam.message.failCreateSupplyPlanSubmitterByDuplicate',
                    {
                      code: this.formValue.code,
                    },
                  ).toString()
                : this.$t('iam.message.failCreateBalancingGroupByDuplicate', {
                    area: this.formValue.area?.label,
                  }).toString()
              : this.$t('iam.message.failCreateOcctoMaster').toString()
          setNotification(message, 'danger')
          throw e
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
})
