
import VueDatePicker from '@vuepic/vue-datepicker'
import { Field, ErrorMessage } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import { InputMode } from '@/components/common/constants/InputMode'

export default defineComponent({
  name: 'BaseDatepicker',
  components: {
    BaseButton,
    BaseIcon,
    BaseLabel,
    ErrorMessage,
    Field,
    VueDatePicker,
  },
  props: {
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    rules: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    minDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    maxDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    unselectableDaysOfWeek: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    selectableDates: {
      type: Array as PropType<Date[]>,
      default: () => [],
    },
    focusedDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    help: {
      type: String,
      default: '',
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY].includes(value),
      default: InputMode.INPUT,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data(): {
    date?: Date
    monday: 1
    touched: boolean
  } {
    return {
      date: undefined,
      monday: 1,
      touched: false,
    }
  },
  computed: {
    localeUnicode(): string {
      return this.locale === Locale.Ja ? 'ja-JP' : 'en-US'
    },
    readonly(): boolean {
      return this.mode === InputMode.READONLY
    },
    labelString(): string | undefined {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
    yearRange(): [number, number] {
      const today = new Date()
      const min =
        this.minDate?.getFullYear() ??
        this.selectableDates[0]?.getFullYear() ??
        today.getFullYear() - 10
      const max = this.maxDate?.getFullYear() ?? today.getFullYear() + 10
      return [min, max]
    },
  },
  watch: {
    value() {
      this.setValue(this.value)
    },
    minDate() {
      this.clearValuesIfInvalid()
    },
    maxDate() {
      this.clearValuesIfInvalid()
    },
    unselectableDaysOfWeek() {
      this.clearValuesIfInvalid()
    },
  },
  created() {
    const value = this.value
    this.setValue(value)
  },
  methods: {
    setValue(value?: Date) {
      if (value instanceof Date) {
        this.date = new Date(value)
      } else {
        this.date = undefined
      }
    },
    onInput(value?: Date) {
      this.$emit('input', value)
      this.touched = true
    },
    clearValue() {
      this.date = undefined
      this.$emit('input', undefined)
      this.touched = true
    },
    clearValuesIfInvalid() {
      if (!this.value) {
        return
      }
      if (
        (this.minDate !== undefined && this.value < this.minDate) ||
        (this.maxDate !== undefined && this.value > this.maxDate) ||
        this.unselectableDaysOfWeek.includes(this.value.getDay())
      ) {
        this.clearValue()
      }
    },
  },
})
