import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30ac7529"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status-toggle-button" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.allInactive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "list-toggle-button__text",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onActivateAll && _ctx.onActivateAll(...args)))
          }, _toDisplayString(_ctx.activateAllLabel), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", {
            class: "list-toggle-button__text",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDeactivateAll && _ctx.onDeactivateAll(...args)))
          }, _toDisplayString(_ctx.deactivateAllLabel), 1)
        ]))
  ]))
}