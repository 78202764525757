
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { Locale, ReportType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputCheckbox from '@/components/common/BaseInputCheckbox.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import ReportContentForm from '@/components/reports/ReportContentForm.vue'
import ReportContentList from '@/components/reports/ReportContentList.vue'
import { ReportContentFormInput } from '@/components/reports/interface/ReportContentFormInput'
import { ReportsModule } from '@/store/modules/reports/reports'
import { ReportCreatePayload } from '@/store/modules/reports/reports/ReportsActions'
import { setNotification } from '@/utils/utils'

const { mapActions: reportsMapActions } = createNamespacedHelpers(
  'reports',
) as ReportsModule

export default defineComponent({
  name: 'ReportNew',
  components: {
    ActionDropdown,
    BaseButton,
    BaseInputCheckbox,
    BaseLabel,
    BaseModal,
    BrokerPage,
    ConfirmationDialog,
    ReportContentForm,
    ReportContentList,
    ValidationForm,
  },
  data(): {
    locale: typeof Locale
    reportContentModalName: string
    publishReportModalName: string
    currentLocale: Locale
    values: {
      reportTypes: string[]
      reportContents: {
        [_ in Locale]: ReportContentFormInput
      }
    }
    reportTypeOptions: InputOption[]
    actionDropdownIsActive: boolean
    publishReportSubmitting: boolean
  } {
    return {
      locale: Locale,
      reportContentModalName: 'reportContent',
      publishReportModalName: 'publishReport',
      currentLocale: Locale.Ja,
      values: {
        reportTypes: [],
        reportContents: {
          [Locale.Ja]: {
            title: undefined,
            summary: undefined,
            selectedFile: undefined,
          },
          [Locale.En]: {
            title: undefined,
            summary: undefined,
            selectedFile: undefined,
          },
        },
      },
      reportTypeOptions: [
        {
          value: ReportType.Power,
          label: this.$t('reports.label.power').toString(),
        },
        {
          value: ReportType.Gas,
          label: this.$t('reports.label.gas').toString(),
        },
      ],
      actionDropdownIsActive: false,
      publishReportSubmitting: false,
    }
  },
  computed: {
    reportContentModalTitle(): string {
      const displayLanguage = {
        [Locale.Ja]: this.$t('common.label.japanese'),
        [Locale.En]: this.$t('common.label.english'),
      }[this.currentLocale]
      return `${this.$t('reports.label.reportContent')} (${displayLanguage})`
    },
    actionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('common.label.delete').toString(),
          eventName: 'delete-report-content-click',
          disabled: !this.isValidContentOf(this.currentLocale),
        },
      ]
    },
    hasValidContent(): boolean {
      return (
        this.isValidContentOf(Locale.Ja) || this.isValidContentOf(Locale.En)
      )
    },
    payload(): ReportCreatePayload {
      const payload: ReportCreatePayload = {
        reportTypes: this.values.reportTypes,
        reportContents: {},
        attachmentFileJa: this.values.reportContents[Locale.Ja].selectedFile,
        attachmentFileEn: this.values.reportContents[Locale.En].selectedFile,
      }
      const jaContent = this.values.reportContents[Locale.Ja]
      if (jaContent.title && jaContent.summary && jaContent.selectedFile) {
        payload.reportContents[Locale.Ja] = {
          title: jaContent.title,
          description: jaContent.summary,
        }
      }
      const enContent = this.values.reportContents[Locale.En]
      if (enContent.title && enContent.summary && enContent.selectedFile) {
        payload.reportContents[Locale.En] = {
          title: enContent.title,
          description: enContent.summary,
        }
      }
      return payload
    },
  },
  methods: {
    ...reportsMapActions(['createReport']),
    onInputReportTypes(reportTypes: string[]) {
      this.values.reportTypes = reportTypes
    },
    openReportContentModal(locale: Locale) {
      this.currentLocale = locale
      this.$vfm.open(this.reportContentModalName)
    },
    async backListPage() {
      await this.$router.push('/reports')
    },
    async onCancelClick() {
      await this.backListPage()
    },
    async onPublishReportClick() {
      this.$vfm.open(this.publishReportModalName)
    },
    async onPublishReportCancel() {
      this.$vfm.close(this.publishReportModalName)
    },
    async onPublishReportConfirm() {
      this.publishReportSubmitting = true
      await this.createReport(this.payload)
        .then(async () => {
          setNotification(
            this.$t('reports.message.successPublishReport').toString(),
          )
          await this.backListPage()
        })
        .catch(() => {
          setNotification(
            this.$t('reports.message.failPublishReport').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.$vfm.close(this.publishReportModalName)
          this.publishReportSubmitting = false
        })
    },
    onConfirmReportContentClick(
      reportContentFormInput: ReportContentFormInput,
    ) {
      this.values.reportContents[this.currentLocale] = reportContentFormInput
      this.hideReportContentModal()
    },
    onDeleteReportContentClick() {
      this.values.reportContents[this.currentLocale] = {
        title: undefined,
        summary: undefined,
        selectedFile: undefined,
      }
      this.hideReportContentModal()
    },
    isValidContentOf(locale: Locale): boolean {
      return !!this.payload.reportContents[locale]
    },
    hideReportContentModal() {
      this.actionDropdownIsActive = false
      this.$vfm.close(this.reportContentModalName)
    },
  },
})
