
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale } from '@/api/generated'
import BrokingOrderBookDetailItem from '@/components/trading/BrokingOrderBookDetailItem.vue'
import OldDeliveryTerms from '@/components/trading/OldDeliveryTerms.vue'
import { BrokingOrderBookDetailProps } from '@/components/trading/interface/BrokingOrderBookDetailProps'
import { User } from '@/models/iam/User'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndicationOfProduct } from '@/models/trading/BrokingIndicationOfProduct'
import { DeliveryTerms as DeliveryTermsModel } from '@/models/trading/DeliveryTerms'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'

const recordTypesSorter = (recordTypeA: string, recordTypeB: string) => {
  if (recordTypeA === 'ask-indication' || recordTypeB === 'deal') {
    return -1
  }
  return 1
}

export default defineComponent({
  name: 'BrokingOrderBookDetail',
  components: {
    BrokingOrderBookDetailItem,
    OldDeliveryTerms,
  },
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTermsModel>,
      required: true,
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
    indications: {
      type: Array as PropType<BrokingIndicationOfProduct[]>,
      default: () => [],
    },
    deal: {
      type: Object as PropType<BrokingDeal>,
      default: undefined,
    },
    statusTypes: {
      type: Array as PropType<IndicationStatusType[]>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    selectedIndicationIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    targetIndicationId: {
      type: String,
      default: undefined,
    },
  },
  emits: [
    'activate',
    'deactivate',
    'open-to-market-change',
    'visible-to-traders-change',
    'change',
    'targeting',
    'update-indication',
    'record-select',
    'new-message',
    'copy-clipboard',
    'copy-indication',
  ],
  computed: {
    orderBookRecords(): BrokingOrderBookDetailProps[] {
      const result: BrokingOrderBookDetailProps[] = []
      for (const indication of this.indications) {
        result.push({
          product: indication.product,
          recordType:
            indication.position === 'ask' ? 'ask-indication' : 'bid-indication',
          price: indication.price,
          unitPrice: indication.price.energyUnitPrices[0],
          volume: indication.volume,
          fuelSurchargeType: indication.fuelSurchargeType,
          status: indication.status,
          organizationName: indication.publicOrganizationProfile.name.translation(),
          organizationAbbreviation:
            indication.publicOrganizationProfile.abbreviation,
          createdParticipantName: this.users.find(
            user => user.userId === indication.createdParticipantId,
          )?.name,
          selected: this.selectedIndicationIds.includes(
            indication.indicationId,
          ),
          indicationId: indication.indicationId,
          openToMarket: indication.openToMarket,
          isVisibleToTraders: indication.isVisibleToTraders,
          isEclearIndication: indication.isEclearIndication,
          commandType: 'indication',
          createdAt: indication.displayCreatedAt(
            this.$t('common.format.formatDate').toString(),
          ),
          updatedAt: indication.displayUpdatedAt(
            this.$t('common.format.formatDate').toString(),
          ),
          lastUpdatedParticipantName: this.users.find(
            user => user.userId === indication.lastUpdatedParticipantId,
          )?.name,
          publicInformation: indication.publicInformation,
          maskVolume: indication.maskVolume,
        })
      }

      if (this.deal) {
        // 組織名にはdealの両者を表示する
        const dealOrgName =
          this.deal.sellerAccountName.translation() +
          ', ' +
          this.deal.buyerAccountName.translation()
        result.push({
          product: this.deal.product,
          recordType: 'deal',
          price: this.deal.price,
          unitPrice: this.deal.unitPrice,
          volume: this.deal.volume,
          fuelSurchargeType: this.deal.fuelSurchargeType,
          status: undefined,
          organizationName: dealOrgName,
          organizationAbbreviation: dealOrgName,
          createdParticipantName: '',
          dealId: this.deal.dealId,
          // TODO: /broking-deal のエンドポイント改修時に deal.openToMarket に書き換える
          openToMarket: !this.deal.privateConfidential,
          createdAt: this.deal.displayCreatedAt(),
          commandType: 'deal',
          maskVolume: this.deal.maskVolume,
        })
      }

      result.sort(
        (a, b) =>
          b.unitPrice - a.unitPrice ||
          (b.price.basicUnitPrice?.unitPrice ?? 0) -
            (a.price.basicUnitPrice?.unitPrice ?? 0) ||
          recordTypesSorter(a.recordType, b.recordType),
      )

      return result
    },
  },
  methods: {
    onRecordSelect(indicationId: string) {
      this.$emit('record-select', indicationId)
    },
    onNewMessage(indicationId: string) {
      this.$emit('new-message', indicationId)
    },
    onCopyClipboard(indicationId: string) {
      this.$emit('copy-clipboard', indicationId)
    },
    onCopyIndication(indicationId: string) {
      this.$emit('copy-indication', indicationId)
    },
  },
})
