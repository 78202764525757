
import { defineComponent, PropType } from 'vue'

import ChatInput from '@/components/chat/ChatInput.vue'
import HintComment from '@/components/chat/HintComment.vue'
import TypingIndicator from '@/components/chat/TypingIndicator.vue'

export default defineComponent({
  name: 'ChatInputWrapper',
  components: {
    ChatInput,
    HintComment,
    TypingIndicator,
  },
  props: {
    chatInputValue: {
      type: String,
      default: '',
    },
    chatInputRows: {
      type: Number,
      default: 1,
    },
    typingUserNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input', 'typing', 'send'],
  computed: {
    display(): boolean {
      return this.chatInputValue.trim().length > 2
    },
  },
  methods: {
    onInput(event: Event) {
      this.$emit('input', event)
    },
    onTyping() {
      this.$emit('typing')
    },
    onSend() {
      this.$emit('send')
    },
  },
})
