
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseChatWindow',
  props: {
    isOpened: {
      type: Boolean,
      default: true,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  emits: ['title-bar-click'],
  data() {
    return {
      titleBarHeight: 44,
    }
  },
  computed: {
    heightComputed(): number | undefined {
      if (this.isOpened) {
        return this.height
      }
      return this.titleBarHeight
    },
    styleVars(): object {
      return {
        // TODO: 高さの指定方法を見直し要
        '--body-height': this.isOpened
          ? `${(this.heightComputed as number) - this.titleBarHeight}px`
          : undefined,
        '--title-bar-height': `${this.titleBarHeight}px`,
      }
    },
  },
  methods: {
    onTitleBarClick() {
      this.$emit('title-bar-click')
    },
  },
})
