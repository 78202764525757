import { Type } from 'class-transformer'

import { Locale } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export abstract class EntityAttribute {
  @Type(() => MultiLocaleName)
  name!: MultiLocaleName

  displayPriority!: number

  abstract get id(): string

  translationOf(locale: Locale): string {
    return this.name.translationOf(locale)
  }

  translation(): string {
    return this.name.translation()
  }

  toInputOption(locale?: Locale): InputOption {
    return locale
      ? { value: this.id, label: this.translationOf(locale) }
      : { value: this.id, label: this.translation() }
  }
}
