import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "email-setting" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_horizontal_tab = _resolveComponent("horizontal-tab")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_horizontal_tab, {
      "tab-list": _ctx.tabList,
      "current-tab-index": _ctx.currentTabIndex,
      "is-child": _ctx.isChild
    }, null, 8, ["tab-list", "current-tab-index", "is-child"]),
    _createVNode(_component_router_view)
  ]))
}