
import sanitizeHtml from 'sanitize-html'
import { defineComponent } from 'vue'

import Sentences from '@/components/common/Typography/Sentences.vue'

export default defineComponent({
  name: 'MessageBody',
  components: {
    Sentences,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
  },
  computed: {
    styleVars(): object {
      return {
        '--font-size': this.size === 'regular' ? '14px' : '10px',
      }
    },
    compiledMessage(): string {
      const regexpUrl = /https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+/g
      return sanitizeHtml(
        this.message.replace(regexpUrl, (url: string) => {
          return `<a target="_blank" rel="noopener noreferrer" href="${url}">${url}</a>`
        }),
      )
    },
  },
})
