
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale } from '@/api/generated'
import OldDeliveryTerms from '@/components/trading/OldDeliveryTerms.vue'
import OrderBookBestPrice from '@/components/trading/OrderBookBestPrice.vue'
import { DeliveryTerms as DeliveryTermsModel } from '@/models/trading/DeliveryTerms'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndicationOfProduct } from '@/models/trading/PublicIndicationOfProduct'

export default defineComponent({
  name: 'PublicOrderBookSummary',
  components: {
    OldDeliveryTerms,
    OrderBookBestPrice,
  },
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTermsModel>,
      required: true,
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
    indications: {
      type: Array as PropType<PublicIndicationOfProduct[]>,
      default: () => [],
    },
    deal: {
      type: Object as PropType<PublicDeal>,
      default: undefined,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  computed: {
    fuelSurchargeTypeName(): string | undefined {
      const fuelSurchargeType =
        this.indications[0]?.fuelSurchargeType || this.deal?.fuelSurchargeType
      return fuelSurchargeType?.name.translation()
    },
  },
})
