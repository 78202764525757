
import debounce from 'lodash/debounce'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { Organization } from '@/models/iam/Organization'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { isAbortError } from '@/utils/error/AbortError'

const { mapActions: organizationMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

export default defineComponent({
  name: 'Organizations',
  components: {
    BaseAlert,
    BaseBox,
    BaseInputText,
    BasePicture,
    BrokerPage,
    CenteredLoadingContent,
    UiStackSelector,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    organizations: Organization[]
    searchInput: string
    currentAbortController: AbortController
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      organizations: [] as Organization[],
      searchInput: '',
      currentAbortController: new AbortController(),
    }
  },
  async created() {
    await this.loadOrganizations()
  },
  methods: {
    fetchOrganizations: organizationMapActions(['fetchOrganizations'])
      .fetchOrganizations as (payload: {
      organizationName: string
      isEsquareEnabled?: boolean
      abortController?: AbortController
    }) => Promise<Organization[]>,
    async loadOrganizations() {
      this.uiStack = UiStack.Loading
      this.currentAbortController.abort()
      this.currentAbortController = new AbortController()
      await this.fetchOrganizations({
        organizationName: this.searchInput,
        isEsquareEnabled: true,
        abortController: this.currentAbortController,
      })
        .then(organizations => {
          this.organizations = organizations
          this.uiStack =
            this.organizations.length === 0 ? UiStack.Blank : UiStack.Ideal
        })
        .catch(e => {
          if (isAbortError(e)) {
            return
          }
          this.uiStack = UiStack.Error
          throw e
        })
    },
    debouncedLoadOrganizations: debounce(function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      this.loadOrganizations()
    }, 750),
    onInputText(text: string) {
      this.searchInput = text
      this.uiStack = UiStack.Loading
      this.debouncedLoadOrganizations()
    },
    async onClickListItem(organizationId: string) {
      await this.$router.push(`/organizations/${organizationId}`)
    },
  },
})
