import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-115aab61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eex-postings" }
const _hoisted_2 = { class: "eex-postings__content-header-wrapper" }
const _hoisted_3 = { class: "eex-postings__header" }
const _hoisted_4 = { class: "eex-postings" }
const _hoisted_5 = { class: "eex-postings__list" }
const _hoisted_6 = {
  key: 0,
  class: "eex-postings__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_list_header = _resolveComponent("list-header")!
  const _component_posting_list = _resolveComponent("posting-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_eex_posting = _resolveComponent("eex-posting")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.eexPostings'),
    "active-menu-item": "eexPostings",
    "layout-mode": _ctx.layoutMode
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_base_button, {
              color: "primary",
              onClick: _ctx.newEEXPostingClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, { "icon-name": "paper-plane" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.label.newEEXPosting')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
            loading: _withCtx(() => [
              _createVNode(_component_centered_loading_content, { type: _ctx.grayscale }, null, 8, ["type"])
            ]),
            blank: _withCtx(() => [
              _createVNode(_component_base_alert, { type: _ctx.alertTypeInfo }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
                  name: _ctx.$t('common.label.eexPostings'),
                })), 1)
                ]),
                _: 1
              }, 8, ["type"])
            ]),
            error: _withCtx(() => [
              _createVNode(_component_base_alert, { type: _ctx.alertTypeError }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.message.failFetch', {
                  name: _ctx.$t('common.label.eexPostings'),
                })), 1)
                ]),
                _: 1
              }, 8, ["type"])
            ]),
            ideal: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_list_header),
                _createVNode(_component_posting_list, {
                  "eex-postings": _ctx.eexPostings,
                  locale: _ctx.userProfile.locale,
                  onLastItemVisible: _ctx.onLastItemVisible,
                  onOnRepostClick: _ctx.onRepostClick
                }, null, 8, ["eex-postings", "locale", "onLastItemVisible", "onOnRepostClick"]),
                (!_ctx.isLoaded)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_centered_loading_content, { type: _ctx.grayscale }, null, 8, ["type"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["ui-stack"])
        ])
      ]),
      _createVNode(_component_base_modal, {
        name: _ctx.modalName,
        title: _ctx.$t('trading.label.newEEXPosting'),
        width: 1096
      }, {
        default: _withCtx(() => [
          _createVNode(_component_eex_posting, {
            "initial-mode": _ctx.eexPostingFormInputMode,
            "eex-posting": _ctx.selectedEEXPosting,
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vfm.close(_ctx.modalName)))
          }, null, 8, ["initial-mode", "eex-posting"])
        ]),
        _: 1
      }, 8, ["name", "title"])
    ]),
    _: 1
  }, 8, ["title", "layout-mode"]))
}