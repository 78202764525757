import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7092b36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eclear-users" }
const _hoisted_2 = { class: "eclear-organizations__filter" }
const _hoisted_3 = { class: "eclear-users__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_organization_body = _resolveComponent("organization-body")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('common.label.eclearOrganizations'),
    "active-menu-item": "eclear-organizations"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_input_text, {
            label: _ctx.$t('common.label.search'),
            name: "search",
            value: _ctx.searchInput,
            onInput: _ctx.onInputText
          }, null, 8, ["label", "value", "onInput"])
        ]),
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
              name: _ctx.$t('common.label.eclearOrganizations'),
            })), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          blank: _withCtx(() => [
            _createVNode(_component_base_alert, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.notExists', {
              name: _ctx.$t('common.label.eclearOrganizations'),
            })), 1)
              ]),
              _: 1
            })
          ]),
          ideal: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations, (organization, index) => {
                return (_openBlock(), _createBlock(_component_organization_body, {
                  key: index,
                  picture: organization.picture,
                  name: organization.name.translation(),
                  onClick: ($event: any) => (_ctx.onOrganizationClick(organization))
                }, null, 8, ["picture", "name", "onClick"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}