import { Type } from 'class-transformer'

import { Locale, MultiLocaleName as IMultiLocaleName } from '@/api/generated'
import { i18n } from '@/i18n'
import { Translation } from '@/models/common/Translation'

export class MultiLocaleName implements IMultiLocaleName {
  @Type(() => Translation)
  translations!: Translation[]

  translationOf(locale: Locale): string {
    const translation = this.translations.find(translation =>
      translation.has(locale),
    )
    if (translation) {
      return translation.text
    }

    return (
      this.translations.find(translation => translation.has(Locale.Ja))?.text ||
      this.translations.find(translation => translation.has(Locale.En))?.text ||
      ''
    )
  }

  translation(): string {
    return this.translationOf(i18n.locale as Locale)
  }
}
