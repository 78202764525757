import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-935d6f3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!
  const _component_dynamic_scroller_item = _resolveComponent("dynamic-scroller-item")!
  const _component_dynamic_scroller = _resolveComponent("dynamic-scroller")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dynamic_scroller, {
      ref: "scroller",
      class: "message-list__scroller",
      items: _ctx.messages,
      "min-item-size": 42,
      "key-field": "messageId",
      "emit-update": true,
      buffer: 0,
      onUpdate: _ctx.onUpdate
    }, {
      default: _withCtx(({ item, index, active }) => [
        _createVNode(_component_dynamic_scroller_item, {
          item: item,
          active: active,
          "size-dependencies": [item.message],
          index: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_message, _mergeProps(item, {
              class: "message-list__message",
              size: _ctx.size,
              "picture-size": _ctx.pictureSize,
              onReadMessage: _ctx.onReadMessage
            }), null, 16, ["size", "picture-size", "onReadMessage"])
          ]),
          _: 2
        }, 1032, ["item", "active", "size-dependencies", "index"])
      ]),
      _: 1
    }, 8, ["items", "onUpdate"])
  ]))
}