import { Type } from 'class-transformer'

import { DealDetail as IDealDetail } from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class DealDetail implements IDealDetail {
  organizationId!: string

  @Type(() => MultiLocaleName)
  organizationName!: MultiLocaleName

  organizationAbbreviation?: string
  isCreditSleeve!: boolean
}
