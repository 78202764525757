
import { defineComponent, PropType } from 'vue'

import TradingOrderBookListItem from '@/components/trading/TradingOrderBookListItem.vue'
import { OrderBook } from '@/models/trading/OrderBook'

export default defineComponent({
  name: 'TradingOrderBookList',
  components: {
    TradingOrderBookListItem,
  },
  props: {
    orderBooks: {
      type: Array as PropType<OrderBook[]>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    expandedList: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['click', 'order-button-click'],
  methods: {
    isExpandedOf(id: string): boolean {
      return this.expandedList.includes(id)
    },
  },
})
