import { Getters } from 'vuex-smart-module'

import { Locale } from '@/api/generated'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { MyDeal } from '@/models/trading/MyDeal'
import { MyDealState } from '@/store/modules/trading/myDeal/MyDealState'
import {
  getMyDealFilter,
  myDealFilter,
} from '@/store/modules/trading/myDeal/helper'

export class MyDealGetters extends Getters<MyDealState> {
  get filteredMyDeals(): MyDeal[] {
    return this.state.myDeals.filter(myDealFilter(this.state.filterCondition))
  }

  productFilterOfMyDeal(locale: Locale): AttributeFilter[] {
    return getMyDealFilter(
      this.state.selectedFiltersOfMyDeal,
      this.state.filterCondition,
      locale,
      this.state.myDeals,
    )
  }
}
