import { Locale } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { i18n } from '@/i18n'
import { EntityAttribute } from '@/models/common/EntityAttribute'
import { MyIndication } from '@/models/trading/MyIndication'
import {
  MyIndicationFilterAttribute,
  MyIndicationFilterConditions,
} from '@/store/modules/trading/myIndication/interface'
import {
  getAttributeObjects,
  baseIndicationFilter,
} from '@/store/modules/trading/utils'

export const myIndicationFilter = (
  filterConditions: MyIndicationFilterConditions,
) => (indication: MyIndication) => {
  return baseIndicationFilter(filterConditions)(indication)
}

export const getMyIndicationFilter = (
  indications: MyIndication[],
  filterAttributes: MyIndicationFilterAttribute[],
  filterCondition: MyIndicationFilterConditions,
  locale: Locale,
): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    if (attribute !== 'status') {
      const attributeObjects: EntityAttribute[] = getAttributeObjects({
        attribute,
        indications,
      })
      attributeObjects.sort((a, b) => b.displayPriority - a.displayPriority)

      const options = attributeObjects.map(value => value.toInputOption(locale))
      const value = options.filter((option: InputOption) =>
        filterCondition[attribute].includes(option.value),
      )

      return {
        attribute: attribute,
        filterMultiSelect: {
          value: value,
          options: options,
        },
        type: 'multi',
      }
    }
    const options: InputOption[] = []
    for (const indication of indications) {
      const value = indication.status

      if (!options.some(el => el.value === value)) {
        options.push({
          value: value,
          label: i18n.t('trading.label.statusType.' + value).toString(),
        })
      }
    }
    const value = options.filter((option: InputOption) =>
      filterCondition[attribute].includes(option.value),
    )
    return {
      attribute: 'status',
      filterMultiSelect: {
        value: value,
        options: options,
      },
      type: 'multi',
    }
  })
}
