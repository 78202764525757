import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59286c72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-picture" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_status_indicator = _resolveComponent("status-indicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_picture, {
      url: _ctx.picture,
      width: 24,
      "border-radius": _ctx.pictureBorderRadiusStyle
    }, null, 8, ["url", "border-radius"]),
    (_ctx.isStatusShown)
      ? (_openBlock(), _createBlock(_component_status_indicator, {
          key: 0,
          class: "channel-picture__status",
          "is-active": _ctx.isStatusActive
        }, null, 8, ["is-active"]))
      : _createCommentVNode("", true)
  ]))
}