import { Type } from 'class-transformer'

import { TOCOMClearingInformationItem as ITocomClearingInformationItem } from '@/api/generated/api'
import { TocomBroker } from '@/models/iam/TocomBroker'

export class TocomClearingInformationItem
  implements ITocomClearingInformationItem {
  id!: string
  organizationId!: string

  @Type(() => TocomBroker)
  tocomBroker!: TocomBroker

  createdAt!: string
  updatedAt!: string

  displayName(): string {
    return `${this.tocomBroker.name} (${this.tocomBroker.offFloorTradingCode})`
  }
}
