import { Type } from 'class-transformer'

import { BaseBrokingIndication } from '@/models/trading/BaseBrokingIndication'
import { Product } from '@/models/trading/Product'

export class BrokingIndicationOfProduct extends BaseBrokingIndication {
  isEclearIndication = false

  @Type(() => Product)
  product!: Product
}
