
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { OcctoMasterType } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import OcctoMasterItem from '@/components/iam/OcctoMasterItem.vue'
import { OcctoMaster } from '@/models/iam/OcctoMaster'
import { OcctoMasterItem as OcctoMasterItemModel } from '@/models/iam/OcctoMasterItem'
import { OcctoMasterModule } from '@/store/modules/iam/occtoMaster'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { ProductModule } from '@/store/modules/trading/product'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapActions: occtoMasterMapActions } = createNamespacedHelpers(
  'occtoMaster',
) as OcctoMasterModule

const {
  mapState: productMapState,
  mapActions: productMapActions,
} = createNamespacedHelpers('product') as ProductModule

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'OcctoMaster',
  components: {
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    ConfirmationDialog,
    OcctoMasterItem,
    UiStackSelector,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType.Error
    infoType: AlertType.Info
    deleteModalName: string
    occtoMaster: OcctoMaster
    deleteTargetInformation?: {
      id: string
      name: string
      type: OcctoMasterType
    }
    isBalancingGroupBlank: boolean
    isSupplyPlanSubmitterBlank: boolean
    deleteSubmitting: boolean
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      infoType: AlertType.Info,
      deleteModalName: 'deleteConfirmModal',
      occtoMaster: {
        balancingGroups: [],
        supplyPlanSubmitters: [],
      },
      isBalancingGroupBlank: false,
      isSupplyPlanSubmitterBlank: false,
      deleteTargetInformation: undefined,
      deleteSubmitting: false,
    }
  },
  computed: {
    ...productMapState(['areas']),
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    deleteTargetName(): string {
      return this.deleteTargetInformation?.name || ''
    },
    basePath(): string {
      return this.$route.path.replace(/\/[^/]+\/?$/, '')
    },
  },
  async created() {
    await Promise.all([this.loadOcctoMaster(), this.fetchAreas()])
      .then(() => {
        this.isBalancingGroupBlank =
          this.occtoMaster.balancingGroups.length === 0
        this.isSupplyPlanSubmitterBlank =
          this.occtoMaster.supplyPlanSubmitters.length === 0
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    fetchOcctoMaster: occtoMasterMapActions(['fetchOcctoMaster'])
      .fetchOcctoMaster as (payload: {
      organizationId: string
    }) => Promise<OcctoMaster>,
    ...occtoMasterMapActions([
      'deleteSupplyPlanSubmitter',
      'deleteBalancingGroup',
    ]),
    ...productMapActions(['fetchAreas']),
    async loadOcctoMaster() {
      await this.fetchOcctoMaster({
        organizationId: this._organizationId,
      }).then(occtoMaster => {
        this.occtoMaster = occtoMaster
      })
    },
    onButtonClick() {
      this.$router.push(`${this.basePath}/occto-master/new`)
    },
    onBalancingGroupItemDeleteClick(target: OcctoMasterItemModel) {
      this.deleteTargetInformation = {
        id: target.id,
        name: target.name,
        type: OcctoMasterType.Demand,
      }
      this.$vfm.open(this.deleteModalName)
    },
    onSupplyPlanSubmitterItemDeleteClick(target: OcctoMasterItemModel) {
      this.deleteTargetInformation = {
        id: target.id,
        name: target.name,
        type: OcctoMasterType.Generation,
      }
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteOcctoMasterCancel() {
      this.deleteTargetInformation = undefined
      this.$vfm.close(this.deleteModalName)
    },
    async onDeleteOcctoMasterConfirm() {
      if (!this.deleteTargetInformation) {
        return
      }
      this.deleteSubmitting = true
      const request =
        this.deleteTargetInformation.type === OcctoMasterType.Generation
          ? this.deleteSupplyPlanSubmitter({
              organizationId: this._organizationId,
              supplyPlanSubmitterId: this.deleteTargetInformation.id,
            })
          : this.deleteBalancingGroup({
              organizationId: this._organizationId,
              balancingGroupId: this.deleteTargetInformation.id,
            })
      await request
        .then(async () => {
          setNotification(
            this.$t('iam.message.successDeleteOcctoMaster').toString(),
          )
          this.$vfm.close(this.deleteModalName)
          this.uiStack = UiStack.Loading
          await this.loadOcctoMaster()
            .then(() => {
              this.uiStack = UiStack.Ideal
            })
            .catch(() => {
              this.uiStack = UiStack.Error
            })
        })
        .catch(e => {
          setNotification(
            this.$t('iam.message.failDeleteOcctoMaster').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
  },
})
