import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5222faa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-picture" }
const _hoisted_2 = ["src", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: _normalizeClass(["base-picture__img", [{ 'base-picture__img--clickable': _ctx.clickable }]]),
      src: _ctx.url,
      width: _ctx.width,
      style: _normalizeStyle(`border-radius: ${_ctx.borderRadius}`),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, null, 14, _hoisted_2)
  ]))
}