
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseIcon from '@/components/common/BaseIcon.vue'
import FilterDateSelect from '@/components/common/FilterDateSelect.vue'
import FilterInputText from '@/components/common/FilterInputText.vue'
import FilterMultiSelect from '@/components/common/FilterMultiSelect.vue'
import FilterSingleSelect from '@/components/common/FilterSingleSelect.vue'
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { IndicationFilterAttribute } from '@/store/modules/trading/indication/interface'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export default defineComponent({
  name: 'ProductFilter',
  components: {
    BaseIcon,
    FilterDateSelect,
    FilterInputText,
    FilterMultiSelect,
    FilterSingleSelect,
    MultiSelectDropdown,
  },
  props: {
    filters: {
      type: Array as PropType<AttributeFilter[]>,
      required: true,
    },
    actions: {
      type: Array as PropType<ActionDropdownItem[]>,
      required: true,
    },
    isActionDropdownActive: {
      type: Boolean,
      required: true,
    },
    selectedFilters: {
      type: Array as PropType<
        (BaseFilterAttribute | IndicationFilterAttribute)[]
      >,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      default: Locale.Ja,
    },
  },
  emits: [
    'select',
    'remove',
    'input',
    'filter-remove',
    'click-button',
    'click-outside',
    'change-list',
    'date-select',
  ],
  data(): {
    inputValues: { [attribute: string]: string }
    isComposition: boolean
    isDirty: { [key: string]: boolean }
  } {
    return {
      inputValues: {},
      isComposition: false,
      isDirty: {},
    }
  },
  methods: {
    onSelect(value: InputOption, attribute: string) {
      this.$emit('select', { attribute, value })
    },
    onRemove(event: Event, attribute: string) {
      this.$emit('remove', { attribute: attribute, value: event })
    },
    onInput(value: string, attribute: string) {
      this.inputValues[attribute] = value
      this.isDirty[attribute] = true
    },
    onBlur(value: string, attribute: string) {
      if (this.isDirty[attribute]) {
        this.$emit('input', { attribute, value })
        this.isDirty[attribute] = false
      }
    },
    onKeydown(event: KeyboardEvent, attribute: string) {
      if (
        !this.isComposition &&
        event.code === 'Enter' &&
        this.isDirty[attribute]
      ) {
        this.$emit('input', { attribute, value: this.inputValues[attribute] })
        this.isDirty[attribute] = false
      }
    },
    onFilterRemove(attribute: string) {
      this.$emit('filter-remove', { attribute: attribute })
    },
    onClickDropdown() {
      this.$emit('click-button')
    },
    onClickOutside() {
      this.$emit('click-outside')
    },
    onChangeFilters(event: Event) {
      this.$emit('change-list', event)
    },
    onDateSelect(value: string, attribute: string) {
      this.$emit('date-select', { attribute, value })
    },
    onClickAllClear() {
      for (const filter of this.filters) {
        this.$emit('filter-remove', { attribute: filter.attribute })
      }
    },
  },
})
