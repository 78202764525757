import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { MyDealsApi } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { MyDeal } from '@/models/trading/MyDeal'
import { MyDealGetters } from '@/store/modules/trading/myDeal/MyDealGetters'
import {
  MyDealMutations,
  SET_MY_DEALS,
  SET_IS_FETCHING_MY_DEALS,
  SET_FILTER_CONDITION_OF_MY_DEAL,
} from '@/store/modules/trading/myDeal/MyDealMutations'
import { MyDealState } from '@/store/modules/trading/myDeal/MyDealState'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'

const myDealSorter = (a: MyDeal, b: MyDeal) =>
  moment(b.createdAt).diff(moment(a.createdAt), 'days') ||
  b.product.area.displayPriority - a.product.area.displayPriority ||
  b.product.startDeliveryYearMonth.displayPriority -
    a.product.startDeliveryYearMonth.displayPriority ||
  b.product.endDeliveryYearMonth.displayPriority -
    a.product.endDeliveryYearMonth.displayPriority ||
  (b.product.hourType?.displayPriority ?? 0) -
    (a.product.hourType?.displayPriority ?? 0) ||
  b.product.productType.displayPriority - a.product.productType.displayPriority

export class MyDealActions extends Actions<
  MyDealState,
  MyDealGetters,
  MyDealMutations,
  MyDealActions
> {
  async fetchMyDeals() {
    if (this.state.myDeals.length === 0) {
      this.commit(SET_IS_FETCHING_MY_DEALS, {
        isFetchingMyDeals: true,
      })
    }

    const myDeals = plainToClass(
      MyDeal,
      (await new MyDealsApi(apiConfig).getMyDeals()).data,
    )
    myDeals.sort(myDealSorter)

    this.commit(SET_MY_DEALS, {
      myDeals,
    })

    this.commit(SET_IS_FETCHING_MY_DEALS, {
      isFetchingMyDeals: false,
    })
  }

  async addFilterConditionSelectedOfMyDeal(payload: {
    attribute: BaseFilterAttribute
    value: InputOption
  }) {
    this.commit(SET_FILTER_CONDITION_OF_MY_DEAL, {
      attribute: payload.attribute,
      selected: [
        ...this.state.filterCondition[payload.attribute],
        payload.value.value,
      ],
    })
  }

  async removeFilterConditionSelectedOfMyDeal(payload: {
    attribute: BaseFilterAttribute
    value: InputOption
  }) {
    this.commit(SET_FILTER_CONDITION_OF_MY_DEAL, {
      attribute: payload.attribute,
      selected: this.state.filterCondition[payload.attribute].filter(
        id => id !== payload.value.value,
      ),
    })
  }
}
