
import { defineComponent } from 'vue'

import BaseLink from '@/components/common/BaseLink.vue'

export default defineComponent({
  name: 'Unauthorized',
  components: {
    BaseLink,
  },
  data() {
    return {
      contactUrl: 'https://enechain.co.jp/contact',
    }
  },
})
