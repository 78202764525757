import { ContractCondition as IContractCondition } from '@/api/generated/api'
import { InputOption } from '@/components/common/interface/InputOption'
import { Area } from '@/models/trading/Area'

export class ContractCondition implements IContractCondition {
  id!: string

  dealId!: string

  restrictions?: string

  remarks?: string

  paymentDueInformation?: string

  settlementUnitPriceAreaId?: string

  note?: string

  settlementUnitPriceAreaInputOption(areas: Area[]): InputOption | undefined {
    const area = areas.find(area => area.id === this.settlementUnitPriceAreaId)
    return area?.toInputOption()
  }
}
