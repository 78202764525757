import {
  BrokingOrder as IBrokingOrder,
  OrderFuelSurchargeType as FuelSurchargeType,
  OrderOrderType as OrderType,
  OrderProductType as ProductType,
  IndicationStatusType,
  PositionType,
} from '@/api/generated'

export class BrokingOrder implements IBrokingOrder {
  orderType!: OrderType
  productType!: ProductType
  fuelSurchargeType!: FuelSurchargeType
  unitPrice!: string
  volume!: string
  exchangeVolume?: string
  executedAt?: string
  indicationId?: string
  dealId?: string
  organizationName!: string
  organizationAbbreviation?: string
  createdParticipantName!: string
  createdAt!: string
  lastUpdatedParticipantName!: string
  updatedAt!: string
  status!: IndicationStatusType
  openToMarket!: boolean
  isVisibleToTraders!: boolean
  dealPrivateConfidential?: boolean

  get isAsk(): boolean {
    return this.orderType === OrderType.Ask
  }

  get isBid(): boolean {
    return this.orderType === OrderType.Bid
  }

  get isLast(): boolean {
    return this.orderType === OrderType.Last
  }

  get positionType(): PositionType | undefined {
    switch (this.orderType) {
      case OrderType.Ask:
        return PositionType.Ask
      case OrderType.Bid:
        return PositionType.Bid
      default:
        return undefined
    }
  }
}
