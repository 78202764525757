import { Locale } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { EntityAttribute } from '@/models/common/EntityAttribute'
import { Area } from '@/models/trading/Area'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import {
  SET_FILTER_CONDITION,
  SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE,
  SET_SELECTED_FILTERS,
  SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE,
} from '@/store/modules/trading/publicIndication/PublicIndicationMutations'
import { PublicIndicationState } from '@/store/modules/trading/publicIndication/PublicIndicationState'
import {
  keyOfSelectedFilters,
  keyOfSelectedFiltersCondition,
  keyOfSelectedFiltersConditionWithFuelSurcharge,
  keyOfSelectedFiltersWithFuelSurcharge,
  ProductFilterCondition,
} from '@/store/modules/trading/publicIndication/interface'
import {
  getAttributeObjects,
  baseDealFilter,
} from '@/store/modules/trading/utils'
import {
  BaseFilterAttribute,
  TAB_INDEXES,
} from '@/store/modules/trading/utils/interface'

export const publicIndicationFilter = (
  filterConditions: ProductFilterCondition,
) => (indication: PublicIndication) => {
  return (
    (filterConditions.area.length === 0 ||
      filterConditions.area.includes(indication.products[0].area.areaId)) &&
    (filterConditions.startDeliveryYearMonth.length === 0 ||
      filterConditions.startDeliveryYearMonth.includes(
        indication.products[0].startDeliveryYearMonth.deliveryYearMonthId,
      )) &&
    (filterConditions.endDeliveryYearMonth.length === 0 ||
      filterConditions.endDeliveryYearMonth.includes(
        indication.products[0].endDeliveryYearMonth.deliveryYearMonthId,
      )) &&
    (filterConditions.hourType.length === 0 ||
      filterConditions.hourType.includes(
        indication.products[0].hourType?.hourTypeId ?? '',
      )) &&
    (filterConditions.productType.length === 0 ||
      indication.products.some(product =>
        filterConditions.productType.includes(
          product.productType.productTypeId,
        ),
      ))
  )
}

export const publicDealFilter = (filterConditions: ProductFilterCondition) => (
  deal: PublicDeal,
) => {
  return baseDealFilter(filterConditions)(deal)
}

export const getPublicIndicationFilter = ({
  indications,
  filterAttributes,
  filterCondition,
  locale,
  deals,
  areas,
  hourTypes,
  productTypes,
}: {
  indications: PublicIndication[]
  filterAttributes: BaseFilterAttribute[]
  filterCondition: ProductFilterCondition
  locale: Locale
  deals?: PublicDeal[]
  areas: Area[]
  hourTypes: HourType[]
  productTypes: ProductType[]
}): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    const attributeObjects: EntityAttribute[] = getAttributeObjects({
      attribute,
      indications,
      areas,
      hourTypes,
      productTypes,
      deals,
    })

    attributeObjects.sort((a, b) => b.displayPriority - a.displayPriority)

    const options = attributeObjects.map(value => value.toInputOption(locale))
    const value = options.filter((option: InputOption) =>
      filterCondition[attribute].includes(option.value),
    )

    return {
      attribute: attribute,
      filterMultiSelect: {
        value: value,
        options: options,
      },
      type: 'multi',
    }
  })
}

export const findFilterState = (
  index: number,
  state: PublicIndicationState,
): {
  state: {
    selectedFilters: () => BaseFilterAttribute[]
    filterCondition: () => ProductFilterCondition
    filterAttributes: () => BaseFilterAttribute[]
  }
  keyOfSelectedFilters: string
  keyOfSelectedFiltersCondition: string
  SET_SELECTED_FILTERS:
    | typeof SET_SELECTED_FILTERS
    | typeof SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE
  SET_FILTER_CONDITION:
    | typeof SET_FILTER_CONDITION
    | typeof SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE
} => {
  switch (index) {
    case TAB_INDEXES.NO_FUEL_SURCHARGE:
      return {
        state: {
          selectedFilters: () => state.selectedFilters,
          filterCondition: () => state.filterCondition,
          filterAttributes: () => state.filterAttributes,
        },
        keyOfSelectedFilters: keyOfSelectedFilters,
        keyOfSelectedFiltersCondition: keyOfSelectedFiltersCondition,
        SET_SELECTED_FILTERS,
        SET_FILTER_CONDITION,
      }
    case TAB_INDEXES.WITH_FUEL_SURCHARGE:
      return {
        state: {
          selectedFilters: () => state.selectedFiltersWithFuelSurcharge,
          filterCondition: () => state.filterConditionWithFuelSurcharge,
          filterAttributes: () => state.filterAttributes,
        },
        keyOfSelectedFilters: keyOfSelectedFiltersWithFuelSurcharge,
        keyOfSelectedFiltersCondition: keyOfSelectedFiltersConditionWithFuelSurcharge,
        SET_SELECTED_FILTERS: SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE,
        SET_FILTER_CONDITION: SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE,
      }
    default:
      throw new Error(`Unexpected index: ${index}`)
  }
}
