
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import ChatAssemblyContainer from '@/components/chat/container/ChatAssemblyContainer.vue'
import PageTemplate from '@/components/common/PageTemplate.vue'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import CollapsedNavigationContainer from '@/components/common/container/CollapsedNavigationContainer.vue'
import GlobalHeaderContainer from '@/components/common/container/GlobalHeaderContainer.vue'
import { GlobalModule } from '@/store/modules/common/global'
import { SET_IS_MENU_COLLAPSED } from '@/store/modules/common/global/GlobalMutations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { setPageTitle } from '@/utils/utils'

const { mapState, mapMutations } = createNamespacedHelpers(
  'global',
) as GlobalModule
const {
  mapState: userProfileMapState,
  mapGetters: userProfileMapGetters,
} = createNamespacedHelpers('userProfile') as UserProfileModule

export default defineComponent({
  name: 'TraderPage',
  components: {
    ChatAssemblyContainer,
    CollapsedNavigationContainer,
    GlobalHeaderContainer,
    PageTemplate,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    activeMenuItem: {
      type: String,
      required: true,
    },
    layoutMode: {
      type: String as PropType<LayoutMode>,
      default: LayoutMode.Normal,
      validator(val: LayoutMode) {
        return [
          LayoutMode.Normal,
          LayoutMode.Wide,
          LayoutMode.SuperWide,
        ].includes(val)
      },
    },
    hideSideMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isMenuCollapsed']),
    ...userProfileMapState(['localeOriginal']),
    ...userProfileMapGetters(['userProfile', 'isEsquareEnabled']),
    userId(): string {
      return this.userProfile.userId
    },
    organizationId(): string {
      return this.userProfile.organizationId
    },
    picture(): string {
      return this.userProfile.picture
    },
  },
  watch: {
    localeOriginal: function() {
      if (this.localeOriginal) {
        this.$i18n.locale = this.localeOriginal
      }
    },
    title() {
      setPageTitle(this.title)
    },
  },
  created() {
    if (this.userProfile.locale) {
      this.$i18n.locale = this.userProfile.locale
    }
    setPageTitle(this.title)
  },
  methods: {
    ...mapMutations([SET_IS_MENU_COLLAPSED]),
    onToggle() {
      this[SET_IS_MENU_COLLAPSED]({ isMenuCollapsed: !this.isMenuCollapsed })
    },
  },
})
