import { Mutations } from 'vuex-smart-module'

import { Locale, RoleType, Services, Status } from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { UserProfileState } from '@/store/modules/iam/userProfile/UserProfileState'

export const SET_LOCALE_SUBMITTING = 'SET_LOCALE_SUBMITTING'
export const SET_PICTURE_SUBMITTING = 'SET_PICTURE_SUBMITTING'
export const SET_DELETE_PICTURE_SUBMITTING = 'SET_DELETE_PICTURE_SUBMITTING'
export const SET_SERVICES_SUBMITTING = 'SET_SERVICES_SUBMITTING'

export const SET_USER_ID = 'SET_USER_ID'
export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID'
export const SET_STATUS = 'SET_STATUS'

export const SET_PICTURE = 'SET_PICTURE'
export const SET_PICTURE_ORIGINAL = 'SET_PICTURE_ORIGINAL'
export const SET_NAME = 'SET_NAME'
export const SET_NAME_ORIGINAL = 'SET_NAME_ORIGINAL'
export const SET_MAIL = 'SET_MAIL'
export const SET_ORGANIZATION_NAME = 'SET_ORGANIZATION_NAME'
export const SET_DEPARTMENT = 'SET_DEPARTMENT'
export const SET_DEPARTMENT_ORIGINAL = 'SET_DEPARTMENT_ORIGINAL'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_MOBILE_PHONE_NUMBER = 'SET_MOBILE_PHONE_NUMBER'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_LOCALE_ORIGINAL = 'SET_LOCALE_ORIGINAL'
export const SET_ROLE_IN_ORGANIZATION = 'SET_ROLE_IN_ORGANIZATION'
export const SET_SERVICES = 'SET_SERVICES'
export const SET_SERVICES_ORIGINAL = 'SET_SERVICES_ORIGINAL'

export class UserProfileMutations extends Mutations<UserProfileState> {
  [SET_LOCALE_SUBMITTING](payload: { localeSubmitting: boolean }) {
    this.state.localeSubmitting = payload.localeSubmitting
  }

  [SET_PICTURE_SUBMITTING](payload: { pictureSubmitting: boolean }) {
    this.state.pictureSubmitting = payload.pictureSubmitting
  }

  [SET_DELETE_PICTURE_SUBMITTING](payload: {
    deletePictureSubmitting: boolean
  }) {
    this.state.deletePictureSubmitting = payload.deletePictureSubmitting
  }

  [SET_SERVICES_SUBMITTING](payload: { servicesSubmitting: boolean }) {
    this.state.servicesSubmitting = payload.servicesSubmitting
  }

  [SET_USER_ID](payload: { userId: string }) {
    this.state.userId = payload.userId
  }

  [SET_ORGANIZATION_ID](payload: { organizationId: string }) {
    this.state.organizationId = payload.organizationId
  }

  [SET_STATUS](payload: { status: Status }) {
    this.state.status = payload.status
  }

  [SET_PICTURE](payload: { picture: string }) {
    this.state.picture = payload.picture
  }

  [SET_NAME](payload: { name: string }) {
    this.state.name = payload.name
  }

  [SET_NAME_ORIGINAL](payload: { nameOriginal: string }) {
    this.state.nameOriginal = payload.nameOriginal
  }

  [SET_MAIL](payload: { mail: string }) {
    this.state.mail = payload.mail
  }

  [SET_ORGANIZATION_NAME](payload: { organizationName: MultiLocaleName }) {
    this.state.organizationName = payload.organizationName
  }

  [SET_DEPARTMENT](payload: { department?: string }) {
    this.state.department = payload.department
  }

  [SET_DEPARTMENT_ORIGINAL](payload: { departmentOriginal?: string }) {
    this.state.departmentOriginal = payload.departmentOriginal
  }

  [SET_PHONE_NUMBER](payload: { phoneNumber?: string }) {
    this.state.phoneNumber = payload.phoneNumber || ''
  }

  [SET_MOBILE_PHONE_NUMBER](payload: { mobilePhoneNumber?: string }) {
    this.state.mobilePhoneNumber = payload.mobilePhoneNumber || ''
  }

  [SET_LOCALE](payload: { locale: Locale }) {
    this.state.locale = payload.locale
  }

  [SET_LOCALE_ORIGINAL](payload: { localeOriginal: Locale }) {
    this.state.localeOriginal = payload.localeOriginal
  }

  [SET_ROLE_IN_ORGANIZATION](payload: { roleInOrganization: RoleType }) {
    this.state.roleInOrganization = payload.roleInOrganization
  }

  [SET_SERVICES](payload: { services: Services }) {
    this.state.services = payload.services
  }

  [SET_SERVICES_ORIGINAL](payload: { servicesOriginal: Services }) {
    this.state.servicesOriginal = payload.servicesOriginal
  }
}
