import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37e4473e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-assembly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channel_list_window = _resolveComponent("channel-list-window")!
  const _component_channel_message_window = _resolveComponent("channel-message-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { tag: "span" }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_channel_list_window, _mergeProps({ key: 0 }, _ctx.channelListWindow, {
          width: _ctx.channelListWindowWidth,
          style: { position: 'fixed', bottom: '0', right: `${_ctx.margin}px` },
          "display-create-message-button": _ctx.displayCreateMessageButton,
          "display-open-d-m-list-button": _ctx.displayOpenDMListButton,
          "is-fetching-channel-list": _ctx.isFetchingChannelList,
          onTitleBarClick: _ctx.onChannelListWindowTitleBarClick,
          onChannelSelect: _ctx.onChannelSelect,
          onCreateMessageButtonClick: _ctx.onCreateMessageButtonClick,
          onOpenDmChannel: _ctx.onOpenDMChannel
        }), null, 16, ["width", "style", "display-create-message-button", "display-open-d-m-list-button", "is-fetching-channel-list", "onTitleBarClick", "onChannelSelect", "onCreateMessageButtonClick", "onOpenDmChannel"]))
      ]),
      _: 1
    }),
    _createVNode(_TransitionGroup, { tag: "span" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelMessageWindows, (channelMessageWindow, index) => {
          return (_openBlock(), _createBlock(_component_channel_message_window, _mergeProps({
            ref_for: true,
            ref: "channelMessageWindows",
            key: channelMessageWindow.channelId
          }, channelMessageWindow, {
            width: _ctx.channelMessageWindowWidth,
            style: {
          position: 'fixed',
          bottom: '0',
          right: `${_ctx.channelListWindowWidth +
            2 * _ctx.margin +
            _ctx.channelMessageWindows
              .filter((channelMessageWindow, i) => i < index)
              .reduce(
                (sum, channelMessageWindow) =>
                  sum + _ctx.channelMessageWindowWidth + _ctx.margin,
                0,
              )}px`,
        },
            "is-sending": _ctx.isSending,
            onClose: _ctx.onClose,
            onInput: _ctx.onInput,
            onTyping: _ctx.onTyping,
            onSend: _ctx.onSend,
            onTitleBarClick: _ctx.onChannelMessageWindowTitleBarClick,
            onTouchStart: _ctx.onTouchStart,
            onReadMessage: _ctx.onReadMessage
          }), null, 16, ["width", "style", "is-sending", "onClose", "onInput", "onTyping", "onSend", "onTitleBarClick", "onTouchStart", "onReadMessage"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}