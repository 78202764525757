import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-189a5715"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-book-header" }
const _hoisted_2 = { class: "order-book-header__item" }
const _hoisted_3 = { class: "order-book-header__label-set" }
const _hoisted_4 = {
  key: 0,
  class: "order-book-header__label"
}
const _hoisted_5 = {
  key: 1,
  class: "order-book-header__label"
}
const _hoisted_6 = { class: "order-book-header__item" }
const _hoisted_7 = { class: "order-book-header__label-set" }
const _hoisted_8 = {
  key: 0,
  class: "order-book-header__label"
}
const _hoisted_9 = {
  key: 1,
  class: "order-book-header__label"
}
const _hoisted_10 = { class: "order-book-header__item" }
const _hoisted_11 = { class: "order-book-header__label-set" }
const _hoisted_12 = { class: "order-book-header__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.positionReversed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.askClassName)
                }, _toDisplayString(_ctx.$t('trading.label.ask')), 3)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.bidClassName)
                }, _toDisplayString(_ctx.$t('trading.label.bid')), 3)
              ])),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.label.unitPriceUnit')), 1)
        ])
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.volumeUnit')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.positionReversed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.bidClassName)
                }, _toDisplayString(_ctx.$t('trading.label.bid')), 3)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.askClassName)
                }, _toDisplayString(_ctx.$t('trading.label.ask')), 3)
              ])),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.label.unitPriceUnit')), 1)
        ])
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.volumeUnit')), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('trading.label.last')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.label.unitPriceUnit')), 1)
        ])
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.volumeUnit')), 1)
    ])
  ]))
}