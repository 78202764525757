import { Locale } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { EntityAttribute } from '@/models/common/EntityAttribute'
import { MyDeal } from '@/models/trading/MyDeal'
import { MyDealFilterConditions } from '@/store/modules/trading/myDeal/interface'
import {
  getAttributeObjects,
  baseDealFilter,
} from '@/store/modules/trading/utils'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export const myDealFilter = (filterConditions: MyDealFilterConditions) => (
  deal: MyDeal,
) => {
  return baseDealFilter(filterConditions)(deal)
}

export const getMyDealFilter = (
  filterAttributes: BaseFilterAttribute[],
  filterCondition: MyDealFilterConditions,
  locale: Locale,
  deals: MyDeal[],
): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    const attributeObjects: EntityAttribute[] = getAttributeObjects({
      attribute,
      indications: [],
      deals,
    })

    attributeObjects.sort((a, b) => b.displayPriority - a.displayPriority)

    const options = attributeObjects.map(value => value.toInputOption(locale))
    const value = options.filter((option: InputOption) =>
      filterCondition[attribute].includes(option.value),
    )

    return {
      attribute: attribute,
      filterMultiSelect: {
        value: value,
        options: options,
      },
      type: 'multi',
    }
  })
}
