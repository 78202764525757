import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { UsersApi, UpdateUser, UserType } from '@/api/generated'
import { User } from '@/models/iam/User'

export class UsersActions extends Actions<UsersActions> {
  async updateUserById(payload: {
    userId: string
    user: UpdateUser
  }): Promise<void> {
    await new UsersApi(apiConfig).updateUser(payload.userId, payload.user)
  }

  async getUserById(userId: string): Promise<User> {
    return plainToClass(
      User,
      (await new UsersApi(apiConfig).getUsers(userId)).data,
    )[0]
  }

  async fetchBrokerUsers(): Promise<User[]> {
    const perPage = 100
    let page = 0
    const maxPage = 10
    const allUsers: User[] = []
    while (page < maxPage) {
      const users = plainToClass(
        User,
        (
          await new UsersApi(apiConfig).getUsers(
            undefined,
            undefined,
            undefined,
            UserType.Broker,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            page,
            perPage,
          )
        ).data,
      )
      allUsers.push(...users)
      if (users.length < perPage) {
        break
      }
      page += 1
    }
    return allUsers
  }
}
