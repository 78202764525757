import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b7b226e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recap-receiver-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.department'),
        name: "department",
        value: _ctx.formValue.department,
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.$t('iam.label.department'),
          })
        ,
        onInput: _cache[0] || (_cache[0] = event => _ctx.onInput('department', event))
      }, null, 8, ["label", "value", "placeholder"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.name'),
        name: "name",
        value: _ctx.formValue.name,
        rules: "required",
        required: "",
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', { name: _ctx.$t('iam.label.name') })
        ,
        onInput: _cache[1] || (_cache[1] = event => _ctx.onInput('name', event))
      }, null, 8, ["label", "value", "placeholder"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.phoneNumber'),
        name: "phoneNumber",
        value: _ctx.formValue.phoneNumber,
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.$t('iam.label.phoneNumber'),
          })
        ,
        onInput: _cache[2] || (_cache[2] = event => _ctx.onInput('phoneNumber', event))
      }, null, 8, ["label", "value", "placeholder"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.mail'),
        name: "mail",
        value: _ctx.formValue.emailAddress,
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', { name: _ctx.$t('iam.label.mail') })
        ,
        onInput: _cache[3] || (_cache[3] = event => _ctx.onInput('emailAddress', event))
      }, null, 8, ["label", "value", "placeholder"])
    ])
  ]))
}