import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_header_container = _resolveComponent("global-header-container")!
  const _component_collapsed_navigation_container = _resolveComponent("collapsed-navigation-container")!
  const _component_chat_assembly_container = _resolveComponent("chat-assembly-container")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.title,
    "layout-mode": _ctx.layoutMode
  }, _createSlots({
    header: _withCtx(() => [
      _createVNode(_component_global_header_container, {
        "user-id": _ctx.userId,
        picture: _ctx.picture
      }, null, 8, ["user-id", "picture"])
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.isEsquareEnabled)
        ? (_openBlock(), _createBlock(_component_chat_assembly_container, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (!_ctx.hideSideMenu)
      ? {
          name: "side-menu",
          fn: _withCtx(() => [
            _createVNode(_component_collapsed_navigation_container, {
              type: "trader",
              "is-collapsed": _ctx.isMenuCollapsed,
              "current-menu": _ctx.activeMenuItem,
              toggle: _ctx.onToggle
            }, null, 8, ["is-collapsed", "current-menu", "toggle"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "layout-mode"]))
}