import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0babaceb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "base-label"
}
const _hoisted_2 = { class: "base-label__main" }
const _hoisted_3 = { class: "base-label__label" }
const _hoisted_4 = { class: "base-label__accessory" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (!!_ctx.label)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.label), 1),
          (!!_ctx.help)
            ? (_openBlock(), _createBlock(_component_base_tooltip, {
                key: 0,
                label: _ctx.help
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_icon, {
                    class: "base-label__icon",
                    "icon-name": "question-circle"
                  })
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "accessory", {}, undefined, true)
        ])
      ]))
    : _createCommentVNode("", true)
}