import { root, store } from '@/store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let instance: any
const LOCAL_STORAGE_KEY = 'esquare:local:jwt'

const ctx = root.context(store)
const userProfileCtx = ctx.modules.userProfile

const useAuth0 = () => {
  if (instance) {
    return instance
  }
  instance = {
    loading: true,
    isAuthenticated: true,
    user: {},
    auth0Client: null,
    popupOpen: false,
    error: null,
    isProfileLoaded: true,
    async loginWithPopup() {
      console.log('loginWithPopup')
      if (!this.getTokenSilently()) {
        console.error(
          `Please set token in localStorage using key=${LOCAL_STORAGE_KEY} `,
        )
      }
    },
    loginWithRedirect() {
      console.error(
        `Please set token in localStorage using key=${LOCAL_STORAGE_KEY} `,
      )
    },
    getIdTokenClaims() {
      console.log('getIdTokenClaims')
      return ''
    },
    getTokenSilently(): string {
      return localStorage.getItem(LOCAL_STORAGE_KEY) || ''
    },
    getTokenWithPopup() {
      console.log('getTokenWithPopup')
    },
    logout() {
      console.log('logout')
    },
    refreshToken() {
      // そのものずばりの refresh がなかったので常に auth0 に問い合わせるモードでトークンを取得する
      this.getTokenSilently({ ignoreCache: true })
    },
    async getAuthorizationHeader() {
      return {
        headers: {
          Authorization: `Bearer ${this.getTokenSilently()}`,
        },
      }
    },
    async getDecodedPayload() {
      const token = this.getTokenSilently()
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
    },

    async getUserType() {
      const decodedPayload = await this.getDecodedPayload()
      return decodedPayload['https://esquare.enechain.co.jp/user_type']
    },
  }
  ;(async () => {
    const decodedPayload = await instance.getDecodedPayload()
    await userProfileCtx.dispatch('fetchUserProfile', {
      userId: decodedPayload.sub,
    })
    instance.loading = false
  })()

  return instance
}

export const Auth0MockPlugin = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: any, _: any) {
    app.config.globalProperties.$auth = useAuth0()
  },
}

export const getMockInstance = () => instance
