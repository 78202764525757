
import { plainToClass } from 'class-transformer'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { OcctoMasterType } from '@/api/generated/api'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { BalancingGroup } from '@/models/iam/BalancingGroup'
import { SupplyPlanSubmitter } from '@/models/iam/SupplyPlanSubmitter'
import { OcctoMaster } from '@/models/trading/OcctoMaster'
import { OcctoMasterModule } from '@/store/modules/iam/occtoMaster'

const { mapActions: occtoMasterActions } = createNamespacedHelpers(
  'occtoMaster',
) as OcctoMasterModule

export default defineComponent({
  name: 'OcctoMasterSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    areaId: {
      type: String,
      required: true,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    balancingGroups: BalancingGroup[]
    supplyPlanSubmitters: SupplyPlanSubmitter[]
    uiStack: UiStack
    alertTypeInfo: AlertType
    alertTypeError: AlertType
  } {
    return {
      balancingGroups: [],
      supplyPlanSubmitters: [],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
    }
  },
  async created() {
    await this.fetchOcctoMaster({
      organizationId: this.organizationId,
      areaId: this.areaId,
    })
      .then(occtoMasters => {
        this.balancingGroups = occtoMasters.balancingGroups
        this.supplyPlanSubmitters = occtoMasters.supplyPlanSubmitters
        this.uiStack = UiStack.Ideal
      })
      .catch(() => {
        this.uiStack = UiStack.Error
      })
  },
  methods: {
    fetchOcctoMaster: occtoMasterActions(['fetchOcctoMaster'])
      .fetchOcctoMaster as (paylaod: {
      organizationId: string
      areaId: string
    }) => Promise<{
      balancingGroups: BalancingGroup[]
      supplyPlanSubmitters: SupplyPlanSubmitter[]
    }>,
    onBalancingGroupSelect(balancingGroup: BalancingGroup) {
      this.$emit(
        'select',
        plainToClass(OcctoMaster, {
          occtoMasterType: OcctoMasterType.Demand,
          code: balancingGroup.code,
          name: balancingGroup.name,
          companyPrefix: balancingGroup.companyPrefix,
        }),
      )
    },
    onSupplyPlanSubmitterSelect(supplyPlanSubmitter: SupplyPlanSubmitter) {
      this.$emit(
        'select',
        plainToClass(OcctoMaster, {
          occtoMasterType: OcctoMasterType.Generation,
          code: supplyPlanSubmitter.code,
          name: supplyPlanSubmitter.name,
          companyPrefix: supplyPlanSubmitter.companyPrefix,
        }),
      )
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
})
