import { Mutations } from 'vuex-smart-module'

import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { PublicIndicationState } from '@/store/modules/trading/publicIndication/PublicIndicationState'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export const SET_PUBLIC_INDICATIONS = 'SET_PUBLIC_INDICATIONS'
export const SET_PUBLIC_DEALS = 'SET_PUBLIC_DEALS'
export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION'
export const SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE =
  'SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'
export const SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE =
  'SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE'

export class PublicIndicationMutations extends Mutations<
  PublicIndicationState
> {
  [SET_PUBLIC_INDICATIONS](payload: { publicIndications: PublicIndication[] }) {
    this.state.publicIndications = payload.publicIndications
  }

  [SET_PUBLIC_DEALS](payload: { publicDeals: PublicDeal[] }) {
    this.state.publicDeals = payload.publicDeals
  }

  [SET_FILTER_CONDITION](payload: {
    attribute: BaseFilterAttribute
    selected: string[]
  }) {
    this.state.filterCondition[payload.attribute] = payload.selected
  }

  [SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE](payload: {
    attribute: BaseFilterAttribute
    selected: string[]
  }) {
    this.state.filterConditionWithFuelSurcharge[payload.attribute] =
      payload.selected
  }

  [SET_SELECTED_FILTERS](payload: { selectedFilters: BaseFilterAttribute[] }) {
    this.state.selectedFilters = payload.selectedFilters
  }

  [SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE](payload: {
    selectedFilters: BaseFilterAttribute[]
  }) {
    this.state.selectedFiltersWithFuelSurcharge = payload.selectedFilters
  }
}
