import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b638104"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-body__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: "organization-body",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_base_box, {
      spacing: "8px 16px",
      class: "organization-body__list-item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_picture, {
          class: "organization-body__picture",
          url: _ctx.picture,
          width: 24
        }, null, 8, ["url"]),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.name), 1)
      ]),
      _: 1
    })
  ]))
}