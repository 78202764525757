
import { defineComponent } from 'vue'

import { InputMode } from '@/components/common/constants/InputMode'

export default defineComponent({
  name: 'BaseInputSingleCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY].includes(value),
      default: InputMode.INPUT,
    },
  },
  emits: ['input'],
  computed: {
    readonly(): boolean {
      return this.mode === InputMode.READONLY
    },
  },
  methods: {
    onChange(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        this.$emit('input', event.target.checked)
      }
    },
  },
})
