import { Type } from 'class-transformer'

import {
  BrokerageFeePaymentTiming,
  OrganizationRecap as IOrganizationRecap,
  PositionType,
} from '@/api/generated/api'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { Area } from '@/models/trading/Area'
import { EexClearingHouse } from '@/models/trading/EexClearingHouse'
import { OcctoMaster } from '@/models/trading/OcctoMaster'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'
import { RecapReceiver } from '@/models/trading/RecapReceiver'
import { TocomBroker } from '@/models/trading/TocomBroker'

export class OrganizationRecap implements IOrganizationRecap {
  id!: string

  dealId!: string

  publicRecapId!: string

  position!: PositionType

  brokingFee!: number

  brokerageFeePaymentTiming!: BrokerageFeePaymentTiming

  @Type(() => Product)
  product!: Product

  areaId!: string

  price!: Price

  unitPrices!: number[]

  volumes!: number[]

  organizationId!: string

  @Type(() => MultiLocaleName)
  organizationName!: MultiLocaleName

  @Type(() => RecapReceiver)
  recapReceiver?: RecapReceiver

  address!: string

  @Type(() => EexClearingHouse)
  eexClearingHouse?: EexClearingHouse

  @Type(() => TocomBroker)
  tocomBroker?: TocomBroker

  @Type(() => OcctoMaster)
  occtoMaster?: OcctoMaster

  isCreditSleever?: boolean

  totalBrokingFee!: number

  createdParticipantId!: string

  createdAt!: string

  lastUpdatedParticipantId!: string

  updatedAt!: string

  get displayBrokingFee(): string {
    return i18n
      .t('trading.label.unitPriceWithUnit', {
        unitPrice: Number(this.brokingFee).toLocaleString(undefined, {
          // Ref: デフォルトでは、少数第4桁以降は四捨五入されてしまうため https://onl.la/GVx8nUg
          maximumFractionDigits: 4,
        }),
      })
      .toString()
  }

  get displayTotalBrokingFee(): string {
    return i18n
      .t('trading.label.priceWithUnit', {
        price: Number(this.totalBrokingFee).toLocaleString(),
      })
      .toString()
  }

  get displayBrokerageFeePaymentTiming(): string {
    return this.brokerageFeePaymentTiming ===
      BrokerageFeePaymentTiming.DeliveryMonth
      ? i18n.t('trading.label.paymentInDeliveryMonth').toString()
      : i18n.t('trading.label.paymentInContractMonth').toString()
  }

  areaInputOption(areas: Area[]): InputOption {
    const area = areas.find(area => area.id === this.areaId)
    return area
      ? area.toInputOption()
      : {
          value: '',
          label: '',
        }
  }
}
