
import { defineComponent, PropType } from 'vue'

import { PositionType } from '@/api/generated'
import Angle from '@/components/common/Angle.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import DeliveryTermsWithPeriod from '@/components/trading/DeliveryTermsWithPeriod.vue'
import OrderBestPrice from '@/components/trading/OrderBestPrice.vue'
import OrderButtons from '@/components/trading/OrderButtons.vue'
import TradingOrderList from '@/components/trading/TradingOrderList.vue'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { OrderBook } from '@/models/trading/OrderBook'

export default defineComponent({
  name: 'TradingOrderBookListItem',
  components: {
    Angle,
    BaseBox,
    DeliveryTermsWithPeriod,
    OrderBestPrice,
    OrderButtons,
    TradingOrderList,
  },
  props: {
    orderBook: {
      type: Object as PropType<OrderBook>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'order-button-click', 'expand'],
  data(): {
    ask: PositionType
    bid: PositionType
  } {
    return {
      ask: PositionType.Ask,
      bid: PositionType.Bid,
    }
  },
  computed: {
    deliveryTerms(): DeliveryTermsWithPeriodProps {
      return this.orderBook.deliveryTerms()
    },
  },
})
