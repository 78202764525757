
import cloneDeep from 'lodash/cloneDeep'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { NegotiationStatusType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { DirectionType } from '@/components/common/constants/DirectionType'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import DeliverySummaries from '@/components/trading/DeliverySummaries.vue'
import NegotiationConfirmModalContent from '@/components/trading/NegotiationConfirmModalContent.vue'
import NegotiationForm from '@/components/trading/NegotiationForm.vue'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import TradingSteps from '@/components/trading/TradingSteps.vue'
import { NegotiationFormInputMode } from '@/components/trading/constants/NegotiationFormInputMode'
import { NegotiationFormProps } from '@/components/trading/interface/NegotiationFormProps'
import { Organization } from '@/models/iam/Organization'
import { CalendarType } from '@/models/trading/CalendarType'
import { DefaultBrokingFees } from '@/models/trading/DefaultBrokingFees'
import { DeliverySummaries as DeliverySummariesModel } from '@/models/trading/DeliverySummaries'
import { Negotiation } from '@/models/trading/Negotiation'
import { Product } from '@/models/trading/Product'
import { TradingSteps as TradingStepsModel } from '@/models/trading/TradingSteps'
import { IUpdateNegotiation } from '@/models/trading/UpdateNegotiation'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { NegotiationModule } from '@/store/modules/trading/negotiation'
import { ProductModule } from '@/store/modules/trading/product'
import { setNotification } from '@/utils/utils'

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const { mapActions: negotiationMapActions } = createNamespacedHelpers(
  'negotiation',
) as NegotiationModule
const { mapActions: organizationsMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

export default defineComponent({
  name: 'Negotiation',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseBox,
    BaseButton,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    DeliverySummaries,
    NegotiationConfirmModalContent,
    NegotiationForm,
    ParticipantSummary,
    TradingSteps,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    id: string
    status: NegotiationStatusType
    formValue: NegotiationFormProps
    originalFormValue: NegotiationFormProps | undefined
    negotiation: Negotiation | undefined
    uiStack: UiStack
    errorType: AlertType
    actionDropdownIsActive: boolean
    deliverySummaries: DeliverySummariesModel | undefined
    tradingSteps: TradingStepsModel | undefined
    calendarTypeInputOptions: InputOption[]
    updateModalName: string
    deleteModalName: string
    updateSubmitting: boolean
    deleteSubmitting: boolean
    creditSleeverIsShown: boolean
    eclearOrganization: Organization | undefined
    participantSummaryDirection: DirectionType
  } {
    return {
      id: '',
      status: NegotiationStatusType.UnderNegotiation,
      formValue: {
        status: NegotiationStatusType.UnderNegotiation,
        spreadBasePrice: '',
        spreadUnitPrice: '',
        calendarType: undefined,
        exchangeCalendarType: undefined,
        askIndication: undefined,
        askProductType: undefined,
        askAfterUnitPrices: [],
        askAfterExchangeVolumes: undefined,
        askAfterVolumes: [],
        askBrokingFee: '',
        askExchangeBrokingFee: '',
        askDefaultBrokingFees: [],
        askAfterPrice: undefined,
        bidIndication: undefined,
        bidProductType: undefined,
        bidAfterUnitPrices: [],
        bidAfterExchangeVolumes: undefined,
        bidAfterVolumes: [],
        bidBrokingFee: '',
        bidExchangeBrokingFee: '',
        bidDefaultBrokingFees: [],
        bidAfterPrice: undefined,
        creditSleever: undefined,
        creditSleeverAskBrokingFee: '',
        creditSleeverBidBrokingFee: '',
        creditSleeverSleeveSpread: '',
        memo: '',
      },
      originalFormValue: undefined,
      negotiation: undefined,
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      actionDropdownIsActive: false,
      deliverySummaries: undefined,
      tradingSteps: undefined,
      calendarTypeInputOptions: [],
      updateModalName: 'updateConfirmModal',
      deleteModalName: 'deleteConfirmModal',
      updateSubmitting: false,
      deleteSubmitting: false,
      creditSleeverIsShown: false,
      eclearOrganization: undefined,
      participantSummaryDirection: DirectionType.Row,
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['productTypes']),
    actionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('common.label.delete').toString(),
          eventName: 'delete-negotiation-click',
          disabled: this.isInvalidForDelete,
        },
      ]
    },
    isSwingOption(): boolean {
      return this.negotiation?.isSwingOption ?? false
    },
    executed(): boolean {
      return this.status === NegotiationStatusType.Executed
    },
    isNotChangeableStatus(): boolean {
      return (
        this.status === NegotiationStatusType.Broken ||
        this.status === NegotiationStatusType.Deleted
      )
    },
    NegotiationStatusType() {
      return NegotiationStatusType
    },
    isStandardSpread(): boolean {
      return (
        !!this.negotiation?.spreadTypes &&
        this.negotiation.spreadTypes.length > 0
      )
    },
    mode(): NegotiationFormInputMode | undefined {
      switch (this.status) {
        case NegotiationStatusType.UnderNegotiation:
          return NegotiationFormInputMode.UNDER_NEGOTIATION
        case NegotiationStatusType.Executed:
          return NegotiationFormInputMode.EXECUTED
        case NegotiationStatusType.Broken:
          return NegotiationFormInputMode.BROKEN
        case NegotiationStatusType.Deleted:
          return NegotiationFormInputMode.DELETED
        default:
          return undefined
      }
    },
    product(): Product | undefined {
      // 売りと買いの商品は同一のため、決めうちで売りの商品を使う
      return this.negotiation
        ? (this.negotiation.askNegotiationDetail.product as Product)
        : undefined
    },
    hasCreditSleever(): boolean {
      if (!this.creditSleeverIsShown) {
        return true
      }
      return !!this.formValue.creditSleever?.organizationId
    },
    updateConfirmModalTitle(): string {
      switch (this.formValue.status) {
        case NegotiationStatusType.UnderNegotiation:
          return this.$t(
            'trading.message.negotiationUpdateConfirmModalTitle',
          ).toString()
        case NegotiationStatusType.Broken:
          return this.$t(
            'trading.message.negotiationBreakConfirmModalTitle',
          ).toString()
        case NegotiationStatusType.Executed:
          return this.$t(
            'trading.message.negotiationExecuteConfirmModalTitle',
          ).toString()
        default:
          return ''
      }
    },
    updateConfirmModalMessage(): string {
      switch (this.formValue.status) {
        case NegotiationStatusType.UnderNegotiation:
          return this.$t(
            'trading.message.negotiationUpdateConfirmModalMessage',
          ).toString()
        case NegotiationStatusType.Broken:
          return this.$t(
            'trading.message.negotiationBreakConfirmModalMessage',
          ).toString()
        case NegotiationStatusType.Executed:
          return this.$t(
            'trading.message.negotiationExecuteConfirmModalMessage',
          ).toString()
        default:
          return ''
      }
    },
    updateConfirmModalButtonLabel(): string {
      switch (this.formValue.status) {
        case NegotiationStatusType.UnderNegotiation:
          return this.$t('common.label.update').toString()
        case NegotiationStatusType.Broken:
          return this.$t(
            'trading.label.negotiationStatusType.broken',
          ).toString()
        case NegotiationStatusType.Executed:
          return this.$t(
            'trading.label.negotiationStatusType.executed',
          ).toString()
        default:
          return ''
      }
    },
    updateConfirmModalButtonColor(): string {
      return this.formValue.status === NegotiationStatusType.Broken
        ? 'danger'
        : 'primary'
    },
    successUpdateMessage(): string {
      switch (this.formValue.status) {
        case NegotiationStatusType.UnderNegotiation:
          return this.$t('trading.message.successUpdateNegotiation').toString()
        case NegotiationStatusType.Broken:
          return this.$t('trading.message.successBreakNegotiation').toString()
        case NegotiationStatusType.Executed:
          return this.$t('trading.message.successExecuteNegotiation').toString()
        default:
          return ''
      }
    },
    failUpdateMessage(): string {
      switch (this.formValue.status) {
        case NegotiationStatusType.UnderNegotiation:
          return this.$t('trading.message.failUpdateNegotiation').toString()
        case NegotiationStatusType.Broken:
          return this.$t('trading.message.failBreakNegotiation').toString()
        case NegotiationStatusType.Executed:
          return this.$t('trading.message.failExecuteNegotiation').toString()
        default:
          return ''
      }
    },
    eclearOrganizationId(): string | undefined {
      return this.eclearOrganization?.organizationId
    },
    eclearProductTypeIdList(): string[] {
      return this.productTypes
        .filter(p => p.isEclear())
        .map(p => p.productTypeId)
    },
    isRequiredMemo(): boolean {
      if (
        this.formValue.status === 'under-negotiation' &&
        this.originalFormValue?.status === 'executed'
      ) {
        return true
      }
      if (
        this.formValue.status === 'broken' &&
        this.originalFormValue?.status !== 'broken'
      ) {
        return true
      }
      return false
    },
    isInvalidForDelete(): boolean {
      return this.formValue.status === 'deleted' || !this.formValue.memo
    },
  },
  async mounted() {
    this.id = this.$route.params.id as string

    try {
      const [
        negotiation,
        deliverySummaries,
        tradingSteps,
        calendarTypes,
        eclearOrganization,
      ] = await Promise.all([
        this.fetchNegotiation(this.id),
        this.fetchDeliverySummaries(this.id),
        this.fetchTradingSteps(this.id),
        this.fetchCalendarTypes(),
        this.fetchEclearOrganization(),
      ])

      this.negotiation = negotiation
      this.status = negotiation.status
      this.creditSleeverIsShown = negotiation.creditSleever != null

      this.deliverySummaries = deliverySummaries
      this.tradingSteps = tradingSteps
      this.calendarTypeInputOptions = calendarTypes.map(calendarType =>
        calendarType.toInputOption(this.userProfile.locale),
      )
      this.eclearOrganization = eclearOrganization

      const creditSleever = negotiation.creditSleever
        ? await this.fetchOrganization(negotiation.creditSleever.organizationId)
        : undefined

      const [askDefaultBrokingFees, bidDefaultBrokingFees] = await Promise.all([
        this.fetchDefaultBrokingFees(
          negotiation.askNegotiationDetail.indication.organizationId,
        ),
        this.fetchDefaultBrokingFees(
          negotiation.bidNegotiationDetail.indication.organizationId,
        ),
      ])

      this.setFormValue(
        negotiation,
        askDefaultBrokingFees,
        bidDefaultBrokingFees,
        creditSleever,
      )
      this.uiStack = UiStack.Ideal
    } catch (e) {
      this.uiStack = UiStack.Error
      throw e
    }
  },
  methods: {
    ...negotiationMapActions(['updateNegotiation', 'deleteNegotiation']),
    fetchCalendarTypes: productMapActions(['fetchCalendarTypes'])
      .fetchCalendarTypes as () => Promise<CalendarType[]>,
    fetchNegotiation: negotiationMapActions(['fetchNegotiation'])
      .fetchNegotiation as (id: string) => Promise<Negotiation>,
    fetchDeliverySummaries: negotiationMapActions(['fetchDeliverySummaries'])
      .fetchDeliverySummaries as (
      id: string,
    ) => Promise<DeliverySummariesModel>,
    fetchTradingSteps: negotiationMapActions(['fetchTradingSteps'])
      .fetchTradingSteps as (id: string) => Promise<TradingStepsModel>,
    fetchDefaultBrokingFees: negotiationMapActions(['fetchDefaultBrokingFees'])
      .fetchDefaultBrokingFees as (
      organizationId: string,
    ) => Promise<DefaultBrokingFees[]>,
    fetchEclearOrganization: organizationsMapActions([
      'fetchEclearOrganization',
    ]).fetchEclearOrganization as () => Promise<Organization>,
    fetchOrganization: organizationsMapActions(['fetchOrganization'])
      .fetchOrganization as (organizationId: string) => Promise<Organization>,
    onFormInput(formValue: NegotiationFormProps): void {
      if (
        (this.formValue.bidProductType?.value !==
          formValue.bidProductType?.value ||
          this.formValue.askProductType?.value !==
            formValue.askProductType?.value) &&
        !!formValue.askProductType &&
        !!formValue.bidProductType
      ) {
        const askProductType = this.productTypes.find(
          p => p.productTypeId === formValue.askProductType?.value,
        )
        const bidProductType = this.productTypes.find(
          p => p.productTypeId === formValue.bidProductType?.value,
        )
        if (askProductType?.isEclear() && bidProductType?.isEclear()) {
          formValue.creditSleever = this.eclearOrganization
          if (!this.eclearOrganization) {
            setNotification(
              this.$t('iam.message.failGetEclearOrganization').toString(),
              'danger',
            )
          }
        }
      }
      Object.assign(this.formValue, formValue)
    },
    setFormValue(
      negotiation: Negotiation,
      askDefaultBrokingFees: DefaultBrokingFees[],
      bidDefaultBrokingFees: DefaultBrokingFees[],
      creditSleever?: Organization,
    ): void {
      this.formValue = {
        status: negotiation.status,
        spreadBasePrice: negotiation.spreadBasePrice?.toString(),
        spreadUnitPrice: negotiation.askNegotiationDetail.afterUnitPrices[0].toString(),
        calendarType: this.calendarTypeInputOptions.find(
          inputOption => inputOption.value === negotiation.calendarTypeId,
        ),
        exchangeCalendarType: this.calendarTypeInputOptions.find(
          inputOption =>
            inputOption.value === negotiation.exchangeCalendarTypeId,
        ),
        askIndication: negotiation.askNegotiationDetail.indication,
        askProductType: negotiation.askNegotiationDetail.product.productType.toInputOption(
          this.userProfile.locale,
        ),
        // TODO: Price型に移行
        askAfterUnitPrices: negotiation.askNegotiationDetail.afterUnitPrices.map(
          unitPrice => unitPrice.toString(),
        ),
        askAfterExchangeVolumes: negotiation.askNegotiationDetail.afterExchangeVolumes?.map(
          volume => volume?.toString() ?? null,
        ),
        askAfterVolumes: negotiation.askNegotiationDetail.afterVolumes.map(
          volume => {
            return volume?.toString() ?? null
          },
        ),
        askBrokingFee: negotiation.askNegotiationDetail.brokingFee.toString(),
        askExchangeBrokingFee:
          negotiation.askNegotiationDetail.exchangeBrokingFee?.toString() || '',
        askDefaultBrokingFees,
        askAfterPrice: negotiation.askNegotiationDetail.afterPrice,
        bidIndication: negotiation.bidNegotiationDetail.indication,
        bidProductType: negotiation.bidNegotiationDetail.product.productType.toInputOption(
          this.userProfile.locale,
        ),
        // TODO: Price型に移行
        bidAfterUnitPrices: negotiation.bidNegotiationDetail.afterUnitPrices.map(
          unitPrice => unitPrice.toString(),
        ),
        bidAfterVolumes: negotiation.bidNegotiationDetail.afterVolumes.map(
          volume => {
            return volume?.toString() ?? null
          },
        ),
        bidAfterExchangeVolumes: negotiation.bidNegotiationDetail.afterExchangeVolumes?.map(
          volume => volume?.toString() ?? null,
        ),
        bidBrokingFee: negotiation.bidNegotiationDetail.brokingFee.toString(),
        bidExchangeBrokingFee:
          negotiation.bidNegotiationDetail.exchangeBrokingFee?.toString() || '',
        bidDefaultBrokingFees,
        bidAfterPrice: negotiation.bidNegotiationDetail.afterPrice,
        creditSleever,
        creditSleeverAskBrokingFee: negotiation.creditSleever?.askBrokingFee.toString(),
        creditSleeverBidBrokingFee: negotiation.creditSleever?.bidBrokingFee.toString(),
        creditSleeverSleeveSpread: negotiation.creditSleever?.sleeveSpread.toString(),
        memo: negotiation.memo ?? '',
      }
      this.originalFormValue = cloneDeep(this.formValue)
    },
    buildPayload(): IUpdateNegotiation | undefined {
      const payload: IUpdateNegotiation = {
        status: this.formValue.status,
        calendarTypeId: this.formValue.calendarType?.value,
        exchangeCalendarTypeId: this.formValue.exchangeCalendarType?.value,
        askNegotiationDetail: {
          productTypeId: this.formValue.askProductType?.value,
          afterPrice:
            this.formValue.askAfterPrice !== undefined
              ? {
                  ...this.formValue.askAfterPrice,
                  // TODO: NegotiationFormでUnitPriceの扱いをPrice型に移行できたらここも修正する
                  energyUnitPrices: this.formValue.askAfterUnitPrices.map(
                    unitPrice => Number(unitPrice),
                  ),
                  basicUnitPrice:
                    this.formValue.askAfterPrice.basicUnitPrice !== undefined
                      ? {
                          ...this.formValue.askAfterPrice.basicUnitPrice,
                          unitPrice: Number(
                            this.formValue.askAfterPrice.basicUnitPrice
                              .unitPrice,
                          ),
                        }
                      : undefined,
                }
              : undefined,
          afterVolumes: this.formValue.askAfterVolumes.map(volume =>
            volume === null || volume === '' ? null : Number(volume),
          ),
          brokingFee: Number(this.formValue.askBrokingFee),
        },
        bidNegotiationDetail: {
          productTypeId: this.formValue.bidProductType?.value,
          afterPrice:
            this.formValue.bidAfterPrice !== undefined
              ? {
                  ...this.formValue.bidAfterPrice,
                  // TODO: NegotiationFormでUnitPriceの扱いをPrice型に移行できたらここも修正する
                  energyUnitPrices: this.formValue.bidAfterUnitPrices.map(
                    unitPrice => Number(unitPrice),
                  ),
                  basicUnitPrice:
                    this.formValue.bidAfterPrice.basicUnitPrice !== undefined
                      ? {
                          ...this.formValue.bidAfterPrice.basicUnitPrice,
                          unitPrice: Number(
                            this.formValue.bidAfterPrice.basicUnitPrice
                              .unitPrice,
                          ),
                        }
                      : undefined,
                }
              : undefined,
          afterVolumes: this.formValue.bidAfterVolumes.map(volume =>
            volume === null || volume === '' ? null : Number(volume),
          ),
          brokingFee: Number(this.formValue.bidBrokingFee),
        },
        creditSleever: this.formValue.creditSleever
          ? {
              organizationId: this.formValue.creditSleever.organizationId,
              sleeveSpread: Number(this.formValue.creditSleeverSleeveSpread),
              askBrokingFee: Number(this.formValue.creditSleeverAskBrokingFee),
              bidBrokingFee: Number(this.formValue.creditSleeverBidBrokingFee),
            }
          : undefined,
        memo: this.formValue.memo,
      }
      return payload
    },
    buildSpreadPayload(): IUpdateNegotiation | undefined {
      const payload: IUpdateNegotiation = {
        status: this.formValue.status,
        spreadBasePrice: Number(this.formValue.spreadBasePrice),
        calendarTypeId: this.formValue.calendarType?.value,
        exchangeCalendarTypeId: this.formValue.exchangeCalendarType?.value,
        askNegotiationDetail: {
          productTypeId: this.formValue.askProductType?.value,
          afterPrice:
            this.formValue.askAfterPrice !== undefined
              ? {
                  ...this.formValue.askAfterPrice,
                  // TODO: NegotiationFormでUnitPriceの扱いをPrice型に移行できたらここも修正する
                  energyUnitPrices: this.formValue.askAfterUnitPrices.map(
                    unitPrice => Number(unitPrice),
                  ),
                }
              : undefined,
          afterVolumes: this.formValue.askAfterVolumes.map(volume =>
            volume === null || volume === '' ? null : Number(volume),
          ),
          afterExchangeVolumes: (
            this.formValue.bidAfterExchangeVolumes || []
          ).map(volume =>
            volume === null || volume === '' ? null : Number(volume),
          ),
          brokingFee: Number(this.formValue.askBrokingFee),
          exchangeBrokingFee: Number(this.formValue.askExchangeBrokingFee),
        },
        bidNegotiationDetail: {
          productTypeId: this.formValue.bidProductType?.value,
          afterPrice:
            this.formValue.bidAfterPrice !== undefined
              ? {
                  ...this.formValue.bidAfterPrice,
                  // TODO: NegotiationFormでUnitPriceの扱いをPrice型に移行できたらここも修正する
                  energyUnitPrices: this.formValue.bidAfterUnitPrices.map(
                    unitPrice => Number(unitPrice),
                  ),
                }
              : undefined,
          afterVolumes: this.formValue.bidAfterVolumes.map(volume =>
            volume === null || volume === '' ? null : Number(volume),
          ),
          afterExchangeVolumes: (
            this.formValue.askAfterExchangeVolumes || []
          ).map(volume =>
            volume === null || volume === '' ? null : Number(volume),
          ),
          brokingFee: Number(this.formValue.bidBrokingFee),
          exchangeBrokingFee: Number(this.formValue.bidExchangeBrokingFee),
        },
        creditSleever: this.formValue.creditSleever
          ? {
              organizationId: this.formValue.creditSleever.organizationId,
              sleeveSpread: Number(this.formValue.creditSleeverSleeveSpread),
              askBrokingFee: Number(this.formValue.creditSleeverAskBrokingFee),
              bidBrokingFee: Number(this.formValue.creditSleeverBidBrokingFee),
            }
          : undefined,
        memo: this.formValue.memo,
      }
      return payload
    },
    async onMemoInput(value: string) {
      this.formValue.memo = value
    },
    onConfirmClick(): void {
      this.$vfm.open(this.updateModalName)
    },
    async backListPage(): Promise<void> {
      await this.$router.push(`/negotiations`)
    },
    async onCancelClick(): Promise<void> {
      await this.backListPage()
    },
    onUpdateNegotiationCancel(): void {
      this.$vfm.close(this.updateModalName)
    },
    async onUpdateNegotiationConfirm(): Promise<void> {
      const payload = this.isStandardSpread
        ? this.buildSpreadPayload()
        : this.buildPayload()
      if (!payload) {
        return
      }
      this.updateSubmitting = true
      await this.updateNegotiation({ id: this.id, updateNegotiation: payload })
        .then(async () => {
          setNotification(this.successUpdateMessage)
          this.$vfm.close(this.updateModalName)
          await this.backListPage()
        })
        .catch(e => {
          setNotification(this.failUpdateMessage, 'danger')
          throw e
        })
        .finally(() => {
          this.updateSubmitting = false
        })
    },
    onDeleteNegotiationClick(): void {
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteNegotiationCancel(): void {
      this.$vfm.close(this.deleteModalName)
    },
    async onDeleteNegotiationConfirm(): Promise<void> {
      this.deleteSubmitting = true
      await this.deleteNegotiation({ id: this.id, memo: this.formValue.memo })
        .then(async () => {
          setNotification(
            this.$t('trading.message.successDeleteNegotiation').toString(),
          )
          this.$vfm.close(this.deleteModalName)
          await this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failDeleteNegotiation').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
    setCreditSleeverIsShown(isShown: boolean): void {
      this.creditSleeverIsShown = isShown
    },
  },
})
