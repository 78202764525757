import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-690b9df4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-email-address-new" }
const _hoisted_2 = { class: "organization-email-address-new__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_receive_email_form = _resolveComponent("receive-email-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_validation_form, null, {
      default: _withCtx(({ errors }) => [
        _createVNode(_component_base_box, { class: "organization-email-address-new__box" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_base_input_text, {
                value: _ctx.organizationEmailAddress.name,
                label: _ctx.$t('iam.label.name'),
                name: "name",
                required: true,
                rules: "required",
                onInput: _cache[0] || (_cache[0] = $event => (_ctx.organizationEmailAddress.name = $event))
              }, null, 8, ["value", "label"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_base_input_text, {
                value: _ctx.organizationEmailAddress.emailAddress,
                label: _ctx.$t('iam.label.mail'),
                name: "mail",
                required: true,
                rules: "required|email",
                onInput: _cache[1] || (_cache[1] = $event => (_ctx.organizationEmailAddress.emailAddress = $event))
              }, null, 8, ["value", "label"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_base_box, { class: "organization-email-address-new__box" }, {
          default: _withCtx(() => [
            _createVNode(_component_receive_email_form, {
              "form-value": _ctx.emailFormValue,
              onFormInput: _ctx.onInput
            }, null, 8, ["form-value", "onFormInput"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_button, {
            class: "organization-email-address-new__cancel-button",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_base_button, {
            class: "organization-email-address-new__save-button",
            color: "primary",
            disabled: Object.keys(errors).length > 0,
            loading: _ctx.isLoading,
            onClick: _ctx.onClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "loading", "onClick"])
        ])
      ]),
      _: 1
    })
  ]))
}