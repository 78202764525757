
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import SelectedIndicationContent from '@/components/trading/SelectedIndicationContent.vue'
import { Indication } from '@/models/trading/Indication'
import { Product } from '@/models/trading/Product'

export default defineComponent({
  components: {
    BaseBox,
    BaseIcon,
    PositionIcon,
    SelectedIndicationContent,
  },
  props: {
    isSelectable: {
      type: Boolean,
      required: true,
    },
    selectedIndication: {
      type: Object as PropType<Indication>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    product(): Product {
      return this.selectedIndication.products[0]
    },
    areaName(): string {
      return this.product.deliveryTerms.area.name.translation()
    },
    exchangeAreaName(): string | undefined {
      return this.product.exchangeDeliveryTerms?.area.name.translation()
    },
    deliveryPeriod(): string {
      return this.product.deliveryTerms.displayDeliveryPeriodWithDateName(
        this.product.deliveryUnit,
      )
    },
    exchangeDeliveryPeriod(): string | undefined {
      return this.product.exchangeDeliveryTerms?.displayDeliveryPeriodWithDateName(
        this.product.deliveryUnit,
      )
    },
    hourTypeName(): string {
      if (this.product.deliveryUnit !== DeliveryUnit.NonStandard) {
        return this.product.deliveryTerms.hourTypeName() || ''
      }
      return '-'
    },
    exchangeHourTypeName(): string {
      if (this.product.deliveryUnit !== DeliveryUnit.NonStandard) {
        return this.product.exchangeDeliveryTerms?.hourTypeName() || ''
      }
      return '-'
    },
    productTypeNames(): string {
      return this.selectedIndication.products
        .map(product => product.productType.translation())
        .join(this.$t('common.label.separator').toString())
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      if (this.isSelectable) {
        this.$emit('click')
      }
    },
  },
})
