import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58bb4206"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "occto-master-new" }
const _hoisted_2 = { class: "occto-master-new__form" }
const _hoisted_3 = { class: "occto-master-new__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_occto_master_item_form = _resolveComponent("occto-master-item-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, {
      "ui-stack": _ctx.uiStack,
      class: "occto-master-new__content"
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeOcctoMasterNew')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createVNode(_component_validation_form, null, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_occto_master_item_form, {
                locale: _ctx.userProfile.locale,
                "form-value": _ctx.formValue,
                areas: _ctx.areas,
                onFormInput: _ctx.onFormInput
              }, null, 8, ["locale", "form-value", "areas", "onFormInput"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_button, {
                class: "occto-master-new__cancel-button",
                onClick: _ctx.onCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                class: "occto-master-new__save-button",
                color: "primary",
                disabled: Object.keys(errors).length > 0,
                loading: _ctx.isLoading,
                onClick: _ctx.onClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "loading", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["ui-stack"])
  ]))
}