import {
  Locale,
  RoleType,
  Services,
  User as IUser,
  UserStatusType,
  UserType,
} from '@/api/generated'

export class User implements IUser {
  userId!: string
  name!: string
  mail!: string
  picture!: string
  organizationId!: string
  organizationName!: string
  roleInOrganization!: RoleType
  userType?: UserType
  status?: UserStatusType
  displayName?: string
  department!: string
  locale!: Locale
  services!: Services
  blocked!: boolean
}
