import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d89fe92e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eex-posting" }
const _hoisted_2 = {
  key: 0,
  class: "eex-posting__description"
}
const _hoisted_3 = { class: "eex-posting__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_eex_posting_form = _resolveComponent("eex-posting-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: _ctx.grayscale }, null, 8, ["type"])
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failInitializeEEXPosting')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_validation_form, null, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.formClassName)
            }, [
              _createVNode(_component_eex_posting_form, {
                mode: _ctx.mode,
                locale: _ctx.userProfile.locale,
                "form-value": _ctx.formValue,
                onFormInput: _ctx.onFormInput
              }, null, 8, ["mode", "locale", "form-value", "onFormInput"])
            ], 2),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_button, { onClick: _ctx.onCancelOrBackClick }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelOrBackButtonLabel), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                disabled: _ctx.isInvalid(Object.keys(errors).length > 0),
                color: "primary",
                onClick: _ctx.onConfirmClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.confirmButtonLabel), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["ui-stack"])
  ]))
}