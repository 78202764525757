
import { plainToClass } from 'class-transformer'
import { defineComponent, PropType } from 'vue'

import BrokingOrderBookSpreadItem from '@/components/trading/BrokingOrderBookSpreadItem.vue'
import BrokingOrderBookSpreadListHeader from '@/components/trading/BrokingOrderBookSpreadListHeader.vue'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingOrder } from '@/models/trading/BrokingOrder'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'

export default defineComponent({
  name: 'BrokingOrderBookSpreadList',
  components: {
    BrokingOrderBookSpreadItem,
    BrokingOrderBookSpreadListHeader,
  },
  props: {
    brokingOrderBooks: {
      type: Array as PropType<BrokingOrderBook[]>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    productTypeFilterCondition: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedIndicationIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    deals: {
      type: Array as PropType<BrokingDeal[]>,
      default: () => [],
    },
  },
  emits: ['record-select', 'copy-indication'],
  data(): { unexpandedList: string[] } {
    return {
      unexpandedList: [],
    }
  },
  computed: {
    allCollapsed(): boolean {
      return this.unexpandedList.length === this.brokingOrderBooks.length
    },
    filteredBrokingOrderBooks(): BrokingOrderBook[] {
      const dealIds = this.deals.map(deal => deal.dealId)

      const shouldIncludeBasedOnProductType = (order: BrokingOrder) =>
        this.productTypeFilterCondition.length === 0 ||
        this.productTypeFilterCondition.includes(order.productType.id)

      const shouldIncludeBasedOnDealId = (order: BrokingOrder) =>
        order.dealId === undefined ||
        (dealIds.length > 0 && dealIds.includes(order.dealId))

      return this.brokingOrderBooks
        .map(book => {
          const filteredOrder = book.orders.filter(
            order =>
              shouldIncludeBasedOnProductType(order) &&
              shouldIncludeBasedOnDealId(order),
          )

          return plainToClass(BrokingOrderBook, {
            ...book,
            orders: filteredOrder,
          })
        })
        .filter(book => book.orders.length !== 0)
    },
  },
  methods: {
    isExpandedOf(id: string): boolean {
      return !this.unexpandedList.includes(id)
    },
    onClick(id: string) {
      const index = this.unexpandedList.findIndex(item => item === id)
      if (index >= 0) {
        this.unexpandedList.splice(index, 1)
      } else {
        this.unexpandedList.push(id)
      }
    },
    onActivateAll() {
      this.unexpandedList = []
    },
    onDeactivateAll() {
      this.unexpandedList = this.brokingOrderBooks.map(item => item.id)
    },
    onRecordSelect(event: Event) {
      this.$emit('record-select', event)
    },
    onCopyIndication(event: Event) {
      this.$emit('copy-indication', event)
    },
  },
})
