
import { defineComponent, PropType } from 'vue'

import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'

export default defineComponent({
  name: 'DeliveryTermsWithPeriod',
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTermsWithPeriodProps>,
      required: true,
    },
  },
  computed: {
    areaNameClass(): Record<string, boolean> {
      return {
        'delivery-terms-with-period__text--spread': this.deliveryTerms
          .isAreaSpread,
      }
    },
    deliveryPeriodClass(): Record<string, boolean> {
      return {
        'delivery-terms-with-period__text--spread': this.deliveryTerms
          .isDeliveryPeriodSpread,
      }
    },
    deliveryPatternSpread(): Record<string, boolean> {
      return {
        'delivery-terms-with-period__text--spread': this.deliveryTerms
          .isDeliveryPatternSpread,
      }
    },
  },
})
