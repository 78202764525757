import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { ProductFilterCondition } from '@/store/modules/trading/publicIndication/interface'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export class PublicIndicationState {
  publicIndications = [] as PublicIndication[]
  publicDeals = [] as PublicDeal[]

  filterCondition = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
  } as ProductFilterCondition

  filterConditionWithFuelSurcharge = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
  } as ProductFilterCondition

  filterAttributes: BaseFilterAttribute[] = [
    'area',
    'startDeliveryYearMonth',
    'endDeliveryYearMonth',
    'hourType',
    'productType',
  ]

  selectedFilters = [] as BaseFilterAttribute[]
  selectedFiltersWithFuelSurcharge = [] as BaseFilterAttribute[]
}
