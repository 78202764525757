import { Module } from 'vuex-smart-module'

import { DealActions } from '@/store/modules/trading/deal/DealActions'
import { DealGetters } from '@/store/modules/trading/deal/DealGetters'
import { DealMutations } from '@/store/modules/trading/deal/DealMutations'
import { DealState } from '@/store/modules/trading/deal/DealState'

export const deal = new Module({
  actions: DealActions,
  mutations: DealMutations,
  state: DealState,
  getters: DealGetters,
})

export type DealModule = typeof deal
