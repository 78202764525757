
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'

export default defineComponent({
  name: 'MultiSelectDropdown',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array as PropType<ActionDropdownItem[]>,
      default: () => [],
    },
    selectedValues: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['click-button', 'change-list', 'click-outside'],
  data() {
    return {
      values: this.selectedValues,
    }
  },
  watch: {
    isActive() {
      if (this.isActive) {
        document.addEventListener('click', this.onClickOutside, false)
      } else {
        document.removeEventListener('click', this.onClickOutside, false)
      }
    },
    selectedValues: {
      handler() {
        this.values = this.selectedValues
      },
      deep: true,
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClickOutside, false)
  },
  methods: {
    onClickButton() {
      this.$emit('click-button')
    },
    onChange(event: string) {
      this.$emit('change-list', {
        selectedValues: this.values,
        changedValue: event,
      })
    },
    onClickOutside() {
      this.$emit('click-outside')
    },
  },
})
