
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit } from '@/api/generated'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'

export default defineComponent({
  name: 'DeliveryUnitIcon',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
  },
  computed: {
    isNonStandard(): boolean {
      return this.deliveryUnit === DeliveryUnit.NonStandard
    },
  },
})
