import { Type } from 'class-transformer'

import { LocalizedReport as ILocalizedReport } from '@/api/generated'
import { i18n } from '@/i18n'
import { ReportContent } from '@/models/reports/ReportContent'
import { moment } from '@/utils/date'

export class LocalizedReport implements ILocalizedReport {
  id!: string
  publishedAt!: string

  @Type(() => ReportContent)
  reportContent!: ReportContent

  displayPublishedAt(): string {
    return moment(this.publishedAt).format(i18n.t('common.format.dateTime'))
  }
}
