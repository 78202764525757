import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  ReportsApi,
  Locale,
  GetLocalizedReportUrl200Response,
} from '@/api/generated'
import { Report } from '@/models/reports/Report'

export type ReportCreatePayload = {
  reportTypes: string[]
  reportContents: {
    [_ in Locale]?: {
      title: string
      description: string
    }
  }
  attachmentFileJa?: File
  attachmentFileEn?: File
}

export type ReportUpdatePayload = ReportCreatePayload & {
  reportId: string
}

export class ReportsActions extends Actions<ReportsActions> {
  async fetchAttachmentUrl({
    reportId,
    reportContentId,
  }: {
    reportId: string
    reportContentId: string
  }): Promise<GetLocalizedReportUrl200Response> {
    return (
      await new ReportsApi(apiConfig).getReportUrl(reportId, reportContentId)
    ).data
  }

  async fetchReports(lastReportId?: string): Promise<Report[]> {
    return plainToClass(
      Report,
      (await new ReportsApi(apiConfig).getReports(lastReportId)).data,
    )
  }

  async fetchReport(reportId: string): Promise<Report> {
    return plainToClass(
      Report,
      (await new ReportsApi(apiConfig).getReport(reportId)).data,
    )
  }

  async createReport({
    reportTypes,
    reportContents,
    attachmentFileJa,
    attachmentFileEn,
  }: ReportCreatePayload): Promise<Report> {
    return plainToClass(
      Report,
      (
        await new ReportsApi(apiConfig).createReport(
          JSON.stringify(reportTypes),
          JSON.stringify(reportContents),
          attachmentFileJa,
          attachmentFileEn,
        )
      ).data,
    )
  }

  async updateReport({
    reportId,
    reportTypes,
    reportContents,
    attachmentFileJa,
    attachmentFileEn,
  }: ReportUpdatePayload): Promise<Report> {
    return plainToClass(
      Report,
      (
        await new ReportsApi(apiConfig).updateReport(
          reportId,
          JSON.stringify(reportTypes),
          JSON.stringify(reportContents),
          attachmentFileJa,
          attachmentFileEn,
        )
      ).data,
    )
  }

  async deleteReport(reportId: string) {
    await new ReportsApi(apiConfig).deleteReport(reportId)
  }
}
