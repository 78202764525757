import { Getters } from 'vuex-smart-module'

import { RecipientListProps } from '@/components/chat/interface/RecipientListProps'
import { PublicOrganizationProfile } from '@/models/iam/PublicOrganizationProfile'
import { store } from '@/store'
import { ChatBroadcastState } from '@/store/modules/chat/broadcast/ChatBroadcastState'

export class ChatBroadcastGetters extends Getters<ChatBroadcastState> {
  get recipientList(): RecipientListProps {
    const options = []
    const checkedValues = this.state.selectedOrganizationChannelIds
    for (const channel of this.state.organizationChannels) {
      const channelProfile = store.state.publicOrganizationProfile.publicOrganizationProfiles.find(
        (profile: PublicOrganizationProfile) =>
          profile.organizationId === channel.profileId,
      )

      if (
        channelProfile &&
        channelProfile.name
          .translation()
          .includes(this.state.recipientSearchInput)
      ) {
        const recipient = {
          label: channelProfile.name.translation(),
          locale: channelProfile.locale,
          value: channel.channelId,
        }

        if (
          this.state.recipientSelectedLanguage &&
          this.state.recipientSelectedLanguage.value === channelProfile.locale
        ) {
          options.push(recipient)
        } else if (!this.state.recipientSelectedLanguage) {
          options.push(recipient)
        }
      }
    }
    return { options, checkedValues }
  }
}
