import moment from 'moment'

import { ClearingInformationParticipantSummaryItem as IClearingInformationParticipantSummaryItem } from '@/api/generated/api'
import { i18n } from '@/i18n'

export class ClearingInformationParticipantSummaryItem
  implements IClearingInformationParticipantSummaryItem {
  updatedAt?: string
  lastUpdatedParticipantName?: string

  displayUpdatedAt(): string {
    return moment(this.updatedAt).format(
      `${i18n.t('common.format.formatDate').toString()} HH:mm`,
    )
  }
}
