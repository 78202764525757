import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a52add0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eex-posting-form" }
const _hoisted_2 = { class: "eex-posting-form__input-container" }
const _hoisted_3 = { class: "eex-posting-form__input" }
const _hoisted_4 = { class: "eex-posting-form__input" }
const _hoisted_5 = { class: "eex-posting-form__input" }
const _hoisted_6 = { class: "eex-posting-form__input" }
const _hoisted_7 = { class: "eex-posting-form__price-quantity-box" }
const _hoisted_8 = { class: "eex-posting-form__player-box" }
const _hoisted_9 = { class: "eex-posting-form__input" }
const _hoisted_10 = { class: "eex-posting-form__input" }
const _hoisted_11 = { class: "eex-posting-form__input" }
const _hoisted_12 = { class: "eex-posting-form__input" }
const _hoisted_13 = { class: "eex-posting-form__input" }
const _hoisted_14 = { class: "eex-posting-form__input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_delivery_date_form = _resolveComponent("delivery-date-form")!
  const _component_heading = _resolveComponent("heading")!
  const _component_organization_selector = _resolveComponent("organization-selector")!
  const _component_account_selector = _resolveComponent("account-selector")!
  const _component_trader_selector = _resolveComponent("trader-selector")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_base_input_text, {
            label: _ctx.$t('trading.label.tradeType').toString(),
            name: "tradeType",
            value: _ctx.$t('trading.label.tradeTypeValue').toString(),
            mode: _ctx.readonly
          }, null, 8, ["label", "value", "mode"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_base_input_text, {
            label: _ctx.$t('trading.label.assetClass').toString(),
            name: "assetClass",
            value: _ctx.$t('trading.label.assetClassValue').toString(),
            mode: _ctx.readonly
          }, null, 8, ["label", "value", "mode"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_base_single_select, {
            label: _ctx.$t('trading.label.eexProduct'),
            name: "eexProduct",
            value: _ctx.selectedProductCodeAsInputOption,
            options: _ctx.productCodeInputOptions,
            placeholder: 
              _ctx.$t('common.message.selectPlaceholder', {
                name: _ctx.$t('trading.label.eexProduct'),
              })
            ,
            mode: _ctx.inputMode,
            rules: "required",
            required: "",
            onInput: _ctx.onProductCodeInput
          }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_base_label, {
            label: _ctx.$t('trading.label.eexInstrument')
          }, null, 8, ["label"]),
          (_ctx.needToUseDeliveryDateForm)
            ? (_openBlock(), _createBlock(_component_delivery_date_form, {
                key: 0,
                index: 1,
                locale: _ctx.locale,
                mode: _ctx.inputMode,
                "form-value": _ctx.deliveryDateFormValue,
                "start-delivery-max-date": _ctx.startDeliveryMaxDate,
                "end-delivery-min-date": _ctx.endDeliveryMinDate,
                "is-shown-label": true,
                onFormInput: _cache[0] || (_cache[0] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  ...event,
                })
            )
              }, null, 8, ["locale", "mode", "form-value", "start-delivery-max-date", "end-delivery-min-date"]))
            : (_openBlock(), _createBlock(_component_base_input_text, {
                key: 1,
                name: "instrument",
                value: _ctx.formValue.instrument,
                mode: _ctx.readonly
              }, null, 8, ["value", "mode"])),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_base_input_text, {
              name: "price",
              value: _ctx.formValue.price,
              "value-type": "number",
              mode: _ctx.inputMode,
              label: _ctx.$t('trading.label.unitPrice'),
              placeholder: _ctx.$t('trading.label.halfWidthNumeric'),
              rules: "required|numeric",
              required: "",
              onInput: _ctx.onPriceInput
            }, null, 8, ["value", "mode", "label", "placeholder", "onInput"]),
            _createVNode(_component_base_input_text, {
              name: "quantity",
              value: _ctx.formValue.quantity,
              "value-type": "number",
              mode: _ctx.inputMode,
              label: _ctx.$t('trading.label.volumeShort'),
              placeholder: _ctx.$t('trading.label.halfWidthNumeric'),
              rules: "required|integer",
              required: "",
              onInput: _ctx.onQuantityInput
            }, null, 8, ["value", "mode", "label", "placeholder", "onInput"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_base_box, { class: "eex-posting-form__box" }, {
        default: _withCtx(() => [
          _createVNode(_component_heading, {
            type: _ctx.headerLevel3,
            class: "eex-posting-form__heading-buyer"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.label.buyerName')), 1)
            ]),
            _: 1
          }, 8, ["type"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.esquareOrganization')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_organization_selector, {
              id: "bid",
              "selected-organization": _ctx.formValue.bidEEXPostingDetail.organization,
              "is-selectable": !_ctx.isReadonly,
              "query-params": { isEsquareEnabled: true },
              onSelect: _cache[1] || (_cache[1] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  askEEXPostingDetail: {
                    ..._ctx.formValue.askEEXPostingDetail,
                  },
                  bidEEXPostingDetail: {
                    ..._ctx.formValue.bidEEXPostingDetail,
                    organization: event,
                    eexTradingFirm: undefined,
                    eexTrader: undefined,
                    eexClearingAccount: undefined,
                  },
                })
            )
            }, null, 8, ["selected-organization", "is-selectable"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_base_input_text, {
              label: _ctx.$t('trading.label.eexTradingFirm').toString(),
              name: "bidEexTradingFirm",
              value: _ctx.formValue.bidEEXPostingDetail.eexTradingFirm?.name,
              mode: _ctx.readonly
            }, null, 8, ["label", "value", "mode"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.account')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_account_selector, {
              id: "bid",
              "organization-id": 
              _ctx.formValue.bidEEXPostingDetail.organization?.organizationId
            ,
              "selected-account": _ctx.formValue.bidEEXPostingDetail.eexClearingAccount,
              "is-selectable": !_ctx.isReadonly,
              "query-params": undefined,
              onSelect: _cache[2] || (_cache[2] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  askEEXPostingDetail: {
                    ..._ctx.formValue.askEEXPostingDetail,
                  },
                  bidEEXPostingDetail: {
                    ..._ctx.formValue.bidEEXPostingDetail,
                    eexClearingAccount: event,
                  },
                })
            )
            }, null, 8, ["organization-id", "selected-account", "is-selectable"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.trader')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_trader_selector, {
              id: "bid",
              "organization-id": 
              _ctx.formValue.bidEEXPostingDetail.organization?.organizationId
            ,
              "selected-trader": _ctx.formValue.bidEEXPostingDetail.eexTrader,
              "is-selectable": !_ctx.isReadonly,
              onSelect: _cache[3] || (_cache[3] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  askEEXPostingDetail: {
                    ..._ctx.formValue.askEEXPostingDetail,
                  },
                  bidEEXPostingDetail: {
                    ..._ctx.formValue.bidEEXPostingDetail,
                    eexTrader: event,
                  },
                })
            )
            }, null, 8, ["organization-id", "selected-trader", "is-selectable"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_base_box, { class: "eex-posting-form__box" }, {
        default: _withCtx(() => [
          _createVNode(_component_heading, {
            type: _ctx.headerLevel3,
            class: "eex-posting-form__heading-seller"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.label.sellerName')), 1)
            ]),
            _: 1
          }, 8, ["type"]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.esquareOrganization')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_organization_selector, {
              id: "ask",
              "selected-organization": _ctx.formValue.askEEXPostingDetail.organization,
              "is-selectable": !_ctx.isReadonly,
              "query-params": { isEsquareEnabled: true },
              onSelect: _cache[4] || (_cache[4] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  askEEXPostingDetail: {
                    ..._ctx.formValue.askEEXPostingDetail,
                    organization: event,
                    eexTradingFirm: undefined,
                    eexTrader: undefined,
                    eexClearingAccount: undefined,
                  },
                  bidEEXPostingDetail: {
                    ..._ctx.formValue.bidEEXPostingDetail,
                  },
                })
            )
            }, null, 8, ["selected-organization", "is-selectable"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_base_input_text, {
              label: _ctx.$t('trading.label.eexTradingFirm').toString(),
              name: "askEexTradingFirm",
              value: _ctx.formValue.askEEXPostingDetail.eexTradingFirm?.name,
              mode: _ctx.readonly
            }, null, 8, ["label", "value", "mode"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.account')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_account_selector, {
              id: "ask",
              "organization-id": 
              _ctx.formValue.askEEXPostingDetail.organization?.organizationId
            ,
              "selected-account": _ctx.formValue.askEEXPostingDetail.eexClearingAccount,
              "is-selectable": !_ctx.isReadonly,
              "query-params": undefined,
              onSelect: _cache[5] || (_cache[5] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  askEEXPostingDetail: {
                    ..._ctx.formValue.askEEXPostingDetail,
                    eexClearingAccount: event,
                  },
                  bidEEXPostingDetail: {
                    ..._ctx.formValue.bidEEXPostingDetail,
                  },
                })
            )
            }, null, 8, ["organization-id", "selected-account", "is-selectable"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.trader')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_trader_selector, {
              id: "ask",
              "organization-id": 
              _ctx.formValue.askEEXPostingDetail.organization?.organizationId
            ,
              "selected-trader": _ctx.formValue.askEEXPostingDetail.eexTrader,
              "is-selectable": !_ctx.isReadonly,
              "query-params": undefined,
              onSelect: _cache[6] || (_cache[6] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  askEEXPostingDetail: {
                    ..._ctx.formValue.askEEXPostingDetail,
                    eexTrader: event,
                  },
                  bidEEXPostingDetail: {
                    ..._ctx.formValue.bidEEXPostingDetail,
                  },
                })
            )
            }, null, 8, ["organization-id", "selected-trader", "is-selectable"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}