import { plainToClass } from 'class-transformer'
import { Getters } from 'vuex-smart-module'

import { RoleType } from '@/api/generated'
import { validateName, validateDepartment } from '@/components/iam/validation'
import { UserProfile } from '@/models/iam/UserProfile'
import { UserProfileState } from '@/store/modules/iam/userProfile/UserProfileState'

export class UserProfileGetters extends Getters<UserProfileState> {
  get userProfile(): UserProfile {
    return plainToClass(UserProfile, {
      userId: this.state.userId,
      mail: this.state.mail,
      name: this.state.name,
      department: this.state.department,
      locale: this.state.locale,
      picture: this.state.picture,
      organizationId: this.state.organizationId,
      status: this.state.status,
      services: this.state.services,
    })
  }

  get isValidName(): boolean {
    let result = this.state.name !== this.state.nameOriginal
    result = result && validateName(this.state.name)
    return result
  }

  get isValidDepartment(): boolean {
    let result = this.state.department !== this.state.departmentOriginal
    result = result && validateDepartment(this.state.department)
    return result
  }

  get isValidLocale(): boolean {
    return this.state.locale !== this.state.localeOriginal
  }

  get isValidDisplaySetting(): boolean {
    return (
      this.state.services?.esquare.userSetting?.positionReversed !==
        this.state.servicesOriginal?.esquare.userSetting?.positionReversed ||
      this.state.services?.esquare.userSetting?.colorReversed !==
        this.state.servicesOriginal?.esquare.userSetting?.colorReversed
    )
  }

  get isEcompassEnabled(): boolean {
    if (
      this.state.services &&
      this.state.services.ecompass.appSetting?.enabled
    ) {
      return this.state.services.ecompass.appSetting.enabled
    }
    return false
  }

  get isEsquareEnabled(): boolean {
    return (
      !!this.state.services && !!this.state.services.esquare.appSetting?.enabled
    )
  }

  get isEclearEnabled(): boolean {
    return !!this.state.services?.eclear.appSetting?.enabled
  }

  get isEclearTrader(): boolean {
    return !!this.state.services?.eclear.appSetting?.isTrader
  }

  get isEclearDemandAndSupplyPlanner(): boolean {
    return !!this.state.services?.eclear.appSetting?.isDemandAndSupplyPlanner
  }

  get eclearTraderId(): string {
    return this.state.services?.eclear.appSetting?.traderId || ''
  }

  get eclearKeyword(): string {
    return this.state.services?.eclear.userSetting?.keyword || ''
  }

  get isOrganizationMember(): boolean {
    return this.state.roleInOrganization === RoleType.Member
  }
}
