import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { OrganizationsApi } from '@/api/generated'
import { OrganizationProfile } from '@/models/iam/OrganizationProfile'
import { OrganizationProfileGetters } from '@/store/modules/iam/organizationProfile/OrganizationProfileGetters'
import {
  OrganizationProfileMutations,
  SET_ORGANIZATION_PROFILE,
} from '@/store/modules/iam/organizationProfile/OrganizationProfileMutations'
import { OrganizationProfileState } from '@/store/modules/iam/organizationProfile/OrganizationProfileState'
import {
  arrayBuffer2JpegDataUrl,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class OrganizationProfileActions extends Actions<
  OrganizationProfileState,
  OrganizationProfileGetters,
  OrganizationProfileMutations,
  OrganizationProfileActions
> {
  async fetchOrganizationProfile(payload: { organizationId: string }) {
    const organizationProfile = plainToClass(
      OrganizationProfile,
      (
        await new OrganizationsApi(apiConfig).getOrganizationProfileById(
          payload.organizationId,
        )
      ).data,
    )
    if (isRequiredAuthenticatedRequest(organizationProfile.picture)) {
      const data = (
        await new OrganizationsApi(apiConfig).getPictureOfOrganization(
          payload.organizationId,
          {
            responseType: 'arraybuffer',
          },
        )
      ).data
      const dataurl = arrayBuffer2JpegDataUrl((data as unknown) as ArrayBuffer)
      organizationProfile.picture = dataurl
    }
    this.commit(SET_ORGANIZATION_PROFILE, {
      organizationProfile,
    })
  }
}
