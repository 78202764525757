
import { defineComponent, PropType } from 'vue'
import { Locale } from 'vue-i18n'

import { PositionType } from '@/api/generated'
import OrderBookUnitPriceAndVolume from '@/components/trading/OrderBookBestUnitPriceAndVolume.vue'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndicationOfProduct } from '@/models/trading/BrokingIndicationOfProduct'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndicationOfProduct } from '@/models/trading/PublicIndicationOfProduct'

type IndicationOfProduct =
  | PublicIndicationOfProduct
  | BrokingIndicationOfProduct
type Deal = PublicDeal | BrokingDeal

export default defineComponent({
  name: 'OrderBookBestPrice',
  components: {
    OrderBookUnitPriceAndVolume,
  },
  props: {
    indications: {
      type: Array as PropType<IndicationOfProduct[]>,
      default: () => [],
    },
    deal: {
      type: Object as PropType<Deal>,
      default: undefined,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bestIndicationAsk(): IndicationOfProduct | undefined {
      const askIndications = this.indications.filter(
        indication =>
          indication.position === PositionType.Ask &&
          this.isOpenToMarket(indication),
      )

      if (askIndications.length > 0) {
        return askIndications.reduce((a, b) =>
          a.unitPrice < b.unitPrice
            ? a
            : (a.price.basicUnitPrice?.unitPrice ?? 0) <
              (b.price.basicUnitPrice?.unitPrice ?? 0)
            ? a
            : b,
        )
      }
      return undefined
    },
    bestIndicationBid(): IndicationOfProduct | undefined {
      const bidIndications = this.indications.filter(
        indication =>
          indication.position === PositionType.Bid &&
          this.isOpenToMarket(indication),
      )

      if (bidIndications.length > 0) {
        return bidIndications.reduce((a, b) =>
          a.unitPrice > b.unitPrice
            ? a
            : (a.price.basicUnitPrice?.unitPrice ?? 0) >
              (b.price.basicUnitPrice?.unitPrice ?? 0)
            ? a
            : b,
        )
      }
      return undefined
    },
  },
  methods: {
    isOpenToMarket(indication: IndicationOfProduct): boolean {
      if (indication instanceof PublicIndicationOfProduct) {
        return true
      }
      if (indication instanceof BrokingIndicationOfProduct) {
        return indication.openToMarket
      }
      return false
    },
  },
})
