
import { defineComponent } from 'vue'

import ChannelPicture from '@/components/chat/ChannelPicture.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'

export default defineComponent({
  name: 'ChannelMember',
  components: {
    ChannelPicture,
    Sentences,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'inactive',
    },
  },
})
