import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a79612dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-select" }
const _hoisted_2 = { class: "channel-select__list" }
const _hoisted_3 = { class: "channel-select__cancel-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_channel_d_m = _resolveComponent("channel-d-m")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_input_text, {
      class: "channel-select__input-text",
      name: "searchInput",
      value: _ctx.searchInput,
      onInput: _ctx.onInputText
    }, null, 8, ["value", "onInput"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredChannelList(), (channel, index) => {
        return (_openBlock(), _createBlock(_component_channel_d_m, _mergeProps({
          key: index,
          class: "channel-select__list"
        }, channel, {
          selected: false,
          "display-organization-name": true,
          onClick: ($event: any) => (_ctx.onClickChannel(channel.channelId))
        }), null, 16, ["onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}