
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseButton from '@/components/common/BaseButton.vue'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import RecapReceiverForm from '@/components/iam/RecapReceiverForm.vue'
import { RecapReceiverFormProps } from '@/components/iam/interface/RecapReceiverFormProps'
import { RecapReceiverModule } from '@/store/modules/iam/recapReceiver'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapActions: recapReceiverMapActions } = createNamespacedHelpers(
  'recapReceiver',
) as RecapReceiverModule

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'RecapReceiverNew',
  components: {
    BaseButton,
    RecapReceiverForm,
    ValidationForm,
  },
  data(): {
    formValue: RecapReceiverFormProps
    submitting: boolean
  } {
    return {
      formValue: {
        department: undefined,
        name: undefined,
        phoneNumber: undefined,
        emailAddress: undefined,
      },
      submitting: false,
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
  },
  methods: {
    ...recapReceiverMapActions(['createRecapReceiver']),
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    async onSaveClick() {
      if (!this.formValue.name) {
        return
      }
      this.submitting = true
      await this.createRecapReceiver({
        organizationId: this._organizationId,
        recapReceiver: {
          department: this.formValue.department,
          name: this.formValue.name,
          phoneNumber: this.formValue.phoneNumber,
          emailAddress: this.formValue.emailAddress,
        },
      })
        .then(() => {
          setNotification(
            this.$t('iam.message.successCreateRecapReceiver').toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          const message =
            e.response.status === HttpStatus.Conflict
              ? this.$t('iam.message.failCreateRecapReceiverByDuplicate', {
                  name: this.formValue.name,
                }).toString()
              : this.$t('iam.message.failCreateRecapReceiver').toString()
          setNotification(message, 'danger')
          throw e
        })
        .finally(() => {
          this.submitting = false
        })
    },
    onFormInput(value: RecapReceiverFormProps) {
      Object.assign(this.formValue, value)
    },
  },
})
