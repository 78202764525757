import { RecapReceiverValue } from '@/api/generated/api'
import { i18n } from '@/i18n'

export class RecapReceiver implements RecapReceiverValue {
  name!: string
  department?: string
  phoneNumber?: string
  emailAddress?: string

  get displayDepartment(): string {
    return this.department || i18n.t('common.label.blank').toString()
  }

  get displayPhoneNumber(): string {
    return this.phoneNumber || i18n.t('common.label.blank').toString()
  }

  get displayEmailAddress(): string {
    return this.emailAddress || i18n.t('common.label.blank').toString()
  }
}
