import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e022cf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input-text" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = {
  key: 0,
  class: "control"
}
const _hoisted_4 = ["value", "placeholder"]
const _hoisted_5 = { class: "help is-danger" }
const _hoisted_6 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_7 = {
  key: 2,
  class: "base-input-text__value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_field = _resolveComponent("field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_field, {
      ref: "field",
      name: _ctx.name,
      value: _ctx.value,
      rules: _ctx.rules,
      "validate-on-input": "",
      "validate-on-change": "",
      "validate-on-mount": ""
    }, {
      default: _withCtx(({ field, meta }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_label, {
            label: _ctx.labelString,
            help: _ctx.help,
            class: "base-input-text__label"
          }, null, 8, ["label", "help"]),
          (_ctx.inputable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("input", _mergeProps({
                  class: ["input base-input-text__input", {
              'is-danger':
                (meta.touched || meta.dirty || _ctx.forceShownErrorMessage) &&
                (!!_ctx.validationErrorMessage || !meta.valid),
            }],
                  type: "text"
                }, field, {
                  value: _ctx.value,
                  placeholder: _ctx.placeholder,
                  onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
                  onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
                  onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
                  onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
                  onCompositionstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onCompositionstart && _ctx.onCompositionstart(...args))),
                  onCompositionend: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onCompositionend && _ctx.onCompositionend(...args)))
                }), null, 16, _hoisted_4),
                (meta.touched || meta.dirty || _ctx.forceShownErrorMessage)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_error_message, { name: _ctx.name }, {
                        default: _withCtx(({ message }) => [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(message), 1)
                        ]),
                        _: 1
                      }, 8, ["name"]),
                      (!!_ctx.validationErrorMessage)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.validationErrorMessage), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.editable)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "base-input-text__value--editable",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
                }, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.value), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.value), 1)
                ]))
        ])
      ]),
      _: 1
    }, 8, ["name", "value", "rules"])
  ]))
}