
import { defineComponent, PropType } from 'vue'

import { OrderFuelSurchargeType, FuelSurchargeTypeType } from '@/api/generated'
import BaseTooltip from '@/components/common/BaseTooltip.vue'

export default defineComponent({
  name: 'FuelSurchargeTypeIcon',
  components: { BaseTooltip },
  props: {
    fuelSurchargeType: {
      type: Object as PropType<OrderFuelSurchargeType>,
      required: true,
    },
  },
  computed: {
    iconType(): string {
      switch (this.fuelSurchargeType.type) {
        case FuelSurchargeTypeType.UltraHigh:
          return '🅤'
        case FuelSurchargeTypeType.High:
          return '🅗'
        case FuelSurchargeTypeType.Low:
          return '🅛'
        case FuelSurchargeTypeType.Jera:
          return 'Ⓙ'
        case FuelSurchargeTypeType.Backup:
          return '常'
        default:
          return 'Ⓕ'
      }
    },
  },
})
