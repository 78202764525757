// https://auth0.com/docs/quickstart/spa/vuejs
import { RouteLocationNormalized } from 'vue-router'

import router from '@/router'

export const routeExistsGuard = (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: CallableFunction,
) => {
  if (to.matched.length === 0) {
    router.replace('/')
    return
  }
  return next()
}
