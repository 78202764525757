import { Type } from 'class-transformer'

import {
  OrderBook as IOrderBook,
  DeliveryUnit,
  SpreadType,
} from '@/api/generated'
import { SPREAD_SYMBOL } from '@/components/common/constants/Spread'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { Order } from '@/models/trading/Order'
import { SpreadDeliveryTerms } from '@/models/trading/SpreadDeliveryTerms'

export class OrderBook implements IOrderBook {
  deliveryTermsId!: string
  exchangeDeliveryTermsId?: string
  deliveryUnit!: DeliveryUnit
  askUnitPrice?: string
  bidUnitPrice?: string
  lastUnitPrice?: string
  spreadTypes?: SpreadType[]
  executedAt?: string

  @Type(() => SpreadDeliveryTerms)
  base!: SpreadDeliveryTerms

  @Type(() => SpreadDeliveryTerms)
  exchange?: SpreadDeliveryTerms

  @Type(() => Order)
  orders!: Order[]

  get id(): string {
    return this.deliveryTermsId + (this.exchangeDeliveryTermsId ?? '')
  }

  get isSpread(): boolean {
    return !!this.exchange
  }

  get isAreaSpread(): boolean {
    return !!this.exchange?.area && this.base.area.id !== this.exchange?.area.id
  }

  get isDeliveryPeriodSpread(): boolean {
    return (
      !!this.exchange?.deliveryPeriod &&
      this.base.deliveryPeriod !== this.exchange?.deliveryPeriod
    )
  }

  get isDeliveryPatternSpread(): boolean {
    return (
      !!this.exchange?.hourType &&
      this.base.hourType !== this.exchange?.hourType
    )
  }

  // BrokingOrderBookとの互換性を保つためのプロパティ。常にundefinedとして扱う。
  get lastDealPrivateConfidential(): undefined {
    return undefined
  }

  deliveryTerms(): DeliveryTermsWithPeriodProps {
    const areaName = this.isAreaSpread
      ? `${this.base.area.name} ${SPREAD_SYMBOL} ${this.exchange?.area.name}`
      : this.base.area.name

    const deliveryPeriod = this.isDeliveryPeriodSpread
      ? `${this.base.deliveryPeriod} ${SPREAD_SYMBOL} ${this.exchange?.deliveryPeriod}`
      : this.base.deliveryPeriod

    const deliveryPatternSpread = this.isDeliveryPatternSpread
      ? `${this.base.hourType.name} ${SPREAD_SYMBOL} ${this.exchange?.hourType.name}`
      : this.base.hourType.name

    return {
      isAreaSpread: this.isAreaSpread,
      isDeliveryPeriodSpread: this.isDeliveryPeriodSpread,
      isDeliveryPatternSpread: this.isDeliveryPatternSpread,
      areaName,
      deliveryPeriod,
      hourTypeName: deliveryPatternSpread,
    }
  }

  deliveryYearMonthStartList(): { year: number; month: number }[] {
    const idList: { year: number; month: number }[] = []
    const dateCandidates: string[] = []

    const baseStart = this.base.deliveryStartDate
    if (baseStart) {
      dateCandidates.push(baseStart)
    }
    if (this.isSpread) {
      const exchangeStart = this.exchange?.deliveryStartDate
      if (exchangeStart) {
        dateCandidates.push(exchangeStart)
      }
    }

    for (const dateStr of dateCandidates) {
      const date = dateStr.split('-')
      idList.push({ year: parseInt(date[0]), month: parseInt(date[1]) })
    }
    return idList
  }

  deliveryYearMonthEndList(): { year: number; month: number }[] {
    const idList: { year: number; month: number }[] = []
    const dateCandidates: string[] = []

    const baseStart = this.base.deliveryEndDate
    if (baseStart) {
      dateCandidates.push(baseStart)
    }
    if (this.isSpread) {
      const exchangeStart = this.exchange?.deliveryEndDate
      if (exchangeStart) {
        dateCandidates.push(exchangeStart)
      }
    }

    for (const dateStr of dateCandidates) {
      const date = dateStr.split('-')
      idList.push({ year: parseInt(date[0]), month: parseInt(date[1]) })
    }
    return idList
  }
}
