import { createRouter, createWebHistory } from 'vue-router'

import { authGuard } from '@/plugins/auth/authGuard'
import { esquareGuard } from '@/plugins/esquare/esquareGuard'
import { memberGuard } from '@/plugins/member/memberGuard'
import { routeExistsGuard } from '@/plugins/routeExists/routeExistsGuard'
import { welcomeGuard } from '@/plugins/welcome/welcomeGuard'
import { getRoutes } from '@/router/routes'

const routes = getRoutes(process.env.VUE_APP_APPLICATION_TYPE)

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
})

router.beforeEach(authGuard)
router.beforeEach(welcomeGuard)
router.beforeEach(memberGuard)
router.beforeEach(routeExistsGuard)
router.beforeEach(esquareGuard)

export default router
