import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0486e26e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-book-detail-item__product-type-wrapper" }
const _hoisted_2 = { class: "broking-order-book-detail-item__product-type" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "broking-order-book-detail-item__fuel-surcharge-type-name" }
const _hoisted_5 = ["checked", "disabled"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "broking-order-book-detail-item__created-participant-name-text" }
const _hoisted_9 = { class: "broking-order-book-detail-item__public-information" }
const _hoisted_10 = { class: "broking-order-book-detail-item__price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_type = _resolveComponent("product-type")!
  const _component_broking_indication_status_changer = _resolveComponent("broking-indication-status-changer")!
  const _component_public_information = _resolveComponent("public-information")!
  const _component_order_book_price = _resolveComponent("order-book-price")!
  const _component_broking_order_book_command = _resolveComponent("broking-order-book-command")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createBlock(_component_base_tooltip, {
    "is-active": _ctx.isIndication,
    label: _ctx.editorialInformation,
    "full-width": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "broking-order-book-detail-item",
        onMouseover: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('targeting', _ctx.item.indicationId))),
        onMouseleave: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('targeting')))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_product_type, { name: _ctx.productTypeName }, null, 8, ["name"])
          ]),
          (_ctx.fuelSurchargeName)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "broking-order-book-detail-item__fuel-surcharge-type-name-wrapper",
                title: _ctx.fuelSurchargeName
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.fuelSurchargeName), 1)
              ], 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["broking-order-book-detail-item__order-wrapper", {
          'broking-order-book-detail-item__order-wrapper--is-target': _ctx.isTarget,
        }]),
          onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.directDispatchEvent && _ctx.directDispatchEvent(...args)), ["stop"]))
        }, [
          _createElementVNode("label", {
            class: _normalizeClass(["broking-order-book-detail-item__organization-name-wrapper", {
            'broking-order-book-detail-item__organization-name-wrapper--not-open-to-market': !_ctx.item.openToMarket,
          }])
          }, [
            _createElementVNode("input", {
              checked: _ctx.item.selected,
              type: "checkbox",
              class: "broking-order-book-detail-item__checkbox checkbox",
              disabled: _ctx.item.recordType === 'deal',
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRecordSelect()))
            }, null, 40, _hoisted_5),
            _createElementVNode("span", {
              class: _normalizeClass(["broking-order-book-detail-item__organization-name", {
              'broking-order-book-detail-item__organization-name--not-open-to-market': !_ctx.item.openToMarket,
            }]),
              title: _ctx.item.organizationName
            }, _toDisplayString(_ctx.displayOrganizationName), 11, _hoisted_6)
          ], 2),
          _createElementVNode("div", {
            class: "broking-order-book-detail-item__created-participant-name",
            title: _ctx.item.createdParticipantName
          }, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.item.createdParticipantName), 1)
          ], 8, _hoisted_7),
          _createVNode(_component_broking_indication_status_changer, {
            id: (_ctx.item.indicationId || _ctx.item.dealId) + `_${_ctx.item.product.id}--1`,
            "indication-id": _ctx.item.indicationId,
            status: _ctx.item.status,
            "status-types": _ctx.statusTypes,
            "is-eclear-indication": _ctx.item.isEclearIndication,
            onDispatchDirectEvent: _ctx.directDispatchEvent,
            onActivate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('activate', $event))),
            onDeactivate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('deactivate', $event)))
          }, null, 8, ["id", "indication-id", "status", "status-types", "is-eclear-indication", "onDispatchDirectEvent"]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.item.publicInformation)
              ? (_openBlock(), _createBlock(_component_public_information, {
                  key: 0,
                  information: _ctx.item.publicInformation,
                  direction: _ctx.tooltipDirection
                }, null, 8, ["information", "direction"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_order_book_price, {
              "record-type": _ctx.item.recordType,
              price: _ctx.item.price,
              "unit-price": _ctx.item.unitPrice,
              volume: _ctx.item.volume,
              "created-at": _ctx.item.createdAt,
              "fuel-surcharge-type": _ctx.item.fuelSurchargeType,
              locale: _ctx.locale,
              "color-reversed": _ctx.colorReversed,
              "position-reversed": _ctx.positionReversed,
              "is-edit-mode": _ctx.isOrderBookPriceEditable,
              submitting: _ctx.submitting,
              "mask-volume": _ctx.item.maskVolume,
              "open-to-market": _ctx.item.openToMarket,
              onChange: _ctx.onChange,
              onFocus: _ctx.onFocus,
              onUnitPriceAndVolumeClick: _ctx.onUnitPriceAndVolumeClick
            }, null, 8, ["record-type", "price", "unit-price", "volume", "created-at", "fuel-surcharge-type", "locale", "color-reversed", "position-reversed", "is-edit-mode", "submitting", "mask-volume", "open-to-market", "onChange", "onFocus", "onUnitPriceAndVolumeClick"])
          ]),
          _createVNode(_component_broking_order_book_command, {
            class: "broking-order-book-detail-item__command",
            "command-type": _ctx.item.commandType,
            "dropdown-id": 
            (_ctx.item.indicationId || _ctx.item.dealId) + `_${_ctx.item.product.id}--2`
          ,
            "indication-id": _ctx.item.indicationId,
            "deal-id": _ctx.item.dealId,
            "open-to-market": _ctx.item.openToMarket,
            "is-visible-to-traders": _ctx.item.isVisibleToTraders,
            submitting: _ctx.submitting,
            status: _ctx.item.status,
            onNewMessage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onNewMessage())),
            onCopyClipboard: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCopyClipboard())),
            onCopyIndication: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCopyIndication())),
            onOpenToMarketChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('open-to-market-change', $event))),
            onVisibleToTradersChange: _cache[7] || (_cache[7] = ($event: any) => (
            _ctx.$emit('visible-to-traders-change', $event)
          )),
            onUpdateIndication: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update-indication', $event)))
          }, null, 8, ["command-type", "dropdown-id", "indication-id", "deal-id", "open-to-market", "is-visible-to-traders", "submitting", "status"])
        ], 2)
      ], 32)
    ]),
    _: 1
  }, 8, ["is-active", "label"]))
}