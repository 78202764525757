import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-indication-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_form = _resolveComponent("product-form")!
  const _component_indication_confirm_modal_content = _resolveComponent("indication-confirm-modal-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isEditing)
      ? (_openBlock(), _createBlock(_component_product_form, {
          key: 0,
          mode: _ctx.mode,
          locale: _ctx.locale,
          "position-reversed": _ctx.positionReversed,
          "selectable-inputs": _ctx.selectableInputs,
          "form-value": _ctx.formValue,
          "opened-spread-form-types": _ctx.openedSpreadFormTypes,
          "product-types": _ctx.productTypes,
          "date-names": _ctx.dateNames,
          onFormInput: _cache[0] || (_cache[0] = event => _ctx.$emit('form-input', event)),
          onTextInput: _cache[1] || (_cache[1] = event => _ctx.$emit('text-input', event)),
          onStandardDeliveryTermsFormOpen: _ctx.onStandardDeliveryTermsFormOpen,
          onStandardDeliveryTermsFormClose: _ctx.onStandardDeliveryTermsFormClose
        }, null, 8, ["mode", "locale", "position-reversed", "selectable-inputs", "form-value", "opened-spread-form-types", "product-types", "date-names", "onStandardDeliveryTermsFormOpen", "onStandardDeliveryTermsFormClose"]))
      : (_openBlock(), _createBlock(_component_indication_confirm_modal_content, {
          key: 1,
          "form-value": _ctx.formValue,
          "selectable-inputs": _ctx.selectableInputs,
          locale: _ctx.locale,
          message: ""
        }, null, 8, ["form-value", "selectable-inputs", "locale"]))
  ]))
}