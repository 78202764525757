import { RouteRecordRaw } from 'vue-router'

import Report from '@/views/reports/Report.vue'
import ReportNew from '@/views/reports/ReportNew.vue'
import Reports from '@/views/reports/Reports.vue'

/**
 * パス: /reports/...
 */
export const reportsRoutes: RouteRecordRaw[] = [
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/reports/new',
    name: 'ReportNew',
    component: ReportNew,
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: Report,
    props: route => ({ id: route.params.id }),
  },
]
