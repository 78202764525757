import { Client, PushNotification } from '@twilio/conversations'
import firebase from 'firebase/app'
import { App, createVNode, defineComponent, render } from 'vue'

import '@firebase/messaging'
import { DMChannel } from '@/models/chat/DMChannel'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'
import { root, store } from '@/store'
import { SET_IS_FETCHING_CHANNEL_LIST } from '@/store/modules/chat/chat/ChatMutations'
import { unix } from '@/utils/date'

const ctx = root.context(store)
const chatCtx = ctx.modules.chat
const userProfileCtx = ctx.modules.userProfile
const publicUserProfileCtx = ctx.modules.publicUserProfile
const publicOrganizationProfileCtx = ctx.modules.publicOrganizationProfile

export interface TwilioPluginOptions {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export const useTwilioChat = (options: TwilioPluginOptions) => {
  const component = defineComponent({
    render() {
      return ''
    },
    data() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        client: null as any,
      }
    },
    watch: {
      client: async function() {
        chatCtx.mutations[SET_IS_FETCHING_CHANNEL_LIST]({
          isFetchingChannelList: true,
        })
        await chatCtx.dispatch('loadChannelList', {
          client: this.client,
          user: userProfileCtx.getters.userProfile,
          vm: this,
        })
        chatCtx.mutations[SET_IS_FETCHING_CHANNEL_LIST]({
          isFetchingChannelList: false,
        })
        await Promise.all([
          publicUserProfileCtx.dispatch('fetchPublicUserProfilesByIds', {
            userIds: store.state.chat.channelList.dMChannels.map(
              (channel: DMChannel) => channel.profileId,
            ),
          }),
          publicOrganizationProfileCtx.dispatch(
            'fetchPublicOrganizationProfilesByIds',
            {
              organizationIds: store.state.chat.channelList.organizationChannels.map(
                (channel: OrganizationChannel) => channel.profileId,
              ),
            },
          ),
        ])
      },
    },
    created() {
      const timerId = setInterval(async () => {
        if (!userProfileCtx.state.userId) {
          return
        }
        clearInterval(timerId)
        const token = await this.fetchToken()
        this.client = new Client(token)

        // Setup FCM
        const firebaseConfig = {
          apiKey: options.apiKey,
          authDomain: options.authDomain,
          databaseURL: options.databaseURL,
          projectId: options.projectId,
          storageBucket: options.storageBucket,
          messagingSenderId: options.messagingSenderId,
          appId: options.appId,
          measurementId: options.messagingSenderId,
        }
        firebase.initializeApp(firebaseConfig)
        if (firebase.messaging()) {
          const fcmToken = await firebase.messaging().getToken()
          await this.client.setPushRegistrationId('fcm', fcmToken)
        }
        firebase.messaging().onMessage(payload => {
          this.client.handlePushNotification(payload)
        })

        this.client.on(
          'pushNotification',
          (pushNotification: PushNotification) => {
            console.log(pushNotification)
          },
        )

        this.client.on('tokenAboutToExpire', async () => {
          const newToken = await this.fetchToken()
          await this.client.updateToken(newToken)
          console.log('token refreshed')
        })
      }, 100)
    },
    methods: {
      async fetchToken(): Promise<string> {
        return (await chatCtx.actions.getAccessToken()) as string
      },
      getTokenExpireDate() {
        const token = this.client.token
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const decodedToken = JSON.parse(
          decodeURIComponent(escape(window.atob(base64))),
        )
        return decodedToken.exp
      },
      isTokenExpired() {
        const currentDate = new Date()
        const expireDate = unix(this.getTokenExpireDate()).toDate()
        return currentDate > expireDate
      },
      async refreshTokenIfExpired() {
        if (this.isTokenExpired()) {
          const newToken = await this.fetchToken()
          await this.client.updateToken(newToken)
        }
      },
    },
  })
  const vNode = createVNode(component)
  render(vNode, document.createElement('div'))
  return vNode.component?.proxy
}

export const TwilioChatPlugin = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: App, options?: TwilioPluginOptions) {
    if (options) {
      const TOKEN = '$chat'
      const INJECTION_KEY = Symbol(TOKEN)
      const instance = useTwilioChat(options)
      app.config.globalProperties[TOKEN] = instance
      app.provide(INJECTION_KEY, instance)
    }
  },
}
