
import { ErrorMessage, Field } from 'vee-validate'
import { defineComponent } from 'vue'

import BaseLabel from '@/components/common/BaseLabel.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { toHalfWidthNumber } from '@/utils/utils'

export default defineComponent({
  name: 'BaseInputText',
  components: {
    BaseLabel,
    ErrorMessage,
    Field,
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    valueType: {
      type: String,
      default: 'string',
      validator: (value: 'string' | 'number') => {
        return ['string', 'number'].includes(value)
      },
    },
    rules: {
      type: String,
      default: undefined,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    help: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isShownErrorMessage: {
      type: Boolean,
      default: true,
    },
    validationErrorMessage: {
      type: String,
      default: undefined,
    },
    forceShownErrorMessage: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
    'edit',
    'focus',
    'blur',
    'keydown',
    'compositionstart',
    'compositionend',
  ],
  computed: {
    inputable(): boolean {
      return this.mode === InputMode.INPUT
    },
    editable(): boolean {
      return this.mode === InputMode.EDIT
    },
    labelString(): string | undefined {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  watch: {
    rules() {
      if (!this.value) {
        return
      }
      const field = this.$refs.field as InstanceType<typeof Field>
      if (field) {
        field.validate()
      }
    },
  },
  methods: {
    onInput(event: InputEvent) {
      if (event.target instanceof HTMLInputElement) {
        this.$emit('input', event.target.value)
      }
    },
    onClick() {
      this.$emit('edit')
    },
    onFocus(event: Event) {
      this.$emit('focus', event)
    },
    onBlur(event: Event) {
      this.$emit('blur', event)
    },
    onKeydown(event: KeyboardEvent) {
      this.$emit('keydown', event)
    },
    onCompositionstart() {
      this.$emit('compositionstart')
    },
    onCompositionend() {
      if (this.valueType === 'number' && this.value !== undefined) {
        this.$emit('input', toHalfWidthNumber(this.value.toString()))
      }
      this.$emit('compositionend')
    },
  },
})
