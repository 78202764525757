
import { defineComponent, PropType } from 'vue'

import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { BrokerageFeePaymentConfigurationFormProps } from '@/components/iam/interface/BrokerageFeePaymentConfigurationFormProps'

export default defineComponent({
  name: 'BrokerageFeePaymentConfigurationForm',
  components: {
    BaseSingleSelect,
  },
  props: {
    formValue: {
      type: Object as PropType<BrokerageFeePaymentConfigurationFormProps>,
      required: true,
    },
    timingInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: string
  } {
    return {
      formInputEventName: 'form-input',
    }
  },
})
