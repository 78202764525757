import { Type } from 'class-transformer'

import {
  DeliveryUnit,
  ProductTypeDateName as IProductTypeDateName,
} from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class ProductTypeDateName implements IProductTypeDateName {
  id!: string
  productTypeId!: string
  deliveryUnit!: DeliveryUnit
  date!: string

  @Type(() => MultiLocaleName)
  name!: MultiLocaleName

  get dateName(): string | undefined {
    return this.name.translation()
  }
}
