
import debounce from 'lodash/debounce'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { IndicationStatusType, Locale, PositionType } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import IndicationSelectorModalList from '@/components/trading/IndicationSelectorModalList.vue'
import IndicationSelectorModalListHeader from '@/components/trading/IndicationSelectorModalListHeader.vue'
import { Indication } from '@/models/trading/Indication'
import { ProductSubType } from '@/models/trading/ProductSubType'
import { IndicationModule } from '@/store/modules/trading/indication'

const { mapActions: indicationsMapActions } = createNamespacedHelpers(
  'indication',
) as IndicationModule

export default defineComponent({
  name: 'IndicationSelectorModal',
  components: {
    BaseAlert,
    BaseButton,
    BaseInputText,
    CenteredLoadingContent,
    IndicationSelectorModalList,
    IndicationSelectorModalListHeader,
    UiStackSelector,
  },
  props: {
    fuelSurchargeTypeId: {
      type: String,
      default: undefined,
    },
    otherDeliveryTermsId: {
      type: String,
      default: undefined,
    },
    otherExchangeDeliveryTermsId: {
      type: String,
      default: undefined,
    },
    productId: {
      type: String,
      default: undefined,
    },
    productTypeId: {
      type: String,
      default: undefined,
    },
    otherProductSubType: {
      type: String as PropType<ProductSubType>,
      default: undefined,
    },
    position: {
      type: String as PropType<PositionType>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    value: string
    indications: Indication[]
    uiStack: UiStack
    alertTypeInfo: AlertType
    alertTypeError: AlertType
  } {
    return {
      value: '',
      indications: [],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
    }
  },
  computed: {
    fetchIndicationsParams(): {
      organizationName?: string
      productId?: string
      productTypeId?: string
      status?: IndicationStatusType
      position?: PositionType
      deliveryTermsId?: string
      exchangeDeliveryTermsId?: string
      fuelSurchargeTypeId?: string
      excludeTbd: boolean
      productSubType?: ProductSubType
    } {
      return {
        organizationName: this.value,
        productId: this.productId,
        productTypeId: this.productTypeId,
        status: IndicationStatusType.Active,
        position: this.position,
        deliveryTermsId: this.otherDeliveryTermsId,
        exchangeDeliveryTermsId: this.otherExchangeDeliveryTermsId,
        fuelSurchargeTypeId: this.fuelSurchargeTypeId,
        excludeTbd: false,
        productSubType: this.otherProductSubType,
      }
    },
  },
  async created() {
    await this.search()
  },
  methods: {
    fetchIndications: indicationsMapActions(['fetchIndications'])
      .fetchIndications as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any,
    ) => Promise<Indication[]>,
    async search() {
      await this.fetchIndications(this.fetchIndicationsParams)
        .then(indications => {
          this.indications = indications
          this.uiStack = indications.length > 0 ? UiStack.Ideal : UiStack.Blank
        })
        .catch(() => {
          this.uiStack = UiStack.Error
        })
    },
    debouncedSearch: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.search()
    }, 500),
    onSelect(indication: Indication) {
      this.$emit('select', indication)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onInput(value: string) {
      this.value = value
      this.debouncedSearch()
    },
  },
})
