import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "eex-posting-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_posting_list_item = _resolveComponent("posting-list-item")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eexPostings, (eexPosting, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_posting_list_item, {
        key: index,
        "eex-posting": eexPosting,
        "is-action-dropdown-active": eexPosting.id === _ctx.openActionDropdownId,
        onOnDropdownClick: _ctx.onDropdownClick,
        onOnDropdownOutsideClick: _ctx.onDropdownOutsideClick,
        onOnRepostClick: _ctx.onRepostClick
      }, null, 8, ["eex-posting", "is-action-dropdown-active", "onOnDropdownClick", "onOnDropdownOutsideClick", "onOnRepostClick"])), [
        [_directive_observe_visibility, 
        index === _ctx.eexPostings.length - 1 ? _ctx.visibilityChanged : false
      ]
      ])
    }), 128))
  ]))
}