import uniq from 'lodash/uniq'

import { FuelSurchargeTypeType } from '@/api/generated'
import { i18n } from '@/i18n'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { ContractCondition } from '@/models/trading/ContractCondition'
import { Deal } from '@/models/trading/Deal'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import {
  DeliverySummaries as DeliverySummariesModel,
  DeliverySummaryWithCalendarTypeName,
} from '@/models/trading/DeliverySummaries'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'
import {
  convertDate,
  convertDeliveryTermsAnnex,
  convertDisplayDeliveryPeriod,
  convertWithNone,
  convertRecapDetail,
  convertReceiverInfo,
  filterReceiveMemberEmails,
  filterReceiveOrganizationMemberEmails,
  FooterMail,
  FooterPhoneNumber,
  isNonStandard,
  RECAP_ORDER_BID,
  RECAP_ORDER_BID_SPREAD,
  RECAP_ORDER_ASK,
  RECAP_ORDER_ASK_SPREAD,
  RecapEndpointType,
  SERVICE,
  convertProductName,
  getRecapTitle,
  convertQuantity,
  MAX_DIGIT,
  forceShowNote,
} from '@/store/modules/trading/recap/helper'
import {
  BodyBg,
  DataDetail,
  RecapCreatedOption,
  RecapDetail,
  RecapStatus,
} from '@/store/modules/trading/recap/interface'

const convertDisplayFee = (fee: number, locale?: string): string => {
  if (fee === 0) {
    if (locale === 'en') {
      return 'None'
    }
    return i18n.t('common.label.none')
  }
  return `${fee.toLocaleString(undefined, {
    maximumFractionDigits: MAX_DIGIT,
  })} ${i18n.t('trading.label.unitPriceUnit')} ${i18n.t(
    'trading.label.recapFeeDescription',
  )}`
}

const convertUnitPrice = (deal: Deal, prices: number[]): string => {
  if (!prices.length) {
    return ''
  }
  if (prices.length > 1 && !deal.fuelSurchargeType?.type) {
    return i18n.t('trading.label.recapSeeAppendix')
  }

  if (!deal.fuelSurchargeType?.type) {
    return `${prices[0]} ${i18n.t(
      'trading.label.recapUnitPriceUnitWithoutTax',
    )}`
  }

  const uniqPrice = uniq(prices)

  switch (deal.fuelSurchargeType?.type) {
    case FuelSurchargeTypeType.High:
    case FuelSurchargeTypeType.UltraHigh:
    case FuelSurchargeTypeType.Low:
    case FuelSurchargeTypeType.Backup:
    case FuelSurchargeTypeType.JeraFy24Tokyo:
    case FuelSurchargeTypeType.JeraFy24Chubu1:
    case FuelSurchargeTypeType.JeraFy24Chubu2:
      if (uniqPrice.length > 1) {
        return `${i18n.t('trading.label.recapSeeAppendix')} ${i18n.t(
          'trading.label.recapSeeNoteFuelSurcharge',
        )}`
      }

      return `${prices[0]} ${i18n.t(
        'trading.label.recapUnitPriceUnitWithoutTax',
      )} + ${deal.fuelSurchargeType?.translation()}${i18n.t(
        'trading.message.fuelSurchargeTypeNameSuffix',
      )} ${i18n.t('trading.label.recapSeeNoteFuelSurcharge')}`
  }

  return i18n.t('trading.label.recapSeeNote')
}

const convertBgNoFuelSurchargeDataDetail = (
  userName: string,
  sellerInfo: string[],
  buyerInfo: string[],
  deal: Deal,
  deliveryTerms: DeliveryTerms,
  contractCondition: ContractCondition,
  organizationRecap: OrganizationRecap,
  recapCreatedOptions: RecapCreatedOption,
  deliverySummaryWithCalendarTypeName?: DeliverySummaryWithCalendarTypeName,
): BodyBg => {
  const getNote = (note?: string): string[] => {
    if (
      deal.fuelSurchargeType?.type === FuelSurchargeTypeType.High ||
      deal.fuelSurchargeType?.type === FuelSurchargeTypeType.UltraHigh ||
      deal.fuelSurchargeType?.type === FuelSurchargeTypeType.Low ||
      deal.fuelSurchargeType?.type === FuelSurchargeTypeType.Backup
    ) {
      const ret = [
        i18n.t('trading.label.recapNoteFuelSurcharge', {
          area: deliveryTerms.areaName(),
          fuelSurcharge: deal.fuelSurchargeType?.translation(),
        }),
      ]
      if (note) {
        ret.push(note)
      }
      return ret
    }
    return [convertWithNone(contractCondition.note)]
  }

  return {
    publicationDate: {
      text: convertDate(new Date().toDateString()),
    },
    organizationName: {
      text: `${organizationRecap.organizationName.translation()} ${i18n.t(
        'common.label.dear',
      )}`,
    },
    dealDetails: {
      items: {
        executionDate: {
          text: [convertDate(deal.executedAt)],
        },
        transactionId: {
          text: [organizationRecap.publicRecapId],
        },
        seller: {
          text: sellerInfo,
        },
        buyer: {
          text: buyerInfo,
        },
        product: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteProduct,
              convertProductName(deal, deliveryTerms),
            ),
          ],
        },
        deliveryPeriod: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteDeliveryPeriod,
              convertDisplayDeliveryPeriod(deal.product, deliveryTerms),
            ),
          ],
        },
        basicUnitPrice: organizationRecap.price.basicUnitPrice
          ? {
              text: [
                `${organizationRecap.price.basicUnitPrice.unitPrice ||
                  0} ${i18n.t('trading.label.basicUnitPriceUnit.jpyPerKw')}`,
              ],
            }
          : undefined,
        unitPrice: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteUnitPrice,
              convertUnitPrice(deal, organizationRecap.unitPrices),
            ),
          ],
        },
        quantity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteVolume,
              convertQuantity(organizationRecap.volumes),
            ),
          ],
        },
        totalContractElectricity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteTotalContractElectricity,
              deliverySummaryWithCalendarTypeName?.deliverySummary
                .totalDeliveryVolumes
                ? `${(
                    deliverySummaryWithCalendarTypeName.deliverySummary
                      .totalDeliveryVolumes * 1000
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: MAX_DIGIT,
                  })} ${i18n.t('trading.label.kwh')}`
                : '',
            ),
          ],
        },
        transactionConditions: {
          text: [contractCondition.remarks || ''],
        },
        paymentDueDate: {
          text: [contractCondition.paymentDueInformation || ''],
        },
        brokerageUnitPrice: {
          text: [convertDisplayFee(organizationRecap.brokingFee)],
        },
        note: {
          text: getNote(contractCondition.note),
        },
      },
    },
    footer: {
      footer: {
        from: userName,
        mail: FooterMail.ENECHAIN,
        phone: FooterPhoneNumber.ENECHAIN,
      },
    },
  }
}

export const convertBgAndCfgData = (
  userName: string,
  data: DealWithOrganizationRecap,
  summary: DeliverySummariesModel,
  askOrganizationMembers: MemberProfile[],
  bidOrganizationMembers: MemberProfile[],
  userOrganizationMembers: MemberProfile[],
  askOrganization: Organization,
  bidOrganization: Organization,
  askOrganizationEmailAddresses: OrganizationEmailAddress[],
  bidOrganizationEmailAddresses: OrganizationEmailAddress[],
  userOrganizationEmailAddresses: OrganizationEmailAddress[],
  recapCreatedOptions: RecapCreatedOption,
): RecapDetail[] => {
  const {
    deal,
    contractCondition,
    recapOfAskOrganization,
    recapOfBidOrganization,
    recapOfExchangeAskOrganization,
    recapOfExchangeBidOrganization,
  } = data
  const base = {
    name: getRecapTitle(data),
    templateEndpoint: RecapEndpointType.BG_JEPX_NO_SURCHARGE,
    status: RecapStatus.NONE,
    service: SERVICE,
  }

  if (
    deal.fuelSurchargeType?.type === FuelSurchargeTypeType.JeraFy24Tokyo ||
    deal.fuelSurchargeType?.type === FuelSurchargeTypeType.JeraFy24Chubu1 ||
    deal.fuelSurchargeType?.type === FuelSurchargeTypeType.JeraFy24Chubu2
  ) {
    if (deal.price.type === 'basic-plus-volume') {
      base.templateEndpoint = RecapEndpointType.JERA_FUEL_SURCHARGE_WITH_OPTION
    } else {
      base.templateEndpoint = RecapEndpointType.JERA_FUEL_SURCHARGE
    }
  }

  const askDetails: DataDetail[] = [
    convertBgNoFuelSurchargeDataDetail(
      userName,
      convertReceiverInfo(
        recapOfAskOrganization.organizationName.translation(),
        recapOfAskOrganization.recapReceiver,
      ),
      convertReceiverInfo(
        recapOfBidOrganization.organizationName.translation(),
        recapOfBidOrganization.recapReceiver,
      ),
      deal,
      deal.product.deliveryTerms,
      contractCondition,
      recapOfAskOrganization,
      recapCreatedOptions,
      summary.base,
    ),
  ]
  if (
    !recapCreatedOptions.excludeAnnex &&
    isNonStandard(recapOfAskOrganization.product) &&
    (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents.length > 1)
  ) {
    askDetails.push(convertDeliveryTermsAnnex(deal, recapOfAskOrganization))
  }

  const bidDetails: DataDetail[] = [
    convertBgNoFuelSurchargeDataDetail(
      userName,
      convertReceiverInfo(
        recapOfAskOrganization.organizationName.translation(),
        recapOfAskOrganization.recapReceiver,
      ),
      convertReceiverInfo(
        recapOfBidOrganization.organizationName.translation(),
        recapOfBidOrganization.recapReceiver,
      ),
      deal,
      deal.product.deliveryTerms,
      contractCondition,
      recapOfBidOrganization,
      recapCreatedOptions,
      summary.base,
    ),
  ]
  if (
    !recapCreatedOptions.excludeAnnex &&
    isNonStandard(recapOfBidOrganization.product) &&
    (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents.length > 1)
  ) {
    bidDetails.push(convertDeliveryTermsAnnex(deal, recapOfBidOrganization))
  }

  const recapDetails: RecapDetail[] = [
    convertRecapDetail(
      {
        ...base,
        identifier: recapOfAskOrganization.publicRecapId,
        organization: recapOfAskOrganization.organizationId,
        organizationName: recapOfAskOrganization.organizationName.translationOf(
          askOrganization.locale || 'en',
        ),
        orderNumber: RECAP_ORDER_ASK,
        language: askOrganization.locale,
        userMail: {
          to: [
            ...filterReceiveMemberEmails(
              recapOfAskOrganization.product.productType,
              askOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              recapOfAskOrganization.product.productType,
              askOrganizationEmailAddresses,
            ),
          ],
          cc: [FooterMail.ENECHAIN],
          bcc: [],
        },
        operatorMail: {
          to: [
            ...filterReceiveMemberEmails(
              recapOfAskOrganization.product.productType,
              userOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              recapOfAskOrganization.product.productType,
              userOrganizationEmailAddresses,
            ),
          ],
          cc: [],
          bcc: [],
        },
      },
      ...askDetails,
    ),
    convertRecapDetail(
      {
        ...base,
        identifier: recapOfBidOrganization.publicRecapId,
        organization: recapOfBidOrganization.organizationId,
        organizationName: recapOfBidOrganization.organizationName.translationOf(
          bidOrganization.locale || 'en',
        ),
        orderNumber: RECAP_ORDER_BID,
        language: bidOrganization.locale,
        userMail: {
          to: [
            ...filterReceiveMemberEmails(
              recapOfBidOrganization.product.productType,
              bidOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              recapOfBidOrganization.product.productType,
              bidOrganizationEmailAddresses,
            ),
          ],
          cc: [FooterMail.ENECHAIN],
          bcc: [],
        },
        operatorMail: {
          to: [
            ...filterReceiveMemberEmails(
              recapOfBidOrganization.product.productType,
              userOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              recapOfBidOrganization.product.productType,
              userOrganizationEmailAddresses,
            ),
          ],
          cc: [],
          bcc: [],
        },
      },
      ...bidDetails,
    ),
  ]

  if (
    deal.product.exchangeDeliveryTerms &&
    recapOfExchangeAskOrganization &&
    recapOfExchangeBidOrganization
  ) {
    const exchangeAskDetails: DataDetail[] = [
      convertBgNoFuelSurchargeDataDetail(
        userName,
        convertReceiverInfo(
          recapOfExchangeBidOrganization.organizationName.translation(),
          recapOfExchangeBidOrganization.recapReceiver,
        ),
        convertReceiverInfo(
          recapOfExchangeAskOrganization.organizationName.translation(),
          recapOfExchangeAskOrganization.recapReceiver,
        ),
        deal,
        deal.product.exchangeDeliveryTerms,
        contractCondition,
        recapOfExchangeAskOrganization,
        recapCreatedOptions,
        summary.exchange,
      ),
    ]
    if (
      !recapCreatedOptions.excludeAnnex &&
      isNonStandard(recapOfExchangeAskOrganization.product) &&
      (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
        deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
          .deliveryPatternComponents.length > 1)
    ) {
      exchangeAskDetails.push(
        convertDeliveryTermsAnnex(deal, recapOfExchangeAskOrganization),
      )
    }

    const exchangeBidDetails: DataDetail[] = [
      convertBgNoFuelSurchargeDataDetail(
        userName,
        convertReceiverInfo(
          recapOfExchangeBidOrganization.organizationName.translation(),
          recapOfExchangeBidOrganization.recapReceiver,
        ),
        convertReceiverInfo(
          recapOfExchangeAskOrganization.organizationName.translation(),
          recapOfExchangeAskOrganization.recapReceiver,
        ),
        deal,
        deal.product.exchangeDeliveryTerms,
        contractCondition,
        recapOfExchangeBidOrganization,
        recapCreatedOptions,
        summary.exchange,
      ),
    ]
    if (
      !recapCreatedOptions.excludeAnnex &&
      isNonStandard(recapOfExchangeBidOrganization.product) &&
      (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
        deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
          .deliveryPatternComponents.length > 1)
    ) {
      exchangeBidDetails.push(
        convertDeliveryTermsAnnex(deal, recapOfExchangeBidOrganization),
      )
    }

    recapDetails.push(
      convertRecapDetail(
        {
          ...base,
          identifier: recapOfExchangeAskOrganization.publicRecapId,
          organization: recapOfExchangeAskOrganization.organizationId,
          organizationName: recapOfExchangeAskOrganization.organizationName.translationOf(
            askOrganization.locale || 'en',
          ),
          orderNumber: RECAP_ORDER_ASK_SPREAD,
          language: askOrganization.locale,
          userMail: {
            to: [],
            cc: [],
            bcc: [],
          },
          operatorMail: {
            to: [],
            cc: [],
            bcc: [],
          },
        },
        ...exchangeAskDetails,
      ),
    )

    recapDetails.push(
      convertRecapDetail(
        {
          ...base,
          identifier: recapOfExchangeBidOrganization.publicRecapId,
          organization: recapOfExchangeBidOrganization.organizationId,
          organizationName: recapOfExchangeBidOrganization.organizationName.translationOf(
            bidOrganization.locale || 'en',
          ),
          orderNumber: RECAP_ORDER_BID_SPREAD,
          language: bidOrganization.locale,
          userMail: {
            to: [],
            cc: [],
            bcc: [],
          },
          operatorMail: {
            to: [],
            cc: [],
            bcc: [],
          },
        },
        ...exchangeBidDetails,
      ),
    )
  }

  return recapDetails
}
