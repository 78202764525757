import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e57999f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-content-list" }
const _hoisted_2 = { class: "report-content-list__inner" }
const _hoisted_3 = { class: "report-content-list__locale-badge" }
const _hoisted_4 = { class: "report-content-list__title" }
const _hoisted_5 = { class: "report-content-list__inner" }
const _hoisted_6 = { class: "report-content-list__locale-badge" }
const _hoisted_7 = { class: "report-content-list__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_badge = _resolveComponent("base-badge")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "report-content-list__row",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick(_ctx.locale.Ja)))
    }, [
      _createVNode(_component_base_box, { spacing: "0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_badge, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.japanese')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.displayJaContentTitle), 1)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "report-content-list__row",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick(_ctx.locale.En)))
    }, [
      _createVNode(_component_base_box, { spacing: "0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_base_badge, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.english')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.displayEnContentTitle), 1)
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}