import {
  DeliveryUnit,
  MyStandardIndicationSelectableInputsRequest,
  SpreadTypeTypeEnum,
} from '@/api/generated'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'

export function buildSelectableInputsRequest({
  formValue,
  openedSpreadFormTypes,
  selectableInputs,
}: {
  formValue: ProductFormProps
  openedSpreadFormTypes: SpreadTypeTypeEnum[]
  selectableInputs: BaseStandardIndicationSelectableInputs
}): MyStandardIndicationSelectableInputsRequest {
  const isAreaSpread = openedSpreadFormTypes.some(
    t => t === SpreadTypeTypeEnum.Area,
  )
  const isDeliveryPeriodSpread = openedSpreadFormTypes.some(
    t => t === SpreadTypeTypeEnum.DeliveryPeriod,
  )
  const isDeliveryPatternSpread = openedSpreadFormTypes.some(
    t => t === SpreadTypeTypeEnum.DeliveryPattern,
  )

  const deliveryStartDate = (() => {
    switch (formValue.deliveryUnit) {
      case DeliveryUnit.Month:
        return formValue.base.startDeliveryYearMonthId
          ? selectableInputs.getDeliveryStartDateByYearMonthId(
              formValue.base.startDeliveryYearMonthId,
            )
          : undefined
      case DeliveryUnit.WeekOrDay:
      default:
        return formValue.base.startDeliveryDate
    }
  })()

  const deliveryEndDate = (() => {
    switch (formValue.deliveryUnit) {
      case DeliveryUnit.Month:
        return formValue.base.endDeliveryYearMonthId
          ? selectableInputs.getDeliveryEndDateByYearMonthId(
              formValue.base.endDeliveryYearMonthId,
            )
          : undefined
      case DeliveryUnit.WeekOrDay:
      default:
        return formValue.base.endDeliveryDate
    }
  })()

  const exchangeDeliveryStartDate = (() => {
    switch (formValue.deliveryUnit) {
      case DeliveryUnit.Month:
        return formValue.exchange.startDeliveryYearMonthId
          ? selectableInputs.getExchangeDeliveryStartDateByYearMonthId(
              formValue.exchange.startDeliveryYearMonthId,
            )
          : undefined
      case DeliveryUnit.WeekOrDay:
      default:
        return formValue.exchange.startDeliveryDate
    }
  })()

  const exchangeDeliveryEndDate = (() => {
    switch (formValue.deliveryUnit) {
      case DeliveryUnit.Month:
        return formValue.exchange.endDeliveryYearMonthId
          ? selectableInputs.getExchangeDeliveryEndDateByYearMonthId(
              formValue.exchange.endDeliveryYearMonthId,
            )
          : undefined
      case DeliveryUnit.WeekOrDay:
      default:
        return formValue.exchange.endDeliveryDate
    }
  })()

  const TBD = 'TBD'
  const volume =
    formValue.base.volumes[0] === '' ? TBD : formValue.base.volumes[0]
  const exchangeVolume =
    formValue.exchange.volumes[0] === '' ? TBD : formValue.exchange.volumes[0]

  return {
    position: formValue.position,
    productTypeIds: formValue.productTypeIds,
    areaId: formValue.base.areaId,
    hourTypeId: formValue.base.hourTypeId,
    deliveryStartDate,
    deliveryEndDate,
    exchangeAreaId: formValue.exchange.areaId,
    exchangeHourTypeId: formValue.exchange.hourTypeId,
    exchangeDeliveryStartDate,
    exchangeDeliveryEndDate,
    fuelSurchargeTypeId: formValue.fuelSurchargeTypeId,
    deliveryUnit: formValue.deliveryUnit,
    price: formValue.price,
    volume,
    exchangeVolume,
    request: formValue.request,
    publicInformation: formValue.publicInformation,
    isAreaSpread,
    isDeliveryPeriodSpread,
    isDeliveryPatternSpread,
  }
}

export function buildProductFormValue({
  formValue,
  selectableInputs,
  isInitialize = false,
}: {
  formValue: ProductFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
  isInitialize?: boolean
}): ProductFormProps {
  return {
    position: selectableInputs.currentPosition,
    deliveryUnit: selectableInputs.currentDeliveryUnit,
    productTypeIds: selectableInputs.currentProductTypes.map(
      productType => productType.id,
    ),
    fuelSurchargeTypeId:
      selectableInputs.currentFuelSurchargeType?.fuelSurchargeTypeId,

    base: {
      areaId: selectableInputs.currentArea?.areaId,
      startDeliveryYearMonthId:
        selectableInputs.currentDeliveryStartYearMonth?.deliveryYearMonthId,
      endDeliveryYearMonthId:
        selectableInputs.currentDeliveryEndYearMonth?.deliveryYearMonthId,
      startDeliveryDate: selectableInputs.currentDeliveryStartDate,
      endDeliveryDate: selectableInputs.currentDeliveryEndDate,
      hourTypeId: selectableInputs.currentHourType?.hourTypeId,
      volumes: isInitialize
        ? [selectableInputs.volume.value]
        : formValue.base.volumes,
    },

    exchange: {
      areaId: selectableInputs.currentExchangeArea?.areaId,
      startDeliveryYearMonthId:
        selectableInputs.currentExchangeDeliveryStartYearMonth
          ?.deliveryYearMonthId,
      endDeliveryYearMonthId:
        selectableInputs.currentExchangeDeliveryEndYearMonth
          ?.deliveryYearMonthId,
      startDeliveryDate: selectableInputs.currentExchangeDeliveryStartDate,
      endDeliveryDate: selectableInputs.currentExchangeDeliveryEndDate,
      hourTypeId: selectableInputs.currentExchangeHourType?.hourTypeId,
      volumes:
        isInitialize && selectableInputs.exchangeVolume
          ? [selectableInputs.exchangeVolume.value]
          : formValue.exchange.volumes,
    },

    price: isInitialize
      ? {
          type: selectableInputs.price.type,
          energyUnitPrices: selectableInputs.price.energyUnitPrices.map(
            price => price.value,
          ),
          basicUnitPrice:
            selectableInputs.price.basicUnitPrice !== undefined
              ? {
                  type: selectableInputs.price.basicUnitPrice.type,
                  unitPrice:
                    selectableInputs.price.basicUnitPrice.unitPrice.value,
                }
              : undefined,
        }
      : formValue.price,
    unitPrices: isInitialize
      ? [selectableInputs.unitPrice.value]
      : formValue.unitPrices,
    request: selectableInputs.request,
    publicInformation: selectableInputs.publicInformation,

    // 非標準の項目はselectable-inputs未対応
    deliveryPeriods: formValue.deliveryPeriods,
  }
}
