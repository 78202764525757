import {
  Auth0Plugin as Auth0RealPlugin,
  getInstance as getRealInstance,
} from '@/plugins/auth/auth0'
import { Auth0MockPlugin, getMockInstance } from '@/plugins/auth/mock'
import { appEnv } from '@/utils/env'

export interface AccessTokenPayload {
  sub: string
  organization: string
  scope?: string
}

const isLocal = appEnv === 'local' && !process.env.VUE_APP_AUTH_CLIENT_ID

export const Auth0Plugin = isLocal ? Auth0MockPlugin : Auth0RealPlugin
export const getInstance = isLocal ? getMockInstance : getRealInstance
