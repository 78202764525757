import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-192410d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "indication-list-item__inner" }
const _hoisted_2 = { class: "indication-list-item__column" }
const _hoisted_3 = { class: "indication-list-item__delivery-unit-icon" }
const _hoisted_4 = { class: "indication-list-item__column" }
const _hoisted_5 = { class: "indication-list-item__column" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "indication-list-item__column" }
const _hoisted_8 = { class: "indication-list-item__column" }
const _hoisted_9 = { class: "indication-list-item__volumes" }
const _hoisted_10 = { class: "indication-list-item__volumes__column" }
const _hoisted_11 = { class: "indication-list-item__volumes__text" }
const _hoisted_12 = {
  key: 0,
  class: "indication-list-item__volumes__column"
}
const _hoisted_13 = { class: "indication-list-item__volumes__text" }
const _hoisted_14 = ["title"]
const _hoisted_15 = ["title"]
const _hoisted_16 = { class: "indication-list-item__column" }
const _hoisted_17 = { class: "indication-list-item__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_position_icon = _resolveComponent("position-icon")!
  const _component_delivery_unit_icon = _resolveComponent("delivery-unit-icon")!
  const _component_spread_badge_list = _resolveComponent("spread-badge-list")!
  const _component_delivery_terms = _resolveComponent("delivery-terms")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_link = _resolveComponent("base-link")!

  return (_openBlock(), _createBlock(_component_base_link, {
    class: "indication-list-item",
    "reset-font-style": "",
    path: _ctx.itemPath
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_box, { spacing: "0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_position_icon, {
                class: "indication-list-item__position-icon",
                position: _ctx.indication.position,
                "color-reversed": _ctx.colorReversed
              }, null, 8, ["position", "color-reversed"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_delivery_unit_icon, {
                  "delivery-unit": _ctx.indication.products[0].deliveryUnit
                }, null, 8, ["delivery-unit"])
              ])
            ]),
            _createVNode(_component_spread_badge_list, {
              "spread-types": _ctx.indication.spreadTypes
            }, null, 8, ["spread-types"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_delivery_terms, {
                "area-name": _ctx.deliveryTerms.base.areaName,
                "delivery-start": _ctx.deliveryTerms.base.deliveryStart,
                "delivery-end": _ctx.deliveryTerms.base.deliveryEnd,
                "hour-type-name": _ctx.deliveryTerms.base.hourTypeName
              }, null, 8, ["area-name", "delivery-start", "delivery-end", "hour-type-name"]),
              (_ctx.indication.type === 'spread')
                ? (_openBlock(), _createBlock(_component_delivery_terms, {
                    key: 0,
                    "area-name": _ctx.deliveryTerms.exchange.areaName,
                    "delivery-start": _ctx.deliveryTerms.exchange.deliveryStart,
                    "delivery-end": _ctx.deliveryTerms.exchange.deliveryEnd,
                    "hour-type-name": _ctx.deliveryTerms.exchange.hourTypeName
                  }, null, 8, ["area-name", "delivery-start", "delivery-end", "hour-type-name"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_product_type, { name: _ctx.productTypeNames }, null, 8, ["name"])
            ]),
            _createElementVNode("div", {
              class: "indication-list-item__column",
              title: _ctx.fuelSurchargeTypeName
            }, _toDisplayString(_ctx.fuelSurchargeTypeName), 9, _hoisted_6),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.indication.unitPrices[0]), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.indication.volumes[0]), 1)
                ]),
                (_ctx.indication.exchangeVolumes && _ctx.hasExchangeVolume)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.indication.exchangeVolumes[0]), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", {
              class: "indication-list-item__column",
              title: _ctx.indication.organizationName.translation()
            }, _toDisplayString(_ctx.indication.displayOrganizationAbbreviation()), 9, _hoisted_14),
            _createElementVNode("div", {
              class: "indication-list-item__column",
              title: _ctx.indication.traderName
            }, _toDisplayString(_ctx.indication.traderName), 9, _hoisted_15),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.indicationStatusTypeName), 1),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.indication.displayUpdatedAt()), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["path"]))
}