import {
  NewMyIndication,
  SpreadTypeTypeEnum,
  UpdateMyIndication,
} from '@/api/generated'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import {
  buildIndicationBasePayload,
  buildIndicationExchangePayload,
} from '@/components/trading/services/baseIndicationPayloadBuilder'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'

export function buildNewMyIndicationPayload({
  formValue,
  selectableInputs,
}: {
  formValue: ProductFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
}): NewMyIndication | undefined {
  const base = buildIndicationBasePayload({ formValue, selectableInputs })
  if (!base) {
    return
  }
  const exchange = buildIndicationExchangePayload({
    formValue,
    selectableInputs,
  })
  if (formValue.price === undefined) {
    return
  }
  return {
    productTypeIds: formValue.productTypeIds,
    base,
    exchange,
    position: formValue.position,
    price: {
      type: formValue.price.type,
      energyUnitPrices: formValue.price.energyUnitPrices.map(unitPrice =>
        Number(unitPrice),
      ),
      basicUnitPrice:
        formValue.price.basicUnitPrice !== undefined
          ? {
              type: formValue.price.basicUnitPrice.type,
              unitPrice: Number(formValue.price.basicUnitPrice.unitPrice),
            }
          : undefined,
    },
    fuelSurchargeTypeId: formValue.fuelSurchargeTypeId,
    request: formValue.request,
    publicInformation: formValue.publicInformation,
  }
}

export function buildUpdateMyIndicationPayload({
  formValue,
  selectableInputs,
  spreadTypes,
}: {
  formValue: ProductFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
  spreadTypes: SpreadTypeTypeEnum[]
}): UpdateMyIndication | undefined {
  const newMyIndicationPayload = buildNewMyIndicationPayload({
    formValue,
    selectableInputs,
  })
  const exchange =
    spreadTypes.length === 0 ? null : newMyIndicationPayload?.exchange
  return (
    newMyIndicationPayload && {
      ...newMyIndicationPayload,
      exchange,
    }
  )
}
