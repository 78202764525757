import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f3d649d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-book-detail" }
const _hoisted_2 = { class: "broking-order-book-detail__records" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_old_delivery_terms = _resolveComponent("old-delivery-terms")!
  const _component_broking_order_book_detail_item = _resolveComponent("broking-order-book-detail-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_old_delivery_terms, {
      class: "broking-order-book-detail__delivery-terms",
      "delivery-terms": _ctx.deliveryTerms,
      "delivery-unit": _ctx.deliveryUnit,
      locale: _ctx.locale
    }, null, 8, ["delivery-terms", "delivery-unit", "locale"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderBookRecords, (record, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "broking-order-book-detail__record"
        }, [
          _createVNode(_component_broking_order_book_detail_item, {
            item: record,
            "is-first-child": index === 0,
            "is-edit-mode": _ctx.isEditMode,
            "status-types": _ctx.statusTypes,
            locale: _ctx.locale,
            "color-reversed": _ctx.colorReversed,
            "position-reversed": _ctx.positionReversed,
            submitting: _ctx.submitting,
            "target-indication-id": _ctx.targetIndicationId,
            onRecordSelect: _ctx.onRecordSelect,
            onNewMessage: _ctx.onNewMessage,
            onCopyClipboard: _ctx.onCopyClipboard,
            onCopyIndication: _ctx.onCopyIndication,
            onActivate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('activate', $event))),
            onDeactivate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('deactivate', $event))),
            onOpenToMarketChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('open-to-market-change', $event))),
            onVisibleToTradersChange: _cache[3] || (_cache[3] = ($event: any) => (
            _ctx.$emit('visible-to-traders-change', $event)
          )),
            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('change', $event))),
            onTargeting: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('targeting', $event))),
            onUpdateIndication: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update-indication', $event)))
          }, null, 8, ["item", "is-first-child", "is-edit-mode", "status-types", "locale", "color-reversed", "position-reversed", "submitting", "target-indication-id", "onRecordSelect", "onNewMessage", "onCopyClipboard", "onCopyIndication"])
        ]))
      }), 128))
    ])
  ]))
}