
import { defineComponent } from 'vue'

import BaseButton from '@/components/common/BaseButton.vue'

export default defineComponent({
  name: 'OrderButtons',
  components: {
    BaseButton,
  },
  props: {
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['ask-button-click', 'bid-button-click'],
  data() {
    return {
      askButtonInactive: true,
      bidButtonInactive: true,
    }
  },
  methods: {
    onAskButtonClick(): void {
      this.$emit('ask-button-click')
    },
    onBidButtonClick(): void {
      this.$emit('bid-button-click')
    },
    onAskButtonMouseOver() {
      this.askButtonInactive = false
    },
    onBidButtonMouseOver() {
      this.bidButtonInactive = false
    },
    onAskButtonMouseLeave() {
      this.askButtonInactive = true
    },
    onBidButtonMouseLeave() {
      this.bidButtonInactive = true
    },
  },
})
