
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import BaseInputText from '@/components/common/BaseInputText.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { UnitPriceAndVolumeFormProps } from '@/components/trading/interface/UnitPriceAndVolumeFormProps'

export default defineComponent({
  name: 'UnitPriceAndVolumeForm',
  components: {
    BaseInputText,
    ValidationForm,
  },
  props: {
    formValue: {
      type: Object as PropType<UnitPriceAndVolumeFormProps>,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['focus', 'form-input'],
  data(): {
    formInputEventName: 'form-input'
    innerFormValue: {
      unitPrice: string
      volume: string | null
    }
    isComposition: boolean
    isDirty: { [key: string]: boolean }
    emitting: {
      unitPrice: boolean
      volume: boolean
    }
  } {
    return {
      formInputEventName: 'form-input',
      innerFormValue: {
        unitPrice: '0',
        volume: null,
      },
      isComposition: false,
      isDirty: {},
      emitting: {
        unitPrice: false,
        volume: false,
      },
    }
  },
  computed: {
    unitPriceMode(): InputMode {
      return this.submitting && this.emitting.unitPrice
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    volumeMode(): InputMode {
      return this.submitting && this.emitting.volume
        ? InputMode.READONLY
        : InputMode.INPUT
    },
  },
  watch: {
    formValue() {
      Object.assign(this.innerFormValue, this.formValue)
    },
    submitting() {
      if (!this.submitting) {
        this.emitting.unitPrice = false
        this.emitting.volume = false
        Object.assign(this.innerFormValue, this.formValue)
      }
    },
  },
  created() {
    Object.assign(this.innerFormValue, this.formValue)
  },
  methods: {
    onUnitPriceInput(unitPrice: string): void {
      this.isDirty.unitPrice = true
      Object.assign(this.innerFormValue, {
        unitPrice,
      })
    },
    onVolumeInput(volume: string) {
      this.isDirty.volume = true
      Object.assign(this.innerFormValue, {
        volume: volume === '' ? null : volume,
      })
    },
    onFocus() {
      this.$emit('focus')
    },
    onBlur(attribute: keyof UnitPriceAndVolumeFormProps) {
      if (this.isDirty[attribute]) {
        const value = this.innerFormValue[attribute]
        if (this.isValidValue()) {
          this.$emit(this.formInputEventName, {
            [attribute]: value ? Number(value) : null,
          })
        }
        this.isDirty[attribute] = false
        this.emitting[attribute] = true
      }
    },
    onKeydown(
      event: KeyboardEvent,
      attribute: keyof UnitPriceAndVolumeFormProps,
    ) {
      if (
        !this.isComposition &&
        event.code === 'Enter' &&
        this.isDirty[attribute]
      ) {
        const value = this.innerFormValue[attribute]
        if (this.isValidValue()) {
          this.$emit(this.formInputEventName, {
            [attribute]: value ? Number(value) : null,
          })
        }
        this.isDirty[attribute] = false
        this.emitting[attribute] = true
      }
    },
    isValidValue(): boolean {
      // const validationObserver = this.$refs.validationObserver as InstanceType<
      //   typeof ValidationForm
      // >
      // return validationObserver.flags.valid
      return true
    },
  },
})
