import {
  MemberProfile as IMemberProfile,
  RoleType,
  Services,
  UserStatusType,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'

const ECLEAR_PREFIX = '(eClear)'

export class MemberProfile implements IMemberProfile {
  userId!: string
  name!: string
  mail!: string
  phoneNumber?: string
  mobilePhoneNumber?: string
  picture!: string
  organizationId!: string
  department?: string
  roleInOrganization!: RoleType
  status!: UserStatusType
  services?: Services
  createdUserName?: string
  eSquareAppSettingLastUpdatedUserName?: string
  createdAt?: string
  eSquareAppSettingUpdatedAt?: string

  toInputOption({
    isEnableEclearPrefix,
  }: {
    isEnableEclearPrefix?: boolean
  }): InputOption {
    const label =
      isEnableEclearPrefix &&
      !!this.services?.eclear?.appSetting.enabled &&
      !!this.services?.eclear?.appSetting.isTrader
        ? `${ECLEAR_PREFIX} ${this.name}`
        : this.name
    return { value: this.userId, label }
  }

  get isEclearTrader(): boolean {
    return !!this.services?.eclear.appSetting.isTrader
  }

  get isEclearDemandAndSupplyPlanner(): boolean {
    return !!this.services?.eclear.appSetting.isDemandAndSupplyPlanner
  }

  get eclearTraderId(): string {
    return this.services?.eclear?.appSetting?.traderId
      ? this.services.eclear.appSetting.traderId
      : ''
  }

  get eclearKeyword(): string {
    return this.services?.eclear?.userSetting?.keyword
      ? this.services.eclear.userSetting.keyword
      : ''
  }
}
