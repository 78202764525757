import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { OrganizationsApi } from '@/api/generated'
import { OrganizationProfile } from '@/models/common/OrganizationProfile'
import { GlobalGetters } from '@/store/modules/common/global/GlobalGetters'
import {
  GlobalMutations,
  SET_ORGANIZATION_PROFILE,
} from '@/store/modules/common/global/GlobalMutations'
import { GlobalState } from '@/store/modules/common/global/GlobalState'
import {
  arrayBuffer2JpegDataUrl,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class GlobalActions extends Actions<
  GlobalState,
  GlobalGetters,
  GlobalMutations,
  GlobalActions
> {
  async fetchOrganization(id: string): Promise<void> {
    const organization = plainToClass(
      OrganizationProfile,
      (await new OrganizationsApi(apiConfig).getOrganizationProfileById(id))
        .data,
    )
    if (isRequiredAuthenticatedRequest(organization.picture)) {
      const data = (
        await new OrganizationsApi(apiConfig).getPictureOfOrganization(id, {
          responseType: 'arraybuffer',
        })
      ).data
      const dataurl = arrayBuffer2JpegDataUrl((data as unknown) as ArrayBuffer)
      organization.picture = dataurl
    }

    this.commit(SET_ORGANIZATION_PROFILE, {
      organizationName: organization.name,
      organizationPicture: organization.picture,
    })
  }
}
