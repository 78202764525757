import { notify } from '@kyvg/vue3-notification'

import { appEnv } from '@/utils/env'

export const allTrue = (array: boolean[]): boolean => {
  return array.every(_ => _)
}

export const allFalse = (array: boolean[]): boolean => {
  return array.every(_ => !_)
}

export const setPageTitle = (title: string): void => {
  const fullTitle =
    appEnv === 'prod'
      ? `${title} - eSquare for brokers`
      : `[${appEnv}] ${title} - eSquare for brokers`
  document.title = fullTitle
}

export const setNotification = (
  message: string,
  color = 'success',
  duration = 5000,
): void => {
  notify({
    type: color,
    duration: duration,
    text: message,
  })
}

export const downloadFile = ({
  url,
  name,
}: {
  url: string
  name: string
}): void => {
  const link = document.createElement('a')
  link.href = url
  link.download = name
  link.click()
}

export const toFixed = (number: number, digit: number): number =>
  Number(number.toFixed(digit))

export const toHalfWidthNumber = (value: string) => {
  return value.replace(/[０-９。．ー]/g, s => {
    switch (s) {
      case '。':
      case '．':
        return '.'
      case 'ー':
        return '-'
      default:
        return String.fromCharCode(s.charCodeAt(0) - 65248)
    }
  })
}
