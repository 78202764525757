import { Type } from 'class-transformer'

import { ClearingInformationParticipantSummary as IClearingInformationParticipantSummary } from '@/api/generated/api'
import { ClearingInformationParticipantSummaryItem } from '@/models/iam/ClearingInformationParticipantSummaryItem'

export class ClearingInformationParticipantSummary
  implements IClearingInformationParticipantSummary {
  @Type(() => ClearingInformationParticipantSummaryItem)
  eexClearingInformation!: ClearingInformationParticipantSummaryItem

  @Type(() => ClearingInformationParticipantSummaryItem)
  tocomClearingInformation!: ClearingInformationParticipantSummaryItem
}
