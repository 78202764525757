import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fa2541f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-best-price" }
const _hoisted_2 = {
  key: 0,
  class: "order-best-price__order-left-wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "order-best-price__order-left-wrapper"
}
const _hoisted_4 = {
  key: 2,
  class: "order-best-price__order-right-wrapper"
}
const _hoisted_5 = {
  key: 3,
  class: "order-best-price__order-right-wrapper"
}
const _hoisted_6 = { class: "order-best-price__last-deal-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unit_price_and_volume = _resolveComponent("unit-price-and-volume")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.positionReversed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.orderBook.askUnitPrice)
            ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
                key: 0,
                "unit-price": _ctx.orderBook.askUnitPrice,
                volume: _ctx.orderBook.base.askVolume,
                position: _ctx.ask,
                "color-reversed": _ctx.colorReversed,
                "exchange-volume": _ctx.orderBook.exchange?.askVolume
              }, null, 8, ["unit-price", "volume", "position", "color-reversed", "exchange-volume"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.orderBook.bidUnitPrice)
            ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
                key: 0,
                "unit-price": _ctx.orderBook.bidUnitPrice,
                volume: _ctx.orderBook.base.bidVolume,
                position: _ctx.bid,
                "color-reversed": _ctx.colorReversed,
                "exchange-volume": _ctx.orderBook.exchange?.bidVolume
              }, null, 8, ["unit-price", "volume", "position", "color-reversed", "exchange-volume"]))
            : _createCommentVNode("", true)
        ])),
    (_ctx.positionReversed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.orderBook.bidUnitPrice)
            ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
                key: 0,
                "unit-price": _ctx.orderBook.bidUnitPrice,
                volume: _ctx.orderBook.base.bidVolume,
                position: _ctx.bid,
                "color-reversed": _ctx.colorReversed,
                "exchange-volume": _ctx.orderBook.exchange?.bidVolume
              }, null, 8, ["unit-price", "volume", "position", "color-reversed", "exchange-volume"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.orderBook.askUnitPrice)
            ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
                key: 0,
                "unit-price": _ctx.orderBook.askUnitPrice,
                volume: _ctx.orderBook.base.askVolume,
                position: _ctx.ask,
                "color-reversed": _ctx.colorReversed,
                "exchange-volume": _ctx.orderBook.exchange?.askVolume
              }, null, 8, ["unit-price", "volume", "position", "color-reversed", "exchange-volume"]))
            : _createCommentVNode("", true)
        ])),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.orderBook.lastUnitPrice)
        ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
            key: 0,
            "unit-price": _ctx.orderBook.lastUnitPrice,
            volume: _ctx.orderBook.base.lastVolume,
            "executed-at": _ctx.orderBook.executedAt,
            "light-font": !!_ctx.orderBook.lastDealPrivateConfidential,
            "exchange-volume": _ctx.orderBook.exchange?.lastVolume
          }, null, 8, ["unit-price", "volume", "executed-at", "light-font", "exchange-volume"]))
        : _createCommentVNode("", true)
    ])
  ]))
}