import { RouteRecordRaw } from 'vue-router'

import { reportsRoutes } from '@/router/routes/trader/reports'
import History from '@/views/History.vue'
import Order from '@/views/Order.vue'
import Trading from '@/views/Trading.vue'

export const traderRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Order',
    component: Order,
  },
  {
    path: '/trading/spread',
    name: 'Trading',
    component: Trading,
  },
  {
    path: '/history',
    name: 'History',
    component: History,
  },
  ...reportsRoutes,
]
