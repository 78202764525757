import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea25c35e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-indication-detail" }
const _hoisted_2 = { class: "my-indication-detail__form" }
const _hoisted_3 = {
  key: 0,
  class: "my-indication-detail__loading"
}
const _hoisted_4 = {
  key: 1,
  class: "my-indication-detail__wrapper"
}
const _hoisted_5 = { class: "my-indication-detail__buttons" }
const _hoisted_6 = { class: "my-indication-detail__metadata" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_my_indication_form = _resolveComponent("my-indication-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_my_indication_metadata = _resolveComponent("my-indication-metadata")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.selectableInputs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_centered_loading_content, {
              type: "grayscale",
              "is-relative": ""
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_my_indication_form, {
              "form-value": _ctx.formValue,
              mode: _ctx.mode,
              locale: _ctx.userProfile.locale,
              "position-reversed": _ctx.services.esquare.userSetting.positionReversed,
              "selectable-inputs": _ctx.selectableInputs,
              "is-editing": _ctx.isEditing,
              "opened-spread-form-types": _ctx.openedSpreadFormTypes,
              "product-types": _ctx.productTypes,
              "date-names": _ctx.dateNames,
              onFormInput: _ctx.onFormInput,
              onTextInput: _ctx.onTextInput,
              onStandardDeliveryTermsFormOpen: _ctx.onStandardDeliveryTermsFormOpen,
              onStandardDeliveryTermsFormClose: 
              _ctx.onStandardDeliveryTermsFormClose
            
            }, null, 8, ["form-value", "mode", "locale", "position-reversed", "selectable-inputs", "is-editing", "opened-spread-form-types", "product-types", "date-names", "onFormInput", "onTextInput", "onStandardDeliveryTermsFormOpen", "onStandardDeliveryTermsFormClose"]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.isEditing)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_base_button, {
                      disabled: _ctx.isInvalid,
                      color: "primary",
                      onClick: _ctx.onConfirmClick
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_base_button, {
                      loading: _ctx.isSubmitting,
                      color: "primary",
                      onClick: _ctx.onCreateIndicationConfirmClick
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.update')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "onClick"]),
                    _createVNode(_component_base_button, { onClick: _ctx.onConfirmCancelClick }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.back')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ], 64))
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_my_indication_metadata, {
        "my-indication": _ctx.myIndication,
        "member-profiles": _ctx.memberProfiles
      }, null, 8, ["my-indication", "member-profiles"])
    ])
  ]))
}