import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86a0b182"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-input-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_input_text, {
      class: "filter-input-text__selector",
      name: "filterInputText",
      value: _ctx.inputValue,
      placeholder: _ctx.placeholder,
      onInput: _ctx.onInput,
      onKeydown: _ctx.onKeydown,
      onBlur: _ctx.onBlur,
      onCompositionstart: _ctx.onCompositionstart,
      onCompositionend: _ctx.onCompositionend
    }, null, 8, ["value", "placeholder", "onInput", "onKeydown", "onBlur", "onCompositionstart", "onCompositionend"]),
    _createVNode(_component_base_icon, {
      class: "filter-input-text__close",
      "icon-name": "times-circle",
      onClick: _ctx.onClick
    }, null, 8, ["onClick"])
  ]))
}