import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40b9f288"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negotiation-indications-form__input" }
const _hoisted_2 = { class: "negotiation-indications-form__input" }
const _hoisted_3 = {
  key: 0,
  class: "negotiation-indications-form__split"
}
const _hoisted_4 = { class: "negotiation-indications-form__credit-sleeve-heading" }
const _hoisted_5 = { class: "negotiation-indications-form__input" }
const _hoisted_6 = { class: "help is-danger" }
const _hoisted_7 = {
  key: 0,
  class: "negotiation-indications-form__input"
}
const _hoisted_8 = {
  key: 1,
  class: "negotiation-indications-form__split"
}
const _hoisted_9 = { class: "negotiation-indications-form__input" }
const _hoisted_10 = { class: "negotiation-indications-form__input" }
const _hoisted_11 = {
  key: 0,
  class: "negotiation-indications-form__split"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_indication_selector = _resolveComponent("indication-selector")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_field_broking_fee = _resolveComponent("field-broking-fee")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_organization_selector = _resolveComponent("organization-selector")!
  const _component_field = _resolveComponent("field")!
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["negotiation-indications-form", {
      'negotiation-indications-form--credit-sleever-not-editable': !_ctx.creditSleeverEditable,
      'negotiation-indications-form--has-credit-sleever': _ctx.creditSleeverIsShown,
    }])
  }, [
    _createVNode(_component_base_box, { class: "negotiation-indications-form__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_heading, {
          type: _ctx.headerLevel3,
          class: "negotiation-indications-form__heading"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.label.askIndication')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_base_label, {
            label: `${_ctx.$t('trading.label.indicationShort')}*`
          }, null, 8, ["label"]),
          _createVNode(_component_indication_selector, {
            "modal-name": "askIndicationSelectorModal",
            "is-selectable": _ctx.indicationIsSelectable,
            "selected-indication": _ctx.formValue.askIndication,
            "other-indication": _ctx.formValue.bidIndication,
            "other-product-type": _ctx.bidProductType,
            position: _ctx.positionTypeAsk,
            "color-reversed": _ctx.colorReversed,
            onSelect: _ctx.onInputAskIndication
          }, null, 8, ["is-selectable", "selected-indication", "other-indication", "other-product-type", "position", "color-reversed", "onSelect"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_single_select, {
            label: _ctx.$t('trading.label.productType'),
            name: "askProductType",
            value: _ctx.formValue.askProductType,
            options: _ctx.askProductTypeInputOptions,
            mode: _ctx.inputMode,
            placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.productType'),
            })
          ,
            rules: _ctx.askProductTypesRules,
            required: "",
            onInput: _cache[0] || (_cache[0] = event => _ctx.onInputAskProductType(event))
          }, null, 8, ["label", "value", "options", "mode", "placeholder", "rules"])
        ]),
        (_ctx.brokingFeeInputIsVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_field_broking_fee, {
                class: "negotiation-indications-form__input",
                name: "askBrokingFee",
                value: _ctx.formValue.askBrokingFee,
                mode: _ctx.inputMode,
                label: _ctx.brokingFeeLabel,
                onInput: _cache[1] || (_cache[1] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                askBrokingFee: event,
              })
          )
              }, null, 8, ["value", "mode", "label"]),
              (_ctx.isStandardSpread)
                ? (_openBlock(), _createBlock(_component_field_broking_fee, {
                    key: 0,
                    class: "negotiation-indications-form__input",
                    name: "askExchangeBrokingFee",
                    value: _ctx.formValue.askExchangeBrokingFee,
                    mode: _ctx.inputMode,
                    label: _ctx.exchangeBrokingFeeLabel,
                    onInput: _cache[2] || (_cache[2] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                askExchangeBrokingFee: event,
              })
          )
                  }, null, 8, ["value", "mode", "label"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.creditSleeverIsShown)
      ? (_openBlock(), _createBlock(_component_base_box, {
          key: 0,
          class: "negotiation-indications-form__box"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_heading, { type: _ctx.headerLevel3 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('trading.label.creditSleeve')), 1)
                ]),
                _: 1
              }, 8, ["type"]),
              (_ctx.creditSleeverEditable)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "negotiation-indications-form__icon",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onCreditSleeverRemoveClick && _ctx.onCreditSleeverRemoveClick(...args)))
                  }, [
                    _createVNode(_component_base_icon, { "icon-name": "times" })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_base_label, {
                label: `${_ctx.$t('trading.label.creditSleever')}*`
              }, null, 8, ["label"]),
              _createVNode(_component_field, {
                ref: "validationProvider",
                name: "creditSleever",
                "model-value": _ctx.formValue.creditSleever?.organizationId,
                rules: _ctx.creditSleeverSelectorRules
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_organization_selector, {
                    "selected-organization": _ctx.formValue.creditSleever,
                    "is-selectable": _ctx.creditSleeverEditable,
                    "query-params": _ctx.organizationSelectorModalQueryParams,
                    onSelect: _cache[4] || (_cache[4] = 
              event => {
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  creditSleever: event,
                })
              }
            )
                  }, null, 8, ["selected-organization", "is-selectable", "query-params"]),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(errors[0]), 1)
                ]),
                _: 1
              }, 8, ["model-value", "rules"])
            ]),
            (_ctx.brokingFeeInputIsVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_base_input_text, {
                    label: _ctx.$t('trading.label.sleeveSpread'),
                    name: "sleeveSpread",
                    value: _ctx.formValue.creditSleeverSleeveSpread,
                    "value-type": "number",
                    mode: _ctx.inputMode,
                    rules: "numeric|scale:4|between:0,999.999|required",
                    required: "",
                    onInput: _cache[5] || (_cache[5] = event => _ctx.$emit('credit-sleever-sleeve-spread-input', event))
                  }, null, 8, ["label", "value", "mode"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.brokingFeeInputIsVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_field_broking_fee, {
                    class: "negotiation-indications-form__input",
                    name: "creditSleeverAskBrokingFee",
                    value: _ctx.formValue.creditSleeverAskBrokingFee,
                    mode: _ctx.inputMode,
                    label: _ctx.$t('trading.label.askBrokingFee'),
                    onInput: _cache[6] || (_cache[6] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                creditSleeverAskBrokingFee: event,
              })
          )
                  }, null, 8, ["value", "mode", "label"]),
                  _createVNode(_component_field_broking_fee, {
                    class: "negotiation-indications-form__input",
                    name: "creditSleeverBidBrokingFee",
                    value: _ctx.formValue.creditSleeverBidBrokingFee,
                    mode: _ctx.inputMode,
                    label: _ctx.$t('trading.label.bidBrokingFee'),
                    onInput: _cache[7] || (_cache[7] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                creditSleeverBidBrokingFee: event,
              })
          )
                  }, null, 8, ["value", "mode", "label"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : (_ctx.creditSleeverEditable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "negotiation-indications-form__icon",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onCreditSleeverAddClick && _ctx.onCreditSleeverAddClick(...args)))
          }, [
            _createVNode(_component_base_tooltip, {
              label: _ctx.$t('trading.message.addCreditSleever')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, { "icon-name": "plus-circle" })
              ]),
              _: 1
            }, 8, ["label"])
          ]))
        : _createCommentVNode("", true),
    _createVNode(_component_base_box, { class: "negotiation-indications-form__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_heading, {
          type: _ctx.headerLevel3,
          class: "negotiation-indications-form__heading"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.label.bidIndication')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_base_label, {
            label: `${_ctx.$t('trading.label.indicationShort')}*`
          }, null, 8, ["label"]),
          _createVNode(_component_indication_selector, {
            "modal-name": "bidIndicationSelectorModal",
            "is-selectable": _ctx.indicationIsSelectable,
            "selected-indication": _ctx.formValue.bidIndication,
            "other-indication": _ctx.formValue.askIndication,
            "other-product-type": _ctx.askProductType,
            position: _ctx.positionTypeBid,
            "color-reversed": _ctx.colorReversed,
            onSelect: _ctx.onInputBidIndication
          }, null, 8, ["is-selectable", "selected-indication", "other-indication", "other-product-type", "position", "color-reversed", "onSelect"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_base_single_select, {
            label: _ctx.$t('trading.label.productType'),
            name: "bidProductType",
            value: _ctx.formValue.bidProductType,
            options: _ctx.bidProductTypeInputOptions,
            mode: _ctx.inputMode,
            placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.productType'),
            })
          ,
            rules: _ctx.bidProductTypesRules,
            required: "",
            onInput: _cache[9] || (_cache[9] = event => _ctx.onInputBidProductType(event))
          }, null, 8, ["label", "value", "options", "mode", "placeholder", "rules"])
        ]),
        (_ctx.brokingFeeInputIsVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_field_broking_fee, {
                class: "negotiation-indications-form__input",
                name: "bidBrokingFee",
                value: _ctx.formValue.bidBrokingFee,
                mode: _ctx.inputMode,
                label: _ctx.brokingFeeLabel,
                onInput: _cache[10] || (_cache[10] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                bidBrokingFee: event,
              })
          )
              }, null, 8, ["value", "mode", "label"]),
              (_ctx.isStandardSpread)
                ? (_openBlock(), _createBlock(_component_field_broking_fee, {
                    key: 0,
                    class: "negotiation-indications-form__input",
                    name: "bidExchangeBrokingFee",
                    value: _ctx.formValue.bidExchangeBrokingFee,
                    mode: _ctx.inputMode,
                    label: _ctx.exchangeBrokingFeeLabel,
                    onInput: _cache[11] || (_cache[11] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                bidExchangeBrokingFee: event,
              })
          )
                  }, null, 8, ["value", "mode", "label"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}