import { RouteRecordRaw } from 'vue-router'

import { OcctoMasterType } from '@/api/generated'
import EsquareOrganization from '@/views/iam/EsquareOrganization.vue'
import ClearingInformation from '@/views/iam/children/ClearingInformation.vue'
import OcctoMaster from '@/views/iam/children/OcctoMaster.vue'
import OcctoMasterDetail from '@/views/iam/children/OcctoMasterDetail.vue'
import OcctoMasterNew from '@/views/iam/children/OcctoMasterNew.vue'
import RecapConfiguration from '@/views/iam/children/RecapConfiguration.vue'
import RecapReceiver from '@/views/iam/children/RecapReceiver.vue'
import RecapReceiverNew from '@/views/iam/children/RecapReceiverNew.vue'
import RecapReceivers from '@/views/iam/children/RecapReceivers.vue'

/**
 * パス: /recap-configuration/...
 */
export const recapConfigurationRoute: RouteRecordRaw = {
  path: '/recap-configuration',
  component: EsquareOrganization,
  children: [
    {
      path: '',
      component: RecapConfiguration,
      children: [
        {
          path: '',
          redirect: 'recap-receivers',
        },
        {
          path: 'recap-receivers',
          name: 'MyRecapReceivers',
          component: RecapReceivers,
          meta: {
            activeMenuItem: '',
            titleKey: 'iam.label.recapConfiguration',
          },
        },
        {
          path: 'occto-master',
          name: 'MyOcctoMaster',
          component: OcctoMaster,
          meta: {
            activeMenuItem: '',
            titleKey: 'iam.label.recapConfiguration',
          },
        },
        {
          path: 'clearing-information',
          name: 'MyClearingInformation',
          component: ClearingInformation,
          meta: {
            activeMenuItem: '',
            titleKey: 'iam.label.recapConfiguration',
          },
        },
      ],
    },
    {
      path: 'recap-receivers/new',
      name: 'MyRecapReceiverNew',
      component: RecapReceiverNew,
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.newRecapReceiver',
      },
    },
    {
      path: 'recap-receivers/:id',
      name: 'MyRecapReceiver',
      component: RecapReceiver,
      props: route => ({
        id: route.params.id,
      }),
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.detailedRecapReceiver',
      },
    },
    {
      path: 'occto-master/new',
      name: 'MyOcctoMasterNew',
      component: OcctoMasterNew,
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.newOcctoMaster',
      },
    },
    {
      path: 'occto-master/balancing-groups/:id',
      name: 'MyOcctoMasterDemandDetail',
      component: OcctoMasterDetail,
      props: route => ({
        id: route.params.id,
      }),
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.detailedOcctoMaster',
        type: OcctoMasterType.Demand,
      },
    },
    {
      path: 'occto-master/supply-plan-submitters/:id',
      name: 'MyOcctoMasterGenerationDetail',
      component: OcctoMasterDetail,
      props: route => ({
        id: route.params.id,
      }),
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.detailedOcctoMaster',
        type: OcctoMasterType.Generation,
      },
    },
  ],
}
