
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseDatepicker from '@/components/common/BaseDatepicker.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { DeliveryDateFormProps } from '@/components/trading/interface/DeliveryDateFormProps'
import { moment } from '@/utils/date'

export default defineComponent({
  name: 'DeliveryDateForm',
  components: {
    BaseDatepicker,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    formValue: {
      type: Object as PropType<DeliveryDateFormProps>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    mode: {
      type: Number,
      required: true,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY].includes(value),
    },
    isShownLabel: {
      type: Boolean,
      default: true,
    },
    startDeliveryMinDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    startDeliveryMaxDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    endDeliveryMinDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    startSelectableDates: {
      type: Array as PropType<Date[]>,
      default() {
        return []
      },
    },
    endSelectableDates: {
      type: Array as PropType<Date[]>,
      default() {
        return []
      },
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
  } {
    return {
      formInputEventName: 'form-input',
    }
  },
  computed: {
    startDeliveryDate(): Date | undefined {
      return this.formValue.startDeliveryDate
        ? moment(this.formValue.startDeliveryDate).toDate()
        : undefined
    },
    endDeliveryDate(): Date | undefined {
      return this.formValue.endDeliveryDate
        ? moment(this.formValue.endDeliveryDate).toDate()
        : undefined
    },
    focusedStartDeliveryDate(): Date | undefined {
      return this.formValue.startDeliveryDate ? undefined : this.endDeliveryDate
    },
    focusedEndDeliveryDate(): Date | undefined {
      return this.formValue.endDeliveryDate ? undefined : this.startDeliveryDate
    },
  },
  methods: {
    onStartDeliveryDateInput(date: Date) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        startDeliveryDate: date ? moment(date).format('YYYY-MM-DD') : undefined,
      })
    },
    onEndDeliveryDateInput(date: Date) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        endDeliveryDate: date ? moment(date).format('YYYY-MM-DD') : undefined,
      })
    },
  },
})
