import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25eac7ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-info" }
const _hoisted_2 = { class: "organization-info__picture" }
const _hoisted_3 = { class: "organization-info__name" }
const _hoisted_4 = { class: "organization-info__overflow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_picture, {
        url: _ctx.picture,
        width: 24,
        "border-radius": "4px"
      }, null, 8, ["url"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_tooltip, {
        direction: "downward",
        label: _ctx.name
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.name), 1)
        ]),
        _: 1
      }, 8, ["label"])
    ])
  ]))
}