import { RecapReceiver as IRecapReceiver } from '@/api/generated'

export class RecapReceiver implements IRecapReceiver {
  id!: string
  organizationId!: string
  department?: string
  name!: string
  phoneNumber?: string
  emailAddress?: string
  createdAt!: string
  updatedAt!: string
  createdParticipantName?: string
  lastUpdatedParticipantName?: string

  displayName(): string {
    return this.department ? `${this.name} (${this.department})` : this.name
  }
}
