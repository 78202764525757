import { Mutations } from 'vuex-smart-module'

import { PublicUserProfile } from '@/models/iam/PublicUserProfile'
import { PublicUserProfileState } from '@/store/modules/iam/publicUserProfile/PublicUserProfileState'

export const SET_PUBLIC_USER_PROFILES = 'SET_PUBLIC_USER_PROFILES'

export class PublicUserProfileMutations extends Mutations<
  PublicUserProfileState
> {
  [SET_PUBLIC_USER_PROFILES](payload: {
    publicUserProfiles: PublicUserProfile[]
  }) {
    this.state.publicUserProfiles = payload.publicUserProfiles
  }
}
