import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be169d2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-form" }
const _hoisted_2 = { class: "member-form__phone-numbers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_input_radio = _resolveComponent("base-input-radio")!
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, null, {
      default: _withCtx(() => [
        _createVNode(_component_base_dataset, {
          class: "member-form__dataset",
          label: _ctx.$t('iam.label.name'),
          data: _ctx.formValue.name
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          class: "member-form__dataset",
          label: _ctx.$t('iam.label.mail'),
          data: _ctx.formValue.mail
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_input_radio, {
          class: "member-form__input",
          label: _ctx.$t('iam.label.role'),
          name: "role",
          options: _ctx.roleOptions,
          value: _ctx.formValue.roleInOrganization,
          mode: _ctx.readonly,
          help: 
          _ctx.$t('iam.message.memberHelpMessage') +
            _ctx.$t('iam.message.ownerHelpMessage')
        
        }, null, 8, ["label", "options", "value", "mode", "help"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_input_text, {
            class: "member-form__input",
            label: _ctx.$t('iam.label.phoneNumber'),
            name: "phoneNumber",
            value: _ctx.formValue.phoneNumber,
            mode: _ctx.readonly
          }, null, 8, ["label", "value", "mode"]),
          _createVNode(_component_base_input_text, {
            class: "member-form__input",
            label: _ctx.$t('iam.label.mobilePhoneNumber'),
            name: "mobilePhoneNumber",
            value: _ctx.formValue.mobilePhoneNumber,
            mode: _ctx.readonly
          }, null, 8, ["label", "value", "mode"])
        ])
      ]),
      _: 1
    })
  ]))
}