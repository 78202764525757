
import { ErrorMessage, Field } from 'vee-validate'
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'

type Status = 'NotSelected' | 'Selected' | 'Uploaded'

export default defineComponent({
  name: 'BaseInputFile',
  components: {
    BaseIcon,
    BaseLabel,
    ErrorMessage,
    Field,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: undefined,
    },
    help: {
      type: String,
      default: '',
    },
    initialSelectedFile: {
      type: File,
      default: undefined,
    },
    uploadedFileName: {
      type: String,
      default: '',
    },
    acceptFileTypes: {
      type: Array,
      default() {
        return []
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selected', 'removed', 'download-click'],
  data(): {
    selectedFile: undefined | File
    isRemoveClicked: boolean
  } {
    return {
      selectedFile: undefined,
      isRemoveClicked: false,
    }
  },
  computed: {
    isNotSelected(): boolean {
      return this.status === 'NotSelected'
    },
    isSelected(): boolean {
      return this.status === 'Selected'
    },
    isUploaded(): boolean {
      return this.status === 'Uploaded'
    },
    status(): Status {
      if (this.selectedFile !== undefined) {
        return 'Selected'
      }
      if (this.uploadedFileName !== '' && !this.isRemoveClicked) {
        return 'Uploaded'
      }
      return 'NotSelected'
    },
    accept(): string {
      return this.acceptFileTypes.join(', ')
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  created() {
    this.selectedFile = this.initialSelectedFile
  },
  methods: {
    onChange(event: Event) {
      this.isRemoveClicked = true
      const target = event.target as HTMLInputElement
      const files = target.files as FileList
      this.selectedFile = files[0]
      if (files.length > 0) {
        this.$emit('selected', this.selectedFile)
      } else {
        this.$emit('removed')
      }
    },
    onRemoveClick() {
      const fileInput = this.$refs.fileInput as HTMLInputElement
      fileInput.value = ''
      fileInput.dispatchEvent(new Event('change'))
    },
    onDownloadClick() {
      this.$emit('download-click')
    },
  },
})
