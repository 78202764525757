import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ecf369a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-email-addresses" }
const _hoisted_2 = { class: "organization-email-addresses__button" }
const _hoisted_3 = { class: "organization-email-addresses__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_organization_email_address_list_header = _resolveComponent("organization-email-address-list-header")!
  const _component_organization_email_address_list = _resolveComponent("organization-email-address-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_button, {
        color: "primary",
        onClick: _ctx.onButtonClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('iam.label.addOrganizationEmailAddress')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
            name: _ctx.$t('iam.label.organizationEmailAddress'),
          })), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      blank: _withCtx(() => [
        _createVNode(_component_base_alert, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.message.notExists', {
            name: _ctx.$t('iam.label.organizationEmailAddress'),
          })), 1)
          ]),
          _: 1
        })
      ]),
      ideal: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_organization_email_address_list_header),
          _createVNode(_component_organization_email_address_list, {
            "organization-email-addresses": _ctx.organizationEmailAddresses,
            "parent-path": `${_ctx.organizationUrl}/organization-email-addresses`,
            onDelete: _ctx.onDelete
          }, null, 8, ["organization-email-addresses", "parent-path", "onDelete"])
        ])
      ]),
      _: 1
    }, 8, ["ui-stack"]),
    _createVNode(_component_confirmation_dialog, {
      name: _ctx.deleteModalName,
      title: _ctx.$t('iam.label.deleteOrganizationEmailAddress'),
      "confirm-button-label": _ctx.$t('common.label.delete'),
      "confirm-button-color": 'danger',
      loading: _ctx.deleteSubmitting,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.onDeleteCancel
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('iam.message.deleteOrganizationEmailAddressMessage', {
          name: _ctx.nameOfToDelete,
        })), 1)
      ]),
      _: 1
    }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
  ]))
}