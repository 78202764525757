
import { defineComponent } from 'vue'

import BaseInputText from '@/components/common/BaseInputText.vue'
import { InputMode } from '@/components/common/constants/InputMode'

export default defineComponent({
  name: 'FieldBrokingFee',
  components: {
    BaseInputText,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY].includes(value),
      default: InputMode.INPUT,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['input'],
  methods: {
    onInput(event: Event) {
      this.$emit('input', event)
    },
  },
})
