import { Type } from 'class-transformer'

import { Report as IReport, ReportType } from '@/api/generated'
import { i18n } from '@/i18n'
import { ReportContent } from '@/models/reports/ReportContent'
import { moment } from '@/utils/date'

export class Report implements IReport {
  id!: string
  reportTypes!: Array<ReportType>
  publishedAt!: string

  @Type(() => ReportContent)
  reportContents!: { [key: string]: ReportContent }

  displayPublishedAt(): string {
    return moment(this.publishedAt).format(i18n.t('common.format.dateTime'))
  }

  displayReportType(type: string): string {
    switch (type) {
      case 'power':
        return i18n.t('reports.label.power')
      case 'gas':
        return i18n.t('reports.label.gas')
      default:
        return '-'
    }
  }
}
