
import { debounce } from 'lodash'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { PositionType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import FuelSurchargeTypeTab from '@/components/trading/FuelSurchargeTypeTab.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import TheNewIndicationButton from '@/components/trading/TheNewIndicationButton.vue'
import TradingOrderBookList from '@/components/trading/TradingOrderBookList.vue'
import TradingOrderBookListHeader from '@/components/trading/TradingOrderBookListHeader.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import MyIndicationNew from '@/components/trading/container/MyIndicationNew.vue'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { OrderBook } from '@/models/trading/OrderBook'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { OrderBookModule } from '@/store/modules/trading/orderBook'
import {
  SET_FILTER_CONDITION_WITH_SPREAD,
  SET_SELECTED_FILTERS_WITH_SPREAD,
} from '@/store/modules/trading/orderBook/OrderBookMutations'
import { getOrderBookFilter } from '@/store/modules/trading/orderBook/helper'
import { OrderBookFilterAttribute } from '@/store/modules/trading/orderBook/interface'
import { ProductModule } from '@/store/modules/trading/product'
import { PublicIndicationModule } from '@/store/modules/trading/publicIndication'
import { TAB_INDEXES } from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'
import { setNotification } from '@/utils/utils'

const {
  mapActions: orderBookMapActions,
  mapGetters: orderBookMapGetters,
  mapMutations: orderBookMapMutations,
  mapState: orderBookMapState,
} = createNamespacedHelpers('orderBook') as OrderBookModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const {
  mapGetters: userProfileMapGetters,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule

const { mapActions: publicIndicationMapActions } = createNamespacedHelpers(
  'publicIndication',
) as PublicIndicationModule

type StateType = {
  orderBooks: OrderBook[]
  activeTabIndex: number
  uiStack: UiStack
  errorType: AlertType
  expandedList: string[]
  isFilterActionDropdownActive: boolean
  isDownloadActionDropdownActive: boolean
  indicationFormInputMode: IndicationFormInputMode
  selectedOrderBook: OrderBook | undefined
  selectedPosition: PositionType | undefined
  layoutMode: LayoutMode
  downloadActions: ActionDropdownItem[]
  limitedDeliveryYearMonths: DeliveryYearMonth[]
}

export default defineComponent({
  name: 'Trading',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    BaseIcon,
    BaseModal,
    CenteredLoadingContent,
    FuelSurchargeTypeTab,
    MyIndicationNew,
    ProductFilter,
    TheNewIndicationButton,
    TraderPage,
    TradingOrderBookList,
    TradingOrderBookListHeader,
    UiStackSelector,
  },
  data(): StateType {
    return {
      orderBooks: [],
      activeTabIndex: TAB_INDEXES.SPREAD,
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      expandedList: [],
      isFilterActionDropdownActive: false,
      isDownloadActionDropdownActive: false,
      indicationFormInputMode: IndicationFormInputMode.NEW,
      selectedOrderBook: undefined,
      selectedPosition: undefined,
      layoutMode: LayoutMode.Wide,
      downloadActions: [
        {
          label: this.$t('trading.message.orderCsvDownload').toString(),
          eventName: 'order-csv-download-click',
        },
        {
          label: this.$t('trading.message.recentDealCsvDownload').toString(),
          eventName: 'recent-deal-csv-download-click',
        },
      ],
      limitedDeliveryYearMonths: [] as DeliveryYearMonth[],
    }
  },
  computed: {
    ...userProfileMapState(['services']),
    ...userProfileMapGetters(['userProfile']),
    ...orderBookMapState([
      'filterAttributes',
      'filterConditionWithSpread',
      'selectedFiltersWithSpread',
    ]),
    ...orderBookMapGetters(['spreadFilterParams']),
    ...productMapState([
      'areas',
      'hourTypes',
      'productTypes',
      'deliveryYearMonths',
    ]),
    allCollapsed(): boolean {
      return this.expandedList.length === 0
    },
    filters(): AttributeFilter[] {
      return getOrderBookFilter({
        areas: this.areas,
        deliveryYearMonths: this.limitedDeliveryYearMonths,
        hourTypes: this.hourTypes,
        productTypes: this.productTypes,
        filterAttributes: this.selectedFiltersWithSpread,
        filterCondition: this.filterConditionWithSpread,
      })
    },
    filterActions(): ActionDropdownItem[] {
      return this.filterAttributes.map(attribute => ({
        label: this.$t(`trading.label.${attribute}`).toString(),
        eventName: attribute,
      }))
    },
  },
  async created() {
    this.loadFiltersFromLocalStorage()
    await Promise.all([this.setupStoreState(), this.fetchList()])
  },
  methods: {
    fetchOrderBooks: orderBookMapActions(['fetchOrderBooks'])
      .fetchOrderBooks as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any,
    ) => Promise<OrderBook[]>,
    ...orderBookMapActions([
      'addFilterConditionSelected',
      'removeFilterConditionSelected',
      'loadFiltersFromLocalStorage',
      'changeFilter',
      'removeFilterCondition',
    ]),
    ...orderBookMapMutations([
      SET_FILTER_CONDITION_WITH_SPREAD,
      SET_SELECTED_FILTERS_WITH_SPREAD,
    ]),
    ...productMapActions([
      'fetchAreas',
      'fetchDeliveryYearMonths',
      'fetchHourTypes',
      'fetchProductTypes',
    ]),
    fetchPublicIndicationsCsvString: publicIndicationMapActions([
      'fetchPublicIndicationsCsvString',
    ]).fetchPublicIndicationsCsvString as () => Promise<string>,
    fetchPublicLastDealsCsvString: publicIndicationMapActions([
      'fetchPublicLastDealsCsvString',
    ]).fetchPublicLastDealsCsvString as () => Promise<string>,
    async setupStoreState() {
      await Promise.all([
        this.fetchAreas(),
        this.fetchDeliveryYearMonths(),
        this.fetchHourTypes(),
        this.fetchProductTypes(),
        this.fetchForFilter(),
      ])
    },
    async fetchForFilter() {
      // フィルターのためのfetch
      //   * 取得した全件から「開始月」「終了月」のoptionsにする
      await this.fetchOrderBooks({ isSpread: true })
        .then((orders: OrderBook[]) => {
          // filterの対象となる deliveryYearMonth を作成する
          const toCandidateKey = (d: { year: number; month: number }) => {
            return `${d.year}-${d.month}`
          }
          // orderBooksのdelivery dateのみに限定する
          let filterStartCandidate: string[] = []
          let filterEndCandidate: string[] = []
          for (const order of orders) {
            if (!order.isSpread) {
              continue // スプレッド以外は無視して良い
            }
            const startKeys: string[] = order
              .deliveryYearMonthStartList()
              .map(d => toCandidateKey({ year: d.year, month: d.month }))
            const endKeys: string[] = order
              .deliveryYearMonthEndList()
              .map(d => toCandidateKey({ year: d.year, month: d.month }))

            filterStartCandidate.push(...startKeys)
            filterEndCandidate.push(...endKeys)
          }
          // uniqueにする
          filterStartCandidate = [...new Set(filterStartCandidate)]
          filterEndCandidate = [...new Set(filterEndCandidate)]

          const limitedDeliveryYearMonths = this.deliveryYearMonths.filter(
            d => {
              const key = toCandidateKey({ year: d.year, month: d.month })
              if (
                filterStartCandidate.includes(key) ||
                filterEndCandidate.includes(key)
              ) {
                return true // 現状optionsの対象をstartとendで区別できないのでまとめる
              }
              return false
            },
          )
          this.limitedDeliveryYearMonths = limitedDeliveryYearMonths
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    async fetchList() {
      // フィルターありで取得するfetch
      await this.fetchOrderBooks({ ...this.spreadFilterParams, isSpread: true })
        .then((orderBooks: OrderBook[]) => {
          this.orderBooks = orderBooks
          if (this.orderBooks.length === 0) {
            this.uiStack = UiStack.Blank
          } else {
            this.uiStack = UiStack.Ideal
          }
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    debouncedFetchList: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.fetchList()
    }, 500),
    async onTabActive(index: number) {
      if (index === 6) {
        window.location.href = `${process.env.VUE_APP_ETENDER_URL}/tender`
        return
      }
      await this.$router.push(`/?tabIndex=${index}`)
    },
    onOrderButtonClick({
      position,
      orderBook,
    }: {
      position: PositionType
      orderBook: OrderBook
    }) {
      this.indicationFormInputMode = IndicationFormInputMode.PRODUCT_FIXED
      this.selectedOrderBook = orderBook
      this.selectedPosition = position
      this.$vfm.open('new-indication')
    },
    onExpandAll() {
      this.expandedList = this.orderBooks.map(item => item.id)
    },
    onCollapseAll() {
      this.expandedList = []
    },
    onClick(id: string) {
      const index = this.expandedList.findIndex(item => item === id)
      if (index >= 0) {
        this.expandedList.splice(index, 1)
      } else {
        this.expandedList.push(id)
      }
    },
    onNewIndicationButtonClick() {
      this.indicationFormInputMode = IndicationFormInputMode.NEW
      this.selectedOrderBook = undefined
      this.selectedPosition = undefined
      this.$vfm.open('new-indication')
    },
    onFilterItemSelect(event: {
      attribute: OrderBookFilterAttribute
      value: InputOption
    }) {
      this.addFilterConditionSelected({ index: this.activeTabIndex, ...event })
      this.debouncedFetchList()
    },
    onFilterItemRemove(event: {
      attribute: OrderBookFilterAttribute
      value: InputOption
    }) {
      this.removeFilterConditionSelected({
        index: this.activeTabIndex,
        ...event,
      })
      this.debouncedFetchList()
    },
    onFilterRemove(event: { attribute: OrderBookFilterAttribute }) {
      this.removeFilterCondition({ index: this.activeTabIndex, ...event })
      this.debouncedFetchList()
    },
    onClickFilterDropdown() {
      this.isFilterActionDropdownActive = !this.isFilterActionDropdownActive
    },
    onClickFilterOutside() {
      this.isFilterActionDropdownActive = false
    },
    onChangeFilters(event: {
      selectedValues: OrderBookFilterAttribute[]
      changedValue: OrderBookFilterAttribute
    }) {
      this.changeFilter({
        index: this.activeTabIndex,
        ...event,
      })
    },
    onClickOrderCsvDownload() {
      this.fetchPublicIndicationsCsvString()
        .then(csvString => {
          this.downloadCsvData(csvString, 'order-data')
        })
        .catch(e => {
          setNotification(
            this.$t('common.message.failFetch', {
              name: this.$t('common.label.order').toString(),
            }).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.isDownloadActionDropdownActive = false
        })
    },
    async onRecentDealCsvDownloadClick() {
      this.fetchPublicLastDealsCsvString()
        .then(csvString => {
          this.downloadCsvData(csvString, 'recent-traded-data')
        })
        .catch(e => {
          setNotification(
            this.$t('common.message.failFetch', {
              name: this.$t('common.label.order').toString(),
            }).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.isDownloadActionDropdownActive = false
        })
    },
    downloadCsvData(csv: string, prefixFileName: string) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const downloadLink = document.createElement('a')
      downloadLink.download = `${prefixFileName}-${moment().format(
        'YYYYMMDDTHHmmSS',
      )}.csv`
      downloadLink.href = URL.createObjectURL(
        new Blob([bom, csv], { type: 'text/csv' }),
      )
      downloadLink.dataset.downloadurl = [
        'text/csv',
        downloadLink.download,
        downloadLink.href,
      ].join(':')
      downloadLink.click()
    },
    directDispatchEvent() {
      document.dispatchEvent(new Event('click'))
    },
    onDownloadClick() {
      this.isDownloadActionDropdownActive = true
      this.directDispatchEvent()
    },
  },
})
