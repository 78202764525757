import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  OrganizationEmailAddressApi,
  RequestOrganizationEmailAddress,
} from '@/api/generated'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'

export class OrganizationEmailAddressActions extends Actions<
  OrganizationEmailAddressActions
> {
  async fetchOrganizationEmailAddresses(
    organizationId: string,
  ): Promise<OrganizationEmailAddress[]> {
    return plainToClass(
      OrganizationEmailAddress,
      (
        await new OrganizationEmailAddressApi(
          apiConfig,
        ).getOrganizationEmailAddresses(organizationId)
      ).data,
    )
  }

  async fetchOrganizationEmailAddress({
    organizationId,
    organizationEmailAddressId,
  }: {
    organizationId: string
    organizationEmailAddressId: string
  }): Promise<OrganizationEmailAddress> {
    return plainToClass(
      OrganizationEmailAddress,
      (
        await new OrganizationEmailAddressApi(
          apiConfig,
        ).getOrganizationEmailAddress(
          organizationId,
          organizationEmailAddressId,
        )
      ).data,
    )
  }

  async createOrganizationEmailAddress({
    organizationId,
    organizationEmailAddress,
  }: {
    organizationId: string
    organizationEmailAddress: RequestOrganizationEmailAddress
  }): Promise<OrganizationEmailAddress> {
    return plainToClass(
      OrganizationEmailAddress,
      (
        await new OrganizationEmailAddressApi(
          apiConfig,
        ).newOrganizationEmailAddress(organizationId, organizationEmailAddress)
      ).data,
    )
  }

  async updateOrganizationEmailAddress({
    organizationId,
    organizationEmailAddressId,
    organizationEmailAddress,
  }: {
    organizationId: string
    organizationEmailAddressId: string
    organizationEmailAddress: RequestOrganizationEmailAddress
  }): Promise<OrganizationEmailAddress> {
    return plainToClass(
      OrganizationEmailAddress,
      (
        await new OrganizationEmailAddressApi(
          apiConfig,
        ).updateOrganizationEmailAddress(
          organizationId,
          organizationEmailAddressId,
          organizationEmailAddress,
        )
      ).data,
    )
  }

  async deleteOrganizationEmailAddress({
    organizationId,
    organizationEmailAddressId,
  }: {
    organizationId: string
    organizationEmailAddressId: string
  }) {
    return await new OrganizationEmailAddressApi(
      apiConfig,
    ).deleteOrganizationEmailAddress(organizationId, organizationEmailAddressId)
  }
}
