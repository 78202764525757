import { Type } from 'class-transformer'

import { BrokingIndication as IBrokingIndication } from '@/api/generated'
import { BaseBrokingIndication } from '@/models/trading/BaseBrokingIndication'
import { BrokingIndicationOfProduct } from '@/models/trading/BrokingIndicationOfProduct'
import { Product } from '@/models/trading/Product'

export class BrokingIndication extends BaseBrokingIndication
  implements IBrokingIndication {
  @Type(() => Product)
  products!: Array<Product>

  toIndicationOfProduct(product: Product): BrokingIndicationOfProduct {
    const indicationOfProduct = new BrokingIndicationOfProduct()
    indicationOfProduct.isEclearIndication = this.products.some(p =>
      p.productType.isEclear(),
    )
    indicationOfProduct.product = product

    indicationOfProduct.indicationId = this.indicationId
    indicationOfProduct.publicOrganizationProfile = this.publicOrganizationProfile
    indicationOfProduct.position = this.position
    indicationOfProduct.price = this.price
    indicationOfProduct.unitPrice = this.unitPrice
    indicationOfProduct.volume = this.volume
    indicationOfProduct.fuelSurchargeType = this.fuelSurchargeType
    indicationOfProduct.status = this.status
    indicationOfProduct.request = this.request
    indicationOfProduct.publicInformation = this.publicInformation
    indicationOfProduct.openToMarket = this.openToMarket
    indicationOfProduct.isVisibleToTraders = !!this.isVisibleToTraders
    indicationOfProduct.externalIndicationId = this.externalIndicationId
    indicationOfProduct.createdParticipantId = this.createdParticipantId
    indicationOfProduct.lastUpdatedParticipantId = this.lastUpdatedParticipantId
    indicationOfProduct.createdAt = this.createdAt
    indicationOfProduct.updatedAt = this.updatedAt
    indicationOfProduct.maskVolume = this.maskVolume
    indicationOfProduct.isDailyPowerMarket = this.isDailyPowerMarket

    return indicationOfProduct
  }
}
