import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  IndicationsApi,
  IndicationStatusTypesApi,
  PositionType,
  NewStandardIndication,
  IndicationStatusType as IndicationStatusTypeEnum,
  UpdateStandardIndication,
  UpdateNonStandardIndication,
  NewNonStandardIndication,
  UpdateStandardIndicationWithId,
  StandardIndicationSelectableInputsRequest,
  StandardIndicationSelectableInputsSpellRequest,
} from '@/api/generated'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { DeliverySummaries } from '@/models/trading/DeliverySummaries'
import { Indication } from '@/models/trading/Indication'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'
import { ProductSubType } from '@/models/trading/ProductSubType'
import { TradingSteps } from '@/models/trading/TradingSteps'
import { UpdatedIndicationInBulk } from '@/models/trading/UpdatedIndicationInBulk'
import { IndicationGetters } from '@/store/modules/trading/indication/IndicationGetters'
import { IndicationMutations } from '@/store/modules/trading/indication/IndicationMutations'
import { IndicationState } from '@/store/modules/trading/indication/IndicationState'

export class IndicationActions extends Actions<
  IndicationState,
  IndicationGetters,
  IndicationMutations,
  IndicationActions
> {
  async fetchIndications({
    areaId,
    startDeliveryYearMonthId,
    endDeliveryYearMonthId,
    hourTypeId,
    productTypeId,
    organizationName,
    status,
    position,
    nonStandard,
    productId,
    deliveryTermsId,
    exchangeDeliveryTermsId,
    fuelSurchargeTypeId,
    lastId,
    fromDate,
    excludeTbd,
    productSubType,
  }: {
    areaId?: string
    startDeliveryYearMonthId?: string
    endDeliveryYearMonthId?: string
    hourTypeId?: string
    productTypeId?: string
    organizationName?: string
    status?: IndicationStatusTypeEnum
    position?: PositionType
    nonStandard?: boolean
    productId?: string
    deliveryTermsId?: string
    exchangeDeliveryTermsId?: string
    fuelSurchargeTypeId?: string
    lastId?: string
    fromDate?: string
    excludeTbd?: boolean
    productSubType?: ProductSubType
  }): Promise<Indication[]> {
    return plainToClass(
      Indication,
      (
        await new IndicationsApi(apiConfig).getIndications(
          organizationName,
          areaId,
          hourTypeId,
          productTypeId,
          startDeliveryYearMonthId,
          endDeliveryYearMonthId,
          status,
          position,
          nonStandard,
          productId,
          deliveryTermsId,
          exchangeDeliveryTermsId,
          fuelSurchargeTypeId,
          lastId,
          fromDate,
          excludeTbd,
          undefined,
          productSubType,
        )
      ).data,
    )
  }

  async fetchIndication(id: string): Promise<Indication> {
    return plainToClass(
      Indication,
      (await new IndicationsApi(apiConfig).getIndication(id)).data,
    )
  }

  async createStandardIndication(
    standardIndication: NewStandardIndication,
  ): Promise<Indication> {
    return plainToClass(
      Indication,
      (
        await new IndicationsApi(apiConfig).newStandardIndication(
          standardIndication,
        )
      ).data,
    )
  }

  async createNonStandardIndication(
    nonStandardIndication: NewNonStandardIndication,
  ): Promise<Indication> {
    return plainToClass(
      Indication,
      (
        await new IndicationsApi(apiConfig).newNonStandardIndication(
          nonStandardIndication,
        )
      ).data,
    )
  }

  async updateStandardIndication({
    id,
    standardIndication,
  }: {
    id: string
    standardIndication: UpdateStandardIndication
  }): Promise<Indication> {
    return plainToClass(
      Indication,
      (
        await new IndicationsApi(apiConfig).updateStandardIndication(
          id,
          standardIndication,
        )
      ).data,
    )
  }

  async updateNonStandardIndication({
    id,
    nonStandardIndication,
  }: {
    id: string
    nonStandardIndication: UpdateNonStandardIndication
  }): Promise<Indication> {
    return plainToClass(
      Indication,
      (
        await new IndicationsApi(apiConfig).updateNonStandardIndication(
          id,
          nonStandardIndication,
        )
      ).data,
    )
  }

  async fetchIndicationStatusTypes(): Promise<IndicationStatusType[]> {
    return plainToClass(
      IndicationStatusType,
      (await new IndicationStatusTypesApi(apiConfig).getIndicationStatusTypes())
        .data,
    )
  }

  async deleteIndication({
    id,
    memo,
  }: {
    id: string
    memo: string
  }): Promise<void> {
    await new IndicationsApi(apiConfig).deleteIndication(id, memo)
  }

  async fetchDeliverySummaries(id: string): Promise<DeliverySummaries> {
    return plainToClass(
      DeliverySummaries,
      (await new IndicationsApi(apiConfig).getIndicationDeliverySummary(id))
        .data,
    )
  }

  async fetchTradingSteps(id: string): Promise<TradingSteps> {
    return plainToClass(
      TradingSteps,
      (await new IndicationsApi(apiConfig).getIndicationTradingSteps(id)).data,
    )
  }

  async updateStandardIndicationInBulk(
    standardIndicationWithIds: UpdateStandardIndicationWithId[],
  ): Promise<UpdatedIndicationInBulk> {
    return plainToClass(
      UpdatedIndicationInBulk,
      (
        await new IndicationsApi(apiConfig).updateStandardIndications({
          indications: standardIndicationWithIds,
        })
      ).data,
    )
  }

  async fetchStandardIndicationSelectableInputs({
    request,
    abortController,
  }: {
    request: StandardIndicationSelectableInputsRequest
    abortController: AbortController
  }): Promise<BaseStandardIndicationSelectableInputs> {
    return plainToClass(
      BaseStandardIndicationSelectableInputs,
      (
        await new IndicationsApi(
          apiConfig,
        ).generateStandardIndicationSelectableInputs(request, {
          signal: abortController.signal,
        })
      ).data,
    )
  }

  async fetchStandardIndicationSelectableInputsBySpell({
    request,
    abortController,
  }: {
    request: StandardIndicationSelectableInputsSpellRequest
    abortController: AbortController
  }): Promise<BaseStandardIndicationSelectableInputs> {
    return plainToClass(
      BaseStandardIndicationSelectableInputs,
      (
        await new IndicationsApi(
          apiConfig,
        ).generateStandardIndicationSelectableInputsBySpell(request, {
          signal: abortController.signal,
        })
      ).data,
    )
  }
}
