import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7264b392"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-book-price__order-left-wrapper" }
const _hoisted_2 = { class: "order-book-price__order-right-wrapper" }
const _hoisted_3 = { class: "order-book-price__last-deal-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unit_price_and_volume_form = _resolveComponent("unit-price-and-volume-form")!
  const _component_order_book_unit_price_and_volume = _resolveComponent("order-book-unit-price-and-volume")!

  return (_openBlock(), _createElementBlock("div", {
    class: "order-book-price",
    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('unit-price-and-volume-click')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLeftShown)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isEditMode && _ctx.price.type === 'volume')
              ? (_openBlock(), _createBlock(_component_unit_price_and_volume_form, {
                  key: 0,
                  "form-value": _ctx.formValue,
                  submitting: _ctx.submitting,
                  onFormInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event))),
                  onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus')))
                }, null, 8, ["form-value", "submitting"]))
              : (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
                  key: 1,
                  price: _ctx.price,
                  volume: _ctx.volume,
                  position: _ctx.leftPosition,
                  locale: _ctx.locale,
                  "color-reversed": _ctx.colorReversed,
                  "mask-volume": _ctx.maskVolume,
                  "light-font": !_ctx.openToMarket
                }, null, 8, ["price", "volume", "position", "locale", "color-reversed", "mask-volume", "light-font"]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isRightShown)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isEditMode && _ctx.price.type === 'volume')
              ? (_openBlock(), _createBlock(_component_unit_price_and_volume_form, {
                  key: 0,
                  "form-value": _ctx.formValue,
                  submitting: _ctx.submitting,
                  onFormInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $event))),
                  onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focus')))
                }, null, 8, ["form-value", "submitting"]))
              : (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
                  key: 1,
                  price: _ctx.price,
                  volume: _ctx.volume,
                  position: _ctx.rightPosition,
                  locale: _ctx.locale,
                  "color-reversed": _ctx.colorReversed,
                  "mask-volume": _ctx.maskVolume,
                  "light-font": !_ctx.openToMarket
                }, null, 8, ["price", "volume", "position", "locale", "color-reversed", "mask-volume", "light-font"]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.recordType === 'deal')
        ? (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
            key: 0,
            class: "order-book-price__last-deal",
            price: _ctx.price,
            volume: _ctx.volume,
            locale: _ctx.locale,
            "created-at": _ctx.createdAt,
            "mask-volume": _ctx.maskVolume,
            "light-font": !_ctx.openToMarket
          }, null, 8, ["price", "volume", "locale", "created-at", "mask-volume", "light-font"]))
        : _createCommentVNode("", true)
    ])
  ]))
}