
import { defineComponent } from 'vue'

import ChannelPicture from '@/components/chat/ChannelPicture.vue'
import BaseSkeleton from '@/components/common/BaseSkeleton.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'

export default defineComponent({
  name: 'BaseChannel',
  components: {
    BaseSkeleton,
    ChannelPicture,
    Sentences,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    channelId: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'none',
    },
    organizationName: {
      type: String,
      default: '',
    },
    displayOrganizationName: {
      type: Boolean,
      default: false,
    },
    isOrganization: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    isBlank(): boolean {
      return this.name === ''
    },
  },
  methods: {
    onClick() {
      this.$emit('click', { channelId: this.channelId })
    },
  },
})
