
import { defineComponent, PropType } from 'vue'

import EnableIcon from '@/components/common/EnableIcon.vue'
import { ReceiveEmailStatusesProps } from '@/components/iam/interface/ReceiveEmailStatusesProps'

export default defineComponent({
  name: 'ReceiveEmailStatuses',
  components: {
    EnableIcon,
  },
  props: {
    receiveEmailSetting: {
      type: Object as PropType<ReceiveEmailStatusesProps>,
      default: undefined,
    },
  },
  computed: {
    receiveIndicationEmail(): boolean {
      return !!this.receiveEmailSetting?.receiveIndicationEmail
    },
    receiveLiveMarketEmail(): boolean {
      return !!this.receiveEmailSetting?.receiveLiveMarketEmail
    },
    receiveSpotTradingRecapEmail(): boolean {
      return !!this.receiveEmailSetting?.receiveSpotTradingRecapEmail
    },
    receiveDerivativeTradingRecapEmail(): boolean {
      return !!this.receiveEmailSetting?.receiveDerivativeTradingRecapEmail
    },
    receiveReportEmail(): boolean {
      return !!this.receiveEmailSetting?.receiveReportEmail
    },
    receiveInvoiceEmail(): boolean {
      return !!this.receiveEmailSetting?.receiveInvoiceEmail
    },
  },
})
