import {
  BrokerageFeePaymentConfiguration as IBrokerageFeePaymentConfiguration,
  BrokerageFeePaymentTiming,
} from '@/api/generated'

export class BrokerageFeePaymentConfiguration
  implements IBrokerageFeePaymentConfiguration {
  id!: string
  organizationId!: string
  spotTrading?: BrokerageFeePaymentTiming
  derivativeTrading?: BrokerageFeePaymentTiming
  createdAt!: string
  updatedAt!: string
}
