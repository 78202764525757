import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  AreasApi,
  DayPatternsApi,
  DeliveryYearMonthsApi,
  FuelSurchargeTypesApi,
  HourTypesApi,
  ProductTypeDateNamesApi,
  ProductTypesApi,
  CalendarTypesApi,
} from '@/api/generated'
import { Area } from '@/models/trading/Area'
import { CalendarType } from '@/models/trading/CalendarType'
import { DayPattern } from '@/models/trading/DayPattern'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { ProductGetters } from '@/store/modules/trading/product/ProductGetters'
import {
  ProductMutations,
  SET_AREAS,
  SET_DELIVERY_YEAR_MONTHS,
  SET_END_DELIVERY_YEAR_MONTHS,
  SET_FUEL_SURCHARGE_TYPES,
  SET_HOUR_TYPES,
  SET_PRODUCT_TYPES,
  SET_START_DELIVERY_YEAR_MONTHS,
} from '@/store/modules/trading/product/ProductMutations'
import { ProductState } from '@/store/modules/trading/product/ProductState'
import { moment } from '@/utils/date'

// TODO: 例外処理を追加
export class ProductActions extends Actions<
  ProductState,
  ProductGetters,
  ProductMutations,
  ProductActions
> {
  async fetchProductTypes() {
    const productTypes = plainToClass(
      ProductType,
      (await new ProductTypesApi(apiConfig).getProductTypes()).data,
    )

    productTypes.sort((a, b) => b.displayPriority - a.displayPriority)

    this.commit(SET_PRODUCT_TYPES, {
      productTypes: productTypes,
    })
  }

  async fetchAreas() {
    const areas = plainToClass(
      Area,
      (await new AreasApi(apiConfig).getAreas()).data,
    )

    areas.sort((a, b) => b.displayPriority - a.displayPriority)

    this.commit(SET_AREAS, {
      areas: areas,
    })
  }

  async fetchHourTypes() {
    const hourTypes = plainToClass(
      HourType,
      (await new HourTypesApi(apiConfig).getHourTypes()).data,
    )

    hourTypes.sort((a, b) => b.displayPriority - a.displayPriority)

    this.commit(SET_HOUR_TYPES, {
      hourTypes: hourTypes,
    })
  }

  async fetchDeliveryYearMonths() {
    const deliveryYearMonths = plainToClass(
      DeliveryYearMonth,
      (await new DeliveryYearMonthsApi(apiConfig).getDeliveryYearMonths()).data,
    )

    deliveryYearMonths.sort((a, b) => b.displayPriority - a.displayPriority)

    this.commit(SET_START_DELIVERY_YEAR_MONTHS, {
      startDeliveryYearMonths: deliveryYearMonths,
    })
    this.commit(SET_END_DELIVERY_YEAR_MONTHS, {
      endDeliveryYearMonths: deliveryYearMonths,
    })
    this.commit(SET_DELIVERY_YEAR_MONTHS, {
      deliveryYearMonths: deliveryYearMonths,
    })
  }

  async fetchFuelSurchargeTypes() {
    const fuelSurchargeTypes = plainToClass(
      FuelSurchargeType,
      (await new FuelSurchargeTypesApi(apiConfig).getFuelSurchargeTypes()).data,
    )

    fuelSurchargeTypes.sort((a, b) => b.displayPriority - a.displayPriority)

    this.commit(SET_FUEL_SURCHARGE_TYPES, {
      fuelSurchargeTypes: fuelSurchargeTypes,
    })
  }

  async fetchDayPatterns(): Promise<DayPattern[]> {
    return plainToClass(
      DayPattern,
      (await new DayPatternsApi(apiConfig).getDayPatterns()).data,
    )
  }

  async fetchProductTypeDateName({
    id,
    date,
  }: {
    id: string
    date: Date
  }): Promise<ProductTypeDateName> {
    return plainToClass(
      ProductTypeDateName,
      (
        await new ProductTypeDateNamesApi(apiConfig).getDateNameOfWeekOrDay(
          id,
          moment(date).format('YYYY-MM-DD'),
        )
      ).data,
    )
  }

  async fetchProductTypeDateNames(): Promise<ProductTypeDateName[]> {
    return plainToClass(
      ProductTypeDateName,
      (await new ProductTypeDateNamesApi(apiConfig).getDateNames()).data,
    )
  }

  async fetchCalendarTypes(): Promise<CalendarType[]> {
    return plainToClass(
      CalendarType,
      (await new CalendarTypesApi(apiConfig).getCalendarTypes()).data,
    )
  }
}
