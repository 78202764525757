import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cc26a24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trader-selector" }
const _hoisted_2 = { class: "trader-selector__information" }
const _hoisted_3 = { class: "trader-selector__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Trader_selector_modal = _resolveComponent("Trader-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["trader-selector__wrapper", {
        'trader-selector__wrapper--selectable': _ctx.isSelectable,
      }])
    }, [
      (_ctx.selectedTrader)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "trader-selector__select-button--selected",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.selectedTraderName), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "trader-selector__select-button--not-selected",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, _toDisplayString(_ctx.$t('trading.label.selectTrader')), 1))
    ], 2),
    _createVNode(_component_base_modal, {
      title: _ctx.$t('trading.label.selectEEXTrader'),
      name: _ctx.traderSelectorModalName
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Trader_selector_modal, {
          "organization-id": _ctx.organizationId,
          "selected-trader": _ctx.selectedTrader,
          onSelect: _ctx.onSelect,
          onCancel: _ctx.onCancel
        }, null, 8, ["organization-id", "selected-trader", "onSelect", "onCancel"])
      ]),
      _: 1
    }, 8, ["title", "name"])
  ]))
}