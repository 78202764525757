import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createBlock(_component_sentences, {
    type: "text-small",
    class: "typing-indicator"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
    ]),
    _: 1
  }))
}