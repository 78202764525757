import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0adfb6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tocom-broker-selector-modal" }
const _hoisted_2 = { class: "tocom-broker-selector-modal__result" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tocom-broker-selector-modal__result-item-name" }
const _hoisted_5 = { class: "tocom-broker-selector-modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
        loading: _withCtx(() => [
          _createVNode(_component_centered_loading_content, {
            type: "grayscale",
            "is-relative": ""
          })
        ]),
        error: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.errorType }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failFetchTocomBroker')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        blank: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.alertTypeInfo }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.message.notFoundTocomBroker')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        ideal: _withCtx(() => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tocomClearingInformationList, (tocomClearingInformation, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (_ctx.onSelect(tocomClearingInformation))
              }, [
                _createVNode(_component_base_box, { class: "tocom-broker-selector-modal__result-item" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(tocomClearingInformation.displayName()), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 8, _hoisted_3))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["ui-stack"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_base_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}