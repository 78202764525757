import { Locale, Translation as ITranslation } from '@/api/generated'

export class Translation implements ITranslation {
  locale!: Locale
  text!: string

  has(locale: Locale) {
    return this.locale === locale
  }
}
