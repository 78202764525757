import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  BrokingDealsApi,
  BrokingIndicationsApi,
  BrokingOrderBooksApi,
  UsersApi,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { User } from '@/models/iam/User'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'
import { BrokingGetters } from '@/store/modules/trading/broking/BrokingGetters'
import {
  BrokingMutations,
  SET_BROKING_DEALS,
  SET_BROKING_INDICATIONS,
  SET_BROKING_ORDER_BOOKS,
  SET_SELECTED_INDICATION_IDS,
  SET_USERS,
} from '@/store/modules/trading/broking/BrokingMutations'
import { BrokingState } from '@/store/modules/trading/broking/BrokingState'
import { findFilterState } from '@/store/modules/trading/broking/helper'
import {
  BrokingFilterAttribute,
  BrokingFilterAttributeSchema,
  BrokingFilterConditionSchema,
} from '@/store/modules/trading/broking/interface'
import {
  BaseFilterAttribute,
  TAB_INDEXES,
} from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'

export const brokingIndicationSorter = (
  a: BrokingIndication,
  b: BrokingIndication,
) =>
  b.products[0].area.displayPriority - a.products[0].area.displayPriority ||
  b.products[0].startDeliveryYearMonth.displayPriority -
    a.products[0].startDeliveryYearMonth.displayPriority ||
  b.products[0].endDeliveryYearMonth.displayPriority -
    a.products[0].endDeliveryYearMonth.displayPriority ||
  (b.products[0].hourType?.displayPriority ?? 0) -
    (a.products[0].hourType?.displayPriority ?? 0) ||
  b.products[0].productType.displayPriority -
    a.products[0].productType.displayPriority

const brokingDealSorter = (a: BrokingDeal, b: BrokingDeal) =>
  b.product.area.displayPriority - a.product.area.displayPriority ||
  b.product.startDeliveryYearMonth.displayPriority -
    a.product.startDeliveryYearMonth.displayPriority ||
  b.product.endDeliveryYearMonth.displayPriority -
    a.product.endDeliveryYearMonth.displayPriority ||
  (b.product.hourType?.displayPriority ?? 0) -
    (a.product.hourType?.displayPriority ?? 0) ||
  b.product.productType.displayPriority - a.product.productType.displayPriority

export class BrokingActions extends Actions<
  BrokingState,
  BrokingGetters,
  BrokingMutations,
  BrokingActions
> {
  async loadBrokingIndications() {
    const brokingIndications = plainToClass(
      BrokingIndication,
      (await new BrokingIndicationsApi(apiConfig).getBrokingIndications()).data,
    )
    const brokingSpreadIndications = plainToClass(
      BrokingIndication,
      (await new BrokingIndicationsApi(apiConfig).getBrokingIndications(true))
        .data,
    )

    this.commit(SET_BROKING_INDICATIONS, {
      brokingIndications: [
        ...brokingIndications,
        ...brokingSpreadIndications,
      ].sort(brokingIndicationSorter),
    })
  }

  async loadBrokingDeals() {
    const brokingDeals = plainToClass(
      BrokingDeal,
      (await new BrokingDealsApi(apiConfig).getBrokingLastDeals()).data,
    )
    const brokingSpreadDeals = plainToClass(
      BrokingDeal,
      (await new BrokingDealsApi(apiConfig).getBrokingLastDeals(true)).data,
    )
    this.commit(SET_BROKING_DEALS, {
      brokingDeals: [...brokingDeals, ...brokingSpreadDeals].sort(),
    })
  }

  async fetchBrokingRecentDeals() {
    const brokingDeals = plainToClass(
      BrokingDeal,
      (await new BrokingDealsApi(apiConfig).getBrokingRecentDeals()).data,
    )
    brokingDeals.sort(brokingDealSorter)
    return brokingDeals
  }

  addSelectedIndicationId(payload: { indicationId: string }) {
    this.commit(SET_SELECTED_INDICATION_IDS, {
      indicationIds: [
        ...this.state.selectedIndicationIds,
        payload.indicationId,
      ],
    })
  }

  removeSelectedIndicationId(payload: { indicationId: string }) {
    this.commit(SET_SELECTED_INDICATION_IDS, {
      indicationIds: this.state.selectedIndicationIds.filter(
        id => id !== payload.indicationId,
      ),
    })
  }

  resetSelectedIndicationIds() {
    this.commit(SET_SELECTED_INDICATION_IDS, { indicationIds: [] })
  }

  loadFiltersFromLocalStorage() {
    for (const index of [
      TAB_INDEXES.NO_FUEL_SURCHARGE,
      TAB_INDEXES.WITH_FUEL_SURCHARGE,
      TAB_INDEXES.SPREAD,
    ]) {
      const filterState = findFilterState(index, this.state)

      try {
        const selectedFiltersFromLocalStorage = localStorage.getItem(
          filterState.keyOfSelectedFilters,
        )
        const selectedFiltersConditionFromLocalStorage = localStorage.getItem(
          filterState.keyOfSelectedFiltersCondition,
        )
        if (
          selectedFiltersFromLocalStorage === null ||
          selectedFiltersConditionFromLocalStorage === null
        ) {
          throw new Error('LocalStorage is null')
        }
        const parsedSelectedFilters = BrokingFilterAttributeSchema.parse(
          JSON.parse(selectedFiltersFromLocalStorage),
        )
        const parsedSelectedFiltersCondition = BrokingFilterConditionSchema.parse(
          JSON.parse(selectedFiltersConditionFromLocalStorage),
        )
        this.commit(filterState.SET_SELECTED_FILTERS, {
          selectedFilters: parsedSelectedFilters,
        })

        // 開始月と終了月が古くなったものは除外する
        const availableDeliveryYearMonth = parsedSelectedFiltersCondition.startDeliveryYearMonth.filter(
          deliveryYearMonth =>
            moment(deliveryYearMonth).isSameOrAfter(moment(), 'month'),
        )
        const availableDeliveryEndMonth = parsedSelectedFiltersCondition.endDeliveryYearMonth.filter(
          deliveryEndMonth =>
            moment(deliveryEndMonth).isSameOrAfter(moment(), 'month'),
        )

        const refreshedSelectedFiltersConditionFromLocal = {
          ...parsedSelectedFiltersCondition,
          startDeliveryYearMonth: availableDeliveryYearMonth,
          endDeliveryYearMonth: availableDeliveryEndMonth,
        }

        for (const attribute of filterState.state.filterAttributes()) {
          this.commit(filterState.SET_FILTER_CONDITION, {
            attribute: attribute,
            selected: refreshedSelectedFiltersConditionFromLocal[attribute],
          })
        }
      } catch (e) {
        // LocalStorageのデータが壊れている場合は、初期化する
        localStorage.setItem(
          filterState.keyOfSelectedFilters,
          JSON.stringify([]),
        )
        localStorage.setItem(
          filterState.keyOfSelectedFiltersCondition,
          JSON.stringify(filterState.state.filterCondition()),
        )
      }
    }
  }

  addFilterConditionSelected(payload: {
    index: number
    attribute: BrokingFilterAttribute
    value: InputOption
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: [
        ...filterState.state.filterCondition()[payload.attribute],
        payload.value.value,
      ],
    })
    window.localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  addFilterConditionInputSelected(payload: {
    index: number
    attribute: BrokingFilterAttribute
    value?: string
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: payload.value ? [payload.value] : [],
    })
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  removeFilterConditionSelected(payload: {
    index: number
    attribute: BrokingFilterAttribute
    value: InputOption
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: filterState.state
        .filterCondition()
        [payload.attribute].filter(id => id !== payload.value.value),
    })
    window.localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  removeFilterCondition(payload: {
    index: number
    attribute: BrokingFilterAttribute
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_SELECTED_FILTERS, {
      selectedFilters: filterState.state
        .selectedFilters()
        .filter(filter => filter !== payload.attribute),
    })
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: [],
    })

    localStorage.setItem(
      filterState.keyOfSelectedFilters,
      JSON.stringify(filterState.state.selectedFilters()),
    )
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  changeFilter(payload: {
    index: number
    selectedValues: BaseFilterAttribute[]
    changedValue: BaseFilterAttribute
  }) {
    const filterState = findFilterState(payload.index, this.state)

    this.commit(filterState.SET_SELECTED_FILTERS, {
      selectedFilters: payload.selectedValues,
    })
    if (!payload.selectedValues.includes(payload.changedValue)) {
      this.commit(filterState.SET_FILTER_CONDITION, {
        attribute: payload.changedValue,
        selected: [],
      })
    }

    localStorage.setItem(
      filterState.keyOfSelectedFilters,
      JSON.stringify(filterState.state.selectedFilters()),
    )
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  async loadUsers() {
    const participantIdsPromises = this.getters.participantIds.map(id =>
      new UsersApi(apiConfig).getUsers(id),
    )

    const users = (await Promise.all(participantIdsPromises))
      .map(response => plainToClass(User, response.data[0]))
      .filter(user => user !== undefined)

    this.commit(SET_USERS, { users })
  }

  async loadBrokingOrderBooks(isSpread: boolean) {
    const response = await new BrokingOrderBooksApi(
      apiConfig,
    ).getBrokingOrderBooks(isSpread)

    this.commit(SET_BROKING_ORDER_BOOKS, {
      brokingOrderBooks: plainToClass(BrokingOrderBook, response.data),
    })
  }
}
