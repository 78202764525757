import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7ca52e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "receive-email-form" }
const _hoisted_2 = { class: "receive-email-form__indication" }
const _hoisted_3 = { class: "receive-email-form__indication" }
const _hoisted_4 = { class: "receive-email-form__recap" }
const _hoisted_5 = { class: "receive-email-form__recap" }
const _hoisted_6 = { class: "receive-email-form__report" }
const _hoisted_7 = { class: "receive-email-form__invoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_single_checkbox = _resolveComponent("base-input-single-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_single_checkbox, {
        label: _ctx.$t('iam.label.receiveIndicationEmail'),
        name: "receiveIndicationEmail",
        value: _ctx.formValue.receiveIndicationEmail,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('receiveIndicationEmail', $event)))
      }, null, 8, ["label", "value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_input_single_checkbox, {
        label: _ctx.$t('iam.label.receiveLiveMarketEmail'),
        name: "receiveLiveMarketEmail",
        value: _ctx.formValue.receiveLiveMarketEmail,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('receiveLiveMarketEmail', $event)))
      }, null, 8, ["label", "value"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_input_single_checkbox, {
        label: _ctx.$t('iam.label.receiveSpotTradingRecapEmail'),
        name: "receiveSpotTradingRecapEmail",
        value: _ctx.formValue.receiveSpotTradingRecapEmail,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('receiveSpotTradingRecapEmail', $event)))
      }, null, 8, ["label", "value"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_base_input_single_checkbox, {
        label: _ctx.$t('iam.label.receiveDerivativeTradingRecapEmail'),
        name: "receiveDerivativeTradingRecapEmail",
        value: _ctx.formValue.receiveDerivativeTradingRecapEmail,
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('receiveDerivativeTradingRecapEmail', $event)))
      }, null, 8, ["label", "value"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_input_single_checkbox, {
        label: _ctx.$t('iam.label.receiveReportEmail'),
        name: "receiveReportEmail",
        value: _ctx.formValue.receiveReportEmail,
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInput('receiveReportEmail', $event)))
      }, null, 8, ["label", "value"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_base_input_single_checkbox, {
        label: _ctx.$t('iam.label.receiveInvoiceEmail'),
        name: "receiveInvoiceEmail",
        value: _ctx.formValue.receiveInvoiceEmail,
        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onInput('receiveInvoiceEmail', $event)))
      }, null, 8, ["label", "value"])
    ])
  ]))
}