
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseBadge from '@/components/common/BaseBadge.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { Report as ReportModel } from '@/models/reports/Report'

export default defineComponent({
  name: 'ReportListItem',
  components: {
    BaseBadge,
    BaseBox,
    BaseIcon,
    BaseTooltip,
  },
  props: {
    report: {
      type: Object as PropType<ReportModel>,
      required: true,
    },
  },
  emits: ['download-click'],
  data() {
    return {
      locale: Locale,
    }
  },
  computed: {
    hasJaContent(): boolean {
      return !!this.report.reportContents?.ja
    },
    hasEnContent(): boolean {
      return !!this.report.reportContents?.en
    },
    jaContentTitle(): string {
      return this.hasJaContent
        ? this.report.reportContents.ja.title
        : this.$t('common.label.notAvailable').toString()
    },
    enContentTitle(): string {
      return this.hasEnContent
        ? this.report.reportContents[Locale.En].title
        : this.$t('common.label.notAvailable').toString()
    },
  },
  methods: {
    onDownloadClick($event: Event, locale: Locale) {
      $event.preventDefault()
      this.$emit('download-click', {
        reportId: this.report.id,
        reportContentId: this.report.reportContents[locale].id,
        attachmentName: this.report.reportContents[locale].attachmentName,
      })
    },
  },
})
