import { RouteRecordRaw } from 'vue-router'

import Error from '@/views/error/Error.vue'
import Unauthorized from '@/views/error/children/Unauthorized.vue'

export const errorRoutes: RouteRecordRaw[] = [
  {
    path: '/error',
    component: Error,
    children: [
      {
        path: '401',
        name: 'Unauthorized',
        component: Unauthorized,
      },
    ],
  },
]
