import { datadogLogs } from '@datadog/browser-logs'
import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  OrganizationsApi,
  UsersApi,
  UpdateEClearUserSetting,
} from '@/api/generated'
import { OrganizationProfile } from '@/models/iam/OrganizationProfile'
import { UserProfile } from '@/models/iam/UserProfile'
import { UserProfileGetters } from '@/store/modules/iam/userProfile/UserProfileGetters'
import {
  SET_DELETE_PICTURE_SUBMITTING,
  SET_DEPARTMENT,
  SET_LOCALE,
  SET_LOCALE_ORIGINAL,
  SET_LOCALE_SUBMITTING,
  SET_MAIL,
  SET_MOBILE_PHONE_NUMBER,
  SET_NAME,
  SET_ORGANIZATION_ID,
  SET_ORGANIZATION_NAME,
  SET_PICTURE,
  SET_PICTURE_SUBMITTING,
  SET_PHONE_NUMBER,
  SET_ROLE_IN_ORGANIZATION,
  SET_STATUS,
  SET_SERVICES,
  SET_SERVICES_ORIGINAL,
  SET_SERVICES_SUBMITTING,
  SET_USER_ID,
  UserProfileMutations,
} from '@/store/modules/iam/userProfile/UserProfileMutations'
import { UserProfileState } from '@/store/modules/iam/userProfile/UserProfileState'
import {
  arrayBuffer2JpegDataUrl,
  dataUrl2Base64,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class UserProfileActions extends Actions<
  UserProfileState,
  UserProfileGetters,
  UserProfileMutations,
  UserProfileActions
> {
  async fetchUserProfile(payload: { userId: string }) {
    const userProfile = plainToClass(
      UserProfile,
      (await new UsersApi(apiConfig).getUserProfileById(payload.userId)).data,
    )
    this.dispatch('fetchOrganizationName', {
      organizationId: userProfile.organizationId,
    })

    this.commit(SET_USER_ID, { userId: userProfile.userId })
    this.commit(SET_ORGANIZATION_ID, {
      organizationId: userProfile.organizationId,
    })
    this.commit(SET_STATUS, { status: userProfile.status })

    if (isRequiredAuthenticatedRequest(userProfile.picture)) {
      await this.dispatch('fetchPicture', {
        userId: payload.userId,
      })
    } else {
      this.commit(SET_PICTURE, { picture: userProfile.picture })
    }

    this.commit(SET_NAME, { name: userProfile.name })
    this.commit(SET_MAIL, { mail: userProfile.mail })
    this.commit(SET_DEPARTMENT, { department: userProfile.department })
    this.commit(SET_PHONE_NUMBER, { phoneNumber: userProfile.phoneNumber })
    this.commit(SET_MOBILE_PHONE_NUMBER, {
      mobilePhoneNumber: userProfile.mobilePhoneNumber,
    })
    this.commit(SET_LOCALE, { locale: userProfile.locale })
    this.commit(SET_ROLE_IN_ORGANIZATION, {
      roleInOrganization: userProfile.roleInOrganization,
    })
    this.commit(SET_SERVICES, {
      services: userProfile.services,
    })

    this.commit(SET_LOCALE_ORIGINAL, { localeOriginal: userProfile.locale })
    this.commit(SET_SERVICES_ORIGINAL, {
      servicesOriginal: userProfile.services,
    })

    datadogLogs.setUser({
      id: userProfile.userId,
      email: userProfile.mail,
    })
  }

  async updateLocale(payload: { userId: string }) {
    if (this.state.localeSubmitting) {
      return
    }
    this.commit(SET_LOCALE_SUBMITTING, { localeSubmitting: true })

    const userProfile = plainToClass(
      UserProfile,
      (
        await new UsersApi(apiConfig).updateUserProfileById(payload.userId, {
          locale: this.state.locale,
        })
      ).data,
    )

    this.commit(SET_LOCALE, { locale: userProfile.locale })
    this.commit(SET_LOCALE_ORIGINAL, { localeOriginal: userProfile.locale })
    this.commit(SET_LOCALE_SUBMITTING, { localeSubmitting: false })
  }

  async updateServices(payload: { userId: string }) {
    if (this.state.servicesSubmitting) {
      return
    }
    this.commit(SET_SERVICES_SUBMITTING, { servicesSubmitting: true })

    const userProfile = plainToClass(
      UserProfile,
      (
        await new UsersApi(apiConfig).updateUserProfileById(payload.userId, {
          services: {
            esquare: {
              userSetting: { ...this.state.services?.esquare.userSetting },
            },
          },
        })
      ).data,
    )

    this.commit(SET_SERVICES, { services: userProfile.services })
    this.commit(SET_SERVICES_ORIGINAL, {
      servicesOriginal: userProfile.services,
    })
    this.commit(SET_SERVICES_SUBMITTING, { servicesSubmitting: false })
  }

  async updatePicture(payload: { userId: string; body: string }) {
    if (this.state.pictureSubmitting) {
      return
    }
    this.commit(SET_PICTURE_SUBMITTING, { pictureSubmitting: true })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const base64 = dataUrl2Base64(payload.body) as any
    await new UsersApi(apiConfig).updatePictureOfUser(payload.userId, base64)

    this.commit(SET_PICTURE_SUBMITTING, { pictureSubmitting: false })
  }

  async deletePicture(payload: { userId: string }) {
    if (this.state.deletePictureSubmitting) {
      return
    }
    this.commit(SET_DELETE_PICTURE_SUBMITTING, {
      deletePictureSubmitting: true,
    })

    await new UsersApi(apiConfig).deletePictureOfUser(payload.userId)
    // TODO: 戻り値でデフォルトの画像URLが帰って来たらコミット.

    this.commit(SET_DELETE_PICTURE_SUBMITTING, {
      deletePictureSubmitting: false,
    })
  }

  async fetchPicture(payload: { userId: string }) {
    const data = (
      await new UsersApi(apiConfig).getPictureOfUser(payload.userId, {
        responseType: 'arraybuffer',
      })
    ).data

    const dataurl = arrayBuffer2JpegDataUrl((data as unknown) as ArrayBuffer)
    this.commit(SET_PICTURE, { picture: dataurl })
  }

  async fetchOrganizationName(payload: { organizationId: string }) {
    const organizationProfile = plainToClass(
      OrganizationProfile,
      (
        await new OrganizationsApi(apiConfig).getOrganizationProfileById(
          payload.organizationId,
        )
      ).data,
    )

    this.commit(SET_ORGANIZATION_NAME, {
      organizationName: organizationProfile.name,
    })
  }

  async updateEclearUserSetting({
    userId,
    userSetting,
  }: {
    userId: string
    userSetting: UpdateEClearUserSetting
  }): Promise<UserProfile> {
    return plainToClass(
      UserProfile,
      (
        await new UsersApi(apiConfig).updateUserProfileById(userId, {
          services: {
            eclear: {
              userSetting,
            },
          },
        })
      ).data,
    )
  }
}
