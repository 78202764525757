export enum LargeTitleLevel {
  Lv1 = 'large-title1',
  Lv2 = 'large-title2',
}

export enum HeaderLevel {
  Lv1 = 'header1',
  Lv2 = 'header2',
  Lv3 = 'header3',
  Lv4 = 'header4',
  Lv5 = 'header5',
}

export enum TextLevel {
  Large = 'text-large',
  Medium = 'text-medium',
  Small = 'text-small',
}

export enum InnerLabelLevel {
  Button = 'button',
  Label = 'label',
}
