
import { defineComponent } from 'vue'

import StatusToggleButton from '@/components/common/StatusToggleButton.vue'
import OrderBookHeader from '@/components/trading/OrderBookHeader.vue'

export default defineComponent({
  name: 'BrokingOrderBookSpreadListHeader',
  components: {
    OrderBookHeader,
    StatusToggleButton,
  },
  props: {
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    allCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['activate-all', 'deactivate-all'],
  methods: {
    onActivateAll() {
      this.$emit('activate-all')
    },
    onDeactivateAll() {
      this.$emit('deactivate-all')
    },
  },
})
