
import { ErrorMessage, Field, FieldArray } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import BaseLabel from '@/components/common/BaseLabel.vue'
import StatusToggleButton from '@/components/common/StatusToggleButton.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'

export default defineComponent({
  name: 'BaseInputCheckbox',
  components: {
    BaseLabel,
    ErrorMessage,
    Field,
    FieldArray,
    StatusToggleButton,
  },
  props: {
    options: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    togglable: {
      type: Boolean,
      default: false,
    },
    rules: { type: String, default: undefined },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    initialValues: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    help: {
      type: String,
      default: '',
    },
    ignoreOptionsOnToggle: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    activeAllLabel: {
      type: String,
      default: '',
    },
    deActiveAllLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'edit'],
  data(): {
    values: string[]
    touched: boolean
  } {
    return {
      values: this.initialValues,
      touched: false,
    }
  },
  computed: {
    allInactive(): boolean {
      return this.values.length === 0
    },
    readonly(): boolean {
      return [InputMode.READONLY, InputMode.EDIT].includes(this.mode)
    },
    editable(): boolean {
      return this.mode === InputMode.EDIT
    },
    labelDisabled(): CallableFunction {
      return (option: InputOption) => {
        return !!option.disabled
      }
    },
    inputDisabled(): CallableFunction {
      return (option: InputOption) => {
        return this.readonly || !!option.disabled
      }
    },
    wrapperClass(): object {
      return { 'base-input-checkbox__wrapper--editable': this.editable }
    },
    inputClass(): object {
      return { 'base-input-checkbox__input--editable': this.editable }
    },
    labelClass(): object {
      return { 'base-input-checkbox__checkbox--editable': this.editable }
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  watch: {
    initialValues: {
      handler() {
        this.values = this.initialValues
        if (this.initialValues.length > 0) {
          this.touched = true
        }
      },
      deep: true,
    },
  },
  methods: {
    onChange(values: string[]) {
      this.$emit('input', values)
      this.touched = true
    },
    onClick(event: Event) {
      if (this.editable) {
        // 子要素のinputイベントの送出を止める
        event.preventDefault()
        this.$emit('edit')
      }
    },
    onActivateAll() {
      this.options.forEach(option => {
        if (!this.ignoreOptionsOnToggle.includes(option.value)) {
          this.values.push(option.value)
        }
      })
      this.$emit('input', this.values)
      this.touched = true
    },
    onDeactivateAll() {
      this.values.splice(0, this.values.length)
      this.$emit('input', this.values)
      this.touched = true
    },
  },
})
