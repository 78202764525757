
import { defineComponent } from 'vue'

import InternalDashboardPage from '@/components/sales/InternalDashboardPage.vue'

export default defineComponent({
  name: 'VesselAvailability',
  components: {
    InternalDashboardPage,
  },
})
