export const validateName = (value: string): boolean =>
  value.length > 0 && value.length < 50

export const validateDepartment = (value?: string): boolean => {
  if (!value) {
    return false
  }
  return value.length > 0 && value.length < 100
}
export const validatePassword = (value: string): boolean => {
  const isAscii = /^[!-~]*$/
  if (!isAscii.test(value)) {
    return false
  }
  if (value.length < 8) {
    return false
  }

  const policy1 = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{1,}$/ // 小文字・大文字・数字を最低１文字含む
  const policy2 = /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{1,}$/ // 小文字・数字・特殊文字
  const policy3 = /^(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{1,}$/ // 大文字・数字・特殊文字
  const policy4 = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[!-/:-@[-`{-~])[!-~]{1,}$/ // 小文字・大文字・特殊文字
  const isValid =
    policy1.test(value) ||
    policy2.test(value) ||
    policy3.test(value) ||
    policy4.test(value)

  return isValid
}
const name = validateName
const department = validateDepartment
const password = validatePassword

const companyPrefix = (value: string, [areaCode]: string[]) => {
  if (!areaCode) {
    return false
  }
  return new RegExp(`^[A-Z0-9]{4}${areaCode}$`).test(value)
}

const bgCode = (value: string, [areaCode]: string[]) => {
  if (!areaCode) {
    return false
  }
  return new RegExp(`^L[A-Z0-9]{3}${areaCode}$`).test(value)
}

const spsCode = (value: string, [areaCode]: string[]) => {
  if (!areaCode) {
    return false
  }
  return new RegExp(`^G[0-9][A-Z0-9]{2}${areaCode}$`).test(value)
}

const eclearKeyword = (value: string) => {
  return new RegExp(`^[a-zA-Z0-9]{8}$`).test(value)
}

export const VALIDATION_RULES = {
  name,
  department,
  password,
  companyPrefix,
  bgCode,
  spsCode,
  eclearKeyword,
}
