
import debounce from 'lodash/debounce'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { OrganizationSelectorModalProps } from '@/components/iam/interface/OrganizationSelectorModalProps'
import { Organization } from '@/models/iam/Organization'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { isAbortError } from '@/utils/error/AbortError'

const { mapActions: organizationsActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

export default defineComponent({
  name: 'OrganizationSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    BaseInputText,
    BasePicture,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    selectedOrganization: {
      type: Object as PropType<Organization>,
      default: undefined,
    },
    queryParams: {
      type: Object as PropType<OrganizationSelectorModalProps['queryParams']>,
      default: undefined,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    value: string
    organizations: Organization[]
    uiStack: UiStack
    alertTypeInfo: AlertType
    alertTypeError: AlertType
    currentAbortController: AbortController
  } {
    return {
      value: '',
      organizations: [],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
      currentAbortController: new AbortController(),
    }
  },
  async created() {
    await this.search()
  },
  methods: {
    searchOrganizations: organizationsActions(['searchOrganizations'])
      .searchOrganizations as (payload: {
      organizationName?: string
      isEclearCreditSleever?: boolean
      isEsquareEnabled?: boolean
      abortController?: AbortController
    }) => Promise<Organization[]>,
    onSelect(organization: Organization) {
      this.$emit('select', organization)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onInput(value: string) {
      this.value = value
      this.uiStack = UiStack.Loading
      this.debouncedSearch()
    },
    async search() {
      this.uiStack = UiStack.Loading
      this.currentAbortController.abort()
      this.currentAbortController = new AbortController()
      await this.searchOrganizations({
        organizationName: this.value,
        isEclearCreditSleever: this.queryParams?.isEclearCreditSleever,
        isEsquareEnabled: this.queryParams?.isEsquareEnabled,
        abortController: this.currentAbortController,
      })
        .then(organizations => {
          this.organizations = organizations
          this.uiStack =
            organizations.length > 0 ? UiStack.Ideal : UiStack.Blank
        })
        .catch(e => {
          if (isAbortError(e)) {
            return
          }
          this.uiStack = UiStack.Error
        })
    },
    debouncedSearch: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.search()
    }, 750),
  },
})
