import { Module } from 'vuex-smart-module'

import { PublicIndicationActions } from '@/store/modules/trading/publicIndication/PublicIndicationActions'
import { PublicIndicationGetters } from '@/store/modules/trading/publicIndication/PublicIndicationGetters'
import { PublicIndicationMutations } from '@/store/modules/trading/publicIndication/PublicIndicationMutations'
import { PublicIndicationState } from '@/store/modules/trading/publicIndication/PublicIndicationState'

export const publicIndication = new Module({
  state: PublicIndicationState,
  getters: PublicIndicationGetters,
  mutations: PublicIndicationMutations,
  actions: PublicIndicationActions,
})

export type PublicIndicationModule = typeof publicIndication
