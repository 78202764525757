import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6b6388e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "occto-master-selector-modal" }
const _hoisted_2 = { class: "occto-master-selector-modal__inner" }
const _hoisted_3 = { class: "occto-master-selector-modal__result" }
const _hoisted_4 = { class: "occto-master-selector-modal__result-label" }
const _hoisted_5 = { class: "occto-master-selector-modal__result-header" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "occto-master-selector-modal__result" }
const _hoisted_8 = { class: "occto-master-selector-modal__result-label" }
const _hoisted_9 = { class: "occto-master-selector-modal__result-header" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "occto-master-selector-modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
        loading: _withCtx(() => [
          _createVNode(_component_centered_loading_content, {
            type: "grayscale",
            "is-relative": ""
          })
        ]),
        error: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.errorType }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failFetchOcctoMaster')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        ideal: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('trading.label.balancingGroup')), 1),
            (_ctx.balancingGroups.length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.companyPrefix')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.balancingGroupCode')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.balancingGroupName')), 1)
                  ]),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.balancingGroups, (balancingGroup) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: balancingGroup.id,
                        onClick: ($event: any) => (_ctx.onBalancingGroupSelect(balancingGroup))
                      }, [
                        _createVNode(_component_base_box, { class: "occto-master-selector-modal__result-item" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(balancingGroup.companyPrefix), 1),
                            _createElementVNode("div", null, _toDisplayString(balancingGroup.code), 1),
                            _createElementVNode("div", null, _toDisplayString(balancingGroup.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ], 8, _hoisted_6))
                    }), 128))
                  ])
                ], 64))
              : (_openBlock(), _createBlock(_component_base_alert, {
                  key: 1,
                  type: _ctx.alertTypeInfo
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('trading.message.notFoundBalancingGroup')), 1)
                  ]),
                  _: 1
                }, 8, ["type"]))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('trading.label.supplyPlanSubmitter')), 1),
            (_ctx.supplyPlanSubmitters.length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.companyPrefix')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.supplyPlanSubmitterCode')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.supplyPlanSubmitterName')), 1)
                  ]),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supplyPlanSubmitters, (supplyPlanSubmitter) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: supplyPlanSubmitter.id,
                        onClick: ($event: any) => (_ctx.onSupplyPlanSubmitterSelect(supplyPlanSubmitter))
                      }, [
                        _createVNode(_component_base_box, { class: "occto-master-selector-modal__result-item" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(supplyPlanSubmitter.companyPrefix), 1),
                            _createElementVNode("div", null, _toDisplayString(supplyPlanSubmitter.code), 1),
                            _createElementVNode("div", null, _toDisplayString(supplyPlanSubmitter.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ], 8, _hoisted_10))
                    }), 128))
                  ])
                ], 64))
              : (_openBlock(), _createBlock(_component_base_alert, {
                  key: 1,
                  type: _ctx.alertTypeInfo
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('trading.message.notFoundSupplyPlanSubmitter')), 1)
                  ]),
                  _: 1
                }, 8, ["type"]))
          ])
        ]),
        _: 1
      }, 8, ["ui-stack"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_base_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}