
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import CreditSleeveIcon from '@/components/trading/CreditSleeveIcon.vue'
import DeliveryTerms from '@/components/trading/DeliveryTerms.vue'
import DeliveryUnitIcon from '@/components/trading/DeliveryUnitIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import SpreadBadgeList from '@/components/trading/SpreadBadgeList.vue'
import { SpreadDeliveryTermsProps } from '@/components/trading/interface/DeliveryTerms'
import { Negotiation } from '@/models/trading/Negotiation'
import { Product } from '@/models/trading/Product'

export default defineComponent({
  name: 'NegotiationListItem',
  components: {
    BaseBox,
    BaseLink,
    BaseTooltip,
    CreditSleeveIcon,
    DeliveryTerms,
    DeliveryUnitIcon,
    ProductType,
    SpreadBadgeList,
  },
  props: {
    negotiation: {
      type: Object as PropType<Negotiation>,
      required: true,
    },
  },
  computed: {
    itemPath(): string {
      return `/negotiations/${this.negotiation.id}`
    },
    product(): Product {
      // askとbidのproductは交渉時には同じなのでaskを参照している
      return this.negotiation.askNegotiationDetail.product
    },
    deliveryTerms(): SpreadDeliveryTermsProps {
      return this.product.spreadDeliveryTerms()
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.negotiation.askNegotiationDetail.indication.fuelSurchargeType?.name.translation()
    },
  },
})
