
import { defineComponent } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BasePicture from '@/components/common/BasePicture.vue'

export default defineComponent({
  name: 'OrganizationBody',
  components: {
    BaseBox,
    BasePicture,
  },
  props: {
    picture: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
