
import { defineComponent, PropType } from 'vue'

import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'

type TabTriggerElement = HTMLAnchorElement | HTMLButtonElement

export default defineComponent({
  name: 'HorizontalTab',
  props: {
    tabList: {
      type: Array as PropType<HorizontalTabItem[]>,
      required: true,
    },
    currentTabIndex: {
      type: Number,
      default: 0,
    },
    ariaLabelledBy: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tab-activate'],
  data() {
    return {
      tabIndex: this.currentTabIndex,
    }
  },
  computed: {
    tabTriggers(): TabTriggerElement[] {
      return this.$refs.tabTrigger as TabTriggerElement[]
    },
    modifierName(): string {
      return this.isChild ? 'child' : 'parent'
    },
  },
  watch: {
    currentTabIndex() {
      this.tabIndex = this.currentTabIndex
    },
  },
  methods: {
    isCurrentTab(tabIndex: number): boolean {
      return this.tabIndex === tabIndex
    },
    getTabTypeId(type: 'tab' | 'tabPanel', tabIndex: number): string {
      return `${type}-${tabIndex}`
    },
    onClickLink(e: MouseEvent) {
      e.preventDefault()
      if (e.target instanceof HTMLAnchorElement) {
        const path = new URL(e.target.href).pathname
        this.$router.push(path)
      }
    },
    onClickTabButton(tabIndex: number): void {
      if (this.isCurrentTab(tabIndex)) {
        return
      }
      this.tabIndex = tabIndex
      this.$emit(`tab-activate`, tabIndex)
    },
    onFocus(nextIndex: number): void {
      this.tabTriggers.forEach((target: TabTriggerElement, index: number) => {
        if (index === nextIndex) {
          target.focus()
        } else {
          target.blur()
        }
      })
    },
    onKeyDown(e: KeyboardEvent): void {
      const currentElementIndex = this.tabTriggers.findIndex(
        (target: TabTriggerElement) => target.isEqualNode(e.target as Node),
      )
      const currentTargetElement = this.tabTriggers[currentElementIndex]

      switch (e.code) {
        case 'ArrowRight': {
          const nextIndex = (() => {
            const tmpIndex =
              this.tabList.length - 1 === currentElementIndex
                ? 0
                : currentElementIndex + 1
            if (this.tabIndex === tmpIndex) {
              return this.tabList.length - 1 === tmpIndex ? 0 : tmpIndex + 1
            }
            return tmpIndex
          })()
          this.onFocus(nextIndex)
          break
        }
        case 'ArrowLeft': {
          const nextIndex = (() => {
            const tmpIndex =
              currentElementIndex === 0
                ? this.tabList.length - 1
                : currentElementIndex - 1
            if (this.tabIndex === tmpIndex) {
              return tmpIndex === 0 ? this.tabList.length - 1 : tmpIndex - 1
            }
            return tmpIndex
          })()
          this.onFocus(nextIndex)
          break
        }
        case 'Enter':
        case 'Space': {
          if (currentTargetElement instanceof HTMLButtonElement) {
            setTimeout(() => {
              const nextIndex =
                this.tabList.length - 1 === this.tabIndex
                  ? 0
                  : this.tabIndex + 1
              this.onFocus(nextIndex)
            }, 200)
          }
          break
        }
        case 'Escape': {
          currentTargetElement.blur()
          break
        }
      }
    },
  },
})
