import { camelize } from 'humps'

import { NegotiationStatusType } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { i18n } from '@/i18n'
import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import {
  NegotiationFilterAttribute,
  NegotiationFilterConditions,
} from '@/store/modules/trading/negotiation/interface'

export const getNegotiationsFilter = (
  areas: Area[],
  hourTypes: HourType[],
  productTypes: ProductType[],
  filterAttributes: NegotiationFilterAttribute[],
  filterCondition: NegotiationFilterConditions,
  deliveryYearMonths: DeliveryYearMonth[],
): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    switch (attribute) {
      case 'startDeliveryYearMonth': {
        const options = deliveryYearMonths.map(type =>
          type.toProductFiltersOption(),
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'endDeliveryYearMonth': {
        const options = deliveryYearMonths.map(type =>
          type.toProductFiltersOption(),
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'area': {
        const options = areas.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'hourType': {
        const options = hourTypes.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'productType': {
        const options = productTypes.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'status': {
        const options: InputOption[] = Object.values(NegotiationStatusType).map(
          value => {
            return {
              value: value.toString(),
              label: i18n
                .t(`trading.label.negotiationStatusType.${camelize(value)}`)
                .toString(),
            }
          },
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'organizationName':
      case 'negotiationId':
      default:
        return {
          type: 'text',
          attribute,
          value: filterCondition[attribute],
        }
    }
  })
}
