import { Type } from 'class-transformer'

import { EEXPostingStatusType } from '@/api/generated'
import { EEXPostingDetail } from '@/models/trading/EEXPostingDetail'

export class EEXPosting {
  id!: string
  productCode!: string
  instrument!: string
  quantity!: string
  price!: string
  status!: EEXPostingStatusType
  updatedAt!: string

  @Type(() => EEXPostingDetail)
  askEEXPostingDetail!: EEXPostingDetail

  @Type(() => EEXPostingDetail)
  bidEEXPostingDetail!: EEXPostingDetail
}
