
import { defineComponent } from 'vue'

import { UiStack } from '@/components/common/constants/UiStack'

export default defineComponent({
  name: 'UiStackSelector',
  props: {
    uiStack: {
      type: Number,
      default: UiStack.Loading,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.uiStack === UiStack.Loading
    },
    isBlank(): boolean {
      return this.uiStack === UiStack.Blank
    },
    isPartial(): boolean {
      return this.uiStack === UiStack.Partial
    },
    isError(): boolean {
      return this.uiStack === UiStack.Error
    },
    isIdeal(): boolean {
      return this.uiStack === UiStack.Ideal
    },
  },
})
