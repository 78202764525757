import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-281e05cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-date-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_date_picker = _resolveComponent("base-date-picker")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_date_picker, {
      class: "filter-date-select__selector",
      locale: _ctx.locale,
      value: _ctx.valueOfDate,
      placeholder: _ctx.placeholder,
      onInput: _ctx.onSelect
    }, null, 8, ["locale", "value", "placeholder", "onInput"]),
    _createVNode(_component_base_icon, {
      class: "filter-date-select__close",
      "icon-name": "times-circle",
      onClick: _ctx.onClick
    }, null, 8, ["onClick"])
  ]))
}