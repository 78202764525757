import { Mutations } from 'vuex-smart-module'

import { IndicationState } from '@/store/modules/trading/indication/IndicationState'
import { IndicationFilterConditions } from '@/store/modules/trading/indication/interface'

export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION'

export class IndicationMutations extends Mutations<IndicationState> {
  [SET_FILTER_CONDITION](payload: {
    filterCondition: IndicationFilterConditions
  }) {
    this.state.filterCondition = payload.filterCondition
  }
}
