
import debounce from 'lodash/debounce'
import moment from 'moment'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import {
  DeliveryUnit,
  NewNonStandardIndication,
  NewStandardIndication,
  PositionType,
  SpreadTypeTypeEnum,
  StandardIndicationSelectableInputsRequest,
  StandardIndicationSelectableInputsSpellRequest,
} from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { InputOption } from '@/components/common/interface/InputOption'
import BrokerIndicationForm from '@/components/trading/BrokerIndicationForm.vue'
import IndicationConfirmModalContent from '@/components/trading/IndicationConfirmModalContent.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { BrokerIndicationFormProps } from '@/components/trading/interface/BrokerIndicationFormProps'
import { DeliveryPatternComponentFormProps } from '@/components/trading/interface/DeliveryPatternComponentFormProps'
import { buildNewStandardIndicationPayload } from '@/components/trading/services/indicationPayloadBuilder'
import { buildOpenedSpreadFormTypesForSpell } from '@/components/trading/services/openedSpreadFormTypesBuilder'
import { spellValidationErrorMessageParser } from '@/components/trading/services/spellValidationErrorMessageParser'
import {
  buildProductFormValue,
  buildSelectableInputsRequest,
} from '@/components/trading/services/starndardIndicationSelectableInputsServices'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { DayPattern } from '@/models/trading/DayPattern'
import { Indication } from '@/models/trading/Indication'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { MemberModule } from '@/store/modules/iam/member'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { IndicationModule } from '@/store/modules/trading/indication'
import { ProductModule } from '@/store/modules/trading/product'
import { setNotification } from '@/utils/utils'

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule
const { mapActions: indicationMapActions } = createNamespacedHelpers(
  'indication',
) as IndicationModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule
const { mapActions: organizationsMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule
const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

export default defineComponent({
  name: 'BrokerIndicationNew',
  components: {
    BaseAlert,
    BaseButton,
    BrokerIndicationForm,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    IndicationConfirmModalContent,
    UiStackSelector,
    ValidationForm,
  },
  emits: ['confirm', 'cancel'],
  props: {
    copyFromIndicationId: {
      type: String,
    },
  },
  data(): {
    formValue: BrokerIndicationFormProps
    traders: MemberProfile[]
    brokers: MemberProfile[]
    dayPatternOptions: InputOption[]
    uiStack: UiStack
    errorType: AlertType
    modalName: string
    submitting: boolean
    mode: IndicationFormInputMode.NEW
    openedSpreadFormTypes: SpreadTypeTypeEnum[]
    selectableInputs: BaseStandardIndicationSelectableInputs | undefined
    selectableInputsRequest: StandardIndicationSelectableInputsRequest
    currentAbortController: AbortController
    dateNames: ProductTypeDateName[]
    spellErrorMessages: string[]
  } {
    return {
      formValue: {
        openToMarket: true,
        isVisibleToTraders: false,
        position: PositionType.Bid,
        deliveryUnit: DeliveryUnit.Month,
        productTypeIds: [],
        fuelSurchargeTypeId: undefined,
        base: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        exchange: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        deliveryPeriods: [
          {
            startDeliveryDate: undefined,
            endDeliveryDate: undefined,
            deliveryPatternComponentFormValues: [
              {
                dayPattern: undefined,
                timeRangeStart: undefined,
                timeRangeEnd: undefined,
              },
            ],
            unitPrice: '',
            volume: null,
          },
        ],
        price: {
          type: 'volume',
          energyUnitPrices: [],
          basicUnitPrice: undefined,
        },
        unitPrices: [],
        request: '',
        trader: undefined,
        broker: undefined,
        status: undefined,
        organization: undefined,
        excludedEnecCurve: false,
        publicInformation: '',
        maskVolume: false,
        spell: '',
      },
      traders: [],
      brokers: [],
      dayPatternOptions: [],
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      modalName: 'brokerIndicationConfirmModalContent',
      submitting: false,
      mode: IndicationFormInputMode.NEW,
      openedSpreadFormTypes: [],
      selectableInputs: undefined,
      selectableInputsRequest: {},
      currentAbortController: new AbortController(),
      dateNames: [],
      spellErrorMessages: [],
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['productTypes']),
  },
  async created() {
    this.selectableInputsRequest = {
      indicationId: this.copyFromIndicationId ?? undefined,
    }

    await Promise.all([
      this.fetchDayPatterns() as Promise<DayPattern[]>,
      this.fetchProductTypeDateNames() as Promise<ProductTypeDateName[]>,
      this.fetchProductTypes(),
      this.fetchSelectableInputs(new AbortController()),
      this.fetchBrokers(),
    ])
      .then(([dayPatterns, dateNames]) => {
        this.dayPatternOptions = dayPatterns.map(dayPattern =>
          dayPattern.toInputOption(this.userProfile.locale),
        )
        this.dateNames = dateNames
        this.uiStack = UiStack.Ideal
      })
      .catch(() => {
        this.uiStack = UiStack.Error
      })

    if (this.copyFromIndicationId) {
      await Promise.all([
        this.fetchIndication(this.copyFromIndicationId) as Promise<Indication>,
        this.fetchIndicationStatusTypes() as Promise<IndicationStatusType[]>,
        this.fetchDayPatterns() as Promise<DayPattern[]>,
        this.fetchSelectableInputs(new AbortController()),
      ])
        .then(async ([indication, statusTypes]) => {
          this.openedSpreadFormTypes = (indication.spreadTypes || []).map(
            s => s.type,
          )
          const organization = await this.fetchOrganization(
            indication.organizationId,
          )
          // 後続処理に依存関係はないので no await
          this.fetchTraders(organization.organizationId)

          this.formValue = {
            ...this.formValue,
            organization,
            trader: indication.traderInputOption,
            broker: indication.brokerInputOption,
            status: statusTypes
              .find(type => type.id === indication.status)
              ?.toInputOption(this.userProfile.locale),
            openToMarket: indication.openToMarket,
            isVisibleToTraders: indication.isVisibleToTraders,
            excludedEnecCurve: indication.excludedEnecCurve,
            publicInformation: indication.publicInformation,
            maskVolume: indication.maskVolume,
          }
          if (this.formValue.deliveryUnit === DeliveryUnit.NonStandard) {
            this.setDeliveryPeriods(indication)
          }
        })
        .catch(() => {
          this.uiStack = UiStack.Error
        })
    } else {
      this.uiStack = UiStack.Ideal
    }
  },
  methods: {
    createStandardIndication: indicationMapActions(['createStandardIndication'])
      .createStandardIndication as (
      payload: NewStandardIndication,
    ) => Promise<Indication>,
    ...indicationMapActions(['fetchIndication', 'fetchIndicationStatusTypes']),
    createNonStandardIndication: indicationMapActions([
      'createNonStandardIndication',
    ]).createNonStandardIndication as (
      payload: NewNonStandardIndication,
    ) => Promise<Indication>,
    fetchStandardIndicationSelectableInputs: indicationMapActions([
      'fetchStandardIndicationSelectableInputs',
    ]).fetchStandardIndicationSelectableInputs as (payload: {
      request: StandardIndicationSelectableInputsRequest
      abortController: AbortController
    }) => Promise<BaseStandardIndicationSelectableInputs>,
    fetchStandardIndicationSelectableInputsBySpell: indicationMapActions([
      'fetchStandardIndicationSelectableInputsBySpell',
    ]).fetchStandardIndicationSelectableInputsBySpell as (payload: {
      request: StandardIndicationSelectableInputsSpellRequest
      abortController: AbortController
    }) => Promise<BaseStandardIndicationSelectableInputs>,
    ...productMapActions([
      'fetchDayPatterns',
      'fetchProductTypes',
      'fetchProductTypeDateNames',
    ]),
    fetchBrokerOrganization: organizationsMapActions([
      'fetchBrokerOrganization',
    ]).fetchBrokerOrganization as () => Promise<Organization>,
    fetchMembers: memberMapActions(['fetchMembers']).fetchMembers as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any,
    ) => Promise<MemberProfile[]>,
    fetchOrganization: organizationsMapActions(['fetchOrganization'])
      .fetchOrganization as (id: string) => Promise<Organization>,
    buildPayload(): NewStandardIndication | undefined {
      if (!this.formValue || !this.selectableInputs) {
        return
      }
      return buildNewStandardIndicationPayload({
        formValue: this.formValue,
        selectableInputs: this.selectableInputs,
      })
    },
    debouncedFetchSelectableInputs: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.fetchSelectableInputs(new AbortController())
    }, 500),
    async fetchSelectableInputs(abortController: AbortController) {
      this.currentAbortController.abort()
      this.currentAbortController = abortController
      await this.fetchStandardIndicationSelectableInputs({
        request: this.selectableInputsRequest,
        abortController: this.currentAbortController,
      })
        .then((selectableInputs: BaseStandardIndicationSelectableInputs) => {
          this.selectableInputs = selectableInputs
          this.formValue = {
            ...this.formValue,
            ...buildProductFormValue({
              formValue: {
                ...this.formValue,
                maskVolume: selectableInputs.isSpread
                  ? false
                  : this.formValue.maskVolume,
              },
              selectableInputs,
            }),
          }
        })
        .catch(e => {
          if (e.message === 'canceled') {
            return
          }
          throw e
        })
    },
    async fetchSelectableInputsBySpell(abortController: AbortController) {
      if (this.formValue.spell === undefined) {
        return
      }
      this.currentAbortController.abort()
      this.currentAbortController = abortController

      const request = {
        spell: this.formValue.spell,
        traderId: this.formValue.trader?.value,
      }

      this.spellErrorMessages = []

      try {
        const selectableInputs = await this.fetchStandardIndicationSelectableInputsBySpell(
          {
            request,
            abortController: this.currentAbortController,
          },
        )
        this.selectableInputs = selectableInputs
        this.spellErrorMessages = spellValidationErrorMessageParser(
          selectableInputs.spellValidationErrorMessage,
        )

        Object.assign(
          this.formValue,
          buildProductFormValue({
            formValue: {
              ...this.formValue,
              maskVolume: selectableInputs.isSpread
                ? false
                : this.formValue.maskVolume,
            },
            selectableInputs,
            isInitialize: true,
          }),
        )
        this.openedSpreadFormTypes = buildOpenedSpreadFormTypesForSpell({
          selectableInputs,
        })
      } catch (e) {
        if (e.message === 'canceled') {
          return
        }
        this.spellErrorMessages = [e.message]
        throw e
      }
    },
    async onFormInput(formValue: BrokerIndicationFormProps) {
      if (!this.selectableInputs) {
        return
      }

      if (
        formValue.organization &&
        this.formValue.organization?.organizationId !==
          formValue.organization.organizationId
      ) {
        // 後続処理に依存関係はないので no await
        this.fetchTraders(formValue.organization.organizationId)
        formValue.trader = undefined
      }

      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })

      await this.fetchSelectableInputs(new AbortController())
    },
    async onTextInput(formValue: BrokerIndicationFormProps) {
      if (!this.selectableInputs) {
        return
      }

      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })

      await this.debouncedFetchSelectableInputs()
    },
    async onSpellInput(value: string) {
      this.formValue.spell = value
    },
    async fetchTraders(organizationId: string) {
      this.traders = await this.fetchMembers({ organizationId })
    },
    async fetchBrokers() {
      const { organizationId } = await this.fetchBrokerOrganization()
      this.brokers = await this.fetchMembers({ organizationId })
    },
    buildNonStandardPayload(): NewNonStandardIndication | undefined {
      if (this.formValue.base.areaId === undefined) {
        return undefined
      }
      if (this.formValue.organization === undefined) {
        return undefined
      }
      const format = 'YYYY-MM-DD'
      const deliveryPeriods = this.formValue.deliveryPeriods
        .filter(
          (
            deliveryPeriod,
          ): deliveryPeriod is {
            startDeliveryDate: Date
            endDeliveryDate: Date
            deliveryPatternComponentFormValues: {
              [K in keyof DeliveryPatternComponentFormProps]: Exclude<
                DeliveryPatternComponentFormProps[K],
                undefined
              >
            }[]
            unitPrice: string
            volume: string
          } =>
            deliveryPeriod.startDeliveryDate !== undefined &&
            deliveryPeriod.endDeliveryDate !== undefined &&
            deliveryPeriod.deliveryPatternComponentFormValues.every(
              deliveryPatternComponentFormValue =>
                deliveryPatternComponentFormValue.dayPattern !== undefined &&
                deliveryPatternComponentFormValue.timeRangeStart !==
                  undefined &&
                deliveryPatternComponentFormValue.timeRangeEnd !== undefined,
            ),
        )
        .map(deliveryPeriod => {
          return {
            deliveryPattern: {
              deliveryPatternComponents: deliveryPeriod.deliveryPatternComponentFormValues.map(
                deliveryPatternComponentFormValue => {
                  return {
                    dayPatternId:
                      deliveryPatternComponentFormValue.dayPattern.value,
                    timeRange: {
                      start: Number(
                        deliveryPatternComponentFormValue.timeRangeStart.value,
                      ),
                      end: Number(
                        deliveryPatternComponentFormValue.timeRangeEnd.value,
                      ),
                    },
                  }
                },
              ),
            },
            deliveryStartDate: moment(deliveryPeriod.startDeliveryDate).format(
              format,
            ),
            deliveryEndDate: moment(deliveryPeriod.endDeliveryDate).format(
              format,
            ),
          }
        })
      if (deliveryPeriods.length === 0) {
        return undefined
      }
      return {
        deliveryPeriods,
        productTypeIds: this.formValue.productTypeIds,
        areaId: this.formValue.base.areaId,
        position: this.formValue.position,
        unitPrices: this.formValue.deliveryPeriods.map(deliveryPeriod =>
          Number(deliveryPeriod.unitPrice),
        ),
        volumes: this.formValue.deliveryPeriods.map(deliveryPeriod => {
          // FIXME: openapi-generator で生成されるコードで配列内の nullable が無視されるため暫定対応として any を許容している
          return (deliveryPeriod.volume === null
            ? null
            : // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Number(deliveryPeriod.volume)) as any
        }),
        fuelSurchargeTypeId: this.formValue.fuelSurchargeTypeId,
        request: this.formValue.request,
        isVisibleToTraders: this.formValue.isVisibleToTraders,
        openToMarket: this.formValue.openToMarket,
        brokerId: this.formValue.broker?.value,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization.organizationId,
        excludedEnecCurve: this.formValue.excludedEnecCurve,
        publicInformation: this.formValue.publicInformation,
        maskVolume: this.formValue.maskVolume ?? false,
      }
    },
    async createIndication(thenable: Promise<Indication>) {
      this.submitting = true
      await thenable
        .then(async () => {
          setNotification(
            this.$t('trading.message.successCreateIndication').toString(),
          )
          this.$vfm.close(this.modalName)
          this.$emit('confirm')
        })
        .catch(() => {
          setNotification(
            this.$t('trading.message.failCreateIndication').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
    async onApplyClick() {
      await this.fetchSelectableInputsBySpell(new AbortController())
    },
    async onConfirmClick() {
      this.$vfm.open(this.modalName)
    },
    async onCancelClick() {
      this.$emit('cancel')
    },
    async onCreateIndicationConfirm() {
      if (
        this.formValue.deliveryUnit === DeliveryUnit.Month ||
        this.formValue.deliveryUnit === DeliveryUnit.WeekOrDay
      ) {
        const payload = this.buildPayload()
        if (payload === undefined) {
          return
        }
        await this.createIndication(this.createStandardIndication(payload))
      } else if (this.formValue.deliveryUnit === DeliveryUnit.NonStandard) {
        const payload = this.buildNonStandardPayload()
        if (payload === undefined) {
          return
        }
        await this.createIndication(this.createNonStandardIndication(payload))
      }
    },
    async onCreateIndicationCancel() {
      this.$vfm.close(this.modalName)
    },
    async onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = Array.from(
        new Set([...this.openedSpreadFormTypes, type]),
      )
      this.formValue.exchange.volumes[0] =
        this.formValue.exchange.volumes[0] || this.formValue.base.volumes[0]
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
    async onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = this.openedSpreadFormTypes.filter(
        t => t !== type,
      )
      switch (type) {
        case SpreadTypeTypeEnum.Area:
          this.formValue.exchange.areaId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPattern:
          this.formValue.exchange.hourTypeId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPeriod:
          this.formValue.exchange.startDeliveryYearMonthId = undefined
          this.formValue.exchange.endDeliveryYearMonthId = undefined
          this.formValue.exchange.startDeliveryDate = undefined
          this.formValue.exchange.endDeliveryDate = undefined
          break
      }
      if (this.openedSpreadFormTypes.length === 0) {
        this.formValue.exchange.volumes = []
      }
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
    isInvalid(veeValidateInvalid: boolean): boolean {
      if (DeliveryUnit.NonStandard === this.formValue.deliveryUnit) {
        // 非標準はselectable-inputs未対応のためvee-validateのinvalidフラグを参照する
        return this.formValue.organization === undefined || veeValidateInvalid
      }

      // 初回レンダリング時に正しく string[] が空(undefined)な場合の処理を行ってくれないため、こちらでValidationを実施
      if (!this.formValue.unitPrices[0]) {
        return true
      }

      return (
        !this.selectableInputs?.isValid ||
        this.formValue.organization === undefined
      )
    },
    setDeliveryPeriods(indication: Indication): void {
      const product = indication.products[0]
      this.formValue.deliveryPeriods = product.deliveryTerms.deliveryPeriods.map(
        ({ deliveryStartDate, deliveryEndDate, deliveryPattern }) => {
          return {
            startDeliveryDate: moment(deliveryStartDate).toDate(),
            endDeliveryDate: moment(deliveryEndDate).toDate(),
            deliveryPatternComponentFormValues: deliveryPattern.deliveryPatternComponents.map(
              ({ timeRange, dayPattern }) => {
                const halfHourRegex = /\.5$/
                const startValue = timeRange.start.toString()
                const startLabel = halfHourRegex.test(startValue)
                  ? `${startValue.replace('.5', '')}:30`
                  : `${startValue}:00`
                const endValue = timeRange.end.toString()
                const endLabel = halfHourRegex.test(endValue)
                  ? `${endValue.replace('.5', '')}:30`
                  : `${endValue}:00`
                return {
                  dayPattern: this.dayPatternOptions.find(
                    d => d.value === dayPattern.id,
                  ),
                  timeRangeStart: {
                    value: startValue,
                    label: startLabel,
                  },
                  timeRangeEnd: {
                    value: endValue,
                    label: endLabel,
                  },
                }
              },
            ),
            // 注文複製時は unitPriceとVolumeを引き継がない
            unitPrice: '',
            volume: null,
          }
        },
      )
    },
  },
})
