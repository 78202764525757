import uniqBy from 'lodash/uniqBy'
import moment from 'moment/moment'

import { DeliveryUnit } from '@/api/generated'
import { i18n } from '@/i18n'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { Deal } from '@/models/trading/Deal'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { DeliveryPeriod } from '@/models/trading/DeliveryPeriod'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'
import { Product } from '@/models/trading/Product'
import { ProductType } from '@/models/trading/ProductType'
import { RecapReceiver } from '@/models/trading/RecapReceiver'
import {
  DataDetail,
  DeliveryTermsAnnex,
  DeliveryTermsAnnexTableItem,
  DetailItem,
  RecapBulkResponse,
  RecapDetail,
  RecapState,
  RecapStateDetail,
  RecapStatus,
} from '@/store/modules/trading/recap/interface'

export const SERVICE = 'esquare'
export const MAX_DIGIT = 5
// RecapAPI
export enum RecapEndpointType {
  BG_JEPX_NO_SURCHARGE = 'bgJepxNoSurcharge',
  EEX_FOR_SELLER = 'eexForSeller',
  EEX_FOR_BUYER = 'eexForBuyer',
  TOCOM_FOR_SELLER = 'tocomForSeller',
  TOCOM_FOR_BUYER = 'tocomForBuyer',
  SWAP_BILATERAL = 'swapBilateral',
  JERA_FUEL_SURCHARGE = 'JERA_FuelSurcharge',
  JERA_FUEL_SURCHARGE_WITH_OPTION = 'JERA_FuelSurcharge_with_option',
}

// @see https://www.notion.so/enechain/Recap-b39d4f89e67e46dc9a87afbe7b29b140?pvs=4#bbef8215b12347f3aae166c48302759a
export const RECAP_ORDER_ASK = 1
export const RECAP_ORDER_ASK_SPREAD = 2
export const RECAP_ORDER_BID = 10 // askとbidの間に追加のページをくることを考慮して並び順の間隔をあけておく
export const RECAP_ORDER_BID_SPREAD = 11

export const convertTerms = (product: Product): string => {
  const start = convertDate(product.deliveryTerms.displayStartByDate('en'))
  const end = convertDate(product.deliveryTerms.displayEndByDate('en'))
  let result = `${start}-${end}`

  if (product.exchangeDeliveryTerms) {
    // 交換側ありならその期間を表示するか決める
    const exchangeStart = convertDate(
      product.exchangeDeliveryTerms.displayStartByDate('en'),
    )
    const exchangeEnd = convertDate(
      product.exchangeDeliveryTerms.displayEndByDate('en'),
    )
    // 期間が異なる場合のみ出力に交換側を記載する
    if (start !== exchangeStart || end !== exchangeEnd) {
      result += `<->${exchangeStart}-${exchangeEnd}`
    }
  }

  return result
}

export const convertHourTypes = (product: Product): string => {
  const deliveryPatternName = product.hourType?.translation() ?? ''
  const exchangeDeliveryPatternName = product.exchangeHourType?.translation()
  const isDeliveryPatternSpread =
    !!exchangeDeliveryPatternName &&
    deliveryPatternName !== exchangeDeliveryPatternName

  let result = `${product.hourType?.translation()}`
  if (isDeliveryPatternSpread) {
    result += `<->${product.exchangeHourType?.translation()}`
  }
  return result
}

export const convertAreaName = (product: Product): string => {
  if (
    product.exchangeDeliveryTerms &&
    product.deliveryTerms.areaName() !==
      product.exchangeDeliveryTerms.areaName()
  ) {
    return `${product.deliveryTerms.areaName()}${product.exchangeDeliveryTerms.areaName()}${i18n.t(
      'trading.label.spread',
    )}`
  }

  return product.deliveryTerms.areaName()
}

export const convertProductName = (
  deal: Deal,
  deliveryTerms: DeliveryTerms,
): string => {
  let hourType: string
  if (
    isNonStandard(deal.product) &&
    (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents.length > 1)
  ) {
    hourType = i18n.t('trading.label.recapNonStandardAppendix')
  } else if (
    isNonStandard(deal.product) &&
    deal.product.deliveryTerms.deliveryPeriods.length === 1 &&
    deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
      .deliveryPatternComponents.length === 1
  ) {
    const pattern =
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents[0]
    hourType =
      `- ${pattern.displayDayPatternName()}${pattern.displayTimeRange()}` || ''
  } else {
    hourType = deliveryTerms.hourTypeName() ?? ''
  }
  let fuelSurchargeType: string
  if (deal.fuelSurchargeType) {
    fuelSurchargeType = ` (${deal.fuelSurchargeType?.translation()})`
  } else {
    fuelSurchargeType = ''
  }

  const productName = deal.product.productType.isCfd()
    ? deal.product.productType.translation().replaceAll('CFD', 'JEPX渡し')
    : deal.product.productType.translation()

  return `${deliveryTerms.areaName()} - ${productName}${fuelSurchargeType}${
    hourType ? ` - ${hourType}` : ''
  }`
}

export const getRecapTitle = (data: DealWithOrganizationRecap): string => {
  const { deal } = data
  const executionDate = moment(deal.executedAt).format('YYYYMMDD')
  const area = convertAreaName(data.deal.product)
  const productName = deal.product.productType.isCfd()
    ? deal.product.productType.translation().replaceAll('CFD', 'JEPX渡し')
    : deal.product.productType.translation()
  const terms = convertTerms(deal.product)

  let hourType: string
  if (
    isNonStandard(deal.product) &&
    (deal.product.deliveryTerms.deliveryPeriods.length > 1 ||
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents.length > 1)
  ) {
    const pattern =
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents[0]
    hourType =
      `${pattern.displayDayPatternName()}${pattern.displayTimeRange()}他` || ''
  } else if (
    isNonStandard(deal.product) &&
    deal.product.deliveryTerms.deliveryPeriods.length > 0 &&
    deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
      .deliveryPatternComponents.length === 1
  ) {
    const pattern =
      deal.product.deliveryTerms.deliveryPeriods[0].deliveryPattern
        .deliveryPatternComponents[0]
    hourType =
      `${pattern.displayDayPatternName()}${pattern.displayTimeRange()}` || ''
  } else {
    hourType = deal.product.hourType?.translation()
      ? `${convertHourTypes(deal.product)}`
      : ''
  }

  return `【Recap】From enechain) ${executionDate}_${area} - ${productName} - ${terms}${
    hourType ? ` - ${hourType}` : ''
  }_${deal.publicNegotiationId}`
}

export const convertQuantity = (prices: number[]): string => {
  if (!prices.length) {
    return ''
  }
  if (prices.length > 1) {
    return i18n.t('trading.label.recapSeeAppendix')
  }

  return `${prices[0]} ${i18n.t('trading.label.volumeUnit')}`
}

// @see https://www.notion.so/enechain/Recap-b39d4f89e67e46dc9a87afbe7b29b140?pvs=4#84e5b2cf083e4339ad954be79cc9c4e9
export enum FooterPhoneNumber {
  ENECHAIN = '050-1791-4699',
  ECLEAR = '03-6264-7000',
}

export const FooterMail = {
  ENECHAIN: String(process.env.VUE_APP_RECAP_OPERATOR_MAIL_ADDRESS || ''),
  ECLEAR: 'eclear-jukyu@eclear.co.jp',
}

export const convertDate = (date: string): string => {
  return moment(date).format('YYYY/MM/DD')
}

export const convertRecapDetail = (
  item: DetailItem,
  ...pages: DataDetail[]
): RecapDetail => {
  return {
    name: item.name,
    templateEndpoint: item.templateEndpoint,
    status: item.status as RecapStatus,
    service: SERVICE,
    identifier: item.identifier,
    organization: item.organization,
    organizationName: item.organizationName,
    orderNumber: item.orderNumber,
    language: item.language,
    userMail: item.userMail,
    operatorMail: item.operatorMail,
    pages: pages.map(data => {
      return {
        data,
      }
    }),
  }
}

export const convertReceiverInfo = (
  organizationName: string,
  receiver?: RecapReceiver,
): string[] => {
  const result = [organizationName]
  if (!receiver) {
    return result
  }
  if (receiver.name) {
    result.push(receiver.name)
  }
  if (receiver.phoneNumber) {
    result.push(receiver.phoneNumber)
  }
  if (receiver.emailAddress) {
    result.push(receiver.emailAddress)
  }
  return result
}

// 現物取引の確認書メールを受信する
export const isReceiveSpotTradingRecapEmail = (
  member: MemberProfile,
): boolean => {
  return (
    member.services?.esquare.appSetting.receiveSpotTradingRecapEmail || false
  )
}

// デリバティブ取引の確認書メールを受信する
export const isReceiveDerivativeTradingRecapEmail = (
  member: MemberProfile,
): boolean => {
  return (
    member.services?.esquare.appSetting.receiveDerivativeTradingRecapEmail ||
    false
  )
}

export const filterReceiveMemberEmails = (
  productType: ProductType,
  members: MemberProfile[],
): string[] => {
  const result: string[] = []
  for (const member of members) {
    if (
      (productType.isBg() || productType.isCfd()) &&
      isReceiveSpotTradingRecapEmail(member) &&
      !result.includes(member.mail)
    ) {
      result.push(member.mail)
    }
    if (
      (productType.isSwapEex() ||
        productType.isSwapTocom() ||
        productType.isSwapBilateral()) &&
      isReceiveDerivativeTradingRecapEmail(member) &&
      !result.includes(member.mail)
    ) {
      result.push(member.mail)
    }
  }
  return result
}

export const filterReceiveOrganizationMemberEmails = (
  productType: ProductType,
  members: OrganizationEmailAddress[],
): string[] => {
  const result: string[] = []
  for (const member of members) {
    if (
      (productType.isBg() || productType.isCfd()) &&
      member.receiveSpotTradingRecapEmail &&
      !result.includes(member.emailAddress)
    ) {
      result.push(member.emailAddress)
    }
    if (
      (productType.isSwapEex() ||
        productType.isSwapTocom() ||
        productType.isSwapBilateral()) &&
      member.receiveDerivativeTradingRecapEmail &&
      !result.includes(member.emailAddress)
    ) {
      result.push(member.emailAddress)
    }
  }

  return result
}

export const convertDisplayFee = (fee: number, locale?: string): string => {
  if (fee === 0) {
    if (locale === 'en') {
      return 'None'
    }
    return i18n.t('common.label.none')
  }
  return `${fee.toLocaleString(undefined, {
    maximumFractionDigits: MAX_DIGIT,
  })} ${locale === 'en' ? 'JPY/kWh' : i18n.t('trading.label.unitPriceUnit')}`
}

export const convertWithNone = (note?: string, locale?: string): string => {
  if (!note) {
    if (locale === 'en') {
      return 'None'
    }
    return i18n.t('common.label.none')
  }
  return note
}

const hasDiffDeliveryPeriod = (deliveryPeriods: DeliveryPeriod[]): boolean => {
  if (!deliveryPeriods.length) {
    return false
  }
  const uniqStateDates = uniqBy(deliveryPeriods, period => {
    return period.displayDeliveryStartDate()
  })
  const uniqEndDates = uniqBy(deliveryPeriods, period => {
    return period.displayDeliveryStartDate()
  })

  return uniqStateDates.length > 1 || uniqEndDates.length > 1
}

export const convertDisplayDeliveryPeriod = (
  product: Product,
  terms: DeliveryTerms,
): string => {
  const result = ''
  if (!terms.deliveryPeriods.length) {
    return result
  }
  if (hasDiffDeliveryPeriod(terms.deliveryPeriods)) {
    return i18n.t('trading.label.recapSeeAppendix')
  }

  const start = moment(terms.displayStartByDate('en'))
  const end = moment(terms.displayEndByDate('en'))
  const hourFormat =
    product.deliveryUnit === DeliveryUnit.Month ? 'YYYY/MM' : 'YYYY/MM/DD'
  const startDate = start.format(hourFormat)
  const endDate = end.format(hourFormat)
  if (startDate === endDate) {
    return startDate
  }
  return `${startDate} 〜 ${endDate}`
}

export const convertDeliveryTermsAnnex = (
  deal: Deal,
  organizationRecap: OrganizationRecap,
): DeliveryTermsAnnex => {
  return {
    transactionId: {
      text: deal.publicNegotiationId,
    },
    deliveryPatternList: {
      tableItems: convertDeliveryPatternList(
        organizationRecap.product.deliveryTerms.deliveryPeriods,
        organizationRecap,
        deal,
      ),
    },
  }
}

export const isNonStandard = (product: Product): boolean => {
  return product.deliveryUnit === DeliveryUnit.NonStandard
}

const convertDeliveryPatternList = (
  periods: DeliveryPeriod[],
  organizationRecap: OrganizationRecap,
  deal: Deal,
): DeliveryTermsAnnexTableItem[] => {
  const result: DeliveryTermsAnnexTableItem[] = []

  const getPricePrefix = (): string => {
    if (!deal.fuelSurchargeType) {
      return ''
    }

    return ` + ${deal.fuelSurchargeType.translation()}燃調`
  }

  periods.forEach((period, index) => {
    if (period.deliveryPattern.deliveryPatternComponents.length > 0) {
      for (const component of period.deliveryPattern
        .deliveryPatternComponents) {
        result.push({
          items: {
            deliveryPeriod: {
              text: `${period.displayDeliveryStartDate()} 〜 ${period.displayDeliveryEndDate()}`,
            },
            hourTypeName: {
              text: `${component.displayDayPatternName()} ${component.displayTimeRange()}`,
            },
            unitPrice: {
              text: `${String(
                organizationRecap.unitPrices[index]?.toLocaleString(undefined, {
                  maximumFractionDigits: MAX_DIGIT,
                }),
              )}${getPricePrefix()}`,
            },
            quantity: {
              text: String(organizationRecap.volumes[index]),
            },
          },
        })
      }
    } else {
      result.push({
        items: {
          deliveryPeriod: {
            text: `${period.displayDeliveryStartDate()} 〜 ${period.displayDeliveryEndDate()}`,
          },
          hourTypeName: {
            text: period.deliveryPattern.displayHourTypeNamesWithTimeRanges(),
          },
          unitPrice: {
            text: `${String(
              organizationRecap.unitPrices[index]?.toLocaleString(undefined, {
                maximumFractionDigits: MAX_DIGIT,
              }),
            )}${getPricePrefix()}`,
          },
          quantity: {
            text: String(organizationRecap.volumes[index]),
          },
        },
      })
    }
  })

  return result
}

export const displayRecapStatusName = (status?: RecapStatus): string => {
  switch (status) {
    case RecapStatus.REJECT:
      return i18n.t('trading.label.recapReject')
    case RecapStatus.FIXED:
      return i18n.t('trading.label.recapFixed')
    case RecapStatus.NONE:
      return i18n.t('trading.label.recapNone')
    case RecapStatus.IN_REVIEW:
      return i18n.t('trading.label.recapInReview')
    case RecapStatus.REMAND:
      return i18n.t('trading.label.recapRemand')
    default:
      return ''
  }
}

export const convertRecapStateSummary = (
  res: RecapBulkResponse,
): RecapState => {
  const result: RecapState = {}
  if (!res.items) {
    return result
  }
  for (const [key, value] of Object.entries(res.items)) {
    const detailMap: RecapStateDetail = {}
    for (const detail of value.items) {
      if (!detailMap[detail.organization]) {
        detailMap[detail.organization] = detail.status
      }
    }
    result[key] = detailMap
  }

  return result
}

export const forceShowNote = (
  force: boolean,
  defaultValue: string,
  language?: string,
): string => {
  if (!force) {
    return defaultValue
  }
  if (language === 'en') {
    return 'Please refer to the notes below'
  }
  return i18n.t('trading.label.recapSeeNote')
}
