import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ee340c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broker-indication-new" }
const _hoisted_2 = { class: "indication-new__form" }
const _hoisted_3 = { class: "indication-new__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_broker_indication_form = _resolveComponent("broker-indication-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_indication_confirm_modal_content = _resolveComponent("indication-confirm-modal-content")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failInitializeIndicationNew')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createVNode(_component_validation_form, null, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_broker_indication_form, {
                mode: _ctx.mode,
                locale: _ctx.userProfile.locale,
                "position-reversed": 
                _ctx.userProfile.services?.esquare?.userSetting?.positionReversed
              ,
                "product-types": _ctx.productTypes,
                "day-pattern-options": _ctx.dayPatternOptions,
                traders: _ctx.traders,
                brokers: _ctx.brokers,
                "form-value": _ctx.formValue,
                "opened-spread-form-types": _ctx.openedSpreadFormTypes,
                "selectable-inputs": _ctx.selectableInputs,
                "date-names": _ctx.dateNames,
                "spell-error-messages": _ctx.spellErrorMessages,
                "memo-input-enabled": false,
                onFormInput: _ctx.onFormInput,
                onTextInput: _ctx.onTextInput,
                onSpellInput: _ctx.onSpellInput,
                onApplyClick: _ctx.onApplyClick,
                onStandardDeliveryTermsFormOpen: 
                _ctx.onStandardDeliveryTermsFormOpen
              ,
                onStandardDeliveryTermsFormClose: 
                _ctx.onStandardDeliveryTermsFormClose
              
              }, null, 8, ["mode", "locale", "position-reversed", "product-types", "day-pattern-options", "traders", "brokers", "form-value", "opened-spread-form-types", "selectable-inputs", "date-names", "spell-error-messages", "onFormInput", "onTextInput", "onSpellInput", "onApplyClick", "onStandardDeliveryTermsFormOpen", "onStandardDeliveryTermsFormClose"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                disabled: _ctx.isInvalid(Object.keys(errors).length > 0),
                color: "primary",
                onClick: _ctx.onConfirmClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["ui-stack"]),
    _createVNode(_component_confirmation_dialog, {
      name: _ctx.modalName,
      title: _ctx.$t('trading.message.brokerIndicationConfirmModalTitle'),
      "confirm-button-label": _ctx.$t('trading.label.indicationShort'),
      loading: _ctx.submitting,
      width: 650,
      onConfirm: _ctx.onCreateIndicationConfirm,
      onCancel: _ctx.onCreateIndicationCancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_indication_confirm_modal_content, {
          "form-value": _ctx.formValue,
          "selectable-inputs": _ctx.selectableInputs,
          locale: _ctx.userProfile.locale,
          message: _ctx.$t('trading.message.brokerIndicationConfirmModalMessage'),
          "is-broker": true
        }, null, 8, ["form-value", "selectable-inputs", "locale", "message"])
      ]),
      _: 1
    }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
  ]))
}