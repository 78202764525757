import { Module } from 'vuex-smart-module'

import { EEXPostingActions } from '@/store/modules/trading/eexPosting/EEXPostingActions'
import { EEXPostingGetters } from '@/store/modules/trading/eexPosting/EEXPostingGetters'
import { EEXPostingMutations } from '@/store/modules/trading/eexPosting/EEXPostingMutations'
import { EEXPostingState } from '@/store/modules/trading/eexPosting/EEXPostingState'

export const eexPosting = new Module({
  state: EEXPostingState,
  getters: EEXPostingGetters,
  mutations: EEXPostingMutations,
  actions: EEXPostingActions,
})

export type EEXPostingModule = typeof eexPosting
