import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-627de82f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negotiation-list-item__inner" }
const _hoisted_2 = { class: "negotiation-list-item__delivery-unit-icon" }
const _hoisted_3 = { class: "negotiation-list-item__column" }
const _hoisted_4 = { class: "negotiation-list-item__column" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "negotiation-list-item__credit-sleever" }
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "negotiation-list-item__column" }
const _hoisted_9 = { class: "negotiation-list-item__column" }
const _hoisted_10 = { class: "negotiation-list-item__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delivery_unit_icon = _resolveComponent("delivery-unit-icon")!
  const _component_spread_badge_list = _resolveComponent("spread-badge-list")!
  const _component_delivery_terms = _resolveComponent("delivery-terms")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_credit_sleeve_icon = _resolveComponent("credit-sleeve-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_link = _resolveComponent("base-link")!

  return (_openBlock(), _createBlock(_component_base_link, {
    class: "negotiation-list-item",
    "reset-font-style": "",
    path: _ctx.itemPath
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_box, { spacing: "0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_delivery_unit_icon, {
                  "delivery-unit": _ctx.product.deliveryUnit
                }, null, 8, ["delivery-unit"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_spread_badge_list, {
                "spread-types": _ctx.negotiation.spreadTypes
              }, null, 8, ["spread-types"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_delivery_terms, {
                "area-name": _ctx.deliveryTerms.base.areaName,
                "delivery-start": _ctx.deliveryTerms.base.deliveryStart,
                "delivery-end": _ctx.deliveryTerms.base.deliveryEnd,
                "hour-type-name": _ctx.deliveryTerms.base.hourTypeName
              }, null, 8, ["area-name", "delivery-start", "delivery-end", "hour-type-name"]),
              (_ctx.negotiation.isSpread)
                ? (_openBlock(), _createBlock(_component_delivery_terms, {
                    key: 0,
                    "area-name": _ctx.deliveryTerms.exchange.areaName,
                    "delivery-start": _ctx.deliveryTerms.exchange.deliveryStart,
                    "delivery-end": _ctx.deliveryTerms.exchange.deliveryEnd,
                    "hour-type-name": _ctx.deliveryTerms.exchange.hourTypeName
                  }, null, 8, ["area-name", "delivery-start", "delivery-end", "hour-type-name"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_product_type, {
                name: _ctx.negotiation.displayProductTypes()
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", null, _toDisplayString(_ctx.fuelSurchargeTypeName), 1),
            _createElementVNode("div", {
              class: "negotiation-list-item__column",
              title: _ctx.negotiation.displayAskOrganizationName()
            }, _toDisplayString(_ctx.negotiation.displayAskOrganizationAbbreviation()), 9, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.negotiation.creditSleever)
                ? (_openBlock(), _createBlock(_component_base_tooltip, {
                    key: 0,
                    label: _ctx.negotiation.displaySleeverName()
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_credit_sleeve_icon)
                    ]),
                    _: 1
                  }, 8, ["label"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.label.blank')), 1)
                  ], 64))
            ]),
            _createElementVNode("div", {
              class: "negotiation-list-item__column",
              title: _ctx.negotiation.displayBidOrganizationName()
            }, _toDisplayString(_ctx.negotiation.displayBidOrganizationAbbreviation()), 9, _hoisted_7),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.negotiation.publicNegotiationId), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.negotiation.displayStatus()), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.negotiation.displayUpdatedAt()), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["path"]))
}