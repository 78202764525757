import { Type } from 'class-transformer'

import { BrokingDeal as IBrokingDeal } from '@/api/generated'
import { i18n } from '@/i18n'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'
import { moment } from '@/utils/date'

export class BrokingDeal implements IBrokingDeal {
  dealId!: string

  @Type(() => Product)
  product!: Product

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  price!: Price

  unitPrice!: number

  volume!: number

  privateConfidential!: boolean

  sellerOrganizationId?: string

  @Type(() => MultiLocaleName)
  sellerAccountName!: MultiLocaleName

  buyerOrganizationId?: string

  @Type(() => MultiLocaleName)
  buyerAccountName!: MultiLocaleName

  externalDealId?: string

  createdAt!: string

  maskVolume!: boolean

  displayCreatedAt(): string {
    return moment(this.createdAt).format(i18n.t('common.format.formatDate'))
  }
}
