
import debounce from 'lodash/debounce'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import ChannelSelect from '@/components/chat/ChannelSelect.vue'
import ChatAssembly from '@/components/chat/ChatAssembly.vue'
import NewMessage from '@/components/chat/NewMessage.vue'
import { ChannelListProps } from '@/components/chat/interface/ChannelListProps'
import { ChannelListWindowProps } from '@/components/chat/interface/ChannelListWindowProps'
import { ChannelMessageWindowCloseEvent } from '@/components/chat/interface/event/ChannelMessageWindowCloseEvent'
import { ChannelMessageWindowInputEvent } from '@/components/chat/interface/event/ChannelMessageWindowInputEvent'
import { ChannelMessageWindowSendEvent } from '@/components/chat/interface/event/ChannelMessageWindowSendEvent'
import { ChannelMessageWindowTitleBarClickEvent } from '@/components/chat/interface/event/ChannelMessageWindowTitleBarClickEvent'
import { ChannelMessageWindowTouchStartEvent } from '@/components/chat/interface/event/ChannelMessageWindowTouchStartEvent'
import { ChannelMessageWindowTypingEvent } from '@/components/chat/interface/event/ChannelMessageWindowTypingEvent'
import { ChannelSelectEvent } from '@/components/chat/interface/event/ChannelSelectEvent'
import BaseModal from '@/components/common/BaseModal.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { Member } from '@/models/chat/Member'
import { MultiLocaleMessageBody } from '@/models/chat/MultiLocaleMessageBody'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { ChatBroadcastModule } from '@/store/modules/chat/broadcast'
import {
  SET_LOCKED,
  SET_MESSAGE,
  SET_RECIPIENT_SEARCH_INPUT,
  SET_RECIPIENT_SELECTED_LANGUAGE,
  SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
} from '@/store/modules/chat/broadcast/ChatBroadcastMutations'
import { ChatModule } from '@/store/modules/chat/chat'
import {
  SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE,
  INCREMENT_CHANNEL_COUNTER,
} from '@/store/modules/chat/chat/ChatMutations'
import { PublicOrganizationProfileModule } from '@/store/modules/iam/publicOrganizationProfile'
import { PublicUserProfileModule } from '@/store/modules/iam/publicUserProfile'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'

const {
  mapState: broadcastMapState,
  mapActions: broadcastMapActions,
  mapMutations: broadcastMapMutations,
  mapGetters: broadcastMapGetters,
} = createNamespacedHelpers('chatBroadcast') as ChatBroadcastModule
const { mapState: publicOrganizationProfileState } = createNamespacedHelpers(
  'publicOrganizationProfile',
) as PublicOrganizationProfileModule
const {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} = createNamespacedHelpers('chat') as ChatModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule
const { mapState: chatBroadcastState } = createNamespacedHelpers(
  'chatBroadcast',
) as ChatBroadcastModule

const { mapActions: publicUserProfileMapActions } = createNamespacedHelpers(
  'publicUserProfile',
) as PublicUserProfileModule

export default defineComponent({
  name: 'ChatAssemblyContainer',
  components: {
    BaseModal,
    ChannelSelect,
    ChatAssembly,
    NewMessage,
  },
  data(): {
    cachedChannelListWindow: ChannelListWindowProps
    cachedChannelList: ChannelListProps
    isSending: boolean
  } {
    return {
      cachedChannelListWindow: this.$store.getters['chat/channelListWindow'],
      cachedChannelList: this.$store.getters['chat/channelList'],
      isSending: false,
    }
  },
  computed: {
    modalName() {
      return 'chat-assembly-modal'
    },
    displayCreateMessageButton() {
      return isBrokerApp
    },
    ...broadcastMapState([
      'recipientSearchInput',
      'message',
      'recipientSelectedLanguage',
      'locked',
    ]),
    ...broadcastMapGetters(['recipientList']),
    ...userProfileMapGetters(['userProfile']),
    ...publicOrganizationProfileState(['publicOrganizationProfiles']),
    ...mapState(['activeChannelWindowList', 'isFetchingChannelList']),
    ...mapGetters([
      'channelList',
      'channelListWindow',
      'channelMessageWindowList',
      'isOpenDMListButtonDisplayed',
      'channelListRawState',
    ]),
    ...userProfileMapGetters(['userProfile']),
    ...chatBroadcastState(['message']),
  },
  watch: {
    channelListRawState() {
      this.debouncedGetChannelListCaches()
    },
  },
  methods: {
    ...broadcastMapActions([
      'loadOrganizationChannels',
      'sendNewMessage',
      'sendMultiLocaleNewMessage',
      'addSelectedOrganizationChannelIds',
    ]),
    ...mapActions([
      'toggleChannelListWindow',
      'addChannelWindow',
      'loadChannelWindowMessages',
      'removeChannelWindow',
      'sendChannelWindowMessage',
      'toggleChannelWindow',
      'startChannelWindowMessageLoading',
      'endChannelWindowMessageLoading',
    ]),
    ...publicUserProfileMapActions(['fetchPublicUserProfilesByIds']),
    ...broadcastMapMutations([
      SET_RECIPIENT_SEARCH_INPUT,
      SET_MESSAGE,
      SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
      SET_RECIPIENT_SELECTED_LANGUAGE,
      SET_LOCKED,
    ]),
    ...mapMutations([
      SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE,
      INCREMENT_CHANNEL_COUNTER,
    ]),
    async initNewMessageModal() {
      this.$vfm.open(this.modalName)
      this.loadOrganizationChannels()
      this[SET_RECIPIENT_SELECTED_LANGUAGE]({
        recipientSelectedLanguage: undefined,
      })
    },
    onCancelButtonClick() {
      this.$vfm.close(this.modalName)
    },
    onSelectedListChange(event: string[]) {
      this[SET_SELECTED_ORGANIZATION_CHANNEL_IDS]({
        selectedOrganizationChannelIds: event,
      })
    },
    onRecipientSearchInput(event: string) {
      this[SET_RECIPIENT_SEARCH_INPUT]({
        recipientSearchInput: event,
      })
    },
    onRecipientSelectedLanguage(event: undefined | InputOption) {
      this[SET_RECIPIENT_SELECTED_LANGUAGE]({
        recipientSelectedLanguage: event,
      })
    },
    onLockClick() {
      if (this.message instanceof MultiLocaleMessageBody) {
        this[SET_LOCKED]({
          locked: !this.locked,
        })
      }
    },
    onNewChatInput(event: string | MultiLocaleMessageBody) {
      this[SET_MESSAGE]({
        message: event,
      })
    },
    onSendNewMessage() {
      this.sendNewMessage()
      this.$vfm.close(this.modalName)
    },
    onSendMultiLocaleNewMessage() {
      this.sendMultiLocaleNewMessage(this.publicOrganizationProfiles)
      this.$vfm.close(this.modalName)
    },
    resetChatInput() {
      this[SET_MESSAGE]({
        message: '',
      })
    },
    debouncedGetChannelListCaches: debounce(function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      this.cachedChannelListWindow = this.channelListWindow
      this.cachedChannelList = this.channelList
    }, 500),
    onChannelSelect: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
      event: ChannelSelectEvent,
    ) {
      if (
        this.activeChannelWindowList.indexOfChannelId(event.channelId) === -1
      ) {
        await this.addChannelWindow({
          client: this.$chat.client,
          channelId: event.channelId,
          user: this.userProfile,
          vm: this.$chat,
        })
        await this.fetchPublicUserProfilesByIds({
          userIds: this.activeChannelWindowList
            .activeChannelOfChannelId(event.channelId)
            .members.map((member: Member) => member.userId),
        })
        await this.loadChannelWindowMessages({
          client: this.$chat.client,
          channelId: event.channelId,
          vm: this.$chat,
        })
      }
    }, 200),
    onClose(event: ChannelMessageWindowCloseEvent) {
      this.removeChannelWindow({ channelId: event.channelId })
    },
    async onTyping(event: ChannelMessageWindowTypingEvent) {
      const activeChannel = this.activeChannelWindowList.activeChannelOfChannelId(
        event.channelId,
      )
      if (activeChannel) {
        await activeChannel.typing()
      }
    },
    onInput(event: ChannelMessageWindowInputEvent) {
      this[SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE]({
        channelId: event.channelId,
        chatInputValue: event.value,
      })
    },
    async onSend(event: ChannelMessageWindowSendEvent) {
      this.isSending = true
      await this.sendChannelWindowMessage({
        channelId: event.channelId,
        vm: this.$chat,
      }).finally(() => {
        this.isSending = false
      })
    },
    onChannelMessageWindowTitleBarClick(
      event: ChannelMessageWindowTitleBarClickEvent,
    ) {
      this.toggleChannelWindow({ channelId: event.channelId })
    },
    async onTouchStart(event: ChannelMessageWindowTouchStartEvent) {
      const activeChannel = this.activeChannelWindowList.activeChannelOfChannelId(
        event.channelId,
      )
      if (activeChannel) {
        // TODO: 初期読み込みが終わっているかフラグを付ける？
        if (!activeChannel.messageLoading) {
          this.startChannelWindowMessageLoading({ channelId: event.channelId })
          const baseIndex = activeChannel.getMessageCountsToLoad()
          await this.loadChannelWindowMessages({
            channelId: event.channelId,
            fromAnchor: true,
            vm: this.$chat,
          })
          const chatAssembly = this.$refs.chatAssembly as InstanceType<
            typeof ChatAssembly
          >
          chatAssembly.scrollToItem(
            this.activeChannelWindowList.indexOfChannelId(event.channelId),
            baseIndex,
          )
          this.endChannelWindowMessageLoading({ channelId: event.channelId })
        }
      }
    },
    async onCreateMessageButtonClick() {
      await this.initNewMessageModal()
      if (this.message instanceof MultiLocaleName) {
        this.resetChatInput()
      }
    },
    async onReadMessage(event: { index: number; channelId: string }) {
      const activeChannel = this.activeChannelWindowList.activeChannelOfChannelId(
        event.channelId,
      )
      if (activeChannel) {
        await activeChannel.updateLastConsumedMessageIndex(event.index)
        this[INCREMENT_CHANNEL_COUNTER]({
          channelId: activeChannel.channelId,
        })
      }
    },
    onOpenDMChannel() {
      this.$vfm.open('newDMChannelModalInWindow')
    },
    async onClickChannel(event: ChannelSelectEvent) {
      await this.onChannelSelect(event)
      this.$vfm.close('newDMChannelModalInWindow')
    },
    onCancel() {
      this.$vfm.close('newDMChannelModalInWindow')
    },
  },
})
