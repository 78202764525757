
import { defineComponent, PropType } from 'vue'

import MyIndicationComponent from '@/components/trading/MyIndication.vue'
import MyIndicationListHeader from '@/components/trading/MyIndicationListHeader.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MyIndication } from '@/models/trading/MyIndication'

export default defineComponent({
  name: 'MyIndicationList',
  components: {
    MyIndicationComponent,
    MyIndicationListHeader,
  },
  props: {
    myIndications: {
      type: Array as PropType<MyIndication[]>,
      default: () => [],
    },
    locale: {
      type: String,
      required: true,
    },
    memberProfiles: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
    isEclearEnabled: {
      type: Boolean,
      default: false,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'activate', 'deactivate', 'delete'],
  methods: {
    onSelect(event: string) {
      this.$emit('select', { indicationId: event })
    },
    onActivate(event: string) {
      this.$emit('activate', { indicationId: event })
    },
    onDeactivate(event: string) {
      this.$emit('deactivate', { indicationId: event })
    },
    onDelete(event: string) {
      this.$emit('delete', { indicationId: event })
    },
  },
})
