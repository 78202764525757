import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

const PRIVATE = 'private'
const PUBLIC = 'public'

export class PrivateConfidentialOptions {
  get options(): InputOption[] {
    return [
      {
        value: PRIVATE,
        label: i18n.t(`trading.label.${PRIVATE}`).toString(),
      },
      {
        value: PUBLIC,
        label: i18n.t(`trading.label.${PUBLIC}`).toString(),
      },
    ]
  }

  static valueOf(value?: string): boolean | undefined {
    return value ? value === PRIVATE : undefined
  }
}
