import { Module } from 'vuex-smart-module'

import { OrganizationProfileActions } from '@/store/modules/iam/organizationProfile/OrganizationProfileActions'
import { OrganizationProfileGetters } from '@/store/modules/iam/organizationProfile/OrganizationProfileGetters'
import { OrganizationProfileMutations } from '@/store/modules/iam/organizationProfile/OrganizationProfileMutations'
import { OrganizationProfileState } from '@/store/modules/iam/organizationProfile/OrganizationProfileState'

export const organizationProfile = new Module({
  state: OrganizationProfileState,
  getters: OrganizationProfileGetters,
  mutations: OrganizationProfileMutations,
  actions: OrganizationProfileActions,
})

export type OrganizationProfileModule = typeof organizationProfile
