import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "channel-member-list" }
const _hoisted_2 = { class: "channel-member-list__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channel_member = _resolveComponent("channel-member")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, index) => {
        return (_openBlock(), _createBlock(_component_channel_member, _mergeProps({ key: index }, member), null, 16))
      }), 128))
    ])
  ]))
}