import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a276f29a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reports__header" }
const _hoisted_2 = { class: "reports" }
const _hoisted_3 = { class: "reports__list" }
const _hoisted_4 = {
  key: 0,
  class: "reports__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_report_list = _resolveComponent("report-list")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('common.label.reports'),
    "active-menu-item": "reports"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_button, {
          color: "primary",
          onClick: _ctx.onNewButtonClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('reports.label.newButton')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_report_list, {
            reports: _ctx.reports,
            onLastItemVisible: _ctx.onLastItemVisible,
            onDownloadClick: _ctx.onDownloadClick
          }, null, 8, ["reports", "onLastItemVisible", "onDownloadClick"]),
          (!_ctx.isLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_centered_loading_content, { type: "grayscale" })
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}