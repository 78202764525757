
import { defineComponent, PropType } from 'vue'

import { Locale, OrganizationType, SpreadTypeTypeEnum } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputSingleCheckbox from '@/components/common/BaseInputSingleCheckbox.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { HeaderLevel } from '@/components/common/constants/TypographyLevel'
import { InputOption } from '@/components/common/interface/InputOption'
import OrganizationSelector from '@/components/iam/OrganizationSelector.vue'
import { OrganizationSelectorModalProps } from '@/components/iam/interface/OrganizationSelectorModalProps'
import ProductForm from '@/components/trading/ProductForm.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { BrokerIndicationFormProps } from '@/components/trading/interface/BrokerIndicationFormProps'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { DeliveryUnitOptions } from '@/models/trading/DeliveryUnitOptions'
import { ProductType } from '@/models/trading/ProductType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'

export default defineComponent({
  name: 'BrokerIndicationForm',
  components: {
    BaseBox,
    BaseButton,
    BaseInputSingleCheckbox,
    BaseInputText,
    BaseLabel,
    BaseSingleSelect,
    Heading,
    OrganizationSelector,
    ProductForm,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: IndicationFormInputMode) => {
        return [
          IndicationFormInputMode.NEW,
          IndicationFormInputMode.EDIT,
          IndicationFormInputMode.NEGOTIATION,
          IndicationFormInputMode.EXPIRED,
          IndicationFormInputMode.DELETED,
        ].includes(value)
      },
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    productTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
    dayPatternOptions: {
      type: Array as PropType<InputOption[]>,
      default: () => [],
    },
    traders: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
    brokers: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
    statusOptions: {
      type: Array as PropType<InputOption[]>,
      default: () => [],
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    formValue: {
      type: Object as PropType<BrokerIndicationFormProps>,
      required: true,
    },
    selectableInputs: {
      type: Object as PropType<BaseStandardIndicationSelectableInputs>,
      required: true,
    },
    openedSpreadFormTypes: {
      type: Array as PropType<SpreadTypeTypeEnum[]>,
      default: () => [],
    },
    dateNames: {
      type: Array as PropType<ProductTypeDateName[]>,
      required: true,
    },
    spellErrorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    memoInputEnabled: {
      type: Boolean,
      default: true,
    },
    isRequiredMemo: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'form-input',
    'text-input',
    'standard-delivery-terms-form-open',
    'standard-delivery-terms-form-close',
    'spell-input',
    'memo-input',
    'apply-click',
  ],
  data(): {
    formInputEventName: 'form-input'
    textInputEventName: 'text-input'
    spellInputEventName: 'spell-input'
    memoInputEventName: 'memo-input'
    headerLevel: HeaderLevel
  } {
    return {
      formInputEventName: 'form-input',
      textInputEventName: 'text-input',
      spellInputEventName: 'spell-input',
      memoInputEventName: 'memo-input',
      headerLevel: HeaderLevel.Lv3,
    }
  },
  computed: {
    traderOptions(): InputOption[] {
      return this.traders.map((trader: MemberProfile) =>
        trader.toInputOption({ isEnableEclearPrefix: true }),
      )
    },
    brokerOptions(): InputOption[] {
      return this.brokers.map((broker: MemberProfile) => {
        return { value: broker.userId, label: broker.name }
      })
    },
    isOrganizationSelectable(): boolean {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
    },
    organizationSelectorModalQueryParams(): OrganizationSelectorModalProps['queryParams'] {
      return { isEclearCreditSleever: false, isEsquareEnabled: true }
    },
    isReadonly(): boolean {
      return (
        this.mode === IndicationFormInputMode.EXPIRED ||
        this.mode === IndicationFormInputMode.DELETED
      )
    },
    traderNameInputMode(): InputMode {
      return !this.isReadonly ? InputMode.INPUT : InputMode.READONLY
    },
    isShownStatusInput(): boolean {
      return this.mode !== IndicationFormInputMode.NEW
    },
    statusInputMode(): InputMode {
      return this.mode === IndicationFormInputMode.EDIT
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    openToMarketInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    isVisibleToTradersInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    brokerNameInputMode(): InputMode {
      return !this.isReadonly ? InputMode.INPUT : InputMode.READONLY
    },
    excludeEnecCurveInputMode(): InputMode {
      return !this.isReadonly ? InputMode.INPUT : InputMode.READONLY
    },
    memoInputMode(): InputMode {
      return this.mode !== IndicationFormInputMode.DELETED
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    productFormValue(): ProductFormProps {
      return {
        position: this.formValue.position,
        deliveryUnit: this.formValue.deliveryUnit,
        productTypeIds: this.formValue.productTypeIds,
        fuelSurchargeTypeId: this.formValue.fuelSurchargeTypeId,
        base: this.formValue.base,
        exchange: this.formValue.exchange,
        deliveryPeriods: this.formValue.deliveryPeriods,
        price: this.formValue.price,
        unitPrices: this.formValue.unitPrices,
        request: this.formValue.request,
        publicInformation: this.formValue.publicInformation,
        maskVolume: this.formValue.maskVolume,
      }
    },
    deliveryUnitOptions(): InputOption[] {
      return new DeliveryUnitOptions().getOptions(OrganizationType.Broker)
    },
    memoRules(): string {
      return this.isRequiredMemo ? 'required' : ''
    },
  },
  methods: {
    onTraderInput(trader?: InputOption) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        trader,
      })
    },
    onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      this.$emit('standard-delivery-terms-form-open', type)
    },
    onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      this.$emit('standard-delivery-terms-form-close', type)
    },
  },
})
