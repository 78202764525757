
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'

export default defineComponent({
  name: 'EclearMemberList',
  components: { BaseBox, BaseButton, BaseIcon },
  props: {
    members: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
  },
  emits: ['delete'],
  data(): {
    maskedKeywordList: boolean[]
  } {
    return {
      maskedKeywordList: [],
    }
  },
  created() {
    this.maskedKeywordList = this.members.map(() => true)
  },
  methods: {
    onKeywordClick(index: number): void {
      this.maskedKeywordList[index] = !this.maskedKeywordList[index]
    },
    maskKeyword(keyword: string): string {
      return keyword.replace(/./g, '*')
    },
    onDelete(index: number) {
      this.$emit('delete', this.members[index].userId)
    },
  },
})
