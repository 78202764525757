import { HourType as IHourType } from '@/api/generated'
import { EntityAttribute } from '@/models/common/EntityAttribute'

export class HourType extends EntityAttribute implements IHourType {
  hourTypeId!: string
  deliveryPatternId!: string

  get id(): string {
    return this.hourTypeId
  }
}
