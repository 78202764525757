
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import ChannelContent from '@/components/chat/ChannelContent.vue'
import ChannelList from '@/components/chat/ChannelList.vue'
import ChannelSelect from '@/components/chat/ChannelSelect.vue'
import NewMessage from '@/components/chat/NewMessage.vue'
import { ChannelSelectEvent } from '@/components/chat/interface/event/ChannelSelectEvent'
import BaseModal from '@/components/common/BaseModal.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { MultiLocaleMessageBody } from '@/models/chat/MultiLocaleMessageBody'
import { ChatBroadcastModule } from '@/store/modules/chat/broadcast'
import {
  SET_LOCKED,
  SET_MESSAGE,
  SET_RECIPIENT_SEARCH_INPUT,
  SET_RECIPIENT_SELECTED_LANGUAGE,
  SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
} from '@/store/modules/chat/broadcast/ChatBroadcastMutations'
import { ChatModule } from '@/store/modules/chat/chat'
import { SET_CHAT_INPUT_VALUE } from '@/store/modules/chat/chat/ChatMutations'
import { PublicOrganizationProfileModule } from '@/store/modules/iam/publicOrganizationProfile'
import { PublicUserProfileModule } from '@/store/modules/iam/publicUserProfile'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'

const PageTemplate = isBrokerApp ? BrokerPage : TraderPage

const {
  mapState: broadcastMapState,
  mapActions: broadcastMapActions,
  mapMutations: broadcastMapMutations,
  mapGetters: broadcastMapGetters,
} = createNamespacedHelpers('chatBroadcast') as ChatBroadcastModule
const { mapState: publicOrganizationProfileState } = createNamespacedHelpers(
  'publicOrganizationProfile',
) as PublicOrganizationProfileModule
const {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} = createNamespacedHelpers('chat') as ChatModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const { mapActions: publicUserProfileMapActions } = createNamespacedHelpers(
  'publicUserProfile',
) as PublicUserProfileModule

export default defineComponent({
  name: 'Chat',
  components: {
    BaseModal,
    CenteredLoadingContent,
    ChannelContent,
    ChannelList,
    ChannelSelect,
    NewMessage,
    PageTemplate,
  },
  data(): {
    isSending: boolean
  } {
    return {
      isSending: false,
    }
  },
  async created() {
    const index = this.activeChannel.channel?.conversationInstance?.lastMessage
      ?.index
    if (index) {
      await this.onReadMessage(index)
    }
  },
  computed: {
    ...broadcastMapState([
      'recipientSearchInput',
      'message',
      'recipientSelectedLanguage',
      'locked',
    ]),
    ...broadcastMapGetters(['recipientList']),
    ...userProfileMapGetters(['userProfile']),
    ...publicOrganizationProfileState(['publicOrganizationProfiles']),
    modalName() {
      return 'chat-page-modal'
    },
    ...mapState(['activeChannel', 'isFetchingChannelList', 'channelList']),
    ...mapGetters([
      'activeChannelPictureData',
      'channelList',
      'sortedChannelList',
      'channelContent',
      'isChannelListEmpty',
      'isOpenDMListButtonDisplayed',
      'isActiveChannelStarred',
    ]),
    ...userProfileMapGetters(['userProfile']),
    displayCreateMessageButton() {
      return isBrokerApp
    },
  },
  watch: {
    channelList() {
      this.loadActiveChannelIfNeed()
    },
  },
  mounted() {
    this.loadActiveChannelIfNeed()
  },
  methods: {
    ...mapActions([
      'loadChannelList',
      'loadChannel',
      'loadMessages',
      'sendMessage',
      'startMessageLoading',
      'endMessageLoading',
      'addStarredChannel',
      'removeStarredChannel',
    ]),
    ...mapMutations([SET_CHAT_INPUT_VALUE]),
    ...publicUserProfileMapActions(['fetchPublicUserProfilesByIds']),
    ...broadcastMapActions([
      'loadOrganizationChannels',
      'sendNewMessage',
      'sendMultiLocaleNewMessage',
      'addSelectedOrganizationChannelIds',
    ]),
    ...broadcastMapMutations([
      SET_RECIPIENT_SEARCH_INPUT,
      SET_MESSAGE,
      SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
      SET_RECIPIENT_SELECTED_LANGUAGE,
      SET_LOCKED,
    ]),
    async initNewMessageModal() {
      this.$vfm.open(this.modalName)
      this.loadOrganizationChannels()
      this[SET_RECIPIENT_SELECTED_LANGUAGE]({
        recipientSelectedLanguage: undefined,
      })
    },
    onCancelButtonClick() {
      this.$vfm.close(this.modalName)
    },
    onSelectedListChange(event: string[]) {
      this[SET_SELECTED_ORGANIZATION_CHANNEL_IDS]({
        selectedOrganizationChannelIds: event,
      })
    },
    onRecipientSearchInput(event: string) {
      this[SET_RECIPIENT_SEARCH_INPUT]({
        recipientSearchInput: event,
      })
    },
    onRecipientSelectedLanguage(event: undefined | InputOption) {
      this[SET_RECIPIENT_SELECTED_LANGUAGE]({
        recipientSelectedLanguage: event,
      })
    },
    onLockClick() {
      if (this.message instanceof MultiLocaleMessageBody) {
        this[SET_LOCKED]({
          locked: !this.locked,
        })
      }
    },
    onNewChatInput(event: string | MultiLocaleMessageBody) {
      this[SET_MESSAGE]({
        message: event,
      })
    },
    onSendNewMessage() {
      this.sendNewMessage()
      this.$vfm.close(this.modalName)
    },
    onSendMultiLocaleNewMessage() {
      this.sendMultiLocaleNewMessage(this.publicOrganizationProfiles)
      this.$vfm.close(this.modalName)
    },
    resetChatInput() {
      this[SET_MESSAGE]({
        message: '',
      })
    },
    async onChannelSelect(event: ChannelSelectEvent) {
      await this.loadChannel({
        client: this.$chat.client,
        channelId: event.channelId,
        user: this.userProfile,
        vm: this.$chat,
      })
      await this.fetchPublicUserProfilesByIds({
        userIds: this.activeChannel.members.map(member => member.userId),
      })
      this.startMessageLoading()
      await this.loadMessages({ vm: this.$chat })
      this.endMessageLoading()
      const channelContent = this.$refs.channelContent as InstanceType<
        typeof ChannelContent
      >
      if (channelContent) {
        channelContent.scrollToBottom()
      }
    },
    onInput(event: string) {
      this[SET_CHAT_INPUT_VALUE]({ chatInputValue: event })
    },
    async onTyping() {
      if (this.activeChannel.channel) {
        await this.activeChannel.typing()
      }
    },
    async onSend() {
      this.isSending = true
      await this.sendMessage({ vm: this.$chat }).finally(() => {
        this.isSending = false
      })
    },
    async onTouchStart() {
      if (this.activeChannel.channel) {
        if (!this.activeChannel.messageLoading) {
          // TODO: 初期読み込みが終わっているかフラグを付ける？
          this.startMessageLoading()
          const baseIndex = this.activeChannel.getMessageCountsToLoad()
          await this.loadMessages({ vm: this.$chat, fromAnchor: true })
          const channelContent = this.$refs.channelContent as InstanceType<
            typeof ChannelContent
          >
          if (channelContent) {
            channelContent.scrollToItem(baseIndex)
          }
          this.endMessageLoading()
        }
      }
    },
    async onCreateMessageButtonClick() {
      await this.initNewMessageModal()
    },
    async onReadMessage(index: number) {
      await this.activeChannel.updateLastConsumedMessageIndex(index)
    },
    onOpenDMChannel() {
      this.$vfm.open('newDMChannelModal')
    },
    async onClickChannel(event: ChannelSelectEvent) {
      await this.onChannelSelect(event)
      this.$vfm.close('newDMChannelModal')
    },
    onCancel() {
      this.$vfm.close('newDMChannelModal')
    },
    async onSelectAddStar() {
      if (!this.activeChannel.channel) {
        return
      }
      await this.addStarredChannel({
        channelId: this.activeChannel.channel.channelId,
      })
    },
    async onSelectRemoveStar() {
      if (!this.activeChannel.channel) {
        return
      }
      await this.removeStarredChannel({
        channelId: this.activeChannel.channel.channelId,
      })
    },
    async loadActiveChannelIfNeed() {
      if (
        !this.activeChannel.channel &&
        !this.activeChannel.messageLoading &&
        this.channelList.organizationChannels &&
        this.channelList.organizationChannels.length > 0
      ) {
        this.startMessageLoading()
        await this.loadChannel({
          client: this.$chat.client,
          channelId: this.channelList.organizationChannels[0].channelId,
          user: this.userProfile,
          vm: this.$chat,
        })
        await this.fetchPublicUserProfilesByIds({
          userIds: this.activeChannel.members.map(member => member.userId),
        })
        await this.loadMessages({ vm: this.$chat })
        this.endMessageLoading()
      }
    },
  },
})
