import { Mutations } from 'vuex-smart-module'

import { OrderBookState } from '@/store/modules/trading/orderBook/OrderBookState'
import { OrderBookFilterAttribute } from '@/store/modules/trading/orderBook/interface'

export const SET_FILTER_CONDITION_WITH_SPREAD =
  'SET_FILTER_CONDITION_WITH_SPREAD'
export const SET_SELECTED_FILTERS_WITH_SPREAD =
  'SET_SELECTED_FILTERS_WITH_SPREAD'

export class OrderBookMutations extends Mutations<OrderBookState> {
  [SET_FILTER_CONDITION_WITH_SPREAD]({
    attribute,
    selected,
  }: {
    attribute: OrderBookFilterAttribute
    selected: string[]
  }) {
    this.state.filterConditionWithSpread[attribute] = selected
  }

  [SET_SELECTED_FILTERS_WITH_SPREAD](payload: {
    selectedFilters: OrderBookFilterAttribute[]
  }) {
    this.state.selectedFiltersWithSpread = payload.selectedFilters
  }
}
