
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import SelectedRecapReceiver from '@/components/trading/SelectedRecapReceiver.vue'
import RecapReceiverSelectorModal from '@/components/trading/container/RecapReceiverSelectorModal.vue'
import { RecapReceiver } from '@/models/trading/RecapReceiver'

export default defineComponent({
  name: 'RecapReceiverSelector',
  components: {
    BaseModal,
    RecapReceiverSelectorModal,
    SelectedRecapReceiver,
  },
  props: {
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedRecapReceiver: {
      type: Object as PropType<RecapReceiver>,
      default: undefined,
    },
    organizationId: {
      type: String,
      required: true,
    },
    modalName: {
      type: String,
      default: 'recapReceiverSelectorModal',
    },
  },
  emits: ['select'],
  methods: {
    onSelect(recapReceiver: RecapReceiver) {
      this.$emit('select', recapReceiver)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.modalName)
    },
    hideModal() {
      this.$vfm.close(this.modalName)
    },
  },
})
