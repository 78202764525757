
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'

export default defineComponent({
  name: 'ActionDropdown',
  components: {
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array as PropType<ActionDropdownItem[]>,
      default: () => [],
    },
    isParentActive: {
      type: Boolean,
      default: false,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    isButton: {
      type: Boolean,
      default: true,
    },
    buttonSize: {
      type: String,
      default: 'small',
    },
  },
  watch: {
    isActive() {
      if (this.isActive) {
        document.addEventListener('click', this.onClickOutside, false)
      } else {
        document.removeEventListener('click', this.onClickOutside, false)
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClickOutside, false)
  },
  methods: {
    onClick(eventName: string, index: number) {
      this.$emit(eventName, index)
    },
    onClickOutside() {
      this.$emit('click-outside')
    },
  },
})
