import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-631f0d2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-indication-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('trading.label.area')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.areaName), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('trading.label.period')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.deliveryPeriod), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('trading.label.hourType')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.hourTypeName), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('trading.label.productType')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.productTypeNames) + " ", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.fuelSurchargeTypeName), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('trading.label.swingOption')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.priceType === 'basic-plus-volume'
          ? _ctx.$t('trading.label.withSwingOption')
          : _ctx.$t('trading.label.withoutSwingOption')), 1)
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}