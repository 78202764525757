
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseTag',
  props: {
    type: {
      type: String,
      default: 'is-primary',
    },
  },
})
