import { Getters } from 'vuex-smart-module'

import { IndicationStatusType, PositionType } from '@/api/generated'
import { NonStandardOptions } from '@/models/trading/NonStandardOptions'
import { IndicationState } from '@/store/modules/trading/indication/IndicationState'

export class IndicationGetters extends Getters<IndicationState> {
  get filterConditionParameter(): {
    areaId?: string
    startDeliveryYearMonthId?: string
    endDeliveryYearMonthId?: string
    hourTypeId?: string
    productTypeId?: string
    organizationName?: string
    status?: IndicationStatusType
    position?: PositionType
    nonStandard?: boolean
  } {
    return {
      areaId: this.state.filterCondition.area,
      startDeliveryYearMonthId: this.state.filterCondition
        .startDeliveryYearMonth,
      endDeliveryYearMonthId: this.state.filterCondition.endDeliveryYearMonth,
      hourTypeId: this.state.filterCondition.hourType,
      productTypeId: this.state.filterCondition.productType,
      organizationName:
        this.state.filterCondition.organizationName || undefined,
      status: Object.values(IndicationStatusType).find(
        status => status === this.state.filterCondition.status,
      ),
      position: Object.values(PositionType).find(
        position => position === this.state.filterCondition.position,
      ),
      nonStandard: NonStandardOptions.valueOf(
        this.state.filterCondition.nonStandard,
      ),
    }
  }
}
