import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37b245a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ecloud-members__member-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_member_header = _resolveComponent("member-header")!
  const _component_member = _resolveComponent("member")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createBlock(_component_ui_stack_selector, {
    "ui-stack": _ctx.uiStack,
    class: "ecloud-members"
  }, {
    loading: _withCtx(() => [
      _createVNode(_component_centered_loading_content, { type: "grayscale" })
    ]),
    error: _withCtx(() => [
      _createVNode(_component_base_alert, { type: _ctx.errorType }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeMembers')), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    blank: _withCtx(() => [
      _createVNode(_component_base_alert, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.message.noOne', {
          name: _ctx.$t('iam.label.member'),
        })), 1)
        ]),
        _: 1
      })
    ]),
    ideal: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_member_header),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberProfiles, (memberProfile, index) => {
          return (_openBlock(), _createBlock(_component_member, {
            key: index,
            "member-profile": memberProfile,
            onClick: ($event: any) => (_ctx.onMemberClick(memberProfile))
          }, null, 8, ["member-profile", "onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["ui-stack"]))
}