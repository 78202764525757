import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edccc242"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "products-summary" }
const _hoisted_2 = { class: "products-summary__row" }
const _hoisted_3 = { class: "products-summary__row" }
const _hoisted_4 = { class: "products-summary__row" }
const _hoisted_5 = { class: "products-summary__row" }
const _hoisted_6 = { class: "products-summary__row" }
const _hoisted_7 = { class: "products-summary__product-type-names" }
const _hoisted_8 = { class: "products-summary__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_dataset, {
        label: _ctx.$t('trading.label.deliveryUnit'),
        data: _ctx.product.deliveryUnitName
      }, null, 8, ["label", "data"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_dataset, {
        label: _ctx.$t('trading.label.area'),
        data: _ctx.areaName
      }, null, 8, ["label", "data"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_dataset, {
        label: _ctx.$t('trading.label.deliveryPeriod'),
        data: _ctx.deliveryPeriodLabel
      }, null, 8, ["label", "data"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_base_dataset, {
        label: _ctx.$t('trading.label.hourType'),
        data: _ctx.hourTypeName
      }, null, 8, ["label", "data"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_dataset, {
        label: _ctx.$t('trading.label.productType')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_sentences, { type: "text-medium" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.productTypeNames), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_sentences, { type: "text-medium" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.fuelSurchargeTypeName), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_base_dataset, {
        label: _ctx.$t('trading.label.swingOption'),
        data: _ctx.swingOptionLabel
      }, null, 8, ["label", "data"])
    ])
  ]))
}