import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e662ffb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negotiations" }
const _hoisted_2 = { class: "negotiations__content-header-wrapper" }
const _hoisted_3 = { class: "negotiations__filter" }
const _hoisted_4 = { class: "negotiations__header" }
const _hoisted_5 = { class: "negotiations__list" }
const _hoisted_6 = {
  key: 0,
  class: "negotiations__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_filter = _resolveComponent("product-filter")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_negotiation_list_header = _resolveComponent("negotiation-list-header")!
  const _component_negotiation_list = _resolveComponent("negotiation-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.negotiations'),
    "active-menu-item": "trading"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_product_filter, {
              filters: _ctx.filters,
              actions: _ctx.filterActions,
              "is-action-dropdown-active": _ctx.isFilterActionDropdownActive,
              "selected-filters": _ctx.selectedFilters,
              onInput: _ctx.onFilterItemInput,
              onSelect: _ctx.onFilterItemSelect,
              onFilterRemove: _ctx.onFilterRemove,
              onClickButton: _ctx.onClickFilterDropdown,
              onClickOutside: _ctx.onClickFilterOutside,
              onChangeList: _ctx.onChangeFilters
            }, null, 8, ["filters", "actions", "is-action-dropdown-active", "selected-filters", "onInput", "onSelect", "onFilterRemove", "onClickButton", "onClickOutside", "onChangeList"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_button, {
              color: "primary",
              onClick: _ctx.onNewButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.label.newNegotiation')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          blank: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.alertTypeInfo }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
                name: _ctx.$t('trading.label.negotiations'),
              })), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.alertTypeError }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.failFetch', {
                name: _ctx.$t('trading.label.negotiations'),
              })), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          ideal: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_negotiation_list_header),
              _createVNode(_component_negotiation_list, {
                negotiations: _ctx.negotiations,
                onLastItemVisible: _ctx.onLastItemVisible
              }, null, 8, ["negotiations", "onLastItemVisible"]),
              (!_ctx.isLoaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_centered_loading_content, { type: "grayscale" })
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}