import { InputOption } from '@/components/common/interface/InputOption'

export class TimeRangeOptions {
  get options(): InputOption[] {
    return [
      ...new Array(24)
        .fill(0)
        .map((_, i) => {
          return [
            {
              value: i.toString(),
              label: `${i}:00`,
            },
            {
              value: `${i.toString()}.5`,
              label: `${i}:30`,
            },
          ]
        })
        .flat(),
      {
        value: '24',
        label: '24:00',
      },
    ]
  }
}
