import { Getters } from 'vuex-smart-module'

import { Locale } from '@/api/generated'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { Area } from '@/models/trading/Area'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { PublicIndicationState } from '@/store/modules/trading/publicIndication/PublicIndicationState'
import {
  publicDealFilter,
  getPublicIndicationFilter,
  publicIndicationFilter,
} from '@/store/modules/trading/publicIndication/helper'

export class PublicIndicationGetters extends Getters<PublicIndicationState> {
  private get publicIndications(): PublicIndication[] {
    return this.state.publicIndications.filter(
      indication => !indication.fuelSurchargeType,
    )
  }

  private get publicIndicationsWithFuelSurcharge(): PublicIndication[] {
    return this.state.publicIndications.filter(
      indication => indication.fuelSurchargeType,
    )
  }

  private get publicDeals(): PublicDeal[] {
    return this.state.publicDeals.filter(deal => !deal.fuelSurchargeType)
  }

  private get publicDealsWithFuelSurcharge(): PublicDeal[] {
    return this.state.publicDeals.filter(deal => deal.fuelSurchargeType)
  }

  get filteredPublicIndications(): PublicIndication[] {
    return this.getters.publicIndications.filter(
      publicIndicationFilter(this.state.filterCondition),
    )
  }

  get filteredPublicIndicationsWithFuelSurcharge(): PublicIndication[] {
    return this.getters.publicIndicationsWithFuelSurcharge.filter(
      publicIndicationFilter(this.state.filterConditionWithFuelSurcharge),
    )
  }

  get filteredPublicDeals(): PublicDeal[] {
    return this.getters.publicDeals.filter(
      publicDealFilter(this.state.filterCondition),
    )
  }

  get filteredPublicDealsWithFuelSurcharge(): PublicDeal[] {
    return this.getters.publicDealsWithFuelSurcharge.filter(
      publicDealFilter(this.state.filterConditionWithFuelSurcharge),
    )
  }

  productFilter({
    locale,
    areas,
    hourTypes,
    productTypes,
  }: {
    locale: Locale
    areas: Area[]
    hourTypes: HourType[]
    productTypes: ProductType[]
  }): AttributeFilter[] {
    return getPublicIndicationFilter({
      indications: this.getters.publicIndications,
      filterAttributes: this.state.selectedFilters,
      filterCondition: this.state.filterCondition,
      deals: this.getters.publicDeals,
      locale,
      areas,
      hourTypes,
      productTypes,
    })
  }

  productFilterWithFuelSurcharge({
    locale,
    areas,
    hourTypes,
    productTypes,
  }: {
    locale: Locale
    areas: Area[]
    hourTypes: HourType[]
    productTypes: ProductType[]
  }): AttributeFilter[] {
    return getPublicIndicationFilter({
      indications: this.getters.publicIndicationsWithFuelSurcharge,
      filterAttributes: this.state.selectedFiltersWithFuelSurcharge,
      filterCondition: this.state.filterConditionWithFuelSurcharge,
      deals: this.getters.publicDeals,
      locale,
      areas,
      hourTypes,
      productTypes,
    })
  }
}
