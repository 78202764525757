import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f9c8c78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-item-category" }
const _hoisted_2 = { class: "nav-item-category__trigger" }
const _hoisted_3 = {
  class: "nav-item-category__contents",
  "data-category": "primary"
}
const _hoisted_4 = { class: "nav-item-category__label" }
const _hoisted_5 = ["href", "target", "rel"]
const _hoisted_6 = { class: "nav-item-category__label" }
const _hoisted_7 = { class: "nav-item-category__icon" }
const _hoisted_8 = {
  key: 2,
  class: "nav-item-category__item"
}
const _hoisted_9 = { class: "nav-item-category__label" }
const _hoisted_10 = { class: "nav-item-category__arrow" }
const _hoisted_11 = {
  key: 3,
  class: "nav-item-category__contents",
  "data-category": "secondary"
}
const _hoisted_12 = { class: "nav-item-category__label" }
const _hoisted_13 = ["href", "target", "rel"]
const _hoisted_14 = { class: "nav-item-category__label" }
const _hoisted_15 = { class: "nav-item-category__icon" }
const _hoisted_16 = {
  key: 2,
  class: "nav-item-category__item"
}
const _hoisted_17 = { class: "nav-item-category__label" }
const _hoisted_18 = { class: "nav-item-category__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, idx) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `category-${idx}`,
          class: "nav-item-category"
        }, [
          (category.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: category.to,
                class: "nav-item-category__item"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(category.label), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (category.href)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: category.href,
                  target: category.isExternal ? '_blank' : '_self',
                  rel: category.isExternal ? 'noopener noreferrer' : undefined,
                  class: "nav-item-category__item"
                }, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(category.label), 1),
                  _createElementVNode("div", _hoisted_7, [
                    (category.isExternal)
                      ? (_openBlock(), _createBlock(_component_base_icon, {
                          key: 0,
                          "icon-name": "external-link-alt",
                          size: "xs"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ], 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, _toDisplayString(category.label), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_base_icon, { "icon-name": "angle-right" })
                  ])
                ])),
          (category.items)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.items, (item, key) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `category-item-${key}`
                  }, [
                    (item.to)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "nav-item-category__item",
                          to: item.to
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_12, _toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (item.href)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: item.href,
                            target: item.isExternal ? '_blank' : '_self',
                            rel: item.isExternal ? 'noopener noreferrer' : undefined,
                            class: "nav-item-category__item"
                          }, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(item.label), 1),
                            _createElementVNode("div", _hoisted_15, [
                              (item.isExternal)
                                ? (_openBlock(), _createBlock(_component_base_icon, {
                                    key: 0,
                                    "icon-name": "external-link-alt",
                                    size: "xs"
                                  }))
                                : _createCommentVNode("", true)
                            ])
                          ], 8, _hoisted_13))
                        : (!item.tooltip)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createElementVNode("p", _hoisted_17, _toDisplayString(item.label), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 3,
                              class: _normalizeClass([
                'nav-item-category__item',
                { 'nav-item-category__item--disabled': item.isDisabled },
              ])
                            }, [
                              _createVNode(_component_base_tooltip, {
                                direction: "right",
                                label: item.tooltip
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_18, _toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"])
                            ], 2))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}