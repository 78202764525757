import { Getters } from 'vuex-smart-module'

import { Locale } from '@/api/generated'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { MyIndication } from '@/models/trading/MyIndication'
import { MyIndicationState } from '@/store/modules/trading/myIndication/MyIndicationState'
import {
  myIndicationFilter,
  getMyIndicationFilter,
} from '@/store/modules/trading/myIndication/helper'

export class MyIndicationGetters extends Getters<MyIndicationState> {
  get filteredMyIndications(): MyIndication[] {
    return this.state.myIndications.filter(
      myIndicationFilter(this.state.filterCondition),
    )
  }

  productFilter(locale: Locale): AttributeFilter[] {
    return getMyIndicationFilter(
      this.state.myIndications,
      this.state.selectedFilters,
      this.state.filterCondition,
      locale,
    )
  }
}
