import { RouteRecordRaw } from 'vue-router'

import EsquareOrganization from '@/views/iam/EsquareOrganization.vue'
import EmailSetting from '@/views/iam/children/EmailSetting.vue'
import Member from '@/views/iam/children/Member.vue'
import Members from '@/views/iam/children/Members.vue'
import OrganizationEmailAddress from '@/views/iam/children/OrganizationEmailAddress.vue'
import OrganizationEmailAddressNew from '@/views/iam/children/OrganizationEmailAddressNew.vue'
import OrganizationEmailAddresses from '@/views/iam/children/OrganizationEmailAddresses.vue'

/**
 * パス: /email-setting/...
 */
export const emailSettingRoute: RouteRecordRaw = {
  path: '/email-setting',
  component: EsquareOrganization,
  children: [
    {
      path: '',
      component: EmailSetting,
      meta: {
        activeMenuItem: '',
      },
      children: [
        {
          path: '',
          redirect: 'members',
        },
        {
          path: 'members',
          name: 'MyMembers',
          component: Members,
          meta: {
            activeMenuItem: '',
            titleKey: 'iam.label.mailSetting',
          },
        },
        {
          path: 'organization-email-addresses',
          name: 'MyOrganizationEmailAddresses',
          component: OrganizationEmailAddresses,
          meta: {
            activeMenuItem: '',
            titleKey: 'iam.label.mailSetting',
          },
        },
      ],
    },
    {
      path: 'members/:userId',
      name: 'MyMember',
      component: Member,
      props: route => ({ userId: route.params.userId }),
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.detailedMember',
      },
    },
    {
      path: 'organization-email-addresses/new',
      name: 'MyOrganizationEmailAddressNew',
      component: OrganizationEmailAddressNew,
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.newOrganizationEmailAddress',
      },
    },
    {
      path: 'organization-email-addresses/:organizationEmailAddressId',
      name: 'MyOrganizationEmailAddress',
      component: OrganizationEmailAddress,
      props: route => ({
        organizationEmailAddressId: route.params.organizationEmailAddressId,
      }),
      meta: {
        activeMenuItem: '',
        titleKey: 'iam.label.detailedOrganizationEmailAddress',
      },
    },
  ],
}
