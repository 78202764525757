import { Type } from 'class-transformer'

import { PositionType } from '@/api/generated'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'

export abstract class BaseIndication {
  position!: PositionType
  price!: Price
  unitPrice!: number
  volume!: number
  publicInformation?: string

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType
}
