
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import IndicationListItem from '@/components/trading/IndicationListItem.vue'
import { Indication } from '@/models/trading/Indication'

export default defineComponent({
  name: 'IndicationList',
  components: {
    IndicationListItem,
  },
  props: {
    indications: {
      type: Array as PropType<Indication[]>,
      default: () => [],
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  emits: ['last-item-visible'],
  methods: {
    visibilityChanged(isVisible: boolean, index: number) {
      if (!isVisible || index !== this.indications.length - 1) {
        return
      }
      this.$emit('last-item-visible')
    },
  },
})
