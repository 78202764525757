import { Type } from 'class-transformer'
import { Moment } from 'moment'

import {
  DeliveryTerms as IDeliveryTerms,
  DeliveryUnit,
  Locale,
} from '@/api/generated'
import { i18n } from '@/i18n'
import { Area } from '@/models/trading/Area'
import { DeliveryPeriod } from '@/models/trading/DeliveryPeriod'
import { moment } from '@/utils/date'

function getMinTimestamp(deliveryTerms: DeliveryPeriod[]): number {
  return Math.min(
    ...deliveryTerms.map(term => new Date(term.deliveryStartDate).valueOf()),
  )
}

function getMaxTimestamp(deliveryTerms: DeliveryPeriod[]): number {
  return Math.max(
    ...deliveryTerms.map(term => new Date(term.deliveryEndDate).valueOf()),
  )
}

export class DeliveryTerms implements IDeliveryTerms {
  id!: string

  @Type(() => Area)
  area!: Area

  @Type(() => DeliveryPeriod)
  deliveryPeriods!: DeliveryPeriod[]

  private getDateName(deliveryunit: DeliveryUnit): string | undefined {
    return deliveryunit === DeliveryUnit.WeekOrDay
      ? this.deliveryPeriods[0].dateName
      : undefined
  }

  private displayStartByMonth(): string {
    const minTimestamp = getMinTimestamp(this.deliveryPeriods)
    return moment(minTimestamp).format(
      i18n.t('common.format.shortFormatYearMonth').toString(),
    )
  }

  displayStartByDate(locale = i18n.locale as Locale): string {
    const minTimestamp = getMinTimestamp(this.deliveryPeriods)
    return moment(minTimestamp).format(
      i18n.t('common.format.shortDate', locale).toString(),
    )
  }

  private displayEndByMonth(): string {
    const maxTimestamp = getMaxTimestamp(this.deliveryPeriods)
    return moment(maxTimestamp).format(
      i18n.t('common.format.shortFormatYearMonth').toString(),
    )
  }

  displayEndByDate(locale = i18n.locale as Locale): string {
    const maxTimestamp = getMaxTimestamp(this.deliveryPeriods)
    return moment(maxTimestamp).format(
      i18n.t('common.format.shortDate', locale).toString(),
    )
  }

  hourTypeName(): string | undefined {
    return this.deliveryPeriods.length === 1
      ? this.deliveryPeriods[0].deliveryPattern.hourType?.name.translation()
      : undefined
  }

  firstStartDate(): Moment {
    return moment(getMinTimestamp(this.deliveryPeriods))
  }

  lastEndDate(): Moment {
    return moment(getMaxTimestamp(this.deliveryPeriods))
  }

  areaName(): string {
    return this.area.name.translation()
  }

  deliveryStart(deliveryUnit: DeliveryUnit): string {
    const dateName = this.deliveryPeriods[0].dateName
    return deliveryUnit === DeliveryUnit.Month
      ? this.displayStartByMonth()
      : `${
          deliveryUnit === DeliveryUnit.WeekOrDay && dateName
            ? dateName + ' -\n'
            : ''
        }${this.displayStartByDate()}`
  }

  deliveryEnd(deliveryUnit: DeliveryUnit): string {
    const dateName = this.deliveryPeriods[0].dateName
    return deliveryUnit === DeliveryUnit.Month
      ? this.displayEndByMonth()
      : `${
          deliveryUnit === DeliveryUnit.WeekOrDay && dateName
            ? dateName + ' -\n'
            : ''
        }${this.displayEndByDate()}`
  }

  displayDeliveryPeriodByDateWithDateName(deliveryUnit: DeliveryUnit): string {
    const start = this.displayStartByDate()
    const end = this.displayEndByDate()
    const dateName = this.getDateName(deliveryUnit)
    return dateName ? `${start} - ${end} (${dateName})` : `${start} - ${end}`
  }

  displayDeliveryPeriodWithDateName(deliveryUnit: DeliveryUnit): string {
    if (deliveryUnit !== DeliveryUnit.Month) {
      return this.displayDeliveryPeriodByDateWithDateName(deliveryUnit)
    }
    const start = this.displayStartByMonth()
    const end = this.displayEndByMonth()
    return `${start} - ${end}`
  }

  standardHourTypeName(deliveryUnit: DeliveryUnit): string {
    if (deliveryUnit !== DeliveryUnit.NonStandard) {
      return this.hourTypeName() || ''
    }
    return i18n.t('common.label.blank').toString()
  }
}
