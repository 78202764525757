import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field-broking-fee" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_input_text, {
      label: _ctx.label,
      name: _ctx.name,
      value: _ctx.value,
      "value-type": "number",
      mode: _ctx.mode,
      required: "",
      rules: "required|numeric|scale:5|between:-999.9999,999.9999",
      onInput: _ctx.onInput
    }, null, 8, ["label", "name", "value", "mode", "onInput"])
  ]))
}