
import { defineComponent, PropType } from 'vue'

import ChannelListWindow from '@/components/chat/ChannelListWindow.vue'
import ChannelMessageWindow from '@/components/chat/ChannelMessageWindow.vue'
import { ChannelListWindowProps } from '@/components/chat/interface/ChannelListWindowProps'
import { ChannelMessageWindowProps } from '@/components/chat/interface/ChannelMessageWindowProps'
import { ChannelSelectEvent } from '@/components/chat/interface/event/ChannelSelectEvent'

export default defineComponent({
  name: 'ChatAssembly',
  components: {
    ChannelListWindow,
    ChannelMessageWindow,
  },
  props: {
    channelListWindow: {
      type: Object as PropType<ChannelListWindowProps>,
      required: true,
    },
    channelMessageWindows: {
      type: Array as PropType<ChannelMessageWindowProps[]>,
      default: () => [],
    },
    margin: {
      type: Number,
      default: 20,
    },
    channelListWindowWidth: {
      type: Number,
      default: 250,
    },
    channelMessageWindowWidth: {
      type: Number,
      default: 300,
    },
    displayCreateMessageButton: {
      type: Boolean,
      default: false,
    },
    displayOpenDMListButton: {
      type: Boolean,
      default: false,
    },
    isFetchingChannelList: {
      type: Boolean,
      default: false,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'channel-list-window-title-bar-click',
    'channel-select',
    'close',
    'input',
    'typing',
    'send',
    'channel-message-window-title-bar-click',
    'touch-start',
    'create-message-button-click',
    'read-message',
    'open-dm-channel',
  ],
  methods: {
    onChannelListWindowTitleBarClick() {
      this.$emit('channel-list-window-title-bar-click')
    },
    onChannelSelect(event: ChannelSelectEvent) {
      this.$emit('channel-select', event)
    },
    onClose(event: Event) {
      this.$emit('close', event)
    },
    onInput(event: Event) {
      this.$emit('input', event)
    },
    onTyping(event: Event) {
      this.$emit('typing', event)
    },
    onSend(event: Event) {
      this.$emit('send', event)
    },
    onChannelMessageWindowTitleBarClick(event: Event) {
      this.$emit('channel-message-window-title-bar-click', event)
    },
    onTouchStart(event: Event) {
      this.$emit('touch-start', event)
    },
    scrollToItem(windowIndex: number, itemIndex: number) {
      const channelMessageWindows = this.$refs
        .channelMessageWindows as InstanceType<typeof ChannelMessageWindow>[]
      if (channelMessageWindows) {
        channelMessageWindows[windowIndex].scrollToItem(itemIndex)
      }
    },
    onCreateMessageButtonClick() {
      this.$emit('create-message-button-click')
    },
    onReadMessage(event: Event) {
      this.$emit('read-message', event)
    },
    onOpenDMChannel() {
      this.$emit('open-dm-channel')
    },
  },
})
