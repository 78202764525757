import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "broker-collapsed-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_collapsed_navigation = _resolveComponent("base-collapsed-navigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_collapsed_navigation, {
      "is-collapsed": _ctx.isCollapsed,
      "organization-name": _ctx.organizationName,
      "organization-picture": _ctx.organizationPicture,
      toggle: _ctx.toggle,
      "nav-items": _ctx.navItems
    }, null, 8, ["is-collapsed", "organization-name", "organization-picture", "toggle", "nav-items"])
  ]))
}