
import { defineComponent } from 'vue'

import ChatInputForm from '@/components/chat/ChatInputForm.vue'
import InnerLabel from '@/components/common/Typography/InnerLabel.vue'

export default defineComponent({
  name: 'ChatInput',
  components: {
    ChatInputForm,
    InnerLabel,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input', 'typing', 'send'],
  computed: {
    sendable(): boolean {
      return !!this.value.trim()
    },
  },
  methods: {
    onInput(event: Event) {
      this.$emit('input', event)
    },
    onTyping() {
      this.$emit('typing')
    },
    onSend() {
      this.$emit('send')
    },
  },
})
