import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e53b7e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member" }
const _hoisted_2 = { class: "member__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_participant_summary = _resolveComponent("participant-summary")!
  const _component_member_information = _resolveComponent("member-information")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_receive_email_form = _resolveComponent("receive-email-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createBlock(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
    loading: _withCtx(() => [
      _createVNode(_component_centered_loading_content, { type: "grayscale" })
    ]),
    error: _withCtx(() => [
      _createVNode(_component_base_alert, { type: _ctx.errorType }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', { name: _ctx.$t('iam.label.member') })), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    ideal: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_participant_summary, {
          class: "member__box",
          "created-participant-name": _ctx.memberProfile.createdUserName,
          "last-updated-participant-name": 
            _ctx.memberProfile.eSquareAppSettingLastUpdatedUserName
          ,
          "created-at": _ctx.memberProfile.createdAt,
          "updated-at": _ctx.memberProfile.eSquareAppSettingUpdatedAt,
          "show-hyphen-if-participant-name-unknown": true
        }, null, 8, ["created-participant-name", "last-updated-participant-name", "created-at", "updated-at"]),
        _createVNode(_component_base_box, { class: "member__box" }, {
          default: _withCtx(() => [
            _createVNode(_component_member_information, { member: _ctx.memberProfile }, null, 8, ["member"])
          ]),
          _: 1
        }),
        _createVNode(_component_base_box, { class: "member__box" }, {
          default: _withCtx(() => [
            _createVNode(_component_receive_email_form, {
              "form-value": _ctx.emailFormValue,
              onFormInput: _ctx.onInput
            }, null, 8, ["form-value", "onFormInput"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_base_button, {
            color: "primary",
            loading: _ctx.isLoading,
            onClick: _ctx.onClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["ui-stack"]))
}