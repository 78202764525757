
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { Locale, Status } from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { GlobalModule } from '@/store/modules/common/global'
import { SET_IS_INITIALIZING } from '@/store/modules/common/global/GlobalMutations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import {
  SET_LOCALE as SET_PROFILE_LOCALE,
  SET_LOCALE_ORIGINAL,
  SET_NAME,
  SET_STATUS,
} from '@/store/modules/iam/userProfile/UserProfileMutations'
import { WelcomeModule } from '@/store/modules/iam/welcome'
import {
  SET_USER_LOCALE,
  SET_USER_NAME,
} from '@/store/modules/iam/welcome/WelcomeMutations'

const {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} = createNamespacedHelpers('welcome') as WelcomeModule
const {
  mapGetters: userProfileMapGetters,
  mapMutations: userProfileMapMutations,
} = createNamespacedHelpers('userProfile') as UserProfileModule
const { mapMutations: gloalMapMutations } = createNamespacedHelpers(
  'global',
) as GlobalModule

export default defineComponent({
  name: 'Welcome',
  components: {
    BaseButton,
    BaseInputText,
    BaseLabel,
    BaseSingleSelect,
    Heading,
  },
  data(): {
    locale: {
      value: Locale
      label: string
    }
    localeOptions: InputOption[]
  } {
    return {
      locale: {
        value: Locale.Ja,
        label: this.$t('common.label.japanese', Locale.Ja).toString(),
      },
      localeOptions: [Locale.Ja, Locale.En].map(locale =>
        this.buildLocaleOption(locale),
      ),
    }
  },
  computed: {
    ...mapState(['userName', 'submitting']),
    ...mapGetters(['isValidWelcome']),
    ...userProfileMapGetters(['userProfile', 'isEsquareEnabled']),
  },
  created() {
    this[SET_IS_INITIALIZING]({ isInitializing: false })
  },
  methods: {
    ...mapMutations([SET_USER_LOCALE, SET_USER_NAME]),
    ...mapActions(['acceptUser']),
    ...userProfileMapMutations([
      SET_PROFILE_LOCALE,
      SET_LOCALE_ORIGINAL,
      SET_NAME,
      SET_STATUS,
    ]),
    ...gloalMapMutations([SET_IS_INITIALIZING]),
    buildLocaleOption(locale: Locale) {
      if (locale === Locale.En) {
        return {
          value: Locale.En,
          label: this.$t('common.label.english', Locale.En).toString(),
        }
      }
      return {
        value: Locale.Ja,
        label: this.$t('common.label.japanese', Locale.Ja).toString(),
      }
    },
    onLocaleInput(event: { value: Locale }) {
      this.locale = this.buildLocaleOption(event.value)
      this[SET_USER_LOCALE]({ locale: event.value })
      this.$i18n.locale = event.value
    },
    async onUseButtonClick() {
      await this.acceptUser({
        userId: this.userProfile.userId,
      })
      this[SET_PROFILE_LOCALE]({ locale: this.locale.value })
      this[SET_LOCALE_ORIGINAL]({ localeOriginal: this.locale.value })
      this[SET_STATUS]({ status: Status.Active })
      this[SET_IS_INITIALIZING]({ isInitializing: true })
      // eCloudへ一律で遷移する
      window.location.href = `${process.env.VUE_APP_ECLOUD_URL}`
    },
  },
})
