
import { defineComponent, PropType } from 'vue'

import BaseLabel from '@/components/common/BaseLabel.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'

const directions: ReadonlyArray<string> = ['horizontal', 'vertical']

export default defineComponent({
  name: 'BaseInputRadio',
  components: {
    BaseLabel,
    Sentences,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    help: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String as PropType<typeof directions[number]>,
      default: 'horizontal',
      validator: (val: typeof directions[number]) => {
        return directions.includes(val)
      },
    },
  },
  emits: ['input', 'edit'],
  computed: {
    readonly(): boolean {
      return [InputMode.READONLY, InputMode.EDIT].includes(this.mode)
    },
    editable(): boolean {
      return this.mode === InputMode.EDIT
    },
    wrapperClass(): Array<string | Record<string, boolean>> {
      return [
        `base-input-radio__wrapper--${this.direction}`,
        {
          'base-input-radio__wrapper--editable': this.editable,
        },
      ]
    },
    inputClass(): object {
      return { 'base-input-radio__input--editable': this.editable }
    },
    labelClass(): object {
      return { 'base-input-radio__radio--editable': this.editable }
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  methods: {
    onChange(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        this.$emit('input', event.target.value)
      }
    },
    onClick() {
      if (this.editable) {
        this.$emit('edit')
      }
    },
  },
})
