
import { defineComponent, PropType } from 'vue'

import { PriceType } from '@/api/generated'
import BaseDataset from '@/components/common/BaseDataset.vue'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'

export default defineComponent({
  name: 'SelectedIndicationContent',
  components: {
    BaseDataset,
  },
  props: {
    areaName: {
      type: String,
      required: true,
    },
    deliveryPeriod: {
      type: String,
      required: true,
    },
    hourTypeName: {
      type: String,
      required: true,
    },
    productTypeNames: {
      type: String,
      required: true,
    },
    fuelSurchargeType: {
      type: Object as PropType<FuelSurchargeType>,
      default: undefined,
    },
    priceType: {
      type: String as PropType<PriceType>,
      required: true,
    },
  },
  computed: {
    fuelSurchargeTypeName(): string | undefined {
      return this.fuelSurchargeType?.name.translation()
    },
  },
})
