
import { defineComponent, PropType } from 'vue'

import LoadingIcon from '@/components/common/Loading/LoadingIcon.vue'
import { LoadingType } from '@/components/common/constants/LoadingType'

export default defineComponent({
  name: 'CenteredLoadingContent',
  components: {
    LoadingIcon,
  },
  props: {
    type: {
      type: String as PropType<LoadingType>,
      default: LoadingType.DEFAULT,
    },
    isRelative: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className(): (string | Record<string, boolean>)[] {
      const blockClassName = 'centered-loading-content'
      return [
        blockClassName,
        `${blockClassName}--${this.type}`,
        {
          [`${blockClassName}--relative`]: this.isRelative,
        },
      ]
    },
  },
})
