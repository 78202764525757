
import { defineComponent, PropType } from 'vue'

import Sentences from '@/components/common/Typography/Sentences.vue'

export default defineComponent({
  name: 'TypingIndicator',
  components: {
    Sentences,
  },
  props: {
    names: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  computed: {
    message(): string {
      if (this.names.length === 1) {
        return `${this.names[0]}${this.$t('chat.message.oneUserTyping')}`
      }
      if (this.names.length === 2) {
        return `${this.names[0]}${this.$t('chat.message.twoUserTyping1')}${
          this.names[1]
        }${this.$t('chat.message.twoUserTyping2')}`
      }
      if (this.names.length > 2) {
        return this.$t('chat.message.manyUserTyping').toString()
      }
      return ''
    },
  },
})
