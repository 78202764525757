import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5637b38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order" }
const _hoisted_2 = { class: "order__tab" }
const _hoisted_3 = {
  key: 0,
  class: "order__header"
}
const _hoisted_4 = { class: "order__filter" }
const _hoisted_5 = { class: "order__buttons" }
const _hoisted_6 = { class: "order__download-button" }
const _hoisted_7 = { class: "order__download-icon" }
const _hoisted_8 = { class: "order__download-text" }
const _hoisted_9 = {
  key: 1,
  class: "order__blank-order"
}
const _hoisted_10 = { class: "order__blank-order_icon" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 3,
  class: "order__developing-message"
}
const _hoisted_13 = {
  key: 0,
  class: "order__annotation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fuel_surcharge_type_tab = _resolveComponent("fuel-surcharge-type-tab")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_product_filter = _resolveComponent("product-filter")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_the_new_indication_button = _resolveComponent("the-new-indication-button")!
  const _component_public_order_book_list = _resolveComponent("public-order-book-list")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_my_indication_new = _resolveComponent("my-indication-new")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_trader_page = _resolveComponent("trader-page")!

  return (_openBlock(), _createBlock(_component_trader_page, {
    title: _ctx.$t('common.label.order'),
    "active-menu-item": "order",
    "layout-mode": _ctx.layoutMode
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_fuel_surcharge_type_tab, {
            "current-tab-index": _ctx.activeTabIndex,
            onTabActive: _ctx.onTabActive
          }, null, 8, ["current-tab-index", "onTabActive"])
        ]),
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, {
              type: "grayscale",
              "is-relative": ""
            })
          ]),
          ideal: _withCtx(() => [
            (_ctx.isBgActiveTabIndex)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_product_filter, {
                      class: "order__product-filter",
                      filters: _ctx.productFilters,
                      actions: _ctx.filterActions,
                      "is-action-dropdown-active": _ctx.isFilterActionDropdownActive,
                      "selected-filters": _ctx.selectedProductFilters,
                      onSelect: _ctx.onFilterItemSelect,
                      onRemove: _ctx.onFilterItemRemove,
                      onFilterRemove: _ctx.onFilterRemove,
                      onClickButton: _ctx.onClickFilterDropdown,
                      onClickOutside: _ctx.onClickFilterOutside,
                      onChangeList: _ctx.onChangeFilters
                    }, null, 8, ["filters", "actions", "is-action-dropdown-active", "selected-filters", "onSelect", "onRemove", "onFilterRemove", "onClickButton", "onClickOutside", "onChangeList"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_action_dropdown, {
                      id: "download",
                      actions: _ctx.downloadActions,
                      "is-active": _ctx.isDownloadActionDropdownActive,
                      "is-button": false,
                      "is-right": "",
                      onClickOutside: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDownloadActionDropdownActive = false)),
                      onOrderCsvDownloadClick: _ctx.onClickOrderCsvDownload,
                      onRecentDealCsvDownloadClick: _ctx.onRecentDealCsvDownloadClick
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_base_button, {
                          onClick: _withModifiers(_ctx.onDownloadClick, ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_base_icon, { "icon-name": "cloud-download" })
                              ]),
                              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(
                          'trading.message.downloadIndicationAndDealInformation',
                        )), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["actions", "is-active", "onOrderCsvDownloadClick", "onRecentDealCsvDownloadClick"]),
                    _createVNode(_component_the_new_indication_button, {
                      class: "order__the-new-indication-button",
                      onTheNewIndicationButtonClick: _ctx.show
                    }, null, 8, ["onTheNewIndicationButtonClick"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isBgActiveTabIndex)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.orderBookIndications.length > 0)
                    ? (_openBlock(), _createBlock(_component_public_order_book_list, {
                        key: 0,
                        indications: _ctx.orderBookIndications,
                        deals: _ctx.orderBookDeals,
                        "product-type-filter-condition": _ctx.productTypeFilterCondition,
                        locale: _ctx.userProfile.locale,
                        "color-reversed": _ctx.services.esquare.userSetting.colorReversed,
                        "position-reversed": 
                  _ctx.services.esquare.userSetting.positionReversed
                ,
                        "with-fuel-surcharge": _ctx.withFuelSurcharge,
                        onButtonClick: _ctx.newIndicationProductFixed
                      }, null, 8, ["indications", "deals", "product-type-filter-condition", "locale", "color-reversed", "position-reversed", "with-fuel-surcharge", "onButtonClick"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_base_icon, {
                            "icon-name": "list",
                            size: "5x"
                          })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.message.notFoundOrder')), 1)
                      ]))
                ], 64))
              : (_ctx.etenderActiveTabIndex)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('common.label.developing')), 1))
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.alertType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.failFetch', {
                name: _ctx.$t('common.label.indications'),
              })), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          _: 1
        }, 8, ["ui-stack"]),
        (_ctx.isBgActiveTabIndex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('trading.message.orderAnnotation')), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_base_modal, {
          name: "new-indication",
          title: _ctx.$t('trading.label.newIndication'),
          width: 1096
        }, {
          default: _withCtx(() => [
            _createVNode(_component_my_indication_new, {
              mode: _ctx.indicationFormInputMode,
              "delivery-terms-id": _ctx.selectedDeliveryTermsId,
              position: _ctx.selectedPosition,
              onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$vfm.close('new-indication')))
            }, null, 8, ["mode", "delivery-terms-id", "position"])
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ]),
    _: 1
  }, 8, ["title", "layout-mode"]))
}