import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f04f3dec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report" }
const _hoisted_2 = { class: "report__header" }
const _hoisted_3 = { class: "report__published-at" }
const _hoisted_4 = { class: "report__action-dropdown" }
const _hoisted_5 = { class: "report__report-type" }
const _hoisted_6 = { class: "report__content-list" }
const _hoisted_7 = { class: "report__buttons" }
const _hoisted_8 = { class: "report-content-modal" }
const _hoisted_9 = { class: "report-content-modal__action-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_base_input_checkbox = _resolveComponent("base-input-checkbox")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_report_content_list = _resolveComponent("report-content-list")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_report_content_form = _resolveComponent("report-content-form")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('reports.label.edit'),
    "active-menu-item": "reports"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
        loading: _withCtx(() => [
          _createVNode(_component_centered_loading_content, { type: "grayscale" })
        ]),
        error: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.errorType }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('reports.message.failFetchReport')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        ideal: _withCtx(() => [
          _createVNode(_component_validation_form, null, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('reports.label.publishedAt')) + ": " + _toDisplayString(_ctx.displayPublishedAt), 1),
                  _createElementVNode("div", {
                    class: "report__copy-url",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCopyUrlClick && _ctx.onCopyUrlClick(...args)))
                  }, [
                    _createVNode(_component_base_tooltip, {
                      label: _ctx.$t('reports.message.copyUrl')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_base_icon, { "icon-name": "clipboard" })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_action_dropdown, {
                      id: "report-action-dropdown",
                      actions: _ctx.reportActionDropdownItems,
                      "is-active": _ctx.reportActionDropdownIsActive,
                      "is-right": "",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                    _ctx.reportActionDropdownIsActive = !_ctx.reportActionDropdownIsActive
                  ), ["stop"])),
                      onClickOutside: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reportActionDropdownIsActive = false)),
                      onDeleteReportClick: _ctx.onDeleteReportClick
                    }, null, 8, ["actions", "is-active", "onDeleteReportClick"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_base_input_checkbox, {
                    label: _ctx.$t('reports.label.reportType'),
                    name: "reportTypes",
                    options: _ctx.reportTypeOptions,
                    "initial-values": _ctx.values.reportTypes,
                    required: true,
                    rules: "requiredArray",
                    onInput: _ctx.onInputReportTypes
                  }, null, 8, ["label", "options", "initial-values", "onInput"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_base_label, {
                    class: "report__summary-label",
                    label: _ctx.$t('reports.label.reportContents')
                  }, null, 8, ["label"]),
                  _createVNode(_component_report_content_list, {
                    "ja-content-title": _ctx.values.reportContents[_ctx.locale.Ja].title,
                    "en-content-title": _ctx.values.reportContents[_ctx.locale.En].title,
                    onListItemClick: _ctx.openReportContentModal
                  }, null, 8, ["ja-content-title", "en-content-title", "onListItemClick"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_base_button, {
                    class: "report__cancel-button",
                    onClick: _ctx.onCancelClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    class: "report__publish-button",
                    disabled: !_ctx.hasValidContent || Object.keys(errors).length > 0,
                    color: "primary",
                    onClick: _ctx.onPublishReportClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.publish')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ]),
              _createVNode(_component_base_modal, {
                name: _ctx.reportContentModalName,
                title: _ctx.reportContentModalTitle,
                width: 850,
                "click-to-close": false,
                onClosed: _cache[5] || (_cache[5] = ($event: any) => (_ctx.reportContentActionDropdownIsActive = false))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_action_dropdown, {
                        id: "report-content-modal-action-dropdown",
                        actions: _ctx.reportContentActionDropdownItems,
                        "is-active": _ctx.reportContentActionDropdownIsActive,
                        "is-right": "",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (
                    _ctx.reportContentActionDropdownIsActive = !_ctx.reportContentActionDropdownIsActive
                  ), ["stop"])),
                        onClickOutside: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reportContentActionDropdownIsActive = false)),
                        onDeleteReportContentClick: _ctx.onDeleteReportContentClick
                      }, null, 8, ["actions", "is-active", "onDeleteReportContentClick"])
                    ]),
                    _createVNode(_component_report_content_form, {
                      locale: _ctx.currentLocale,
                      title: _ctx.values.reportContents[_ctx.currentLocale].title,
                      summary: _ctx.values.reportContents[_ctx.currentLocale].summary,
                      "selected-file": 
                  _ctx.values.reportContents[_ctx.currentLocale].selectedFile
                ,
                      "uploaded-file-name": _ctx.currentContentAttachmentName,
                      onDownloadClick: _ctx.onDownloadClick,
                      onConfirmClick: _ctx.onConfirmReportContentClick,
                      onCancelClick: _ctx.hideReportContentModal
                    }, null, 8, ["locale", "title", "summary", "selected-file", "uploaded-file-name", "onDownloadClick", "onConfirmClick", "onCancelClick"])
                  ])
                ]),
                _: 1
              }, 8, ["name", "title"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["ui-stack"]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.deleteReportModalName,
        title: _ctx.$t('reports.message.deleteReportTitle'),
        "confirm-button-label": _ctx.$t('common.label.delete'),
        "confirm-button-color": "danger",
        loading: _ctx.deleteReportSubmitting,
        onConfirm: _ctx.onDeleteReportConfirm,
        onCancel: _ctx.onDeleteReportCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('reports.message.deleteReportMessage')), 1)
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.publishReportModalName,
        title: _ctx.$t('reports.message.publishReportTitle'),
        "confirm-button-label": _ctx.$t('common.label.publish'),
        loading: _ctx.publishReportSubmitting,
        onConfirm: _ctx.onPublishReportConfirm,
        onCancel: _ctx.onPublishReportCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('reports.message.publishReportMessage')), 1)
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}