import { RouteRecordRaw } from 'vue-router'

import EcloudOrganization from '@/views/iam/EcloudOrganization.vue'
import EcloudOrganizations from '@/views/iam/EcloudOrganizations.vue'
import EcloudMember from '@/views/iam/children/EcloudMember.vue'
import EcloudMembers from '@/views/iam/children/EcloudMembers.vue'
import EcloudOrganizationDetail from '@/views/iam/children/EcloudOrganizationDetail.vue'

/**
 * パス: /ecloud-organizations/...
 */
export const eCloudOrganizationsRoutes: RouteRecordRaw[] = [
  {
    path: '/ecloud-organizations',
    name: 'EcloudOrganizations',
    component: EcloudOrganizations,
  },
  {
    path: '/ecloud-organizations/:organizationId',
    component: EcloudOrganization,
    props: route => ({
      organizationId: route.params.organizationId,
      basePath: `/ecloud-organizations/${route.params.organizationId}`,
    }),
    children: [
      {
        path: '',
        name: 'EcloudOrganizationDetail',
        component: EcloudOrganizationDetail,
        props: route => ({ organizationId: route.params.organizationId }),
        meta: {
          activeMenuItem: 'ecloud-organizations',
          titleKey: 'iam.label.detailedEcloudOrganizationProfile',
          tabShown: true,
        },
      },
      {
        path: 'members',
        name: 'EcloudMembers',
        component: EcloudMembers,
        props: route => ({ organizationId: route.params.organizationId }),
        meta: {
          activeMenuItem: 'ecloud-organizations',
          titleKey: 'iam.label.detailedEcloudOrganizationProfile',
          tabShown: true,
        },
      },
      {
        path: 'members/:id',
        name: 'EcloudMember',
        component: EcloudMember,
        props: route => ({
          organizationId: route.params.organizationId,
          id: route.params.id,
        }),
        meta: {
          activeMenuItem: 'ecloud-organizations',
          titleKey: 'iam.label.detailedEcloudMember',
        },
      },
    ],
  },
]
