
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import InnerLabel from '@/components/common/Typography/InnerLabel.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'
import { LocalizedReport as LocalizedReportModel } from '@/models/reports/LocalizedReport'

export default defineComponent({
  name: 'Report',
  components: {
    BaseIcon,
    Heading,
    InnerLabel,
    Sentences,
  },
  props: {
    report: {
      type: Object as PropType<LocalizedReportModel>,
      required: true,
    },
  },
  emits: ['download-click'],
  methods: {
    onDownloadClick() {
      this.$emit('download-click', {
        reportId: this.report.id,
        reportContentId: this.report.reportContent.id,
        attachmentName: this.report.reportContent.attachmentName,
      })
    },
  },
})
