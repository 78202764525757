import { Mutations } from 'vuex-smart-module'

import { PublicOrganizationProfile } from '@/models/iam/PublicOrganizationProfile'
import { PublicOrganizationProfileState } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileState'

export const SET_PUBLIC_ORGANIZATION_PROFILES =
  'SET_PUBLIC_ORGANIZATION_PROFILES'

export class PublicOrganizationProfileMutations extends Mutations<
  PublicOrganizationProfileState
> {
  [SET_PUBLIC_ORGANIZATION_PROFILES](payload: {
    publicOrganizationProfiles: PublicOrganizationProfile[]
  }) {
    this.state.publicOrganizationProfiles = payload.publicOrganizationProfiles
  }
}
