
import { defineComponent, PropType } from 'vue'
import Multiselect from 'vue-multiselect'

import { InputOption } from '@/components/common/interface/InputOption'

export default defineComponent({
  name: 'BaseMultiSelect',
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    options: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'regular',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'remove'],
  computed: {
    styleVars(): object {
      return {
        '--font-size': this.size === 'regular' ? '14px' : '12px',
      }
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  methods: {
    onSelect(event: InputOption) {
      this.$emit('select', event)
    },
    onRemove(event: InputOption) {
      this.$emit('remove', event)
    },
  },
})
