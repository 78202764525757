import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03f84e1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-list-item" }
const _hoisted_2 = { class: "report-list-item__contents" }
const _hoisted_3 = { class: "report-list-item__row" }
const _hoisted_4 = { class: "report-list-item__header" }
const _hoisted_5 = { class: "report-list-item__report-types" }
const _hoisted_6 = { class: "report-list-item__published-at" }
const _hoisted_7 = { class: "report-list-item__row" }
const _hoisted_8 = { class: "report-list-item__content" }
const _hoisted_9 = { class: "report-list-item__locale-badge" }
const _hoisted_10 = { class: "report-list-item__title" }
const _hoisted_11 = { class: "report-list-item__locale-badge" }
const _hoisted_12 = { class: "report-list-item__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_badge = _resolveComponent("base-badge")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: "report-list-item__link",
            to: { path: `/reports/${_ctx.report.id}` }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.reportTypes, (type, index) => {
                      return (_openBlock(), _createBlock(_component_base_badge, {
                        key: index,
                        class: "report-list-item__report-type",
                        type: "outline"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.report.displayReportType(type)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('reports.label.publishedAt')) + ": " + _toDisplayString(_ctx.report.displayPublishedAt()), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_base_badge, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.japanese')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.jaContentTitle), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "report-list-item__download",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onDownloadClick($event, _ctx.locale.Ja)), ["stop"]))
                  }, [
                    (_ctx.hasJaContent)
                      ? (_openBlock(), _createBlock(_component_base_tooltip, {
                          key: 0,
                          direction: "left",
                          label: _ctx.report.reportContents.ja.attachmentName
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_base_icon, { "icon-name": "file-download" })
                          ]),
                          _: 1
                        }, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_base_badge, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.english')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.enContentTitle), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "report-list-item__download",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDownloadClick($event, _ctx.locale.En)))
                  }, [
                    (_ctx.hasEnContent)
                      ? (_openBlock(), _createBlock(_component_base_tooltip, {
                          key: 0,
                          direction: "left",
                          label: _ctx.report.reportContents[_ctx.locale.En].attachmentName
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_base_icon, { "icon-name": "file-download" })
                          ]),
                          _: 1
                        }, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _: 1
    })
  ]))
}