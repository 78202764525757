import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "base-modal" }
const _hoisted_2 = { class: "base-modal__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_final_modal, {
      class: "base-modal__wrapper",
      "modal-id": _ctx.name,
      "content-style": _ctx.contentStyle,
      "click-to-close": _ctx.clickToClose,
      onClosed: _ctx.onClosed
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["base-modal__content", _ctx.contentClass])
        }, [
          _createElementVNode("header", _hoisted_2, [
            _createVNode(_component_heading, {
              type: "header1",
              class: "base-modal__title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }),
            (_ctx.showCloseButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "base-modal__close-button",
                  "aria-label": "Close modal",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClosed && _ctx.onClosed(...args)))
                }, [
                  _createVNode(_component_base_icon, { "icon-name": "times" })
                ]))
              : _createCommentVNode("", true)
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ]),
      _: 3
    }, 8, ["modal-id", "content-style", "click-to-close", "onClosed"])
  ]))
}