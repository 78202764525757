import { Type } from 'class-transformer'

import {
  MyDeal as IMyDeal,
  DealSubType,
  SpreadType,
  PositionType,
} from '@/api/generated'
import { i18n } from '@/i18n'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'
import { moment } from '@/utils/date'

export class MyDeal implements IMyDeal {
  dealId!: string

  @Type(() => Product)
  product!: Product

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  type!: DealSubType
  spreadTypes?: SpreadType[]
  price!: Price
  unitPrice!: number
  volume!: number
  exchangeVolume?: number
  position!: PositionType

  @Type(() => MultiLocaleName)
  counterName?: MultiLocaleName

  createdAt!: string

  displayCreatedAt(): string {
    return moment(this.createdAt).format(i18n.t('common.format.formatDate'))
  }

  get isSpread(): boolean {
    return this.type === DealSubType.Spread
  }
}
