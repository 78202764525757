import { Type } from 'class-transformer'

import {
  IndicationStatusType,
  MyIndication as IMyIndication,
  IndicationSubType,
  SpreadType,
  PositionType,
} from '@/api/generated'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'
import { moment } from '@/utils/date'

export class MyIndication implements IMyIndication {
  indicationId!: string
  organizationId!: string

  @Type(() => Product)
  products!: Array<Product>

  type!: IndicationSubType
  spreadTypes?: SpreadType[]
  position!: PositionType
  price!: Price
  unitPrice!: number
  volume!: number | null
  exchangeVolume?: number

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  status!: IndicationStatusType
  request?: string
  createdParticipantId!: string
  lastUpdatedParticipantId!: string
  createdAt!: string
  updatedAt!: string

  displayCreatedAt(formatDate: string): string {
    return moment(this.createdAt).format(`${formatDate} HH:mm`)
  }

  displayUpdatedAt(formatDate: string): string {
    return moment(this.updatedAt).format(`${formatDate} HH:mm`)
  }

  get isSpread(): boolean {
    return this.type === IndicationSubType.Spread
  }
}
