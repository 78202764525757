import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "channel-message-window-title",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["channel-message-window-title__name", {
        'channel-message-window-title__name--unread': _ctx.hasUnreadMessage,
      }])
    }, _toDisplayString(_ctx.name), 3)
  ]))
}