import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f5f3996"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-switcher" }
const _hoisted_2 = { class: "dropdown is-active" }
const _hoisted_3 = { class: "app-switcher__icon-wrapper" }
const _hoisted_4 = { class: "app-switcher__icon" }
const _hoisted_5 = { class: "dropdown-menu" }
const _hoisted_6 = { class: "dropdown-content" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "app-switcher__item-name-wrapper" }
const _hoisted_10 = { class: "app-switcher__item-name" }
const _hoisted_11 = { class: "app-switcher__item-description" }
const _hoisted_12 = { class: "app-switcher__item-check" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_dropdown = _resolveComponent("base-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_dropdown, {
        id: "app-switcher",
        "is-active": _ctx.isDropdownNavigationActive,
        toggle: 
          () => (_ctx.isDropdownNavigationActive = !_ctx.isDropdownNavigationActive)
        
      }, {
        "trigger-content": _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_base_icon, { "icon-name": "grid" })
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                return (_openBlock(), _createElementBlock("li", { key: i }, [
                  _createElementVNode("a", {
                    href: item.href,
                    class: _normalizeClass(["app-switcher__item", { 'app-switcher__no-link': item.checked }]),
                    target: "_blank",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDropdownNavigationActive = false))
                  }, [
                    _createElementVNode("img", {
                      src: item.logo,
                      alt: `${item.name} logo`,
                      width: "40",
                      height: "40"
                    }, null, 8, _hoisted_8),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("p", _hoisted_10, _toDisplayString(item.name), 1),
                      _createElementVNode("p", _hoisted_11, _toDisplayString(item.description), 1)
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_base_icon, { "icon-name": "check" })
                    ], 512), [
                      [_vShow, item.checked]
                    ])
                  ], 10, _hoisted_7)
                ]))
              }), 128))
            ])
          ])
        ]),
        _: 1
      }, 8, ["is-active", "toggle"])
    ])
  ]))
}