
import moment from 'moment'
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import { DirectionType } from '@/components/common/constants/DirectionType'

export default defineComponent({
  name: 'ParticipantSummary',
  components: {
    BaseBox,
    BaseDataset,
  },
  props: {
    createdParticipantName: {
      type: String,
      default: undefined,
    },
    lastUpdatedParticipantName: {
      type: String,
      default: undefined,
    },
    createdAt: {
      type: String,
      default: undefined,
    },
    updatedAt: {
      type: String,
      default: undefined,
    },
    direction: {
      type: String as PropType<DirectionType>,
      default: DirectionType.Column,
      validator: (value: DirectionType) => {
        return [DirectionType.Column, DirectionType.Row].includes(value)
      },
    },
    showHyphenIfParticipantNameUnknown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayCreatedAt(): string {
      return this.createdAt
        ? moment(this.createdAt).format(
            this.$t('common.format.dateTime').toString(),
          )
        : this.$t('common.label.blank').toString()
    },
    displayUpdatedAt(): string {
      return this.updatedAt
        ? moment(this.updatedAt).format(
            this.$t('common.format.dateTime').toString(),
          )
        : this.$t('common.label.blank').toString()
    },
    displayCreatedParticipantName(): string {
      return this.createdParticipantName
        ? this.createdParticipantName
        : this.labelStringForParticipantNameUnknown
    },
    displayLastUpdatedParticipantName(): string {
      return this.lastUpdatedParticipantName
        ? this.lastUpdatedParticipantName
        : this.labelStringForParticipantNameUnknown
    },
    labelStringForParticipantNameUnknown(): string {
      return this.showHyphenIfParticipantNameUnknown
        ? this.$t('common.label.blank').toString()
        : this.$t('trading.label.traderNameUnknown').toString()
    },
  },
})
