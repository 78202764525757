
import { defineComponent, PropType } from 'vue'

import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import ReceiveEmailStatuses from '@/components/iam/ReceiveEmailStatuses.vue'
import { ReceiveEmailStatusesProps } from '@/components/iam/interface/ReceiveEmailStatusesProps'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'

export default defineComponent({
  name: 'OrganizationEmailAddressListItem',
  components: { ActionDropdown, BaseBox, BaseLink, ReceiveEmailStatuses },
  props: {
    organizationEmailAddress: {
      type: Object as PropType<OrganizationEmailAddress>,
      required: true,
    },
    parentPath: {
      type: String,
      required: true,
    },
  },
  emits: ['delete-click'],
  data(): {
    isDropdownActive: boolean
    itemPath: string
  } {
    return {
      isDropdownActive: false,
      itemPath: `${this.parentPath}/${this.organizationEmailAddress.id}`,
    }
  },
  computed: {
    actionItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('iam.label.deleteOrganizationEmailAddress').toString(),
          eventName: 'delete-click',
        },
      ]
    },
    dropdownId(): string {
      return `organization-email-address-list-item__delete-${this.organizationEmailAddress.id}`
    },
    mailSetting(): ReceiveEmailStatusesProps {
      return {
        receiveIndicationEmail: this.organizationEmailAddress
          .receiveIndicationEmail,
        receiveLiveMarketEmail: this.organizationEmailAddress
          .receiveLiveMarketEmail,
        receiveSpotTradingRecapEmail: this.organizationEmailAddress
          .receiveSpotTradingRecapEmail,
        receiveDerivativeTradingRecapEmail: this.organizationEmailAddress
          .receiveDerivativeTradingRecapEmail,
        receiveReportEmail: this.organizationEmailAddress.receiveReportEmail,
        receiveInvoiceEmail: this.organizationEmailAddress.receiveInvoiceEmail,
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('delete-click')
    },
  },
})
