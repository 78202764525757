import {
  ActiveInformation as IActiveInformation,
  InformationType,
} from '@/api/generated'

export class ActiveInformation implements IActiveInformation {
  informationId!: string
  title!: string
  message!: string
  informationType!: InformationType
  expiresAt!: string
}
