
import { defineComponent, PropType } from 'vue'
import { Locale } from 'vue-i18n'

import { DeliveryUnit, PositionType } from '@/api/generated'
import Angle from '@/components/common/Angle.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import OrderButtons from '@/components/trading/OrderButtons.vue'
import PublicOrderBookDetail from '@/components/trading/PublicOrderBookDetail.vue'
import PublicOrderBookSummary from '@/components/trading/PublicOrderBookSummary.vue'
import { MyIndicationNewProps } from '@/components/trading/interface/MyIndicationNewProps'
import { generateId } from '@/components/trading/services/generateMaps'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { Product } from '@/models/trading/Product'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndicationOfProduct } from '@/models/trading/PublicIndicationOfProduct'

export default defineComponent({
  name: 'PublicOrderBook',
  components: {
    Angle,
    BaseBox,
    OrderButtons,
    PublicOrderBookDetail,
    PublicOrderBookSummary,
  },
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTerms>,
      required: true,
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
    indications: {
      type: Array as PropType<PublicIndicationOfProduct[]>,
      default: () => [],
    },
    deal: {
      type: Object as PropType<PublicDeal>,
      default: undefined,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['expand', 'button-click'],
  computed: {
    product(): Product {
      return this.indications.length > 0
        ? this.indications[0].product
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.deal!.product
    },
  },
  methods: {
    onClick() {
      const fuelSurchargeTypeId =
        (this.indications || [])[0]?.fuelSurchargeType?.id ||
        this.deal?.fuelSurchargeType?.id
      const id = generateId(this.deliveryTerms.id, fuelSurchargeTypeId)
      this.$emit('expand', { id })
    },
    onButtonClick(position: PositionType) {
      const newIndicationModalProps: MyIndicationNewProps = {
        deliveryTermsId: this.product.deliveryTermsId,
        position,
      }
      this.$emit('button-click', newIndicationModalProps)
    },
  },
})
