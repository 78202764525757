import { DealFilterConditions } from '@/store/modules/trading/deal/interface'

export class DealState {
  filterCondition: DealFilterConditions = {
    startDeliveryYearMonth: undefined,
    endDeliveryYearMonth: undefined,
    organizationName: undefined,
    area: undefined,
    hourType: undefined,
    productType: undefined,
    privateConfidentialOfFilter: undefined,
    negotiationId: undefined,
    executedDate: undefined,
  }
}
