import { Module } from 'vuex-smart-module'

import { GlobalActions } from '@/store/modules/common/global/GlobalActions'
import { GlobalGetters } from '@/store/modules/common/global/GlobalGetters'
import { GlobalMutations } from '@/store/modules/common/global/GlobalMutations'
import { GlobalState } from '@/store/modules/common/global/GlobalState'

export const global = new Module({
  state: GlobalState,
  getters: GlobalGetters,
  mutations: GlobalMutations,
  actions: GlobalActions,
})

export type GlobalModule = typeof global
