
import moment from 'moment'
import { defineComponent, PropType } from 'vue'

import { Locale, PositionType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import OrganizationRecapForm from '@/components/trading/OrganizationRecapForm.vue'
import { DealFormProps } from '@/components/trading/interface/DealFormProps'
import { Area } from '@/models/trading/Area'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { ProductType } from '@/models/trading/ProductType'

export default defineComponent({
  name: 'DealConfirmModalContent',
  components: {
    BaseBox,
    BaseDataset,
    OrganizationRecapForm,
  },
  props: {
    formValue: {
      type: Object as PropType<DealFormProps>,
      required: true,
    },
    dealWithOrganizationRecap: {
      type: Object as PropType<DealWithOrganizationRecap>,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    areas: {
      type: Array as PropType<Area[]>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  data() {
    return {
      ask: PositionType.Ask,
      bid: PositionType.Bid,
    }
  },
  computed: {
    productType(): ProductType {
      return this.dealWithOrganizationRecap.deal.product.productType
    },
    isCfdAndIsNotAreaSpread(): boolean {
      return (
        this.productType.isCfd() && !this.dealWithOrganizationRecap.isSpread
      )
    },
    notIncludingTocomOrEex(): boolean {
      return !(this.productType.isSwapEex() || this.productType.isSwapTocom())
    },
    displayExecutedAt(): string {
      return moment(this.formValue.executedAt).format(
        this.$t('common.format.dateTime').toString(),
      )
    },
  },
})
