
import { defineComponent, PropType } from 'vue'

import Heading from '@/components/common/Typography/Heading.vue'
import { ProductType } from '@/models/trading/ProductType'
import {
  getOptions,
  SpreadPreset,
  SpreadPresetItems,
} from '@/models/trading/SpreadPreset'

export default defineComponent({
  name: 'SpreadPresetButtons',
  components: {
    Heading,
  },
  props: {
    selectedProductTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
  },
  emits: ['click'],
  data(): {
    selected: {
      rowIndex?: number
      colIndex?: number
    }
  } {
    return {
      selected: {
        rowIndex: undefined,
        colIndex: undefined,
      },
    }
  },
  computed: {
    presets(): SpreadPresetItems {
      return getOptions(this.selectedProductTypes)
    },
  },
  methods: {
    isSelected(rowIndex: number, colIndex: number): boolean {
      return (
        this.selected.rowIndex === rowIndex &&
        this.selected.colIndex === colIndex
      )
    },
    onMouseDown(rowIndex: number, colIndex: number, item: SpreadPreset) {
      if (item.disabled) {
        return
      }
      this.selected = {
        rowIndex,
        colIndex,
      }
    },
    onClick(item: SpreadPreset) {
      if (item.disabled) {
        return
      }
      this.selected = {
        rowIndex: undefined,
        colIndex: undefined,
      }
      this.$emit('click', item.value)
    },
  },
})
