
import { defineComponent, PropType } from 'vue'

import LocalizedReportListItem from '@/components/reports/LocalizedReportListItem.vue'
import { LocalizedReport as LocalizedReportModel } from '@/models/reports/LocalizedReport'

export default defineComponent({
  name: 'LocalizedReportList',
  components: {
    LocalizedReportListItem,
  },
  props: {
    reports: {
      type: Array as PropType<LocalizedReportModel[]>,
      default: () => [],
    },
  },
  emits: ['item-click', 'download-click', 'last-item-visible'],
  methods: {
    onItemClick(reportId: string) {
      this.$emit('item-click', reportId)
    },
    onDownloadClick({
      reportId,
      reportContentId,
      attachmentName,
    }: {
      reportId: string
      reportContentId: string
      attachmentName: string
    }) {
      this.$emit('download-click', {
        reportId,
        reportContentId,
        attachmentName,
      })
    },
    visibilityChanged(isVisible: boolean, index: number) {
      if (!isVisible || index !== this.reports.length - 1) {
        return
      }
      this.$emit('last-item-visible')
    },
  },
})
