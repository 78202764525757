import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-893ed204"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "indication-confirm-modal-content" }
const _hoisted_2 = { class: "indication-confirm-modal-content__message" }
const _hoisted_3 = { class: "indication-confirm-modal-content__heading" }
const _hoisted_4 = { class: "indication-confirm-modal-content__heading" }
const _hoisted_5 = { class: "indication-confirm-modal-content__delivery-periods-heading" }
const _hoisted_6 = { class: "indication-confirm-modal-content__delivery-periods-header" }
const _hoisted_7 = { class: "indication-confirm-modal-content__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
    _createElementVNode("div", null, [
      (_ctx.isBroker)
        ? (_openBlock(), _createBlock(_component_base_box, {
            key: 0,
            class: "indication-confirm-modal-content__box"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('trading.label.organizationInformation')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationBlockItems, (item, index) => {
                return (_openBlock(), _createBlock(_component_base_dataset, {
                  key: index,
                  direction: "column",
                  label: item.label,
                  data: item.data
                }, null, 8, ["label", "data"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_base_box, { class: "indication-confirm-modal-content__box" }, {
        default: _withCtx(() => [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('trading.label.productInformation')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productBlockItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_base_dataset, {
              key: index,
              direction: "column",
              label: item.label,
              data: item.data
            }, null, 8, ["label", "data"]))
          }), 128)),
          (_ctx.isNonStandard)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_base_dataset, {
                  direction: "column",
                  label: _ctx.$t('trading.label.area'),
                  data: _ctx.areaValue
                }, null, 8, ["label", "data"]),
                _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('trading.label.deliveryPeriod')), 1),
                _createVNode(_component_base_box, {
                  type: "fill",
                  class: "indication-confirm-modal-content__delivery-periods"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.period')), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.deliveryPatternComponent')), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.unitPriceShort')), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.volumeShort')), 1)
                    ]),
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValue.deliveryPeriods, (deliveryPeriod, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "indication-confirm-modal-content__delivery-periods-body"
                        }, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatPeriod(
                      deliveryPeriod.startDeliveryDate,
                      deliveryPeriod.endDeliveryDate,
                    )), 1),
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatPatternComponent(
                      deliveryPeriod.deliveryPatternComponentFormValues,
                    )), 1),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.unitPriceWithUnit', {
                      unitPrice: deliveryPeriod.unitPrice,
                    })), 1),
                          _createElementVNode("div", null, _toDisplayString(_ctx.formatVolume(deliveryPeriod.volume)), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }),
                (_ctx.isBroker)
                  ? (_openBlock(), _createBlock(_component_base_dataset, {
                      key: 0,
                      direction: "column",
                      label: _ctx.$t('trading.label.toDisplayVolumeAsTbd'),
                      data: 
              _ctx.formValue.maskVolume
                ? _ctx.$t('common.label.yes')
                : _ctx.$t('common.label.no')
            
                    }, null, 8, ["label", "data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_base_dataset, {
            direction: "column",
            label: _ctx.$t('trading.label.swingOption'),
            data: 
            `${
              _ctx.isSwingOption
                ? _ctx.$t('trading.label.withSwingOption')
                : _ctx.$t('trading.label.withoutSwingOption')
            }`
          
          }, null, 8, ["label", "data"]),
          (_ctx.isSwingOption)
            ? (_openBlock(), _createBlock(_component_base_dataset, {
                key: 1,
                direction: "column",
                label: 
            _ctx.$t('trading.label.basicUnitPriceLabelWithUnit', {
              unit: _ctx.$t('trading.label.basicUnitPriceUnit.jpyPerKw'),
            })
          ,
                data: 
            _ctx.$t('trading.label.basicUnitPriceWithUnit', {
              basicUnitPrice: _ctx.formValue.price?.basicUnitPrice?.unitPrice,
              unit: _ctx.$t('trading.label.basicUnitPriceUnit.jpyPerKw'),
            })
          
              }, null, 8, ["label", "data"]))
            : _createCommentVNode("", true),
          _createVNode(_component_base_dataset, {
            direction: "column",
            label: _ctx.$t('trading.label.request'),
            data: _ctx.requestValue
          }, null, 8, ["label", "data"]),
          (_ctx.isDisplayPublicInformation)
            ? (_openBlock(), _createBlock(_component_base_dataset, {
                key: 2,
                direction: "column",
                label: _ctx.$t('trading.label.publicInformation'),
                data: _ctx.publicInformation
              }, null, 8, ["label", "data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.isBroker)
        ? (_openBlock(), _createBlock(_component_base_box, {
            key: 1,
            class: "indication-confirm-modal-content__box"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t('trading.label.indicationSettings')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brokerBlockItems, (item, index) => {
                return (_openBlock(), _createBlock(_component_base_dataset, {
                  key: index,
                  direction: "column",
                  label: item.label,
                  data: item.data
                }, null, 8, ["label", "data"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}