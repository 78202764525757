
import { defineComponent, PropType } from 'vue'

import ChannelMemberCount from '@/components/chat/ChannelMemberCount.vue'
import ChatInputWrapper from '@/components/chat/ChatInputWrapper.vue'
import MessageList from '@/components/chat/MessageList.vue'
import { ChannelMemberProps } from '@/components/chat/interface/ChannelMemberProps'
import { MessageProps } from '@/components/chat/interface/MessageProps'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import BaseSkeleton from '@/components/common/BaseSkeleton.vue'
import Heading from '@/components/common/Typography/Heading.vue'

export default defineComponent({
  name: 'ChannelContent',
  components: {
    ActionDropdown,
    BasePicture,
    BaseSkeleton,
    ChannelMemberCount,
    ChatInputWrapper,
    Heading,
    MessageList,
  },
  props: {
    channelName: {
      type: String,
      default: '',
    },
    messages: {
      type: Array as PropType<MessageProps[]>,
      default: () => [],
    },
    chatInputValue: {
      type: String,
      default: '',
    },
    typingUserNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    members: {
      type: Array as PropType<ChannelMemberProps[]>,
      default: () => [],
    },
    organizationName: {
      type: String,
      default: '',
    },
    isStarred: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    activeChannelPictureData: {
      type: Object as PropType<
        { picture: string; isOrganization: boolean } | undefined
      >,
      default: undefined,
    },
  },
  emits: [
    'input',
    'typing',
    'send',
    'touch-start',
    'read-message',
    'select-add-star',
    'select-remove-star',
  ],
  data() {
    return {
      isActionDropdownActive: false,
    }
  },
  computed: {
    actions() {
      return this.isStarred
        ? [
            {
              label: this.$t('chat.label.removeStar'),
              eventName: 'select-remove-star',
            },
          ]
        : [
            {
              label: this.$t('chat.label.addStar'),
              eventName: 'select-add-star',
            },
          ]
    },
    pictureBorderRadius(): string {
      return this.activeChannelPictureData?.isOrganization ? '4px' : '50%'
    },
  },
  methods: {
    onInput(event: Event) {
      this.$emit('input', event)
    },
    onTyping() {
      this.$emit('typing')
    },
    onSend() {
      this.$emit('send')
    },
    onTouchStart() {
      this.$emit('touch-start')
    },
    scrollToBottom() {
      const messageList = this.$refs.messageList as InstanceType<
        typeof MessageList
      >
      if (messageList) {
        messageList.scrollToBottom()
      }
    },
    scrollToItem(index: number) {
      const messageList = this.$refs.messageList as InstanceType<
        typeof MessageList
      >
      if (messageList) {
        messageList.scrollToItem(index)
      }
    },
    onReadMessage(index: number) {
      this.$emit('read-message', index)
    },
    onSelectAddStar() {
      this.$emit('select-add-star')
    },
    onSelectRemoveStar() {
      this.$emit('select-remove-star')
    },
  },
})
