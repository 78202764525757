
import debounce from 'lodash/debounce'
import moment from 'moment'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import {
  DeliveryUnit,
  IndicationStatusType as IndicationStatusTypeEnum,
  PositionType,
  SpreadTypeTypeEnum,
  StandardIndicationSelectableInputsRequest,
  UpdateNonStandardIndication,
  UpdateStandardIndication,
} from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { DirectionType } from '@/components/common/constants/DirectionType'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import BrokerIndicationForm from '@/components/trading/BrokerIndicationForm.vue'
import DeliverySummaries from '@/components/trading/DeliverySummaries.vue'
import IndicationConfirmModalContent from '@/components/trading/IndicationConfirmModalContent.vue'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import TradingSteps from '@/components/trading/TradingSteps.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import BrokerIndicationNew from '@/components/trading/container/BrokerIndicationNew.vue'
import { BrokerIndicationFormProps } from '@/components/trading/interface/BrokerIndicationFormProps'
import { DeliveryPatternComponentFormProps } from '@/components/trading/interface/DeliveryPatternComponentFormProps'
import { buildUpdateStandardIndicationPayload } from '@/components/trading/services/indicationPayloadBuilder'
import {
  buildProductFormValue,
  buildSelectableInputsRequest,
} from '@/components/trading/services/starndardIndicationSelectableInputsServices'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { DayPattern } from '@/models/trading/DayPattern'
import { DeliverySummaries as DeliverySummariesModel } from '@/models/trading/DeliverySummaries'
import { Indication } from '@/models/trading/Indication'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { TradingSteps as TradingStepsModel } from '@/models/trading/TradingSteps'
import { MemberModule } from '@/store/modules/iam/member'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { IndicationModule } from '@/store/modules/trading/indication'
import { ProductModule } from '@/store/modules/trading/product'
import { setNotification } from '@/utils/utils'

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule
const { mapActions: indicationMapActions } = createNamespacedHelpers(
  'indication',
) as IndicationModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule
const { mapActions: organizationsMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule
const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

export default defineComponent({
  name: 'Indication',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseBox,
    BaseButton,
    BaseModal,
    BrokerIndicationForm,
    BrokerIndicationNew,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    DeliverySummaries,
    IndicationConfirmModalContent,
    ParticipantSummary,
    TradingSteps,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    id: string
    indication: Indication | undefined
    formValue: BrokerIndicationFormProps
    organization: Organization | undefined
    traders: MemberProfile[]
    brokers: MemberProfile[]
    statusOptions: InputOption[]
    dayPatternOptions: InputOption[]
    uiStack: UiStack
    errorType: AlertType
    updateModalName: string
    deleteModalName: string
    updateSubmitting: boolean
    deleteSubmitting: boolean
    actionDropdownIsActive: boolean
    deliverySummaries: DeliverySummariesModel | undefined
    tradingSteps: TradingStepsModel | undefined
    openedSpreadFormTypes: SpreadTypeTypeEnum[]
    selectableInputs: BaseStandardIndicationSelectableInputs | undefined
    selectableInputsRequest: StandardIndicationSelectableInputsRequest
    currentAbortController: AbortController
    participantSummaryDirection: DirectionType
    dateNames: ProductTypeDateName[]
    isInitialized: boolean
    newIndicationModalName: 'new-indication'
  } {
    return {
      id: '',
      indication: undefined,
      formValue: {
        openToMarket: true,
        isVisibleToTraders: false,
        position: PositionType.Bid,
        deliveryUnit: DeliveryUnit.Month,
        productTypeIds: [],
        fuelSurchargeTypeId: undefined,
        base: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        exchange: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        deliveryPeriods: [
          {
            startDeliveryDate: undefined,
            endDeliveryDate: undefined,
            deliveryPatternComponentFormValues: [
              {
                dayPattern: undefined,
                timeRangeStart: undefined,
                timeRangeEnd: undefined,
              },
            ],
            unitPrice: '',
            volume: null,
          },
        ],
        price: undefined,
        unitPrices: [],
        request: '',
        trader: undefined,
        broker: undefined,
        status: undefined,
        organization: undefined,
        excludedEnecCurve: false,
        publicInformation: '',
        maskVolume: false,
        memo: '',
      },
      organization: undefined,
      traders: [],
      brokers: [],
      dayPatternOptions: [],
      statusOptions: [],
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      updateModalName: 'updateConfirmModal',
      deleteModalName: 'deleteConfirmModal',
      updateSubmitting: false,
      deleteSubmitting: false,
      actionDropdownIsActive: false,
      deliverySummaries: undefined,
      tradingSteps: undefined,
      openedSpreadFormTypes: [],
      selectableInputs: undefined,
      selectableInputsRequest: {},
      currentAbortController: new AbortController(),
      participantSummaryDirection: DirectionType.Row,
      dateNames: [],
      isInitialized: false,
      newIndicationModalName: 'new-indication',
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['productTypes']),
    status(): IndicationStatusTypeEnum {
      return this.indication?.status || IndicationStatusTypeEnum.Unconfirmed
    },
    selectedStatus(): IndicationStatusTypeEnum | undefined {
      return Object.values(IndicationStatusTypeEnum).find(
        type => type === this.formValue.status?.value,
      )
    },
    mode(): IndicationFormInputMode {
      switch (this.status) {
        case IndicationStatusTypeEnum.Expired:
          return IndicationFormInputMode.EXPIRED
        case IndicationStatusTypeEnum.Deleted:
          return IndicationFormInputMode.DELETED
        case IndicationStatusTypeEnum.Negotiating:
        case IndicationStatusTypeEnum.Done:
          return IndicationFormInputMode.NEGOTIATION
        case IndicationStatusTypeEnum.Unconfirmed:
        case IndicationStatusTypeEnum.Active:
        case IndicationStatusTypeEnum.Inactive:
        default:
          return IndicationFormInputMode.EDIT
      }
    },
    actionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('common.label.delete').toString(),
          eventName: 'delete-indication-click',
          disabled: this.isInvalidForDelete,
        },
        {
          label: this.$t('trading.message.copyIndication').toString(),
          eventName: 'copy-indication',
        },
      ]
    },
    isRequiredMemo(): boolean {
      return this.selectedStatus === 'inactive' && this.status !== 'inactive'
    },
    isInvalidForDelete(): boolean {
      const disabledTypes: IndicationStatusTypeEnum[] = [
        IndicationStatusTypeEnum.Negotiating,
        IndicationStatusTypeEnum.Done,
        IndicationStatusTypeEnum.Deleted,
      ]
      return disabledTypes.includes(this.status) || !this.formValue.memo
    },
    isInvalidMemo(): boolean {
      return this.isRequiredMemo && !this.formValue.memo
    },
  },
  async created() {
    this.id = this.$route.params.id as string
    this.selectableInputsRequest = {
      indicationId: this.id,
    }
    await this.initialize()
  },
  methods: {
    async initialize() {
      await Promise.all([
        this.fetchIndication(this.id) as Promise<Indication>,
        this.fetchIndicationStatusTypes() as Promise<IndicationStatusType[]>,
        this.fetchDayPatterns() as Promise<DayPattern[]>,
        this.fetchDeliverySummaries(this.id) as Promise<DeliverySummariesModel>,
        this.fetchTradingSteps(this.id) as Promise<TradingStepsModel>,
        this.fetchProductTypeDateNames() as Promise<ProductTypeDateName[]>,
        this.fetchProductTypes(),
        this.fetchBrokers(),
        this.fetchSelectableInputs(new AbortController()),
      ])
        .then(
          async ([
            indication,
            statusTypes,
            dayPatterns,
            deliverySummaries,
            tradingSteps,
            dateNames,
          ]) => {
            this.indication = indication
            this.openedSpreadFormTypes = (
              this.indication.spreadTypes || []
            ).map(s => s.type)
            this.organization = await this.fetchOrganization(
              indication.organizationId,
            )
            if (this.organization) {
              // 後続処理に依存関係はないので no await
              this.fetchTraders(this.organization.organizationId)
            }

            const currentStatusType = statusTypes.find(
              type => type.id === indication.status,
            )
            this.statusOptions = statusTypes
              .filter(type =>
                currentStatusType?.changeableStatuses.includes(type.id),
              )
              .map(type => {
                return type.toInputOption(this.userProfile.locale)
              })
            this.dayPatternOptions = dayPatterns.map(dayPattern =>
              dayPattern.toInputOption(this.userProfile.locale),
            )
            this.deliverySummaries = deliverySummaries
            this.tradingSteps = tradingSteps
            this.dateNames = dateNames

            this.uiStack = UiStack.Ideal

            this.formValue = {
              ...this.formValue,
              organization: this.organization,
              trader: indication.traderInputOption,
              broker: indication.brokerInputOption,
              status: statusTypes
                .find(type => type.id === indication.status)
                ?.toInputOption(this.userProfile.locale),
              openToMarket: indication.openToMarket,
              isVisibleToTraders: indication.isVisibleToTraders,
              excludedEnecCurve: indication.excludedEnecCurve,
              publicInformation: indication.publicInformation,
              maskVolume: indication.maskVolume,
              memo: indication.memo,
            }
            if (this.formValue.deliveryUnit === DeliveryUnit.NonStandard) {
              this.setDeliveryPeriods(indication)
            }
          },
        )
        .catch(() => {
          this.uiStack = UiStack.Error
        })
    },
    updateNonStandardIndication: indicationMapActions([
      'updateNonStandardIndication',
    ]).updateNonStandardIndication as (payload: {
      id: string
      nonStandardIndication: UpdateNonStandardIndication
    }) => Promise<Indication>,
    fetchStandardIndicationSelectableInputs: indicationMapActions([
      'fetchStandardIndicationSelectableInputs',
    ]).fetchStandardIndicationSelectableInputs as (payload: {
      request: StandardIndicationSelectableInputsRequest
      abortController: AbortController
    }) => Promise<BaseStandardIndicationSelectableInputs>,
    updateStandardIndication: indicationMapActions(['updateStandardIndication'])
      .updateStandardIndication as (payload: {
      id: string
      standardIndication: UpdateStandardIndication
    }) => Promise<Indication>,
    ...indicationMapActions([
      'fetchIndication',
      'fetchIndicationStatusTypes',
      'deleteIndication',
      'fetchDeliverySummaries',
      'fetchTradingSteps',
    ]),
    ...productMapActions([
      'fetchProductTypes',
      'fetchDayPatterns',
      'fetchProductTypeDateNames',
    ]),
    fetchOrganization: organizationsMapActions(['fetchOrganization'])
      .fetchOrganization as (id: string) => Promise<Organization>,
    fetchBrokerOrganization: organizationsMapActions([
      'fetchBrokerOrganization',
    ]).fetchBrokerOrganization as () => Promise<Organization>,
    fetchMembers: memberMapActions(['fetchMembers']).fetchMembers as (payload: {
      organizationId: string
    }) => Promise<MemberProfile[]>,
    setDeliveryPeriods(indication: Indication): void {
      const product = indication.products[0]
      this.formValue.deliveryPeriods = product.deliveryTerms.deliveryPeriods.map(
        ({ deliveryStartDate, deliveryEndDate, deliveryPattern }, i) => {
          return {
            startDeliveryDate: moment(deliveryStartDate).toDate(),
            endDeliveryDate: moment(deliveryEndDate).toDate(),
            deliveryPatternComponentFormValues: deliveryPattern.deliveryPatternComponents.map(
              ({ timeRange, dayPattern }) => {
                const halfHourRegex = /\.5$/
                const startValue = timeRange.start.toString()
                const startLabel = halfHourRegex.test(startValue)
                  ? `${startValue.replace('.5', '')}:30`
                  : `${startValue}:00`
                const endValue = timeRange.end.toString()
                const endLabel = halfHourRegex.test(endValue)
                  ? `${endValue.replace('.5', '')}:30`
                  : `${endValue}:00`
                return {
                  dayPattern: this.dayPatternOptions.find(
                    d => d.value === dayPattern.id,
                  ),
                  timeRangeStart: {
                    value: startValue,
                    label: startLabel,
                  },
                  timeRangeEnd: {
                    value: endValue,
                    label: endLabel,
                  },
                }
              },
            ),
            unitPrice: indication.unitPrices[i].toString(),
            volume:
              indication.volumes[i] === null
                ? null
                : indication.volumes[i].toString(),
          }
        },
      )
    },
    debouncedFetchSelectableInputs: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.fetchSelectableInputs(new AbortController())
    }, 500),
    async fetchSelectableInputs(abortController: AbortController) {
      this.currentAbortController.abort()
      this.currentAbortController = abortController
      await this.fetchStandardIndicationSelectableInputs({
        request: this.selectableInputsRequest,
        abortController: this.currentAbortController,
      })
        .then((selectableInputs: BaseStandardIndicationSelectableInputs) => {
          this.selectableInputs = selectableInputs
          this.formValue = {
            ...this.formValue,
            ...buildProductFormValue({
              formValue: {
                ...this.formValue,
                maskVolume: selectableInputs.isSpread
                  ? false
                  : this.formValue.maskVolume,
              },
              selectableInputs,
              isInitialize: !this.isInitialized,
            }),
          }
          this.isInitialized = true
        })
        .catch(e => {
          if (e.message === 'canceled') {
            return
          }
          throw e
        })
    },
    async onFormInput(formValue: BrokerIndicationFormProps) {
      if (!this.selectableInputs) {
        return
      }

      if (
        formValue.organization &&
        this.formValue.organization?.organizationId !==
          formValue.organization.organizationId
      ) {
        // 後続処理に依存関係はないので no await
        this.fetchTraders(formValue.organization.organizationId)
        formValue.trader = undefined
      }

      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })

      await this.fetchSelectableInputs(new AbortController())
    },
    async onTextInput(formValue: BrokerIndicationFormProps) {
      if (!this.selectableInputs) {
        return
      }

      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })

      await this.debouncedFetchSelectableInputs()
    },
    async onMemoInput(value: string) {
      this.formValue.memo = value
    },
    async fetchTraders(organizationId: string) {
      this.traders = await this.fetchMembers({ organizationId })
    },
    async fetchBrokers() {
      const { organizationId } = await this.fetchBrokerOrganization()
      this.brokers = await this.fetchMembers({ organizationId })
    },
    buildPayload(): UpdateStandardIndication | undefined {
      if (!this.formValue || !this.selectableInputs) {
        return
      }
      return buildUpdateStandardIndicationPayload({
        formValue: this.formValue,
        selectableInputs: this.selectableInputs,
        spreadTypes: this.openedSpreadFormTypes,
      })
    },
    buildNonStandardPayload(): UpdateNonStandardIndication | undefined {
      if (this.formValue.base.areaId === undefined) {
        return undefined
      }
      if (this.formValue.organization === undefined) {
        return undefined
      }
      const format = 'YYYY-MM-DD'
      const deliveryPeriods = this.formValue.deliveryPeriods
        .filter(
          (
            deliveryPeriod,
          ): deliveryPeriod is {
            startDeliveryDate: Date
            endDeliveryDate: Date
            deliveryPatternComponentFormValues: {
              [K in keyof DeliveryPatternComponentFormProps]: Exclude<
                DeliveryPatternComponentFormProps[K],
                undefined
              >
            }[]
            unitPrice: string
            volume: string
          } =>
            deliveryPeriod.startDeliveryDate !== undefined &&
            deliveryPeriod.endDeliveryDate !== undefined &&
            deliveryPeriod.deliveryPatternComponentFormValues.every(
              deliveryPatternComponentFormValue =>
                deliveryPatternComponentFormValue.dayPattern !== undefined &&
                deliveryPatternComponentFormValue.timeRangeStart !==
                  undefined &&
                deliveryPatternComponentFormValue.timeRangeEnd !== undefined,
            ),
        )
        .map(deliveryPeriod => {
          return {
            deliveryPattern: {
              deliveryPatternComponents: deliveryPeriod.deliveryPatternComponentFormValues.map(
                deliveryPatternComponentFormValue => {
                  return {
                    dayPatternId:
                      deliveryPatternComponentFormValue.dayPattern.value,
                    timeRange: {
                      start: Number(
                        deliveryPatternComponentFormValue.timeRangeStart.value,
                      ),
                      end: Number(
                        deliveryPatternComponentFormValue.timeRangeEnd.value,
                      ),
                    },
                  }
                },
              ),
            },
            deliveryStartDate: moment(deliveryPeriod.startDeliveryDate).format(
              format,
            ),
            deliveryEndDate: moment(deliveryPeriod.endDeliveryDate).format(
              format,
            ),
          }
        })
      if (deliveryPeriods.length === 0) {
        return undefined
      }
      return {
        deliveryPeriods,
        productTypeIds: this.formValue.productTypeIds,
        areaId: this.formValue.base.areaId,
        position: this.formValue.position,
        unitPrices: this.formValue.deliveryPeriods.map(deliveryPeriod =>
          Number(deliveryPeriod.unitPrice),
        ),
        volumes: this.formValue.deliveryPeriods.map(deliveryPeriod => {
          // FIXME: openapi-generator で生成されるコードで配列内の nullable が無視されるため暫定対応として any を許容している
          return (deliveryPeriod.volume === null
            ? null
            : // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Number(deliveryPeriod.volume)) as any
        }),
        fuelSurchargeTypeId: this.formValue.fuelSurchargeTypeId,
        request: this.formValue.request,
        isVisibleToTraders: this.formValue.isVisibleToTraders,
        openToMarket: this.formValue.openToMarket,
        brokerId: this.formValue.broker?.value,
        traderId: this.formValue.trader?.value,
        status: Object.values(IndicationStatusTypeEnum).find(
          type => type === this.formValue.status?.value,
        ),
        organizationId: this.formValue.organization.organizationId,
        excludedEnecCurve: this.formValue.excludedEnecCurve,
        publicInformation: this.formValue.publicInformation,
        maskVolume: this.formValue.maskVolume,
        memo: this.formValue.memo,
      }
    },
    async updateIndication(thenable: Promise<Indication>) {
      this.updateSubmitting = true
      await thenable
        .then(async () => {
          setNotification(
            this.$t('trading.message.successUpdateIndication').toString(),
          )
          this.$vfm.close(this.updateModalName)
          await this.backListPage()
        })
        .catch(() => {
          setNotification(
            this.$t('trading.message.failUpdateIndication').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.updateSubmitting = false
        })
    },
    onConfirmClick() {
      this.$vfm.open(this.updateModalName)
    },
    async backListPage() {
      await this.$router.push(`/indications`)
    },
    async onCancelClick() {
      await this.backListPage()
    },
    async onUpdateIndicationConfirm() {
      if (
        this.formValue.deliveryUnit === DeliveryUnit.Month ||
        this.formValue.deliveryUnit === DeliveryUnit.WeekOrDay
      ) {
        const standardIndication = this.buildPayload()
        if (standardIndication === undefined) {
          return
        }
        await this.updateIndication(
          this.updateStandardIndication({ id: this.id, standardIndication }),
        )
      } else if (this.formValue.deliveryUnit === DeliveryUnit.NonStandard) {
        const nonStandardIndication = this.buildNonStandardPayload()
        if (nonStandardIndication === undefined) {
          return
        }
        await this.updateIndication(
          this.updateNonStandardIndication({
            id: this.id,
            nonStandardIndication,
          }),
        )
      }
    },
    onUpdateIndicationCancel() {
      this.$vfm.close(this.updateModalName)
    },
    onDeleteIndicationClick() {
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteIndicationCancel() {
      this.$vfm.close(this.deleteModalName)
    },
    async onDeleteIndicationConfirm() {
      this.deleteSubmitting = true
      await this.deleteIndication({
        id: this.id,
        memo: this.formValue.memo ?? '',
      })
        .then(async () => {
          setNotification(
            this.$t('trading.message.successDeleteIndication').toString(),
          )
          this.$vfm.close(this.deleteModalName)
          await this.backListPage()
        })
        .catch(() => {
          setNotification(
            this.$t('trading.message.failDeleteIndication').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
    async onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = Array.from(
        new Set([...this.openedSpreadFormTypes, type]),
      )
      this.formValue.exchange.volumes[0] =
        this.formValue.exchange.volumes[0] || this.formValue.base.volumes[0]
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
    async onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = this.openedSpreadFormTypes.filter(
        t => t !== type,
      )
      switch (type) {
        case SpreadTypeTypeEnum.Area:
          this.formValue.exchange.areaId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPattern:
          this.formValue.exchange.hourTypeId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPeriod:
          this.formValue.exchange.startDeliveryYearMonthId = undefined
          this.formValue.exchange.endDeliveryYearMonthId = undefined
          this.formValue.exchange.startDeliveryDate = undefined
          this.formValue.exchange.endDeliveryDate = undefined
          break
      }
      if (this.openedSpreadFormTypes.length === 0) {
        this.formValue.exchange.volumes = []
      }
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
        traderId: this.formValue.trader?.value,
        organizationId: this.formValue.organization?.organizationId,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
    isInvalid(veeValidateInvalid: boolean): boolean {
      if (
        this.status === IndicationStatusTypeEnum.Expired ||
        this.status === IndicationStatusTypeEnum.Deleted
      ) {
        return true
      }

      if (DeliveryUnit.NonStandard === this.formValue.deliveryUnit) {
        // 非標準はselectable-inputs未対応のためvee-validateのinvalidフラグを参照する
        return this.formValue.organization === undefined || veeValidateInvalid
      }

      return (
        !this.selectableInputs?.isValid ||
        this.formValue.organization === undefined ||
        this.isInvalidMemo
      )
    },
    onCopyIndication() {
      this.$vfm.open(this.newIndicationModalName)
    },
    onCopyIndicationCancel() {
      this.$vfm.close(this.newIndicationModalName)
    },
    async onCopyIndicationConfirm() {
      this.$vfm.close(this.newIndicationModalName)
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async beforeRouteUpdate(to: any, _: any, next: any) {
    this.uiStack = UiStack.Loading
    this.id = to.params.id
    await this.initialize()
    next()
  },
})
