import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-664fff5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-list-item__ellipsis" }
const _hoisted_2 = { class: "member-list-item__ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_receive_email_statuses = _resolveComponent("receive-email-statuses")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_link = _resolveComponent("base-link")!

  return (_openBlock(), _createBlock(_component_base_link, {
    class: "member-list-item",
    path: _ctx.itemPath,
    "reset-font-style": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_box, {
        spacing: "8px 16px",
        class: "member-list-item__box"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_picture, {
            url: _ctx.member.picture,
            width: 24
          }, null, 8, ["url"]),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.member.name), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.member.mail), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.member.phoneNumber), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.member.mobilePhoneNumber), 1),
          _createVNode(_component_receive_email_statuses, { "receive-email-setting": _ctx.appSetting }, null, 8, ["receive-email-setting"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["path"]))
}