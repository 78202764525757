import { RouteRecordRaw } from 'vue-router'

import Deal from '@/views/trading/Deal.vue'
import Deals from '@/views/trading/Deals.vue'

/**
 * パス: /deals/...
 */
export const dealsRoutes: RouteRecordRaw[] = [
  {
    path: '/deals',
    name: 'Deals',
    component: Deals,
  },
  {
    path: '/deals/:id',
    name: 'Deal',
    component: Deal,
    props: route => ({ id: route.params.id }),
  },
]
