
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import SelectedOcctoMaster from '@/components/trading/SelectedOcctoMaster.vue'
import OcctoMasterSelectorModal from '@/components/trading/container/OcctoMasterSelectorModal.vue'
import { OcctoMaster } from '@/models/trading/OcctoMaster'
import { setNotification } from '@/utils/utils'

export default defineComponent({
  name: 'OcctoMasterSelector',
  components: {
    BaseModal,
    OcctoMasterSelectorModal,
    SelectedOcctoMaster,
  },
  props: {
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedOcctoMaster: {
      type: Object as PropType<OcctoMaster>,
      default: undefined,
    },
    organizationId: {
      type: String,
      required: true,
    },
    areaId: {
      type: String,
      default: undefined,
    },
    modalName: {
      type: String,
      default: 'occtoMasterSelectorModal',
    },
  },
  emits: ['select'],
  methods: {
    onSelect(occtoMaster: OcctoMaster) {
      this.$emit('select', occtoMaster)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        if (!this.areaId) {
          setNotification(this.$t('trading.message.requiredArea'), 'danger')
          return
        }
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.modalName)
    },
    hideModal() {
      this.$vfm.close(this.modalName)
    },
  },
})
