
import { defineComponent } from 'vue'

import MessageBody from '@/components/chat/MessageBody.vue'
import MessageHeader from '@/components/chat/MessageHeader.vue'
import BasePicture from '@/components/common/BasePicture.vue'

export default defineComponent({
  name: 'Message',
  components: {
    BasePicture,
    MessageBody,
    MessageHeader,
  },
  props: {
    messageId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    dateTime: {
      type: Date,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
    pictureSize: {
      type: String,
      default: 'regular',
    },
  },
  emits: ['read-message'],
  computed: {
    pictureWidth(): number {
      return this.pictureSize === 'regular' ? 32 : 24
    },
    styleVars(): object {
      return {
        '--column-gap': this.size === 'regular' ? '8px' : '10px',
      }
    },
  },
  updated: function() {
    this.$emit('read-message', this.index)
  },
})
