import { Mutations } from 'vuex-smart-module'

import { MyIndication } from '@/models/trading/MyIndication'
import { MyIndicationState } from '@/store/modules/trading/myIndication/MyIndicationState'
import { MyIndicationFilterAttribute } from '@/store/modules/trading/myIndication/interface'

export const SET_MY_INDICATIONS = 'SET_MY_INDICATIONS'
export const SET_INDICATION_ID_TO_DELETE = 'SET_INDICATION_ID_TO_DELETE'
export const SET_DELETE_SUBMITTING = 'SET_DELETE_SUBMITTING'
export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION'
export const SET_IS_FETCHING_MY_INDICATIONS = 'SET_IS_FETCHING_MY_INDICATIONS'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'

export class MyIndicationMutations extends Mutations<MyIndicationState> {
  [SET_MY_INDICATIONS](payload: { myIndications: MyIndication[] }) {
    this.state.myIndications = payload.myIndications
  }

  [SET_INDICATION_ID_TO_DELETE](payload: { indicationId: string | undefined }) {
    this.state.indicationIdToDelete = payload.indicationId
  }

  [SET_DELETE_SUBMITTING](payload: { submitting: boolean }) {
    this.state.deleteSubmitting = payload.submitting
  }

  [SET_FILTER_CONDITION](payload: {
    attribute: MyIndicationFilterAttribute
    selected: string[]
  }) {
    this.state.filterCondition[payload.attribute] = payload.selected
  }

  [SET_IS_FETCHING_MY_INDICATIONS](payload: {
    isFetchingMyIndications: boolean
  }) {
    this.state.isFetchingMyIndications = payload.isFetchingMyIndications
  }

  [SET_SELECTED_FILTERS](payload: {
    selectedFilters: MyIndicationFilterAttribute[]
  }) {
    this.state.selectedFilters = payload.selectedFilters
  }
}
