
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import DeliveryTerms from '@/components/trading/DeliveryTerms.vue'
import DeliveryUnitIcon from '@/components/trading/DeliveryUnitIcon.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import SpreadBadgeList from '@/components/trading/SpreadBadgeList.vue'
import { SpreadDeliveryTermsProps } from '@/components/trading/interface/DeliveryTerms'
import { Indication } from '@/models/trading/Indication'

export default defineComponent({
  name: 'IndicationListItem',
  components: {
    BaseBox,
    BaseLink,
    DeliveryTerms,
    DeliveryUnitIcon,
    PositionIcon,
    ProductType,
    SpreadBadgeList,
  },
  props: {
    indication: {
      type: Object as PropType<Indication>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemPath(): string {
      return `/indications/${this.indication.id}`
    },
    deliveryTerms(): SpreadDeliveryTermsProps {
      return this.indication.products[0].spreadDeliveryTerms()
    },
    indicationStatusTypeName(): string {
      return this.$t(
        `trading.label.brokingIndicationStatusType.${this.indication.status}`,
      ).toString()
    },
    productTypeNames(): string {
      return this.indication.products
        .map(product => product.productType.translation())
        .join(',')
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.indication.fuelSurchargeType?.name.translation()
    },
    hasExchangeVolume(): boolean {
      const exchangeVolume =
        this.indication.exchangeVolumes && this.indication.exchangeVolumes[0]
      return (
        exchangeVolume !== undefined &&
        this.indication.volumes[0] !== exchangeVolume
      )
    },
  },
})
