import { i18n } from '@/i18n'
import { ProductType } from '@/models/trading/ProductType'

export type SpreadPresetValue = {
  areaId: string
  exchangeAreaId: string
  hourTypeId: string
}

export type SpreadPreset = {
  text: string
  value: SpreadPresetValue
  disabled: boolean
}

export type SpreadPresetItems = SpreadPreset[][]

type Seed = {
  id: string
  text: string
}

const TOKYO_AREA_ID = '418beaa5-965c-151b-ec16-6d8e4bdd75dd'
const KANSAI_AREA_ID = '40758166-46ce-2d4b-07b1-38a53dfa298a'
const HOKKAIDO_AREA_ID = 'b226a82e-0a2b-beb1-5f7b-8770c70a25c7'
const TOHOKU_AREA_ID = 'd7202b7c-160a-4324-9dc7-98137cea5058'
const CHUBU_AREA_ID = '33940ee0-00a3-4467-be0f-b7154469e1f4'
const BASE_HOUR_TYPE_ID = '872a8bd5-d4ce-4f12-b614-7d7474b52a3a'
const MIDDLE_HOUR_TYPE_ID = '1f3b5af4-b7b4-4680-b49c-2768bd6e9b78'

const disabled = (
  productTypes: ProductType[],
  areaId: string,
  exchangeAreaId: string,
  hourTypeId: string,
): boolean => {
  const enabled = productTypes.every(
    productType =>
      (!productType.availableAreaIds?.length ||
        (productType.availableAreaIds.includes(areaId) &&
          productType.availableAreaIds.includes(exchangeAreaId))) &&
      (!productType.availableHourTypeIds?.length ||
        productType.availableHourTypeIds.includes(hourTypeId)),
  )
  return !enabled
}

const buildOption = (
  productTypes: ProductType[],
  baseArea: Seed,
  exchangeArea: Seed,
  hourType: Seed,
): SpreadPreset => {
  return {
    text: i18n
      .t('trading.label.spreadPresetItem', {
        base: baseArea.text,
        exchange: exchangeArea.text,
        hourType: hourType.text,
      })
      .toString(),
    value: {
      areaId: baseArea.id,
      exchangeAreaId: exchangeArea.id,
      hourTypeId: hourType.id,
    },
    disabled: disabled(productTypes, baseArea.id, exchangeArea.id, hourType.id),
  }
}

export function getOptions(
  selectedProductTypes: ProductType[],
): SpreadPresetItems {
  const tokyo: Seed = {
    id: TOKYO_AREA_ID,
    text: i18n.t('trading.label.spreadAreaTokyo').toString(),
  }
  const kansai: Seed = {
    id: KANSAI_AREA_ID,
    text: i18n.t('trading.label.spreadAreaKansai').toString(),
  }
  const chubu: Seed = {
    id: CHUBU_AREA_ID,
    text: i18n.t('trading.label.spreadAreaChubu').toString(),
  }
  const hokkaido: Seed = {
    id: HOKKAIDO_AREA_ID,
    text: i18n.t('trading.label.spreadAreaHokkaido').toString(),
  }
  const tohoku: Seed = {
    id: TOHOKU_AREA_ID,
    text: i18n.t('trading.label.spreadAreaTohoku').toString(),
  }
  const baseHourType: Seed = {
    id: BASE_HOUR_TYPE_ID,
    text: i18n.t('trading.label.spreadHourTypeBase').toString(),
  }
  const middleHourType: Seed = {
    id: MIDDLE_HOUR_TYPE_ID,
    text: i18n.t('trading.label.spreadHourTypeMiddle').toString(),
  }
  return [
    [
      { base: tokyo, exchange: kansai },
      { base: chubu, exchange: kansai },
    ],
    [
      { base: hokkaido, exchange: tokyo },
      { base: tohoku, exchange: tokyo },
    ],
  ].map(seeds => {
    return seeds.flatMap(seed => {
      return [
        buildOption(
          selectedProductTypes,
          seed.base,
          seed.exchange,
          baseHourType,
        ),
        buildOption(
          selectedProductTypes,
          seed.base,
          seed.exchange,
          middleHourType,
        ),
      ]
    })
  })
}
