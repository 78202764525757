import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { InformationsApi } from '@/api/generated'
import { ActiveInformation } from '@/models/common/ActiveInformation'
import { InformationGetters } from '@/store/modules/common/information/InformationGetters'
import {
  InformationMutations,
  SET_ACTIVE_INFORMATIONS,
} from '@/store/modules/common/information/InformationMutations'
import { InformationState } from '@/store/modules/common/information/InformationState'

export class InformationActions extends Actions<
  InformationState,
  InformationGetters,
  InformationMutations,
  InformationActions
> {
  async fetchActiveInformations() {
    const activeInformations = plainToClass(
      ActiveInformation,
      (await new InformationsApi(apiConfig).getActiveInformations()).data,
    )
    this.commit(SET_ACTIVE_INFORMATIONS, {
      activeInformations,
    })
  }
}
