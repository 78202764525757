import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  ClearingInformationApi,
  EexApi,
  UpdateTOCOMClearingInformation,
} from '@/api/generated'
import { ClearingInformation } from '@/models/iam/ClearingInformation'
import { EexClearingAccount } from '@/models/iam/EexClearingAccount'
import { TocomBroker } from '@/models/iam/TocomBroker'
import { TocomClearingInformationItem } from '@/models/iam/TocomClearingInformationItem'
import { EexTradingFirm } from '@/models/trading/EexTradingFirm'

export class ClearingInformationActions extends Actions<
  ClearingInformationActions
> {
  async fetchClearingInformation(
    organizationId: string,
  ): Promise<ClearingInformation> {
    return plainToClass(
      ClearingInformation,
      (
        await new ClearingInformationApi(apiConfig).getClearingInformation(
          organizationId,
        )
      ).data,
    )
  }

  async fetchTocomClearingInformation(
    organizationId: string,
  ): Promise<TocomClearingInformationItem> {
    return plainToClass(
      TocomClearingInformationItem,
      (
        await new ClearingInformationApi(apiConfig).getTOCOMClearingInformation(
          organizationId,
        )
      ).data,
    )
  }

  async fetchEexTradingFirms(): Promise<EexTradingFirm[]> {
    return plainToClass(
      EexTradingFirm,
      (await new EexApi(apiConfig).getEEXTradingFirms()).data,
    )
  }

  async fetchEexTradingFirmOfOrganization({
    organizationId,
  }: {
    organizationId: string
  }): Promise<EexTradingFirm | undefined> {
    try {
      return plainToClass(
        EexTradingFirm,
        (
          await new EexApi(apiConfig).getEEXTradingFirmOfOrganization(
            organizationId,
          )
        ).data,
      )
    } catch (e) {
      return undefined
    }
  }

  async fetchEexClearingAccounts({
    organizationId,
  }: {
    organizationId: string
  }): Promise<EexClearingAccount[]> {
    return plainToClass(
      EexClearingAccount,
      (
        await new EexApi(apiConfig).getEEXClearingAccountsOfOrganization(
          organizationId,
        )
      ).data,
    )
  }

  async fetchTocomBrokers(): Promise<TocomBroker[]> {
    return plainToClass(
      TocomBroker,
      (await new ClearingInformationApi(apiConfig).getTOCOMBrokers()).data,
    )
  }

  async updateEexTradingFirm({
    organizationId,
    eexTradingFirmCode,
  }: {
    organizationId: string
    eexTradingFirmCode: string | undefined
  }): Promise<void> {
    await new EexApi(apiConfig).updateEEXTradingFirmOfOrganization(
      organizationId,
      {
        eexTradingFirmCode: eexTradingFirmCode ?? null,
      },
    )
  }

  async updateTocomClearingInformation({
    organizationId,
    tocomClearingInformation,
  }: {
    organizationId: string
    tocomClearingInformation: UpdateTOCOMClearingInformation
  }): Promise<TocomClearingInformationItem[]> {
    return plainToClass(
      TocomClearingInformationItem,
      (
        await new ClearingInformationApi(
          apiConfig,
        ).updateTOCOMClearingInformation(
          organizationId,
          tocomClearingInformation,
        )
      ).data,
    )
  }
}
