
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import Member from '@/components/iam/Member.vue'
import MemberHeader from '@/components/iam/MemberHeader.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MemberModule } from '@/store/modules/iam/member'
import {
  SET_MAIL_ADDRESS,
  SET_ROLE_IN_ORGANIZATION,
} from '@/store/modules/iam/member/MemberMutations'
import { OrganizationProfileModule } from '@/store/modules/iam/organizationProfile'
import {
  SET_CHANGE_PICTURE_SUBMITTING,
  SET_DELETE_PICTURE_SUBMITTING,
} from '@/store/modules/iam/organizationProfile/OrganizationProfileMutations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule
const {
  mapState: memberMapState,
  mapMutations: memberMapMutations,
  mapActions: memberMapActions,
} = createNamespacedHelpers('member') as MemberModule
const {
  mapMutations: organizationProfileMapMutations,
} = createNamespacedHelpers('organizationProfile') as OrganizationProfileModule

export default defineComponent({
  name: 'EcloudMembers',
  components: {
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    Member,
    MemberHeader,
    UiStackSelector,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    inviteMemberSubmitting: boolean
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      inviteMemberSubmitting: false,
    }
  },
  computed: {
    ...userProfileMapState(['userId', 'organizationId']),
    ...memberMapState(['memberProfiles', 'mailAddress', 'roleInOrganization']),
    basePath(): string {
      return this.$route.path.replace(/\/[^/]+\/?$/, '')
    },
  },
  async created() {
    await this.loadMemberProfiles(this.$attrs.organizationId)
      .then(() => {
        this.uiStack =
          this.memberProfiles.length === 0 ? UiStack.Blank : UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...memberMapMutations([SET_MAIL_ADDRESS, SET_ROLE_IN_ORGANIZATION]),
    ...memberMapActions(['loadMemberProfiles', 'reset']),
    ...organizationProfileMapMutations([
      SET_CHANGE_PICTURE_SUBMITTING,
      SET_DELETE_PICTURE_SUBMITTING,
    ]),
    onMemberClick(memberProfile: MemberProfile) {
      this.$router.push(`${this.basePath}/members/${memberProfile.userId}`)
    },
  },
})
