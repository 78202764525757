
import { defineComponent, PropType } from 'vue'

import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import {
  DefaultBrokingFeeOfOrganizationFormProps,
  DefaultBrokingFeeOfOrganizationFormValueProps,
} from '@/components/iam/interface/DefaultBrokingFeeOfOrganizationFormProps'

export default defineComponent({
  name: 'DefaultBrokingFeeOfOrganizationForm',
  components: {
    BaseButton,
    BaseIcon,
    BaseInputText,
    BaseSingleSelect,
  },
  props: {
    productTypeInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    formValue: {
      type: Object as PropType<DefaultBrokingFeeOfOrganizationFormProps>,
      required: true,
    },
    lastUpdatedParticipantName: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      default: '',
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
    selectedProductType: InputOption | undefined
  } {
    return {
      formInputEventName: 'form-input',
      selectedProductType: undefined,
    }
  },
  computed: {
    filteredProductTypeInputOptions(): InputOption[] {
      const registeredProductTypes = this.formValue.defaultBrokingFeeOfOrganizations.map(
        fee => fee.productType.value,
      )
      return this.productTypeInputOptions.filter(
        inputOption => !registeredProductTypes.includes(inputOption.value),
      )
    },
    formValues(): DefaultBrokingFeeOfOrganizationFormValueProps[] {
      const registeredProductTypes = this.formValue.defaultBrokingFeeOfOrganizations.map(
        fee => fee.productType.value,
      )
      return this.productTypeInputOptions
        .filter(inputOption =>
          registeredProductTypes.includes(inputOption.value),
        )
        .map(inputOption => {
          const value = this.formValue.defaultBrokingFeeOfOrganizations.find(
            fee => fee.productType.value === inputOption.value,
          )
          return {
            productType: inputOption,
            brokingFee: value?.brokingFee,
          }
        })
    },
  },
  methods: {
    onChangeProductType(value: InputOption | undefined) {
      this.selectedProductType = value
    },
    onClickAdd() {
      if (!this.selectedProductType) {
        return
      }
      this.$emit(this.formInputEventName, {
        defaultBrokingFeeOfOrganizations: [
          ...this.formValue.defaultBrokingFeeOfOrganizations,
          {
            productType: this.selectedProductType,
            brokingFee: undefined,
          },
        ],
      })
      this.selectedProductType = undefined
    },
    onClickRemove(productType: InputOption) {
      this.$emit(this.formInputEventName, {
        defaultBrokingFeeOfOrganizations: this.formValue.defaultBrokingFeeOfOrganizations.filter(
          each => each.productType.value !== productType.value,
        ),
      })
    },
    onInput(value: DefaultBrokingFeeOfOrganizationFormValueProps) {
      this.$emit(this.formInputEventName, {
        defaultBrokingFeeOfOrganizations: this.formValue.defaultBrokingFeeOfOrganizations.map(
          each => {
            if (each.productType.value === value.productType.value) {
              return value
            }
            return each
          },
        ),
      })
    },
  },
})
