
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import GlobalHeader from '@/components/common/GlobalHeader.vue'
import { UserProfileModule } from '@/store/modules/iam/userProfile'

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'GlobalHeaderContainer',
  components: {
    GlobalHeader,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...userProfileMapGetters(['isOrganizationMember']),
  },
})
