import { Locale, RoleType, Services, Status } from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class UserProfileState {
  localeSubmitting = false
  pictureSubmitting = false
  deletePictureSubmitting = false
  servicesSubmitting = false

  userId = ''
  organizationId = ''
  status = undefined as Status | undefined

  picture = '' // string urlかdataurl(imgに直渡し)
  // pictureUrl = '' // TODO:

  name = ''
  nameOriginal = ''
  mail = ''
  organizationName = undefined as MultiLocaleName | undefined
  phoneNumber = ''
  mobilePhoneNumber = ''
  department = undefined as string | undefined
  departmentOriginal = undefined as string | undefined
  services = undefined as Services | undefined
  servicesOriginal = undefined as Services | undefined

  locale = undefined as Locale | undefined
  localeOriginal = undefined as Locale | undefined
  roleInOrganization = undefined as RoleType | undefined

  eexTraderCode = ''
}
