import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19761992"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-navigation" }
const _hoisted_2 = { class: "dropdown-menu" }
const _hoisted_3 = { class: "dropdown-content" }
const _hoisted_4 = ["href", "tabindex"]
const _hoisted_5 = ["disabled", "tabindex", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_dropdown = _resolveComponent("base-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['dropdown', 'is-active', { 'is-right': _ctx.isRight }])
    }, [
      _createVNode(_component_base_dropdown, {
        id: _ctx.id,
        "is-active": _ctx.isActive,
        toggle: _ctx.toggle
      }, {
        "trigger-content": _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.label
                }, [
                  (item.to)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: item.to,
                        class: _normalizeClass(["dropdown-navigation__item dropdown-item", {
                  'dropdown-navigation__item--disabled': item.disabled,
                }]),
                        tabindex: item.disabled ? -1 : 0
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "class", "tabindex"]))
                    : (item.href)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          href: item.href,
                          class: _normalizeClass(["dropdown-navigation__item dropdown-item", {
                  'dropdown-navigation__item--disabled': item.disabled,
                }]),
                          tabindex: item.disabled ? -1 : 0,
                          target: "_blank"
                        }, _toDisplayString(item.label), 11, _hoisted_4))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 2,
                          class: "dropdown-navigation__item dropdown-item",
                          type: "button",
                          disabled: item.disabled,
                          tabindex: item.disabled ? -1 : 0,
                          onClick: item.onClick
                        }, _toDisplayString(item.label), 9, _hoisted_5))
                ]))
              }), 128))
            ])
          ])
        ]),
        _: 3
      }, 8, ["id", "is-active", "toggle"])
    ], 2)
  ]))
}