
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseDropdown',
  props: {
    id: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    document.addEventListener('click', this.onOutsideClick, false)
    document.addEventListener('keydown', this.onKeydown, false)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onOutsideClick, false)
    document.removeEventListener('keydown', this.onKeydown, false)
  },
  methods: {
    onOutsideClick(e: MouseEvent) {
      if (this.isActive) {
        if (!this.$el.contains(e.target as Node)) {
          // ドロップダウンのトリガーとなるボタンとコンテンツの外をクリックしたら、ドロップダウンを閉じる
          this.toggle()
        }
      }
    },
    onKeydown(e: KeyboardEvent) {
      if (this.isActive) {
        if (e.key === 'Escape') {
          this.toggle()
        }
      }
    },
  },
})
