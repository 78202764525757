import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33217c77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broker-indication-form" }
const _hoisted_2 = { class: "broker-indication-form__input" }
const _hoisted_3 = { class: "broker-indication-form__input" }
const _hoisted_4 = { class: "broker-indication-form__spell" }
const _hoisted_5 = { class: "broker-indication-form__spell-input" }
const _hoisted_6 = {
  key: 0,
  class: "broker-indication-form__input"
}
const _hoisted_7 = { class: "broker-indication-form__input" }
const _hoisted_8 = { class: "broker-indication-form__input" }
const _hoisted_9 = { class: "broker-indication-form__input" }
const _hoisted_10 = { class: "broker-indication-form__input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_organization_selector = _resolveComponent("organization-selector")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_product_form = _resolveComponent("product-form")!
  const _component_base_input_single_checkbox = _resolveComponent("base-input-single-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { class: "broker-indication-form__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_heading, {
          type: _ctx.headerLevel,
          class: "broker-indication-form__heading"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.label.organizationInformation')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_label, {
            label: `${_ctx.$t('trading.label.organizationName')}*`
          }, null, 8, ["label"]),
          _createVNode(_component_organization_selector, {
            "selected-organization": _ctx.formValue.organization,
            "is-selectable": _ctx.isOrganizationSelectable,
            "query-params": _ctx.organizationSelectorModalQueryParams,
            onSelect: _cache[0] || (_cache[0] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, organization: event })
          )
          }, null, 8, ["selected-organization", "is-selectable", "query-params"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_base_single_select, {
            label: _ctx.$t('trading.label.traderName'),
            name: "traderName",
            options: _ctx.traderOptions,
            value: _ctx.formValue.trader,
            placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.traderName'),
            })
          ,
            mode: _ctx.traderNameInputMode,
            onInput: _ctx.onTraderInput
          }, null, 8, ["label", "options", "value", "placeholder", "mode", "onInput"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_base_box, { class: "broker-indication-form__box" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_base_input_text, {
              value: _ctx.formValue.spell,
              help: _ctx.$t('trading.message.explanationOfEasyIndicationBuild'),
              label: _ctx.$t('trading.label.easyIndicationBuild'),
              placeholder: 
              _ctx.$t('common.message.inputPlaceholder', {
                name: _ctx.$t('trading.label.indicationInformation'),
              })
            ,
              onInput: _cache[1] || (_cache[1] = value => _ctx.$emit(_ctx.spellInputEventName, value))
            }, null, 8, ["value", "help", "label", "placeholder"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.spellErrorMessages, (message) => {
              return (_openBlock(), _createElementBlock("div", {
                key: message,
                class: "broker-indication-form__spell-error help is-danger"
              }, _toDisplayString(message), 1))
            }), 128))
          ]),
          _createVNode(_component_base_button, {
            color: "primary",
            disabled: !_ctx.formValue.spell,
            onClick: _cache[2] || (_cache[2] = event => _ctx.$emit('apply-click', event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.apply')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_base_box, { class: "broker-indication-form__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_heading, {
          type: _ctx.headerLevel,
          class: "broker-indication-form__heading"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.label.productInformation')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createVNode(_component_product_form, {
          mode: _ctx.mode,
          locale: _ctx.locale,
          "position-reversed": _ctx.positionReversed,
          "selectable-inputs": _ctx.selectableInputs,
          "form-value": _ctx.productFormValue,
          "opened-spread-form-types": _ctx.openedSpreadFormTypes,
          "product-types": _ctx.productTypes,
          "day-pattern-options": _ctx.dayPatternOptions,
          "date-names": _ctx.dateNames,
          "is-broker": "",
          onFormInput: _cache[3] || (_cache[3] = 
          event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, ...event })
        ),
          onTextInput: _cache[4] || (_cache[4] = 
          event => _ctx.$emit(_ctx.textInputEventName, { ..._ctx.formValue, ...event })
        ),
          onStandardDeliveryTermsFormOpen: _ctx.onStandardDeliveryTermsFormOpen,
          onStandardDeliveryTermsFormClose: _ctx.onStandardDeliveryTermsFormClose
        }, null, 8, ["mode", "locale", "position-reversed", "selectable-inputs", "form-value", "opened-spread-form-types", "product-types", "day-pattern-options", "date-names", "onStandardDeliveryTermsFormOpen", "onStandardDeliveryTermsFormClose"])
      ]),
      _: 1
    }),
    _createVNode(_component_base_box, { class: "broker-indication-form__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_heading, {
          type: _ctx.headerLevel,
          class: "broker-indication-form__heading"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('trading.label.indicationSettings')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        (_ctx.isShownStatusInput)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_base_single_select, {
                label: _ctx.$t('trading.label.status'),
                name: "status",
                options: _ctx.statusOptions,
                value: _ctx.formValue.status,
                mode: _ctx.statusInputMode,
                placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.status'),
            })
          ,
                rules: "required",
                required: "",
                onInput: _cache[5] || (_cache[5] = 
            event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, status: event })
          )
              }, null, 8, ["label", "options", "value", "mode", "placeholder"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_base_label, {
            label: _ctx.$t('trading.label.publicSettings')
          }, null, 8, ["label"]),
          _createVNode(_component_base_input_single_checkbox, {
            label: _ctx.$t('trading.label.openToMarket'),
            name: "openToMarket",
            value: _ctx.formValue.openToMarket,
            mode: _ctx.openToMarketInputMode,
            onInput: _cache[6] || (_cache[6] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, openToMarket: event })
          )
          }, null, 8, ["label", "value", "mode"]),
          _createVNode(_component_base_input_single_checkbox, {
            label: _ctx.$t('trading.label.isVisibleToTraders'),
            name: "isVisibleToTraders",
            value: _ctx.formValue.isVisibleToTraders,
            mode: _ctx.isVisibleToTradersInputMode,
            onInput: _cache[7] || (_cache[7] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                isVisibleToTraders: event,
              })
          )
          }, null, 8, ["label", "value", "mode"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_base_single_select, {
            label: _ctx.$t('trading.label.brokerName'),
            name: "brokerName",
            options: _ctx.brokerOptions,
            value: _ctx.formValue.broker,
            placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.brokerName'),
            })
          ,
            mode: _ctx.brokerNameInputMode,
            onInput: _cache[8] || (_cache[8] = 
            event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, broker: event })
          )
          }, null, 8, ["label", "options", "value", "placeholder", "mode"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_base_label, {
            label: _ctx.$t('trading.label.enecCurveSettings'),
            help: _ctx.$t('trading.message.enecCurveSettingsHelpText')
          }, null, 8, ["label", "help"]),
          _createVNode(_component_base_input_single_checkbox, {
            label: _ctx.$t('trading.label.excludedEnecCurve'),
            name: "excludedEnecCurve",
            value: _ctx.formValue.excludedEnecCurve,
            mode: _ctx.excludeEnecCurveInputMode,
            onInput: _cache[9] || (_cache[9] = 
            event =>
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                excludedEnecCurve: event,
              })
          )
          }, null, 8, ["label", "value", "mode"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.memoInputEnabled)
            ? (_openBlock(), _createBlock(_component_base_input_text, {
                key: 0,
                name: "memo",
                value: _ctx.formValue.memo,
                label: _ctx.$t('trading.label.memo'),
                mode: _ctx.memoInputMode,
                rules: _ctx.memoRules,
                required: _ctx.isRequiredMemo,
                "force-shown-error-message": true,
                onInput: _cache[10] || (_cache[10] = value => _ctx.$emit(_ctx.memoInputEventName, value))
              }, null, 8, ["value", "label", "mode", "rules", "required"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}