import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

if (
  process.env.VUE_APP_DD_APPLICATION_ID &&
  process.env.VUE_APP_DD_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: `esquare-frontend-${process.env.VUE_APP_APPLICATION_TYPE}`,
    allowedTracingUrls: [/https:\/\/.*\.enechain\.com/],
    sampleRate: 100,
    trackInteractions: true,
    env: process.env.VUE_APP_DD_ENV,
  })
  datadogRum.startSessionReplayRecording()

  datadogLogs.init({
    clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: `esquare-frontend-${process.env.VUE_APP_APPLICATION_TYPE}`,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    sampleRate: 100,
    env: process.env.VUE_APP_DD_ENV,
  })
}
