
import { defineComponent, PropType } from 'vue'

import MyDealComponent from '@/components/trading/MyDeal.vue'
import MyDealListHeader from '@/components/trading/MyDealListHeader.vue'
import { MyDeal } from '@/models/trading/MyDeal'

export default defineComponent({
  name: 'MyDealList',
  components: {
    MyDealComponent,
    MyDealListHeader,
  },
  props: {
    myDeals: {
      type: Array as PropType<MyDeal[]>,
      default: () => [],
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
})
