import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2afd57a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-select-dropdown" }
const _hoisted_2 = { class: "dropdown-trigger" }
const _hoisted_3 = ["aria-controls"]
const _hoisted_4 = ["aria-controls"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "dropdown-content" }
const _hoisted_7 = ["value", "onChange"]
const _hoisted_8 = { class: "multi-select-dropdown__item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown", { 'is-right': _ctx.isRight, 'is-active': _ctx.isActive }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.tooltipLabel.length !== 0)
          ? (_openBlock(), _createBlock(_component_base_tooltip, {
              key: 0,
              label: _ctx.tooltipLabel
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  class: "button is-white",
                  type: "button",
                  "aria-haspopup": "true",
                  "aria-controls": `dropdown-menu-${_ctx.id}`,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickButton && _ctx.onClickButton(...args)), ["stop"]))
                }, [
                  _renderSlot(_ctx.$slots, "default", {}, () => [
                    _createVNode(_component_base_icon, {
                      class: "multi-select-dropdown__icon",
                      "icon-name": "ellipsis-h"
                    })
                  ], true)
                ], 8, _hoisted_3)
              ]),
              _: 3
            }, 8, ["label"]))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "button is-white",
              type: "button",
              "aria-haspopup": "true",
              "aria-controls": `dropdown-menu-${_ctx.id}`,
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickButton && _ctx.onClickButton(...args)), ["stop"]))
            }, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createVNode(_component_base_icon, {
                  class: "multi-select-dropdown__icon",
                  "icon-name": "ellipsis-h"
                })
              ], true)
            ], 8, _hoisted_4))
      ]),
      _createElementVNode("div", {
        id: `dropdown-menu-${_ctx.id}`,
        class: "dropdown-menu",
        role: "menu"
      }, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              class: "multi-select-dropdown__item dropdown-item",
              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values) = $event)),
                type: "checkbox",
                value: action.eventName,
                onChange: ($event: any) => (_ctx.onChange(action.eventName))
              }, null, 40, _hoisted_7), [
                [_vModelCheckbox, _ctx.values]
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(action.label), 1)
            ]))
          }), 128))
        ])
      ], 8, _hoisted_5)
    ], 2)
  ]))
}