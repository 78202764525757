import { Type } from 'class-transformer'

import {
  DeliverySummaries as IDeliverySummaries,
  DeliverySummaryWithCalendarTypeName as IDeliverySummaryWithCalendarTypeName,
} from '@/api/generated'
import { DeliverySummary } from '@/models/trading/DeliverySummary'

export class DeliverySummaryWithCalendarTypeName
  implements IDeliverySummaryWithCalendarTypeName {
  @Type(() => DeliverySummary)
  deliverySummary!: DeliverySummary

  calendarTypeName!: string
}

export class DeliverySummaries implements IDeliverySummaries {
  @Type(() => DeliverySummaryWithCalendarTypeName)
  base!: DeliverySummaryWithCalendarTypeName

  @Type(() => DeliverySummaryWithCalendarTypeName)
  exchange?: DeliverySummaryWithCalendarTypeName
}
