
import { defineComponent, PropType } from 'vue'

import BaseInputText from '@/components/common/BaseInputText.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { TradingCompanyNameFormProps } from '@/components/iam//interface/TradingCompanyNameFormProps'

export default defineComponent({
  name: 'BrokerageFeePaymentConfigurationForm',
  components: {
    BaseInputText,
  },
  props: {
    formValue: {
      type: Object as PropType<TradingCompanyNameFormProps>,
      required: true,
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
  } {
    return {
      formInputEventName: 'form-input',
    }
  },
  computed: {
    readonly(): InputMode {
      return InputMode.READONLY
    },
  },
})
