import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3cd4dfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recap-receiver-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selected_recap_receiver = _resolveComponent("selected-recap-receiver")!
  const _component_recap_receiver_selector_modal = _resolveComponent("recap-receiver-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedRecapReceiver)
      ? (_openBlock(), _createBlock(_component_selected_recap_receiver, {
          key: 0,
          "is-selectable": _ctx.isSelectable,
          "selected-recap-receiver": _ctx.selectedRecapReceiver,
          onClick: _ctx.onClick
        }, null, 8, ["is-selectable", "selected-recap-receiver", "onClick"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["recap-receiver-selector__select-button--not-selected", {
        'recap-receiver-selector__select-button--not-selected-selectable': _ctx.isSelectable,
      }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.$t('trading.message.selectRecapReceiver')), 3)),
    _createVNode(_component_base_modal, {
      title: _ctx.$t('trading.label.selectRecapReceiver'),
      name: _ctx.modalName,
      width: 800
    }, {
      default: _withCtx(() => [
        _createVNode(_component_recap_receiver_selector_modal, {
          "organization-id": _ctx.organizationId,
          onSelect: _ctx.onSelect,
          onCancel: _ctx.onCancel
        }, null, 8, ["organization-id", "onSelect", "onCancel"])
      ]),
      _: 1
    }, 8, ["title", "name"])
  ]))
}