import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import {
  SET_FILTER_CONDITION_WITH_SPREAD,
  SET_SELECTED_FILTERS_WITH_SPREAD,
} from '@/store/modules/trading/orderBook/OrderBookMutations'
import { OrderBookState } from '@/store/modules/trading/orderBook/OrderBookState'
import {
  keyOfSelectedFiltersConditionWithSpread,
  keyOfSelectedFiltersWithSpread,
  OrderBookFilterAttribute,
  OrderBookFilterCondition,
} from '@/store/modules/trading/orderBook/interface'
import { TAB_INDEXES } from '@/store/modules/trading/utils/interface'

type GetOrderBookFilterArgs = {
  areas: Area[]
  deliveryYearMonths: DeliveryYearMonth[]
  hourTypes: HourType[]
  productTypes: ProductType[]
  filterAttributes: OrderBookFilterAttribute[]
  filterCondition: OrderBookFilterCondition
}

export const getOrderBookFilter = ({
  areas,
  deliveryYearMonths,
  hourTypes,
  productTypes,
  filterAttributes,
  filterCondition,
}: GetOrderBookFilterArgs): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    const options = ((attribute: string): InputOption[] => {
      switch (attribute) {
        case 'areaIds':
          return areas.map(type => type.toInputOption())
        case 'productTypeIds':
          return productTypes.map(type => type.toInputOption())
        case 'hourTypeIds':
          return hourTypes.map(type => type.toInputOption())
        case 'startDeliveryYearMonths':
        case 'endDeliveryYearMonths':
        default:
          return deliveryYearMonths.map(type => type.toProductFiltersOption())
      }
    })(attribute)
    const value = options.filter((option: InputOption) =>
      filterCondition[attribute].includes(option.value),
    )
    return {
      attribute,
      filterMultiSelect: {
        value,
        options,
      },
      type: 'multi',
    }
  })
}

export const findFilterState = (
  index: number,
  state: OrderBookState,
): {
  state: {
    selectedFilters: () => OrderBookFilterAttribute[]
    filterCondition: () => OrderBookFilterCondition
    filterAttributes: () => OrderBookFilterAttribute[]
  }
  keyOfSelectedFilters: string
  keyOfSelectedFiltersCondition: string
  SET_SELECTED_FILTERS: typeof SET_SELECTED_FILTERS_WITH_SPREAD
  SET_FILTER_CONDITION: typeof SET_FILTER_CONDITION_WITH_SPREAD
} => {
  // OrderBook(Trading.vue)でSpread以外も表示するように変更する場合、ここを増やす
  switch (index) {
    case TAB_INDEXES.SPREAD:
      return {
        state: {
          selectedFilters: () => state.selectedFiltersWithSpread,
          filterCondition: () => state.filterConditionWithSpread,
          filterAttributes: () => state.filterAttributes,
        },
        keyOfSelectedFilters: keyOfSelectedFiltersWithSpread,
        keyOfSelectedFiltersCondition: keyOfSelectedFiltersConditionWithSpread,
        SET_SELECTED_FILTERS: SET_SELECTED_FILTERS_WITH_SPREAD,
        SET_FILTER_CONDITION: SET_FILTER_CONDITION_WITH_SPREAD,
      }
    default:
      throw new Error('Selected tab does not match tab data')
  }
}
