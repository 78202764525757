import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-profile" }
const _hoisted_2 = { class: "user-profile__tab-panel" }
const _hoisted_3 = { class: "user-profile__tab-panel" }
const _hoisted_4 = {
  key: 0,
  class: "user-profile__setting-update-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_setting = _resolveComponent("user-setting")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_eclear_setting = _resolveComponent("eclear-setting")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_horizontal_tab = _resolveComponent("horizontal-tab")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.$t('iam.label.userProfile'),
    "active-menu-item": "user-profile"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_horizontal_tab, { "tab-list": _ctx.tabList }, {
          "tabPanel-1": _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_user_setting, {
                "locale-submitting": _ctx.localeSubmitting,
                "locale-update-button-disabled": !_ctx.isValidLocale,
                "display-setting-submitting": _ctx.servicesSubmitting,
                "display-setting-update-button-disabled": !_ctx.isValidDisplaySetting,
                "is-esquare-enabled": _ctx.isEsquareEnabled,
                locale: _ctx.locale,
                "display-setting": _ctx.displaySetting,
                onLocaleInput: _ctx.onLocaleInput,
                onLocaleUpdateClick: _ctx.onLocaleUpdateClick,
                onDisplaySettingUpdateClick: _ctx.onDisplaySettingUpdateClick,
                onPositionSettingInput: _ctx.onPositionSettingInput,
                onColorSettingInput: _ctx.onColorSettingInput
              }, null, 8, ["locale-submitting", "locale-update-button-disabled", "display-setting-submitting", "display-setting-update-button-disabled", "is-esquare-enabled", "locale", "display-setting", "onLocaleInput", "onLocaleUpdateClick", "onDisplaySettingUpdateClick", "onPositionSettingInput", "onColorSettingInput"])
            ])
          ]),
          "tabPanel-2": _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_validation_form, null, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_eclear_setting, {
                    "form-value": _ctx.eclearSettingFormValue,
                    onFormInput: _ctx.onEclearSettingFormInput
                  }, {
                    default: _withCtx(() => [
                      (_ctx.eclearSettingFormValue.isEclearTrader)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_base_button, {
                              disabled: 
                      Object.keys(errors).length > 0 ||
                        _ctx.eclearKeyword === _ctx.eclearSettingFormValue.keyword
                    ,
                              loading: _ctx.eclearSettingUpdateButtonSubmitting,
                              color: "primary",
                              onClick: _ctx.onEclearSettingUpdateButtonClick
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.label.update')), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "loading", "onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["form-value", "onFormInput"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["tab-list"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}