import { Type } from 'class-transformer'

import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class EEXPostingDetail {
  organizationId!: string

  @Type(() => MultiLocaleName)
  organizationName!: MultiLocaleName

  eexTraderCode!: string
  eexTraderName!: string
  eexClearingAccountCode!: string
  clearingFirm!: string
}
