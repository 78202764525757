
import { defineComponent, PropType } from 'vue'

import BaseButton from '@/components/common/BaseButton.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { MemberProfile } from '@/models/iam/MemberProfile'

export default defineComponent({
  name: 'RecapReceiverSelector',
  components: {
    BaseButton,
    BaseSingleSelect,
  },
  props: {
    members: {
      type: Array as PropType<MemberProfile[]>,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input', 'cancel', 'select'],
  data(): {
    selectedMember: InputOption | undefined
  } {
    return {
      selectedMember: undefined,
    }
  },
  computed: {
    complete(): boolean {
      return this.selectedMember !== undefined
    },
    options(): InputOption[] {
      return this.members.map(member => ({
        value: member.userId,
        label: member.name,
      }))
    },
  },
  methods: {
    onInput(input?: InputOption) {
      this.selectedMember = input
    },
    onCancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$emit('select', this.selectedMember?.value)
    },
  },
})
