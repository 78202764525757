import { SendMediaOptions } from '@twilio/conversations'

import { DMChannel } from '@/models/chat/DMChannel'
import { Member } from '@/models/chat/Member'
import { Message } from '@/models/chat/Message'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'

export class ActiveChannel {
  channel?: OrganizationChannel | DMChannel
  messages: Message[]
  anchor?: number
  members: Member[]
  chatInputValue: string
  typingUserIds: string[]
  messageLoading: boolean
  pageSize: number
  private lastReadMessageIndexForCompare: number | undefined

  constructor({
    channel = undefined,
    messages = [],
    anchor = undefined,
    members = [],
  }: {
    channel?: OrganizationChannel | DMChannel
    messages?: Message[]
    anchor?: number
    members?: Member[]
  } = {}) {
    this.channel = channel
    this.messages = messages
    this.anchor = anchor
    this.members = members
    this.chatInputValue = ''
    this.typingUserIds = []
    this.messageLoading = false
    this.pageSize = 30
    this.lastReadMessageIndexForCompare = undefined
  }

  get channelId(): string {
    return this.channel?.channelId || ''
  }

  get getLastMessageIndex(): number {
    if (
      this.channel &&
      this.channel.conversationInstance &&
      this.channel.conversationInstance.lastMessage &&
      this.channel.conversationInstance.lastMessage.index
    ) {
      return this.channel.conversationInstance.lastMessage.index
    }
    return 0
  }

  async getMessagesFromLatest(): Promise<Message[]> {
    if (!this.channel) {
      return []
    }
    return this.channel.getMessages(this.pageSize)
  }

  async getMessagesFromAnchor(): Promise<Message[]> {
    if (!this.channel || this.anchor === 0) {
      return []
    }
    if (!this.anchor) {
      return this.channel.getMessages(this.pageSize)
    }
    return this.channel.getMessages(this.pageSize, this.anchor)
  }

  async sendMessage(
    message: string | FormData | SendMediaOptions,
  ): Promise<void> {
    if (this.channel) {
      const messageIndex = await this.channel.sendMessage(message)
      await this.updateLastConsumedMessageIndex(messageIndex)
    }
  }

  async typing(): Promise<void> {
    if (this.channel) {
      await this.channel.typing()
    }
  }

  getMessageCountsToLoad() {
    return this.anchor !== undefined
      ? Math.min(this.anchor, this.pageSize)
      : this.pageSize
  }

  async updateLastConsumedMessageIndex(index: number): Promise<void> {
    if (!this.channel || !this.channel.conversationInstance) {
      return
    }

    const lastMessageIndex = this.getLastMessageIndex
    if (
      this.channel.conversationInstance.lastReadMessageIndex !== null &&
      this.channel.conversationInstance.lastReadMessageIndex >= index
    ) {
      return
    }
    if (lastMessageIndex < index) {
      return
    }
    if (
      this.lastReadMessageIndexForCompare !== undefined &&
      this.lastReadMessageIndexForCompare >= index
    ) {
      return
    }

    this.lastReadMessageIndexForCompare = index
    await this.channel.conversationInstance.advanceLastReadMessageIndex(index)
    // advanceLastReadMessageIndexは非同期処理において現時点の最大値よりも小さいindexが渡されても
    // その値で更新してしまうことがあるため、現時点の最大値で再度更新し直す。
    if (this.lastReadMessageIndexForCompare > index) {
      await this.channel.conversationInstance.advanceLastReadMessageIndex(
        this.lastReadMessageIndexForCompare,
      )
    }
  }
}
