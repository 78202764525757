
import { defineComponent } from 'vue'

import { IndicationStatusType } from '@/api/generated'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'

export default defineComponent({
  name: 'MyIndicationStatus',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusIcon(): string {
      switch (this.status) {
        case IndicationStatusType.Active:
          return 'check-circle'
        case IndicationStatusType.Negotiating:
          return 'comments'
        case IndicationStatusType.Done:
          return 'handshake'
        case IndicationStatusType.Inactive:
          return 'stop-circle'
        case IndicationStatusType.Expired:
          return 'calendar-times'
        default:
          return 'hourglass-start'
      }
    },
    statusIconClass(): string {
      const baseClassName = 'my-indication-status'

      switch (this.status) {
        case IndicationStatusType.Active:
          return baseClassName + '--active'
        case IndicationStatusType.Negotiating:
          return baseClassName + '--negotiating'
        case IndicationStatusType.Done:
          return baseClassName + '--done'
        case IndicationStatusType.Inactive:
          return baseClassName + '--inactive'
        case IndicationStatusType.Expired:
          return baseClassName + '--expired'
        default:
          return baseClassName + '--unconfirmed'
      }
    },
    statusHelp(): string {
      switch (this.status) {
        case IndicationStatusType.Active:
          return this.$t('trading.message.statusType.active').toString()
        case IndicationStatusType.Negotiating:
          return this.$t('trading.message.statusType.negotiating').toString()
        case IndicationStatusType.Done:
          return this.$t('trading.message.statusType.done').toString()
        case IndicationStatusType.Inactive:
          return this.$t('trading.message.statusType.inactive').toString()
        case IndicationStatusType.Expired:
          return this.$t('trading.message.statusType.expired').toString()
        default:
          return this.$t('trading.message.statusType.unconfirmed').toString()
      }
    },
  },
})
