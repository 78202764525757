import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77ce73fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-book-best-price" }
const _hoisted_2 = {
  key: 0,
  class: "order-book-best-price__order-left-wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "order-book-best-price__order-left-wrapper"
}
const _hoisted_4 = {
  key: 2,
  class: "order-book-best-price__order-right-wrapper"
}
const _hoisted_5 = {
  key: 3,
  class: "order-book-best-price__order-right-wrapper"
}
const _hoisted_6 = { class: "order-book-best-price__last-deal-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_book_unit_price_and_volume = _resolveComponent("order-book-unit-price-and-volume")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.positionReversed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.bestIndicationAsk)
            ? (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
                key: 0,
                price: _ctx.bestIndicationAsk.price,
                volume: _ctx.bestIndicationAsk.volume,
                position: _ctx.bestIndicationAsk.position,
                locale: _ctx.locale,
                "color-reversed": _ctx.colorReversed,
                "mask-volume": _ctx.bestIndicationAsk.maskVolume
              }, null, 8, ["price", "volume", "position", "locale", "color-reversed", "mask-volume"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.bestIndicationBid)
            ? (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
                key: 0,
                price: _ctx.bestIndicationBid.price,
                volume: _ctx.bestIndicationBid.volume,
                position: _ctx.bestIndicationBid.position,
                locale: _ctx.locale,
                "color-reversed": _ctx.colorReversed,
                "mask-volume": _ctx.bestIndicationBid.maskVolume
              }, null, 8, ["price", "volume", "position", "locale", "color-reversed", "mask-volume"]))
            : _createCommentVNode("", true)
        ])),
    (_ctx.positionReversed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.bestIndicationBid)
            ? (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
                key: 0,
                price: _ctx.bestIndicationBid.price,
                volume: _ctx.bestIndicationBid.volume,
                position: _ctx.bestIndicationBid.position,
                locale: _ctx.locale,
                "color-reversed": _ctx.colorReversed,
                "mask-volume": _ctx.bestIndicationBid.maskVolume
              }, null, 8, ["price", "volume", "position", "locale", "color-reversed", "mask-volume"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.bestIndicationAsk)
            ? (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
                key: 0,
                price: _ctx.bestIndicationAsk.price,
                volume: _ctx.bestIndicationAsk.volume,
                position: _ctx.bestIndicationAsk.position,
                locale: _ctx.locale,
                "color-reversed": _ctx.colorReversed,
                "mask-volume": _ctx.bestIndicationAsk.maskVolume
              }, null, 8, ["price", "volume", "position", "locale", "color-reversed", "mask-volume"]))
            : _createCommentVNode("", true)
        ])),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.deal)
        ? (_openBlock(), _createBlock(_component_order_book_unit_price_and_volume, {
            key: 0,
            price: _ctx.deal.price,
            volume: _ctx.deal.volume,
            locale: _ctx.locale,
            "created-at": _ctx.deal.displayCreatedAt(),
            "mask-volume": _ctx.deal.maskVolume,
            "light-font": !!_ctx.deal.privateConfidential
          }, null, 8, ["price", "volume", "locale", "created-at", "mask-volume", "light-font"]))
        : _createCommentVNode("", true)
    ])
  ]))
}