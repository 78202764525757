import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d39710b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-dropdown" }
const _hoisted_2 = ["aria-expanded", "aria-controls"]
const _hoisted_3 = ["id", "hidden", "aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "base-dropdown__trigger",
      type: "button",
      "aria-expanded": _ctx.isActive || undefined,
      "aria-controls": `base-dropdown__panel--${_ctx.id}`,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _renderSlot(_ctx.$slots, "trigger-content", {}, undefined, true)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: `base-dropdown__panel--${_ctx.id}`,
      class: "base-dropdown__contents",
      role: "menu",
      hidden: !_ctx.isActive,
      "aria-hidden": !_ctx.isActive || undefined
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_3)
  ]))
}