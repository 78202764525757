import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81e20fbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input-single-checkbox" }
const _hoisted_2 = { class: "base-input-single-checkbox__wrapper" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["checked", "disabled"]
const _hoisted_5 = { class: "base-input-single-checkbox__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        class: "base-input-sinble-checkbox__checkbox checkbox",
        disabled: _ctx.readonly || undefined
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          class: "base-input-single-checkbox__input checkbox",
          checked: _ctx.value,
          disabled: _ctx.readonly,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40, _hoisted_4),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.label), 1)
      ], 8, _hoisted_3)
    ])
  ]))
}