import { Module } from 'vuex-smart-module'

import { NegotiationActions } from '@/store/modules/trading/negotiation/NegotiationActions'
import { NegotiationGetters } from '@/store/modules/trading/negotiation/NegotiationGetters'
import { NegotiationMutations } from '@/store/modules/trading/negotiation/NegotiationMutations'
import { NegotiationState } from '@/store/modules/trading/negotiation/NegotiationState'

export const negotiation = new Module({
  state: NegotiationState,
  actions: NegotiationActions,
  mutations: NegotiationMutations,
  getters: NegotiationGetters,
})

export type NegotiationModule = typeof negotiation
