import { Type } from 'class-transformer'

import {
  DealSummary as IDealSummary,
  DealSubType,
  SpreadType,
} from '@/api/generated'
import { i18n } from '@/i18n'
import { DealDetail } from '@/models/trading//DealDetail'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'
import { moment } from '@/utils/date'

export class DealSummary implements IDealSummary {
  id!: string

  @Type(() => Product)
  product!: Product

  negotiationId!: string

  publicNegotiationId!: string

  type!: DealSubType

  spreadTypes?: SpreadType[]

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  executedAt!: string

  @Type(() => DealDetail)
  askDealDetail!: DealDetail

  @Type(() => DealDetail)
  bidDealDetail!: DealDetail

  isPrivateConfidential!: boolean

  totalBrokingFee!: number

  price!: Price

  volumes!: number[]

  exchangeVolumes?: number[]

  displayProductTypeNames(): string {
    return this.product.productType.translation()
  }

  displayAskOrganizationName(): string {
    return this.askDealDetail.organizationName.translation()
  }

  displayAskOrganizationAbbreviation(): string {
    return (
      this.askDealDetail.organizationAbbreviation ||
      this.askDealDetail.organizationName.translation()
    )
  }

  displayBidOrganizationName(): string {
    return this.bidDealDetail.organizationName.translation()
  }

  displayBidOrganizationAbbreviation(): string {
    return (
      this.bidDealDetail.organizationAbbreviation ||
      this.bidDealDetail.organizationName.translation()
    )
  }

  displayTotalBrokingFee(): string {
    return `¥${Number(this.totalBrokingFee).toLocaleString()}`
  }

  displayExecutedAt(): string {
    return moment(this.executedAt).format(
      i18n.t('common.format.dateTime').toString(),
    )
  }

  get isSpread(): boolean {
    return this.type === DealSubType.Spread
  }
}
