import {
  SpreadDeliveryTerms as ISpreadDeliveryTerms,
  SpreadDeliveryTermsArea as Area,
  SpreadDeliveryTermsHourType as HourType,
} from '@/api/generated'

export class SpreadDeliveryTerms implements ISpreadDeliveryTerms {
  area!: Area
  deliveryPeriod!: string
  deliveryStartDate?: string
  deliveryEndDate?: string
  hourType!: HourType
  askVolume?: string
  bidVolume?: string
  lastVolume?: string
}
