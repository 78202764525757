import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { RecapReceiversApi, RequestRecapReceiver } from '@/api/generated'
import { RecapReceiver } from '@/models/iam/RecapReceiver'

export class RecapReceiverActions extends Actions<RecapReceiverActions> {
  async fetchRecapReceivers(organizationId: string): Promise<RecapReceiver[]> {
    return plainToClass(
      RecapReceiver,
      (await new RecapReceiversApi(apiConfig).getRecapReceivers(organizationId))
        .data,
    )
  }

  async fetchRecapReceiver({
    organizationId,
    recapReceiverId,
  }: {
    organizationId: string
    recapReceiverId: string
  }): Promise<RecapReceiver> {
    return plainToClass(
      RecapReceiver,
      (
        await new RecapReceiversApi(apiConfig).getRecapReceiver(
          organizationId,
          recapReceiverId,
        )
      ).data,
    )
  }

  async createRecapReceiver({
    organizationId,
    recapReceiver,
  }: {
    organizationId: string
    recapReceiver: RequestRecapReceiver
  }): Promise<RecapReceiver> {
    return plainToClass(
      RecapReceiver,
      (
        await new RecapReceiversApi(apiConfig).newRecapReceiver(
          organizationId,
          recapReceiver,
        )
      ).data,
    )
  }

  async updateRecapReceiver({
    organizationId,
    recapReceiverId,
    recapReceiver,
  }: {
    organizationId: string
    recapReceiverId: string
    recapReceiver: RequestRecapReceiver
  }): Promise<RecapReceiver> {
    return plainToClass(
      RecapReceiver,
      (
        await new RecapReceiversApi(apiConfig).updateRecapReceiver(
          organizationId,
          recapReceiverId,
          recapReceiver,
        )
      ).data,
    )
  }

  async deleteRecapReceiver({
    organizationId,
    recapReceiverId,
  }: {
    organizationId: string
    recapReceiverId: string
  }): Promise<void> {
    await new RecapReceiversApi(apiConfig).deleteRecapReceiver(
      organizationId,
      recapReceiverId,
    )
  }
}
