
import { defineComponent, PropType } from 'vue'

import { ESquareAppSetting, MemberProfile } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import ReceiveEmailStatuses from '@/components/iam/ReceiveEmailStatuses.vue'

export default defineComponent({
  name: 'MemberEmailSettingListItem',
  components: {
    BaseBox,
    BaseLink,
    BasePicture,
    ReceiveEmailStatuses,
  },
  props: {
    member: {
      type: Object as PropType<MemberProfile>,
      required: true,
    },
    parentPath: {
      type: String,
      required: true,
    },
  },
  data(): {
    itemPath: string
  } {
    return {
      itemPath: `${this.parentPath}/${this.member.userId}`,
    }
  },
  computed: {
    appSetting(): ESquareAppSetting | undefined {
      return this.member.services?.esquare.appSetting
    },
  },
})
