import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1148c672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negotiation-new" }
const _hoisted_2 = { class: "negotiation-new__form" }
const _hoisted_3 = { class: "negotiation-new__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_negotiation_indications_form = _resolveComponent("negotiation-indications-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_negotiation_confirm_modal_content = _resolveComponent("negotiation-confirm-modal-content")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.newNegotiation'),
    "active-menu-item": "trading"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failInitializeNegotiationNew')), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          ideal: _withCtx(() => [
            _createVNode(_component_validation_form, null, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_negotiation_indications_form, {
                    mode: _ctx.mode,
                    "color-reversed": 
                  _ctx.userProfile.services.esquare.userSetting.colorReversed
                ,
                    locale: _ctx.userProfile.locale,
                    "product-types": _ctx.productTypes,
                    "form-value": _ctx.formValue,
                    "eclear-organization-id": _ctx.eclearOrganizationId,
                    "eclear-product-type-id-list": _ctx.eclearProductTypeIdList,
                    onFormInput: _ctx.onFormInput,
                    onCreditSleeverAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCreditSleeverIsShown(true))),
                    onCreditSleeverRemove: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCreditSleeverIsShown(false)))
                  }, null, 8, ["mode", "color-reversed", "locale", "product-types", "form-value", "eclear-organization-id", "eclear-product-type-id-list", "onFormInput"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    disabled: 
                  Object.keys(errors).length > 0 || !_ctx.creditSleeverIsValid
                ,
                    color: "primary",
                    onClick: _ctx.onConfirmClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.modalName,
        title: _ctx.$t('trading.message.negotiationNewConfirmModalTitle'),
        "confirm-button-label": _ctx.$t('trading.label.newNegotiation'),
        loading: _ctx.submitting,
        width: 650,
        onConfirm: _ctx.onCreateNegotiationConfirm,
        onCancel: _ctx.onCreateNegotiationCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_negotiation_confirm_modal_content, {
            mode: _ctx.mode,
            product: _ctx.product,
            "form-value": _ctx.formValue,
            locale: _ctx.userProfile.locale,
            message: _ctx.$t('trading.message.negotiationNewConfirmModalMessage')
          }, null, 8, ["mode", "product", "form-value", "locale", "message"])
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}