import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { OrderBooksApi } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { OrderBook } from '@/models/trading/OrderBook'
import { OrderBookGetters } from '@/store/modules/trading/orderBook/OrderBookGetters'
import { OrderBookMutations } from '@/store/modules/trading/orderBook/OrderBookMutations'
import { OrderBookState } from '@/store/modules/trading/orderBook/OrderBookState'
import { findFilterState } from '@/store/modules/trading/orderBook/helper'
import {
  FetchOrderBooksParams,
  OrderBookFilterAttribute,
  OrderBookFilterAttributeSchema,
  OrderBookFilterConditionSchema,
} from '@/store/modules/trading/orderBook/interface'
import { TAB_INDEXES } from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'

export class OrderBookActions extends Actions<
  OrderBookState,
  OrderBookGetters,
  OrderBookMutations,
  OrderBookActions
> {
  async fetchOrderBooks({
    areaIds,
    startDeliveryYearMonths,
    endDeliveryYearMonths,
    hourTypeIds,
    productTypeIds,
    isSpread,
  }: FetchOrderBooksParams & { isSpread: boolean }): Promise<OrderBook[]> {
    return plainToClass(
      OrderBook,
      (
        await new OrderBooksApi(apiConfig).getOrderBooks(
          areaIds,
          startDeliveryYearMonths,
          endDeliveryYearMonths,
          hourTypeIds,
          productTypeIds,
          isSpread,
        )
      ).data,
    )
  }

  loadFiltersFromLocalStorage() {
    for (const index of [
      TAB_INDEXES.SPREAD,
      // OrderBook(Trading.vue)でSpread以外も表示するように変更する場合、ここを増やす
    ]) {
      const filterState = findFilterState(index, this.state)

      try {
        const selectedFiltersFromLocalStorage = localStorage.getItem(
          filterState.keyOfSelectedFilters,
        )
        const selectedFiltersConditionFromLocalStorage = localStorage.getItem(
          filterState.keyOfSelectedFiltersCondition,
        )
        if (
          selectedFiltersFromLocalStorage === null ||
          selectedFiltersConditionFromLocalStorage === null
        ) {
          throw new Error('LocalStorage is null')
        }
        const parsedSelectedFilters = OrderBookFilterAttributeSchema.parse(
          JSON.parse(selectedFiltersFromLocalStorage),
        )
        const parsedSelectedFiltersCondition = OrderBookFilterConditionSchema.parse(
          JSON.parse(selectedFiltersConditionFromLocalStorage),
        )
        this.commit(filterState.SET_SELECTED_FILTERS, {
          selectedFilters: parsedSelectedFilters,
        })

        // 開始月と終了月が古くなったものは除外する
        const availableDeliveryYearMonths = parsedSelectedFiltersCondition.startDeliveryYearMonths.filter(
          deliveryYearMonth =>
            moment(deliveryYearMonth).isSameOrAfter(moment(), 'month'),
        )
        const availableDeliveryEndMonths = parsedSelectedFiltersCondition.endDeliveryYearMonths.filter(
          deliveryEndMonth =>
            moment(deliveryEndMonth).isSameOrAfter(moment(), 'month'),
        )

        const refreshedSelectedFiltersConditionFromLocal = {
          ...parsedSelectedFiltersCondition,
          startDeliveryYearMonths: availableDeliveryYearMonths,
          endDeliveryYearMonths: availableDeliveryEndMonths,
        }

        for (const attribute of filterState.state.filterAttributes()) {
          this.commit(filterState.SET_FILTER_CONDITION, {
            attribute: attribute,
            selected: refreshedSelectedFiltersConditionFromLocal[attribute],
          })
        }
      } catch (e) {
        // LocalStorageのデータが壊れている場合は、初期化する
        localStorage.setItem(
          filterState.keyOfSelectedFilters,
          JSON.stringify([]),
        )
        localStorage.setItem(
          filterState.keyOfSelectedFiltersCondition,
          JSON.stringify(filterState.state.filterCondition()),
        )
      }
    }
  }

  addFilterConditionSelected({
    index,
    attribute,
    value,
  }: {
    index: number
    attribute: OrderBookFilterAttribute
    value: InputOption
  }) {
    const filterState = findFilterState(index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute,
      selected: [
        ...this.state.filterConditionWithSpread[attribute],
        value.value,
      ],
    })
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  removeFilterConditionSelected({
    index,
    attribute,
    value,
  }: {
    index: number
    attribute: OrderBookFilterAttribute
    value: InputOption
  }) {
    const filterState = findFilterState(index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute,
      selected: filterState.state
        .filterCondition()
        [attribute].filter(id => id !== value.value),
    })
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  removeFilterCondition(payload: {
    index: number
    attribute: OrderBookFilterAttribute
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_SELECTED_FILTERS, {
      selectedFilters: filterState.state
        .selectedFilters()
        .filter(filter => filter !== payload.attribute),
    })
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: [],
    })

    localStorage.setItem(
      filterState.keyOfSelectedFilters,
      JSON.stringify(filterState.state.selectedFilters()),
    )
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  changeFilter(payload: {
    index: number
    selectedValues: OrderBookFilterAttribute[]
    changedValue: OrderBookFilterAttribute
  }) {
    const filterState = findFilterState(payload.index, this.state)

    this.commit(filterState.SET_SELECTED_FILTERS, {
      selectedFilters: payload.selectedValues,
    })
    if (!payload.selectedValues.includes(payload.changedValue)) {
      this.commit(filterState.SET_FILTER_CONDITION, {
        attribute: payload.changedValue,
        selected: [],
      })
    }

    localStorage.setItem(
      filterState.keyOfSelectedFilters,
      JSON.stringify(filterState.state.selectedFilters()),
    )
    localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }
}
