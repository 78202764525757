import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  LocalizedReportsApi,
  GetLocalizedReportUrl200Response,
} from '@/api/generated'
import { LocalizedReport } from '@/models/reports/LocalizedReport'

export class LocalizedReportsActions extends Actions<LocalizedReportsActions> {
  async fetchLocalizedReports(
    lastReportId?: string,
  ): Promise<LocalizedReport[]> {
    return plainToClass(
      LocalizedReport,
      (
        await new LocalizedReportsApi(apiConfig).getLocalizedReports(
          lastReportId,
        )
      ).data,
    )
  }

  async fetchLocalizedReport(reportId: string): Promise<LocalizedReport> {
    return plainToClass(
      LocalizedReport,
      (await new LocalizedReportsApi(apiConfig).getLocalizedReport(reportId))
        .data,
    )
  }

  async fetchAttachmentUrl({
    reportId,
    reportContentId,
  }: {
    reportId: string
    reportContentId: string
  }): Promise<GetLocalizedReportUrl200Response> {
    return (
      await new LocalizedReportsApi(apiConfig).getLocalizedReportUrl(
        reportId,
        reportContentId,
      )
    ).data
  }
}
