// composition-apiに移行するにあたり、一時的に問題を回避するためのユーティリティ関数郡
import { Locale } from '@/api/generated'
import en from '@/locales/en.json'
import ja from '@/locales/ja.json'

const localeStrings = { ja, en }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedProperty = (obj: any, key: string): any => {
  const keys = key.split('.')
  return keys.reduce((prev, current) => {
    return prev[current]
  }, obj)
}

export const workAroundTranslate = (key: string, locale: Locale): string => {
  return getNestedProperty(localeStrings[locale], key)
}
