import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a672bf92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "old-unit-price-and-volume" }
const _hoisted_2 = { class: "old-unit-price-and-volume__wrapper" }
const _hoisted_3 = { class: "old-unit-price-and-volume__wrapper" }
const _hoisted_4 = { class: "old-unit-price-and-volume__column" }
const _hoisted_5 = {
  key: 0,
  class: "old-unit-price-and-volume__calendar-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (
          _ctx.price.type === 'basic-plus-volume' &&
            _ctx.price.basicUnitPrice !== undefined
        )
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["old-unit-price-and-volume__basic-unit-price-container", _ctx.UnitPriceAndVolumeTextClass])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["old-unit-price-and-volume__basic-unit-price", _ctx.UnitPriceAndVolumeTextClass])
            }, _toDisplayString(_ctx.formatBasicUnitPrice(_ctx.price.basicUnitPrice.unitPrice)), 3),
            _createElementVNode("div", {
              class: _normalizeClass(["old-unit-price-and-volume__basic-unit-price-unit", _ctx.UnitPriceAndVolumeTextClass])
            }, _toDisplayString(_ctx.$t('trading.label.basicUnitPriceUnit.jpyPerKw')), 3)
          ], 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["old-unit-price-and-volume__unit-price", _ctx.UnitPriceAndVolumeTextClass])
          }, _toDisplayString(_ctx.formatUnitPrice(_ctx.price.energyUnitPrices[0])), 3))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["old-unit-price-and-volume__volume", _ctx.UnitPriceAndVolumeTextClass])
        }, _toDisplayString(_ctx.formatVolume(_ctx.volume)), 3)
      ]),
      (!!_ctx.createdAt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_base_tooltip, {
              label: `${_ctx.$t('trading.label.executedDate')} ${_ctx.createdAt}`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, { "icon-name": "calendar-alt" })
              ]),
              _: 1
            }, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}