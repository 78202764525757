import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07928471"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-book-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_angle = _resolveComponent("angle")!
  const _component_broking_order_book_summary = _resolveComponent("broking-order-book-summary")!
  const _component_broking_order_book_detail = _resolveComponent("broking-order-book-detail")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "broking-order-book-item__inner",
          onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["self"]))
        }, [
          _createElementVNode("div", {
            class: "broking-order-book-item__angle",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, [
            _createVNode(_component_angle, {
              "is-opened": _ctx.orderBook.isExpanded,
              onClick: _ctx.onClick
            }, null, 8, ["is-opened", "onClick"])
          ]),
          (!_ctx.orderBook.isExpanded)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "broking-order-book-item__collapsed-wrapper",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
              }, [
                _createVNode(_component_broking_order_book_summary, {
                  "delivery-terms": _ctx.orderBook.deliveryTerms,
                  "delivery-unit": _ctx.orderBook.deliveryUnit,
                  indications: _ctx.orderBook.indications,
                  deal: _ctx.orderBook.deal,
                  "color-reversed": _ctx.colorReversed,
                  "position-reversed": _ctx.positionReversed,
                  locale: _ctx.locale
                }, null, 8, ["delivery-terms", "delivery-unit", "indications", "deal", "color-reversed", "position-reversed", "locale"])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "broking-order-book-item__expanded-wrapper",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
              }, [
                _createVNode(_component_broking_order_book_detail, {
                  "delivery-terms": _ctx.orderBook.deliveryTerms,
                  "delivery-unit": _ctx.orderBook.deliveryUnit,
                  indications: _ctx.orderBook.indications,
                  deal: _ctx.orderBook.deal,
                  "color-reversed": _ctx.colorReversed,
                  "position-reversed": _ctx.positionReversed,
                  "status-types": _ctx.statusTypes,
                  locale: _ctx.locale,
                  users: _ctx.orderBook.users,
                  "selected-indication-ids": _ctx.selectedIndicationIds,
                  "is-edit-mode": _ctx.isEditMode,
                  submitting: _ctx.submitting,
                  "target-indication-id": _ctx.targetIndicationId,
                  onRecordSelect: _ctx.onRecordSelect,
                  onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $event))),
                  onNewMessage: _ctx.onNewMessage,
                  onCopyClipboard: _ctx.onCopyClipboard,
                  onCopyIndication: _ctx.onCopyIndication,
                  onActivate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('activate', $event))),
                  onDeactivate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('deactivate', $event))),
                  onOpenToMarketChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('open-to-market-change', $event))),
                  onVisibleToTradersChange: _cache[6] || (_cache[6] = ($event: any) => (
              _ctx.$emit('visible-to-traders-change', $event)
            )),
                  onTargeting: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('targeting', $event))),
                  onUpdateIndication: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update-indication', $event)))
                }, null, 8, ["delivery-terms", "delivery-unit", "indications", "deal", "color-reversed", "position-reversed", "status-types", "locale", "users", "selected-indication-ids", "is-edit-mode", "submitting", "target-indication-id", "onRecordSelect", "onNewMessage", "onCopyClipboard", "onCopyIndication"])
              ]))
        ])
      ]),
      _: 1
    })
  ]))
}