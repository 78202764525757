
import { defineComponent } from 'vue'

import StatusIndicator from '@/components/chat/StatusIndicator.vue'
import BasePicture from '@/components/common/BasePicture.vue'

export default defineComponent({
  name: 'ChannelPicture',
  components: {
    BasePicture,
    StatusIndicator,
  },
  props: {
    picture: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'none',
    },
    isOrganization: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStatusActive(): boolean {
      return this.status === 'active'
    },
    isStatusShown(): boolean {
      return ['active', 'inactive'].includes(this.status)
    },
    pictureBorderRadiusStyle(): string {
      return this.isOrganization ? '4px' : '50%'
    },
  },
})
