import { Module } from 'vuex-smart-module'

import { MyDealActions } from '@/store/modules/trading/myDeal/MyDealActions'
import { MyDealGetters } from '@/store/modules/trading/myDeal/MyDealGetters'
import { MyDealMutations } from '@/store/modules/trading/myDeal/MyDealMutations'
import { MyDealState } from '@/store/modules/trading/myDeal/MyDealState'

export const myDeal = new Module({
  state: MyDealState,
  getters: MyDealGetters,
  mutations: MyDealMutations,
  actions: MyDealActions,
})

export type MyDealModule = typeof myDeal
