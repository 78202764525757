import { Type } from 'class-transformer'

import { PublicDeal as IPublicDeal } from '@/api/generated'
import { i18n } from '@/i18n'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'
import { moment } from '@/utils/date'

export class PublicDeal implements IPublicDeal {
  @Type(() => Product)
  product!: Product

  price!: Price
  unitPrice!: number
  volume!: number

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  createdAt!: string

  displayCreatedAt(): string {
    return moment(this.createdAt).format(i18n.t('common.format.formatDate'))
  }

  // BrokingDealと互換性を保つためのプロパティ。常にundefinedとして扱う。
  get privateConfidential(): undefined {
    return undefined
  }
}
