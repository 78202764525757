
import { defineComponent, PropType } from 'vue'

import { Locale, PositionType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import DealDeliveryPeriodList from '@/components/trading/DealDeliveryPeriodList.vue'
import EexClearingHouseSelector from '@/components/trading/EexClearingHouseSelector.vue'
import OcctoMasterSelector from '@/components/trading/OcctoMasterSelector.vue'
import RecapReceiverSelector from '@/components/trading/RecapReceiverSelector.vue'
import TocomBrokerSelector from '@/components/trading/TocomBrokerSelector.vue'
import { OrganizationRecapFormProps } from '@/components/trading/interface/OrganizationRecapFormProps'
import { Area } from '@/models/trading/Area'
import { DeliveryPeriod } from '@/models/trading/DeliveryPeriod'
import { EexClearingHouse } from '@/models/trading/EexClearingHouse'
import { OcctoMaster } from '@/models/trading/OcctoMaster'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'
import { ProductType } from '@/models/trading/ProductType'
import { RecapReceiver } from '@/models/trading/RecapReceiver'
import { TocomBroker } from '@/models/trading/TocomBroker'

export default defineComponent({
  name: 'OrganizationRecapForm',
  components: {
    BaseBox,
    BaseDataset,
    BaseIcon,
    BaseLabel,
    BaseLink,
    BaseSingleSelect,
    DealDeliveryPeriodList,
    EexClearingHouseSelector,
    OcctoMasterSelector,
    RecapReceiverSelector,
    TocomBrokerSelector,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: true,
    },
    baseOrganizationRecap: {
      type: Object as PropType<OrganizationRecap>,
      required: true,
    },
    exchangeOrganizationRecap: {
      type: Object as PropType<OrganizationRecap> | undefined,
      required: false,
      default: undefined,
    },
    namePrefix: {
      type: String,
      required: true,
    },
    areas: {
      type: Array as PropType<Area[]>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    position: {
      type: String as PropType<PositionType>,
      required: true,
    },
    formValues: {
      type: Array as PropType<OrganizationRecapFormProps[]>,
      required: true,
    },
    isSpread: {
      type: Boolean,
      default: false,
    },
    isAreaSpread: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
  } {
    return {
      formInputEventName: 'form-input',
    }
  },
  computed: {
    baseFormValue(): OrganizationRecapFormProps | undefined {
      return this.formValues.find(
        formValue => formValue.id === this.baseOrganizationRecap.id,
      )
    },
    exchangeFormValue(): OrganizationRecapFormProps | undefined {
      return this.formValues.find(
        formValue => formValue.id === this.exchangeOrganizationRecap?.id,
      )
    },
    organizationId(): string {
      return this.baseOrganizationRecap.organizationId
    },
    positionType(): PositionType {
      return this.baseOrganizationRecap.position
    },
    title(): string {
      const name =
        this.positionType === PositionType.Ask
          ? this.$t('trading.label.sellerName').toString()
          : this.$t('trading.label.buyerName').toString()

      return this.baseOrganizationRecap.isCreditSleever ? `${name} (CS)` : name
    },
    noSpreadAreaInputOptions(): InputOption[] {
      return this.areas
        .filter(area => !area.isSpread)
        .map(area => area.toInputOption())
    },
    selectedArea(): Area | undefined {
      return this.areas.find(
        area => area.areaId === this.baseFormValue?.areaInputOption?.value,
      )
    },
    selectedAreaInputOption(): InputOption | undefined {
      return this.selectedArea?.toInputOption()
    },
    inputAreaMode(): number {
      return this.selectedArea?.isSpread ? InputMode.READONLY : InputMode.INPUT
    },
    productType(): ProductType {
      return this.baseOrganizationRecap.product.productType
    },
    originAreaName(): string | undefined {
      return this.areas
        .find(area => area.id === this.baseOrganizationRecap.areaId)
        ?.name.translation()
    },
    destinationAreaName(): string | undefined {
      return this.areas
        .find(area => area.id === this.exchangeOrganizationRecap?.areaId ?? '')
        ?.name.translation()
    },
    exchangeDeliveryPeriods(): DeliveryPeriod[] {
      return (
        this.exchangeOrganizationRecap?.product.exchangeDeliveryTerms
          ?.deliveryPeriods ||
        this.exchangeOrganizationRecap?.product.deliveryTerms.deliveryPeriods ||
        []
      )
    },
    selectedBaseOcctoMaster(): OcctoMaster | undefined {
      return this.baseFormValue?.occtoMaster
    },
    selectedExchangeOcctoMaster(): OcctoMaster | undefined {
      return this.exchangeFormValue?.occtoMaster
    },
    recapReceiversPath(): string {
      return `/organizations/${this.organizationId}/recap-configuration/recap-receivers`
    },
    clearingInformationPath(): string {
      return `/organizations/${this.organizationId}/recap-configuration/clearing-information`
    },
    occtoMasterPath(): string {
      return `/organizations/${this.organizationId}/recap-configuration/occto-master`
    },
    isSwingOption(): boolean {
      return this.baseOrganizationRecap.price.type === 'basic-plus-volume'
    },
    basicUnitPrice(): string {
      return this.$t('trading.label.basicUnitPriceWithUnit', {
        basicUnitPrice: this.baseOrganizationRecap.price.basicUnitPrice
          ?.unitPrice,
        unit: this.$t('trading.label.basicUnitPriceUnit.jpyPerKw'),
      })
    },
  },
  methods: {
    onAreaInput(areaInputOption: InputOption) {
      this.$emit(
        this.formInputEventName,
        this.formValues.map(formValue => {
          return formValue.id === this.baseOrganizationRecap.id
            ? { ...formValue, areaInputOption, occtoMaster: undefined }
            : formValue
        }),
      )
    },
    onRecapReceiverSelect(recapReceiver: RecapReceiver) {
      this.$emit(
        this.formInputEventName,
        this.formValues.map(formValue => {
          return { ...formValue, recapReceiver }
        }),
      )
    },
    onBaseOcctoMasterSelect(occtoMaster: OcctoMaster) {
      const formValues = this.isAreaSpread
        ? this.formValues.map(formValue => {
            return formValue.id === this.baseOrganizationRecap.id
              ? { ...formValue, occtoMaster }
              : formValue
          })
        : this.formValues.map(formValue => {
            // エリアスプレッド以外は基準・交換に基準の値を設定する
            return { ...formValue, occtoMaster }
          })
      this.$emit(this.formInputEventName, formValues)
    },
    onExchangeOcctoMasterSelect(occtoMaster: OcctoMaster) {
      this.$emit(
        this.formInputEventName,
        this.formValues.map(formValue => {
          return formValue.id === this.exchangeOrganizationRecap?.id
            ? { ...formValue, occtoMaster }
            : formValue
        }),
      )
    },
    onEexClearingHouseSelect(eexClearingHouse: EexClearingHouse) {
      this.$emit(
        this.formInputEventName,
        this.formValues.map(formValue => {
          return { ...formValue, eexClearingHouse }
        }),
      )
    },
    onTocomBrokerSelect(tocomBroker: TocomBroker) {
      this.$emit(
        this.formInputEventName,
        this.formValues.map(formValue => {
          return { ...formValue, tocomBroker }
        }),
      )
    },
  },
})
