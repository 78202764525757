
import { defineComponent } from 'vue'

import BasePicture from '@/components/common/BasePicture.vue'

export default defineComponent({
  name: 'ChannelMessageWindowTitle',
  components: {
    BasePicture,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
