
import { defineComponent, PropType } from 'vue'

import ChatInput from '@/components/chat/ChatInput.vue'
import RecipientList from '@/components/chat/RecipientList.vue'
import { RecipientListProps } from '@/components/chat/interface/RecipientListProps'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { InputOption } from '@/components/common/interface/InputOption'

export default defineComponent({
  name: 'NewMessage',
  components: {
    BaseButton,
    BaseIcon,
    BaseInputText,
    BaseSingleSelect,
    BaseTooltip,
    ChatInput,
    RecipientList,
  },
  props: {
    organizationChannels: {
      type: Object as PropType<RecipientListProps>,
      default: () => ({
        options: [],
        checkedValues: [],
      }),
    },
    selectedLanguage: {
      type: Object as PropType<InputOption>,
      default: undefined,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    chatInputValue: {
      type: String,
      default: '',
    },
    searchInputValue: {
      type: String,
      default: '',
    },
    isSending: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'search-input',
    'language-input',
    'chat-input',
    'send',
    'cancel-button-click',
    'selected-list-change',
    'lock-click',
  ],
  methods: {
    onSearchInput(event: Event) {
      this.$emit('search-input', event)
    },
    onLanguageInput(event: Event) {
      this.$emit('language-input', event)
    },
    onChatInput(event: Event) {
      this.$emit('chat-input', event)
    },
    onSend() {
      this.$emit('send')
    },
    onCancel() {
      this.$emit('cancel-button-click')
    },
    onSelectedListChange(event: Event) {
      this.$emit('selected-list-change', event)
    },
    onLockClick() {
      this.$emit('lock-click')
    },
  },
})
