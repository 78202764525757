
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import Angle from '@/components/common/Angle.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BrokingOrderBookDetail from '@/components/trading/BrokingOrderBookDetail.vue'
import BrokingOrderBookSummary from '@/components/trading/BrokingOrderBookSummary.vue'
import { BrokingOrderBookItemProps } from '@/components/trading/interface/BrokingOrderBookItemProps'
import { generateId } from '@/components/trading/services/generateMaps'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'
import { Product } from '@/models/trading/Product'

export default defineComponent({
  name: 'BrokingOrderBookItem',
  components: {
    Angle,
    BaseBox,
    BrokingOrderBookDetail,
    BrokingOrderBookSummary,
  },
  props: {
    orderBook: {
      type: Object as PropType<BrokingOrderBookItemProps>,
      required: true,
    },
    statusTypes: {
      type: Array as PropType<IndicationStatusType[]>,
      required: true,
    },
    selectedIndicationIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    targetIndicationId: {
      type: String,
      default: undefined,
    },
  },
  emits: [
    'change',
    'activate',
    'deactivate',
    'open-to-market-change',
    'visible-to-traders-change',
    'targeting',
    'update-indication',
    'expand',
    'record-select',
    'new-message',
    'copy-clipboard',
    'copy-indication',
  ],
  computed: {
    product(): Product | undefined {
      return this.orderBook.indications
        ? this.orderBook.indications[0].product
        : this.orderBook.deal?.product
    },
  },
  methods: {
    onClick() {
      const fuelSurchargeTypeId =
        (this.orderBook.indications || [])[0]?.fuelSurchargeType?.id ||
        this.orderBook.deal?.fuelSurchargeType?.id
      const id = generateId(
        this.orderBook.deliveryTerms.id,
        fuelSurchargeTypeId,
      )
      if (id) {
        this.$emit('expand', { id })
      }
    },
    onRecordSelect(indicationId: string) {
      this.$emit('record-select', { indicationId })
    },
    onNewMessage(indicationId: string) {
      this.$emit('new-message', { indicationId })
    },
    onCopyClipboard(indicationId: string) {
      this.$emit('copy-clipboard', { indicationId })
    },
    onCopyIndication(indicationId: string) {
      this.$emit('copy-indication', { indicationId })
    },
  },
})
