import { i18n } from '@/i18n'

type NavigationLinkDataType = {
  icon: string
  label: string
} & (
  | {
      to: string
    }
  | {
      href: string
    }
)

/**
 * サイドメニューでアプリケーションタイプ共通で使われるナビゲーションデータを管理
 */
export class NavigationManager {
  get report(): NavigationLinkDataType {
    return {
      to: '/reports',
      icon: 'file-chart-line',
      label: i18n.t('common.label.reports').toString(),
    }
  }

  get chat(): NavigationLinkDataType {
    return {
      to: '/chat',
      icon: 'comment',
      label: i18n.t('common.label.chat').toString(),
    }
  }
}
