import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edb690c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trading-order-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_type = _resolveComponent("product-type")!
  const _component_order_price = _resolveComponent("order-price")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "trading-order-list__list"
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_product_type, {
            name: order.productType.name
          }, null, 8, ["name"])
        ]),
        _createVNode(_component_order_price, {
          order: order,
          "color-reversed": _ctx.colorReversed,
          "position-reversed": _ctx.positionReversed
        }, null, 8, ["order", "color-reversed", "position-reversed"])
      ]))
    }), 128))
  ]))
}