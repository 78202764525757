
import { defineComponent, PropType } from 'vue'

import { RoleType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import EnableIcon from '@/components/common/EnableIcon.vue'
import InvitationStatus from '@/components/iam/InvitationStatus.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'

export default defineComponent({
  name: 'Member',
  components: {
    BaseBox,
    BasePicture,
    EnableIcon,
    InvitationStatus,
  },
  props: {
    memberProfile: {
      type: Object as PropType<MemberProfile>,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    roleType(): string {
      return this.memberProfile.roleInOrganization === RoleType.Owner
        ? this.$t('iam.label.owner').toString()
        : this.$t('iam.label.member').toString()
    },
    enabledESquare(): boolean {
      return !!this.memberProfile.services?.esquare.appSetting.enabled
    },
    enabledECompass(): boolean {
      return !!this.memberProfile.services?.ecompass.appSetting.enabled
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
