import { Type } from 'class-transformer'

import { UpdatedIndicationInBulk as IUpdatedIndicationInBulk } from '@/api/generated/api'
import { Indication } from '@/models/trading/Indication'

export class UpdatedIndicationInBulk implements IUpdatedIndicationInBulk {
  @Type(() => Indication)
  successfulIndications!: Indication[]

  @Type(() => Indication)
  failedIndications!: Indication[]
}
