
import { defineComponent, PropType } from 'vue'

import { SpreadType } from '@/api/generated'
import BaseBadge from '@/components/common/BaseBadge.vue'

export default defineComponent({
  name: 'SpreadBadgeList',
  components: {
    BaseBadge,
  },
  props: {
    spreadTypes: {
      type: Array as PropType<SpreadType[]>,
      default: () => [],
    },
  },
})
