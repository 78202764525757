import { Module } from 'vuex-smart-module'

import { MyIndicationActions } from '@/store/modules/trading/myIndication/MyIndicationActions'
import { MyIndicationGetters } from '@/store/modules/trading/myIndication/MyIndicationGetters'
import { MyIndicationMutations } from '@/store/modules/trading/myIndication/MyIndicationMutations'
import { MyIndicationState } from '@/store/modules/trading/myIndication/MyIndicationState'

export const myIndication = new Module({
  state: MyIndicationState,
  getters: MyIndicationGetters,
  mutations: MyIndicationMutations,
  actions: MyIndicationActions,
})

export type MyIndicationModule = typeof myIndication
