import { RouteLocationNormalized } from 'vue-router'

import { RoleType } from '@/api/generated'
import router from '@/router'
import { root, store } from '@/store'

const ctx = root.context(store)
const userProfileCtx = ctx.modules.userProfile
// TODO: Name が変更されたときに検知しづらいので、こっちじゃなくて Route.meta の方に定義を持たせたい
// meta をすべてのルート定義に持たせる変更が終わったらやる
const restrictedPageNames: (string | null | undefined)[] = [
  'MyRecapReceivers',
  'MyOcctoMaster',
  'MyClearingInformation',
  'MyRecapReceiverNew',
  'MyRecapReceiver',
  'MyOcctoMasterNew',
  'MyOcctoMasterDemandDetail',
  'MyOcctoMasterGenerationDetail',
  'MyMembers',
  'MyOrganizationEmailAddresses',
  'MyMember',
  'MyOrganizationEmailAddressNew',
  'MyOrganizationEmailAddress',
]

export const memberGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: CallableFunction,
) => {
  if (to.meta?.isPublic) {
    return next()
  }

  const fn = () => {
    if (
      userProfileCtx.state.roleInOrganization === RoleType.Member &&
      restrictedPageNames.includes(to.name as string)
    ) {
      // エラーページがいるか
      if (from.path !== '/') {
        router.replace('/')
      }
    } else {
      return next()
    }
  }

  if (userProfileCtx.state.roleInOrganization !== undefined) {
    return fn()
  }
}
