import { Module } from 'vuex-smart-module'

import { ChatActions } from '@/store/modules/chat/chat/ChatActions'
import { ChatGetters } from '@/store/modules/chat/chat/ChatGetters'
import { ChatMutations } from '@/store/modules/chat/chat/ChatMutations'
import { ChatState } from '@/store/modules/chat/chat/ChatState'

export const chat = new Module({
  state: ChatState,
  getters: ChatGetters,
  mutations: ChatMutations,
  actions: ChatActions,
})

export type ChatModule = typeof chat
