import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54511eb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-input" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_input_form = _resolveComponent("chat-input-form")!
  const _component_inner_label = _resolveComponent("inner-label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chat_input_form, {
      value: _ctx.value,
      rows: _ctx.rows,
      disabled: _ctx.disabled,
      onInput: _ctx.onInput,
      onTyping: _ctx.onTyping,
      onSend: _ctx.onSend
    }, null, 8, ["value", "rows", "disabled", "onInput", "onTyping", "onSend"]),
    _createElementVNode("button", {
      class: "chat-input__send",
      type: "button",
      disabled: !_ctx.sendable || _ctx.isSending,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSend && _ctx.onSend(...args)))
    }, [
      _createVNode(_component_inner_label, { type: "button" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('chat.label.send')), 1)
        ]),
        _: 1
      })
    ], 8, _hoisted_2)
  ]))
}