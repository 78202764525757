import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08b4aed9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recap-receiver" }
const _hoisted_2 = { class: "recap-receiver__dropdown" }
const _hoisted_3 = { class: "recap-receiver__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_participant_summary = _resolveComponent("participant-summary")!
  const _component_recap_receiver_form = _resolveComponent("recap-receiver-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeRecapReceiver')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_action_dropdown, {
            id: "recap-receiver-action-dropdown",
            actions: _ctx.actionDropdownItems,
            "is-active": _ctx.actionDropdownIsActive,
            "is-right": "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
              _ctx.actionDropdownIsActive = !_ctx.actionDropdownIsActive
            ), ["stop"])),
            onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actionDropdownIsActive = false)),
            onDeleteRecapReceiverClick: _ctx.onDeleteRecapReceiverClick
          }, null, 8, ["actions", "is-active", "onDeleteRecapReceiverClick"])
        ]),
        _createVNode(_component_participant_summary, {
          class: "recap-receiver__participant-summary",
          "created-participant-name": _ctx.createdParticipantName,
          "last-updated-participant-name": _ctx.lastUpdatedParticipantName,
          "created-at": _ctx.createdAt,
          "updated-at": _ctx.updatedAt
        }, null, 8, ["created-participant-name", "last-updated-participant-name", "created-at", "updated-at"]),
        _createVNode(_component_validation_form, { class: "recap-receiver__form" }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_recap_receiver_form, {
              "form-value": _ctx.formValue,
              onFormInput: _ctx.onFormInput
            }, null, 8, ["form-value", "onFormInput"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_button, {
                class: "recap-receiver__cancel-button",
                onClick: _ctx.onCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                color: "primary",
                disabled: Object.keys(errors).length > 0,
                submitting: _ctx.updateSubmitting,
                onClick: _ctx.onUpdateClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.update')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "submitting", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["ui-stack"]),
    _createVNode(_component_confirmation_dialog, {
      name: _ctx.deleteModalName,
      title: _ctx.$t('iam.message.recapReceiverDeleteConfirmModalTitle'),
      "confirm-button-label": _ctx.$t('common.label.delete'),
      "confirm-button-color": "danger",
      loading: _ctx.deleteSubmitting,
      width: 650,
      onConfirm: _ctx.onDeleteRecapReceiverConfirm,
      onCancel: _ctx.onDeleteRecapReceiverCancel
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('iam.message.deleteRecapReceiverMessage', {
          name: _ctx.originalName,
        })), 1)
      ]),
      _: 1
    }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
  ]))
}