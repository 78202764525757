import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

const NON_STANDARD = 'nonStandard'
const STANDARD = 'standard'

export class NonStandardOptions {
  get options(): InputOption[] {
    return [
      {
        value: NON_STANDARD,
        label: i18n.t(`trading.label.${NON_STANDARD}`).toString(),
      },
      {
        value: STANDARD,
        label: i18n.t(`trading.label.${STANDARD}`).toString(),
      },
    ]
  }

  static valueOf(value?: string): boolean | undefined {
    return value ? value === NON_STANDARD : undefined
  }
}
