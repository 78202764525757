import { NegotiationFilterConditions } from '@/store/modules/trading/negotiation/interface'

export class NegotiationState {
  filterCondition: NegotiationFilterConditions = {
    startDeliveryYearMonth: undefined,
    endDeliveryYearMonth: undefined,
    area: undefined,
    hourType: undefined,
    productType: undefined,
    organizationName: undefined,
    negotiationId: undefined,
    status: undefined,
  }
}
