
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { UpdateDeal } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { DirectionType } from '@/components/common/constants/DirectionType'
import { UiStack } from '@/components/common/constants/UiStack'
import DealConfirmModalContent from '@/components/trading/DealConfirmModalContent.vue'
import DealWithOrganizationRecapForm from '@/components/trading/DealWithOrganizationRecapForm.vue'
import DeliverySummaries from '@/components/trading/DeliverySummaries.vue'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import TradingSteps from '@/components/trading/TradingSteps.vue'
import { DealFormProps } from '@/components/trading/interface/DealFormProps'
import { OrganizationRecapFormProps } from '@/components/trading/interface/OrganizationRecapFormProps'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { DeliverySummaries as DeliverySummariesModel } from '@/models/trading/DeliverySummaries'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'
import { Product } from '@/models/trading/Product'
import { TradingSteps as TradingStepsModel } from '@/models/trading/TradingSteps'
import { MemberModule } from '@/store/modules/iam/member'
import { OrganizationEmailAddressModule } from '@/store/modules/iam/organizationEmailAddress'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { DealModule } from '@/store/modules/trading/deal'
import { ProductModule } from '@/store/modules/trading/product'
import { RecapModule } from '@/store/modules/trading/recap'
import {
  RecapInfo,
  RecapCreatedOption,
} from '@/store/modules/trading/recap/interface'
import { setNotification } from '@/utils/utils'

const { mapActions: dealMapActions } = createNamespacedHelpers(
  'deal',
) as DealModule

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

const {
  mapActions: organizationEmailAddressMapActions,
} = createNamespacedHelpers(
  'organizationEmailAddress',
) as OrganizationEmailAddressModule

const { mapActions: organizationsActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

const { mapActions: recapMapActions } = createNamespacedHelpers(
  'recap',
) as RecapModule

export default defineComponent({
  name: 'Deal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    DealConfirmModalContent,
    DealWithOrganizationRecapForm,
    DeliverySummaries,
    ParticipantSummary,
    TradingSteps,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    id: string
    uiStack: UiStack
    errorType: AlertType
    dealWithOrganizationRecap: DealWithOrganizationRecap | undefined
    deliverySummaries: DeliverySummariesModel | undefined
    tradingSteps: TradingStepsModel | undefined
    formValue: DealFormProps
    updateModalName: string
    updateSubmitting: boolean
    participantSummaryDirection: DirectionType
    recapInfo: RecapInfo | undefined
    recapCreatedOptions: RecapCreatedOption
  } {
    return {
      id: '',
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      dealWithOrganizationRecap: undefined,
      deliverySummaries: undefined,
      tradingSteps: undefined,
      formValue: {
        executedAt: '',
        privateConfidential: false,
        settlementUnitPriceAreaInputOption: undefined,
        restrictions: undefined,
        remarks: undefined,
        paymentDueInformation: undefined,
        recapsOfAskOrganizationFormValues: [],
        recapsOfBidOrganizationFormValues: [],
        excludedEnecCurve: false,
        maskVolume: false,
        note: undefined,
      },
      updateModalName: 'updateConfirmModal',
      updateSubmitting: false,
      participantSummaryDirection: DirectionType.Row,
      recapInfo: undefined,
      recapCreatedOptions: {
        forceNoteProduct: false,
        forceNoteDeliveryPeriod: false,
        forceNoteUnitPrice: false,
        forceNoteVolume: false,
        forceNoteTotalContractElectricity: false,
        excludeAnnex: false,
      },
    }
  },
  computed: {
    ...productMapState(['areas']),
    ...userProfileMapGetters(['userProfile']),
    products(): Product[] {
      return this.dealWithOrganizationRecap
        ? [this.dealWithOrganizationRecap.deal.product as Product]
        : []
    },
    isSpread(): boolean {
      return !!this.dealWithOrganizationRecap?.isSpread
    },
    isSwingOption(): boolean {
      return this.dealWithOrganizationRecap?.isSwingOption ?? false
    },
  },
  async created() {
    this.id = this.$route.params.id as string
    this.initialize()
  },
  methods: {
    ...dealMapActions([
      'fetchDealWithOrganizationRecap',
      'fetchDeliverySummaries',
      'fetchTradingSteps',
      'updateDealWithOrganizationRecap',
    ]),
    ...productMapActions(['fetchAreas']),
    ...memberMapActions(['fetchMemberProfiles']),
    ...organizationsActions(['fetchOrganization']),
    ...organizationEmailAddressMapActions(['fetchOrganizationEmailAddresses']),
    ...recapMapActions(['fetchRecap', 'createRecap']),
    async initialize() {
      try {
        const token = String(await this.$auth.getTokenSilently())
        const [
          dealWithOrganizationRecap,
          deliverySummaries,
          tradingSteps,
        ] = await Promise.all([
          this.fetchDealWithOrganizationRecap(this.id) as Promise<
            DealWithOrganizationRecap
          >,
          this.fetchDeliverySummaries(this.id) as Promise<
            DeliverySummariesModel
          >,
          this.fetchTradingSteps(this.id) as Promise<TradingStepsModel>,
          this.fetchAreas(),
        ])

        this.dealWithOrganizationRecap = dealWithOrganizationRecap
        this.setFormValue(dealWithOrganizationRecap)
        this.deliverySummaries = deliverySummaries
        this.tradingSteps = tradingSteps
        this.recapInfo = (await this.fetchRecap({
          identifier: this.dealWithOrganizationRecap.deal.id as string,
          token,
        })) as RecapInfo
        this.uiStack = UiStack.Ideal
      } catch (e) {
        this.uiStack = UiStack.Error
        throw e
      }
    },
    onFormInput(formValue: DealFormProps): void {
      Object.assign(this.formValue, formValue)
    },
    enableRecap(): boolean {
      if (!this.dealWithOrganizationRecap) {
        return false
      }
      return (
        this.dealWithOrganizationRecap.deal.product.productType.isBgNoFuelSurcharge() ||
        this.dealWithOrganizationRecap.deal.product.productType.isBgWithFuelSurcharge() ||
        this.dealWithOrganizationRecap.deal.product.productType.isSwapBilateral() ||
        this.dealWithOrganizationRecap.deal.product.productType.isSwapTocom() ||
        this.dealWithOrganizationRecap.deal.product.productType.isSwapEex() ||
        this.dealWithOrganizationRecap.deal.product.productType.isCfd()
      )
    },
    onChangeRecapOptions(options: RecapCreatedOption): void {
      this.recapCreatedOptions = options
    },
    async onCreateRecap(): Promise<void> {
      if (!this.dealWithOrganizationRecap) {
        return
      }
      try {
        this.uiStack = UiStack.Loading
        const token = String(await this.$auth.getTokenSilently())

        const [
          askOrganizationMembers,
          bidOrganizationMembers,
          userOrganizationMembers,
          askOrganizationEmailAddresses,
          bidOrganizationEmailAddresses,
          userOrganizationEmailAddresses,
          askOrganization,
          bidOrganization,
        ] = await Promise.all([
          this.fetchMemberProfiles(
            this.dealWithOrganizationRecap.recapOfAskOrganization
              .organizationId,
          ),
          this.fetchMemberProfiles(
            this.dealWithOrganizationRecap.recapOfBidOrganization
              .organizationId,
          ),
          this.fetchMemberProfiles(this.userProfile.organizationId),
          this.fetchOrganizationEmailAddresses(
            this.dealWithOrganizationRecap.recapOfAskOrganization
              .organizationId,
          ),
          this.fetchOrganizationEmailAddresses(
            this.dealWithOrganizationRecap.recapOfBidOrganization
              .organizationId,
          ),
          this.fetchOrganizationEmailAddresses(this.userProfile.organizationId),
          this.fetchOrganization(
            this.dealWithOrganizationRecap.recapOfAskOrganization
              .organizationId,
          ),
          this.fetchOrganization(
            this.dealWithOrganizationRecap.recapOfBidOrganization
              .organizationId,
          ),
        ])

        this.recapInfo = (await this.createRecap({
          token,
          organizationId: this.userProfile.organizationId,
          userName: this.userProfile.name,
          askOrganizationMembers: askOrganizationMembers as MemberProfile[],
          bidOrganizationMembers: bidOrganizationMembers as MemberProfile[],
          userOrganizationMembers: userOrganizationMembers as MemberProfile[],
          askOrganization: askOrganization as Organization,
          bidOrganization: bidOrganization as Organization,
          askOrganizationEmailAddresses: askOrganizationEmailAddresses as OrganizationEmailAddress[],
          bidOrganizationEmailAddresses: bidOrganizationEmailAddresses as OrganizationEmailAddress[],
          userOrganizationEmailAddresses: userOrganizationEmailAddresses as OrganizationEmailAddress[],
          dealWithOrganizationRecap: this
            .dealWithOrganizationRecap as DealWithOrganizationRecap,
          deliverySummaries: this.deliverySummaries as DeliverySummariesModel,
          recapCreatedOptions: this.recapCreatedOptions,
        })) as RecapInfo

        window.open(
          `${process.env.VUE_APP_RECAP_OPERATOR_URL}/recap/${this.dealWithOrganizationRecap?.deal?.id}`,
          '_blank',
        )
      } catch (e) {
        setNotification(
          this.$t('trading.message.failCreateRecap').toString(),
          'danger',
        )
        this.uiStack = UiStack.Error
        throw e
      } finally {
        this.uiStack = UiStack.Ideal
      }
    },
    async onConfirmRecap(): Promise<void> {
      window.open(
        `${process.env.VUE_APP_RECAP_OPERATOR_URL}/recap/${this.dealWithOrganizationRecap?.deal?.id}`,
        '_blank',
      )
    },
    setFormValue(dealWithOrganizationRecap: DealWithOrganizationRecap): void {
      const recapsOfAskOrganizationFormValues = dealWithOrganizationRecap.recapOfExchangeAskOrganization
        ? [
            this.convertOrganizationRecapFormProps(
              dealWithOrganizationRecap.recapOfAskOrganization,
            ),
            this.convertOrganizationRecapFormProps(
              dealWithOrganizationRecap.recapOfExchangeAskOrganization,
            ),
          ]
        : [
            this.convertOrganizationRecapFormProps(
              dealWithOrganizationRecap.recapOfAskOrganization,
            ),
          ]
      const recapsOfBidOrganizationFormValues = dealWithOrganizationRecap.recapOfExchangeBidOrganization
        ? [
            this.convertOrganizationRecapFormProps(
              dealWithOrganizationRecap.recapOfBidOrganization,
            ),
            this.convertOrganizationRecapFormProps(
              dealWithOrganizationRecap.recapOfExchangeBidOrganization,
            ),
          ]
        : [
            this.convertOrganizationRecapFormProps(
              dealWithOrganizationRecap.recapOfBidOrganization,
            ),
          ]
      this.formValue = {
        executedAt: dealWithOrganizationRecap.deal.executedAt,
        privateConfidential: dealWithOrganizationRecap.deal.privateConfidential,
        settlementUnitPriceAreaInputOption: dealWithOrganizationRecap.contractCondition.settlementUnitPriceAreaInputOption(
          this.areas,
        ),
        restrictions: dealWithOrganizationRecap.contractCondition.restrictions,
        remarks: dealWithOrganizationRecap.contractCondition.remarks,
        paymentDueInformation:
          dealWithOrganizationRecap.contractCondition.paymentDueInformation,
        recapsOfAskOrganizationFormValues,
        recapsOfBidOrganizationFormValues,
        excludedEnecCurve: dealWithOrganizationRecap.deal.excludedEnecCurve,
        maskVolume: dealWithOrganizationRecap.deal.maskVolume,
        note: dealWithOrganizationRecap.contractCondition.note,
      }
    },
    convertOrganizationRecapFormProps(
      organizationRecap: OrganizationRecap,
    ): OrganizationRecapFormProps {
      return {
        id: organizationRecap.id,

        // エリアが選択可能な場合にペイロードとして利用する
        areaInputOption: organizationRecap.areaInputOption(this.areas), // その他の場合はエリア名を活性状態で表示

        // エリアが選択不可 (エリアスプレッドの場合) にペイロードとして利用する
        areaId: organizationRecap.areaId,
        recapReceiver: organizationRecap.recapReceiver,
        occtoMaster: organizationRecap.occtoMaster,
        eexClearingHouse: organizationRecap.eexClearingHouse,
        tocomBroker: organizationRecap.tocomBroker,
      }
    },
    onConfirmClick(): void {
      this.$vfm.open(this.updateModalName)
    },
    async backListPage(): Promise<void> {
      await this.$router.push(`/deals`)
    },
    async onCancelClick(): Promise<void> {
      await this.backListPage()
    },
    onUpdateDealCancel(): void {
      this.$vfm.close(this.updateModalName)
    },
    buildPayload(): UpdateDeal | undefined {
      const payload: UpdateDeal = {
        deal: {
          executedAt: this.formValue.executedAt,
          privateConfidential: this.formValue.privateConfidential,
          excludedEnecCurve: this.formValue.excludedEnecCurve,
          maskVolume: this.formValue.maskVolume,
        },
        contractCondition: {
          restrictions: this.formValue.restrictions,
          remarks: this.formValue.remarks,
          paymentDueInformation: this.formValue.paymentDueInformation,
          settlementUnitPriceAreaId: this.formValue
            .settlementUnitPriceAreaInputOption?.value,
          note: this.formValue.note,
        },
        recapsOfAskOrganization: this.formValue.recapsOfAskOrganizationFormValues.map(
          formValue => {
            return {
              id: formValue.id,
              areaId: this.isSpread
                ? formValue.areaId
                : formValue.areaInputOption?.value,
              recapReceiver: formValue.recapReceiver,
              eexClearingHouse: formValue.eexClearingHouse,
              tocomBroker: formValue.tocomBroker,
              occtoMaster: formValue.occtoMaster,
            }
          },
        ),
        recapsOfBidOrganization: this.formValue.recapsOfBidOrganizationFormValues.map(
          formValue => {
            return {
              id: formValue.id,
              areaId: this.isSpread
                ? formValue.areaId
                : formValue.areaInputOption?.value,
              recapReceiver: formValue.recapReceiver,
              eexClearingHouse: formValue.eexClearingHouse,
              tocomBroker: formValue.tocomBroker,
              occtoMaster: formValue.occtoMaster,
            }
          },
        ),
      }
      return payload
    },
    async onUpdateDealConfirm(): Promise<void> {
      const payload = this.buildPayload()
      if (!payload) {
        return
      }
      this.updateSubmitting = true
      await this.updateDealWithOrganizationRecap({
        id: this.id,
        updateDeal: payload,
      })
        .then(async () => {
          setNotification(
            this.$t('trading.message.successUpdateDeal').toString(),
          )
          this.$vfm.close(this.updateModalName)
          await this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failUpdateDeal').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.updateSubmitting = false
        })
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async beforeRouteUpdate(to: any, _: any, next: any) {
    this.uiStack = UiStack.Loading
    this.id = to.params.id
    await this.initialize()
    next()
  },
})
