import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00357c02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking" }
const _hoisted_2 = { class: "broking__tab" }
const _hoisted_3 = {
  key: 0,
  class: "broking__header"
}
const _hoisted_4 = { class: "broking__select-buttons" }
const _hoisted_5 = { class: "broking__select-all" }
const _hoisted_6 = {
  key: 0,
  class: "broking__command-buttons"
}
const _hoisted_7 = {
  key: 0,
  class: "broking__selected-new-message"
}
const _hoisted_8 = { class: "broking__header__icon" }
const _hoisted_9 = {
  key: 1,
  class: "broking__selected-copy"
}
const _hoisted_10 = {
  key: 2,
  class: "broking__command-buttons-item"
}
const _hoisted_11 = { class: "broking__header__icon" }
const _hoisted_12 = {
  key: 3,
  class: "broking__command-buttons-item"
}
const _hoisted_13 = { class: "broking__header__icon" }
const _hoisted_14 = { class: "broking__selected-count" }
const _hoisted_15 = { class: "broking__filter-and-buttons" }
const _hoisted_16 = { class: "broking__filter" }
const _hoisted_17 = { class: "broking__buttons" }
const _hoisted_18 = { class: "broking__download-button" }
const _hoisted_19 = { class: "broking__download-icon" }
const _hoisted_20 = { class: "broking__download-text" }
const _hoisted_21 = {
  key: 1,
  class: "broking__blank-order"
}
const _hoisted_22 = { class: "broking__blank-order_icon" }
const _hoisted_23 = {
  key: 1,
  class: "broking__blank-order"
}
const _hoisted_24 = { class: "broking__blank-order_icon" }
const _hoisted_25 = { key: 2 }
const _hoisted_26 = {
  key: 3,
  class: "broking__developing-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fuel_surcharge_type_tab = _resolveComponent("fuel-surcharge-type-tab")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_copy_to_clipboard_icon = _resolveComponent("copy-to-clipboard-icon")!
  const _component_broking_indication_status_changer = _resolveComponent("broking-indication-status-changer")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_product_filter = _resolveComponent("product-filter")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_broking_order_book_list = _resolveComponent("broking-order-book-list")!
  const _component_broking_order_book_spread_list = _resolveComponent("broking-order-book-spread-list")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_new_message = _resolveComponent("new-message")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_broker_indication_new = _resolveComponent("broker-indication-new")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('common.label.broking'),
    "active-menu-item": "broking",
    "layout-mode": _ctx.layoutMode
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_fuel_surcharge_type_tab, {
            "current-tab-index": _ctx.activeTabIndex,
            onTabActive: _ctx.onTabActive
          }, null, 8, ["current-tab-index", "onTabActive"])
        ]),
        (_ctx.isBgActiveTabIndex || _ctx.isSpreadActiveTabIndex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: "broking__select-all-indications",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectAllIndicationsClick && _ctx.onSelectAllIndicationsClick(...args)))
                  }, _toDisplayString(_ctx.$t('trading.message.selectAll')), 1),
                  (_ctx.selectedIndicationIds.length > 0)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "broking__clear-selected-indications",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClearSelectedIndicationsClick && _ctx.onClearSelectedIndicationsClick(...args)))
                      }, _toDisplayString(_ctx.$t('trading.message.clearAll')), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.selectedIndicationIds.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (!_ctx.isSpreadActiveTabIndex)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_base_tooltip, {
                            label: _ctx.$t('trading.message.broadcastIndication')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_base_icon, {
                                  "icon-name": "comment",
                                  onClick: _ctx.onNewMessageBulk
                                }, null, 8, ["onClick"])
                              ])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isSpreadActiveTabIndex)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_copy_to_clipboard_icon, { onCopyClipboard: _ctx.onCopyClipboardBulk }, null, 8, ["onCopyClipboard"])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_base_tooltip, {
                      label: _ctx.$t('trading.label.updateStatus')
                    }, {
                      default: _withCtx(() => [
                        (_ctx.enabledChangingStatusOfSelectedIndications)
                          ? (_openBlock(), _createBlock(_component_broking_indication_status_changer, {
                              key: 0,
                              id: "status-in-bulk",
                              class: "broking__command-buttons-item",
                              status: _ctx.statusOfSelectedIndications,
                              "status-types": _ctx.statusTypes,
                              "is-bulked": true,
                              "is-submitting": _ctx.isStatusSubmitting,
                              onDispatchDirectEvent: _ctx.directDispatchEvent,
                              onActivate: _ctx.onInBulkActivate,
                              onDeactivate: _ctx.onInBulkDeactivate
                            }, null, 8, ["status", "status-types", "is-submitting", "onDispatchDirectEvent", "onActivate", "onDeactivate"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    (!_ctx.hasIndicationOfNegotiating && !_ctx.isIncludedEclear)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_action_dropdown, {
                            id: "open-to-market-in-bulk",
                            actions: _ctx.actions,
                            "is-active": _ctx.isActionDropdownOfOpenToMarketInBulkActive,
                            "is-button": false,
                            onClickOutside: _cache[3] || (_cache[3] = ($event: any) => (
                _ctx.isActionDropdownOfOpenToMarketInBulkActive = false
              )),
                            onOpen: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onOpenToMarketInBulkChange(true))),
                            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onOpenToMarketInBulkChange(false)))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass({
                  'broking__bulk-icon--submitting': _ctx.isOpenToMarketSubmitting,
                }),
                                onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onOpenToMarketInBulkClick && _ctx.onOpenToMarketInBulkClick(...args)), ["stop"]))
                              }, [
                                _createVNode(_component_base_tooltip, {
                                  label: _ctx.$t('trading.label.openToMarketStatusInBulk')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_11, [
                                      _createVNode(_component_base_icon, { "icon-name": "exchange-alt" })
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["label"])
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["actions", "is-active"])
                        ]))
                      : _createCommentVNode("", true),
                    (
              !_ctx.hasIndicationOfNegotiating &&
                !_ctx.isIncludedEclearAndNotDailyPowerMarket
            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_action_dropdown, {
                            id: "visible-to-traders-in-bulk",
                            actions: _ctx.actions,
                            "is-active": _ctx.isActionDropdownOfVisibleToTradersInBulkActive,
                            "is-button": false,
                            onClickOutside: _cache[7] || (_cache[7] = ($event: any) => (
                _ctx.isActionDropdownOfVisibleToTradersInBulkActive = false
              )),
                            onOpen: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onVisibleToTradersInBulkChange(true))),
                            onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onVisibleToTradersInBulkChange(false)))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass({
                  'broking__icon--submitting': _ctx.isVisibleToTradersSubmitting,
                }),
                                onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onVisibleToTradersInBulkClick && _ctx.onVisibleToTradersInBulkClick(...args)), ["stop"]))
                              }, [
                                _createVNode(_component_base_tooltip, {
                                  label: _ctx.$t('trading.label.isVisibleToTradersStatusInBulk')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createVNode(_component_base_icon, { "icon-name": "history" })
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["label"])
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["actions", "is-active"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.selectedIndicationIds.length) + " " + _toDisplayString(_ctx.$t('trading.message.indicationsSelected')), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  (_ctx.isBgActiveTabIndex || _ctx.isSpreadActiveTabIndex)
                    ? (_openBlock(), _createBlock(_component_product_filter, {
                        key: 0,
                        class: "broking__product-filter",
                        filters: _ctx.productFilters,
                        actions: _ctx.filterActions,
                        "is-action-dropdown-active": _ctx.isFilterActionDropdownActive,
                        "selected-filters": _ctx.selectedProductFilters,
                        onInput: _ctx.onFilterItemInput,
                        onSelect: _ctx.onFilterItemSelect,
                        onRemove: _ctx.onFilterItemRemove,
                        onFilterRemove: _ctx.onFilterRemove,
                        onClickButton: _ctx.onClickFilterDropdown,
                        onClickOutside: _ctx.onClickFilterOutside,
                        onChangeList: _ctx.onChangeFilters
                      }, null, 8, ["filters", "actions", "is-action-dropdown-active", "selected-filters", "onInput", "onSelect", "onRemove", "onFilterRemove", "onClickButton", "onClickOutside", "onChangeList"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_action_dropdown, {
                    id: "download",
                    actions: _ctx.downloadActions,
                    "is-active": _ctx.isDownloadActionDropdownActive,
                    "is-button": false,
                    onClickOutside: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isDownloadActionDropdownActive = false)),
                    onOrderCsvDownloadClick: _cache[11] || (_cache[11] = ($event: any) => (
                _ctx.downloadOrderCsvData(_ctx.brokingIndications, true)
              )),
                    onRecentDealCsvDownloadClick: _ctx.onRecentDealCsvDownloadClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_base_button, {
                        onClick: _withModifiers(_ctx.onDownloadClick, ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_base_icon, { "icon-name": "cloud-download" })
                            ]),
                            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('trading.message.downloadIndicationAndDealInformation')), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["actions", "is-active", "onRecentDealCsvDownloadClick"])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, {
              type: "grayscale",
              "is-relative": ""
            })
          ]),
          ideal: _withCtx(() => [
            (_ctx.isBgActiveTabIndex)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.orderBookIndications.length > 0)
                    ? (_openBlock(), _createBlock(_component_broking_order_book_list, {
                        key: 0,
                        indications: _ctx.orderBookIndications,
                        "selected-indication-ids": _ctx.selectedIndicationIds,
                        deals: _ctx.orderBookDeals,
                        users: _ctx.users,
                        "product-type-filter-condition": _ctx.productTypeFilterCondition,
                        "status-types": _ctx.statusTypes,
                        locale: _ctx.userProfile.locale,
                        "color-reversed": _ctx.services.esquare.userSetting.colorReversed,
                        "position-reversed": 
                  _ctx.services.esquare.userSetting.positionReversed
                ,
                        "with-fuel-surcharge": _ctx.withFuelSurcharge,
                        "is-edit-mode": _ctx.isEditMode,
                        submitting: _ctx.indicationSubmitting,
                        "target-indication-id": _ctx.targetIndicationId,
                        onModeChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isEditMode = !_ctx.isEditMode)),
                        onRecordSelect: _ctx.onRecordSelect,
                        onNewMessage: _ctx.onNewMessage,
                        onCopyClipboard: _ctx.onCopyClipboard,
                        onCopyIndication: _ctx.onCopyIndication,
                        onActivate: _ctx.onActivate,
                        onDeactivate: _ctx.onDeactivate,
                        onOpenToMarketChange: _ctx.onOpenToMarketChange,
                        onVisibleToTradersChange: _ctx.onVisibleToTradersChange,
                        onChange: _ctx.onChange,
                        onTargeting: _ctx.onTargeting,
                        onUpdateIndication: _ctx.onIndicationUpdate
                      }, null, 8, ["indications", "selected-indication-ids", "deals", "users", "product-type-filter-condition", "status-types", "locale", "color-reversed", "position-reversed", "with-fuel-surcharge", "is-edit-mode", "submitting", "target-indication-id", "onRecordSelect", "onNewMessage", "onCopyClipboard", "onCopyIndication", "onActivate", "onDeactivate", "onOpenToMarketChange", "onVisibleToTradersChange", "onChange", "onTargeting", "onUpdateIndication"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_base_icon, {
                            "icon-name": "list",
                            size: "5x"
                          })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.message.notFoundOrder')), 1)
                      ]))
                ], 64))
              : (_ctx.isSpreadActiveTabIndex)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.spreadBrokingOrderBooks.length > 0)
                      ? (_openBlock(), _createBlock(_component_broking_order_book_spread_list, {
                          key: 0,
                          "broking-order-books": _ctx.spreadBrokingOrderBooks,
                          "product-type-filter-condition": _ctx.productTypeFilterCondition,
                          "color-reversed": _ctx.services.esquare.userSetting.colorReversed,
                          "position-reversed": 
                  _ctx.services.esquare.userSetting.positionReversed
                ,
                          "selected-indication-ids": _ctx.selectedIndicationIds,
                          deals: _ctx.orderBookDeals,
                          onRecordSelect: _ctx.onRecordSelect,
                          onCopyIndication: _ctx.onCopyIndication
                        }, null, 8, ["broking-order-books", "product-type-filter-condition", "color-reversed", "position-reversed", "selected-indication-ids", "deals", "onRecordSelect", "onCopyIndication"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_base_icon, {
                              "icon-name": "list",
                              size: "5x"
                            })
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.message.notFoundOrder')), 1)
                        ]))
                  ], 64))
                : (_ctx.isEtenderActiveTabIndex)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25))
                  : (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.$t('common.label.developing')), 1))
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.alertType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.failFetch', {
                name: _ctx.$t('common.label.indications'),
              })), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ]),
      _createVNode(_component_base_modal, {
        name: _ctx.newMessageModalName,
        title: _ctx.$t('chat.message.newMessageModalTitle'),
        onClosed: _ctx.resetChatInput
      }, {
        default: _withCtx(() => [
          _createVNode(_component_new_message, {
            "organization-channels": _ctx.recipientList,
            "search-input-value": _ctx.recipientSearchInput,
            "chat-input-value": _ctx.displayChatInputValue,
            locked: _ctx.locked,
            "selected-language": _ctx.recipientSelectedLanguage,
            onCancelButtonClick: _ctx.onCancelButtonClick,
            onSelectedListChange: _ctx.onSelectedListChange,
            onSearchInput: _ctx.onRecipientSearchInput,
            onChatInput: _ctx.onNewChatInput,
            onLanguageInput: _ctx.onRecipientSelectedLanguage,
            onLockClick: _ctx.onLockClick,
            onSend: _ctx.onSendMultiLocaleNewMessage
          }, null, 8, ["organization-channels", "search-input-value", "chat-input-value", "locked", "selected-language", "onCancelButtonClick", "onSelectedListChange", "onSearchInput", "onChatInput", "onLanguageInput", "onLockClick", "onSend"])
        ]),
        _: 1
      }, 8, ["name", "title", "onClosed"]),
      _createVNode(_component_base_modal, {
        name: _ctx.newIndicationModalName,
        title: _ctx.$t('trading.label.newIndication'),
        width: 1096
      }, {
        default: _withCtx(() => [
          _createVNode(_component_broker_indication_new, {
            "copy-from-indication-id": _ctx.copyFromIndicationId,
            onConfirm: _ctx.onCopyIndicationConfirm,
            onCancel: _ctx.onCopyIndicationCancel
          }, null, 8, ["copy-from-indication-id", "onConfirm", "onCancel"])
        ]),
        _: 1
      }, 8, ["name", "title"])
    ]),
    _: 1
  }, 8, ["title", "layout-mode"]))
}