import { Mutations } from 'vuex-smart-module'

import { BrokingIndication as IBrokingIndication } from '@/api/generated'
import { User } from '@/models/iam/User'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'
import { BrokingState } from '@/store/modules/trading/broking/BrokingState'
import { BrokingFilterAttribute } from '@/store/modules/trading/broking/interface'

export const SET_BROKING_INDICATIONS = 'SET_BROKING_INDICATIONS'
export const SET_BROKING_DEALS = 'SET_BROKING_DEALS'
export const SET_BROKING_ORDER_BOOKS = 'SET_ORDER_BOOKS'
export const SET_SELECTED_INDICATION_IDS = 'SET_SELECTED_INDICATION_IDS'
export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION'
export const SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE =
  'SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE'
export const SET_FILTER_CONDITION_WITH_SPREAD =
  'SET_FILTER_CONDITION_WITH_SPREAD'
export const SET_USERS = 'SET_USERS'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'
export const SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE =
  'SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE'
export const SET_SELECTED_FILTERS_WITH_SPREAD =
  'SET_SELECTED_FILTERS_WITH_SPREAD'
export const UPDATE_BROKING_INDICATION_BY_ID = 'UPDATE_BROKING_INDICATION_BY_ID'

export class BrokingMutations extends Mutations<BrokingState> {
  [SET_BROKING_INDICATIONS](payload: {
    brokingIndications: BrokingIndication[]
  }) {
    this.state.brokingIndications = payload.brokingIndications
  }

  [SET_BROKING_DEALS](payload: { brokingDeals: BrokingDeal[] }) {
    this.state.brokingDeals = payload.brokingDeals
  }

  [SET_BROKING_ORDER_BOOKS](payload: {
    brokingOrderBooks: BrokingOrderBook[]
  }) {
    this.state.brokingOrderBooks = payload.brokingOrderBooks
  }

  [SET_SELECTED_INDICATION_IDS](payload: { indicationIds: string[] }) {
    this.state.selectedIndicationIds = payload.indicationIds
  }

  [SET_FILTER_CONDITION](payload: {
    attribute: BrokingFilterAttribute
    selected: string[]
  }) {
    this.state.filterCondition[payload.attribute] = payload.selected
  }

  [SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE](payload: {
    attribute: BrokingFilterAttribute
    selected: string[]
  }) {
    this.state.filterConditionWithFuelSurcharge[payload.attribute] =
      payload.selected
  }

  [SET_FILTER_CONDITION_WITH_SPREAD](payload: {
    attribute: BrokingFilterAttribute
    selected: string[]
  }) {
    this.state.filterConditionWithSpread[payload.attribute] = payload.selected
  }

  [SET_USERS](payload: { users: User[] }) {
    this.state.users = payload.users
  }

  [SET_SELECTED_FILTERS](payload: {
    selectedFilters: BrokingFilterAttribute[]
  }) {
    this.state.selectedFilters = payload.selectedFilters
  }

  [SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE](payload: {
    selectedFilters: BrokingFilterAttribute[]
  }) {
    this.state.selectedFiltersWithFuelSurcharge = payload.selectedFilters
  }

  [SET_SELECTED_FILTERS_WITH_SPREAD](payload: {
    selectedFilters: BrokingFilterAttribute[]
  }) {
    this.state.selectedFiltersWithSpread = payload.selectedFilters
  }

  [UPDATE_BROKING_INDICATION_BY_ID](payload: {
    indicationId: string
    partial: Pick<
      Partial<IBrokingIndication>,
      | 'status'
      | 'openToMarket'
      | 'isVisibleToTraders'
      | 'price'
      | 'unitPrice'
      | 'volume'
      | 'updatedAt'
      | 'lastUpdatedParticipantId'
    >
  }) {
    this.state.brokingIndications = this.state.brokingIndications.map(
      brokingIndication => {
        if (brokingIndication.indicationId === payload.indicationId) {
          Object.entries(payload.partial).forEach(([k, v]) => {
            if (v !== undefined) {
              Object.assign(brokingIndication, { [k]: v })
            }
          })
        }
        return brokingIndication
      },
    )
  }
}
