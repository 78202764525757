import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field-unit-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_input_text, {
      label: _ctx.label,
      name: _ctx.name,
      value: _ctx.value,
      "value-type": "number",
      mode: _ctx.mode,
      required: true,
      help: _ctx.isShownLabel ? _ctx.$t('trading.message.unitPriceHelpText') : undefined,
      rules: `required|numeric|scale:4|between:${_ctx.underRule},999.999`,
      placeholder: 
        _ctx.$t('common.message.inputPlaceholder', {
          name: _ctx.$t('trading.label.unitPriceShort'),
        })
      ,
      onEdit: _ctx.onEdit,
      onInput: _ctx.onInput
    }, null, 8, ["label", "name", "value", "mode", "help", "rules", "placeholder", "onEdit", "onInput"])
  ]))
}