import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ff25d25"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal" }
const _hoisted_2 = { class: "deal__trading-steps" }
const _hoisted_3 = { class: "deal__summary" }
const _hoisted_4 = { class: "deal__form" }
const _hoisted_5 = { class: "deal__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_trading_steps = _resolveComponent("trading-steps")!
  const _component_delivery_summaries = _resolveComponent("delivery-summaries")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_participant_summary = _resolveComponent("participant-summary")!
  const _component_deal_with_organization_recap_form = _resolveComponent("deal-with-organization-recap-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_deal_confirm_modal_content = _resolveComponent("deal-confirm-modal-content")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.deal'),
    "active-menu-item": "trading"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failInitializeDeal')), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          ideal: _withCtx(() => [
            _createVNode(_component_validation_form, null, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_trading_steps, {
                    "trading-steps": _ctx.tradingSteps,
                    "color-reversed": 
                  _ctx.userProfile.services.esquare.userSetting.colorReversed
                
                  }, null, 8, ["trading-steps", "color-reversed"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_base_box, { class: "deal__summary-box" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_delivery_summaries, {
                        class: "deal__delivery-summary",
                        "delivery-summaries": _ctx.deliverySummaries,
                        products: _ctx.products,
                        "fuel-surcharge-type": 
                    _ctx.dealWithOrganizationRecap.deal.fuelSurchargeType
                  ,
                        "is-swing-option": _ctx.isSwingOption
                      }, null, 8, ["delivery-summaries", "products", "fuel-surcharge-type", "is-swing-option"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_participant_summary, {
                    class: "deal__participant-summary",
                    "created-participant-name": 
                  _ctx.dealWithOrganizationRecap.createdParticipantName
                ,
                    "last-updated-participant-name": 
                  _ctx.dealWithOrganizationRecap.lastUpdatedParticipantName
                ,
                    "created-at": _ctx.dealWithOrganizationRecap.createdAt,
                    "updated-at": _ctx.dealWithOrganizationRecap.updatedAt,
                    direction: _ctx.participantSummaryDirection
                  }, null, 8, ["created-participant-name", "last-updated-participant-name", "created-at", "updated-at", "direction"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_deal_with_organization_recap_form, {
                    "deal-with-organization-recap": _ctx.dealWithOrganizationRecap,
                    areas: _ctx.areas,
                    locale: _ctx.userProfile.locale,
                    "form-value": _ctx.formValue,
                    "enable-recap": _ctx.enableRecap(),
                    "recap-info": _ctx.recapInfo,
                    "recap-created-options": _ctx.recapCreatedOptions,
                    onFormInput: _ctx.onFormInput,
                    onCreateRecap: _ctx.onCreateRecap,
                    onConfirmRecap: _ctx.onConfirmRecap,
                    onRecreateRecap: _ctx.onCreateRecap,
                    onChangeRecapOptions: _ctx.onChangeRecapOptions
                  }, null, 8, ["deal-with-organization-recap", "areas", "locale", "form-value", "enable-recap", "recap-info", "recap-created-options", "onFormInput", "onCreateRecap", "onConfirmRecap", "onRecreateRecap", "onChangeRecapOptions"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_base_button, {
                      disabled: Object.keys(errors).length > 0,
                      color: "primary",
                      onClick: _ctx.onConfirmClick
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.updateModalName,
        title: _ctx.$t('trading.message.dealUpdateConfirmModalTitle'),
        "confirm-button-label": _ctx.$t('common.label.update'),
        "confirm-button-color": "primary",
        loading: _ctx.updateSubmitting,
        width: 1096,
        onConfirm: _ctx.onUpdateDealConfirm,
        onCancel: _ctx.onUpdateDealCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_deal_confirm_modal_content, {
            "form-value": _ctx.formValue,
            "deal-with-organization-recap": _ctx.dealWithOrganizationRecap,
            message: _ctx.$t('trading.message.dealUpdateConfirmModalMessage'),
            areas: _ctx.areas,
            locale: _ctx.userProfile.locale
          }, null, 8, ["form-value", "deal-with-organization-recap", "message", "areas", "locale"])
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}