import { Module } from 'vuex-smart-module'

import { InformationActions } from '@/store/modules/common/information/InformationActions'
import { InformationGetters } from '@/store/modules/common/information/InformationGetters'
import { InformationMutations } from '@/store/modules/common/information/InformationMutations'
import { InformationState } from '@/store/modules/common/information/InformationState'

export const information = new Module({
  state: InformationState,
  getters: InformationGetters,
  mutations: InformationMutations,
  actions: InformationActions,
})

export type InformationModule = typeof information
