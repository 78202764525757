
import { defineComponent, PropType } from 'vue'

import { EexClearingHouse } from '@/models/trading/EexClearingHouse'

export default defineComponent({
  name: 'SelectedEexClearingHouse',
  props: {
    isSelectable: {
      type: Boolean,
      required: true,
    },
    selectedEexClearingHouse: {
      type: Object as PropType<EexClearingHouse>,
      required: true,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      if (this.isSelectable) {
        this.$emit('click')
      }
    },
  },
})
