import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-529efae0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brokerage-fee-payment-configuration-form" }
const _hoisted_2 = { class: "brokerage-fee-payment-configuration-form__heading" }
const _hoisted_3 = { class: "brokerage-fee-payment-configuration-form__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_single_select = _resolveComponent("base-single-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('iam.label.brokerageFeePaymentTiming')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('iam.label.spotTrading'),
        name: "spotTrading",
        value: _ctx.formValue.spotTrading,
        options: _ctx.timingInputOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('iam.label.spotTrading'),
          })
        ,
        onInput: _cache[0] || (_cache[0] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, spotTrading: event })
        )
      }, null, 8, ["label", "value", "options", "placeholder"]),
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('iam.label.derivativeTrading'),
        name: "derivativeTrading",
        value: _ctx.formValue.derivativeTrading,
        options: _ctx.timingInputOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('iam.label.derivativeTrading'),
          })
        ,
        onInput: _cache[1] || (_cache[1] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              derivativeTrading: event,
            })
        )
      }, null, 8, ["label", "value", "options", "placeholder"])
    ])
  ]))
}