import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

const TRUE = 'true'
const FALSE = 'false'

export class IsDailyPowerMarketOptions {
  get options(): InputOption[] {
    return [
      {
        value: TRUE,
        label: i18n.t(`trading.label.onlyDailyPowerMarket`).toString(),
      },
      {
        value: FALSE,
        label: i18n.t(`trading.label.exceptDailyPowerMarket`).toString(),
      },
    ]
  }

  static valueOf(value?: string): boolean | undefined {
    return value ? value === FALSE : undefined
  }
}
