import { Mutations } from 'vuex-smart-module'

import { ActiveInformation } from '@/models/common/ActiveInformation'
import { InformationState } from '@/store/modules/common/information/InformationState'

export const SET_ACTIVE_INFORMATIONS = 'SET_ACTIVE_INFORMATIONS'

export class InformationMutations extends Mutations<InformationState> {
  [SET_ACTIVE_INFORMATIONS](payload: {
    activeInformations: ActiveInformation[]
  }) {
    this.state.activeInformations = payload.activeInformations
  }
}
