import { User } from '@/models/iam/User'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'
import {
  BrokingFilterAttribute,
  BrokingFilterCondition,
} from '@/store/modules/trading/broking/interface'

export class BrokingState {
  brokingIndications = [] as BrokingIndication[]
  brokingDeals = [] as BrokingDeal[]
  brokingOrderBooks = [] as BrokingOrderBook[]
  selectedIndicationIds = [] as string[]
  users = [] as User[]

  filterCondition = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
    status: [],
    organizationName: [],
    createdParticipantName: [],
    openToMarketOfFilter: [],
    isDailyPowerMarket: [],
  } as BrokingFilterCondition

  filterConditionWithFuelSurcharge = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
    status: [],
    organizationName: [],
    createdParticipantName: [],
    openToMarketOfFilter: [],
    isDailyPowerMarket: [],
  } as BrokingFilterCondition

  filterConditionWithSpread = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
    status: [],
    organizationName: [],
    createdParticipantName: [],
    openToMarketOfFilter: [],
    isDailyPowerMarket: [],
  } as BrokingFilterCondition

  filterAttributes: BrokingFilterAttribute[] = [
    'area',
    'startDeliveryYearMonth',
    'endDeliveryYearMonth',
    'hourType',
    'productType',
    'status',
    'organizationName',
    'createdParticipantName',
    'openToMarketOfFilter',
    'isDailyPowerMarket',
  ]

  filterAttributesWithFuelSurcharge: BrokingFilterAttribute[] = [
    'area',
    'startDeliveryYearMonth',
    'endDeliveryYearMonth',
    'hourType',
    'productType',
    'status',
    'organizationName',
    'createdParticipantName',
    'openToMarketOfFilter',
    'isDailyPowerMarket',
  ]

  filterAttributesWithSpread: BrokingFilterAttribute[] = [
    'area',
    'startDeliveryYearMonth',
    'endDeliveryYearMonth',
    'hourType',
    'productType',
    'status',
    'organizationName',
    'createdParticipantName',
    'openToMarketOfFilter',
  ]

  selectedFilters = [] as BrokingFilterAttribute[]
  selectedFiltersWithFuelSurcharge = [] as BrokingFilterAttribute[]
  selectedFiltersWithSpread = [] as BrokingFilterAttribute[]
}
