import { Type } from 'class-transformer'

import { DeliveryPeriod as IDeliveryPeriod } from '@/api/generated'
import { i18n } from '@/i18n'
import { DeliveryPattern } from '@/models/trading/DeliveryPattern'
import { moment } from '@/utils/date'

export class DeliveryPeriod implements IDeliveryPeriod {
  @Type(() => DeliveryPattern)
  deliveryPattern!: DeliveryPattern

  deliveryStartDate!: string
  deliveryEndDate!: string
  deliveryPeriod!: string
  dateName?: string

  displayDeliveryStartDate(): string {
    return moment(this.deliveryStartDate).format(
      i18n.t('common.format.shortDate').toString(),
    )
  }

  displayDeliveryEndDate(): string {
    return moment(this.deliveryEndDate).format(
      i18n.t('common.format.shortDate').toString(),
    )
  }
}
