import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { PublicDealsApi, PublicIndicationsApi } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { PublicIndicationGetters } from '@/store/modules/trading/publicIndication/PublicIndicationGetters'
import {
  PublicIndicationMutations,
  SET_PUBLIC_DEALS,
  SET_PUBLIC_INDICATIONS,
} from '@/store/modules/trading/publicIndication/PublicIndicationMutations'
import { PublicIndicationState } from '@/store/modules/trading/publicIndication/PublicIndicationState'
import { findFilterState } from '@/store/modules/trading/publicIndication/helper'
import {
  ProductFilterAttributeSchema,
  ProductFilterConditionsSchema,
} from '@/store/modules/trading/publicIndication/interface'
import {
  BaseFilterAttribute,
  TAB_INDEXES,
} from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'

const publicIndicationSorter = (a: PublicIndication, b: PublicIndication) =>
  b.products[0].area.displayPriority - a.products[0].area.displayPriority ||
  b.products[0].startDeliveryYearMonth.displayPriority -
    a.products[0].startDeliveryYearMonth.displayPriority ||
  b.products[0].endDeliveryYearMonth.displayPriority -
    a.products[0].endDeliveryYearMonth.displayPriority ||
  (b.products[0].hourType?.displayPriority ?? 0) -
    (a.products[0].hourType?.displayPriority ?? 0) ||
  b.products[0].productType.displayPriority -
    a.products[0].productType.displayPriority

const publicDealSorter = (a: PublicDeal, b: PublicDeal) =>
  b.product.area.displayPriority - a.product.area.displayPriority ||
  b.product.startDeliveryYearMonth.displayPriority -
    a.product.startDeliveryYearMonth.displayPriority ||
  b.product.endDeliveryYearMonth.displayPriority -
    a.product.endDeliveryYearMonth.displayPriority ||
  (b.product.hourType?.displayPriority ?? 0) -
    (a.product.hourType?.displayPriority ?? 0) ||
  b.product.productType.displayPriority - a.product.productType.displayPriority

export class PublicIndicationActions extends Actions<
  PublicIndicationState,
  PublicIndicationGetters,
  PublicIndicationMutations,
  PublicIndicationActions
> {
  async fetchPublicIndications() {
    const publicIndications = plainToClass(
      PublicIndication,
      (await new PublicIndicationsApi(apiConfig).getPublicIndications()).data,
    )

    publicIndications.sort(publicIndicationSorter)
    this.commit(SET_PUBLIC_INDICATIONS, { publicIndications })
  }

  async fetchPublicIndicationsCsvString(): Promise<string> {
    const response = await new PublicIndicationsApi(
      apiConfig,
    ).getPublicIndicationsCsv()
    return response.data
  }

  async fetchPublicDeals() {
    const publicDeals = plainToClass(
      PublicDeal,
      (await new PublicDealsApi(apiConfig).getPublicLastDeals()).data,
    )

    publicDeals.sort(publicDealSorter)
    this.commit(SET_PUBLIC_DEALS, { publicDeals })
  }

  async fetchPublicRecentDeals() {
    const publicDeals = plainToClass(
      PublicDeal,
      (await new PublicDealsApi(apiConfig).getPublicRecentDeals()).data,
    )
    publicDeals.sort(publicDealSorter)
    return publicDeals
  }

  async fetchPublicLastDealsCsvString(): Promise<string> {
    const response = await new PublicDealsApi(apiConfig).getPublicLastDealsCsv()
    return response.data
  }

  loadFiltersFromLocalStorage() {
    for (const index of [
      TAB_INDEXES.NO_FUEL_SURCHARGE,
      TAB_INDEXES.WITH_FUEL_SURCHARGE,
      // スプレッドはOrderBook(Order.vue)で実装されているためここでは取得しない
    ]) {
      const filterState = findFilterState(index, this.state)

      try {
        const selectedFiltersFromLocalStorage = localStorage.getItem(
          filterState.keyOfSelectedFilters,
        )
        const selectedFiltersConditionFromLocalStorage = localStorage.getItem(
          filterState.keyOfSelectedFiltersCondition,
        )
        if (
          selectedFiltersFromLocalStorage === null ||
          selectedFiltersConditionFromLocalStorage === null
        ) {
          throw new Error('LocalStorage is null')
        }
        const parsedSelectedFilters = ProductFilterAttributeSchema.parse(
          JSON.parse(selectedFiltersFromLocalStorage),
        )
        const parsedSelectedFiltersCondition = ProductFilterConditionsSchema.parse(
          JSON.parse(selectedFiltersConditionFromLocalStorage),
        )
        this.commit(filterState.SET_SELECTED_FILTERS, {
          selectedFilters: parsedSelectedFilters,
        })

        // 開始月と終了月が古くなったものは除外する
        const availableDeliveryYearMonth = parsedSelectedFiltersCondition.startDeliveryYearMonth.filter(
          deliveryYearMonth =>
            moment(deliveryYearMonth).isSameOrAfter(moment(), 'month'),
        )
        const availableDeliveryEndMonth = parsedSelectedFiltersCondition.endDeliveryYearMonth.filter(
          deliveryEndMonth =>
            moment(deliveryEndMonth).isSameOrAfter(moment(), 'month'),
        )

        const refreshedSelectedFiltersConditionFromLocal = {
          ...parsedSelectedFiltersCondition,
          startDeliveryYearMonth: availableDeliveryYearMonth,
          endDeliveryYearMonth: availableDeliveryEndMonth,
        }

        for (const attribute of filterState.state.filterAttributes()) {
          this.commit(filterState.SET_FILTER_CONDITION, {
            attribute: attribute,
            selected: refreshedSelectedFiltersConditionFromLocal[attribute],
          })
        }
      } catch (e) {
        // LocalStorageのデータが壊れている場合は、初期化する
        localStorage.setItem(
          filterState.keyOfSelectedFilters,
          JSON.stringify([]),
        )
        localStorage.setItem(
          filterState.keyOfSelectedFiltersCondition,
          JSON.stringify(filterState.state.filterCondition()),
        )
      }
    }
  }

  addFilterConditionSelected(payload: {
    index: number
    attribute: BaseFilterAttribute
    value: InputOption
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: [
        ...filterState.state.filterCondition()[payload.attribute],
        payload.value.value,
      ],
    })
    window.localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  removeFilterConditionSelected(payload: {
    index: number
    attribute: BaseFilterAttribute
    value: InputOption
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: filterState.state
        .filterCondition()
        [payload.attribute].filter(id => id !== payload.value.value),
    })
    window.localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  removeFilterCondition(payload: {
    index: number
    attribute: BaseFilterAttribute
  }) {
    const filterState = findFilterState(payload.index, this.state)
    this.commit(filterState.SET_SELECTED_FILTERS, {
      selectedFilters: filterState.state
        .selectedFilters()
        .filter(filter => filter !== payload.attribute),
    })
    this.commit(filterState.SET_FILTER_CONDITION, {
      attribute: payload.attribute,
      selected: [],
    })

    window.localStorage.setItem(
      filterState.keyOfSelectedFilters,
      JSON.stringify(filterState.state.selectedFilters()),
    )
    window.localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }

  changeFilter(payload: {
    index: number
    selectedValues: BaseFilterAttribute[]
    changedValue: BaseFilterAttribute
  }) {
    const filterState = findFilterState(payload.index, this.state)

    this.commit(filterState.SET_SELECTED_FILTERS, {
      selectedFilters: payload.selectedValues,
    })
    if (!payload.selectedValues.includes(payload.changedValue)) {
      this.commit(filterState.SET_FILTER_CONDITION, {
        attribute: payload.changedValue,
        selected: [],
      })
    }

    window.localStorage.setItem(
      filterState.keyOfSelectedFilters,
      JSON.stringify(filterState.state.selectedFilters()),
    )
    window.localStorage.setItem(
      filterState.keyOfSelectedFiltersCondition,
      JSON.stringify(filterState.state.filterCondition()),
    )
  }
}
