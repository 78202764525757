import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17641ba0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "receive-email-statuses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_enable_icon = _resolveComponent("enable-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_enable_icon, { enable: _ctx.receiveIndicationEmail }, null, 8, ["enable"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_enable_icon, { enable: _ctx.receiveLiveMarketEmail }, null, 8, ["enable"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_enable_icon, { enable: _ctx.receiveSpotTradingRecapEmail }, null, 8, ["enable"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_enable_icon, { enable: _ctx.receiveDerivativeTradingRecapEmail }, null, 8, ["enable"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_enable_icon, { enable: _ctx.receiveReportEmail }, null, 8, ["enable"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_enable_icon, { enable: _ctx.receiveInvoiceEmail }, null, 8, ["enable"])
    ])
  ]))
}