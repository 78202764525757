import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23f823b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-indication-status-changer" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_broking_indication_status = _resolveComponent("broking-indication-status")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_action_dropdown, {
      id: _ctx.id,
      actions: _ctx.actions,
      "is-active": _ctx.isActionDropdownActive,
      "is-button": false,
      "is-right": "",
      onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isActionDropdownActive = false)),
      onActivate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onActivate())),
      onDeactivate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDeactivate()))
    }, {
      default: _withCtx(() => [
        (_ctx.status)
          ? (_openBlock(), _createBlock(_component_broking_indication_status, {
              key: 0,
              class: _normalizeClass({
          'broking-indication-status-changer__icon--submitting': _ctx.isSubmitting,
        }),
              status: _ctx.status,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onStatusClick()), ["stop"]))
            }, null, 8, ["class", "status"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2))
      ]),
      _: 1
    }, 8, ["id", "actions", "is-active"])
  ]))
}