import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4faa8674"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ecloud-organization-detail__content" }
const _hoisted_2 = { class: "ecloud-organization-detail__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_ecloud_organization_detail_form = _resolveComponent("ecloud-organization-detail-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createBlock(_component_ui_stack_selector, {
    "ui-stack": _ctx.uiStack,
    class: "ecloud-organization-detail"
  }, {
    loading: _withCtx(() => [
      _createVNode(_component_centered_loading_content, { type: "grayscale" })
    ]),
    error: _withCtx(() => [
      _createVNode(_component_base_alert, { type: _ctx.errorType }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', { name: _ctx.$t('iam.label.organization') })), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    ideal: _withCtx(() => [
      _createVNode(_component_validation_form, null, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_ecloud_organization_detail_form, {
                "form-value": _ctx.formValue,
                "readable-report-type-input-options": 
                _ctx.readableReportTypeInputOptions
              ,
                "eclear-status-input-options": _ctx.eclearStatusInputOptions,
                "enable-status-input-options": _ctx.enableStatusInputOptions,
                "sales-tax-included-in-invoice-input-options": 
                _ctx.salesTaxIncludedInInvoiceInputOptions
              ,
                onFormInput: _cache[0] || (_cache[0] = event => Object.assign(_ctx.formValue, event))
              }, null, 8, ["form-value", "readable-report-type-input-options", "eclear-status-input-options", "enable-status-input-options", "sales-tax-included-in-invoice-input-options"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_base_button, {
                class: "ecloud-organization-detail__submit-button",
                color: "primary",
                disabled: Object.keys(errors).length > 0,
                loading: _ctx.organizationSubmitting,
                onClick: _ctx.onSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.update')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "loading", "onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["ui-stack"]))
}