
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { PER_PAGE } from '@/components/common/constants/List'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import NegotiationList from '@/components/trading/NegotiationList.vue'
import NegotiationListHeader from '@/components/trading/NegotiationListHeader.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { Negotiation } from '@/models/trading/Negotiation'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { NegotiationModule } from '@/store/modules/trading/negotiation'
import { SET_FILTER_CONDITION } from '@/store/modules/trading/negotiation/NegotiationMutations'
import { getNegotiationsFilter } from '@/store/modules/trading/negotiation/helper'
import { NegotiationFilterAttribute } from '@/store/modules/trading/negotiation/interface'
import { ProductModule } from '@/store/modules/trading/product'

const {
  mapState: negotiationsMapState,
  mapActions: negotiationsMapActions,
  mapMutations: negotiationsMutations,
  mapGetters: negotiationsMapGetters,
} = createNamespacedHelpers('negotiation') as NegotiationModule

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const keyOfSelectedFilters = 'selectedFiltersOfNegotiations'

export default defineComponent({
  name: 'Negotiations',
  components: {
    BaseAlert,
    BaseButton,
    BrokerPage,
    CenteredLoadingContent,
    NegotiationList,
    NegotiationListHeader,
    ProductFilter,
    UiStackSelector,
  },
  data(): {
    negotiations: Negotiation[]
    isLoaded: boolean
    uiStack: UiStack
    alertTypeInfo: AlertType.Info
    alertTypeError: AlertType.Error
    attributes: NegotiationFilterAttribute[]
    selectedFilters: NegotiationFilterAttribute[]
    isFilterActionDropdownActive: boolean
  } {
    return {
      negotiations: [],
      isLoaded: false,
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
      attributes: [
        'area',
        'startDeliveryYearMonth',
        'endDeliveryYearMonth',
        'hourType',
        'productType',
        'organizationName',
        'negotiationId',
        'status',
      ],
      selectedFilters: [],
      isFilterActionDropdownActive: false,
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState([
      'productTypes',
      'areas',
      'hourTypes',
      'deliveryYearMonths',
    ]),
    ...negotiationsMapState(['filterCondition']),
    ...negotiationsMapGetters(['filterConditionParameter']),
    lastId(): string | undefined {
      if (this.negotiations.length === 0) {
        return undefined
      }
      return this.negotiations[this.negotiations.length - 1].id
    },
    filters(): AttributeFilter[] {
      return getNegotiationsFilter(
        this.areas,
        this.hourTypes,
        this.productTypes,
        this.selectedFilters,
        this.filterCondition,
        this.deliveryYearMonths,
      )
    },
    filterActions(): ActionDropdownItem[] {
      return this.attributes.map(attribute => ({
        label: this.$t('trading.label.' + attribute),
        eventName: attribute,
      })) as ActionDropdownItem[]
    },
  },
  async created() {
    await Promise.all([
      this.fetchInitialList(),
      this.fetchProductTypes(),
      this.fetchAreas(),
      this.fetchHourTypes(),
      this.fetchDeliveryYearMonths(),
    ])
    this.getFilters(keyOfSelectedFilters)
  },
  methods: {
    fetchNegotiations: negotiationsMapActions(['fetchNegotiations'])
      .fetchNegotiations as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any,
    ) => Promise<Negotiation[]>,
    ...negotiationsMutations([SET_FILTER_CONDITION]),
    ...productMapActions([
      'fetchProductTypes',
      'fetchAreas',
      'fetchHourTypes',
      'fetchDeliveryYearMonths',
    ]),
    async fetchInitialList() {
      this.negotiations = []
      this.isLoaded = false
      await this.fetchNegotiations(this.filterConditionParameter)
        .then((negotiations: Negotiation[]) => {
          this.negotiations = negotiations
          this.isLoaded =
            negotiations.length === 0 || negotiations.length % PER_PAGE !== 0
          if (this.negotiations.length === 0) {
            this.uiStack = UiStack.Blank
          } else {
            this.uiStack = UiStack.Ideal
          }
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    async fetchNextList(lastId?: string) {
      const negotiations = await this.fetchNegotiations({
        ...this.filterConditionParameter,
        lastId,
      }).catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
      this.negotiations.push(...negotiations)
      this.isLoaded =
        this.isLoaded ||
        negotiations.length === 0 ||
        negotiations.length % PER_PAGE !== 0
    },
    onLastItemVisible() {
      if (!this.isLoaded) {
        this.fetchNextList(this.lastId)
      }
    },
    async onNewButtonClick() {
      await this.$router.push('/negotiations/new')
    },
    getFilters(key: string) {
      const item = localStorage.getItem(key)
      if (item !== null) {
        const selectedFiltersFromLocal = JSON.parse(item)
        this.selectedFilters = selectedFiltersFromLocal
      }
    },
    onClickFilterDropdown() {
      this.isFilterActionDropdownActive = !this.isFilterActionDropdownActive
    },
    onClickFilterOutside() {
      this.isFilterActionDropdownActive = false
    },
    onFilterItemInput(event: {
      attribute: NegotiationFilterAttribute
      value?: string
    }) {
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: event.value,
        },
      })
      this.fetchInitialList()
    },
    onFilterItemSelect(event: {
      attribute: NegotiationFilterAttribute
      value?: InputOption
    }) {
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: event.value?.value,
        },
      })
      this.fetchInitialList()
    },
    onFilterRemove(event: { attribute: NegotiationFilterAttribute }) {
      this.selectedFilters = this.selectedFilters.filter(
        attribute => attribute !== event.attribute,
      )
      const isRequiredFetchList = !!this.filterCondition[event.attribute]
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: undefined,
        },
      })
      this.updateLocalStorageOfSelectedFilters(
        keyOfSelectedFilters,
        this.selectedFilters,
      )
      if (isRequiredFetchList) {
        this.fetchInitialList()
      }
    },
    updateLocalStorageOfSelectedFilters(
      key: string,
      value: NegotiationFilterAttribute[],
    ) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    onChangeFilters(event: {
      selectedValues: NegotiationFilterAttribute[]
      changeValue: NegotiationFilterAttribute
    }) {
      this.selectedFilters = event.selectedValues
      this.updateLocalStorageOfSelectedFilters(
        keyOfSelectedFilters,
        this.selectedFilters,
      )
    },
  },
})
