
import { defineComponent, PropType } from 'vue'

import BaseBadge from '@/components/common/BaseBadge.vue'
import { displayRecapStatusName } from '@/store/modules/trading/recap/helper'
import { RecapStatus } from '@/store/modules/trading/recap/interface'

export default defineComponent({
  name: 'DealRecapStatus',
  components: {
    BaseBadge,
  },
  props: {
    dealType: {
      type: String,
    },
    status: {
      type: String as PropType<RecapStatus>,
      default: 'default',
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  methods: {
    convertDisplayName() {
      return displayRecapStatusName(this.status)
    },
  },
})
