import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b870e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "occto-master-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selected_occto_master = _resolveComponent("selected-occto-master")!
  const _component_occto_master_selector_modal = _resolveComponent("occto-master-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedOcctoMaster)
      ? (_openBlock(), _createBlock(_component_selected_occto_master, {
          key: 0,
          "is-selectable": _ctx.isSelectable,
          "selected-occto-master": _ctx.selectedOcctoMaster,
          onClick: _ctx.onClick
        }, null, 8, ["is-selectable", "selected-occto-master", "onClick"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["occto-master-selector__select-button--not-selected", {
        'occto-master-selector__select-button--not-selected-selectable': _ctx.isSelectable,
      }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.$t('trading.message.selectOcctoMaster')), 3)),
    _createVNode(_component_base_modal, {
      title: _ctx.$t('trading.label.selectOcctoMaster'),
      name: _ctx.modalName,
      width: 800
    }, {
      default: _withCtx(() => [
        _createVNode(_component_occto_master_selector_modal, {
          "organization-id": _ctx.organizationId,
          "area-id": _ctx.areaId,
          onSelect: _ctx.onSelect,
          onCancel: _ctx.onCancel
        }, null, 8, ["organization-id", "area-id", "onSelect", "onCancel"])
      ]),
      _: 1
    }, 8, ["title", "name"])
  ]))
}