import { plainToClass } from 'class-transformer'
import { Getters } from 'vuex-smart-module'

import { Locale } from '@/api/generated'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { Area } from '@/models/trading/Area'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import { BrokingState } from '@/store/modules/trading/broking/BrokingState'
import {
  getBrokingFilter,
  brokingIndicationFilter,
  brokingDealFilter,
} from '@/store/modules/trading/broking/helper'

export class BrokingGetters extends Getters<BrokingState> {
  private get brokingIndications(): BrokingIndication[] {
    return this.state.brokingIndications.filter(indication => {
      return (
        !indication.fuelSurchargeType &&
        ['normal', 'swing-option'].includes(indication.products[0].type)
      )
    })
  }

  private get brokingIndicationsWithFuelSurcharge(): BrokingIndication[] {
    return this.state.brokingIndications.filter(indication => {
      return (
        indication.fuelSurchargeType &&
        ['normal', 'swing-option'].includes(indication.products[0].type)
      )
    })
  }

  private get brokingIndicationsWithSpread(): BrokingIndication[] {
    return this.state.brokingIndications.filter(
      indication => indication.products[0].type === 'spread',
    )
  }

  private get brokingDeals(): BrokingDeal[] {
    return this.state.brokingDeals.filter(deal => {
      return (
        !deal.fuelSurchargeType &&
        ['normal', 'swing-option'].includes(deal.product.type)
      )
    })
  }

  private get brokingDealsWithFuelSurcharge(): BrokingDeal[] {
    return this.state.brokingDeals.filter(deal => {
      return (
        deal.fuelSurchargeType &&
        ['normal', 'swing-option'].includes(deal.product.type)
      )
    })
  }

  private get brokingDealsWithSpread(): BrokingDeal[] {
    return this.state.brokingDeals.filter(
      deal => deal.product.type === 'spread',
    )
  }

  get brokingOrderBooks(): BrokingOrderBook[] {
    return this.state.brokingOrderBooks
  }

  get filteredBrokingIndications(): BrokingIndication[] {
    return this.getters.brokingIndications.filter(
      brokingIndicationFilter(this.state.filterCondition, this.state.users),
    )
  }

  get filteredBrokingIndicationsWithFuelSurcharge(): BrokingIndication[] {
    return this.getters.brokingIndicationsWithFuelSurcharge.filter(
      brokingIndicationFilter(
        this.state.filterConditionWithFuelSurcharge,
        this.state.users,
      ),
    )
  }

  get filteredBrokingIndicationsWithSpread(): BrokingIndication[] {
    return this.getters.brokingIndicationsWithSpread.filter(
      brokingIndicationFilter(
        this.state.filterConditionWithSpread,
        this.state.users,
      ),
    )
  }

  get filteredBrokingOrderBooksWithSpread(): BrokingOrderBook[] {
    const filteredIndicationIds = this.getters.brokingIndicationsWithSpread
      .filter(
        brokingIndicationFilter(
          this.state.filterConditionWithSpread,
          this.state.users,
        ),
      )
      .map(brokingIndication => brokingIndication.indicationId)

    return this.state.brokingOrderBooks
      .filter(book =>
        book.orders.some(order =>
          order.indicationId !== undefined
            ? filteredIndicationIds.includes(order.indicationId)
            : order.executedAt !== undefined,
        ),
      )
      .map(book => {
        const orders = book.orders.filter(order =>
          order.indicationId
            ? filteredIndicationIds.includes(order.indicationId)
            : order.executedAt !== undefined,
        )

        return orders.length > 0
          ? plainToClass(BrokingOrderBook, {
              ...book,
              orders,
            })
          : book
      })
  }

  get filteredBrokingDeals(): BrokingDeal[] {
    if (
      this.state.filterCondition.status.length !== 0 ||
      this.state.filterCondition.createdParticipantName.length !== 0
    ) {
      return []
    }
    return this.getters.brokingDeals.filter(
      brokingDealFilter(this.state.filterCondition),
    )
  }

  get filteredBrokingDealsWithFuelSurcharge(): BrokingDeal[] {
    if (
      this.state.filterConditionWithFuelSurcharge.status.length !== 0 ||
      this.state.filterConditionWithFuelSurcharge.createdParticipantName
        .length !== 0
    ) {
      return []
    }
    return this.getters.brokingDealsWithFuelSurcharge.filter(
      brokingDealFilter(this.state.filterConditionWithFuelSurcharge),
    )
  }

  get filteredBrokingDealsWithSpread(): BrokingDeal[] {
    if (
      this.state.filterConditionWithSpread.status.length !== 0 ||
      this.state.filterConditionWithSpread.createdParticipantName.length !== 0
    ) {
      return []
    }
    return this.getters.brokingDealsWithSpread.filter(
      brokingDealFilter(this.state.filterConditionWithSpread),
    )
  }

  productFilter({
    locale,
    areas,
    hourTypes,
    productTypes,
  }: {
    locale: Locale
    areas: Area[]
    hourTypes: HourType[]
    productTypes: ProductType[]
  }): AttributeFilter[] {
    return getBrokingFilter({
      indications: this.getters.brokingIndications,
      filterAttributes: this.state.selectedFilters,
      filterCondition: this.state.filterCondition,
      deals: this.getters.brokingDeals,
      locale,
      areas,
      hourTypes,
      productTypes,
    })
  }

  productFilterWithFuelSurcharge({
    locale,
    areas,
    hourTypes,
    productTypes,
  }: {
    locale: Locale
    areas: Area[]
    hourTypes: HourType[]
    productTypes: ProductType[]
  }): AttributeFilter[] {
    return getBrokingFilter({
      indications: this.getters.brokingIndicationsWithFuelSurcharge,
      filterAttributes: this.state.selectedFiltersWithFuelSurcharge,
      filterCondition: this.state.filterConditionWithFuelSurcharge,
      deals: this.getters.brokingDealsWithFuelSurcharge,
      locale,
      areas,
      hourTypes,
      productTypes,
    })
  }

  productFilterWithSpread({
    locale,
    areas,
    hourTypes,
    productTypes,
  }: {
    locale: Locale
    areas: Area[]
    hourTypes: HourType[]
    productTypes: ProductType[]
  }): AttributeFilter[] {
    return getBrokingFilter({
      indications: this.getters.brokingIndicationsWithSpread,
      filterAttributes: this.state.selectedFiltersWithSpread,
      filterCondition: this.state.filterConditionWithSpread,
      deals: this.getters.brokingDealsWithSpread,
      locale,
      areas,
      hourTypes,
      productTypes,
    })
  }

  get participantIds(): Array<string> {
    const participantIdSet = new Set<string>()
    for (const indication of this.state.brokingIndications) {
      participantIdSet.add(indication.createdParticipantId)
      participantIdSet.add(indication.lastUpdatedParticipantId)
    }
    return Array.from(participantIdSet)
  }
}
