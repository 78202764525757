import { Type } from 'class-transformer'

import { Deal as IDeal, DealSubType, SpreadType } from '@/api/generated/api'
import { DealDetail } from '@/models/trading/DealDetail'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Price } from '@/models/trading/Price'
import { Product } from '@/models/trading/Product'

export class Deal implements IDeal {
  id!: string

  negotiationId!: string

  publicNegotiationId!: string

  type!: DealSubType

  spreadTypes?: SpreadType[]

  @Type(() => Product)
  product!: Product

  price!: Price

  unitPrices!: number[]

  volumes!: number[]

  exchangeVolumes?: number[]

  spreadBasePrice?: number

  executedAt!: string

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  privateConfidential!: boolean

  excludedEnecCurve!: boolean

  @Type(() => DealDetail)
  askDealDetail!: DealDetail

  @Type(() => DealDetail)
  bidDealDetail!: DealDetail

  createdParticipantId!: string

  createdAt!: string

  lastUpdatedParticipantId!: string

  updatedAt!: string

  maskVolume!: boolean
}
