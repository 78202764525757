
import { defineComponent } from 'vue'

import { UserStatusType } from '@/api/generated'

export default defineComponent({
  name: 'InvitationStatus',
  props: {
    status: {
      type: String,
      validator: (value: UserStatusType) =>
        [
          UserStatusType.Active.toString(),
          UserStatusType.Invited.toString(),
        ].includes(value),
      required: true,
    },
  },
  computed: {
    label() {
      return this.status === UserStatusType.Active.toString()
        ? ''
        : this.$t('iam.label.invited')
    },
  },
})
