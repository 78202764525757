
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { TooltipDirections } from '@/components/common/constants/TooltipDirections'

export default defineComponent({
  name: 'PublicInformation',
  components: { BaseIcon, BaseTooltip },
  props: {
    information: {
      type: String,
      default: undefined,
    },
    direction: {
      type: String as PropType<typeof TooltipDirections[number]>,
      default: 'upward',
      validator: (value: typeof TooltipDirections[number]) => {
        return TooltipDirections.includes(value)
      },
    },
  },
})
