
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseLink',
  props: {
    path: {
      type: String,
      required: true,
    },
    includeChildAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetFontStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTargetBlank: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['item-click'],
  data(): { enableWithHref: boolean } {
    return {
      enableWithHref: false,
    }
  },
  computed: {
    href(): string {
      return this.enableWithHref ? this.path : 'javascript:void(0)'
    },
  },
  methods: {
    async navigate(e: PointerEvent) {
      if (this.disabled) {
        return
      }
      const isTargetBlank =
        this.isTargetBlank ||
        // metakey reference: https://developer.mozilla.org/ja/docs/Web/API/MouseEvent/metaKey
        (e.button !== undefined && e.button !== 0) ||
        e.metaKey ||
        e.ctrlKey
      const url =
        isTargetBlank && /^\//.test(this.path)
          ? this.$router.resolve(this.path).href
          : this.path

      if (isTargetBlank) {
        window.open(url, '_blank')
        return
      }

      this.$emit('item-click')

      this.$router.push(url)
    },
  },
})
