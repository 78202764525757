
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import DeliveryDateForm from '@/components/trading/DeliveryDateForm.vue'
import DeliveryPatternComponentForm from '@/components/trading/DeliveryPatternComponentForm.vue'
import FieldUnitPrice from '@/components/trading/FieldUnitPrice.vue'
import FieldVolume from '@/components/trading/FieldVolume.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { DeliveryDateFormProps } from '@/components/trading/interface/DeliveryDateFormProps'
import { DeliveryPatternComponentFormProps } from '@/components/trading/interface/DeliveryPatternComponentFormProps'
import { DeliveryPeriodFormProps } from '@/components/trading/interface/DeliveryPeriodFormProps'
import { moment } from '@/utils/date'

export default defineComponent({
  name: 'DeliveryPeriodForm',
  components: {
    BaseBox,
    BaseButton,
    BaseIcon,
    DeliveryDateForm,
    DeliveryPatternComponentForm,
    FieldUnitPrice,
    FieldVolume,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    formValue: {
      type: Object as PropType<DeliveryPeriodFormProps>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    dayPatternOptions: {
      type: Array as PropType<InputOption[]>,
      default: () => [],
    },
    mode: {
      type: Number,
      required: true,
      validator: (value: IndicationFormInputMode) => {
        return Object.values(IndicationFormInputMode).includes(value)
      },
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
    startDeliveryMinDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    startDeliveryMaxDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    endDeliveryMinDate: {
      type: Date as PropType<Date>,
      required: true,
    },
  },
  emits: ['form-input', 'delete'],
  data(): {
    formInputEventName: 'form-input'
  } {
    return {
      formInputEventName: 'form-input',
    }
  },
  computed: {
    deliveryDateFormValue(): DeliveryDateFormProps {
      const format = 'YYYY-MM-DD'
      return {
        startDeliveryDate: this.formValue.startDeliveryDate
          ? moment(this.formValue.startDeliveryDate).format(format)
          : undefined,
        endDeliveryDate: this.formValue.endDeliveryDate
          ? moment(this.formValue.endDeliveryDate).format(format)
          : undefined,
      }
    },
    isShownButton(): boolean {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
    },
    deliveryDateFormInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    deliveryPatternComponentFormInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    unitPriceInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    volumeInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
  },
  methods: {
    addDeliveryPatternComponentValue() {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        deliveryPatternComponentFormValues: [
          ...this.formValue.deliveryPatternComponentFormValues,
          {
            dayPattern: undefined,
            timeRangeStart: undefined,
            timeRangeEnd: undefined,
          },
        ],
      })
    },
    onDeleteDeliveryPatternComponentValue(index: number) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        deliveryPatternComponentFormValues: this.formValue.deliveryPatternComponentFormValues.filter(
          (_, i) => i !== index,
        ),
      })
    },
    onDeliveryPatternComponentFormInput(
      index: number,
      value: DeliveryPatternComponentFormProps,
    ) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        deliveryPatternComponentFormValues: this.formValue.deliveryPatternComponentFormValues.map(
          (deliveryPatternComponentFormValue, i) =>
            index === i ? value : deliveryPatternComponentFormValue,
        ),
      })
    },
    onVolumeInput(value: string | undefined) {
      const volume = value === undefined || value === '' ? null : value
      this.$emit(this.formInputEventName, { ...this.formValue, volume })
    },
  },
})
