import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfbb90ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input-radio" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = ["value", "checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_base_label, {
          label: _ctx.labelString,
          help: _ctx.help,
          class: "base-input-radio__label"
        }, null, 8, ["label", "help"]),
        _createElementVNode("div", {
          class: _normalizeClass(["base-input-radio__wrapper", _ctx.wrapperClass]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              class: _normalizeClass(["base-input-radio__radio", _ctx.labelClass])
            }, [
              _createElementVNode("input", {
                type: "radio",
                class: _normalizeClass(["base-input-radio__input radio", _ctx.inputClass]),
                value: option.value,
                checked: option.value === _ctx.value,
                disabled: _ctx.readonly,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
              }, null, 42, _hoisted_4),
              _createVNode(_component_sentences, {
                type: "text-medium",
                class: "base-input-radio__value"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(option.label), 1)
                ]),
                _: 2
              }, 1024)
            ], 2))
          }), 128))
        ], 2)
      ])
    ])
  ]))
}