import { RouteRecordRaw } from 'vue-router'

import LocalizedReports from '@/views/reports/LocalizedReports.vue'

/**
 * パス: /reports/...
 */
export const reportsRoutes: RouteRecordRaw[] = [
  {
    path: '/reports',
    name: 'LocalizedReports',
    component: LocalizedReports,
  },
  {
    path: '/reports/:id',
    name: 'LocalizedReport',
    component: LocalizedReports,
    props: route => ({ id: route.params.id }),
  },
]
