
import { defineComponent } from 'vue'

import HorizontalTab from '@/components/common/HorizontalTab.vue'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'

export default defineComponent({
  name: 'FuelSurchargeTypeTab',
  components: {
    HorizontalTab,
  },
  props: {
    currentTabIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ['tab-active'],
  computed: {
    tabList(): HorizontalTabItem[] {
      return [
        this.$t('trading.label.noFuelSurcharge').toString(),
        this.$t('trading.label.withFuelSurcharge').toString(),
        this.$t('trading.label.spread').toString(),
        this.$t('trading.label.onlyFuelSurcharge').toString(),
        this.$t('trading.label.lng').toString(),
        this.$t('trading.label.envValues').toString(),
      ]
    },
  },
  methods: {
    onTabActive(index: number) {
      this.$emit('tab-active', index)
    },
  },
})
