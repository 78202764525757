
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import { PER_PAGE } from '@/components/common/constants/List'
import ReportList from '@/components/reports/ReportList.vue'
import { Report as ReportModel } from '@/models/reports/Report'
import { ReportsModule } from '@/store/modules/reports/reports'
import { downloadFile } from '@/utils/utils'

const { mapActions: reportsMapActions } = createNamespacedHelpers(
  'reports',
) as ReportsModule

export default defineComponent({
  name: 'Reports',
  components: {
    BaseButton,
    BrokerPage,
    CenteredLoadingContent,
    ReportList,
  },
  data() {
    return {
      reports: [] as ReportModel[],
      isLoaded: false,
    }
  },
  computed: {
    lastReportId(): string | undefined {
      if (this.reports.length === 0) {
        return undefined
      }
      return this.reports[this.reports.length - 1].id
    },
  },
  async created() {
    await this.fetchList()
  },
  methods: {
    fetchAttachmentUrl: reportsMapActions(['fetchAttachmentUrl'])
      .fetchAttachmentUrl as (payload: {
      reportId: string
      reportContentId: string
    }) => Promise<{ url: string }>,
    fetchReports: reportsMapActions(['fetchReports']).fetchReports as (
      lastReportId?: string,
    ) => Promise<ReportModel[]>,
    async fetchList(lastReportId?: string) {
      const reports = await this.fetchReports(lastReportId)
      this.reports.push(...reports)
      this.isLoaded =
        this.isLoaded || reports.length === 0 || reports.length % PER_PAGE !== 0
    },
    onLastItemVisible() {
      if (!this.isLoaded) {
        this.fetchList(this.lastReportId)
      }
    },
    async onNewButtonClick() {
      await this.$router.push('/reports/new')
    },
    async onDownloadClick({
      reportId,
      reportContentId,
      attachmentName,
    }: {
      reportId: string
      reportContentId: string
      attachmentName: string
    }) {
      const { url } = await this.fetchAttachmentUrl({
        reportId,
        reportContentId,
      })
      downloadFile({ url, name: attachmentName })
    },
  },
})
