
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { RequestOrganizationEmailAddress } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import ReceiveEmailForm from '@/components/iam/ReceiveEmailForm.vue'
import { ReceiveEmailFormProps } from '@/components/iam/interface/ReceiveEmailFormProps'
import { OrganizationEmailAddressModule } from '@/store/modules/iam/organizationEmailAddress'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: organizationEmailAddressMapActions,
} = createNamespacedHelpers(
  'organizationEmailAddress',
) as OrganizationEmailAddressModule

export default defineComponent({
  name: 'OrganizationEmailAddressNew',
  components: {
    BaseBox,
    BaseButton,
    BaseInputText,
    ReceiveEmailForm,
    ValidationForm,
  },
  data(): {
    isLoading: boolean
    organizationEmailAddress: RequestOrganizationEmailAddress
  } {
    return {
      isLoading: false,
      organizationEmailAddress: {
        name: '',
        emailAddress: '',
        receiveIndicationEmail: false,
        receiveLiveMarketEmail: false,
        receiveSpotTradingRecapEmail: false,
        receiveDerivativeTradingRecapEmail: false,
        receiveReportEmail: false,
        receiveInvoiceEmail: false,
      },
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    emailFormValue(): ReceiveEmailFormProps {
      return {
        receiveIndicationEmail: this.organizationEmailAddress
          .receiveIndicationEmail,
        receiveLiveMarketEmail: this.organizationEmailAddress
          .receiveLiveMarketEmail,
        receiveSpotTradingRecapEmail: this.organizationEmailAddress
          .receiveSpotTradingRecapEmail,
        receiveDerivativeTradingRecapEmail: this.organizationEmailAddress
          .receiveDerivativeTradingRecapEmail,
        receiveReportEmail: this.organizationEmailAddress.receiveReportEmail,
        receiveInvoiceEmail: this.organizationEmailAddress.receiveInvoiceEmail,
      }
    },
  },
  methods: {
    ...organizationEmailAddressMapActions(['createOrganizationEmailAddress']),
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    async onClick() {
      this.isLoading = true
      await this.createOrganizationEmailAddress({
        organizationId: this._organizationId,
        organizationEmailAddress: this.organizationEmailAddress,
      })
        .then(() => {
          setNotification(
            this.$t(
              'iam.message.successUpdateOrganizationEmailAddress',
            ).toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          const message =
            e.response.status === HttpStatus.Conflict
              ? this.$t(
                  'iam.message.failSaveOrganizationEmailAddressByDuplicate',
                  {
                    mail: this.organizationEmailAddress.emailAddress,
                  },
                ).toString()
              : this.$t(
                  'iam.message.failSaveOrganizationEmailAddress',
                ).toString()
          setNotification(message, 'danger')
          throw e
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onInput(value: ReceiveEmailFormProps) {
      Object.assign(this.organizationEmailAddress, value)
    },
  },
})
