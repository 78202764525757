
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import HorizontalTab from '@/components/common/HorizontalTab.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'
import OrganizationHeadline from '@/components/iam/OrganizationHeadline.vue'
import { OrganizationProfileModule } from '@/store/modules/iam/organizationProfile'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'

const PageTemplate = isBrokerApp ? BrokerPage : TraderPage

const {
  mapState: organizationProfileMapState,
  mapActions: organizationProfileMapActions,
} = createNamespacedHelpers('organizationProfile') as OrganizationProfileModule

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const ESQUARE_ORGANIZATION_TAB_INDEXES = {
  BASIC: 0,
  RECAP: 1,
  EMAIL: 2,
}

export default defineComponent({
  name: 'Organization',
  components: {
    BaseAlert,
    CenteredLoadingContent,
    HorizontalTab,
    OrganizationHeadline,
    PageTemplate,
    UiStackSelector,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType.Error
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
    }
  },
  computed: {
    ...organizationProfileMapState(['organizationProfile']),
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    isMyOrganizationPage(): boolean {
      return !isBrokerApp || !this.$attrs.organizationId
    },
    titleKey(): string {
      return (this.$route.meta?.titleKey as string | undefined) || ''
    },
    activeMenuItem(): string | undefined {
      return this.$route.meta?.activeMenuItem as string | undefined
    },
    tabList(): HorizontalTabItem[] {
      return [
        {
          label: this.$t('iam.label.basicSetting').toString(),
          to: `/organizations/${this.$attrs.organizationId}`,
        },
        {
          label: this.$t('iam.label.recapConfiguration').toString(),
          to: `/organizations/${this.$attrs.organizationId}/recap-configuration/recap-receivers`,
        },
        {
          label: this.$t('iam.label.mailSetting').toString(),
          to: `/organizations/${this.$attrs.organizationId}/email-setting/members`,
        },
      ]
    },
    currentTabIndex(): number {
      const parentName = this.$route.meta?.parentName
      switch (parentName) {
        case 'RecapConfiguration':
          return ESQUARE_ORGANIZATION_TAB_INDEXES.RECAP
        case 'EmailSetting':
          return ESQUARE_ORGANIZATION_TAB_INDEXES.EMAIL
        default:
          return ESQUARE_ORGANIZATION_TAB_INDEXES.BASIC
      }
    },
  },
  async created() {
    await this.fetchOrganizationProfile({
      organizationId: this._organizationId,
    })
      .then(() => {
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...organizationProfileMapActions(['fetchOrganizationProfile']),
  },
})
