import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86e8e088"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-dropdown" }
const _hoisted_2 = { class: "dropdown-trigger" }
const _hoisted_3 = ["aria-controls"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "action-dropdown__content dropdown-content" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown", { 'is-right': _ctx.isRight, 'is-active': _ctx.isActive }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(["button action-dropdown__button", [
            'is-' + _ctx.buttonSize,
            _ctx.isParentActive ? 'is-light' : 'is-white',
          ]]),
              type: "button",
              "aria-haspopup": "true",
              "aria-controls": `dropdown-menu-${_ctx.id}`
            }, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createVNode(_component_base_icon, {
                  class: "action-dropdown__icon",
                  "icon-name": "ellipsis-h"
                })
              ], true)
            ], 10, _hoisted_3))
          : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
              _createVNode(_component_base_icon, {
                class: "action-dropdown__icon",
                "icon-name": "ellipsis-h"
              })
            ], true)
      ]),
      _createElementVNode("div", {
        id: `dropdown-menu-${_ctx.id}`,
        class: "dropdown-menu",
        role: "menu"
      }, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
            return (_openBlock(), _createElementBlock("a", {
              key: action.label,
              class: _normalizeClass(["action-dropdown__item dropdown-item", { 'action-dropdown__item--disabled': action.disabled }]),
              onClick: _withModifiers(($event: any) => (_ctx.onClick(action.eventName, index)), ["stop"])
            }, _toDisplayString(action.label), 11, _hoisted_6))
          }), 128))
        ])
      ], 8, _hoisted_4)
    ], 2)
  ]))
}