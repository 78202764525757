import { Mutations } from 'vuex-smart-module'

import { NegotiationState } from '@/store/modules/trading/negotiation/NegotiationState'
import { NegotiationFilterConditions } from '@/store/modules/trading/negotiation/interface'

export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION'

export class NegotiationMutations extends Mutations<NegotiationState> {
  [SET_FILTER_CONDITION](payload: {
    filterCondition: NegotiationFilterConditions
  }) {
    this.state.filterCondition = payload.filterCondition
  }
}
