// https://auth0.com/docs/quickstart/spa/vuejs
import { RouteLocationNormalized } from 'vue-router'

import { getInstance } from '@/plugins/auth/'

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: CallableFunction,
) => {
  if (to.meta?.isPublic) {
    return next()
  }

  const authService = getInstance()

  const fn = () => {
    if (authService.isAuthenticated) {
      return next()
    }

    const path = `${process.env.VUE_APP_BASE_URL}`.endsWith('/')
      ? process.env.VUE_APP_BASE_URL
      : `${process.env.VUE_APP_BASE_URL}/`

    authService.loginWithRedirect({
      connection: process.env.VUE_APP_AUTH_CONNECTION,
      appState: { targetUrl: to.fullPath },
      redirect_uri: `${location.origin}${path}`,
    })
  }

  if (!authService.loading) {
    return fn()
  }

  const timer = setInterval(() => {
    if (!authService.loading) {
      fn()
      clearInterval(timer)
    }
  }, 100)
}
