import { Getters } from 'vuex-smart-module'

import { validateName } from '@/components/iam/validation'
import { WelcomeState } from '@/store/modules/iam/welcome/WelcomeState'

export class WelcomeGetters extends Getters<WelcomeState> {
  get isValidWelcome(): boolean {
    return validateName(this.state.userName)
  }
}
