
import { defineComponent, PropType } from 'vue'

import BaseDropdown from '@/components/common/BaseDropdown.vue'
import { DropdownNavigationItem } from '@/components/common/interface/DropdownNavigationProps'

export default defineComponent({
  name: 'DropdownNavigation',
  components: {
    BaseDropdown,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<DropdownNavigationItem[]>,
      default: () => [],
    },
  },
})
