import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c10f4010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "occto-master-item-form" }
const _hoisted_2 = { class: "occto-master-item-form__row" }
const _hoisted_3 = { class: "occto-master-item-form__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_radio = _resolveComponent("base-input-radio")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_input_text = _resolveComponent("base-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_radio, {
        label: _ctx.$t('iam.label.occtoMasterType'),
        name: "occtoMasterType",
        value: _ctx.formValue.type,
        options: _ctx.typeOptions,
        mode: _ctx.typeMode,
        rules: "required",
        required: "",
        onInput: _cache[0] || (_cache[0] = 
          event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, type: event })
        )
      }, null, 8, ["label", "value", "options", "mode"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('trading.label.area'),
        name: "area",
        value: _ctx.formValue.area,
        options: _ctx.areaOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.area'),
          })
        ,
        rules: "required",
        required: "",
        onInput: _cache[1] || (_cache[1] = 
          event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, area: event })
        )
      }, null, 8, ["label", "value", "options", "placeholder"]),
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.companyPrefix'),
        name: "companyPrefix",
        value: _ctx.formValue.companyPrefix,
        rules: `required|companyPrefix:${_ctx.areaCode}`,
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.$t('iam.label.companyPrefix'),
          })
        ,
        required: "",
        onInput: _cache[2] || (_cache[2] = event => _ctx.onInputWithToUpperCase('companyPrefix', event))
      }, null, 8, ["label", "value", "rules", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_input_text, {
        label: _ctx.nameLabel,
        name: "name",
        value: _ctx.formValue.name,
        rules: "required|max:25",
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.nameLabel,
          })
        ,
        required: "",
        onInput: _cache[3] || (_cache[3] = 
          event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, name: event })
        )
      }, null, 8, ["label", "value", "placeholder"]),
      _createVNode(_component_base_input_text, {
        label: _ctx.codeLabel,
        name: "code",
        value: _ctx.formValue.code,
        rules: _ctx.codeRules,
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.codeLabel,
          })
        ,
        required: "",
        onInput: _cache[4] || (_cache[4] = event => _ctx.onInputWithToUpperCase('code', event))
      }, null, 8, ["label", "value", "rules", "placeholder"])
    ])
  ]))
}