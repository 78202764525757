import { Type } from 'class-transformer'

import {
  Locale,
  Organization as IOrganization,
  OrganizationType,
  ReadableReportType,
} from '@/api/generated'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'

export class Organization implements IOrganization {
  organizationId!: string
  organizationType!: OrganizationType

  @Type(() => MultiLocaleName)
  name!: MultiLocaleName

  @Type(() => MultiLocaleName)
  tradingCompanyName!: MultiLocaleName

  abbreviation?: string
  picture!: string
  locale!: Locale
  accountId?: string
  readableReportTypes!: ReadableReportType[]
  recapAddress!: string

  @Type(() => OrganizationEmailAddress)
  emailAddresses!: OrganizationEmailAddress[]

  isEClearContracted!: boolean
  isEClearCreditSleever!: boolean
  isSalesTaxIncludedInInvoice!: boolean
  isEsquareEnabled!: boolean
  isEcompassEnabled!: boolean
  isEcompassForGxEnabled!: boolean
}
