import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'

export class ProductState {
  productTypes = [] as ProductType[]
  areas = [] as Area[]
  startDeliveryYearMonths = [] as DeliveryYearMonth[]
  endDeliveryYearMonths = [] as DeliveryYearMonth[]
  deliveryYearMonths = [] as DeliveryYearMonth[]
  hourTypes = [] as HourType[]
  fuelSurchargeTypes = [] as FuelSurchargeType[]
}
