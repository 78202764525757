import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ce0e69b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "localized-reports" }
const _hoisted_2 = { class: "localized-reports__list" }
const _hoisted_3 = {
  key: 0,
  class: "localized-reports__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_report_list = _resolveComponent("localized-report-list")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_localized_report = _resolveComponent("localized-report")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_trader_page = _resolveComponent("trader-page")!

  return (_openBlock(), _createBlock(_component_trader_page, {
    title: _ctx.$t('common.label.reports'),
    "active-menu-item": "reports"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_localized_report_list, {
            reports: _ctx.reports,
            onLastItemVisible: _ctx.onLastItemVisible,
            onItemClick: _ctx.onItemClick,
            onDownloadClick: _ctx.onDownloadClick
          }, null, 8, ["reports", "onLastItemVisible", "onItemClick", "onDownloadClick"]),
          (!_ctx.isLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_centered_loading_content, { type: "grayscale" })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_base_modal, {
          name: _ctx.modalName,
          title: _ctx.currentReportTitle,
          width: 850,
          "show-close-button": true,
          onClosed: _ctx.onModalClosed
        }, {
          default: _withCtx(() => [
            (!!_ctx.currentReport)
              ? (_openBlock(), _createBlock(_component_localized_report, {
                  key: 0,
                  report: _ctx.currentReport,
                  onDownloadClick: _ctx.onDownloadClick
                }, null, 8, ["report", "onDownloadClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["name", "title", "onClosed"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}