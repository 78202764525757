
import { defineComponent, PropType } from 'vue'

import {
  IndicationStatusType,
  PositionType,
  Locale,
  DeliveryUnit,
} from '@/api/generated'
import BaseTag from '@/components/common/BaseTag.vue'
import OldDeliveryTerms from '@/components/trading/OldDeliveryTerms.vue'
import OrderBookBestPrice from '@/components/trading/OrderBookBestPrice.vue'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndicationOfProduct } from '@/models/trading/BrokingIndicationOfProduct'
import { DeliveryTerms as DeliveryTermsModel } from '@/models/trading/DeliveryTerms'

export default defineComponent({
  name: 'BrokingOrderBookSummary',
  components: {
    BaseTag,
    OldDeliveryTerms,
    OrderBookBestPrice,
  },
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTermsModel>,
      required: true,
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
    indications: {
      type: Array as PropType<BrokingIndicationOfProduct[]>,
      default: () => [],
    },
    selectedIndicationIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    deal: {
      type: Object as PropType<BrokingDeal>,
      default: undefined,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedProductIds: [] as string[],
      expandedDeliveryTermsIds: [] as string[],
    }
  },
  computed: {
    fuelSurchargeTypeName(): string | undefined {
      const fuelSurchargeType =
        this.indications[0]?.fuelSurchargeType || this.deal?.fuelSurchargeType
      return fuelSurchargeType?.name.translation()
    },
    bestIndicationAsk(): BrokingIndicationOfProduct | undefined {
      const askIndications = this.indications.filter(
        indication => indication.position === PositionType.Ask,
      )

      if (askIndications.length > 0) {
        return askIndications.reduce((a, b) =>
          a.unitPrice < b.unitPrice ? a : b,
        )
      }
      return undefined
    },
    bestIndicationBid(): BrokingIndicationOfProduct | undefined {
      const bidIndications = this.indications.filter(
        indication => indication.position === PositionType.Bid,
      )

      if (bidIndications.length > 0) {
        return bidIndications.reduce((a, b) =>
          a.unitPrice > b.unitPrice ? a : b,
        )
      }
      return undefined
    },
    unconfirmedIndicationsCount(): number {
      return this.indications.filter(
        indication => indication.status === IndicationStatusType.Unconfirmed,
      ).length
    },
  },
  methods: {},
})
