import { Type } from 'class-transformer'

import {
  BrokingOrderBook as IBrokingOrderBook,
  DeliveryUnit,
  SpreadType,
} from '@/api/generated'
import { SPREAD_SYMBOL } from '@/components/common/constants/Spread'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { BrokingOrder } from '@/models/trading/BrokingOrder'
import { SpreadDeliveryTerms } from '@/models/trading/SpreadDeliveryTerms'

export class BrokingOrderBook implements IBrokingOrderBook {
  deliveryTermsId!: string
  exchangeDeliveryTermsId?: string
  deliveryUnit!: DeliveryUnit
  askUnitPrice?: string
  bidUnitPrice?: string
  lastUnitPrice?: string
  spreadTypes?: SpreadType[]
  executedAt?: string
  lastDealPrivateConfidential?: boolean

  @Type(() => SpreadDeliveryTerms)
  base!: SpreadDeliveryTerms

  @Type(() => SpreadDeliveryTerms)
  exchange?: SpreadDeliveryTerms

  @Type(() => BrokingOrder)
  orders!: BrokingOrder[]

  get isSpread(): boolean {
    return !!this.exchange
  }

  get isAreaSpread(): boolean {
    return !!this.exchange?.area && this.base.area.id !== this.exchange?.area.id
  }

  get isDeliveryPeriodSpread(): boolean {
    return (
      !!this.exchange?.deliveryPeriod &&
      this.base.deliveryPeriod !== this.exchange?.deliveryPeriod
    )
  }

  get isDeliveryPatternSpread(): boolean {
    return (
      !!this.exchange?.hourType &&
      this.base.hourType.name !== this.exchange?.hourType.name
    )
  }

  get id(): string {
    return this.deliveryTermsId + (this.exchangeDeliveryTermsId ?? '')
  }

  deliveryTerms(): DeliveryTermsWithPeriodProps {
    const areaName = this.isAreaSpread
      ? `${this.base.area.name} ${SPREAD_SYMBOL} ${this.exchange?.area.name}`
      : this.base.area.name

    const deliveryPeriod = this.isDeliveryPeriodSpread
      ? `${this.base.deliveryPeriod} ${SPREAD_SYMBOL} ${this.exchange?.deliveryPeriod}`
      : this.base.deliveryPeriod

    const deliveryPatternSpread = this.isDeliveryPatternSpread
      ? `${this.base.hourType.name} ${SPREAD_SYMBOL} ${this.exchange?.hourType.name}`
      : this.base.hourType.name

    return {
      isAreaSpread: this.isAreaSpread,
      isDeliveryPeriodSpread: this.isDeliveryPeriodSpread,
      isDeliveryPatternSpread: this.isDeliveryPatternSpread,
      areaName,
      deliveryPeriod,
      hourTypeName: deliveryPatternSpread,
    }
  }
}
