import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0097c1b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-recap-receiver" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'selected-recap-receiver__container--selectable': _ctx.isSelectable,
      }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.selectedRecapReceiver.name), 1),
      (_ctx.selectedRecapReceiver.department)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('trading.label.department')) + ": " + _toDisplayString(_ctx.selectedRecapReceiver.displayDepartment), 1))
        : _createCommentVNode("", true),
      (_ctx.selectedRecapReceiver.phoneNumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('trading.label.phoneNumber')) + ": " + _toDisplayString(_ctx.selectedRecapReceiver.displayPhoneNumber), 1))
        : _createCommentVNode("", true),
      (_ctx.selectedRecapReceiver.emailAddress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('trading.label.emailAddress')) + ": " + _toDisplayString(_ctx.selectedRecapReceiver.displayEmailAddress), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}