import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d5a4be3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eclear-trading-member-selector-modal" }
const _hoisted_2 = { class: "eclear-trading-member-selector-modal__select" }
const _hoisted_3 = { class: "eclear-trading-member-selector-modal__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_single_select, {
        class: "eclear-trading-member-selector-modal__single-select",
        name: "selectedAccount",
        label: _ctx.$t('iam.label.addEclearTradingMember'),
        value: _ctx.selectedMember,
        options: _ctx.options,
        placeholder: _ctx.$t('iam.label.selectMember'),
        "options-label": "label",
        required: "",
        searchable: true,
        onInput: _ctx.onInput
      }, null, 8, ["label", "value", "options", "placeholder", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_button, {
        class: "eclear-trading-member-selector-modal__cancel-button",
        onClick: _ctx.onCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_base_button, {
        class: "eclear-trading-member-selector-modal__confirm-button",
        color: "primary",
        disabled: !_ctx.complete,
        loading: _ctx.submitting,
        onClick: _ctx.onConfirm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.add')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ])
  ]))
}