
import { defineComponent } from 'vue'

import BrokerPage from '@/components/common/BrokerPage.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { isBrokerApp } from '@/utils/env'

const PageTemplate = isBrokerApp ? BrokerPage : TraderPage

export default defineComponent({
  name: 'ServiceInformation',
  components: {
    PageTemplate,
  },
  computed: {
    layoutMode(): LayoutMode {
      return LayoutMode.SuperWide
    },
  },
})
