
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { RequestOrganizationEmailAddress } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import ReceiveEmailForm from '@/components/iam/ReceiveEmailForm.vue'
import { ReceiveEmailFormProps } from '@/components/iam/interface/ReceiveEmailFormProps'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { OrganizationEmailAddressModule } from '@/store/modules/iam/organizationEmailAddress'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: organizationEmailAddressMapActions,
} = createNamespacedHelpers(
  'organizationEmailAddress',
) as OrganizationEmailAddressModule

export default defineComponent({
  name: 'OrganizationEmailAddress',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseBox,
    BaseButton,
    BaseInputText,
    CenteredLoadingContent,
    ConfirmationDialog,
    ParticipantSummary,
    ReceiveEmailForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    modalName: string
    isLoading: boolean
    isDropdownActive: boolean
    deleteSubmitting: boolean
    organizationEmailAddress: RequestOrganizationEmailAddress
    actionItems: ActionDropdownItem[]
    createdUserName: string
    createdAt: string
    lastUpdatedUserName: string
    updatedAt: string
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      modalName: 'delete-organization-email-address',
      isLoading: false,
      isDropdownActive: false,
      deleteSubmitting: false,
      organizationEmailAddress: {
        name: '',
        emailAddress: '',
        receiveIndicationEmail: false,
        receiveLiveMarketEmail: false,
        receiveSpotTradingRecapEmail: false,
        receiveDerivativeTradingRecapEmail: false,
        receiveReportEmail: false,
        receiveInvoiceEmail: false,
      },
      actionItems: [
        {
          label: this.$t('iam.label.deleteOrganizationEmailAddress').toString(),
          eventName: 'delete-click',
        },
      ],
      createdUserName: '',
      lastUpdatedUserName: '',
      createdAt: '',
      updatedAt: '',
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    dropdownId(): string {
      return `organization-email-address-list-item__delete-${this.$attrs.organizationEmailAddressId}`
    },
    emailFormValue(): ReceiveEmailFormProps {
      return {
        receiveIndicationEmail: this.organizationEmailAddress
          .receiveIndicationEmail,
        receiveLiveMarketEmail: this.organizationEmailAddress
          .receiveLiveMarketEmail,
        receiveSpotTradingRecapEmail: this.organizationEmailAddress
          .receiveSpotTradingRecapEmail,
        receiveDerivativeTradingRecapEmail: this.organizationEmailAddress
          .receiveDerivativeTradingRecapEmail,
        receiveReportEmail: this.organizationEmailAddress.receiveReportEmail,
        receiveInvoiceEmail: this.organizationEmailAddress.receiveInvoiceEmail,
      }
    },
  },
  async created() {
    await (this.fetchOrganizationEmailAddress as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _: any,
    ) => Promise<OrganizationEmailAddress>)({
      organizationId: this._organizationId,
      organizationEmailAddressId: this.$attrs.organizationEmailAddressId,
    })
      .then(
        ({
          name,
          emailAddress,
          receiveIndicationEmail,
          receiveLiveMarketEmail,
          receiveSpotTradingRecapEmail,
          receiveDerivativeTradingRecapEmail,
          receiveReportEmail,
          receiveInvoiceEmail,
          createdUserName,
          lastUpdatedUserName,
          createdAt,
          updatedAt,
        }: OrganizationEmailAddress) => {
          this.organizationEmailAddress.name = name
          this.organizationEmailAddress.emailAddress = emailAddress
          this.organizationEmailAddress.receiveIndicationEmail = receiveIndicationEmail
          this.organizationEmailAddress.receiveLiveMarketEmail = receiveLiveMarketEmail
          this.organizationEmailAddress.receiveSpotTradingRecapEmail = receiveSpotTradingRecapEmail
          this.organizationEmailAddress.receiveDerivativeTradingRecapEmail = receiveDerivativeTradingRecapEmail
          this.organizationEmailAddress.receiveReportEmail = receiveReportEmail
          this.organizationEmailAddress.receiveInvoiceEmail = receiveInvoiceEmail

          this.createdUserName = createdUserName || ''
          this.lastUpdatedUserName = lastUpdatedUserName || ''
          this.createdAt = createdAt
          this.updatedAt = updatedAt

          this.uiStack = UiStack.Ideal
        },
      )
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...organizationEmailAddressMapActions([
      'fetchOrganizationEmailAddress',
      'deleteOrganizationEmailAddress',
      'updateOrganizationEmailAddress',
    ]),
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    async onClick() {
      this.isLoading = true
      await this.updateOrganizationEmailAddress({
        organizationId: this._organizationId,
        organizationEmailAddressId: this.$attrs.organizationEmailAddressId,
        organizationEmailAddress: this.organizationEmailAddress,
      })
        .then(() => {
          setNotification(
            this.$t(
              'iam.message.successSaveOrganizationEmailAddress',
            ).toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          const message =
            e.response.status === HttpStatus.Conflict
              ? this.$t(
                  'iam.message.failUpdateOrganizationEmailAddressByDuplicate',
                  {
                    mail: this.organizationEmailAddress.emailAddress,
                  },
                ).toString()
              : this.$t(
                  'iam.message.failUpdateOrganizationEmailAddress',
                ).toString()
          setNotification(message, 'danger')
          throw e
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onInput(value: ReceiveEmailFormProps) {
      Object.assign(this.organizationEmailAddress, value)
    },
    onDelete() {
      this.$vfm.open(this.modalName)
    },
    onDeleteCancel() {
      this.deleteSubmitting = false
      this.$vfm.close(this.modalName)
    },
    async onDeleteConfirm() {
      this.deleteSubmitting = true
      await this.deleteOrganizationEmailAddress({
        organizationId: this._organizationId,
        organizationEmailAddressId: this.$attrs.organizationEmailAddressId,
      })
        .then(() => {
          setNotification(
            this.$t(
              'iam.message.successDeleteOrganizationEmailAddress',
            ).toString(),
          )
          this.$vfm.close(this.modalName)
          this.backListPage()
        })
        .catch(_ => {
          setNotification(
            this.$t(
              'iam.message.failDeleteOrganizationEmailAddress',
            ).toString(),
            'danger',
          )
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
  },
})
