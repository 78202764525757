import { Type } from 'class-transformer'

import { DeliveryPatternComponent as IDeliveryPatternComponent } from '@/api/generated'
import { DayPattern } from '@/models/trading/DayPattern'
import { TimeRange } from '@/models/trading/TimeRange'

const halfHourRegex = /\.5$/

export class DeliveryPatternComponent implements IDeliveryPatternComponent {
  @Type(() => DayPattern)
  dayPattern!: DayPattern

  @Type(() => TimeRange)
  timeRange!: TimeRange

  get startLabel(): string {
    const start = this.timeRange.start.toString()
    return halfHourRegex.test(start)
      ? `${start.replace(halfHourRegex, '')}:30`
      : `${start}:00`
  }

  get endLabel(): string {
    const end = this.timeRange.end.toString()
    return halfHourRegex.test(end)
      ? `${end.replace(halfHourRegex, '')}:30`
      : `${end}:00`
  }

  displayTimeRange(): string {
    return `${this.startLabel} - ${this.endLabel}`
  }

  displayDayPatternName(): string {
    return this.dayPattern.translation()
  }
}
