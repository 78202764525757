import { DMChannel } from '@/models/chat/DMChannel'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'

export class ChannelList {
  organizationChannels: OrganizationChannel[]
  dMChannels: DMChannel[]

  constructor({
    organizationChannels = [],
    dMChannels = [],
  }: {
    organizationChannels?: OrganizationChannel[]
    dMChannels?: DMChannel[]
  } = {}) {
    this.organizationChannels = organizationChannels
    this.dMChannels = dMChannels
  }

  channelOfChannelId(
    channelId: string,
  ): OrganizationChannel | DMChannel | undefined {
    return (
      this.organizationChannels.find(
        channel => channel.channelId === channelId,
      ) || this.dMChannels.find(channel => channel.channelId === channelId)
    )
  }

  get hasUnreadMessage(): boolean {
    return (
      this.organizationChannels.some(channel => channel.hasUnreadMessage) ||
      this.dMChannels.some(channel => channel.hasUnreadMessage)
    )
  }

  get getTotalNumberOfUnreadMessage(): number {
    let totalNumber = 0
    for (const channel of this.organizationChannels) {
      totalNumber =
        totalNumber +
        channel.getNumberOfUnreadMessage +
        channel.counter -
        channel.counter
    }
    for (const channel of this.dMChannels) {
      totalNumber =
        totalNumber +
        channel.getNumberOfUnreadMessage +
        channel.counter -
        channel.counter
    }
    return totalNumber
  }
}
