import { Mutations } from 'vuex-smart-module'

import { OrganizationsState } from '@/store/modules/iam/organizations/OrganizationsState'

export const SET_ORGANIZATION_SEARCH_INPUT = 'SET_ORGANIZATION_SEARCH_INPUT'

export class OrganizationsMutations extends Mutations<OrganizationsState> {
  [SET_ORGANIZATION_SEARCH_INPUT](payload: { searchInput: string }) {
    this.state.searchInput = payload.searchInput
  }
}
