import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b132ef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-book-list" }
const _hoisted_2 = { class: "broking-order-book-list__header-wrapper" }
const _hoisted_3 = { class: "broking-order-book-list__body-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_broking_order_book_spread_list_header = _resolveComponent("broking-order-book-spread-list-header")!
  const _component_broking_order_book_spread_item = _resolveComponent("broking-order-book-spread-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_broking_order_book_spread_list_header, {
        "color-reversed": _ctx.colorReversed,
        "position-reversed": _ctx.positionReversed,
        "all-collapsed": _ctx.allCollapsed,
        "is-spread": "",
        onActivateAll: _ctx.onActivateAll,
        onDeactivateAll: _ctx.onDeactivateAll
      }, null, 8, ["color-reversed", "position-reversed", "all-collapsed", "onActivateAll", "onDeactivateAll"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBrokingOrderBooks, (item) => {
        return (_openBlock(), _createBlock(_component_broking_order_book_spread_item, {
          key: item.id,
          "order-book": item,
          "color-reversed": _ctx.colorReversed,
          "position-reversed": _ctx.positionReversed,
          "is-expanded": _ctx.isExpandedOf(item.id),
          "selected-indication-ids": _ctx.selectedIndicationIds,
          onClick: ($event: any) => (_ctx.onClick(item.id)),
          onRecordSelect: _ctx.onRecordSelect,
          onCopyIndication: _ctx.onCopyIndication
        }, null, 8, ["order-book", "color-reversed", "position-reversed", "is-expanded", "selected-indication-ids", "onClick", "onRecordSelect", "onCopyIndication"]))
      }), 128))
    ])
  ]))
}