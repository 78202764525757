import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7545b30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-book-command" }
const _hoisted_2 = {
  key: 0,
  class: "broking-order-book-command__list"
}
const _hoisted_3 = {
  key: 0,
  class: "broking-order-book-command__icon--disabled"
}
const _hoisted_4 = {
  key: 1,
  class: "broking-order-book-command__container--empty"
}
const _hoisted_5 = {
  key: 0,
  class: "broking-order-book-command__icon--disabled"
}
const _hoisted_6 = {
  key: 3,
  class: "broking-order-book-command__container--empty"
}
const _hoisted_7 = { class: "broking-order-book-command__action-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.commandType === 'indication')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.isNegotiating)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["broking-order-book-command__container", {
          'broking-order-book-command__container--submitting': _ctx.isOpenToMarketSubmitting,
        }]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onOpenToMarketChange && _ctx.onOpenToMarketChange(...args)))
              }, [
                (!_ctx.isEclearIndication)
                  ? (_openBlock(), _createBlock(_component_base_tooltip, {
                      key: 0,
                      label: _ctx.$t('trading.label.openToMarketStatus')
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.openToMarket)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_3))
                          : _createCommentVNode("", true),
                        _createVNode(_component_base_icon, { "icon-name": "exchange-alt" })
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true)
              ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_4)),
          (!_ctx.isNegotiating)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["broking-order-book-command__container", {
          'broking-order-book-command__container--submitting': _ctx.isVisibleToTradersSubmitting,
        }]),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onVisibleToTradersChange && _ctx.onVisibleToTradersChange(...args)))
              }, [
                (!_ctx.isEclearIndication)
                  ? (_openBlock(), _createBlock(_component_base_tooltip, {
                      key: 0,
                      label: _ctx.$t('trading.label.isVisibleToTradersStatus')
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.isVisibleToTraders)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_5))
                          : _createCommentVNode("", true),
                        _createVNode(_component_base_icon, { "icon-name": "history" })
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true)
              ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_6)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_action_dropdown, {
              id: _ctx.dropdownId,
              actions: _ctx.actions,
              "is-active": _ctx.isActionDropdownActive,
              "is-button": false,
              "is-right": "",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isActionDropdownActive = !_ctx.isActionDropdownActive), ["stop"])),
              onClickOutside: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isActionDropdownActive = false)),
              onBroadcast: _ctx.onBroadcastClick,
              onCopyClipboard: _ctx.onCopyClipboard,
              onCopyIndication: _ctx.onCopyIndication,
              onToDetail: _ctx.onOpenDetailClick,
              onUpdateIndication: _ctx.onUpdatingIndicationClick
            }, null, 8, ["id", "actions", "is-active", "onBroadcast", "onCopyClipboard", "onCopyIndication", "onToDetail", "onUpdateIndication"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}