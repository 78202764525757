import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  NegotiationsApi,
  UpdateNegotiation,
  NewNegotiation,
  NegotiationStatusType,
  DefaultBrokingFeeOfOrganizationsApi,
} from '@/api/generated'
import { DefaultBrokingFees } from '@/models/trading/DefaultBrokingFees'
import { DeliverySummaries } from '@/models/trading/DeliverySummaries'
import { Negotiation } from '@/models/trading/Negotiation'
import { TradingSteps } from '@/models/trading/TradingSteps'
import { IUpdateNegotiation } from '@/models/trading/UpdateNegotiation'
import { NegotiationGetters } from '@/store/modules/trading/negotiation/NegotiationGetters'
import { NegotiationMutations } from '@/store/modules/trading/negotiation/NegotiationMutations'
import { NegotiationState } from '@/store/modules/trading/negotiation/NegotiationState'

export class NegotiationActions extends Actions<
  NegotiationState,
  NegotiationGetters,
  NegotiationMutations,
  NegotiationActions
> {
  async fetchNegotiations({
    lastId,
    areaId,
    hourTypeId,
    productTypeId,
    organizationName,
    publicNegotiationId,
    status,
    startDeliveryYearMonth,
    endDeliveryYearMonth,
  }: {
    lastId?: string
    areaId?: string
    hourTypeId?: string
    productTypeId?: string
    organizationName?: string
    publicNegotiationId?: string
    status?: NegotiationStatusType
    startDeliveryYearMonth?: string
    endDeliveryYearMonth?: string
  }): Promise<Negotiation[]> {
    return plainToClass(
      Negotiation,
      (
        await new NegotiationsApi(apiConfig).getNegotiations(
          lastId,
          areaId,
          hourTypeId,
          productTypeId,
          organizationName,
          publicNegotiationId,
          status,
          startDeliveryYearMonth,
          endDeliveryYearMonth,
        )
      ).data,
    )
  }

  async fetchNegotiation(id: string): Promise<Negotiation> {
    return plainToClass(
      Negotiation,
      (await new NegotiationsApi(apiConfig).getNegotiation(id)).data,
    )
  }

  async fetchDeliverySummaries(id: string): Promise<DeliverySummaries> {
    return plainToClass(
      DeliverySummaries,
      (await new NegotiationsApi(apiConfig).getNegotiationDeliverySummary(id))
        .data,
    )
  }

  async fetchTradingSteps(id: string): Promise<TradingSteps> {
    return plainToClass(
      TradingSteps,
      (await new NegotiationsApi(apiConfig).getNegotiationTradingSteps(id))
        .data,
    )
  }

  async updateNegotiation({
    id,
    updateNegotiation,
  }: {
    id: string
    updateNegotiation: IUpdateNegotiation
  }): Promise<Negotiation> {
    return plainToClass(
      Negotiation,
      (
        await new NegotiationsApi(apiConfig).updateNegotiation(
          id,
          updateNegotiation as UpdateNegotiation,
        )
      ).data,
    )
  }

  async deleteNegotiation({
    id,
    memo,
  }: {
    id: string
    memo?: string
  }): Promise<void> {
    await new NegotiationsApi(apiConfig).deleteNegotiation(id, memo)
  }

  async createNegotiation(negotiation: NewNegotiation): Promise<Negotiation> {
    return plainToClass(
      Negotiation,
      (await new NegotiationsApi(apiConfig).newNegotiation(negotiation)).data,
    )
  }

  async fetchDefaultBrokingFees(
    organizationId: string,
  ): Promise<DefaultBrokingFees[]> {
    return plainToClass(
      DefaultBrokingFees,
      (
        await new DefaultBrokingFeeOfOrganizationsApi(
          apiConfig,
        ).getDefaultBrokingFeeOfOrganization(organizationId)
      ).data,
    )
  }
}
