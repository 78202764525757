import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ecd9399"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negotiation" }
const _hoisted_2 = {
  key: 0,
  class: "negotiation__dropdown"
}
const _hoisted_3 = { class: "negotiation__trading-steps" }
const _hoisted_4 = { class: "negotiation__summary" }
const _hoisted_5 = { class: "negotiation__form" }
const _hoisted_6 = { class: "negotiation__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_trading_steps = _resolveComponent("trading-steps")!
  const _component_delivery_summaries = _resolveComponent("delivery-summaries")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_participant_summary = _resolveComponent("participant-summary")!
  const _component_negotiation_form = _resolveComponent("negotiation-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_negotiation_confirm_modal_content = _resolveComponent("negotiation-confirm-modal-content")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.negotiation'),
    "active-menu-item": "trading"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failInitializeNegotiation')), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          ideal: _withCtx(() => [
            (!_ctx.executed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_action_dropdown, {
                    id: "negotiation-action-dropdown",
                    actions: _ctx.actionDropdownItems,
                    "is-active": _ctx.actionDropdownIsActive,
                    "is-right": "",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                _ctx.actionDropdownIsActive = !_ctx.actionDropdownIsActive
              ), ["stop"])),
                    onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actionDropdownIsActive = false)),
                    onDeleteNegotiationClick: _ctx.onDeleteNegotiationClick
                  }, null, 8, ["actions", "is-active", "onDeleteNegotiationClick"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_validation_form, null, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_trading_steps, {
                    "trading-steps": _ctx.tradingSteps,
                    "color-reversed": 
                  _ctx.userProfile.services.esquare.userSetting.colorReversed
                
                  }, null, 8, ["trading-steps", "color-reversed"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_base_box, { class: "negotiation__summary-box" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_delivery_summaries, {
                        class: "negotiation__delivery-summary",
                        "delivery-summaries": _ctx.deliverySummaries,
                        products: [_ctx.product],
                        "fuel-surcharge-type": 
                    _ctx.negotiation.askNegotiationDetail.indication
                      .fuelSurchargeType
                  ,
                        "is-swing-option": _ctx.isSwingOption
                      }, null, 8, ["delivery-summaries", "products", "fuel-surcharge-type", "is-swing-option"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_participant_summary, {
                    class: "negotiation__participant-summary",
                    "created-participant-name": _ctx.negotiation.createdParticipantName,
                    "last-updated-participant-name": 
                  _ctx.negotiation.lastUpdatedParticipantName
                ,
                    "created-at": _ctx.negotiation.createdAt,
                    "updated-at": _ctx.negotiation.updatedAt,
                    direction: _ctx.participantSummaryDirection
                  }, null, 8, ["created-participant-name", "last-updated-participant-name", "created-at", "updated-at", "direction"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_negotiation_form, {
                    mode: _ctx.mode,
                    "is-standard-spread": _ctx.isStandardSpread,
                    "is-swing-option": _ctx.negotiation.isSwingOption,
                    "color-reversed": 
                  _ctx.userProfile.services.esquare.userSetting.colorReversed
                ,
                    product: _ctx.product,
                    "product-types": _ctx.productTypes,
                    "calendar-type-input-options": _ctx.calendarTypeInputOptions,
                    "form-value": _ctx.formValue,
                    "public-negotiation-id": _ctx.negotiation.publicNegotiationId,
                    "eclear-organization-id": _ctx.eclearOrganizationId,
                    "eclear-product-type-id-list": _ctx.eclearProductTypeIdList,
                    "is-required-memo": _ctx.isRequiredMemo,
                    "is-volume-required": 
                  _ctx.formValue.status === _ctx.NegotiationStatusType.Executed
                ,
                    onFormInput: _ctx.onFormInput,
                    onCreditSleeverAdd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setCreditSleeverIsShown(true))),
                    onCreditSleeverRemove: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setCreditSleeverIsShown(false))),
                    onMemoInput: _ctx.onMemoInput
                  }, null, 8, ["mode", "is-standard-spread", "is-swing-option", "color-reversed", "product", "product-types", "calendar-type-input-options", "form-value", "public-negotiation-id", "eclear-organization-id", "eclear-product-type-id-list", "is-required-memo", "is-volume-required", "onFormInput", "onMemoInput"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    disabled: 
                  Object.keys(errors).length > 0 ||
                    !_ctx.hasCreditSleever ||
                    _ctx.isNotChangeableStatus
                ,
                    color: "primary",
                    onClick: _ctx.onConfirmClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.updateModalName,
        title: _ctx.updateConfirmModalTitle,
        "confirm-button-label": _ctx.updateConfirmModalButtonLabel,
        "confirm-button-color": _ctx.updateConfirmModalButtonColor,
        loading: _ctx.updateSubmitting,
        width: 650,
        onConfirm: _ctx.onUpdateNegotiationConfirm,
        onCancel: _ctx.onUpdateNegotiationCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_negotiation_confirm_modal_content, {
            mode: _ctx.mode,
            "form-value": _ctx.formValue,
            locale: _ctx.userProfile.locale,
            message: _ctx.updateConfirmModalMessage,
            product: _ctx.product,
            "is-standard-spread": _ctx.isStandardSpread,
            "is-swing-option": _ctx.negotiation.isSwingOption
          }, null, 8, ["mode", "form-value", "locale", "message", "product", "is-standard-spread", "is-swing-option"])
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "confirm-button-color", "loading", "onConfirm", "onCancel"]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.deleteModalName,
        title: _ctx.$t('trading.message.negotiationDeleteConfirmModalTitle'),
        "confirm-button-label": _ctx.$t('common.label.delete'),
        "confirm-button-color": "danger",
        loading: _ctx.deleteSubmitting,
        width: 650,
        onConfirm: _ctx.onDeleteNegotiationConfirm,
        onCancel: _ctx.onDeleteNegotiationCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_negotiation_confirm_modal_content, {
            mode: _ctx.mode,
            "form-value": _ctx.formValue,
            locale: _ctx.userProfile.locale,
            message: _ctx.$t('trading.message.negotiationDeleteConfirmModalMessage'),
            product: _ctx.product,
            "is-standard-spread": _ctx.isStandardSpread,
            "is-swing-option": _ctx.negotiation.isSwingOption
          }, null, 8, ["mode", "form-value", "locale", "message", "product", "is-standard-spread", "is-swing-option"])
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}