
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputOption } from '@/components/common/interface/InputOption'

export default defineComponent({
  name: 'FilterSingleSelect',
  components: {
    BaseIcon,
    BaseSingleSelect,
  },
  props: {
    filterAttribute: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<InputOption>,
      default: undefined,
    },
    options: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
  },
  emits: ['select', 'filter-remove'],
  computed: {
    name(): string {
      return this.$t('trading.label.' + this.filterAttribute).toString()
    },
  },
  methods: {
    onSelect(value: InputOption) {
      this.$emit('select', value)
    },
    onClick() {
      this.$emit('filter-remove')
    },
  },
})
