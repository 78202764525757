
import { defineComponent, PropType } from 'vue'

import Sentences from '@/components/common/Typography/Sentences.vue'
import { DirectionType } from '@/components/common/constants/DirectionType'

export default defineComponent({
  name: 'BaseDataset',
  components: {
    Sentences,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    // TODO: 利用方法をシンプルにしたいので slot に統一し data は削除する
    data: {
      type: String,
      default: undefined,
    },
    direction: {
      type: String as PropType<DirectionType>,
      default: DirectionType.Row,
    },
  },
  computed: {
    directionClass() {
      return `base-dataset__wrapper--${this.direction}`
    },
  },
})
