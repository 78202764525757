
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import InnerLabel from '@/components/common/Typography/InnerLabel.vue'

const baseButtonColor: ReadonlyArray<string> = [
  'primary',
  'secondary',
  'tertiary',
  'ask',
  'bid',
  'danger',
]
const baseButtonSize: ReadonlyArray<string> = ['small', 'medium', 'form']

type BaseButtonColorType = typeof baseButtonColor[number]
type BaseButtonSizeType = typeof baseButtonSize[number]

// https://www.notion.so/enechain/Button-db8d9e90edb84098aef8506b4ecade61

export default defineComponent({
  name: 'BaseButton',
  components: {
    BaseIcon,
    InnerLabel,
  },
  props: {
    color: {
      type: String as PropType<BaseButtonColorType>,
      default: 'tertiary',
      validator: (val: string) => {
        return baseButtonColor.includes(val)
      },
    },
    size: {
      type: String as PropType<BaseButtonSizeType>,
      default: 'medium',
      validator: (val: string) => {
        return baseButtonSize.includes(val)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'mouseover', 'mouseleave'],
  computed: {
    outerButtonClass(): Array<string | Record<string, boolean>> {
      return ['base-button', { 'base-button--loading': this.loading }]
    },
    buttonClass(): Record<string, boolean> {
      return {
        [`base-button__content--${this.color}`]: !!this.color,
        [`base-button__content--${this.size}`]: true,
        [`base-button__content--disabled`]: this.disabled,
        [`base-button__content--loading`]: this.loading,
      }
    },
    buttonLabelClass(): Array<string | Record<string, boolean>> {
      return [
        'base-button__label',
        { 'base-button__label--loading': this.loading },
      ]
    },
  },
  methods: {
    onClick($event: MouseEvent) {
      this.$emit('click', $event)
    },
    onMouseOver() {
      this.$emit('mouseover')
    },
    onMouseLeave() {
      this.$emit('mouseleave')
    },
  },
})
