import { MyIndication } from '@/models/trading/MyIndication'
import {
  MyIndicationFilterAttribute,
  MyIndicationFilterConditions,
} from '@/store/modules/trading/myIndication/interface'

export class MyIndicationState {
  myIndications = [] as MyIndication[]

  indicationIdToDelete = undefined as string | undefined
  deleteSubmitting = false
  isFetchingMyIndications = true

  filterCondition = {
    area: [],
    startDeliveryYearMonth: [],
    endDeliveryYearMonth: [],
    hourType: [],
    productType: [],
    status: [],
  } as MyIndicationFilterConditions

  filterAttributes: MyIndicationFilterAttribute[] = [
    'area',
    'startDeliveryYearMonth',
    'endDeliveryYearMonth',
    'hourType',
    'productType',
    'status',
  ]

  selectedFilters = [] as MyIndicationFilterAttribute[]
}
