import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-new-indication-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_button, {
      color: "primary",
      onClick: _ctx.onClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('trading.label.newIndication')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}