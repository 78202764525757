import axios, { AxiosRequestConfig } from 'axios'

import { getInstance } from '@/plugins/auth'

export async function addAuthorizationHeader(
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> {
  const authService = getInstance()
  config.headers = config.headers || {}
  config.headers.Authorization =
    config.headers.Authorization ||
    (await authService.getAuthorizationHeader()).headers?.Authorization
  return config
}

export function isRequiredAuthorizationHeader(
  _config: AxiosRequestConfig,
): boolean {
  // Authorization Header が不要な API をコールする必要がある場合はここに実装する
  return true
}

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (isRequiredAuthorizationHeader(config)) {
    config = await addAuthorizationHeader(config)
  }
  return config
})

export default axios
