import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2625caca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "standard-delivery-terms-form" }
const _hoisted_2 = { class: "standard-delivery-terms-form__row" }
const _hoisted_3 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_4 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_5 = {
  key: 0,
  class: "standard-delivery-terms-form__row"
}
const _hoisted_6 = { class: "standard-delivery-terms-form__delivery-year-month" }
const _hoisted_7 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_8 = {
  key: 0,
  class: "standard-delivery-terms-form__delivery-year-month"
}
const _hoisted_9 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_10 = {
  key: 1,
  class: "standard-delivery-terms-form__row"
}
const _hoisted_11 = {
  key: 0,
  class: "standard-delivery-terms-form__product-type-date-name"
}
const _hoisted_12 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_13 = {
  key: 1,
  class: "standard-delivery-terms-form__product-type-date-name"
}
const _hoisted_14 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_15 = { class: "standard-delivery-terms-form__row" }
const _hoisted_16 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_17 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_18 = { class: "standard-delivery-terms-form__row" }
const _hoisted_19 = { class: "standard-delivery-terms-form__row" }
const _hoisted_20 = { class: "standard-delivery-terms-form__toggle-button" }
const _hoisted_21 = { class: "standard-delivery-terms-form__toggle-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_delivery_date_form = _resolveComponent("delivery-date-form")!
  const _component_base_input_text = _resolveComponent("base-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('trading.label.area'),
        name: "area",
        value: _ctx.areaValue,
        options: _ctx.areaInputOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.area'),
          })
        ,
        mode: _ctx.areaInputMode,
        rules: "required",
        required: "",
        onInput: _ctx.onAreaInput
      }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isShownAreaOpenButton)
          ? (_openBlock(), _createBlock(_component_base_button, {
              key: 0,
              size: "small",
              onClick: _ctx.onAreaOpen
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.label.spread')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isOpenedArea)
        ? (_openBlock(), _createBlock(_component_base_single_select, {
            key: 0,
            label: _ctx.$t('trading.label.area'),
            name: "area",
            value: _ctx.exchangeAreaValue,
            options: _ctx.exchangeAreaInputOptions,
            placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.area'),
          })
        ,
            mode: _ctx.areaInputMode,
            rules: "required",
            required: "",
            onInput: _ctx.onExchangeAreaInput
          }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isShownAreaCloseButton)
          ? (_openBlock(), _createBlock(_component_base_icon, {
              key: 0,
              class: "standard-delivery-terms-form__toggle-button standard-delivery-terms-form__toggle-button--close",
              "icon-name": "times-circle",
              onClick: _ctx.onAreaClose
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isMonthType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_base_single_select, {
              label: _ctx.$t('trading.label.startDeliveryYearMonth'),
              name: "startDeliveryYearMonth",
              value: _ctx.startDeliveryYearMonthValue,
              options: _ctx.startDeliveryYearMonthInputOptions,
              placeholder: 
              _ctx.$t('common.message.selectPlaceholder', {
                name: _ctx.$t('trading.label.startDeliveryYearMonth'),
              })
            ,
              mode: _ctx.startDeliveryYearMonthInputMode,
              rules: "required",
              required: "",
              onInput: _ctx.onStartDeliveryYearMonthInput
            }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]),
            _createVNode(_component_base_single_select, {
              label: _ctx.$t('trading.label.endDeliveryYearMonth'),
              name: "endDeliveryYearMonth",
              value: _ctx.endDeliveryYearMonthValue,
              options: _ctx.endDeliveryYearMonthInputOptions,
              placeholder: 
              _ctx.$t('common.message.selectPlaceholder', {
                name: _ctx.$t('trading.label.endDeliveryYearMonth'),
              })
            ,
              mode: _ctx.endDeliveryYearMonthInputMode,
              rules: "required",
              required: "",
              onInput: _ctx.onEndDeliveryYearMonthInput
            }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.isShownDeliveryPeriodOpenButton)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 0,
                  size: "small",
                  onClick: _ctx.onDeliveryPeriodOpen
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('trading.label.spread')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isOpenedDeliveryPeriod)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_base_single_select, {
                  label: _ctx.$t('trading.label.startDeliveryYearMonth'),
                  name: "startDeliveryYearMonth",
                  value: _ctx.exchangeStartDeliveryYearMonthValue,
                  options: _ctx.exchangeStartDeliveryYearMonthInputOptions,
                  placeholder: 
              _ctx.$t('common.message.selectPlaceholder', {
                name: _ctx.$t('trading.label.startDeliveryYearMonth'),
              })
            ,
                  mode: _ctx.startDeliveryYearMonthInputMode,
                  rules: "required",
                  required: "",
                  onInput: _ctx.onExchangeStartDeliveryYearMonthInput
                }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]),
                _createVNode(_component_base_single_select, {
                  label: _ctx.$t('trading.label.endDeliveryYearMonth'),
                  name: "endDeliveryYearMonth",
                  value: _ctx.exchangeEndDeliveryYearMonthValue,
                  options: _ctx.exchangeEndDeliveryYearMonthInputOptions,
                  placeholder: 
              _ctx.$t('common.message.selectPlaceholder', {
                name: _ctx.$t('trading.label.endDeliveryYearMonth'),
              })
            ,
                  mode: _ctx.endDeliveryYearMonthInputMode,
                  rules: "required",
                  required: "",
                  onInput: _ctx.onExchangeEndDeliveryYearMonthInput
                }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.isShownDeliveryPeriodCloseButton)
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 0,
                  class: "standard-delivery-terms-form__toggle-button standard-delivery-terms-form__toggle-button--close",
                  "icon-name": "times-circle",
                  onClick: _ctx.onDeliveryPeriodClose
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _createVNode(_component_delivery_date_form, {
              locale: _ctx.locale,
              "form-value": _ctx.deliveryDateFormValue,
              mode: _ctx.deliveryDateFormMode,
              "start-selectable-dates": _ctx.startSelectableDates,
              "end-selectable-dates": _ctx.endSelectableDates,
              onFormInput: _ctx.onDeliveryDateInput
            }, null, 8, ["locale", "form-value", "mode", "start-selectable-dates", "end-selectable-dates", "onFormInput"]),
            (_ctx.dateName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.dateName), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.isShownDeliveryPeriodOpenButton)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 0,
                  size: "small",
                  onClick: _ctx.onDeliveryPeriodOpen
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('trading.label.spread')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            (_ctx.isOpenedDeliveryPeriod)
              ? (_openBlock(), _createBlock(_component_delivery_date_form, {
                  key: 0,
                  locale: _ctx.locale,
                  "form-value": _ctx.exchangeDeliveryDateFormValue,
                  mode: _ctx.deliveryDateFormMode,
                  "start-selectable-dates": _ctx.exchangeStartSelectableDates,
                  "end-selectable-dates": _ctx.exchangeEndSelectableDates,
                  onFormInput: _ctx.onExchangeDeliveryDateInput
                }, null, 8, ["locale", "form-value", "mode", "start-selectable-dates", "end-selectable-dates", "onFormInput"]))
              : _createCommentVNode("", true),
            (_ctx.exchangeDateName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.exchangeDateName), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            (_ctx.isShownDeliveryPeriodCloseButton)
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 0,
                  class: "standard-delivery-terms-form__toggle-button standard-delivery-terms-form__toggle-button--close",
                  "icon-name": "times-circle",
                  onClick: _ctx.onDeliveryPeriodClose
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('trading.label.hourType'),
        name: "hourType",
        value: _ctx.hourTypeValue,
        options: _ctx.hourTypeInputOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.hourType'),
          })
        ,
        mode: _ctx.hourTypeInputMode,
        rules: "required",
        required: "",
        onInput: _ctx.onHourTypeInput
      }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]),
      _createElementVNode("div", _hoisted_16, [
        (_ctx.isShownHourTypeOpenButton)
          ? (_openBlock(), _createBlock(_component_base_button, {
              key: 0,
              size: "small",
              onClick: _ctx.onHourTypeOpen
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.label.spread')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isOpenedHourType)
        ? (_openBlock(), _createBlock(_component_base_single_select, {
            key: 0,
            label: _ctx.$t('trading.label.hourType'),
            name: "hourType",
            value: _ctx.exchangeHourTypeValue,
            options: _ctx.exchangeHourTypeInputOptions,
            placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.hourType'),
          })
        ,
            mode: _ctx.hourTypeInputMode,
            rules: "required",
            required: "",
            onInput: _ctx.onExchangeHourTypeInput
          }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        (_ctx.isShownHourTypeCloseButton)
          ? (_openBlock(), _createBlock(_component_base_icon, {
              key: 0,
              class: "standard-delivery-terms-form__toggle-button standard-delivery-terms-form__toggle-button--close",
              "icon-name": "times-circle",
              onClick: _ctx.onHourTypeClose
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(_component_base_input_text, {
        value: _ctx.volumeValue,
        "value-type": "number",
        mode: _ctx.volumeInputMode,
        label: _ctx.$t('trading.label.volume'),
        name: "volume",
        help: _ctx.$t('trading.message.volumeHelpText'),
        placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.$t('trading.label.volumeShort'),
          })
        ,
        "validation-error-message": _ctx.volumeValidationErrorMessage,
        onInput: _ctx.onVolumeInput
      }, null, 8, ["value", "mode", "label", "help", "placeholder", "validation-error-message", "onInput"]),
      _createElementVNode("div", _hoisted_20, [
        (_ctx.isShownVolumeOpenButton)
          ? (_openBlock(), _createBlock(_component_base_button, {
              key: 0,
              size: "small",
              onClick: _ctx.onVolumeOpen
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.label.spread')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isOpenedVolume)
        ? (_openBlock(), _createBlock(_component_base_input_text, {
            key: 0,
            value: _ctx.exchangeVolumeValue,
            "value-type": "number",
            mode: _ctx.volumeInputMode,
            label: _ctx.$t('trading.label.volume'),
            name: "volume",
            help: _ctx.$t('trading.message.volumeHelpText'),
            placeholder: 
          _ctx.$t('common.message.inputPlaceholder', {
            name: _ctx.$t('trading.label.volumeShort'),
          })
        ,
            "validation-error-message": _ctx.volumeValidationErrorMessage,
            onInput: _ctx.onExchangeVolumeInput
          }, null, 8, ["value", "mode", "label", "help", "placeholder", "validation-error-message", "onInput"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_21, [
        (_ctx.isShownVolumeCloseButton)
          ? (_openBlock(), _createBlock(_component_base_icon, {
              key: 0,
              class: "standard-delivery-terms-form__toggle-button standard-delivery-terms-form__toggle-button--close",
              "icon-name": "times-circle",
              onClick: _ctx.onVolumeClose
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}