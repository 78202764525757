import _moment, { MomentInput } from 'moment'

export function moment(inp?: MomentInput) {
  return _moment(inp)
}

export const unix = _moment.unix

const SUNDAY = 0
const MONDAY = 1

export function isOneWeek({
  from,
  to,
}: {
  from: string | undefined
  to: string | undefined
}): boolean {
  if (!from || !to) {
    return false
  }
  const d1 = moment(from)
  const d2 = moment(to)
  return d1.day() === MONDAY && d2.day() === SUNDAY && d2.diff(d1, 'days') === 6
}
