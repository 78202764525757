import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08ce68db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-indication-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_tooltip, { label: _ctx.statusHelp }, {
      default: _withCtx(() => [
        _createVNode(_component_base_icon, {
          "icon-name": _ctx.statusIcon,
          class: _normalizeClass(_ctx.statusIconClass)
        }, null, 8, ["icon-name", "class"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}