import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cba1de1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-delivery-period-header" }
const _hoisted_2 = { class: "deal-delivery-period-list__column" }
const _hoisted_3 = { class: "deal-delivery-period-list__column" }
const _hoisted_4 = { class: "deal-delivery-period-list__column" }
const _hoisted_5 = { class: "deal-delivery-period-list__column" }
const _hoisted_6 = { class: "deal-delivery-period-list__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('trading.label.startDeliveryDate')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('trading.label.endDeliveryDate')), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('trading.label.deliveryPatternComponent')), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('trading.label.unitPriceShort')), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('trading.label.volumeShort')), 1)
  ]))
}