import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62307f09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-alert__header" }
const _hoisted_2 = { class: "base-alert__icon" }
const _hoisted_3 = { class: "base-alert__title" }
const _hoisted_4 = {
  key: 0,
  class: "base-alert__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`base-alert base-alert--${_ctx.type}`),
    role: "alert"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_base_icon, { "icon-name": _ctx.icon }, null, 8, ["icon-name"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        (_ctx.hasDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "description", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}