import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "indication-selector-modal-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_indication_selector_modal_list_item = _resolveComponent("indication-selector-modal-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indications, (indication) => {
      return (_openBlock(), _createBlock(_component_indication_selector_modal_list_item, {
        key: indication.id,
        indication: indication,
        "color-reversed": _ctx.colorReversed,
        locale: _ctx.locale,
        onSelect: _ctx.onSelect
      }, null, 8, ["indication", "color-reversed", "locale", "onSelect"]))
    }), 128))
  ]))
}