
import { defineComponent, PropType } from 'vue'

import { RoleType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import BaseInputCheckbox from '@/components/common/BaseInputCheckbox.vue'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import { MemberFormProps } from '@/components/iam/interface/MemberFormProps'

export default defineComponent({
  name: 'MemberForm',
  components: {
    BaseBox,
    BaseDataset,
    BaseInputCheckbox,
    BaseInputRadio,
    BaseInputText,
  },
  props: {
    formValue: {
      type: Object as PropType<MemberFormProps>,
      required: true,
    },
    isEclearContracted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['form-input'],
  computed: {
    roleOptions(): InputOption[] {
      return [
        {
          value: RoleType.Member,
          label: this.$t('iam.label.member').toString(),
        },
        { value: RoleType.Owner, label: this.$t('iam.label.owner').toString() },
      ]
    },
    readonly(): InputMode {
      return InputMode.READONLY
    },
  },
})
