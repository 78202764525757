import { Module } from 'vuex-smart-module'

import { PublicUserProfileActions } from '@/store/modules/iam/publicUserProfile/PublicUserProfileActions'
import { PublicUserProfileGetters } from '@/store/modules/iam/publicUserProfile/PublicUserProfileGetters'
import { PublicUserProfileMutations } from '@/store/modules/iam/publicUserProfile/PublicUserProfileMutations'
import { PublicUserProfileState } from '@/store/modules/iam/publicUserProfile/PublicUserProfileState'

export const publicUserProfile = new Module({
  state: PublicUserProfileState,
  getters: PublicUserProfileGetters,
  mutations: PublicUserProfileMutations,
  actions: PublicUserProfileActions,
})

export type PublicUserProfileModule = typeof publicUserProfile
