
import { defineComponent, PropType } from 'vue'

import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseInputSingleCheckbox from '@/components/common/BaseInputSingleCheckbox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import CreditSleeveIcon from '@/components/trading/CreditSleeveIcon.vue'
import DealRecapStatus from '@/components/trading/DealRecapStatus.vue'
import DeliveryTerms from '@/components/trading/DeliveryTerms.vue'
import DeliveryUnitIcon from '@/components/trading/DeliveryUnitIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import SpreadBadgeList from '@/components/trading/SpreadBadgeList.vue'
import { SpreadDeliveryTermsProps } from '@/components/trading/interface/DeliveryTerms'
import { DealSummary } from '@/models/trading/DealSummary'
import { RecapStateDetail } from '@/store/modules/trading/recap/interface'

export default defineComponent({
  name: 'DealListItem',
  components: {
    ActionDropdown,
    BaseBox,
    BaseInputSingleCheckbox,
    BaseLink,
    CreditSleeveIcon,
    DealRecapStatus,
    DeliveryTerms,
    DeliveryUnitIcon,
    ProductType,
    SpreadBadgeList,
  },
  props: {
    deal: {
      type: Object as PropType<DealSummary>,
      required: true,
    },
    privateConfidentialInputMode: {
      type: Number as PropType<InputMode>,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY].includes(value),
      default: InputMode.INPUT,
    },
    recapState: {
      type: Object as PropType<RecapStateDetail>,
      default: () => ({}),
    },
  },
  emits: ['confirm', 'private-confidential-change'],
  data(): {
    actions: ActionDropdownItem[]
    isActive: boolean
    isActionDropdownActive: boolean
  } {
    return {
      actions: [
        {
          label: this.$t('trading.label.confirmNegotiation').toString(),
          eventName: 'confirm',
        },
      ],
      isActive: false,
      isActionDropdownActive: false,
    }
  },
  computed: {
    itemPath(): string {
      return `/deals/${this.deal.id}`
    },
    // TODO: 型参照がUpdateで変更され次第、実装方針合わせて修正
    deliveryTerms(): SpreadDeliveryTermsProps {
      return this.deal.product.spreadDeliveryTerms()
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.deal.fuelSurchargeType?.name.translation()
    },
  },
  methods: {
    onMouseOver() {
      this.isActive = true
    },
    onMouseLeave() {
      this.isActive = false
    },
    onActionDropdownClick() {
      this.isActionDropdownActive = !this.isActionDropdownActive
    },
    onClickOutside() {
      this.isActionDropdownActive = false
    },
    onConfirm() {
      this.$emit('confirm', this.deal.negotiationId)
    },
    onPrivateConfidentialChange(event: PointerEvent) {
      if (this.privateConfidentialInputMode === InputMode.INPUT) {
        this.$emit('private-confidential-change', {
          id: this.deal.id,
          privateConfidential: !this.deal.isPrivateConfidential,
        })
      }
      event.preventDefault()
    },
  },
})
