
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { OcctoMasterType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import OcctoMasterItemForm from '@/components/iam/OcctoMasterItemForm.vue'
import { OcctoMasterItemFormProps } from '@/components/iam/interface/OcctoMasterItemFormProps'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import { BalancingGroup } from '@/models/iam/BalancingGroup'
import { SupplyPlanSubmitter } from '@/models/iam/SupplyPlanSubmitter'
import { OcctoMasterModule } from '@/store/modules/iam/occtoMaster'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { ProductModule } from '@/store/modules/trading/product'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapActions: occtoMasterMapActions } = createNamespacedHelpers(
  'occtoMaster',
) as OcctoMasterModule

const {
  mapState: productMapState,
  mapActions: productMapActions,
} = createNamespacedHelpers('product') as ProductModule

const {
  mapGetters: userProfileMapGetters,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule

export default defineComponent({
  name: 'OcctoMasterDetail',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    ConfirmationDialog,
    OcctoMasterItemForm,
    ParticipantSummary,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    isLoading: boolean
    deleteSubmitting: boolean
    actionDropdownIsActive: boolean
    originalName: string
    deleteModalName: string
    formValue: OcctoMasterItemFormProps
    createdParticipantName: string
    createdAt: string
    lastUpdatedParticipantName: string
    updatedAt: string
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      isLoading: false,
      deleteSubmitting: false,
      actionDropdownIsActive: false,
      originalName: '',
      deleteModalName: 'deleteConfirmModal',
      formValue: {
        type: OcctoMasterType.Demand,
        companyPrefix: '',
        area: undefined,
        name: '',
        code: '',
      },
      createdParticipantName: '',
      createdAt: '',
      lastUpdatedParticipantName: '',
      updatedAt: '',
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['areas']),
    actionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('iam.label.deleteOcctoMaster').toString(),
          eventName: 'delete-occto-master-click',
        },
      ]
    },
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
  },
  async created() {
    await this.fetchAreas()
      .then(async () => {
        await this.loadItem()
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    fetchSupplyPlanSubmitter: occtoMasterMapActions([
      'fetchSupplyPlanSubmitter',
    ]).fetchSupplyPlanSubmitter as (payload: {
      organizationId: string
      supplyPlanSubmitterId: string
    }) => Promise<SupplyPlanSubmitter>,
    fetchBalancingGroup: occtoMasterMapActions(['fetchBalancingGroup'])
      .fetchBalancingGroup as (payload: {
      organizationId: string
      balancingGroupId: string
    }) => Promise<SupplyPlanSubmitter>,
    ...occtoMasterMapActions([
      'updateBalancingGroup',
      'updateSupplyPlanSubmitter',
      'deleteBalancingGroup',
      'deleteSupplyPlanSubmitter',
    ]),
    ...productMapActions(['fetchAreas']),
    backListPage() {
      this.$router.push('../')
    },
    onCancelClick() {
      this.backListPage()
    },
    onFormInput(newValue: OcctoMasterItemFormProps) {
      Object.assign(this.formValue, newValue)
    },
    async loadItem() {
      return (this.$route.meta?.type === OcctoMasterType.Generation
        ? this.fetchSupplyPlanSubmitter({
            organizationId: this._organizationId,
            supplyPlanSubmitterId: this.$attrs.id,
          })
        : this.fetchBalancingGroup({
            organizationId: this._organizationId,
            balancingGroupId: this.$attrs.id,
          })
      ).then((item: SupplyPlanSubmitter | BalancingGroup) => {
        this.originalName = item.name
        this.formValue.type = this.$route.meta?.type as OcctoMasterType
        this.formValue.area = this.areas
          .find(a => a.id === item.areaId)
          ?.toInputOption(this.userProfile.locale)
        this.formValue.companyPrefix = item.companyPrefix
        this.formValue.name = item.name
        this.formValue.code = item.code
        this.createdParticipantName = item.createdParticipantName || ''
        this.createdAt = item.createdAt
        this.lastUpdatedParticipantName = item.lastUpdatedParticipantName || ''
        this.updatedAt = item.updatedAt
      })
    },
    async onClick() {
      if (
        !this.formValue.companyPrefix ||
        !this.formValue.area ||
        !this.formValue.name ||
        !this.formValue.code
      ) {
        return
      }
      this.isLoading = true
      const payload = {
        companyPrefix: this.formValue.companyPrefix,
        areaId: this.formValue.area.value,
        name: this.formValue.name,
        code: this.formValue.code,
      }
      const request =
        this.formValue.type === OcctoMasterType.Generation
          ? this.updateSupplyPlanSubmitter({
              organizationId: this._organizationId,
              supplyPlanSubmitterId: this.$attrs.id,
              supplyPlanSubmitter: payload,
            })
          : this.updateBalancingGroup({
              organizationId: this._organizationId,
              balancingGroupId: this.$attrs.id,
              balancingGroup: payload,
            })
      await request
        .then(() => {
          setNotification(
            this.$t('iam.message.successUpdateOcctoMaster').toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          const message =
            e.response.status === HttpStatus.Conflict
              ? this.formValue.type === OcctoMasterType.Generation
                ? this.$t(
                    'iam.message.failUpdateSupplyPlanSubmitterByDuplicate',
                    {
                      code: this.formValue.code,
                    },
                  ).toString()
                : this.$t('iam.message.failUpdateBalancingGroupByDuplicate', {
                    area: this.formValue.area?.label,
                  }).toString()
              : this.$t('iam.message.failUpdateOcctoMaster').toString()
          setNotification(message, 'danger')
          throw e
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async onDeleteOcctoMasterClick() {
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteOcctoMasterCancel() {
      this.$vfm.close(this.deleteModalName)
    },
    async onDeleteOcctoMasterConfirm() {
      this.deleteSubmitting = true
      const request =
        this.formValue.type === OcctoMasterType.Generation
          ? this.deleteSupplyPlanSubmitter({
              organizationId: this._organizationId,
              supplyPlanSubmitterId: this.$attrs.id,
            })
          : this.deleteBalancingGroup({
              organizationId: this._organizationId,
              balancingGroupId: this.$attrs.id,
            })
      await request
        .then(() => {
          setNotification(
            this.$t('iam.message.successDeleteOcctoMaster').toString(),
          )
          this.$vfm.close(this.deleteModalName)
          this.backListPage()
        })
        .catch(() => {
          setNotification(
            this.$t('iam.message.failDeleteOcctoMaster').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
  },
})
