import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eee1ae2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-pattern-component-form" }
const _hoisted_2 = { class: "delivery-pattern-component-form__form" }
const _hoisted_3 = { class: "delivery-pattern-component-form__delete-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_single_select, {
        name: `dayPattern-${_ctx.nameSuffix}`,
        value: _ctx.formValue.dayPattern,
        options: _ctx.dayPatternOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.dayPattern'),
          })
        ,
        mode: _ctx.mode,
        required: "",
        rules: "required",
        onInput: _cache[0] || (_cache[0] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, dayPattern: event })
        )
      }, null, 8, ["name", "value", "options", "placeholder", "mode"]),
      _createVNode(_component_base_single_select, {
        name: `timeRangeStart-${_ctx.nameSuffix}`,
        value: _ctx.formValue.timeRangeStart,
        options: _ctx.availableTimeRangeStartOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.timeRangeStart'),
          })
        ,
        mode: _ctx.mode,
        required: "",
        rules: "required",
        onInput: _cache[1] || (_cache[1] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, timeRangeStart: event })
        )
      }, null, 8, ["name", "value", "options", "placeholder", "mode"]),
      _createVNode(_component_base_single_select, {
        name: `timeRangeEnd-${_ctx.nameSuffix}`,
        value: _ctx.formValue.timeRangeEnd,
        options: _ctx.availableTimeRangeEndOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.timeRangeEnd'),
          })
        ,
        mode: _ctx.mode,
        required: "",
        rules: "required",
        onInput: _cache[2] || (_cache[2] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, timeRangeEnd: event })
        )
      }, null, 8, ["name", "value", "options", "placeholder", "mode"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isDeletable)
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 0,
            "icon-name": "times-circle",
            onClick: _cache[3] || (_cache[3] = event => _ctx.$emit('delete', event))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}