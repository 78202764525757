import { plainToClass, Type } from 'class-transformer'

import {
  Product as IProduct,
  DeliveryUnit,
  Locale,
  DeliveryYearMonth as IDeliveryYearMonth,
  ProductSubType,
} from '@/api/generated'
import { SPREAD_SYMBOL } from '@/components/common/constants/Spread'
import {
  DeliveryTermsWithPeriodProps,
  SpreadDeliveryTermsProps,
} from '@/components/trading/interface/DeliveryTerms'
import { i18n } from '@/i18n'
import { Area } from '@/models/trading/Area'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'
import { moment } from '@/utils/date'

type SearchPayload = {
  area: Area
  startDeliveryYearMonth: DeliveryYearMonth
  endDeliveryYearMonth: DeliveryYearMonth
  hourType: HourType | undefined
}

export class Product implements IProduct {
  id!: string

  type!: ProductSubType

  @Type(() => ProductType)
  productType!: ProductType

  @Type(() => DeliveryTerms)
  deliveryTerms!: DeliveryTerms

  @Type(() => DeliveryTerms)
  exchangeDeliveryTerms?: DeliveryTerms

  deliveryUnit!: DeliveryUnit

  hasFuelSurcharge(): boolean {
    return this.productType.hasFuelSurcharge
  }

  get deliveryTermsId(): string {
    return this.deliveryTerms.id
  }

  get area(): Area {
    return this.deliveryTerms.area
  }

  // ダミーのモデルを生成しているが deliveryYearMonthId や displayPriority がマスタと異なってしまうため問題が起きていた
  // 暫定対応を行ったPR: https://github.com/enechain/esquare-frontend/pull/611
  // TODO: マスタから該当するモデルを返すようなリファクタをしたい
  get startDeliveryYearMonth(): DeliveryYearMonth {
    return this.calcDeliveryYearMonth({ isStart: true })
  }

  get endDeliveryYearMonth(): DeliveryYearMonth {
    return this.calcDeliveryYearMonth({})
  }

  get startDeliveryDate(): Date {
    const { deliveryStartDate } = this.deliveryTerms.deliveryPeriods[0]
    return moment(deliveryStartDate).toDate()
  }

  get endDeliveryDate(): Date {
    const { deliveryEndDate } = this.deliveryTerms.deliveryPeriods[0]
    return moment(deliveryEndDate).toDate()
  }

  get hourType(): HourType | undefined {
    return plainToClass(
      HourType,
      this.deliveryTerms.deliveryPeriods[0].deliveryPattern.hourType,
    )
  }

  get exchangeHourType(): HourType | undefined {
    return plainToClass(
      HourType,
      this.exchangeDeliveryTerms?.deliveryPeriods[0].deliveryPattern.hourType,
    )
  }

  get formatStartDelivery(): string {
    return this.deliveryTerms.deliveryStart(this.deliveryUnit)
  }

  get formatEndDelivery(): string {
    return this.deliveryTerms.deliveryEnd(this.deliveryUnit)
  }

  get formatExchangeStartDelivery(): string | undefined {
    return this.exchangeDeliveryTerms?.deliveryStart(this.deliveryUnit)
  }

  get formatExchangeEndDelivery(): string | undefined {
    return this.exchangeDeliveryTerms?.deliveryEnd(this.deliveryUnit)
  }

  get deliveryUnitName(): string {
    return i18n
      .t(`trading.label.deliveryUnitType.${this.deliveryUnit}`)
      .toString()
  }

  get deliveryTermsWithPeriod(): DeliveryTermsWithPeriodProps {
    // エリア
    const isAreaSpread =
      !!this.exchangeDeliveryTerms?.area &&
      this.area.id !== this.exchangeDeliveryTerms?.area.id
    const areaName = isAreaSpread
      ? `${this.area.name.translation()} ${SPREAD_SYMBOL} ${this.exchangeDeliveryTerms?.area.name.translation()}`
      : this.area.name.translation()

    // 期間
    const deliveryPeriodLabel = this.deliveryTerms.deliveryPeriods[0]
      .deliveryPeriod
    const exchangeDeliveryPeriodLabel = this.exchangeDeliveryTerms
      ?.deliveryPeriods[0].deliveryPeriod
    const isDeliveryPeriodSpread =
      !!exchangeDeliveryPeriodLabel &&
      deliveryPeriodLabel !== exchangeDeliveryPeriodLabel
    const deliveryPeriod = isDeliveryPeriodSpread
      ? `${deliveryPeriodLabel} ${SPREAD_SYMBOL} ${exchangeDeliveryPeriodLabel}`
      : deliveryPeriodLabel

    // 時間帯区分
    const deliveryPatternName = this.hourType?.name.translation() ?? ''
    const exchangeDeliveryPatternName = this.exchangeHourType?.name.translation()
    const isDeliveryPatternSpread =
      !!exchangeDeliveryPatternName &&
      deliveryPatternName !== exchangeDeliveryPatternName
    const deliveryPattern = isDeliveryPatternSpread
      ? `${deliveryPatternName} ${SPREAD_SYMBOL} ${exchangeDeliveryPatternName}`
      : deliveryPatternName ?? ''

    return {
      isAreaSpread,
      isDeliveryPeriodSpread,
      isDeliveryPatternSpread,
      areaName,
      deliveryPeriod,
      hourTypeName: deliveryPattern,
    }
  }

  spreadDeliveryTerms(): SpreadDeliveryTermsProps {
    const { deliveryTerms, exchangeDeliveryTerms, deliveryUnit } = this
    const base = {
      areaName: deliveryTerms.areaName(),
      deliveryStart: deliveryTerms.deliveryStart(deliveryUnit),
      deliveryEnd: deliveryTerms.deliveryEnd(deliveryUnit),
      hourTypeName: deliveryTerms.standardHourTypeName(deliveryUnit),
    }
    const exchange = {
      areaName: exchangeDeliveryTerms?.areaName() ?? '',
      deliveryStart: exchangeDeliveryTerms?.deliveryStart(deliveryUnit) ?? '',
      deliveryEnd: exchangeDeliveryTerms?.deliveryEnd(deliveryUnit) ?? '',
      hourTypeName:
        exchangeDeliveryTerms?.standardHourTypeName(deliveryUnit) ?? '',
    }

    // 同じ値なら空文字列を返す
    function f(x: string, y: string): string {
      return x === y ? '' : y
    }

    return {
      base,
      exchange: {
        areaName: f(base.areaName, exchange.areaName),
        deliveryStart: f(base.deliveryStart, exchange.deliveryStart),
        deliveryEnd: f(base.deliveryEnd, exchange.deliveryEnd),
        hourTypeName: f(base.hourTypeName, exchange.hourTypeName),
      },
    }
  }

  get filterSearchPayload(): {
    base: SearchPayload
    exchange: SearchPayload | undefined
  } {
    const base = {
      area: this.area,
      startDeliveryYearMonth: this.startDeliveryYearMonth,
      endDeliveryYearMonth: this.endDeliveryYearMonth,
      hourType: this.hourType,
    }
    const exchange =
      this.exchangeDeliveryTerms && this.exchangeHourType
        ? {
            area: this.exchangeDeliveryTerms.area,
            startDeliveryYearMonth: this.calcDeliveryYearMonth({
              isStart: true,
              isExchange: true,
            }),
            endDeliveryYearMonth: this.calcDeliveryYearMonth({
              isStart: false,
              isExchange: true,
            }),
            hourType: this.exchangeHourType,
          }
        : undefined
    return {
      base,
      exchange,
    }
  }

  private calcDeliveryYearMonth({
    isStart,
    isExchange,
  }: {
    isStart?: boolean
    isExchange?: boolean
  }): DeliveryYearMonth {
    // TODO: deliveryTerms が複数ある場合、最も過去の年月を設定する
    const { deliveryStartDate, deliveryEndDate } =
      isExchange && this.exchangeDeliveryTerms
        ? this.exchangeDeliveryTerms.deliveryPeriods[0]
        : this.deliveryTerms.deliveryPeriods[0]
    const dt = moment(isStart ? deliveryStartDate : deliveryEndDate)
    const year = dt.format('YYYY')
    const month = dt.format('MM')

    const deliveryYearMonth: IDeliveryYearMonth = {
      name: {
        translations: [
          {
            locale: Locale.Ja,
            text: dt.format('YYYY年M月'),
          },
          {
            locale: Locale.En,
            text: dt.format('MMM YYYY'),
          },
        ],
      },
      startDate: isStart
        ? dt.format('YYYY-MM-DD')
        : moment(deliveryStartDate).format('YYYY-MM-DD'),
      endDate: isStart
        ? moment(deliveryEndDate).format('YYYY-MM-DD')
        : dt.format('YYYY-MM-DD'),
      deliveryYearMonthId: `${year}-${month}`,
      year: Number(year),
      month: Number(month),
      displayPriority: -(Number(year) * 100 + Number(month)),
    }
    return plainToClass(DeliveryYearMonth, deliveryYearMonth)
  }
}
