
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import OrganizationSelectorModal from '@/components/iam/container/OrganizationSelectorModal.vue'
import { OrganizationSelectorModalProps } from '@/components/iam/interface/OrganizationSelectorModalProps'
import { Organization } from '@/models/iam/Organization'

export default defineComponent({
  name: 'OrganizationSelector',
  components: {
    BaseModal,
    BasePicture,
    OrganizationSelectorModal,
  },
  props: {
    id: {
      type: String,
      default: '1',
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedOrganization: {
      type: Object as PropType<Organization>,
      default: undefined,
    },
    isEclearCreditSleever: {
      type: Boolean,
      default: undefined,
    },
    queryParams: {
      type: Object as PropType<OrganizationSelectorModalProps['queryParams']>,
      default: undefined,
    },
  },
  emits: ['select'],
  computed: {
    organizationSelectorModalName(): string {
      return `organizationSelectorModal-${this.id}`
    },
    selectedOrganizationName(): string {
      return this.selectedOrganization?.name.translation() || ''
    },
  },
  methods: {
    onSelect(organization: Organization) {
      this.$emit('select', organization)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.organizationSelectorModalName)
    },
    hideModal() {
      this.$vfm.close(this.organizationSelectorModalName)
    },
  },
})
