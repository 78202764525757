import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f2fd9de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-book-list" }
const _hoisted_2 = { class: "broking-order-book-list__header-wrapper" }
const _hoisted_3 = { class: "broking-order-book-list__body-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_broking_order_book_list_header = _resolveComponent("broking-order-book-list-header")!
  const _component_broking_order_book_item = _resolveComponent("broking-order-book-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_broking_order_book_list_header, {
        locale: _ctx.locale,
        "color-reversed": _ctx.colorReversed,
        "position-reversed": _ctx.positionReversed,
        "all-collapsed": _ctx.allCollapsed,
        "with-fuel-surcharge": _ctx.withFuelSurcharge,
        "is-edit-mode": _ctx.isEditMode,
        onModeChange: _ctx.onModeChange,
        onActivateAll: _ctx.onActivateAll,
        onDeactivateAll: _ctx.onDeactivateAll
      }, null, 8, ["locale", "color-reversed", "position-reversed", "all-collapsed", "with-fuel-surcharge", "is-edit-mode", "onModeChange", "onActivateAll", "onDeactivateAll"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_broking_order_book_item, {
          key: index,
          "order-book": item,
          "status-types": _ctx.statusTypes,
          "selected-indication-ids": _ctx.selectedIndicationIds,
          locale: _ctx.locale,
          "color-reversed": _ctx.colorReversed,
          "position-reversed": _ctx.positionReversed,
          "is-edit-mode": _ctx.isEditMode,
          submitting: _ctx.submitting,
          "target-indication-id": _ctx.targetIndicationId,
          onExpand: _ctx.onExpand,
          onRecordSelect: _ctx.onRecordSelect,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event))),
          onNewMessage: _ctx.onNewMessage,
          onCopyClipboard: _ctx.onCopyClipboard,
          onCopyIndication: _ctx.onCopyIndication,
          onActivate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('activate', $event))),
          onDeactivate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('deactivate', $event))),
          onOpenToMarketChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('open-to-market-change', $event))),
          onVisibleToTradersChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('visible-to-traders-change', $event))),
          onTargeting: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('targeting', $event))),
          onUpdateIndication: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update-indication', $event)))
        }, null, 8, ["order-book", "status-types", "selected-indication-ids", "locale", "color-reversed", "position-reversed", "is-edit-mode", "submitting", "target-indication-id", "onExpand", "onRecordSelect", "onNewMessage", "onCopyClipboard", "onCopyIndication"]))
      }), 128))
    ])
  ]))
}