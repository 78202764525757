
import { defineComponent, PropType } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import { AlertType } from '@/components/common/constants/AlertType'

export default defineComponent({
  name: 'BaseAlert',
  components: {
    BaseIcon,
  },
  props: {
    type: {
      type: String as PropType<AlertType>,
      default: AlertType.Info,
      validator(val: AlertType) {
        return [
          AlertType.Info,
          AlertType.Success,
          AlertType.Error,
          AlertType.Warning,
        ].includes(val)
      },
    },
  },
  computed: {
    // TODO: figmaではfont awesomeのsolidアイコンを使っているが、eSquareではregularアイコンを標準としているため、regularを設定
    // アイコンのバリエーションが増えて変更がある場合は追って反映
    // https://www.figma.com/file/44XiWn0coifWnLcvNdznj5/Design-Renewal?node-id=104%3A8735
    icon(): string {
      switch (this.type) {
        case AlertType.Success:
          return 'check-circle'
        case AlertType.Error:
          return 'exclamation-circle'
        case AlertType.Warning:
          return 'exclamation-triangle'
        default:
          return 'info-circle'
      }
    },
    hasDescription(): boolean {
      return !!this.$slots.description
    },
  },
})
