
import { defineComponent, PropType } from 'vue'

import BaseChatWindow from '@/components/chat/BaseChatWindow.vue'
import ChannelMessageWindowTitle from '@/components/chat/ChannelMessageWindowTitle.vue'
import ChatInputWrapper from '@/components/chat/ChatInputWrapper.vue'
import MessageList from '@/components/chat/MessageList.vue'
import { MessageProps } from '@/components/chat/interface/MessageProps'
import BaseIcon from '@/components/common/BaseIcon.vue'

export default defineComponent({
  name: 'ChannelMessageWindow',
  components: {
    BaseChatWindow,
    BaseIcon,
    ChannelMessageWindowTitle,
    ChatInputWrapper,
    MessageList,
  },
  props: {
    channelId: {
      type: String,
      required: true,
    },
    channelName: {
      type: String,
      required: true,
    },
    channelPicture: {
      type: String,
      required: true,
    },
    messages: {
      type: Array as PropType<MessageProps[]>,
      default: () => [],
    },
    chatInputValue: {
      type: String,
      default: '',
    },
    typingUserNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isOpened: {
      type: Boolean,
      default: true,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  emits: [
    'input',
    'typing',
    'send',
    'close',
    'title-bar-click',
    'touch-start',
    'read-message',
  ],
  methods: {
    onInput(event: Event) {
      this.$emit('input', { value: event, channelId: this.channelId })
    },
    onTyping() {
      this.$emit('typing', { channelId: this.channelId })
    },
    onSend() {
      this.$emit('send', { channelId: this.channelId })
    },
    onCloseClick() {
      this.$emit('close', { channelId: this.channelId })
    },
    onTitleBarClick() {
      this.$emit('title-bar-click', { channelId: this.channelId })
    },
    onTouchStart() {
      this.$emit('touch-start', { channelId: this.channelId })
    },
    scrollToItem(index: number) {
      const messageList = this.$refs.messageList as InstanceType<
        typeof MessageList
      >
      if (messageList) {
        messageList.scrollToItem(index)
      }
    },
    onReadMessage(index: number) {
      this.$emit('read-message', { channelId: this.channelId, index: index })
    },
  },
})
