
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale } from '@/api/generated'
import OldDeliveryTerms from '@/components/trading/OldDeliveryTerms.vue'
import OrderBookPrice from '@/components/trading/OrderBookPrice.vue'
import ProductType from '@/components/trading/ProductType.vue'
import PublicInformation from '@/components/trading/PublicInformation.vue'
import { OrderBookPriceProps } from '@/components/trading/interface/OrderBookPriceProps'
import { DeliveryTerms as DeliveryTermsModel } from '@/models/trading/DeliveryTerms'
import { Product } from '@/models/trading/Product'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndicationOfProduct } from '@/models/trading/PublicIndicationOfProduct'

const recordTypesSorter = (recordTypeA: string, recordTypeB: string) => {
  if (recordTypeA === 'ask-indication' || recordTypeB === 'deal') {
    return -1
  }
  return 1
}

export default defineComponent({
  name: 'PublicOrderBookDetail',
  components: {
    OldDeliveryTerms,
    OrderBookPrice,
    ProductType,
    PublicInformation,
  },
  props: {
    deliveryTerms: {
      type: Object as PropType<DeliveryTermsModel>,
      required: true,
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      default: DeliveryUnit.Month,
    },
    indications: {
      type: Array as PropType<PublicIndicationOfProduct[]>,
      default: () => [],
    },
    deal: {
      type: Object as PropType<PublicDeal>,
      default: undefined,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    orderBookRecords(): OrderBookPriceProps[] {
      const result: OrderBookPriceProps[] = []

      for (const indication of this.indications) {
        result.push({
          product: indication.product,
          recordType:
            indication.position === 'ask' ? 'ask-indication' : 'bid-indication',
          price: indication.price,
          unitPrice: indication.unitPrice,
          volume: indication.volume,
          fuelSurchargeType: indication.fuelSurchargeType,
          createdAt: undefined,
          publicInformation: indication.publicInformation,
        })
      }

      if (this.deal) {
        result.push({
          product: this.deal.product,
          recordType: 'deal',
          price: this.deal.price,
          unitPrice: this.deal.unitPrice,
          volume: this.deal.volume,
          fuelSurchargeType: this.deal.fuelSurchargeType,
          createdAt: this.deal.displayCreatedAt(),
        })
      }

      result.sort(
        (a, b) =>
          b.unitPrice - a.unitPrice ||
          (b.price.basicUnitPrice?.unitPrice ?? 0) -
            (a.price.basicUnitPrice?.unitPrice ?? 0) ||
          recordTypesSorter(a.recordType, b.recordType),
      )

      return result
    },
    fuelSurchargeName(): string | undefined {
      return (
        this.indications[0]?.fuelSurchargeType?.name.translation() ||
        this.deal?.fuelSurchargeType?.name.translation()
      )
    },
  },
  methods: {
    productTypeNameOf(product: Product): string {
      return product.productType.translationOf(this.locale)
    },
  },
})
