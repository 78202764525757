
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import MemberList from '@/components/iam/MemberList.vue'
import MemberListHeader from '@/components/iam/MemberListHeader.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MemberModule } from '@/store/modules/iam/member'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'

const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'Members',
  components: {
    BaseAlert,
    BaseIcon,
    BaseLink,
    CenteredLoadingContent,
    MemberList,
    MemberListHeader,
    UiStackSelector,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType
    members: MemberProfile[]
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      members: [],
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    isAdminPage(): boolean {
      return isBrokerApp && !!this.$attrs.organizationId
    },
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    ecloudOrganizationUrl(): string {
      return `/ecloud-organizations/${this.$attrs.organizationId}/members`
    },
    basePath(): string {
      return this.isAdminPage
        ? `/organizations/${this.$attrs.organizationId}/email-setting`
        : '/email-setting'
    },
  },
  watch: {
    async '$route.params.organizationId'() {
      await this.initialize()
    },
  },
  async created() {
    this.initialize()
  },
  methods: {
    fetchMemberProfiles: memberMapActions(['fetchMemberProfiles'])
      .fetchMemberProfiles as (
      organizationId: string,
    ) => Promise<MemberProfile[]>,
    async initialize() {
      this.uiStack = UiStack.Loading
      await this.fetchMemberProfiles(this._organizationId)
        .then(members => {
          this.members = members
          this.uiStack =
            this.members.length === 0 ? UiStack.Blank : UiStack.Ideal
        })
        .catch(_ => {
          this.uiStack = UiStack.Error
        })
    },
  },
})
