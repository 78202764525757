import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_broker_indication_new = _resolveComponent("broker-indication-new")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.newIndication'),
    "active-menu-item": "trading"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_broker_indication_new, {
        onCancel: _ctx.onChancel,
        onConfirm: _ctx.onConfirm
      }, null, 8, ["onCancel", "onConfirm"])
    ]),
    _: 1
  }, 8, ["title"]))
}