
import { defineComponent, PropType } from 'vue'

import { RecapReceiver } from '@/models/trading/RecapReceiver'

export default defineComponent({
  name: 'SelectedRecapReceiver',
  props: {
    isSelectable: {
      type: Boolean,
      required: true,
    },
    selectedRecapReceiver: {
      type: Object as PropType<RecapReceiver>,
      required: true,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      if (this.isSelectable) {
        this.$emit('click')
      }
    },
  },
})
