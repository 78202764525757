import { Conversation, SendMediaOptions } from '@twilio/conversations'

import { ChannelType } from '@/models/chat/ChannelType'
import { Member } from '@/models/chat/Member'
import { Message } from '@/models/chat/Message'

export abstract class Channel {
  channelId: string
  conversationInstance: Conversation
  profileId: string
  broadcastIndices: number[] = []
  private unstoredUnreadBroadcastCount?: number // フロントエンドに保存していない未読の一斉送信数
  readonly broadcastPrefix = '__BROADCAST__'
  counter: number // 変更を検知させるための変数。ChatMutation の INCREMENT_CHANNEL_COUNTER でカウントアップさせる。

  constructor(params: {
    channelId: string
    conversationInstance: Conversation
    profileId: string
  }) {
    this.channelId = params.channelId
    this.conversationInstance = params.conversationInstance
    this.profileId = params.profileId
    this.counter = 0
  }

  async getMembers(): Promise<Member[]> {
    const participantInstances = await this.conversationInstance.getParticipants()
    const members: Member[] = []

    const userInstancePromise = participantInstances.map(participantInstance =>
      participantInstance.getUser(),
    )
    const userInstances = await Promise.all(userInstancePromise)

    for (const participantInstance of participantInstances) {
      const userInstance = userInstances.find(
        userInstance => userInstance.identity === participantInstance.identity,
      )

      if (userInstance) {
        members.push(
          new Member({
            userId: participantInstance.identity,
            userInstance,
          }),
        )
      }
    }
    return members
  }

  async getMessages(pageSize?: number, anchor?: number): Promise<Message[]> {
    if (!this.conversationInstance) {
      return []
    }
    const messageInstances = await this.conversationInstance.getMessages(
      pageSize,
      anchor,
    )
    return messageInstances.items.map(message => {
      const displayMessage = message.body.replace(
        new RegExp(`^${this.broadcastPrefix}`),
        '',
      )
      return new Message({
        messageId: message.sid,
        userId: message.author,
        dateTime: message.dateCreated,
        message: displayMessage,
        index: message.index,
      })
    })
  }

  async sendMessage(
    message: string | FormData | SendMediaOptions,
    isBroadcast?: boolean,
  ): Promise<number> {
    return isBroadcast
      ? await this.conversationInstance.sendMessage(
          `${this.broadcastPrefix}${message}`,
          {
            broadcast: true,
          },
        )
      : await this.conversationInstance.sendMessage(message)
  }

  async typing(): Promise<void> {
    await this.conversationInstance.typing()
  }

  private get unreadBroadcastCount(): number {
    // 最終既読位置が、フロントエンドに保存していない一斉送信インデックスの場合は、サーバから取得した値を加算する
    const lastBroadcastIndex =
      this.broadcastIndices.length > 0
        ? this.broadcastIndices[this.broadcastIndices.length - 1]
        : null
    let unstoredUnreadBroadcastCount = 0
    if (
      lastBroadcastIndex === null ||
      this.conversationInstance.lastReadMessageIndex === null ||
      this.conversationInstance.lastReadMessageIndex < lastBroadcastIndex
    ) {
      unstoredUnreadBroadcastCount = this.unstoredUnreadBroadcastCount ?? 0
    }

    // 最終既読メッセージ以前の一斉送信は無視する
    const storedUnreadBroadcastCount = this.broadcastIndices.filter(index => {
      return (
        this.conversationInstance.lastReadMessageIndex === null ||
        index > this.conversationInstance.lastReadMessageIndex
      )
    }).length
    return unstoredUnreadBroadcastCount + storedUnreadBroadcastCount
  }

  get hasUnreadMessage() {
    if (
      this.conversationInstance.lastMessage === undefined ||
      this.conversationInstance.lastMessage.index === undefined
    ) {
      return false
    }
    return this.getNumberOfUnreadMessage > 0
  }

  get getNumberOfUnreadMessage(): number {
    if (
      this.conversationInstance.lastMessage === undefined ||
      this.conversationInstance.lastMessage.index === undefined
    ) {
      return 0
    }
    if (this.conversationInstance.lastReadMessageIndex === null) {
      return (
        this.conversationInstance.lastMessage.index +
        1 -
        this.unreadBroadcastCount
      )
    }
    return (
      this.conversationInstance.lastMessage.index -
      (this.conversationInstance.lastReadMessageIndex +
        this.unreadBroadcastCount)
    )
  }

  get lastMessageIndex(): number | undefined {
    return this.conversationInstance.lastMessage?.index
  }

  get lastReadMessageIndex(): number | null {
    return this.conversationInstance.lastReadMessageIndex
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on(event: any, listener: (..._: any[]) => void) {
    this.conversationInstance.on(event, listener)
  }

  setUnstoredUnreadBroadcastCount(unstoredUnreadBroadcastCount: number): void {
    this.unstoredUnreadBroadcastCount = unstoredUnreadBroadcastCount
  }

  appendBroadcastIndex(broadcastIndex: number): void {
    if (!this.broadcastIndices) {
      return
    }
    this.broadcastIndices.push(broadcastIndex)
  }

  abstract get channelType(): ChannelType
}
