import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-icon",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_font_awesome_icon, {
      icon: [_ctx.prefix, _ctx.iconName],
      size: _ctx.size,
      onMouseleave: _ctx.onMouseleave
    }, null, 8, ["icon", "size", "onMouseleave"])
  ]))
}