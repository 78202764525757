import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22565314"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "members" }
const _hoisted_2 = {
  key: 0,
  class: "members__link"
}
const _hoisted_3 = { class: "members__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_member_list_header = _resolveComponent("member-list-header")!
  const _component_member_list = _resolveComponent("member-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAdminPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_base_link, {
            path: _ctx.ecloudOrganizationUrl,
            "is-target-blank": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('iam.label.linkToGlobalOrganizationMembers')) + " ", 1),
              _createVNode(_component_base_icon, {
                class: "members__icon",
                "icon-name": "external-link-alt"
              })
            ]),
            _: 1
          }, 8, ["path"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', { name: _ctx.$t('iam.label.member') })), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      blank: _withCtx(() => [
        _createVNode(_component_base_alert, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.message.noOne', { name: _ctx.$t('iam.label.member') })), 1)
          ]),
          _: 1
        })
      ]),
      ideal: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_member_list_header),
          _createVNode(_component_member_list, {
            members: _ctx.members,
            "parent-path": `${_ctx.basePath}/members`
          }, null, 8, ["members", "parent-path"])
        ])
      ]),
      _: 1
    }, 8, ["ui-stack"])
  ]))
}