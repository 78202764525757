import { IndicationFilterConditions } from '@/store/modules/trading/indication/interface'

export class IndicationState {
  filterCondition: IndicationFilterConditions = {
    area: undefined,
    startDeliveryYearMonth: undefined,
    endDeliveryYearMonth: undefined,
    hourType: undefined,
    productType: undefined,
    organizationName: undefined,
    status: undefined,
    position: undefined,
    nonStandard: undefined,
  }
}
