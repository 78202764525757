
import { defineComponent } from 'vue'
import { VueFinalModal, StyleValue } from 'vue-final-modal'

import BaseIcon from '@/components/common/BaseIcon.vue'
import Heading from '@/components/common/Typography/Heading.vue'

export default defineComponent({
  name: 'BaseModal',
  components: {
    BaseIcon,
    Heading,
    VueFinalModal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: undefined,
    },
    overflow: {
      type: String,
      default: 'visible',
    },
    clickToClose: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed'],
  computed: {
    contentStyle(): StyleValue {
      return {
        width: this.width ? `${this.width}px` : 'auto',
        'max-height': '100%',
        ...(this.height !== undefined
          ? { height: `${this.height}px` }
          : { 'overflow-y': 'scroll' }),
      }
    },
    contentClass(): object {
      return { 'base-modal__content--with-scroll': this.height === undefined }
    },
  },
  methods: {
    onClosed() {
      this.$emit('closed')
    },
  },
})
