import { DeliverySummary as IDeliverySummary } from '@/api/generated'
import { i18n } from '@/i18n'

export class DeliverySummary implements IDeliverySummary {
  totalDeliveryHours!: number
  totalDeliveryVolumes?: number
  totalDeliveryWeekdays!: number
  totalDeliveryHolidays!: number

  displayTotalDeliveryHours(): string {
    return i18n
      .t('trading.label.hoursWithUnit', {
        hours: this.totalDeliveryHours.toLocaleString(),
      })
      .toString()
  }

  displayTotalDeliveryVolumes(): string {
    return this.totalDeliveryVolumes
      ? i18n
          .t('trading.label.totalVolumeWithUnit', {
            volume: this.totalDeliveryVolumes.toLocaleString(),
          })
          .toString()
      : i18n.t('common.label.blank').toString()
  }

  displayTotalDeliveryDays(): string {
    return i18n
      .t('trading.label.daysWithUnit', {
        days: `${(
          this.totalDeliveryWeekdays + this.totalDeliveryHolidays
        ).toLocaleString()}`,
      })
      .toString()
  }

  displayTotalDeliveryWeekdays(): string {
    return i18n
      .t('trading.label.daysWithUnit', {
        days: this.totalDeliveryWeekdays.toLocaleString(),
      })
      .toString()
  }

  displayTotalDeliveryHolidays(): string {
    return i18n
      .t('trading.label.daysWithUnit', {
        days: this.totalDeliveryHolidays.toLocaleString(),
      })
      .toString()
  }
}
