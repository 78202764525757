import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "indication-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_indication_list_item = _resolveComponent("indication-list-item")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indications, (indication, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_indication_list_item, {
        key: index,
        indication: indication,
        "color-reversed": _ctx.colorReversed
      }, null, 8, ["indication", "color-reversed"])), [
        [_directive_observe_visibility, isVisible => _ctx.visibilityChanged(isVisible, index)]
      ])
    }), 128))
  ]))
}