import { Type } from 'class-transformer'
import { camelize } from 'humps'

import {
  Negotiation as GeneratedINegotiation,
  NegotiationStatusType,
  NegotiationSubType,
  SpreadType,
} from '@/api/generated'
import { i18n } from '@/i18n'
import { CreditSleever } from '@/models/trading/CreditSleever'
import {
  INegotiationDetail,
  NegotiationDetail,
} from '@/models/trading/NegotiationDetail'
import { moment } from '@/utils/date'

// FIXME: openapi-generator で生成されるコードで配列内の nullable が無視されるため暫定対応として型をここで指定している
export interface INegotiation
  extends Omit<
    GeneratedINegotiation,
    'askNegotiationDetail' | 'bidNegotiationDetail'
  > {
  askNegotiationDetail: INegotiationDetail
  bidNegotiationDetail: INegotiationDetail
}

export class Negotiation implements INegotiation {
  id!: string
  publicNegotiationId!: string
  status!: NegotiationStatusType
  type!: NegotiationSubType
  spreadTypes?: SpreadType[]
  spreadBasePrice?: number
  calendarTypeId!: string
  exchangeCalendarTypeId?: string
  memo?: string

  @Type(() => NegotiationDetail)
  askNegotiationDetail!: NegotiationDetail

  @Type(() => NegotiationDetail)
  bidNegotiationDetail!: NegotiationDetail

  @Type(() => CreditSleever)
  creditSleever?: CreditSleever

  createdParticipantName!: string
  lastUpdatedParticipantName!: string
  createdAt!: string
  updatedAt!: string

  displayProductTypes(): string {
    return [
      ...new Set([
        this.askNegotiationDetail.product.productType.translation(),
        this.bidNegotiationDetail.product.productType.translation(),
      ]),
    ].join(i18n.t('common.label.separator').toString())
  }

  displayAskOrganizationName(): string {
    return this.askNegotiationDetail.indication.organizationName.translation()
  }

  displayAskOrganizationAbbreviation(): string {
    return (
      this.askNegotiationDetail.indication.organizationAbbreviation ||
      this.askNegotiationDetail.indication.organizationName.translation()
    )
  }

  displayBidOrganizationName(): string {
    return this.bidNegotiationDetail.indication.organizationName.translation()
  }

  displayBidOrganizationAbbreviation(): string {
    return (
      this.bidNegotiationDetail.indication.organizationAbbreviation ||
      this.bidNegotiationDetail.indication.organizationName.translation()
    )
  }

  displaySleeverName(): string {
    return this.creditSleever
      ? this.creditSleever.organizationName.translation()
      : i18n.t('common.label.blank').toString()
  }

  displayStatus(): string {
    return i18n
      .t(`trading.label.negotiationStatusType.${camelize(this.status)}`)
      .toString()
  }

  displayCreatedAt(): string {
    return moment(this.createdAt).format(
      i18n.t('common.format.dateTime').toString(),
    )
  }

  displayUpdatedAt(): string {
    return moment(this.updatedAt).format(
      i18n.t('common.format.dateTime').toString(),
    )
  }

  get isSpread(): boolean {
    return this.type === NegotiationSubType.Spread
  }

  get isSwingOption(): boolean {
    return (
      this.askNegotiationDetail.indication.price.type === 'basic-plus-volume' &&
      this.bidNegotiationDetail.indication.price.type === 'basic-plus-volume'
    )
  }
}
