
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'

export default defineComponent({
  name: 'Angle',
  components: {
    BaseIcon,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
