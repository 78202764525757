import { Locale } from '@/api/generated/api'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

export class LocaleOptions {
  get options(): InputOption[] {
    return [
      {
        label: i18n.t('common.label.japanese').toString(),
        value: Locale.Ja,
      },
      {
        label: i18n.t('common.label.english').toString(),
        value: Locale.En,
      },
    ]
  }
}
