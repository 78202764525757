import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b013362"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-member-count" }
const _hoisted_2 = { class: "channel-member-count__button" }
const _hoisted_3 = { class: "channel-member-count__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_inner_label = _resolveComponent("inner-label")!
  const _component_channel_member_list = _resolveComponent("channel-member-list")!
  const _component_base_dropdown = _resolveComponent("base-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_dropdown, {
      id: "channel-member-list",
      "is-active": _ctx.isMemberListShown,
      toggle: _ctx.showMemberList
    }, {
      "trigger-content": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_icon, { "icon-name": "user" }),
          _createVNode(_component_inner_label, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.memberCount), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_channel_member_list, { members: _ctx.members }, null, 8, ["members"])
        ])
      ]),
      _: 1
    }, 8, ["is-active", "toggle"])
  ]))
}