import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68107733"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-datepicker" }
const _hoisted_2 = { class: "base-datepicker__wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_vue_date_picker = _resolveComponent("vue-date-picker")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_field = _resolveComponent("field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_field, {
      "model-value": _ctx.date,
      name: _ctx.name,
      rules: _ctx.rules,
      "validate-on-mount": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_label, {
          label: _ctx.labelString,
          help: _ctx.help,
          class: "base-datepicker__label"
        }, null, 8, ["label", "help"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_vue_date_picker, {
            "model-value": _ctx.date,
            locale: _ctx.localeUnicode,
            "min-date": _ctx.minDate,
            "max-date": _ctx.maxDate,
            clearable: false,
            format: "yyyy/MM/dd",
            position: "left",
            "auto-apply": "",
            "disabled-week-days": _ctx.unselectableDaysOfWeek,
            "year-range": _ctx.yearRange,
            "allowed-dates": _ctx.selectableDates,
            "enable-time-picker": false,
            "week-start": _ctx.monday,
            disabled: _ctx.readonly,
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _ctx.onInput
          }, null, 8, ["model-value", "locale", "min-date", "max-date", "disabled-week-days", "year-range", "allowed-dates", "week-start", "disabled", "placeholder", "onUpdate:modelValue"]),
          (!_ctx.readonly)
            ? (_openBlock(), _createBlock(_component_base_button, {
                key: 0,
                class: "base-datepicker__clear-button",
                size: "form",
                onClick: _ctx.clearValue
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_icon, { "icon-name": "times" })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_error_message, { name: _ctx.name }, {
          default: _withCtx(({ message }) => [
            (_ctx.touched)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(message), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["name"])
      ]),
      _: 1
    }, 8, ["model-value", "name", "rules"])
  ]))
}