import { Type } from 'class-transformer'

import {
  Indication as IIndication,
  PositionType,
  IndicationStatusType,
  IndicationSubType,
  SpreadType,
  Price,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { i18n } from '@/i18n'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Product } from '@/models/trading/Product'
import { ProductSubType } from '@/models/trading/ProductSubType'
import { moment } from '@/utils/date'

export class Indication implements IIndication {
  id!: string
  organizationId!: string

  @Type(() => MultiLocaleName)
  organizationName!: MultiLocaleName

  organizationAbbreviation?: string
  type!: IndicationSubType
  spreadTypes?: SpreadType[]

  position!: PositionType
  price!: Price
  unitPrices!: number[]
  volumes!: number[]
  exchangeVolumes?: number[]
  status!: IndicationStatusType
  request?: string
  publicInformation?: string
  createdParticipantName!: string
  lastUpdatedParticipantName!: string
  createdAt!: string
  updatedAt!: string
  brokerId?: string
  brokerName?: string
  traderId?: string
  traderName?: string
  isVisibleToTraders!: boolean
  openToMarket!: boolean
  excludedEnecCurve!: boolean
  maskVolume!: boolean
  isDailyPowerMarket!: boolean
  memo?: string

  @Type(() => FuelSurchargeType)
  fuelSurchargeType?: FuelSurchargeType

  @Type(() => Product)
  products!: Product[]

  displayCreatedAt(): string {
    return moment(this.createdAt).format(
      i18n.t('common.format.dateTime').toString(),
    )
  }

  displayUpdatedAt(): string {
    return moment(this.updatedAt).format(
      i18n.t('common.format.dateTime').toString(),
    )
  }

  displayOrganizationAbbreviation(): string {
    return this.organizationAbbreviation || this.organizationName.translation()
  }

  get isSpread(): boolean {
    return this.type === IndicationSubType.Spread
  }

  get isSwingOption(): boolean {
    return this.price.type === 'basic-plus-volume'
  }

  get traderInputOption(): InputOption {
    return {
      value: this.traderId || '',
      label:
        this.traderName ||
        // トレーダーが削除されている場合、traderIdは空ではないが、traderNameは空となる。
        (this.traderId
          ? i18n.t('trading.label.traderNameUnknown').toString()
          : ''),
    }
  }

  get brokerInputOption(): InputOption {
    return {
      value: this.brokerId || '',
      label: this.brokerName || '',
    }
  }

  get deliveryTerms(): DeliveryTermsWithPeriodProps {
    const product = this.products[0]
    return product.deliveryTermsWithPeriod
  }

  get productSubType(): ProductSubType {
    if (this.price.type === 'basic-plus-volume') {
      return 'swing-option'
    }
    if (this.isSpread) {
      return 'spread'
    }
    return 'normal'
  }
}
