import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b021f2de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-email-address" }
const _hoisted_2 = { class: "organization-email-address__action-dropdown-wrapper" }
const _hoisted_3 = { class: "organization-email-address__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_participant_summary = _resolveComponent("participant-summary")!
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_receive_email_form = _resolveComponent("receive-email-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
            name: _ctx.$t('iam.label.organizationEmailAddress'),
          })), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createVNode(_component_validation_form, { class: "organization-email-address__content" }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_action_dropdown, {
                id: _ctx.dropdownId,
                class: "organization-email-address__action-dropdown",
                actions: _ctx.actionItems,
                "is-active": _ctx.isDropdownActive,
                "is-right": "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDropdownActive = !_ctx.isDropdownActive), ["stop"])),
                onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDropdownActive = false)),
                onDeleteClick: _ctx.onDelete
              }, null, 8, ["id", "actions", "is-active", "onDeleteClick"])
            ]),
            _createVNode(_component_participant_summary, {
              class: "organization-email-address__participant-summary",
              "created-participant-name": _ctx.createdUserName,
              "last-updated-participant-name": _ctx.lastUpdatedUserName,
              "created-at": _ctx.createdAt,
              "updated-at": _ctx.updatedAt
            }, null, 8, ["created-participant-name", "last-updated-participant-name", "created-at", "updated-at"]),
            _createVNode(_component_base_box, { class: "organization-email-address__box" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_base_input_text, {
                    value: _ctx.organizationEmailAddress.name,
                    label: _ctx.$t('iam.label.name'),
                    name: "name",
                    required: true,
                    rules: "required",
                    onInput: _cache[2] || (_cache[2] = $event => (_ctx.organizationEmailAddress.name = $event))
                  }, null, 8, ["value", "label"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_base_input_text, {
                    value: _ctx.organizationEmailAddress.emailAddress,
                    label: _ctx.$t('iam.label.mail'),
                    name: "main",
                    required: true,
                    rules: "required|email",
                    onInput: _cache[3] || (_cache[3] = 
                  $event => (_ctx.organizationEmailAddress.emailAddress = $event)
                )
                  }, null, 8, ["value", "label"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_base_box, { class: "organization-email-address__box" }, {
              default: _withCtx(() => [
                _createVNode(_component_receive_email_form, {
                  "form-value": _ctx.emailFormValue,
                  onFormInput: _ctx.onInput
                }, null, 8, ["form-value", "onFormInput"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                color: "primary",
                disabled: Object.keys(errors).length > 0,
                loading: _ctx.isLoading,
                onClick: _ctx.onClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "loading", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["ui-stack"]),
    _createVNode(_component_confirmation_dialog, {
      name: _ctx.modalName,
      title: _ctx.$t('iam.label.deleteOrganizationEmailAddress'),
      "confirm-button-label": _ctx.$t('common.label.delete'),
      "confirm-button-color": 'danger',
      loading: _ctx.deleteSubmitting,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.onDeleteCancel
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('iam.message.deleteOrganizationEmailAddressMessage', {
          name: _ctx.organizationEmailAddress.name,
        })), 1)
      ]),
      _: 1
    }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
  ]))
}