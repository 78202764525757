
import { defineComponent, PropType } from 'vue'

import { BaseBoxType } from '@/components/common/constants/BaseBoxType'

export default defineComponent({
  name: 'BaseBox',
  props: {
    type: {
      type: String as PropType<BaseBoxType>,
      default: BaseBoxType.Shadow,
      validator: (value: BaseBoxType) => {
        return [
          BaseBoxType.Shadow,
          BaseBoxType.Border,
          BaseBoxType.Fill,
          BaseBoxType.None,
        ].includes(value)
      },
    },
    spacing: {
      type: String,
      default: '16px',
    },
  },
  emits: ['click'],
  computed: {
    className() {
      return `base-box--${this.type}`
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
