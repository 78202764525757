import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3621a22f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trading" }
const _hoisted_2 = { class: "trading__tab" }
const _hoisted_3 = { class: "trading__content-header" }
const _hoisted_4 = { class: "trading__filter" }
const _hoisted_5 = { class: "trading__buttons" }
const _hoisted_6 = { class: "trading__download-button" }
const _hoisted_7 = { class: "trading__download-icon" }
const _hoisted_8 = { class: "trading__download-text" }
const _hoisted_9 = { class: "trading__blank-order" }
const _hoisted_10 = { class: "trading__blank-order_icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fuel_surcharge_type_tab = _resolveComponent("fuel-surcharge-type-tab")!
  const _component_product_filter = _resolveComponent("product-filter")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_the_new_indication_button = _resolveComponent("the-new-indication-button")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_trading_order_book_list_header = _resolveComponent("trading-order-book-list-header")!
  const _component_trading_order_book_list = _resolveComponent("trading-order-book-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_my_indication_new = _resolveComponent("my-indication-new")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_trader_page = _resolveComponent("trader-page")!

  return (_openBlock(), _createBlock(_component_trader_page, {
    title: _ctx.$t('common.label.order'),
    "active-menu-item": "order",
    "layout-mode": _ctx.layoutMode
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_fuel_surcharge_type_tab, {
            "current-tab-index": _ctx.activeTabIndex,
            onTabActive: _ctx.onTabActive
          }, null, 8, ["current-tab-index", "onTabActive"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_product_filter, {
              filters: _ctx.filters,
              actions: _ctx.filterActions,
              "is-action-dropdown-active": _ctx.isFilterActionDropdownActive,
              "selected-filters": _ctx.selectedFiltersWithSpread,
              onSelect: _ctx.onFilterItemSelect,
              onRemove: _ctx.onFilterItemRemove,
              onFilterRemove: _ctx.onFilterRemove,
              onClickButton: _ctx.onClickFilterDropdown,
              onClickOutside: _ctx.onClickFilterOutside,
              onChangeList: _ctx.onChangeFilters
            }, null, 8, ["filters", "actions", "is-action-dropdown-active", "selected-filters", "onSelect", "onRemove", "onFilterRemove", "onClickButton", "onClickOutside", "onChangeList"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_action_dropdown, {
              id: "download",
              actions: _ctx.downloadActions,
              "is-active": _ctx.isDownloadActionDropdownActive,
              "is-button": false,
              "is-right": "",
              onClickOutside: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDownloadActionDropdownActive = false)),
              onOrderCsvDownloadClick: _ctx.onClickOrderCsvDownload,
              onRecentDealCsvDownloadClick: _ctx.onRecentDealCsvDownloadClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_button, {
                  onClick: _withModifiers(_ctx.onDownloadClick, ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_base_icon, { "icon-name": "cloud-download" })
                      ]),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('trading.message.downloadIndicationAndDealInformation')), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["actions", "is-active", "onOrderCsvDownloadClick", "onRecentDealCsvDownloadClick"]),
            _createVNode(_component_the_new_indication_button, { onTheNewIndicationButtonClick: _ctx.onNewIndicationButtonClick }, null, 8, ["onTheNewIndicationButtonClick"])
          ])
        ]),
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.message.failFetch', { name: _ctx.$t('common.label.order') })), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          blank: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_base_icon, {
                  "icon-name": "list",
                  size: "5x"
                })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('trading.message.notFoundOrder')), 1)
            ])
          ]),
          ideal: _withCtx(() => [
            _createVNode(_component_trading_order_book_list_header, {
              "color-reversed": _ctx.services.esquare.userSetting.colorReversed,
              "position-reversed": _ctx.services.esquare.userSetting.positionReversed,
              "all-collapsed": _ctx.allCollapsed,
              onExpandAll: _ctx.onExpandAll,
              onCollapseAll: _ctx.onCollapseAll
            }, null, 8, ["color-reversed", "position-reversed", "all-collapsed", "onExpandAll", "onCollapseAll"]),
            _createVNode(_component_trading_order_book_list, {
              "order-books": _ctx.orderBooks,
              "color-reversed": _ctx.services.esquare.userSetting.colorReversed,
              "position-reversed": _ctx.services.esquare.userSetting.positionReversed,
              "expanded-list": _ctx.expandedList,
              onOrderButtonClick: _ctx.onOrderButtonClick,
              onClick: _ctx.onClick
            }, null, 8, ["order-books", "color-reversed", "position-reversed", "expanded-list", "onOrderButtonClick", "onClick"])
          ]),
          _: 1
        }, 8, ["ui-stack"]),
        _createVNode(_component_base_modal, {
          name: "new-indication",
          title: _ctx.$t('trading.label.newIndication'),
          width: 1096
        }, {
          default: _withCtx(() => [
            _createVNode(_component_my_indication_new, {
              mode: _ctx.indicationFormInputMode,
              "order-book": _ctx.selectedOrderBook,
              position: _ctx.selectedPosition,
              onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$vfm.close('new-indication')))
            }, null, 8, ["mode", "order-book", "position"])
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ]),
    _: 1
  }, 8, ["title", "layout-mode"]))
}