import { AxiosResponse } from 'axios'
import { plainToClass } from 'class-transformer'
import chunk from 'lodash/chunk'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { UsersApi, PublicUserProfilesApi } from '@/api/generated'
import { PublicUserProfile } from '@/models/iam/PublicUserProfile'
import { PublicUserProfileGetters } from '@/store/modules/iam/publicUserProfile/PublicUserProfileGetters'
import {
  PublicUserProfileMutations,
  SET_PUBLIC_USER_PROFILES,
} from '@/store/modules/iam/publicUserProfile/PublicUserProfileMutations'
import { PublicUserProfileState } from '@/store/modules/iam/publicUserProfile/PublicUserProfileState'
import {
  arrayBuffer2JpegDataUrl,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class PublicUserProfileActions extends Actions<
  PublicUserProfileState,
  PublicUserProfileGetters,
  PublicUserProfileMutations,
  PublicUserProfileActions
> {
  async fetchPublicUserProfilesByIds(payload: { userIds: string[] }) {
    const userIdsToFetch = payload.userIds.filter(
      userId =>
        !this.state.publicUserProfiles
          .map(profile => profile.userId)
          .includes(userId),
    )
    if (userIdsToFetch.length > 0) {
      const userIdsPerRequest = chunk(userIdsToFetch, 20)

      const publicUserProfilePromise = userIdsPerRequest.map(userIds => {
        const id = userIds.length === 1 ? userIds[0] : undefined
        const ids = userIds.length > 1 ? userIds : undefined
        return new PublicUserProfilesApi(apiConfig)
          .getPublicUserProfiles(id, ids)
          .catch(error => console.log(error))
      })

      const publicUserProfiles = (
        await Promise.all(publicUserProfilePromise).then(resolve => {
          return resolve
        })
      )
        .map(response => {
          if (response) {
            return response.data?.users
          }
        })
        .flat()
        .filter(user => user)
        .map(user => plainToClass(PublicUserProfile, user))

      const userPicturePromises = publicUserProfiles.map(profile =>
        isRequiredAuthenticatedRequest(profile.picture)
          ? new UsersApi(apiConfig).getPictureOfUser(profile.userId, {
              responseType: 'arraybuffer',
            })
          : Promise.resolve({ data: '' } as AxiosResponse),
      )
      const userPictures = (await Promise.all(userPicturePromises)).map(
        response => response.data,
      )

      for (const [index, publicUserProfile] of publicUserProfiles.entries()) {
        if (isRequiredAuthenticatedRequest(publicUserProfile.picture)) {
          publicUserProfiles[index].picture = arrayBuffer2JpegDataUrl(
            (userPictures[index] as unknown) as ArrayBuffer,
          )
        }
      }

      this.commit(SET_PUBLIC_USER_PROFILES, {
        publicUserProfiles: [
          ...this.state.publicUserProfiles,
          ...publicUserProfiles,
        ],
      })
    }
  }
}
