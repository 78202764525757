import { ActiveChannel } from '@/models/chat/ActiveChannel'
import { ActiveChannelWindowList } from '@/models/chat/ActiveChannelWindowList'
import { ChannelList } from '@/models/chat/ChannelList'

export class ChatState {
  channelList = new ChannelList()
  activeChannel = new ActiveChannel()
  activeChannelWindowList = new ActiveChannelWindowList({ maxLength: 3 })
  channelListWindowIsOpened = false
  isFetchingChannelList = true
  starredChannelIds: string[] = []
}
