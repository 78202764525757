import { OcctoMasterType, OcctoMasterValue } from '@/api/generated/api'
import { i18n } from '@/i18n'

export class OcctoMaster implements OcctoMasterValue {
  occtoMasterType!: OcctoMasterType

  code!: string

  name!: string

  companyPrefix!: string

  get displayOcctoMasterTypeName(): string {
    return this.occtoMasterType === OcctoMasterType.Demand
      ? i18n.t('trading.label.balancingGroup').toString()
      : i18n.t('trading.label.supplyPlanSubmitter').toString()
  }

  get displayCodeLabel(): string {
    return this.occtoMasterType === OcctoMasterType.Demand
      ? i18n.t('trading.label.balancingGroupCode').toString()
      : i18n.t('trading.label.supplyPlanSubmitterCode').toString()
  }

  get displayNameLabel(): string {
    return this.occtoMasterType === OcctoMasterType.Demand
      ? i18n.t('trading.label.balancingGroupName').toString()
      : i18n.t('trading.label.supplyPlanSubmitterName').toString()
  }
}
