import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b8a3b56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('iam.label.userName'),
      data: _ctx.displayName
    }, null, 8, ["label", "data"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('iam.label.mail'),
      data: _ctx.member.mail
    }, null, 8, ["label", "data"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('iam.label.phoneNumber'),
      data: _ctx.displayPhoneNumber
    }, null, 8, ["label", "data"]),
    _createVNode(_component_base_dataset, {
      label: _ctx.$t('iam.label.mobilePhoneNumber'),
      data: _ctx.displayMobilePhoneNumber
    }, null, 8, ["label", "data"])
  ]))
}