
import { defineComponent, PropType } from 'vue'

import { PositionType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import CreditSleeveIcon from '@/components/trading/CreditSleeveIcon.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import { TradingSteps } from '@/models/trading/TradingSteps'

export default defineComponent({
  name: 'TradingSteps',
  components: {
    BaseBox,
    BaseLink,
    CreditSleeveIcon,
    PositionIcon,
  },
  props: {
    tradingSteps: {
      type: Object as PropType<TradingSteps>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    positionAsk: PositionType
    positionBid: PositionType
  } {
    return {
      positionAsk: PositionType.Ask,
      positionBid: PositionType.Bid,
    }
  },
  computed: {
    askIndicationClickable(): boolean {
      return this.askIndicationUrl !== this.$route.path
    },
    bidIndicationClickable(): boolean {
      return this.bidIndicationUrl !== this.$route.path
    },
    negotiationClickable(): boolean {
      return this.negotiationUrl !== this.$route.path
    },
    dealClickableList(): boolean[] {
      return this.tradingSteps.deals.map(
        deal => `/deals/${deal.id}` !== this.$route.path,
      )
    },
    askIndicationUrl(): string {
      return `/indications/${this.tradingSteps.askIndication?.id}`
    },
    bidIndicationUrl(): string {
      return `/indications/${this.tradingSteps.bidIndication?.id}`
    },
    negotiationUrl(): string {
      return `/negotiations/${this.tradingSteps.negotiation?.id}`
    },
    dealUrlList(): string[] {
      return this.tradingSteps.deals.map(deal => `/deals/${deal.id}`)
    },
  },
})
