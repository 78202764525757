
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChatInputForm',
  props: {
    value: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'typing', 'send'],
  computed: {
    placeholder(): string {
      return this.$t('chat.message.placeholder').toString()
    },
  },
  methods: {
    onInput(event: Event) {
      if (event.target instanceof HTMLTextAreaElement) {
        this.$emit('input', event.target.value)
      }
    },
    onSend() {
      this.$emit('send')
    },
    onTyping() {
      this.$emit('typing')
    },
  },
})
