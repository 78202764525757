import { Getters } from 'vuex-smart-module'

import { GlobalState } from '@/store/modules/common/global/GlobalState'

export class GlobalGetters extends Getters<GlobalState> {
  get organizationName(): string | undefined {
    return this.state.organizationName?.translation()
  }

  get organizationPicture(): string | undefined {
    return this.state.organizationPicture
  }
}
