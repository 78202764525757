import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ed3f7f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-list-item__inner" }
const _hoisted_2 = { class: "deal-list-item__column" }
const _hoisted_3 = { class: "deal-list-item__column" }
const _hoisted_4 = { class: "deal-list-item__column" }
const _hoisted_5 = { class: "deal-list-item__column" }
const _hoisted_6 = { class: "deal-list-item__volumes" }
const _hoisted_7 = { class: "deal-list-item__volumes__column" }
const _hoisted_8 = { class: "deal-list-item__volumes__text" }
const _hoisted_9 = {
  key: 0,
  class: "deal-list-item__volumes__column"
}
const _hoisted_10 = { class: "deal-list-item__volumes__text" }
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = { class: "deal-list-item__private-confidential" }
const _hoisted_14 = { class: "deal-list-item__column" }
const _hoisted_15 = { class: "deal-list-item__column" }
const _hoisted_16 = { class: "deal-list-item__total-broking-fee" }
const _hoisted_17 = { class: "deal-list-item__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delivery_unit_icon = _resolveComponent("delivery-unit-icon")!
  const _component_spread_badge_list = _resolveComponent("spread-badge-list")!
  const _component_delivery_terms = _resolveComponent("delivery-terms")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_credit_sleeve_icon = _resolveComponent("credit-sleeve-icon")!
  const _component_base_input_single_checkbox = _resolveComponent("base-input-single-checkbox")!
  const _component_deal_recap_status = _resolveComponent("deal-recap-status")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_link = _resolveComponent("base-link")!

  return (_openBlock(), _createBlock(_component_base_link, {
    class: "deal-list-item",
    "include-child-action": "",
    "reset-font-style": "",
    path: _ctx.itemPath,
    onMouseover: _ctx.onMouseOver,
    onMouseleave: _ctx.onMouseLeave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_box, { spacing: "0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_delivery_unit_icon, {
                class: "deal-list-item__delivery-unit-icon",
                "delivery-unit": _ctx.deal.product.deliveryUnit
              }, null, 8, ["delivery-unit"])
            ]),
            _createVNode(_component_spread_badge_list, {
              "spread-types": _ctx.deal.spreadTypes
            }, null, 8, ["spread-types"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_delivery_terms, {
                "area-name": _ctx.deliveryTerms.base.areaName,
                "delivery-start": _ctx.deliveryTerms.base.deliveryStart,
                "delivery-end": _ctx.deliveryTerms.base.deliveryEnd,
                "hour-type-name": _ctx.deliveryTerms.base.hourTypeName
              }, null, 8, ["area-name", "delivery-start", "delivery-end", "hour-type-name"]),
              (_ctx.deal.isSpread)
                ? (_openBlock(), _createBlock(_component_delivery_terms, {
                    key: 0,
                    "area-name": _ctx.deliveryTerms.exchange.areaName,
                    "delivery-start": _ctx.deliveryTerms.exchange.deliveryStart,
                    "delivery-end": _ctx.deliveryTerms.exchange.deliveryEnd,
                    "hour-type-name": _ctx.deliveryTerms.exchange.hourTypeName
                  }, null, 8, ["area-name", "delivery-start", "delivery-end", "hour-type-name"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_product_type, {
                name: _ctx.deal.displayProductTypeNames()
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.deal.price.energyUnitPrices[0]), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.deal.volumes[0]), 1)
                ]),
                (_ctx.deal.exchangeVolumes)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.deal.exchangeVolumes[0]), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", {
              class: "deal-list-item__column",
              title: _ctx.deal.displayAskOrganizationName()
            }, [
              (_ctx.deal.askDealDetail.isCreditSleeve)
                ? (_openBlock(), _createBlock(_component_credit_sleeve_icon, { key: 0 }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.deal.displayAskOrganizationAbbreviation()), 1)
                  ], 64))
            ], 8, _hoisted_11),
            _createElementVNode("div", {
              class: "deal-list-item__column",
              title: _ctx.deal.displayBidOrganizationName()
            }, [
              (_ctx.deal.bidDealDetail.isCreditSleeve)
                ? (_openBlock(), _createBlock(_component_credit_sleeve_icon, { key: 0 }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.deal.displayBidOrganizationAbbreviation()), 1)
                  ], 64))
            ], 8, _hoisted_12),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_base_input_single_checkbox, {
                class: "deal-list-item__private-confidential-checkbox",
                name: "isPrivateConfidential",
                value: _ctx.deal.isPrivateConfidential,
                mode: _ctx.privateConfidentialInputMode,
                onClick: _withModifiers(_ctx.onPrivateConfidentialChange, ["stop"])
              }, null, 8, ["value", "mode", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.deal.publicNegotiationId), 1),
            _createElementVNode("div", _hoisted_15, [
              (_ctx.recapState[_ctx.deal.askDealDetail.organizationId])
                ? (_openBlock(), _createBlock(_component_deal_recap_status, {
                    key: 0,
                    "deal-type": _ctx.$t('trading.label.sellerName'),
                    status: _ctx.recapState[_ctx.deal.askDealDetail.organizationId]
                  }, null, 8, ["deal-type", "status"]))
                : _createCommentVNode("", true),
              (_ctx.recapState[_ctx.deal.bidDealDetail.organizationId])
                ? (_openBlock(), _createBlock(_component_deal_recap_status, {
                    key: 1,
                    "deal-type": _ctx.$t('trading.label.buyerName'),
                    status: _ctx.recapState[_ctx.deal.bidDealDetail.organizationId]
                  }, null, 8, ["deal-type", "status"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.deal.displayTotalBrokingFee()), 1),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.deal.displayExecutedAt()), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_action_dropdown, {
                id: _ctx.deal.id,
                actions: _ctx.actions,
                "is-active": _ctx.isActionDropdownActive,
                "is-parent-active": _ctx.isActive,
                "is-right": "",
                onClick: _withModifiers(_ctx.onActionDropdownClick, ["stop"]),
                onClickOutside: _ctx.onClickOutside,
                onConfirm: _ctx.onConfirm
              }, null, 8, ["id", "actions", "is-active", "is-parent-active", "onClick", "onClickOutside", "onConfirm"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["path", "onMouseover", "onMouseleave"]))
}