import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a186bed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-indication" }
const _hoisted_2 = { class: "my-indication__column" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_position_icon = _resolveComponent("position-icon")!
  const _component_delivery_terms_with_period = _resolveComponent("delivery-terms-with-period")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_old_unit_price_and_volume = _resolveComponent("old-unit-price-and-volume")!
  const _component_my_indication_status = _resolveComponent("my-indication-status")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["my-indication__inner", _ctx.myIndicationClass]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
          onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
          onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
        }, [
          _createVNode(_component_position_icon, {
            class: "my-indication__position-icon",
            position: _ctx.myIndication.position,
            "color-reversed": _ctx.colorReversed
          }, null, 8, ["position", "color-reversed"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_delivery_terms_with_period, { "delivery-terms": _ctx.deliveryTerms }, null, 8, ["delivery-terms"])
          ]),
          _createVNode(_component_product_type, { name: _ctx.productTypeNames }, null, 8, ["name"]),
          _createElementVNode("div", {
            class: "my-indication__fuel-surcharge-type-name",
            title: _ctx.fuelSurchargeTypeName
          }, _toDisplayString(_ctx.fuelSurchargeTypeName), 9, _hoisted_3),
          _createVNode(_component_old_unit_price_and_volume, {
            class: "my-indication__unit-price-and-volume",
            position: _ctx.myIndication.position,
            "color-disabled": "",
            "unit-price": _ctx.myIndication.unitPrice,
            volume: _ctx.myIndication.volume,
            locale: _ctx.locale,
            "exchange-volume": _ctx.myIndication.exchangeVolume?.toString()
          }, null, 8, ["position", "unit-price", "volume", "locale", "exchange-volume"]),
          _createVNode(_component_my_indication_status, {
            class: "my-indication__status",
            status: _ctx.myIndication.status
          }, null, 8, ["status"]),
          _createElementVNode("div", {
            class: "my-indication__created-participant-name",
            title: _ctx.createdParticipantName
          }, _toDisplayString(_ctx.createdParticipantName), 9, _hoisted_4),
          _createElementVNode("div", {
            class: "my-indication__updated-at",
            title: _ctx.myIndication.displayUpdatedAt(_ctx.$t('common.format.formatDate'))
          }, _toDisplayString(_ctx.myIndication.displayUpdatedAt(_ctx.$t('common.format.formatDate'))), 9, _hoisted_5),
          _createVNode(_component_action_dropdown, {
            id: _ctx.myIndication.indicationId,
            class: "my-indication__dropdown",
            actions: _ctx.actions,
            "is-active": _ctx.isActionDropdownActive,
            "is-parent-active": _ctx.isActive,
            onClick: _withModifiers(_ctx.onActionDropdownClick, ["stop"]),
            onActivate: _ctx.onActivate,
            onDeactivate: _ctx.onDeactivate,
            onDelete: _ctx.onDelete,
            onClickOutside: _ctx.onClickOutside
          }, null, 8, ["id", "actions", "is-active", "is-parent-active", "onClick", "onActivate", "onDeactivate", "onDelete", "onClickOutside"])
        ], 34)
      ]),
      _: 1
    })
  ]))
}