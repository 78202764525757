import { Module } from 'vuex-smart-module'

import { WelcomeActions } from '@/store/modules/iam/welcome/WelcomeActions'
import { WelcomeGetters } from '@/store/modules/iam/welcome/WelcomeGetters'
import { WelcomeMutations } from '@/store/modules/iam/welcome/WelcomeMutations'
import { WelcomeState } from '@/store/modules/iam/welcome/WelcomeState'

export const welcome = new Module({
  state: WelcomeState,
  getters: WelcomeGetters,
  mutations: WelcomeMutations,
  actions: WelcomeActions,
})

export type WelcomeModule = typeof welcome
