
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import TraderSelectorModal from '@/components/trading/container/TraderSelectorModal.vue'
import { EexTrader } from '@/models/trading/EexTrader'

export default defineComponent({
  name: 'TraderSelector',
  components: {
    BaseModal,
    BasePicture,
    TraderSelectorModal,
  },
  props: {
    id: {
      type: String,
      default: '1',
    },
    organizationId: {
      type: String,
      default: undefined,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedTrader: {
      type: Object as PropType<EexTrader>,
      default: undefined,
    },
  },
  emits: ['select'],
  computed: {
    traderSelectorModalName(): string {
      return `traderSelectorModal-${this.id}`
    },
    selectedTraderName(): string {
      return this.selectedTrader?.eexTraderName || ''
    },
  },
  methods: {
    onSelect(trader: EexTrader) {
      this.$emit('select', trader)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.traderSelectorModalName)
    },
    hideModal() {
      this.$vfm.close(this.traderSelectorModalName)
    },
  },
})
