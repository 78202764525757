import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56dbcc3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input-checkbox" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "base-input-checkbox__value" }
const _hoisted_6 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_field = _resolveComponent("field")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_status_toggle_button = _resolveComponent("status-toggle-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_base_label, {
          label: _ctx.labelString,
          help: _ctx.help,
          class: "base-input-checkbox__label"
        }, null, 8, ["label", "help"]),
        _createElementVNode("div", {
          class: _normalizeClass(["base-input-checkbox__wrapper", _ctx.wrapperClass]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              class: _normalizeClass(["base-input-checkbox__checkbox checkbox", _ctx.labelClass]),
              disabled: _ctx.labelDisabled(option) || undefined
            }, [
              _createVNode(_component_field, {
                "model-value": _ctx.values,
                name: _ctx.name,
                rules: _ctx.rules,
                value: option.value,
                type: "checkbox",
                class: _normalizeClass(["base-input-checkbox__input checkbox", _ctx.inputClass]),
                disabled: _ctx.inputDisabled(option),
                "onUpdate:modelValue": _ctx.onChange,
                "validate-on-mount": ""
              }, null, 8, ["model-value", "name", "rules", "value", "class", "disabled", "onUpdate:modelValue"]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1)
            ], 10, _hoisted_4))
          }), 128)),
          _createVNode(_component_error_message, { name: _ctx.name }, {
            default: _withCtx(({ message }) => [
              (_ctx.touched)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(message), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["name"])
        ], 2)
      ]),
      (_ctx.togglable)
        ? (_openBlock(), _createBlock(_component_status_toggle_button, {
            key: 0,
            "all-inactive": _ctx.allInactive,
            "activate-all-label": 
          _ctx.activeAllLabel
            ? _ctx.activeAllLabel
            : _ctx.$t('common.label.activateAll').toString()
        ,
            "deactivate-all-label": 
          _ctx.deActiveAllLabel
            ? _ctx.deActiveAllLabel
            : _ctx.$t('common.label.deactivateAll').toString()
        ,
            onActivateAll: _ctx.onActivateAll,
            onDeactivateAll: _ctx.onDeactivateAll
          }, null, 8, ["all-inactive", "activate-all-label", "deactivate-all-label", "onActivateAll", "onDeactivateAll"]))
        : _createCommentVNode("", true)
    ])
  ]))
}