
import { defineComponent, PropType } from 'vue'

import { PositionType } from '@/api/generated'
import UnitPriceAndVolume from '@/components/trading/UnitPriceAndVolume.vue'
import { OrderBook } from '@/models/trading/OrderBook'

export default defineComponent({
  name: 'OrderBestPrice',
  components: {
    UnitPriceAndVolume,
  },
  props: {
    orderBook: {
      type: Object as PropType<OrderBook>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    ask: PositionType
    bid: PositionType
  } {
    return {
      ask: PositionType.Ask,
      bid: PositionType.Bid,
    }
  },
  computed: {
    exchangeAskVolume(): string | undefined {
      return this.orderBook.exchange?.askVolume
    },
    exchangeBidVolume(): string | undefined {
      return this.orderBook.exchange?.bidVolume
    },
    exchangeLastVolume(): string | undefined {
      return this.orderBook.exchange?.lastVolume
    },
  },
})
