
import { defineComponent, PropType } from 'vue'

import { OrganizationEmailAddress } from '@/api/generated'
import OrganizationEmailAddressListItem from '@/components/iam/OrganizationEmailAddressListItem.vue'

export default defineComponent({
  name: 'OrganizationEmailAddressList',
  components: {
    OrganizationEmailAddressListItem,
  },
  props: {
    organizationEmailAddresses: {
      type: Array as PropType<OrganizationEmailAddress[]>,
      default() {
        return []
      },
    },
    parentPath: {
      type: String,
      required: true,
    },
  },
  emits: ['delete'],
  methods: {
    onDeleteClick(organizationEmailAddress: OrganizationEmailAddress) {
      this.$emit('delete', organizationEmailAddress)
    },
  },
})
