import { Module } from 'vuex-smart-module'

import { BrokingActions } from '@/store/modules/trading/broking/BrokingActions'
import { BrokingGetters } from '@/store/modules/trading/broking/BrokingGetters'
import { BrokingMutations } from '@/store/modules/trading/broking/BrokingMutations'
import { BrokingState } from '@/store/modules/trading/broking/BrokingState'

export const broking = new Module({
  state: BrokingState,
  getters: BrokingGetters,
  mutations: BrokingMutations,
  actions: BrokingActions,
})

export type BrokingModule = typeof broking
