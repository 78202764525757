
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import {
  DeliveryUnit,
  IndicationStatusType as IndicationStatusTypeEnum,
  Locale,
  UpdateStandardIndication,
  UpdateStandardIndicationWithId,
  UserType,
} from '@/api/generated'
import NewMessage from '@/components/chat/NewMessage.vue'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import BrokingIndicationStatusChanger from '@/components/trading/BrokingIndicationStatusChanger.vue'
import BrokingOrderBookList from '@/components/trading/BrokingOrderBookList.vue'
import BrokingOrderBookSpreadList from '@/components/trading/BrokingOrderBookSpreadList.vue'
import CopyToClipboardIcon from '@/components/trading/CopyToClipboardIcon.vue'
import FuelSurchargeTypeTab from '@/components/trading/FuelSurchargeTypeTab.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import BrokerIndicationNew from '@/components/trading/container/BrokerIndicationNew.vue'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { MultiLocaleMessageBody } from '@/models/chat/MultiLocaleMessageBody'
import { Translation } from '@/models/chat/Translation'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'
import { Indication } from '@/models/trading/Indication'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'
import { Product } from '@/models/trading/Product'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { UpdatedIndicationInBulk } from '@/models/trading/UpdatedIndicationInBulk'
import { ChatBroadcastModule } from '@/store/modules/chat/broadcast'
import {
  SET_LOCKED,
  SET_MESSAGE,
  SET_RECIPIENT_SEARCH_INPUT,
  SET_RECIPIENT_SELECTED_LANGUAGE,
  SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
} from '@/store/modules/chat/broadcast/ChatBroadcastMutations'
import { PublicOrganizationProfileModule } from '@/store/modules/iam/publicOrganizationProfile'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { SET_SERVICES } from '@/store/modules/iam/userProfile/UserProfileMutations'
import { BrokingModule } from '@/store/modules/trading/broking'
import {
  SET_BROKING_INDICATIONS,
  SET_FILTER_CONDITION,
  SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE,
  SET_SELECTED_FILTERS,
  SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE,
  SET_SELECTED_INDICATION_IDS,
  UPDATE_BROKING_INDICATION_BY_ID,
  SET_FILTER_CONDITION_WITH_SPREAD,
  SET_SELECTED_FILTERS_WITH_SPREAD,
} from '@/store/modules/trading/broking/BrokingMutations'
import { BrokingFilterAttribute } from '@/store/modules/trading/broking/interface'
import { IndicationModule } from '@/store/modules/trading/indication'
import { ProductModule } from '@/store/modules/trading/product'
import {
  BaseFilterAttribute,
  TAB_INDEXES,
} from '@/store/modules/trading/utils/interface'
import { moment } from '@/utils/date'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

type StateType = {
  isFilterActionDropdownActive: boolean
  intervalId: number
  isDownloadActionDropdownActive: boolean
  downloadActions: ActionDropdownItem[]
  newMessageModalName: string
  newIndicationModalName: string
  activeTabIndex: number
  statusTypes: IndicationStatusType[]
  isEditMode: boolean
  indicationSubmitting: boolean
  targetIndicationId: string | undefined
  actions: ActionDropdownItem[]
  isActionDropdownOfOpenToMarketInBulkActive: boolean
  isActionDropdownOfVisibleToTradersInBulkActive: boolean
  isStatusSubmitting: boolean
  isOpenToMarketSubmitting: boolean
  isVisibleToTradersSubmitting: boolean
  isBeforeUnmountCalled: boolean
  isAdmin: boolean
  layoutMode: LayoutMode
  copyFromIndicationId: string | undefined
  uiStack: UiStack
  alertType: AlertType
}

type MappedArrayType<T> = {
  tabIndex: number
  data: Record<typeof TAB_INDEXES[keyof typeof TAB_INDEXES], T>
}

const getMappedArray = <T extends Array<unknown>>({
  tabIndex,
  data,
}: MappedArrayType<T>) => {
  if (tabIndex in data) {
    return data[tabIndex]
  }
  return []
}

const {
  mapMutations: userProfileMapMutations,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule
const {
  mapState: broadcastMapState,
  mapActions: broadcastMapActions,
  mapMutations: broadcastMapMutations,
  mapGetters: broadcastMapGetters,
} = createNamespacedHelpers('chatBroadcast') as ChatBroadcastModule
const { mapState: publicOrganizationProfileState } = createNamespacedHelpers(
  'publicOrganizationProfile',
) as PublicOrganizationProfileModule
const {
  mapState: brokingMapState,
  mapActions: brokingMapActions,
  mapMutations: brokingMapMutations,
  mapGetters: brokingMapGetters,
} = createNamespacedHelpers('broking') as BrokingModule

const { mapActions: indicationMapActions } = createNamespacedHelpers(
  'indication',
) as IndicationModule

const {
  mapState: productMapState,
  mapActions: productMapActions,
} = createNamespacedHelpers('product') as ProductModule

export default defineComponent({
  name: 'Broking',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    BaseIcon,
    BaseModal,
    BaseTooltip,
    BrokerIndicationNew,
    BrokerPage,
    BrokingIndicationStatusChanger,
    BrokingOrderBookList,
    BrokingOrderBookSpreadList,
    CenteredLoadingContent,
    CopyToClipboardIcon,
    FuelSurchargeTypeTab,
    NewMessage,
    ProductFilter,
    UiStackSelector,
  },
  data(): StateType {
    return {
      isFilterActionDropdownActive: false,
      intervalId: 0,
      isDownloadActionDropdownActive: false,
      downloadActions: [
        {
          label: this.$t('trading.message.orderCsvDownload').toString(),
          eventName: 'order-csv-download-click',
        },
        {
          label: this.$t('trading.message.recentDealCsvDownload').toString(),
          eventName: 'recent-deal-csv-download-click',
        },
      ],
      newMessageModalName: 'new-message',
      newIndicationModalName: 'new-indication',
      activeTabIndex: TAB_INDEXES.NO_FUEL_SURCHARGE,
      statusTypes: [],
      isEditMode: false,
      indicationSubmitting: false,
      targetIndicationId: undefined,
      actions: [
        {
          label: this.$t('trading.label.open').toString(),
          eventName: 'open',
        },
        {
          label: this.$t('trading.label.close').toString(),
          eventName: 'close',
        },
      ],
      isActionDropdownOfOpenToMarketInBulkActive: false,
      isActionDropdownOfVisibleToTradersInBulkActive: false,
      isStatusSubmitting: false,
      isOpenToMarketSubmitting: false,
      isVisibleToTradersSubmitting: false,
      isBeforeUnmountCalled: false,
      isAdmin: false,
      layoutMode: LayoutMode.Wide,
      copyFromIndicationId: undefined,
      uiStack: UiStack.Loading,
      alertType: AlertType.Error,
    }
  },
  computed: {
    ...userProfileMapState(['services', 'userId']),
    ...userProfileMapGetters(['userProfile']),
    ...broadcastMapState([
      'recipientSearchInput',
      'message',
      'recipientSelectedLanguage',
      'locked',
    ]),
    ...broadcastMapGetters(['recipientList']),
    ...userProfileMapGetters(['userProfile']),
    ...publicOrganizationProfileState(['publicOrganizationProfiles']),
    ...brokingMapState([
      'filterCondition',
      'filterConditionWithFuelSurcharge',
      'filterConditionWithSpread',
      'brokingIndications',
      'brokingDeals',
      'brokingOrderBooks',
      'selectedIndicationIds',
      'users',
      'filterAttributes',
      'filterAttributesWithSpread',
      'selectedFilters',
      'selectedFiltersWithFuelSurcharge',
      'selectedFiltersWithSpread',
    ]),
    ...brokingMapGetters([
      'filteredBrokingIndications',
      'filteredBrokingIndicationsWithFuelSurcharge',
      'filteredBrokingIndicationsWithSpread',
      'filteredBrokingOrderBooksWithSpread',
      'filteredBrokingDeals',
      'filteredBrokingDealsWithFuelSurcharge',
      'filteredBrokingDealsWithSpread',
      'productFilter',
      'productFilterWithFuelSurcharge',
      'productFilterWithSpread',
    ]),
    ...productMapState(['productTypes', 'areas', 'hourTypes']),
    displayCreateMessageButton() {
      return isBrokerApp
    },
    filterActions(): ActionDropdownItem[] {
      return this.isSpreadActiveTabIndex
        ? this.filterAttributesWithSpread.map(attribute => ({
            label: this.$t('trading.label.' + attribute).toString(),
            eventName: attribute,
          }))
        : this.filterAttributes.map(attribute => ({
            label: this.$t('trading.label.' + attribute).toString(),
            eventName: attribute,
          }))
    },
    displayChatInputValue(): string {
      return this.message instanceof MultiLocaleMessageBody
        ? this.message.translationOf(this.userProfile.locale)
        : this.message
    },
    orderBookIndications(): BrokingIndication[] {
      return getMappedArray<BrokingIndication[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.filteredBrokingIndications,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .filteredBrokingIndicationsWithFuelSurcharge,
        },
      })
    },
    spreadBrokingOrderBooks(): BrokingOrderBook[] {
      return this.filteredBrokingOrderBooksWithSpread
    },
    orderBookDeals(): BrokingDeal[] {
      return getMappedArray<BrokingDeal[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.filteredBrokingDeals,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .filteredBrokingDealsWithFuelSurcharge,
          [TAB_INDEXES.SPREAD]: this.filteredBrokingDealsWithSpread,
        },
      })
    },
    productTypeFilterCondition(): string[] {
      return getMappedArray<string[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.filterCondition.productType,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .filterConditionWithFuelSurcharge.productType,
          [TAB_INDEXES.SPREAD]: this.filterConditionWithSpread.productType,
        },
      })
    },
    isBgActiveTabIndex(): boolean {
      return [
        TAB_INDEXES.NO_FUEL_SURCHARGE,
        TAB_INDEXES.WITH_FUEL_SURCHARGE,
      ].includes(this.activeTabIndex)
    },
    isSpreadActiveTabIndex(): boolean {
      return this.activeTabIndex === TAB_INDEXES.SPREAD
    },
    isEtenderActiveTabIndex(): boolean {
      return this.activeTabIndex === TAB_INDEXES.ETENDER
    },
    withFuelSurcharge(): boolean {
      return this.activeTabIndex === TAB_INDEXES.WITH_FUEL_SURCHARGE
    },
    productFilters(): AttributeFilter[] {
      return getMappedArray<AttributeFilter[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.productFilter({
            locale: this.userProfile.locale,
            areas: this.areas,
            hourTypes: this.hourTypes,
            productTypes: this.productTypes,
          }),
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this.productFilterWithFuelSurcharge(
            {
              locale: this.userProfile.locale,
              areas: this.areas,
              hourTypes: this.hourTypes,
              productTypes: this.productTypes,
            },
          ),
          [TAB_INDEXES.SPREAD]: this.productFilterWithSpread({
            locale: this.userProfile.locale,
            areas: this.areas,
            hourTypes: this.hourTypes,
            productTypes: this.productTypes,
          }),
        },
      })
    },
    selectedProductFilters(): BrokingFilterAttribute[] {
      return getMappedArray<BrokingFilterAttribute[]>({
        tabIndex: this.activeTabIndex,
        data: {
          [TAB_INDEXES.NO_FUEL_SURCHARGE]: this.selectedFilters,
          [TAB_INDEXES.WITH_FUEL_SURCHARGE]: this
            .selectedFiltersWithFuelSurcharge,
          [TAB_INDEXES.SPREAD]: this.selectedFiltersWithSpread,
        },
      })
    },
    selectedIndications(): BrokingIndication[] {
      const selectedIndications: BrokingIndication[] = []
      this.selectedIndicationIds.forEach(id => {
        const selectedIndication = this.brokingIndications.find(
          indication => indication.indicationId === id,
        )
        if (selectedIndication) {
          selectedIndications.push(selectedIndication)
        }
      })
      return selectedIndications
    },
    isIncludedEclear(): boolean {
      return this.selectedIndications.some(indication =>
        indication.products.some(p => p.productType.isEclear()),
      )
    },
    isIncludedEclearAndNotDailyPowerMarket(): boolean {
      return this.selectedIndications.some(
        indication =>
          indication.products.some(p => p.productType.isEclear()) &&
          !indication.isDailyPowerMarket,
      )
    },
    enabledChangingStatusOfSelectedIndications(): boolean {
      if (this.selectedIndications.length === 0) {
        return false
      }
      return (
        this.selectedIndications.every(
          indication =>
            indication.status === this.selectedIndications[0].status,
        ) &&
        !this.isIncludedEclearAndNotDailyPowerMarket &&
        this.statusTypes.find(
          statusType => statusType.id === this.selectedIndications[0].status,
        )?.changeableStatuses.length !== 0
      )
    },
    statusOfSelectedIndications(): IndicationStatusTypeEnum | undefined {
      return this.enabledChangingStatusOfSelectedIndications
        ? this.selectedIndications[0].status
        : undefined
    },
    hasIndicationOfNegotiating(): boolean {
      return this.selectedIndications.some(
        indication =>
          indication.status === IndicationStatusTypeEnum.Negotiating,
      )
    },
  },
  async created() {
    if (this.$route.query.tabIndex) {
      this.activeTabIndex = Number(this.$route.query.tabIndex)
    }
    this.loadFiltersFromLocalStorage()
    this.isAdmin = (await this.$auth.getUserType()) === UserType.SystemAdmin

    await this.fetchBrokingData().catch(e => {
      this.uiStack = UiStack.Error
      throw e
    })
    this.uiStack = UiStack.Ideal

    if (this.isBeforeUnmountCalled) {
      return
    }

    this.intervalId = window.setInterval(this.fetchBrokingData, 300000)
  },
  async mounted() {
    await this.setupStoreState().catch(e => {
      this.uiStack = UiStack.Error
      throw e
    })
  },
  beforeUnmount() {
    this.isBeforeUnmountCalled = true
    clearInterval(this.intervalId)
  },
  methods: {
    fetchBrokingRecentDeals: brokingMapActions(['fetchBrokingRecentDeals'])
      .fetchBrokingRecentDeals as () => Promise<BrokingDeal[]>,
    ...brokingMapActions([
      'loadBrokingIndications',
      'loadBrokingDeals',
      'addSelectedIndicationId',
      'removeSelectedIndicationId',
      'resetSelectedIndicationIds',
      'addFilterConditionSelected',
      'removeFilterConditionSelected',
      'loadUsers',
      'loadBrokingOrderBooks',
      'loadFiltersFromLocalStorage',
      'removeFilterCondition',
      'addFilterConditionInputSelected',
      'changeFilter',
    ]),
    updateStandardIndication: indicationMapActions(['updateStandardIndication'])
      .updateStandardIndication as (payload: {
      id: string
      standardIndication: UpdateStandardIndication
    }) => Promise<Indication>,
    updateStandardIndicationInBulk: indicationMapActions([
      'updateStandardIndicationInBulk',
    ]).updateStandardIndicationInBulk as (
      payload: UpdateStandardIndicationWithId[],
    ) => Promise<UpdatedIndicationInBulk>,
    ...indicationMapActions(['fetchIndicationStatusTypes']),
    ...broadcastMapActions([
      'loadOrganizationChannels',
      'sendNewMessage',
      'sendMultiLocaleNewMessage',
      'addSelectedOrganizationChannelIds',
    ]),
    ...broadcastMapMutations([
      SET_RECIPIENT_SEARCH_INPUT,
      SET_MESSAGE,
      SET_SELECTED_ORGANIZATION_CHANNEL_IDS,
      SET_RECIPIENT_SELECTED_LANGUAGE,
      SET_LOCKED,
    ]),
    ...brokingMapMutations([
      SET_BROKING_INDICATIONS,
      SET_FILTER_CONDITION,
      SET_FILTER_CONDITION_WITH_FUEL_SURCHARGE,
      SET_FILTER_CONDITION_WITH_SPREAD,
      SET_SELECTED_FILTERS,
      SET_SELECTED_FILTERS_WITH_FUEL_SURCHARGE,
      SET_SELECTED_FILTERS_WITH_SPREAD,
      UPDATE_BROKING_INDICATION_BY_ID,
      SET_SELECTED_INDICATION_IDS,
    ]),
    ...userProfileMapMutations([SET_SERVICES]),
    ...productMapActions(['fetchAreas', 'fetchHourTypes', 'fetchProductTypes']),
    async fetchBrokingData() {
      await Promise.all([
        this.fetchIndicationStatusTypes() as Promise<IndicationStatusType[]>,
        this.loadBrokingIndications(),
        this.loadBrokingDeals(),
        this.loadBrokingOrderBooks(true),
      ]).then(([statusTypes]) => {
        this.statusTypes = statusTypes
      })

      await this.loadUsers()

      if (this.isAdmin) {
        return
      }
      await this.loadOrganizationChannels({
        client: this.$chat.client,
        user: this.userProfile,
      })
    },
    downloadOrderCsvData(
      indications: PublicIndication[] | BrokingIndication[],
      isBroking: boolean,
    ) {
      const csv = this.createOrderCsvData(indications, isBroking)
      this.downloadCsvData(csv, 'order-data')
      this.isDownloadActionDropdownActive = false
    },
    downloadRecentTradedCsvData(
      deals: PublicDeal[] | BrokingDeal[],
      isBroking: boolean,
    ) {
      const csv = this.createRecentTradedCsvData(deals, isBroking)
      this.downloadCsvData(csv, 'recent-traded-data')
      this.isDownloadActionDropdownActive = false
    },
    createOrderCsvData(
      indications: PublicIndication[] | BrokingIndication[],
      isBroking: boolean,
    ) {
      const locale = this.userProfile.locale
      const header = this.createCsvHeader(true, isBroking)
      const rows = [header.join(',')]

      for (const indication of indications) {
        const indicationElements = []
        if (isBroking && indication instanceof BrokingIndication) {
          indicationElements.push(
            indication.publicOrganizationProfile.name.translationOf(locale),
          )
        }
        indicationElements.push(
          indication.position === 'ask'
            ? this.$t('trading.label.ask')
            : this.$t('trading.label.bid'),
        )

        indicationElements.push(
          indication.fuelSurchargeType?.translationOf(locale),
        )
        indicationElements.push(indication.unitPrice)
        indicationElements.push(indication.volume)
        for (const product of indication.products) {
          const productElements = []
          productElements.push(product.area.translationOf(locale))
          productElements.push(product.formatStartDelivery)
          productElements.push(product.formatEndDelivery)
          productElements.push(product.hourType?.translationOf(locale) ?? '')
          productElements.push(product.productType.translationOf(locale))
          const row = [...productElements, ...indicationElements]

          rows.push(`"${row.join('","')}"`)
        }
      }
      return rows.join('\n')
    },
    createRecentTradedCsvData(
      deals: PublicDeal[] | BrokingDeal[],
      isBroking: boolean,
    ) {
      const locale = this.userProfile.locale
      const header = this.createCsvHeader(false, isBroking)
      const rows = [header.join(',')]

      for (const deal of deals) {
        const dealElements = []
        if (isBroking) {
          dealElements.push(
            (deal as BrokingDeal).sellerAccountName.translationOf(locale),
            (deal as BrokingDeal).buyerAccountName.translationOf(locale),
          )
        }

        dealElements.push(this.$t('trading.label.last'))

        dealElements.push(deal.fuelSurchargeType?.translationOf(locale))
        dealElements.push(deal.unitPrice)
        dealElements.push(deal.volume)
        dealElements.push(deal.displayCreatedAt())

        const productElements = []
        productElements.push(deal.product.area.translationOf(locale))
        productElements.push(deal.product.formatStartDelivery)
        productElements.push(deal.product.formatEndDelivery)
        productElements.push(deal.product.hourType?.translationOf(locale) ?? '')
        productElements.push(deal.product.productType.translationOf(locale))
        const row = [...productElements, ...dealElements]

        rows.push(`"${row.join('","')}"`)
      }
      return rows.join('\n')
    },
    createCsvHeader(isIndication: boolean, isBroking: boolean) {
      const header = [
        this.$t('trading.label.area'),
        this.$t('trading.label.startDelivery'),
        this.$t('trading.label.endDelivery'),
        this.$t('trading.label.hourType'),
        this.$t('trading.label.productType'),
        this.$t('trading.label.orderType'),
        this.$t('trading.label.fuelSurchargeType'),
        this.$t('trading.label.unitPrice'),
        this.$t('trading.label.volume'),
        this.$t('trading.label.executedDate'),
      ]
      if (isBroking) {
        if (isIndication) {
          header.splice(5, 0, this.$t('trading.label.accountName'))
        } else {
          header.splice(
            5,
            0,
            this.$t('trading.label.sellerName'),
            this.$t('trading.label.buyerName'),
          )
        }
      }
      return header
    },
    downloadCsvData(csv: string, prefixFileName: string) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const downloadLink = document.createElement('a')
      downloadLink.download = `${prefixFileName}-${moment().format(
        'YYYYMMDDTHHmmSS',
      )}.csv`
      downloadLink.href = URL.createObjectURL(
        new Blob([bom, csv], { type: 'text/csv' }),
      )
      downloadLink.dataset.downloadurl = [
        'text/csv',
        downloadLink.download,
        downloadLink.href,
      ].join(':')
      downloadLink.click()
    },
    onClickFilterDropdown() {
      this.isFilterActionDropdownActive = !this.isFilterActionDropdownActive
    },
    onClickFilterOutside() {
      this.isFilterActionDropdownActive = false
    },
    directDispatchEvent() {
      document.dispatchEvent(new Event('click'))
    },
    onDownloadClick() {
      this.isDownloadActionDropdownActive = true
      this.directDispatchEvent()
    },
    onRecordSelect(event: { indicationId: string }) {
      if (this.selectedIndicationIds.includes(event.indicationId)) {
        this.removeSelectedIndicationId(event)
      } else {
        this.addSelectedIndicationId(event)
      }
    },
    async onTabActive(index: number) {
      this.activeTabIndex = index

      if (index === TAB_INDEXES.ETENDER) {
        window.location.href = `${process.env.VUE_APP_ETENDER_URL}/tender`
        return
      }
      await this.$router.push(`/?tabIndex=${index}`)
      this.resetSelectedIndicationIds()
    },
    onClearSelectedIndicationsClick() {
      this.resetSelectedIndicationIds()
    },
    async onNewMessage(event: { indicationId: string }) {
      if (this.isAdmin) {
        return
      }
      const organizationIds = this.getOrganizationIdsOfSelectedIndications([
        event.indicationId,
      ])
      this.addSelectedOrganizationChannelIds(organizationIds)
      await this.initNewMessageModal()
      const message = this.getIndicationMessageOfId(event.indicationId, true)
      if (message) {
        this[SET_MESSAGE]({ message })
      }
      this[SET_LOCKED]({ locked: true })
    },
    async onNewMessageBulk() {
      if (this.isAdmin) {
        return
      }
      const organizationIds = this.getOrganizationIdsOfSelectedIndications(
        this.selectedIndicationIds,
      )
      this.addSelectedOrganizationChannelIds(organizationIds)
      await this.initNewMessageModal()
      const message = this.getIndicationMessageOfIds(
        this.selectedIndicationIds,
        true,
      )
      this[SET_MESSAGE]({ message })
      this[SET_LOCKED]({ locked: true })
    },
    onCopyClipboard(event: { indicationId: string }) {
      const message = this.getIndicationMessageOfId(event.indicationId)
      if (message) {
        navigator.clipboard.writeText(
          message.translationOf(this.userProfile.locale),
        )
        setNotification(this.$t('trading.message.copied').toString())
      }
    },
    onCopyClipboardBulk() {
      const message = this.getIndicationMessageOfIds(this.selectedIndicationIds)
      navigator.clipboard.writeText(
        message.translationOf(this.userProfile.locale),
      )
    },
    getIndicationMessageOfId(
      indicationId: string,
      containsSuffix?: boolean,
    ): MultiLocaleMessageBody | null {
      const indication = this.brokingIndications.find(
        indication => indication.indicationId === indicationId,
      )

      if (!indication) {
        return null
      }

      return new MultiLocaleMessageBody({
        translations: [Locale.Ja, Locale.En].map(locale => {
          return new Translation({
            locale,
            text:
              this.getIndicationMessage(indication, locale) +
              (containsSuffix
                ? this.$t(
                    'trading.message.indicationMessageOfIdSuffix',
                    locale,
                    {
                      reversePosition:
                        indication.position === 'ask'
                          ? this.$t('trading.label.bid', locale)
                          : this.$t('trading.label.ask', locale),
                    },
                  )
                : ''),
          })
        }),
      })
    },
    getIndicationMessageOfIds(
      indicationIds: string[],
      containsSuffix?: boolean,
    ): MultiLocaleMessageBody {
      const indications = this.brokingIndications.filter(indication =>
        indicationIds.includes(indication.indicationId),
      )

      return new MultiLocaleMessageBody({
        translations: [Locale.Ja, Locale.En].map(locale => {
          return new Translation({
            locale,
            text:
              indications
                .map(
                  (indication, index) =>
                    `${this.$t('trading.message.indicationIndex', locale, {
                      index: index + 1,
                    })}\n` + this.getIndicationMessage(indication, locale),
                )
                .join('\n\n') +
              (containsSuffix
                ? this.$t(
                    'trading.message.indicationMessageOfIdsSuffix',
                    locale,
                  )
                : ''),
          })
        }),
      })
    },
    getIndicationMessage(
      indication: BrokingIndication,
      locale: Locale,
    ): string {
      return this.$t('trading.message.indicationMessage', locale, {
        position:
          indication.position === 'ask'
            ? this.$t('trading.label.ask', locale)
            : this.$t('trading.label.bid', locale),
        productType: indication.products
          .map((product: Product) =>
            product.productType.name.translationOf(locale),
          )
          .join(' or '),
        fuelSurchargeType: indication.fuelSurchargeType
          ? `+${indication.fuelSurchargeType.name.translationOf(locale) +
              this.$t('trading.message.fuelSurchargeTypeNameSuffix', locale)}`
          : '',
        area: indication.products[0].area.name.translationOf(locale),
        startDeliveryDate:
          indication.products[0].deliveryUnit === DeliveryUnit.Month
            ? indication.products[0].startDeliveryYearMonth.shortName(locale)
            : indication.products[0].deliveryTerms.displayStartByDate(locale),
        endDeliveryDate:
          indication.products[0].deliveryUnit === DeliveryUnit.Month
            ? indication.products[0].endDeliveryYearMonth.shortName(locale)
            : indication.products[0].deliveryTerms.displayEndByDate(locale),
        hourType:
          indication.products[0].hourType?.name.translationOf(locale) ?? '',
        energyUnitPrice: indication.price.energyUnitPrices[0],
        volume: indication.volume
          ? indication.volume + 'MW'
          : this.$t('trading.label.tbd').toString(),
      }).toString()
    },
    getOrganizationIdsOfSelectedIndications(indicationIds: string[]) {
      const organizationIdsSet = new Set<string>()
      for (const indicationId of indicationIds) {
        const organizationId = this.brokingIndications.find(
          indication => indication.indicationId === indicationId,
        )?.publicOrganizationProfile.organizationId
        if (organizationId) {
          organizationIdsSet.add(organizationId)
        }
      }
      return Array.from(organizationIdsSet)
    },
    onFilterItemInput(event: {
      attribute: BrokingFilterAttribute
      value?: string
    }) {
      this.addFilterConditionInputSelected({
        index: this.activeTabIndex,
        ...event,
      })
    },
    onFilterItemSelect(event: {
      attribute: BrokingFilterAttribute
      value: InputOption
    }) {
      this.addFilterConditionSelected({
        index: this.activeTabIndex,
        ...event,
      })
    },
    onFilterItemRemove(event: {
      attribute: BrokingFilterAttribute
      value: InputOption
    }) {
      this.removeFilterConditionSelected({
        index: this.activeTabIndex,
        ...event,
      })
    },
    onFilterRemove(event: { attribute: BaseFilterAttribute }) {
      this.removeFilterCondition({ index: this.activeTabIndex, ...event })
    },
    onChangeFilters(event: {
      selectedValues: BaseFilterAttribute[]
      changedValue: BaseFilterAttribute
    }) {
      this.changeFilter({
        index: this.activeTabIndex,
        ...event,
      })
    },
    async onRecentDealCsvDownloadClick() {
      const brokingRecentDeals = await this.fetchBrokingRecentDeals()
      this.downloadRecentTradedCsvData(brokingRecentDeals, true)
    },
    async onActivate(indicationId: string) {
      await this.updateStandardIndication({
        id: indicationId,
        standardIndication: {
          status: IndicationStatusTypeEnum.Active,
        },
      })
        .then((indication: Indication) => {
          setNotification(
            this.$t('trading.message.successActivateMyIndication').toString(),
          )
          this[UPDATE_BROKING_INDICATION_BY_ID]({
            indicationId,
            partial: {
              status: IndicationStatusTypeEnum.Active,
              updatedAt: indication.updatedAt,
              lastUpdatedParticipantId: this.users.find(
                user => user.name === indication.lastUpdatedParticipantName,
              )?.userId,
            },
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failActivateMyIndication').toString(),
            'danger',
          )
          throw e
        })
    },
    async onDeactivate(indicationId: string) {
      await this.updateStandardIndication({
        id: indicationId,
        standardIndication: {
          status: IndicationStatusTypeEnum.Inactive,
          memo: 'オフ', // 板画面から無効化するときは、自由記入欄を自動で設定する
        },
      })
        .then(() => {
          setNotification(
            this.$t('trading.message.successDeactivateMyIndication').toString(),
          )
          this[SET_BROKING_INDICATIONS]({
            brokingIndications: this.brokingIndications.filter(
              brokingIndication =>
                brokingIndication.indicationId !== indicationId,
            ),
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failDeactivateMyIndication').toString(),
            'danger',
          )
          throw e
        })
    },
    async onOpenToMarketChange({
      indicationId,
      openToMarket,
    }: {
      indicationId: string
      openToMarket: boolean
    }) {
      this.indicationSubmitting = true
      await this.updateStandardIndication({
        id: indicationId,
        standardIndication: {
          openToMarket,
        },
      })
        .then((indication: Indication) => {
          setNotification(
            this.$t(
              'trading.message.successUpdateOpenToMarketStatus',
            ).toString(),
          )
          this[UPDATE_BROKING_INDICATION_BY_ID]({
            indicationId,
            partial: {
              openToMarket: indication.openToMarket,
              updatedAt: indication.updatedAt,
              lastUpdatedParticipantId: this.users.find(
                user => user.name === indication.lastUpdatedParticipantName,
              )?.userId,
            },
          })
        })
        .catch(e => {
          setNotification(
            this.$t(
              'trading.message.failedUpdateOpenToMarketStatus',
            ).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.indicationSubmitting = false
        })
    },
    async onVisibleToTradersChange({
      indicationId,
      isVisibleToTraders,
    }: {
      indicationId: string
      isVisibleToTraders: boolean
    }) {
      this.indicationSubmitting = true
      await this.updateStandardIndication({
        id: indicationId,
        standardIndication: {
          isVisibleToTraders,
        },
      })
        .then((indication: Indication) => {
          setNotification(
            this.$t(
              'trading.message.successUpdatesVisibleToTraders',
            ).toString(),
          )
          this[UPDATE_BROKING_INDICATION_BY_ID]({
            indicationId,
            partial: {
              isVisibleToTraders: indication.isVisibleToTraders,
              updatedAt: indication.updatedAt,
              lastUpdatedParticipantId: this.users.find(
                user => user.name === indication.lastUpdatedParticipantName,
              )?.userId,
            },
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failedUpdatesVisibleToTraders').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.indicationSubmitting = false
        })
    },
    async onChange({
      indicationId,
      unitPrice,
      volume,
    }: {
      indicationId: string
      unitPrice?: number
      volume?: number | null
    }) {
      const indication = this.brokingIndications.find(
        brokingIndication => brokingIndication.indicationId === indicationId,
      )

      if (indication === undefined) {
        return
      }

      const standardIndication: UpdateStandardIndication = {}
      if (unitPrice !== undefined) {
        standardIndication.price = {
          ...indication.price,
          energyUnitPrices: [unitPrice],
        }
      }
      if (volume !== undefined) {
        standardIndication.base = { volume }
      }
      this.indicationSubmitting = true
      await this.updateStandardIndication({
        id: indicationId,
        standardIndication,
      })
        .then((indication: Indication) => {
          const messageKey =
            standardIndication.price !== undefined
              ? 'trading.message.successUpdateUnitPrice'
              : 'trading.message.successUpdateVolume'
          setNotification(this.$t(messageKey).toString())
          this[UPDATE_BROKING_INDICATION_BY_ID]({
            indicationId,
            partial: {
              ...{
                price: standardIndication.price,
                volume: standardIndication.base?.volume,
              },
              updatedAt: indication.updatedAt,
              lastUpdatedParticipantId: this.users.find(
                user => user.name === indication.lastUpdatedParticipantName,
              )?.userId,
            },
          })
        })
        .catch(e => {
          const messageKey =
            standardIndication.price !== undefined
              ? 'trading.message.failedUpdateUnitPrice'
              : 'trading.message.failedUpdateVolume'
          setNotification(this.$t(messageKey).toString(), 'danger')
          throw e
        })
        .finally(() => {
          this.indicationSubmitting = false
        })
    },
    onTargeting(indicationId?: string) {
      this.targetIndicationId = indicationId
    },
    async onInBulkActivate() {
      this.isStatusSubmitting = true
      await this.updateStandardIndicationInBulk(
        this.selectedIndicationIds.map(id => ({
          id,
          status: IndicationStatusTypeEnum.Active,
        })),
      )
        .then((updatedIndicationInBulk: UpdatedIndicationInBulk) => {
          if (updatedIndicationInBulk.failedIndications.length === 0) {
            setNotification(
              this.$t(
                'trading.message.successActivateMyIndicationInBulk',
              ).toString(),
            )
          } else {
            setNotification(
              this.$t('trading.message.failActivateMyIndicationInBulk', {
                denominator: this.selectedIndicationIds.length,
                numerator: updatedIndicationInBulk.failedIndications.length,
              }).toString(),
              'danger',
            )
          }

          updatedIndicationInBulk.successfulIndications.forEach(indication => {
            this[UPDATE_BROKING_INDICATION_BY_ID]({
              indicationId: indication.id,
              partial: {
                status: indication.status,
                updatedAt: indication.updatedAt,
                lastUpdatedParticipantId: this.users.find(
                  user => user.name === indication.lastUpdatedParticipantName,
                )?.userId,
              },
            })
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failActivateMyIndicationInBulk', {
              denominator: this.selectedIndicationIds.length,
              numerator: this.selectedIndicationIds.length,
            }).toString(),
            'danger',
          )
          throw e
        })
      this.isStatusSubmitting = false
    },
    async onInBulkDeactivate() {
      this.isStatusSubmitting = true
      await this.updateStandardIndicationInBulk(
        this.selectedIndicationIds.map(id => ({
          id,
          status: IndicationStatusTypeEnum.Inactive,
        })),
      )
        .then((updatedIndicationInBulk: UpdatedIndicationInBulk) => {
          if (updatedIndicationInBulk.failedIndications.length === 0) {
            setNotification(
              this.$t(
                'trading.message.successDeActivateMyIndicationInBulk',
              ).toString(),
            )
          } else {
            setNotification(
              this.$t('trading.message.failDeActivateMyIndicationInBulk', {
                denominator: this.selectedIndicationIds.length,
                numerator: updatedIndicationInBulk.failedIndications.length,
              }).toString(),
              'danger',
            )
          }

          updatedIndicationInBulk.successfulIndications.forEach(indication => {
            this[SET_BROKING_INDICATIONS]({
              brokingIndications: this.brokingIndications.filter(
                brokingIndication =>
                  brokingIndication.indicationId !== indication.id,
              ),
            })
            this[SET_SELECTED_INDICATION_IDS]({
              indicationIds: this.selectedIndicationIds.filter(
                id => id !== indication.id,
              ),
            })
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failDeActivateMyIndicationInBulk', {
              denominator: this.selectedIndicationIds.length,
              numerator: this.selectedIndicationIds.length,
            }).toString(),
            'danger',
          )
          throw e
        })
      this.isStatusSubmitting = false
    },
    async onOpenToMarketInBulkChange(openToMarket: boolean) {
      this.isActionDropdownOfOpenToMarketInBulkActive = false
      this.isOpenToMarketSubmitting = true
      await this.updateStandardIndicationInBulk(
        this.selectedIndicationIds.map(id => ({
          id,
          openToMarket,
        })),
      )
        .then((updatedIndicationInBulk: UpdatedIndicationInBulk) => {
          if (updatedIndicationInBulk.failedIndications.length === 0) {
            setNotification(
              this.$t(
                'trading.message.successUpdateOpenToMarketStatusInBulk',
              ).toString(),
            )
          } else {
            setNotification(
              this.$t('trading.message.failUpdateOpenToMarketStatusInBulk', {
                denominator: this.selectedIndicationIds.length,
                numerator: updatedIndicationInBulk.failedIndications.length,
              }).toString(),
              'danger',
            )
          }

          updatedIndicationInBulk.successfulIndications.forEach(indication => {
            this[UPDATE_BROKING_INDICATION_BY_ID]({
              indicationId: indication.id,
              partial: {
                openToMarket: indication.openToMarket,
                updatedAt: indication.updatedAt,
                lastUpdatedParticipantId: this.users.find(
                  user => user.name === indication.lastUpdatedParticipantName,
                )?.userId,
              },
            })
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failUpdateOpenToMarketStatusInBulk', {
              denominator: this.selectedIndicationIds.length,
              numerator: this.selectedIndicationIds.length,
            }).toString(),
            'danger',
          )
          throw e
        })
      this.isOpenToMarketSubmitting = false
    },
    async onVisibleToTradersInBulkChange(isVisibleToTraders: boolean) {
      this.isActionDropdownOfVisibleToTradersInBulkActive = false
      this.isVisibleToTradersSubmitting = true
      await this.updateStandardIndicationInBulk(
        this.selectedIndicationIds.map(id => ({
          id,
          isVisibleToTraders,
        })),
      )
        .then((updatedIndicationInBulk: UpdatedIndicationInBulk) => {
          if (updatedIndicationInBulk.failedIndications.length === 0) {
            setNotification(
              this.$t(
                'trading.message.successUpdatesVisibleToTradersInBulk',
              ).toString(),
            )
          } else {
            setNotification(
              this.$t('trading.message.failUpdatesVisibleToTradersInBulk', {
                denominator: this.selectedIndicationIds.length,
                numerator: updatedIndicationInBulk.failedIndications.length,
              }).toString(),
              'danger',
            )
          }

          updatedIndicationInBulk.successfulIndications.forEach(indication => {
            this[UPDATE_BROKING_INDICATION_BY_ID]({
              indicationId: indication.id,
              partial: {
                isVisibleToTraders: indication.isVisibleToTraders,
                updatedAt: indication.updatedAt,
                lastUpdatedParticipantId: this.users.find(
                  user => user.name === indication.lastUpdatedParticipantName,
                )?.userId,
              },
            })
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failUpdatesVisibleToTradersInBulk', {
              denominator: this.selectedIndicationIds.length,
              numerator: this.selectedIndicationIds.length,
            }).toString(),
            'danger',
          )
          throw e
        })
      this.isVisibleToTradersSubmitting = false
    },
    onOpenToMarketInBulkClick() {
      if (this.isOpenToMarketSubmitting) {
        return
      }
      this.isActionDropdownOfOpenToMarketInBulkActive = true
      this.directDispatchEvent()
    },
    onVisibleToTradersInBulkClick() {
      if (this.isVisibleToTradersSubmitting) {
        return
      }
      this.isActionDropdownOfVisibleToTradersInBulkActive = true
      this.directDispatchEvent()
    },
    onIndicationUpdate(indicationId: string) {
      const indication = this.brokingIndications.find(
        indication => indication.indicationId === indicationId,
      )

      if (indication === undefined) {
        return
      }

      this.updateStandardIndication({
        id: indicationId,
        standardIndication: {
          price: indication.price,
          base: {
            volume: indication.volume,
          },
        },
      })
        .then((indication: Indication) => {
          setNotification(
            this.$t('trading.message.successUpdateIndication').toString(),
          )
          this[UPDATE_BROKING_INDICATION_BY_ID]({
            indicationId: indication.id,
            partial: {
              updatedAt: indication.updatedAt,
              lastUpdatedParticipantId: this.users.find(
                user => user.name === indication.lastUpdatedParticipantName,
              )?.userId,
            },
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failUpdateIndication').toString(),
            'danger',
          )
          throw e
        })
    },
    onSelectAllIndicationsClick() {
      switch (this.activeTabIndex) {
        case TAB_INDEXES.NO_FUEL_SURCHARGE:
          this.filteredBrokingIndications
            .filter(
              indication =>
                !this.selectedIndications.some(
                  selectedIndication =>
                    selectedIndication.indicationId === indication.indicationId,
                ),
            )
            .forEach(({ indicationId }) => {
              this.addSelectedIndicationId({ indicationId })
            })
          break
        case TAB_INDEXES.WITH_FUEL_SURCHARGE:
          this.filteredBrokingIndicationsWithFuelSurcharge
            .filter(
              indication =>
                !this.selectedIndications.some(
                  selectedIndication =>
                    selectedIndication.indicationId === indication.indicationId,
                ),
            )
            .forEach(({ indicationId }) => {
              this.addSelectedIndicationId({ indicationId })
            })
          break
        case TAB_INDEXES.SPREAD:
          this.filteredBrokingIndicationsWithSpread
            .filter(
              indication =>
                !this.selectedIndications.some(
                  selectedIndication =>
                    selectedIndication.indicationId === indication.indicationId,
                ),
            )
            .forEach(({ indicationId }) => {
              this.addSelectedIndicationId({ indicationId })
            })
          break
      }
    },
    async initNewMessageModal() {
      this.$vfm.open(this.newMessageModalName)
      this.loadOrganizationChannels()
      this[SET_RECIPIENT_SELECTED_LANGUAGE]({
        recipientSelectedLanguage: undefined,
      })
    },
    onCancelButtonClick() {
      this.$vfm.close(this.newMessageModalName)
    },
    onSelectedListChange(event: string[]) {
      this[SET_SELECTED_ORGANIZATION_CHANNEL_IDS]({
        selectedOrganizationChannelIds: event,
      })
    },
    onRecipientSearchInput(event: string) {
      this[SET_RECIPIENT_SEARCH_INPUT]({
        recipientSearchInput: event,
      })
    },
    onRecipientSelectedLanguage(event: undefined | InputOption) {
      this[SET_RECIPIENT_SELECTED_LANGUAGE]({
        recipientSelectedLanguage: event,
      })
    },
    onLockClick() {
      if (this.message instanceof MultiLocaleMessageBody) {
        this[SET_LOCKED]({
          locked: !this.locked,
        })
      }
    },
    onNewChatInput(event: string | MultiLocaleMessageBody) {
      this[SET_MESSAGE]({
        message: event,
      })
    },
    onSendNewMessage() {
      this.sendNewMessage()
      this.$vfm.close(this.newMessageModalName)
    },
    onSendMultiLocaleNewMessage() {
      this.sendMultiLocaleNewMessage(this.publicOrganizationProfiles)
      this.$vfm.close(this.newMessageModalName)
    },
    resetChatInput() {
      this[SET_MESSAGE]({
        message: '',
      })
    },
    onCopyIndication(event: { indicationId: string }) {
      this.copyFromIndicationId = event.indicationId
      this.$vfm.open(this.newIndicationModalName)
    },
    async onCopyIndicationConfirm() {
      this.$vfm.close(this.newIndicationModalName)
      await this.fetchBrokingData()
    },
    onCopyIndicationCancel() {
      this.$vfm.close(this.newIndicationModalName)
    },
    async setupStoreState() {
      await Promise.all([
        this.fetchAreas(),
        this.fetchHourTypes(),
        this.fetchProductTypes(),
      ])
    },
  },
})
