import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-stack-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "loading", { key: 0 })
      : (_ctx.isBlank)
        ? _renderSlot(_ctx.$slots, "blank", { key: 1 })
        : (_ctx.isPartial)
          ? _renderSlot(_ctx.$slots, "partial", { key: 2 })
          : (_ctx.isError)
            ? _renderSlot(_ctx.$slots, "error", { key: 3 })
            : (_ctx.isIdeal)
              ? _renderSlot(_ctx.$slots, "ideal", { key: 4 })
              : _createCommentVNode("", true)
  ]))
}