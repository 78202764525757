import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1688754c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clearing-information" }
const _hoisted_2 = { class: "clearing-information__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_broking_eex_clearing_information_form = _resolveComponent("broking-eex-clearing-information-form")!
  const _component_eex_clearing_information_form = _resolveComponent("eex-clearing-information-form")!
  const _component_tocom_clearing_information_form = _resolveComponent("tocom-clearing-information-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeClearingInformation')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            (_ctx.isBrokerApp())
              ? (_openBlock(), _createBlock(_component_broking_eex_clearing_information_form, {
                  key: 0,
                  "eex-trading-firms": _ctx.eexClearingInformation.tradingFirms,
                  "eex-clearing-accounts": _ctx.eexClearingInformation.clearingAccounts,
                  "selected-eex-trading-firm": 
                _ctx.eexClearingInformation.selectedTradingFirm
              ,
                  "on-update": _ctx.onUpdateEexClearingInformation
                }, null, 8, ["eex-trading-firms", "eex-clearing-accounts", "selected-eex-trading-firm", "on-update"]))
              : (_openBlock(), _createBlock(_component_eex_clearing_information_form, {
                  key: 1,
                  "eex-clearing-accounts": _ctx.eexClearingInformation.clearingAccounts,
                  "selected-eex-trading-firm": 
                _ctx.eexClearingInformation.selectedTradingFirm
              
                }, null, 8, ["eex-clearing-accounts", "selected-eex-trading-firm"]))
          ]),
          _createVNode(_component_tocom_clearing_information_form, {
            "tocom-brokers": _ctx.tocomClearingInformation.brokers,
            "tocom-clearing-informations": 
              _ctx.tocomClearingInformation.clearingInformation
            ,
            "tocom-participant-summary": 
              _ctx.tocomClearingInformation.participantSummary
            ,
            "on-update": _ctx.onUpdateTocomClearingInformation
          }, null, 8, ["tocom-brokers", "tocom-clearing-informations", "tocom-participant-summary", "on-update"])
        ])
      ]),
      _: 1
    }, 8, ["ui-stack"])
  ]))
}