
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import PostingListItem from '@/components/trading/EEXPostingListItem.vue'
import { EEXPosting } from '@/models/trading/EEXPosting'

export default defineComponent({
  name: 'EEXPostingList',
  components: {
    PostingListItem,
  },
  props: {
    eexPostings: {
      type: Array as PropType<EEXPosting[]>,
      default: () => [],
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  data() {
    return {
      openActionDropdownId: undefined as string | undefined,
    }
  },
  emits: ['on-repost-click', 'last-item-visible'],
  methods: {
    visibilityChanged(isVisible: boolean) {
      if (isVisible) {
        this.$emit('last-item-visible')
      }
    },
    onRepostClick(id: string) {
      this.$emit('on-repost-click', id)
    },
    onDropdownClick(id: string) {
      this.openActionDropdownId = id
    },
    onDropdownOutsideClick() {
      this.openActionDropdownId = undefined
    },
  },
})
