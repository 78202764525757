
import moment from 'moment'
import { defineComponent } from 'vue'

import Heading from '@/components/common/Typography/Heading.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'

export default defineComponent({
  name: 'MessageHeader',
  components: {
    Heading,
    Sentences,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    dateTime: {
      type: Date,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
  },
  computed: {
    styleVars(): object {
      return {
        '--font-size-name': this.size === 'regular' ? '14px' : '12px',
        '--font-size-datetime': this.size === 'regular' ? '12px' : '10px',
      }
    },
  },
  methods: {
    formatDateTime(dateTime: Date): string {
      const today = new Date()
      if (
        dateTime.getFullYear() === today.getFullYear() &&
        dateTime.getMonth() === today.getMonth() &&
        dateTime.getDate() === today.getDate()
      ) {
        return moment(this.dateTime).format('HH:mm')
      }
      return moment(this.dateTime).format(
        `${this.$t('common.format.formatDate')} HH:mm`,
      )
    },
  },
})
