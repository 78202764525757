
import { defineComponent } from 'vue'

import HorizontalTab from '@/components/common/HorizontalTab.vue'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'

const RECAP_CONFIGURATION_TAB_INDEXES = {
  RECAP_RECEIVERS: 0,
  OCCTO_MASTER: 1,
  CLEARING_INFORMATION: 2,
}

export default defineComponent({
  name: 'RecapConfiguration',
  components: {
    HorizontalTab,
  },
  props: {
    basePath: {
      type: String,
      default: '/recap-configuration',
    },
  },
  computed: {
    tabList(): HorizontalTabItem[] {
      return [
        {
          label: this.$t('iam.label.recapReceivers').toString(),
          to: `${this.basePath}/recap-receivers`,
        },
        {
          label: this.$t('iam.label.occtoMasters').toString(),
          to: `${this.basePath}/occto-master`,
        },
        {
          label: this.$t('iam.label.clearingInformation').toString(),
          to: `${this.basePath}/clearing-information`,
        },
      ]
    },
    currentTabIndex(): number {
      switch (this.$route.name) {
        case 'OcctoMaster':
        case 'MyOcctoMaster':
          return RECAP_CONFIGURATION_TAB_INDEXES.OCCTO_MASTER
        case 'ClearingInformation':
        case 'MyClearingInformation':
          return RECAP_CONFIGURATION_TAB_INDEXES.CLEARING_INFORMATION
        case 'RecapReceivers':
        case 'MyRecapReceivers':
        default:
          return RECAP_CONFIGURATION_TAB_INDEXES.RECAP_RECEIVERS
      }
    },
    isChild(): boolean {
      return this.$route.meta?.parentName === 'RecapConfiguration'
    },
  },
})
