
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { OrganizationType } from '@/api/generated'
import BaseModal from '@/components/common/BaseModal.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import HorizontalTab from '@/components/common/HorizontalTab.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'
import { InputOption } from '@/components/common/interface/InputOption'
import MyDealList from '@/components/trading/MyDealList.vue'
import MyIndicationList from '@/components/trading/MyIndicationList.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import MyIndicationDetail from '@/components/trading/container/MyIndicationDetail.vue'
import { DayPattern } from '@/models/trading/DayPattern'
import { DeliveryUnitOptions } from '@/models/trading/DeliveryUnitOptions'
import { MyIndication } from '@/models/trading/MyIndication'
import { MemberModule } from '@/store/modules/iam/member'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { MyDealModule } from '@/store/modules/trading/myDeal'
import {
  SET_FILTER_CONDITION_OF_MY_DEAL,
  SET_SELECTED_FILTERS_OF_MY_DEAL,
} from '@/store/modules/trading/myDeal/MyDealMutations'
import { MyIndicationModule } from '@/store/modules/trading/myIndication'
import {
  SET_INDICATION_ID_TO_DELETE,
  SET_FILTER_CONDITION,
  SET_SELECTED_FILTERS,
} from '@/store/modules/trading/myIndication/MyIndicationMutations'
import { MyIndicationFilterAttribute } from '@/store/modules/trading/myIndication/interface'
import { ProductModule } from '@/store/modules/trading/product'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'
import { setNotification } from '@/utils/utils'

const keyOfSelectedFiltersOfMyIndication = 'selectedFiltersOfMyIndication'
const keyOfSelectedFiltersOfMyDeal = 'selectedFiltersOfMyDeal'

const historyTab = {
  indication: 0,
  deal: 1,
} as const

type HistoryTabType = keyof typeof historyTab

type StateType = {
  dayPatternOptions: InputOption[]
  activeHistoryType: HistoryTabType
  myIndication: MyIndication | undefined
  isActionDropdownActive: boolean
  myIndicationDetailModalName: string
}

const {
  mapState: myIndicationMapState,
  mapGetters: myIndicationMapGetters,
  mapMutations: myIndicationMapMutations,
  mapActions: myIndicationMapActions,
} = createNamespacedHelpers('myIndication') as MyIndicationModule
const {
  mapState: myDealMapState,
  mapGetters: myDealMapGetters,
  mapMutations: myDealMapMutations,
  mapActions: myDealMapActions,
} = createNamespacedHelpers('myDeal') as MyDealModule
const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const {
  mapState: memberMapState,
  mapActions: memberMapActions,
} = createNamespacedHelpers('member') as MemberModule
const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'History',
  components: {
    BaseModal,
    CenteredLoadingContent,
    ConfirmationDialog,
    HorizontalTab,
    MyDealList,
    MyIndicationDetail,
    MyIndicationList,
    ProductFilter,
    TraderPage,
  },
  data(): StateType {
    return {
      dayPatternOptions: [],
      activeHistoryType: 'indication',
      myIndication: undefined as MyIndication | undefined,
      isActionDropdownActive: false,
      myIndicationDetailModalName: 'my-indication-detail',
    }
  },
  computed: {
    ...productMapState([
      'productTypes',
      'areas',
      'startDeliveryYearMonths',
      'endDeliveryYearMonths',
      'hourTypes',
      'fuelSurchargeTypes',
    ]),
    ...myIndicationMapState([
      'myIndications',
      'deleteSubmitting',
      'isFetchingMyIndications',
      'filterAttributes',
      'selectedFilters',
    ]),
    ...myIndicationMapGetters(['filteredMyIndications', 'productFilter']),
    ...myDealMapState([
      'myDeals',
      'isFetchingMyDeals',
      'filterAttributesOfMyDeal',
      'selectedFiltersOfMyDeal',
    ]),
    ...myDealMapGetters(['filteredMyDeals', 'productFilterOfMyDeal']),
    ...memberMapState(['memberProfiles']),
    ...userProfileMapGetters(['userProfile']),
    tabList(): HorizontalTabItem[] {
      return [
        this.$t('trading.label.myIndication').toString(),
        this.$t('trading.label.myDeal').toString(),
      ]
    },
    isIndicationTabActive(): boolean {
      return this.activeHistoryType === 'indication'
    },
    isDealTabActive(): boolean {
      return this.activeHistoryType === 'deal'
    },
    isEclearEnabled(): boolean {
      return (
        !!this.userProfile.services.eclear?.appSetting.enabled &&
        !!this.userProfile.services.eclear?.appSetting.isTrader
      )
    },
    indicationActions(): ActionDropdownItem[] {
      const actions = (this.filterAttributes as BaseFilterAttribute[]).map(
        attribute => ({
          label: this.$t('trading.label.' + attribute),
          eventName: attribute,
        }),
      ) as ActionDropdownItem[]
      return actions
    },
    dealActions(): ActionDropdownItem[] {
      const actions = (this
        .filterAttributesOfMyDeal as BaseFilterAttribute[]).map(attribute => ({
        label: this.$t('trading.label.' + attribute),
        eventName: attribute,
      })) as ActionDropdownItem[]
      return actions
    },
    deliveryUnitOptions(): InputOption[] {
      return new DeliveryUnitOptions().getOptions(OrganizationType.Trader)
    },
  },
  async created() {
    await Promise.all([
      this.fetchDayPatterns() as Promise<DayPattern[]>,
      this.fetchProductTypes(),
      this.fetchAreas(),
      this.fetchHourTypes(),
      this.fetchDeliveryYearMonths(),
      this.fetchFuelSurchargeTypes(),
      this.fetchMyIndications(),
      this.fetchMyDeals(),
      this.loadMemberProfiles(this.userProfile.organizationId),
    ]).then(([dayPatterns]) => {
      this.dayPatternOptions = dayPatterns.map(d =>
        d.toInputOption(this.userProfile.locale),
      )
    })
  },
  mounted() {
    if (localStorage.getItem(keyOfSelectedFiltersOfMyIndication)) {
      const selectedFiltersFromLocal = JSON.parse(
        localStorage.getItem(keyOfSelectedFiltersOfMyIndication) as string,
      )
      this[SET_SELECTED_FILTERS]({ selectedFilters: selectedFiltersFromLocal })
    }
    if (localStorage.getItem(keyOfSelectedFiltersOfMyDeal)) {
      const selectedFiltersFromLocal: (
        | BaseFilterAttribute
        | string
      )[] = JSON.parse(
        localStorage.getItem(keyOfSelectedFiltersOfMyDeal) as string,
      )

      // TODO: 不正なKEYが紛れてしまった問題の対応。しばらくしたらこの処理を消す
      // 参照: https://www.notion.so/enechain/2b3b4531d95a49c083054ab313227929
      const statusKey = 'status'
      const statusFilteredSelectedFiltersFromLocal: BaseFilterAttribute[] = selectedFiltersFromLocal.filter(
        (item): item is BaseFilterAttribute => item !== statusKey,
      )
      if (selectedFiltersFromLocal.includes(statusKey)) {
        localStorage.setItem(
          keyOfSelectedFiltersOfMyDeal,
          JSON.stringify(statusFilteredSelectedFiltersFromLocal),
        )
      }
      this[SET_SELECTED_FILTERS_OF_MY_DEAL]({
        selectedFilters: statusFilteredSelectedFiltersFromLocal,
      })
    }
  },
  methods: {
    ...myIndicationMapMutations([
      SET_INDICATION_ID_TO_DELETE,
      SET_FILTER_CONDITION,
      SET_SELECTED_FILTERS,
    ]),
    ...myIndicationMapActions([
      'fetchMyIndications',
      'activate',
      'deactivate',
      'delete',
      'addFilterConditionSelected',
      'removeFilterConditionSelected',
    ]),
    ...myDealMapMutations([
      SET_FILTER_CONDITION_OF_MY_DEAL,
      SET_SELECTED_FILTERS_OF_MY_DEAL,
    ]),
    ...myDealMapActions([
      'fetchMyDeals',
      'addFilterConditionSelectedOfMyDeal',
      'removeFilterConditionSelectedOfMyDeal',
    ]),
    ...productMapActions([
      'fetchProductTypes',
      'fetchAreas',
      'fetchHourTypes',
      'fetchDeliveryYearMonths',
      'fetchDayPatterns',
      'fetchFuelSurchargeTypes',
      'fetchProductTypeDateName',
    ]),
    ...memberMapActions(['loadMemberProfiles']),
    // Tab
    onTabActive(index: number) {
      switch (index) {
        case historyTab.indication:
          this.activeHistoryType = 'indication'
          break
        case historyTab.deal:
          this.activeHistoryType = 'deal'
          break
        default:
          throw new Error('Selected tab does not match tab data')
      }
    },
    // Product filter
    onFilterItemSelect(event: {
      attribute: MyIndicationFilterAttribute | BaseFilterAttribute
      value: InputOption
    }) {
      if (this.isIndicationTabActive) {
        this.addFilterConditionSelected(event)
      } else {
        this.addFilterConditionSelectedOfMyDeal(
          event as { attribute: BaseFilterAttribute; value: InputOption },
        )
      }
    },
    onFilterItemRemove(event: {
      attribute: MyIndicationFilterAttribute | BaseFilterAttribute
      value: InputOption
    }) {
      if (this.isIndicationTabActive) {
        this.removeFilterConditionSelected(event)
      } else {
        this.removeFilterConditionSelectedOfMyDeal(
          event as { attribute: BaseFilterAttribute; value: InputOption },
        )
      }
    },
    onFilterRemove(event: {
      attribute: MyIndicationFilterAttribute | BaseFilterAttribute
    }) {
      if (this.isIndicationTabActive) {
        const selectedFilters = (this
          .selectedFilters as BaseFilterAttribute[]).filter(
          attribute => attribute !== event.attribute,
        )
        this[SET_FILTER_CONDITION]({ attribute: event.attribute, selected: [] })
        this[SET_SELECTED_FILTERS]({ selectedFilters })
        localStorage.setItem(
          keyOfSelectedFiltersOfMyIndication,
          JSON.stringify(this.selectedFilters),
        )
      } else {
        const selectedFilters = (this
          .selectedFiltersOfMyDeal as BaseFilterAttribute[]).filter(
          attribute => attribute !== event.attribute,
        )
        this[SET_FILTER_CONDITION_OF_MY_DEAL]({
          attribute: event.attribute as BaseFilterAttribute,
          selected: [],
        })
        this[SET_SELECTED_FILTERS_OF_MY_DEAL]({ selectedFilters })
        localStorage.setItem(
          keyOfSelectedFiltersOfMyDeal,
          JSON.stringify(this.selectedFiltersOfMyDeal),
        )
      }
    },
    onClickDropdown() {
      this.isActionDropdownActive = !this.isActionDropdownActive
    },
    onClickOutside() {
      this.isActionDropdownActive = false
    },
    onChangeFilters(event: {
      selectedValues: BaseFilterAttribute[]
      changedValue: BaseFilterAttribute
    }) {
      if (this.isIndicationTabActive) {
        this[SET_SELECTED_FILTERS]({ selectedFilters: event.selectedValues })
        if (!event.selectedValues.includes(event.changedValue)) {
          this[SET_FILTER_CONDITION]({
            attribute: event.changedValue,
            selected: [],
          })
        }
        localStorage.setItem(
          keyOfSelectedFiltersOfMyIndication,
          JSON.stringify(this.selectedFilters),
        )
      } else {
        this[SET_SELECTED_FILTERS_OF_MY_DEAL]({
          selectedFilters: event.selectedValues,
        })
        if (!event.selectedValues.includes(event.changedValue)) {
          this[SET_FILTER_CONDITION_OF_MY_DEAL]({
            attribute: event.changedValue,
            selected: [],
          })
        }
        localStorage.setItem(
          keyOfSelectedFiltersOfMyDeal,
          JSON.stringify(this.selectedFiltersOfMyDeal),
        )
      }
    },
    onCancel() {
      this.$vfm.close(this.myIndicationDetailModalName)
    },
    // myIndication
    onSelect(event: { indicationId: string }) {
      this.myIndication = this.myIndications.find(
        (indication: MyIndication) =>
          indication.indicationId === event.indicationId,
      )
      this.$vfm.open(this.myIndicationDetailModalName)
    },
    async onActivate(event: { indicationId: string }) {
      await this.activate({
        indicationId: event.indicationId,
      })
        .then(() => {
          setNotification(
            this.$t('trading.message.successActivateMyIndication').toString(),
          )
        })
        .catch(() => {
          setNotification(
            this.$t('trading.message.failActivateMyIndication').toString(),
            'danger',
          )
        })
      await this.fetchMyIndications()
    },
    async onDeactivate(event: { indicationId: string }) {
      await this.deactivate({
        indicationId: event.indicationId,
      })
        .then(() => {
          setNotification(
            this.$t('trading.message.successDeactivateMyIndication').toString(),
          )
        })
        .catch(() => {
          setNotification(
            this.$t('trading.message.failDeactivateMyIndication').toString(),
            'danger',
          )
        })
      await this.fetchMyIndications()
    },
    async onDelete(event: { indicationId: string }) {
      this[SET_INDICATION_ID_TO_DELETE]({ indicationId: event.indicationId })
      this.$vfm.open('deleteMyIndication')
    },
    async onDeleteConfirm() {
      await this.delete()
        .then(() => {
          setNotification(
            this.$t('trading.message.successDeleteMyIndication').toString(),
          )
        })
        .catch(() => {
          setNotification(
            this.$t('trading.message.failDeleteMyIndication').toString(),
            'danger',
          )
        })
      await this.fetchMyIndications()
      this.$vfm.close('deleteMyIndication')
    },
    onDeleteCancel() {
      this[SET_INDICATION_ID_TO_DELETE]({ indicationId: undefined })
      this.$vfm.close('deleteMyIndication')
    },
  },
})
