
import { defineComponent } from 'vue'

import BasePicture from '@/components/common/BasePicture.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'

export default defineComponent({
  name: 'OrganizationInfo',
  components: {
    BasePicture,
    BaseTooltip,
  },
  props: {
    picture: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
