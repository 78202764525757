import { DeliveryUnit } from '@/api/generated'
import { BrokingDeal } from '@/models/trading/BrokingDeal'
import { BrokingIndication } from '@/models/trading/BrokingIndication'
import { BrokingIndicationOfProduct } from '@/models/trading/BrokingIndicationOfProduct'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'
import { PublicIndicationOfProduct } from '@/models/trading/PublicIndicationOfProduct'

type BrokingProps = {
  indications: BrokingIndication[]
  deals: BrokingDeal[]
  productTypeFilterCondition: string[]
}
type PublicProps = {
  indications: PublicIndication[]
  deals: PublicDeal[]
  productTypeFilterCondition: string[]
}

export function generateMapsByDeliveryTerms<
  PropsType extends BrokingProps | PublicProps
>({
  indications,
  deals,
  productTypeFilterCondition,
}: PropsType): {
  indicationMap: Map<
    string,
    (PropsType extends BrokingProps
      ? BrokingIndicationOfProduct
      : PublicIndicationOfProduct)[]
  >
  deliveryTermsMap: Map<
    string,
    {
      deliveryTerms: DeliveryTerms
      deliveryUnit: DeliveryUnit
    }
  >
} {
  type IndicationOfProductType = PropsType extends BrokingProps
    ? BrokingIndicationOfProduct
    : PublicIndicationOfProduct

  const deliveryTermsMap = new Map<
    string,
    {
      deliveryTerms: DeliveryTerms
      deliveryUnit: DeliveryUnit
    }
  >()

  // 注文を受給条件ごとに集約する
  const indicationMap = new Map<string, IndicationOfProductType[]>()
  for (const indication of indications) {
    for (const product of indication.products) {
      if (
        productTypeFilterCondition.length > 0 &&
        !productTypeFilterCondition.includes(product.productType.productTypeId)
      ) {
        continue
      }

      const key = generateId(
        product.deliveryTermsId,
        indication.fuelSurchargeType?.id,
      )
      if (!deliveryTermsMap.has(key)) {
        deliveryTermsMap.set(key, {
          deliveryTerms: product.deliveryTerms,
          deliveryUnit: product.deliveryUnit,
        })
      }

      const indicationOfProduct = indication.toIndicationOfProduct(
        product,
      ) as IndicationOfProductType
      if (!indicationMap.has(key)) {
        indicationMap.set(key, [indicationOfProduct])
      } else {
        const indicationsOfProduct = indicationMap.get(key)
        if (indicationsOfProduct) {
          indicationMap.set(key, [...indicationsOfProduct, indicationOfProduct])
        }
      }
    }
  }

  for (const deal of deals) {
    const key = generateId(
      deal.product.deliveryTermsId,
      deal.fuelSurchargeType?.id,
    )
    if (!deliveryTermsMap.has(key)) {
      deliveryTermsMap.set(key, {
        deliveryTerms: deal.product.deliveryTerms,
        deliveryUnit: deal.product.deliveryUnit,
      })
    }
  }
  return { deliveryTermsMap, indicationMap }
}

export function generateId(
  deliveryTermsId: string,
  fuelSurchargeTypeId: string | undefined,
): string {
  return deliveryTermsId + ':' + (fuelSurchargeTypeId || '')
}
