
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { ESquareAppSetting } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import MemberInformation from '@/components/iam/MemberInformation.vue'
import ReceiveEmailForm from '@/components/iam/ReceiveEmailForm.vue'
import { ReceiveEmailFormProps } from '@/components/iam/interface/ReceiveEmailFormProps'
import ParticipantSummary from '@/components/trading/ParticipantSummary.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MemberModule } from '@/store/modules/iam/member'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const { mapActions: memberMapActions } = createNamespacedHelpers(
  'member',
) as MemberModule

export default defineComponent({
  name: 'Member',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    CenteredLoadingContent,
    MemberInformation,
    ParticipantSummary,
    ReceiveEmailForm,
    UiStackSelector,
  },
  data() {
    return {
      isLoading: false,
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      memberProfile: undefined as MemberProfile | undefined,
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    memberAppSetting(): ESquareAppSetting {
      const appSetting = this.memberProfile?.services?.esquare?.appSetting
      return {
        enabled: appSetting?.enabled || false,
        receiveIndicationEmail: appSetting?.receiveIndicationEmail || false,
        receiveLiveMarketEmail: appSetting?.receiveLiveMarketEmail || false,
        receiveInvoiceEmail: appSetting?.receiveInvoiceEmail || false,
        receiveSpotTradingRecapEmail:
          appSetting?.receiveSpotTradingRecapEmail || false,
        receiveDerivativeTradingRecapEmail:
          appSetting?.receiveDerivativeTradingRecapEmail || false,
        receiveReportEmail: appSetting?.receiveReportEmail || false,
        receiveDailyDealSummaryEmail:
          appSetting?.receiveDailyDealSummaryEmail || false,
      }
    },
    emailFormValue(): ReceiveEmailFormProps {
      return {
        receiveIndicationEmail: this.memberAppSetting.receiveIndicationEmail,
        receiveLiveMarketEmail: this.memberAppSetting.receiveLiveMarketEmail,
        receiveSpotTradingRecapEmail: this.memberAppSetting
          .receiveSpotTradingRecapEmail,
        receiveDerivativeTradingRecapEmail: this.memberAppSetting
          .receiveDerivativeTradingRecapEmail,
        receiveReportEmail: this.memberAppSetting.receiveReportEmail,
        receiveInvoiceEmail: this.memberAppSetting.receiveInvoiceEmail,
      }
    },
  },
  async created() {
    await this.fetchMemberProfile({
      organizationId: this._organizationId,
      userId: this.$attrs.userId,
    })
      .then(memberProfile => {
        this.memberProfile = memberProfile
        this.uiStack = UiStack.Ideal
      })
      .catch(_ => {
        this.uiStack = UiStack.Error
      })
  },
  methods: {
    fetchMemberProfile: memberMapActions(['fetchMemberProfile'])
      .fetchMemberProfile as (payload: {
      organizationId: string
      userId: string
    }) => Promise<MemberProfile>,
    ...memberMapActions(['updateAppSetting']),
    onInput(value: ReceiveEmailFormProps) {
      if (this.memberProfile?.services?.esquare) {
        Object.assign(this.memberProfile.services.esquare.appSetting, value)
      }
    },
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    async onClick() {
      this.isLoading = true
      await this.updateAppSetting({
        organizationId: this._organizationId,
        userId: this.$attrs.userId,
        appSetting: this.memberAppSetting,
      })
        .then(() => {
          setNotification(
            this.$t('iam.message.successUpdateMailSettings').toString(),
          )
          this.backListPage()
        })
        .catch(_ => {
          setNotification(
            this.$t('iam.message.failUpdateMailSettings').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
})
