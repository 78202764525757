import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "new-message" }
const _hoisted_2 = { class: "new-message__search-input-label" }
const _hoisted_3 = { class: "new-message__filter" }
const _hoisted_4 = { class: "new-message__lock" }
const _hoisted_5 = { class: "new-message__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_recipient_list = _resolveComponent("recipient-list")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_chat_input = _resolveComponent("chat-input")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('chat.message.selectOrganizationToSend')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_input_text, {
        name: "searchInputValue",
        value: _ctx.searchInputValue,
        onInput: _ctx.onSearchInput
      }, null, 8, ["value", "onInput"]),
      _createVNode(_component_base_single_select, {
        value: _ctx.selectedLanguage,
        name: "selectedLanguage",
        options: [
          { value: 'ja', label: _ctx.$t('common.label.japanese') },
          { value: 'en', label: _ctx.$t('common.label.english') },
        ],
        placeholder: _ctx.$t('chat.message.localePlaceholder'),
        onInput: _ctx.onLanguageInput
      }, null, 8, ["value", "options", "placeholder", "onInput"])
    ]),
    _createVNode(_component_recipient_list, {
      options: _ctx.organizationChannels.options,
      togglable: true,
      "checked-values": _ctx.organizationChannels.checkedValues,
      onSelectedListChange: _ctx.onSelectedListChange
    }, null, 8, ["options", "checked-values", "onSelectedListChange"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_tooltip, {
        direction: "left",
        label: _ctx.locked ? _ctx.$t('chat.message.locked') : _ctx.$t('chat.message.open'),
        class: "new-message__lock__icon",
        onClick: _ctx.onLockClick
      }, {
        default: _withCtx(() => [
          (_ctx.locked)
            ? (_openBlock(), _createBlock(_component_base_icon, {
                key: 0,
                "icon-name": "lock"
              }))
            : (_openBlock(), _createBlock(_component_base_icon, {
                key: 1,
                "icon-name": "lock-open"
              }))
        ]),
        _: 1
      }, 8, ["label", "onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_chat_input, {
        value: _ctx.chatInputValue,
        rows: 7,
        disabled: _ctx.locked,
        "is-sending": _ctx.isSending,
        onInput: _ctx.onChatInput,
        onSend: _ctx.onSend
      }, null, 8, ["value", "disabled", "is-sending", "onInput", "onSend"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_base_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}