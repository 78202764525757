import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680a0a64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-message-button__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_inner_label = _resolveComponent("inner-label")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "create-message-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_base_icon, { "icon-name": "comment-medical" })
    ]),
    _createVNode(_component_inner_label, {
      type: "button",
      class: "create-message-button__label"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('chat.message.newMessageModalTitle')), 1)
      ]),
      _: 1
    })
  ]))
}