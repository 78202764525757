import {
  Order as IOrder,
  OrderFuelSurchargeType as FuelSurchargeType,
  OrderOrderType as OrderType,
  OrderProductType as ProductType,
  PositionType,
} from '@/api/generated'

export class Order implements IOrder {
  orderType!: OrderType
  productType!: ProductType
  fuelSurchargeType?: FuelSurchargeType
  unitPrice!: string
  volume!: string
  exchangeVolume?: string
  executedAt?: string

  get isAsk(): boolean {
    return this.orderType === OrderType.Ask
  }

  get isBid(): boolean {
    return this.orderType === OrderType.Bid
  }

  get isLast(): boolean {
    return this.orderType === OrderType.Last
  }

  get positionType(): PositionType | undefined {
    switch (this.orderType) {
      case OrderType.Ask:
        return PositionType.Ask
      case OrderType.Bid:
        return PositionType.Bid
      default:
        return undefined
    }
  }

  get openToMarket(): boolean {
    return true
  }

  // BrokingOrderと互換性を保つためのプロパティ。常にundefinedとして扱う
  get dealPrivateConfidential(): undefined {
    return undefined
  }
}
