import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-741611b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-recap" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "organization-recap__heading" }
const _hoisted_4 = { class: "organization-recap__input" }
const _hoisted_5 = {
  key: 1,
  class: "organization-recap__input"
}
const _hoisted_6 = {
  key: 2,
  class: "organization-recap__input"
}
const _hoisted_7 = { class: "organization-recap__dataset" }
const _hoisted_8 = { class: "organization-recap__input" }
const _hoisted_9 = {
  key: 3,
  class: "organization-recap__input"
}
const _hoisted_10 = { class: "organization-recap__input" }
const _hoisted_11 = { class: "organization-recap__input" }
const _hoisted_12 = { class: "organization-recap__input" }
const _hoisted_13 = { class: "organization-recap__input" }
const _hoisted_14 = { class: "organization-recap__input" }
const _hoisted_15 = { class: "organization-recap__input" }
const _hoisted_16 = { class: "organization-recap__input" }
const _hoisted_17 = { class: "organization-recap__input" }
const _hoisted_18 = { class: "organization-recap__input" }
const _hoisted_19 = { class: "organization-recap__input" }
const _hoisted_20 = { class: "organization-recap__dataset" }
const _hoisted_21 = { class: "organization-recap__link-label" }
const _hoisted_22 = { class: "organization-recap__input" }
const _hoisted_23 = {
  key: 1,
  class: "organization-recap__input"
}
const _hoisted_24 = { class: "organization-recap__dataset" }
const _hoisted_25 = { class: "organization-recap__link-label" }
const _hoisted_26 = { class: "organization-recap__occto-master-area" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "organization-recap__occto-master-area" }
const _hoisted_29 = {
  key: 2,
  class: "organization-recap__input"
}
const _hoisted_30 = { class: "organization-recap__dataset" }
const _hoisted_31 = { class: "organization-recap__link-label" }
const _hoisted_32 = {
  key: 3,
  class: "organization-recap__input"
}
const _hoisted_33 = { class: "organization-recap__dataset" }
const _hoisted_34 = { class: "organization-recap__link-label" }
const _hoisted_35 = {
  key: 1,
  class: "organization-recap__confirm"
}
const _hoisted_36 = { class: "organization-recap__confirm-heading" }
const _hoisted_37 = { class: "organization-recap__confirm-heading" }
const _hoisted_38 = { class: "organization-recap__confirm-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_deal_delivery_period_list = _resolveComponent("deal-delivery-period-list")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_recap_receiver_selector = _resolveComponent("recap-receiver-selector")!
  const _component_occto_master_selector = _resolveComponent("occto-master-selector")!
  const _component_eex_clearing_house_selector = _resolveComponent("eex-clearing-house-selector")!
  const _component_tocom_broker_selector = _resolveComponent("tocom-broker-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_base_box, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
              _createVNode(_component_base_box, {
                type: "border",
                class: "organization-recap__confirm-box"
              }, {
                default: _withCtx(() => [
                  (_ctx.isSpread)
                    ? (_openBlock(), _createBlock(_component_base_label, {
                        key: 0,
                        class: "organization-recap__sub-heading",
                        label: _ctx.$t('trading.label.baseSpreadIndication')
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_base_dataset, {
                      class: "organization-recap__dataset",
                      data: _ctx.baseOrganizationRecap.publicRecapId,
                      label: _ctx.$t('trading.label.recapReceiverId')
                    }, null, 8, ["data", "label"])
                  ]),
                  (_ctx.isSpread)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_base_dataset, {
                          class: "organization-recap__dataset",
                          data: 
                _ctx.originAreaName ||
                  _ctx.baseOrganizationRecap.product.deliveryTerms.area.translation()
              ,
                          label: _ctx.$t('trading.label.area')
                        }, null, 8, ["data", "label"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_base_single_select, {
                            label: _ctx.$t('trading.label.area'),
                            name: `${_ctx.namePrefix}-area`,
                            value: _ctx.baseFormValue.areaInputOption,
                            options: _ctx.noSpreadAreaInputOptions,
                            placeholder: 
                  _ctx.$t('common.message.selectPlaceholder', {
                    name: _ctx.$t('trading.label.area'),
                  })
                ,
                            mode: _ctx.inputAreaMode,
                            rules: "required",
                            required: "",
                            onInput: _ctx.onAreaInput
                          }, null, 8, ["label", "name", "value", "options", "placeholder", "mode", "onInput"])
                        ])
                      ])),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_deal_delivery_period_list, {
                      class: "deal-with-organization-recap-form__dataset",
                      "delivery-periods": 
                _ctx.baseOrganizationRecap.product.deliveryTerms.deliveryPeriods
              ,
                      "unit-prices": _ctx.baseOrganizationRecap.unitPrices,
                      volumes: _ctx.baseOrganizationRecap.volumes
                    }, null, 8, ["delivery-periods", "unit-prices", "volumes"])
                  ]),
                  (_ctx.isSwingOption)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_base_dataset, {
                          class: "organization-recap__dataset",
                          data: _ctx.basicUnitPrice,
                          label: _ctx.$t('trading.label.basicUnitPrice')
                        }, null, 8, ["data", "label"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_base_dataset, {
                      class: "organization-recap__dataset",
                      data: _ctx.baseOrganizationRecap.displayBrokingFee,
                      label: _ctx.$t('trading.label.brokingFee')
                    }, null, 8, ["data", "label"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_base_dataset, {
                      class: "organization-recap__dataset",
                      data: _ctx.baseOrganizationRecap.displayTotalBrokingFee,
                      label: _ctx.$t('trading.label.totalBrokingFee')
                    }, null, 8, ["data", "label"])
                  ])
                ]),
                _: 1
              }),
              (_ctx.exchangeOrganizationRecap && _ctx.isSpread)
                ? (_openBlock(), _createBlock(_component_base_box, {
                    key: 0,
                    type: "border",
                    class: "organization-recap__confirm-box"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_base_label, {
                        class: "organization-recap__sub-heading",
                        label: _ctx.$t('trading.label.exchangeSpreadIndication')
                      }, null, 8, ["label"]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_base_dataset, {
                          class: "organization-recap__dataset",
                          data: _ctx.exchangeOrganizationRecap.publicRecapId,
                          label: _ctx.$t('trading.label.recapReceiverId')
                        }, null, 8, ["data", "label"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_base_dataset, {
                          class: "organization-recap__dataset",
                          data: 
                _ctx.destinationAreaName ||
                  _ctx.exchangeOrganizationRecap.product.deliveryTerms.area.translation()
              ,
                          label: _ctx.$t('trading.label.area')
                        }, null, 8, ["data", "label"])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_deal_delivery_period_list, {
                          class: "deal-with-organization-recap-form__dataset",
                          "delivery-periods": _ctx.exchangeDeliveryPeriods,
                          "unit-prices": _ctx.exchangeOrganizationRecap.unitPrices,
                          volumes: _ctx.exchangeOrganizationRecap.volumes,
                          "is-spread": _ctx.isSpread
                        }, null, 8, ["delivery-periods", "unit-prices", "volumes", "is-spread"])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_base_dataset, {
                          class: "organization-recap__dataset",
                          data: _ctx.exchangeOrganizationRecap.displayBrokingFee,
                          label: _ctx.$t('trading.label.brokingFee')
                        }, null, 8, ["data", "label"])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_base_dataset, {
                          class: "organization-recap__dataset",
                          data: _ctx.exchangeOrganizationRecap.displayTotalBrokingFee,
                          label: _ctx.$t('trading.label.totalBrokingFee')
                        }, null, 8, ["data", "label"])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_base_dataset, {
                  class: "organization-recap__dataset",
                  data: _ctx.baseOrganizationRecap.organizationName.translation(),
                  label: _ctx.$t('trading.label.dealPartner')
                }, null, 8, ["data", "label"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_base_dataset, {
                  class: "organization-recap__dataset",
                  data: _ctx.baseOrganizationRecap.address,
                  label: _ctx.$t('trading.label.address')
                }, null, 8, ["data", "label"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_base_label, {
                    label: _ctx.$t('trading.label.recapReceiver')
                  }, {
                    accessory: _withCtx(() => [
                      _createVNode(_component_base_link, {
                        class: "organization-recap__link",
                        path: _ctx.recapReceiversPath,
                        "is-target-blank": "",
                        "reset-font-style": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('trading.label.checkRecapReceiverList')), 1),
                          _createVNode(_component_base_icon, { "icon-name": "external-link-alt" })
                        ]),
                        _: 1
                      }, 8, ["path"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_recap_receiver_selector, {
                    "selected-recap-receiver": _ctx.baseFormValue.recapReceiver,
                    "organization-id": _ctx.baseOrganizationRecap.organizationId,
                    "modal-name": `${_ctx.position}ReceiverSelectorModal`,
                    onSelect: _ctx.onRecapReceiverSelect
                  }, null, 8, ["selected-recap-receiver", "organization-id", "modal-name", "onSelect"])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_base_dataset, {
                  class: "organization-recap__dataset",
                  data: _ctx.baseOrganizationRecap.displayBrokerageFeePaymentTiming,
                  label: _ctx.$t('trading.label.howToPayBrokerageFee')
                }, null, 8, ["data", "label"])
              ]),
              (_ctx.productType.isBg())
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(_component_base_label, {
                        label: _ctx.$t('trading.label.occtoMaster')
                      }, {
                        accessory: _withCtx(() => [
                          _createVNode(_component_base_link, {
                            class: "organization-recap__link",
                            path: _ctx.occtoMasterPath,
                            "is-target-blank": "",
                            "reset-font-style": ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('trading.label.checkOcctoMasterList')), 1),
                              _createVNode(_component_base_icon, { "icon-name": "external-link-alt" })
                            ]),
                            _: 1
                          }, 8, ["path"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      (_ctx.isAreaSpread)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.originAreaName), 1),
                              _createVNode(_component_occto_master_selector, {
                                "selected-occto-master": _ctx.selectedBaseOcctoMaster,
                                "organization-id": _ctx.baseOrganizationRecap.organizationId,
                                "area-id": _ctx.baseOrganizationRecap.areaId,
                                "modal-name": `${_ctx.position}BaseOcctoMasterSelectorModal`,
                                onSelect: _ctx.onBaseOcctoMasterSelect
                              }, null, 8, ["selected-occto-master", "organization-id", "area-id", "modal-name", "onSelect"])
                            ]),
                            (_ctx.exchangeOrganizationRecap)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                  _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.destinationAreaName), 1),
                                  _createVNode(_component_occto_master_selector, {
                                    "selected-occto-master": _ctx.selectedExchangeOcctoMaster,
                                    "organization-id": _ctx.exchangeOrganizationRecap.organizationId,
                                    "area-id": _ctx.exchangeOrganizationRecap.areaId,
                                    "modal-name": `${_ctx.position}ExchangeOcctoMasterSelectorModal`,
                                    onSelect: _ctx.onExchangeOcctoMasterSelect
                                  }, null, 8, ["selected-occto-master", "organization-id", "area-id", "modal-name", "onSelect"])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : (_openBlock(), _createBlock(_component_occto_master_selector, {
                            key: 1,
                            "selected-occto-master": _ctx.selectedBaseOcctoMaster,
                            "organization-id": _ctx.baseOrganizationRecap.organizationId,
                            "area-id": _ctx.baseFormValue?.areaInputOption?.value,
                            "modal-name": `${_ctx.position}OcctoMasterSelectorModal`,
                            onSelect: _ctx.onBaseOcctoMasterSelect
                          }, null, 8, ["selected-occto-master", "organization-id", "area-id", "modal-name", "onSelect"]))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.productType.isSwapEex())
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_base_label, {
                        label: _ctx.$t('trading.label.eexClearingHouse')
                      }, {
                        accessory: _withCtx(() => [
                          _createVNode(_component_base_link, {
                            class: "organization-recap__link",
                            path: _ctx.clearingInformationPath,
                            "is-target-blank": "",
                            "reset-font-style": ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('trading.label.checkEexClearingHouse')), 1),
                              _createVNode(_component_base_icon, { "icon-name": "external-link-alt" })
                            ]),
                            _: 1
                          }, 8, ["path"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_eex_clearing_house_selector, {
                        "selected-eex-clearing-house": _ctx.baseFormValue.eexClearingHouse,
                        "organization-id": _ctx.baseOrganizationRecap.organizationId,
                        "modal-name": `${_ctx.position}EexClearingHouseSelectorModal`,
                        onSelect: _ctx.onEexClearingHouseSelect
                      }, null, 8, ["selected-eex-clearing-house", "organization-id", "modal-name", "onSelect"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.productType.isSwapTocom())
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createVNode(_component_base_label, {
                        label: _ctx.$t('trading.label.tocomBroker')
                      }, {
                        accessory: _withCtx(() => [
                          _createVNode(_component_base_link, {
                            class: "organization-recap__link",
                            path: _ctx.clearingInformationPath,
                            "is-target-blank": "",
                            "reset-font-style": ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('trading.label.checkTocomBroker')), 1),
                              _createVNode(_component_base_icon, { "icon-name": "external-link-alt" })
                            ]),
                            _: 1
                          }, 8, ["path"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_tocom_broker_selector, {
                        "selected-tocom-broker": _ctx.baseFormValue.tocomBroker,
                        "organization-id": _ctx.baseOrganizationRecap.organizationId,
                        "modal-name": `${_ctx.position}TocomBrokerSelectorModal`,
                        onSelect: _ctx.onTocomBrokerSelect
                      }, null, 8, ["selected-tocom-broker", "organization-id", "modal-name", "onSelect"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_35, [
          _createVNode(_component_base_box, { class: "organization-recap__confirm-box" }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_36, _toDisplayString(_ctx.title), 1),
              _createVNode(_component_base_dataset, {
                direction: "column",
                label: _ctx.$t('trading.label.dealPartner'),
                data: _ctx.baseOrganizationRecap.organizationName.translation()
              }, null, 8, ["label", "data"]),
              _createVNode(_component_base_dataset, {
                direction: "column",
                label: _ctx.$t('trading.label.address'),
                data: _ctx.baseOrganizationRecap.address
              }, null, 8, ["label", "data"]),
              _createVNode(_component_base_dataset, {
                direction: "column",
                label: _ctx.$t('trading.label.area'),
                data: _ctx.selectedArea.name.translation()
              }, null, 8, ["label", "data"]),
              (_ctx.baseFormValue.recapReceiver)
                ? (_openBlock(), _createBlock(_component_base_dataset, {
                    key: 0,
                    direction: "column",
                    label: _ctx.$t('trading.label.recapReceiver'),
                    data: _ctx.baseFormValue.recapReceiver.name
                  }, null, 8, ["label", "data"]))
                : _createCommentVNode("", true),
              _createVNode(_component_base_dataset, {
                direction: "column",
                label: _ctx.$t('trading.label.brokingFee'),
                data: _ctx.baseOrganizationRecap.displayBrokingFee
              }, null, 8, ["label", "data"]),
              _createVNode(_component_base_dataset, {
                direction: "column",
                label: _ctx.$t('trading.label.totalBrokingFee'),
                data: _ctx.baseOrganizationRecap.displayTotalBrokingFee
              }, null, 8, ["label", "data"]),
              _createVNode(_component_base_dataset, {
                direction: "column",
                label: _ctx.$t('trading.label.howToPayBrokerageFee'),
                data: _ctx.baseOrganizationRecap.displayBrokerageFeePaymentTiming
              }, null, 8, ["label", "data"]),
              (_ctx.productType.isSwapEex() && _ctx.baseFormValue.eexClearingHouse)
                ? (_openBlock(), _createBlock(_component_base_dataset, {
                    key: 1,
                    direction: "column",
                    label: _ctx.$t('trading.label.eexClearingHouse'),
                    data: _ctx.baseFormValue.eexClearingHouse.name
                  }, null, 8, ["label", "data"]))
                : _createCommentVNode("", true),
              (_ctx.productType.isSwapTocom() && _ctx.baseFormValue.tocomBroker)
                ? (_openBlock(), _createBlock(_component_base_dataset, {
                    key: 2,
                    direction: "column",
                    label: _ctx.$t('trading.label.tocomBroker'),
                    data: _ctx.baseFormValue.tocomBroker.displayName
                  }, null, 8, ["label", "data"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.productType.isBg() && _ctx.baseFormValue.occtoMaster)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_base_box, { class: "organization-recap__confirm-box" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_37, _toDisplayString(_ctx.$t('trading.label.occtoMaster')) + " (" + _toDisplayString(_ctx.originAreaName ||
                _ctx.baseOrganizationRecap.product.deliveryTerms.area.translation()) + ") ", 1),
                    _createVNode(_component_base_dataset, {
                      direction: "column",
                      label: _ctx.$t('trading.label.occtoMasterType'),
                      data: _ctx.baseFormValue.occtoMaster.displayOcctoMasterTypeName
                    }, null, 8, ["label", "data"]),
                    _createVNode(_component_base_dataset, {
                      direction: "column",
                      label: _ctx.$t('trading.label.companyPrefix'),
                      data: _ctx.baseFormValue.occtoMaster.companyPrefix
                    }, null, 8, ["label", "data"]),
                    _createVNode(_component_base_dataset, {
                      direction: "column",
                      label: _ctx.baseFormValue.occtoMaster.displayCodeLabel,
                      data: _ctx.baseFormValue.occtoMaster.code
                    }, null, 8, ["label", "data"]),
                    _createVNode(_component_base_dataset, {
                      direction: "column",
                      label: _ctx.baseFormValue.occtoMaster.displayNameLabel,
                      data: _ctx.baseFormValue.occtoMaster.name
                    }, null, 8, ["label", "data"])
                  ]),
                  _: 1
                }),
                (
            _ctx.isAreaSpread && _ctx.exchangeFormValue && _ctx.exchangeFormValue.occtoMaster
          )
                  ? (_openBlock(), _createBlock(_component_base_box, {
                      key: 0,
                      class: "organization-recap__confirm-box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", _hoisted_38, _toDisplayString(_ctx.$t('trading.label.occtoMaster')) + " (" + _toDisplayString(_ctx.destinationAreaName ||
                _ctx.exchangeOrganizationRecap.product.deliveryTerms.area.translation()) + ") ", 1),
                        _createVNode(_component_base_dataset, {
                          direction: "column",
                          label: _ctx.$t('trading.label.occtoMasterType'),
                          data: _ctx.exchangeFormValue.occtoMaster.displayOcctoMasterTypeName
                        }, null, 8, ["label", "data"]),
                        _createVNode(_component_base_dataset, {
                          direction: "column",
                          label: _ctx.$t('trading.label.companyPrefix'),
                          data: _ctx.exchangeFormValue.occtoMaster.companyPrefix
                        }, null, 8, ["label", "data"]),
                        _createVNode(_component_base_dataset, {
                          direction: "column",
                          label: _ctx.exchangeFormValue.occtoMaster.displayCodeLabel,
                          data: _ctx.exchangeFormValue.occtoMaster.code
                        }, null, 8, ["label", "data"]),
                        _createVNode(_component_base_dataset, {
                          direction: "column",
                          label: _ctx.exchangeFormValue.occtoMaster.displayNameLabel,
                          data: _ctx.exchangeFormValue.occtoMaster.name
                        }, null, 8, ["label", "data"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]))
  ]))
}