import { Type } from 'class-transformer'

import { EEXPosting } from '@/models/trading/EEXPosting'

export class EEXPostingResponse {
  @Type(() => EEXPosting)
  eexPostings!: EEXPosting[]

  totalCount!: number
  nextPageToken!: string
}
