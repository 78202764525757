import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ed2efc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negotiation-confirm-modal-content" }
const _hoisted_2 = { class: "negotiation-confirm-modal-content__message" }
const _hoisted_3 = { class: "negotiation-confirm-modal-content__heading" }
const _hoisted_4 = { class: "negotiation-confirm-modal-content__heading" }
const _hoisted_5 = { class: "negotiation-confirm-modal-content__heading" }
const _hoisted_6 = { class: "negotiation-confirm-modal-content__heading" }
const _hoisted_7 = { class: "negotiation-confirm-modal-content__heading" }
const _hoisted_8 = { class: "negotiation-confirm-modal-content__heading" }
const _hoisted_9 = { class: "negotiation-confirm-modal-content__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
    (_ctx.isEditMode)
      ? (_openBlock(), _createBlock(_component_base_box, {
          key: 0,
          class: "negotiation-confirm-modal-content__box"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('trading.label.basicInformation')), 1),
            _createVNode(_component_base_dataset, {
              direction: "column",
              label: _ctx.$t('trading.label.status'),
              data: _ctx.statusValue
            }, null, 8, ["label", "data"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_base_box, { class: "negotiation-confirm-modal-content__box" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('trading.label.productInformation')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productBlockItems, (item, index) => {
          return (_openBlock(), _createBlock(_component_base_dataset, {
            key: index,
            direction: "column",
            label: item.label,
            data: item.data
          }, null, 8, ["label", "data"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_base_box, { class: "negotiation-confirm-modal-content__box" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('trading.label.askIndication')), 1),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.organizationName'),
          data: _ctx.formValue.askIndication.organizationName.translation()
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.productType'),
          data: _ctx.formValue.askProductType.label
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.swingOption'),
          data: 
          _ctx.formValue.askIndication.price.type === 'basic-plus-volume'
            ? _ctx.$t('trading.label.withSwingOption')
            : _ctx.$t('trading.label.withoutSwingOption')
        
        }, null, 8, ["label", "data"]),
        (_ctx.isEditMode)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 0,
              direction: "column",
              label: _ctx.brokingFeeLabel,
              data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.askBrokingFee,
          })
        
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true),
        (_ctx.isEditMode && _ctx.isStandardSpread)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 1,
              direction: "column",
              label: _ctx.exchangeBrokingFeeLabel,
              data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.askExchangeBrokingFee,
          })
        
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.formValue.creditSleever)
      ? (_openBlock(), _createBlock(_component_base_box, {
          key: 1,
          class: "negotiation-confirm-modal-content__box"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('trading.label.creditSleeve')), 1),
            _createVNode(_component_base_dataset, {
              direction: "column",
              label: _ctx.$t('trading.label.creditSleever'),
              data: _ctx.formValue.creditSleever.name.translation()
            }, null, 8, ["label", "data"]),
            (_ctx.isEditMode)
              ? (_openBlock(), _createBlock(_component_base_dataset, {
                  key: 0,
                  direction: "column",
                  label: _ctx.$t('trading.label.sleeveSpread'),
                  data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.creditSleeverSleeveSpread,
          })
        
                }, null, 8, ["label", "data"]))
              : _createCommentVNode("", true),
            (_ctx.isEditMode)
              ? (_openBlock(), _createBlock(_component_base_dataset, {
                  key: 1,
                  direction: "column",
                  label: _ctx.$t('trading.label.askBrokingFee'),
                  data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.creditSleeverAskBrokingFee,
          })
        
                }, null, 8, ["label", "data"]))
              : _createCommentVNode("", true),
            (_ctx.isEditMode)
              ? (_openBlock(), _createBlock(_component_base_dataset, {
                  key: 2,
                  direction: "column",
                  label: _ctx.$t('trading.label.bidBrokingFee'),
                  data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.creditSleeverBidBrokingFee,
          })
        
                }, null, 8, ["label", "data"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_base_box, { class: "negotiation-confirm-modal-content__box" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t('trading.label.bidIndication')), 1),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.organizationName'),
          data: _ctx.formValue.bidIndication.organizationName.translation()
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.productType'),
          data: _ctx.formValue.bidProductType.label
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.swingOption'),
          data: 
          _ctx.formValue.bidIndication.price.type === 'basic-plus-volume'
            ? _ctx.$t('trading.label.withSwingOption')
            : _ctx.$t('trading.label.withoutSwingOption')
        
        }, null, 8, ["label", "data"]),
        (_ctx.isEditMode)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 0,
              direction: "column",
              label: _ctx.brokingFeeLabel,
              data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.bidBrokingFee,
          })
        
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true),
        (_ctx.isEditMode && _ctx.isStandardSpread)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 1,
              direction: "column",
              label: _ctx.exchangeBrokingFeeLabel,
              data: 
          _ctx.$t('trading.label.priceWithUnit', {
            price: _ctx.formValue.bidExchangeBrokingFee,
          })
        
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isEditMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.negotiationBlockItemsList, (items, index) => {
            return (_openBlock(), _createBlock(_component_base_box, {
              key: index,
              class: "negotiation-confirm-modal-content__box"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('trading.label.negotiationWithNumber', { number: index + 1 })), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_base_dataset, {
                    key: i,
                    direction: "column",
                    label: item.label,
                    data: item.data
                  }, null, 8, ["label", "data"]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createVNode(_component_base_box, { class: "negotiation-confirm-modal-content__box" }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t('trading.label.others')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherBlockItems, (item, index) => {
                return (_openBlock(), _createBlock(_component_base_dataset, {
                  key: index,
                  direction: "column",
                  label: item.label,
                  data: item.data
                }, null, 8, ["label", "data"]))
              }), 128))
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ]))
}