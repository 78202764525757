import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e816cba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public-order-book-detail" }
const _hoisted_2 = { class: "broking-order-book-detail__records" }
const _hoisted_3 = { class: "public-order-book-detail__product-type-wrapper" }
const _hoisted_4 = { class: "public-order-book-detail__product-type" }
const _hoisted_5 = {
  key: 0,
  class: "public-order-book-detail__fuel-surcharge-type-name"
}
const _hoisted_6 = { class: "public-order-book-detail__public-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_old_delivery_terms = _resolveComponent("old-delivery-terms")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_public_information = _resolveComponent("public-information")!
  const _component_order_book_price = _resolveComponent("order-book-price")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_old_delivery_terms, {
      class: "public-order-book-detail__delivery-terms",
      "delivery-terms": _ctx.deliveryTerms,
      "delivery-unit": _ctx.deliveryUnit,
      locale: _ctx.locale
    }, null, 8, ["delivery-terms", "delivery-unit", "locale"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderBookRecords, (record, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "public-order-book-detail__record"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_product_type, {
                name: _ctx.productTypeNameOf(record.product)
              }, null, 8, ["name"])
            ]),
            (_ctx.fuelSurchargeName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.fuelSurchargeName), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (record.publicInformation)
              ? (_openBlock(), _createBlock(_component_public_information, {
                  key: 0,
                  information: record.publicInformation
                }, null, 8, ["information"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_order_book_price, {
              class: "public-order-book__price",
              "record-type": record.recordType,
              price: record.price,
              "unit-price": record.unitPrice,
              volume: record.volume,
              "created-at": record.createdAt,
              "fuel-surcharge-type": record.fuelSurchargeType,
              locale: _ctx.locale,
              "color-reversed": _ctx.colorReversed,
              "position-reversed": _ctx.positionReversed
            }, null, 8, ["record-type", "price", "unit-price", "volume", "created-at", "fuel-surcharge-type", "locale", "color-reversed", "position-reversed"])
          ])
        ]))
      }), 128))
    ])
  ]))
}