
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { PER_PAGE } from '@/components/common/constants/List'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import IndicationList from '@/components/trading/IndicationList.vue'
import IndicationListHeader from '@/components/trading/IndicationListHeader.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { Indication } from '@/models/trading/Indication'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { IndicationModule } from '@/store/modules/trading/indication'
import { SET_FILTER_CONDITION } from '@/store/modules/trading/indication/IndicationMutations'
import { getIndicationsFilter } from '@/store/modules/trading/indication/helper'
import { IndicationFilterAttribute } from '@/store/modules/trading/indication/interface'
import { ProductModule } from '@/store/modules/trading/product'

const {
  mapState: indicationsMapState,
  mapActions: indicationsMapActions,
  mapMutations: indicationsMutations,
  mapGetters: indicationsMapGetters,
} = createNamespacedHelpers('indication') as IndicationModule

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const keyOfSelectedFilters = 'selectedFiltersOfIndications'

export default defineComponent({
  name: 'Indications',
  components: {
    BaseAlert,
    BaseButton,
    BrokerPage,
    CenteredLoadingContent,
    IndicationList,
    IndicationListHeader,
    ProductFilter,
    UiStackSelector,
  },
  data() {
    return {
      indications: [] as Indication[],
      isLoaded: false,
      isUtilActionDropdownActive: false,
      isFilterActionDropdownActive: false,
      attributes: [
        'area',
        'startDeliveryYearMonth',
        'endDeliveryYearMonth',
        'hourType',
        'productType',
        'organizationName',
        'status',
        'position',
        'nonStandard',
      ] as IndicationFilterAttribute[],
      selectedFilters: [] as IndicationFilterAttribute[],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState([
      'areas',
      'hourTypes',
      'productTypes',
      'deliveryYearMonths',
    ]),
    ...indicationsMapState(['filterCondition']),
    ...indicationsMapGetters(['filterConditionParameter']),
    lastId(): string | undefined {
      if (this.indications.length === 0) {
        return undefined
      }
      return this.indications[this.indications.length - 1].id
    },
    filters(): AttributeFilter[] {
      return getIndicationsFilter(
        this.areas,
        this.deliveryYearMonths,
        this.hourTypes,
        this.productTypes,
        this.selectedFilters,
        this.filterCondition,
        this.userProfile.locale,
      )
    },
    filterActions(): ActionDropdownItem[] {
      return this.attributes.map(attribute => ({
        label: this.$t('trading.label.' + attribute),
        eventName: attribute,
      })) as ActionDropdownItem[]
    },
  },
  async created() {
    await Promise.all([
      this.fetchList(),
      this.fetchAreas(),
      this.fetchHourTypes(),
      this.fetchProductTypes(),
      this.fetchDeliveryYearMonths(),
    ])
    this.getFilters(keyOfSelectedFilters)
  },
  methods: {
    fetchIndications: indicationsMapActions(['fetchIndications'])
      .fetchIndications as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any,
    ) => Promise<Indication[]>,
    ...indicationsMutations([SET_FILTER_CONDITION]),
    ...productMapActions([
      'fetchAreas',
      'fetchHourTypes',
      'fetchProductTypes',
      'fetchDeliveryYearMonths',
    ]),
    async fetchList() {
      this.indications = []
      this.isLoaded = false
      await this.fetchIndications(this.filterConditionParameter)
        .then((indications: Indication[]) => {
          this.indications = indications
          this.isLoaded =
            indications.length === 0 || indications.length % PER_PAGE !== 0
          if (this.indications.length === 0) {
            this.uiStack = UiStack.Blank
          } else {
            this.uiStack = UiStack.Ideal
          }
        })
        .catch(e => {
          this.uiStack = UiStack.Error
          throw e
        })
    },
    async fetchNextList(lastId?: string) {
      const indications = await this.fetchIndications({
        ...this.filterConditionParameter,
        lastId,
      }).catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
      this.indications.push(...indications)
      this.isLoaded =
        this.isLoaded ||
        indications.length === 0 ||
        indications.length % PER_PAGE !== 0
    },
    onClickUtilDropdown() {
      this.isUtilActionDropdownActive = !this.isUtilActionDropdownActive
    },
    onClickUtilOutside() {
      this.isUtilActionDropdownActive = false
    },
    onClickFilterDropdown() {
      this.isFilterActionDropdownActive = !this.isFilterActionDropdownActive
    },
    onClickFilterOutside() {
      this.isFilterActionDropdownActive = false
    },
    onFilterItemSelect(event: {
      attribute: IndicationFilterAttribute
      value?: InputOption
    }) {
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: event.value?.value,
        },
      })
      this.fetchList()
    },
    onFilterItemInput(event: {
      attribute: IndicationFilterAttribute
      value?: string
    }) {
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: event.value,
        },
      })
      this.fetchList()
    },
    onFilterRemove(event: { attribute: IndicationFilterAttribute }) {
      this.selectedFilters = this.selectedFilters.filter(
        attribute => attribute !== event.attribute,
      )
      const isRequiredFetchList = !!this.filterCondition[event.attribute]
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: undefined,
        },
      })
      this.updateLocalStorageOfSelectedFilters(
        keyOfSelectedFilters,
        this.selectedFilters,
      )
      if (isRequiredFetchList) {
        this.fetchList()
      }
    },
    onLastItemVisible() {
      if (!this.isLoaded) {
        this.fetchNextList(this.lastId)
      }
    },
    onChangeFilters(event: {
      selectedValues: IndicationFilterAttribute[]
      changeValue: IndicationFilterAttribute
    }) {
      this.selectedFilters = event.selectedValues
      this.updateLocalStorageOfSelectedFilters(
        keyOfSelectedFilters,
        this.selectedFilters,
      )
    },
    getFilters(keyOfSelectedFilters: string) {
      const item = localStorage.getItem(keyOfSelectedFilters)
      if (item !== null) {
        const selectedFiltersFromLocal = JSON.parse(item)
        this.selectedFilters = selectedFiltersFromLocal
      }
    },
    updateLocalStorageOfSelectedFilters(
      key: string,
      value: IndicationFilterAttribute[],
    ) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    async onNewButtonClick() {
      await this.$router.push('/indications/new')
    },
  },
})
