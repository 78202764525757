import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7697cb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-date-form" }
const _hoisted_2 = { class: "delivery-date-form__input" }
const _hoisted_3 = { class: "delivery-date-form__input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_datepicker = _resolveComponent("base-datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_datepicker, {
        ref: "startDeliveryDate",
        label: 
          _ctx.isShownLabel ? _ctx.$t('trading.label.startDeliveryDate') : undefined
        ,
        name: `startDeliveryDate-${_ctx.index}`,
        value: _ctx.startDeliveryDate,
        locale: _ctx.locale,
        "min-date": _ctx.startDeliveryMinDate,
        "max-date": _ctx.startDeliveryMaxDate,
        "selectable-dates": _ctx.startSelectableDates,
        mode: _ctx.mode,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.startDeliveryDate'),
          })
        ,
        "focused-date": _ctx.focusedStartDeliveryDate,
        rules: "required",
        required: "",
        onInput: _ctx.onStartDeliveryDateInput
      }, null, 8, ["label", "name", "value", "locale", "min-date", "max-date", "selectable-dates", "mode", "placeholder", "focused-date", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_datepicker, {
        ref: "endDeliveryDate",
        label: _ctx.isShownLabel ? _ctx.$t('trading.label.endDeliveryDate') : undefined,
        name: `endDeliveryDate-${_ctx.index}`,
        value: _ctx.endDeliveryDate,
        locale: _ctx.locale,
        "min-date": _ctx.endDeliveryMinDate,
        "selectable-dates": _ctx.endSelectableDates,
        mode: _ctx.mode,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.endDeliveryDate'),
          })
        ,
        "focused-date": _ctx.focusedEndDeliveryDate,
        rules: "required",
        required: "",
        onInput: _ctx.onEndDeliveryDateInput
      }, null, 8, ["label", "name", "value", "locale", "min-date", "selectable-dates", "mode", "placeholder", "focused-date", "onInput"])
    ])
  ]))
}