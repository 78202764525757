import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5d60f00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "horizontal-tab" }
const _hoisted_2 = ["aria-labelledby"]
const _hoisted_3 = { class: "horizontal-tab__item-wrapper" }
const _hoisted_4 = ["id", "href", "aria-controls", "aria-selected", "tabindex"]
const _hoisted_5 = ["id", "aria-controls", "aria-selected", "disabled", "tabindex", "onClick"]
const _hoisted_6 = ["id", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["horizontal-tab__list", { 'horizontal-tab__list--parent': !_ctx.isChild }]),
      role: "tablist",
      "aria-labelledby": _ctx.ariaLabelledBy
    }, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(`horizontal-tab__item--${_ctx.modifierName}`)
          }, [
            (tab.to)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  id: _ctx.getTabTypeId('tab', index + 1),
                  ref_for: true,
                  ref: "tabTrigger",
                  href: tab.to,
                  class: _normalizeClass(["horizontal-tab__tab", `horizontal-tab__tab--${_ctx.modifierName}`]),
                  role: "tab",
                  "aria-controls": _ctx.getTabTypeId('tabPanel', index + 1),
                  "aria-selected": _ctx.isCurrentTab(index) || undefined,
                  tabindex: _ctx.isCurrentTab(index) ? -1 : 0,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickLink && _ctx.onClickLink(...args))),
                  onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
                }, _toDisplayString(tab.label), 43, _hoisted_4))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  id: _ctx.getTabTypeId('tab', index + 1),
                  ref_for: true,
                  ref: "tabTrigger",
                  class: _normalizeClass(["horizontal-tab__tab", `horizontal-tab__tab--${_ctx.modifierName}`]),
                  role: "tab",
                  type: "button",
                  "aria-controls": _ctx.getTabTypeId('tabPanel', index + 1),
                  "aria-selected": _ctx.isCurrentTab(index) || undefined,
                  disabled: _ctx.isCurrentTab(index),
                  tabindex: _ctx.isCurrentTab(index) ? -1 : 0,
                  onClick: () => _ctx.onClickTabButton(index),
                  onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
                }, _toDisplayString(tab), 43, _hoisted_5))
          ], 2))
        }), 128))
      ])
    ], 10, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.isCurrentTab(index))
          ? (_openBlock(), _createElementBlock("div", {
              id: _ctx.getTabTypeId('tabPanel', index + 1),
              key: index,
              role: "tabpanel",
              "aria-labelledby": _ctx.getTabTypeId('tab', index + 1)
            }, [
              _renderSlot(_ctx.$slots, _ctx.getTabTypeId('tabPanel', index + 1), {}, undefined, true)
            ], 8, _hoisted_6))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}