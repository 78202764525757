
import { ErrorMessage, Field } from 'vee-validate'
import { defineComponent } from 'vue'

import BaseLabel from '@/components/common/BaseLabel.vue'
import { InputMode } from '@/components/common/constants/InputMode'

export default defineComponent({
  name: 'BaseTextArea',
  components: {
    BaseLabel,
    ErrorMessage,
    Field,
  },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: undefined,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    help: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'edit'],
  computed: {
    inputable(): boolean {
      return this.mode === InputMode.INPUT
    },
    editable(): boolean {
      return this.mode === InputMode.EDIT
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  methods: {
    onInput(event: Event) {
      if (event.target instanceof HTMLTextAreaElement) {
        this.$emit('input', event.target.value)
      }
    },
    onClick() {
      this.$emit('edit')
    },
  },
})
