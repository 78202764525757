import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { HourType } from '@/models/trading/HourType'
import { PrivateConfidentialOptions } from '@/models/trading/PrivateConfidentialOptions'
import { ProductType } from '@/models/trading/ProductType'
import {
  DealFilterAttribute,
  DealFilterConditions,
} from '@/store/modules/trading/deal/interface'

export const getDealsFilter = (
  areas: Area[],
  hourTypes: HourType[],
  productTypes: ProductType[],
  filterAttributes: DealFilterAttribute[],
  filterCondition: DealFilterConditions,
  deliveryYearMonths: DeliveryYearMonth[],
): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    switch (attribute) {
      case 'startDeliveryYearMonth': {
        const options = deliveryYearMonths.map(type =>
          type.toProductFiltersOption(),
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'endDeliveryYearMonth': {
        const options = deliveryYearMonths.map(type =>
          type.toProductFiltersOption(),
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'area': {
        const options = areas.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'hourType': {
        const options = hourTypes.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'productType': {
        const options = productTypes.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'privateConfidentialOfFilter': {
        const options = new PrivateConfidentialOptions().options
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'executedDate': {
        return {
          type: 'date',
          attribute,
          value: filterCondition[attribute],
        }
      }
      case 'organizationName':
      case 'negotiationId':
      default:
        return {
          type: 'text',
          attribute,
          value: filterCondition[attribute],
        }
    }
  })
}
