import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e48ec74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "history" }
const _hoisted_2 = {
  key: 1,
  class: "history__no-history"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_filter = _resolveComponent("product-filter")!
  const _component_my_indication_list = _resolveComponent("my-indication-list")!
  const _component_my_deal_list = _resolveComponent("my-deal-list")!
  const _component_horizontal_tab = _resolveComponent("horizontal-tab")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_my_indication_detail = _resolveComponent("my-indication-detail")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_trader_page = _resolveComponent("trader-page")!

  return (_openBlock(), _createBlock(_component_trader_page, {
    title: _ctx.$t('common.label.history'),
    "active-menu-item": "history"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_horizontal_tab, {
          "tab-list": _ctx.tabList,
          onTabActivate: _ctx.onTabActive
        }, {
          "tabPanel-1": _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_product_filter, {
                class: "history__product-filter",
                filters: _ctx.productFilter(_ctx.userProfile.locale),
                actions: _ctx.indicationActions,
                "is-action-dropdown-active": _ctx.isActionDropdownActive,
                "selected-filters": _ctx.selectedFilters,
                onSelect: _ctx.onFilterItemSelect,
                onRemove: _ctx.onFilterItemRemove,
                onFilterRemove: _ctx.onFilterRemove,
                onClickButton: _ctx.onClickDropdown,
                onClickOutside: _ctx.onClickOutside,
                onChangeList: _ctx.onChangeFilters
              }, null, 8, ["filters", "actions", "is-action-dropdown-active", "selected-filters", "onSelect", "onRemove", "onFilterRemove", "onClickButton", "onClickOutside", "onChangeList"]),
              _createVNode(_component_my_indication_list, {
                class: "history__my-indication-list",
                "my-indications": _ctx.filteredMyIndications,
                "member-profiles": _ctx.memberProfiles,
                "display-header": _ctx.myIndications.length > 0,
                locale: _ctx.userProfile.locale,
                "is-eclear-enabled": _ctx.isEclearEnabled,
                "color-reversed": 
                _ctx.userProfile.services.esquare.userSetting.colorReversed
              ,
                onSelect: _ctx.onSelect,
                onActivate: _ctx.onActivate,
                onDeactivate: _ctx.onDeactivate,
                onDelete: _ctx.onDelete
              }, null, 8, ["my-indications", "member-profiles", "display-header", "locale", "is-eclear-enabled", "color-reversed", "onSelect", "onActivate", "onDeactivate", "onDelete"])
            ])
          ]),
          "tabPanel-2": _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_product_filter, {
                class: "history__product-filter",
                filters: _ctx.productFilterOfMyDeal(_ctx.userProfile.locale),
                actions: _ctx.dealActions,
                "is-action-dropdown-active": _ctx.isActionDropdownActive,
                "selected-filters": _ctx.selectedFiltersOfMyDeal,
                onSelect: _ctx.onFilterItemSelect,
                onRemove: _ctx.onFilterItemRemove,
                onFilterRemove: _ctx.onFilterRemove,
                onClickButton: _ctx.onClickDropdown,
                onClickOutside: _ctx.onClickOutside,
                onChangeList: _ctx.onChangeFilters
              }, null, 8, ["filters", "actions", "is-action-dropdown-active", "selected-filters", "onSelect", "onRemove", "onFilterRemove", "onClickButton", "onClickOutside", "onChangeList"]),
              _createVNode(_component_my_deal_list, {
                class: "history__my-deal-list",
                "my-deals": _ctx.filteredMyDeals,
                "display-header": _ctx.myDeals.length > 0,
                locale: _ctx.userProfile.locale,
                "color-reversed": 
                _ctx.userProfile.services.esquare.userSetting.colorReversed
              
              }, null, 8, ["my-deals", "display-header", "locale", "color-reversed"])
            ])
          ]),
          _: 1
        }, 8, ["tab-list", "onTabActivate"]),
        (
          (_ctx.isIndicationTabActive && _ctx.isFetchingMyIndications) ||
            (_ctx.isDealTabActive && _ctx.isFetchingMyDeals)
        )
          ? (_openBlock(), _createBlock(_component_centered_loading_content, {
              key: 0,
              type: "grayscale"
            }))
          : (
          (_ctx.isIndicationTabActive &&
            !_ctx.isFetchingMyIndications &&
            _ctx.filteredMyIndications.length === 0) ||
            (_ctx.isDealTabActive &&
              !_ctx.isFetchingMyDeals &&
              _ctx.filteredMyDeals.length === 0)
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('trading.message.noHistory')), 1))
            : _createCommentVNode("", true)
      ]),
      _createVNode(_component_base_modal, {
        name: _ctx.myIndicationDetailModalName,
        title: _ctx.$t('trading.label.orderDetail'),
        width: 1200
      }, {
        default: _withCtx(() => [
          _createVNode(_component_my_indication_detail, {
            "my-indication": _ctx.myIndication,
            onCancel: _ctx.onCancel
          }, null, 8, ["my-indication", "onCancel"])
        ]),
        _: 1
      }, 8, ["name", "title"]),
      _createVNode(_component_confirmation_dialog, {
        name: 'deleteMyIndication',
        title: _ctx.$t('trading.message.deleteMyIndicationModalTitle'),
        "confirm-button-label": _ctx.$t('common.label.delete'),
        "confirm-button-color": 'danger',
        loading: _ctx.deleteSubmitting,
        onConfirm: _ctx.onDeleteConfirm,
        onCancel: _ctx.onDeleteCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('trading.message.deleteMyIndicationMessage')), 1)
        ]),
        _: 1
      }, 8, ["title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title"]))
}