import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25098bfd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-list" }
const _hoisted_2 = { class: "channel-list__list" }
const _hoisted_3 = { class: "channel-list__list__content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "channel-list__member-list"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "channel-list__member-list"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  key: 0,
  class: "channel-list__member-list"
}
const _hoisted_10 = {
  key: 0,
  class: "channel-list__separator"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "channel-list__member-list"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "channel-list__member-list"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "channel-list__header-d-m" }
const _hoisted_17 = {
  key: 0,
  class: "channel-list__member-list"
}
const _hoisted_18 = {
  key: 0,
  class: "channel-list__create-message-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_angle = _resolveComponent("angle")!
  const _component_heading = _resolveComponent("heading")!
  const _component_channel_organization = _resolveComponent("channel-organization")!
  const _component_channel_d_m = _resolveComponent("channel-d-m")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_create_message_button = _resolveComponent("create-message-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_ctx.hasUnreadStarredChannels)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "channel-list__list-title",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleUnreadStarredChannels && _ctx.toggleUnreadStarredChannels(...args)))
                }, [
                  _createVNode(_component_angle, {
                    class: "channel-list__angle",
                    "is-opened": _ctx.unreadStarredChannelsOpened,
                    onClick: _ctx.toggleUnreadStarredChannels
                  }, null, 8, ["is-opened", "onClick"]),
                  _createVNode(_component_heading, {
                    type: "header5",
                    class: "channel-list__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('chat.label.starredChannels')), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.unreadStarredChannelsOpened)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unreadChannelList.starredOrganizationChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_organization, _mergeProps({
                          key: `unread-starred-organization-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unreadChannelList.starredDMChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_d_m, _mergeProps({
                          key: `unread-starred-dm-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasUnreadOrganizationChannels)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "channel-list__list-title",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleUnreadOrganizationChannels && _ctx.toggleUnreadOrganizationChannels(...args)))
                }, [
                  _createVNode(_component_angle, {
                    class: "channel-list__angle",
                    "is-opened": _ctx.unreadOrganizationChannelsOpened,
                    onClick: _ctx.toggleUnreadOrganizationChannels
                  }, null, 8, ["is-opened", "onClick"]),
                  _createVNode(_component_heading, {
                    type: "header5",
                    class: "channel-list__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('chat.label.organizationChannels')), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.unreadOrganizationChannelsOpened)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unreadChannelList.organizationChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_organization, _mergeProps({
                          key: `unread-organization-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasUnreadDMChannels)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    class: "channel-list__list-title",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleUnreadDMChannels && _ctx.toggleUnreadDMChannels(...args)))
                  }, [
                    _createVNode(_component_angle, {
                      class: "channel-list__angle",
                      "is-opened": _ctx.unreadDMChannelsOpened,
                      onClick: _ctx.toggleUnreadDMChannels
                    }, null, 8, ["is-opened", "onClick"]),
                    _createVNode(_component_heading, {
                      type: "header5",
                      class: "channel-list__title"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('chat.label.dMChannels')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                (_ctx.unreadDMChannelsOpened)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unreadChannelList.dMChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_d_m, _mergeProps({
                          key: `unread-dm-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasUnreadChannels && _ctx.hasReadChannels)
          ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.hasReadStarredChannels)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: "channel-list__list-title",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleReadStarredChannels && _ctx.toggleReadStarredChannels(...args)))
                }, [
                  _createVNode(_component_angle, {
                    class: "channel-list__angle",
                    "is-opened": _ctx.readStarredChannelsOpened,
                    onClick: _ctx.toggleReadStarredChannels
                  }, null, 8, ["is-opened", "onClick"]),
                  _createVNode(_component_heading, {
                    type: "header5",
                    class: "channel-list__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('chat.label.starredChannels')), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.readStarredChannelsOpened)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readChannelList.starredOrganizationChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_organization, _mergeProps({
                          key: `read-starred-organization-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readChannelList.starredDMChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_d_m, _mergeProps({
                          key: `read-starred-dm-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasReadOrganizationChannels)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", {
                  class: "channel-list__list-title",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleReadOrganizationChannels && _ctx.toggleReadOrganizationChannels(...args)))
                }, [
                  _createVNode(_component_angle, {
                    class: "channel-list__angle",
                    "is-opened": _ctx.readOrganizationChannelsOpened,
                    onClick: _ctx.toggleReadOrganizationChannels
                  }, null, 8, ["is-opened", "onClick"]),
                  _createVNode(_component_heading, {
                    type: "header5",
                    class: "channel-list__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('chat.label.organizationChannels')), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.readOrganizationChannelsOpened)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readChannelList.organizationChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_organization, _mergeProps({
                          key: `read-organization-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasReadDMChannels)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    class: "channel-list__list-title",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleReadDMChannels && _ctx.toggleReadDMChannels(...args)))
                  }, [
                    _createVNode(_component_angle, {
                      class: "channel-list__angle",
                      "is-opened": _ctx.readDMChannelsOpened,
                      onClick: _ctx.toggleReadDMChannels
                    }, null, 8, ["is-opened", "onClick"]),
                    _createVNode(_component_heading, {
                      type: "header5",
                      class: "channel-list__title"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('chat.label.dMChannels')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.displayOpenDMListButton)
                    ? (_openBlock(), _createBlock(_component_base_tooltip, {
                        key: 0,
                        label: _ctx.$t('chat.message.openDMChannel'),
                        direction: "left"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: "channel-list__open-channel-button-d-m",
                            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClickOpenDMChannelButton && _ctx.onClickOpenDMChannelButton(...args)))
                          }, [
                            _createVNode(_component_base_icon, { "icon-name": "plus" })
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.readDMChannelsOpened)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readChannelList.dMChannels, (channel, index) => {
                        return (_openBlock(), _createBlock(_component_channel_d_m, _mergeProps({
                          key: `read-dm-${index}`
                        }, channel, {
                          selected: _ctx.displaysSelected ? channel.selected : false,
                          onClick: _ctx.onClick
                        }), null, 16, ["selected", "onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.displayCreateMessageButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createVNode(_component_create_message_button, { onClick: _ctx.onCreateMessageButtonClick }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}