import {
  IndicationStatusType,
  NewStandardIndication,
  SpreadTypeTypeEnum,
  UpdateStandardIndication,
} from '@/api/generated'
import { BrokerIndicationFormProps } from '@/components/trading/interface/BrokerIndicationFormProps'
import {
  buildIndicationBasePayload,
  buildIndicationExchangePayload,
} from '@/components/trading/services/baseIndicationPayloadBuilder'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'

export function buildNewStandardIndicationPayload({
  formValue,
  selectableInputs,
}: {
  formValue: BrokerIndicationFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
}): (NewStandardIndication & { memo?: string }) | undefined {
  if (!formValue.organization) {
    return
  }
  const base = buildIndicationBasePayload({ formValue, selectableInputs })
  if (!base) {
    return
  }
  const exchange = buildIndicationExchangePayload({
    formValue,
    selectableInputs,
  })
  if (formValue.price === undefined) {
    return
  }
  return {
    productTypeIds: formValue.productTypeIds,
    base,
    exchange,
    position: formValue.position,
    price: {
      type: formValue.price.type,
      energyUnitPrices: formValue.price.energyUnitPrices.map(unitPrice =>
        Number(unitPrice),
      ),
      basicUnitPrice:
        formValue.price.basicUnitPrice !== undefined
          ? {
              type: formValue.price.basicUnitPrice.type,
              unitPrice: Number(formValue.price.basicUnitPrice.unitPrice),
            }
          : undefined,
    },
    fuelSurchargeTypeId: formValue.fuelSurchargeTypeId,
    request: formValue.request,
    isVisibleToTraders: formValue.isVisibleToTraders,
    openToMarket: formValue.openToMarket,
    brokerId: formValue.broker?.value,
    traderId: formValue.trader?.value,
    organizationId: formValue.organization.organizationId,
    excludedEnecCurve: formValue.excludedEnecCurve,
    publicInformation: formValue.publicInformation,
    maskVolume: formValue.maskVolume ?? false,
    memo: formValue.memo,
  }
}

export function buildUpdateStandardIndicationPayload({
  formValue,
  selectableInputs,
  spreadTypes,
}: {
  formValue: BrokerIndicationFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
  spreadTypes: SpreadTypeTypeEnum[]
}): UpdateStandardIndication | undefined {
  if (!formValue.organization) {
    return
  }
  const newStandardIndicationPayload = buildNewStandardIndicationPayload({
    formValue,
    selectableInputs,
  })
  const exchange =
    spreadTypes.length === 0 ? null : newStandardIndicationPayload?.exchange
  return (
    newStandardIndicationPayload && {
      ...newStandardIndicationPayload,
      exchange,
      status: Object.values(IndicationStatusType).find(
        type => type === formValue.status?.value,
      ),
    }
  )
}
