import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { DealsApi, UpdateDeal } from '@/api/generated'
import { DealSummary } from '@/models/trading/DealSummary'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { DeliverySummaries } from '@/models/trading/DeliverySummaries'
import { TradingSteps } from '@/models/trading/TradingSteps'
import { DealGetters } from '@/store/modules/trading/deal/DealGetters'
import { DealMutations } from '@/store/modules/trading/deal/DealMutations'
import { DealState } from '@/store/modules/trading/deal/DealState'

export class DealActions extends Actions<
  DealState,
  DealGetters,
  DealMutations,
  DealActions
> {
  async fetchDealSummaries({
    lastId,
    startDeliveryYearMonth,
    endDeliveryYearMonth,
    organizationName,
    areaId,
    hourTypeId,
    productTypeId,
    privateConfidentialOfFilter,
    publicNegotiationId,
    executedDate,
  }: {
    lastId?: string
    startDeliveryYearMonth?: string
    endDeliveryYearMonth?: string
    organizationName?: string
    areaId?: string
    hourTypeId?: string
    productTypeId?: string
    privateConfidentialOfFilter?: boolean
    publicNegotiationId?: string
    executedDate?: string
  }) {
    return plainToClass(
      DealSummary,
      (
        await new DealsApi(apiConfig).getDealSummaries(
          lastId,
          organizationName,
          areaId,
          hourTypeId,
          productTypeId,
          publicNegotiationId,
          startDeliveryYearMonth,
          endDeliveryYearMonth,
          privateConfidentialOfFilter,
          executedDate,
        )
      ).data,
    )
  }

  async fetchDealWithOrganizationRecap(id: string) {
    return plainToClass(
      DealWithOrganizationRecap,
      (await new DealsApi(apiConfig).getDeal(id)).data,
    )
  }

  async fetchDeliverySummaries(id: string): Promise<DeliverySummaries> {
    return plainToClass(
      DeliverySummaries,
      (await new DealsApi(apiConfig).getDealDeliverySummary(id)).data,
    )
  }

  async fetchTradingSteps(id: string): Promise<TradingSteps> {
    return plainToClass(
      TradingSteps,
      (await new DealsApi(apiConfig).getDealTradingSteps(id)).data,
    )
  }

  async updateDealWithOrganizationRecap({
    id,
    updateDeal,
  }: {
    id: string
    updateDeal: UpdateDeal
  }): Promise<DealWithOrganizationRecap> {
    return plainToClass(
      DealWithOrganizationRecap,
      (await new DealsApi(apiConfig).updateDeal(id, updateDeal)).data,
    )
  }
}
