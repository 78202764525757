import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_assembly = _resolveComponent("chat-assembly")!
  const _component_new_message = _resolveComponent("new-message")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_channel_select = _resolveComponent("channel-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_chat_assembly, {
      ref: "chatAssembly",
      "channel-list-window": _ctx.cachedChannelListWindow,
      "channel-message-windows": _ctx.channelMessageWindowList,
      "display-create-message-button": _ctx.displayCreateMessageButton,
      "display-open-d-m-list-button": _ctx.isOpenDMListButtonDisplayed,
      "is-fetching-channel-list": _ctx.isFetchingChannelList,
      "is-sending": _ctx.isSending,
      onChannelListWindowTitleBarClick: _ctx.toggleChannelListWindow,
      onChannelSelect: _ctx.onChannelSelect,
      onClose: _ctx.onClose,
      onInput: _ctx.onInput,
      onTyping: _ctx.onTyping,
      onSend: _ctx.onSend,
      onChannelMessageWindowTitleBarClick: 
        _ctx.onChannelMessageWindowTitleBarClick
      ,
      onTouchStart: _ctx.onTouchStart,
      onCreateMessageButtonClick: _ctx.onCreateMessageButtonClick,
      onReadMessage: _ctx.onReadMessage,
      onOpenDmChannel: _ctx.onOpenDMChannel
    }, null, 8, ["channel-list-window", "channel-message-windows", "display-create-message-button", "display-open-d-m-list-button", "is-fetching-channel-list", "is-sending", "onChannelListWindowTitleBarClick", "onChannelSelect", "onClose", "onInput", "onTyping", "onSend", "onChannelMessageWindowTitleBarClick", "onTouchStart", "onCreateMessageButtonClick", "onReadMessage", "onOpenDmChannel"]),
    _createVNode(_component_base_modal, {
      name: _ctx.modalName,
      title: _ctx.$t('chat.message.newMessageModalTitle'),
      onClosed: _ctx.resetChatInput
    }, {
      default: _withCtx(() => [
        _createVNode(_component_new_message, {
          "organization-channels": _ctx.recipientList,
          "search-input-value": _ctx.recipientSearchInput,
          "chat-input-value": _ctx.message,
          locked: false,
          "selected-language": _ctx.recipientSelectedLanguage,
          "is-sending": _ctx.isSending,
          onCancelButtonClick: _ctx.onCancelButtonClick,
          onSelectedListChange: _ctx.onSelectedListChange,
          onLanguageInput: _ctx.onRecipientSelectedLanguage,
          onSearchInput: _ctx.onRecipientSearchInput,
          onChatInput: _ctx.onNewChatInput,
          onSend: _ctx.onSendNewMessage
        }, null, 8, ["organization-channels", "search-input-value", "chat-input-value", "selected-language", "is-sending", "onCancelButtonClick", "onSelectedListChange", "onLanguageInput", "onSearchInput", "onChatInput", "onSend"])
      ]),
      _: 1
    }, 8, ["name", "title", "onClosed"]),
    _createVNode(_component_base_modal, {
      name: "newDMChannelModalInWindow",
      title: _ctx.$t('chat.message.openChannel')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_channel_select, {
          "channel-list": _ctx.cachedChannelList.dMChannels,
          onClickChannel: _ctx.onClickChannel,
          onCancel: _ctx.onCancel
        }, null, 8, ["channel-list", "onClickChannel", "onCancel"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}