import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf460dae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-indication-list" }
const _hoisted_2 = {
  key: 0,
  class: "my-indication-list__header-wrapper"
}
const _hoisted_3 = { class: "my-indication-list__body-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_indication_list_header = _resolveComponent("my-indication-list-header")!
  const _component_my_indication_component = _resolveComponent("my-indication-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_my_indication_list_header)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myIndications, (myIndication) => {
        return (_openBlock(), _createBlock(_component_my_indication_component, {
          key: myIndication.indicationId,
          "my-indication": myIndication,
          "member-profiles": _ctx.memberProfiles,
          locale: _ctx.locale,
          "is-eclear-enabled": _ctx.isEclearEnabled,
          "color-reversed": _ctx.colorReversed,
          onSelect: ($event: any) => (_ctx.onSelect(myIndication.indicationId)),
          onActivate: ($event: any) => (_ctx.onActivate(myIndication.indicationId)),
          onDeactivate: ($event: any) => (_ctx.onDeactivate(myIndication.indicationId)),
          onDelete: ($event: any) => (_ctx.onDelete(myIndication.indicationId))
        }, null, 8, ["my-indication", "member-profiles", "locale", "is-eclear-enabled", "color-reversed", "onSelect", "onActivate", "onDeactivate", "onDelete"]))
      }), 128))
    ])
  ]))
}