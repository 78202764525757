
import { camelize } from 'humps'
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import { NegotiationFormInputMode } from '@/components/trading/constants/NegotiationFormInputMode'
import { NegotiationFormProps } from '@/components/trading/interface/NegotiationFormProps'
import { Product } from '@/models/trading/Product'

type DatasetProps = {
  label: string
  data: string | undefined
}

export default defineComponent({
  name: 'NegotiationConfirmModalContent',
  components: {
    BaseBox,
    BaseDataset,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: NegotiationFormInputMode) => {
        return [
          NegotiationFormInputMode.NEW,
          NegotiationFormInputMode.UNDER_NEGOTIATION,
          NegotiationFormInputMode.EXECUTED,
          NegotiationFormInputMode.BROKEN,
        ].includes(value)
      },
    },
    formValue: {
      type: Object as PropType<NegotiationFormProps>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    isStandardSpread: {
      type: Boolean,
      default: false,
    },
    isSwingOption: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEditMode(): boolean {
      return NegotiationFormInputMode.NEW !== this.mode
    },
    productBlockItems(): DatasetProps[] {
      return this.isStandardSpread
        ? [
            {
              label: `${this.$t('trading.label.area')} (${this.$t(
                'trading.label.baseSpreadIndication',
              )})`,
              data: this.product.area.translation(),
            },
            {
              label: `${this.$t('trading.label.area')} (${this.$t(
                'trading.label.exchangeSpreadIndication',
              )})`,
              data: this.product.exchangeDeliveryTerms?.areaName(),
            },
          ]
        : [
            {
              label: this.$t('trading.label.area').toString(),
              data: this.product.area.translation(),
            },
            ...(this.product.hasFuelSurcharge()
              ? [
                  {
                    label: this.$t(
                      'trading.label.fuelSurchargeType',
                    ).toString(),
                    data: this.formValue.askIndication?.fuelSurchargeType?.translation(),
                  },
                ]
              : []),
          ]
    },
    negotiationBlockItemsList(): DatasetProps[][] {
      return this.negotiationRows.map(row => {
        return this.isStandardSpread
          ? [
              {
                label: `${this.$t('trading.label.period')}  (${this.$t(
                  'trading.label.baseSpreadIndication',
                )})`,
                data: `${row.base.deliveryStartDate} - ${row.base.deliveryEndDate}`,
              },
              {
                label: `${this.$t('trading.label.period')}  (${this.$t(
                  'trading.label.exchangeSpreadIndication',
                )})`,
                data: `${row.exchange?.deliveryStartDate} - ${row.exchange?.deliveryEndDate}`,
              },
              {
                label: `${this.$t(
                  'trading.label.deliveryPatternComponent',
                )} (${this.$t('trading.label.baseSpreadIndication')})`,
                data: row.base.hourTypeNamesWithTimeRanges,
              },
              {
                label: `${this.$t(
                  'trading.label.deliveryPatternComponent',
                )} (${this.$t('trading.label.exchangeSpreadIndication')})`,
                data: row.exchange?.hourTypeNamesWithTimeRanges,
              },
              {
                label: `${this.$t('trading.label.volume')}  (${this.$t(
                  'trading.label.baseSpreadIndication',
                )})`,
                data: this.$t('trading.label.volumeWithUnit', {
                  volume: row.base.askAfterVolume,
                }).toString(),
              },
              {
                label: `${this.$t('trading.label.volume')}  (${this.$t(
                  'trading.label.exchangeSpreadIndication',
                )})`,
                data: this.$t('trading.label.volumeWithUnit', {
                  volume: row.exchange?.askAfterVolume,
                }).toString(),
              },
              {
                label: `${this.$t('trading.label.spreadBasePrice')}`,
                data: this.$t('trading.label.unitPriceWithUnit', {
                  unitPrice: this.formValue.spreadBasePrice,
                }).toString(),
              },
              {
                label: this.$t('trading.label.spreadPrice').toString(),
                data: this.$t('trading.label.unitPriceWithUnit', {
                  unitPrice: this.formValue.spreadUnitPrice,
                }).toString(),
              },
            ]
          : [
              {
                label: this.$t('trading.label.period').toString(),
                data: `${row.base.deliveryStartDate} - ${row.base.deliveryEndDate}`,
              },
              {
                label: this.$t(
                  'trading.label.deliveryPatternComponent',
                ).toString(),
                data: row.base.hourTypeNamesWithTimeRanges,
              },
              {
                label: this.$t('trading.label.volume').toString(),
                data: this.$t('trading.label.volumeWithUnit', {
                  volume: row.base.askAfterVolume,
                }).toString(),
              },
              {
                label: this.$t('trading.label.unitPrice').toString(),
                data: this.$t('trading.label.unitPriceWithUnit', {
                  unitPrice: row.base.askAfterUnitPrice,
                }).toString(),
              },
            ]
      })
    },
    otherBlockItems(): DatasetProps[] {
      if (this.isStandardSpread) {
        return [
          {
            label: `${this.$t('trading.label.calendarType')} (${this.$t(
              'trading.label.baseSpreadIndication',
            )})`,
            data: this.formValue.calendarType?.label,
          },
          {
            label: `${this.$t('trading.label.calendarType')} (${this.$t(
              'trading.label.exchangeSpreadIndication',
            )})`,
            data: this.formValue.exchangeCalendarType?.label,
          },
        ]
      }

      if (this.isSwingOption) {
        return [
          {
            label: this.$t('trading.label.calendarType').toString(),
            data: this.formValue.calendarType?.label,
          },
          {
            label: this.$t('trading.label.basicUnitPrice').toString(),
            data: this.$t('trading.label.basicUnitPriceWithUnit', {
              basicUnitPrice: this.formValue.askAfterPrice?.basicUnitPrice
                ?.unitPrice,
              unit: this.$t('trading.label.basicUnitPriceUnit.jpyPerKw'),
            }),
          },
        ]
      }

      return [
        {
          label: this.$t('trading.label.calendarType').toString(),
          data: this.formValue.calendarType?.label,
        },
      ]
    },
    statusValue(): string | undefined {
      return this.formValue.status
        ? this.$t(
            `trading.label.negotiationStatusType.${camelize(
              this.formValue.status,
            )}`,
          ).toString()
        : undefined
    },
    spreadBasePriceValue(): string {
      return this.formValue.spreadBasePrice
        ? this.$t('trading.label.priceWithUnit', {
            price: this.formValue.spreadBasePrice,
          }).toString()
        : this.$t('common.label.blank').toString()
    },
    negotiationRows(): {
      base: {
        areaName: string
        deliveryStartDate: string
        deliveryEndDate: string
        hourTypeNamesWithTimeRanges: string
        askAfterUnitPrice: string
        askAfterVolume: string | null
        bidAfterUnitPrice: string
        bidAfterVolume: string | null
      }
      exchange?: {
        areaName: string
        deliveryStartDate: string
        deliveryEndDate: string
        hourTypeNamesWithTimeRanges: string
        askAfterVolume: string | null
        bidAfterVolume: string | null
      }
    }[] {
      return this.product.deliveryTerms.deliveryPeriods.map(
        (deliveryPeriod, index) => {
          const deliveryStartDate = deliveryPeriod.displayDeliveryStartDate()
          const deliveryEndDate = deliveryPeriod.displayDeliveryEndDate()
          const hourTypeNamesWithTimeRanges = deliveryPeriod.deliveryPattern.displayHourTypeNamesWithTimeRanges()
          const exchangeDeliveryTerms = this.product.exchangeDeliveryTerms
          // deliveryPeriods と以下の配列の長さは常に同一という前提がある
          const exchange =
            exchangeDeliveryTerms !== undefined
              ? {
                  areaName: exchangeDeliveryTerms.area.translation(),
                  deliveryStartDate: exchangeDeliveryTerms.deliveryPeriods[
                    index
                  ].displayDeliveryStartDate(),
                  deliveryEndDate: exchangeDeliveryTerms.deliveryPeriods[
                    index
                  ].displayDeliveryEndDate(),
                  hourTypeNamesWithTimeRanges: exchangeDeliveryTerms.deliveryPeriods[
                    index
                  ].deliveryPattern.displayHourTypeNamesWithTimeRanges(),
                  askAfterVolume: (this.formValue.askAfterExchangeVolumes ||
                    [])[index],
                  bidAfterVolume: (this.formValue.bidAfterExchangeVolumes ||
                    [])[index],
                }
              : undefined
          return {
            base: {
              areaName: this.product.area.translation(),
              deliveryStartDate,
              deliveryEndDate,
              hourTypeNamesWithTimeRanges,
              askAfterUnitPrice: this.formValue.askAfterUnitPrices[index],
              askAfterVolume: this.formValue.askAfterVolumes[index],
              bidAfterUnitPrice: this.formValue.bidAfterUnitPrices[index],
              bidAfterVolume: this.formValue.bidAfterVolumes[index],
            },
            exchange,
          }
        },
      )
    },
    brokingFeeLabel(): string {
      return this.isStandardSpread
        ? `${this.$t('trading.label.brokingFee').toString()} (${this.$t(
            'trading.label.baseSpreadIndication',
          ).toString()})`
        : this.$t('trading.label.brokingFee').toString()
    },
    exchangeBrokingFeeLabel(): string {
      return `${this.$t('trading.label.brokingFee').toString()} (${this.$t(
        'trading.label.exchangeSpreadIndication',
      ).toString()})`
    },
  },
})
