
import { defineComponent, PropType } from 'vue'

import {
  Locale,
  IndicationStatusType as IndicationStatusTypeEnum,
} from '@/api/generated'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { TooltipDirections } from '@/components/common/constants/TooltipDirections'
import BrokingIndicationStatusChanger from '@/components/trading/BrokingIndicationStatusChanger.vue'
import BrokingOrderBookCommand from '@/components/trading/BrokingOrderBookCommand.vue'
import OrderBookPrice from '@/components/trading/OrderBookPrice.vue'
import ProductType from '@/components/trading/ProductType.vue'
import PublicInformation from '@/components/trading/PublicInformation.vue'
import { BrokingCommandTypeValue } from '@/components/trading/constants/BrokingCommandType'
import { BrokingOrderBookDetailProps } from '@/components/trading/interface/BrokingOrderBookDetailProps'
import { UnitPriceAndVolumeFormProps } from '@/components/trading/interface/UnitPriceAndVolumeFormProps'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'

export default defineComponent({
  name: 'BrokingOrderBookDetailItem',
  components: {
    BaseTooltip,
    BrokingIndicationStatusChanger,
    BrokingOrderBookCommand,
    OrderBookPrice,
    ProductType,
    PublicInformation,
  },
  props: {
    item: {
      type: Object as PropType<BrokingOrderBookDetailProps>,
      required: true,
    },
    isFirstChild: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    statusTypes: {
      type: Array as PropType<IndicationStatusType[]>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    targetIndicationId: {
      type: String,
      default: undefined,
    },
  },
  emits: [
    'targeting',
    'activate',
    'deactivate',
    'open-to-market-change',
    'visible-to-traders-change',
    'update-indication',
    'change',
    'record-select',
    'new-message',
    'copy-clipboard',
    'copy-indication',
  ],
  data(): {
    isEditing: boolean
    tooltipDirection: typeof TooltipDirections[number]
  } {
    return {
      isEditing: false,
      tooltipDirection: 'downward',
    }
  },
  computed: {
    isTarget(): boolean {
      return (
        !!this.item.indicationId &&
        this.targetIndicationId === this.item.indicationId
      )
    },
    fuelSurchargeName(): string | undefined {
      return this.item.fuelSurchargeType?.name.translation()
    },
    productTypeName(): string {
      return this.item.product.productType.translation()
    },
    isOrderBookPriceEditable(): boolean {
      const editableStatus: (IndicationStatusTypeEnum | undefined)[] = [
        IndicationStatusTypeEnum.Unconfirmed,
        IndicationStatusTypeEnum.Active,
      ]
      return (
        !this.item.isEclearIndication &&
        (this.isEditMode || this.isEditing) &&
        editableStatus.includes(this.item.status)
      )
    },
    editorialInformation(): string {
      return `${this.$t('trading.label.updatedAt').toString()}: ${
        this.item.updatedAt
      } ${this.item.lastUpdatedParticipantName}\n${this.$t(
        'trading.label.createdAt',
      ).toString()}: ${this.item.createdAt} ${this.item.createdParticipantName}`
    },
    isIndication(): boolean {
      return this.item.commandType === BrokingCommandTypeValue.Indication
    },
    displayOrganizationName(): string {
      if (this.item.organizationAbbreviation !== '') {
        return this.item.organizationAbbreviation as string
      }
      return this.item.organizationName || ''
    },
  },
  watch: {
    isEditMode() {
      if (!this.isEditMode) {
        this.isEditing = false
      }
    },
    submitting() {
      if (!this.submitting) {
        this.isEditing = false
      }
    },
  },
  methods: {
    directDispatchEvent() {
      document.dispatchEvent(new Event('click'))
    },
    onChange(formValue: Partial<UnitPriceAndVolumeFormProps>) {
      this.$emit('change', {
        ...formValue,
        indicationId: this.item.indicationId,
      })
      this.$emit('targeting')
    },
    onRecordSelect() {
      this.$emit('record-select', this.item.indicationId)
    },
    onNewMessage() {
      this.$emit('new-message', this.item.indicationId)
    },
    onCopyClipboard() {
      this.$emit('copy-clipboard', this.item.indicationId)
    },
    onCopyIndication() {
      this.$emit('copy-indication', this.item.indicationId)
    },
    onFocus() {
      this.$emit('targeting', this.item.indicationId)
    },
    onUnitPriceAndVolumeClick() {
      this.isEditing = true
    },
  },
})
