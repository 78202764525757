
import { plainToClass } from 'class-transformer'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { ClearingInformation } from '@/models/iam/ClearingInformation'
import { EexClearingHouse } from '@/models/trading/EexClearingHouse'
import { ClearingInformationModule } from '@/store/modules/iam/clearingInformation'

const { mapActions: clearingInformationActions } = createNamespacedHelpers(
  'clearingInformation',
) as ClearingInformationModule

export default defineComponent({
  name: 'EexClearingHouseSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    eexClearingFirmNames: string[]
    uiStack: UiStack
    alertTypeInfo: AlertType
    alertTypeError: AlertType
  } {
    return {
      eexClearingFirmNames: [],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
    }
  },
  async created() {
    await this.fetchClearingInformation(this.organizationId)
      .then(clearingInformation => {
        this.eexClearingFirmNames = clearingInformation.eexClearingFirmNames
        this.uiStack =
          this.eexClearingFirmNames.length > 0 ? UiStack.Ideal : UiStack.Blank
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    fetchClearingInformation: clearingInformationActions([
      'fetchClearingInformation',
    ]).fetchClearingInformation as (
      organizationId: string,
    ) => Promise<ClearingInformation>,
    onSelect(eexClearingFirmName: string) {
      this.$emit(
        'select',
        plainToClass(EexClearingHouse, {
          name: eexClearingFirmName,
        }),
      )
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
})
