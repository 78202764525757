import { apiConfig } from '@/api'

export const arrayBuffer2JpegDataUrl = (buffer: ArrayBuffer): string => {
  const blob = new Blob([buffer], { type: 'image/jpeg' })
  return URL.createObjectURL(blob)
}

export const dataUrl2Base64 = (dataUrl: string): string => {
  return dataUrl.substring(dataUrl.indexOf(',') + 1)
}

export const isRequiredAuthenticatedRequest = (pictureUrl: string): boolean => {
  return (
    pictureUrl.includes(apiConfig.basePath) ||
    (process.env.VUE_APP_OLD_API_URL !== undefined &&
      pictureUrl.includes(process.env.VUE_APP_OLD_API_URL))
  )
}
