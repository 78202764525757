
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import HorizontalTab from '@/components/common/HorizontalTab.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'
import OrganizationHeadline from '@/components/iam/OrganizationHeadline.vue'
import { OrganizationProfileModule } from '@/store/modules/iam/organizationProfile'
import { OrganizationsModule } from '@/store/modules/iam/organizations'

const {
  mapState: organizationProfileMapState,
  mapActions: organizationProfileMapActions,
} = createNamespacedHelpers('organizationProfile') as OrganizationProfileModule
const { mapActions: organizationMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

const ECLOUD_ORGANIZATION_TAB_INDEXES = {
  MEMBERS: 0,
  DETAIL: 1,
}

export default defineComponent({
  name: 'EcloudOrganization',
  components: {
    BaseAlert,
    BaseModal,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    HorizontalTab,
    OrganizationHeadline,
    UiStackSelector,
  },
  props: {
    basePath: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data(): {
    organizationSubmitting: boolean
    uiStack: UiStack
    errorType: AlertType
  } {
    return {
      organizationSubmitting: false,
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
    }
  },
  computed: {
    ...organizationProfileMapState([
      'organizationProfile',
      'changePictureSubmitting',
      'deletePictureSubmitting',
    ]),
    titleKey(): string {
      return (this.$route.meta?.titleKey as string | undefined) || ''
    },
    activeMenuItem(): string | undefined {
      return this.$route.meta?.activeMenuItem as string | undefined
    },
    tabShown(): boolean {
      return !!this.$route.meta?.tabShown
    },
    tabList(): HorizontalTabItem[] {
      return [
        {
          label: this.$t('iam.label.members').toString(),
          to: `${this.basePath}/members`,
        },
        {
          label: this.$t(
            'iam.label.detailedEcloudOrganizationProfile',
          ).toString(),
          to: this.basePath,
        },
      ]
    },
    currentTabIndex(): number {
      switch (this.$route.name) {
        case 'EcloudMembers':
          return ECLOUD_ORGANIZATION_TAB_INDEXES.MEMBERS
        case 'EcloudOrganizationDetail':
        default:
          return ECLOUD_ORGANIZATION_TAB_INDEXES.DETAIL
      }
    },
  },
  async created() {
    await this.fetchOrganizationProfile({
      organizationId: this.organizationId,
    })
      .then(() => {
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...organizationProfileMapActions(['fetchOrganizationProfile']),
    ...organizationMapActions(['updateOrganization']),
  },
})
