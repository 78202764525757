import { Module } from 'vuex-smart-module'

import { PublicOrganizationProfileActions } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileActions'
import { PublicOrganizationProfileGetters } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileGetters'
import { PublicOrganizationProfileMutations } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileMutations'
import { PublicOrganizationProfileState } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileState'

export const publicOrganizationProfile = new Module({
  state: PublicOrganizationProfileState,
  getters: PublicOrganizationProfileGetters,
  mutations: PublicOrganizationProfileMutations,
  actions: PublicOrganizationProfileActions,
})

export type PublicOrganizationProfileModule = typeof publicOrganizationProfile
