import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a25229a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "indication-selector-modal-list-item__inner" }
const _hoisted_2 = { class: "indication-selector-modal-list-item__delivery-unit-icon" }
const _hoisted_3 = { class: "indication-selector-modal-list-item__ellipsis" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "indication-selector-modal-list-item__number-column" }
const _hoisted_6 = { class: "indication-selector-modal-list-item__number-column" }
const _hoisted_7 = { class: "indication-selector-modal-list-item__ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_position_icon = _resolveComponent("position-icon")!
  const _component_delivery_unit_icon = _resolveComponent("delivery-unit-icon")!
  const _component_delivery_terms_with_period = _resolveComponent("delivery-terms-with-period")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: "indication-selector-modal-list-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelect(_ctx.indication)))
  }, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_position_icon, {
              class: "indication-selector-modal-list-item__position-icon",
              position: _ctx.indication.position,
              "color-reversed": _ctx.colorReversed
            }, null, 8, ["position", "color-reversed"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_delivery_unit_icon, {
              "delivery-unit": _ctx.indication.products[0].deliveryUnit
            }, null, 8, ["delivery-unit"])
          ]),
          _createVNode(_component_delivery_terms_with_period, { "delivery-terms": _ctx.deliveryTerms }, null, 8, ["delivery-terms"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_product_type, { name: _ctx.productTypeNames }, null, 8, ["name"])
          ]),
          _createElementVNode("div", null, _toDisplayString(_ctx.fuelSurchargeTypeName), 1),
          _createElementVNode("div", {
            class: "indication-selector-modal-list-item__ellipsis",
            title: _ctx.indication.organizationName.translation()
          }, _toDisplayString(_ctx.indication.organizationName.translation()), 9, _hoisted_4),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.unitPrice), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.volume), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.indication.displayUpdatedAt()), 1)
        ])
      ]),
      _: 1
    })
  ]))
}