import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-031ab28b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-dataset" }
const _hoisted_2 = { class: "base-dataset__label" }
const _hoisted_3 = { class: "base-dataset__data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.directionClass)
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.data)
          ? (_openBlock(), _createBlock(_component_sentences, {
              key: 0,
              type: "text-medium"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.data), 1)
              ]),
              _: 1
            }))
          : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
      ])
    ], 2)
  ]))
}