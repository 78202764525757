import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18a527bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-indication-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_icon, {
      "icon-name": _ctx.statusIcon,
      class: _normalizeClass(_ctx.statusIconClass)
    }, null, 8, ["icon-name", "class"])
  ]))
}