import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "trading-order-book-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trading_order_book_list_item = _resolveComponent("trading-order-book-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderBooks, (orderBook) => {
      return (_openBlock(), _createBlock(_component_trading_order_book_list_item, {
        key: orderBook.id,
        "order-book": orderBook,
        "color-reversed": _ctx.colorReversed,
        "position-reversed": _ctx.positionReversed,
        "is-expanded": _ctx.isExpandedOf(orderBook.id),
        onOrderButtonClick: 
        position => _ctx.$emit('order-button-click', { position, orderBook })
      ,
        onClick: ($event: any) => (_ctx.$emit('click', orderBook.id))
      }, null, 8, ["order-book", "color-reversed", "position-reversed", "is-expanded", "onOrderButtonClick", "onClick"]))
    }), 128))
  ]))
}