import { Locale } from '@/api/generated'
import { Translation } from '@/models/chat/Translation'

export class MultiLocaleMessageBody {
  translations: Translation[]

  constructor({ translations }: { translations: Translation[] }) {
    this.translations = translations
  }

  translationOf(locale: Locale): string {
    const translation = this.translations.find(translation =>
      translation.has(locale),
    )
    if (translation) {
      return translation.text
    }

    return (
      this.translations.find(translation => translation.has(Locale.Ja))?.text ||
      this.translations.find(translation => translation.has(Locale.En))?.text ||
      ''
    )
  }
}
