import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43081f1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-collapsed-navigation__header" }
const _hoisted_2 = { class: "base-collapsed-navigation__organization" }
const _hoisted_3 = ["aria-expanded", "aria-label"]
const _hoisted_4 = { "aria-label": "Page navigation" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "base-collapsed-navigation__clickable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_organization_info = _resolveComponent("organization-info")!
  const _component_base_skeleton = _resolveComponent("base-skeleton")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_nav_item = _resolveComponent("base-nav-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_nav_item_category = _resolveComponent("nav-item-category")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'base-collapsed-navigation',
      { 'base-collapsed-navigation--collapsed': _ctx.isCollapsed },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        (_ctx.isOrganizationDataFetched)
          ? (_openBlock(), _createBlock(_component_organization_info, {
              key: 0,
              picture: _ctx.organizationPicture,
              name: _ctx.organizationName
            }, null, 8, ["picture", "name"]))
          : (_openBlock(), _createBlock(_component_base_skeleton, { key: 1 }))
      ], 512), [
        [_vShow, !_ctx.isCollapsed]
      ]),
      _createElementVNode("button", {
        class: "base-collapsed-navigation__toggle__button",
        type: "button",
        "aria-expanded": !_ctx.isCollapsed || undefined,
        "aria-label": _ctx.isCollapsed ? 'Open navigation' : 'Close navigation',
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, [
        _createVNode(_component_base_icon, {
          "icon-name": _ctx.isCollapsed ? 'angle-double-right' : 'angle-double-left'
        }, null, 8, ["icon-name"])
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("nav", _hoisted_4, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item, idx) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `nav-${idx}`,
            class: _normalizeClass([
            'base-collapsed-navigation__item',
            { 'base-collapsed-navigation__item--current': item.isCurrent },
          ])
          }, [
            (item.to)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: item.to,
                  class: "base-collapsed-navigation__clickable",
                  "aria-selected": item.isCurrent || undefined,
                  tabindex: item.isCurrent ? -1 : 0
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_nav_item, {
                      "icon-name": item.icon,
                      "is-current": item.isCurrent,
                      label: item.label,
                      "is-collapsed": _ctx.isCollapsed
                    }, null, 8, ["icon-name", "is-current", "label", "is-collapsed"])
                  ]),
                  _: 2
                }, 1032, ["to", "aria-selected", "tabindex"]))
              : (item.href)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: item.href,
                    target: "_blank",
                    rel: "noopener noreferrer",
                    class: "base-collapsed-navigation__clickable"
                  }, [
                    _createVNode(_component_base_nav_item, {
                      "icon-name": item.icon,
                      "is-current": item.isCurrent,
                      label: item.label,
                      "is-collapsed": _ctx.isCollapsed,
                      "is-external": ""
                    }, null, 8, ["icon-name", "is-current", "label", "is-collapsed"])
                  ], 8, _hoisted_5))
                : (_openBlock(), _createBlock(_component_nav_item_category, {
                    key: 2,
                    categories: item.categories
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_base_nav_item, {
                          "icon-name": item.icon,
                          "is-current": item.isCurrent,
                          label: item.label,
                          "is-collapsed": _ctx.isCollapsed,
                          "has-arrow": true
                        }, null, 8, ["icon-name", "is-current", "label", "is-collapsed"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["categories"]))
          ], 2))
        }), 128))
      ])
    ])
  ], 2))
}