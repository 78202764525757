import moment from 'moment'

import {
  DeliveryUnit,
  NewStandardIndicationBase,
  NewStandardIndicationExchange,
} from '@/api/generated'
import { BrokerIndicationFormProps } from '@/components/trading/interface/BrokerIndicationFormProps'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'

type IndicationBasePayloadProps = NewStandardIndicationBase
type IndicationExchangePayloadProps = NewStandardIndicationExchange

const format = 'YYYY-MM-DD'

export function buildIndicationBasePayload({
  formValue,
  selectableInputs,
}: {
  formValue: BrokerIndicationFormProps | ProductFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
}): IndicationBasePayloadProps | undefined {
  if (!formValue.base.areaId || !formValue.base.hourTypeId) {
    return
  }

  const deliveryStartDate = (() => {
    if (DeliveryUnit.Month === formValue.deliveryUnit) {
      if (!formValue.base.startDeliveryYearMonthId) {
        return
      }
      return selectableInputs.getDeliveryStartDateByYearMonthId(
        formValue.base.startDeliveryYearMonthId,
      )
    }
    if (!formValue.base.startDeliveryDate) {
      return
    }
    return moment(formValue.base.startDeliveryDate).format(format)
  })()
  if (!deliveryStartDate) {
    return
  }

  const deliveryEndDate = (() => {
    if (DeliveryUnit.Month === formValue.deliveryUnit) {
      if (!formValue.base.endDeliveryYearMonthId) {
        return
      }
      return selectableInputs.getDeliveryEndDateByYearMonthId(
        formValue.base.endDeliveryYearMonthId,
      )
    }
    if (!formValue.base.endDeliveryDate) {
      return
    }
    return moment(formValue.base.endDeliveryDate).format(format)
  })()
  if (!deliveryEndDate) {
    return
  }

  const volume =
    formValue.base.volumes[0] !== '' ? Number(formValue.base.volumes[0]) : null

  return {
    areaId: formValue.base.areaId,
    hourTypeId: formValue.base.hourTypeId,
    deliveryStartDate,
    deliveryEndDate,
    volume,
  }
}

export function buildIndicationExchangePayload({
  formValue,
  selectableInputs,
}: {
  formValue: BrokerIndicationFormProps | ProductFormProps
  selectableInputs: BaseStandardIndicationSelectableInputs
}): IndicationExchangePayloadProps | undefined {
  if (
    !selectableInputs.isSpread ||
    !formValue.exchange ||
    !formValue.base.areaId ||
    !formValue.base.hourTypeId
  ) {
    return
  }

  const areaId = formValue.exchange.areaId || formValue.base.areaId
  const hourTypeId = formValue.exchange.hourTypeId || formValue.base.hourTypeId

  const deliveryStartDate = (() => {
    if (DeliveryUnit.Month === formValue.deliveryUnit) {
      if (!formValue.base.startDeliveryYearMonthId) {
        return
      }
      return (
        (formValue.exchange.startDeliveryYearMonthId &&
          selectableInputs.getExchangeDeliveryStartDateByYearMonthId(
            formValue.exchange.startDeliveryYearMonthId,
          )) ||
        selectableInputs.getDeliveryStartDateByYearMonthId(
          formValue.base.startDeliveryYearMonthId,
        )
      )
    }
    return formValue.exchange.startDeliveryDate
      ? moment(formValue.exchange.startDeliveryDate).format(format)
      : moment(formValue.base.startDeliveryDate).format(format)
  })()
  if (!deliveryStartDate) {
    return
  }

  const deliveryEndDate = (() => {
    if (DeliveryUnit.Month === formValue.deliveryUnit) {
      if (!formValue.base.endDeliveryYearMonthId) {
        return
      }
      return (
        (formValue.exchange.endDeliveryYearMonthId &&
          selectableInputs.getExchangeDeliveryEndDateByYearMonthId(
            formValue.exchange.endDeliveryYearMonthId,
          )) ||
        selectableInputs.getDeliveryEndDateByYearMonthId(
          formValue.base.endDeliveryYearMonthId,
        )
      )
    }
    return formValue.exchange.endDeliveryDate
      ? moment(formValue.exchange.endDeliveryDate).format(format)
      : moment(formValue.base.endDeliveryDate).format(format)
  })()
  if (!deliveryEndDate) {
    return
  }

  const volume =
    formValue.exchange.volumes[0] !== ''
      ? Number(formValue.exchange.volumes[0])
      : null

  return {
    areaId,
    hourTypeId,
    deliveryStartDate,
    deliveryEndDate,
    volume,
  }
}
