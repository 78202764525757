
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import DeliveryTermsWithPeriod from '@/components/trading/DeliveryTermsWithPeriod.vue'
import OldUnitPriceAndVolume from '@/components/trading/OldUnitPriceAndVolume.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { MyDeal } from '@/models/trading/MyDeal'
import { Product } from '@/models/trading/Product'

export default defineComponent({
  name: 'MyDeal',
  components: {
    BaseBox,
    DeliveryTermsWithPeriod,
    OldUnitPriceAndVolume,
    PositionIcon,
    ProductType,
  },
  props: {
    myDeal: {
      type: Object as PropType<MyDeal>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    counterNameTranslation(): string {
      return (
        this.myDeal.counterName?.translationOf(this.locale as Locale) || '-'
      )
    },
    myDealClass(): object {
      return { 'my-deal__inner--active': this.isActive }
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.myDeal.fuelSurchargeType?.name.translation()
    },
    product(): Product {
      return this.myDeal.product
    },
    deliveryTerms(): DeliveryTermsWithPeriodProps {
      return this.product.deliveryTermsWithPeriod
    },
  },
  methods: {
    onMouseOver() {
      this.isActive = true
    },
    onMouseLeave() {
      this.isActive = false
    },
  },
})
