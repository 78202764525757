
import { defineComponent, PropType } from 'vue'

import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { BalancingGroup } from '@/models/iam/BalancingGroup'
import { SupplyPlanSubmitter } from '@/models/iam/SupplyPlanSubmitter'
import { Area } from '@/models/trading/Area'

export default defineComponent({
  name: 'OcctoMasterItem',
  components: {
    ActionDropdown,
    BaseBox,
    BaseLink,
  },
  props: {
    areas: {
      type: Array as PropType<Area[]>,
      required: true,
    },
    item: {
      type: Object as PropType<BalancingGroup | SupplyPlanSubmitter>,
      required: true,
    },
    parentPath: {
      type: String,
      required: true,
    },
  },
  emits: ['item-click', 'delete-click'],
  data(): {
    isActionDropdownActive: boolean
    actions: ActionDropdownItem[]
    itemPath: string
  } {
    return {
      isActionDropdownActive: false,
      actions: [
        {
          label: this.$t('iam.message.deleteOcctoMasterTitle').toString(),
          eventName: 'delete',
        },
      ],
      itemPath: `${this.parentPath}/${this.item.id}`,
    }
  },
  computed: {
    areaName(): string {
      const area = this.areas.find(a => a.id === this.item.areaId)
      return area?.name.translation() || ''
    },
  },
  methods: {
    onItemClick() {
      this.$emit('item-click')
    },
    onDeleteClick() {
      this.$emit('delete-click')
    },
  },
})
