import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-049fb612"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recap-receiver-list-item__column" }
const _hoisted_2 = { class: "recap-receiver-list-item__column" }
const _hoisted_3 = { class: "recap-receiver-list-item__column" }
const _hoisted_4 = { class: "recap-receiver-list-item__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_link = _resolveComponent("base-link")!

  return (_openBlock(), _createBlock(_component_base_link, {
    class: "recap-receiver-list-item",
    path: _ctx.itemPath,
    "include-child-action": "",
    "reset-font-style": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_box, {
        spacing: "8px 16px",
        class: "recap-receiver-list-item__box"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.department), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.recapReceiver.name), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.phoneNumber), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.emailAddress), 1),
          _createVNode(_component_action_dropdown, {
            id: _ctx.dropdownId,
            actions: _ctx.actionItems,
            "is-active": _ctx.isDropdownActive,
            "is-right": "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDropdownActive = !_ctx.isDropdownActive), ["stop"])),
            onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDropdownActive = false)),
            onDeleteClick: _ctx.onDeleteClick
          }, null, 8, ["id", "actions", "is-active", "onDeleteClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["path"]))
}