
import { defineComponent, PropType } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import BaseBanner from '@/components/common/BaseBanner.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import { ActiveInformation } from '@/models/common/ActiveInformation'

export default defineComponent({
  name: 'CarouselBanner',
  components: {
    BaseBanner,
    BaseIcon,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  props: {
    informations: {
      type: Array as PropType<ActiveInformation[]>,
      default() {
        return []
      },
    },
  },
  emits: ['close'],
  data() {
    return { isHover: false }
  },
  computed: {
    autoplay(): number {
      return this.informations.length > 1 ? 5000 : 0
    },
  },
  methods: {
    onClick(event: Event) {
      this.$emit('close', event)
    },
  },
})
