import { Type } from 'class-transformer'

import { DefaultBrokingFeeOfOrganizations as IDefaultBrokingFeeOfOrganizations } from '@/api/generated'
import { i18n } from '@/i18n'
import { DefaultBrokingFeeOfOrganization } from '@/models/iam/DefaultBrokingFeeOfOrganization'
import { moment } from '@/utils/date'

export class DefaultBrokingFeeOfOrganizations
  implements IDefaultBrokingFeeOfOrganizations {
  @Type(() => DefaultBrokingFeeOfOrganization)
  defaultBrokingFees!: DefaultBrokingFeeOfOrganization[]

  lastUpdatedParticipantName?: string
  updatedAt?: string

  get displayUpdatedAt(): string | undefined {
    return this.updatedAt
      ? moment(this.updatedAt).format(i18n.t('common.format.dateTime'))
      : undefined
  }
}
