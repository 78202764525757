import { User as TwilioUser } from '@twilio/conversations'

import { StatusType } from '@/models/chat/StatusType'

export class Member {
  userId: string
  userInstance: TwilioUser

  constructor(params: { userId: string; userInstance: TwilioUser }) {
    this.userId = params.userId
    this.userInstance = params.userInstance
  }

  get status(): StatusType {
    return this.userInstance.isOnline ? 'active' : 'inactive'
  }
}
