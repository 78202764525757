
import moment from 'moment'
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import { BrokerIndicationFormProps } from '@/components/trading/interface/BrokerIndicationFormProps'
import { DeliveryPatternComponentFormProps } from '@/components/trading/interface/DeliveryPatternComponentFormProps'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { DeliveryUnitOptions } from '@/models/trading/DeliveryUnitOptions'
import { PositionOptions } from '@/models/trading/PositionOptions'

type DatasetProps = {
  label: string
  data: string
}

export default defineComponent({
  name: 'IndicationConfirmModalContent',
  components: {
    BaseBox,
    BaseDataset,
  },
  props: {
    formValue: {
      type: Object as PropType<BrokerIndicationFormProps>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    message: {
      type: String as PropType<string>,
      required: true,
    },
    isBroker: {
      type: Boolean,
      default: false,
    },
    selectableInputs: {
      type: Object as PropType<BaseStandardIndicationSelectableInputs>,
      required: true,
    },
  },
  emits: ['confirm', 'cancel'],
  computed: {
    isSwingOption(): boolean {
      return this.formValue.price?.type === 'basic-plus-volume'
    },
    isNonStandard(): boolean {
      return this.formValue.deliveryUnit === DeliveryUnit.NonStandard
    },
    isStandardSpread(): boolean {
      return this.formValue.exchange.volumes.length > 0
    },
    isDisplayPublicInformation(): boolean {
      if (this.isBroker) {
        return true
      }
      return (
        this.formValue.publicInformation !== undefined &&
        this.formValue.publicInformation.length > 0
      )
    },
    organizationBlockItems(): DatasetProps[] {
      return [
        {
          label: this.$t('trading.label.organizationName').toString(),
          data: this.organizationNameValue,
        },
        {
          label: this.$t('trading.label.traderName').toString(),
          data: this.traderNameValue,
        },
      ]
    },
    productBlockItems(): DatasetProps[] {
      return [
        {
          label: this.$t('trading.label.position').toString(),
          data: this.positionValue,
        },
        {
          label: this.$t('trading.label.deliveryUnit').toString(),
          data: this.deliveryUnitValue,
        },
        {
          label: this.$t('trading.label.productType').toString(),
          data: this.productTypesValue,
        },
        {
          label: this.$t('trading.label.fuelSurchargeType').toString(),
          data: this.fuelSurchargeTypeValue,
        },
        ...this.standardProductBlockItems,
      ]
    },
    standardProductBlockItems(): DatasetProps[] {
      return this.isNonStandard
        ? []
        : this.isStandardSpread
        ? [
            {
              label: `${this.$t('trading.label.area').toString()} (${this.$t(
                'trading.label.baseSpreadIndication',
              )})`,
              data: this.areaValue,
            },
            {
              label: `${this.$t('trading.label.area').toString()} (${this.$t(
                'trading.label.exchangeSpreadIndication',
              )})`,
              data: this.exchangeAreaValue,
            },
            {
              label: `${this.$t('trading.label.period').toString()} (${this.$t(
                'trading.label.baseSpreadIndication',
              )})`,
              data: this.periodValue,
            },
            {
              label: `${this.$t('trading.label.period').toString()} (${this.$t(
                'trading.label.exchangeSpreadIndication',
              )})`,
              data: this.exchangePeriodValue,
            },
            {
              label: `${this.$t(
                'trading.label.hourType',
              ).toString()} (${this.$t('trading.label.baseSpreadIndication')})`,
              data: this.hourTypeValue,
            },
            {
              label: `${this.$t(
                'trading.label.hourType',
              ).toString()} (${this.$t(
                'trading.label.exchangeSpreadIndication',
              )})`,
              data: this.exchangeHourTypeValue,
            },
            {
              label: this.$t('trading.label.unitPrice').toString(),
              data: this.$t('trading.label.unitPriceWithUnit', {
                unitPrice: this.formValue.unitPrices[0],
              }).toString(),
            },
            {
              label: `${this.$t('trading.label.volume').toString()} (${this.$t(
                'trading.label.baseSpreadIndication',
              )})`,
              data: this.formatVolume(this.formValue.base.volumes[0]),
            },
            {
              label: `${this.$t('trading.label.volume').toString()} (${this.$t(
                'trading.label.exchangeSpreadIndication',
              )})`,
              data: this.formatVolume(this.formValue.exchange.volumes[0]),
            },
            ...(this.isBroker
              ? [
                  {
                    label: this.$t(
                      'trading.label.toDisplayVolumeAsTbd',
                    ).toString(),
                    data: this.formValue.maskVolume
                      ? this.$t('common.label.yes').toString()
                      : this.$t('common.label.no').toString(),
                  },
                ]
              : []),
          ]
        : [
            {
              label: this.$t('trading.label.area').toString(),
              data: this.areaValue,
            },
            {
              label: this.$t('trading.label.period').toString(),
              data: this.periodValue,
            },
            {
              label: this.$t('trading.label.hourType').toString(),
              data: this.hourTypeValue,
            },
            {
              label: this.$t('trading.label.unitPrice').toString(),
              data: this.$t('trading.label.unitPriceWithUnit', {
                unitPrice: this.formValue.unitPrices[0],
              }).toString(),
            },
            {
              label: this.$t('trading.label.volume').toString(),
              data: this.formatVolume(this.formValue.base.volumes[0]),
            },
            ...(this.isBroker
              ? [
                  {
                    label: this.$t(
                      'trading.label.toDisplayVolumeAsTbd',
                    ).toString(),
                    data: this.formValue.maskVolume
                      ? this.$t('common.label.yes').toString()
                      : this.$t('common.label.no').toString(),
                  },
                ]
              : []),
          ]
    },
    brokerBlockItems(): DatasetProps[] {
      return [
        ...(this.formValue.status
          ? [
              {
                label: this.$t('trading.label.status').toString(),
                data: this.formValue.status.label,
              },
            ]
          : []),
        {
          label: this.$t('trading.label.openToMarket').toString(),
          data: this.formValue.openToMarket
            ? this.$t('common.label.yes').toString()
            : this.$t('common.label.no').toString(),
        },
        {
          label: this.$t('trading.label.isVisibleToTraders').toString(),
          data: this.formValue.isVisibleToTraders
            ? this.$t('common.label.yes').toString()
            : this.$t('common.label.no').toString(),
        },
        {
          label: this.$t('trading.label.brokerName').toString(),
          data: this.brokerNameValue,
        },
        {
          label: this.$t('trading.label.excludedEnecCurve').toString(),
          data: this.formValue.excludedEnecCurve
            ? this.$t('common.label.yes').toString()
            : this.$t('common.label.no').toString(),
        },
      ]
    },
    organizationNameValue(): string {
      return (
        this.formValue.organization?.name.translation() ??
        this.$t('common.label.blank').toString()
      )
    },
    traderNameValue(): string {
      return (
        this.formValue.trader?.label ?? this.$t('common.label.blank').toString()
      )
    },
    deliveryUnitValue(): string {
      return new DeliveryUnitOptions().label(this.formValue.deliveryUnit)
    },
    positionValue(): string {
      return new PositionOptions().label(this.formValue.position)
    },
    periodValue(): string {
      if (this.formValue.deliveryUnit === DeliveryUnit.Month) {
        if (
          !this.formValue.base.startDeliveryYearMonthId ||
          !this.formValue.base.endDeliveryYearMonthId
        ) {
          return this.$t('common.label.blank').toString()
        }
        const startYearMonth = this.selectableInputs.currentDeliveryStartYearMonth?.translation()
        const endYearMonth = this.selectableInputs.currentDeliveryEndYearMonth?.translation()
        return `${startYearMonth} - ${endYearMonth}`
      }
      if (this.formValue.deliveryUnit === DeliveryUnit.WeekOrDay) {
        if (
          !this.formValue.base.startDeliveryDate ||
          !this.formValue.base.endDeliveryDate
        ) {
          return this.$t('common.label.blank').toString()
        }
        const startDeliveryDate = moment(
          this.formValue.base.startDeliveryDate,
        ).format(this.$t('common.format.shortDate').toString())
        const endDeliveryDate = moment(
          this.formValue.base.endDeliveryDate,
        ).format(this.$t('common.format.shortDate').toString())
        return `${startDeliveryDate} - ${endDeliveryDate}`
      }
      return this.$t('common.label.blank').toString()
    },
    exchangePeriodValue(): string {
      if (this.formValue.deliveryUnit === DeliveryUnit.Month) {
        if (
          !this.formValue.exchange.startDeliveryYearMonthId ||
          !this.formValue.exchange.endDeliveryYearMonthId
        ) {
          return this.$t('common.label.blank').toString()
        }
        const startYearMonth = this.selectableInputs.currentExchangeDeliveryStartYearMonth?.translation()
        const endYearMonth = this.selectableInputs.currentExchangeDeliveryEndYearMonth?.translation()
        return `${startYearMonth} - ${endYearMonth}`
      }
      if (this.formValue.deliveryUnit === DeliveryUnit.WeekOrDay) {
        if (
          !this.formValue.exchange.startDeliveryDate ||
          !this.formValue.exchange.endDeliveryDate
        ) {
          return this.$t('common.label.blank').toString()
        }
        const startDeliveryDate = moment(
          this.formValue.exchange.startDeliveryDate,
        ).format(this.$t('common.format.shortDate').toString())
        const endDeliveryDate = moment(
          this.formValue.exchange.endDeliveryDate,
        ).format(this.$t('common.format.shortDate').toString())
        return `${startDeliveryDate} - ${endDeliveryDate}`
      }
      return this.$t('common.label.blank').toString()
    },
    areaValue(): string {
      return (
        this.selectableInputs.currentArea?.translation() ??
        this.$t('common.label.blank').toString()
      )
    },
    exchangeAreaValue(): string {
      return (
        this.selectableInputs.currentExchangeArea?.translation() ??
        this.$t('common.label.blank').toString()
      )
    },
    fuelSurchargeTypeValue(): string {
      return (
        this.selectableInputs.currentFuelSurchargeType?.translation() ??
        this.$t('common.label.blank').toString()
      )
    },
    hourTypeValue(): string {
      return (
        this.selectableInputs.currentHourType?.translation() ??
        this.$t('common.label.blank').toString()
      )
    },
    exchangeHourTypeValue(): string {
      return (
        this.selectableInputs.currentExchangeHourType?.translation() ??
        this.$t('common.label.blank').toString()
      )
    },
    productTypesValue(): string {
      return this.selectableInputs.currentProductTypes
        .map(productType => productType.translation())
        .join(this.$t('common.label.separator').toString())
    },
    brokerNameValue(): string {
      return (
        this.formValue.broker?.label ?? this.$t('common.label.blank').toString()
      )
    },
    requestValue(): string {
      return this.formValue.request !== ''
        ? this.formValue.request
        : this.$t('common.label.blank').toString()
    },
    publicInformation(): string {
      return (
        this.formValue.publicInformation ||
        this.$t('common.label.blank').toString()
      )
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
    },
    onCancel() {
      this.$emit('cancel')
    },
    formatPeriod(startDeliveryDate: Date, endDeliveryDate: Date): string {
      const start = moment(startDeliveryDate).format(
        this.$t('common.format.shortDate').toString(),
      )
      const end = moment(endDeliveryDate).format(
        this.$t('common.format.shortDate').toString(),
      )
      return `${start} - ${end}`
    },
    formatPatternComponent(
      patternComponents: DeliveryPatternComponentFormProps[],
    ): string {
      return patternComponents
        .map(patternComponent => {
          return `${patternComponent.dayPattern?.label} ${patternComponent.timeRangeStart?.label}-${patternComponent.timeRangeEnd?.label}`
        })
        .join(this.$t('common.label.separator').toString())
    },
    formatVolume(volume: string | null): string {
      return volume === null || volume === ''
        ? this.$t('trading.label.tbd').toString()
        : this.$t('trading.label.volumeWithUnit', {
            volume,
          }).toString()
    },
  },
})
