import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_internal_dashboard_page = _resolveComponent("internal-dashboard-page")!

  return (_openBlock(), _createBlock(_component_internal_dashboard_page, {
    "title-key": "sales.label.lngPlantOutage",
    workbook: "Lng_plant_outage",
    view: "Lng_plant_outage",
    "footnote-key": "sales.label.lngPlantOutageFootnote"
  }))
}