import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { EexPostingsApi, NewEEXPosting, EexApi } from '@/api/generated'
import { EexClearingAccount } from '@/models/iam/EexClearingAccount'
import { EEXPosting } from '@/models/trading/EEXPosting'
import { EEXPostingResponse } from '@/models/trading/EEXPostingResponse'
import { EexTrader } from '@/models/trading/EexTrader'
import { EEXPostingGetters } from '@/store/modules/trading/eexPosting/EEXPostingGetters'
import { EEXPostingMutations } from '@/store/modules/trading/eexPosting/EEXPostingMutations'
import { EEXPostingState } from '@/store/modules/trading/eexPosting/EEXPostingState'

export class EEXPostingActions extends Actions<
  EEXPostingState,
  EEXPostingGetters,
  EEXPostingMutations,
  EEXPostingActions
> {
  async fetchEEXPostings({
    pageSize,
    pageToken,
  }: {
    pageSize?: number
    pageToken?: string
  }) {
    return plainToClass(
      EEXPostingResponse,
      (await new EexPostingsApi(apiConfig).getEEXPostings(pageSize, pageToken))
        .data,
    )
  }

  async searchEEXTraders({
    organizationId,
    eexTraderName,
    abortController,
  }: {
    organizationId: string
    eexTraderName: string
    abortController?: AbortController
  }) {
    const eexTraders = plainToClass(
      EexTrader,
      (
        await new EexApi(apiConfig).getEEXTradersOfOrganization(
          organizationId,
          { signal: abortController?.signal },
        )
      ).data,
    )
    return eexTraders.filter(eexTrader => {
      return eexTrader.eexTraderName?.includes(eexTraderName)
    })
  }

  async getEEXTraderOfCode({
    organizationId,
    eexTraderCode,
    abortController,
  }: {
    organizationId: string
    eexTraderCode: string
    abortController?: AbortController
  }): Promise<EexTrader | undefined> {
    const eexTraders = plainToClass(
      EexTrader,
      (
        await new EexApi(apiConfig).getEEXTradersOfOrganization(
          organizationId,
          { signal: abortController?.signal },
        )
      ).data,
    )
    return eexTraders.find(eexTrader => {
      return eexTrader.eexTraderCode === eexTraderCode
    })
  }

  async searchEEXClearingAccounts({
    organizationId,
    eexClearingAccountCode,
    abortController,
  }: {
    organizationId: string
    eexClearingAccountCode: string
    abortController?: AbortController
  }) {
    const accounts = plainToClass(
      EexClearingAccount,
      (
        await new EexApi(apiConfig).getEEXClearingAccountsOfOrganization(
          organizationId,
          {
            signal: abortController?.signal,
          },
        )
      ).data,
    )
    return accounts.filter(account => {
      return account.eexClearingAccountCode.includes(eexClearingAccountCode)
    })
  }

  async getEEXClearingAccountOfCode({
    organizationId,
    eexClearingAccountCode,
    abortController,
  }: {
    organizationId: string
    eexClearingAccountCode: string
    abortController?: AbortController
  }) {
    const accounts = plainToClass(
      EexClearingAccount,
      (
        await new EexApi(apiConfig).getEEXClearingAccountsOfOrganization(
          organizationId,
          {
            signal: abortController?.signal,
          },
        )
      ).data,
    )
    return accounts.find(
      account => account.eexClearingAccountCode === eexClearingAccountCode,
    )
  }

  async createEEXPosting(posting: NewEEXPosting) {
    return plainToClass(
      EEXPosting,
      (await new EexPostingsApi(apiConfig).newEEXPosting(posting)).data,
    )
  }
}
