import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e15cad8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-unit-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isNonStandard)
      ? (_openBlock(), _createBlock(_component_base_tooltip, {
          key: 0,
          class: "delivery-unit-icon__icon",
          label: _ctx.$t('trading.label.deliveryUnitType.nonStandard')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_icon, { "icon-name": "concierge-bell" })
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true)
  ]))
}