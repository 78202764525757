import { BrokingOrderBookItemProps } from '@/components/trading/interface/BrokingOrderBookItemProps'
import { PublicOrderBookProps } from '@/components/trading/interface/PublicOrderBookProps'

type OrderBookProps = PublicOrderBookProps | BrokingOrderBookItemProps

export const productSorter = (a: OrderBookProps, b: OrderBookProps) => {
  const productTypeDisplayPriorityOfA = (a.indications
    ? a.indications[0].product.productType
    : a.deal
    ? a.deal.product.productType
    : {}
  ).displayPriority
  const productTypeDisplayPriorityOfB = (b.indications
    ? b.indications[0].product.productType
    : b.deal
    ? b.deal.product.productType
    : {}
  ).displayPriority
  const productTypePriority =
    productTypeDisplayPriorityOfA !== undefined &&
    productTypeDisplayPriorityOfB !== undefined
      ? productTypeDisplayPriorityOfB - productTypeDisplayPriorityOfA
      : 0
  return (
    b.deliveryTerms.area.displayPriority -
      a.deliveryTerms.area.displayPriority ||
    a.deliveryTerms.firstStartDate().diff(b.deliveryTerms.firstStartDate()) ||
    a.deliveryTerms.lastEndDate().diff(b.deliveryTerms.lastEndDate()) ||
    (b.deliveryTerms.deliveryPeriods[0]?.deliveryPattern.hourType
      ?.displayPriority || 0) -
      (a.deliveryTerms.deliveryPeriods[0]?.deliveryPattern.hourType
        ?.displayPriority || 0) ||
    productTypePriority
  )
}
