import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  OcctoMastersApi,
  RequestBalancingGroup,
  RequestSupplyPlanSubmitter,
} from '@/api/generated'
import { BalancingGroup } from '@/models/iam/BalancingGroup'
import { OcctoMaster } from '@/models/iam/OcctoMaster'
import { SupplyPlanSubmitter } from '@/models/iam/SupplyPlanSubmitter'

export class OcctoMasterActions extends Actions<OcctoMasterActions> {
  async fetchBalancingGroup({
    organizationId,
    balancingGroupId,
  }: {
    organizationId: string
    balancingGroupId: string
  }): Promise<BalancingGroup> {
    return plainToClass(
      BalancingGroup,
      (
        await new OcctoMastersApi(apiConfig).getBalancingGroup(
          organizationId,
          balancingGroupId,
        )
      ).data,
    )
  }

  async fetchSupplyPlanSubmitter({
    organizationId,
    supplyPlanSubmitterId,
  }: {
    organizationId: string
    supplyPlanSubmitterId: string
  }): Promise<SupplyPlanSubmitter> {
    return plainToClass(
      SupplyPlanSubmitter,
      (
        await new OcctoMastersApi(apiConfig).getSupplyPlanSubmitter(
          organizationId,
          supplyPlanSubmitterId,
        )
      ).data,
    )
  }

  async fetchOcctoMaster({
    organizationId,
    areaId,
  }: {
    organizationId: string
    areaId?: string
  }): Promise<OcctoMaster> {
    return plainToClass(
      OcctoMaster,
      (
        await new OcctoMastersApi(apiConfig).getOcctoMaster(
          organizationId,
          areaId,
        )
      ).data,
    )
  }

  async createBalancingGroup({
    organizationId,
    balancingGroup,
  }: {
    organizationId: string
    balancingGroup: RequestBalancingGroup
  }): Promise<BalancingGroup> {
    return plainToClass(
      BalancingGroup,
      (
        await new OcctoMastersApi(apiConfig).newBalancingGroup(
          organizationId,
          balancingGroup,
        )
      ).data,
    )
  }

  async createSupplyPlanSubmitter({
    organizationId,
    supplyPlanSubmitter,
  }: {
    organizationId: string
    supplyPlanSubmitter: RequestSupplyPlanSubmitter
  }): Promise<SupplyPlanSubmitter> {
    return plainToClass(
      SupplyPlanSubmitter,
      (
        await new OcctoMastersApi(apiConfig).newSupplyPlanSubmitter(
          organizationId,
          supplyPlanSubmitter,
        )
      ).data,
    )
  }

  async updateBalancingGroup({
    organizationId,
    balancingGroupId,
    balancingGroup,
  }: {
    organizationId: string
    balancingGroupId: string
    balancingGroup: RequestBalancingGroup
  }): Promise<BalancingGroup> {
    return plainToClass(
      BalancingGroup,
      (
        await new OcctoMastersApi(apiConfig).updateBalancingGroup(
          organizationId,
          balancingGroupId,
          balancingGroup,
        )
      ).data,
    )
  }

  async updateSupplyPlanSubmitter({
    organizationId,
    supplyPlanSubmitterId,
    supplyPlanSubmitter,
  }: {
    organizationId: string
    supplyPlanSubmitterId: string
    supplyPlanSubmitter: RequestSupplyPlanSubmitter
  }): Promise<SupplyPlanSubmitter> {
    return plainToClass(
      SupplyPlanSubmitter,
      (
        await new OcctoMastersApi(apiConfig).updateSupplyPlanSubmitter(
          organizationId,
          supplyPlanSubmitterId,
          supplyPlanSubmitter,
        )
      ).data,
    )
  }

  async deleteBalancingGroup({
    organizationId,
    balancingGroupId,
  }: {
    organizationId: string
    balancingGroupId: string
  }): Promise<void> {
    await new OcctoMastersApi(apiConfig).deleteBalancingGroup(
      organizationId,
      balancingGroupId,
    )
  }

  async deleteSupplyPlanSubmitter({
    organizationId,
    supplyPlanSubmitterId,
  }: {
    organizationId: string
    supplyPlanSubmitterId: string
  }): Promise<void> {
    await new OcctoMastersApi(apiConfig).deleteSupplyPlanSubmitter(
      organizationId,
      supplyPlanSubmitterId,
    )
  }
}
