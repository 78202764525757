import { Locale } from '@/api/generated'

export class Translation {
  locale: Locale
  text: string

  constructor({ locale, text }: { locale: Locale; text: string }) {
    this.locale = locale
    this.text = text
  }

  has(locale: Locale) {
    return this.locale === locale
  }
}
