import { withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b5b2cfd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-input-form" }
const _hoisted_2 = ["value", "rows", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", {
      class: "textarea chat-input-form__input",
      value: _ctx.value,
      rows: _ctx.rows,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onSend && _ctx.onSend(...args)), ["ctrl"]), ["enter"])),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTyping && _ctx.onTyping(...args)))
    }, null, 40, _hoisted_2)
  ]))
}