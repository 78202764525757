import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dd9f267"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "unit-price-and-volume-form" }
const _hoisted_2 = { class: "unit-price-and-volume-form__input" }
const _hoisted_3 = { class: "unit-price-and-volume-form__input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_validation_form = _resolveComponent("validation-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_validation_form, {
      ref: "validationObserver",
      class: "unit-price-and-volume-form__validation-form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_input_text, {
            name: "unitPrice",
            value: _ctx.innerFormValue.unitPrice,
            "value-type": "number",
            required: true,
            rules: `required|numeric|scale:4|between:0.001,999.999`,
            mode: _ctx.unitPriceMode,
            "is-shown-error-message": false,
            onFocus: _ctx.onFocus,
            onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onBlur('unitPrice'))),
            onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onKeydown($event, 'unitPrice'))),
            onCompositionstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isComposition = true)),
            onCompositionend: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isComposition = false)),
            onInput: _ctx.onUnitPriceInput
          }, null, 8, ["value", "rules", "mode", "onFocus", "onInput"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_base_input_text, {
            name: "volume",
            value: _ctx.innerFormValue.volume,
            "value-type": "number",
            rules: 'numeric|scale:4|between:0.001,9999.999',
            mode: _ctx.volumeMode,
            "is-shown-error-message": false,
            onFocus: _ctx.onFocus,
            onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onBlur('volume'))),
            onKeydown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onKeydown($event, 'volume'))),
            onCompositionstart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isComposition = true)),
            onCompositionend: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isComposition = false)),
            onInput: _ctx.onVolumeInput
          }, null, 8, ["value", "rules", "mode", "onFocus", "onInput"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}