import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "base-single-select" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = {
  key: 0,
  class: "control"
}
const _hoisted_4 = {
  key: 2,
  class: "base-single-select__value"
}
const _hoisted_5 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_field = _resolveComponent("field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_field, {
      name: _ctx.name,
      rules: _ctx.rules,
      "model-value": _ctx.value,
      "validate-on-mount": ""
    }, {
      default: _withCtx(({ handleChange }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_label, {
            label: _ctx.labelString,
            help: _ctx.help,
            class: "base-single-select__label"
          }, null, 8, ["label", "help"]),
          _createElementVNode("div", {
            class: _normalizeClass(["control", { 'base-single-select__select--hidden': !_ctx.inputable }])
          }, [
            _createVNode(_component_multiselect, {
              "model-value": _ctx.value,
              options: _ctx.options,
              placeholder: 
              _ctx.disabled ? _ctx.$t('common.label.unselectable') : _ctx.placeholder
            ,
              "track-by": "value",
              label: _ctx.optionsLabel,
              name: _ctx.name,
              searchable: _ctx.searchable,
              disabled: _ctx.disabled,
              "select-label": "",
              "deselect-label": "",
              "selected-label": "",
              "onUpdate:modelValue": 
              $event => {
                handleChange($event), _ctx.onInput($event)
              }
            
            }, null, 8, ["model-value", "options", "placeholder", "label", "name", "searchable", "disabled", "onUpdate:modelValue"])
          ], 2),
          (_ctx.inputable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : (_ctx.editable)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "base-single-select__value--editable",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
                }, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.valueLabel), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.valueLabel), 1)
                ]))
        ]),
        _createVNode(_component_error_message, { name: _ctx.name }, {
          default: _withCtx(({ message }) => [
            (_ctx.touched)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(message), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["name"])
      ]),
      _: 1
    }, 8, ["name", "rules", "model-value"])
  ]))
}