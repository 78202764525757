/* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

// https://auth0.com/docs/quickstart/spa/vuejs
import createAuth0Client, {
  Auth0Client,
  PopupLoginOptions,
  RedirectLoginOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js'
import { App } from 'vue'

import { root, store } from '@/store'
import { setAuth0IdToGa4 } from '@/utils/ga'

const ctx = root.context(store)
const userProfileCtx = ctx.modules.userProfile

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance: any

export const getInstance = () => instance

const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) {
    return instance
  }

  instance = {
    loading: true,
    isAuthenticated: false,
    user: {},
    auth0Client: null as null | Auth0Client,
    popupOpen: false,
    error: null,
    async loginWithPopup(o: PopupLoginOptions) {
      instance.popupOpen = true

      try {
        await instance.auth0Client!.loginWithPopup(o)
      } catch (e) {
        // eslint-disable-next-line
        console.error(e)
      } finally {
        instance.popupOpen = false
      }

      instance.user = await instance.auth0Client!.getUser()
      instance.isAuthenticated = true
    },
    async handleRedirectCallback() {
      instance.loading = true
      try {
        await instance.auth0Client!.handleRedirectCallback()
        instance.user = await instance.auth0Client!.getUser()
        instance.isAuthenticated = true
      } catch (e) {
        instance.error = e
      } finally {
        instance.loading = false
      }
    },
    loginWithRedirect(o: RedirectLoginOptions) {
      return instance.auth0Client!.loginWithRedirect(o)
    },
    getIdTokenClaims(o: GetIdTokenClaimsOptions) {
      return instance.auth0Client!.getIdTokenClaims(o)
    },
    getTokenSilently(o?: GetTokenSilentlyOptions) {
      return instance.auth0Client!.getTokenSilently(o)
    },
    getTokenWithPopup(o: GetTokenWithPopupOptions) {
      return instance.auth0Client!.getTokenWithPopup(o)
    },
    logout(o: LogoutOptions) {
      return instance.auth0Client!.logout(o)
    },
    refreshToken() {
      // そのものずばりの refresh がなかったので常に auth0 に問い合わせるモードでトークンを取得する
      instance.getTokenSilently({ ignoreCache: true })
    },
    async getAuthorizationHeader() {
      return {
        headers: {
          Authorization: `Bearer ${await instance.getTokenSilently()}`,
        },
      }
    },
    async getDecodedPayload() {
      const token = await instance.getTokenSilently()
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
    },
    async getUserType() {
      const decodedPayload = await instance.getDecodedPayload()
      return decodedPayload['https://esquare.enechain.co.jp/user_type']
    },
  }
  ;(async () => {
    instance.auth0Client = await createAuth0Client({
      domain: options.domain,
      client_id: options.clientId,
      audience: options.audience,
      redirect_uri: redirectUri,
    })

    try {
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await instance.auth0Client.handleRedirectCallback()
        onRedirectCallback(appState)
      }
    } catch (e) {
      instance.error = e
    } finally {
      instance.isAuthenticated = await instance.auth0Client.isAuthenticated()
      instance.user = await instance.auth0Client.getUser()
      if (instance.user?.sub) {
        await userProfileCtx.dispatch('fetchUserProfile', {
          userId: instance.user.sub,
        })
        // 初期化できたタイミングで、Auth0のトークンをGA4に追加する
        const authId = instance.user.sub.split('auth0|')[1]
        setAuth0IdToGa4(authId)
        // GTMから取得するため、クッキーにauth_idを追加する
        document.cookie = `auth_id_EN=${authId}; secure`
      }

      instance.loading = false
    }
  })()
  return instance
}

export const Auth0Plugin = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: App, options: any) {
    const TOKEN = '$auth'
    const INJECTION_KEY = Symbol(TOKEN)
    const instance = useAuth0(options)
    app.config.globalProperties[TOKEN] = instance
    app.provide(INJECTION_KEY, instance)
  },
}
