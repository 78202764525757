import {
  UserProfile as IUserProfile,
  Locale,
  Status,
  RoleType,
  Services,
} from '@/api/generated'

export class UserProfile implements IUserProfile {
  userId!: string
  mail!: string
  name!: string
  department?: string
  locale!: Locale
  picture!: string
  organizationId!: string
  status!: Status
  roleInOrganization!: RoleType
  services!: Services
  phoneNumber?: string
  mobilePhoneNumber?: string
}
