import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6549cad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participant-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { class: "participant-summary__box" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["participant-summary__content", `participant-summary__content--${_ctx.direction}`])
        }, [
          _createVNode(_component_base_dataset, {
            data: _ctx.displayCreatedParticipantName,
            label: _ctx.$t('trading.label.createdParticipantName')
          }, null, 8, ["data", "label"]),
          _createVNode(_component_base_dataset, {
            data: _ctx.displayCreatedAt,
            label: _ctx.$t('trading.label.createdAt')
          }, null, 8, ["data", "label"]),
          _createVNode(_component_base_dataset, {
            data: _ctx.displayLastUpdatedParticipantName,
            label: _ctx.$t('trading.label.lastUpdatedParticipantName')
          }, null, 8, ["data", "label"]),
          _createVNode(_component_base_dataset, {
            data: _ctx.displayUpdatedAt,
            label: _ctx.$t('trading.label.updatedAt')
          }, null, 8, ["data", "label"])
        ], 2)
      ]),
      _: 1
    })
  ]))
}