import { AxiosResponse } from 'axios'
import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  OrganizationsApi,
  OrganizationType,
  UpdateOrganization,
  UpdateBrokerageFeePaymentConfiguration,
  BrokerageFeePaymentConfigurationApi,
  DefaultBrokingFeeOfOrganizationsApi,
  UpdateDefaultBrokingFeeOfOrganizations,
} from '@/api/generated'
import { BrokerageFeePaymentConfiguration } from '@/models/iam/BrokerageFeePaymentConfiguration'
import { DefaultBrokingFeeOfOrganizations } from '@/models/iam/DefaultBrokingFeeOfOrganizations'
import { Organization } from '@/models/iam/Organization'
import { OrganizationsGetters } from '@/store/modules/iam/organizations/OrganizationsGetters'
import { OrganizationsMutations } from '@/store/modules/iam/organizations/OrganizationsMutations'
import { OrganizationsState } from '@/store/modules/iam/organizations/OrganizationsState'
import {
  arrayBuffer2JpegDataUrl,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class OrganizationsActions extends Actions<
  OrganizationsState,
  OrganizationsGetters,
  OrganizationsMutations,
  OrganizationsActions
> {
  async fetchOrganizations({
    organizationType,
    organizationName,
    isEClearContracted,
    isEClearCreditSleever,
    isEsquareEnabled,
    abortController,
  }: {
    organizationType?: OrganizationType
    organizationName?: string
    isEClearContracted?: boolean
    isEClearCreditSleever?: boolean
    isEsquareEnabled?: boolean
    abortController?: AbortController
  }): Promise<Organization[]> {
    const organizations = plainToClass(
      Organization,
      (
        await new OrganizationsApi(apiConfig).getOrganizations(
          undefined,
          organizationType,
          organizationName,
          isEClearContracted,
          isEClearCreditSleever,
          isEsquareEnabled,
          { signal: abortController?.signal },
        )
      ).data,
    )

    const organizationPictures = await (this.dispatch(
      'fetchOrganizationPictures',
      { organizations, signal: abortController?.signal },
    ) as ReturnType<typeof this.fetchOrganizationPictures>)

    for (const [index, organization] of organizations.entries()) {
      if (isRequiredAuthenticatedRequest(organization.picture)) {
        organizations[index].picture = arrayBuffer2JpegDataUrl(
          (organizationPictures[index] as unknown) as ArrayBuffer,
        )
      }
    }
    return organizations
  }

  async fetchOrganization(id: string): Promise<Organization> {
    const organization = plainToClass(
      Organization,
      (await new OrganizationsApi(apiConfig).getOrganizationById(id)).data,
    )

    if (isRequiredAuthenticatedRequest(organization.picture)) {
      const organizationPictures = await (this.dispatch(
        'fetchOrganizationPictures',
        { organizations: [organization] },
      ) as ReturnType<typeof this.fetchOrganizationPictures>)
      organization.picture = arrayBuffer2JpegDataUrl(
        (organizationPictures[0] as unknown) as ArrayBuffer,
      )
    }

    return organization
  }

  async fetchBrokerageFeePaymentConfiguration(
    organizationId: string,
  ): Promise<BrokerageFeePaymentConfiguration> {
    return plainToClass(
      BrokerageFeePaymentConfiguration,
      (
        await new BrokerageFeePaymentConfigurationApi(
          apiConfig,
        ).getBrokerageFeePaymentConfiguration(organizationId)
      ).data,
    )
  }

  async fetchDefaultBrokingFeeOfOrganizations(
    organizationId: string,
  ): Promise<DefaultBrokingFeeOfOrganizations> {
    return plainToClass(
      DefaultBrokingFeeOfOrganizations,
      (
        await new DefaultBrokingFeeOfOrganizationsApi(
          apiConfig,
        ).getCustomizeDefaultBrokingFeeOfOrganization(organizationId)
      ).data,
    )
  }

  async fetchBrokerOrganization(): Promise<Organization> {
    return plainToClass(
      Organization,
      (
        await new OrganizationsApi(apiConfig).getOrganizations(
          undefined,
          OrganizationType.Broker,
        )
      ).data[0],
    )
  }

  async fetchEclearOrganization(): Promise<Organization> {
    return plainToClass(
      Organization,
      (
        await new OrganizationsApi(apiConfig).getOrganizations(
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          undefined,
        )
      ).data[0],
    )
  }

  async searchOrganizations({
    organizationName,
    isEclearCreditSleever,
    isEsquareEnabled,
    abortController,
  }: {
    organizationName?: string
    isEclearCreditSleever?: boolean
    isEsquareEnabled?: boolean
    abortController?: AbortController
  }): Promise<Organization[]> {
    const organizations = plainToClass(
      Organization,
      (
        await new OrganizationsApi(apiConfig).getOrganizations(
          undefined,
          OrganizationType.Trader,
          organizationName,
          undefined,
          isEclearCreditSleever,
          isEsquareEnabled,
          { signal: abortController?.signal },
        )
      ).data,
    )

    const organizationPictures = await (this.dispatch(
      'fetchOrganizationPictures',
      { organizations, signal: abortController?.signal },
    ) as ReturnType<typeof this.fetchOrganizationPictures>)

    for (const [index, organization] of organizations.entries()) {
      if (isRequiredAuthenticatedRequest(organization.picture)) {
        organizations[index].picture = arrayBuffer2JpegDataUrl(
          (organizationPictures[index] as unknown) as ArrayBuffer,
        )
      }
    }
    return organizations
  }

  async fetchOrganizationPictures(payload: {
    organizations: Organization[]
    signal?: AbortSignal
  }): Promise<string[]> {
    const organizationPicturePromises = payload.organizations.map(
      organization =>
        isRequiredAuthenticatedRequest(organization.picture)
          ? new OrganizationsApi(apiConfig).getPictureOfOrganization(
              organization.organizationId,
              {
                responseType: 'arraybuffer',
                signal: payload.signal,
              },
            )
          : Promise.resolve({ data: '' } as AxiosResponse),
    )
    const organizationPictures: string[] = (
      await Promise.all(organizationPicturePromises)
    )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map(response => (response.data as any) as string)
    return organizationPictures
  }

  async updateOrganization({
    organizationId,
    organization,
  }: {
    organizationId: string
    organization: UpdateOrganization
  }): Promise<Organization> {
    return plainToClass(
      Organization,
      (
        await new OrganizationsApi(apiConfig).updateOrganization(
          organizationId,
          organization,
        )
      ).data,
    )
  }

  async updateOrganizationPaymentConfiguration({
    organizationId,
    organization,
  }: {
    organizationId: string
    organization: UpdateBrokerageFeePaymentConfiguration
  }): Promise<BrokerageFeePaymentConfiguration> {
    return plainToClass(
      BrokerageFeePaymentConfiguration,
      (
        await new BrokerageFeePaymentConfigurationApi(
          apiConfig,
        ).updateBrokerageFeePaymentConfiguration(organizationId, organization)
      ).data,
    )
  }

  async updateDefaultBrokingFeeOfOrganization({
    organizationId,
    defaultBrokingFeeOfOrganizations,
  }: {
    organizationId: string
    defaultBrokingFeeOfOrganizations: UpdateDefaultBrokingFeeOfOrganizations
  }): Promise<DefaultBrokingFeeOfOrganizations> {
    return plainToClass(
      DefaultBrokingFeeOfOrganizations,
      (
        await new DefaultBrokingFeeOfOrganizationsApi(
          apiConfig,
        ).updateCustomizeDefaultBrokingFeeOfOrganization(
          organizationId,
          defaultBrokingFeeOfOrganizations,
        )
      ).data,
    )
  }
}
