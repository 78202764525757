
import { defineComponent, PropType } from 'vue'

import OrderPrice from '@/components/trading/OrderPrice.vue'
import ProductType from '@/components/trading/ProductType.vue'
import { Order } from '@/models/trading/Order'

export default defineComponent({
  name: 'TradingOrderList',
  components: {
    OrderPrice,
    ProductType,
  },
  props: {
    orders: {
      type: Array as PropType<Order[]>,
      default: () => [],
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
})
