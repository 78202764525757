import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25f6351c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recap-receivers" }
const _hoisted_2 = { class: "recap-receivers__content" }
const _hoisted_3 = { class: "recap-receivers__list" }
const _hoisted_4 = { class: "recap-receivers__content-header" }
const _hoisted_5 = { class: "recap-receivers__list-header" }
const _hoisted_6 = { class: "recap-receivers__list-header-column" }
const _hoisted_7 = { class: "recap-receivers__list-header-column" }
const _hoisted_8 = { class: "recap-receivers__list-header-column" }
const _hoisted_9 = { class: "recap-receivers__list-header-column" }
const _hoisted_10 = { class: "recap-receivers__list-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_recap_receiver_list_item = _resolveComponent("recap-receiver-list-item")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeRecapReceivers')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_base_button, {
                color: "primary",
                onClick: _ctx.onAddRecapReceiverClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('iam.label.addRecapReceiver')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('iam.label.department')), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('iam.label.name')), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('iam.label.phoneNumber')), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('iam.label.emailAddress')), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recapReceivers, (recapReceiver) => {
                return (_openBlock(), _createBlock(_component_recap_receiver_list_item, {
                  key: recapReceiver.id,
                  "recap-receiver": recapReceiver,
                  "parent-path": `${_ctx.basePath}/recap-receivers`,
                  onDeleteClick: ($event: any) => (_ctx.onDeleteClick(recapReceiver))
                }, null, 8, ["recap-receiver", "parent-path", "onDeleteClick"]))
              }), 128))
            ]),
            (_ctx.isReceiverBlank)
              ? (_openBlock(), _createBlock(_component_base_alert, {
                  key: 0,
                  type: _ctx.infoType
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
                name: _ctx.$t('iam.label.recapReceiver'),
              })), 1)
                  ]),
                  _: 1
                }, 8, ["type"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["ui-stack"]),
    _createVNode(_component_confirmation_dialog, {
      name: _ctx.deleteModalName,
      title: _ctx.$t('iam.message.recapReceiverDeleteConfirmModalTitle'),
      "confirm-button-label": _ctx.$t('common.label.delete'),
      "confirm-button-color": "danger",
      loading: _ctx.deleteSubmitting,
      width: 650,
      onConfirm: _ctx.onDeleteRecapReceiverConfirm,
      onCancel: _ctx.onDeleteRecapReceiverCancel
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('iam.message.deleteRecapReceiverMessage', {
          name: _ctx.deleteTargetName,
        })), 1)
      ]),
      _: 1
    }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
  ]))
}