import { Type } from 'class-transformer'

import { OcctoMaster as IOcctoMaster } from '@/api/generated'
import { BalancingGroup } from '@/models/iam/BalancingGroup'
import { SupplyPlanSubmitter } from '@/models/iam/SupplyPlanSubmitter'

export class OcctoMaster implements IOcctoMaster {
  @Type(() => BalancingGroup)
  balancingGroups!: BalancingGroup[]

  @Type(() => SupplyPlanSubmitter)
  supplyPlanSubmitters!: SupplyPlanSubmitter[]
}
