import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "deal-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deal_list_item = _resolveComponent("deal-list-item")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deals, (deal, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_deal_list_item, {
        key: index,
        deal: deal,
        "recap-state": _ctx.recapState[deal.id],
        "private-confidential-input-mode": _ctx.privateConfidentialInputModeList[index],
        onConfirm: _ctx.onConfirm,
        onPrivateConfidentialChange: _ctx.onPrivateConfidentialChange
      }, null, 8, ["deal", "recap-state", "private-confidential-input-mode", "onConfirm", "onPrivateConfidentialChange"])), [
        [_directive_observe_visibility, isVisible => _ctx.visibilityChanged(isVisible, index)]
      ])
    }), 128))
  ]))
}