
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import {
  BrokerageFeePaymentTiming,
  UpdateDefaultBrokingFeeOfOrganizations,
} from '@/api/generated'
import { Locale } from '@/api/generated/api'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { HttpStatus } from '@/components/common/constants/HttpStatus'
import { UiStack } from '@/components/common/constants/UiStack'
import { InputOption } from '@/components/common/interface/InputOption'
import BrokerageFeePaymentConfigurationForm from '@/components/iam/BrokerageFeePaymentConfigurationForm.vue'
import BusinessInfoForm from '@/components/iam/BusinessInfoForm.vue'
import DefaultBrokingFeeOfOrganizationForm from '@/components/iam/DefaultBrokingFeeOfOrganizationForm.vue'
import TradingCompanyNameForm from '@/components/iam/TradingCompanyNameForm.vue'
import { BrokerageFeePaymentConfigurationFormProps } from '@/components/iam/interface/BrokerageFeePaymentConfigurationFormProps'
import { BusinessInfoFormProps } from '@/components/iam/interface/BusinessInfoFormProps'
import {
  DefaultBrokingFeeOfOrganizationFormProps,
  DefaultBrokingFeeOfOrganizationFormValueProps,
} from '@/components/iam/interface/DefaultBrokingFeeOfOrganizationFormProps'
import { TradingCompanyNameFormProps } from '@/components/iam/interface/TradingCompanyNameFormProps'
import { BrokerageFeePaymentConfiguration } from '@/models/iam/BrokerageFeePaymentConfiguration'
import { BrokerageFeePaymentTimingOptions } from '@/models/iam/BrokerageFeePaymentTimingOptions'
import { DefaultBrokingFeeOfOrganizations } from '@/models/iam/DefaultBrokingFeeOfOrganizations'
import { Organization } from '@/models/iam/Organization'
import { User } from '@/models/iam/User'
import { AssignedBrokerOfOrganization } from '@/models/trading/AssignedBrokerOfOrganization'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UsersModule } from '@/store/modules/iam/users'
import { AssignedBrokerOfOrganizationModule } from '@/store/modules/trading/assginedBrokerOfOrganization'
import { ProductModule } from '@/store/modules/trading/product'
import { isBrokerApp } from '@/utils/env'
import { isAxiosError } from '@/utils/error/AxiosError'
import { setNotification } from '@/utils/utils'

const { mapActions: organizationMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const { mapActions: usersMapActions } = createNamespacedHelpers(
  'users',
) as UsersModule

const {
  mapActions: assignedBrokerOfOrganizationMapActions,
} = createNamespacedHelpers(
  'assignedBrokerOfOrganization',
) as AssignedBrokerOfOrganizationModule

export default defineComponent({
  name: 'Organization',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    BaseIcon,
    BaseLink,
    BrokerageFeePaymentConfigurationForm,
    BusinessInfoForm,
    CenteredLoadingContent,
    DefaultBrokingFeeOfOrganizationForm,
    TradingCompanyNameForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType.Error
    timingInputOptions: InputOption[]
    tradingCompanyNameFormValue: TradingCompanyNameFormProps
    brokerageFeePaymentConfigurationFormValue: BrokerageFeePaymentConfigurationFormProps
    defaultBrokingFeeOfOrganizationsFormValue: DefaultBrokingFeeOfOrganizationFormProps
    defaultBrokingFeeOfOrganizationLastUpdatedParticipantName?: string
    defaultBrokingFeeOfOrganizationUpdatedAt?: string
    tradingCompanyNameSubmitting: boolean
    brokerageFeePaymentConfigurationSubmitting: boolean
    defaultBrokingFeeOfOrganizationSubmitting: boolean
    assignedBrokerOfOrganizationFormOption: InputOption[]
    businessInfoFormValue: BusinessInfoFormProps
    businessInfoSubmitting: boolean
    isExistAssignedBrokerOfOrganization: boolean
    salesTaxIncludedInInvoiceInputOptions: InputOption[]
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      timingInputOptions: new BrokerageFeePaymentTimingOptions().options,
      tradingCompanyNameFormValue: {
        tradingCompanyName: {
          [Locale.Ja]: undefined,
          [Locale.En]: undefined,
        },
        abbreviation: undefined,
      },
      brokerageFeePaymentConfigurationFormValue: {
        spotTrading: undefined,
        derivativeTrading: undefined,
      },
      defaultBrokingFeeOfOrganizationsFormValue: {
        defaultBrokingFeeOfOrganizations: [],
      },
      defaultBrokingFeeOfOrganizationLastUpdatedParticipantName: undefined,
      defaultBrokingFeeOfOrganizationUpdatedAt: undefined,
      tradingCompanyNameSubmitting: false,
      brokerageFeePaymentConfigurationSubmitting: false,
      defaultBrokingFeeOfOrganizationSubmitting: false,
      assignedBrokerOfOrganizationFormOption: [],
      businessInfoFormValue: {
        brokerId: undefined,
        brokerName: undefined,
        recapAddress: undefined,
        isSalesTaxIncludedInInvoice: false.toString(),
      },
      businessInfoSubmitting: false,
      isExistAssignedBrokerOfOrganization: false,
      salesTaxIncludedInInvoiceInputOptions: [
        {
          label: this.$t('iam.label.notIncludedSalesTax').toString(),
          value: false.toString(),
        },
        {
          label: this.$t('iam.label.includedSalesTax').toString(),
          value: true.toString(),
        },
      ],
    }
  },
  computed: {
    ...productMapState(['productTypes']),
    productTypeInputOptions(): InputOption[] {
      return this.productTypes.map(p => p.toInputOption())
    },
    isAdminPage(): boolean {
      return isBrokerApp && !!this.$attrs.organizationId
    },
    ecloudOrganizationUrl(): string {
      return `/ecloud-organizations/${this.$attrs.organizationId}`
    },
  },
  async created() {
    await Promise.all([
      this.fetchOrganization(this.$attrs.organizationId) as Promise<
        Organization
      >,
      this.fetchBrokerageFeePaymentConfiguration(
        this.$attrs.organizationId,
      ) as Promise<BrokerageFeePaymentConfiguration>,
      this.fetchDefaultBrokingFeeOfOrganizations(
        this.$attrs.organizationId,
      ) as Promise<DefaultBrokingFeeOfOrganizations>,
      this.fetchBrokerUsers() as Promise<User[]>,
      this.fetchProductTypes(),
    ])
      .then(
        async ([
          organization,
          brokerageFeePaymentConfiguration,
          defaultBrokingFeeOfOrganizations,
          brokerUsers,
        ]) => {
          this.tradingCompanyNameFormValue = {
            tradingCompanyName: {
              [Locale.Ja]: organization.tradingCompanyName.translations?.find(
                t => t.locale === Locale.Ja,
              )?.text,
              [Locale.En]: organization.tradingCompanyName.translations?.find(
                t => t.locale === Locale.En,
              )?.text,
            },
            abbreviation: organization.abbreviation,
          }
          this.businessInfoFormValue = {
            recapAddress: organization.recapAddress,
            isSalesTaxIncludedInInvoice: organization.isSalesTaxIncludedInInvoice.toString(),
          }
          this.brokerageFeePaymentConfigurationFormValue.spotTrading = this.timingInputOptions.find(
            t => t.value === brokerageFeePaymentConfiguration.spotTrading,
          )
          this.brokerageFeePaymentConfigurationFormValue.derivativeTrading = this.timingInputOptions.find(
            t => t.value === brokerageFeePaymentConfiguration.derivativeTrading,
          )
          this.defaultBrokingFeeOfOrganizationsFormValue.defaultBrokingFeeOfOrganizations = (
            defaultBrokingFeeOfOrganizations.defaultBrokingFees || []
          ).map(fee => {
            return {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              productType: this.productTypes
                .find(productType => productType.id === fee.productTypeId)!
                .toInputOption(),
              brokingFee: fee.brokingFee,
            }
          })
          this.defaultBrokingFeeOfOrganizationLastUpdatedParticipantName =
            defaultBrokingFeeOfOrganizations.lastUpdatedParticipantName
          this.defaultBrokingFeeOfOrganizationUpdatedAt =
            defaultBrokingFeeOfOrganizations.displayUpdatedAt
          this.assignedBrokerOfOrganizationFormOption = brokerUsers.map(
            user => {
              return {
                label: user.name,
                value: user.userId,
              }
            },
          )
        },
      )
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })

    await Promise.all([
      this.fetchAssignedBrokerOfOrganization(
        this.$attrs.organizationId,
      ) as Promise<AssignedBrokerOfOrganization>,
    ])
      .then(async ([assignedBrokerOfOrganization]) => {
        this.isExistAssignedBrokerOfOrganization = true
        if (assignedBrokerOfOrganization?.brokerId) {
          await Promise.all([
            this.getUserById(assignedBrokerOfOrganization.brokerId) as Promise<
              User | undefined
            >,
          ]).then(([brokerUser]) => {
            // Auth0上から削除されている可能性があるため、存在しない場合は「削除されたユーザー」として表示する
            this.businessInfoFormValue.brokerName =
              brokerUser?.name ?? this.$t('trading.message.deletedUser')
          })
        }
        this.businessInfoFormValue.brokerId =
          assignedBrokerOfOrganization.brokerId
      })
      .catch(e => {
        if (isAxiosError(e) && e.request?.status === 404) {
          this.isExistAssignedBrokerOfOrganization = false
        } else {
          this.uiStack = UiStack.Error
          throw e
        }
      })

    this.uiStack = UiStack.Ideal
  },
  methods: {
    ...organizationMapActions([
      'fetchBrokerageFeePaymentConfiguration',
      'fetchDefaultBrokingFeeOfOrganizations',
      'fetchOrganization',
      'updateOrganization',
      'updateOrganizationPaymentConfiguration',
      'updateDefaultBrokingFeeOfOrganization',
    ]),
    ...productMapActions(['fetchProductTypes']),
    ...usersMapActions(['fetchBrokerUsers', 'getUserById']),
    ...assignedBrokerOfOrganizationMapActions([
      'fetchAssignedBrokerOfOrganization',
      'createAssignedBrokerOfOrganization',
      'updateAssignedBrokerOfOrganization',
      'deleteAssignedBrokerOfOrganization',
    ]),
    backListPage() {
      this.$router.push('./')
    },
    onCancelClick() {
      this.backListPage()
    },
    onTradingCompanyNameFormInput(value: TradingCompanyNameFormProps) {
      Object.assign(this.tradingCompanyNameFormValue, value)
    },
    onBrokerageFeePaymentConfigurationFormInput(
      value: BrokerageFeePaymentConfigurationFormProps,
    ) {
      Object.assign(this.brokerageFeePaymentConfigurationFormValue, value)
    },
    onDefaultBrokingFeeOfOrganizationFormInput(
      value: DefaultBrokingFeeOfOrganizationFormProps,
    ) {
      Object.assign(this.defaultBrokingFeeOfOrganizationsFormValue, value)
    },
    onBusinessInfoFormInput(value: BusinessInfoFormProps) {
      Object.assign(this.businessInfoFormValue, value)
    },
    async onUpdateTradingCompanyNameClick() {
      this.tradingCompanyNameSubmitting = true
      await this.updateOrganization({
        organizationId: this.$attrs.organizationId,
        organization: {
          tradingCompanyName: {
            [Locale.Ja]:
              this.tradingCompanyNameFormValue.tradingCompanyName[Locale.Ja] ||
              '',
            [Locale.En]:
              this.tradingCompanyNameFormValue.tradingCompanyName[Locale.En] ||
              '',
          },
        },
      })
        .then(() => {
          setNotification(
            this.$t('iam.message.successUpdateTradingCompanyName').toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t('iam.message.failUpdateTradingCompanyName').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.tradingCompanyNameSubmitting = false
        })
    },
    async onUpdateBrokerageFeePaymentConfigurationClick() {
      this.brokerageFeePaymentConfigurationSubmitting = true
      await this.updateOrganizationPaymentConfiguration({
        organizationId: this.$attrs.organizationId,
        organization: {
          spotTrading: Object.values(BrokerageFeePaymentTiming).find(
            t =>
              t ===
              this.brokerageFeePaymentConfigurationFormValue.spotTrading?.value,
          ),
          derivativeTrading: Object.values(BrokerageFeePaymentTiming).find(
            t =>
              t ===
              this.brokerageFeePaymentConfigurationFormValue.derivativeTrading
                ?.value,
          ),
        },
      })
        .then(() => {
          setNotification(
            this.$t(
              'iam.message.successUpdateBrokerageFeePaymentConfiguration',
            ).toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t(
              'iam.message.failUpdateBrokerageFeePaymentConfiguration',
            ).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.brokerageFeePaymentConfigurationSubmitting = false
        })
    },
    async onUpdateDefaultBrokingFeeOfOrganizationClick() {
      const validateBrokingFees = (
        defaultBrokingFeeOfOrganizations: DefaultBrokingFeeOfOrganizationFormValueProps[],
      ): defaultBrokingFeeOfOrganizations is {
        [key in keyof DefaultBrokingFeeOfOrganizationFormValueProps]: NonNullable<
          DefaultBrokingFeeOfOrganizationFormValueProps[key]
        >
      }[] => {
        return defaultBrokingFeeOfOrganizations.every(
          fee => fee.brokingFee !== undefined,
        )
      }
      if (
        !validateBrokingFees(
          this.defaultBrokingFeeOfOrganizationsFormValue
            .defaultBrokingFeeOfOrganizations,
        )
      ) {
        setNotification(
          this.$t(
            'iam.message.failUpdateDefaultBrokingFeeOfOrganization',
          ).toString(),
          'danger',
        )
        return
      }
      const defaultBrokingFees = this.defaultBrokingFeeOfOrganizationsFormValue.defaultBrokingFeeOfOrganizations.map(
        fee => {
          return {
            productTypeId: fee.productType.value,
            brokingFee: fee.brokingFee,
          }
        },
      )
      const updateDefaultBrokingFeeOfOrganization: UpdateDefaultBrokingFeeOfOrganizations = {
        defaultBrokingFees,
      }
      this.defaultBrokingFeeOfOrganizationSubmitting = true
      await this.updateDefaultBrokingFeeOfOrganization({
        organizationId: this.$attrs.organizationId,
        defaultBrokingFeeOfOrganizations: updateDefaultBrokingFeeOfOrganization,
      })
        .then(() => {
          setNotification(
            this.$t(
              'iam.message.successUpdateDefaultBrokingFeeOfOrganization',
            ).toString(),
          )
          this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t(
              'iam.message.failUpdateDefaultBrokingFeeOfOrganization',
            ).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.defaultBrokingFeeOfOrganizationSubmitting = false
        })
    },
    async onUpdateBusinessInfoClick() {
      this.businessInfoSubmitting = true

      await this.updateIsSalesTaxIncludedInInvoiceAndRecapAddress()
      await this.applyAssignedBrokerOfOrganizationChange()

      this.backListPage()
      this.businessInfoSubmitting = false
    },
    async updateIsSalesTaxIncludedInInvoiceAndRecapAddress() {
      await this.updateOrganization({
        organizationId: this.$attrs.organizationId,
        organization: {
          recapAddress: this.businessInfoFormValue.recapAddress,
          isSalesTaxIncludedInInvoice:
            this.businessInfoFormValue.isSalesTaxIncludedInInvoice ===
            true.toString(),
        },
      })
        .then(() => {
          setNotification(
            this.$t(
              'trading.message.successUpdateIsSalesTaxIncludedInInvoiceAndRecapAddress',
            ).toString(),
          )
        })
        .catch(e => {
          setNotification(
            this.$t(
              'trading.message.failUpdateIsSalesTaxIncludedInInvoiceAndRecapAddress',
            ).toString(),
            'danger',
          )
          this.businessInfoSubmitting = false
          throw e
        })
    },
    async applyAssignedBrokerOfOrganizationChange() {
      if (this.isExistAssignedBrokerOfOrganization) {
        if (this.businessInfoFormValue.brokerId) {
          // 既にbrokerIdが存在して、FormでbrokerIdを指定した場合はPUT
          await this.updateAssignedBrokerOfOrganization({
            organizationId: this.$attrs.organizationId,
            brokerId: this.businessInfoFormValue.brokerId,
          })
            .then(() => {
              setNotification(
                this.$t(
                  'trading.message.successUpdateAssignedBrokerOfOrganization',
                ).toString(),
              )
              this.backListPage()
            })
            .catch(e => {
              setNotification(
                this.$t(
                  'trading.message.failUpdateAssignedBrokerOfOrganization',
                ).toString(),
                'danger',
              )
              this.businessInfoSubmitting = false
              throw e
            })
        } else {
          // 既にbrokerIdが存在して、FormでbrokerIdの指定を外した場合はDELETE
          await this.deleteAssignedBrokerOfOrganization({
            organizationId: this.$attrs.organizationId,
          })
            .then(() => {
              setNotification(
                this.$t(
                  'trading.message.successDeleteAssignedBrokerOfOrganization',
                ).toString(),
              )
              this.backListPage()
            })
            .catch(e => {
              setNotification(
                this.$t(
                  'trading.message.failDeleteAssignedBrokerOfOrganization',
                ).toString(),
                'danger',
              )
              this.businessInfoSubmitting = false
              throw e
            })
        }
      } else {
        if (this.businessInfoFormValue.brokerId) {
          // brokerIdを指定していなく、FormでbrokerIdを指定した場合はPOST
          await this.createAssignedBrokerOfOrganization({
            organizationId: this.$attrs.organizationId,
            brokerId: this.businessInfoFormValue.brokerId,
          })
            .then(() => {
              setNotification(
                this.$t(
                  'trading.message.successCreateAssignedBrokerOfOrganization',
                ).toString(),
              )
            })
            .catch(e => {
              const message =
                e.response.status === HttpStatus.Conflict
                  ? this.$t(
                      'trading.message.failCreateAssignedBrokerOfOrganizationByDuplicate',
                      {
                        name: this.businessInfoFormValue.brokerName,
                      },
                    ).toString()
                  : this.$t(
                      'trading.message.failCreateAssignedBrokerOfOrganization',
                    ).toString()

              setNotification(message, 'danger')
              this.businessInfoSubmitting = false
              throw e
            })
        }
      }
    },
  },
})
