import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d977944c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.level.isLv1)
      ? (_openBlock(), _createElementBlock("h1", {
          key: 0,
          class: _normalizeClass(_ctx.className)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
      : (_ctx.level.isLv2)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 1,
            class: _normalizeClass(_ctx.className)
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2))
        : (_ctx.level.isLv3)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 2,
              class: _normalizeClass(_ctx.className)
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 2))
          : (_ctx.level.isLv4)
            ? (_openBlock(), _createElementBlock("h4", {
                key: 3,
                class: _normalizeClass(_ctx.className)
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2))
            : (_openBlock(), _createElementBlock("h5", {
                key: 4,
                class: _normalizeClass(_ctx.className)
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2))
  ]))
}