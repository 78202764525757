
import { defineComponent, PropType } from 'vue'

import { HeaderLevel } from '@/components/common/constants/TypographyLevel'

// https://www.notion.so/enechain/98fbe159814e464cbb917ef5e8d71b8d#3c0f5576eaea4f968d1e1cc04998a454
export default defineComponent({
  name: 'Heading',
  props: {
    type: {
      type: String as PropType<HeaderLevel>,
      required: true,
    },
  },
  computed: {
    level() {
      return {
        isLv1: this.type === HeaderLevel.Lv1.toString(),
        isLv2: this.type === HeaderLevel.Lv2.toString(),
        isLv3: this.type === HeaderLevel.Lv3.toString(),
        isLv4: this.type === HeaderLevel.Lv4.toString(),
      }
    },
    className() {
      return `heading--${this.type}`
    },
  },
})
