import { Mutations } from 'vuex-smart-module'

import { InputOption } from '@/components/common/interface/InputOption'
import { MultiLocaleMessageBody } from '@/models/chat/MultiLocaleMessageBody'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'
import { ChatBroadcastState } from '@/store/modules/chat/broadcast/ChatBroadcastState'

export const SET_RECIPIENT_SEARCH_INPUT = 'SET_RECIPIENT_SEARCH_IN'
export const SET_RECIPIENT_SELECTED_LANGUAGE = 'SET_RECIPIENT_SELECTED_LANGUAGE'
export const SET_LOCKED = 'SET_LOCKED'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_ORGANIZATION_CHANNELS = 'SET_ORGANIZATION_CHANNELS'
export const SET_SELECTED_ORGANIZATION_CHANNEL_IDS =
  'SET_SELECTED_ORGANIZATION_CHANNEL_IDS'

export class ChatBroadcastMutations extends Mutations<ChatBroadcastState> {
  [SET_ORGANIZATION_CHANNELS](payload: {
    organizationChannels: OrganizationChannel[]
  }) {
    this.state.organizationChannels = payload.organizationChannels
  }

  [SET_RECIPIENT_SEARCH_INPUT](payload: { recipientSearchInput: string }) {
    this.state.recipientSearchInput = payload.recipientSearchInput
  }

  [SET_RECIPIENT_SELECTED_LANGUAGE](payload: {
    recipientSelectedLanguage: undefined | InputOption
  }) {
    this.state.recipientSelectedLanguage = payload.recipientSelectedLanguage
  }

  [SET_LOCKED](payload: { locked: boolean }) {
    this.state.locked = payload.locked
  }

  [SET_SELECTED_ORGANIZATION_CHANNEL_IDS](payload: {
    selectedOrganizationChannelIds: string[]
  }) {
    this.state.selectedOrganizationChannelIds =
      payload.selectedOrganizationChannelIds
  }

  [SET_MESSAGE](payload: { message: string | MultiLocaleMessageBody }) {
    this.state.message = payload.message
  }
}
