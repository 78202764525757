import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-371b20a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tocom-broker-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selected_tocom_broker = _resolveComponent("selected-tocom-broker")!
  const _component_tocom_broker_selector_modal = _resolveComponent("tocom-broker-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedTocomBroker)
      ? (_openBlock(), _createBlock(_component_selected_tocom_broker, {
          key: 0,
          "is-selectable": _ctx.isSelectable,
          "selected-tocom-broker": _ctx.selectedTocomBroker,
          onClick: _ctx.onClick
        }, null, 8, ["is-selectable", "selected-tocom-broker", "onClick"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["tocom-broker-selector__select-button--not-selected", {
        'tocom-broker-selector__select-button--not-selected-selectable': _ctx.isSelectable,
      }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.$t('trading.message.selectTocomBroker')), 3)),
    _createVNode(_component_base_modal, {
      title: _ctx.$t('trading.label.selectTocomBroker'),
      name: _ctx.modalName,
      width: 800
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tocom_broker_selector_modal, {
          "organization-id": _ctx.organizationId,
          onSelect: _ctx.onSelect,
          onCancel: _ctx.onCancel
        }, null, 8, ["organization-id", "onSelect", "onCancel"])
      ]),
      _: 1
    }, 8, ["title", "name"])
  ]))
}