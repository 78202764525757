import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1249714"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-price" }
const _hoisted_2 = { class: "order-price__order-left-wrapper" }
const _hoisted_3 = { class: "order-price__order-right-wrapper" }
const _hoisted_4 = { class: "order-price__last-deal-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unit_price_and_volume = _resolveComponent("unit-price-and-volume")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isShownLeft)
        ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
            key: 0,
            "unit-price": _ctx.order.unitPrice,
            volume: _ctx.order.volume,
            "fuel-surcharge-type": _ctx.order.fuelSurchargeType,
            position: _ctx.order.positionType,
            "color-reversed": _ctx.colorReversed,
            "light-font": !_ctx.order.openToMarket,
            "exchange-volume": _ctx.order.exchangeVolume
          }, null, 8, ["unit-price", "volume", "fuel-surcharge-type", "position", "color-reversed", "light-font", "exchange-volume"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isShownRight)
        ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
            key: 0,
            "unit-price": _ctx.order.unitPrice,
            volume: _ctx.order.volume,
            "fuel-surcharge-type": _ctx.order.fuelSurchargeType,
            position: _ctx.order.positionType,
            "color-reversed": _ctx.colorReversed,
            "light-font": !_ctx.order.openToMarket,
            "exchange-volume": _ctx.order.exchangeVolume
          }, null, 8, ["unit-price", "volume", "fuel-surcharge-type", "position", "color-reversed", "light-font", "exchange-volume"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.order.isLast)
        ? (_openBlock(), _createBlock(_component_unit_price_and_volume, {
            key: 0,
            class: "order-price__last-deal",
            "unit-price": _ctx.order.unitPrice,
            volume: _ctx.order.volume,
            "fuel-surcharge-type": _ctx.order.fuelSurchargeType,
            "executed-at": _ctx.order.executedAt,
            "light-font": !!_ctx.order.dealPrivateConfidential,
            "exchange-volume": _ctx.order.exchangeVolume
          }, null, 8, ["unit-price", "volume", "fuel-surcharge-type", "executed-at", "light-font", "exchange-volume"]))
        : _createCommentVNode("", true)
    ])
  ]))
}