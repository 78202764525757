
import { defineComponent } from 'vue'

import AppSwitcher from '@/components/common/AppSwitcher.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import DropdownNavigation from '@/components/common/DropdownNavigation.vue'
import { DropdownNavigationItem } from '@/components/common/interface/DropdownNavigationProps'
import { pushGtag } from '@/utils/ga'

export default defineComponent({
  name: 'GlobalHeader',
  components: {
    AppSwitcher,
    BaseIcon,
    BasePicture,
    DropdownNavigation,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    isMember: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDropdownNavigationActive: false,
    }
  },
  computed: {
    dropdownNavItems(): DropdownNavigationItem[] {
      const {
        eCloudProfile,
        userProfile,
        recapConfiguration,
        mailSetting,
        signOut,
      } = this.getDropdownItems()

      return this.isMember
        ? [eCloudProfile, userProfile, signOut]
        : [eCloudProfile, userProfile, recapConfiguration, mailSetting, signOut]
    },
  },
  methods: {
    getDropdownLabel(id: string) {
      return this.$t(`iam.label.${id}`).toString()
    },
    getDropdownItems() {
      return {
        eCloudProfile: {
          label: this.getDropdownLabel('eCloudProfile'),
          href: `${process.env.VUE_APP_ECLOUD_URL}/profile`,
        },
        userProfile: {
          label: this.getDropdownLabel('userProfile'),
          to: '/user-profile',
        },
        recapConfiguration: {
          label: this.getDropdownLabel('recapConfiguration'),
          to: '/recap-configuration/recap-receivers',
        },
        mailSetting: {
          label: this.getDropdownLabel('mailSetting'),
          to: '/email-setting/members',
        },
        signOut: {
          label: this.getDropdownLabel('signOut'),
          onClick: () => {
            const path = `${process.env.VUE_APP_BASE_URL}`.endsWith('/')
              ? process.env.VUE_APP_BASE_URL
              : `${process.env.VUE_APP_BASE_URL}/`

            this.$auth.logout({
              returnTo: `${location.origin}${path}`,
            })
          },
        },
      }
    },
    onQuestionPictureClick() {
      pushGtag('ヘルプ_クリック_EN')
    },
    onUserPictureClick() {
      this.isDropdownNavigationActive = !this.isDropdownNavigationActive
      // ドロップダウンリストが出てくるタイミングで発火する
      if (this.isDropdownNavigationActive) {
        pushGtag('設定_クリック_EN')
      }
    },
  },
})
