
import { defineComponent, PropType } from 'vue'

import DealDeliveryPeriodHeader from '@/components/trading/DealDeliveryPeriodHeader.vue'
import { DeliveryPeriod } from '@/models/trading/DeliveryPeriod'

export default defineComponent({
  name: 'DealDeliveryPeriodList',
  components: {
    DealDeliveryPeriodHeader,
  },
  props: {
    deliveryPeriods: {
      type: Array as PropType<DeliveryPeriod[]>,
      default: () => [],
    },
    unitPrices: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    volumes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  computed: {
    rows(): {
      deliveryStartDate: string
      deliveryEndDate: string
      hourTypeNamesWithTimeRanges: string
      unitPrice: string
      volume: string
    }[] {
      return this.deliveryPeriods.map((deliveryPeriod, index) => {
        const deliveryStartDate = deliveryPeriod.displayDeliveryStartDate()
        const deliveryEndDate = deliveryPeriod.displayDeliveryEndDate()
        const hourTypeNamesWithTimeRanges = deliveryPeriod.deliveryPattern.displayHourTypeNamesWithTimeRanges()

        return {
          deliveryStartDate,
          deliveryEndDate,
          hourTypeNamesWithTimeRanges,
          unitPrice: this.unitPrices[index],
          volume: this.volumes[index],
        }
      })
    },
  },
})
