import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28ad7660"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "base-chat-window__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "base-chat-window",
    style: _normalizeStyle({
      height: `${_ctx.heightComputed}px`,
      width: `${_ctx.width}px`,
      ..._ctx.styleVars,
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["base-chat-window__title-bar", { 'base-chat-window__title-bar--unread': _ctx.hasUnreadMessage }]),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onTitleBarClick && _ctx.onTitleBarClick(...args)), ["self"]))
    }, [
      _renderSlot(_ctx.$slots, "title-bar", {}, undefined, true)
    ], 2),
    (_ctx.isOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}