import { Module } from 'vuex-smart-module'

import { ChatBroadcastActions } from '@/store/modules/chat/broadcast/ChatBroadcastActions'
import { ChatBroadcastGetters } from '@/store/modules/chat/broadcast/ChatBroadcastGetters'
import { ChatBroadcastMutations } from '@/store/modules/chat/broadcast/ChatBroadcastMutations'
import { ChatBroadcastState } from '@/store/modules/chat/broadcast/ChatBroadcastState'

export const chatBroadcast = new Module({
  state: ChatBroadcastState,
  getters: ChatBroadcastGetters,
  mutations: ChatBroadcastMutations,
  actions: ChatBroadcastActions,
})

export type ChatBroadcastModule = typeof chatBroadcast
