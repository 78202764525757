import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d566978"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-confirm-modal-content" }
const _hoisted_2 = { class: "deal-confirm-modal-content__message" }
const _hoisted_3 = { class: "deal-confirm-modal-content__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_organization_recap_form = _resolveComponent("organization-recap-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
    _createVNode(_component_base_box, { class: "deal-confirm-modal-content__box" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('trading.label.dealCondition')), 1),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.negotiationId'),
          data: _ctx.dealWithOrganizationRecap.deal.publicNegotiationId
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.executedAt'),
          data: _ctx.displayExecutedAt
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.privateConfidentialOfDealDetail'),
          data: 
          _ctx.formValue.privateConfidential
            ? _ctx.$t('common.label.yes')
            : _ctx.$t('common.label.no')
        
        }, null, 8, ["label", "data"]),
        (!_ctx.dealWithOrganizationRecap.isSpread)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 0,
              direction: "column",
              label: _ctx.$t('trading.label.openingSettingsOfVolume'),
              data: 
          _ctx.formValue.maskVolume ? _ctx.$t('common.label.yes') : _ctx.$t('common.label.no')
        
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_base_box, { class: "deal-confirm-modal-content__box" }, {
      default: _withCtx(() => [
        (_ctx.isCfdAndIsNotAreaSpread)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 0,
              direction: "column",
              label: _ctx.$t('trading.label.settlementUnitPriceArea'),
              data: _ctx.formValue.settlementUnitPriceAreaInputOption.label
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.restrictions'),
          data: _ctx.formValue.restrictions
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.notes'),
          data: _ctx.formValue.note
        }, null, 8, ["label", "data"]),
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.remarks'),
          data: _ctx.formValue.remarks
        }, null, 8, ["label", "data"]),
        (_ctx.notIncludingTocomOrEex)
          ? (_openBlock(), _createBlock(_component_base_dataset, {
              key: 1,
              direction: "column",
              label: _ctx.$t('trading.label.paymentDueInformation'),
              data: _ctx.formValue.paymentDueInformation
            }, null, 8, ["label", "data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_organization_recap_form, {
      "is-editable": false,
      "base-organization-recap": 
        _ctx.dealWithOrganizationRecap.recapOfAskOrganization
      ,
      "exchange-organization-recap": 
        _ctx.dealWithOrganizationRecap.recapOfExchangeAskOrganization
      ,
      "name-prefix": "ask",
      areas: _ctx.areas,
      locale: _ctx.locale,
      position: _ctx.ask,
      "form-values": _ctx.formValue.recapsOfAskOrganizationFormValues,
      "is-spread": _ctx.dealWithOrganizationRecap.isSpread
    }, null, 8, ["base-organization-recap", "exchange-organization-recap", "areas", "locale", "position", "form-values", "is-spread"]),
    _createVNode(_component_organization_recap_form, {
      "is-editable": false,
      "base-organization-recap": 
        _ctx.dealWithOrganizationRecap.recapOfBidOrganization
      ,
      "exchange-organization-recap": 
        _ctx.dealWithOrganizationRecap.recapOfExchangeBidOrganization
      ,
      "name-prefix": "bid",
      areas: _ctx.areas,
      locale: _ctx.locale,
      position: _ctx.bid,
      "form-values": _ctx.formValue.recapsOfBidOrganizationFormValues,
      "is-spread": _ctx.dealWithOrganizationRecap.isSpread
    }, null, 8, ["base-organization-recap", "exchange-organization-recap", "areas", "locale", "position", "form-values", "is-spread"]),
    _createVNode(_component_base_box, { class: "deal-confirm-modal-content__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_dataset, {
          direction: "column",
          label: _ctx.$t('trading.label.excludedEnecCurve'),
          data: 
          _ctx.formValue.excludedEnecCurve
            ? _ctx.$t('common.label.yes')
            : _ctx.$t('common.label.no')
        
        }, null, 8, ["label", "data"])
      ]),
      _: 1
    })
  ]))
}