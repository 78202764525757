import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2353ba16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-template" }
const _hoisted_2 = { class: "page-template__header" }
const _hoisted_3 = { class: "page-template__side-menu" }
const _hoisted_4 = { class: "page-template__carousel-banner" }
const _hoisted_5 = { class: "page-template__main" }
const _hoisted_6 = { class: "page-template__title" }
const _hoisted_7 = { class: "page-template__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_carousel_banner = _resolveComponent("carousel-banner")!
  const _component_heading = _resolveComponent("heading")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "side-menu", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isEsquareEnabled)
        ? (_openBlock(), _createBlock(_component_carousel_banner, {
            key: 0,
            informations: _ctx.filteredActiveInformations,
            onClose: _ctx.onClose
          }, null, 8, ["informations", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["page-template__main-wrapper", `page-template__main-wrapper--${_ctx.layoutMode}`])
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_heading, { type: "header1" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ])
      ])
    ], 2),
    _createVNode(_component_notifications, {
      classes: "app-notification",
      position: "bottom left",
      width: "450"
    })
  ]))
}