
import { defineComponent, PropType } from 'vue'

import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTextArea from '@/components/common/BaseTextArea.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { BusinessInfoFormProps } from '@/components/iam/interface/BusinessInfoFormProps'

export default defineComponent({
  name: 'BusinessInfoForm',
  components: {
    BaseButton,
    BaseIcon,
    BaseInputRadio,
    BaseInputText,
    BaseSingleSelect,
    BaseTextArea,
  },
  props: {
    brokerIdsInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    formValue: {
      type: Object as PropType<BusinessInfoFormProps>,
      required: true,
    },
    salesTaxIncludedInInvoiceInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
    selectedBrokerId?: InputOption
  } {
    return {
      formInputEventName: 'form-input',
      selectedBrokerId:
        this.formValue.brokerId && this.formValue.brokerName
          ? {
              label: this.formValue.brokerName,
              value: this.formValue.brokerId,
            }
          : undefined,
    }
  },
  computed: {
    getBrokerIdInputOptions(): InputOption[] {
      return this.brokerIdsInputOptions.length === 0 &&
        this.formValue.brokerId &&
        this.formValue.brokerName
        ? [
            {
              label: this.formValue.brokerName,
              value: this.formValue.brokerId,
            },
          ]
        : this.brokerIdsInputOptions
    },
  },
  methods: {
    onChangeBrokerId(input?: InputOption) {
      this.selectedBrokerId = input
      this.$emit(this.formInputEventName, { brokerId: input?.value ?? null })
    },
  },
})
