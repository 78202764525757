import { NegotiationStatusType } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

export class NegotiationStatusTypeOptions {
  get options(): InputOption[] {
    return [
      {
        value: NegotiationStatusType.UnderNegotiation.toString(),
        label: i18n
          .t('trading.label.negotiationStatusType.underNegotiation')
          .toString(),
      },
      {
        value: NegotiationStatusType.Executed.toString(),
        label: i18n
          .t('trading.label.negotiationStatusType.executed')
          .toString(),
      },
      {
        value: NegotiationStatusType.Broken.toString(),
        label: i18n.t('trading.label.negotiationStatusType.broken').toString(),
      },
      {
        value: NegotiationStatusType.Deleted.toString(),
        label: i18n.t('trading.label.negotiationStatusType.deleted').toString(),
      },
    ]
  }

  label(negotiationStatusType: NegotiationStatusType): string {
    return (
      this.options.find(option => option.value === negotiationStatusType)
        ?.label || ''
    )
  }
}
