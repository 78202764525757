
import { defineComponent } from '@vue/runtime-dom'
import marked from 'marked'
import { createNamespacedHelpers } from 'vuex'

import { Locale } from '@/api/generated'
import privacyPolicyEn from '@/assets/doc/privacy-policy-en.md'
import privacyPolicyJa from '@/assets/doc/privacy-policy-ja.md'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { GlobalModule } from '@/store/modules/common/global'
import { SET_IS_INITIALIZING } from '@/store/modules/common/global/GlobalMutations'

const { mapMutations: gloalMapMutations } = createNamespacedHelpers(
  'global',
) as GlobalModule

export default defineComponent({
  name: 'PrivacyPolicy',
  components: {
    BaseBox,
    BaseSingleSelect,
  },
  data(): {
    locale: {
      value: Locale
      label: string
    }
    localeOptions: InputOption[]
  } {
    return {
      locale: {
        value: Locale.Ja,
        label: this.$t('common.label.japanese', Locale.Ja).toString(),
      },
      localeOptions: [
        {
          value: Locale.Ja,
          label: this.$t('common.label.japanese', Locale.Ja).toString(),
        },
        {
          value: Locale.En,
          label: this.$t('common.label.english', Locale.En).toString(),
        },
      ] as InputOption[],
    }
  },
  computed: {
    privacyPolicyContent(): string {
      return this.locale.value === Locale.Ja
        ? marked(privacyPolicyJa)
        : marked(privacyPolicyEn)
    },
  },
  created() {
    this[SET_IS_INITIALIZING]({ isInitializing: false })
  },
  methods: {
    ...gloalMapMutations([SET_IS_INITIALIZING]),
    onLocaleInput(event: { value: Locale }) {
      const label =
        event.value === Locale.Ja
          ? this.$t('common.label.japanese', Locale.Ja).toString()
          : this.$t('common.label.english', Locale.En).toString()
      this.locale = {
        value: event.value,
        label,
      }
    },
  },
})
