import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31c9108c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public-order-book-list" }
const _hoisted_2 = { class: "public-order-book-list__header-wrapper" }
const _hoisted_3 = { class: "public-order-book-list__body-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_public_order_book_list_header = _resolveComponent("public-order-book-list-header")!
  const _component_public_order_book = _resolveComponent("public-order-book")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_public_order_book_list_header, {
        locale: _ctx.locale,
        "color-reversed": _ctx.colorReversed,
        "position-reversed": _ctx.positionReversed,
        "all-collapsed": _ctx.allCollapsed,
        "with-fuel-surcharge": _ctx.withFuelSurcharge,
        onActivateAll: _ctx.onActivateAll,
        onDeactivateAll: _ctx.onDeactivateAll
      }, null, 8, ["locale", "color-reversed", "position-reversed", "all-collapsed", "with-fuel-surcharge", "onActivateAll", "onDeactivateAll"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_public_order_book, _mergeProps({ key: index }, item, {
          onExpand: _ctx.onExpand,
          onButtonClick: _ctx.onButtonClick
        }), null, 16, ["onExpand", "onButtonClick"]))
      }), 128))
    ])
  ]))
}