import { RouteLocationNormalized } from 'vue-router'

import { Status } from '@/api/generated'
import router from '@/router'
import { root, store } from '@/store'

const ctx = root.context(store)
const userProfileCtx = ctx.modules.userProfile
const welcomePageName = 'Welcome'

// HACK: 条件分岐がネストしてて嫌だ
export const welcomeGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: CallableFunction,
) => {
  if (to.meta?.isPublic) {
    return next()
  }

  const fn = () => {
    // activeな人はwelcomeページには行けない
    if (userProfileCtx.state.status === Status.Active) {
      if (to.name === welcomePageName) {
        window.location.href = `${process.env.VUE_APP_ECLOUD_URL}`
      } else {
        return next()
      }
    }
    // invitedな人はwelcomeページしか行くことができない
    else if (userProfileCtx.state.status === Status.Invited) {
      if (to.name === welcomePageName) {
        return next()
      }
      router.replace({ name: welcomePageName })
    }
  }

  if (userProfileCtx.state.status !== undefined) {
    return fn()
  }
}
