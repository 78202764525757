
import { defineComponent, PropType } from 'vue'

import { InnerLabelLevel } from '@/components/common/constants/TypographyLevel'

// https://www.notion.so/enechain/98fbe159814e464cbb917ef5e8d71b8d#3c0f5576eaea4f968d1e1cc04998a454
export default defineComponent({
  name: 'InnerLabel',
  props: {
    type: {
      type: String as PropType<InnerLabelLevel>,
      required: true,
    },
  },
  computed: {
    className() {
      return `inner-label--${this.type}`
    },
  },
})
