
import { defineComponent, PropType } from 'vue'
import { DynamicScroller } from 'vue-virtual-scroller'

import Message from '@/components/chat/Message.vue'
import { MessageProps } from '@/components/chat/interface/MessageProps'

export default defineComponent({
  name: 'MessageList',
  components: {
    Message,
  },
  props: {
    messages: {
      type: Array as PropType<MessageProps[]>,
      default: () => [],
    },
    size: {
      type: String,
      default: 'regular',
    },
    pictureSize: {
      type: String,
      default: 'regular',
    },
  },
  emits: ['touch-start', 'read-message'],
  data() {
    return {
      isBottom: false,
    }
  },
  watch: {
    messages: {
      handler() {
        if (this.isBottom) {
          this.scrollToBottom()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.scrollToBottom()
  },
  methods: {
    onUpdate(startIndex: number, endIndex: number) {
      if (startIndex === 0) {
        this.$emit('touch-start')
      }
      this.isBottom = endIndex === this.messages.length
    },
    scrollToBottom() {
      const scroller = this.$refs.scroller as InstanceType<
        typeof DynamicScroller
      >
      if (scroller) {
        scroller.scrollToBottom()
      }
    },
    async scrollToItem(index: number) {
      if (
        this.messages.length > 0 &&
        index > 0 &&
        // TODO: 呼び出し側で messages の件数より大きな index が指定されるため。呼び出し側を修正したい
        index <= this.messages.length
      ) {
        const scroller = this.$refs.scroller as InstanceType<
          typeof DynamicScroller
        >
        if (scroller) {
          scroller.scrollToItem(index)
          await this.sleep(1)
          scroller.scrollToItem(index)
        }
      }
    },
    sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    onReadMessage(index: number) {
      this.$emit('read-message', index)
    },
  },
})
