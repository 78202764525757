import { Module } from 'vuex-smart-module'

import { IndicationActions } from '@/store/modules/trading/indication/IndicationActions'
import { IndicationGetters } from '@/store/modules/trading/indication/IndicationGetters'
import { IndicationMutations } from '@/store/modules/trading/indication/IndicationMutations'
import { IndicationState } from '@/store/modules/trading/indication/IndicationState'

export const indication = new Module({
  actions: IndicationActions,
  getters: IndicationGetters,
  mutations: IndicationMutations,
  state: IndicationState,
})

export type IndicationModule = typeof indication
