
import { defineComponent } from 'vue'

import { IndicationStatusType } from '@/api/generated'
import BaseIcon from '@/components/common/BaseIcon.vue'

export default defineComponent({
  name: 'BrokingIndicationStatus',
  components: {
    BaseIcon,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusIcon(): string {
      if (this.status === IndicationStatusType.Active) {
        return 'check-circle'
      }
      if (this.status === IndicationStatusType.Negotiating) {
        return 'comments'
      }
      return 'exclamation-circle'
    },
    statusIconClass(): string {
      const baseClassName = 'broking-indication-status'

      if (this.status === IndicationStatusType.Active) {
        return baseClassName + '--active'
      }
      if (this.status === IndicationStatusType.Negotiating) {
        return baseClassName + '--negotiating'
      }
      return baseClassName + '--unconfirmed'
    },
  },
})
