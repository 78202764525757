import { PositionType } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

export class PositionOptions {
  positionReversed: boolean

  constructor(positionReversed = false) {
    this.positionReversed = positionReversed
  }

  get options(): InputOption[] {
    return [
      {
        value: this.positionReversed ? PositionType.Bid : PositionType.Ask,
        label: this.positionReversed
          ? i18n.t('trading.label.bid').toString()
          : i18n.t('trading.label.ask').toString(),
      },
      {
        value: this.positionReversed ? PositionType.Ask : PositionType.Bid,
        label: this.positionReversed
          ? i18n.t('trading.label.ask').toString()
          : i18n.t('trading.label.bid').toString(),
      },
    ]
  }

  label(position: PositionType): string {
    const option = this.options.find(option => option.value === position)
    return option ? option.label : ''
  }
}
