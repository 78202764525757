
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'

export default defineComponent({
  name: 'BaseNavItem',
  components: {
    BaseIcon,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
  },
})
