
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { UpdateTOCOMClearingInformation } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import BrokingEexClearingInformationForm from '@/components/iam/BrokingEexClearingInformationForm.vue'
import EexClearingInformationForm from '@/components/iam/EexClearingInformationForm.vue'
import TocomClearingInformationForm from '@/components/iam/TocomClearingInformationForm.vue'
import { ClearingInformation } from '@/models/iam/ClearingInformation'
import { ClearingInformationParticipantSummaryItem } from '@/models/iam/ClearingInformationParticipantSummaryItem'
import { EexClearingAccount } from '@/models/iam/EexClearingAccount'
import { TocomBroker } from '@/models/iam/TocomBroker'
import { TocomClearingInformation } from '@/models/iam/TocomClearingInformation'
import { TocomClearingInformationItem } from '@/models/iam/TocomClearingInformationItem'
import { EexTradingFirm } from '@/models/trading/EexTradingFirm'
import { ClearingInformationModule } from '@/store/modules/iam/clearingInformation'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const { mapActions: clearingInformationMapActions } = createNamespacedHelpers(
  'clearingInformation',
) as ClearingInformationModule

export default defineComponent({
  name: 'ClearingInformation',
  components: {
    BaseAlert,
    BaseButton,
    BrokingEexClearingInformationForm,
    CenteredLoadingContent,
    ConfirmationDialog,
    EexClearingInformationForm,
    TocomClearingInformationForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    uiStack: UiStack
    errorType: AlertType.Error
    eexClearingInformation: {
      tradingFirms: EexTradingFirm[]
      selectedTradingFirm: EexTradingFirm | undefined
      clearingAccounts: EexClearingAccount[]
    }
    tocomClearingInformation: {
      brokers: TocomBroker[]
      clearingInformation: TocomClearingInformationItem[]
      participantSummary: ClearingInformationParticipantSummaryItem | undefined
    }
  } {
    return {
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      eexClearingInformation: {
        tradingFirms: [],
        selectedTradingFirm: undefined,
        clearingAccounts: [],
      },
      tocomClearingInformation: {
        brokers: [],
        clearingInformation: [],
        participantSummary: undefined,
      },
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    basePath(): string {
      return this.$route.path.replace(/\/[^/]+\/?$/, '')
    },
  },
  async mounted() {
    try {
      const [
        tocomBrokers,
        tocomClearingInformation,
        selectedTradingFirm,
        clearingAccounts,
      ] = await Promise.all([
        this.fetchTocomBrokers(),
        this.fetchTocomClearingInformation(this._organizationId),
        this.fetchEexTradingFirmOfOrganization({
          organizationId: this._organizationId,
        }),
        this.fetchEexClearingAccounts({ organizationId: this._organizationId }),
      ])

      this.tocomClearingInformation = {
        ...this.tocomClearingInformation,
        brokers: tocomBrokers,
        clearingInformation: tocomClearingInformation.items,
        participantSummary: tocomClearingInformation.participantSummary,
      }

      if (this.isBrokerApp()) {
        const tradingFirms = await this.fetchEexTradingFirms()
        this.eexClearingInformation = {
          ...this.eexClearingInformation,
          tradingFirms,
          selectedTradingFirm,
          clearingAccounts,
        }
      } else {
        this.eexClearingInformation = {
          ...this.eexClearingInformation,
          selectedTradingFirm,
          clearingAccounts,
        }
      }

      this.uiStack = UiStack.Ideal
    } catch (e) {
      this.uiStack = UiStack.Error
      throw e
    }
  },
  methods: {
    isBrokerApp() {
      return isBrokerApp
    },
    updateEexTradingFirm: clearingInformationMapActions([
      'updateEexTradingFirm',
    ]).updateEexTradingFirm as (payload: {
      organizationId: string
      eexTradingFirmCode: string | undefined
    }) => Promise<void>,
    updateTocomClearingInformation: clearingInformationMapActions([
      'updateTocomClearingInformation',
    ]).updateTocomClearingInformation as (payload: {
      organizationId: string
      tocomClearingInformation: UpdateTOCOMClearingInformation
    }) => Promise<TocomClearingInformationItem[]>,
    fetchClearingInformation: clearingInformationMapActions([
      'fetchClearingInformation',
    ]).fetchClearingInformation as (
      organizationId: string,
    ) => Promise<ClearingInformation>,
    fetchTocomBrokers: clearingInformationMapActions(['fetchTocomBrokers'])
      .fetchTocomBrokers as () => Promise<TocomBroker[]>,
    fetchTocomClearingInformation: clearingInformationMapActions([
      'fetchTocomClearingInformation',
    ]).fetchTocomClearingInformation as (
      organizationId: string,
    ) => Promise<TocomClearingInformation>,
    fetchEexTradingFirms: clearingInformationMapActions([
      'fetchEexTradingFirms',
    ]).fetchEexTradingFirms as () => Promise<EexTradingFirm[]>,
    fetchEexTradingFirmOfOrganization: clearingInformationMapActions([
      'fetchEexTradingFirmOfOrganization',
    ]).fetchEexTradingFirmOfOrganization as (payload: {
      organizationId: string
    }) => Promise<EexTradingFirm | undefined>,
    fetchEexClearingAccounts: clearingInformationMapActions([
      'fetchEexClearingAccounts',
    ]).fetchEexClearingAccounts as (payload: {
      organizationId: string
    }) => Promise<EexClearingAccount[]>,
    async onUpdateEexClearingInformation(
      eexTradingFirm: EexTradingFirm | undefined,
    ) {
      if (!this.isBrokerApp()) {
        return
      }

      try {
        await this.updateEexTradingFirm({
          organizationId: this._organizationId,
          eexTradingFirmCode: eexTradingFirm?.eexTradingFirmCode,
        })

        setNotification(
          this.$t('iam.message.successUpdateClearingInformation').toString(),
        )
      } catch (e) {
        setNotification(
          this.$t('iam.message.failUpdateClearingInformation').toString(),
          'danger',
        )
      } finally {
        const [
          tradingFirms,
          selectedTradingFirm,
          clearingAccounts,
        ] = await Promise.all([
          this.fetchEexTradingFirms(),
          this.fetchEexTradingFirmOfOrganization({
            organizationId: this._organizationId,
          }),
          this.fetchEexClearingAccounts({
            organizationId: this._organizationId,
          }),
        ])

        this.eexClearingInformation = {
          ...this.eexClearingInformation,
          tradingFirms,
          selectedTradingFirm,
          clearingAccounts,
        }
      }
    },
    async onUpdateTocomClearingInformation(tocomBrokers: TocomBroker[]) {
      try {
        await this.updateTocomClearingInformation({
          organizationId: this._organizationId,
          tocomClearingInformation: {
            items: tocomBrokers.map(broker => ({ tocomBrokerId: broker.id })),
          },
        })
        setNotification(
          this.$t('iam.message.successUpdateClearingInformation').toString(),
        )
      } catch (e) {
        setNotification(
          this.$t('iam.message.failUpdateClearingInformation').toString(),
          'danger',
        )
      } finally {
        const clearingInformation = await this.fetchTocomClearingInformation(
          this._organizationId,
        )
        this.tocomClearingInformation = {
          ...this.tocomClearingInformation,
          clearingInformation: clearingInformation.items,
          participantSummary: clearingInformation.participantSummary,
        }
      }
    },
  },
})
