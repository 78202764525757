import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fuel-surcharge-type-icon", `fuel-surcharge-type-icon--${_ctx.fuelSurchargeType.type}`])
  }, [
    _createVNode(_component_base_tooltip, {
      label: _ctx.fuelSurchargeType.name
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.iconType), 1)
      ]),
      _: 1
    }, 8, ["label"])
  ], 2))
}