import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3308966"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recap-receiver-new" }
const _hoisted_2 = { class: "recap-receiver-new__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_recap_receiver_form = _resolveComponent("recap-receiver-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_validation_form, { class: "recap-receiver-new__form" }, {
      default: _withCtx(({ errors }) => [
        _createVNode(_component_recap_receiver_form, {
          "form-value": _ctx.formValue,
          onFormInput: _ctx.onFormInput
        }, null, 8, ["form-value", "onFormInput"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_button, {
            class: "recap-receiver-new__cancel-button",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_base_button, {
            color: "primary",
            disabled: Object.keys(errors).length > 0,
            submitting: _ctx.submitting,
            onClick: _ctx.onSaveClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "submitting", "onClick"])
        ])
      ]),
      _: 1
    })
  ]))
}