
import { defineComponent } from 'vue'

import { PositionType } from '@/api/generated'

export default defineComponent({
  name: 'PositionIcon',
  props: {
    position: {
      type: String,
      validator: (value: PositionType) =>
        [PositionType.Ask.toString(), PositionType.Bid.toString()].includes(
          value,
        ),
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return this.position === PositionType.Ask.toString()
        ? this.$t('trading.label.askIcon')
        : this.$t('trading.label.bidIcon')
    },
    iconClass() {
      let cls = 'position-icon__'
      if (this.position === PositionType.Ask.toString()) {
        if (this.colorReversed) {
          cls += PositionType.Bid
        } else {
          cls += PositionType.Ask
        }
      } else {
        if (this.colorReversed) {
          cls += PositionType.Ask
        } else {
          cls += PositionType.Bid
        }
      }
      return cls
    },
  },
})
