import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_icon = _resolveComponent("loading-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.className)
  }, [
    _createVNode(_component_loading_icon, {
      class: "centered-loading-content__icon",
      type: _ctx.type
    }, null, 8, ["type"])
  ], 2))
}