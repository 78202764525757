
import { defineComponent, PropType } from 'vue'

import { Locale, OcctoMasterType } from '@/api/generated'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import { OcctoMasterItemFormProps } from '@/components/iam/interface/OcctoMasterItemFormProps'
import { Area } from '@/models/trading/Area'

export default defineComponent({
  name: 'OcctoMasterItemForm',
  components: {
    BaseInputRadio,
    BaseInputText,
    BaseSingleSelect,
  },
  props: {
    fixedType: {
      type: String as PropType<OcctoMasterType>,
      default: undefined,
    },
    formValue: {
      type: Object as PropType<OcctoMasterItemFormProps>,
      required: true,
    },
    areas: {
      type: Array as PropType<Area[]>,
      default: () => [],
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
  },
  emits: ['form-input'],
  data(): {
    typeMode: InputMode
    formInputEventName: 'form-input'
  } {
    return {
      typeMode: InputMode.INPUT,
      formInputEventName: 'form-input',
    }
  },
  computed: {
    typeOptions(): InputOption[] {
      return [
        {
          label: this.$t('iam.label.occtoMasterTypeDemand').toString(),
          value: OcctoMasterType.Demand,
        },
        {
          label: this.$t('iam.label.occtoMasterTypeGeneration').toString(),
          value: OcctoMasterType.Generation,
        },
      ]
    },
    nameLabel(): string {
      return this.formValue.type === OcctoMasterType.Generation
        ? this.$t('iam.label.supplyPlanSubmitterName').toString()
        : this.$t('iam.label.balancingGroupName').toString()
    },
    codeLabel(): string {
      return this.formValue.type === OcctoMasterType.Generation
        ? this.$t('iam.label.supplyPlanSubmitterCode').toString()
        : this.$t('iam.label.balancingGroupCode').toString()
    },
    codeRules(): string {
      return this.formValue.type === OcctoMasterType.Generation
        ? `required|spsCode:${this.areaCode}`
        : `required|bgCode:${this.areaCode}`
    },
    areaOptions(): InputOption[] {
      return this.areas
        .filter(a => !a.isSpread)
        .map(a => a.toInputOption(this.locale))
    },
    areaCode(): string {
      const selectedArea = this.areas.find(
        a => a.id === this.formValue.area?.value,
      )
      return selectedArea?.code?.toString() || ''
    },
  },
  created() {
    if (this.fixedType) {
      this.typeMode = InputMode.READONLY
      if (this.fixedType !== this.formValue.type) {
        this.$emit(this.formInputEventName, {
          ...this.formValue,
          type: this.fixedType,
        })
      }
    }
  },
  methods: {
    onInputWithToUpperCase(key: string, value?: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        [key]: value?.toUpperCase(),
      })
    },
  },
})
