import { plainToClass } from 'class-transformer'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import { AssignedBrokerOfOrganizationApi } from '@/api/generated'
import { AssignedBrokerOfOrganization } from '@/models/trading/AssignedBrokerOfOrganization'

export class AssignedBrokerOfOrganizationAction extends Actions<
  AssignedBrokerOfOrganizationAction
> {
  async fetchAssignedBrokerOfOrganization(
    organizationId: string,
  ): Promise<AssignedBrokerOfOrganization> {
    return plainToClass(
      AssignedBrokerOfOrganization,
      (
        await new AssignedBrokerOfOrganizationApi(
          apiConfig,
        ).getAssignedBrokerOfOrganization(organizationId)
      ).data,
    )
  }

  async createAssignedBrokerOfOrganization({
    organizationId,
    brokerId,
  }: {
    organizationId: string
    brokerId: string
  }): Promise<AssignedBrokerOfOrganization> {
    return plainToClass(
      AssignedBrokerOfOrganization,
      (
        await new AssignedBrokerOfOrganizationApi(
          apiConfig,
        ).createAssignedBrokerOfOrganization(organizationId, {
          brokerId,
        })
      ).data,
    )
  }

  async updateAssignedBrokerOfOrganization({
    organizationId,
    brokerId,
  }: {
    organizationId: string
    brokerId: string
  }): Promise<AssignedBrokerOfOrganization> {
    return plainToClass(
      AssignedBrokerOfOrganization,
      (
        await new AssignedBrokerOfOrganizationApi(
          apiConfig,
        ).updateAssignedBrokerOfOrganization(organizationId, {
          brokerId,
        })
      ).data,
    )
  }

  async deleteAssignedBrokerOfOrganization({
    organizationId,
  }: {
    organizationId: string
  }): Promise<void> {
    await new AssignedBrokerOfOrganizationApi(
      apiConfig,
    ).deleteAssignedBrokerOfOrganization(organizationId)
  }
}
