import { ReportType } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

export class ReadableReportTypeOptions {
  get options(): InputOption[] {
    return [
      {
        value: ReportType.Gas,
        label: i18n.t('reports.label.gas').toString(),
      },
      {
        value: ReportType.Power,
        label: i18n.t('reports.label.power').toString(),
      },
    ]
  }
}
