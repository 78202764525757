
import { defineComponent, PropType } from 'vue'

import BaseDataset from '@/components/common/BaseDataset.vue'
import Sentences from '@/components/common/Typography/Sentences.vue'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Product } from '@/models/trading/Product'
export default defineComponent({
  name: 'ProductsSummary',
  components: {
    BaseDataset,
    Sentences,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    fuelSurchargeType: {
      type: Object as PropType<FuelSurchargeType>,
      default: undefined,
    },
    isSwingOption: {
      type: Boolean,
      required: true,
    },
    isBase: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    product(): Product {
      return this.products[0]
    },
    areaName(): string {
      return this.isBase
        ? this.product.deliveryTerms.area.name.translation()
        : this.product.exchangeDeliveryTerms?.area.name.translation() ||
            this.$t('common.label.blank').toString()
    },
    hourTypeName(): string {
      return this.isBase
        ? this.product.deliveryTerms.hourTypeName() ||
            this.$t('common.label.blank').toString()
        : this.product.exchangeDeliveryTerms?.hourTypeName() ||
            this.$t('common.label.blank').toString()
    },
    productTypeNames(): string {
      return this.products
        .map(product => product.productType.translation())
        .join(this.$t('common.label.separator').toString())
    },
    fuelSurchargeTypeName(): string | undefined {
      return this.fuelSurchargeType?.name.translation()
    },
    deliveryPeriodLabel(): string {
      return this.isBase
        ? this.product.deliveryTerms.displayDeliveryPeriodByDateWithDateName(
            this.product.deliveryUnit,
          )
        : this.product.exchangeDeliveryTerms?.displayDeliveryPeriodByDateWithDateName(
            this.product.deliveryUnit,
          ) ?? ''
    },
    swingOptionLabel(): string {
      return this.isSwingOption
        ? this.$t('trading.label.withSwingOption')
        : this.$t('trading.label.withoutSwingOption')
    },
  },
})
