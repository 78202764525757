
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import AccountSelectorModal from '@/components/trading/container/AccountSelectorModal.vue'
import { EexClearingAccount } from '@/models/iam/EexClearingAccount'

export default defineComponent({
  name: 'AccountSelector',
  components: {
    AccountSelectorModal,
    BaseModal,
    BasePicture,
  },
  props: {
    id: {
      type: String,
      default: '1',
    },
    organizationId: {
      type: String,
      default: undefined,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedAccount: {
      type: Object as PropType<EexClearingAccount>,
      default: undefined,
    },
  },
  emits: ['select'],
  computed: {
    accountSelectorModalName(): string {
      return `accountSelectorModal-${this.id}`
    },
    selectedAccountName(): string {
      if (!this.selectedAccount) {
        return ''
      }
      const referenceStr = `(${this.selectedAccount.reference})`
      const extraStr = this.selectedAccount.eexClearingAccountCode.includes(
        referenceStr,
      )
        ? ''
        : referenceStr
      return `${this.selectedAccount.clearingFirm}(${this.selectedAccount.eexClearingAccountCode}${extraStr})`
    },
  },
  methods: {
    onSelect(account: EexClearingAccount) {
      this.$emit('select', account)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.accountSelectorModalName)
    },
    hideModal() {
      this.$vfm.close(this.accountSelectorModalName)
    },
  },
})
