import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc2bab20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-summaries" }
const _hoisted_2 = { class: "delivery-summaries__column" }
const _hoisted_3 = { class: "delivery-summaries__contents" }
const _hoisted_4 = {
  key: 0,
  class: "delivery-summaries__column"
}
const _hoisted_5 = { class: "delivery-summaries__contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_products_summary = _resolveComponent("products-summary")!
  const _component_delivery_summary = _resolveComponent("delivery-summary")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_box, { type: "fill" }, {
        default: _withCtx(() => [
          (_ctx.hasExchange)
            ? (_openBlock(), _createBlock(_component_heading, {
                key: 0,
                type: _ctx.headerLevel,
                class: "delivery-summaries__header"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('trading.label.baseSpreadIndication')), 1)
                ]),
                _: 1
              }, 8, ["type"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_products_summary, {
                products: _ctx.products,
                "fuel-surcharge-type": _ctx.fuelSurchargeType,
                "is-swing-option": _ctx.isSwingOption
              }, null, 8, ["products", "fuel-surcharge-type", "is-swing-option"])
            ]),
            _createVNode(_component_delivery_summary, {
              "delivery-summary": _ctx.baseDeliverySummary,
              "calendar-type-name": _ctx.baseCalendarTypeName
            }, null, 8, ["delivery-summary", "calendar-type-name"])
          ])
        ]),
        _: 1
      })
    ]),
    (_ctx.hasExchange)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_base_box, { type: "fill" }, {
            default: _withCtx(() => [
              _createVNode(_component_heading, {
                type: _ctx.headerLevel,
                class: "delivery-summaries__header"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('trading.label.exchangeSpreadIndication')), 1)
                ]),
                _: 1
              }, 8, ["type"]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createVNode(_component_products_summary, {
                    products: _ctx.products,
                    "fuel-surcharge-type": _ctx.fuelSurchargeType,
                    "is-base": false,
                    "is-swing-option": _ctx.isSwingOption
                  }, null, 8, ["products", "fuel-surcharge-type", "is-swing-option"])
                ]),
                _createVNode(_component_delivery_summary, {
                  "delivery-summary": _ctx.exchangeDeliverySummary,
                  "calendar-type-name": _ctx.exchangeCalendarTypeName
                }, null, 8, ["delivery-summary", "calendar-type-name"])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}