import { Configuration } from '@/api/generated'

// TODO: あとでグローバルstateにまとめる
const basePath = process.env.VUE_APP_API_URL
  ? `https://${process.env.VUE_APP_API_URL}/v2`
  : 'http://localhost:3000/api/v2'

export const apiConfig = new Configuration({
  basePath,
}) as Configuration & Required<Pick<Configuration, 'basePath'>>
