import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec4922f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-content" }
const _hoisted_2 = { class: "channel-content__title" }
const _hoisted_3 = { class: "channel-content__name" }
const _hoisted_4 = { class: "channel-content__member-count" }
const _hoisted_5 = { class: "channel-content__dropdown-trigger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_channel_member_count = _resolveComponent("channel-member-count")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_message_list = _resolveComponent("message-list")!
  const _component_chat_input_wrapper = _resolveComponent("chat-input-wrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_heading, { type: "header4" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.channelName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_heading, {
          type: "header4",
          class: "channel-content__organization-name"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.organizationName), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_channel_member_count, { members: _ctx.members }, null, 8, ["members"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_action_dropdown, {
          id: "channel-content",
          actions: _ctx.actions,
          "is-active": _ctx.isActionDropdownActive,
          "is-right": "",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isActionDropdownActive = !_ctx.isActionDropdownActive), ["stop"])),
          onSelectAddStar: _ctx.onSelectAddStar,
          onSelectRemoveStar: _ctx.onSelectRemoveStar,
          onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isActionDropdownActive = false))
        }, null, 8, ["actions", "is-active", "onSelectAddStar", "onSelectRemoveStar"])
      ])
    ]),
    _createVNode(_component_message_list, {
      ref: "messageList",
      class: "channel-content__messages",
      messages: _ctx.messages,
      onTouchStart: _ctx.onTouchStart,
      onReadMessage: _ctx.onReadMessage
    }, null, 8, ["messages", "onTouchStart", "onReadMessage"]),
    _createVNode(_component_chat_input_wrapper, {
      class: "channel-content__input",
      "chat-input-value": _ctx.chatInputValue,
      "typing-user-names": _ctx.typingUserNames,
      "is-sending": _ctx.isSending,
      onInput: _ctx.onInput,
      onTyping: _ctx.onTyping,
      onSend: _ctx.onSend
    }, null, 8, ["chat-input-value", "typing-user-names", "is-sending", "onInput", "onTyping", "onSend"])
  ]))
}