import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70ea2b36"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-selected"]
const _hoisted_2 = ["title", "aria-label"]
const _hoisted_3 = { class: "base-nav-item__label" }
const _hoisted_4 = {
  key: 0,
  class: "base-nav-item__arrow"
}
const _hoisted_5 = {
  key: 1,
  class: "base-nav-item__arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'base-nav-item',
      {
        'base-nav-item--collapsed': _ctx.isCollapsed,
        'base-nav-item--current': _ctx.isCurrent,
      },
    ]),
    "aria-selected": _ctx.isCurrent || undefined
  }, [
    _createElementVNode("div", {
      class: "base-nav-item__icon",
      title: _ctx.isCollapsed ? _ctx.label : undefined,
      "aria-label": _ctx.label
    }, [
      _createVNode(_component_base_icon, { "icon-name": _ctx.iconName }, null, 8, ["icon-name"])
    ], 8, _hoisted_2),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_ctx.label), 1)
    ], 512), [
      [_vShow, !_ctx.isCollapsed]
    ]),
    (_ctx.hasArrow)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_base_icon, { "icon-name": "angle-right" })
        ], 512)), [
          [_vShow, !_ctx.isCollapsed]
        ])
      : _createCommentVNode("", true),
    (_ctx.isExternal)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_base_icon, { "icon-name": "external-link-alt" })
        ], 512)), [
          [_vShow, !_ctx.isCollapsed]
        ])
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}