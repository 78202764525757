import { Module } from 'vuex-smart-module'

import { UserProfileActions } from '@/store/modules/iam/userProfile/UserProfileActions'
import { UserProfileGetters } from '@/store/modules/iam/userProfile/UserProfileGetters'
import { UserProfileMutations } from '@/store/modules/iam/userProfile/UserProfileMutations'
import { UserProfileState } from '@/store/modules/iam/userProfile/UserProfileState'

export const userProfile = new Module({
  state: UserProfileState,
  getters: UserProfileGetters,
  mutations: UserProfileMutations,
  actions: UserProfileActions,
})

export type UserProfileModule = typeof userProfile
