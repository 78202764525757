
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import CarouselBanner from '@/components/common/CarouselBanner.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { ActiveInformation } from '@/models/common/ActiveInformation'
import { GlobalModule } from '@/store/modules/common/global'
import { SET_IS_INITIALIZING } from '@/store/modules/common/global/GlobalMutations'
import { InformationModule } from '@/store/modules/common/information'
import { UserProfileModule } from '@/store/modules/iam/userProfile'

const { mapMutations } = createNamespacedHelpers('global') as GlobalModule
const {
  mapState: informationMapState,
  mapActions: informationMapActions,
} = createNamespacedHelpers('information') as InformationModule
const { mapGetters: userProfileMapGemapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

export default defineComponent({
  name: 'PageTemplate',
  components: {
    CarouselBanner,
    Heading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    layoutMode: {
      type: String as PropType<LayoutMode>,
      default: LayoutMode.Normal,
      validator(val: LayoutMode) {
        return [
          LayoutMode.Normal,
          LayoutMode.Wide,
          LayoutMode.SuperWide,
        ].includes(val)
      },
    },
  },
  data() {
    return { closedInformations: [] as string[] }
  },
  computed: {
    ...informationMapState(['activeInformations']),
    ...userProfileMapGemapGetters(['isEsquareEnabled']),
    filteredActiveInformations(): ActiveInformation[] {
      return this.activeInformations.filter(
        (information: ActiveInformation) =>
          !this.closedInformations.includes(information.informationId),
      )
    },
  },
  async created() {
    await this.fetchActiveInformations()
  },
  mounted() {
    this[SET_IS_INITIALIZING]({ isInitializing: false })
    if (localStorage.getItem('closedInformations')) {
      this.closedInformations = JSON.parse(
        localStorage.getItem('closedInformations') as string,
      )
    }
  },
  methods: {
    ...mapMutations([SET_IS_INITIALIZING]),
    ...informationMapActions(['fetchActiveInformations']),
    onClose(event: string) {
      this.closedInformations.push(event)
      localStorage.setItem(
        'closedInformations',
        JSON.stringify(this.closedInformations),
      )
    },
  },
})
