
import { defineComponent } from 'vue'

import BaseChannel from '@/components/chat/BaseChannel.vue'

export default defineComponent({
  name: 'ChannelOrganization',
  components: {
    BaseChannel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    channelId: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick(event: Event) {
      this.$emit('click', event)
    },
  },
})
