export type BaseFilterAttribute =
  | 'area'
  | 'startDeliveryYearMonth'
  | 'endDeliveryYearMonth'
  | 'hourType'
  | 'productType'

export const TAB_INDEXES = {
  NO_FUEL_SURCHARGE: 0,
  WITH_FUEL_SURCHARGE: 1,
  SPREAD: 2,
  ONLY_FUEL_SURCHARGE: 3,
  LNG: 4,
  ENV_VALUES: 5,
  ETENDER: 6,
}
