
import { plainToClass } from 'class-transformer'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { RecapReceiver as IamRecapReceiver } from '@/models/iam/RecapReceiver'
import { RecapReceiver as TradingRecapReceiver } from '@/models/trading/RecapReceiver'
import { RecapReceiverModule } from '@/store/modules/iam/recapReceiver'

const { mapActions: recapReceiverActions } = createNamespacedHelpers(
  'recapReceiver',
) as RecapReceiverModule

export default defineComponent({
  name: 'RecapReceiverSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    recapReceivers: IamRecapReceiver[]
    uiStack: UiStack
    alertTypeInfo: AlertType
    alertTypeError: AlertType
  } {
    return {
      recapReceivers: [],
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
    }
  },
  async created() {
    await this.fetchRecapReceivers(this.organizationId)
      .then(recapReceivers => {
        this.recapReceivers = recapReceivers
        this.uiStack = recapReceivers.length > 0 ? UiStack.Ideal : UiStack.Blank
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    fetchRecapReceivers: recapReceiverActions(['fetchRecapReceivers'])
      .fetchRecapReceivers as (
      organizationId: string,
    ) => Promise<IamRecapReceiver[]>,
    onSelect(recapReceiver: IamRecapReceiver) {
      this.$emit(
        'select',
        plainToClass(TradingRecapReceiver, {
          name: recapReceiver.name,
          department: recapReceiver.department,
          phoneNumber: recapReceiver.phoneNumber,
          emailAddress: recapReceiver.emailAddress,
        }),
      )
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
})
