import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f50f52fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "indication-selector-modal" }
const _hoisted_2 = { class: "indication-selector-modal__form" }
const _hoisted_3 = { class: "indication-selector-modal__result" }
const _hoisted_4 = { class: "indication-selector-modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_indication_selector_modal_list_header = _resolveComponent("indication-selector-modal-list-header")!
  const _component_indication_selector_modal_list = _resolveComponent("indication-selector-modal-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_text, {
        class: "indication-selector-modal__input-text",
        label: _ctx.$t('iam.label.organizationName'),
        name: "organizationName",
        value: _ctx.value,
        placeholder: _ctx.$t('iam.label.organizationName'),
        onInput: _ctx.onInput
      }, null, 8, ["label", "value", "placeholder", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
        loading: _withCtx(() => [
          _createVNode(_component_centered_loading_content, {
            type: "grayscale",
            "is-relative": ""
          })
        ]),
        error: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.errorType }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failSearchIndications')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        blank: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.alertTypeInfo }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('iam.message.notFoundIndications')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        ideal: _withCtx(() => [
          _createVNode(_component_indication_selector_modal_list_header),
          _createVNode(_component_indication_selector_modal_list, {
            indications: _ctx.indications,
            "color-reversed": _ctx.colorReversed,
            locale: _ctx.locale,
            onSelect: _ctx.onSelect
          }, null, 8, ["indications", "color-reversed", "locale", "onSelect"])
        ]),
        _: 1
      }, 8, ["ui-stack"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}