
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faArrowsH,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarTimes,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCity,
  faClipboard,
  faClock,
  faClone,
  faCloud,
  faCloudDownload,
  faComment,
  faCommentMedical,
  faComments,
  faConciergeBell,
  faEllipsisH,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileChartLine,
  faFileDownload,
  faFileUpload,
  faFilter,
  faHandshake,
  faHistory,
  faHourglassStart,
  faInfoCircle,
  faLock,
  faLockOpen,
  faPaperclip,
  faPaperPlane,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faList,
  faShieldCheck,
  faSpinner,
  faStopCircle,
  faTimes,
  faTimesCircle,
  faUnlock,
  faUser,
  faInbox,
} from '@fortawesome/pro-regular-svg-icons'
import { faGrid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { defineComponent } from 'vue'

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faArrowsH,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarTimes,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCity,
  faClipboard,
  faClock,
  faClone,
  faCloud,
  faCloudDownload,
  faComment,
  faCommentMedical,
  faComments,
  faConciergeBell,
  faEllipsisH,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileChartLine,
  faFileUpload,
  faFileDownload,
  faFilter,
  faGrid,
  faHandshake,
  faHistory,
  faHourglassStart,
  faInfoCircle,
  faLock,
  faLockOpen,
  faPencil,
  faPaperclip,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faShieldCheck,
  faSpinner,
  faStopCircle,
  faTimes,
  faTimesCircle,
  faUnlock,
  faUser,
  faList,
  faInbox,
)

export default defineComponent({
  name: 'BaseIcon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: undefined,
    },
  },
  emits: ['click', 'mouseleave'],
  computed: {
    prefix(): string {
      switch (this.iconName) {
        case 'grid':
          return 'fas'
        default:
          return 'far'
      }
    },
  },
  methods: {
    onClick($event: Event) {
      this.$emit('click', $event)
    },
    onMouseleave($event: Event) {
      this.$emit('mouseleave', $event)
    },
  },
})
