
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseModal from '@/components/common/BaseModal.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import { PER_PAGE } from '@/components/common/constants/List'
import LocalizedReport from '@/components/reports/LocalizedReport.vue'
import LocalizedReportList from '@/components/reports/LocalizedReportList.vue'
import { LocalizedReport as LocalizedReportModel } from '@/models/reports/LocalizedReport'
import { LocalizedReportsModule } from '@/store/modules/reports/localizedReports'
import { downloadFile } from '@/utils/utils'

const { mapActions: localizedReportsMapActions } = createNamespacedHelpers(
  'localizedReports',
) as LocalizedReportsModule

export default defineComponent({
  name: 'LocalizedReports',
  components: {
    BaseModal,
    CenteredLoadingContent,
    LocalizedReport,
    LocalizedReportList,
    TraderPage,
  },
  data() {
    return {
      reports: [] as LocalizedReportModel[],
      currentReport: undefined as LocalizedReportModel | undefined,
      modalName: 'report',
      isLoaded: false,
    }
  },
  computed: {
    lastReportId(): string | undefined {
      if (this.reports.length === 0) {
        return undefined
      }
      return this.reports[this.reports.length - 1].id
    },
    currentReportTitle(): string {
      if (!this.currentReport) {
        return ''
      }
      return this.currentReport.reportContent.title
    },
  },
  async created() {
    if (this.$route.params.id) {
      await this.openModal(this.$route.params.id as string)
    }
    await this.fetchList()
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async beforeRouteLeave(to: any, from: any, next: any) {
    if (to.params.id) {
      await this.openModal(to.params.id)
    } else {
      this.onModalClose()
    }
    next()
  },
  methods: {
    fetchLocalizedReports: localizedReportsMapActions(['fetchLocalizedReports'])
      .fetchLocalizedReports as (
      lastReportId?: string,
    ) => Promise<LocalizedReportModel[]>,
    fetchLocalizedReport: localizedReportsMapActions(['fetchLocalizedReport'])
      .fetchLocalizedReport as (
      reportId: string,
    ) => Promise<LocalizedReportModel>,
    fetchAttachmentUrl: localizedReportsMapActions(['fetchAttachmentUrl'])
      .fetchAttachmentUrl as (payload: {
      reportId: string
      reportContentId: string
    }) => Promise<{ url: string }>,
    async fetchList(lastReportId?: string) {
      const reports = await this.fetchLocalizedReports(lastReportId)
      this.reports.push(...reports)
      this.isLoaded =
        this.isLoaded || reports.length === 0 || reports.length % PER_PAGE !== 0
    },
    onLastItemVisible() {
      if (!this.isLoaded) {
        this.fetchList(this.lastReportId)
      }
    },
    async onItemClick(reportId: string) {
      await this.openModal(reportId)
      await this.$router.push(`/reports/${reportId}`)
    },
    onModalClose() {
      this.$vfm.close(this.modalName)
    },
    async onModalClosed() {
      this.currentReport = undefined
      await this.$router.push('/reports')
    },
    async onDownloadClick({
      reportId,
      reportContentId,
      attachmentName,
    }: {
      reportId: string
      reportContentId: string
      attachmentName: string
    }) {
      const { url } = await this.fetchAttachmentUrl({
        reportId,
        reportContentId,
      })
      downloadFile({ url, name: attachmentName })
    },
    async openModal(reportId: string) {
      this.currentReport = await this.fetchLocalizedReport(reportId)
      this.$vfm.open(this.modalName)
    },
  },
})
