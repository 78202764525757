import {
  TradingSteps as ITradingSteps,
  TradingStepsAskIndication,
  TradingStepsBidIndication,
  TradingStepsDealsInner,
  TradingStepsNegotiation,
} from '@/api/generated'

export class TradingSteps implements ITradingSteps {
  askIndication?: TradingStepsAskIndication
  bidIndication?: TradingStepsBidIndication
  negotiation?: TradingStepsNegotiation
  deals!: TradingStepsDealsInner[]
}
