import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f53c46c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "indication" }
const _hoisted_2 = { class: "indication__dropdown" }
const _hoisted_3 = { class: "indication__trading-steps" }
const _hoisted_4 = { class: "indication__summary" }
const _hoisted_5 = { class: "indication__form" }
const _hoisted_6 = { class: "indication__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_trading_steps = _resolveComponent("trading-steps")!
  const _component_delivery_summaries = _resolveComponent("delivery-summaries")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_participant_summary = _resolveComponent("participant-summary")!
  const _component_broker_indication_form = _resolveComponent("broker-indication-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_indication_confirm_modal_content = _resolveComponent("indication-confirm-modal-content")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_indication_new = _resolveComponent("broker-indication-new")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t('trading.label.indication'),
    "active-menu-item": "trading"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failInitializeIndication')), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          ideal: _withCtx(() => [
            _createVNode(_component_validation_form, null, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_action_dropdown, {
                    id: "indication-action-dropdown",
                    actions: _ctx.actionDropdownItems,
                    "is-active": _ctx.actionDropdownIsActive,
                    "is-right": "",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                  _ctx.actionDropdownIsActive = !_ctx.actionDropdownIsActive
                ), ["stop"])),
                    onClickOutside: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actionDropdownIsActive = false)),
                    onDeleteIndicationClick: _ctx.onDeleteIndicationClick,
                    onCopyIndication: _ctx.onCopyIndication
                  }, null, 8, ["actions", "is-active", "onDeleteIndicationClick", "onCopyIndication"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_trading_steps, {
                    "trading-steps": _ctx.tradingSteps,
                    "color-reversed": 
                  _ctx.userProfile.services.esquare.userSetting.colorReversed
                
                  }, null, 8, ["trading-steps", "color-reversed"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_base_box, { class: "indication__summary-box" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_delivery_summaries, {
                        class: "indication__delivery-summary",
                        "delivery-summaries": _ctx.deliverySummaries,
                        products: _ctx.indication.products,
                        "fuel-surcharge-type": _ctx.indication.fuelSurchargeType,
                        "is-swing-option": _ctx.indication.isSwingOption
                      }, null, 8, ["delivery-summaries", "products", "fuel-surcharge-type", "is-swing-option"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_participant_summary, {
                    class: "indication__participant-summary",
                    "created-participant-name": _ctx.indication.createdParticipantName,
                    "last-updated-participant-name": 
                  _ctx.indication.lastUpdatedParticipantName
                ,
                    "created-at": _ctx.indication.createdAt,
                    "updated-at": _ctx.indication.updatedAt,
                    direction: _ctx.participantSummaryDirection
                  }, null, 8, ["created-participant-name", "last-updated-participant-name", "created-at", "updated-at", "direction"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_broker_indication_form, {
                    mode: _ctx.mode,
                    locale: _ctx.userProfile.locale,
                    "position-reversed": 
                  _ctx.userProfile.services.esquare.userSetting.positionReversed
                ,
                    "product-types": _ctx.productTypes,
                    "day-pattern-options": _ctx.dayPatternOptions,
                    traders: _ctx.traders,
                    brokers: _ctx.brokers,
                    "form-value": _ctx.formValue,
                    "status-options": _ctx.statusOptions,
                    "opened-spread-form-types": _ctx.openedSpreadFormTypes,
                    "selectable-inputs": _ctx.selectableInputs,
                    "date-names": _ctx.dateNames,
                    "is-required-memo": _ctx.isRequiredMemo,
                    onFormInput: _ctx.onFormInput,
                    onTextInput: _ctx.onTextInput,
                    onStandardDeliveryTermsFormOpen: 
                  _ctx.onStandardDeliveryTermsFormOpen
                ,
                    onStandardDeliveryTermsFormClose: 
                  _ctx.onStandardDeliveryTermsFormClose
                ,
                    onMemoInput: _ctx.onMemoInput
                  }, null, 8, ["mode", "locale", "position-reversed", "product-types", "day-pattern-options", "traders", "brokers", "form-value", "status-options", "opened-spread-form-types", "selectable-inputs", "date-names", "is-required-memo", "onFormInput", "onTextInput", "onStandardDeliveryTermsFormOpen", "onStandardDeliveryTermsFormClose", "onMemoInput"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    disabled: _ctx.isInvalid(Object.keys(errors).length > 0),
                    color: "primary",
                    onClick: _ctx.onConfirmClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.updateModalName,
        title: _ctx.$t('trading.message.brokerIndicationUpdateConfirmModalTitle'),
        "confirm-button-label": _ctx.$t('common.label.update'),
        loading: _ctx.updateSubmitting,
        width: 650,
        onConfirm: _ctx.onUpdateIndicationConfirm,
        onCancel: _ctx.onUpdateIndicationCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_indication_confirm_modal_content, {
            "form-value": _ctx.formValue,
            "selectable-inputs": _ctx.selectableInputs,
            locale: _ctx.userProfile.locale,
            message: 
          _ctx.$t('trading.message.brokerIndicationUpdateConfirmModalMessage')
        ,
            "is-broker": true
          }, null, 8, ["form-value", "selectable-inputs", "locale", "message"])
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"]),
      _createVNode(_component_confirmation_dialog, {
        name: _ctx.deleteModalName,
        title: _ctx.$t('trading.message.brokerIndicationDeleteConfirmModalTitle'),
        "confirm-button-label": _ctx.$t('common.label.delete'),
        "confirm-button-color": "danger",
        loading: _ctx.deleteSubmitting,
        width: 650,
        onConfirm: _ctx.onDeleteIndicationConfirm,
        onCancel: _ctx.onDeleteIndicationCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_indication_confirm_modal_content, {
            "form-value": _ctx.formValue,
            "selectable-inputs": _ctx.selectableInputs,
            locale: _ctx.userProfile.locale,
            message: 
          _ctx.$t('trading.message.brokerIndicationDeleteConfirmModalMessage')
        ,
            "is-broker": true
          }, null, 8, ["form-value", "selectable-inputs", "locale", "message"])
        ]),
        _: 1
      }, 8, ["name", "title", "confirm-button-label", "loading", "onConfirm", "onCancel"]),
      _createVNode(_component_base_modal, {
        name: _ctx.newIndicationModalName,
        title: _ctx.$t('trading.label.newIndication'),
        width: 1096
      }, {
        default: _withCtx(() => [
          _createVNode(_component_broker_indication_new, {
            "copy-from-indication-id": _ctx.id,
            onCancel: _ctx.onCopyIndicationCancel,
            onConfirm: _ctx.onCopyIndicationConfirm
          }, null, 8, ["copy-from-indication-id", "onCancel", "onConfirm"])
        ]),
        _: 1
      }, 8, ["name", "title"])
    ]),
    _: 1
  }, 8, ["title"]))
}