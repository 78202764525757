import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f6b68c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-selector" }
const _hoisted_2 = { class: "organization-selector__information" }
const _hoisted_3 = { class: "organization-selector__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_organization_selector_modal = _resolveComponent("organization-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["organization-selector__wrapper", {
        'organization-selector__wrapper--selectable': _ctx.isSelectable,
      }])
    }, [
      (_ctx.selectedOrganization)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "organization-selector__select-button--selected",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_base_picture, {
                class: "organization-selector__icon",
                url: _ctx.selectedOrganization.picture,
                width: 24
              }, null, 8, ["url"]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.selectedOrganizationName), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "organization-selector__select-button--not-selected",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, _toDisplayString(_ctx.$t('iam.label.selectOrganization')), 1))
    ], 2),
    _createVNode(_component_base_modal, {
      title: _ctx.$t('trading.label.selectOrganization'),
      name: _ctx.organizationSelectorModalName
    }, {
      default: _withCtx(() => [
        _createVNode(_component_organization_selector_modal, {
          "selected-organization": _ctx.selectedOrganization,
          "query-params": _ctx.queryParams,
          onSelect: _ctx.onSelect,
          onCancel: _ctx.onCancel
        }, null, 8, ["selected-organization", "query-params", "onSelect", "onCancel"])
      ]),
      _: 1
    }, 8, ["title", "name"])
  ]))
}