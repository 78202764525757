import { ProductType } from '@/models/trading/ProductType'
import { moment } from '@/utils/date'

export const unselectableStartDeliveryDaysOfWeek = (
  productTypes: ProductType[],
): number[] => {
  if (productTypes.some(p => p.isSwapEex())) {
    // 商品区分にSwap EEXが含まれる場合は常に月曜始まり
    return [0, 2, 3, 4, 5, 6, 7]
  }
  return []
}

export const startDeliveryMinDate = (
  productTypes: ProductType[],
): Date | undefined => {
  const today = moment().startOf('day')
  const hasSwapEex = productTypes.some(p => p.isSwapEex())
  const hasBgNoFuelSurcharge = productTypes.some(p => p.isBgNoFuelSurcharge())
  const hasBgWithFuelSurcharge = productTypes.some(p =>
    p.isBgWithFuelSurcharge(),
  )
  const hasCfd = productTypes.some(p => p.isCfd())
  const hasSwapBilateral = productTypes.some(p => p.isSwapBilateral())

  if (hasSwapEex) {
    // 商品区分にBG燃調なしが含まれるかつ現在曜日が月曜でなければ来週の月曜日
    if (hasBgNoFuelSurcharge && today.day() !== 1) {
      return today
        .add(1, 'weeks')
        .startOf('isoWeek')
        .toDate()
    }
    return today.startOf('isoWeek').toDate()
  }

  if (hasBgNoFuelSurcharge || hasBgWithFuelSurcharge) {
    return today.toDate()
  }

  if (hasCfd || hasSwapBilateral) {
    return today.startOf('month').toDate()
  }

  return undefined
}

export const startDeliveryMaxDate = (
  endDeliveryDate: Date | undefined,
  productTypes: ProductType[],
): Date | undefined => {
  const hasSwapEex = productTypes.some(p => p.isSwapEex())
  if (!endDeliveryDate || hasSwapEex) {
    // 終了日が未設定または商品区分にSwap EEXが含まれる場合は自由
    return undefined
  }
  // 終了日が設定済の場合は終了日と同日を設定する
  return moment(endDeliveryDate)
    .startOf('day')
    .toDate()
}

export const unselectableEndDeliveryDaysOfWeek = (
  productTypes: ProductType[],
): number[] => {
  if (productTypes.some(p => p.isSwapEex())) {
    // 商品区分にSwap EEXが含まれる場合は常に日曜終わり
    return [1, 2, 3, 4, 5, 6, 7]
  }
  return []
}

export const endDeliveryMinDate = (
  startDeliveryDate: Date | undefined,
  productTypes: ProductType[],
): Date => {
  const hasSwapEex = productTypes.some(p => p.isSwapEex())
  const hasBgNoFuelSurcharge = productTypes.some(p => p.isBgWithFuelSurcharge())
  const hasCfdOrSwapBilateral = productTypes.some(
    p => p.isCfd() || p.isSwapBilateral(),
  )

  // Swap(EEX) が最優先
  if (hasSwapEex) {
    if (startDeliveryDate) {
      // 選択した開始日の翌日曜
      return moment(startDeliveryDate)
        .endOf('isoWeek')
        .startOf('day')
        .toDate()
    }
    // 最小開始日の週の日曜日
    return moment(startDeliveryMinDate(productTypes))
      .endOf('isoWeek')
      .startOf('day')
      .toDate()
  }

  if (startDeliveryDate) {
    // 開始日が設定済の場合は開始日と同日を設定する
    return moment(startDeliveryDate)
      .startOf('day')
      .toDate()
  }

  // BG(燃調なし) が優先度2 (かつこの時点で !hasCfdOrSwapBilateral は結局 BG(燃調あり) しかない)
  if (hasBgNoFuelSurcharge || !hasCfdOrSwapBilateral) {
    // 開始日が未設定の場合は今日を設定する
    return moment()
      .startOf('day')
      .toDate()
  }

  // この時点で hasCfdOrSwapBilateral = true かつ開始日が未選択 なので現在月の1日を返す
  return moment()
    .startOf('month')
    .toDate()
}

export const getEndDeliveryDate = (
  startDeliveryDate: Date | undefined,
  endDeliveryDate: Date | undefined,
  hasSwapEex: boolean,
): Date | undefined => {
  if (!hasSwapEex) {
    return endDeliveryDate
  }
  // 商品区分にSwap EEXが含まれる場合 かつ 日付選択時は常に6日後の日曜日を設定する
  return startDeliveryDate
    ? moment(startDeliveryDate)
        .add(6, 'day')
        .toDate()
    : undefined
}
