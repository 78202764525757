import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f8819cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delivery-terms" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "delivery-terms__text" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "delivery-terms__text" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "delivery-terms__text" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "delivery-terms__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "delivery-terms__column",
      title: _ctx.areaName
    }, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.areaName), 1)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      class: "delivery-terms__column",
      title: _ctx.deliveryStart
    }, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.deliveryStart), 1)
    ], 8, _hoisted_4),
    _createElementVNode("div", {
      class: "delivery-terms__column",
      title: _ctx.deliveryEnd
    }, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.deliveryEnd), 1)
    ], 8, _hoisted_6),
    _createElementVNode("div", {
      class: "delivery-terms__column",
      title: _ctx.hourTypeName
    }, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.hourTypeName), 1)
    ], 8, _hoisted_8)
  ]))
}