
import { defineComponent, PropType } from 'vue'

import { NegotiationStatusType, PositionType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseDataset from '@/components/common/BaseDataset.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { BaseBoxType } from '@/components/common/constants/BaseBoxType'
import { InputMode } from '@/components/common/constants/InputMode'
import { HeaderLevel } from '@/components/common/constants/TypographyLevel'
import { InputOption } from '@/components/common/interface/InputOption'
import FieldUnitPrice from '@/components/trading/FieldUnitPrice.vue'
import FieldVolume from '@/components/trading/FieldVolume.vue'
import NegotiationIndicationsForm from '@/components/trading/NegotiationIndicationsForm.vue'
import PositionIcon from '@/components/trading/PositionIcon.vue'
import { NegotiationFormInputMode } from '@/components/trading/constants/NegotiationFormInputMode'
import { NegotiationFormProps } from '@/components/trading/interface/NegotiationFormProps'
import { NegotiationIndicationsFormProps } from '@/components/trading/interface/NegotiationIndicationsFormProps'
import { NegotiationStatusTypeOptions } from '@/models/trading/NegotiationStatusTypeOptions'
import { Product } from '@/models/trading/Product'
import { ProductType } from '@/models/trading/ProductType'
import { toFixed } from '@/utils/utils'

type NegotiationRowItem = {
  base: {
    areaName: string
    deliveryStartDate: string
    deliveryEndDate: string
    hourTypeNamesWithTimeRanges: string
    askAfterUnitPrice: string
    askAfterVolume: string | null
    bidAfterUnitPrice: string
    bidAfterVolume: string | null
  }
  exchange?: {
    areaName: string
    deliveryStartDate: string
    deliveryEndDate: string
    hourTypeNamesWithTimeRanges: string
    askAfterUnitPrice: string
    askAfterVolume: string
    bidAfterUnitPrice: string
    bidAfterVolume: string
  }
}

const sum = (a: string | undefined, b: string | undefined): string => {
  return toFixed(Number(a ?? 0) + Number(b ?? 0), 3).toString()
}

const diff = (a: string | undefined, b: string | undefined): string => {
  return toFixed(Number(a ?? 0) - Number(b ?? 0), 3).toString()
}

export default defineComponent({
  name: 'NegotiationForm',
  components: {
    BaseBox,
    BaseDataset,
    BaseIcon,
    BaseInputRadio,
    BaseInputText,
    BaseLabel,
    BaseSingleSelect,
    FieldUnitPrice,
    FieldVolume,
    Heading,
    NegotiationIndicationsForm,
    PositionIcon,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: NegotiationFormInputMode) => {
        return [
          NegotiationFormInputMode.NEW,
          NegotiationFormInputMode.UNDER_NEGOTIATION,
          NegotiationFormInputMode.EXECUTED,
          NegotiationFormInputMode.BROKEN,
          NegotiationFormInputMode.DELETED,
        ].includes(value)
      },
    },
    isStandardSpread: {
      type: Boolean,
      default: false,
    },
    isSwingOption: {
      type: Boolean,
      default: false,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    productTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    calendarTypeInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    formValue: {
      type: Object as PropType<NegotiationFormProps>,
      required: true,
    },
    publicNegotiationId: {
      type: String,
      required: true,
    },
    eclearOrganizationId: {
      type: String,
      default: undefined,
    },
    eclearProductTypeIdList: {
      type: Array as PropType<string[]>,
      default: [],
    },
    isRequiredMemo: {
      type: Boolean,
      default: true,
    },
    isVolumeRequired: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'form-input',
    'credit-sleever-add',
    'credit-sleever-remove',
    'memo-input',
  ],
  data(): {
    formInputEventName: 'form-input'
    positionTypeAsk: PositionType
    positionTypeBid: PositionType
    baseBoxTypeBorder: BaseBoxType
    readonly: InputMode
    headerLevel3: HeaderLevel
  } {
    return {
      formInputEventName: 'form-input',
      positionTypeAsk: PositionType.Ask,
      positionTypeBid: PositionType.Bid,
      baseBoxTypeBorder: BaseBoxType.Border,
      readonly: InputMode.READONLY,
      headerLevel3: HeaderLevel.Lv3,
    }
  },
  computed: {
    negotiationStatusOptions(): InputOption[] {
      const options = new NegotiationStatusTypeOptions().options.filter(
        option =>
          this.mode === NegotiationFormInputMode.DELETED
            ? true
            : option.value !== NegotiationStatusType.Deleted,
      )
      switch (this.mode) {
        case NegotiationFormInputMode.EXECUTED:
          return options.filter(
            option => option.value !== NegotiationStatusType.Broken,
          )
        case NegotiationFormInputMode.BROKEN:
          return options.filter(
            option =>
              ![
                NegotiationStatusType.UnderNegotiation.toString(),
                NegotiationStatusType.Executed.toString(),
              ].includes(option.value),
          )
        default:
          return options
      }
    },
    negotiationRows(): NegotiationRowItem[] {
      return this.product.deliveryTerms.deliveryPeriods.map(
        (deliveryPeriod, index) => {
          const areaName = this.product.area.translation()
          const deliveryStartDate = deliveryPeriod.displayDeliveryStartDate()
          const deliveryEndDate = deliveryPeriod.displayDeliveryEndDate()
          const hourTypeNamesWithTimeRanges = deliveryPeriod.deliveryPattern.displayHourTypeNamesWithTimeRanges()
          const exchangeDeliveryTerms = this.product.exchangeDeliveryTerms

          const askAfterUnitPrice = (() => {
            if (!this.isSpread) {
              return this.formValue.askAfterUnitPrices[index]
            }
            return this.formValue.spreadBasePrice || ''
          })()

          const askAfterVolume = (() => {
            return this.formValue.askAfterVolumes[index]
          })()

          const bidAfterUnitPrice = (() => {
            const sleeveSpread = this.formValue.creditSleeverSleeveSpread
            if (!this.isSpread) {
              return this.formValue.bidAfterUnitPrices[index]
            }
            return sum(this.formValue.spreadBasePrice, sleeveSpread)
          })()

          const bidAfterVolume = this.formValue.bidAfterVolumes[index]

          const exchangeAskAfterUnitPrice = (() => {
            return diff(
              this.formValue.spreadBasePrice,
              this.formValue.spreadUnitPrice,
            )
          })()

          const exchangeAskAfterVolume = (() => {
            if (!this.formValue.bidAfterExchangeVolumes) {
              return ''
            }
            return this.formValue.bidAfterExchangeVolumes[index] || ''
          })()

          const exchangeBidAfterUnitPrice = (() => {
            return diff(
              this.formValue.spreadBasePrice,
              this.formValue.spreadUnitPrice,
            )
          })()

          const exchangeBidAfterVolume = (() => {
            if (!this.formValue.askAfterExchangeVolumes) {
              return ''
            }
            return this.formValue.askAfterExchangeVolumes[index] || ''
          })()

          const exchange =
            exchangeDeliveryTerms !== undefined
              ? {
                  areaName: exchangeDeliveryTerms.area.translation(),
                  deliveryStartDate: exchangeDeliveryTerms.deliveryPeriods[
                    index
                  ].displayDeliveryStartDate(),
                  deliveryEndDate: exchangeDeliveryTerms.deliveryPeriods[
                    index
                  ].displayDeliveryEndDate(),
                  hourTypeNamesWithTimeRanges: exchangeDeliveryTerms.deliveryPeriods[
                    index
                  ].deliveryPattern.displayHourTypeNamesWithTimeRanges(),
                  askAfterUnitPrice: exchangeAskAfterUnitPrice,
                  askAfterVolume: exchangeAskAfterVolume,
                  bidAfterUnitPrice: exchangeBidAfterUnitPrice,
                  bidAfterVolume: exchangeBidAfterVolume,
                }
              : undefined

          return {
            base: {
              areaName,
              deliveryStartDate,
              deliveryEndDate,
              hourTypeNamesWithTimeRanges,
              askAfterUnitPrice,
              askAfterVolume,
              bidAfterUnitPrice,
              bidAfterVolume,
            },
            exchange,
          }
        },
      )
    },
    negotiationIndicationsFormValue(): NegotiationIndicationsFormProps {
      return {
        askIndication: this.formValue.askIndication,
        askProductType: this.formValue.askProductType,
        askBrokingFee: this.formValue.askBrokingFee,
        askExchangeBrokingFee: this.formValue.askExchangeBrokingFee,
        askDefaultBrokingFees: this.formValue.askDefaultBrokingFees,
        bidIndication: this.formValue.bidIndication,
        bidProductType: this.formValue.bidProductType,
        bidBrokingFee: this.formValue.bidBrokingFee,
        bidExchangeBrokingFee: this.formValue.bidExchangeBrokingFee,
        bidDefaultBrokingFees: this.formValue.bidDefaultBrokingFees,
        creditSleever: this.formValue.creditSleever,
        creditSleeverAskBrokingFee: this.formValue.creditSleeverAskBrokingFee,
        creditSleeverBidBrokingFee: this.formValue.creditSleeverBidBrokingFee,
        creditSleeverSleeveSpread: this.formValue.creditSleeverSleeveSpread,
      }
    },
    inputMode(): InputMode {
      return this.mode === NegotiationFormInputMode.UNDER_NEGOTIATION
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    statusInputMode(): InputMode {
      return [
        NegotiationFormInputMode.UNDER_NEGOTIATION,
        NegotiationFormInputMode.EXECUTED,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    memoInputMode(): InputMode {
      return this.mode === NegotiationFormInputMode.DELETED
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    isSpread(): boolean {
      return this.product.area.isSpread || this.isStandardSpread
    },
    spreadUnitPriceRules(): string {
      const spreadBasePrice = Number(this.formValue.spreadBasePrice)
      const minPrice = spreadBasePrice ? spreadBasePrice - 999.999 : -999.999
      const maxPrice = spreadBasePrice ? spreadBasePrice - 0.001 : 999.999
      return `required|numeric|scale:4|between:${minPrice.toString()},${maxPrice.toString()}`
    },
    memoRules(): string {
      return this.isRequiredMemo ? 'required' : ''
    },
  },
  methods: {
    creditSleeverSleeveSpreadInput(creditSleeverSleeveSpread: string): void {
      const bidAfterUnitPrices = this.formValue.askAfterUnitPrices.map(
        askAfterUnitPrice => sum(askAfterUnitPrice, creditSleeverSleeveSpread),
      )
      const partial: Pick<
        NegotiationFormProps,
        'creditSleeverSleeveSpread' | 'bidAfterUnitPrices'
      > = {
        creditSleeverSleeveSpread,
        bidAfterUnitPrices,
      }
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        ...partial,
      })
    },
    spreadUnitPriceInput(spreadUnitPrice: string): void {
      // スプレッド以外のときには呼ばれないはず
      if (!this.isStandardSpread) {
        return
      }
      const askAfterUnitPrices = [spreadUnitPrice]
      const bidAfterUnitPrices = [
        sum(spreadUnitPrice, this.formValue.creditSleeverSleeveSpread),
      ]
      const partial: Pick<
        NegotiationFormProps,
        'spreadUnitPrice' | 'askAfterUnitPrices' | 'bidAfterUnitPrices'
      > = {
        spreadUnitPrice,
        askAfterUnitPrices,
        bidAfterUnitPrices,
      }
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        ...partial,
      })
    },
    askAfterUnitPriceInput(index: number, unitPrice: string): void {
      // スプレッドのときには呼ばれないはず
      if (this.isStandardSpread) {
        return
      }
      const askAfterUnitPrices = [...this.formValue.askAfterUnitPrices]
      askAfterUnitPrices[index] = unitPrice
      const bidAfterUnitPrices = [...this.formValue.bidAfterUnitPrices]
      bidAfterUnitPrices[index] = sum(
        unitPrice,
        this.formValue.creditSleeverSleeveSpread,
      )
      const partial: Pick<
        NegotiationFormProps,
        'askAfterUnitPrices' | 'bidAfterUnitPrices'
      > = {
        askAfterUnitPrices,
        bidAfterUnitPrices,
      }
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        ...partial,
      })
    },
    askAfterVolumeInput(index: number, volume: string): void {
      const askAfterVolumes = [...this.formValue.askAfterVolumes]
      askAfterVolumes[index] = volume
      const bidAfterVolumes = [...this.formValue.bidAfterVolumes]
      bidAfterVolumes[index] = volume

      const partial: Pick<
        NegotiationFormProps,
        'askAfterVolumes' | 'bidAfterVolumes'
      > = {
        askAfterVolumes,
        bidAfterVolumes,
      }
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        ...partial,
      })
    },
    askExchangeAfterVolumeInput(index: number, volume: string): void {
      if (
        this.formValue.askAfterExchangeVolumes === undefined ||
        this.formValue.bidAfterExchangeVolumes === undefined
      ) {
        return // 交換側の数量なしは想定しない
      }
      const askAfterExchangeVolumes = [
        ...this.formValue.askAfterExchangeVolumes,
      ]
      askAfterExchangeVolumes[index] = volume
      const bidAfterExchangeVolumes = [
        ...this.formValue.bidAfterExchangeVolumes,
      ]
      bidAfterExchangeVolumes[index] = volume

      const partial: Pick<
        NegotiationFormProps,
        'askAfterExchangeVolumes' | 'bidAfterExchangeVolumes'
      > = {
        askAfterExchangeVolumes,
        bidAfterExchangeVolumes,
      }
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        ...partial,
      })
    },
  },
})
