import { RouteRecordRaw } from 'vue-router'

import LngPlantOutage from '@/views/sales/kpler/LngPlantOutage.vue'
import SpotTrade from '@/views/sales/kpler/SpotTrade.vue'
import VesselAvailability from '@/views/sales/kpler/VesselAvailability.vue'
import ActiveUsers from '@/views/sales/sales-kpi/ActiveUsers.vue'
import BrokerageStrategy from '@/views/sales/sales-kpi/BrokerageStrategy.vue'
import RequestCounts from '@/views/sales/sales-kpi/RequestCounts.vue'
import WalletShare from '@/views/sales/sales-kpi/WalletShare.vue'

/**
 * パス: /sales/...
 */
export const salesRoutes: RouteRecordRaw[] = [
  {
    path: '/sales/kpler/spot-trade',
    name: 'SpotTrade',
    component: SpotTrade,
  },
  {
    path: '/sales/kpler/vessel-availability',
    name: 'VesselAvailability',
    component: VesselAvailability,
  },
  {
    path: '/sales/kpler/lng-plant-outage',
    name: 'LngPlantOutage',
    component: LngPlantOutage,
  },
  {
    path: '/sales/sales-kpi/wallet-share',
    name: 'WalletShare',
    component: WalletShare,
  },
  {
    path: '/sales/sales-kpi/brokerage-strategy',
    name: 'BrokerageStrategy',
    component: BrokerageStrategy,
  },
  {
    path: '/sales/sales-kpi/request-counts',
    name: 'RequestCounts',
    component: RequestCounts,
  },
  {
    path: '/sales/sales-kpi/active-users',
    name: 'ActiveUsers',
    component: ActiveUsers,
  },
]
