import { RouteRecordRaw } from 'vue-router'

import Negotiation from '@/views/trading/Negotiation.vue'
import NegotiationNew from '@/views/trading/NegotiationNew.vue'
import Negotiations from '@/views/trading/Negotiations.vue'

/**
 * パス: /negotiations/...
 */
export const negotiationsRoutes: RouteRecordRaw[] = [
  {
    path: '/negotiations',
    name: 'Negotiations',
    component: Negotiations,
  },
  {
    path: '/negotiations/new',
    name: 'NegotiationNew',
    component: NegotiationNew,
  },
  {
    path: '/negotiations/:id',
    name: 'Negotiation',
    component: Negotiation,
    props: route => ({ id: route.params.id }),
  },
]
