
import { Form as ValidationProvider } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import DeliveryPeriodForm from '@/components/trading/DeliveryPeriodForm.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { DeliveryPeriodFormProps } from '@/components/trading/interface/DeliveryPeriodFormProps'

export default defineComponent({
  name: 'NonStandardDeliveryTermsForm',
  components: {
    BaseButton,
    BaseIcon,
    BaseLabel,
    DeliveryPeriodForm,
    ValidationProvider,
  },
  props: {
    formValue: {
      type: Object as PropType<{ deliveryPeriods: DeliveryPeriodFormProps[] }>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    dayPatternOptions: {
      type: Array as PropType<InputOption[]>,
      default: () => [],
    },
    mode: {
      type: Number,
      required: true,
      validator: (value: IndicationFormInputMode) => {
        return Object.values(IndicationFormInputMode).includes(value)
      },
    },
    startDeliveryMinDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    startDeliveryMaxDates: {
      type: Array as PropType<Date[]>,
      default: undefined,
    },
    endDeliveryMinDates: {
      type: Array as PropType<Date[]>,
      required: true,
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
  } {
    return {
      formInputEventName: 'form-input',
    }
  },
  computed: {
    isShownAddButton(): boolean {
      return [
        IndicationFormInputMode.NEW,
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.EDIT,
      ].includes(this.mode)
    },
  },
  methods: {
    onFormInput(index: number, value: DeliveryPeriodFormProps) {
      this.$emit(this.formInputEventName, {
        deliveryPeriods: this.formValue.deliveryPeriods.map((v, i) =>
          i === index ? value : v,
        ),
      })
    },
    addDeliveryPeriodValue() {
      this.$emit(this.formInputEventName, {
        deliveryPeriods: [
          ...this.formValue.deliveryPeriods,
          {
            startDeliveryDate: undefined,
            endDeliveryDate: undefined,
            deliveryPatternComponentFormValues: [
              {
                dayPattern: undefined,
                timeRangeStart: undefined,
                timeRangeEnd: undefined,
              },
            ],
            unitPrice: '',
            volume: null,
          },
        ],
      })
    },
    onDeleteDeliveryPeriodValue(index: number) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        deliveryPeriods: this.formValue.deliveryPeriods.filter(
          (_, i) => i !== index,
        ),
      })
    },
  },
})
