// import { AxiosResponse } from 'axios'
import { plainToClass } from 'class-transformer'
import chunk from 'lodash/chunk'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  // OrganizationsApi,
  PublicOrganizationProfilesApi,
} from '@/api/generated'
import { PublicOrganizationProfile } from '@/models/iam/PublicOrganizationProfile'
import { PublicOrganizationProfileGetters } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileGetters'
import {
  PublicOrganizationProfileMutations,
  SET_PUBLIC_ORGANIZATION_PROFILES,
} from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileMutations'
import { PublicOrganizationProfileState } from '@/store/modules/iam/publicOrganizationProfile/PublicOrganizationProfileState'
import {
  // arrayBuffer2JpegDataUrl,
  isRequiredAuthenticatedRequest,
} from '@/utils/picture'

export class PublicOrganizationProfileActions extends Actions<
  PublicOrganizationProfileState,
  PublicOrganizationProfileGetters,
  PublicOrganizationProfileMutations,
  PublicOrganizationProfileActions
> {
  async fetchPublicOrganizationProfilesByIds(payload: {
    organizationIds: string[]
  }) {
    const organizationIdsToFetch = payload.organizationIds.filter(
      organizationId =>
        !this.state.publicOrganizationProfiles
          .map(profile => profile.organizationId)
          .includes(organizationId),
    )
    if (organizationIdsToFetch.length > 0) {
      const organizationIdsPerRequest = chunk(organizationIdsToFetch, 20)

      const publicOrganizationProfilePromise = organizationIdsPerRequest.map(
        organizationIds => {
          const id =
            organizationIds.length === 1 ? organizationIds[0] : undefined
          const ids = organizationIds.length > 1 ? organizationIds : undefined
          return new PublicOrganizationProfilesApi(apiConfig)
            .getPublicOrganizationProfiles(id, ids)
            .catch(error => console.log(error))
        },
      )

      const publicOrganizationProfiles = (
        await Promise.all(publicOrganizationProfilePromise)
      )
        .map(response => {
          if (response) {
            return response.data?.organizations
          }
        })
        .flat()
        .filter(organization => organization)
        .map(organization =>
          plainToClass(PublicOrganizationProfile, organization),
        )

      // const organizationPicturePromises = publicOrganizationProfiles.map(
      //   profile =>
      //     isRequiredAuthenticatedRequest(profile.picture)
      //       ? new OrganizationsApi(apiConfig).getPictureOfOrganization(
      //           profile.organizationId,
      //           {
      //             responseType: 'arraybuffer',
      //           },
      //         )
      //       : Promise.resolve({ data: '' } as AxiosResponse),
      // )
      // const organizationPictures = (
      //   await Promise.all(organizationPicturePromises)
      // ).map(response => response.data)

      for (const [
        index,
        publicOrganizationProfile,
      ] of publicOrganizationProfiles.entries()) {
        if (isRequiredAuthenticatedRequest(publicOrganizationProfile.picture)) {
          // publicOrganizationProfiles[index].picture = arrayBuffer2JpegDataUrl(
          //   (organizationPictures[index] as unknown) as ArrayBuffer,
          // )
          publicOrganizationProfiles[index].picture = ''
        }
      }

      this.commit(SET_PUBLIC_ORGANIZATION_PROFILES, {
        publicOrganizationProfiles: [
          ...this.state.publicOrganizationProfiles,
          ...publicOrganizationProfiles,
        ],
      })
    }
  }
}
