import { Mutations } from 'vuex-smart-module'

import { MyDeal } from '@/models/trading/MyDeal'
import { MyDealState } from '@/store/modules/trading/myDeal/MyDealState'
import { BaseFilterAttribute } from '@/store/modules/trading/utils/interface'

export const SET_MY_DEALS = 'SET_MY_DEALS'
export const SET_IS_FETCHING_MY_DEALS = 'SET_IS_FETCHING_MY_DEALS'
export const SET_FILTER_CONDITION_OF_MY_DEAL = 'SET_FILTER_CONDITION_OF_MY_DEAL'
export const SET_SELECTED_FILTERS_OF_MY_DEAL = 'SET_SELECTED_FILTERS_OF_MY_DEAL'

export class MyDealMutations extends Mutations<MyDealState> {
  [SET_MY_DEALS](payload: { myDeals: MyDeal[] }) {
    this.state.myDeals = payload.myDeals
  }

  [SET_IS_FETCHING_MY_DEALS](payload: { isFetchingMyDeals: boolean }) {
    this.state.isFetchingMyDeals = payload.isFetchingMyDeals
  }

  [SET_FILTER_CONDITION_OF_MY_DEAL](payload: {
    attribute: BaseFilterAttribute
    selected: string[]
  }) {
    this.state.filterCondition[payload.attribute] = payload.selected
  }

  [SET_SELECTED_FILTERS_OF_MY_DEAL](payload: {
    selectedFilters: BaseFilterAttribute[]
  }) {
    this.state.selectedFiltersOfMyDeal = payload.selectedFilters
  }
}
