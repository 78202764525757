import { IndicationStatusType, Locale, PositionType } from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { i18n } from '@/i18n'
import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { HourType } from '@/models/trading/HourType'
import { NonStandardOptions } from '@/models/trading/NonStandardOptions'
import { ProductType } from '@/models/trading/ProductType'
import {
  IndicationFilterAttribute,
  IndicationFilterConditions,
} from '@/store/modules/trading/indication/interface'

export const getIndicationsFilter = (
  areas: Area[],
  deliveryYearMonths: DeliveryYearMonth[],
  hourTypes: HourType[],
  productTypes: ProductType[],
  filterAttributes: IndicationFilterAttribute[],
  filterCondition: IndicationFilterConditions,
  locale: Locale,
): AttributeFilter[] => {
  return filterAttributes.map(attribute => {
    switch (attribute) {
      case 'area': {
        const options = areas.map(type => type.toInputOption())
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'startDeliveryYearMonth': {
        const options = deliveryYearMonths.map(type =>
          type.toProductFiltersOption(),
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'endDeliveryYearMonth': {
        const options = deliveryYearMonths.map(type =>
          type.toProductFiltersOption(),
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'hourType': {
        const options = hourTypes.map(type => type.toInputOption(locale))
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'productType': {
        const options = productTypes.map(type => type.toInputOption(locale))
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'status': {
        const options: InputOption[] = Object.values(IndicationStatusType).map(
          value => {
            return {
              value: value.toString(),
              label: i18n
                .t(`trading.label.brokingIndicationStatusType.${value}`)
                .toString(),
            }
          },
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'position': {
        const options: InputOption[] = Object.values(PositionType).map(
          value => {
            return {
              value: value.toString(),
              label: i18n.t(`trading.label.${value}`).toString(),
            }
          },
        )
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'nonStandard': {
        const options = new NonStandardOptions().options
        return {
          type: 'single',
          attribute,
          filterSingleSelect: {
            value: options.find(
              option => option.value === filterCondition[attribute],
            ),
            options,
          },
        }
      }
      case 'organizationName':
      default:
        return {
          type: 'text',
          attribute,
          value: filterCondition[attribute],
        }
    }
  })
}
