import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4009848a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member__column" }
const _hoisted_2 = { class: "member__column" }
const _hoisted_3 = { class: "member__column" }
const _hoisted_4 = { class: "member__column" }
const _hoisted_5 = { class: "member__column" }
const _hoisted_6 = { class: "member__column" }
const _hoisted_7 = { class: "member__column" }
const _hoisted_8 = { class: "member__column" }
const _hoisted_9 = { class: "member__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_invitation_status = _resolveComponent("invitation-status")!
  const _component_enable_icon = _resolveComponent("enable-icon")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: "member",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_base_box, {
      spacing: "8px 16px",
      class: "member__box"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_base_picture, {
            url: _ctx.memberProfile.picture,
            width: 24
          }, null, 8, ["url"])
        ]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.memberProfile.name), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.memberProfile.mail), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.memberProfile.phoneNumber), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.memberProfile.mobilePhoneNumber), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.roleType), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_invitation_status, {
            class: "member__invitation-status",
            status: _ctx.memberProfile.status
          }, null, 8, ["status"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_enable_icon, { enable: _ctx.enabledESquare }, null, 8, ["enable"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_enable_icon, { enable: _ctx.enabledECompass }, null, 8, ["enable"])
        ])
      ]),
      _: 1
    })
  ]))
}