
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BasePicture from '@/components/common/BasePicture.vue'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export default defineComponent({
  name: 'OrganizationHeadline',
  components: {
    BasePicture,
  },
  props: {
    organizationName: {
      type: Object as PropType<MultiLocaleName>,
      required: true,
    },
    pictureUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    name(): string {
      const ja = this.organizationName.translations?.find(
        t => t.locale === Locale.Ja,
      )
      const en = this.organizationName.translations?.find(
        t => t.locale === Locale.En,
      )
      return [ja?.text, en?.text].filter(item => !!item).join(' / ')
    },
  },
})
