// import Vue from 'vue'
// import Vuex from 'vuex'
import { createStore, Module } from 'vuex-smart-module'

import { chatBroadcast } from '@/store/modules/chat/broadcast'
import { chat } from '@/store/modules/chat/chat'
import { global } from '@/store/modules/common/global'
import { information } from '@/store/modules/common/information'
import { clearingInformation } from '@/store/modules/iam/clearingInformation'
import { member } from '@/store/modules/iam/member'
import { occtoMaster } from '@/store/modules/iam/occtoMaster'
import { organizationEmailAddress } from '@/store/modules/iam/organizationEmailAddress'
import { organizationProfile } from '@/store/modules/iam/organizationProfile'
import { organizations } from '@/store/modules/iam/organizations'
import { publicOrganizationProfile } from '@/store/modules/iam/publicOrganizationProfile'
import { publicUserProfile } from '@/store/modules/iam/publicUserProfile'
import { recapReceiver } from '@/store/modules/iam/recapReceiver'
import { userProfile } from '@/store/modules/iam/userProfile'
import { users } from '@/store/modules/iam/users'
import { welcome } from '@/store/modules/iam/welcome'
import { localizedReports } from '@/store/modules/reports/localizedReports'
import { reports } from '@/store/modules/reports/reports'
import { assignedBrokerOfOrganization } from '@/store/modules/trading/assginedBrokerOfOrganization'
import { broking } from '@/store/modules/trading/broking'
import { deal } from '@/store/modules/trading/deal'
import { eexPosting } from '@/store/modules/trading/eexPosting'
import { indication } from '@/store/modules/trading/indication'
import { myDeal } from '@/store/modules/trading/myDeal'
import { myIndication } from '@/store/modules/trading/myIndication'
import { negotiation } from '@/store/modules/trading/negotiation'
import { orderBook } from '@/store/modules/trading/orderBook'
import { product } from '@/store/modules/trading/product'
import { publicIndication } from '@/store/modules/trading/publicIndication'
import { recap } from '@/store/modules/trading/recap'

// Vue.use(Vuex)

// TODO: アプリケーションタイプによって分ける
export const root = new Module({
  modules: {
    global,
    publicIndication,
    product,
    myIndication,
    myDeal,
    member,
    chat,
    chatBroadcast,
    clearingInformation,
    broking,
    userProfile,
    users,
    publicUserProfile,
    occtoMaster,
    organizationEmailAddress,
    organizationProfile,
    publicOrganizationProfile,
    welcome,
    recapReceiver,
    organizations,
    information,
    localizedReports,
    reports,
    indication,
    deal,
    negotiation,
    orderBook,
    assignedBrokerOfOrganization,
    recap,
    eexPosting,
  },
})

export const store = createStore(root)
