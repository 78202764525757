export function pushGtag(event: string) {
  window.gtag('event', event, {
    page_path: window.location.pathname,
  })
}

export function setAuth0IdToGa4(auth0Id: string) {
  window.gtag('set', 'user_properties', {
    auth0_id: auth0Id,
  })
}
