
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'

export default defineComponent({
  name: 'FilterInputText',
  components: { BaseIcon, BaseInputText },
  props: {
    filterAttribute: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: [
    'input',
    'filter-remove',
    'blur',
    'keydown',
    'compositionstart',
    'compositionend',
  ],
  data() {
    return {
      inputValue: '',
    }
  },
  computed: {
    placeholder(): string {
      return this.name
        ? this.$t('common.message.inputPlaceholder', {
            name: this.name,
          }).toString()
        : ''
    },
    name(): string {
      return this.filterAttribute
        ? this.$t('trading.label.' + this.filterAttribute).toString()
        : ''
    },
  },
  mounted() {
    this.inputValue = this.value
  },
  methods: {
    onInput(event: string) {
      this.inputValue = event
      this.$emit('input', this.inputValue)
    },
    onClick() {
      this.$emit('filter-remove')
    },
    onBlur(event: Event) {
      this.$emit('blur', event)
    },
    onKeydown(event: KeyboardEvent) {
      this.$emit('keydown', event)
    },
    onCompositionstart() {
      this.$emit('compositionstart')
    },
    onCompositionend() {
      this.$emit('compositionend')
    },
  },
})
