import { z } from 'zod'

const orderBookFilterAttribute = [
  'areaIds',
  'startDeliveryYearMonths',
  'endDeliveryYearMonths',
  'hourTypeIds',
  'productTypeIds',
] as const

export const OrderBookFilterAttributeSchema = z.array(
  z.enum(orderBookFilterAttribute),
)

export type OrderBookFilterAttribute = typeof orderBookFilterAttribute[number]

export const OrderBookFilterConditionSchema = z.object({
  areaIds: z.array(z.string().uuid()),
  startDeliveryYearMonths: z.array(z.string().regex(/^\d{4}-\d{2}$/)),
  endDeliveryYearMonths: z.array(z.string().regex(/^\d{4}-\d{2}$/)),
  hourTypeIds: z.array(z.string().uuid()),
  productTypeIds: z.array(z.string().uuid()),
})

export type OrderBookFilterCondition = z.infer<
  typeof OrderBookFilterConditionSchema
>

export type FetchOrderBooksParams = {
  areaIds?: string
  startDeliveryYearMonths?: string
  endDeliveryYearMonths?: string
  hourTypeIds?: string
  productTypeIds?: string
}

export const keyOfSelectedFiltersWithSpread =
  'selectedFiltersWithSpreadOfTrading'
export const keyOfSelectedFiltersConditionWithSpread =
  'selectedFiltersConditionWithSpreadOfTrading'
