
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { Locale, ReportType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputCheckbox from '@/components/common/BaseInputCheckbox.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseModal from '@/components/common/BaseModal.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import ReportContentForm from '@/components/reports/ReportContentForm.vue'
import ReportContentList from '@/components/reports/ReportContentList.vue'
import { ReportContentFormInput } from '@/components/reports/interface/ReportContentFormInput'
import { Report } from '@/models/reports/Report'
import { ReportsModule } from '@/store/modules/reports/reports'
import { ReportUpdatePayload } from '@/store/modules/reports/reports/ReportsActions'
import { downloadFile, setNotification } from '@/utils/utils'

const { mapActions: reportsMapActions } = createNamespacedHelpers(
  'reports',
) as ReportsModule

export default defineComponent({
  name: 'Report',
  components: {
    ActionDropdown,
    BaseAlert,
    BaseButton,
    BaseIcon,
    BaseInputCheckbox,
    BaseLabel,
    BaseModal,
    BaseTooltip,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    ReportContentForm,
    ReportContentList,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    locale: typeof Locale
    reportContentModalName: string
    publishReportModalName: string
    deleteReportModalName: string
    currentLocale: Locale
    reportOrigin: Report | undefined
    values: {
      reportTypes: string[]
      reportContents: {
        [_ in Locale]: ReportContentFormInput
      }
    }
    reportTypeOptions: InputOption[]
    reportActionDropdownIsActive: boolean
    reportContentActionDropdownIsActive: boolean
    publishReportSubmitting: boolean
    deleteReportSubmitting: boolean
    uiStack: UiStack
    errorType: string
  } {
    return {
      locale: Locale,
      reportContentModalName: 'reportContent',
      publishReportModalName: 'publishReport',
      deleteReportModalName: 'deleteReport',
      currentLocale: Locale.Ja,
      reportOrigin: undefined,
      values: {
        reportTypes: [],
        reportContents: {
          [Locale.Ja]: {
            title: undefined,
            summary: undefined,
            selectedFile: undefined,
          },
          [Locale.En]: {
            title: undefined,
            summary: undefined,
            selectedFile: undefined,
          },
        },
      },
      reportTypeOptions: [
        {
          value: ReportType.Power,
          label: this.$t('reports.label.power').toString(),
        },
        {
          value: ReportType.Gas,
          label: this.$t('reports.label.gas').toString(),
        },
      ],
      reportActionDropdownIsActive: false,
      reportContentActionDropdownIsActive: false,
      publishReportSubmitting: false,
      deleteReportSubmitting: false,
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
    }
  },
  computed: {
    reportContentModalTitle(): string {
      const displayLanguage = {
        [Locale.Ja]: this.$t('common.label.japanese'),
        [Locale.En]: this.$t('common.label.english'),
      }[this.currentLocale]
      return `${this.$t('reports.label.reportContent')} (${displayLanguage})`
    },
    reportActionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('common.label.delete').toString(),
          eventName: 'delete-report-click',
        },
      ]
    },
    reportContentActionDropdownItems(): ActionDropdownItem[] {
      return [
        {
          label: this.$t('common.label.delete').toString(),
          eventName: 'delete-report-content-click',
          disabled: !this.isValidContentOf(this.currentLocale),
        },
      ]
    },
    currentContentAttachmentName(): string {
      if (
        // 添付ファイルが未選択
        !this.values.reportContents[this.currentLocale].selectedFile &&
        // かつレポートコンテンツが削除されていない (タイトルが undefined なら削除されたと見なす)
        this.values.reportContents[this.currentLocale].title &&
        // かつアップロード済みの添付ファイルが存在する
        this.reportOrigin?.reportContents[this.currentLocale]
          ?.attachmentName !== undefined
      ) {
        return this.reportOrigin.reportContents[this.currentLocale]
          .attachmentName
      }
      return ''
    },
    hasValidContent(): boolean {
      return (
        this.isValidContentOf(Locale.Ja) || this.isValidContentOf(Locale.En)
      )
    },
    payload(): ReportUpdatePayload | undefined {
      if (this.reportOrigin === undefined) {
        return
      }
      const payload: ReportUpdatePayload = {
        reportId: this.reportOrigin.id,
        reportTypes: this.values.reportTypes,
        reportContents: {},
        attachmentFileJa: this.values.reportContents[Locale.Ja].selectedFile,
        attachmentFileEn: this.values.reportContents[Locale.En].selectedFile,
      }
      const jaContentOriginAttachment = this.reportOrigin?.reportContents[
        Locale.Ja
      ]?.attachmentName
      const jaContent = this.values.reportContents[Locale.Ja]
      if (
        jaContent.title &&
        jaContent.summary &&
        (jaContent.selectedFile || jaContentOriginAttachment)
      ) {
        payload.reportContents[Locale.Ja] = {
          title: jaContent.title,
          description: jaContent.summary,
        }
      }
      const enContentOriginAttachment = this.reportOrigin?.reportContents[
        Locale.En
      ]?.attachmentName
      const enContent = this.values.reportContents[Locale.En]
      if (
        enContent.title &&
        enContent.summary &&
        (enContent.selectedFile || enContentOriginAttachment)
      ) {
        payload.reportContents[Locale.En] = {
          title: enContent.title,
          description: enContent.summary,
        }
      }
      return payload
    },
    displayPublishedAt(): string {
      if (!this.reportOrigin) {
        return ''
      }
      return this.reportOrigin.displayPublishedAt()
    },
  },
  async created() {
    await this.fetchReport(this.$route.params.id as string)
      .then(report => {
        this.uiStack = UiStack.Ideal
        this.reportOrigin = report
        this.setValues(report)
      })
      .catch(() => {
        this.uiStack = UiStack.Error
      })
  },
  methods: {
    fetchAttachmentUrl: reportsMapActions(['fetchAttachmentUrl'])
      .fetchAttachmentUrl as (payload: {
      reportId: string
      reportContentId: string
    }) => Promise<{ url: string }>,
    fetchReport: reportsMapActions(['fetchReport']).fetchReport as (
      reportId: string,
    ) => Promise<Report>,
    ...reportsMapActions(['updateReport', 'deleteReport']),
    onInputReportTypes(reportTypes: string[]) {
      this.values.reportTypes = reportTypes
    },
    setValues(report: Report) {
      this.values.reportTypes = report.reportTypes
      const reportContentJa = report.reportContents[Locale.Ja]
      this.values.reportContents[Locale.Ja] = {
        title: reportContentJa?.title,
        summary: reportContentJa?.description,
      }
      const reportContentEn = report.reportContents[Locale.En]
      this.values.reportContents[Locale.En] = {
        title: reportContentEn?.title,
        summary: reportContentEn?.description,
      }
    },
    openReportContentModal(locale: Locale) {
      this.currentLocale = locale
      this.$vfm.open(this.reportContentModalName)
    },
    async backListPage() {
      await this.$router.push('/reports')
    },
    async onCancelClick() {
      await this.backListPage()
    },
    async onPublishReportClick() {
      this.$vfm.open(this.publishReportModalName)
    },
    async onPublishReportCancel() {
      this.$vfm.close(this.publishReportModalName)
    },
    async onPublishReportConfirm() {
      const payload = this.payload
      if (!payload) {
        return
      }
      this.publishReportSubmitting = true
      await this.updateReport(payload)
        .then(async () => {
          setNotification(
            this.$t('reports.message.successPublishReport').toString(),
          )
          await this.backListPage()
        })
        .catch(() => {
          setNotification(
            this.$t('reports.message.failPublishReport').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.$vfm.close(this.publishReportModalName)
          this.publishReportSubmitting = false
        })
    },
    async onDownloadClick() {
      if (!this.reportOrigin) {
        return
      }
      const reportId = this.reportOrigin.id
      const reportContentId = this.reportOrigin.reportContents[
        this.currentLocale
      ].id
      const name = this.reportOrigin.reportContents[this.currentLocale]
        .attachmentName
      const { url } = await this.fetchAttachmentUrl({
        reportId,
        reportContentId,
      })
      downloadFile({ url, name })
    },
    onConfirmReportContentClick(
      reportContentFormInput: ReportContentFormInput,
    ) {
      this.values.reportContents[this.currentLocale] = reportContentFormInput
      this.hideReportContentModal()
    },
    onDeleteReportContentClick() {
      this.values.reportContents[this.currentLocale] = {
        title: undefined,
        summary: undefined,
        selectedFile: undefined,
      }
      this.hideReportContentModal()
    },
    async onDeleteReportClick() {
      this.$vfm.open(this.deleteReportModalName)
    },
    onDeleteReportCancel() {
      this.$vfm.close(this.deleteReportModalName)
    },
    async onDeleteReportConfirm() {
      if (!this.reportOrigin) {
        return
      }
      this.deleteReportSubmitting = true
      await this.deleteReport(this.reportOrigin.id)
        .then(async () => {
          setNotification(
            this.$t('reports.message.successDeleteReport').toString(),
          )
          await this.backListPage()
        })
        .catch(() => {
          setNotification(
            this.$t('reports.message.failDeleteReport').toString(),
            'danger',
          )
        })
        .finally(() => {
          this.$vfm.close(this.deleteReportModalName)
          this.deleteReportSubmitting = false
        })
    },
    isValidContentOf(locale: Locale): boolean {
      const payload = this.payload
      return !!payload && !!payload.reportContents[locale]
    },
    hideReportContentModal() {
      this.reportContentActionDropdownIsActive = false
      this.$vfm.close(this.reportContentModalName)
    },
    onCopyUrlClick($event: Event) {
      $event.preventDefault()
      if (!this.reportOrigin) {
        return
      }
      navigator.clipboard.writeText(
        `${location.origin}/reports/${this.reportOrigin.id}`,
      )
      setNotification(this.$t('reports.message.successCopyUrl').toString())
    },
  },
})
