import { Module } from 'vuex-smart-module'

import { OrganizationsActions } from '@/store/modules/iam/organizations/OrganizationsActions'
import { OrganizationsGetters } from '@/store/modules/iam/organizations/OrganizationsGetters'
import { OrganizationsMutations } from '@/store/modules/iam/organizations/OrganizationsMutations'
import { OrganizationsState } from '@/store/modules/iam/organizations/OrganizationsState'

export const organizations = new Module({
  state: OrganizationsState,
  getters: OrganizationsGetters,
  mutations: OrganizationsMutations,
  actions: OrganizationsActions,
})

export type OrganizationsModule = typeof organizations
