
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import SelectedTocomBroker from '@/components/trading/SelectedTocomBroker.vue'
import TocomBrokerSelectorModal from '@/components/trading/container/TocomBrokerSelectorModal.vue'
import { TocomBroker } from '@/models/trading/TocomBroker'

export default defineComponent({
  name: 'TocomBrokerSelector',
  components: {
    BaseModal,
    SelectedTocomBroker,
    TocomBrokerSelectorModal,
  },
  props: {
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedTocomBroker: {
      type: Object as PropType<TocomBroker>,
      default: undefined,
    },
    organizationId: {
      type: String,
      required: true,
    },
    modalName: {
      type: String,
      default: 'tocomBrokerSelectorModal',
    },
  },
  emits: ['select'],
  methods: {
    onSelect(tocomBroker: TocomBroker) {
      this.$emit('select', tocomBroker)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.modalName)
    },
    hideModal() {
      this.$vfm.close(this.modalName)
    },
  },
})
