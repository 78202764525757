import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6befa44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assigned-broker-of-organization-form" }
const _hoisted_2 = { class: "assigned-broker-of-organization-form__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_radio = _resolveComponent("base-input-radio")!
  const _component_base_text_area = _resolveComponent("base-text-area")!
  const _component_base_single_select = _resolveComponent("base-single-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_radio, {
        label: _ctx.$t('iam.label.toIncludeSalesTaxInInvoice').toString(),
        name: "toIncludeSalesTaxInInvoice",
        value: _ctx.formValue.isSalesTaxIncludedInInvoice,
        options: _ctx.salesTaxIncludedInInvoiceInputOptions,
        onInput: _cache[0] || (_cache[0] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              isSalesTaxIncludedInInvoice: event,
            })
        )
      }, null, 8, ["label", "value", "options"]),
      _createVNode(_component_base_text_area, {
        label: _ctx.$t('iam.label.recapAddress').toString(),
        name: "recapAddress",
        value: _ctx.formValue.recapAddress,
        rules: "required",
        required: "",
        onInput: _cache[1] || (_cache[1] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, recapAddress: event })
        )
      }, null, 8, ["label", "value"]),
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('trading.label.brokerName'),
        name: "productType",
        value: _ctx.selectedBrokerId,
        options: _ctx.getBrokerIdInputOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.brokerName'),
          })
        ,
        searchable: true,
        onInput: _ctx.onChangeBrokerId
      }, null, 8, ["label", "value", "options", "placeholder", "onInput"])
    ])
  ]))
}