
import { defineComponent, PropType } from 'vue'

import ChannelDM from '@/components/chat/ChannelDM.vue'
import { ChannelDMProps } from '@/components/chat/interface/ChannelDMProps'
import { ChannelSelectEvent } from '@/components/chat/interface/event/ChannelSelectEvent'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'

export default defineComponent({
  name: 'ChannelSelect',
  components: {
    BaseButton,
    BaseInputText,
    ChannelDM,
  },
  props: {
    channelList: {
      type: Array as PropType<ChannelDMProps[]>,
      default: () => [],
    },
  },
  emits: ['click-channel', 'cancel'],
  data() {
    return {
      searchInput: '',
    }
  },
  methods: {
    onInputText(event: string) {
      this.searchInput = event
    },
    filteredChannelList(): ChannelDMProps[] {
      const filteredChannelList = this.channelList.filter(
        channel =>
          channel.name.includes(this.searchInput) ||
          channel.organizationName.includes(this.searchInput),
      )
      return filteredChannelList
    },
    onClickChannel(event: ChannelSelectEvent) {
      this.$emit('click-channel', { channelId: event })
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
})
