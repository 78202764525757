import { Type } from 'class-transformer'

import {
  AreaOption as IAreaOption,
  BaseStandardIndicationSelectableInputs as IBaseStandardIndicationSelectableInputs,
  DateOption,
  DeliveryUnit,
  DeliveryUnitOption,
  DeliveryYearMonthOption as IDeliveryYearMonthOption,
  FuelSurchargeTypeOption as IFuelSurchargeTypeOption,
  HourTypeOption as IHourTypeOption,
  PositionType,
  PositionTypeOption,
  ProductTypeOption as IProductTypeOption,
  SpreadTypeTypeEnum,
  ValidatablePrice,
  ValidatableValue,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { Area } from '@/models/trading/Area'
import { DeliveryYearMonth } from '@/models/trading/DeliveryYearMonth'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { HourType } from '@/models/trading/HourType'
import { ProductType } from '@/models/trading/ProductType'

class ProductTypeOption implements IProductTypeOption {
  @Type(() => ProductType)
  productType!: ProductType

  isAvailable!: boolean
  isSelected!: boolean
}

class AreaOption implements IAreaOption {
  @Type(() => Area)
  area!: Area

  isAvailable!: boolean
  isSelected!: boolean
}

class HourTypeOption implements IHourTypeOption {
  @Type(() => HourType)
  hourType!: HourType

  isAvailable!: boolean
  isSelected!: boolean
}

class DeliveryYearMonthOption implements IDeliveryYearMonthOption {
  @Type(() => DeliveryYearMonth)
  deliveryYearMonth!: DeliveryYearMonth

  isAvailable!: boolean
  isSelected!: boolean
}

class FuelSurchargeTypeOption implements IFuelSurchargeTypeOption {
  @Type(() => FuelSurchargeType)
  fuelSurchargeType!: FuelSurchargeType

  isAvailable!: boolean
  isSelected!: boolean
}

export class BaseStandardIndicationSelectableInputs
  implements IBaseStandardIndicationSelectableInputs {
  positions!: PositionTypeOption[]
  deliveryUnits!: DeliveryUnitOption[]

  @Type(() => ProductTypeOption)
  productTypes!: ProductTypeOption[]

  @Type(() => FuelSurchargeTypeOption)
  fuelSurchargeTypes!: FuelSurchargeTypeOption[]

  @Type(() => AreaOption)
  areas!: AreaOption[]

  @Type(() => AreaOption)
  exchangeAreas?: AreaOption[]

  @Type(() => HourTypeOption)
  hourTypes!: HourTypeOption[]

  @Type(() => HourTypeOption)
  exchangeHourTypes?: HourTypeOption[]

  @Type(() => DeliveryYearMonthOption)
  deliveryStartYearMonths?: DeliveryYearMonthOption[]

  @Type(() => DeliveryYearMonthOption)
  deliveryEndYearMonths?: DeliveryYearMonthOption[]

  @Type(() => DeliveryYearMonthOption)
  exchangeDeliveryStartYearMonths?: DeliveryYearMonthOption[]

  @Type(() => DeliveryYearMonthOption)
  exchangeDeliveryEndYearMonths?: DeliveryYearMonthOption[]

  deliveryStartDates?: DateOption[]
  deliveryEndDates?: DateOption[]
  exchangeDeliveryStartDates?: DateOption[]
  exchangeDeliveryEndDates?: DateOption[]

  price!: ValidatablePrice
  unitPrice!: ValidatableValue
  volume!: ValidatableValue
  exchangeVolume?: ValidatableValue

  request!: string
  publicInformation?: string
  isAreaSpread!: boolean
  isDeliveryPeriodSpread!: boolean
  isDeliveryPatternSpread!: boolean
  isValid!: boolean
  spellValidationErrorMessage?: string

  getPositionInputOptions(positionReversed: boolean): InputOption[] {
    const inputOptions = this.positions.map(option => {
      return { label: option.name, value: option.position }
    })
    return positionReversed ? inputOptions.reverse() : inputOptions
  }

  get deliveryUnitInputOptions(): InputOption[] {
    return this.deliveryUnits.map(option => {
      return { label: option.name, value: option.deliveryUnit }
    })
  }

  get productTypeInputOptions(): InputOption[] {
    return this.productTypes.map(option => {
      return {
        ...option.productType.toInputOption(),
        disabled: !option.isAvailable,
      }
    })
  }

  get unavailableProductTypeIds(): string[] {
    return this.productTypes
      .filter(
        option => !option.isAvailable || option.productType.hasFuelSurcharge,
      )
      .map(option => {
        return option.productType.id
      })
  }

  get fuelSurchargeTypeInputOptions(): InputOption[] {
    return this.fuelSurchargeTypes.map(option => {
      return option.fuelSurchargeType.toInputOption()
    })
  }

  get areaInputOptions(): InputOption[] {
    return this.areas.map(option => {
      return option.area.toInputOption()
    })
  }

  get exchangeAreaInputOptions(): InputOption[] {
    return this.exchangeAreas
      ? this.exchangeAreas.map(option => {
          return option.area.toInputOption()
        })
      : []
  }

  get hourTypeInputOptions(): InputOption[] {
    return this.hourTypes.map(option => {
      return option.hourType.toInputOption()
    })
  }

  get exchangeHourTypeInputOptions(): InputOption[] {
    return this.exchangeHourTypes
      ? this.exchangeHourTypes.map(option => {
          return option.hourType.toInputOption()
        })
      : []
  }

  get deliveryStartYearMonthInputOptions(): InputOption[] {
    return this.deliveryStartYearMonths
      ? this.deliveryStartYearMonths.map(option => {
          return option.deliveryYearMonth.toInputOption()
        })
      : []
  }

  get deliveryEndYearMonthInputOptions(): InputOption[] {
    return this.deliveryEndYearMonths
      ? this.deliveryEndYearMonths.map(option => {
          return option.deliveryYearMonth.toInputOption()
        })
      : []
  }

  get exchangeDeliveryStartYearMonthInputOptions(): InputOption[] {
    return this.exchangeDeliveryStartYearMonths
      ? this.exchangeDeliveryStartYearMonths.map(option => {
          return option.deliveryYearMonth.toInputOption()
        })
      : []
  }

  get exchangeDeliveryEndYearMonthInputOptions(): InputOption[] {
    return this.exchangeDeliveryEndYearMonths
      ? this.exchangeDeliveryEndYearMonths.map(option => {
          return option.deliveryYearMonth.toInputOption()
        })
      : []
  }

  get currentProductTypes(): ProductType[] {
    return this.productTypes
      .filter(option => option.isSelected && option.isAvailable)
      .map(option => option.productType)
  }

  get currentArea(): Area | undefined {
    return this.areas.find(option => option.isSelected)?.area
  }

  get currentExchangeArea(): Area | undefined {
    return this.exchangeAreas?.find(option => option.isSelected)?.area
  }

  get currentFuelSurchargeType(): FuelSurchargeType | undefined {
    return this.fuelSurchargeTypes?.find(option => option.isSelected)
      ?.fuelSurchargeType
  }

  get currentPosition(): PositionType {
    return (
      this.positions.find(option => option.isSelected)?.position ||
      PositionType.Bid
    )
  }

  get currentDeliveryUnit(): DeliveryUnit {
    return (
      this.deliveryUnits.find(option => option.isSelected)?.deliveryUnit ||
      DeliveryUnit.Month
    )
  }

  get currentHourType(): HourType | undefined {
    return this.hourTypes.find(option => option.isSelected)?.hourType
  }

  get currentExchangeHourType(): HourType | undefined {
    return this.exchangeHourTypes?.find(option => option.isSelected)?.hourType
  }

  get currentDeliveryStartYearMonth(): DeliveryYearMonth | undefined {
    return this.deliveryStartYearMonths?.find(option => option.isSelected)
      ?.deliveryYearMonth
  }

  get currentDeliveryEndYearMonth(): DeliveryYearMonth | undefined {
    return this.deliveryEndYearMonths?.find(option => option.isSelected)
      ?.deliveryYearMonth
  }

  get currentExchangeDeliveryStartYearMonth(): DeliveryYearMonth | undefined {
    return this.exchangeDeliveryStartYearMonths?.find(
      option => option.isSelected,
    )?.deliveryYearMonth
  }

  get currentExchangeDeliveryEndYearMonth(): DeliveryYearMonth | undefined {
    return this.exchangeDeliveryEndYearMonths?.find(option => option.isSelected)
      ?.deliveryYearMonth
  }

  get currentDeliveryStartDate(): string | undefined {
    return this.deliveryStartDates?.find(option => option.isSelected)?.date
  }

  get currentDeliveryEndDate(): string | undefined {
    return this.deliveryEndDates?.find(option => option.isSelected)?.date
  }

  get currentExchangeDeliveryStartDate(): string | undefined {
    return this.exchangeDeliveryStartDates?.find(option => option.isSelected)
      ?.date
  }

  get currentExchangeDeliveryEndDate(): string | undefined {
    return this.exchangeDeliveryEndDates?.find(option => option.isSelected)
      ?.date
  }

  get selectableDeliveryStartDates(): Date[] {
    return this.deliveryStartDates?.map(option => new Date(option.date)) ?? []
  }

  get selectableDeliveryEndDates(): Date[] {
    return this.deliveryEndDates?.map(option => new Date(option.date)) ?? []
  }

  get selectableExchangeDeliveryStartDates(): Date[] {
    return (
      this.exchangeDeliveryStartDates?.map(option => new Date(option.date)) ??
      []
    )
  }

  get selectableExchangeDeliveryEndDates(): Date[] {
    return (
      this.exchangeDeliveryEndDates?.map(option => new Date(option.date)) ?? []
    )
  }

  get hasFuelSurcharge(): boolean {
    return this.fuelSurchargeTypes.length > 0
  }

  getDeliveryStartDateByYearMonthId(id: string): string {
    return (
      this.deliveryStartYearMonths?.find(
        option => option.deliveryYearMonth.id === id,
      )?.deliveryYearMonth.startDate || ''
    )
  }

  getDeliveryEndDateByYearMonthId(id: string): string {
    return (
      this.deliveryEndYearMonths?.find(
        option => option.deliveryYearMonth.id === id,
      )?.deliveryYearMonth.endDate || ''
    )
  }

  getExchangeDeliveryStartDateByYearMonthId(id: string): string {
    return (
      this.exchangeDeliveryStartYearMonths?.find(
        option => option.deliveryYearMonth.id === id,
      )?.deliveryYearMonth.startDate || ''
    )
  }

  getExchangeDeliveryEndDateByYearMonthId(id: string): string {
    return (
      this.exchangeDeliveryEndYearMonths?.find(
        option => option.deliveryYearMonth.id === id,
      )?.deliveryYearMonth.endDate || ''
    )
  }

  getAreaInputOptionById(id: string): InputOption | undefined {
    return this.areas
      ?.find(option => option.area.id === id)
      ?.area.toInputOption()
  }

  getExchangeAreaInputOptionById(id: string): InputOption | undefined {
    return this.exchangeAreas
      ?.find(option => option.area.id === id)
      ?.area.toInputOption()
  }

  getHourTypeInputOptionById(id: string): InputOption | undefined {
    return this.hourTypes
      ?.find(option => option.hourType.id === id)
      ?.hourType.toInputOption()
  }

  getExchangeHourTypeInputOptionById(id: string): InputOption | undefined {
    return this.exchangeHourTypes
      ?.find(option => option.hourType.id === id)
      ?.hourType.toInputOption()
  }

  getDeliveryStartYearMonthInputOptionById(
    id?: string,
  ): InputOption | undefined {
    return this.deliveryStartYearMonths
      ?.find(option => option.deliveryYearMonth.id === id)
      ?.deliveryYearMonth.toInputOption()
  }

  getExchangeDeliveryStartYearMonthInputOptionById(
    id?: string,
  ): InputOption | undefined {
    return this.exchangeDeliveryStartYearMonths
      ?.find(option => option.deliveryYearMonth.id === id)
      ?.deliveryYearMonth.toInputOption()
  }

  getDeliveryEndYearMonthInputOptionById(id: string): InputOption | undefined {
    return this.deliveryEndYearMonths
      ?.find(option => option.deliveryYearMonth.id === id)
      ?.deliveryYearMonth.toInputOption()
  }

  getExchangeDeliveryEndYearMonthInputOptionById(
    id: string,
  ): InputOption | undefined {
    return this.exchangeDeliveryEndYearMonths
      ?.find(option => option.deliveryYearMonth.id === id)
      ?.deliveryYearMonth.toInputOption()
  }

  getFuelSurchargeInputOptionById(id: string): InputOption | undefined {
    return this.fuelSurchargeTypes
      ?.find(option => option.fuelSurchargeType.id === id)
      ?.fuelSurchargeType.toInputOption()
  }

  get isSpread(): boolean {
    return (
      this.isAreaSpread ||
      this.isDeliveryPeriodSpread ||
      this.isDeliveryPatternSpread
    )
  }

  get openedSpreadFormTypes(): SpreadTypeTypeEnum[] {
    const types: SpreadTypeTypeEnum[] = []
    if (this.isAreaSpread) {
      types.push(SpreadTypeTypeEnum.Area)
    }
    if (this.isDeliveryPeriodSpread) {
      types.push(SpreadTypeTypeEnum.DeliveryPeriod)
    }
    if (this.isDeliveryPatternSpread) {
      types.push(SpreadTypeTypeEnum.DeliveryPattern)
    }
    return types
  }
}
