import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee852996"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-message-window" }
const _hoisted_2 = { class: "channel-message-window__close" }
const _hoisted_3 = { class: "channel-message-window__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channel_message_window_title = _resolveComponent("channel-message-window-title")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_message_list = _resolveComponent("message-list")!
  const _component_chat_input_wrapper = _resolveComponent("chat-input-wrapper")!
  const _component_base_chat_window = _resolveComponent("base-chat-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_chat_window, {
      height: _ctx.height,
      width: _ctx.width,
      "is-opened": _ctx.isOpened,
      "has-unread-message": _ctx.hasUnreadMessage,
      onTitleBarClick: _ctx.onTitleBarClick
    }, {
      "title-bar": _withCtx(() => [
        _createElementVNode("div", {
          class: "channel-message-window__title-bar",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onTitleBarClick && _ctx.onTitleBarClick(...args)), ["self"]))
        }, [
          _createVNode(_component_channel_message_window_title, {
            class: "channel-message-window__title",
            name: _ctx.channelName,
            picture: _ctx.channelPicture,
            "has-unread-message": _ctx.hasUnreadMessage,
            onClick: _ctx.onTitleBarClick
          }, null, 8, ["name", "picture", "has-unread-message", "onClick"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_base_icon, {
              "icon-name": "times",
              onClick: _ctx.onCloseClick
            }, null, 8, ["onClick"])
          ])
        ])
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_message_list, {
            ref: "messageList",
            class: "channel-message-window__message-list",
            messages: _ctx.messages,
            "picture-size": "small",
            onTouchStart: _ctx.onTouchStart,
            onReadMessage: _ctx.onReadMessage
          }, null, 8, ["messages", "onTouchStart", "onReadMessage"]),
          _createVNode(_component_chat_input_wrapper, {
            class: "channel-message-window__input-wrapper",
            "chat-input-value": _ctx.chatInputValue,
            "chat-input-rows": 2,
            "typing-user-names": _ctx.typingUserNames,
            "is-sending": _ctx.isSending,
            onInput: _ctx.onInput,
            onTyping: _ctx.onTyping,
            onSend: _ctx.onSend
          }, null, 8, ["chat-input-value", "typing-user-names", "is-sending", "onInput", "onTyping", "onSend"])
        ])
      ]),
      _: 1
    }, 8, ["height", "width", "is-opened", "has-unread-message", "onTitleBarClick"])
  ]))
}