
import { defineComponent, PropType } from 'vue'

import UnitPriceAndVolume from '@/components/trading/UnitPriceAndVolume.vue'
import { Order } from '@/models/trading/Order'

export default defineComponent({
  name: 'OrderPrice',
  components: {
    UnitPriceAndVolume,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShownLeft(): boolean {
      return (
        (this.positionReversed && this.order.isAsk) ||
        (!this.positionReversed && this.order.isBid)
      )
    },
    isShownRight(): boolean {
      return (
        (!this.positionReversed && this.order.isAsk) ||
        (this.positionReversed && this.order.isBid)
      )
    },
  },
})
