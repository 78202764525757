
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { Locale, UpdateOrganization } from '@/api/generated/api'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import { InputOption } from '@/components/common/interface/InputOption'
import EcloudOrganizationDetailForm from '@/components/iam/EcloudOrganizationDetailForm.vue'
import { EcloudOrganizationDetailFormProps } from '@/components/iam/interface/EcloudOrganizationDetailFormProps'
import { i18n } from '@/i18n'
import { LocaleOptions } from '@/models/common/LocaleOptions'
import { Organization } from '@/models/iam/Organization'
import { ReadableReportTypeOptions } from '@/models/iam/ReadableReportTypeOptions'
import { OrganizationProfileModule } from '@/store/modules/iam/organizationProfile'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { setNotification } from '@/utils/utils'

const { mapActions: organizationProfileMapActions } = createNamespacedHelpers(
  'organizationProfile',
) as OrganizationProfileModule

const { mapActions: organizationMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

export default defineComponent({
  name: 'EcloudOrganizationDetail',
  components: {
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    EcloudOrganizationDetailForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    organizationSubmitting: boolean
    uiStack: UiStack
    errorType: AlertType
    formValue: EcloudOrganizationDetailFormProps
    readableReportTypeInputOptions: InputOption[]
    eclearStatusInputOptions: InputOption[]
    enableStatusInputOptions: InputOption[]
    salesTaxIncludedInInvoiceInputOptions: InputOption[]
  } {
    return {
      organizationSubmitting: false,
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      formValue: {
        name: {
          [Locale.Ja]: undefined,
          [Locale.En]: undefined,
        },
        readableReportTypes: [],
        isEClearContracted: false.toString(),
        isEsquareEnabled: false.toString(),
        isEcompassEnabled: false.toString(),
        isEcompassForGxEnabled: false.toString(),
        locale: undefined,
      },
      readableReportTypeInputOptions: new ReadableReportTypeOptions().options,
      eclearStatusInputOptions: [
        {
          value: false.toString(),
          label: i18n.t('iam.label.notContract').toString(),
        },
        {
          value: true.toString(),
          label: i18n.t('iam.label.contract').toString(),
        },
      ],
      enableStatusInputOptions: [
        {
          value: false.toString(),
          label: i18n.t('iam.label.notEnable').toString(),
        },
        {
          value: true.toString(),
          label: i18n.t('iam.label.enable').toString(),
        },
      ],
      salesTaxIncludedInInvoiceInputOptions: [
        {
          label: this.$t('iam.label.notIncludedSalesTax').toString(),
          value: false.toString(),
        },
        {
          label: this.$t('iam.label.includedSalesTax').toString(),
          value: true.toString(),
        },
      ],
    }
  },
  async created() {
    await this.fetchOrganization(this.$attrs.organizationId)
      .then((organization: Organization) => {
        this.setFormValue(organization)
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
  },
  methods: {
    ...organizationProfileMapActions(['fetchOrganizationProfile']),
    fetchOrganization: organizationMapActions(['fetchOrganization'])
      .fetchOrganization as (organizationId: string) => Promise<Organization>,
    updateOrganization: organizationMapActions(['updateOrganization'])
      .updateOrganization as (payload: {
      organizationId: string
      organization: UpdateOrganization
    }) => Promise<Organization>,
    async onSubmit() {
      if (!this.formValue.name || !this.formValue.locale) {
        return
      }
      this.organizationSubmitting = true
      await this.updateOrganization({
        organizationId: this.$attrs.organizationId,
        organization: {
          readableReportTypes: this.formValue.readableReportTypes,
          isEClearContracted:
            this.formValue.isEClearContracted === true.toString(),
          isEsquareEnabled: this.formValue.isEsquareEnabled === true.toString(),
          isEcompassEnabled:
            this.formValue.isEcompassEnabled === true.toString(),
          isEcompassForGxEnabled:
            this.formValue.isEcompassForGxEnabled === true.toString(),
        },
      })
        .then(async (organization: Organization) => {
          await this.fetchOrganizationProfile({
            organizationId: this.$attrs.organizationId,
          }).catch(() => {
            this.uiStack = UiStack.Error
          })
          this.setFormValue(organization)
          setNotification(
            this.$t('iam.message.successUpdateOrganizationDetail').toString(),
          )
        })
        .catch(e => {
          setNotification(
            this.$t('iam.message.failUpdateOrganizationDetail').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.organizationSubmitting = false
        })
    },
    setFormValue(organization: Organization): void {
      this.formValue = {
        name: {
          [Locale.Ja]: organization.name.translations?.find(
            t => t.locale === Locale.Ja,
          )?.text,
          [Locale.En]: organization.name.translations?.find(
            t => t.locale === Locale.En,
          )?.text,
        },
        readableReportTypes: organization.readableReportTypes,
        isEClearContracted: organization.isEClearContracted.toString(),
        isEsquareEnabled: organization.isEsquareEnabled.toString(),
        isEcompassEnabled: organization.isEcompassEnabled.toString(),
        isEcompassForGxEnabled: organization.isEcompassForGxEnabled.toString(),
        locale: new LocaleOptions().options.find(
          option => option.value === organization.locale,
        ),
      }
    },
  },
})
