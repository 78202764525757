import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-069fb7b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trader-selector-modal" }
const _hoisted_2 = { class: "trader-selector-modal__form" }
const _hoisted_3 = { class: "trader-selector-modal__result" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "trader-selector-modal__result-item-name" }
const _hoisted_6 = { class: "trader-selector-modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_text, {
        class: "trader-selector-modal__input-text",
        label: _ctx.$t('trading.label.eexTraderName'),
        name: "eexTraderName",
        value: _ctx.value,
        placeholder: _ctx.$t('trading.label.eexTraderName'),
        onInput: _ctx.onInput
      }, null, 8, ["label", "value", "placeholder", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
        loading: _withCtx(() => [
          _createVNode(_component_centered_loading_content, {
            type: _ctx.grayscale,
            "is-relative": ""
          }, null, 8, ["type"])
        ]),
        error: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.alertTypeError }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.message.failSearchEEXTraders')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        blank: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.alertTypeInfo }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.message.notFoundEEXTrader')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        ideal: _withCtx(() => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.traders, (trader) => {
              return (_openBlock(), _createElementBlock("li", {
                key: trader.eexTraderCode,
                onClick: ($event: any) => (_ctx.onSelect(trader))
              }, [
                _createVNode(_component_base_box, {
                  spacing: "8px 16px",
                  class: "trader-selector-modal__result-item"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(trader.eexTraderName), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 8, _hoisted_4))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["ui-stack"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_button, { onClick: _ctx.onCancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}