
import { defineComponent, PropType } from 'vue'

import BaseModal from '@/components/common/BaseModal.vue'
import SelectedEexClearingHouse from '@/components/trading/SelectedEexClearingHouse.vue'
import EexClearingHouseSelectorModal from '@/components/trading/container/EexClearingHouseSelectorModal.vue'
import { EexClearingHouse } from '@/models/trading/EexClearingHouse'

export default defineComponent({
  name: 'EexClearingHouseSelector',
  components: {
    BaseModal,
    EexClearingHouseSelectorModal,
    SelectedEexClearingHouse,
  },
  props: {
    isSelectable: {
      type: Boolean,
      default: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    selectedEexClearingHouse: {
      type: Object as PropType<EexClearingHouse>,
      default: undefined,
    },
    modalName: {
      type: String,
      default: 'eexClearingHouseModal',
    },
  },
  emits: ['select'],
  methods: {
    onSelect(eexClearingHouse: EexClearingHouse) {
      this.$emit('select', eexClearingHouse)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.modalName)
    },
    hideModal() {
      this.$vfm.close(this.modalName)
    },
  },
})
