import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9ad3434"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "order-buttons"
}
const _hoisted_2 = {
  key: 1,
  class: "order-buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.positionReversed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_base_button, {
            color: _ctx.colorReversed ? 'bid' : 'ask',
            size: "small",
            inactive: _ctx.askButtonInactive,
            onMouseover: _ctx.onAskButtonMouseOver,
            onMouseleave: _ctx.onAskButtonMouseLeave,
            onClick: _withModifiers(_ctx.onAskButtonClick, ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.label.ask')), 1)
            ]),
            _: 1
          }, 8, ["color", "inactive", "onMouseover", "onMouseleave", "onClick"]),
          _createVNode(_component_base_button, {
            color: _ctx.colorReversed ? 'ask' : 'bid',
            size: "small",
            inactive: _ctx.bidButtonInactive,
            onMouseover: _ctx.onBidButtonMouseOver,
            onMouseleave: _ctx.onBidButtonMouseLeave,
            onClick: _withModifiers(_ctx.onBidButtonClick, ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.label.bid')), 1)
            ]),
            _: 1
          }, 8, ["color", "inactive", "onMouseover", "onMouseleave", "onClick"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_base_button, {
            color: _ctx.colorReversed ? 'ask' : 'bid',
            size: "small",
            inactive: _ctx.bidButtonInactive,
            onMouseover: _ctx.onBidButtonMouseOver,
            onMouseleave: _ctx.onBidButtonMouseLeave,
            onClick: _withModifiers(_ctx.onBidButtonClick, ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.label.bid')), 1)
            ]),
            _: 1
          }, 8, ["color", "inactive", "onMouseover", "onMouseleave", "onClick"]),
          _createVNode(_component_base_button, {
            color: _ctx.colorReversed ? 'bid' : 'ask',
            size: "small",
            inactive: _ctx.askButtonInactive,
            onMouseover: _ctx.onAskButtonMouseOver,
            onMouseleave: _ctx.onAskButtonMouseLeave,
            onClick: _withModifiers(_ctx.onAskButtonClick, ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('trading.label.ask')), 1)
            ]),
            _: 1
          }, 8, ["color", "inactive", "onMouseover", "onMouseleave", "onClick"])
        ]))
  ]))
}