
import { defineComponent, PropType } from 'vue'

import NegotiationListItem from '@/components/trading/NegotiationListItem.vue'
import { Negotiation } from '@/models/trading/Negotiation'

export default defineComponent({
  name: 'NegotiationList',
  components: {
    NegotiationListItem,
  },
  props: {
    negotiations: {
      type: Array as PropType<Negotiation[]>,
      default: () => [],
    },
  },
  emits: ['last-item-visible'],
  methods: {
    visibilityChanged(isVisible: boolean, index: number) {
      if (!isVisible || index !== this.negotiations.length - 1) {
        return
      }
      this.$emit('last-item-visible')
    },
  },
})
