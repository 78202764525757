import { Mutations } from 'vuex-smart-module'

import { RoleType } from '@/api/generated'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { MemberState } from '@/store/modules/iam/member/MemberState'

export const SET_MEMBER_PROFILES = 'SET_MEMBER_PROFILES'
export const SET_MAIL_ADDRESS = 'SET_MAIL_ADDRESS'
export const SET_ROLE_IN_ORGANIZATION = 'SET_ROLE_IN_ORGANIZATION'

export class MemberMutations extends Mutations<MemberState> {
  [SET_MEMBER_PROFILES](payload: { memberProfiles: MemberProfile[] }) {
    this.state.memberProfiles = payload.memberProfiles
  }

  [SET_MAIL_ADDRESS](payload: { mailAddress: string | undefined }) {
    this.state.mailAddress = payload.mailAddress
  }

  [SET_ROLE_IN_ORGANIZATION](payload: {
    roleInOrganization: RoleType | undefined
  }) {
    this.state.roleInOrganization = payload.roleInOrganization
  }
}
