
import cloneDeep from 'lodash/cloneDeep'
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { Locale } from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import HorizontalTab from '@/components/common/HorizontalTab.vue'
import TraderPage from '@/components/common/TraderPage.vue'
import { HorizontalTabItem } from '@/components/common/interface/HorizontalTabProps'
import EclearSetting from '@/components/iam/EclearSetting.vue'
import UserSetting from '@/components/iam/UserSetting.vue'
import { EclearSettingFormProps } from '@/components/iam/interface/EclearSettingFormProps'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import {
  SET_DEPARTMENT,
  SET_LOCALE,
  SET_NAME,
  SET_SERVICES,
} from '@/store/modules/iam/userProfile/UserProfileMutations'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const PageTemplate = isBrokerApp ? BrokerPage : TraderPage

const {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} = createNamespacedHelpers('userProfile') as UserProfileModule

export default defineComponent({
  name: 'UserProfile',
  components: {
    BaseButton,
    ConfirmationDialog,
    EclearSetting,
    HorizontalTab,
    PageTemplate,
    UserSetting,
    ValidationForm,
  },
  data(): {
    eclearSettingUpdateButtonSubmitting: boolean
    eclearSettingFormValue: EclearSettingFormProps
  } {
    return {
      eclearSettingUpdateButtonSubmitting: false,
      eclearSettingFormValue: {
        isEclearTrader: false,
        isDemandAndSupplyPlanner: false,
        eclearTraderId: '',
        keyword: '',
      },
    }
  },
  computed: {
    ...mapState([
      'localeSubmitting',
      'servicesSubmitting',
      'name',
      'organizationId',
      'organizationName',
      'phoneNumber',
      'mobilePhoneNumber',
      'mail',
      'department',
      'locale',
      'services',
    ]),
    ...mapGetters([
      'userProfile',
      'isValidName',
      'isValidDepartment',
      'isValidLocale',
      'isValidDisplaySetting',
      'isEsquareEnabled',
      'isEclearEnabled',
      'isEclearTrader',
      'isEclearDemandAndSupplyPlanner',
      'eclearTraderId',
      'eclearKeyword',
    ]),
    translatedOrganizationName(): string {
      return this.organizationName?.translation() || ''
    },
    confirmationModalName(): string {
      return 'delete-picture'
    },
    tabList(): HorizontalTabItem[] {
      const tabList = [this.$t('iam.label.setting').toString()]
      if (this.isEsquareEnabled) {
        tabList.push(this.$t('iam.label.eclearSetting').toString())
      }
      return tabList
    },
    displaySetting(): string[] {
      const displaySetting = []
      if (this.services?.esquare.userSetting) {
        for (const [key, value] of Object.entries(
          this.services.esquare.userSetting,
        )) {
          if (value) {
            displaySetting.push(key)
          }
        }
      }
      return displaySetting
    },
  },
  created() {
    this.eclearSettingFormValue = {
      isEclearTrader: this.isEclearTrader,
      isDemandAndSupplyPlanner: this.isEclearDemandAndSupplyPlanner,
      eclearTraderId: this.eclearTraderId,
      keyword: this.eclearKeyword,
    }
  },
  methods: {
    ...mapMutations([SET_NAME, SET_DEPARTMENT, SET_LOCALE, SET_SERVICES]),
    ...mapActions([
      'fetchUserProfile',
      'updateLocale',
      'updateServices',
      'updateEclearUserSetting',
    ]),
    onUserNameInput(event: string) {
      this[SET_NAME]({ name: event })
    },
    onDepartmentInput(event: string) {
      this[SET_DEPARTMENT]({ department: event })
    },
    onLocaleInput(event: Locale) {
      this[SET_LOCALE]({ locale: event })
    },
    onPositionSettingInput(event: string[]) {
      const services = cloneDeep(this.services)
      if (!services || !services.esquare.userSetting) {
        return
      }
      services.esquare.userSetting.positionReversed = event.includes(
        'positionReversed',
      )
      this[SET_SERVICES]({
        services,
      })
    },
    onColorSettingInput(event: string[]) {
      const services = cloneDeep(this.services)
      if (!services || !services.esquare.userSetting) {
        return
      }
      services.esquare.userSetting.colorReversed = event.includes(
        'colorReversed',
      )
      this[SET_SERVICES]({
        services,
      })
    },
    async onLocaleUpdateClick() {
      await this.updateLocale({
        userId: this.userProfile.userId,
      })
        .then(() => {
          setNotification(this.$t('iam.message.successUpdateLocale').toString())
          this.$auth.refreshToken()
        })
        .catch(() => {
          setNotification(
            this.$t('iam.message.failUpdateLocale').toString(),
            'danger',
          )
        })
    },
    async onDisplaySettingUpdateClick() {
      await this.updateServices({
        userId: this.userProfile.userId,
      })
        .then(() => {
          setNotification(
            this.$t('iam.message.successUpdateServices').toString(),
          )
        })
        .catch(() => {
          setNotification(
            this.$t('iam.message.failUpdateServices').toString(),
            'danger',
          )
        })
    },
    onEclearSettingFormInput(formValue: EclearSettingFormProps) {
      Object.assign(this.eclearSettingFormValue, formValue)
    },
    async onEclearSettingUpdateButtonClick() {
      if (!this.eclearSettingFormValue) {
        return
      }
      this.eclearSettingUpdateButtonSubmitting = true
      await this.updateEclearUserSetting({
        userId: this.userProfile.userId,
        userSetting: {
          keyword: this.eclearSettingFormValue.keyword,
        },
      })
        .then(async () => {
          setNotification(
            this.$t('iam.message.successUpdateEclearSetting').toString(),
          )
          await this.fetchUserProfile({
            userId: this.userProfile.userId,
          })
        })
        .catch(e => {
          setNotification(
            this.$t('iam.message.failUpdateEclearSetting').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.eclearSettingUpdateButtonSubmitting = false
        })
    },
  },
})
