import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_header = _resolveComponent("global-header")!

  return (_openBlock(), _createBlock(_component_global_header, {
    "user-id": _ctx.userId,
    picture: _ctx.picture,
    "is-member": _ctx.isOrganizationMember
  }, null, 8, ["user-id", "picture", "is-member"]))
}