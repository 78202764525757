import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1c290a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public-order-book" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_angle = _resolveComponent("angle")!
  const _component_public_order_book_summary = _resolveComponent("public-order-book-summary")!
  const _component_public_order_book_detail = _resolveComponent("public-order-book-detail")!
  const _component_order_buttons = _resolveComponent("order-buttons")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "public-order-book__inner",
          onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["self"]))
        }, [
          _createElementVNode("div", {
            class: "public-order-book__angle",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, [
            _createVNode(_component_angle, {
              "is-opened": _ctx.isExpanded,
              onClick: _ctx.onClick
            }, null, 8, ["is-opened", "onClick"])
          ]),
          (!_ctx.isExpanded)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "public-order-book__collapsed-wrapper",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
              }, [
                _createVNode(_component_public_order_book_summary, {
                  "delivery-terms": _ctx.deliveryTerms,
                  "delivery-unit": _ctx.deliveryUnit,
                  indications: _ctx.indications,
                  deal: _ctx.deal,
                  "color-reversed": _ctx.colorReversed,
                  "position-reversed": _ctx.positionReversed,
                  locale: _ctx.locale
                }, null, 8, ["delivery-terms", "delivery-unit", "indications", "deal", "color-reversed", "position-reversed", "locale"])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "public-order-book__expanded-wrapper",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
              }, [
                _createVNode(_component_public_order_book_detail, {
                  "delivery-terms": _ctx.deliveryTerms,
                  "delivery-unit": _ctx.deliveryUnit,
                  indications: _ctx.indications,
                  deal: _ctx.deal,
                  "color-reversed": _ctx.colorReversed,
                  "position-reversed": _ctx.positionReversed,
                  locale: _ctx.locale
                }, null, 8, ["delivery-terms", "delivery-unit", "indications", "deal", "color-reversed", "position-reversed", "locale"])
              ])),
          _createVNode(_component_order_buttons, {
            class: "public-order-book__order-buttons",
            "color-reversed": _ctx.colorReversed,
            "position-reversed": _ctx.positionReversed,
            onAskButtonClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onButtonClick('ask'))),
            onBidButtonClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onButtonClick('bid')))
          }, null, 8, ["color-reversed", "position-reversed"])
        ])
      ]),
      _: 1
    })
  ]))
}