import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d8d5fe6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-recap-status" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dealType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.dealType), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass({
        'deal-recap-status-icon': true,
        [`deal-recap-status-icon--${_ctx.status.toLowerCase()}`]: true,
        [`deal-recap-status-icon__size--${_ctx.size}`]: true,
      })
    }, _toDisplayString(_ctx.convertDisplayName()), 3)
  ]))
}