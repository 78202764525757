
import { defineComponent, PropType } from 'vue'

import { InputMode } from '@/components/common/constants/InputMode'
import DealListItem from '@/components/trading/DealListItem.vue'
import { ChangePrivateConfidential } from '@/components/trading/interface/DealListItemEmitters'
import { DealSummary } from '@/models/trading/DealSummary'
import { RecapState } from '@/store/modules/trading/recap/interface'

export default defineComponent({
  name: 'DealList',
  components: { DealListItem },
  props: {
    deals: {
      type: Array as PropType<DealSummary[]>,
      default: () => [],
    },
    idsOfDisabledCheckbox: {
      type: Array as PropType<string[]>,
      required: true,
    },
    recapState: {
      type: Object as PropType<RecapState>,
      default: () => ({}),
    },
  },
  emits: ['confirm', 'last-item-visible', 'private-confidential-change'],
  computed: {
    privateConfidentialInputModeList(): InputMode[] {
      return this.deals.map(({ id }) => {
        return this.idsOfDisabledCheckbox.includes(id)
          ? InputMode.READONLY
          : InputMode.INPUT
      })
    },
  },
  methods: {
    onConfirm(negotiationId: string) {
      this.$emit('confirm', negotiationId)
    },
    visibilityChanged(isVisible: boolean, index: number) {
      if (!isVisible || index !== this.deals.length - 1) {
        return
      }
      this.$emit('last-item-visible')
    },
    onPrivateConfidentialChange(
      changePrivateConfidential: ChangePrivateConfidential,
    ) {
      this.$emit('private-confidential-change', changePrivateConfidential)
    },
  },
})
