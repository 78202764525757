import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-728cee20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "unit-price-and-volume" }
const _hoisted_2 = { class: "unit-price-and-volume__wrapper" }
const _hoisted_3 = { class: "unit-price-and-volume__wrapper" }
const _hoisted_4 = { class: "unit-price-and-volume__column" }
const _hoisted_5 = {
  key: 0,
  class: "unit-price-and-volume__calendar-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fuel_surcharge_type_icon = _resolveComponent("fuel-surcharge-type-icon")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.fuelSurchargeType)
        ? (_openBlock(), _createBlock(_component_fuel_surcharge_type_icon, {
            key: 0,
            class: "unit-price-and-volume__fuel-surcharge-type-icon",
            "fuel-surcharge-type": _ctx.fuelSurchargeType
          }, null, 8, ["fuel-surcharge-type"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["unit-price-and-volume__unit-price", `unit-price-and-volume__unit-price--${_ctx.modifierName}`])
      }, _toDisplayString(_ctx.unitPrice), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasOnlyBase)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["unit-price-and-volume__volume", `unit-price-and-volume__volume--${_ctx.modifierName}`])
            }, _toDisplayString(_ctx.volume), 3))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["unit-price-and-volume__volume unit-price-and-volume__volume__text unit-price-and-volume__volume__text--spread", `unit-price-and-volume__volume--${_ctx.modifierName}`])
            }, _toDisplayString(_ctx.spreadDisplay), 3))
      ]),
      (!!_ctx.executedAt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_base_tooltip, {
              label: `${_ctx.$t('trading.label.executedDate')} ${_ctx.executedAt}`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, { "icon-name": "calendar-alt" })
              ]),
              _: 1
            }, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}