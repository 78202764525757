import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-262dfd64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eex-posting-list-item" }
const _hoisted_2 = { class: "eex-posting-list-item__inner" }
const _hoisted_3 = { class: "eex-posting-list-item__column" }
const _hoisted_4 = { class: "eex-posting-list-item__column" }
const _hoisted_5 = { class: "eex-posting-list-item__column" }
const _hoisted_6 = { class: "eex-posting-list-item__column" }
const _hoisted_7 = { class: "eex-posting-list-item__column" }
const _hoisted_8 = { class: "eex-posting-list-item__column" }
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = ["title"]
const _hoisted_14 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_icon = _resolveComponent("status-icon")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.eexPosting.updatedAt), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_status_icon, {
              class: "eex-posting-list-item__position-icon",
              status: _ctx.eexPosting.status
            }, null, 8, ["status"])
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.eexPosting.productCode), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.eexPosting.instrument), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.eexPosting.price), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.eexPosting.quantity), 1),
          _createElementVNode("div", {
            class: "eex-posting-list-item__column",
            title: _ctx.eexPosting.bidEEXPostingDetail.organizationName.translation()
          }, _toDisplayString(_ctx.eexPosting.bidEEXPostingDetail.organizationName.translation()), 9, _hoisted_9),
          _createElementVNode("div", {
            class: "eex-posting-list-item__column",
            title: _ctx.eexPosting.bidEEXPostingDetail.clearingFirm
          }, _toDisplayString(_ctx.eexPosting.bidEEXPostingDetail.clearingFirm), 9, _hoisted_10),
          _createElementVNode("div", {
            class: "eex-posting-list-item__column",
            title: _ctx.eexPosting.bidEEXPostingDetail.eexTraderName
          }, _toDisplayString(_ctx.eexPosting.bidEEXPostingDetail.eexTraderName), 9, _hoisted_11),
          _createElementVNode("div", {
            class: "eex-posting-list-item__column",
            title: _ctx.eexPosting.askEEXPostingDetail.organizationName.translation()
          }, _toDisplayString(_ctx.eexPosting.askEEXPostingDetail.organizationName.translation()), 9, _hoisted_12),
          _createElementVNode("div", {
            class: "eex-posting-list-item__column",
            title: _ctx.eexPosting.askEEXPostingDetail.clearingFirm
          }, _toDisplayString(_ctx.eexPosting.askEEXPostingDetail.clearingFirm), 9, _hoisted_13),
          _createElementVNode("div", {
            class: "eex-posting-list-item__column",
            title: _ctx.eexPosting.askEEXPostingDetail.eexTraderName
          }, _toDisplayString(_ctx.eexPosting.askEEXPostingDetail.eexTraderName), 9, _hoisted_14),
          _withDirectives(_createVNode(_component_action_dropdown, {
            id: _ctx.eexPosting.id,
            "is-active": _ctx.isActionDropdownActive,
            actions: _ctx.actions,
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onDropdownClick(_ctx.eexPosting.id)), ["stop"])),
            onClickOutside: _ctx.onDropdownOutsideClick,
            onRepost: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRepostClick(_ctx.eexPosting.id)))
          }, null, 8, ["id", "is-active", "actions", "onClickOutside"]), [
            [_vShow, _ctx.isRepostable]
          ])
        ])
      ]),
      _: 1
    })
  ]))
}