import { SpreadTypeTypeEnum } from '@/api/generated'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'

// 呪文使用時専用の関数。呪文が扱えるスプレッドのみを判定する。
export function buildOpenedSpreadFormTypesForSpell({
  selectableInputs,
}: {
  selectableInputs: BaseStandardIndicationSelectableInputs
}): SpreadTypeTypeEnum[] {
  const area = selectableInputs.isAreaSpread ? 'area' : undefined
  const deliveryPeriod = selectableInputs.isDeliveryPeriodSpread
    ? 'deliveryPeriod'
    : undefined

  return [area, deliveryPeriod].filter(
    (v): v is SpreadTypeTypeEnum => v !== undefined,
  )
}
