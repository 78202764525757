
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import StatusToggleButton from '@/components/common/StatusToggleButton.vue'
import OrderBookHeader from '@/components/trading/OrderBookHeader.vue'

export default defineComponent({
  name: 'PublicOrderBookListHeader',
  components: {
    OrderBookHeader,
    StatusToggleButton,
  },
  props: {
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    allCollapsed: {
      type: Boolean,
      default: true,
    },
    withFuelSurcharge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['activate-all', 'deactivate-all'],
  methods: {
    onActivateAll() {
      this.$emit('activate-all')
    },
    onDeactivateAll() {
      this.$emit('deactivate-all')
    },
  },
})
