
import { defineComponent, PropType } from 'vue'

import Angle from '@/components/common/Angle.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BrokingOrderItem from '@/components/trading/BrokingOrderItem.vue'
import DeliveryTermsWithPeriod from '@/components/trading/DeliveryTermsWithPeriod.vue'
import OrderBestPrice from '@/components/trading/OrderBestPrice.vue'
import { DeliveryTermsWithPeriodProps } from '@/components/trading/interface/DeliveryTerms'
import { BrokingOrder } from '@/models/trading/BrokingOrder'
import { BrokingOrderBook } from '@/models/trading/BrokingOrderBook'

export default defineComponent({
  name: 'BrokingOrderBookSpreadItem',
  components: {
    Angle,
    BaseBox,
    BrokingOrderItem,
    DeliveryTermsWithPeriod,
    OrderBestPrice,
  },
  props: {
    orderBook: {
      type: Object as PropType<BrokingOrderBook>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    selectedIndicationIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['click', 'record-select', 'copy-indication'],
  computed: {
    deliveryTerms(): DeliveryTermsWithPeriodProps {
      return this.orderBook.deliveryTerms()
    },
  },
  methods: {
    onRecordSelect(event: Event) {
      this.$emit('record-select', event)
    },
    isOrderSelected(order: BrokingOrder) {
      return order.indicationId
        ? this.selectedIndicationIds.includes(order.indicationId)
        : false
    },
    onCopyIndication(indicationId: string) {
      this.$emit('copy-indication', { indicationId })
    },
  },
})
