import { Type } from 'class-transformer'

import { IndicationStatusType } from '@/api/generated'
import { PublicOrganizationProfile } from '@/models/iam/PublicOrganizationProfile'
import { BaseIndication } from '@/models/trading/BaseIndication'
import { moment } from '@/utils/date'

export abstract class BaseBrokingIndication extends BaseIndication {
  indicationId!: string

  @Type(() => PublicOrganizationProfile)
  publicOrganizationProfile!: PublicOrganizationProfile

  status!: IndicationStatusType
  request?: string
  openToMarket!: boolean
  isVisibleToTraders!: boolean
  externalIndicationId?: string

  createdParticipantId!: string
  lastUpdatedParticipantId!: string
  createdAt!: string
  updatedAt!: string
  maskVolume!: boolean
  isDailyPowerMarket!: boolean

  displayCreatedAt(formatDate: string): string {
    return moment(this.createdAt).format(`${formatDate} HH:mm`)
  }

  displayUpdatedAt(formatDate: string): string {
    return moment(this.updatedAt).format(`${formatDate} HH:mm`)
  }
}
