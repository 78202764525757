
import debounce from 'lodash/debounce'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import {
  DeliveryUnit,
  IndicationStatusType,
  MyStandardIndicationSelectableInputsRequest,
  PositionType,
  SpreadTypeTypeEnum,
  UpdateMyIndication,
} from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import MyIndicationForm from '@/components/trading/MyIndicationForm.vue'
import MyIndicationMetadata from '@/components/trading/MyIndicationMetadata.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { buildUpdateMyIndicationPayload } from '@/components/trading/services/myIndicationPayloadBuilder'
import {
  buildSelectableInputsRequest,
  buildProductFormValue,
} from '@/components/trading/services/myStandardIndicationSelectableInputsServices'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { MyIndication } from '@/models/trading/MyIndication'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { MemberModule } from '@/store/modules/iam/member'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { MyIndicationModule } from '@/store/modules/trading/myIndication'
import { ProductModule } from '@/store/modules/trading/product'
import { setNotification } from '@/utils/utils'

const { mapActions: myIndicationMapActions } = createNamespacedHelpers(
  'myIndication',
) as MyIndicationModule

const { mapState: memberMapState } = createNamespacedHelpers(
  'member',
) as MemberModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const {
  mapGetters: userProfileMapGetters,
  mapState: userProfileMapState,
} = createNamespacedHelpers('userProfile') as UserProfileModule

export default defineComponent({
  name: 'MyIndicationDetail',
  components: {
    BaseButton,
    CenteredLoadingContent,
    MyIndicationForm,
    MyIndicationMetadata,
  },
  props: {
    myIndication: {
      type: Object as PropType<MyIndication>,
      required: true,
    },
  },
  emits: ['cancel'],
  data(): {
    formValue: ProductFormProps
    isEditing: boolean
    isSubmitting: boolean
    selectableInputs: BaseStandardIndicationSelectableInputs | undefined
    selectableInputsRequest: MyStandardIndicationSelectableInputsRequest
    openedSpreadFormTypes: SpreadTypeTypeEnum[]
    myIndicationDetailModalName: string
    currentAbortController: AbortController
    dateNames: ProductTypeDateName[]
    isInitialized: boolean
  } {
    return {
      formValue: {
        position: PositionType.Bid,
        deliveryUnit: DeliveryUnit.Month,
        productTypeIds: [],
        fuelSurchargeTypeId: undefined,
        base: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        exchange: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: [''],
        },
        deliveryPeriods: [],
        price: undefined,
        unitPrices: [],
        request: '',
      },
      isEditing: true,
      isSubmitting: false,
      selectableInputs: undefined,
      selectableInputsRequest: {},
      openedSpreadFormTypes: [],
      myIndicationDetailModalName: 'my-indication-detail',
      currentAbortController: new AbortController(),
      dateNames: [],
      isInitialized: false,
    }
  },
  computed: {
    ...userProfileMapState(['services']),
    ...userProfileMapGetters(['userProfile']),
    ...memberMapState(['memberProfiles']),
    ...productMapState(['productTypes']),
    mode(): IndicationFormInputMode {
      switch (this.myIndication.status) {
        case IndicationStatusType.Expired:
          return IndicationFormInputMode.EXPIRED
        case IndicationStatusType.Negotiating:
        case IndicationStatusType.Done:
          return IndicationFormInputMode.NEGOTIATION
        case IndicationStatusType.Unconfirmed:
        case IndicationStatusType.Active:
        case IndicationStatusType.Inactive:
        default:
          return IndicationFormInputMode.EDIT
      }
    },
    isInvalid(): boolean {
      return (
        this.mode !== IndicationFormInputMode.EDIT ||
        !this.selectableInputs?.isValid
      )
    },
  },
  watch: {
    isSubmitting() {
      if (!this.isSubmitting) {
        this.isEditing = true
      }
    },
  },
  async created() {
    this.selectableInputsRequest = {
      position: this.myIndication.position,
      indicationId: this.myIndication.indicationId,
    }
    await Promise.all([
      this.fetchProductTypeDateNames() as Promise<ProductTypeDateName[]>,
      this.fetchProductTypes(),
      this.fetchSelectableInputs(new AbortController()),
    ]).then(([dateNames]) => {
      this.dateNames = dateNames
      this.openedSpreadFormTypes =
        this.selectableInputs?.openedSpreadFormTypes || []
    })
  },
  methods: {
    fetchMyStandardIndicationSelectableInputs: myIndicationMapActions([
      'fetchMyStandardIndicationSelectableInputs',
    ]).fetchMyStandardIndicationSelectableInputs as (payload: {
      request: MyStandardIndicationSelectableInputsRequest
      abortController: AbortController
    }) => Promise<BaseStandardIndicationSelectableInputs>,
    ...myIndicationMapActions(['fetchMyIndications', 'updateMyIndication']),
    ...productMapActions(['fetchProductTypes', 'fetchProductTypeDateNames']),
    buildPayload(): UpdateMyIndication | undefined {
      if (!this.formValue || !this.selectableInputs) {
        return
      }
      return buildUpdateMyIndicationPayload({
        formValue: this.formValue,
        selectableInputs: this.selectableInputs,
        spreadTypes: this.openedSpreadFormTypes,
      })
    },
    debouncedFetchSelectableInputs: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.fetchSelectableInputs(new AbortController())
    }, 500),
    async fetchSelectableInputs(abortController: AbortController) {
      this.currentAbortController.abort()
      this.currentAbortController = abortController

      try {
        const selectableInputs = await this.fetchMyStandardIndicationSelectableInputs(
          {
            request: this.selectableInputsRequest,
            abortController: this.currentAbortController,
          },
        )

        this.selectableInputs = selectableInputs
        this.formValue = buildProductFormValue({
          formValue: this.formValue,
          selectableInputs,
          isInitialize: !this.isInitialized,
        })
        this.isInitialized = true
      } catch (e) {
        if (e.message === 'canceled') {
          return
        }
        throw e
      }
    },
    async onFormInput(formValue: ProductFormProps) {
      if (!this.selectableInputs) {
        return
      }

      // 入力内容が即座に変更されたかのようにフィードバックするため一時的に反映する
      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })

      await this.fetchSelectableInputs(new AbortController())
    },
    async onTextInput(formValue: ProductFormProps) {
      if (!this.selectableInputs) {
        return
      }

      Object.assign(this.formValue, formValue)

      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })

      await this.debouncedFetchSelectableInputs()
    },
    onConfirmClick() {
      this.isEditing = false
    },
    onCancelClick() {
      this.$emit('cancel')
    },
    async onCreateIndicationConfirmClick() {
      if (!this.myIndication?.indicationId) {
        return
      }
      const indication = this.buildPayload()
      if (!indication) {
        return
      }

      this.isSubmitting = true
      await this.updateMyIndication({
        id: this.myIndication.indicationId,
        indication,
      })
        .then(async () => {
          await this.fetchMyIndications()
          setNotification(
            this.$t(
              'trading.message.successSubmitUpdateMyIndication',
            ).toString(),
          )
          this.$vfm.close(this.myIndicationDetailModalName)
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failSubmitUpdateMyIndication').toString(),
            'is-danger',
          )
          throw e
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    onConfirmCancelClick() {
      this.isEditing = true
    },
    async onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = Array.from(
        new Set([...this.openedSpreadFormTypes, type]),
      )
      this.formValue.exchange.volumes[0] =
        this.formValue.exchange.volumes[0] || this.formValue.base.volumes[0]
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
    async onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      if (!this.selectableInputs) {
        return
      }
      this.openedSpreadFormTypes = this.openedSpreadFormTypes.filter(
        t => t !== type,
      )
      switch (type) {
        case SpreadTypeTypeEnum.Area:
          this.formValue.exchange.areaId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPattern:
          this.formValue.exchange.hourTypeId = undefined
          break
        case SpreadTypeTypeEnum.DeliveryPeriod:
          this.formValue.exchange.startDeliveryYearMonthId = undefined
          this.formValue.exchange.endDeliveryYearMonthId = undefined
          this.formValue.exchange.startDeliveryDate = undefined
          this.formValue.exchange.endDeliveryDate = undefined
          break
      }
      if (this.openedSpreadFormTypes.length === 0) {
        this.formValue.exchange.volumes = []
      }
      this.selectableInputsRequest = buildSelectableInputsRequest({
        formValue: this.formValue,
        openedSpreadFormTypes: this.openedSpreadFormTypes,
        selectableInputs: this.selectableInputs,
      })
      await this.fetchSelectableInputs(new AbortController())
    },
  },
})
