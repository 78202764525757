import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32458191"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization-headline" }
const _hoisted_2 = { class: "organization-headline__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_picture, {
      class: "organization-headline__picture",
      url: _ctx.pictureUrl,
      width: 48,
      clickable: false
    }, null, 8, ["url"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ]))
}