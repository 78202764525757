
import { defineComponent, PropType } from 'vue'

import BaseInputText from '@/components/common/BaseInputText.vue'
import { RecapReceiverFormProps } from '@/components/iam/interface/RecapReceiverFormProps'

export default defineComponent({
  name: 'RecapReceiverForm',
  components: {
    BaseInputText,
  },
  props: {
    formValue: {
      type: Object as PropType<RecapReceiverFormProps>,
      required: true,
    },
  },
  emits: ['form-input'],
  methods: {
    onInput(key: string, value: string) {
      this.$emit('form-input', { ...this.formValue, [key]: value })
    },
  },
})
