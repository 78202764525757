
import { defineComponent, PropType } from 'vue'

import BaseBox from '@/components/common/BaseBox.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { HeaderLevel } from '@/components/common/constants/TypographyLevel'
import DeliverySummary from '@/components/trading/DeliverySummary.vue'
import ProductsSummary from '@/components/trading/ProductsSummary.vue'
import { DeliverySummaries as DeliverySummariesModel } from '@/models/trading/DeliverySummaries'
import { DeliverySummary as DeliverySummaryModel } from '@/models/trading/DeliverySummary'
import { FuelSurchargeType } from '@/models/trading/FuelSurchargeType'
import { Product } from '@/models/trading/Product'

export default defineComponent({
  name: 'DeliverySummaries',
  components: {
    BaseBox,
    DeliverySummary,
    Heading,
    ProductsSummary,
  },
  props: {
    deliverySummaries: {
      type: Object as PropType<DeliverySummariesModel>,
      required: true,
    },
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    fuelSurchargeType: {
      type: Object as PropType<FuelSurchargeType>,
      default: undefined,
    },
    isSwingOption: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headerLevel: HeaderLevel.Lv4,
    }
  },
  computed: {
    baseDeliverySummary(): DeliverySummaryModel {
      return this.deliverySummaries.base.deliverySummary
    },
    exchangeDeliverySummary(): DeliverySummaryModel | undefined {
      return this.deliverySummaries.exchange?.deliverySummary
    },
    baseCalendarTypeName(): string {
      return this.deliverySummaries.base.calendarTypeName
    },
    exchangeCalendarTypeName(): string | undefined {
      return this.deliverySummaries.exchange?.calendarTypeName
    },
    hasExchange(): boolean {
      return !!this.exchangeDeliverySummary
    },
  },
})
