import {
  OrderBookFilterAttribute,
  OrderBookFilterCondition,
} from '@/store/modules/trading/orderBook/interface'

export class OrderBookState {
  filterAttributes: OrderBookFilterAttribute[] = [
    'areaIds',
    'startDeliveryYearMonths',
    'endDeliveryYearMonths',
    'hourTypeIds',
    'productTypeIds',
  ]

  filterConditionWithSpread: OrderBookFilterCondition = {
    areaIds: [],
    startDeliveryYearMonths: [],
    endDeliveryYearMonths: [],
    hourTypeIds: [],
    productTypeIds: [],
  }

  selectedFiltersWithSpread: OrderBookFilterAttribute[] = []
}
