import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03dd753e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-indication" }
const _hoisted_2 = { class: "selected-indication__title" }
const _hoisted_3 = { class: "selected-indication__organization-name" }
const _hoisted_4 = { class: "selected-indication__body" }
const _hoisted_5 = { class: "selected-indication__label" }
const _hoisted_6 = { class: "selected-indication__spread-arrow" }
const _hoisted_7 = { class: "selected-indication__label" }
const _hoisted_8 = {
  key: 1,
  class: "selected-indication__detail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_position_icon = _resolveComponent("position-icon")!
  const _component_selected_indication_content = _resolveComponent("selected-indication-content")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["selected-indication__container", {
        'selected-indication__container--selectable': _ctx.isSelectable,
      }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_position_icon, {
          position: _ctx.selectedIndication.position,
          "color-reversed": _ctx.colorReversed
        }, null, 8, ["position", "color-reversed"]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.selectedIndication.organizationName.translation()), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.selectedIndication.isSpread)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_base_box, {
                type: "fill",
                class: "selected-indication__detail"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('trading.label.baseSpreadIndication')), 1),
                  _createVNode(_component_selected_indication_content, {
                    "area-name": _ctx.areaName,
                    "delivery-period": _ctx.deliveryPeriod,
                    "hour-type-name": _ctx.hourTypeName,
                    "product-type-names": _ctx.productTypeNames,
                    "fuel-surcharge-type": _ctx.selectedIndication.fuelSurchargeType,
                    "price-type": _ctx.selectedIndication.price.type
                  }, null, 8, ["area-name", "delivery-period", "hour-type-name", "product-type-names", "fuel-surcharge-type", "price-type"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_base_icon, { "icon-name": "arrows-h" })
              ]),
              _createVNode(_component_base_box, {
                type: "fill",
                class: "selected-indication__detail"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('trading.label.exchangeSpreadIndication')), 1),
                  _createVNode(_component_selected_indication_content, {
                    "area-name": _ctx.exchangeAreaName,
                    "delivery-period": _ctx.exchangeDeliveryPeriod,
                    "hour-type-name": _ctx.exchangeHourTypeName,
                    "product-type-names": _ctx.productTypeNames,
                    "fuel-surcharge-type": _ctx.selectedIndication.fuelSurchargeType,
                    "price-type": _ctx.selectedIndication.price.type
                  }, null, 8, ["area-name", "delivery-period", "hour-type-name", "product-type-names", "fuel-surcharge-type", "price-type"])
                ]),
                _: 1
              })
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_selected_indication_content, {
                "area-name": _ctx.areaName,
                "delivery-period": _ctx.deliveryPeriod,
                "hour-type-name": _ctx.hourTypeName,
                "product-type-names": _ctx.productTypeNames,
                "fuel-surcharge-type": _ctx.selectedIndication.fuelSurchargeType,
                "price-type": _ctx.selectedIndication.price.type
              }, null, 8, ["area-name", "delivery-period", "hour-type-name", "product-type-names", "fuel-surcharge-type", "price-type"])
            ]))
      ])
    ], 2)
  ]))
}