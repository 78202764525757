
import { defineComponent } from 'vue'

import BrokerPage from '@/components/common/BrokerPage.vue'
import BrokerIndicationNew from '@/components/trading/container/BrokerIndicationNew.vue'
export default defineComponent({
  name: 'IndicationNew',
  components: {
    BrokerIndicationNew,
    BrokerPage,
  },
  methods: {
    async onConfirm() {
      await this.backListPage()
    },
    async onChancel() {
      await this.backListPage()
    },
    async backListPage() {
      await this.$router.push(`/indications`)
    },
  },
})
