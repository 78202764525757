import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_broker_collapsed_navigation = _resolveComponent("broker-collapsed-navigation")!
  const _component_trader_collapsed_navigation = _resolveComponent("trader-collapsed-navigation")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.type === 'broker')
      ? (_openBlock(), _createBlock(_component_broker_collapsed_navigation, {
          key: 0,
          "is-collapsed": _ctx.isCollapsed,
          "current-menu": _ctx.currentMenu,
          "organization-name": _ctx.organizationName,
          "organization-picture": _ctx.organizationPicture,
          toggle: _ctx.toggle
        }, null, 8, ["is-collapsed", "current-menu", "organization-name", "organization-picture", "toggle"]))
      : (_openBlock(), _createBlock(_component_trader_collapsed_navigation, {
          key: 1,
          "is-collapsed": _ctx.isCollapsed,
          "current-menu": _ctx.currentMenu,
          "organization-name": _ctx.organizationName,
          "organization-picture": _ctx.organizationPicture,
          toggle: _ctx.toggle
        }, null, 8, ["is-collapsed", "current-menu", "organization-name", "organization-picture", "toggle"]))
  ]))
}