
import { defineComponent } from 'vue'

import Sentences from '@/components/common/Typography/Sentences.vue'

export default defineComponent({
  name: 'HintComment',
  components: {
    Sentences,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
})
