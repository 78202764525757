
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BasePicture',
  props: {
    url: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '50%',
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      if (this.clickable) {
        this.$emit('click')
      }
    },
  },
})
