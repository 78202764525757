import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01b1fc3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "base-button__inner" }
const _hoisted_3 = {
  key: 0,
  class: "base-button__loading-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_inner_label = _resolveComponent("inner-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.outerButtonClass)
  }, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["base-button__content", _ctx.buttonClass]),
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
      onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_component_base_icon, { "icon-name": "spinner" })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_inner_label, {
          type: "button",
          class: _normalizeClass(_ctx.buttonLabelClass)
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["class"])
      ])
    ], 42, _hoisted_1)
  ], 2))
}