import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-872df960"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recipient-list" }
const _hoisted_2 = { class: "recipient-list__wrapper" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "recipient-list__item" }
const _hoisted_5 = { class: "recipient-list__value" }
const _hoisted_6 = { class: "recipient-list__locale" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_toggle_button = _resolveComponent("status-toggle-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("label", {
          key: index,
          class: "recipient-list__checkbox checkbox"
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values) = $event)),
            type: "checkbox",
            class: "recipient-list__input checkbox",
            value: option.value,
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }, null, 40, _hoisted_3), [
            [_vModelCheckbox, _ctx.values]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(option.locale === 'ja'
              ? _ctx.$t('common.label.japanese')
              : _ctx.$t('common.label.english')), 1)
          ])
        ]))
      }), 128))
    ]),
    (_ctx.togglable)
      ? (_openBlock(), _createBlock(_component_status_toggle_button, {
          key: 0,
          "all-inactive": _ctx.allInactive,
          "activate-all-label": 
        _ctx.activeAllLabel ? _ctx.activeAllLabel : _ctx.$t('common.label.activateAll')
      ,
          "deactivate-all-label": 
        _ctx.deActiveAllLabel ? _ctx.deActiveAllLabel : _ctx.$t('common.label.deactivateAll')
      ,
          onActivateAll: _ctx.onActivateAll,
          onDeactivateAll: _ctx.onDeactivateAll
        }, null, 8, ["all-inactive", "activate-all-label", "deactivate-all-label", "onActivateAll", "onDeactivateAll"]))
      : _createCommentVNode("", true)
  ]))
}