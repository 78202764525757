import { OrganizationEmailAddress as IOrganizationEmailAddress } from '@/api/generated'

export class OrganizationEmailAddress implements IOrganizationEmailAddress {
  id!: string
  organizationId!: string
  name!: string
  emailAddress!: string
  receiveIndicationEmail = false
  receiveLiveMarketEmail = false
  receiveInvoiceEmail = false
  receiveSpotTradingRecapEmail = false
  receiveDerivativeTradingRecapEmail = false
  receiveReportEmail = false
  createdAt!: string
  updatedAt!: string
  createdUserName?: string
  lastUpdatedUserName?: string
}
