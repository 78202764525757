
import { defineComponent, PropType } from 'vue'

import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import BrokingIndicationStatus from '@/components/trading/BrokingIndicationStatus.vue'
import OrderPrice from '@/components/trading/OrderPrice.vue'
import ProductType from '@/components/trading/ProductType.vue'
import { BrokingOrder } from '@/models/trading/BrokingOrder'

export default defineComponent({
  name: 'BrokingOrderItem',
  components: {
    ActionDropdown,
    BaseTooltip,
    BrokingIndicationStatus,
    OrderPrice,
    ProductType,
  },
  props: {
    order: {
      type: Object as PropType<BrokingOrder>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['record-select', 'copy-indication'],
  data(): { actions: ActionDropdownItem[]; isActionDropdownActive: boolean } {
    return {
      actions: [
        {
          label: this.$t('trading.message.openDetail').toString(),
          eventName: 'to-detail',
        },
        {
          label: this.$t('trading.message.copyIndication').toString(),
          eventName: 'copy-indication',
        },
      ],
      isActionDropdownActive: false,
    }
  },
  computed: {
    tooltipLabel(): string {
      const updatedAtLabel = this.$t('trading.label.updatedAt').toString()
      const updatedAtValue = `${this.order.updatedAt} ${this.order.lastUpdatedParticipantName}`
      const createdAtLabel = this.$t('trading.label.createdAt').toString()
      const createdAtValue = `${this.order.createdAt} ${this.order.createdParticipantName}`
      return `${updatedAtLabel}: ${updatedAtValue}\n${createdAtLabel}: ${createdAtValue}`
    },
  },
  methods: {
    onOpenDetailClick(id: string) {
      const url = this.$router.resolve(`/indications/${id}`).href
      window.open(url, '_blank')
    },
    onRecordSelect() {
      this.$emit('record-select', { indicationId: this.order.indicationId })
    },
    onCopyIndication(indicationId: string) {
      this.$emit('copy-indication', indicationId)
      this.isActionDropdownActive = false
    },
    displayOrganizationName(order: BrokingOrder): string {
      if (order.organizationAbbreviation !== '') {
        return order.organizationAbbreviation as string
      }
      return order.organizationName || ''
    },
  },
})
