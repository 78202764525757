
import { defineComponent } from 'vue'

import BaseInputText from '@/components/common/BaseInputText.vue'
import { InputMode } from '@/components/common/constants/InputMode'

export default defineComponent({
  name: 'FieldVolume',
  components: {
    BaseInputText,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    isShownLabel: {
      type: Boolean,
      default: true,
    },
    isShortLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    forceShownErrorMessage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'edit'],
  computed: {
    label(): string | undefined {
      if (!this.isShownLabel) {
        return undefined
      }
      const label = this.isShortLabel
        ? this.$t('trading.label.volumeShort').toString()
        : this.$t('trading.label.volume').toString()
      return this.required ? `${label}*` : label
    },
    rules(): string {
      const rules = 'scale:4|between:0.001,9999.999'
      return this.required ? `${rules}|required` : rules
    },
  },
  methods: {
    onInput(event: Event) {
      this.$emit('input', event)
    },
    onEdit() {
      this.$emit('edit')
    },
  },
})
