
import { defineComponent, PropType } from 'vue'

import { EEXPostingStatusType } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseLink from '@/components/common/BaseLink.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import DeliveryTerms from '@/components/trading/DeliveryTerms.vue'
import DeliveryUnitIcon from '@/components/trading/DeliveryUnitIcon.vue'
import ProductType from '@/components/trading/ProductType.vue'
import SpreadBadgeList from '@/components/trading/SpreadBadgeList.vue'
import StatusIcon from '@/components/trading/StatusIcon.vue'
import { EEXPosting } from '@/models/trading/EEXPosting'

export default defineComponent({
  name: 'EEXPostingListItem',
  components: {
    ActionDropdown,
    BaseBox,
    BaseLink,
    DeliveryTerms,
    DeliveryUnitIcon,
    ProductType,
    SpreadBadgeList,
    StatusIcon,
  },
  props: {
    eexPosting: {
      type: Object as PropType<EEXPosting>,
      required: true,
    },
    isActionDropdownActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['on-repost-click', 'on-dropdown-click', 'on-dropdown-outside-click'],
  data(): { actions: ActionDropdownItem[] } {
    return {
      actions: [
        {
          label: this.$t('trading.message.repost').toString(),
          eventName: 'repost',
        },
      ],
    }
  },
  computed: {
    isRepostable(): boolean {
      return this.eexPosting.status === EEXPostingStatusType.Rejected
    },
  },
  methods: {
    onRepostClick(id: string) {
      this.$emit('on-repost-click', id)
    },
    onDropdownClick(id: string) {
      this.$emit('on-dropdown-click', id)
    },
    onDropdownOutsideClick() {
      this.$emit('on-dropdown-outside-click')
    },
  },
})
