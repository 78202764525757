
import { ErrorMessage, Field } from 'vee-validate'
import { defineComponent, PropType } from 'vue'
import Multiselect from 'vue-multiselect'

import BaseLabel from '@/components/common/BaseLabel.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'

export default defineComponent({
  name: 'BaseSingleSelect',
  components: {
    BaseLabel,
    ErrorMessage,
    Field,
    Multiselect,
  },
  props: {
    value: {
      type: Object as PropType<InputOption>,
      default: undefined,
    },
    options: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    optionsLabel: {
      type: String,
      default: 'label',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    help: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: undefined,
    },
  },
  emits: ['input', 'edit'],
  data(): {
    touched: boolean
  } {
    return {
      touched: false,
    }
  },
  computed: {
    valueLabel(): string {
      if (this.value) {
        return this.value.label
      }
      return this.$t('common.label.none').toString()
    },
    inputable(): boolean {
      return this.mode === InputMode.INPUT
    },
    editable(): boolean {
      return this.mode === InputMode.EDIT
    },
    disabled(): boolean {
      return this.options.length === 0
    },
    labelString() {
      return this.label === undefined
        ? undefined
        : this.label + (this.required ? '*' : '')
    },
  },
  methods: {
    onInput(event: InputOption) {
      this.touched = true
      this.$emit('input', event)
    },
    onClick() {
      this.$emit('edit')
    },
  },
})
