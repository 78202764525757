export class OcctoMasterItem {
  id!: string
  organizationId!: string
  areaId!: string
  name!: string
  code!: string
  companyPrefix!: string
  createdParticipantName?: string
  createdAt!: string
  lastUpdatedParticipantName?: string
  updatedAt!: string
}
