
import debounce from 'lodash/debounce'
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BasePicture from '@/components/common/BasePicture.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LoadingType } from '@/components/common/constants/LoadingType'
import { UiStack } from '@/components/common/constants/UiStack'
import { EexTrader } from '@/models/trading/EexTrader'
import { EEXPostingModule } from '@/store/modules/trading/eexPosting'
import { isAbortError } from '@/utils/error/AbortError'

const { mapActions: eexPostingActions } = createNamespacedHelpers(
  'eexPosting',
) as EEXPostingModule

export default defineComponent({
  name: 'TraderSelectorModal',
  components: {
    BaseAlert,
    BaseBox,
    BaseButton,
    BaseInputText,
    BasePicture,
    CenteredLoadingContent,
    UiStackSelector,
  },
  props: {
    organizationId: {
      type: String,
      default: undefined,
    },
    selectedTrader: {
      type: Object as PropType<EexTrader>,
      default: undefined,
    },
  },
  emits: ['select', 'cancel'],
  data(): {
    value: string
    traders: EexTrader[]
    uiStack: UiStack
    currentAbortController: AbortController
  } {
    return {
      value: '',
      traders: [],
      uiStack: UiStack.Loading,
      currentAbortController: new AbortController(),
    }
  },
  computed: {
    alertTypeInfo: () => AlertType.Info,
    alertTypeError: () => AlertType.Error,
    grayscale: () => LoadingType.GRAYSCALE,
  },
  async created() {
    await this.search()
  },
  methods: {
    searchTraders: eexPostingActions(['searchEEXTraders'])
      .searchEEXTraders as (payload: {
      organizationId: string
      eexTraderName: string
      abortController?: AbortController
    }) => Promise<EexTrader[]>,
    onSelect(trader: EexTrader) {
      this.$emit('select', trader)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onInput(value: string) {
      this.value = value
      this.uiStack = UiStack.Loading
      this.debouncedSearch()
    },
    async search() {
      if (this.organizationId === undefined) {
        this.uiStack = UiStack.Blank
        this.traders = []
        return
      }
      this.uiStack = UiStack.Loading
      this.currentAbortController.abort()
      this.currentAbortController = new AbortController()
      await this.searchTraders({
        organizationId: this.organizationId,
        eexTraderName: this.value,
        abortController: this.currentAbortController,
      })
        .then(traders => {
          this.traders = traders
          this.uiStack = traders.length > 0 ? UiStack.Ideal : UiStack.Blank
        })
        .catch(e => {
          if (isAbortError(e)) {
            return
          }
          this.uiStack = UiStack.Error
        })
    },
    debouncedSearch: debounce(async function(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this: any,
    ) {
      await this.search()
    }, 750),
  },
})
