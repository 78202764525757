
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import PublicOrderBook from '@/components/trading/PublicOrderBook.vue'
import PublicOrderBookListHeader from '@/components/trading/PublicOrderBookListHeader.vue'
import { MyIndicationNewProps } from '@/components/trading/interface/MyIndicationNewProps'
import { PublicOrderBookProps } from '@/components/trading/interface/PublicOrderBookProps'
import {
  generateId,
  generateMapsByDeliveryTerms,
} from '@/components/trading/services/generateMaps'
import { productSorter } from '@/components/trading/services/productSorter'
import { PublicDeal } from '@/models/trading/PublicDeal'
import { PublicIndication } from '@/models/trading/PublicIndication'

export default defineComponent({
  name: 'PublicOrderBookList',
  components: {
    PublicOrderBook,
    PublicOrderBookListHeader,
  },
  props: {
    indications: {
      type: Array as PropType<PublicIndication[]>,
      default: () => [],
    },
    deals: {
      type: Array as PropType<PublicDeal[]>,
      default: () => [],
    },
    productTypeFilterCondition: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    withFuelSurcharge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['button-click'],
  data() {
    return {
      expandedDeliveryTermsIds: [] as string[],
    }
  },
  computed: {
    items(): PublicOrderBookProps[] {
      const { deliveryTermsMap, indicationMap } = generateMapsByDeliveryTerms({
        indications: this.indications,
        deals: this.deals,
        productTypeFilterCondition: this.productTypeFilterCondition,
      })

      const publicOrderBookItems: PublicOrderBookProps[] = []
      for (const [
        deliveryTermsIdAndFuelSurchargeId,
        deliveryTerms,
      ] of deliveryTermsMap.entries()) {
        let deal
        for (const d of this.deals) {
          if (
            generateId(d.product.deliveryTermsId, d.fuelSurchargeType?.id) ===
              deliveryTermsIdAndFuelSurchargeId &&
            (!deal || deal.createdAt < d.createdAt)
          ) {
            deal = d
          }
        }
        publicOrderBookItems.push({
          deliveryTerms: deliveryTerms.deliveryTerms,
          deliveryUnit: deliveryTerms.deliveryUnit,
          indications: indicationMap.get(deliveryTermsIdAndFuelSurchargeId),
          deal,
          isExpanded: this.expandedDeliveryTermsIds.includes(
            deliveryTermsIdAndFuelSurchargeId,
          ),
          locale: this.locale,
          colorReversed: this.colorReversed,
          positionReversed: this.positionReversed,
        })
      }
      publicOrderBookItems.sort(productSorter)
      return publicOrderBookItems
    },
    allCollapsed(): boolean {
      return this.expandedDeliveryTermsIds.length === 0
    },
  },
  methods: {
    onExpand(event: { id: string }) {
      const ids = this.expandedDeliveryTermsIds
      if (ids.includes(event.id)) {
        ids.splice(
          ids.findIndex(id => id === event.id),
          1,
        )
      } else {
        ids.push(event.id)
      }
    },
    onActivateAll() {
      this.expandedDeliveryTermsIds = this.items
        .map(item => {
          const product = item.indications
            ? item.indications[0].product
            : item.deal?.product
          return generateId(
            product?.deliveryTermsId || '',
            (item.indications || [])[0]?.fuelSurchargeType?.id ||
              item.deal?.fuelSurchargeType?.id,
          )
        })
        .filter((id): id is string => id !== undefined)
    },
    onDeactivateAll() {
      this.expandedDeliveryTermsIds = []
    },
    onButtonClick(newIndicationModalProps: MyIndicationNewProps) {
      this.$emit('button-click', newIndicationModalProps)
    },
  },
})
