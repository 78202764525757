
import moment from 'moment'
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseDatePicker from '@/components/common/BaseDatepicker.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'

export default defineComponent({
  name: 'FilterDateSelect',
  components: { BaseDatePicker, BaseIcon },
  props: {
    filterAttribute: {
      type: String,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  emits: ['select', 'filter-remove'],
  computed: {
    placeholder(): string {
      return this.filterAttribute
        ? this.$t('common.message.selectPlaceholder', {
            name: this.$t('trading.label.' + this.filterAttribute),
          }).toString()
        : ''
    },
    valueOfDate(): Date | undefined {
      return this.value ? new Date(this.value) : undefined
    },
  },
  methods: {
    onSelect(value: Date | undefined) {
      this.$emit(
        'select',
        value ? moment(value).format('YYYY-MM-DD') : undefined,
      )
    },
    onClick() {
      this.$emit('filter-remove')
    },
  },
})
