import {
  Client,
  Conversation,
  UserUpdateReason,
  Participant,
  Message as TwilioMessage,
  User as TwilioUser,
} from '@twilio/conversations'
import cloneDeep from 'lodash/cloneDeep'
import { Actions } from 'vuex-smart-module'

import { apiConfig } from '@/api'
import {
  UserProfile,
  BroadcastIndicesApi,
  ScanMessageIndices,
  StarredChannelsApi,
  ChatAccessTokenApi,
  ScanMessageIndicesIndicesInner,
} from '@/api/generated'
import { ActiveChannel } from '@/models/chat/ActiveChannel'
import { ActiveChannelWindow } from '@/models/chat/ActiveChannelWindow'
import { Channel } from '@/models/chat/Channel'
import { ChannelList } from '@/models/chat/ChannelList'
import { DMChannel } from '@/models/chat/DMChannel'
import { OrganizationChannel } from '@/models/chat/OrganizationChannel'
import { ChatGetters } from '@/store/modules/chat/chat/ChatGetters'
import {
  ChatMutations,
  ADD_ACTIVE_CHANNEL_MESSAGES,
  ADD_ACTIVE_CHANNEL_WINDOW,
  ADD_ACTIVE_CHANNEL_WINDOW_MESSAGES,
  REMOVE_ACTIVE_CHANNEL_WINDOW,
  SET_ACTIVE_CHANNEL,
  SET_ACTIVE_CHANNEL_ANCHOR,
  SET_ACTIVE_CHANNEL_WINDOW_ANCHOR,
  SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE,
  SET_ACTIVE_CHANNEL_WINDOW_IS_OPENED,
  SET_ACTIVE_CHANNEL_WINDOW_TYPING_USER_IDS,
  SET_CHANNEL_LIST,
  SET_CHANNEL_LIST_WINDOW_IS_OPENED,
  SET_CHAT_INPUT_VALUE,
  SET_DM_CHANNEL_USER_INSTANCE,
  SET_TYPING_USER_IDS,
  SET_MESSAGE_LOADING,
  SET_ACTIVE_CHANNEL_WINDOW_MESSAGE_LOADING,
  SET_BROADCAST_INDEX,
  SET_STARRED_CHANNEL_IDS,
  INCREMENT_CHANNEL_COUNTER,
} from '@/store/modules/chat/chat/ChatMutations'
import { ChatState } from '@/store/modules/chat/chat/ChatState'
import { getChannel, getChannelWithCache } from '@/store/modules/chat/utils'
import { isBrokerApp } from '@/utils/env'

export const hasUnreadMessage = async (
  conversationDescriptor: Conversation,
): Promise<boolean> => {
  return (
    (await conversationDescriptor.getMessagesCount()) >
    (conversationDescriptor.lastReadMessageIndex ?? 0) + 1
  )
}

export class ChatActions extends Actions<
  ChatState,
  ChatGetters,
  ChatMutations,
  ChatActions
> {
  async getAccessToken() {
    return (await new ChatAccessTokenApi(apiConfig).getAccessToken()).data
      .accessToken
  }

  async loadChannelList(payload: {
    client: Client
    user: UserProfile
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vm: any
  }) {
    payload.vm.refreshTokenIfExpired()

    let conversationDescriptors = await payload.client.getSubscribedConversations()

    const conversationDescriptorItems: Conversation[] = []
    const organizationChannels: OrganizationChannel[] = []
    const dMChannels: DMChannel[] = []

    while (true) {
      conversationDescriptorItems.push(...conversationDescriptors.items)
      if (!conversationDescriptors.hasNextPage) {
        break
      }
      conversationDescriptors = await conversationDescriptors.nextPage()
    }

    const channelPromise = conversationDescriptorItems.map(
      conversationDescriptor => {
        return getChannelWithCache(
          conversationDescriptor.sid,
          payload.client,
          payload.user,
        ).catch(error => console.log(error))
      },
    )
    const channels = await Promise.all(channelPromise)

    for (const channel of channels) {
      if (channel) {
        channel.on(
          'updated',
          (event: { conversation: Conversation; updateReasons: string[] }) => {
            this.commit(INCREMENT_CHANNEL_COUNTER, {
              channelId: event.conversation.sid,
            })
          },
        )
        if (channel.channelType === 'Organization') {
          channel.on('messageAdded', (event: TwilioMessage) => {
            // 調査のためコメントアウトする
            // channel.forceReRender()
            const isBroadcast = (event.attributes as { broadcast: boolean })
              .broadcast
            if (isBrokerApp && isBroadcast) {
              this.dispatch('appendBroadcastIndex', {
                channel,
                index: event.index,
              })
            }
          })
          organizationChannels.push(channel)
        } else {
          // 調査のためコメントアウトする
          // channel.on('messageAdded', () => {
          //  channel.forceReRender()
          // })
          const typedChannel = channel as DMChannel
          typedChannel.userInstance.on(
            'updated',
            (event: {
              user: TwilioUser
              updateReasons: UserUpdateReason[]
            }) => {
              this.dispatch('updateUserInstanceOfDMChannel', event)
            },
          )
          dMChannels.push(typedChannel)
        }
      }
    }

    const channelList = new ChannelList({ organizationChannels, dMChannels })
    if (isBrokerApp) {
      await this.dispatch('loadUnreadBroadcastMessagesCount', {
        channelList,
      })
    }

    await this.dispatch('loadStarredChannelIds')

    this.commit(SET_CHANNEL_LIST, { channelList })
  }

  appendBroadcastIndex(payload: { channel: Channel; index: number }) {
    const channelId = payload.channel.channelId
    this.commit(SET_BROADCAST_INDEX, { channelId, index: payload.index })
  }

  async loadChannel(payload: {
    client: Client
    channelId: string
    user: UserProfile
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vm: any
  }) {
    payload.vm.refreshTokenIfExpired()

    const channel = await getChannel(
      payload.channelId,
      payload.client,
      payload.user,
    )

    if (channel) {
      channel.on('messageAdded', async (event: TwilioMessage) => {
        await this.dispatch('loadMessages', { vm: payload.vm })
        const isBroadcast = (event.attributes as { broadcast: boolean })
          .broadcast
        if (isBrokerApp && isBroadcast) {
          channel.appendBroadcastIndex(event.index)
        }
      })
      channel.on('typingStarted', (event: Participant) => {
        this.dispatch('addTypingUser', { userId: event.identity })
      })
      channel.on('typingEnded', (event: Participant) => {
        this.dispatch('removeTypingUser', { userId: event.identity })
      })

      const members = await channel.getMembers()
      const activeChannel = new ActiveChannel({ channel, members })
      this.commit(SET_ACTIVE_CHANNEL, { activeChannel })
    }
  }

  async addChannelWindow(payload: {
    client: Client
    channelId: string
    user: UserProfile
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vm: any
  }) {
    payload.vm.refreshTokenIfExpired()

    const channel = await getChannel(
      payload.channelId,
      payload.client,
      payload.user,
    )

    if (channel) {
      channel.on('messageAdded', async (event: TwilioMessage) => {
        await this.dispatch('loadChannelWindowMessages', {
          channelId: payload.channelId,
          vm: payload.vm,
        })
        const isBroadcast = (event.attributes as { broadcast: boolean })
          .broadcast
        if (isBrokerApp && isBroadcast) {
          channel.appendBroadcastIndex(event.index)
        }
      })
      channel.on('typingStarted', (event: Participant) => {
        this.dispatch('addChannelWindowTypingUser', {
          channelId: payload.channelId,
          userId: event.identity,
        })
      })
      channel.on('typingEnded', (event: Participant) => {
        this.dispatch('removeChannelWindowTypingUser', {
          channelId: payload.channelId,
          userId: event.identity,
        })
      })

      const members = await channel.getMembers()
      const activeChannel = new ActiveChannel({ channel, members })
      const activeChannelWindow = new ActiveChannelWindow({
        activeChannel,
        isOpened: true,
      })
      this.commit(ADD_ACTIVE_CHANNEL_WINDOW, { activeChannelWindow })
    }
  }

  async removeChannelWindow(payload: { channelId: string }) {
    this.commit(REMOVE_ACTIVE_CHANNEL_WINDOW, { channelId: payload.channelId })
  }

  async loadMessages({
    vm,
    fromAnchor = false,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vm: any
    fromAnchor?: boolean
  }) {
    if (!this.state.activeChannel) {
      return
    }

    vm.refreshTokenIfExpired()

    const messages = fromAnchor
      ? await this.state.activeChannel.getMessagesFromAnchor()
      : await this.state.activeChannel.getMessagesFromLatest()
    if (messages.length === 0) {
      return
    }

    this.commit(ADD_ACTIVE_CHANNEL_MESSAGES, { messages })
    this.commit(SET_ACTIVE_CHANNEL_ANCHOR, {
      anchor: Math.min(
        ...[...(this.state.activeChannel.messages || []), ...messages].map(
          message => message.index,
        ),
      ),
    })
  }

  async loadChannelWindowMessages({
    channelId,
    fromAnchor = false,
    vm,
  }: {
    channelId: string
    fromAnchor?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vm: any
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      channelId,
    )
    if (!activeChannel) {
      return
    }

    vm.refreshTokenIfExpired()

    const messages = fromAnchor
      ? await activeChannel.getMessagesFromAnchor()
      : await activeChannel.getMessagesFromLatest()
    if (messages.length === 0) {
      return
    }

    this.commit(ADD_ACTIVE_CHANNEL_WINDOW_MESSAGES, {
      channelId,
      messages,
    })
    this.commit(SET_ACTIVE_CHANNEL_WINDOW_ANCHOR, {
      channelId,
      anchor: Math.min(
        ...[...(activeChannel.messages || []), ...messages].map(
          message => message.index,
        ),
      ),
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async sendMessage(payload: { vm: any }) {
    if (this.state.activeChannel && this.state.activeChannel.chatInputValue) {
      payload.vm.refreshTokenIfExpired()
      await this.state.activeChannel.sendMessage(
        this.state.activeChannel.chatInputValue,
      )
      this.commit(SET_CHAT_INPUT_VALUE, { chatInputValue: '' })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async sendChannelWindowMessage(payload: { channelId: string; vm: any }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel && activeChannel.chatInputValue) {
      payload.vm.refreshTokenIfExpired()
      await activeChannel.sendMessage(activeChannel.chatInputValue)
      this.commit(SET_ACTIVE_CHANNEL_WINDOW_CHAT_INPUT_VALUE, {
        channelId: payload.channelId,
        chatInputValue: '',
      })
    }
  }

  startMessageLoading() {
    this.commit(SET_MESSAGE_LOADING, { messageLoading: true })
  }

  endMessageLoading() {
    this.commit(SET_MESSAGE_LOADING, { messageLoading: false })
  }

  startChannelWindowMessageLoading(payload: { channelId: string }) {
    this.commit(SET_ACTIVE_CHANNEL_WINDOW_MESSAGE_LOADING, {
      channelId: payload.channelId,
      messageLoading: true,
    })
  }

  endChannelWindowMessageLoading(payload: { channelId: string }) {
    this.commit(SET_ACTIVE_CHANNEL_WINDOW_MESSAGE_LOADING, {
      channelId: payload.channelId,
      messageLoading: false,
    })
  }

  toggleChannelWindow(payload: { channelId: string }) {
    const activeChannelWindow = this.state.activeChannelWindowList.activeChannelWindowOfChannelId(
      payload.channelId,
    )
    if (activeChannelWindow) {
      this.commit(SET_ACTIVE_CHANNEL_WINDOW_IS_OPENED, {
        channelId: payload.channelId,
        isOpened: !activeChannelWindow.isOpened,
      })
    }
  }

  toggleChannelListWindow() {
    this.commit(SET_CHANNEL_LIST_WINDOW_IS_OPENED, {
      isOpened: !this.state.channelListWindowIsOpened,
    })
  }

  updateUserInstanceOfDMChannel(payload: {
    user: TwilioUser
    updateReasons: UserUpdateReason[]
  }) {
    if (payload.updateReasons.includes('reachabilityOnline')) {
      const userInstance = cloneDeep(payload.user)
      userInstance.on(
        'updated',
        (event: { user: TwilioUser; updateReasons: UserUpdateReason[] }) => {
          this.dispatch('updateUserInstanceOfDMChannel', event)
        },
      )
      this.commit(SET_DM_CHANNEL_USER_INSTANCE, {
        userInstance,
      })
    }
  }

  addTypingUser(payload: { userId: string }) {
    if (this.state.activeChannel) {
      this.commit(SET_TYPING_USER_IDS, {
        typingUserIds: [
          ...new Set([
            ...this.state.activeChannel.typingUserIds,
            payload.userId,
          ]),
        ],
      })
    }
  }

  removeTypingUser(payload: { userId: string }) {
    if (this.state.activeChannel) {
      this.commit(SET_TYPING_USER_IDS, {
        typingUserIds: this.state.activeChannel.typingUserIds.filter(
          existingUserId => existingUserId !== payload.userId,
        ),
      })
    }
  }

  addChannelWindowTypingUser(payload: { channelId: string; userId: string }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      this.commit(SET_ACTIVE_CHANNEL_WINDOW_TYPING_USER_IDS, {
        channelId: payload.channelId,
        typingUserIds: [
          ...new Set([...activeChannel.typingUserIds, payload.userId]),
        ],
      })
    }
  }

  removeChannelWindowTypingUser(payload: {
    channelId: string
    userId: string
  }) {
    const activeChannel = this.state.activeChannelWindowList.activeChannelOfChannelId(
      payload.channelId,
    )
    if (activeChannel) {
      this.commit(SET_ACTIVE_CHANNEL_WINDOW_TYPING_USER_IDS, {
        channelId: payload.channelId,
        typingUserIds: activeChannel.typingUserIds.filter(
          existingUserId => existingUserId !== payload.userId,
        ),
      })
    }
  }

  async loadUnreadBroadcastMessagesCount(payload: {
    channelList: ChannelList
  }) {
    const organizationChannels = payload.channelList.organizationChannels
    const scanMessageIndices: ScanMessageIndices = {
      indices: organizationChannels.map(
        (channel): ScanMessageIndicesIndicesInner => {
          return {
            channelSid: channel.channelId,
            lastConsumedIndex:
              channel.conversationInstance.lastReadMessageIndex,
          }
        },
      ),
    }
    const { unreadBroadcastMessagesCount } = (
      await new BroadcastIndicesApi(apiConfig).getUnreadBroadcastMessagesCount(
        scanMessageIndices,
      )
    ).data

    organizationChannels.forEach(channel => {
      const count = unreadBroadcastMessagesCount[channel.channelId]
      if (count !== undefined) {
        channel.setUnstoredUnreadBroadcastCount(count)
      }
    })
  }

  async loadStarredChannelIds() {
    const starredChannelIds = (
      await new StarredChannelsApi(apiConfig).getStarredChannels()
    ).data
    this.commit(SET_STARRED_CHANNEL_IDS, { starredChannelIds })
  }

  async addStarredChannel(payload: { channelId: string }) {
    await new StarredChannelsApi(apiConfig).newStarredChannel({
      channelId: payload.channelId,
    })
    await this.dispatch('loadStarredChannelIds')
  }

  async removeStarredChannel(payload: { channelId: string }) {
    await new StarredChannelsApi(apiConfig).removeStarredChannel(
      payload.channelId,
    )
    await this.dispatch('loadStarredChannelIds')
  }
}
