import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12c6df46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "esquare-organization" }
const _hoisted_2 = { class: "esquare-organization__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_organization_headline = _resolveComponent("organization-headline")!
  const _component_horizontal_tab = _resolveComponent("horizontal-tab")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.$t(_ctx.titleKey),
    "active-menu-item": _ctx.activeMenuItem
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
          loading: _withCtx(() => [
            _createVNode(_component_centered_loading_content, { type: "grayscale" })
          ]),
          error: _withCtx(() => [
            _createVNode(_component_base_alert, { type: _ctx.errorType }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeOrganization')), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          ideal: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.isMyOrganizationPage)
                ? (_openBlock(), _createBlock(_component_organization_headline, {
                    key: 0,
                    "organization-name": _ctx.organizationProfile.name,
                    "picture-url": _ctx.organizationProfile.picture
                  }, null, 8, ["organization-name", "picture-url"]))
                : _createCommentVNode("", true),
              (!_ctx.isMyOrganizationPage)
                ? (_openBlock(), _createBlock(_component_horizontal_tab, {
                    key: 1,
                    "tab-list": _ctx.tabList,
                    "current-tab-index": _ctx.currentTabIndex
                  }, null, 8, ["tab-list", "current-tab-index"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view)
            ])
          ]),
          _: 1
        }, 8, ["ui-stack"])
      ])
    ]),
    _: 1
  }, 8, ["title", "active-menu-item"]))
}