
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale, SpreadTypeTypeEnum } from '@/api/generated'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import DeliveryDateForm from '@/components/trading/DeliveryDateForm.vue'
import { DeliveryDateFormProps } from '@/components/trading/interface/DeliveryDateFormProps'
import { StandardDeliveryTermsFormProps } from '@/components/trading/interface/StandardDeliveryTermsFormProps'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { ProductType } from '@/models/trading/ProductType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { isOneWeek } from '@/utils/date'

// 交換側の数量選択をスプレッド化するためのclient側の追加SpreadType NOTE: backendでは用いない
const additionalSpreadTypeForExchangeVolume = 'exchangeVolume'
// 上記の追加SpreadTypeを考慮して、フォーム選択状態を拡張した開閉可能なSpreadForms
export type OpenableSpreadForms = (SpreadTypeTypeEnum | 'exchangeVolume')[]

export default defineComponent({
  name: 'StandardDeliveryTermsForm',
  components: {
    BaseButton,
    BaseIcon,
    BaseInputText,
    BaseSingleSelect,
    DeliveryDateForm,
  },
  props: {
    formValue: {
      type: Object as PropType<StandardDeliveryTermsFormProps>,
      required: true,
    },
    selectableInputs: {
      type: Object as PropType<BaseStandardIndicationSelectableInputs>,
      required: true,
    },
    openedForms: {
      type: Array as PropType<OpenableSpreadForms>,
      default: () => [],
    },
    deliveryUnit: {
      type: String as PropType<DeliveryUnit>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    areaInputMode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    hourTypeInputMode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    startDeliveryYearMonthInputMode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    endDeliveryYearMonthInputMode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    volumeInputMode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    deliveryDateFormMode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    dateNames: {
      type: Array as PropType<ProductTypeDateName[]>,
      required: true,
    },
    selectedProductTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
  },
  emits: ['form-input', 'text-input', 'open', 'close'],
  data(): {
    formInputEventName: 'form-input'
    textInputEventName: 'text-input'
  } {
    return {
      formInputEventName: 'form-input',
      textInputEventName: 'text-input',
    }
  },
  computed: {
    isMonthType(): boolean {
      return this.deliveryUnit === DeliveryUnit.Month
    },
    isOpenedArea(): boolean {
      return this.openedForms.includes(SpreadTypeTypeEnum.Area)
    },
    isOpenedHourType(): boolean {
      return this.openedForms.includes(SpreadTypeTypeEnum.DeliveryPattern)
    },
    isOpenedDeliveryPeriod(): boolean {
      return this.openedForms.includes(SpreadTypeTypeEnum.DeliveryPeriod)
    },
    isOpenedVolume(): boolean {
      return this.openedForms.includes(additionalSpreadTypeForExchangeVolume)
    },
    isShownAreaOpenButton(): boolean {
      return !this.isOpenedArea && this.areaInputMode === InputMode.INPUT
    },
    isShownAreaCloseButton(): boolean {
      return this.isOpenedArea && this.areaInputMode === InputMode.INPUT
    },
    isShownVolumeOpenButton(): boolean {
      const isSpread =
        this.openedForms.includes(SpreadTypeTypeEnum.Area) ||
        this.openedForms.includes(SpreadTypeTypeEnum.DeliveryPattern) ||
        this.openedForms.includes(SpreadTypeTypeEnum.DeliveryPeriod)
      return (
        isSpread &&
        !this.isOpenedVolume &&
        this.volumeInputMode === InputMode.INPUT
      )
    },
    isShownVolumeCloseButton(): boolean {
      return this.isOpenedVolume && this.volumeInputMode === InputMode.INPUT
    },
    isShownHourTypeOpenButton(): boolean {
      return (
        !this.isOpenedHourType && this.hourTypeInputMode === InputMode.INPUT
      )
    },
    isShownHourTypeCloseButton(): boolean {
      return this.isOpenedHourType && this.hourTypeInputMode === InputMode.INPUT
    },
    isShownDeliveryPeriodOpenButton(): boolean {
      return (
        !this.isOpenedDeliveryPeriod &&
        this.startDeliveryYearMonthInputMode === InputMode.INPUT
      )
    },
    isShownDeliveryPeriodCloseButton(): boolean {
      return (
        this.isOpenedDeliveryPeriod &&
        this.startDeliveryYearMonthInputMode === InputMode.INPUT
      )
    },
    areaInputOptions(): InputOption[] {
      return this.selectableInputs.areaInputOptions
    },
    exchangeAreaInputOptions(): InputOption[] {
      return this.selectableInputs.exchangeAreaInputOptions
    },
    hourTypeInputOptions(): InputOption[] {
      return this.selectableInputs.hourTypeInputOptions
    },
    exchangeHourTypeInputOptions(): InputOption[] {
      return this.selectableInputs.exchangeHourTypeInputOptions
    },
    startDeliveryYearMonthInputOptions(): InputOption[] {
      return this.selectableInputs.deliveryStartYearMonthInputOptions
    },
    exchangeStartDeliveryYearMonthInputOptions(): InputOption[] {
      return this.selectableInputs.exchangeDeliveryStartYearMonthInputOptions
    },
    endDeliveryYearMonthInputOptions(): InputOption[] {
      return this.selectableInputs.deliveryEndYearMonthInputOptions
    },
    exchangeEndDeliveryYearMonthInputOptions(): InputOption[] {
      return this.selectableInputs.exchangeDeliveryEndYearMonthInputOptions
    },
    volumeValidationErrorMessage(): string {
      return this.selectableInputs.volume.validationErrorMessage ?? ''
    },
    startSelectableDates(): Date[] {
      return this.selectableInputs.selectableDeliveryStartDates
    },
    exchangeStartSelectableDates(): Date[] {
      return this.selectableInputs.selectableExchangeDeliveryStartDates
    },
    endSelectableDates(): Date[] {
      return this.selectableInputs.selectableDeliveryEndDates
    },
    exchangeEndSelectableDates(): Date[] {
      return this.selectableInputs.selectableExchangeDeliveryEndDates
    },
    areaValue(): InputOption | undefined {
      if (!this.formValue.base.areaId) {
        return
      }
      return this.selectableInputs.getAreaInputOptionById(
        this.formValue.base.areaId,
      )
    },
    exchangeAreaValue(): InputOption | undefined {
      if (!this.formValue.exchange.areaId) {
        return
      }
      return this.selectableInputs.getExchangeAreaInputOptionById(
        this.formValue.exchange.areaId,
      )
    },
    hourTypeValue(): InputOption | undefined {
      if (!this.formValue.base.hourTypeId) {
        return
      }
      return this.selectableInputs.getHourTypeInputOptionById(
        this.formValue.base.hourTypeId,
      )
    },
    exchangeHourTypeValue(): InputOption | undefined {
      if (!this.formValue.exchange.hourTypeId) {
        return
      }
      return this.selectableInputs.getExchangeHourTypeInputOptionById(
        this.formValue.exchange.hourTypeId,
      )
    },
    volumeValue(): string | null {
      const volume = this.formValue.base.volumes[0]
      return this.volumeInputMode === InputMode.READONLY && !volume
        ? this.$t('trading.label.tbd').toString()
        : volume
    },
    exchangeVolumeValue(): string | null {
      const volume = this.formValue.exchange.volumes[0]
      return this.volumeInputMode === InputMode.READONLY && !volume
        ? this.$t('trading.label.tbd').toString()
        : volume
    },
    startDeliveryYearMonthValue(): InputOption | undefined {
      if (!this.formValue.base.startDeliveryYearMonthId) {
        return
      }
      return this.selectableInputs.getDeliveryStartYearMonthInputOptionById(
        this.formValue.base.startDeliveryYearMonthId,
      )
    },
    exchangeStartDeliveryYearMonthValue(): InputOption | undefined {
      if (!this.formValue.exchange.startDeliveryYearMonthId) {
        return
      }
      return this.selectableInputs.getExchangeDeliveryStartYearMonthInputOptionById(
        this.formValue.exchange.startDeliveryYearMonthId,
      )
    },
    endDeliveryYearMonthValue(): InputOption | undefined {
      if (!this.formValue.base.endDeliveryYearMonthId) {
        return
      }
      return this.selectableInputs.getDeliveryEndYearMonthInputOptionById(
        this.formValue.base.endDeliveryYearMonthId,
      )
    },
    exchangeEndDeliveryYearMonthValue(): InputOption | undefined {
      if (!this.formValue.exchange.endDeliveryYearMonthId) {
        return
      }
      return this.selectableInputs.getExchangeDeliveryEndYearMonthInputOptionById(
        this.formValue.exchange.endDeliveryYearMonthId,
      )
    },
    deliveryDateFormValue(): DeliveryDateFormProps {
      return {
        startDeliveryDate: this.formValue.base.startDeliveryDate,
        endDeliveryDate: this.formValue.base.endDeliveryDate,
      }
    },
    exchangeDeliveryDateFormValue(): DeliveryDateFormProps {
      return {
        startDeliveryDate: this.formValue.exchange.startDeliveryDate,
        endDeliveryDate: this.formValue.exchange.endDeliveryDate,
      }
    },
    dateName(): string | undefined {
      if (
        !isOneWeek({
          from: this.formValue.base.startDeliveryDate,
          to: this.formValue.base.endDeliveryDate,
        })
      ) {
        return
      }
      const dateName = this.dateNames.find(dateName => {
        return dateName.date === this.formValue.base.startDeliveryDate
      })
      return dateName?.dateName
    },
    exchangeDateName(): string | undefined {
      if (
        !isOneWeek({
          from: this.formValue.exchange.startDeliveryDate,
          to: this.formValue.exchange.endDeliveryDate,
        })
      ) {
        return
      }
      const dateName = this.dateNames.find(dateName => {
        return dateName.date === this.formValue.exchange.startDeliveryDate
      })
      return dateName?.dateName
    },
  },
  mounted() {
    // 数量スプレッドUIはfrontendだけで開閉を制御しているため、更新時のための判定はmount時に行う（新規作成時は何もしない）
    const hasExchangeVolume =
      this.formValue.base.volumes.length ===
        this.formValue.exchange.volumes.length &&
      this.formValue.exchange.volumes[0] !== '' && // 交換側の初期値ではないこと
      !this.formValue.base.volumes.every(
        // 基準と交換の数量が一致しないこと
        (baseVolume, idx) =>
          baseVolume === this.formValue.exchange.volumes[idx],
      )
    if (
      hasExchangeVolume &&
      !this.openedForms.includes(additionalSpreadTypeForExchangeVolume)
    ) {
      this.openedForms.push(additionalSpreadTypeForExchangeVolume)
    }
  },
  methods: {
    onVolumeInput(volume: string | undefined) {
      // 数量スプレッドではないなら、基準と同じ値を都度設定する
      const noExchangeVolume = !this.isOpenedVolume
      const exchange = this.formValue.exchange
      if (noExchangeVolume) {
        let overwriteVolume: string | null
        if (volume === undefined) {
          overwriteVolume = null
        } else {
          overwriteVolume = volume
        }
        exchange.volumes = [overwriteVolume]
      }

      this.$emit(this.textInputEventName, {
        base: {
          ...this.formValue.base,
          volumes: [volume],
        },
        exchange: exchange,
      })
    },
    onExchangeVolumeInput(volume: string | undefined) {
      this.$emit(this.textInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          volumes: [volume],
        },
      })
    },
    onAreaInput(event: InputOption | null) {
      const areaId = event?.value
      this.$emit(this.formInputEventName, {
        base: {
          ...this.formValue.base,
          areaId,
        },
        exchange: this.formValue.exchange,
      })
    },
    onExchangeAreaInput(event: InputOption | null) {
      const areaId = event?.value
      this.$emit(this.formInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          areaId,
        },
      })
    },
    onHourTypeInput(event: InputOption | null) {
      const hourTypeId = event?.value
      this.$emit(this.formInputEventName, {
        base: {
          ...this.formValue.base,
          hourTypeId,
        },
        exchange: this.formValue.exchange,
      })
    },
    onExchangeHourTypeInput(event: InputOption | null) {
      const hourTypeId = event?.value
      this.$emit(this.formInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          hourTypeId,
        },
      })
    },
    onStartDeliveryYearMonthInput(event: InputOption | null) {
      const startDeliveryYearMonthId = event?.value
      this.$emit(this.formInputEventName, {
        base: {
          ...this.formValue.base,
          startDeliveryYearMonthId,
        },
        exchange: this.formValue.exchange,
      })
    },
    onExchangeStartDeliveryYearMonthInput(event: InputOption | null) {
      const startDeliveryYearMonthId = event?.value
      this.$emit(this.formInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          startDeliveryYearMonthId,
        },
      })
    },
    onEndDeliveryYearMonthInput(event: InputOption | null) {
      const endDeliveryYearMonthId = event?.value
      this.$emit(this.formInputEventName, {
        base: {
          ...this.formValue.base,
          endDeliveryYearMonthId,
        },
        exchange: this.formValue.exchange,
      })
    },
    onExchangeEndDeliveryYearMonthInput(event: InputOption | null) {
      const endDeliveryYearMonthId = event?.value
      this.$emit(this.formInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          endDeliveryYearMonthId,
        },
      })
    },
    onDeliveryDateInput({
      startDeliveryDate,
      endDeliveryDate,
    }: {
      startDeliveryDate?: string
      endDeliveryDate?: string
    }) {
      this.$emit(this.formInputEventName, {
        base: {
          ...this.formValue.base,
          startDeliveryDate,
          endDeliveryDate,
        },
        exchange: this.formValue.exchange,
      })
    },
    onExchangeDeliveryDateInput({
      startDeliveryDate,
      endDeliveryDate,
    }: {
      startDeliveryDate?: string
      endDeliveryDate?: string
    }) {
      this.$emit(this.formInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          startDeliveryDate,
          endDeliveryDate,
        },
      })
    },
    onAreaOpen() {
      this.$emit('open', SpreadTypeTypeEnum.Area)
    },
    onAreaClose() {
      this.$emit('close', SpreadTypeTypeEnum.Area)
    },
    onDeliveryPeriodOpen() {
      this.$emit('open', SpreadTypeTypeEnum.DeliveryPeriod)
    },
    onDeliveryPeriodClose() {
      this.$emit('close', SpreadTypeTypeEnum.DeliveryPeriod)
    },
    onHourTypeOpen() {
      this.$emit('open', SpreadTypeTypeEnum.DeliveryPattern)
    },
    onHourTypeClose() {
      this.$emit('close', SpreadTypeTypeEnum.DeliveryPattern)
    },
    onVolumeOpen() {
      this.$emit('open', additionalSpreadTypeForExchangeVolume)
    },
    onVolumeClose() {
      // 数量スプレッドをやめたことに相当し、交換側に基準と同じ数量を入れる
      this.$emit(this.textInputEventName, {
        base: this.formValue.base,
        exchange: {
          ...this.formValue.exchange,
          volumes: this.formValue.base.volumes,
        },
      })
      this.$emit('close', additionalSpreadTypeForExchangeVolume)
    },
  },
})
