
import { defineComponent, PropType } from 'vue'

import { OrderFuelSurchargeType, PositionType } from '@/api/generated'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { SPREAD_SYMBOL } from '@/components/common/constants/Spread'
import FuelSurchargeTypeIcon from '@/components/trading/FuelSurchargeTypeIcon.vue'

export default defineComponent({
  name: 'UnitPriceAndVolume',
  components: {
    BaseIcon,
    BaseTooltip,
    FuelSurchargeTypeIcon,
  },
  props: {
    unitPrice: {
      type: String,
      required: true,
    },
    volume: {
      type: String,
      default: undefined,
    },
    fuelSurchargeType: {
      type: Object as PropType<OrderFuelSurchargeType>,
      default: undefined,
    },
    position: {
      type: String,
      default: '',
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    executedAt: {
      type: String,
      default: undefined,
    },
    lightFont: {
      type: Boolean,
      default: false,
    },
    exchangeVolume: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    modifierName(): string {
      if (this.lightFont) {
        return 'light-font'
      }
      if (
        (!this.colorReversed && this.position === PositionType.Ask) ||
        (this.colorReversed && this.position === PositionType.Bid)
      ) {
        return 'order-red'
      }
      if (
        (!this.colorReversed && this.position === PositionType.Bid) ||
        (this.colorReversed && this.position === PositionType.Ask)
      ) {
        return 'order-blue'
      }
      return ''
    },
    hasOnlyBase(): boolean {
      return (
        this.exchangeVolume === undefined || this.volume === this.exchangeVolume
      )
    },
    spreadDisplay(): string {
      return `${this.volume} ${SPREAD_SYMBOL} ${this.exchangeVolume}`
    },
  },
})
