import { RouteRecordRaw } from 'vue-router'

import EclearOrganization from '@/views/iam/EclearOrganization.vue'
import EclearOrganizations from '@/views/iam/EclearOrganizations.vue'

/**
 * パス: /ecloud-organizations/...
 */
export const eClearOrganizationsRoutes: RouteRecordRaw[] = [
  {
    path: '/eclear-organizations',
    name: 'EclearOrganizations',
    component: EclearOrganizations,
  },
  {
    path: '/eclear-organizations/:organizationId',
    name: 'EclearOrganizationDetail',
    component: EclearOrganization,
    props: route => ({ organizationId: route.params.organizationId }),
    meta: {
      activeMenuItem: 'eclear-organizations',
      titleKey: 'iam.label.detailedEclearOrganizationProfile',
    },
  },
]
