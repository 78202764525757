import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-512bcfb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-deal" }
const _hoisted_2 = { class: "my-deal__column" }
const _hoisted_3 = { class: "my-deal__fuel-surcharge-type-name" }
const _hoisted_4 = { class: "my-deal__created-at" }
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_position_icon = _resolveComponent("position-icon")!
  const _component_delivery_terms_with_period = _resolveComponent("delivery-terms-with-period")!
  const _component_product_type = _resolveComponent("product-type")!
  const _component_old_unit_price_and_volume = _resolveComponent("old-unit-price-and-volume")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["my-deal__inner", _ctx.myDealClass]),
          onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
          onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
        }, [
          _createVNode(_component_position_icon, {
            class: "my-deal__position-icon",
            position: _ctx.myDeal.position,
            "color-reversed": _ctx.colorReversed
          }, null, 8, ["position", "color-reversed"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_delivery_terms_with_period, { "delivery-terms": _ctx.deliveryTerms }, null, 8, ["delivery-terms"])
          ]),
          _createVNode(_component_product_type, {
            name: _ctx.product.productType.translation()
          }, null, 8, ["name"]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fuelSurchargeTypeName), 1),
          _createVNode(_component_old_unit_price_and_volume, {
            class: "my-deal__unit-price-and-volume",
            "unit-price": _ctx.myDeal.unitPrice,
            volume: _ctx.myDeal.volume,
            locale: _ctx.locale,
            "exchange-volume": _ctx.myDeal.exchangeVolume?.toString()
          }, null, 8, ["unit-price", "volume", "locale", "exchange-volume"]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.myDeal.displayCreatedAt()), 1),
          _createElementVNode("div", {
            class: "my-deal__counter-name",
            title: _ctx.counterNameTranslation
          }, _toDisplayString(_ctx.counterNameTranslation), 9, _hoisted_5)
        ], 34)
      ]),
      _: 1
    })
  ]))
}