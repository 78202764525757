
import { defineComponent, PropType } from 'vue'

import MemberListItem from '@/components/iam/MemberListItem.vue'
import { MemberProfile } from '@/models/iam/MemberProfile'

export default defineComponent({
  name: 'MemberEmailSettingList',
  components: {
    MemberListItem,
  },
  props: {
    members: {
      type: Array as PropType<MemberProfile[]>,
      default: () => [],
    },
    parentPath: {
      type: String,
      required: true,
    },
  },
})
