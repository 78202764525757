import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30184664"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "localized-report-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_report_list_item = _resolveComponent("localized-report-list-item")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reports, (report, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_localized_report_list_item, {
        key: index,
        class: "localized-report-list__item",
        report: report,
        onItemClick: _ctx.onItemClick,
        onDownloadClick: _ctx.onDownloadClick
      }, null, 8, ["report", "onItemClick", "onDownloadClick"])), [
        [_directive_observe_visibility, isVisible => _ctx.visibilityChanged(isVisible, index)]
      ])
    }), 128))
  ]))
}