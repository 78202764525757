import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4804400b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channel-list-window" }
const _hoisted_2 = {
  key: 0,
  class: "channel-list-window__body"
}
const _hoisted_3 = {
  key: 1,
  class: "channel-list-window__annotation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tag = _resolveComponent("base-tag")!
  const _component_channel_list = _resolveComponent("channel-list")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_chat_window = _resolveComponent("base-chat-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_chat_window, {
      height: _ctx.height,
      width: _ctx.width,
      "is-opened": _ctx.isOpened,
      "has-unread-message": _ctx.hasUnreadMessage,
      onTitleBarClick: _ctx.onTitleBarClick
    }, {
      "title-bar": _withCtx(() => [
        _createElementVNode("div", {
          class: "channel-list-window__title-bar",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onTitleBarClick && _ctx.onTitleBarClick(...args)), ["self"]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["channel-list-window__title", { 'channel-list-window__title--unread': _ctx.hasUnreadMessage }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTitleBarClick && _ctx.onTitleBarClick(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('common.label.chat')) + " ", 1),
            (_ctx.totalNumberOfUnreadMessage > 0)
              ? (_openBlock(), _createBlock(_component_base_tag, {
                  key: 0,
                  class: "channel-list-window__badge"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.totalNumberOfUnreadMessage), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ]),
      body: _withCtx(() => [
        (!_ctx.isChannelListEmpty)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_channel_list, {
                class: "channel-list-window__channel-list",
                "unread-channel-list": _ctx.unreadChannelList,
                "read-channel-list": _ctx.readChannelList,
                "displays-selected": false,
                "display-create-message-button": _ctx.displayCreateMessageButton,
                "display-open-d-m-list-button": _ctx.displayOpenDMListButton,
                onChannelSelect: _ctx.onChannelSelect,
                onCreateMessageButtonClick: _ctx.onCreateMessageButtonClick,
                onOpenDmChannel: _ctx.onOpenDMChannel
              }, null, 8, ["unread-channel-list", "read-channel-list", "display-create-message-button", "display-open-d-m-list-button", "onChannelSelect", "onCreateMessageButtonClick", "onOpenDmChannel"])
            ]))
          : (!_ctx.isFetchingChannelList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('chat.message.preparingChatAnnotation')), 1))
            : (_openBlock(), _createBlock(_component_centered_loading_content, {
                key: 2,
                type: "grayscale"
              }))
      ]),
      _: 1
    }, 8, ["height", "width", "is-opened", "has-unread-message", "onTitleBarClick"])
  ]))
}