import { Type } from 'class-transformer'

import { DayPattern as IDayPattern } from '@/api/generated'
import { EntityAttribute } from '@/models/common/EntityAttribute'
import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class DayPattern extends EntityAttribute implements IDayPattern {
  id!: string

  @Type(() => MultiLocaleName)
  excludedDayInfo?: MultiLocaleName
}
