
import { defineComponent, PropType } from 'vue'

import BaseInputSingleCheckbox from '@/components/common/BaseInputSingleCheckbox.vue'
import { InputOption } from '@/components/common/interface/InputOption'
import { ReceiveEmailFormProps } from '@/components/iam/interface/ReceiveEmailFormProps'

export default defineComponent({
  name: 'ReceiveEmailForm',
  components: {
    BaseInputSingleCheckbox,
  },
  props: {
    formValue: {
      type: Object as PropType<ReceiveEmailFormProps>,
      required: true,
    },
  },
  emits: ['form-input'],
  computed: {
    receiveIndicationEmailOptions(): InputOption[] {
      return []
    },
  },
  methods: {
    onInput(target: keyof ReceiveEmailFormProps, value: boolean) {
      this.$emit('form-input', { ...this.formValue, [target]: value })
    },
  },
})
