import { TOCOMBroker as ITocomBroker } from '@/api/generated/api'
export class TocomBroker implements ITocomBroker {
  id!: string
  name!: string
  offFloorTradingCode!: string
  createdAt!: string
  updatedAt!: string

  get label(): string {
    return `${this.name} (${this.offFloorTradingCode})`
  }
}
