
import { defineComponent } from 'vue'

import { Locale } from '@/api/generated'
import BaseBadge from '@/components/common/BaseBadge.vue'
import BaseBox from '@/components/common/BaseBox.vue'

export default defineComponent({
  name: 'ReportListItem',
  components: {
    BaseBadge,
    BaseBox,
  },
  props: {
    jaContentTitle: {
      type: String,
      default: undefined,
    },
    enContentTitle: {
      type: String,
      default: undefined,
    },
  },
  emits: ['list-item-click'],
  data() {
    return {
      locale: Locale,
    }
  },
  computed: {
    displayJaContentTitle(): string {
      return (
        this.jaContentTitle ?? this.$t('common.label.notAvailable').toString()
      )
    },
    displayEnContentTitle(): string {
      return (
        this.enContentTitle ?? this.$t('common.label.notAvailable').toString()
      )
    },
  },
  methods: {
    onClick(locale: Locale) {
      this.$emit('list-item-click', locale)
    },
  },
})
