import {
  FuelSurchargeType as IFuelSurchargeType,
  FuelSurchargeTypeType,
} from '@/api/generated'
import { EntityAttribute } from '@/models/common/EntityAttribute'

export class FuelSurchargeType extends EntityAttribute
  implements IFuelSurchargeType {
  fuelSurchargeTypeId!: string
  type!: FuelSurchargeTypeType

  get id(): string {
    return this.fuelSurchargeTypeId
  }
}
