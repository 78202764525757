
import { defineComponent, PropType } from 'vue'

import { TocomBroker } from '@/models/trading/TocomBroker'

export default defineComponent({
  name: 'SelectedTocomBroker',
  props: {
    isSelectable: {
      type: Boolean,
      required: true,
    },
    selectedTocomBroker: {
      type: Object as PropType<TocomBroker>,
      required: true,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      if (this.isSelectable) {
        this.$emit('click')
      }
    },
  },
})
