import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-130783bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chat"
}
const _hoisted_2 = {
  key: 1,
  class: "annotation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channel_list = _resolveComponent("channel-list")!
  const _component_channel_content = _resolveComponent("channel-content")!
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_new_message = _resolveComponent("new-message")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_channel_select = _resolveComponent("channel-select")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.$t('common.label.chat'),
    "active-menu-item": "chat"
  }, {
    default: _withCtx(() => [
      (!_ctx.isChannelListEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_channel_list, {
              class: "chat__channel-list",
              "unread-channel-list": _ctx.sortedChannelList[0],
              "read-channel-list": _ctx.sortedChannelList[1],
              "display-create-message-button": _ctx.displayCreateMessageButton,
              "display-open-d-m-list-button": _ctx.isOpenDMListButtonDisplayed,
              onChannelSelect: _ctx.onChannelSelect,
              onCreateMessageButtonClick: _ctx.onCreateMessageButtonClick,
              onOpenDmChannel: _ctx.onOpenDMChannel
            }, null, 8, ["unread-channel-list", "read-channel-list", "display-create-message-button", "display-open-d-m-list-button", "onChannelSelect", "onCreateMessageButtonClick", "onOpenDmChannel"]),
            _createVNode(_component_channel_content, _mergeProps({
              ref: "channelContent",
              class: "chat__channel-content"
            }, _ctx.channelContent, {
              "is-starred": _ctx.isActiveChannelStarred,
              "is-sending": _ctx.isSending,
              "active-channel-picture-data": _ctx.activeChannelPictureData,
              onInput: _ctx.onInput,
              onTyping: _ctx.onTyping,
              onSend: _ctx.onSend,
              onTouchStart: _ctx.onTouchStart,
              onReadMessage: _ctx.onReadMessage,
              onSelectAddStar: _ctx.onSelectAddStar,
              onSelectRemoveStar: _ctx.onSelectRemoveStar
            }), null, 16, ["is-starred", "is-sending", "active-channel-picture-data", "onInput", "onTyping", "onSend", "onTouchStart", "onReadMessage", "onSelectAddStar", "onSelectRemoveStar"])
          ]))
        : (!_ctx.isFetchingChannelList)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('chat.message.preparingChatAnnotation')), 1))
          : (_openBlock(), _createBlock(_component_centered_loading_content, {
              key: 2,
              type: "grayscale"
            })),
      _createVNode(_component_base_modal, {
        name: _ctx.modalName,
        title: _ctx.$t('chat.message.newMessageModalTitle'),
        onClosed: _ctx.resetChatInput
      }, {
        default: _withCtx(() => [
          _createVNode(_component_new_message, {
            "organization-channels": _ctx.recipientList,
            "search-input-value": _ctx.recipientSearchInput,
            "chat-input-value": _ctx.message,
            locked: false,
            "selected-language": _ctx.recipientSelectedLanguage,
            onCancelButtonClick: _ctx.onCancelButtonClick,
            onSelectedListChange: _ctx.onSelectedListChange,
            onSearchInput: _ctx.onRecipientSearchInput,
            onLanguageInput: _ctx.onRecipientSelectedLanguage,
            onChatInput: _ctx.onNewChatInput,
            onSend: _ctx.onSendNewMessage
          }, null, 8, ["organization-channels", "search-input-value", "chat-input-value", "selected-language", "onCancelButtonClick", "onSelectedListChange", "onSearchInput", "onLanguageInput", "onChatInput", "onSend"])
        ]),
        _: 1
      }, 8, ["name", "title", "onClosed"]),
      _createVNode(_component_base_modal, {
        name: "newDMChannelModal",
        title: _ctx.$t('chat.message.openChannel')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_channel_select, {
            "channel-list": _ctx.channelList.dMChannels,
            onClickChannel: _ctx.onClickChannel,
            onCancel: _ctx.onCancel
          }, null, 8, ["channel-list", "onClickChannel", "onCancel"])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["title"]))
}