
import { defineComponent, PropType } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BrokerCollapsedNavigation from '@/components/common/CollapsedNavigation/BrokerCollapsedNavigation.vue'
import TraderCollapsedNavigation from '@/components/common/CollapsedNavigation/TraderCollapsedNavigation.vue'
import { GlobalModule } from '@/store/modules/common/global'
import { UserProfileModule } from '@/store/modules/iam/userProfile'

const {
  mapActions: globalActions,
  mapGetters: globalGetters,
} = createNamespacedHelpers('global') as GlobalModule
const { mapGetters: userProfileGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const NavigationType = ['broker', 'trader'] as const

export default defineComponent({
  name: 'CollapsedNavigationContainer',
  components: {
    BrokerCollapsedNavigation,
    TraderCollapsedNavigation,
  },
  props: {
    type: {
      type: String as PropType<typeof NavigationType[number]>,
      default: 'broker',
      validator(val: string) {
        return (NavigationType as ReadonlyArray<string>).includes(val)
      },
    },
    currentMenu: {
      type: String,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...globalGetters(['organizationName', 'organizationPicture']),
    ...userProfileGetters(['userProfile']),
  },
  async created() {
    this.handleFetchOrganization()
  },
  methods: {
    ...globalActions(['fetchOrganization']),
    async handleFetchOrganization() {
      // 初回読み込みにapiで組織情報取得、以降のページ遷移では保持したstateを利用するため取得不要
      if (
        this.organizationName === undefined ||
        this.organizationPicture === undefined
      ) {
        await this.fetchOrganization(this.userProfile.organizationId)
      }
    },
  },
})
