import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e842443a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "copy-to-clipboard-icon" }
const _hoisted_2 = { class: "copy-to-clipboard-icon__contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_tooltip, { label: _ctx.helpCopy }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_icon, {
            "icon-name": "clipboard",
            onClick: _withModifiers(_ctx.onCopyClick, ["stop"]),
            onMouseleave: _ctx.resetHelpCopy
          }, null, 8, ["onClick", "onMouseleave"])
        ])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}