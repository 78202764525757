import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2186d8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hint-comment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sentences = _resolveComponent("sentences")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.display)
      ? (_openBlock(), _createBlock(_component_sentences, {
          key: 0,
          type: "text-small"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('chat.message.hintComment')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}