import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca8155ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-input-wrapper" }
const _hoisted_2 = { class: "chat-input-wrapper__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_input = _resolveComponent("chat-input")!
  const _component_typing_indicator = _resolveComponent("typing-indicator")!
  const _component_hint_comment = _resolveComponent("hint-comment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chat_input, {
      value: _ctx.chatInputValue,
      rows: _ctx.chatInputRows,
      "is-sending": _ctx.isSending,
      onInput: _ctx.onInput,
      onSend: _ctx.onSend,
      onTyping: _ctx.onTyping
    }, null, 8, ["value", "rows", "is-sending", "onInput", "onSend", "onTyping"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_typing_indicator, { names: _ctx.typingUserNames }, null, 8, ["names"]),
      _createVNode(_component_hint_comment, { display: _ctx.display }, null, 8, ["display"])
    ])
  ]))
}