/* tslint:disable */
/* eslint-disable */
/**
 * eSquare
 * eSquareバックエンドサービスのAPI仕様
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: foo@enechain.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * チャットのアクセストークン
     * @type {string}
     * @memberof AccessToken
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface ActiveInformation
 */
export interface ActiveInformation {
    /**
     * お知らせID
     * @type {string}
     * @memberof ActiveInformation
     */
    'informationId': string;
    /**
     * タイトル
     * @type {string}
     * @memberof ActiveInformation
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     * @memberof ActiveInformation
     */
    'message': string;
    /**
     * 
     * @type {InformationType}
     * @memberof ActiveInformation
     */
    'informationType': InformationType;
    /**
     * お知らせの有効期限
     * @type {string}
     * @memberof ActiveInformation
     */
    'expiresAt': string;
}
/**
 * 
 * @export
 * @interface AddUserToChannelTask
 */
export interface AddUserToChannelTask {
    /**
     * 
     * @type {string}
     * @memberof AddUserToChannelTask
     */
    'channelId': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserToChannelTask
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AppArea
 */
export interface AppArea {
    /**
     * エリアのID
     * @type {string}
     * @memberof AppArea
     */
    'id': string;
    /**
     * エリア
     * @type {string}
     * @memberof AppArea
     */
    'key': string;
    /**
     * ローカライズされたエリアの文字列
     * @type {string}
     * @memberof AppArea
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppAreaOption
 */
export interface AppAreaOption {
    /**
     * 
     * @type {AppArea}
     * @memberof AppAreaOption
     */
    'area': AppArea;
    /**
     * そのチェックボックスが選択状態にあるかどうか、Falseの場合選択できないに加え既にチェックが入っていた場合はチェックを強制的に外す
     * @type {boolean}
     * @memberof AppAreaOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppAreaOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AppCategorizedChannels
 */
export interface AppCategorizedChannels {
    /**
     * 
     * @type {AppChannelCategory}
     * @memberof AppCategorizedChannels
     */
    'category': AppChannelCategory;
    /**
     * チャンネル一覧
     * @type {Array<AppChannelListItem>}
     * @memberof AppCategorizedChannels
     */
    'channels': Array<AppChannelListItem>;
}
/**
 * 
 * @export
 * @interface AppChannelCategory
 */
export interface AppChannelCategory {
    /**
     * チャンネルのカテゴリID
     * @type {string}
     * @memberof AppChannelCategory
     */
    'id': string;
    /**
     * ローカライズされたチャンネルカテゴリの文字列
     * @type {string}
     * @memberof AppChannelCategory
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppChannelDetail
 */
export interface AppChannelDetail {
    /**
     * チャンネルのID
     * @type {string}
     * @memberof AppChannelDetail
     */
    'id': string;
    /**
     * 
     * @type {AppChannelCategory}
     * @memberof AppChannelDetail
     */
    'category': AppChannelCategory;
    /**
     * チャンネル名
     * @type {string}
     * @memberof AppChannelDetail
     */
    'name': string;
    /**
     * チャンネルのアイコンURL
     * @type {string}
     * @memberof AppChannelDetail
     */
    'pictureUrl': string;
    /**
     * スター付きか否か
     * @type {boolean}
     * @memberof AppChannelDetail
     */
    'isStarred': boolean;
    /**
     * 参加人数
     * @type {number}
     * @memberof AppChannelDetail
     */
    'participants': number;
}
/**
 * 
 * @export
 * @interface AppChannelListItem
 */
export interface AppChannelListItem {
    /**
     * チャンネルのID
     * @type {string}
     * @memberof AppChannelListItem
     */
    'id': string;
    /**
     * チャンネル名
     * @type {string}
     * @memberof AppChannelListItem
     */
    'name': string;
    /**
     * チャンネルのアイコンURL
     * @type {string}
     * @memberof AppChannelListItem
     */
    'pictureUrl': string;
    /**
     * オンラインステータスがアクティブか否か
     * @type {boolean}
     * @memberof AppChannelListItem
     */
    'isActive': boolean;
    /**
     * 未読メッセージがあるか否か
     * @type {boolean}
     * @memberof AppChannelListItem
     */
    'hasUnread': boolean;
    /**
     * 最後のメッセージ文
     * @type {string}
     * @memberof AppChannelListItem
     */
    'lastMessage': string | null;
    /**
     * 更新日時
     * @type {string}
     * @memberof AppChannelListItem
     */
    'updatedAt': string | null;
}
/**
 * 
 * @export
 * @interface AppChannelParticipant
 */
export interface AppChannelParticipant {
    /**
     * チャンネル参加者のID
     * @type {string}
     * @memberof AppChannelParticipant
     */
    'id': string;
    /**
     * 名前
     * @type {string}
     * @memberof AppChannelParticipant
     */
    'name': string;
    /**
     * アイコンのURL
     * @type {string}
     * @memberof AppChannelParticipant
     */
    'pictureUrl': string;
    /**
     * 組織名
     * @type {string}
     * @memberof AppChannelParticipant
     */
    'organizationName': string;
    /**
     * 所属
     * @type {string}
     * @memberof AppChannelParticipant
     */
    'department': string | null;
    /**
     * オンラインステータスがアクティブか否か
     * @type {boolean}
     * @memberof AppChannelParticipant
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface AppChannelParticipantsPagingResponse
 */
export interface AppChannelParticipantsPagingResponse {
    /**
     * 参加者一覧
     * @type {Array<AppChannelParticipant>}
     * @memberof AppChannelParticipantsPagingResponse
     */
    'participants': Array<AppChannelParticipant>;
    /**
     * 次のページを取得するためのパラメータ。次のページが存在しない場合はnull。
     * @type {string}
     * @memberof AppChannelParticipantsPagingResponse
     */
    'next': string | null;
}
/**
 * 
 * @export
 * @interface AppChannelsPagingResponse
 */
export interface AppChannelsPagingResponse {
    /**
     * チャンネル一覧
     * @type {Array<AppChannelListItem>}
     * @memberof AppChannelsPagingResponse
     */
    'channels': Array<AppChannelListItem>;
    /**
     * 次のページを取得するためのパラメータ。次のページが存在しない場合はnull。
     * @type {string}
     * @memberof AppChannelsPagingResponse
     */
    'next': string | null;
}
/**
 * 
 * @export
 * @interface AppChatSummary
 */
export interface AppChatSummary {
    /**
     * 未読件数
     * @type {number}
     * @memberof AppChatSummary
     */
    'unread': number;
    /**
     * カテゴリごとの代表チャンネル一覧
     * @type {Array<AppCategorizedChannels>}
     * @memberof AppChatSummary
     */
    'categories': Array<AppCategorizedChannels>;
}
/**
 * 選択可能な日付一覧
 * @export
 * @interface AppDateOption
 */
export interface AppDateOption {
    /**
     * 
     * @type {string}
     * @memberof AppDateOption
     */
    'date': string;
    /**
     * そのチェックボックスが選択状態にあるかどうか、Falseの場合選択できないに加え既にチェックが入っていた場合はチェックを強制的に外す
     * @type {boolean}
     * @memberof AppDateOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppDateOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AppDeliveryUnit
 */
export interface AppDeliveryUnit {
    /**
     * 受給単位のID
     * @type {string}
     * @memberof AppDeliveryUnit
     */
    'id': string;
    /**
     * 受給単位
     * @type {string}
     * @memberof AppDeliveryUnit
     */
    'key': string;
    /**
     * ローカライズされた受給単位の文字列
     * @type {string}
     * @memberof AppDeliveryUnit
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppDeliveryUnitOption
 */
export interface AppDeliveryUnitOption {
    /**
     * 
     * @type {AppDeliveryUnit}
     * @memberof AppDeliveryUnitOption
     */
    'deliveryUnit': AppDeliveryUnit;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppDeliveryUnitOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AppFcmToken
 */
export interface AppFcmToken {
    /**
     * FCMトークン
     * @type {string}
     * @memberof AppFcmToken
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface AppFuelSurchargeType
 */
export interface AppFuelSurchargeType {
    /**
     * 燃料区分のID
     * @type {string}
     * @memberof AppFuelSurchargeType
     */
    'id': string;
    /**
     * 燃調区分
     * @type {string}
     * @memberof AppFuelSurchargeType
     */
    'key': string | null;
    /**
     * ローカライズされた燃調区分の文字列
     * @type {string}
     * @memberof AppFuelSurchargeType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppFuelSurchargeTypeOption
 */
export interface AppFuelSurchargeTypeOption {
    /**
     * 
     * @type {AppFuelSurchargeType}
     * @memberof AppFuelSurchargeTypeOption
     */
    'fuelSurchargeType': AppFuelSurchargeType | null;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppFuelSurchargeTypeOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AppHourType
 */
export interface AppHourType {
    /**
     * 時間帯区分のID
     * @type {string}
     * @memberof AppHourType
     */
    'id': string;
    /**
     * 時間帯区分
     * @type {string}
     * @memberof AppHourType
     */
    'key': string;
    /**
     * ローカライズされた時間帯区分の文字列
     * @type {string}
     * @memberof AppHourType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppHourTypeOption
 */
export interface AppHourTypeOption {
    /**
     * 
     * @type {AppHourType}
     * @memberof AppHourTypeOption
     */
    'hourType': AppHourType;
    /**
     * そのチェックボックスが選択状態にあるかどうか、Falseの場合選択できないに加え既にチェックが入っていた場合はチェックを強制的に外す
     * @type {boolean}
     * @memberof AppHourTypeOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppHourTypeOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AppIndicationStatus
 */
export interface AppIndicationStatus {
    /**
     * 
     * @type {AppMyIndicationStatusOption}
     * @memberof AppIndicationStatus
     */
    'indicationStatusType': AppMyIndicationStatusOption;
}
/**
 * 
 * @export
 * @interface AppMessage
 */
export interface AppMessage {
    /**
     * メッセージのID
     * @type {string}
     * @memberof AppMessage
     */
    'id': string;
    /**
     * 
     * @type {AppMessageSender}
     * @memberof AppMessage
     */
    'sender': AppMessageSender;
    /**
     * メッセージの本文
     * @type {string}
     * @memberof AppMessage
     */
    'message': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof AppMessage
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AppMessagePost
 */
export interface AppMessagePost {
    /**
     * メッセージの本文
     * @type {string}
     * @memberof AppMessagePost
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface AppMessageSender
 */
export interface AppMessageSender {
    /**
     * メッセージ送信者のユーザーID
     * @type {string}
     * @memberof AppMessageSender
     */
    'id': string;
    /**
     * メッセージ送信者の名前
     * @type {string}
     * @memberof AppMessageSender
     */
    'name': string;
    /**
     * メッセージ送信者のアイコンURL
     * @type {string}
     * @memberof AppMessageSender
     */
    'pictureUrl': string;
}
/**
 * 
 * @export
 * @interface AppMessagesPagingResponse
 */
export interface AppMessagesPagingResponse {
    /**
     * メッセージ一覧
     * @type {Array<AppMessage>}
     * @memberof AppMessagesPagingResponse
     */
    'messages': Array<AppMessage>;
    /**
     * 次のページを取得するためのパラメータ。次のページが存在しない場合はnull。
     * @type {string}
     * @memberof AppMessagesPagingResponse
     */
    'next': string | null;
}
/**
 * 
 * @export
 * @interface AppMyDeal
 */
export interface AppMyDeal {
    /**
     * 約定のユニークID
     * @type {string}
     * @memberof AppMyDeal
     */
    'dealId': string;
    /**
     * 
     * @type {AppPosition}
     * @memberof AppMyDeal
     */
    'position': AppPosition;
    /**
     * 
     * @type {AppArea}
     * @memberof AppMyDeal
     */
    'area': AppArea;
    /**
     * 開始日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyDeal
     */
    'deliveryStartDate': string;
    /**
     * 終了日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyDeal
     */
    'deliveryEndDate': string;
    /**
     * 
     * @type {AppHourType}
     * @memberof AppMyDeal
     */
    'hourType': AppHourType;
    /**
     * 
     * @type {AppProductType}
     * @memberof AppMyDeal
     */
    'productType': AppProductType;
    /**
     * 
     * @type {AppFuelSurchargeType}
     * @memberof AppMyDeal
     */
    'fuelSurchargeType': AppFuelSurchargeType | null;
    /**
     * 注文単価
     * @type {string}
     * @memberof AppMyDeal
     */
    'unitPrice': string;
    /**
     * 注文数量
     * @type {string}
     * @memberof AppMyDeal
     */
    'volume': string;
    /**
     * 約定日のUnixTime
     * @type {number}
     * @memberof AppMyDeal
     */
    'executedAt': number;
    /**
     * 約定相手
     * @type {string}
     * @memberof AppMyDeal
     */
    'counterName': string;
    /**
     * 
     * @type {AppDeliveryUnit}
     * @memberof AppMyDeal
     */
    'deliveryUnit': AppDeliveryUnit;
    /**
     * Swap(EEX)かつ受給単位が\"week-or-day\"の時に返ってくる週番号 そうじゃない場合はnull
     * @type {string}
     * @memberof AppMyDeal
     */
    'dateName': string | null;
}
/**
 * 
 * @export
 * @interface AppMyIndication
 */
export interface AppMyIndication {
    /**
     * 注文のユニークID
     * @type {string}
     * @memberof AppMyIndication
     */
    'indicationId': string;
    /**
     * 
     * @type {AppPosition}
     * @memberof AppMyIndication
     */
    'position': AppPosition;
    /**
     * 
     * @type {AppArea}
     * @memberof AppMyIndication
     */
    'area': AppArea;
    /**
     * 開始日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyIndication
     */
    'deliveryStartDate': string;
    /**
     * 終了日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyIndication
     */
    'deliveryEndDate': string;
    /**
     * 
     * @type {AppHourType}
     * @memberof AppMyIndication
     */
    'hourType': AppHourType;
    /**
     * 商品区分の配列
     * @type {Array<AppProductType>}
     * @memberof AppMyIndication
     */
    'productTypes': Array<AppProductType>;
    /**
     * 
     * @type {AppFuelSurchargeType}
     * @memberof AppMyIndication
     */
    'fuelSurchargeType': AppFuelSurchargeType | null;
    /**
     * 注文単価
     * @type {string}
     * @memberof AppMyIndication
     */
    'unitPrice': string;
    /**
     * 注文数量
     * @type {string}
     * @memberof AppMyIndication
     */
    'volume': string;
    /**
     * 
     * @type {AppMyIndicationStatusType}
     * @memberof AppMyIndication
     */
    'status': AppMyIndicationStatusType;
    /**
     * 作成日のUnixTime
     * @type {number}
     * @memberof AppMyIndication
     */
    'createdAt': number;
    /**
     * レコード作成者
     * @type {string}
     * @memberof AppMyIndication
     */
    'createdName': string;
    /**
     * 最終更新日のUnixTime
     * @type {number}
     * @memberof AppMyIndication
     */
    'lastUpdatedAt': number;
    /**
     * 最終更新者
     * @type {string}
     * @memberof AppMyIndication
     */
    'lastUpdatedName': string;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof AppMyIndication
     */
    'request': string;
    /**
     * 
     * @type {AppDeliveryUnit}
     * @memberof AppMyIndication
     */
    'deliveryUnit': AppDeliveryUnit;
    /**
     * Swap(EEX)かつ受給単位が\"week-or-day\"の時に返ってくる週番号
     * @type {string}
     * @memberof AppMyIndication
     */
    'dateName': string | null;
}
/**
 * 
 * @export
 * @interface AppMyIndicationPrivilege
 */
export interface AppMyIndicationPrivilege {
    /**
     * 有効化可能か否か
     * @type {boolean}
     * @memberof AppMyIndicationPrivilege
     */
    'canActivate': boolean;
    /**
     * 無効化可能か否か
     * @type {boolean}
     * @memberof AppMyIndicationPrivilege
     */
    'canDeactivate': boolean;
    /**
     * 変更可能か否か
     * @type {boolean}
     * @memberof AppMyIndicationPrivilege
     */
    'canChange': boolean;
    /**
     * 削除可能か否か
     * @type {boolean}
     * @memberof AppMyIndicationPrivilege
     */
    'canDelete': boolean;
}
/**
 * 
 * @export
 * @interface AppMyIndicationSelectableInput
 */
export interface AppMyIndicationSelectableInput {
    /**
     * 売買区分一覧
     * @type {Array<AppPositionOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'positions': Array<AppPositionOption>;
    /**
     * 受給単位一覧
     * @type {Array<AppDeliveryUnitOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'deliveryUnits': Array<AppDeliveryUnitOption>;
    /**
     * 選択可能な商品区分一覧
     * @type {Array<AppProductTypeOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'productTypes': Array<AppProductTypeOption>;
    /**
     * 選択可能なエリア一覧
     * @type {Array<AppAreaOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'areas': Array<AppAreaOption>;
    /**
     * 選択可能な時間帯区分一覧
     * @type {Array<AppHourTypeOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'hourTypes': Array<AppHourTypeOption>;
    /**
     * 選択可能な開始日一覧
     * @type {Array<AppDateOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'deliveryStartDates': Array<AppDateOption>;
    /**
     * 選択可能な終了日一覧
     * @type {Array<AppDateOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'deliveryEndDates': Array<AppDateOption>;
    /**
     * 選択可能な燃調区分一覧、燃調ありを選択している場合のみ返却される そうじゃない場合はnull
     * @type {Array<AppFuelSurchargeTypeOption>}
     * @memberof AppMyIndicationSelectableInput
     */
    'fuelSurchargeTypes': Array<AppFuelSurchargeTypeOption> | null;
    /**
     * 
     * @type {AppValidatableValue}
     * @memberof AppMyIndicationSelectableInput
     */
    'unitPrice': AppValidatableValue;
    /**
     * 
     * @type {AppValidatableValue}
     * @memberof AppMyIndicationSelectableInput
     */
    'volume': AppValidatableValue;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof AppMyIndicationSelectableInput
     */
    'request': string | null;
}
/**
 * 
 * @export
 * @interface AppMyIndicationSelectableInputRequest
 */
export interface AppMyIndicationSelectableInputRequest {
    /**
     * 注文ID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'indicationId'?: string;
    /**
     * 公開注文のユニークID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'deliveryTermsId'?: string;
    /**
     * 売買区分のID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'position'?: string;
    /**
     * ユーザーが入力している商品区分
     * @type {Array<string>}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'productTypes'?: Array<string>;
    /**
     * エリアのID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'area'?: string;
    /**
     * 時間帯区分のID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'hourType'?: string;
    /**
     * ユーザーが入力している開始日 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'deliveryStartDate'?: string;
    /**
     * ユーザーが入力している終了日 yyyy-MMdd
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'deliveryEndDate'?: string;
    /**
     * 燃料区分のID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'fuelSurchargeType'?: string;
    /**
     * 受給単位のID
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'deliveryUnit'?: string;
    /**
     * 注文単価
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'unitPrice'?: string;
    /**
     * 注文数量
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'volume'?: string;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof AppMyIndicationSelectableInputRequest
     */
    'request'?: string;
}
/**
 * リクエストしたい注文のStatus
 * @export
 * @enum {string}
 */

export const AppMyIndicationStatusOption = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type AppMyIndicationStatusOption = typeof AppMyIndicationStatusOption[keyof typeof AppMyIndicationStatusOption];


/**
 * 
 * @export
 * @interface AppMyIndicationStatusType
 */
export interface AppMyIndicationStatusType {
    /**
     * 
     * @type {AppMyIndicationStatusTypeKey}
     * @memberof AppMyIndicationStatusType
     */
    'key': AppMyIndicationStatusTypeKey;
    /**
     * ローカライズされた注文の状態の文字列
     * @type {string}
     * @memberof AppMyIndicationStatusType
     */
    'name': string;
}
/**
 * 注文の状態
 * @export
 * @enum {string}
 */

export const AppMyIndicationStatusTypeKey = {
    Unconfirmed: 'unconfirmed',
    Active: 'active',
    Negotiating: 'negotiating',
    Done: 'done',
    Inactive: 'inactive',
    Expired: 'expired'
} as const;

export type AppMyIndicationStatusTypeKey = typeof AppMyIndicationStatusTypeKey[keyof typeof AppMyIndicationStatusTypeKey];


/**
 * 
 * @export
 * @interface AppMyIndicationUpdate
 */
export interface AppMyIndicationUpdate {
    /**
     * 商品区分IDの配列
     * @type {Array<string>}
     * @memberof AppMyIndicationUpdate
     */
    'productTypes': Array<string>;
    /**
     * エリアのID
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'area': string;
    /**
     * 時間帯区分のID
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'hourType': string;
    /**
     * 開始日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'deliveryStartDate': string;
    /**
     * 終了日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'deliveryEndDate': string;
    /**
     * 売買区分のID
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'position': string;
    /**
     * 注文単価
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'unitPrice': string;
    /**
     * 注文数量
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'volume'?: string;
    /**
     * 燃調区分のID 燃調なしの場合はNull
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'fuelSurchargeType'?: string | null;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof AppMyIndicationUpdate
     */
    'request'?: string;
}
/**
 * 
 * @export
 * @interface AppMyNewIndication
 */
export interface AppMyNewIndication {
    /**
     * 商品区分IDの配列
     * @type {Array<string>}
     * @memberof AppMyNewIndication
     */
    'productTypes': Array<string>;
    /**
     * エリアのID
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'area': string;
    /**
     * 時間帯区分のID
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'hourType': string;
    /**
     * 開始日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'deliveryStartDate': string;
    /**
     * 終了日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'deliveryEndDate': string;
    /**
     * 受給単位のID
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'deliveryUnit': string;
    /**
     * 売買区分のID
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'position': string;
    /**
     * 注文単価
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'unitPrice': string;
    /**
     * 注文数量
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'volume': string;
    /**
     * 燃調区分のID 燃調なしの場合はNull
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'fuelSurchargeType': string | null;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof AppMyNewIndication
     */
    'request': string | null;
}
/**
 * 
 * @export
 * @interface AppMyProfile
 */
export interface AppMyProfile {
    /**
     * ユーザーID
     * @type {string}
     * @memberof AppMyProfile
     */
    'id': string;
    /**
     * 
     * @type {AppUserType}
     * @memberof AppMyProfile
     */
    'userType': AppUserType;
    /**
     * 氏名
     * @type {string}
     * @memberof AppMyProfile
     */
    'name': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AppMyProfile
     */
    'mail': string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof AppMyProfile
     */
    'pictureUrl': string;
    /**
     * 組織名
     * @type {string}
     * @memberof AppMyProfile
     */
    'organizationName': string;
    /**
     * 所属
     * @type {string}
     * @memberof AppMyProfile
     */
    'department': string | null;
    /**
     * 買い売りの順序を反転するかどうか
     * @type {boolean}
     * @memberof AppMyProfile
     */
    'positionReversed': boolean;
    /**
     * 買い売りの色を反転するかどうか
     * @type {boolean}
     * @memberof AppMyProfile
     */
    'colorReversed': boolean;
    /**
     * eSquareが有効かどうか
     * @type {boolean}
     * @memberof AppMyProfile
     */
    'esquareEnabled': boolean;
    /**
     * eCompassを契約しているかどうか
     * @type {boolean}
     * @memberof AppMyProfile
     */
    'ecompassSubscribed': boolean;
}
/**
 * 
 * @export
 * @interface AppOrder
 */
export interface AppOrder {
    /**
     * 
     * @type {AppOrderType}
     * @memberof AppOrder
     */
    'orderType': AppOrderType;
    /**
     * 
     * @type {AppProductType}
     * @memberof AppOrder
     */
    'productType': AppProductType;
    /**
     * 
     * @type {AppFuelSurchargeType}
     * @memberof AppOrder
     */
    'fuelSurchargeType': AppFuelSurchargeType | null;
    /**
     * 注文単価
     * @type {string}
     * @memberof AppOrder
     */
    'unitPrice': string;
    /**
     * 注文数量
     * @type {string}
     * @memberof AppOrder
     */
    'volume': string;
    /**
     * 週間物（Swap-EEX且つ受給単位がweek-or-day）の場合返却される週番号
     * @type {string}
     * @memberof AppOrder
     */
    'dateName': string | null;
}
/**
 * 
 * @export
 * @interface AppOrderBook
 */
export interface AppOrderBook {
    /**
     * 公開注文のユニークID
     * @type {string}
     * @memberof AppOrderBook
     */
    'deliveryTermsId': string;
    /**
     * 
     * @type {AppArea}
     * @memberof AppOrderBook
     */
    'area': AppArea;
    /**
     * 開始日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppOrderBook
     */
    'deliveryStartDate': string;
    /**
     * 終了日時 yyyy-MM-dd
     * @type {string}
     * @memberof AppOrderBook
     */
    'deliveryEndDate': string;
    /**
     * 
     * @type {AppDeliveryUnit}
     * @memberof AppOrderBook
     */
    'deliveryUnit'?: AppDeliveryUnit;
    /**
     * 
     * @type {AppHourType}
     * @memberof AppOrderBook
     */
    'hourType': AppHourType;
    /**
     * BestAskの注文単価 存在しない場合はnull
     * @type {string}
     * @memberof AppOrderBook
     */
    'askUnitPrice': string | null;
    /**
     * BestAskの注文数量 未確定の場合は \"TBD\" 存在しない場合はnull
     * @type {string}
     * @memberof AppOrderBook
     */
    'askVolume': string | null;
    /**
     * BestBidの注文単価 存在しない場合はnull
     * @type {string}
     * @memberof AppOrderBook
     */
    'bidUnitPrice': string | null;
    /**
     * BestBidの注文数量 未確定の場合は \"TBD\" 存在しない場合はnull
     * @type {string}
     * @memberof AppOrderBook
     */
    'bidVolume': string | null;
    /**
     * 直近約定の注文単価 存在しない場合はnull
     * @type {string}
     * @memberof AppOrderBook
     */
    'lastUnitPrice': string | null;
    /**
     * 直近約定の注文数量 存在しない場合はnull
     * @type {string}
     * @memberof AppOrderBook
     */
    'lastVolume': string | null;
    /**
     * 板の注文一覧 価格の降順にソートされたもの
     * @type {Array<AppOrder>}
     * @memberof AppOrderBook
     */
    'orders': Array<AppOrder> | null;
}
/**
 * 
 * @export
 * @interface AppOrderBookFilters
 */
export interface AppOrderBookFilters {
    /**
     * エリアの配列
     * @type {Array<AppArea>}
     * @memberof AppOrderBookFilters
     */
    'areas': Array<AppArea>;
    /**
     * 開始日 yyyy-MM-dd
     * @type {Array<string>}
     * @memberof AppOrderBookFilters
     */
    'deliveryStartDates': Array<string>;
    /**
     * 終了日 yyyy-MM-dd
     * @type {Array<string>}
     * @memberof AppOrderBookFilters
     */
    'deliveryEndDates': Array<string>;
    /**
     * 時間帯区分の配列
     * @type {Array<AppHourType>}
     * @memberof AppOrderBookFilters
     */
    'hourTypes': Array<AppHourType>;
    /**
     * 商品区分の配列
     * @type {Array<AppProductType>}
     * @memberof AppOrderBookFilters
     */
    'productTypes': Array<AppProductType>;
}
/**
 * 注文の種類
 * @export
 * @enum {string}
 */

export const AppOrderType = {
    Ask: 'ask',
    Bid: 'bid',
    Last: 'last'
} as const;

export type AppOrderType = typeof AppOrderType[keyof typeof AppOrderType];


/**
 * 売買区分
 * @export
 * @interface AppPosition
 */
export interface AppPosition {
    /**
     * 売買区分のID
     * @type {string}
     * @memberof AppPosition
     */
    'id': string;
    /**
     * 売買区分
     * @type {string}
     * @memberof AppPosition
     */
    'key': string;
    /**
     * ローカライズされた売買区分の文字列
     * @type {string}
     * @memberof AppPosition
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppPositionOption
 */
export interface AppPositionOption {
    /**
     * 
     * @type {AppPosition}
     * @memberof AppPositionOption
     */
    'position': AppPosition;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppPositionOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AppProductType
 */
export interface AppProductType {
    /**
     * 商品区分のID
     * @type {string}
     * @memberof AppProductType
     */
    'id': string;
    /**
     * 商品区分
     * @type {string}
     * @memberof AppProductType
     */
    'key': string;
    /**
     * ローカライズされた商品区分の文字列
     * @type {string}
     * @memberof AppProductType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppProductTypeOption
 */
export interface AppProductTypeOption {
    /**
     * 
     * @type {AppProductType}
     * @memberof AppProductTypeOption
     */
    'productType': AppProductType;
    /**
     * そのチェックボックスが選択状態にあるかどうか、Falseの場合選択できないに加え既にチェックが入っていた場合はチェックを強制的に外す
     * @type {boolean}
     * @memberof AppProductTypeOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AppProductTypeOption
     */
    'isSelected': boolean;
}
/**
 * ユーザーの種類
 * @export
 * @enum {string}
 */

export const AppUserType = {
    Trader: 'trader',
    Broker: 'broker',
    SystemAdmin: 'system-admin'
} as const;

export type AppUserType = typeof AppUserType[keyof typeof AppUserType];


/**
 * 
 * @export
 * @interface AppValidatableValue
 */
export interface AppValidatableValue {
    /**
     * 値
     * @type {string}
     * @memberof AppValidatableValue
     */
    'value': string | null;
    /**
     * バリデーションエラーのメッセージ。エラーなしの場合はnull。
     * @type {string}
     * @memberof AppValidatableValue
     */
    'validationErrorMessage': string | null;
}
/**
 * 
 * @export
 * @interface AppWssChannelChanges
 */
export interface AppWssChannelChanges {
    /**
     * 
     * @type {AppChannelDetail}
     * @memberof AppWssChannelChanges
     */
    'channel': AppChannelDetail;
}
/**
 * 
 * @export
 * @interface AppWssIndication
 */
export interface AppWssIndication {
    /**
     * 表示するインジケータ。非表示の場合はnull。
     * @type {string}
     * @memberof AppWssIndication
     */
    'message': string | null;
}
/**
 * 
 * @export
 * @interface AppWssNewMessages
 */
export interface AppWssNewMessages {
    /**
     * 新着メッセージ。時系列降順。
     * @type {Array<AppMessage>}
     * @memberof AppWssNewMessages
     */
    'messages': Array<AppMessage>;
}
/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * エリアID
     * @type {string}
     * @memberof Area
     */
    'areaId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof Area
     */
    'name': MultiLocaleName;
    /**
     * エリアがスプレッドかどうか
     * @type {boolean}
     * @memberof Area
     */
    'isSpread': boolean;
    /**
     * 表示の優先順位
     * @type {number}
     * @memberof Area
     */
    'displayPriority': number;
    /**
     * デフォルトのカレンダー区分ID
     * @type {string}
     * @memberof Area
     */
    'defaultCalendarTypeId': string;
    /**
     * エリアの管理用コード
     * @type {number}
     * @memberof Area
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface AreaOption
 */
export interface AreaOption {
    /**
     * 
     * @type {Area}
     * @memberof AreaOption
     */
    'area': Area;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof AreaOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof AreaOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface AssignedBrokerOfOrganization
 */
export interface AssignedBrokerOfOrganization {
    /**
     * 担当ブローカーのID
     * @type {string}
     * @memberof AssignedBrokerOfOrganization
     */
    'id': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof AssignedBrokerOfOrganization
     */
    'organizationId': string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof AssignedBrokerOfOrganization
     */
    'brokerId': string;
    /**
     * 作成日
     * @type {string}
     * @memberof AssignedBrokerOfOrganization
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof AssignedBrokerOfOrganization
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface BalancingGroup
 */
export interface BalancingGroup {
    /**
     * BGのID
     * @type {string}
     * @memberof BalancingGroup
     */
    'id': string;
    /**
     * 組織のID
     * @type {string}
     * @memberof BalancingGroup
     */
    'organizationId': string;
    /**
     * エリアのID
     * @type {string}
     * @memberof BalancingGroup
     */
    'areaId': string;
    /**
     * BG名称
     * @type {string}
     * @memberof BalancingGroup
     */
    'name': string;
    /**
     * BGコード
     * @type {string}
     * @memberof BalancingGroup
     */
    'code': string;
    /**
     * 事業者コード
     * @type {string}
     * @memberof BalancingGroup
     */
    'companyPrefix': string;
    /**
     * 作成者名
     * @type {string}
     * @memberof BalancingGroup
     */
    'createdParticipantName'?: string;
    /**
     * 更新者名
     * @type {string}
     * @memberof BalancingGroup
     */
    'lastUpdatedParticipantName'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof BalancingGroup
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof BalancingGroup
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface BaseStandardIndicationSelectableInputs
 */
export interface BaseStandardIndicationSelectableInputs {
    /**
     * 売買区分一覧
     * @type {Array<PositionTypeOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'positions': Array<PositionTypeOption>;
    /**
     * 受給単位一覧
     * @type {Array<DeliveryUnitOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'deliveryUnits': Array<DeliveryUnitOption>;
    /**
     * 選択可能な商品区分一覧
     * @type {Array<ProductTypeOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'productTypes': Array<ProductTypeOption>;
    /**
     * 選択可能なエリア一覧
     * @type {Array<AreaOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'areas': Array<AreaOption>;
    /**
     * 選択可能な時間帯区分一覧
     * @type {Array<HourTypeOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'hourTypes': Array<HourTypeOption>;
    /**
     * 選択可能な開始日一覧
     * @type {Array<DateOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'deliveryStartDates'?: Array<DateOption>;
    /**
     * 選択可能な終了日一覧
     * @type {Array<DateOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'deliveryEndDates'?: Array<DateOption>;
    /**
     * 選択可能な開始月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'deliveryStartYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 選択可能な終了月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'deliveryEndYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 交換側の選択可能なエリア一覧
     * @type {Array<AreaOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeAreas'?: Array<AreaOption>;
    /**
     * 交換側の選択可能な時間帯区分一覧
     * @type {Array<HourTypeOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeHourTypes'?: Array<HourTypeOption>;
    /**
     * 交換側の選択可能な開始日一覧
     * @type {Array<DateOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeDeliveryStartDates'?: Array<DateOption>;
    /**
     * 交換側の選択可能な終了日一覧
     * @type {Array<DateOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeDeliveryEndDates'?: Array<DateOption>;
    /**
     * 交換側の選択可能な開始月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeDeliveryStartYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 交換側の選択可能な終了月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeDeliveryEndYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 選択可能な燃調区分一覧、燃調ありを選択している場合のみ返却される
     * @type {Array<FuelSurchargeTypeOption>}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'fuelSurchargeTypes': Array<FuelSurchargeTypeOption>;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'unitPrice': ValidatableValue;
    /**
     * 
     * @type {ValidatablePrice}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'price'?: ValidatablePrice;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'volume': ValidatableValue;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'exchangeVolume'?: ValidatableValue;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'request': string;
    /**
     * 注文についての備考
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'publicInformation'?: string;
    /**
     * エリアスプレッドを入力中かどうか
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'isAreaSpread': boolean;
    /**
     * 期間スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'isDeliveryPeriodSpread': boolean;
    /**
     * 時間帯スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'isDeliveryPatternSpread': boolean;
    /**
     * 入力内容が有効かどうか。有効であれば注文の作成や変更が実行できる
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'isValid': boolean;
    /**
     * 呪文のバリデーションエラーのメッセージ。エラーなしの場合はundefined
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputs
     */
    'spellValidationErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface BaseStandardIndicationSelectableInputsRequest
 */
export interface BaseStandardIndicationSelectableInputsRequest {
    /**
     * 注文ID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'indicationId'?: string;
    /**
     * 受給条件ID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'deliveryTermsId'?: string;
    /**
     * 交換側の受給条件ID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryTermsId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'position'?: PositionType;
    /**
     * ユーザーが入力している商品区分のID
     * @type {Array<string>}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'productTypeIds'?: Array<string>;
    /**
     * ユーザーが入力しているエリアのID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'areaId'?: string;
    /**
     * ユーザーが入力している時間帯区分のID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'hourTypeId'?: string;
    /**
     * ユーザーが入力している開始日 yyyy-MM-dd
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'deliveryStartDate'?: string;
    /**
     * ユーザーが入力している終了日 yyyy-MMdd
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'deliveryEndDate'?: string;
    /**
     * ユーザーが入力している交換側のエリアのID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'exchangeAreaId'?: string;
    /**
     * ユーザーが入力している交換側の時間帯区分のID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'exchangeHourTypeId'?: string;
    /**
     * ユーザーが入力している交換側の開始日 yyyy-MM-dd
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryStartDate'?: string;
    /**
     * ユーザーが入力している交換側の終了日 yyyy-MMdd
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryEndDate'?: string;
    /**
     * ユーザーが入力している燃調区分のID
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'deliveryUnit'?: DeliveryUnit;
    /**
     * 注文単価
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'unitPrice'?: string;
    /**
     * 
     * @type {SelectedPrice}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'price'?: SelectedPrice;
    /**
     * 注文数量
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'volume'?: string;
    /**
     * 交換側の注文数量
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'exchangeVolume'?: string;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'request'?: string;
    /**
     * 注文についての備考
     * @type {string}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'publicInformation'?: string;
    /**
     * エリアスプレッドを入力中かどうか
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'isAreaSpread'?: boolean;
    /**
     * 期間スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'isDeliveryPeriodSpread'?: boolean;
    /**
     * 時間帯スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof BaseStandardIndicationSelectableInputsRequest
     */
    'isDeliveryPatternSpread'?: boolean;
}
/**
 * 
 * @export
 * @interface BaseValidatedNewDailyPowerMarketIndication
 */
export interface BaseValidatedNewDailyPowerMarketIndication {
    /**
     * 
     * @type {PositionType}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {string}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'deliveryPeriod': string;
    /**
     * 
     * @type {string}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'hourType': string;
    /**
     * 
     * @type {string}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'volume': string;
    /**
     * 
     * @type {string}
     * @memberof BaseValidatedNewDailyPowerMarketIndication
     */
    'unitPrice': string;
}
/**
 * 
 * @export
 * @interface BasicUnitPrice
 */
export interface BasicUnitPrice {
    /**
     * 
     * @type {BasicUnitPriceType}
     * @memberof BasicUnitPrice
     */
    'type': BasicUnitPriceType;
    /**
     * 基本料金単価
     * @type {number}
     * @memberof BasicUnitPrice
     */
    'unitPrice': number;
}
/**
 * 基本料金単価のタイプ
 * @export
 * @enum {string}
 */

export const BasicUnitPriceType = {
    Kw: 'jpy-per-kw',
    Month: 'jpy-per-month'
} as const;

export type BasicUnitPriceType = typeof BasicUnitPriceType[keyof typeof BasicUnitPriceType];


/**
 * 
 * @export
 * @interface BrokerageFeePaymentConfiguration
 */
export interface BrokerageFeePaymentConfiguration {
    /**
     * 支払方法のID
     * @type {string}
     * @memberof BrokerageFeePaymentConfiguration
     */
    'id': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof BrokerageFeePaymentConfiguration
     */
    'organizationId': string;
    /**
     * 
     * @type {BrokerageFeePaymentTiming}
     * @memberof BrokerageFeePaymentConfiguration
     */
    'spotTrading'?: BrokerageFeePaymentTiming;
    /**
     * 
     * @type {BrokerageFeePaymentTiming}
     * @memberof BrokerageFeePaymentConfiguration
     */
    'derivativeTrading'?: BrokerageFeePaymentTiming;
    /**
     * 作成日
     * @type {string}
     * @memberof BrokerageFeePaymentConfiguration
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof BrokerageFeePaymentConfiguration
     */
    'updatedAt': string;
}
/**
 * 支払タイミング
 * @export
 * @enum {string}
 */

export const BrokerageFeePaymentTiming = {
    DeliveryMonth: 'delivery-month',
    ContractMonth: 'contract-month'
} as const;

export type BrokerageFeePaymentTiming = typeof BrokerageFeePaymentTiming[keyof typeof BrokerageFeePaymentTiming];


/**
 * 
 * @export
 * @interface BrokingDeal
 */
export interface BrokingDeal {
    /**
     * 約定ID
     * @type {string}
     * @memberof BrokingDeal
     */
    'dealId': string;
    /**
     * 
     * @type {Product}
     * @memberof BrokingDeal
     */
    'product': Product;
    /**
     * 
     * @type {Price}
     * @memberof BrokingDeal
     */
    'price': Price;
    /**
     * 単価
     * @type {number}
     * @memberof BrokingDeal
     * @deprecated
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof BrokingDeal
     */
    'volume': number;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof BrokingDeal
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * P&Cかどうか
     * @type {boolean}
     * @memberof BrokingDeal
     */
    'privateConfidential': boolean;
    /**
     * 売り手の組織ID
     * @type {string}
     * @memberof BrokingDeal
     */
    'sellerOrganizationId'?: string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof BrokingDeal
     */
    'sellerAccountName': MultiLocaleName;
    /**
     * 買い手の組織ID
     * @type {string}
     * @memberof BrokingDeal
     */
    'buyerOrganizationId'?: string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof BrokingDeal
     */
    'buyerAccountName': MultiLocaleName;
    /**
     * Salesforceの約定ID
     * @type {string}
     * @memberof BrokingDeal
     */
    'externalDealId'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof BrokingDeal
     */
    'createdAt': string;
    /**
     * マーケットで表示する際に、実際の約定の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof BrokingDeal
     */
    'maskVolume': boolean;
}
/**
 * 
 * @export
 * @interface BrokingIndication
 */
export interface BrokingIndication {
    /**
     * 注文ID
     * @type {string}
     * @memberof BrokingIndication
     */
    'indicationId': string;
    /**
     * 
     * @type {PublicOrganizationProfile}
     * @memberof BrokingIndication
     */
    'publicOrganizationProfile': PublicOrganizationProfile;
    /**
     * 商品のリスト
     * @type {Array<Product>}
     * @memberof BrokingIndication
     */
    'products': Array<Product>;
    /**
     * 
     * @type {PositionType}
     * @memberof BrokingIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {Price}
     * @memberof BrokingIndication
     */
    'price': Price;
    /**
     * 単価
     * @type {number}
     * @memberof BrokingIndication
     * @deprecated
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof BrokingIndication
     */
    'volume': number | null;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof BrokingIndication
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof BrokingIndication
     */
    'status': IndicationStatusType;
    /**
     * 要望事項
     * @type {string}
     * @memberof BrokingIndication
     */
    'request'?: string;
    /**
     * informationアイコンでの表示内容
     * @type {string}
     * @memberof BrokingIndication
     */
    'publicInformation'?: string;
    /**
     * 注文をマーケットに公開するかどうか
     * @type {boolean}
     * @memberof BrokingIndication
     */
    'openToMarket': boolean;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof BrokingIndication
     */
    'isVisibleToTraders': boolean;
    /**
     * Salesforceの注文ID
     * @type {string}
     * @memberof BrokingIndication
     */
    'externalIndicationId'?: string;
    /**
     * 注文作成者
     * @type {string}
     * @memberof BrokingIndication
     */
    'createdParticipantId': string;
    /**
     * 直近の更新者
     * @type {string}
     * @memberof BrokingIndication
     */
    'lastUpdatedParticipantId': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof BrokingIndication
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof BrokingIndication
     */
    'updatedAt': string;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof BrokingIndication
     */
    'maskVolume': boolean;
    /**
     * DailyPowerMarket市場の注文かどうか
     * @type {boolean}
     * @memberof BrokingIndication
     */
    'isDailyPowerMarket': boolean;
}
/**
 * 
 * @export
 * @interface BrokingOrder
 */
export interface BrokingOrder {
    /**
     * 注文のユニークID
     * @type {string}
     * @memberof BrokingOrder
     */
    'indicationId'?: string;
    /**
     * 約定のユニークID
     * @type {string}
     * @memberof BrokingOrder
     */
    'dealId'?: string;
    /**
     * 
     * @type {OrderOrderType}
     * @memberof BrokingOrder
     */
    'orderType': OrderOrderType;
    /**
     * 
     * @type {OrderProductType}
     * @memberof BrokingOrder
     */
    'productType': OrderProductType;
    /**
     * 
     * @type {OrderFuelSurchargeType}
     * @memberof BrokingOrder
     */
    'fuelSurchargeType'?: OrderFuelSurchargeType;
    /**
     * 組織名
     * @type {string}
     * @memberof BrokingOrder
     */
    'organizationName': string;
    /**
     * 組織名の略称
     * @type {string}
     * @memberof BrokingOrder
     */
    'organizationAbbreviation'?: string;
    /**
     * 作成者名
     * @type {string}
     * @memberof BrokingOrder
     */
    'createdParticipantName': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof BrokingOrder
     */
    'createdAt': string;
    /**
     * 最終更新者名
     * @type {string}
     * @memberof BrokingOrder
     */
    'lastUpdatedParticipantName': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof BrokingOrder
     */
    'updatedAt': string;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof BrokingOrder
     */
    'status'?: IndicationStatusType;
    /**
     * 単価
     * @type {string}
     * @memberof BrokingOrder
     */
    'unitPrice': string;
    /**
     * 数量 未確定の場合は \"TBD\"
     * @type {string}
     * @memberof BrokingOrder
     */
    'volume': string;
    /**
     * 交換側の数量 未確定の場合は \"TBD\"
     * @type {string}
     * @memberof BrokingOrder
     */
    'exchangeVolume'?: string;
    /**
     * 約定日
     * @type {string}
     * @memberof BrokingOrder
     */
    'executedAt'?: string;
    /**
     * 注文をマーケットに公開するかどうか
     * @type {boolean}
     * @memberof BrokingOrder
     */
    'openToMarket'?: boolean;
    /**
     * 公開フラグ 注文が履歴に表示されるか
     * @type {boolean}
     * @memberof BrokingOrder
     */
    'isVisibleToTraders'?: boolean;
    /**
     * 約定データがP&Cかどうか
     * @type {boolean}
     * @memberof BrokingOrder
     */
    'dealPrivateConfidential'?: boolean;
}
/**
 * 
 * @export
 * @interface BrokingOrderBook
 */
export interface BrokingOrderBook {
    /**
     * 受給条件ID
     * @type {string}
     * @memberof BrokingOrderBook
     */
    'deliveryTermsId': string;
    /**
     * 交換側の受給条件ID
     * @type {string}
     * @memberof BrokingOrderBook
     */
    'exchangeDeliveryTermsId'?: string;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof BrokingOrderBook
     */
    'deliveryUnit': DeliveryUnit;
    /**
     * BestAskの注文単価
     * @type {string}
     * @memberof BrokingOrderBook
     */
    'askUnitPrice'?: string;
    /**
     * BestBidの注文単価
     * @type {string}
     * @memberof BrokingOrderBook
     */
    'bidUnitPrice'?: string;
    /**
     * 直近約定の注文単価
     * @type {string}
     * @memberof BrokingOrderBook
     */
    'lastUnitPrice'?: string;
    /**
     * 直近約定がP&Cかどうか
     * @type {boolean}
     * @memberof BrokingOrderBook
     */
    'lastDealPrivateConfidential'?: boolean;
    /**
     * 
     * @type {SpreadDeliveryTerms}
     * @memberof BrokingOrderBook
     */
    'base': SpreadDeliveryTerms;
    /**
     * 
     * @type {SpreadDeliveryTerms}
     * @memberof BrokingOrderBook
     */
    'exchange'?: SpreadDeliveryTerms;
    /**
     * 注文のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof BrokingOrderBook
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 直近約定の約定日
     * @type {string}
     * @memberof BrokingOrderBook
     */
    'executedAt'?: string;
    /**
     * 板の注文一覧 価格の降順にソートされたもの
     * @type {Array<BrokingOrder>}
     * @memberof BrokingOrderBook
     */
    'orders': Array<BrokingOrder>;
}
/**
 * 
 * @export
 * @interface CalendarType
 */
export interface CalendarType {
    /**
     * カレンダータイプID
     * @type {string}
     * @memberof CalendarType
     */
    'id': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof CalendarType
     */
    'name': MultiLocaleName;
}
/**
 * 
 * @export
 * @interface ClearingInformation
 */
export interface ClearingInformation {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClearingInformation
     */
    'eexClearingFirmNames': Array<string>;
    /**
     * 
     * @type {Array<TOCOMClearingInformationItem>}
     * @memberof ClearingInformation
     */
    'tocomClearingInformation': Array<TOCOMClearingInformationItem>;
    /**
     * 
     * @type {ClearingInformationParticipantSummary}
     * @memberof ClearingInformation
     */
    'participantSummary': ClearingInformationParticipantSummary;
}
/**
 * 複数のクリアリング情報がある場合、更新日時が最新のものを返す
 * @export
 * @interface ClearingInformationParticipantSummary
 */
export interface ClearingInformationParticipantSummary {
    /**
     * 
     * @type {ClearingInformationParticipantSummaryItem}
     * @memberof ClearingInformationParticipantSummary
     */
    'eexClearingInformation': ClearingInformationParticipantSummaryItem;
    /**
     * 
     * @type {ClearingInformationParticipantSummaryItem}
     * @memberof ClearingInformationParticipantSummary
     */
    'tocomClearingInformation': ClearingInformationParticipantSummaryItem;
}
/**
 * 
 * @export
 * @interface ClearingInformationParticipantSummaryItem
 */
export interface ClearingInformationParticipantSummaryItem {
    /**
     * 更新日
     * @type {string}
     * @memberof ClearingInformationParticipantSummaryItem
     */
    'updatedAt'?: string;
    /**
     * 更新者名
     * @type {string}
     * @memberof ClearingInformationParticipantSummaryItem
     */
    'lastUpdatedParticipantName'?: string;
}
/**
 * 
 * @export
 * @interface ContractCondition
 */
export interface ContractCondition {
    /**
     * 識別用UUID
     * @type {string}
     * @memberof ContractCondition
     */
    'id': string;
    /**
     * 約定ID
     * @type {string}
     * @memberof ContractCondition
     */
    'dealId': string;
    /**
     * 制約事項
     * @type {string}
     * @memberof ContractCondition
     */
    'restrictions'?: string;
    /**
     * 特別条項
     * @type {string}
     * @memberof ContractCondition
     */
    'remarks'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ContractCondition
     */
    'note'?: string;
    /**
     * 支払条件説明
     * @type {string}
     * @memberof ContractCondition
     */
    'paymentDueInformation'?: string;
    /**
     * 精算参照単価エリア
     * @type {string}
     * @memberof ContractCondition
     */
    'settlementUnitPriceAreaId'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateAssignedBrokerOfOrganization
 */
export interface CreateOrUpdateAssignedBrokerOfOrganization {
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof CreateOrUpdateAssignedBrokerOfOrganization
     */
    'brokerId': string;
}
/**
 * 
 * @export
 * @interface CreditSleever
 */
export interface CreditSleever {
    /**
     * クレジットスリーバーID
     * @type {string}
     * @memberof CreditSleever
     */
    'id': string;
    /**
     * クレジットスリーバーの組織ID
     * @type {string}
     * @memberof CreditSleever
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof CreditSleever
     */
    'organizationName': MultiLocaleName;
    /**
     * クレジットスリーブの値差(これまで手数料と呼んでいたもの)
     * @type {number}
     * @memberof CreditSleever
     */
    'sleeveSpread': number;
    /**
     * 売り側のクレジットスリーブ仲介手数料
     * @type {number}
     * @memberof CreditSleever
     */
    'askBrokingFee': number;
    /**
     * 買い側のクレジットスリーブ仲介手数料
     * @type {number}
     * @memberof CreditSleever
     */
    'bidBrokingFee': number;
    /**
     * 
     * @type {EEXPostingInformationOfNegotiation}
     * @memberof CreditSleever
     */
    'eexPostingInformation'?: EEXPostingInformationOfNegotiation;
}
/**
 * 
 * @export
 * @interface DailyPowerMarketStatus
 */
export interface DailyPowerMarketStatus {
    /**
     * 
     * @type {DailyPowerMarketStatusType}
     * @memberof DailyPowerMarketStatus
     */
    'status': DailyPowerMarketStatusType;
}
/**
 * DailyPowerMarket市場のステータス
 * @export
 * @enum {string}
 */

export const DailyPowerMarketStatusType = {
    Open: 'open',
    Close: 'close'
} as const;

export type DailyPowerMarketStatusType = typeof DailyPowerMarketStatusType[keyof typeof DailyPowerMarketStatusType];


/**
 * 選択可能な日付一覧
 * @export
 * @interface DateOption
 */
export interface DateOption {
    /**
     * 
     * @type {string}
     * @memberof DateOption
     */
    'date': string;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof DateOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof DateOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface DayPattern
 */
export interface DayPattern {
    /**
     * 識別用UUID
     * @type {string}
     * @memberof DayPattern
     */
    'id': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof DayPattern
     */
    'name': MultiLocaleName;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof DayPattern
     */
    'excludedDayInfo'?: MultiLocaleName;
}
/**
 * 
 * @export
 * @interface Deal
 */
export interface Deal {
    /**
     * 約定ID
     * @type {string}
     * @memberof Deal
     */
    'id': string;
    /**
     * 交渉ID
     * @type {string}
     * @memberof Deal
     */
    'negotiationId': string;
    /**
     * 帳票に出力するID
     * @type {string}
     * @memberof Deal
     */
    'publicNegotiationId': string;
    /**
     * 
     * @type {DealSubType}
     * @memberof Deal
     */
    'type': DealSubType;
    /**
     * 約定のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof Deal
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 
     * @type {Product}
     * @memberof Deal
     */
    'product': Product;
    /**
     * 
     * @type {Price}
     * @memberof Deal
     */
    'price': Price;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof Deal
     * @deprecated
     */
    'unitPrices': Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof Deal
     */
    'volumes': Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof Deal
     */
    'exchangeVolumes'?: Array<number>;
    /**
     * スプレッド基準単価
     * @type {number}
     * @memberof Deal
     */
    'spreadBasePrice'?: number;
    /**
     * 約定日
     * @type {string}
     * @memberof Deal
     */
    'executedAt': string;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof Deal
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 約定がP&Cであるか
     * @type {boolean}
     * @memberof Deal
     */
    'privateConfidential': boolean;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof Deal
     */
    'excludedEnecCurve': boolean;
    /**
     * 
     * @type {DealDetail}
     * @memberof Deal
     */
    'askDealDetail': DealDetail;
    /**
     * 
     * @type {DealDetail}
     * @memberof Deal
     */
    'bidDealDetail': DealDetail;
    /**
     * マーケットで表示する際に、実際の約定の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof Deal
     */
    'maskVolume': boolean;
}
/**
 * 
 * @export
 * @interface DealDetail
 */
export interface DealDetail {
    /**
     * 識別用UUID
     * @type {string}
     * @memberof DealDetail
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof DealDetail
     */
    'organizationName': MultiLocaleName;
    /**
     * 組織略称名
     * @type {string}
     * @memberof DealDetail
     */
    'organizationAbbreviation'?: string;
    /**
     * trueの時、クレジットスリーバー
     * @type {boolean}
     * @memberof DealDetail
     */
    'isCreditSleeve': boolean;
}
/**
 * 約定のサブタイプ
 * @export
 * @enum {string}
 */

export const DealSubType = {
    Normal: 'normal',
    Spread: 'spread'
} as const;

export type DealSubType = typeof DealSubType[keyof typeof DealSubType];


/**
 * 
 * @export
 * @interface DealSummary
 */
export interface DealSummary {
    /**
     * 識別用UUID
     * @type {string}
     * @memberof DealSummary
     */
    'id': string;
    /**
     * 
     * @type {Product}
     * @memberof DealSummary
     */
    'product': Product;
    /**
     * 交渉ID
     * @type {string}
     * @memberof DealSummary
     */
    'negotiationId': string;
    /**
     * 帳票に出力するID
     * @type {string}
     * @memberof DealSummary
     */
    'publicNegotiationId': string;
    /**
     * 
     * @type {DealSubType}
     * @memberof DealSummary
     */
    'type': DealSubType;
    /**
     * 約定のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof DealSummary
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof DealSummary
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 約定日
     * @type {string}
     * @memberof DealSummary
     */
    'executedAt': string;
    /**
     * 
     * @type {DealDetail}
     * @memberof DealSummary
     */
    'askDealDetail': DealDetail;
    /**
     * 
     * @type {DealDetail}
     * @memberof DealSummary
     */
    'bidDealDetail': DealDetail;
    /**
     * P&C
     * @type {boolean}
     * @memberof DealSummary
     */
    'isPrivateConfidential': boolean;
    /**
     * 仲介高合計
     * @type {number}
     * @memberof DealSummary
     */
    'totalBrokingFee': number;
    /**
     * 
     * @type {Price}
     * @memberof DealSummary
     */
    'price': Price;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof DealSummary
     */
    'volumes': Array<number>;
    /**
     * 交換側の数量
     * @type {Array<number>}
     * @memberof DealSummary
     */
    'exchangeVolumes'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DealWithOrganizationRecap
 */
export interface DealWithOrganizationRecap {
    /**
     * 
     * @type {Deal}
     * @memberof DealWithOrganizationRecap
     */
    'deal': Deal;
    /**
     * 
     * @type {ContractCondition}
     * @memberof DealWithOrganizationRecap
     */
    'contractCondition': ContractCondition;
    /**
     * 
     * @type {OrganizationRecap}
     * @memberof DealWithOrganizationRecap
     */
    'recapOfAskOrganization': OrganizationRecap;
    /**
     * 
     * @type {OrganizationRecap}
     * @memberof DealWithOrganizationRecap
     */
    'recapOfBidOrganization': OrganizationRecap;
    /**
     * 
     * @type {OrganizationRecap}
     * @memberof DealWithOrganizationRecap
     */
    'recapOfExchangeAskOrganization'?: OrganizationRecap;
    /**
     * 
     * @type {OrganizationRecap}
     * @memberof DealWithOrganizationRecap
     */
    'recapOfExchangeBidOrganization'?: OrganizationRecap;
    /**
     * 約定と確認の作成者名
     * @type {string}
     * @memberof DealWithOrganizationRecap
     */
    'createdParticipantName': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof DealWithOrganizationRecap
     */
    'createdAt': string;
    /**
     * 約定と確認の更新者名
     * @type {string}
     * @memberof DealWithOrganizationRecap
     */
    'lastUpdatedParticipantName': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof DealWithOrganizationRecap
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface DefaultBrokingFeeOfOrganization
 */
export interface DefaultBrokingFeeOfOrganization {
    /**
     * 規程仲介手数料ID
     * @type {string}
     * @memberof DefaultBrokingFeeOfOrganization
     */
    'id': string;
    /**
     * 商品区分ID
     * @type {string}
     * @memberof DefaultBrokingFeeOfOrganization
     */
    'productTypeId': string;
    /**
     * 仲介手数料
     * @type {number}
     * @memberof DefaultBrokingFeeOfOrganization
     */
    'brokingFee': number;
}
/**
 * 
 * @export
 * @interface DefaultBrokingFeeOfOrganizations
 */
export interface DefaultBrokingFeeOfOrganizations {
    /**
     * 商品区分ごとの規程仲介手数料
     * @type {Array<DefaultBrokingFeeOfOrganization>}
     * @memberof DefaultBrokingFeeOfOrganizations
     */
    'defaultBrokingFees': Array<DefaultBrokingFeeOfOrganization>;
    /**
     * 最終更新者
     * @type {string}
     * @memberof DefaultBrokingFeeOfOrganizations
     */
    'lastUpdatedParticipantName'?: string;
    /**
     * 最終更新日時
     * @type {string}
     * @memberof DefaultBrokingFeeOfOrganizations
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface DefaultBrokingFees
 */
export interface DefaultBrokingFees {
    /**
     * 商品区分ID
     * @type {string}
     * @memberof DefaultBrokingFees
     */
    'productTypeId': string;
    /**
     * 仲介手数料
     * @type {number}
     * @memberof DefaultBrokingFees
     */
    'brokingFee': number;
}
/**
 * 
 * @export
 * @interface DeliveryPattern
 */
export interface DeliveryPattern {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPattern
     */
    'id': string;
    /**
     * 
     * @type {Array<DeliveryPatternComponent>}
     * @memberof DeliveryPattern
     */
    'deliveryPatternComponents': Array<DeliveryPatternComponent>;
    /**
     * 
     * @type {HourType}
     * @memberof DeliveryPattern
     */
    'hourType'?: HourType;
}
/**
 * 
 * @export
 * @interface DeliveryPatternComponent
 */
export interface DeliveryPatternComponent {
    /**
     * 
     * @type {DayPattern}
     * @memberof DeliveryPatternComponent
     */
    'dayPattern': DayPattern;
    /**
     * 
     * @type {TimeRange}
     * @memberof DeliveryPatternComponent
     */
    'timeRange': TimeRange;
}
/**
 * 
 * @export
 * @interface DeliveryPeriod
 */
export interface DeliveryPeriod {
    /**
     * 
     * @type {DeliveryPattern}
     * @memberof DeliveryPeriod
     */
    'deliveryPattern': DeliveryPattern;
    /**
     * 受給開始日
     * @type {string}
     * @memberof DeliveryPeriod
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof DeliveryPeriod
     */
    'deliveryEndDate': string;
    /**
     * フォーマット済みの受給期間。年および月が終了日のものと同じ場合には省略される。週番号がある場合は週番号のみを返却する。
     * @type {string}
     * @memberof DeliveryPeriod
     */
    'deliveryPeriod': string;
    /**
     * 週番号など、期間の別名のラベル
     * @type {string}
     * @memberof DeliveryPeriod
     */
    'dateName'?: string;
}
/**
 * 
 * @export
 * @interface DeliverySummaries
 */
export interface DeliverySummaries {
    /**
     * 
     * @type {DeliverySummaryWithCalendarTypeName}
     * @memberof DeliverySummaries
     */
    'base': DeliverySummaryWithCalendarTypeName;
    /**
     * 
     * @type {DeliverySummaryWithCalendarTypeName}
     * @memberof DeliverySummaries
     */
    'exchange'?: DeliverySummaryWithCalendarTypeName;
}
/**
 * 
 * @export
 * @interface DeliverySummary
 */
export interface DeliverySummary {
    /**
     * 総受給時間
     * @type {number}
     * @memberof DeliverySummary
     */
    'totalDeliveryHours': number;
    /**
     * 総受給電力量
     * @type {number}
     * @memberof DeliverySummary
     */
    'totalDeliveryVolumes'?: number;
    /**
     * 平日受給日数
     * @type {number}
     * @memberof DeliverySummary
     */
    'totalDeliveryWeekdays': number;
    /**
     * 休日受給日数
     * @type {number}
     * @memberof DeliverySummary
     */
    'totalDeliveryHolidays': number;
    /**
     * 加重平均数量
     * @type {number}
     * @memberof DeliverySummary
     */
    'averageVolumes'?: number;
    /**
     * 加重平均単価
     * @type {number}
     * @memberof DeliverySummary
     */
    'averageUnitPrices'?: number;
    /**
     * 受給開始日
     * @type {string}
     * @memberof DeliverySummary
     */
    'deliveryStartDate'?: string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof DeliverySummary
     */
    'deliveryEndDate'?: string;
}
/**
 * 
 * @export
 * @interface DeliverySummaryWithCalendarTypeName
 */
export interface DeliverySummaryWithCalendarTypeName {
    /**
     * 
     * @type {DeliverySummary}
     * @memberof DeliverySummaryWithCalendarTypeName
     */
    'deliverySummary': DeliverySummary;
    /**
     * カレンダー区分の名称
     * @type {string}
     * @memberof DeliverySummaryWithCalendarTypeName
     */
    'calendarTypeName': string;
}
/**
 * 
 * @export
 * @interface DeliveryTerms
 */
export interface DeliveryTerms {
    /**
     * 識別用UUID
     * @type {string}
     * @memberof DeliveryTerms
     */
    'id': string;
    /**
     * 
     * @type {Area}
     * @memberof DeliveryTerms
     */
    'area': Area;
    /**
     * 受給期間
     * @type {Array<DeliveryPeriod>}
     * @memberof DeliveryTerms
     */
    'deliveryPeriods': Array<DeliveryPeriod>;
}
/**
 * 受給単位
 * @export
 * @enum {string}
 */

export const DeliveryUnit = {
    Month: 'month',
    WeekOrDay: 'weekOrDay',
    NonStandard: 'nonStandard'
} as const;

export type DeliveryUnit = typeof DeliveryUnit[keyof typeof DeliveryUnit];


/**
 * 
 * @export
 * @interface DeliveryUnitOption
 */
export interface DeliveryUnitOption {
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof DeliveryUnitOption
     */
    'deliveryUnit': DeliveryUnit;
    /**
     * ローカライズされた受給単位の文字列
     * @type {string}
     * @memberof DeliveryUnitOption
     */
    'name': string;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof DeliveryUnitOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof DeliveryUnitOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface DeliveryYearMonth
 */
export interface DeliveryYearMonth {
    /**
     * デリバリー年月ID
     * @type {string}
     * @memberof DeliveryYearMonth
     */
    'deliveryYearMonthId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof DeliveryYearMonth
     */
    'name': MultiLocaleName;
    /**
     * 年
     * @type {number}
     * @memberof DeliveryYearMonth
     */
    'year': number;
    /**
     * 月
     * @type {number}
     * @memberof DeliveryYearMonth
     */
    'month': number;
    /**
     * 開始年月日
     * @type {string}
     * @memberof DeliveryYearMonth
     */
    'startDate': string;
    /**
     * 終了年月日
     * @type {string}
     * @memberof DeliveryYearMonth
     */
    'endDate': string;
    /**
     * 表示の優先順位
     * @type {number}
     * @memberof DeliveryYearMonth
     */
    'displayPriority': number;
}
/**
 * 
 * @export
 * @interface DeliveryYearMonthOption
 */
export interface DeliveryYearMonthOption {
    /**
     * 
     * @type {DeliveryYearMonth}
     * @memberof DeliveryYearMonthOption
     */
    'deliveryYearMonth': DeliveryYearMonth;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof DeliveryYearMonthOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof DeliveryYearMonthOption
     */
    'isSelected': boolean;
}
/**
 * システムのみ変更できる項目
 * @export
 * @interface EClearAppSetting
 */
export interface EClearAppSetting {
    /**
     * eClearが利用可能か？
     * @type {boolean}
     * @memberof EClearAppSetting
     */
    'enabled': boolean;
    /**
     * eClearのトレーダーか?
     * @type {boolean}
     * @memberof EClearAppSetting
     */
    'isTrader'?: boolean;
    /**
     * 受給担当者か?
     * @type {boolean}
     * @memberof EClearAppSetting
     */
    'isDemandAndSupplyPlanner'?: boolean;
    /**
     * トレーダーID
     * @type {string}
     * @memberof EClearAppSetting
     */
    'traderId'?: string;
}
/**
 * 
 * @export
 * @interface EClearCalendarDate
 */
export interface EClearCalendarDate {
    /**
     * 日付
     * @type {string}
     * @memberof EClearCalendarDate
     */
    'date': string;
    /**
     * 
     * @type {EClearDay}
     * @memberof EClearCalendarDate
     */
    'day': EClearDay;
    /**
     * 祝日かどうか
     * @type {boolean}
     * @memberof EClearCalendarDate
     */
    'isPublicHoliday': boolean;
}
/**
 * 曜日タイプ
 * @export
 * @enum {string}
 */

export const EClearDay = {
    Weekday: 'weekday',
    Saturday: 'saturday',
    Sunday: 'sunday'
} as const;

export type EClearDay = typeof EClearDay[keyof typeof EClearDay];


/**
 * 
 * @export
 * @interface EClearDayPattern
 */
export interface EClearDayPattern {
    /**
     * 
     * @type {string}
     * @memberof EClearDayPattern
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EClearDayPattern
     */
    'name': string;
    /**
     * 
     * @type {Array<EClearDayType>}
     * @memberof EClearDayPattern
     */
    'days': Array<EClearDayType>;
}
/**
 * 
 * @export
 * @interface EClearDayType
 */
export interface EClearDayType {
    /**
     * 
     * @type {EClearDay}
     * @memberof EClearDayType
     */
    'day': EClearDay;
    /**
     * 
     * @type {boolean}
     * @memberof EClearDayType
     */
    'holiday': boolean;
}
/**
 * 
 * @export
 * @interface EClearDeliveryPattern
 */
export interface EClearDeliveryPattern {
    /**
     * 受給パターンID
     * @type {string}
     * @memberof EClearDeliveryPattern
     */
    'id': string;
    /**
     * 
     * @type {Array<EClearDeliveryPatternComponent>}
     * @memberof EClearDeliveryPattern
     */
    'deliveryPatternComponents': Array<EClearDeliveryPatternComponent>;
}
/**
 * 
 * @export
 * @interface EClearDeliveryPatternComponent
 */
export interface EClearDeliveryPatternComponent {
    /**
     * 
     * @type {EClearDayPattern}
     * @memberof EClearDeliveryPatternComponent
     */
    'dayPattern': EClearDayPattern;
    /**
     * 
     * @type {number}
     * @memberof EClearDeliveryPatternComponent
     */
    'startHour': number;
    /**
     * 
     * @type {number}
     * @memberof EClearDeliveryPatternComponent
     */
    'endHour': number;
}
/**
 * 
 * @export
 * @interface EClearDeliveryPeriod
 */
export interface EClearDeliveryPeriod {
    /**
     * 受給開始日
     * @type {string}
     * @memberof EClearDeliveryPeriod
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof EClearDeliveryPeriod
     */
    'deliveryEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof EClearDeliveryPeriod
     */
    'deliveryPatternId': string;
}
/**
 * ポジション
 * @export
 * @enum {string}
 */

export const EClearPositionType = {
    Ask: 'ask',
    Bid: 'bid'
} as const;

export type EClearPositionType = typeof EClearPositionType[keyof typeof EClearPositionType];


/**
 * 
 * @export
 * @interface EClearRecap
 */
export interface EClearRecap {
    /**
     * 確認のID
     * @type {string}
     * @memberof EClearRecap
     */
    'id': string;
    /**
     * 約定のID
     * @type {string}
     * @memberof EClearRecap
     */
    'dealId': string;
    /**
     * 交渉のID
     * @type {string}
     * @memberof EClearRecap
     */
    'negotiationId': string;
    /**
     * 
     * @type {Array<EClearDeliveryPeriod>}
     * @memberof EClearRecap
     */
    'deliveryPeriods': Array<EClearDeliveryPeriod>;
    /**
     * 約定日
     * @type {string}
     * @memberof EClearRecap
     */
    'executedAt': string;
    /**
     * 電力量
     * @type {Array<number>}
     * @memberof EClearRecap
     */
    'volumes': Array<number>;
    /**
     * 受給日のカレンダータイプ
     * @type {string}
     * @memberof EClearRecap
     */
    'calendarTypeId': string;
    /**
     * 
     * @type {EClearPositionType}
     * @memberof EClearRecap
     */
    'position': EClearPositionType;
    /**
     * BGコード/計画提出者コード
     * @type {string}
     * @memberof EClearRecap
     */
    'bgCode': string;
    /**
     * BG名称
     * @type {string}
     * @memberof EClearRecap
     */
    'bgName': string;
    /**
     * 組織の略称
     * @type {string}
     * @memberof EClearRecap
     */
    'bgAbbreviation': string;
}
/**
 * eClearにまつわる設定
 * @export
 * @interface EClearService
 */
export interface EClearService {
    /**
     * 
     * @type {EClearAppSetting}
     * @memberof EClearService
     */
    'appSetting': EClearAppSetting;
    /**
     * 
     * @type {EClearUserSetting}
     * @memberof EClearService
     */
    'userSetting'?: EClearUserSetting;
}
/**
 * ユーザー自身で変更できる項目
 * @export
 * @interface EClearUserSetting
 */
export interface EClearUserSetting {
    /**
     * 確認キー
     * @type {string}
     * @memberof EClearUserSetting
     */
    'keyword'?: string;
}
/**
 * eCompassにまつわる設定
 * @export
 * @interface ECompassService
 */
export interface ECompassService {
    /**
     * 
     * @type {ECompassServiceAppSetting}
     * @memberof ECompassService
     */
    'appSetting': ECompassServiceAppSetting;
}
/**
 * 
 * @export
 * @interface ECompassServiceAppSetting
 */
export interface ECompassServiceAppSetting {
    /**
     * 
     * @type {boolean}
     * @memberof ECompassServiceAppSetting
     */
    'enabled'?: boolean;
}
/**
 * EEXクリアリングアカウント
 * @export
 * @interface EEXClearingAccount
 */
export interface EEXClearingAccount {
    /**
     * EEXクリアリングアカウントコード
     * @type {string}
     * @memberof EEXClearingAccount
     */
    'eexClearingAccountCode': string;
    /**
     * リファレンス
     * @type {string}
     * @memberof EEXClearingAccount
     */
    'reference': string;
    /**
     * EEXの口座名称
     * @type {string}
     * @memberof EEXClearingAccount
     */
    'clearingFirm': string;
}
/**
 * 
 * @export
 * @interface EEXClearingHouseValue
 */
export interface EEXClearingHouseValue {
    /**
     * 名前
     * @type {string}
     * @memberof EEXClearingHouseValue
     */
    'name': string;
    /**
     * EEXの口座番号
     * @type {string}
     * @memberof EEXClearingHouseValue
     */
    'eexNumber'?: string;
}
/**
 * 
 * @export
 * @interface EEXPosting
 */
export interface EEXPosting {
    /**
     * EEXトレードID
     * @type {string}
     * @memberof EEXPosting
     */
    'id': string;
    /**
     * 商品コード
     * @type {string}
     * @memberof EEXPosting
     */
    'productCode': string;
    /**
     * 限月
     * @type {string}
     * @memberof EEXPosting
     */
    'instrument': string;
    /**
     * 数量
     * @type {string}
     * @memberof EEXPosting
     */
    'quantity': string;
    /**
     * 単価
     * @type {string}
     * @memberof EEXPosting
     */
    'price': string;
    /**
     * 
     * @type {EEXPostingDetail}
     * @memberof EEXPosting
     */
    'bidEEXPostingDetail': EEXPostingDetail;
    /**
     * 
     * @type {EEXPostingDetail}
     * @memberof EEXPosting
     */
    'askEEXPostingDetail': EEXPostingDetail;
    /**
     * 
     * @type {EEXPostingStatusType}
     * @memberof EEXPosting
     */
    'status': EEXPostingStatusType;
    /**
     * 更新日時
     * @type {string}
     * @memberof EEXPosting
     */
    'updatedAt': string;
}
/**
 * 新規作成用のEEXポスティング情報の詳細
 * @export
 * @interface EEXPostingDetail
 */
export interface EEXPostingDetail {
    /**
     * 組織ID
     * @type {string}
     * @memberof EEXPostingDetail
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof EEXPostingDetail
     */
    'organizationName': MultiLocaleName;
    /**
     * EEXトレーダーコード
     * @type {string}
     * @memberof EEXPostingDetail
     */
    'eexTraderCode': string;
    /**
     * EEXトレーダー名
     * @type {string}
     * @memberof EEXPostingDetail
     */
    'eexTraderName': string;
    /**
     * EEXクリアリングアカウントのコード
     * @type {string}
     * @memberof EEXPostingDetail
     */
    'eexClearingAccountCode': string;
    /**
     * EEXの口座名称
     * @type {string}
     * @memberof EEXPostingDetail
     */
    'clearingFirm'?: string;
}
/**
 * EEXポスティング情報
 * @export
 * @interface EEXPostingInformationOfNegotiation
 */
export interface EEXPostingInformationOfNegotiation {
    /**
     * EEXトレーダーのユーザーID
     * @type {string}
     * @memberof EEXPostingInformationOfNegotiation
     */
    'traderId'?: string;
    /**
     * EEXクリアリングアカウントコード
     * @type {string}
     * @memberof EEXPostingInformationOfNegotiation
     */
    'eexClearingAccountCode'?: string;
}
/**
 * EEXポスティングステータス
 * @export
 * @enum {string}
 */

export const EEXPostingStatusType = {
    Scheduled: 'scheduled',
    Processing: 'processing',
    Completed: 'completed',
    Canceled: 'canceled',
    Rejected: 'rejected',
    Invalid: 'invalid'
} as const;

export type EEXPostingStatusType = typeof EEXPostingStatusType[keyof typeof EEXPostingStatusType];


/**
 * 
 * @export
 * @interface EEXPostingsResponse
 */
export interface EEXPostingsResponse {
    /**
     * EEXポスティングデータ
     * @type {Array<EEXPosting>}
     * @memberof EEXPostingsResponse
     */
    'eexPostings': Array<EEXPosting>;
    /**
     * 総取引件数
     * @type {number}
     * @memberof EEXPostingsResponse
     */
    'totalCount': number;
    /**
     * 次のページのトークン。次のページがない場合はnull。
     * @type {string}
     * @memberof EEXPostingsResponse
     */
    'nextPageToken': string | null;
}
/**
 * ユーザーのEEXトレーダー情報
 * @export
 * @interface EEXTrader
 */
export interface EEXTrader {
    /**
     * EEXトレーダーコード
     * @type {string}
     * @memberof EEXTrader
     */
    'eexTraderCode': string;
    /**
     * EEXトレーダー名
     * @type {string}
     * @memberof EEXTrader
     */
    'eexTraderName'?: string;
}
/**
 * EEXトレーディングファーム
 * @export
 * @interface EEXTradingFirm
 */
export interface EEXTradingFirm {
    /**
     * EEXトレーディングファームコード
     * @type {string}
     * @memberof EEXTradingFirm
     */
    'eexTradingFirmCode': string;
    /**
     * 名前
     * @type {string}
     * @memberof EEXTradingFirm
     */
    'name': string;
}
/**
 * システムのみ変更できる項目
 * @export
 * @interface ESquareAppSetting
 */
export interface ESquareAppSetting {
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveIndicationEmail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveLiveMarketEmail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveDerivativeTradingRecapEmail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveSpotTradingRecapEmail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveReportEmail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveInvoiceEmail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareAppSetting
     */
    'receiveDailyDealSummaryEmail': boolean;
}
/**
 * eSquareにまつわる設定
 * @export
 * @interface ESquareService
 */
export interface ESquareService {
    /**
     * 
     * @type {ESquareAppSetting}
     * @memberof ESquareService
     */
    'appSetting': ESquareAppSetting;
    /**
     * 
     * @type {ESquareUserSetting}
     * @memberof ESquareService
     */
    'userSetting'?: ESquareUserSetting;
}
/**
 * ユーザー自身で変更できる項目
 * @export
 * @interface ESquareUserSetting
 */
export interface ESquareUserSetting {
    /**
     * 
     * @type {boolean}
     * @memberof ESquareUserSetting
     */
    'chatNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareUserSetting
     */
    'positionReversed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESquareUserSetting
     */
    'colorReversed': boolean;
    /**
     * 
     * @type {IndicationGroupingKey}
     * @memberof ESquareUserSetting
     */
    'indicationGroupingKey': IndicationGroupingKey;
}
/**
 * 
 * @export
 * @interface FuelSurchargeType
 */
export interface FuelSurchargeType {
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof FuelSurchargeType
     */
    'fuelSurchargeTypeId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof FuelSurchargeType
     */
    'name': MultiLocaleName;
    /**
     * 表示の優先順位
     * @type {number}
     * @memberof FuelSurchargeType
     */
    'displayPriority': number;
    /**
     * 
     * @type {FuelSurchargeTypeType}
     * @memberof FuelSurchargeType
     */
    'type': FuelSurchargeTypeType;
}
/**
 * 
 * @export
 * @interface FuelSurchargeTypeOption
 */
export interface FuelSurchargeTypeOption {
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof FuelSurchargeTypeOption
     */
    'fuelSurchargeType': FuelSurchargeType;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof FuelSurchargeTypeOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof FuelSurchargeTypeOption
     */
    'isSelected': boolean;
}
/**
 * 燃調区分
 * @export
 * @enum {string}
 */

export const FuelSurchargeTypeType = {
    UltraHigh: 'ultra-high',
    High: 'high',
    Low: 'low',
    Backup: 'backup',
    Jera: 'jera',
    JeraFy24Tokyo: 'jera-fy24-tokyo',
    JeraFy24Chubu1: 'jera-fy24-chubu-1',
    JeraFy24Chubu2: 'jera-fy24-chubu-2',
    JeraFy25Tokyo: 'jera-fy25-tokyo',
    JeraFy25Chubu1: 'jera-fy25-chubu-1',
    JeraFy25Chubu2: 'jera-fy25-chubu-2',
    Jlc: 'jlc',
    Jcoalc: 'jcoalc',
    Jkm: 'jkm',
    Newc: 'newc',
    Lng: 'lng',
    Coal: 'coal',
    Others: 'others',
    Jcc: 'jcc',
    Ejcc: 'ejcc',
    Ejlc: 'ejlc',
    EjlcJcc: 'ejlc-jcc',
    EjlcJkm: 'ejlc-jkm',
    Ejcoalc: 'ejcoalc',
    Efca: 'efca'
} as const;

export type FuelSurchargeTypeType = typeof FuelSurchargeTypeType[keyof typeof FuelSurchargeTypeType];


/**
 * ファイルのダウンロードURLを返す
 * @export
 * @interface GetLocalizedReportUrl200Response
 */
export interface GetLocalizedReportUrl200Response {
    /**
     * 
     * @type {string}
     * @memberof GetLocalizedReportUrl200Response
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface HourType
 */
export interface HourType {
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof HourType
     */
    'hourTypeId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof HourType
     */
    'name': MultiLocaleName;
    /**
     * 表示の優先順位
     * @type {number}
     * @memberof HourType
     */
    'displayPriority': number;
    /**
     * 受給パターンID
     * @type {string}
     * @memberof HourType
     */
    'deliveryPatternId': string;
}
/**
 * 
 * @export
 * @interface HourTypeOption
 */
export interface HourTypeOption {
    /**
     * 
     * @type {HourType}
     * @memberof HourTypeOption
     */
    'hourType': HourType;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof HourTypeOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof HourTypeOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface Indication
 */
export interface Indication {
    /**
     * 注文ID
     * @type {string}
     * @memberof Indication
     */
    'id': string;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof Indication
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof Indication
     */
    'organizationName': MultiLocaleName;
    /**
     * 組織略称名
     * @type {string}
     * @memberof Indication
     */
    'organizationAbbreviation'?: string;
    /**
     * 
     * @type {IndicationSubType}
     * @memberof Indication
     */
    'type': IndicationSubType;
    /**
     * 注文のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof Indication
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 商品のリスト
     * @type {Array<Product>}
     * @memberof Indication
     */
    'products': Array<Product>;
    /**
     * 
     * @type {PositionType}
     * @memberof Indication
     */
    'position': PositionType;
    /**
     * 
     * @type {Price}
     * @memberof Indication
     */
    'price': Price;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof Indication
     * @deprecated
     */
    'unitPrices': Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof Indication
     */
    'volumes': Array<number>;
    /**
     * 交換側の数量
     * @type {Array<number>}
     * @memberof Indication
     */
    'exchangeVolumes'?: Array<number>;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof Indication
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof Indication
     */
    'status': IndicationStatusType;
    /**
     * 要望事項
     * @type {string}
     * @memberof Indication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof Indication
     */
    'publicInformation'?: string;
    /**
     * 注文の作成者名
     * @type {string}
     * @memberof Indication
     */
    'createdParticipantName': string;
    /**
     * 注文の更新者名
     * @type {string}
     * @memberof Indication
     */
    'lastUpdatedParticipantName': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof Indication
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Indication
     */
    'updatedAt': string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof Indication
     */
    'brokerId'?: string;
    /**
     * 担当ブローカーの名前
     * @type {string}
     * @memberof Indication
     */
    'brokerName'?: string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof Indication
     */
    'traderId'?: string;
    /**
     * 担当トレーダーの名前
     * @type {string}
     * @memberof Indication
     */
    'traderName'?: string;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof Indication
     */
    'isVisibleToTraders': boolean;
    /**
     * マーケットに公開フラグ。注文が板に表示されるか
     * @type {boolean}
     * @memberof Indication
     */
    'openToMarket': boolean;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof Indication
     */
    'excludedEnecCurve': boolean;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof Indication
     */
    'maskVolume': boolean;
    /**
     * DailyPowerMarket市場の注文かどうか
     * @type {boolean}
     * @memberof Indication
     */
    'isDailyPowerMarket': boolean;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof Indication
     */
    'memo'?: string;
}
/**
 * 注文一覧画面での表示方法。商品ごとにまとめるか、受給条件ごとにまとめるか
 * @export
 * @enum {string}
 */

export const IndicationGroupingKey = {
    Product: 'product',
    DeliveryTerms: 'deliveryTerms'
} as const;

export type IndicationGroupingKey = typeof IndicationGroupingKey[keyof typeof IndicationGroupingKey];


/**
 * 注文ステータス
 * @export
 * @enum {string}
 */

export const IndicationStatusType = {
    Unconfirmed: 'unconfirmed',
    Active: 'active',
    Negotiating: 'negotiating',
    Done: 'done',
    Inactive: 'inactive',
    Expired: 'expired',
    Deleted: 'deleted'
} as const;

export type IndicationStatusType = typeof IndicationStatusType[keyof typeof IndicationStatusType];


/**
 * 
 * @export
 * @interface IndicationStatusTypeSpec
 */
export interface IndicationStatusTypeSpec {
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof IndicationStatusTypeSpec
     */
    'id': IndicationStatusType;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof IndicationStatusTypeSpec
     */
    'name': MultiLocaleName;
    /**
     * ブローカーが現在の注文ステータスから変更できる注文ステータスのリスト(現在の注文ステータスを含む)
     * @type {Array<IndicationStatusType>}
     * @memberof IndicationStatusTypeSpec
     */
    'changeableStatuses': Array<IndicationStatusType>;
}
/**
 * 注文のサブタイプ
 * @export
 * @enum {string}
 */

export const IndicationSubType = {
    Normal: 'normal',
    Spread: 'spread'
} as const;

export type IndicationSubType = typeof IndicationSubType[keyof typeof IndicationSubType];


/**
 * 
 * @export
 * @interface Information
 */
export interface Information {
    /**
     * お知らせID
     * @type {string}
     * @memberof Information
     */
    'informationId': string;
    /**
     * タイトル
     * @type {string}
     * @memberof Information
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     * @memberof Information
     */
    'message': string;
    /**
     * 
     * @type {InformationType}
     * @memberof Information
     */
    'informationType': InformationType;
    /**
     * お知らせの有効期限
     * @type {string}
     * @memberof Information
     */
    'expiresAt': string;
    /**
     * お知らせが有効か否か
     * @type {boolean}
     * @memberof Information
     */
    'active': boolean;
}
/**
 * お知らせの種類
 * @export
 * @enum {string}
 */

export const InformationType = {
    Info: 'info',
    Incident: 'incident'
} as const;

export type InformationType = typeof InformationType[keyof typeof InformationType];


/**
 * 言語
 * @export
 * @enum {string}
 */

export const Locale = {
    Ja: 'ja',
    En: 'en'
} as const;

export type Locale = typeof Locale[keyof typeof Locale];


/**
 * 
 * @export
 * @interface LocalizedReport
 */
export interface LocalizedReport {
    /**
     * レポートのID
     * @type {string}
     * @memberof LocalizedReport
     */
    'id': string;
    /**
     * 公開日
     * @type {string}
     * @memberof LocalizedReport
     */
    'publishedAt': string;
    /**
     * 
     * @type {LocalizedReportContent}
     * @memberof LocalizedReport
     */
    'reportContent': LocalizedReportContent;
}
/**
 * レポートコンテンツ
 * @export
 * @interface LocalizedReportContent
 */
export interface LocalizedReportContent {
    /**
     * レポートコンテンツのID
     * @type {string}
     * @memberof LocalizedReportContent
     */
    'id': string;
    /**
     * 
     * @type {Locale}
     * @memberof LocalizedReportContent
     */
    'locale': Locale;
    /**
     * 
     * @type {string}
     * @memberof LocalizedReportContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof LocalizedReportContent
     */
    'description': string;
    /**
     * ファイル名
     * @type {string}
     * @memberof LocalizedReportContent
     */
    'attachmentName': string;
}
/**
 * 
 * @export
 * @interface MemberProfile
 */
export interface MemberProfile {
    /**
     * ユーザーID
     * @type {string}
     * @memberof MemberProfile
     */
    'userId': string;
    /**
     * 氏名
     * @type {string}
     * @memberof MemberProfile
     */
    'name'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof MemberProfile
     */
    'mail': string;
    /**
     * 電話
     * @type {string}
     * @memberof MemberProfile
     */
    'phoneNumber'?: string;
    /**
     * 携帯電話
     * @type {string}
     * @memberof MemberProfile
     */
    'mobilePhoneNumber'?: string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof MemberProfile
     */
    'picture'?: string;
    /**
     * 組織ID
     * @type {string}
     * @memberof MemberProfile
     */
    'organizationId'?: string;
    /**
     * 所属
     * @type {string}
     * @memberof MemberProfile
     */
    'department'?: string;
    /**
     * 
     * @type {RoleType}
     * @memberof MemberProfile
     */
    'roleInOrganization': RoleType;
    /**
     * 
     * @type {Services}
     * @memberof MemberProfile
     */
    'services'?: Services;
    /**
     * 
     * @type {UserStatusType}
     * @memberof MemberProfile
     */
    'status': UserStatusType;
    /**
     * 作成者名
     * @type {string}
     * @memberof MemberProfile
     */
    'createdUserName'?: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof MemberProfile
     */
    'createdAt'?: string;
    /**
     * service.esquare.appSettingの最終更新者の名前
     * @type {string}
     * @memberof MemberProfile
     */
    'eSquareAppSettingLastUpdatedUserName'?: string;
    /**
     * service.esquare.appSettingの最終更新日時
     * @type {string}
     * @memberof MemberProfile
     */
    'eSquareAppSettingUpdatedAt'?: string;
}
/**
 * 
 * @export
 * @interface MessageAdded
 */
export interface MessageAdded {
    /**
     * 
     * @type {string}
     * @memberof MessageAdded
     */
    'channelId': string;
    /**
     * 
     * @type {string}
     * @memberof MessageAdded
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MessageAdded
     */
    'message': string;
    /**
     * 全体通知するかのフラグ
     * @type {boolean}
     * @memberof MessageAdded
     */
    'isBroadcast': boolean;
}
/**
 * 
 * @export
 * @interface MessageIndex
 */
export interface MessageIndex {
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof MessageIndex
     */
    'indices': { [key: string]: Array<number>; };
}
/**
 * 
 * @export
 * @interface MonthlyTradingStatistics
 */
export interface MonthlyTradingStatistics {
    /**
     * 注文ID
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'indicationId': string;
    /**
     * 
     * @type {PositionType}
     * @memberof MonthlyTradingStatistics
     */
    'position': PositionType;
    /**
     * 交渉ID
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'negotiationId': string;
    /**
     * 表示用交渉ID
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'publicNegotiationId': string;
    /**
     * 約定ID
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'dealId': string;
    /**
     * 約定日時
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'executedAt': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'organizationId': string;
    /**
     * 注文者がクレジットスリーバーか否か
     * @type {boolean}
     * @memberof MonthlyTradingStatistics
     */
    'isCreditSleeve': boolean;
    /**
     * クレジットスリーブ手数料
     * @type {number}
     * @memberof MonthlyTradingStatistics
     */
    'sleeveSpread'?: number;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof MonthlyTradingStatistics
     */
    'organizationName': MultiLocaleName;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof MonthlyTradingStatistics
     */
    'counterOrganizationName': MultiLocaleName;
    /**
     * 商品区分名称
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'productTypeName': string;
    /**
     * 燃料調整区分名称
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'fuelSurchargeTypeName'?: string;
    /**
     * 
     * @type {MonthlyTradingStatisticsOfDeliveryTerms}
     * @memberof MonthlyTradingStatistics
     */
    'base'?: MonthlyTradingStatisticsOfDeliveryTerms;
    /**
     * 
     * @type {MonthlyTradingStatisticsOfDeliveryTerms}
     * @memberof MonthlyTradingStatistics
     */
    'exchange'?: MonthlyTradingStatisticsOfDeliveryTerms;
    /**
     * スプレッド基準単価
     * @type {number}
     * @memberof MonthlyTradingStatistics
     */
    'spreadBasePrice'?: number;
    /**
     * 仲介手数料合計(税抜)
     * @type {number}
     * @memberof MonthlyTradingStatistics
     */
    'totalBrokingFee': number;
    /**
     * 仲介手数料支払い方法 or 成約翌月に一括支払い
     * @type {string}
     * @memberof MonthlyTradingStatistics
     */
    'brokerageFeePaymentTiming': string;
    /**
     * P&C
     * @type {boolean}
     * @memberof MonthlyTradingStatistics
     */
    'privateConfidential': boolean;
    /**
     * 
     * @type {Locale}
     * @memberof MonthlyTradingStatistics
     */
    'locale': Locale;
}
/**
 * 
 * @export
 * @interface MonthlyTradingStatisticsOfDeliveryTerms
 */
export interface MonthlyTradingStatisticsOfDeliveryTerms {
    /**
     * 注文のエリア名称
     * @type {string}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'areaName': string;
    /**
     * 注文全体の需給開始年月日
     * @type {string}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'deliveryStartDate': string;
    /**
     * 注文全体の需給終了年月日
     * @type {string}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'deliveryEndDate': string;
    /**
     * 時間帯区分
     * @type {string}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'hourTypeName'?: string;
    /**
     * 日パターンと時間帯パターン名称
     * @type {Array<string>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'hourPatternDayPatternName': Array<string>;
    /**
     * 約定に対する受給年月
     * @type {Array<string>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'yearMonth'?: Array<string>;
    /**
     * キャッシュインとなる受給年月
     * @type {Array<string>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'cashInMonth': Array<string>;
    /**
     * 月の受給電力量
     * @type {Array<number>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'totalVolumePerMonth': Array<number>;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'unitPricePerMonth': Array<number>;
    /**
     * 受給時間
     * @type {Array<number>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'hoursPerMonth': Array<number>;
    /**
     * kWhあたりの手数料単価
     * @type {number}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'brokingFeePerKWh': number;
    /**
     * 仲介手数料(税抜)
     * @type {Array<number>}
     * @memberof MonthlyTradingStatisticsOfDeliveryTerms
     */
    'brokingFeePerMonth': Array<number>;
}
/**
 * 
 * @export
 * @interface MultiLocaleName
 */
export interface MultiLocaleName {
    /**
     * 
     * @type {Array<Translation>}
     * @memberof MultiLocaleName
     */
    'translations': Array<Translation>;
}
/**
 * 
 * @export
 * @interface MyDeal
 */
export interface MyDeal {
    /**
     * 約定ID
     * @type {string}
     * @memberof MyDeal
     */
    'dealId': string;
    /**
     * 
     * @type {DealSubType}
     * @memberof MyDeal
     */
    'type': DealSubType;
    /**
     * 約定のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof MyDeal
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 
     * @type {Product}
     * @memberof MyDeal
     */
    'product': Product;
    /**
     * 
     * @type {Price}
     * @memberof MyDeal
     */
    'price': Price;
    /**
     * 単価
     * @type {number}
     * @memberof MyDeal
     * @deprecated
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof MyDeal
     */
    'volume': number;
    /**
     * 交換側の数量
     * @type {number}
     * @memberof MyDeal
     */
    'exchangeVolume'?: number;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof MyDeal
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 
     * @type {PositionType}
     * @memberof MyDeal
     */
    'position': PositionType;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof MyDeal
     */
    'counterName'?: MultiLocaleName;
    /**
     * 作成日時
     * @type {string}
     * @memberof MyDeal
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface MyIndication
 */
export interface MyIndication {
    /**
     * 注文ID
     * @type {string}
     * @memberof MyIndication
     */
    'indicationId': string;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof MyIndication
     */
    'organizationId': string;
    /**
     * 
     * @type {IndicationSubType}
     * @memberof MyIndication
     */
    'type': IndicationSubType;
    /**
     * 注文のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof MyIndication
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 商品のリスト
     * @type {Array<Product>}
     * @memberof MyIndication
     */
    'products': Array<Product>;
    /**
     * 
     * @type {PositionType}
     * @memberof MyIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {Price}
     * @memberof MyIndication
     */
    'price': Price;
    /**
     * 単価
     * @type {number}
     * @memberof MyIndication
     * @deprecated
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof MyIndication
     */
    'volume': number | null;
    /**
     * 交換側の数量
     * @type {number}
     * @memberof MyIndication
     */
    'exchangeVolume'?: number | null;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof MyIndication
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof MyIndication
     */
    'status': IndicationStatusType;
    /**
     * 要望事項
     * @type {string}
     * @memberof MyIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof MyIndication
     */
    'publicInformation'?: string;
    /**
     * 注文作成者
     * @type {string}
     * @memberof MyIndication
     */
    'createdParticipantId': string;
    /**
     * 直近の更新者
     * @type {string}
     * @memberof MyIndication
     */
    'lastUpdatedParticipantId': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof MyIndication
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof MyIndication
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MyStandardIndicationSelectableInputs
 */
export interface MyStandardIndicationSelectableInputs {
    /**
     * 売買区分一覧
     * @type {Array<PositionTypeOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'positions': Array<PositionTypeOption>;
    /**
     * 受給単位一覧
     * @type {Array<DeliveryUnitOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'deliveryUnits': Array<DeliveryUnitOption>;
    /**
     * 選択可能な商品区分一覧
     * @type {Array<ProductTypeOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'productTypes': Array<ProductTypeOption>;
    /**
     * 選択可能なエリア一覧
     * @type {Array<AreaOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'areas': Array<AreaOption>;
    /**
     * 選択可能な時間帯区分一覧
     * @type {Array<HourTypeOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'hourTypes': Array<HourTypeOption>;
    /**
     * 選択可能な開始日一覧
     * @type {Array<DateOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'deliveryStartDates'?: Array<DateOption>;
    /**
     * 選択可能な終了日一覧
     * @type {Array<DateOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'deliveryEndDates'?: Array<DateOption>;
    /**
     * 選択可能な開始月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'deliveryStartYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 選択可能な終了月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'deliveryEndYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 交換側の選択可能なエリア一覧
     * @type {Array<AreaOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeAreas'?: Array<AreaOption>;
    /**
     * 交換側の選択可能な時間帯区分一覧
     * @type {Array<HourTypeOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeHourTypes'?: Array<HourTypeOption>;
    /**
     * 交換側の選択可能な開始日一覧
     * @type {Array<DateOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeDeliveryStartDates'?: Array<DateOption>;
    /**
     * 交換側の選択可能な終了日一覧
     * @type {Array<DateOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeDeliveryEndDates'?: Array<DateOption>;
    /**
     * 交換側の選択可能な開始月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeDeliveryStartYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 交換側の選択可能な終了月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeDeliveryEndYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 選択可能な燃調区分一覧、燃調ありを選択している場合のみ返却される
     * @type {Array<FuelSurchargeTypeOption>}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'fuelSurchargeTypes': Array<FuelSurchargeTypeOption>;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'unitPrice': ValidatableValue;
    /**
     * 
     * @type {ValidatablePrice}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'price'?: ValidatablePrice;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'volume': ValidatableValue;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'exchangeVolume'?: ValidatableValue;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'request': string;
    /**
     * 注文についての備考
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'publicInformation'?: string;
    /**
     * エリアスプレッドを入力中かどうか
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'isAreaSpread': boolean;
    /**
     * 期間スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'isDeliveryPeriodSpread': boolean;
    /**
     * 時間帯スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'isDeliveryPatternSpread': boolean;
    /**
     * 入力内容が有効かどうか。有効であれば注文の作成や変更が実行できる
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'isValid': boolean;
    /**
     * 呪文のバリデーションエラーのメッセージ。エラーなしの場合はundefined
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputs
     */
    'spellValidationErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface MyStandardIndicationSelectableInputsRequest
 */
export interface MyStandardIndicationSelectableInputsRequest {
    /**
     * 注文ID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'indicationId'?: string;
    /**
     * 受給条件ID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'deliveryTermsId'?: string;
    /**
     * 交換側の受給条件ID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryTermsId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'position'?: PositionType;
    /**
     * ユーザーが入力している商品区分のID
     * @type {Array<string>}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'productTypeIds'?: Array<string>;
    /**
     * ユーザーが入力しているエリアのID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'areaId'?: string;
    /**
     * ユーザーが入力している時間帯区分のID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'hourTypeId'?: string;
    /**
     * ユーザーが入力している開始日 yyyy-MM-dd
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'deliveryStartDate'?: string;
    /**
     * ユーザーが入力している終了日 yyyy-MMdd
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'deliveryEndDate'?: string;
    /**
     * ユーザーが入力している交換側のエリアのID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'exchangeAreaId'?: string;
    /**
     * ユーザーが入力している交換側の時間帯区分のID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'exchangeHourTypeId'?: string;
    /**
     * ユーザーが入力している交換側の開始日 yyyy-MM-dd
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryStartDate'?: string;
    /**
     * ユーザーが入力している交換側の終了日 yyyy-MMdd
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryEndDate'?: string;
    /**
     * ユーザーが入力している燃調区分のID
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'deliveryUnit'?: DeliveryUnit;
    /**
     * 注文単価
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'unitPrice'?: string;
    /**
     * 
     * @type {SelectedPrice}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'price'?: SelectedPrice;
    /**
     * 注文数量
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'volume'?: string;
    /**
     * 交換側の注文数量
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'exchangeVolume'?: string;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'request'?: string;
    /**
     * 注文についての備考
     * @type {string}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'publicInformation'?: string;
    /**
     * エリアスプレッドを入力中かどうか
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'isAreaSpread'?: boolean;
    /**
     * 期間スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'isDeliveryPeriodSpread'?: boolean;
    /**
     * 時間帯スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof MyStandardIndicationSelectableInputsRequest
     */
    'isDeliveryPatternSpread'?: boolean;
}
/**
 * 
 * @export
 * @interface Negotiation
 */
export interface Negotiation {
    /**
     * 交渉ID
     * @type {string}
     * @memberof Negotiation
     */
    'id': string;
    /**
     * 公開交渉ID
     * @type {string}
     * @memberof Negotiation
     */
    'publicNegotiationId': string;
    /**
     * 
     * @type {NegotiationSubType}
     * @memberof Negotiation
     */
    'type': NegotiationSubType;
    /**
     * 交渉のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof Negotiation
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 
     * @type {NegotiationStatusType}
     * @memberof Negotiation
     */
    'status': NegotiationStatusType;
    /**
     * スプレッド基準単価
     * @type {number}
     * @memberof Negotiation
     */
    'spreadBasePrice'?: number;
    /**
     * カレンダータイプID
     * @type {string}
     * @memberof Negotiation
     */
    'calendarTypeId': string;
    /**
     * 交換側のカレンダータイプID
     * @type {string}
     * @memberof Negotiation
     */
    'exchangeCalendarTypeId'?: string;
    /**
     * 
     * @type {NegotiationDetail}
     * @memberof Negotiation
     */
    'askNegotiationDetail': NegotiationDetail;
    /**
     * 
     * @type {NegotiationDetail}
     * @memberof Negotiation
     */
    'bidNegotiationDetail': NegotiationDetail;
    /**
     * 
     * @type {CreditSleever}
     * @memberof Negotiation
     */
    'creditSleever'?: CreditSleever;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof Negotiation
     */
    'memo'?: string;
    /**
     * 交渉の作成者名
     * @type {string}
     * @memberof Negotiation
     */
    'createdParticipantName': string;
    /**
     * 交渉の更新者名
     * @type {string}
     * @memberof Negotiation
     */
    'lastUpdatedParticipantName': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof Negotiation
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Negotiation
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NegotiationDetail
 */
export interface NegotiationDetail {
    /**
     * 交渉詳細ID
     * @type {string}
     * @memberof NegotiationDetail
     */
    'id': string;
    /**
     * 
     * @type {Indication}
     * @memberof NegotiationDetail
     */
    'indication': Indication;
    /**
     * 
     * @type {Product}
     * @memberof NegotiationDetail
     */
    'product': Product;
    /**
     * 
     * @type {Price}
     * @memberof NegotiationDetail
     */
    'beforePrice': Price;
    /**
     * 交渉前単価
     * @type {Array<number>}
     * @memberof NegotiationDetail
     * @deprecated
     */
    'beforeUnitPrices': Array<number>;
    /**
     * 交渉前数量
     * @type {Array<number>}
     * @memberof NegotiationDetail
     */
    'beforeVolumes': Array<number>;
    /**
     * 交渉前の交換側数量
     * @type {Array<number>}
     * @memberof NegotiationDetail
     */
    'beforeExchangeVolumes'?: Array<number>;
    /**
     * 
     * @type {Price}
     * @memberof NegotiationDetail
     */
    'afterPrice': Price;
    /**
     * 交渉後単価
     * @type {Array<number>}
     * @memberof NegotiationDetail
     * @deprecated
     */
    'afterUnitPrices': Array<number>;
    /**
     * 交渉後数量
     * @type {Array<number>}
     * @memberof NegotiationDetail
     */
    'afterVolumes': Array<number>;
    /**
     * 交渉後の交換側数量
     * @type {Array<number>}
     * @memberof NegotiationDetail
     */
    'afterExchangeVolumes'?: Array<number>;
    /**
     * 仲介単価
     * @type {number}
     * @memberof NegotiationDetail
     */
    'brokingFee': number;
    /**
     * 交換側の仲介単価
     * @type {number}
     * @memberof NegotiationDetail
     */
    'exchangeBrokingFee'?: number;
    /**
     * 
     * @type {EEXPostingInformationOfNegotiation}
     * @memberof NegotiationDetail
     */
    'eexPostingInformation'?: EEXPostingInformationOfNegotiation;
}
/**
 * 交渉ステータス
 * @export
 * @enum {string}
 */

export const NegotiationStatusType = {
    UnderNegotiation: 'under-negotiation',
    Executed: 'executed',
    Broken: 'broken',
    Deleted: 'deleted'
} as const;

export type NegotiationStatusType = typeof NegotiationStatusType[keyof typeof NegotiationStatusType];


/**
 * 交渉のサブタイプ
 * @export
 * @enum {string}
 */

export const NegotiationSubType = {
    Normal: 'normal',
    Spread: 'spread'
} as const;

export type NegotiationSubType = typeof NegotiationSubType[keyof typeof NegotiationSubType];


/**
 * 
 * @export
 * @interface NeverUseSinceItIsDummy200Response
 */
export interface NeverUseSinceItIsDummy200Response {
    /**
     * 
     * @type {AppWssChannelChanges}
     * @memberof NeverUseSinceItIsDummy200Response
     */
    'channelChanges'?: AppWssChannelChanges;
    /**
     * 
     * @type {AppWssNewMessages}
     * @memberof NeverUseSinceItIsDummy200Response
     */
    'newMassages'?: AppWssNewMessages;
    /**
     * 
     * @type {AppWssIndication}
     * @memberof NeverUseSinceItIsDummy200Response
     */
    'indication'?: AppWssIndication;
}
/**
 * 
 * @export
 * @interface NewDMChannelTask
 */
export interface NewDMChannelTask {
    /**
     * 
     * @type {Array<string>}
     * @memberof NewDMChannelTask
     */
    'userIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NewDailyPowerMarketEClearCreditSleever
 */
export interface NewDailyPowerMarketEClearCreditSleever {
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof NewDailyPowerMarketEClearCreditSleever
     */
    'recapReceiver': RecapReceiverValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof NewDailyPowerMarketEClearCreditSleever
     */
    'occtoMaster': OcctoMasterValue;
}
/**
 * 
 * @export
 * @interface NewDailyPowerMarketTrade
 */
export interface NewDailyPowerMarketTrade {
    /**
     * エリアID
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'areaId': string;
    /**
     * 商品区分のID
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'productTypeId': string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'hourTypeId': string;
    /**
     * 受給開始日
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'deliveryEndDate': string;
    /**
     * 単価
     * @type {number}
     * @memberof NewDailyPowerMarketTrade
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof NewDailyPowerMarketTrade
     */
    'volume': number;
    /**
     * 約定日時
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'executedAt': string;
    /**
     * 取引作成者のユーザーID
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'createdParticipantId': string;
    /**
     * 
     * @type {NewDailyPowerMarketTradePositionInfo}
     * @memberof NewDailyPowerMarketTrade
     */
    'ask': NewDailyPowerMarketTradePositionInfo;
    /**
     * 
     * @type {NewDailyPowerMarketTradePositionInfo}
     * @memberof NewDailyPowerMarketTrade
     */
    'bid': NewDailyPowerMarketTradePositionInfo;
    /**
     * 
     * @type {NewDailyPowerMarketEClearCreditSleever}
     * @memberof NewDailyPowerMarketTrade
     */
    'creditSleever'?: NewDailyPowerMarketEClearCreditSleever;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof NewDailyPowerMarketTrade
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface NewDailyPowerMarketTradePositionInfo
 */
export interface NewDailyPowerMarketTradePositionInfo {
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'organizationId': string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'traderId'?: string;
    /**
     * 仲介手数料。省略された場合はデフォルトの仲介手数料が適用される
     * @type {number}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'brokingFee'?: number;
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'recapReceiver'?: RecapReceiverValue;
    /**
     * 
     * @type {EEXClearingHouseValue}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'eexClearingHouse'?: EEXClearingHouseValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'occtoMaster'?: OcctoMasterValue;
    /**
     * P&C
     * @type {boolean}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'privateConfidential'?: boolean;
    /**
     * 支払条件説明
     * @type {string}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'paymentDueInformation'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof NewDailyPowerMarketTradePositionInfo
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface NewDailyPowerMarketTradeResult
 */
export interface NewDailyPowerMarketTradeResult {
    /**
     * 交渉ID
     * @type {string}
     * @memberof NewDailyPowerMarketTradeResult
     */
    'negotiationId': string;
    /**
     * 約定ID
     * @type {Array<string>}
     * @memberof NewDailyPowerMarketTradeResult
     */
    'dealIds': Array<string>;
}
/**
 * 
 * @export
 * @interface NewDeliveryPattern
 */
export interface NewDeliveryPattern {
    /**
     * 受給パターンコンポーネント
     * @type {Array<NewDeliveryPatternComponent>}
     * @memberof NewDeliveryPattern
     */
    'deliveryPatternComponents': Array<NewDeliveryPatternComponent>;
}
/**
 * 
 * @export
 * @interface NewDeliveryPatternComponent
 */
export interface NewDeliveryPatternComponent {
    /**
     * 日パターンID
     * @type {string}
     * @memberof NewDeliveryPatternComponent
     */
    'dayPatternId': string;
    /**
     * 
     * @type {TimeRange}
     * @memberof NewDeliveryPatternComponent
     */
    'timeRange': TimeRange;
}
/**
 * 
 * @export
 * @interface NewDeliveryPeriod
 */
export interface NewDeliveryPeriod {
    /**
     * 
     * @type {NewDeliveryPattern}
     * @memberof NewDeliveryPeriod
     */
    'deliveryPattern': NewDeliveryPattern;
    /**
     * 受給開始日
     * @type {string}
     * @memberof NewDeliveryPeriod
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof NewDeliveryPeriod
     */
    'deliveryEndDate': string;
}
/**
 * 新規作成用のEEXポスティング情報
 * @export
 * @interface NewEEXPosting
 */
export interface NewEEXPosting {
    /**
     * 商品コード
     * @type {string}
     * @memberof NewEEXPosting
     */
    'productCode': string;
    /**
     * 限月
     * @type {string}
     * @memberof NewEEXPosting
     */
    'instrument'?: string;
    /**
     * 開始日時 yyyy-MM-dd
     * @type {string}
     * @memberof NewEEXPosting
     */
    'deliveryStartDate'?: string;
    /**
     * 終了日時 yyyy-MM-dd
     * @type {string}
     * @memberof NewEEXPosting
     */
    'deliveryEndDate'?: string;
    /**
     * 数量
     * @type {string}
     * @memberof NewEEXPosting
     */
    'quantity': string;
    /**
     * 単価
     * @type {string}
     * @memberof NewEEXPosting
     */
    'price': string;
    /**
     * 
     * @type {NewEEXPostingDetail}
     * @memberof NewEEXPosting
     */
    'askEEXPostingDetail': NewEEXPostingDetail;
    /**
     * 
     * @type {NewEEXPostingDetail}
     * @memberof NewEEXPosting
     */
    'bidEEXPostingDetail': NewEEXPostingDetail;
}
/**
 * 新規作成用のEEXポスティング情報の詳細
 * @export
 * @interface NewEEXPostingDetail
 */
export interface NewEEXPostingDetail {
    /**
     * 組織ID
     * @type {string}
     * @memberof NewEEXPostingDetail
     */
    'organizationId': string;
    /**
     * EEXトレーダーコード
     * @type {string}
     * @memberof NewEEXPostingDetail
     */
    'eexTraderCode': string;
    /**
     * EEXクリアリングアカウントのコード
     * @type {string}
     * @memberof NewEEXPostingDetail
     */
    'eexClearingAccountCode': string;
}
/**
 * 
 * @export
 * @interface NewInformation
 */
export interface NewInformation {
    /**
     * タイトル
     * @type {Array<Translation>}
     * @memberof NewInformation
     */
    'title': Array<Translation>;
    /**
     * 内容
     * @type {Array<Translation>}
     * @memberof NewInformation
     */
    'message': Array<Translation>;
    /**
     * 
     * @type {InformationType}
     * @memberof NewInformation
     */
    'informationType': InformationType;
    /**
     * お知らせの有効期限
     * @type {string}
     * @memberof NewInformation
     */
    'expiresAt': string;
}
/**
 * 
 * @export
 * @interface NewMember
 */
export interface NewMember {
    /**
     * 
     * @type {string}
     * @memberof NewMember
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface NewMyIndication
 */
export interface NewMyIndication {
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof NewMyIndication
     */
    'productTypeIds': Array<string>;
    /**
     * 
     * @type {NewStandardIndicationBase}
     * @memberof NewMyIndication
     */
    'base': NewStandardIndicationBase;
    /**
     * 
     * @type {NewStandardIndicationExchange}
     * @memberof NewMyIndication
     */
    'exchange'?: NewStandardIndicationExchange;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof NewMyIndication
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof NewMyIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {Price}
     * @memberof NewMyIndication
     */
    'price': Price;
    /**
     * 要望事項
     * @type {string}
     * @memberof NewMyIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof NewMyIndication
     */
    'publicInformation'?: string;
}
/**
 * 
 * @export
 * @interface NewNegotiation
 */
export interface NewNegotiation {
    /**
     * 売り側の注文ID
     * @type {string}
     * @memberof NewNegotiation
     */
    'askIndicationId': string;
    /**
     * 売り側の商品区分ID
     * @type {string}
     * @memberof NewNegotiation
     */
    'askProductTypeId': string;
    /**
     * 買い側の注文ID
     * @type {string}
     * @memberof NewNegotiation
     */
    'bidIndicationId': string;
    /**
     * 買い側の商品区分ID
     * @type {string}
     * @memberof NewNegotiation
     */
    'bidProductTypeId': string;
    /**
     * 買い側の注文ID
     * @type {string}
     * @memberof NewNegotiation
     */
    'creditSleeverOrganizationId'?: string;
}
/**
 * 
 * @export
 * @interface NewNonStandardIndication
 */
export interface NewNonStandardIndication {
    /**
     * 受給期間
     * @type {Array<NewDeliveryPeriod>}
     * @memberof NewNonStandardIndication
     */
    'deliveryPeriods': Array<NewDeliveryPeriod>;
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof NewNonStandardIndication
     */
    'productTypeIds': Array<string>;
    /**
     * エリアID
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'areaId': string;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * マーケットに公開フラグ。注文が板に表示されるか
     * @type {boolean}
     * @memberof NewNonStandardIndication
     */
    'openToMarket': boolean;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof NewNonStandardIndication
     */
    'isVisibleToTraders': boolean;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'organizationId': string;
    /**
     * 
     * @type {PositionType}
     * @memberof NewNonStandardIndication
     */
    'position': PositionType;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof NewNonStandardIndication
     */
    'unitPrices': Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof NewNonStandardIndication
     */
    'volumes': Array<number>;
    /**
     * 要望事項
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'publicInformation'?: string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'brokerId'?: string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof NewNonStandardIndication
     */
    'traderId'?: string;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof NewNonStandardIndication
     */
    'excludedEnecCurve': boolean;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof NewNonStandardIndication
     */
    'maskVolume': boolean;
}
/**
 * 
 * @export
 * @interface NewOrganization
 */
export interface NewOrganization {
    /**
     * 組織ID
     * @type {string}
     * @memberof NewOrganization
     */
    'organizationId': string;
}
/**
 * 
 * @export
 * @interface NewProductOld
 */
export interface NewProductOld {
    /**
     * 商品区分ID
     * @type {string}
     * @memberof NewProductOld
     */
    'productTypeId': string;
    /**
     * エリアID
     * @type {string}
     * @memberof NewProductOld
     */
    'areaId': string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof NewProductOld
     */
    'hourTypeId': string;
    /**
     * デリバリー開始月ID
     * @type {string}
     * @memberof NewProductOld
     */
    'startDeliveryYearMonthId': string;
    /**
     * デリバリー終了月ID
     * @type {string}
     * @memberof NewProductOld
     */
    'endDeliveryYearMonthId': string;
}
/**
 * 
 * @export
 * @interface NewStandardIndication
 */
export interface NewStandardIndication {
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof NewStandardIndication
     */
    'productTypeIds': Array<string>;
    /**
     * 
     * @type {NewStandardIndicationBase}
     * @memberof NewStandardIndication
     */
    'base': NewStandardIndicationBase;
    /**
     * 
     * @type {NewStandardIndicationExchange}
     * @memberof NewStandardIndication
     */
    'exchange'?: NewStandardIndicationExchange;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof NewStandardIndication
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * マーケットに公開フラグ。注文が板に表示されるか
     * @type {boolean}
     * @memberof NewStandardIndication
     */
    'openToMarket': boolean;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof NewStandardIndication
     */
    'isVisibleToTraders': boolean;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof NewStandardIndication
     */
    'organizationId': string;
    /**
     * 
     * @type {PositionType}
     * @memberof NewStandardIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {Price}
     * @memberof NewStandardIndication
     */
    'price': Price;
    /**
     * 要望事項
     * @type {string}
     * @memberof NewStandardIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof NewStandardIndication
     */
    'publicInformation'?: string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof NewStandardIndication
     */
    'brokerId'?: string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof NewStandardIndication
     */
    'traderId'?: string;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof NewStandardIndication
     */
    'excludedEnecCurve': boolean;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof NewStandardIndication
     */
    'maskVolume': boolean;
}
/**
 * 基準の注文情報
 * @export
 * @interface NewStandardIndicationBase
 */
export interface NewStandardIndicationBase {
    /**
     * エリアID
     * @type {string}
     * @memberof NewStandardIndicationBase
     */
    'areaId': string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof NewStandardIndicationBase
     */
    'hourTypeId': string;
    /**
     * 受給開始日
     * @type {string}
     * @memberof NewStandardIndicationBase
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof NewStandardIndicationBase
     */
    'deliveryEndDate': string;
    /**
     * 数量
     * @type {number}
     * @memberof NewStandardIndicationBase
     */
    'volume': number | null;
}
/**
 * 交換側の注文情報
 * @export
 * @interface NewStandardIndicationExchange
 */
export interface NewStandardIndicationExchange {
    /**
     * エリアID
     * @type {string}
     * @memberof NewStandardIndicationExchange
     */
    'areaId': string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof NewStandardIndicationExchange
     */
    'hourTypeId': string;
    /**
     * 受給開始日
     * @type {string}
     * @memberof NewStandardIndicationExchange
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof NewStandardIndicationExchange
     */
    'deliveryEndDate': string;
    /**
     * 数量
     * @type {number}
     * @memberof NewStandardIndicationExchange
     */
    'volume': number | null;
}
/**
 * 
 * @export
 * @interface NewTrade
 */
export interface NewTrade {
    /**
     * エリアID
     * @type {string}
     * @memberof NewTrade
     */
    'areaId': string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof NewTrade
     */
    'hourTypeId': string;
    /**
     * 受給開始日
     * @type {string}
     * @memberof NewTrade
     */
    'deliveryStartDate': string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof NewTrade
     */
    'deliveryEndDate': string;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof NewTrade
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * 単価
     * @type {number}
     * @memberof NewTrade
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof NewTrade
     */
    'volume': number;
    /**
     * 約定日時
     * @type {string}
     * @memberof NewTrade
     */
    'executedAt': string;
    /**
     * 取引作成者のユーザーID
     * @type {string}
     * @memberof NewTrade
     */
    'createdParticipantId': string;
    /**
     * 
     * @type {NewTradePositionInfo}
     * @memberof NewTrade
     */
    'ask': NewTradePositionInfo;
    /**
     * 
     * @type {NewTradePositionInfo}
     * @memberof NewTrade
     */
    'bid': NewTradePositionInfo;
    /**
     * 
     * @type {NewTradeCreditSleever}
     * @memberof NewTrade
     */
    'creditSleever'?: NewTradeCreditSleever;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof NewTrade
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface NewTradeCreditSleever
 */
export interface NewTradeCreditSleever {
    /**
     * 組織ID
     * @type {string}
     * @memberof NewTradeCreditSleever
     */
    'organizationId': string;
    /**
     * クレジットスリーブの値差
     * @type {number}
     * @memberof NewTradeCreditSleever
     */
    'sleeveSpread': number;
    /**
     * 
     * @type {NewTradeCreditSleeverPositionInfo}
     * @memberof NewTradeCreditSleever
     */
    'ask'?: NewTradeCreditSleeverPositionInfo;
    /**
     * 
     * @type {NewTradeCreditSleeverPositionInfo}
     * @memberof NewTradeCreditSleever
     */
    'bid'?: NewTradeCreditSleeverPositionInfo;
}
/**
 * 
 * @export
 * @interface NewTradeCreditSleeverPositionInfo
 */
export interface NewTradeCreditSleeverPositionInfo {
    /**
     * クレジットスリーブ仲介手数料
     * @type {number}
     * @memberof NewTradeCreditSleeverPositionInfo
     */
    'brokingFee'?: number;
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof NewTradeCreditSleeverPositionInfo
     */
    'recapReceiver'?: RecapReceiverValue;
    /**
     * 
     * @type {EEXClearingHouseValue}
     * @memberof NewTradeCreditSleeverPositionInfo
     */
    'eexClearingHouse'?: EEXClearingHouseValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof NewTradeCreditSleeverPositionInfo
     */
    'occtoMaster'?: OcctoMasterValue;
}
/**
 * 
 * @export
 * @interface NewTradePositionInfo
 */
export interface NewTradePositionInfo {
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof NewTradePositionInfo
     */
    'organizationId': string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof NewTradePositionInfo
     */
    'traderId'?: string;
    /**
     * 商品区分のID
     * @type {string}
     * @memberof NewTradePositionInfo
     */
    'productTypeId': string;
    /**
     * 仲介手数料。省略された場合はデフォルトの仲介手数料が適用される
     * @type {number}
     * @memberof NewTradePositionInfo
     */
    'brokingFee'?: number;
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof NewTradePositionInfo
     */
    'recapReceiver'?: RecapReceiverValue;
    /**
     * 
     * @type {EEXClearingHouseValue}
     * @memberof NewTradePositionInfo
     */
    'eexClearingHouse'?: EEXClearingHouseValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof NewTradePositionInfo
     */
    'occtoMaster'?: OcctoMasterValue;
    /**
     * P&C
     * @type {boolean}
     * @memberof NewTradePositionInfo
     */
    'privateConfidential'?: boolean;
    /**
     * 支払条件説明
     * @type {string}
     * @memberof NewTradePositionInfo
     */
    'paymentDueInformation'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof NewTradePositionInfo
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface NewTradeResult
 */
export interface NewTradeResult {
    /**
     * 交渉ID
     * @type {string}
     * @memberof NewTradeResult
     */
    'negotiationId': string;
    /**
     * 約定ID
     * @type {Array<string>}
     * @memberof NewTradeResult
     */
    'dealIds': Array<string>;
}
/**
 * 
 * @export
 * @interface OcctoMaster
 */
export interface OcctoMaster {
    /**
     * BG
     * @type {Array<BalancingGroup>}
     * @memberof OcctoMaster
     */
    'balancingGroups': Array<BalancingGroup>;
    /**
     * BG
     * @type {Array<SupplyPlanSubmitter>}
     * @memberof OcctoMaster
     */
    'supplyPlanSubmitters': Array<SupplyPlanSubmitter>;
}
/**
 * 広域情報区分
 * @export
 * @enum {string}
 */

export const OcctoMasterType = {
    Demand: 'demand',
    Generation: 'generation'
} as const;

export type OcctoMasterType = typeof OcctoMasterType[keyof typeof OcctoMasterType];


/**
 * 
 * @export
 * @interface OcctoMasterValue
 */
export interface OcctoMasterValue {
    /**
     * 
     * @type {OcctoMasterType}
     * @memberof OcctoMasterValue
     */
    'occtoMasterType': OcctoMasterType;
    /**
     * 事業者コード
     * @type {string}
     * @memberof OcctoMasterValue
     */
    'companyPrefix': string;
    /**
     * BG名称または計画提出者名称
     * @type {string}
     * @memberof OcctoMasterValue
     */
    'name': string;
    /**
     * BGコードまたは計画提出者コード
     * @type {string}
     * @memberof OcctoMasterValue
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {OrderOrderType}
     * @memberof Order
     */
    'orderType': OrderOrderType;
    /**
     * 
     * @type {OrderProductType}
     * @memberof Order
     */
    'productType': OrderProductType;
    /**
     * 
     * @type {OrderFuelSurchargeType}
     * @memberof Order
     */
    'fuelSurchargeType'?: OrderFuelSurchargeType;
    /**
     * 単価
     * @type {string}
     * @memberof Order
     */
    'unitPrice': string;
    /**
     * 数量 未確定の場合は \"TBD\"
     * @type {string}
     * @memberof Order
     */
    'volume': string;
    /**
     * 交換側の数量 未確定の場合は \"TBD\"
     * @type {string}
     * @memberof Order
     */
    'exchangeVolume'?: string;
    /**
     * 約定日
     * @type {string}
     * @memberof Order
     */
    'executedAt'?: string;
}
/**
 * 
 * @export
 * @interface OrderBook
 */
export interface OrderBook {
    /**
     * 受給条件ID
     * @type {string}
     * @memberof OrderBook
     */
    'deliveryTermsId': string;
    /**
     * 交換側の受給条件ID
     * @type {string}
     * @memberof OrderBook
     */
    'exchangeDeliveryTermsId'?: string;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof OrderBook
     */
    'deliveryUnit': DeliveryUnit;
    /**
     * BestAskの注文単価
     * @type {string}
     * @memberof OrderBook
     */
    'askUnitPrice'?: string;
    /**
     * BestBidの注文単価
     * @type {string}
     * @memberof OrderBook
     */
    'bidUnitPrice'?: string;
    /**
     * 直近約定の注文単価
     * @type {string}
     * @memberof OrderBook
     */
    'lastUnitPrice'?: string;
    /**
     * 
     * @type {SpreadDeliveryTerms}
     * @memberof OrderBook
     */
    'base': SpreadDeliveryTerms;
    /**
     * 
     * @type {SpreadDeliveryTerms}
     * @memberof OrderBook
     */
    'exchange'?: SpreadDeliveryTerms;
    /**
     * 注文のスプレッド区分(ラベル表示用)のリスト
     * @type {Array<SpreadType>}
     * @memberof OrderBook
     */
    'spreadTypes'?: Array<SpreadType>;
    /**
     * 直近約定の約定日
     * @type {string}
     * @memberof OrderBook
     */
    'executedAt'?: string;
    /**
     * 板の注文一覧 価格の降順にソートされたもの
     * @type {Array<Order>}
     * @memberof OrderBook
     */
    'orders': Array<Order>;
}
/**
 * 
 * @export
 * @interface OrderFuelSurchargeType
 */
export interface OrderFuelSurchargeType {
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof OrderFuelSurchargeType
     */
    'id': string;
    /**
     * 燃調区分名
     * @type {string}
     * @memberof OrderFuelSurchargeType
     */
    'name': string;
    /**
     * 
     * @type {FuelSurchargeTypeType}
     * @memberof OrderFuelSurchargeType
     */
    'type': FuelSurchargeTypeType;
}
/**
 * 注文の種類
 * @export
 * @enum {string}
 */

export const OrderOrderType = {
    Ask: 'ask',
    Bid: 'bid',
    Last: 'last'
} as const;

export type OrderOrderType = typeof OrderOrderType[keyof typeof OrderOrderType];


/**
 * 
 * @export
 * @interface OrderProductType
 */
export interface OrderProductType {
    /**
     * 商品区分ID
     * @type {string}
     * @memberof OrderProductType
     */
    'id': string;
    /**
     * 商品区分名
     * @type {string}
     * @memberof OrderProductType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 組織ID
     * @type {string}
     * @memberof Organization
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof Organization
     */
    'name': MultiLocaleName;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof Organization
     */
    'tradingCompanyName': MultiLocaleName;
    /**
     * 組織略称
     * @type {string}
     * @memberof Organization
     */
    'abbreviation'?: string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof Organization
     */
    'picture': string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof Organization
     */
    'organizationType': OrganizationType;
    /**
     * 
     * @type {Locale}
     * @memberof Organization
     */
    'locale': Locale;
    /**
     * 確認書に記載する住所
     * @type {string}
     * @memberof Organization
     */
    'recapAddress': string;
    /**
     * レポートの種類。powerやgas、または両方
     * @type {Array<ReadableReportType>}
     * @memberof Organization
     */
    'readableReportTypes': Array<ReadableReportType>;
    /**
     * 
     * @type {Array<OrganizationEmailAddress>}
     * @memberof Organization
     */
    'emailAddresses': Array<OrganizationEmailAddress>;
    /**
     * eClear契約締結済みか?
     * @type {boolean}
     * @memberof Organization
     */
    'isEClearContracted': boolean;
    /**
     * eClear社か?
     * @type {boolean}
     * @memberof Organization
     */
    'isEClearCreditSleever': boolean;
    /**
     * 請求書に消費税を含めるかどうか
     * @type {boolean}
     * @memberof Organization
     */
    'isSalesTaxIncludedInInvoice': boolean;
    /**
     * esquareが利用可能か？
     * @type {boolean}
     * @memberof Organization
     */
    'isEsquareEnabled': boolean;
    /**
     * eCompassが利用可能か？
     * @type {boolean}
     * @memberof Organization
     */
    'isEcompassEnabled': boolean;
    /**
     * eCompass for GXが利用可能か？
     * @type {boolean}
     * @memberof Organization
     */
    'isEcompassForGxEnabled': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationChannelMember
 */
export interface OrganizationChannelMember {
    /**
     * 組織チャンネルID
     * @type {string}
     * @memberof OrganizationChannelMember
     */
    'organizationChannelId'?: string;
    /**
     * 組織ID
     * @type {Array<string>}
     * @memberof OrganizationChannelMember
     */
    'organizationIds'?: Array<string>;
    /**
     * チャンネルID
     * @type {string}
     * @memberof OrganizationChannelMember
     */
    'channelId'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationEmailAddress
 */
export interface OrganizationEmailAddress {
    /**
     * 組織メールアドレスのID
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'id': string;
    /**
     * 組織のID
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'organizationId': string;
    /**
     * 組織メールアドレスの名称
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'name': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'emailAddress': string;
    /**
     * 注文に関するメールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof OrganizationEmailAddress
     */
    'receiveIndicationEmail': boolean;
    /**
     * Live-Marketに関するメールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof OrganizationEmailAddress
     */
    'receiveLiveMarketEmail': boolean;
    /**
     * デリバティブ取引の確認書メールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof OrganizationEmailAddress
     */
    'receiveDerivativeTradingRecapEmail': boolean;
    /**
     * 現物取引の確認書メールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof OrganizationEmailAddress
     */
    'receiveSpotTradingRecapEmail': boolean;
    /**
     * レポートメールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof OrganizationEmailAddress
     */
    'receiveReportEmail': boolean;
    /**
     * 請求書メールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof OrganizationEmailAddress
     */
    'receiveInvoiceEmail': boolean;
    /**
     * 作成者名
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'createdUserName'?: string;
    /**
     * 更新者名
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'lastUpdatedUserName'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof OrganizationEmailAddress
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface OrganizationProfile
 */
export interface OrganizationProfile {
    /**
     * 組織ID
     * @type {string}
     * @memberof OrganizationProfile
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof OrganizationProfile
     */
    'name': MultiLocaleName;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof OrganizationProfile
     */
    'tradingCompanyName': MultiLocaleName;
    /**
     * 組織略称
     * @type {string}
     * @memberof OrganizationProfile
     */
    'abbreviation'?: string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof OrganizationProfile
     */
    'picture': string;
    /**
     * 
     * @type {Locale}
     * @memberof OrganizationProfile
     */
    'locale': Locale;
    /**
     * 確認書に記載する住所
     * @type {string}
     * @memberof OrganizationProfile
     */
    'recapAddress': string;
    /**
     * eClear契約締結済みか?
     * @type {boolean}
     * @memberof OrganizationProfile
     */
    'isEClearContracted': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationRecap
 */
export interface OrganizationRecap {
    /**
     * 確認のID
     * @type {string}
     * @memberof OrganizationRecap
     */
    'id': string;
    /**
     * 公開確認ID
     * @type {string}
     * @memberof OrganizationRecap
     */
    'publicRecapId': string;
    /**
     * 約定のID
     * @type {string}
     * @memberof OrganizationRecap
     */
    'dealId': string;
    /**
     * 
     * @type {PositionType}
     * @memberof OrganizationRecap
     */
    'position': PositionType;
    /**
     * 仲介手数料
     * @type {number}
     * @memberof OrganizationRecap
     */
    'brokingFee': number;
    /**
     * 
     * @type {BrokerageFeePaymentTiming}
     * @memberof OrganizationRecap
     */
    'brokerageFeePaymentTiming': BrokerageFeePaymentTiming;
    /**
     * 
     * @type {Product}
     * @memberof OrganizationRecap
     */
    'product': Product;
    /**
     * エリアのID
     * @type {string}
     * @memberof OrganizationRecap
     */
    'areaId': string;
    /**
     * 
     * @type {Price}
     * @memberof OrganizationRecap
     */
    'price': Price;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof OrganizationRecap
     * @deprecated
     */
    'unitPrices': Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof OrganizationRecap
     */
    'volumes': Array<number>;
    /**
     * 組織のID
     * @type {string}
     * @memberof OrganizationRecap
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof OrganizationRecap
     */
    'organizationName': MultiLocaleName;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRecap
     */
    'address': string;
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof OrganizationRecap
     */
    'recapReceiver'?: RecapReceiverValue;
    /**
     * 
     * @type {EEXClearingHouseValue}
     * @memberof OrganizationRecap
     */
    'eexClearingHouse'?: EEXClearingHouseValue;
    /**
     * 
     * @type {TOCOMBrokerValue}
     * @memberof OrganizationRecap
     */
    'tocomBroker'?: TOCOMBrokerValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof OrganizationRecap
     */
    'occtoMaster'?: OcctoMasterValue;
    /**
     * クレジットスリーバーかどうか
     * @type {boolean}
     * @memberof OrganizationRecap
     */
    'isCreditSleever'?: boolean;
    /**
     * 仲介高
     * @type {number}
     * @memberof OrganizationRecap
     */
    'totalBrokingFee': number;
    /**
     * 確認作成者
     * @type {string}
     * @memberof OrganizationRecap
     */
    'createdParticipantId': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof OrganizationRecap
     */
    'createdAt': string;
    /**
     * 直近の更新者
     * @type {string}
     * @memberof OrganizationRecap
     */
    'lastUpdatedParticipantId': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof OrganizationRecap
     */
    'updatedAt': string;
}
/**
 * 組織の種類
 * @export
 * @enum {string}
 */

export const OrganizationType = {
    Trader: 'trader',
    Broker: 'broker',
    SystemAdmin: 'system-admin'
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];


/**
 * ポジション
 * @export
 * @enum {string}
 */

export const PositionType = {
    Ask: 'ask',
    Bid: 'bid'
} as const;

export type PositionType = typeof PositionType[keyof typeof PositionType];


/**
 * 
 * @export
 * @interface PositionTypeOption
 */
export interface PositionTypeOption {
    /**
     * 
     * @type {PositionType}
     * @memberof PositionTypeOption
     */
    'position': PositionType;
    /**
     * ローカライズされた売買区分の文字列
     * @type {string}
     * @memberof PositionTypeOption
     */
    'name': string;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof PositionTypeOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface PostHookPayload
 */
export interface PostHookPayload {
    /**
     * イベントタイプ
     * @type {string}
     * @memberof PostHookPayload
     */
    'EventType'?: string;
    /**
     * サービスID
     * @type {string}
     * @memberof PostHookPayload
     */
    'InstanceSid'?: string;
    /**
     * メッセージのメタ属性
     * @type {string}
     * @memberof PostHookPayload
     */
    'Attributes'?: string;
    /**
     * メッセージ投稿日
     * @type {string}
     * @memberof PostHookPayload
     */
    'DateCreated'?: string;
    /**
     * メッセージのインデックス
     * @type {string}
     * @memberof PostHookPayload
     */
    'Index'?: string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof PostHookPayload
     */
    'From'?: string;
    /**
     * メッセージのID
     * @type {string}
     * @memberof PostHookPayload
     */
    'MessageSid'?: string;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostHookPayload
     */
    'AccountSid'?: string;
    /**
     * イベントソース
     * @type {string}
     * @memberof PostHookPayload
     */
    'Source'?: string;
    /**
     * チャネルID
     * @type {string}
     * @memberof PostHookPayload
     */
    'ConversationSid'?: string;
    /**
     * クライアントのユーザーID
     * @type {string}
     * @memberof PostHookPayload
     */
    'ClientIdentity'?: string;
    /**
     * リトライ回数
     * @type {string}
     * @memberof PostHookPayload
     */
    'RetryCount'?: string;
    /**
     * メッセージ本文
     * @type {string}
     * @memberof PostHookPayload
     */
    'Body'?: string;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {PriceType}
     * @memberof Price
     */
    'type': PriceType;
    /**
     * kWh単価
     * @type {Array<number>}
     * @memberof Price
     */
    'energyUnitPrices': Array<number>;
    /**
     * 
     * @type {BasicUnitPrice}
     * @memberof Price
     */
    'basicUnitPrice'?: BasicUnitPrice;
}
/**
 * 価格のタイプ
 * @export
 * @enum {string}
 */

export const PriceType = {
    Volume: 'volume',
    BasicPlusVolume: 'basic-plus-volume'
} as const;

export type PriceType = typeof PriceType[keyof typeof PriceType];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 商品ID
     * @type {string}
     * @memberof Product
     */
    'id': string;
    /**
     * 
     * @type {ProductSubType}
     * @memberof Product
     */
    'type': ProductSubType;
    /**
     * 
     * @type {ProductType}
     * @memberof Product
     */
    'productType': ProductType;
    /**
     * 
     * @type {DeliveryTerms}
     * @memberof Product
     */
    'deliveryTerms': DeliveryTerms;
    /**
     * 
     * @type {DeliveryTerms}
     * @memberof Product
     */
    'exchangeDeliveryTerms'?: DeliveryTerms;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof Product
     */
    'deliveryUnit': DeliveryUnit;
}
/**
 * 商品の種類
 * @export
 * @enum {string}
 */

export const ProductSubType = {
    Normal: 'normal',
    Spread: 'spread',
    SwingOption: 'swing-option'
} as const;

export type ProductSubType = typeof ProductSubType[keyof typeof ProductSubType];


/**
 * 
 * @export
 * @interface ProductType
 */
export interface ProductType {
    /**
     * 商品区分ID
     * @type {string}
     * @memberof ProductType
     */
    'productTypeId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof ProductType
     */
    'name': MultiLocaleName;
    /**
     * 区分判別用名称
     * @type {string}
     * @memberof ProductType
     */
    'value': ProductTypeValueEnum;
    /**
     * 表示の優先順位
     * @type {number}
     * @memberof ProductType
     */
    'displayPriority': number;
    /**
     * 選択可能なエリアID
     * @type {Array<string>}
     * @memberof ProductType
     */
    'availableAreaIds'?: Array<string>;
    /**
     * 選択可能な時間帯区分ID
     * @type {Array<string>}
     * @memberof ProductType
     */
    'availableHourTypeIds'?: Array<string>;
    /**
     * 燃調付きの商品かどうか
     * @type {boolean}
     * @memberof ProductType
     */
    'hasFuelSurcharge': boolean;
    /**
     * デフォルトのカレンダー区分ID
     * @type {string}
     * @memberof ProductType
     */
    'defaultCalendarTypeId': string;
    /**
     * 商品区分有効日
     * @type {Array<ProductTypeAvailableDates>}
     * @memberof ProductType
     */
    'availableDates'?: Array<ProductTypeAvailableDates>;
}

export const ProductTypeValueEnum = {
    BgNoFuelSurcharge: 'bg-no-fuel-surcharge',
    BgWithFuelSurcharge: 'bg-with-fuel-surcharge',
    Cfd: 'cfd',
    SwapBilateral: 'swap-bilateral',
    SwapTocom: 'swap-tocom',
    SwapEex: 'swap-eex',
    BgWithEclear: 'bg-with-eclear',
    BgWithEclearAndFuelSurcharge: 'bg-with-eclear-and-fuel-surcharge',
    CfdWithFuelSurcharge: 'cfd-with-fuel-surcharge'
} as const;

export type ProductTypeValueEnum = typeof ProductTypeValueEnum[keyof typeof ProductTypeValueEnum];

/**
 * 
 * @export
 * @interface ProductTypeAvailableDates
 */
export interface ProductTypeAvailableDates {
    /**
     * 商品区分有効日ID
     * @type {string}
     * @memberof ProductTypeAvailableDates
     */
    'id': string;
    /**
     * 商品区分ID
     * @type {string}
     * @memberof ProductTypeAvailableDates
     */
    'productTypeId': string;
    /**
     * 
     * @type {ProductTypeAvailableDatesDates}
     * @memberof ProductTypeAvailableDates
     */
    'dates': ProductTypeAvailableDatesDates;
}
/**
 * 商品区分有効日
 * @export
 * @interface ProductTypeAvailableDatesDates
 */
export interface ProductTypeAvailableDatesDates {
    /**
     * 開始日
     * @type {string}
     * @memberof ProductTypeAvailableDatesDates
     */
    'start'?: string;
    /**
     * 終了日
     * @type {string}
     * @memberof ProductTypeAvailableDatesDates
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface ProductTypeDateName
 */
export interface ProductTypeDateName {
    /**
     * 商品区分日名称ID
     * @type {string}
     * @memberof ProductTypeDateName
     */
    'id': string;
    /**
     * 商品区分ID
     * @type {string}
     * @memberof ProductTypeDateName
     */
    'productTypeId': string;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof ProductTypeDateName
     */
    'deliveryUnit': DeliveryUnit;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeDateName
     */
    'date': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof ProductTypeDateName
     */
    'name': MultiLocaleName;
}
/**
 * 
 * @export
 * @interface ProductTypeOption
 */
export interface ProductTypeOption {
    /**
     * 
     * @type {ProductType}
     * @memberof ProductTypeOption
     */
    'productType': ProductType;
    /**
     * 選択可能かどうか
     * @type {boolean}
     * @memberof ProductTypeOption
     */
    'isAvailable': boolean;
    /**
     * 選択されているかどうか
     * @type {boolean}
     * @memberof ProductTypeOption
     */
    'isSelected': boolean;
}
/**
 * 
 * @export
 * @interface PubSubMessage
 */
export interface PubSubMessage {
    /**
     * 
     * @type {PubSubMessageMessage}
     * @memberof PubSubMessage
     */
    'message'?: PubSubMessageMessage;
    /**
     * 
     * @type {string}
     * @memberof PubSubMessage
     */
    'subscription'?: string;
}
/**
 * 
 * @export
 * @interface PubSubMessageMessage
 */
export interface PubSubMessageMessage {
    /**
     * 
     * @type {PubSubMessageMessageAttributes}
     * @memberof PubSubMessageMessage
     */
    'attributes'?: PubSubMessageMessageAttributes;
    /**
     * 
     * @type {string}
     * @memberof PubSubMessageMessage
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof PubSubMessageMessage
     */
    'messageId'?: string;
}
/**
 * 
 * @export
 * @interface PubSubMessageMessageAttributes
 */
export interface PubSubMessageMessageAttributes {
    /**
     * 
     * @type {string}
     * @memberof PubSubMessageMessageAttributes
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface PublicDeal
 */
export interface PublicDeal {
    /**
     * 
     * @type {Product}
     * @memberof PublicDeal
     */
    'product': Product;
    /**
     * 
     * @type {Price}
     * @memberof PublicDeal
     */
    'price': Price;
    /**
     * 単価
     * @type {number}
     * @memberof PublicDeal
     * @deprecated
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof PublicDeal
     */
    'volume': number | null;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof PublicDeal
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * 作成日
     * @type {string}
     * @memberof PublicDeal
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface PublicIndication
 */
export interface PublicIndication {
    /**
     * 商品のリスト
     * @type {Array<Product>}
     * @memberof PublicIndication
     */
    'products': Array<Product>;
    /**
     * 
     * @type {PositionType}
     * @memberof PublicIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {Price}
     * @memberof PublicIndication
     */
    'price': Price;
    /**
     * 単価
     * @type {number}
     * @memberof PublicIndication
     * @deprecated
     */
    'unitPrice': number;
    /**
     * 数量
     * @type {number}
     * @memberof PublicIndication
     */
    'volume': number;
    /**
     * 
     * @type {FuelSurchargeType}
     * @memberof PublicIndication
     */
    'fuelSurchargeType'?: FuelSurchargeType;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof PublicIndication
     */
    'publicInformation'?: string;
}
/**
 * 
 * @export
 * @interface PublicOrganizationProfile
 */
export interface PublicOrganizationProfile {
    /**
     * 組織ID
     * @type {string}
     * @memberof PublicOrganizationProfile
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof PublicOrganizationProfile
     */
    'name': MultiLocaleName;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof PublicOrganizationProfile
     */
    'tradingCompanyName': MultiLocaleName;
    /**
     * 写真のURL
     * @type {string}
     * @memberof PublicOrganizationProfile
     */
    'picture': string;
    /**
     * 組織名の略称
     * @type {string}
     * @memberof PublicOrganizationProfile
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {Locale}
     * @memberof PublicOrganizationProfile
     */
    'locale': Locale;
}
/**
 * 
 * @export
 * @interface PublicOrganizationProfiles
 */
export interface PublicOrganizationProfiles {
    /**
     * 組織
     * @type {Array<PublicOrganizationProfile>}
     * @memberof PublicOrganizationProfiles
     */
    'organizations'?: Array<PublicOrganizationProfile>;
    /**
     * 組織の合計数
     * @type {number}
     * @memberof PublicOrganizationProfiles
     */
    'total'?: number;
    /**
     * 最後のページ
     * @type {number}
     * @memberof PublicOrganizationProfiles
     */
    'lastPage'?: number;
}
/**
 * 
 * @export
 * @interface PublicUserProfile
 */
export interface PublicUserProfile {
    /**
     * ユーザーID
     * @type {string}
     * @memberof PublicUserProfile
     */
    'userId': string;
    /**
     * 氏名
     * @type {string}
     * @memberof PublicUserProfile
     */
    'name': string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof PublicUserProfile
     */
    'picture': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof PublicUserProfile
     */
    'organizationId': string;
}
/**
 * 
 * @export
 * @interface PublicUserProfiles
 */
export interface PublicUserProfiles {
    /**
     * ユーザー
     * @type {Array<PublicUserProfile>}
     * @memberof PublicUserProfiles
     */
    'users'?: Array<PublicUserProfile>;
    /**
     * ユーザーの合計数
     * @type {number}
     * @memberof PublicUserProfiles
     */
    'total'?: number;
    /**
     * 最後のページ
     * @type {number}
     * @memberof PublicUserProfiles
     */
    'lastPage'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReadableReportType = {
    Gas: 'gas',
    Power: 'power'
} as const;

export type ReadableReportType = typeof ReadableReportType[keyof typeof ReadableReportType];


/**
 * 
 * @export
 * @interface Recap
 */
export interface Recap {
    /**
     * 確認書(Recap)ID
     * @type {string}
     * @memberof Recap
     */
    'id': string;
    /**
     * 約定ID
     * @type {string}
     * @memberof Recap
     */
    'dealId': string;
    /**
     * 
     * @type {PositionType}
     * @memberof Recap
     */
    'position': PositionType;
    /**
     * 仲介手数料単価
     * @type {number}
     * @memberof Recap
     */
    'brokingFee': number;
    /**
     * 
     * @type {BrokerageFeePaymentTiming}
     * @memberof Recap
     */
    'brokerageFeePaymentTiming': BrokerageFeePaymentTiming;
    /**
     * 
     * @type {Product}
     * @memberof Recap
     */
    'product': Product;
    /**
     * 
     * @type {Price}
     * @memberof Recap
     */
    'price': Price;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof Recap
     * @deprecated
     */
    'unitPrices': Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof Recap
     */
    'volumes': Array<number>;
    /**
     * 組織ID
     * @type {string}
     * @memberof Recap
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof Recap
     */
    'organizationName': MultiLocaleName;
    /**
     * 
     * @type {RecapOrganization}
     * @memberof Recap
     */
    'askOrganization': RecapOrganization;
    /**
     * 
     * @type {RecapOrganization}
     * @memberof Recap
     */
    'bidOrganization': RecapOrganization;
    /**
     * 
     * @type {DeliverySummary}
     * @memberof Recap
     */
    'deliverySummary': DeliverySummary;
    /**
     * 制約事項
     * @type {string}
     * @memberof Recap
     */
    'restrictions'?: string;
    /**
     * 特別条項
     * @type {string}
     * @memberof Recap
     */
    'remarks'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof Recap
     */
    'note'?: string;
    /**
     * 支払条件説明
     * @type {string}
     * @memberof Recap
     */
    'paymentDueInformation'?: string;
    /**
     * 精算参照単価エリア
     * @type {string}
     * @memberof Recap
     */
    'settlementUnitPriceAreaId'?: string;
    /**
     * 交換側の確認かどうか
     * @type {boolean}
     * @memberof Recap
     */
    'isExchange': boolean;
}
/**
 * 
 * @export
 * @interface RecapOrganization
 */
export interface RecapOrganization {
    /**
     * 組織ID
     * @type {string}
     * @memberof RecapOrganization
     */
    'organizationId': string;
    /**
     * 
     * @type {MultiLocaleName}
     * @memberof RecapOrganization
     */
    'organizationName': MultiLocaleName;
    /**
     * 組織住所
     * @type {string}
     * @memberof RecapOrganization
     */
    'address': string;
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof RecapOrganization
     */
    'recapReceiver'?: RecapReceiverValue;
    /**
     * 
     * @type {EEXClearingHouseValue}
     * @memberof RecapOrganization
     */
    'eexClearingHouse'?: EEXClearingHouseValue;
    /**
     * 
     * @type {TOCOMBrokerValue}
     * @memberof RecapOrganization
     */
    'tocomBroker'?: TOCOMBrokerValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof RecapOrganization
     */
    'occtoMaster'?: OcctoMasterValue;
    /**
     * エリアID
     * @type {string}
     * @memberof RecapOrganization
     */
    'areaId': string;
    /**
     * クレジットスリーバーか否か
     * @type {boolean}
     * @memberof RecapOrganization
     */
    'isCreditSleeve': boolean;
}
/**
 * 
 * @export
 * @interface RecapReceiver
 */
export interface RecapReceiver {
    /**
     * 確認書担当者のID
     * @type {string}
     * @memberof RecapReceiver
     */
    'id': string;
    /**
     * 組織のID
     * @type {string}
     * @memberof RecapReceiver
     */
    'organizationId': string;
    /**
     * 部署
     * @type {string}
     * @memberof RecapReceiver
     */
    'department'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof RecapReceiver
     */
    'name': string;
    /**
     * 電話
     * @type {string}
     * @memberof RecapReceiver
     */
    'phoneNumber'?: string;
    /**
     * メール
     * @type {string}
     * @memberof RecapReceiver
     */
    'emailAddress'?: string;
    /**
     * 確認書担当者の作成者名
     * @type {string}
     * @memberof RecapReceiver
     */
    'createdParticipantName'?: string;
    /**
     * 確認書担当者の更新者名
     * @type {string}
     * @memberof RecapReceiver
     */
    'lastUpdatedParticipantName'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof RecapReceiver
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof RecapReceiver
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface RecapReceiverValue
 */
export interface RecapReceiverValue {
    /**
     * 名前
     * @type {string}
     * @memberof RecapReceiverValue
     */
    'name': string;
    /**
     * 部署
     * @type {string}
     * @memberof RecapReceiverValue
     */
    'department'?: string;
    /**
     * 電話
     * @type {string}
     * @memberof RecapReceiverValue
     */
    'phoneNumber'?: string;
    /**
     * メール
     * @type {string}
     * @memberof RecapReceiverValue
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @interface RemoveUserFromChannelTask
 */
export interface RemoveUserFromChannelTask {
    /**
     * 
     * @type {string}
     * @memberof RemoveUserFromChannelTask
     */
    'channelId': string;
    /**
     * 
     * @type {string}
     * @memberof RemoveUserFromChannelTask
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * レポートのID
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * レポートの種類。powerやgas、または両方
     * @type {Array<ReportType>}
     * @memberof Report
     */
    'reportTypes': Array<ReportType>;
    /**
     * 公開日
     * @type {string}
     * @memberof Report
     */
    'publishedAt': string;
    /**
     * 
     * @type {{ [key: string]: ReportContent; }}
     * @memberof Report
     */
    'reportContents': { [key: string]: ReportContent; };
}
/**
 * レポートコンテンツ
 * @export
 * @interface ReportContent
 */
export interface ReportContent {
    /**
     * レポートコンテンツのID
     * @type {string}
     * @memberof ReportContent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ReportContent
     */
    'description': string;
    /**
     * ファイル名
     * @type {string}
     * @memberof ReportContent
     */
    'attachmentName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    Gas: 'gas',
    Power: 'power'
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @interface RequestBalancingGroup
 */
export interface RequestBalancingGroup {
    /**
     * エリアのID
     * @type {string}
     * @memberof RequestBalancingGroup
     */
    'areaId': string;
    /**
     * BG名称
     * @type {string}
     * @memberof RequestBalancingGroup
     */
    'name': string;
    /**
     * BGコード
     * @type {string}
     * @memberof RequestBalancingGroup
     */
    'code': string;
    /**
     * 事業者コード
     * @type {string}
     * @memberof RequestBalancingGroup
     */
    'companyPrefix': string;
}
/**
 * 
 * @export
 * @interface RequestOrganizationEmailAddress
 */
export interface RequestOrganizationEmailAddress {
    /**
     * 組織メールアドレスの名称
     * @type {string}
     * @memberof RequestOrganizationEmailAddress
     */
    'name': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestOrganizationEmailAddress
     */
    'emailAddress': string;
    /**
     * 注文に関するメールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof RequestOrganizationEmailAddress
     */
    'receiveIndicationEmail': boolean;
    /**
     * Live-Marketに関するメールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof RequestOrganizationEmailAddress
     */
    'receiveLiveMarketEmail': boolean;
    /**
     * デリバティブ取引の確認書メールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof RequestOrganizationEmailAddress
     */
    'receiveDerivativeTradingRecapEmail': boolean;
    /**
     * 現物取引の確認書メールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof RequestOrganizationEmailAddress
     */
    'receiveSpotTradingRecapEmail': boolean;
    /**
     * レポートメールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof RequestOrganizationEmailAddress
     */
    'receiveReportEmail': boolean;
    /**
     * 請求書メールを受信するかどうかのフラグ
     * @type {boolean}
     * @memberof RequestOrganizationEmailAddress
     */
    'receiveInvoiceEmail': boolean;
}
/**
 * 
 * @export
 * @interface RequestRecapReceiver
 */
export interface RequestRecapReceiver {
    /**
     * 部署
     * @type {string}
     * @memberof RequestRecapReceiver
     */
    'department'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof RequestRecapReceiver
     */
    'name': string;
    /**
     * 電話
     * @type {string}
     * @memberof RequestRecapReceiver
     */
    'phoneNumber'?: string;
    /**
     * メール
     * @type {string}
     * @memberof RequestRecapReceiver
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @interface RequestSupplyPlanSubmitter
 */
export interface RequestSupplyPlanSubmitter {
    /**
     * エリアのID
     * @type {string}
     * @memberof RequestSupplyPlanSubmitter
     */
    'areaId': string;
    /**
     * 計画提出者の名称
     * @type {string}
     * @memberof RequestSupplyPlanSubmitter
     */
    'name': string;
    /**
     * コード
     * @type {string}
     * @memberof RequestSupplyPlanSubmitter
     */
    'code': string;
    /**
     * 事業者コード
     * @type {string}
     * @memberof RequestSupplyPlanSubmitter
     */
    'companyPrefix': string;
}
/**
 * ユーザーの役割
 * @export
 * @enum {string}
 */

export const RoleType = {
    Member: 'member',
    Owner: 'owner'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];


/**
 * 
 * @export
 * @interface SaveBroadcastMessageIndex
 */
export interface SaveBroadcastMessageIndex {
    /**
     * 
     * @type {string}
     * @memberof SaveBroadcastMessageIndex
     */
    'channelId': string;
    /**
     * 
     * @type {number}
     * @memberof SaveBroadcastMessageIndex
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof SaveBroadcastMessageIndex
     */
    'attributes'?: string;
}
/**
 * 
 * @export
 * @interface ScanMessageIndices
 */
export interface ScanMessageIndices {
    /**
     * 
     * @type {Array<ScanMessageIndicesIndicesInner>}
     * @memberof ScanMessageIndices
     */
    'indices': Array<ScanMessageIndicesIndicesInner>;
}
/**
 * 
 * @export
 * @interface ScanMessageIndicesIndicesInner
 */
export interface ScanMessageIndicesIndicesInner {
    /**
     * チャネルID
     * @type {string}
     * @memberof ScanMessageIndicesIndicesInner
     */
    'channelSid': string;
    /**
     * 既読の最終インデックス
     * @type {number}
     * @memberof ScanMessageIndicesIndicesInner
     */
    'lastConsumedIndex': number | null;
}
/**
 * 
 * @export
 * @interface SelectedBasicUnitPrice
 */
export interface SelectedBasicUnitPrice {
    /**
     * 
     * @type {BasicUnitPriceType}
     * @memberof SelectedBasicUnitPrice
     */
    'type': BasicUnitPriceType;
    /**
     * 基本料金単価
     * @type {string}
     * @memberof SelectedBasicUnitPrice
     */
    'unitPrice': string;
}
/**
 * 
 * @export
 * @interface SelectedPrice
 */
export interface SelectedPrice {
    /**
     * 
     * @type {PriceType}
     * @memberof SelectedPrice
     */
    'type': PriceType;
    /**
     * kWh単価
     * @type {Array<string>}
     * @memberof SelectedPrice
     */
    'energyUnitPrices': Array<string>;
    /**
     * 
     * @type {SelectedBasicUnitPrice}
     * @memberof SelectedPrice
     */
    'basicUnitPrice'?: SelectedBasicUnitPrice;
}
/**
 * 
 * @export
 * @interface Services
 */
export interface Services {
    /**
     * 
     * @type {ESquareService}
     * @memberof Services
     */
    'esquare': ESquareService;
    /**
     * 
     * @type {ECompassService}
     * @memberof Services
     */
    'ecompass': ECompassService;
    /**
     * 
     * @type {EClearService}
     * @memberof Services
     */
    'eclear': EClearService;
}
/**
 * 
 * @export
 * @interface SpecialCalendarDate
 */
export interface SpecialCalendarDate {
    /**
     * 特殊カレンダー日ID
     * @type {string}
     * @memberof SpecialCalendarDate
     */
    'id': string;
    /**
     * カレンダー区分ID
     * @type {string}
     * @memberof SpecialCalendarDate
     */
    'calendarTypeId': string;
    /**
     * 日付
     * @type {string}
     * @memberof SpecialCalendarDate
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface SpreadDeliveryTerms
 */
export interface SpreadDeliveryTerms {
    /**
     * 
     * @type {SpreadDeliveryTermsArea}
     * @memberof SpreadDeliveryTerms
     */
    'area': SpreadDeliveryTermsArea;
    /**
     * フォーマット済みの受給期間。年および月が終了日のものと同じ場合には省略される。週番号がある場合は週番号も含めて返却する。週番号が複数あっても最初の週番号のみを返す。
     * @type {string}
     * @memberof SpreadDeliveryTerms
     */
    'deliveryPeriod': string;
    /**
     * フォーマット前の開始日時(JST)
     * @type {string}
     * @memberof SpreadDeliveryTerms
     */
    'deliveryStartDate'?: string;
    /**
     * フォーマット前の終了日時(JST)
     * @type {string}
     * @memberof SpreadDeliveryTerms
     */
    'deliveryEndDate'?: string;
    /**
     * 
     * @type {SpreadDeliveryTermsHourType}
     * @memberof SpreadDeliveryTerms
     */
    'hourType': SpreadDeliveryTermsHourType;
    /**
     * BestAskの注文数量 未確定の場合は \"TBD\"
     * @type {string}
     * @memberof SpreadDeliveryTerms
     */
    'askVolume'?: string;
    /**
     * BestBidの注文数量 未確定の場合は \"TBD\"
     * @type {string}
     * @memberof SpreadDeliveryTerms
     */
    'bidVolume'?: string;
    /**
     * 直近約定の注文数量
     * @type {string}
     * @memberof SpreadDeliveryTerms
     */
    'lastVolume'?: string;
}
/**
 * 
 * @export
 * @interface SpreadDeliveryTermsArea
 */
export interface SpreadDeliveryTermsArea {
    /**
     * エリアID
     * @type {string}
     * @memberof SpreadDeliveryTermsArea
     */
    'id': string;
    /**
     * エリア名
     * @type {string}
     * @memberof SpreadDeliveryTermsArea
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SpreadDeliveryTermsHourType
 */
export interface SpreadDeliveryTermsHourType {
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof SpreadDeliveryTermsHourType
     */
    'id': string;
    /**
     * 時間帯区分名
     * @type {string}
     * @memberof SpreadDeliveryTermsHourType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SpreadType
 */
export interface SpreadType {
    /**
     * スプレッド名称
     * @type {string}
     * @memberof SpreadType
     */
    'name': string;
    /**
     * スプレッド区分
     * @type {string}
     * @memberof SpreadType
     */
    'type': SpreadTypeTypeEnum;
}

export const SpreadTypeTypeEnum = {
    Area: 'area',
    DeliveryPattern: 'deliveryPattern',
    DeliveryPeriod: 'deliveryPeriod'
} as const;

export type SpreadTypeTypeEnum = typeof SpreadTypeTypeEnum[keyof typeof SpreadTypeTypeEnum];

/**
 * 
 * @export
 * @interface StandardIndicationSelectableInputs
 */
export interface StandardIndicationSelectableInputs {
    /**
     * 売買区分一覧
     * @type {Array<PositionTypeOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'positions': Array<PositionTypeOption>;
    /**
     * 受給単位一覧
     * @type {Array<DeliveryUnitOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'deliveryUnits': Array<DeliveryUnitOption>;
    /**
     * 選択可能な商品区分一覧
     * @type {Array<ProductTypeOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'productTypes': Array<ProductTypeOption>;
    /**
     * 選択可能なエリア一覧
     * @type {Array<AreaOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'areas': Array<AreaOption>;
    /**
     * 選択可能な時間帯区分一覧
     * @type {Array<HourTypeOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'hourTypes': Array<HourTypeOption>;
    /**
     * 選択可能な開始日一覧
     * @type {Array<DateOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'deliveryStartDates'?: Array<DateOption>;
    /**
     * 選択可能な終了日一覧
     * @type {Array<DateOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'deliveryEndDates'?: Array<DateOption>;
    /**
     * 選択可能な開始月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'deliveryStartYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 選択可能な終了月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'deliveryEndYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 交換側の選択可能なエリア一覧
     * @type {Array<AreaOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeAreas'?: Array<AreaOption>;
    /**
     * 交換側の選択可能な時間帯区分一覧
     * @type {Array<HourTypeOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeHourTypes'?: Array<HourTypeOption>;
    /**
     * 交換側の選択可能な開始日一覧
     * @type {Array<DateOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeDeliveryStartDates'?: Array<DateOption>;
    /**
     * 交換側の選択可能な終了日一覧
     * @type {Array<DateOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeDeliveryEndDates'?: Array<DateOption>;
    /**
     * 交換側の選択可能な開始月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeDeliveryStartYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 交換側の選択可能な終了月一覧
     * @type {Array<DeliveryYearMonthOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeDeliveryEndYearMonths'?: Array<DeliveryYearMonthOption>;
    /**
     * 選択可能な燃調区分一覧、燃調ありを選択している場合のみ返却される
     * @type {Array<FuelSurchargeTypeOption>}
     * @memberof StandardIndicationSelectableInputs
     */
    'fuelSurchargeTypes': Array<FuelSurchargeTypeOption>;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof StandardIndicationSelectableInputs
     */
    'unitPrice': ValidatableValue;
    /**
     * 
     * @type {ValidatablePrice}
     * @memberof StandardIndicationSelectableInputs
     */
    'price'?: ValidatablePrice;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof StandardIndicationSelectableInputs
     */
    'volume': ValidatableValue;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof StandardIndicationSelectableInputs
     */
    'exchangeVolume'?: ValidatableValue;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof StandardIndicationSelectableInputs
     */
    'request': string;
    /**
     * 注文についての備考
     * @type {string}
     * @memberof StandardIndicationSelectableInputs
     */
    'publicInformation'?: string;
    /**
     * エリアスプレッドを入力中かどうか
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputs
     */
    'isAreaSpread': boolean;
    /**
     * 期間スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputs
     */
    'isDeliveryPeriodSpread': boolean;
    /**
     * 時間帯スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputs
     */
    'isDeliveryPatternSpread': boolean;
    /**
     * 入力内容が有効かどうか。有効であれば注文の作成や変更が実行できる
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputs
     */
    'isValid': boolean;
    /**
     * 呪文のバリデーションエラーのメッセージ。エラーなしの場合はundefined
     * @type {string}
     * @memberof StandardIndicationSelectableInputs
     */
    'spellValidationErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface StandardIndicationSelectableInputsRequest
 */
export interface StandardIndicationSelectableInputsRequest {
    /**
     * 注文ID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'indicationId'?: string;
    /**
     * 受給条件ID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'deliveryTermsId'?: string;
    /**
     * 交換側の受給条件ID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryTermsId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'position'?: PositionType;
    /**
     * ユーザーが入力している商品区分のID
     * @type {Array<string>}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'productTypeIds'?: Array<string>;
    /**
     * ユーザーが入力しているエリアのID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'areaId'?: string;
    /**
     * ユーザーが入力している時間帯区分のID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'hourTypeId'?: string;
    /**
     * ユーザーが入力している開始日 yyyy-MM-dd
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'deliveryStartDate'?: string;
    /**
     * ユーザーが入力している終了日 yyyy-MMdd
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'deliveryEndDate'?: string;
    /**
     * ユーザーが入力している交換側のエリアのID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'exchangeAreaId'?: string;
    /**
     * ユーザーが入力している交換側の時間帯区分のID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'exchangeHourTypeId'?: string;
    /**
     * ユーザーが入力している交換側の開始日 yyyy-MM-dd
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryStartDate'?: string;
    /**
     * ユーザーが入力している交換側の終了日 yyyy-MMdd
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'exchangeDeliveryEndDate'?: string;
    /**
     * ユーザーが入力している燃調区分のID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * 
     * @type {DeliveryUnit}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'deliveryUnit'?: DeliveryUnit;
    /**
     * 注文単価
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'unitPrice'?: string;
    /**
     * 
     * @type {SelectedPrice}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'price'?: SelectedPrice;
    /**
     * 注文数量
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'volume'?: string;
    /**
     * 交換側の注文数量
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'exchangeVolume'?: string;
    /**
     * 自由記入欄に記入された文字列
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'request'?: string;
    /**
     * 注文についての備考
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'publicInformation'?: string;
    /**
     * エリアスプレッドを入力中かどうか
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'isAreaSpread'?: boolean;
    /**
     * 期間スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'isDeliveryPeriodSpread'?: boolean;
    /**
     * 時間帯スプレッドを入力中かどうか
     * @type {boolean}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'isDeliveryPatternSpread'?: boolean;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'traderId'?: string;
    /**
     * 組織ID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequest
     */
    'organizationId'?: string;
}
/**
 * 
 * @export
 * @interface StandardIndicationSelectableInputsRequestAllOf
 */
export interface StandardIndicationSelectableInputsRequestAllOf {
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequestAllOf
     */
    'traderId'?: string;
    /**
     * 組織ID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsRequestAllOf
     */
    'organizationId'?: string;
}
/**
 * 
 * @export
 * @interface StandardIndicationSelectableInputsSpellRequest
 */
export interface StandardIndicationSelectableInputsSpellRequest {
    /**
     * 呪文
     * @type {string}
     * @memberof StandardIndicationSelectableInputsSpellRequest
     */
    'spell': string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof StandardIndicationSelectableInputsSpellRequest
     */
    'traderId'?: string;
}
/**
 * 
 * @export
 * @interface StarredChannel
 */
export interface StarredChannel {
    /**
     * スター付きにしたいチャンネルのID
     * @type {string}
     * @memberof StarredChannel
     */
    'channelId': string;
}
/**
 * 初回サインイン処理が完了しているか(利用規約への同意や名前の入力など)
 * @export
 * @enum {string}
 */

export const Status = {
    Active: 'active',
    Invited: 'invited'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface SupplyPlanSubmitter
 */
export interface SupplyPlanSubmitter {
    /**
     * 計画提出者のID
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'id': string;
    /**
     * 組織のID
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'organizationId': string;
    /**
     * エリアのID
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'areaId': string;
    /**
     * 計画提出者の名称
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'name': string;
    /**
     * 計画提出者コード
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'code': string;
    /**
     * 事業者コード
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'companyPrefix': string;
    /**
     * 作成者名
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'createdParticipantName'?: string;
    /**
     * 更新者名
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'lastUpdatedParticipantName'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof SupplyPlanSubmitter
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TOCOMBroker
 */
export interface TOCOMBroker {
    /**
     * TOCOMブローカーのID
     * @type {string}
     * @memberof TOCOMBroker
     */
    'id': string;
    /**
     * 名前
     * @type {string}
     * @memberof TOCOMBroker
     */
    'name': string;
    /**
     * 立会外コード
     * @type {string}
     * @memberof TOCOMBroker
     */
    'offFloorTradingCode': string;
    /**
     * 作成日
     * @type {string}
     * @memberof TOCOMBroker
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof TOCOMBroker
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TOCOMBrokerValue
 */
export interface TOCOMBrokerValue {
    /**
     * 名前
     * @type {string}
     * @memberof TOCOMBrokerValue
     */
    'name': string;
    /**
     * 立会外コード
     * @type {string}
     * @memberof TOCOMBrokerValue
     */
    'offFloorTradingCode': string;
}
/**
 * 
 * @export
 * @interface TOCOMClearingInformation
 */
export interface TOCOMClearingInformation {
    /**
     * 
     * @type {Array<TOCOMClearingInformationItem>}
     * @memberof TOCOMClearingInformation
     */
    'items': Array<TOCOMClearingInformationItem>;
    /**
     * 
     * @type {ClearingInformationParticipantSummaryItem}
     * @memberof TOCOMClearingInformation
     */
    'participantSummary': ClearingInformationParticipantSummaryItem;
}
/**
 * 
 * @export
 * @interface TOCOMClearingInformationItem
 */
export interface TOCOMClearingInformationItem {
    /**
     * TOCOMブローカー情報のID
     * @type {string}
     * @memberof TOCOMClearingInformationItem
     */
    'id': string;
    /**
     * 組織のID
     * @type {string}
     * @memberof TOCOMClearingInformationItem
     */
    'organizationId': string;
    /**
     * 
     * @type {TOCOMBroker}
     * @memberof TOCOMClearingInformationItem
     */
    'tocomBroker': TOCOMBroker;
    /**
     * 作成日
     * @type {string}
     * @memberof TOCOMClearingInformationItem
     */
    'createdAt': string;
    /**
     * 更新日
     * @type {string}
     * @memberof TOCOMClearingInformationItem
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TimeRange
 */
export interface TimeRange {
    /**
     * 
     * @type {number}
     * @memberof TimeRange
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof TimeRange
     */
    'end': number;
}
/**
 * 
 * @export
 * @interface TradingSteps
 */
export interface TradingSteps {
    /**
     * 
     * @type {TradingStepsAskIndication}
     * @memberof TradingSteps
     */
    'askIndication'?: TradingStepsAskIndication;
    /**
     * 
     * @type {TradingStepsBidIndication}
     * @memberof TradingSteps
     */
    'bidIndication'?: TradingStepsBidIndication;
    /**
     * 
     * @type {TradingStepsNegotiation}
     * @memberof TradingSteps
     */
    'negotiation'?: TradingStepsNegotiation;
    /**
     * 約定の情報。クレジットスリーバーありの約定の場合は2件、それ以外の約定は1件返却する。約定していない場合は空の配列を返す。
     * @type {Array<TradingStepsDealsInner>}
     * @memberof TradingSteps
     */
    'deals': Array<TradingStepsDealsInner>;
}
/**
 * 売り注文の情報。交渉が開始していない場合は売り注文、または買い注文が空になる
 * @export
 * @interface TradingStepsAskIndication
 */
export interface TradingStepsAskIndication {
    /**
     * 売り側の注文ID
     * @type {string}
     * @memberof TradingStepsAskIndication
     */
    'id': string;
    /**
     * 売り側の組織名
     * @type {string}
     * @memberof TradingStepsAskIndication
     */
    'organizationName': string;
}
/**
 * 買い注文の情報。交渉が開始していない場合は売り注文、または買い注文が空になる
 * @export
 * @interface TradingStepsBidIndication
 */
export interface TradingStepsBidIndication {
    /**
     * 買い側の注文ID
     * @type {string}
     * @memberof TradingStepsBidIndication
     */
    'id': string;
    /**
     * 買い側の組織名
     * @type {string}
     * @memberof TradingStepsBidIndication
     */
    'organizationName': string;
}
/**
 * 約定
 * @export
 * @interface TradingStepsDealsInner
 */
export interface TradingStepsDealsInner {
    /**
     * 約定ID
     * @type {string}
     * @memberof TradingStepsDealsInner
     */
    'id': string;
    /**
     * 売り側の組織名
     * @type {string}
     * @memberof TradingStepsDealsInner
     */
    'askOrganizationName': string;
    /**
     * 買い側の組織名
     * @type {string}
     * @memberof TradingStepsDealsInner
     */
    'bidOrganizationName': string;
}
/**
 * 交渉の情報。交渉が開始していない場合は空になる
 * @export
 * @interface TradingStepsNegotiation
 */
export interface TradingStepsNegotiation {
    /**
     * 交渉ID
     * @type {string}
     * @memberof TradingStepsNegotiation
     */
    'id': string;
    /**
     * 売り側の組織名
     * @type {string}
     * @memberof TradingStepsNegotiation
     */
    'askOrganizationName': string;
    /**
     * 買い側の組織名
     * @type {string}
     * @memberof TradingStepsNegotiation
     */
    'bidOrganizationName': string;
    /**
     * クレジットスリーバーの組織名
     * @type {string}
     * @memberof TradingStepsNegotiation
     */
    'creditSleeverOrganizationName'?: string;
}
/**
 * 
 * @export
 * @interface Translation
 */
export interface Translation {
    /**
     * 
     * @type {Locale}
     * @memberof Translation
     */
    'locale': Locale;
    /**
     * 内容
     * @type {string}
     * @memberof Translation
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface UnreadBroadcastMessagesCount
 */
export interface UnreadBroadcastMessagesCount {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof UnreadBroadcastMessagesCount
     */
    'unreadBroadcastMessagesCount': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface UpdateBrokerageFeePaymentConfiguration
 */
export interface UpdateBrokerageFeePaymentConfiguration {
    /**
     * 
     * @type {BrokerageFeePaymentTiming}
     * @memberof UpdateBrokerageFeePaymentConfiguration
     */
    'spotTrading'?: BrokerageFeePaymentTiming;
    /**
     * 
     * @type {BrokerageFeePaymentTiming}
     * @memberof UpdateBrokerageFeePaymentConfiguration
     */
    'derivativeTrading'?: BrokerageFeePaymentTiming;
}
/**
 * 
 * @export
 * @interface UpdateCreditSleever
 */
export interface UpdateCreditSleever {
    /**
     * クレジットスリーバーの組織ID
     * @type {string}
     * @memberof UpdateCreditSleever
     */
    'organizationId'?: string;
    /**
     * クレジットスリーブの値差(これまで手数料と呼んでいたもの)
     * @type {number}
     * @memberof UpdateCreditSleever
     */
    'sleeveSpread'?: number;
    /**
     * 売り側のクレジットスリーブ仲介手数料
     * @type {number}
     * @memberof UpdateCreditSleever
     */
    'askBrokingFee'?: number;
    /**
     * 買い側のクレジットスリーブ仲介手数料
     * @type {number}
     * @memberof UpdateCreditSleever
     */
    'bidBrokingFee'?: number;
    /**
     * 
     * @type {UpdateEEXPostingInformation}
     * @memberof UpdateCreditSleever
     */
    'eexPostingInformation'?: UpdateEEXPostingInformation;
}
/**
 * 
 * @export
 * @interface UpdateDeal
 */
export interface UpdateDeal {
    /**
     * 
     * @type {UpdateDealDeal}
     * @memberof UpdateDeal
     */
    'deal'?: UpdateDealDeal;
    /**
     * 
     * @type {UpdateDealContractCondition}
     * @memberof UpdateDeal
     */
    'contractCondition'?: UpdateDealContractCondition;
    /**
     * 
     * @type {Array<UpdateOrganizationRecap>}
     * @memberof UpdateDeal
     */
    'recapsOfAskOrganization'?: Array<UpdateOrganizationRecap>;
    /**
     * 
     * @type {Array<UpdateOrganizationRecap>}
     * @memberof UpdateDeal
     */
    'recapsOfBidOrganization'?: Array<UpdateOrganizationRecap>;
}
/**
 * 
 * @export
 * @interface UpdateDealContractCondition
 */
export interface UpdateDealContractCondition {
    /**
     * 制約事項
     * @type {string}
     * @memberof UpdateDealContractCondition
     */
    'restrictions'?: string;
    /**
     * 特別条項
     * @type {string}
     * @memberof UpdateDealContractCondition
     */
    'remarks'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof UpdateDealContractCondition
     */
    'note'?: string;
    /**
     * 支払条件説明
     * @type {string}
     * @memberof UpdateDealContractCondition
     */
    'paymentDueInformation'?: string;
    /**
     * 精算参照単価エリア
     * @type {string}
     * @memberof UpdateDealContractCondition
     */
    'settlementUnitPriceAreaId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDealDeal
 */
export interface UpdateDealDeal {
    /**
     * 約定日時
     * @type {string}
     * @memberof UpdateDealDeal
     */
    'executedAt'?: string;
    /**
     * P&C
     * @type {boolean}
     * @memberof UpdateDealDeal
     */
    'privateConfidential'?: boolean;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof UpdateDealDeal
     */
    'excludedEnecCurve'?: boolean;
    /**
     * マーケットで表示する際に、実際の約定の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof UpdateDealDeal
     */
    'maskVolume'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDefaultBrokingFeeOfOrganization
 */
export interface UpdateDefaultBrokingFeeOfOrganization {
    /**
     * 商品区分ID
     * @type {string}
     * @memberof UpdateDefaultBrokingFeeOfOrganization
     */
    'productTypeId': string;
    /**
     * 仲介手数料
     * @type {number}
     * @memberof UpdateDefaultBrokingFeeOfOrganization
     */
    'brokingFee': number;
}
/**
 * 
 * @export
 * @interface UpdateDefaultBrokingFeeOfOrganizations
 */
export interface UpdateDefaultBrokingFeeOfOrganizations {
    /**
     * 
     * @type {Array<UpdateDefaultBrokingFeeOfOrganization>}
     * @memberof UpdateDefaultBrokingFeeOfOrganizations
     */
    'defaultBrokingFees': Array<UpdateDefaultBrokingFeeOfOrganization>;
}
/**
 * ユーザー自身で変更できる項目
 * @export
 * @interface UpdateEClearUserSetting
 */
export interface UpdateEClearUserSetting {
    /**
     * 確認キー
     * @type {string}
     * @memberof UpdateEClearUserSetting
     */
    'keyword'?: string;
}
/**
 * 更新用のEEXのポスティング情報
 * @export
 * @interface UpdateEEXPostingInformation
 */
export interface UpdateEEXPostingInformation {
    /**
     * EEXトレーダーのユーザーID
     * @type {string}
     * @memberof UpdateEEXPostingInformation
     */
    'traderId'?: string;
    /**
     * EEXクリアリングアカウントコード
     * @type {string}
     * @memberof UpdateEEXPostingInformation
     */
    'eexClearingAccountCode'?: string;
}
/**
 * 組織とEEXトレーディングファームのひも付きの更新
 * @export
 * @interface UpdateEEXTradingFirm
 */
export interface UpdateEEXTradingFirm {
    /**
     * EEXトレーディングファームコード
     * @type {string}
     * @memberof UpdateEEXTradingFirm
     */
    'eexTradingFirmCode': string | null;
}
/**
 * システムのみ変更できる項目
 * @export
 * @interface UpdateESquareAppSetting
 */
export interface UpdateESquareAppSetting {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareAppSetting
     */
    'receiveIndicationEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareAppSetting
     */
    'receiveLiveMarketEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareAppSetting
     */
    'receiveDerivativeTradingRecapEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareAppSetting
     */
    'receiveSpotTradingRecapEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareAppSetting
     */
    'receiveReportEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareAppSetting
     */
    'receiveInvoiceEmail'?: boolean;
}
/**
 * ユーザー自身で変更できる項目
 * @export
 * @interface UpdateESquareUserSetting
 */
export interface UpdateESquareUserSetting {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareUserSetting
     */
    'chatNotification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareUserSetting
     */
    'positionReversed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateESquareUserSetting
     */
    'colorReversed'?: boolean;
    /**
     * 
     * @type {IndicationGroupingKey}
     * @memberof UpdateESquareUserSetting
     */
    'indicationGroupingKey'?: IndicationGroupingKey;
}
/**
 * 
 * @export
 * @interface UpdateIndication
 */
export interface UpdateIndication {
    /**
     * 商品IDのリスト
     * @type {Array<string>}
     * @memberof UpdateIndication
     */
    'productIds'?: Array<string>;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdateIndication
     */
    'position'?: PositionType;
    /**
     * 単価
     * @type {number}
     * @memberof UpdateIndication
     */
    'unitPrice'?: number;
    /**
     * 数量
     * @type {number}
     * @memberof UpdateIndication
     */
    'volume'?: number | null;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof UpdateIndication
     */
    'fuelSurchargeTypeId'?: string | null;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof UpdateIndication
     */
    'status'?: IndicationStatusType;
    /**
     * 注文がP&Cかどうか
     * @type {boolean}
     * @memberof UpdateIndication
     */
    'privateConfidential'?: boolean;
    /**
     * 直近の更新者
     * @type {string}
     * @memberof UpdateIndication
     */
    'lastUpdatedUserId': string;
}
/**
 * 
 * @export
 * @interface UpdateInformation
 */
export interface UpdateInformation {
    /**
     * タイトル
     * @type {Array<Translation>}
     * @memberof UpdateInformation
     */
    'title'?: Array<Translation>;
    /**
     * 内容
     * @type {Array<Translation>}
     * @memberof UpdateInformation
     */
    'message'?: Array<Translation>;
    /**
     * 
     * @type {InformationType}
     * @memberof UpdateInformation
     */
    'informationType'?: InformationType;
    /**
     * お知らせの有効期限
     * @type {string}
     * @memberof UpdateInformation
     */
    'expiresAt'?: string;
    /**
     * お知らせが有効か否か
     * @type {boolean}
     * @memberof UpdateInformation
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateLastReadMessageIndexTask
 */
export interface UpdateLastReadMessageIndexTask {
    /**
     * 
     * @type {string}
     * @memberof UpdateLastReadMessageIndexTask
     */
    'channelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLastReadMessageIndexTask
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLastReadMessageIndexTask
     */
    'index': number;
    /**
     * 更新日
     * @type {string}
     * @memberof UpdateLastReadMessageIndexTask
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface UpdateMyIndication
 */
export interface UpdateMyIndication {
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof UpdateMyIndication
     */
    'productTypeIds'?: Array<string>;
    /**
     * 
     * @type {UpdateStandardIndicationBase}
     * @memberof UpdateMyIndication
     */
    'base'?: UpdateStandardIndicationBase;
    /**
     * 
     * @type {UpdateStandardIndicationExchange}
     * @memberof UpdateMyIndication
     */
    'exchange'?: UpdateStandardIndicationExchange | null;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof UpdateMyIndication
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdateMyIndication
     */
    'position'?: PositionType;
    /**
     * 
     * @type {Price}
     * @memberof UpdateMyIndication
     */
    'price'?: Price;
    /**
     * 要望事項
     * @type {string}
     * @memberof UpdateMyIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof UpdateMyIndication
     */
    'publicInformation'?: string;
}
/**
 * 
 * @export
 * @interface UpdateNegotiation
 */
export interface UpdateNegotiation {
    /**
     * 
     * @type {NegotiationStatusType}
     * @memberof UpdateNegotiation
     */
    'status'?: NegotiationStatusType;
    /**
     * スプレッド基準単価
     * @type {number}
     * @memberof UpdateNegotiation
     */
    'spreadBasePrice'?: number;
    /**
     * カレンダータイプID
     * @type {string}
     * @memberof UpdateNegotiation
     */
    'calendarTypeId'?: string;
    /**
     * 交換側のカレンダータイプID
     * @type {string}
     * @memberof UpdateNegotiation
     */
    'exchangeCalendarTypeId'?: string;
    /**
     * 
     * @type {UpdateNegotiationDetail}
     * @memberof UpdateNegotiation
     */
    'askNegotiationDetail'?: UpdateNegotiationDetail;
    /**
     * 
     * @type {UpdateNegotiationDetail}
     * @memberof UpdateNegotiation
     */
    'bidNegotiationDetail'?: UpdateNegotiationDetail;
    /**
     * 
     * @type {UpdateCreditSleever}
     * @memberof UpdateNegotiation
     */
    'creditSleever'?: UpdateCreditSleever;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof UpdateNegotiation
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface UpdateNegotiationDetail
 */
export interface UpdateNegotiationDetail {
    /**
     * 商品区分ID
     * @type {string}
     * @memberof UpdateNegotiationDetail
     */
    'productTypeId'?: string;
    /**
     * 
     * @type {Price}
     * @memberof UpdateNegotiationDetail
     */
    'afterPrice'?: Price;
    /**
     * 交渉後数量
     * @type {Array<number>}
     * @memberof UpdateNegotiationDetail
     */
    'afterVolumes'?: Array<number>;
    /**
     * 交換側の交渉後数量
     * @type {Array<number>}
     * @memberof UpdateNegotiationDetail
     */
    'afterExchangeVolumes'?: Array<number>;
    /**
     * 仲介単価
     * @type {number}
     * @memberof UpdateNegotiationDetail
     */
    'brokingFee'?: number;
    /**
     * 交換側の仲介単価
     * @type {number}
     * @memberof UpdateNegotiationDetail
     */
    'exchangeBrokingFee'?: number;
    /**
     * 
     * @type {UpdateEEXPostingInformation}
     * @memberof UpdateNegotiationDetail
     */
    'eexPostingInformation'?: UpdateEEXPostingInformation;
}
/**
 * 
 * @export
 * @interface UpdateNonStandardIndication
 */
export interface UpdateNonStandardIndication {
    /**
     * 受給期間
     * @type {Array<NewDeliveryPeriod>}
     * @memberof UpdateNonStandardIndication
     */
    'deliveryPeriods'?: Array<NewDeliveryPeriod>;
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof UpdateNonStandardIndication
     */
    'productTypeIds'?: Array<string>;
    /**
     * エリアID
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'areaId'?: string;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * マーケットに公開フラグ。注文が板に表示されるか
     * @type {boolean}
     * @memberof UpdateNonStandardIndication
     */
    'openToMarket'?: boolean;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof UpdateNonStandardIndication
     */
    'isVisibleToTraders'?: boolean;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'organizationId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdateNonStandardIndication
     */
    'position'?: PositionType;
    /**
     * 単価
     * @type {Array<number>}
     * @memberof UpdateNonStandardIndication
     */
    'unitPrices'?: Array<number>;
    /**
     * 数量
     * @type {Array<number>}
     * @memberof UpdateNonStandardIndication
     */
    'volumes'?: Array<number>;
    /**
     * 要望事項
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'publicInformation'?: string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'brokerId'?: string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'traderId'?: string;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof UpdateNonStandardIndication
     */
    'excludedEnecCurve'?: boolean;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof UpdateNonStandardIndication
     */
    'maskVolume'?: boolean;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof UpdateNonStandardIndication
     */
    'status'?: IndicationStatusType;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof UpdateNonStandardIndication
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface UpdateOrganization
 */
export interface UpdateOrganization {
    /**
     * 
     * @type {UpdateOrganizationTradingCompanyName}
     * @memberof UpdateOrganization
     */
    'tradingCompanyName'?: UpdateOrganizationTradingCompanyName;
    /**
     * 確認書に記載する住所
     * @type {string}
     * @memberof UpdateOrganization
     */
    'recapAddress'?: string;
    /**
     * レポートの種類。powerやgas、または両方
     * @type {Array<ReadableReportType>}
     * @memberof UpdateOrganization
     */
    'readableReportTypes'?: Array<ReadableReportType>;
    /**
     * eClear契約締結済みか?
     * @type {boolean}
     * @memberof UpdateOrganization
     */
    'isEClearContracted'?: boolean;
    /**
     * 請求書に消費税を含めるかどうか
     * @type {boolean}
     * @memberof UpdateOrganization
     */
    'isSalesTaxIncludedInInvoice'?: boolean;
    /**
     * esquareが利用可能か？
     * @type {boolean}
     * @memberof UpdateOrganization
     */
    'isEsquareEnabled'?: boolean;
    /**
     * eCompassが利用可能か？
     * @type {boolean}
     * @memberof UpdateOrganization
     */
    'isEcompassEnabled'?: boolean;
    /**
     * eCompass for GXが利用可能か？
     * @type {boolean}
     * @memberof UpdateOrganization
     */
    'isEcompassForGxEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationRecap
 */
export interface UpdateOrganizationRecap {
    /**
     * 確認ID
     * @type {string}
     * @memberof UpdateOrganizationRecap
     */
    'id': string;
    /**
     * エリアID
     * @type {string}
     * @memberof UpdateOrganizationRecap
     */
    'areaId'?: string;
    /**
     * 
     * @type {RecapReceiverValue}
     * @memberof UpdateOrganizationRecap
     */
    'recapReceiver'?: RecapReceiverValue;
    /**
     * 
     * @type {EEXClearingHouseValue}
     * @memberof UpdateOrganizationRecap
     */
    'eexClearingHouse'?: EEXClearingHouseValue;
    /**
     * 
     * @type {TOCOMBrokerValue}
     * @memberof UpdateOrganizationRecap
     */
    'tocomBroker'?: TOCOMBrokerValue;
    /**
     * 
     * @type {OcctoMasterValue}
     * @memberof UpdateOrganizationRecap
     */
    'occtoMaster'?: OcctoMasterValue;
}
/**
 * 約定や確認書など対外的に出る正式名称
 * @export
 * @interface UpdateOrganizationTradingCompanyName
 */
export interface UpdateOrganizationTradingCompanyName {
    /**
     * 日本語の正式名称
     * @type {string}
     * @memberof UpdateOrganizationTradingCompanyName
     */
    'ja': string;
    /**
     * 英語の正式名称
     * @type {string}
     * @memberof UpdateOrganizationTradingCompanyName
     */
    'en': string;
}
/**
 * 
 * @export
 * @interface UpdateStandardIndication
 */
export interface UpdateStandardIndication {
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof UpdateStandardIndication
     */
    'productTypeIds'?: Array<string>;
    /**
     * 
     * @type {UpdateStandardIndicationBase}
     * @memberof UpdateStandardIndication
     */
    'base'?: UpdateStandardIndicationBase;
    /**
     * 
     * @type {UpdateStandardIndicationExchange}
     * @memberof UpdateStandardIndication
     */
    'exchange'?: UpdateStandardIndicationExchange | null;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * マーケットに公開フラグ。注文が板に表示されるか
     * @type {boolean}
     * @memberof UpdateStandardIndication
     */
    'openToMarket'?: boolean;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof UpdateStandardIndication
     */
    'isVisibleToTraders'?: boolean;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'organizationId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdateStandardIndication
     */
    'position'?: PositionType;
    /**
     * 
     * @type {Price}
     * @memberof UpdateStandardIndication
     */
    'price'?: Price;
    /**
     * 要望事項
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'publicInformation'?: string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'brokerId'?: string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'traderId'?: string;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof UpdateStandardIndication
     */
    'excludedEnecCurve'?: boolean;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof UpdateStandardIndication
     */
    'maskVolume'?: boolean;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof UpdateStandardIndication
     */
    'status'?: IndicationStatusType;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof UpdateStandardIndication
     */
    'memo'?: string;
}
/**
 * 基準の注文情報
 * @export
 * @interface UpdateStandardIndicationBase
 */
export interface UpdateStandardIndicationBase {
    /**
     * エリアID
     * @type {string}
     * @memberof UpdateStandardIndicationBase
     */
    'areaId'?: string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof UpdateStandardIndicationBase
     */
    'hourTypeId'?: string;
    /**
     * 受給開始日
     * @type {string}
     * @memberof UpdateStandardIndicationBase
     */
    'deliveryStartDate'?: string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof UpdateStandardIndicationBase
     */
    'deliveryEndDate'?: string;
    /**
     * 数量
     * @type {number}
     * @memberof UpdateStandardIndicationBase
     */
    'volume'?: number | null;
}
/**
 * 交換側の注文情報
 * @export
 * @interface UpdateStandardIndicationExchange
 */
export interface UpdateStandardIndicationExchange {
    /**
     * エリアID
     * @type {string}
     * @memberof UpdateStandardIndicationExchange
     */
    'areaId'?: string;
    /**
     * 時間帯区分ID
     * @type {string}
     * @memberof UpdateStandardIndicationExchange
     */
    'hourTypeId'?: string;
    /**
     * 受給開始日
     * @type {string}
     * @memberof UpdateStandardIndicationExchange
     */
    'deliveryStartDate'?: string;
    /**
     * 受給終了日
     * @type {string}
     * @memberof UpdateStandardIndicationExchange
     */
    'deliveryEndDate'?: string;
    /**
     * 数量
     * @type {number}
     * @memberof UpdateStandardIndicationExchange
     */
    'volume'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateStandardIndicationWithId
 */
export interface UpdateStandardIndicationWithId {
    /**
     * 注文ID
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'id': string;
    /**
     * 商品区分のID。複数の商品区分を指定可能
     * @type {Array<string>}
     * @memberof UpdateStandardIndicationWithId
     */
    'productTypeIds'?: Array<string>;
    /**
     * 
     * @type {UpdateStandardIndicationBase}
     * @memberof UpdateStandardIndicationWithId
     */
    'base'?: UpdateStandardIndicationBase;
    /**
     * 
     * @type {UpdateStandardIndicationExchange}
     * @memberof UpdateStandardIndicationWithId
     */
    'exchange'?: UpdateStandardIndicationExchange | null;
    /**
     * 燃調区分ID
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'fuelSurchargeTypeId'?: string;
    /**
     * マーケットに公開フラグ。注文が板に表示されるか
     * @type {boolean}
     * @memberof UpdateStandardIndicationWithId
     */
    'openToMarket'?: boolean;
    /**
     * トレーダーに公開フラグ。注文が履歴に表示されるか
     * @type {boolean}
     * @memberof UpdateStandardIndicationWithId
     */
    'isVisibleToTraders'?: boolean;
    /**
     * 注文を所有する組織ID
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'organizationId'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdateStandardIndicationWithId
     */
    'position'?: PositionType;
    /**
     * 
     * @type {Price}
     * @memberof UpdateStandardIndicationWithId
     */
    'price'?: Price;
    /**
     * 要望事項
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'request'?: string;
    /**
     * infomationアイコンでの表示内容
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'publicInformation'?: string;
    /**
     * 担当ブローカーのユーザーID
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'brokerId'?: string;
    /**
     * 担当トレーダーのユーザーID
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'traderId'?: string;
    /**
     * enechainカーブの対象外か
     * @type {boolean}
     * @memberof UpdateStandardIndicationWithId
     */
    'excludedEnecCurve'?: boolean;
    /**
     * マーケットで表示する際に、実際の注文の数量をTBDとして隠すかどうか
     * @type {boolean}
     * @memberof UpdateStandardIndicationWithId
     */
    'maskVolume'?: boolean;
    /**
     * 
     * @type {IndicationStatusType}
     * @memberof UpdateStandardIndicationWithId
     */
    'status'?: IndicationStatusType;
    /**
     * 自由記入欄
     * @type {string}
     * @memberof UpdateStandardIndicationWithId
     */
    'memo'?: string;
}
/**
 * 
 * @export
 * @interface UpdateStandardIndicationsRequest
 */
export interface UpdateStandardIndicationsRequest {
    /**
     * 更新する注文のリスト
     * @type {Array<UpdateStandardIndicationWithId>}
     * @memberof UpdateStandardIndicationsRequest
     */
    'indications': Array<UpdateStandardIndicationWithId>;
}
/**
 * 
 * @export
 * @interface UpdateTOCOMClearingInformation
 */
export interface UpdateTOCOMClearingInformation {
    /**
     * 
     * @type {Array<UpdateTOCOMClearingInformationItemsInner>}
     * @memberof UpdateTOCOMClearingInformation
     */
    'items': Array<UpdateTOCOMClearingInformationItemsInner>;
}
/**
 * 
 * @export
 * @interface UpdateTOCOMClearingInformationItemsInner
 */
export interface UpdateTOCOMClearingInformationItemsInner {
    /**
     * TOCOMブローカーのID
     * @type {string}
     * @memberof UpdateTOCOMClearingInformationItemsInner
     */
    'tocomBrokerId': string;
}
/**
 * 本来はユーザー情報をすべて変更できるのが望ましいが現時点ではeClearの情報更新のみに対応している。他のプロパティの変更はニーズが生じたタイミングで対応する。
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * 
     * @type {UpdateUserServices}
     * @memberof UpdateUser
     */
    'services'?: UpdateUserServices;
}
/**
 * 
 * @export
 * @interface UpdateUserProfile
 */
export interface UpdateUserProfile {
    /**
     * 
     * @type {Locale}
     * @memberof UpdateUserProfile
     */
    'locale'?: Locale;
    /**
     * 
     * @type {UpdateUserProfileServices}
     * @memberof UpdateUserProfile
     */
    'services'?: UpdateUserProfileServices;
}
/**
 * 
 * @export
 * @interface UpdateUserProfileServices
 */
export interface UpdateUserProfileServices {
    /**
     * 
     * @type {UpdateUserProfileServicesEsquare}
     * @memberof UpdateUserProfileServices
     */
    'esquare'?: UpdateUserProfileServicesEsquare;
    /**
     * 
     * @type {UpdateUserProfileServicesEclear}
     * @memberof UpdateUserProfileServices
     */
    'eclear'?: UpdateUserProfileServicesEclear;
}
/**
 * 
 * @export
 * @interface UpdateUserProfileServicesEclear
 */
export interface UpdateUserProfileServicesEclear {
    /**
     * 
     * @type {UpdateEClearUserSetting}
     * @memberof UpdateUserProfileServicesEclear
     */
    'userSetting': UpdateEClearUserSetting;
}
/**
 * 
 * @export
 * @interface UpdateUserProfileServicesEsquare
 */
export interface UpdateUserProfileServicesEsquare {
    /**
     * 
     * @type {UpdateESquareUserSetting}
     * @memberof UpdateUserProfileServicesEsquare
     */
    'userSetting': UpdateESquareUserSetting;
}
/**
 * 
 * @export
 * @interface UpdateUserServices
 */
export interface UpdateUserServices {
    /**
     * 
     * @type {UpdateUserServicesEclear}
     * @memberof UpdateUserServices
     */
    'eclear'?: UpdateUserServicesEclear;
}
/**
 * 
 * @export
 * @interface UpdateUserServicesEclear
 */
export interface UpdateUserServicesEclear {
    /**
     * 
     * @type {UpdateEClearUserSetting}
     * @memberof UpdateUserServicesEclear
     */
    'userSetting'?: UpdateEClearUserSetting;
    /**
     * 
     * @type {UpdateUserServicesEclearAppSetting}
     * @memberof UpdateUserServicesEclear
     */
    'appSetting'?: UpdateUserServicesEclearAppSetting;
}
/**
 * 
 * @export
 * @interface UpdateUserServicesEclearAppSetting
 */
export interface UpdateUserServicesEclearAppSetting {
    /**
     * eClearのトレーダーか?
     * @type {boolean}
     * @memberof UpdateUserServicesEclearAppSetting
     */
    'isTrader'?: boolean;
    /**
     * 受給担当者か?
     * @type {boolean}
     * @memberof UpdateUserServicesEclearAppSetting
     */
    'isDemandAndSupplyPlanner'?: boolean;
    /**
     * トレーダーID
     * @type {string}
     * @memberof UpdateUserServicesEclearAppSetting
     */
    'traderId'?: string;
}
/**
 * 
 * @export
 * @interface UpdatedIndicationInBulk
 */
export interface UpdatedIndicationInBulk {
    /**
     * 
     * @type {Array<Indication>}
     * @memberof UpdatedIndicationInBulk
     */
    'successfulIndications': Array<Indication>;
    /**
     * 
     * @type {Array<Indication>}
     * @memberof UpdatedIndicationInBulk
     */
    'failedIndications': Array<Indication>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * ユーザーID
     * @type {string}
     * @memberof User
     */
    'userId': string;
    /**
     * 氏名
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof User
     */
    'mail': string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof User
     */
    'picture': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof User
     */
    'organizationId': string;
    /**
     * 組織名
     * @type {string}
     * @memberof User
     */
    'organizationName': string;
    /**
     * 電話
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * 携帯電話
     * @type {string}
     * @memberof User
     */
    'mobilePhoneNumber'?: string;
    /**
     * 
     * @type {RoleType}
     * @memberof User
     */
    'roleInOrganization'?: RoleType;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    'userType'?: UserType;
    /**
     * 
     * @type {UserStatusType}
     * @memberof User
     */
    'status'?: UserStatusType;
    /**
     * 表示名
     * @type {string}
     * @memberof User
     */
    'displayName'?: string;
    /**
     * 所属
     * @type {string}
     * @memberof User
     */
    'department': string;
    /**
     * 
     * @type {Locale}
     * @memberof User
     */
    'locale': Locale;
    /**
     * 
     * @type {Services}
     * @memberof User
     */
    'services': Services;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * ユーザーID
     * @type {string}
     * @memberof UserProfile
     */
    'userId': string;
    /**
     * 氏名
     * @type {string}
     * @memberof UserProfile
     */
    'name': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof UserProfile
     */
    'mail': string;
    /**
     * 写真のURL
     * @type {string}
     * @memberof UserProfile
     */
    'picture': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof UserProfile
     */
    'organizationId': string;
    /**
     * 電話
     * @type {string}
     * @memberof UserProfile
     */
    'phoneNumber'?: string;
    /**
     * 携帯電話
     * @type {string}
     * @memberof UserProfile
     */
    'mobilePhoneNumber'?: string;
    /**
     * 所属
     * @type {string}
     * @memberof UserProfile
     */
    'department'?: string;
    /**
     * 
     * @type {Status}
     * @memberof UserProfile
     */
    'status': Status;
    /**
     * 
     * @type {Locale}
     * @memberof UserProfile
     */
    'locale': Locale;
    /**
     * 
     * @type {RoleType}
     * @memberof UserProfile
     */
    'roleInOrganization': RoleType;
    /**
     * 
     * @type {Services}
     * @memberof UserProfile
     */
    'services': Services;
}
/**
 * ユーザーステータス
 * @export
 * @enum {string}
 */

export const UserStatusType = {
    Active: 'active',
    Invited: 'invited'
} as const;

export type UserStatusType = typeof UserStatusType[keyof typeof UserStatusType];


/**
 * ユーザーの種類
 * @export
 * @enum {string}
 */

export const UserType = {
    Trader: 'trader',
    Broker: 'broker',
    SystemAdmin: 'system-admin'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @interface ValidatableBasicUnitPrice
 */
export interface ValidatableBasicUnitPrice {
    /**
     * 
     * @type {BasicUnitPriceType}
     * @memberof ValidatableBasicUnitPrice
     */
    'type': BasicUnitPriceType;
    /**
     * 
     * @type {ValidatableValue}
     * @memberof ValidatableBasicUnitPrice
     */
    'unitPrice': ValidatableValue;
}
/**
 * バリデーション可能な価格
 * @export
 * @interface ValidatablePrice
 */
export interface ValidatablePrice {
    /**
     * 
     * @type {PriceType}
     * @memberof ValidatablePrice
     */
    'type': PriceType;
    /**
     * 
     * @type {Array<ValidatableValue>}
     * @memberof ValidatablePrice
     */
    'energyUnitPrices': Array<ValidatableValue>;
    /**
     * 
     * @type {ValidatableBasicUnitPrice}
     * @memberof ValidatablePrice
     */
    'basicUnitPrice'?: ValidatableBasicUnitPrice;
    /**
     * バリデーションエラーのメッセージ。エラーなしの場合はundefined
     * @type {string}
     * @memberof ValidatablePrice
     */
    'validationErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface ValidatableValue
 */
export interface ValidatableValue {
    /**
     * 値
     * @type {string}
     * @memberof ValidatableValue
     */
    'value': string;
    /**
     * バリデーションエラーのメッセージ。エラーなしの場合はundefined
     * @type {string}
     * @memberof ValidatableValue
     */
    'validationErrorMessage'?: string;
}
/**
 * eClear確認キーの検証リクエスト
 * @export
 * @interface ValidateEClearKeywordRequest
 */
export interface ValidateEClearKeywordRequest {
    /**
     * eClear確認キー
     * @type {string}
     * @memberof ValidateEClearKeywordRequest
     */
    'keyword': string;
}
/**
 * eClear確認キーの検証レスポンス
 * @export
 * @interface ValidateEClearKeywordResponse
 */
export interface ValidateEClearKeywordResponse {
    /**
     * 検証結果
     * @type {boolean}
     * @memberof ValidateEClearKeywordResponse
     */
    'isValid': boolean;
    /**
     * 検証が失敗したときののメッセージ
     * @type {string}
     * @memberof ValidateEClearKeywordResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ValidateNewDailyPowerMarketIndicationsError
 */
export interface ValidateNewDailyPowerMarketIndicationsError {
    /**
     * エラーが発生した行番号
     * @type {number}
     * @memberof ValidateNewDailyPowerMarketIndicationsError
     */
    'row': number;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ValidateNewDailyPowerMarketIndicationsError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ValidateNewDailyPowerMarketIndicationsResult
 */
export interface ValidateNewDailyPowerMarketIndicationsResult {
    /**
     * ## バリデーション結果 - true: バリデーション成功 - false: バリデーション失敗 
     * @type {boolean}
     * @memberof ValidateNewDailyPowerMarketIndicationsResult
     */
    'isValid': boolean;
    /**
     * 
     * @type {Array<ValidatedNewDailyPowerMarketIndication>}
     * @memberof ValidateNewDailyPowerMarketIndicationsResult
     */
    'validated': Array<ValidatedNewDailyPowerMarketIndication>;
    /**
     * 
     * @type {Array<ValidateNewDailyPowerMarketIndicationsError>}
     * @memberof ValidateNewDailyPowerMarketIndicationsResult
     */
    'errors': Array<ValidateNewDailyPowerMarketIndicationsError>;
    /**
     * ## eClear注文が含まれているか - true: eClear注文が含まれている - false: eClear注文が含まれていない 
     * @type {boolean}
     * @memberof ValidateNewDailyPowerMarketIndicationsResult
     */
    'isEClearIncluded': boolean;
}
/**
 * 
 * @export
 * @interface ValidateNewMyDailyPowerMarketIndicationsResult
 */
export interface ValidateNewMyDailyPowerMarketIndicationsResult {
    /**
     * ## バリデーション結果 - true: バリデーション成功 - false: バリデーション失敗 
     * @type {boolean}
     * @memberof ValidateNewMyDailyPowerMarketIndicationsResult
     */
    'isValid': boolean;
    /**
     * 
     * @type {Array<ValidatedNewMyDailyPowerMarketIndication>}
     * @memberof ValidateNewMyDailyPowerMarketIndicationsResult
     */
    'validated': Array<ValidatedNewMyDailyPowerMarketIndication>;
    /**
     * 
     * @type {Array<ValidateNewDailyPowerMarketIndicationsError>}
     * @memberof ValidateNewMyDailyPowerMarketIndicationsResult
     */
    'errors': Array<ValidateNewDailyPowerMarketIndicationsError>;
    /**
     * ## eClear注文が含まれているか - true: eClear注文が含まれている - false: eClear注文が含まれていない 
     * @type {boolean}
     * @memberof ValidateNewMyDailyPowerMarketIndicationsResult
     */
    'isEClearIncluded': boolean;
}
/**
 * 
 * @export
 * @interface ValidatedNewDailyPowerMarketIndication
 */
export interface ValidatedNewDailyPowerMarketIndication {
    /**
     * 組織名
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'organizationName': string;
    /**
     * 担当トレーダーの名前
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'traderName'?: string;
    /**
     * 
     * @type {PositionType}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'deliveryPeriod': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'hourType': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'volume': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndication
     */
    'unitPrice': string;
}
/**
 * 
 * @export
 * @interface ValidatedNewDailyPowerMarketIndicationAllOf
 */
export interface ValidatedNewDailyPowerMarketIndicationAllOf {
    /**
     * 組織名
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndicationAllOf
     */
    'organizationName': string;
    /**
     * 担当トレーダーの名前
     * @type {string}
     * @memberof ValidatedNewDailyPowerMarketIndicationAllOf
     */
    'traderName'?: string;
}
/**
 * 
 * @export
 * @interface ValidatedNewMyDailyPowerMarketIndication
 */
export interface ValidatedNewMyDailyPowerMarketIndication {
    /**
     * 
     * @type {PositionType}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'position': PositionType;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'deliveryPeriod': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'hourType': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'volume': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatedNewMyDailyPowerMarketIndication
     */
    'unitPrice': string;
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 注文を有効/無効化する
         * @param {string} indicationId 操作対象の注文ID
         * @param {AppIndicationStatus} appIndicationStatus 有効/無効にするためのリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyIndicationForApp: async (indicationId: string, appIndicationStatus: AppIndicationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('changeMyIndicationForApp', 'indicationId', indicationId)
            // verify required parameter 'appIndicationStatus' is not null or undefined
            assertParamExists('changeMyIndicationForApp', 'appIndicationStatus', appIndicationStatus)
            const localVarPath = `/app/my-indications/{indicationId}/status`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appIndicationStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を新規作成する
         * @param {AppMyNewIndication} appMyNewIndication 新規注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMyIndicationForApp: async (appMyNewIndication: AppMyNewIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appMyNewIndication' is not null or undefined
            assertParamExists('createMyIndicationForApp', 'appMyNewIndication', appMyNewIndication)
            const localVarPath = `/app/my-indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appMyNewIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndicationForApp: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('deleteIndicationForApp', 'indicationId', indicationId)
            const localVarPath = `/app/my-indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 新規注文入力画面で選択可能な選択肢を得るエンドポイント
         * @param {AppMyIndicationSelectableInputRequest} appMyIndicationSelectableInputRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIndicationSelectableInputs: async (appMyIndicationSelectableInputRequest: AppMyIndicationSelectableInputRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appMyIndicationSelectableInputRequest' is not null or undefined
            assertParamExists('generateIndicationSelectableInputs', 'appMyIndicationSelectableInputRequest', appMyIndicationSelectableInputRequest)
            const localVarPath = `/app/my-indications/selectable-inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appMyIndicationSelectableInputRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定チャンネルIDのチャンネル情報を取得する
         * @param {string} channelId チャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelById: async (channelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('getChannelById', 'channelId', channelId)
            const localVarPath = `/app/chat/channels/{channelId}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:starred-channels"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定カテゴリIDのカテゴリ情報を取得する
         * @param {string} categoryId チャンネルのカテゴリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCategoryById: async (categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getChannelCategoryById', 'categoryId', categoryId)
            const localVarPath = `/app/chat/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:starred-channels"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定カテゴリのチャンネル一覧を取得する
         * @param {string} categoryId チャンネルのカテゴリID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページのチャンネル件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsByCategoryId: async (categoryId: string, next?: string, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getChannelsByCategoryId', 'categoryId', categoryId)
            const localVarPath = `/app/chat/categories/{categoryId}/channels`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:starred-channels"], configuration)

            if (next !== undefined) {
                localVarQueryParameter['next'] = next;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チャットのサマリを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/chat/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:starred-channels"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定チャンネルIDのメッセージ一覧を取得する
         * @param {string} channelId チャンネルID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページのメッセージ件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesByChannelId: async (channelId: string, next?: string, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('getMessagesByChannelId', 'channelId', channelId)
            const localVarPath = `/app/chat/channels/{channelId}/messages`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:broadcast-indices"], configuration)

            if (next !== undefined) {
                localVarQueryParameter['next'] = next;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自社の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDealsForApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/my-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:my-deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自社の注文をIDから取得する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndicationByIdForApp: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('getMyIndicationByIdForApp', 'indicationId', indicationId)
            const localVarPath = `/app/my-indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自社の注文に対する変更権限をIDから取得する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndicationPrivilegeByIdForApp: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('getMyIndicationPrivilegeByIdForApp', 'indicationId', indicationId)
            const localVarPath = `/app/my-indications/{indicationId}/privilege`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自社の注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndicationsForApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/my-indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザープロファイルを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/my-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:user-profiles"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公開注文をフィルタするためのフィルタオプション一覧を取得する
         * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderBookFiltersForApp: async (fuelSurcharge?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/order-books/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-indications"], configuration)

            if (fuelSurcharge !== undefined) {
                localVarQueryParameter['fuelSurcharge'] = fuelSurcharge;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公開注文を取得する
         * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
         * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
         * @param {string} [hourType] 公開注文のフィルタに使う。指定された時間帯区分IDでフィルタされる。
         * @param {string} [deliveryStartDate] 公開注文のフィルタに使う。指定された開始日時でフィルタされる。 yyyy-MM-dd
         * @param {string} [deliveryEndDate] 公開注文のフィルタに使う。指定された終了日時でフィルタされる。 yyyy-MM-dd
         * @param {string} [productType] 公開注文のフィルタに使う。指定された商品区分IDでフィルタされる。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderBooksForApp: async (fuelSurcharge?: boolean, area?: string, hourType?: string, deliveryStartDate?: string, deliveryEndDate?: string, productType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/order-books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-indications", "read:public-deals"], configuration)

            if (fuelSurcharge !== undefined) {
                localVarQueryParameter['fuelSurcharge'] = fuelSurcharge;
            }

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (hourType !== undefined) {
                localVarQueryParameter['hourType'] = hourType;
            }

            if (deliveryStartDate !== undefined) {
                localVarQueryParameter['deliveryStartDate'] = deliveryStartDate;
            }

            if (deliveryEndDate !== undefined) {
                localVarQueryParameter['deliveryEndDate'] = deliveryEndDate;
            }

            if (productType !== undefined) {
                localVarQueryParameter['productType'] = productType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定チャンネルIDの参加者一覧を取得する
         * @param {string} channelId チャンネルID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページの参加者件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsByChannelId: async (channelId: string, next?: string, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('getParticipantsByChannelId', 'channelId', channelId)
            const localVarPath = `/app/chat/channels/{channelId}/participants`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:organization-channel-members"], configuration)

            if (next !== undefined) {
                localVarQueryParameter['next'] = next;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary schemaをOpenAPI generatorに認識させるためのダミーAPI。実際には利用しない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neverUseSinceItIsDummy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/chat/wss`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FCMトークンを登録する
         * @param {AppFcmToken} appFcmToken FCMトークン登録のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerFcmTokenForApp: async (appFcmToken: AppFcmToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appFcmToken' is not null or undefined
            assertParamExists('registerFcmTokenForApp', 'appFcmToken', appFcmToken)
            const localVarPath = `/app/my-profile/fcm-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:user-profiles"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appFcmToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定チャンネルIDに新規メッセージを送信する
         * @param {string} channelId チャンネルID
         * @param {AppMessagePost} appMessagePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToChannelById: async (channelId: string, appMessagePost: AppMessagePost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('sendMessageToChannelById', 'channelId', channelId)
            // verify required parameter 'appMessagePost' is not null or undefined
            assertParamExists('sendMessageToChannelById', 'appMessagePost', appMessagePost)
            const localVarPath = `/app/chat/channels/{channelId}/messages`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appMessagePost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定したIDのチャンネルをスター付きにする
         * @param {string} channelId チャンネルID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        starChannelById: async (channelId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('starChannelById', 'channelId', channelId)
            const localVarPath = `/app/chat/channels/{channelId}/starred`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:starred-channels"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary FCMトークンを登録解除する
         * @param {AppFcmToken} [appFcmToken] FCMトークン登録解除のリクエストBody。nullの場合、ユーザーに紐づくすべてのFCMトークンが登録解除される。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterFcmTokenForApp: async (appFcmToken?: AppFcmToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/my-profile/fcm-token/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:user-profiles"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appFcmToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定したIDのチャンネルのスターを外す
         * @param {string} channelId チャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unstarChannelById: async (channelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('unstarChannelById', 'channelId', channelId)
            const localVarPath = `/app/chat/channels/{channelId}/starred`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:starred-channels"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文の内容を変更する
         * @param {string} indicationId 操作対象の注文ID
         * @param {AppMyIndicationUpdate} appMyIndicationUpdate 更新する注文の内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyIndicationForApp: async (indicationId: string, appMyIndicationUpdate: AppMyIndicationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('updateMyIndicationForApp', 'indicationId', indicationId)
            // verify required parameter 'appMyIndicationUpdate' is not null or undefined
            assertParamExists('updateMyIndicationForApp', 'appMyIndicationUpdate', appMyIndicationUpdate)
            const localVarPath = `/app/my-indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appMyIndicationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 注文を有効/無効化する
         * @param {string} indicationId 操作対象の注文ID
         * @param {AppIndicationStatus} appIndicationStatus 有効/無効にするためのリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeMyIndicationForApp(indicationId: string, appIndicationStatus: AppIndicationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeMyIndicationForApp(indicationId, appIndicationStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を新規作成する
         * @param {AppMyNewIndication} appMyNewIndication 新規注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMyIndicationForApp(appMyNewIndication: AppMyNewIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMyIndicationForApp(appMyNewIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIndicationForApp(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIndicationForApp(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 新規注文入力画面で選択可能な選択肢を得るエンドポイント
         * @param {AppMyIndicationSelectableInputRequest} appMyIndicationSelectableInputRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateIndicationSelectableInputs(appMyIndicationSelectableInputRequest: AppMyIndicationSelectableInputRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppMyIndicationSelectableInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateIndicationSelectableInputs(appMyIndicationSelectableInputRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定チャンネルIDのチャンネル情報を取得する
         * @param {string} channelId チャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelById(channelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppChannelDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelById(channelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定カテゴリIDのカテゴリ情報を取得する
         * @param {string} categoryId チャンネルのカテゴリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelCategoryById(categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppChannelCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelCategoryById(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定カテゴリのチャンネル一覧を取得する
         * @param {string} categoryId チャンネルのカテゴリID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページのチャンネル件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelsByCategoryId(categoryId: string, next?: string, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppChannelsPagingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelsByCategoryId(categoryId, next, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チャットのサマリを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppChatSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定チャンネルIDのメッセージ一覧を取得する
         * @param {string} channelId チャンネルID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページのメッセージ件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesByChannelId(channelId: string, next?: string, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppMessagesPagingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesByChannelId(channelId, next, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自社の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDealsForApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppMyDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyDealsForApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自社の注文をIDから取得する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyIndicationByIdForApp(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppMyIndication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyIndicationByIdForApp(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自社の注文に対する変更権限をIDから取得する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyIndicationPrivilegeByIdForApp(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppMyIndicationPrivilege>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyIndicationPrivilegeByIdForApp(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自社の注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyIndicationsForApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppMyIndication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyIndicationsForApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザープロファイルを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppMyProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公開注文をフィルタするためのフィルタオプション一覧を取得する
         * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderBookFiltersForApp(fuelSurcharge?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppOrderBookFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderBookFiltersForApp(fuelSurcharge, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公開注文を取得する
         * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
         * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
         * @param {string} [hourType] 公開注文のフィルタに使う。指定された時間帯区分IDでフィルタされる。
         * @param {string} [deliveryStartDate] 公開注文のフィルタに使う。指定された開始日時でフィルタされる。 yyyy-MM-dd
         * @param {string} [deliveryEndDate] 公開注文のフィルタに使う。指定された終了日時でフィルタされる。 yyyy-MM-dd
         * @param {string} [productType] 公開注文のフィルタに使う。指定された商品区分IDでフィルタされる。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderBooksForApp(fuelSurcharge?: boolean, area?: string, hourType?: string, deliveryStartDate?: string, deliveryEndDate?: string, productType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppOrderBook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderBooksForApp(fuelSurcharge, area, hourType, deliveryStartDate, deliveryEndDate, productType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定チャンネルIDの参加者一覧を取得する
         * @param {string} channelId チャンネルID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページの参加者件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantsByChannelId(channelId: string, next?: string, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppChannelParticipantsPagingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipantsByChannelId(channelId, next, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary schemaをOpenAPI generatorに認識させるためのダミーAPI。実際には利用しない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neverUseSinceItIsDummy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeverUseSinceItIsDummy200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neverUseSinceItIsDummy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FCMトークンを登録する
         * @param {AppFcmToken} appFcmToken FCMトークン登録のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerFcmTokenForApp(appFcmToken: AppFcmToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerFcmTokenForApp(appFcmToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定チャンネルIDに新規メッセージを送信する
         * @param {string} channelId チャンネルID
         * @param {AppMessagePost} appMessagePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessageToChannelById(channelId: string, appMessagePost: AppMessagePost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessageToChannelById(channelId, appMessagePost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定したIDのチャンネルをスター付きにする
         * @param {string} channelId チャンネルID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async starChannelById(channelId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.starChannelById(channelId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary FCMトークンを登録解除する
         * @param {AppFcmToken} [appFcmToken] FCMトークン登録解除のリクエストBody。nullの場合、ユーザーに紐づくすべてのFCMトークンが登録解除される。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unregisterFcmTokenForApp(appFcmToken?: AppFcmToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unregisterFcmTokenForApp(appFcmToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定したIDのチャンネルのスターを外す
         * @param {string} channelId チャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unstarChannelById(channelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unstarChannelById(channelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文の内容を変更する
         * @param {string} indicationId 操作対象の注文ID
         * @param {AppMyIndicationUpdate} appMyIndicationUpdate 更新する注文の内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyIndicationForApp(indicationId: string, appMyIndicationUpdate: AppMyIndicationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyIndicationForApp(indicationId, appMyIndicationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary 注文を有効/無効化する
         * @param {string} indicationId 操作対象の注文ID
         * @param {AppIndicationStatus} appIndicationStatus 有効/無効にするためのリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyIndicationForApp(indicationId: string, appIndicationStatus: AppIndicationStatus, options?: any): AxiosPromise<void> {
            return localVarFp.changeMyIndicationForApp(indicationId, appIndicationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を新規作成する
         * @param {AppMyNewIndication} appMyNewIndication 新規注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMyIndicationForApp(appMyNewIndication: AppMyNewIndication, options?: any): AxiosPromise<void> {
            return localVarFp.createMyIndicationForApp(appMyNewIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndicationForApp(indicationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIndicationForApp(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 新規注文入力画面で選択可能な選択肢を得るエンドポイント
         * @param {AppMyIndicationSelectableInputRequest} appMyIndicationSelectableInputRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIndicationSelectableInputs(appMyIndicationSelectableInputRequest: AppMyIndicationSelectableInputRequest, options?: any): AxiosPromise<AppMyIndicationSelectableInput> {
            return localVarFp.generateIndicationSelectableInputs(appMyIndicationSelectableInputRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定チャンネルIDのチャンネル情報を取得する
         * @param {string} channelId チャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelById(channelId: string, options?: any): AxiosPromise<AppChannelDetail> {
            return localVarFp.getChannelById(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定カテゴリIDのカテゴリ情報を取得する
         * @param {string} categoryId チャンネルのカテゴリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCategoryById(categoryId: string, options?: any): AxiosPromise<AppChannelCategory> {
            return localVarFp.getChannelCategoryById(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定カテゴリのチャンネル一覧を取得する
         * @param {string} categoryId チャンネルのカテゴリID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページのチャンネル件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelsByCategoryId(categoryId: string, next?: string, perPage?: number, options?: any): AxiosPromise<AppChannelsPagingResponse> {
            return localVarFp.getChannelsByCategoryId(categoryId, next, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チャットのサマリを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatSummary(options?: any): AxiosPromise<AppChatSummary> {
            return localVarFp.getChatSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定チャンネルIDのメッセージ一覧を取得する
         * @param {string} channelId チャンネルID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページのメッセージ件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesByChannelId(channelId: string, next?: string, perPage?: number, options?: any): AxiosPromise<AppMessagesPagingResponse> {
            return localVarFp.getMessagesByChannelId(channelId, next, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自社の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDealsForApp(options?: any): AxiosPromise<Array<AppMyDeal>> {
            return localVarFp.getMyDealsForApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自社の注文をIDから取得する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndicationByIdForApp(indicationId: string, options?: any): AxiosPromise<AppMyIndication> {
            return localVarFp.getMyIndicationByIdForApp(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自社の注文に対する変更権限をIDから取得する
         * @param {string} indicationId 操作対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndicationPrivilegeByIdForApp(indicationId: string, options?: any): AxiosPromise<AppMyIndicationPrivilege> {
            return localVarFp.getMyIndicationPrivilegeByIdForApp(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自社の注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndicationsForApp(options?: any): AxiosPromise<Array<AppMyIndication>> {
            return localVarFp.getMyIndicationsForApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザープロファイルを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfile(options?: any): AxiosPromise<AppMyProfile> {
            return localVarFp.getMyProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公開注文をフィルタするためのフィルタオプション一覧を取得する
         * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderBookFiltersForApp(fuelSurcharge?: boolean, options?: any): AxiosPromise<AppOrderBookFilters> {
            return localVarFp.getOrderBookFiltersForApp(fuelSurcharge, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公開注文を取得する
         * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
         * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
         * @param {string} [hourType] 公開注文のフィルタに使う。指定された時間帯区分IDでフィルタされる。
         * @param {string} [deliveryStartDate] 公開注文のフィルタに使う。指定された開始日時でフィルタされる。 yyyy-MM-dd
         * @param {string} [deliveryEndDate] 公開注文のフィルタに使う。指定された終了日時でフィルタされる。 yyyy-MM-dd
         * @param {string} [productType] 公開注文のフィルタに使う。指定された商品区分IDでフィルタされる。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderBooksForApp(fuelSurcharge?: boolean, area?: string, hourType?: string, deliveryStartDate?: string, deliveryEndDate?: string, productType?: string, options?: any): AxiosPromise<Array<AppOrderBook>> {
            return localVarFp.getOrderBooksForApp(fuelSurcharge, area, hourType, deliveryStartDate, deliveryEndDate, productType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定チャンネルIDの参加者一覧を取得する
         * @param {string} channelId チャンネルID
         * @param {string} [next] 次のページを取得するためのパラメータ
         * @param {number} [perPage] 1ページの参加者件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsByChannelId(channelId: string, next?: string, perPage?: number, options?: any): AxiosPromise<AppChannelParticipantsPagingResponse> {
            return localVarFp.getParticipantsByChannelId(channelId, next, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary schemaをOpenAPI generatorに認識させるためのダミーAPI。実際には利用しない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neverUseSinceItIsDummy(options?: any): AxiosPromise<NeverUseSinceItIsDummy200Response> {
            return localVarFp.neverUseSinceItIsDummy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FCMトークンを登録する
         * @param {AppFcmToken} appFcmToken FCMトークン登録のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerFcmTokenForApp(appFcmToken: AppFcmToken, options?: any): AxiosPromise<void> {
            return localVarFp.registerFcmTokenForApp(appFcmToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定チャンネルIDに新規メッセージを送信する
         * @param {string} channelId チャンネルID
         * @param {AppMessagePost} appMessagePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToChannelById(channelId: string, appMessagePost: AppMessagePost, options?: any): AxiosPromise<void> {
            return localVarFp.sendMessageToChannelById(channelId, appMessagePost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定したIDのチャンネルをスター付きにする
         * @param {string} channelId チャンネルID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        starChannelById(channelId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.starChannelById(channelId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary FCMトークンを登録解除する
         * @param {AppFcmToken} [appFcmToken] FCMトークン登録解除のリクエストBody。nullの場合、ユーザーに紐づくすべてのFCMトークンが登録解除される。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterFcmTokenForApp(appFcmToken?: AppFcmToken, options?: any): AxiosPromise<void> {
            return localVarFp.unregisterFcmTokenForApp(appFcmToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定したIDのチャンネルのスターを外す
         * @param {string} channelId チャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unstarChannelById(channelId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unstarChannelById(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文の内容を変更する
         * @param {string} indicationId 操作対象の注文ID
         * @param {AppMyIndicationUpdate} appMyIndicationUpdate 更新する注文の内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyIndicationForApp(indicationId: string, appMyIndicationUpdate: AppMyIndicationUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateMyIndicationForApp(indicationId, appMyIndicationUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary 注文を有効/無効化する
     * @param {string} indicationId 操作対象の注文ID
     * @param {AppIndicationStatus} appIndicationStatus 有効/無効にするためのリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public changeMyIndicationForApp(indicationId: string, appIndicationStatus: AppIndicationStatus, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).changeMyIndicationForApp(indicationId, appIndicationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を新規作成する
     * @param {AppMyNewIndication} appMyNewIndication 新規注文内容のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public createMyIndicationForApp(appMyNewIndication: AppMyNewIndication, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).createMyIndicationForApp(appMyNewIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を削除する
     * @param {string} indicationId 操作対象の注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public deleteIndicationForApp(indicationId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).deleteIndicationForApp(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 新規注文入力画面で選択可能な選択肢を得るエンドポイント
     * @param {AppMyIndicationSelectableInputRequest} appMyIndicationSelectableInputRequest ユーザーが入力している注文内容のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public generateIndicationSelectableInputs(appMyIndicationSelectableInputRequest: AppMyIndicationSelectableInputRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).generateIndicationSelectableInputs(appMyIndicationSelectableInputRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定チャンネルIDのチャンネル情報を取得する
     * @param {string} channelId チャンネルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getChannelById(channelId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getChannelById(channelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定カテゴリIDのカテゴリ情報を取得する
     * @param {string} categoryId チャンネルのカテゴリID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getChannelCategoryById(categoryId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getChannelCategoryById(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定カテゴリのチャンネル一覧を取得する
     * @param {string} categoryId チャンネルのカテゴリID
     * @param {string} [next] 次のページを取得するためのパラメータ
     * @param {number} [perPage] 1ページのチャンネル件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getChannelsByCategoryId(categoryId: string, next?: string, perPage?: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getChannelsByCategoryId(categoryId, next, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チャットのサマリを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getChatSummary(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getChatSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定チャンネルIDのメッセージ一覧を取得する
     * @param {string} channelId チャンネルID
     * @param {string} [next] 次のページを取得するためのパラメータ
     * @param {number} [perPage] 1ページのメッセージ件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getMessagesByChannelId(channelId: string, next?: string, perPage?: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getMessagesByChannelId(channelId, next, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自社の約定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getMyDealsForApp(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getMyDealsForApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自社の注文をIDから取得する
     * @param {string} indicationId 操作対象の注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getMyIndicationByIdForApp(indicationId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getMyIndicationByIdForApp(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自社の注文に対する変更権限をIDから取得する
     * @param {string} indicationId 操作対象の注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getMyIndicationPrivilegeByIdForApp(indicationId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getMyIndicationPrivilegeByIdForApp(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自社の注文を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getMyIndicationsForApp(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getMyIndicationsForApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザープロファイルを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getMyProfile(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getMyProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公開注文をフィルタするためのフィルタオプション一覧を取得する
     * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getOrderBookFiltersForApp(fuelSurcharge?: boolean, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getOrderBookFiltersForApp(fuelSurcharge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公開注文を取得する
     * @param {boolean} [fuelSurcharge] 燃調ありのリストがほしいかどうか。渡されなかった場合のデフォルトはfalse
     * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
     * @param {string} [hourType] 公開注文のフィルタに使う。指定された時間帯区分IDでフィルタされる。
     * @param {string} [deliveryStartDate] 公開注文のフィルタに使う。指定された開始日時でフィルタされる。 yyyy-MM-dd
     * @param {string} [deliveryEndDate] 公開注文のフィルタに使う。指定された終了日時でフィルタされる。 yyyy-MM-dd
     * @param {string} [productType] 公開注文のフィルタに使う。指定された商品区分IDでフィルタされる。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getOrderBooksForApp(fuelSurcharge?: boolean, area?: string, hourType?: string, deliveryStartDate?: string, deliveryEndDate?: string, productType?: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getOrderBooksForApp(fuelSurcharge, area, hourType, deliveryStartDate, deliveryEndDate, productType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定チャンネルIDの参加者一覧を取得する
     * @param {string} channelId チャンネルID
     * @param {string} [next] 次のページを取得するためのパラメータ
     * @param {number} [perPage] 1ページの参加者件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getParticipantsByChannelId(channelId: string, next?: string, perPage?: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getParticipantsByChannelId(channelId, next, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary schemaをOpenAPI generatorに認識させるためのダミーAPI。実際には利用しない。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public neverUseSinceItIsDummy(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).neverUseSinceItIsDummy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FCMトークンを登録する
     * @param {AppFcmToken} appFcmToken FCMトークン登録のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public registerFcmTokenForApp(appFcmToken: AppFcmToken, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).registerFcmTokenForApp(appFcmToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定チャンネルIDに新規メッセージを送信する
     * @param {string} channelId チャンネルID
     * @param {AppMessagePost} appMessagePost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public sendMessageToChannelById(channelId: string, appMessagePost: AppMessagePost, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).sendMessageToChannelById(channelId, appMessagePost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定したIDのチャンネルをスター付きにする
     * @param {string} channelId チャンネルID
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public starChannelById(channelId: string, body?: object, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).starChannelById(channelId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary FCMトークンを登録解除する
     * @param {AppFcmToken} [appFcmToken] FCMトークン登録解除のリクエストBody。nullの場合、ユーザーに紐づくすべてのFCMトークンが登録解除される。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public unregisterFcmTokenForApp(appFcmToken?: AppFcmToken, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).unregisterFcmTokenForApp(appFcmToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定したIDのチャンネルのスターを外す
     * @param {string} channelId チャンネルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public unstarChannelById(channelId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).unstarChannelById(channelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文の内容を変更する
     * @param {string} indicationId 操作対象の注文ID
     * @param {AppMyIndicationUpdate} appMyIndicationUpdate 更新する注文の内容のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public updateMyIndicationForApp(indicationId: string, appMyIndicationUpdate: AppMyIndicationUpdate, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).updateMyIndicationForApp(indicationId, appMyIndicationUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AreasApi - axios parameter creator
 * @export
 */
export const AreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのエリアを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:areas"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreasApi - functional programming interface
 * @export
 */
export const AreasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべてのエリアを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Area>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreasApi - factory interface
 * @export
 */
export const AreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreasApiFp(configuration)
    return {
        /**
         * 
         * @summary すべてのエリアを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas(options?: any): AxiosPromise<Array<Area>> {
            return localVarFp.getAreas(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreasApi - object-oriented interface
 * @export
 * @class AreasApi
 * @extends {BaseAPI}
 */
export class AreasApi extends BaseAPI {
    /**
     * 
     * @summary すべてのエリアを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreasApi
     */
    public getAreas(options?: AxiosRequestConfig) {
        return AreasApiFp(this.configuration).getAreas(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssignedBrokerOfOrganizationApi - axios parameter creator
 * @export
 */
export const AssignedBrokerOfOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織の担当ブローカーを登録する
         * @param {string} organizationId 組織ID
         * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー登録オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignedBrokerOfOrganization: async (organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createAssignedBrokerOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'createOrUpdateAssignedBrokerOfOrganization' is not null or undefined
            assertParamExists('createAssignedBrokerOfOrganization', 'createOrUpdateAssignedBrokerOfOrganization', createOrUpdateAssignedBrokerOfOrganization)
            const localVarPath = `/trading/organizations/{organizationId}/assigned-broker`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:assigned-broker-of-organizations"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAssignedBrokerOfOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の担当ブローカーを削除する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignedBrokerOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteAssignedBrokerOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/assigned-broker`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:assigned-broker-of-organizations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の担当ブローカーを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedBrokerOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAssignedBrokerOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/assigned-broker`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:assigned-broker-of-organizations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の担当ブローカーをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedBrokers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/assigned-brokers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:assigned-broker-of-organizations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の担当ブローカーを変更する
         * @param {string} organizationId 組織ID
         * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignedBrokerOfOrganization: async (organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateAssignedBrokerOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'createOrUpdateAssignedBrokerOfOrganization' is not null or undefined
            assertParamExists('updateAssignedBrokerOfOrganization', 'createOrUpdateAssignedBrokerOfOrganization', createOrUpdateAssignedBrokerOfOrganization)
            const localVarPath = `/trading/organizations/{organizationId}/assigned-broker`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:assigned-broker-of-organizations"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAssignedBrokerOfOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignedBrokerOfOrganizationApi - functional programming interface
 * @export
 */
export const AssignedBrokerOfOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignedBrokerOfOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織の担当ブローカーを登録する
         * @param {string} organizationId 組織ID
         * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー登録オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssignedBrokerOfOrganization(organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignedBrokerOfOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignedBrokerOfOrganization(organizationId, createOrUpdateAssignedBrokerOfOrganization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の担当ブローカーを削除する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignedBrokerOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignedBrokerOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の担当ブローカーを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignedBrokerOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignedBrokerOfOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedBrokerOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の担当ブローカーをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignedBrokers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignedBrokerOfOrganization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedBrokers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の担当ブローカーを変更する
         * @param {string} organizationId 組織ID
         * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignedBrokerOfOrganization(organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignedBrokerOfOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignedBrokerOfOrganization(organizationId, createOrUpdateAssignedBrokerOfOrganization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssignedBrokerOfOrganizationApi - factory interface
 * @export
 */
export const AssignedBrokerOfOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignedBrokerOfOrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織の担当ブローカーを登録する
         * @param {string} organizationId 組織ID
         * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー登録オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignedBrokerOfOrganization(organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options?: any): AxiosPromise<AssignedBrokerOfOrganization> {
            return localVarFp.createAssignedBrokerOfOrganization(organizationId, createOrUpdateAssignedBrokerOfOrganization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の担当ブローカーを削除する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignedBrokerOfOrganization(organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAssignedBrokerOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の担当ブローカーを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedBrokerOfOrganization(organizationId: string, options?: any): AxiosPromise<AssignedBrokerOfOrganization> {
            return localVarFp.getAssignedBrokerOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の担当ブローカーをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedBrokers(options?: any): AxiosPromise<Array<AssignedBrokerOfOrganization>> {
            return localVarFp.getAssignedBrokers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の担当ブローカーを変更する
         * @param {string} organizationId 組織ID
         * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignedBrokerOfOrganization(organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options?: any): AxiosPromise<AssignedBrokerOfOrganization> {
            return localVarFp.updateAssignedBrokerOfOrganization(organizationId, createOrUpdateAssignedBrokerOfOrganization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssignedBrokerOfOrganizationApi - object-oriented interface
 * @export
 * @class AssignedBrokerOfOrganizationApi
 * @extends {BaseAPI}
 */
export class AssignedBrokerOfOrganizationApi extends BaseAPI {
    /**
     * 
     * @summary 組織の担当ブローカーを登録する
     * @param {string} organizationId 組織ID
     * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー登録オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedBrokerOfOrganizationApi
     */
    public createAssignedBrokerOfOrganization(organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options?: AxiosRequestConfig) {
        return AssignedBrokerOfOrganizationApiFp(this.configuration).createAssignedBrokerOfOrganization(organizationId, createOrUpdateAssignedBrokerOfOrganization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の担当ブローカーを削除する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedBrokerOfOrganizationApi
     */
    public deleteAssignedBrokerOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return AssignedBrokerOfOrganizationApiFp(this.configuration).deleteAssignedBrokerOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の担当ブローカーを取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedBrokerOfOrganizationApi
     */
    public getAssignedBrokerOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return AssignedBrokerOfOrganizationApiFp(this.configuration).getAssignedBrokerOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の担当ブローカーをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedBrokerOfOrganizationApi
     */
    public getAssignedBrokers(options?: AxiosRequestConfig) {
        return AssignedBrokerOfOrganizationApiFp(this.configuration).getAssignedBrokers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の担当ブローカーを変更する
     * @param {string} organizationId 組織ID
     * @param {CreateOrUpdateAssignedBrokerOfOrganization} createOrUpdateAssignedBrokerOfOrganization 担当ブローカー変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedBrokerOfOrganizationApi
     */
    public updateAssignedBrokerOfOrganization(organizationId: string, createOrUpdateAssignedBrokerOfOrganization: CreateOrUpdateAssignedBrokerOfOrganization, options?: AxiosRequestConfig) {
        return AssignedBrokerOfOrganizationApiFp(this.configuration).updateAssignedBrokerOfOrganization(organizationId, createOrUpdateAssignedBrokerOfOrganization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BroadcastIndicesApi - axios parameter creator
 * @export
 */
export const BroadcastIndicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 強制的に既読にしたメッセージのインデックスを取得する
         * @param {ScanMessageIndices} scanMessageIndices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastIndices: async (scanMessageIndices: ScanMessageIndices, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanMessageIndices' is not null or undefined
            assertParamExists('getBroadcastIndices', 'scanMessageIndices', scanMessageIndices)
            const localVarPath = `/chat/broadcast-indices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:broadcast-indices"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanMessageIndices, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 一斉送信のメッセージ数を取得する
         * @param {ScanMessageIndices} scanMessageIndices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadBroadcastMessagesCount: async (scanMessageIndices: ScanMessageIndices, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanMessageIndices' is not null or undefined
            assertParamExists('getUnreadBroadcastMessagesCount', 'scanMessageIndices', scanMessageIndices)
            const localVarPath = `/chat/unread-broadcast-messages-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:broadcast-indices"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanMessageIndices, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BroadcastIndicesApi - functional programming interface
 * @export
 */
export const BroadcastIndicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BroadcastIndicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 強制的に既読にしたメッセージのインデックスを取得する
         * @param {ScanMessageIndices} scanMessageIndices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBroadcastIndices(scanMessageIndices: ScanMessageIndices, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBroadcastIndices(scanMessageIndices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 一斉送信のメッセージ数を取得する
         * @param {ScanMessageIndices} scanMessageIndices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadBroadcastMessagesCount(scanMessageIndices: ScanMessageIndices, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnreadBroadcastMessagesCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadBroadcastMessagesCount(scanMessageIndices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BroadcastIndicesApi - factory interface
 * @export
 */
export const BroadcastIndicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BroadcastIndicesApiFp(configuration)
    return {
        /**
         * 
         * @summary 強制的に既読にしたメッセージのインデックスを取得する
         * @param {ScanMessageIndices} scanMessageIndices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastIndices(scanMessageIndices: ScanMessageIndices, options?: any): AxiosPromise<MessageIndex> {
            return localVarFp.getBroadcastIndices(scanMessageIndices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 一斉送信のメッセージ数を取得する
         * @param {ScanMessageIndices} scanMessageIndices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadBroadcastMessagesCount(scanMessageIndices: ScanMessageIndices, options?: any): AxiosPromise<UnreadBroadcastMessagesCount> {
            return localVarFp.getUnreadBroadcastMessagesCount(scanMessageIndices, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BroadcastIndicesApi - object-oriented interface
 * @export
 * @class BroadcastIndicesApi
 * @extends {BaseAPI}
 */
export class BroadcastIndicesApi extends BaseAPI {
    /**
     * 
     * @summary 強制的に既読にしたメッセージのインデックスを取得する
     * @param {ScanMessageIndices} scanMessageIndices 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastIndicesApi
     */
    public getBroadcastIndices(scanMessageIndices: ScanMessageIndices, options?: AxiosRequestConfig) {
        return BroadcastIndicesApiFp(this.configuration).getBroadcastIndices(scanMessageIndices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 一斉送信のメッセージ数を取得する
     * @param {ScanMessageIndices} scanMessageIndices 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BroadcastIndicesApi
     */
    public getUnreadBroadcastMessagesCount(scanMessageIndices: ScanMessageIndices, options?: AxiosRequestConfig) {
        return BroadcastIndicesApiFp(this.configuration).getUnreadBroadcastMessagesCount(scanMessageIndices, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrokerageFeePaymentConfigurationApi - axios parameter creator
 * @export
 */
export const BrokerageFeePaymentConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織の仲介手数料の支払方法を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerageFeePaymentConfiguration: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getBrokerageFeePaymentConfiguration', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/brokerage-fee-payment-configuration`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:brokerage-fee-payment-configuration"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の仲介手数料の支払方法を更新する
         * @param {string} organizationId 組織ID
         * @param {UpdateBrokerageFeePaymentConfiguration} updateBrokerageFeePaymentConfiguration 組織の仲介手数料の支払方法オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrokerageFeePaymentConfiguration: async (organizationId: string, updateBrokerageFeePaymentConfiguration: UpdateBrokerageFeePaymentConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateBrokerageFeePaymentConfiguration', 'organizationId', organizationId)
            // verify required parameter 'updateBrokerageFeePaymentConfiguration' is not null or undefined
            assertParamExists('updateBrokerageFeePaymentConfiguration', 'updateBrokerageFeePaymentConfiguration', updateBrokerageFeePaymentConfiguration)
            const localVarPath = `/trading/organizations/{organizationId}/brokerage-fee-payment-configuration`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:brokerage-fee-payment-configuration"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBrokerageFeePaymentConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerageFeePaymentConfigurationApi - functional programming interface
 * @export
 */
export const BrokerageFeePaymentConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerageFeePaymentConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織の仲介手数料の支払方法を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrokerageFeePaymentConfiguration(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFeePaymentConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrokerageFeePaymentConfiguration(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の仲介手数料の支払方法を更新する
         * @param {string} organizationId 組織ID
         * @param {UpdateBrokerageFeePaymentConfiguration} updateBrokerageFeePaymentConfiguration 組織の仲介手数料の支払方法オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrokerageFeePaymentConfiguration(organizationId: string, updateBrokerageFeePaymentConfiguration: UpdateBrokerageFeePaymentConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFeePaymentConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrokerageFeePaymentConfiguration(organizationId, updateBrokerageFeePaymentConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerageFeePaymentConfigurationApi - factory interface
 * @export
 */
export const BrokerageFeePaymentConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerageFeePaymentConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織の仲介手数料の支払方法を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokerageFeePaymentConfiguration(organizationId: string, options?: any): AxiosPromise<BrokerageFeePaymentConfiguration> {
            return localVarFp.getBrokerageFeePaymentConfiguration(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の仲介手数料の支払方法を更新する
         * @param {string} organizationId 組織ID
         * @param {UpdateBrokerageFeePaymentConfiguration} updateBrokerageFeePaymentConfiguration 組織の仲介手数料の支払方法オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrokerageFeePaymentConfiguration(organizationId: string, updateBrokerageFeePaymentConfiguration: UpdateBrokerageFeePaymentConfiguration, options?: any): AxiosPromise<BrokerageFeePaymentConfiguration> {
            return localVarFp.updateBrokerageFeePaymentConfiguration(organizationId, updateBrokerageFeePaymentConfiguration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokerageFeePaymentConfigurationApi - object-oriented interface
 * @export
 * @class BrokerageFeePaymentConfigurationApi
 * @extends {BaseAPI}
 */
export class BrokerageFeePaymentConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary 組織の仲介手数料の支払方法を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerageFeePaymentConfigurationApi
     */
    public getBrokerageFeePaymentConfiguration(organizationId: string, options?: AxiosRequestConfig) {
        return BrokerageFeePaymentConfigurationApiFp(this.configuration).getBrokerageFeePaymentConfiguration(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の仲介手数料の支払方法を更新する
     * @param {string} organizationId 組織ID
     * @param {UpdateBrokerageFeePaymentConfiguration} updateBrokerageFeePaymentConfiguration 組織の仲介手数料の支払方法オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerageFeePaymentConfigurationApi
     */
    public updateBrokerageFeePaymentConfiguration(organizationId: string, updateBrokerageFeePaymentConfiguration: UpdateBrokerageFeePaymentConfiguration, options?: AxiosRequestConfig) {
        return BrokerageFeePaymentConfigurationApiFp(this.configuration).updateBrokerageFeePaymentConfiguration(organizationId, updateBrokerageFeePaymentConfiguration, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrokingDealsApi - axios parameter creator
 * @export
 */
export const BrokingDealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての直近約定を取得する
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingLastDeals: async (isSpread?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/broking-last-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:broking-deals"], configuration)

            if (isSpread !== undefined) {
                localVarQueryParameter['isSpread'] = isSpread;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 直近1週間の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingRecentDeals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/broking-recent-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:broking-deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokingDealsApi - functional programming interface
 * @export
 */
export const BrokingDealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokingDealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての直近約定を取得する
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrokingLastDeals(isSpread?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrokingDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrokingLastDeals(isSpread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 直近1週間の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrokingRecentDeals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrokingDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrokingRecentDeals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokingDealsApi - factory interface
 * @export
 */
export const BrokingDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokingDealsApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての直近約定を取得する
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingLastDeals(isSpread?: boolean, options?: any): AxiosPromise<Array<BrokingDeal>> {
            return localVarFp.getBrokingLastDeals(isSpread, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 直近1週間の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingRecentDeals(options?: any): AxiosPromise<Array<BrokingDeal>> {
            return localVarFp.getBrokingRecentDeals(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokingDealsApi - object-oriented interface
 * @export
 * @class BrokingDealsApi
 * @extends {BaseAPI}
 */
export class BrokingDealsApi extends BaseAPI {
    /**
     * 
     * @summary すべての直近約定を取得する
     * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokingDealsApi
     */
    public getBrokingLastDeals(isSpread?: boolean, options?: AxiosRequestConfig) {
        return BrokingDealsApiFp(this.configuration).getBrokingLastDeals(isSpread, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 直近1週間の約定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokingDealsApi
     */
    public getBrokingRecentDeals(options?: AxiosRequestConfig) {
        return BrokingDealsApiFp(this.configuration).getBrokingRecentDeals(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrokingIndicationsApi - axios parameter creator
 * @export
 */
export const BrokingIndicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのブローキング対象注文を取得する
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingIndications: async (isSpread?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/broking-indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:broking-indications"], configuration)

            if (isSpread !== undefined) {
                localVarQueryParameter['isSpread'] = isSpread;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokingIndicationsApi - functional programming interface
 * @export
 */
export const BrokingIndicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokingIndicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべてのブローキング対象注文を取得する
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrokingIndications(isSpread?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrokingIndication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrokingIndications(isSpread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokingIndicationsApi - factory interface
 * @export
 */
export const BrokingIndicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokingIndicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary すべてのブローキング対象注文を取得する
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingIndications(isSpread?: boolean, options?: any): AxiosPromise<Array<BrokingIndication>> {
            return localVarFp.getBrokingIndications(isSpread, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokingIndicationsApi - object-oriented interface
 * @export
 * @class BrokingIndicationsApi
 * @extends {BaseAPI}
 */
export class BrokingIndicationsApi extends BaseAPI {
    /**
     * 
     * @summary すべてのブローキング対象注文を取得する
     * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokingIndicationsApi
     */
    public getBrokingIndications(isSpread?: boolean, options?: AxiosRequestConfig) {
        return BrokingIndicationsApiFp(this.configuration).getBrokingIndications(isSpread, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrokingOrderBooksApi - axios parameter creator
 * @export
 */
export const BrokingOrderBooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ブローカー向けの注文一覧
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingOrderBooks: async (isSpread?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/broking-order-books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (isSpread !== undefined) {
                localVarQueryParameter['isSpread'] = isSpread;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokingOrderBooksApi - functional programming interface
 * @export
 */
export const BrokingOrderBooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokingOrderBooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ブローカー向けの注文一覧
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrokingOrderBooks(isSpread?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrokingOrderBook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrokingOrderBooks(isSpread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokingOrderBooksApi - factory interface
 * @export
 */
export const BrokingOrderBooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokingOrderBooksApiFp(configuration)
    return {
        /**
         * 
         * @summary ブローカー向けの注文一覧
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrokingOrderBooks(isSpread?: boolean, options?: any): AxiosPromise<Array<BrokingOrderBook>> {
            return localVarFp.getBrokingOrderBooks(isSpread, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokingOrderBooksApi - object-oriented interface
 * @export
 * @class BrokingOrderBooksApi
 * @extends {BaseAPI}
 */
export class BrokingOrderBooksApi extends BaseAPI {
    /**
     * 
     * @summary ブローカー向けの注文一覧
     * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokingOrderBooksApi
     */
    public getBrokingOrderBooks(isSpread?: boolean, options?: AxiosRequestConfig) {
        return BrokingOrderBooksApiFp(this.configuration).getBrokingOrderBooks(isSpread, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarTypesApi - axios parameter creator
 * @export
 */
export const CalendarTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary カレンダー区分一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/calendar-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:calendar-types"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarTypesApi - functional programming interface
 * @export
 */
export const CalendarTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary カレンダー区分一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarTypesApi - factory interface
 * @export
 */
export const CalendarTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary カレンダー区分一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarTypes(options?: any): AxiosPromise<Array<CalendarType>> {
            return localVarFp.getCalendarTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarTypesApi - object-oriented interface
 * @export
 * @class CalendarTypesApi
 * @extends {BaseAPI}
 */
export class CalendarTypesApi extends BaseAPI {
    /**
     * 
     * @summary カレンダー区分一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarTypesApi
     */
    public getCalendarTypes(options?: AxiosRequestConfig) {
        return CalendarTypesApiFp(this.configuration).getCalendarTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatAccessTokenApi - axios parameter creator
 * @export
 */
export const ChatAccessTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary チャットのアクセストークンを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/chat-access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:chat-access-token"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatAccessTokenApi - functional programming interface
 * @export
 */
export const ChatAccessTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatAccessTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary チャットのアクセストークンを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatAccessTokenApi - factory interface
 * @export
 */
export const ChatAccessTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatAccessTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary チャットのアクセストークンを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken(options?: any): AxiosPromise<AccessToken> {
            return localVarFp.getAccessToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatAccessTokenApi - object-oriented interface
 * @export
 * @class ChatAccessTokenApi
 * @extends {BaseAPI}
 */
export class ChatAccessTokenApi extends BaseAPI {
    /**
     * 
     * @summary チャットのアクセストークンを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatAccessTokenApi
     */
    public getAccessToken(options?: AxiosRequestConfig) {
        return ChatAccessTokenApiFp(this.configuration).getAccessToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClearingInformationApi - axios parameter creator
 * @export
 */
export const ClearingInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織のクリアリング関連情報を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClearingInformation: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getClearingInformation', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/clearing-information`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:clearing-information"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TOCOMブローカーをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTOCOMBrokers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/tocom-brokers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:tocom-brokers"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のTOCOMクリアリング関連情報を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTOCOMClearingInformation: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTOCOMClearingInformation', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/tocom-clearing-information`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:clearing-information"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TOCOMクリアリング関連情報を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateTOCOMClearingInformation} updateTOCOMClearingInformation クリアリング関連情報変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTOCOMClearingInformation: async (organizationId: string, updateTOCOMClearingInformation: UpdateTOCOMClearingInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateTOCOMClearingInformation', 'organizationId', organizationId)
            // verify required parameter 'updateTOCOMClearingInformation' is not null or undefined
            assertParamExists('updateTOCOMClearingInformation', 'updateTOCOMClearingInformation', updateTOCOMClearingInformation)
            const localVarPath = `/trading/organizations/{organizationId}/tocom-clearing-information`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:clearing-information"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTOCOMClearingInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClearingInformationApi - functional programming interface
 * @export
 */
export const ClearingInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClearingInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織のクリアリング関連情報を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClearingInformation(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClearingInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClearingInformation(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TOCOMブローカーをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTOCOMBrokers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TOCOMBroker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTOCOMBrokers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のTOCOMクリアリング関連情報を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTOCOMClearingInformation(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TOCOMClearingInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTOCOMClearingInformation(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TOCOMクリアリング関連情報を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateTOCOMClearingInformation} updateTOCOMClearingInformation クリアリング関連情報変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTOCOMClearingInformation(organizationId: string, updateTOCOMClearingInformation: UpdateTOCOMClearingInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TOCOMClearingInformationItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTOCOMClearingInformation(organizationId, updateTOCOMClearingInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClearingInformationApi - factory interface
 * @export
 */
export const ClearingInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClearingInformationApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織のクリアリング関連情報を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClearingInformation(organizationId: string, options?: any): AxiosPromise<ClearingInformation> {
            return localVarFp.getClearingInformation(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TOCOMブローカーをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTOCOMBrokers(options?: any): AxiosPromise<Array<TOCOMBroker>> {
            return localVarFp.getTOCOMBrokers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のTOCOMクリアリング関連情報を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTOCOMClearingInformation(organizationId: string, options?: any): AxiosPromise<TOCOMClearingInformation> {
            return localVarFp.getTOCOMClearingInformation(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TOCOMクリアリング関連情報を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateTOCOMClearingInformation} updateTOCOMClearingInformation クリアリング関連情報変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTOCOMClearingInformation(organizationId: string, updateTOCOMClearingInformation: UpdateTOCOMClearingInformation, options?: any): AxiosPromise<Array<TOCOMClearingInformationItem>> {
            return localVarFp.updateTOCOMClearingInformation(organizationId, updateTOCOMClearingInformation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClearingInformationApi - object-oriented interface
 * @export
 * @class ClearingInformationApi
 * @extends {BaseAPI}
 */
export class ClearingInformationApi extends BaseAPI {
    /**
     * 
     * @summary 組織のクリアリング関連情報を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClearingInformationApi
     */
    public getClearingInformation(organizationId: string, options?: AxiosRequestConfig) {
        return ClearingInformationApiFp(this.configuration).getClearingInformation(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TOCOMブローカーをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClearingInformationApi
     */
    public getTOCOMBrokers(options?: AxiosRequestConfig) {
        return ClearingInformationApiFp(this.configuration).getTOCOMBrokers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のTOCOMクリアリング関連情報を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClearingInformationApi
     */
    public getTOCOMClearingInformation(organizationId: string, options?: AxiosRequestConfig) {
        return ClearingInformationApiFp(this.configuration).getTOCOMClearingInformation(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TOCOMクリアリング関連情報を変更する
     * @param {string} organizationId 組織ID
     * @param {UpdateTOCOMClearingInformation} updateTOCOMClearingInformation クリアリング関連情報変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClearingInformationApi
     */
    public updateTOCOMClearingInformation(organizationId: string, updateTOCOMClearingInformation: UpdateTOCOMClearingInformation, options?: AxiosRequestConfig) {
        return ClearingInformationApiFp(this.configuration).updateTOCOMClearingInformation(organizationId, updateTOCOMClearingInformation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DailyPowerMarketApi - axios parameter creator
 * @export
 */
export const DailyPowerMarketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary DailyPowerMarket市場の状態を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyPowerMarketStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/daily-power-market/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ブローカーが一括で注文を登録する
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} organizationId 注文を所有する組織ID
         * @param {string} [traderId] 担当トレーダーのユーザーID
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDailyPowerMarketIndications: async (file: File, organizationId: string, traderId?: string, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('newDailyPowerMarketIndications', 'file', file)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('newDailyPowerMarketIndications', 'organizationId', organizationId)
            const localVarPath = `/trading/daily-power-market/indications/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (organizationId !== undefined) { 
                localVarFormParams.append('organizationId', organizationId as any);
            }
    
            if (traderId !== undefined) { 
                localVarFormParams.append('traderId', traderId as any);
            }
    
            if (keyword !== undefined) { 
                localVarFormParams.append('keyword', keyword as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Daily Power Market の取引を新規作成する
         * @param {NewDailyPowerMarketTrade} newDailyPowerMarketTrade 新規取引オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDailyPowerMarketTrade: async (newDailyPowerMarketTrade: NewDailyPowerMarketTrade, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newDailyPowerMarketTrade' is not null or undefined
            assertParamExists('newDailyPowerMarketTrade', 'newDailyPowerMarketTrade', newDailyPowerMarketTrade)
            const localVarPath = `/trading/daily-power-market/trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDailyPowerMarketTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーダーが一括で注文を登録する
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMyDailyPowerMarketIndications: async (file: File, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('newMyDailyPowerMarketIndications', 'file', file)
            const localVarPath = `/trading/daily-power-market/my-indications/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:my-indications"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (keyword !== undefined) { 
                localVarFormParams.append('keyword', keyword as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ブローカーが一括注文の内容をバリデーションする
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} organizationId 注文を所有する組織ID
         * @param {string} [traderId] 担当トレーダーのユーザーID
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNewDailyPowerMarketIndications: async (file: File, organizationId: string, traderId?: string, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateNewDailyPowerMarketIndications', 'file', file)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('validateNewDailyPowerMarketIndications', 'organizationId', organizationId)
            const localVarPath = `/trading/daily-power-market/indications/csv/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (organizationId !== undefined) { 
                localVarFormParams.append('organizationId', organizationId as any);
            }
    
            if (traderId !== undefined) { 
                localVarFormParams.append('traderId', traderId as any);
            }
    
            if (keyword !== undefined) { 
                localVarFormParams.append('keyword', keyword as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーダーが一括注文の内容をバリデーションする
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNewMyDailyPowerMarketIndications: async (file: File, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateNewMyDailyPowerMarketIndications', 'file', file)
            const localVarPath = `/trading/daily-power-market/my-indications/csv/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:my-indications"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (keyword !== undefined) { 
                localVarFormParams.append('keyword', keyword as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DailyPowerMarketApi - functional programming interface
 * @export
 */
export const DailyPowerMarketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DailyPowerMarketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary DailyPowerMarket市場の状態を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyPowerMarketStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyPowerMarketStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyPowerMarketStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ブローカーが一括で注文を登録する
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} organizationId 注文を所有する組織ID
         * @param {string} [traderId] 担当トレーダーのユーザーID
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newDailyPowerMarketIndications(file: File, organizationId: string, traderId?: string, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newDailyPowerMarketIndications(file, organizationId, traderId, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Daily Power Market の取引を新規作成する
         * @param {NewDailyPowerMarketTrade} newDailyPowerMarketTrade 新規取引オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newDailyPowerMarketTrade(newDailyPowerMarketTrade: NewDailyPowerMarketTrade, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewDailyPowerMarketTradeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newDailyPowerMarketTrade(newDailyPowerMarketTrade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーダーが一括で注文を登録する
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newMyDailyPowerMarketIndications(file: File, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newMyDailyPowerMarketIndications(file, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ブローカーが一括注文の内容をバリデーションする
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} organizationId 注文を所有する組織ID
         * @param {string} [traderId] 担当トレーダーのユーザーID
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateNewDailyPowerMarketIndications(file: File, organizationId: string, traderId?: string, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateNewDailyPowerMarketIndicationsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateNewDailyPowerMarketIndications(file, organizationId, traderId, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーダーが一括注文の内容をバリデーションする
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateNewMyDailyPowerMarketIndications(file: File, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateNewMyDailyPowerMarketIndicationsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateNewMyDailyPowerMarketIndications(file, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DailyPowerMarketApi - factory interface
 * @export
 */
export const DailyPowerMarketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DailyPowerMarketApiFp(configuration)
    return {
        /**
         * 
         * @summary DailyPowerMarket市場の状態を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyPowerMarketStatus(options?: any): AxiosPromise<DailyPowerMarketStatus> {
            return localVarFp.getDailyPowerMarketStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ブローカーが一括で注文を登録する
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} organizationId 注文を所有する組織ID
         * @param {string} [traderId] 担当トレーダーのユーザーID
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDailyPowerMarketIndications(file: File, organizationId: string, traderId?: string, keyword?: string, options?: any): AxiosPromise<void> {
            return localVarFp.newDailyPowerMarketIndications(file, organizationId, traderId, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Daily Power Market の取引を新規作成する
         * @param {NewDailyPowerMarketTrade} newDailyPowerMarketTrade 新規取引オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newDailyPowerMarketTrade(newDailyPowerMarketTrade: NewDailyPowerMarketTrade, options?: any): AxiosPromise<NewDailyPowerMarketTradeResult> {
            return localVarFp.newDailyPowerMarketTrade(newDailyPowerMarketTrade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーダーが一括で注文を登録する
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMyDailyPowerMarketIndications(file: File, keyword?: string, options?: any): AxiosPromise<void> {
            return localVarFp.newMyDailyPowerMarketIndications(file, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ブローカーが一括注文の内容をバリデーションする
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} organizationId 注文を所有する組織ID
         * @param {string} [traderId] 担当トレーダーのユーザーID
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNewDailyPowerMarketIndications(file: File, organizationId: string, traderId?: string, keyword?: string, options?: any): AxiosPromise<ValidateNewDailyPowerMarketIndicationsResult> {
            return localVarFp.validateNewDailyPowerMarketIndications(file, organizationId, traderId, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーダーが一括注文の内容をバリデーションする
         * @param {File} file CSVファイルのコンテンツ
         * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNewMyDailyPowerMarketIndications(file: File, keyword?: string, options?: any): AxiosPromise<ValidateNewMyDailyPowerMarketIndicationsResult> {
            return localVarFp.validateNewMyDailyPowerMarketIndications(file, keyword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DailyPowerMarketApi - object-oriented interface
 * @export
 * @class DailyPowerMarketApi
 * @extends {BaseAPI}
 */
export class DailyPowerMarketApi extends BaseAPI {
    /**
     * 
     * @summary DailyPowerMarket市場の状態を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyPowerMarketApi
     */
    public getDailyPowerMarketStatus(options?: AxiosRequestConfig) {
        return DailyPowerMarketApiFp(this.configuration).getDailyPowerMarketStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ブローカーが一括で注文を登録する
     * @param {File} file CSVファイルのコンテンツ
     * @param {string} organizationId 注文を所有する組織ID
     * @param {string} [traderId] 担当トレーダーのユーザーID
     * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyPowerMarketApi
     */
    public newDailyPowerMarketIndications(file: File, organizationId: string, traderId?: string, keyword?: string, options?: AxiosRequestConfig) {
        return DailyPowerMarketApiFp(this.configuration).newDailyPowerMarketIndications(file, organizationId, traderId, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Daily Power Market の取引を新規作成する
     * @param {NewDailyPowerMarketTrade} newDailyPowerMarketTrade 新規取引オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyPowerMarketApi
     */
    public newDailyPowerMarketTrade(newDailyPowerMarketTrade: NewDailyPowerMarketTrade, options?: AxiosRequestConfig) {
        return DailyPowerMarketApiFp(this.configuration).newDailyPowerMarketTrade(newDailyPowerMarketTrade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーダーが一括で注文を登録する
     * @param {File} file CSVファイルのコンテンツ
     * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyPowerMarketApi
     */
    public newMyDailyPowerMarketIndications(file: File, keyword?: string, options?: AxiosRequestConfig) {
        return DailyPowerMarketApiFp(this.configuration).newMyDailyPowerMarketIndications(file, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ブローカーが一括注文の内容をバリデーションする
     * @param {File} file CSVファイルのコンテンツ
     * @param {string} organizationId 注文を所有する組織ID
     * @param {string} [traderId] 担当トレーダーのユーザーID
     * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyPowerMarketApi
     */
    public validateNewDailyPowerMarketIndications(file: File, organizationId: string, traderId?: string, keyword?: string, options?: AxiosRequestConfig) {
        return DailyPowerMarketApiFp(this.configuration).validateNewDailyPowerMarketIndications(file, organizationId, traderId, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーダーが一括注文の内容をバリデーションする
     * @param {File} file CSVファイルのコンテンツ
     * @param {string} [keyword] 確認キー。eClearの商品区分のときのみ必要
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyPowerMarketApi
     */
    public validateNewMyDailyPowerMarketIndications(file: File, keyword?: string, options?: AxiosRequestConfig) {
        return DailyPowerMarketApiFp(this.configuration).validateNewMyDailyPowerMarketIndications(file, keyword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DayPatternsApi - axios parameter creator
 * @export
 */
export const DayPatternsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 日パターン一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPatterns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/day-patterns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:day-patterns"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DayPatternsApi - functional programming interface
 * @export
 */
export const DayPatternsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DayPatternsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 日パターン一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayPatterns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DayPattern>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayPatterns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DayPatternsApi - factory interface
 * @export
 */
export const DayPatternsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DayPatternsApiFp(configuration)
    return {
        /**
         * 
         * @summary 日パターン一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPatterns(options?: any): AxiosPromise<Array<DayPattern>> {
            return localVarFp.getDayPatterns(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DayPatternsApi - object-oriented interface
 * @export
 * @class DayPatternsApi
 * @extends {BaseAPI}
 */
export class DayPatternsApi extends BaseAPI {
    /**
     * 
     * @summary 日パターン一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayPatternsApi
     */
    public getDayPatterns(options?: AxiosRequestConfig) {
        return DayPatternsApiFp(this.configuration).getDayPatterns(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DealsApi - axios parameter creator
 * @export
 */
export const DealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 約定を削除する
         * @param {string} dealId 削除対象の約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeal: async (dealId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('deleteDeal', 'dealId', dealId)
            const localVarPath = `/trading/deals/{dealId}`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 約定の詳細を取得する
         * @param {string} dealId 対象の約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeal: async (dealId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('getDeal', 'dealId', dealId)
            const localVarPath = `/trading/deals/{dealId}`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:deals", "read:recaps"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 約定の受給サマリを取得する
         * @param {string} dealId 約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealDeliverySummary: async (dealId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('getDealDeliverySummary', 'dealId', dealId)
            const localVarPath = `/trading/deals/{dealId}/delivery-summary`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 約定一覧を取得する
         * @param {string} [lastId] 取得したい約定一覧の直前のID。このID以降の約定が返される。未指定の場合は、最新の更新日時から約定一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {string} [organizationName] 組織名
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分
         * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
         * @param {string} [publicNegotiationId] 成約ID
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {boolean} [privateConfidential] P&amp;C
         * @param {string} [executedDate] 約定日 (ex. 2022-01-01)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealSummaries: async (lastId?: string, organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, publicNegotiationId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, privateConfidential?: boolean, executedDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:deals"], configuration)

            if (lastId !== undefined) {
                localVarQueryParameter['lastId'] = lastId;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (hourTypeId !== undefined) {
                localVarQueryParameter['hourTypeId'] = hourTypeId;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['productTypeId'] = productTypeId;
            }

            if (publicNegotiationId !== undefined) {
                localVarQueryParameter['publicNegotiationId'] = publicNegotiationId;
            }

            if (startDeliveryYearMonth !== undefined) {
                localVarQueryParameter['startDeliveryYearMonth'] = startDeliveryYearMonth;
            }

            if (endDeliveryYearMonth !== undefined) {
                localVarQueryParameter['endDeliveryYearMonth'] = endDeliveryYearMonth;
            }

            if (privateConfidential !== undefined) {
                localVarQueryParameter['privateConfidential'] = privateConfidential;
            }

            if (executedDate !== undefined) {
                localVarQueryParameter['executedDate'] = (executedDate as any instanceof Date) ?
                    (executedDate as any).toISOString().substr(0,10) :
                    executedDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 約定の取引ステップを取得する
         * @param {string} dealId 約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealTradingSteps: async (dealId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('getDealTradingSteps', 'dealId', dealId)
            const localVarPath = `/trading/deals/{dealId}/trading-steps`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 約定を変更する
         * @param {string} dealId 変更対象の約定ID
         * @param {UpdateDeal} updateDeal 約定変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal: async (dealId: string, updateDeal: UpdateDeal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('updateDeal', 'dealId', dealId)
            // verify required parameter 'updateDeal' is not null or undefined
            assertParamExists('updateDeal', 'updateDeal', updateDeal)
            const localVarPath = `/trading/deals/{dealId}`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:deals", "update:recaps"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealsApi - functional programming interface
 * @export
 */
export const DealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 約定を削除する
         * @param {string} dealId 削除対象の約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeal(dealId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeal(dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 約定の詳細を取得する
         * @param {string} dealId 対象の約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeal(dealId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealWithOrganizationRecap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeal(dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 約定の受給サマリを取得する
         * @param {string} dealId 約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDealDeliverySummary(dealId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliverySummaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDealDeliverySummary(dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 約定一覧を取得する
         * @param {string} [lastId] 取得したい約定一覧の直前のID。このID以降の約定が返される。未指定の場合は、最新の更新日時から約定一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {string} [organizationName] 組織名
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分
         * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
         * @param {string} [publicNegotiationId] 成約ID
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {boolean} [privateConfidential] P&amp;C
         * @param {string} [executedDate] 約定日 (ex. 2022-01-01)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDealSummaries(lastId?: string, organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, publicNegotiationId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, privateConfidential?: boolean, executedDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDealSummaries(lastId, organizationName, areaId, hourTypeId, productTypeId, publicNegotiationId, startDeliveryYearMonth, endDeliveryYearMonth, privateConfidential, executedDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 約定の取引ステップを取得する
         * @param {string} dealId 約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDealTradingSteps(dealId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingSteps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDealTradingSteps(dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 約定を変更する
         * @param {string} dealId 変更対象の約定ID
         * @param {UpdateDeal} updateDeal 約定変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeal(dealId: string, updateDeal: UpdateDeal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealWithOrganizationRecap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeal(dealId, updateDeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealsApi - factory interface
 * @export
 */
export const DealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealsApiFp(configuration)
    return {
        /**
         * 
         * @summary 約定を削除する
         * @param {string} dealId 削除対象の約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeal(dealId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeal(dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 約定の詳細を取得する
         * @param {string} dealId 対象の約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeal(dealId: string, options?: any): AxiosPromise<DealWithOrganizationRecap> {
            return localVarFp.getDeal(dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 約定の受給サマリを取得する
         * @param {string} dealId 約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealDeliverySummary(dealId: string, options?: any): AxiosPromise<DeliverySummaries> {
            return localVarFp.getDealDeliverySummary(dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 約定一覧を取得する
         * @param {string} [lastId] 取得したい約定一覧の直前のID。このID以降の約定が返される。未指定の場合は、最新の更新日時から約定一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {string} [organizationName] 組織名
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分
         * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
         * @param {string} [publicNegotiationId] 成約ID
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {boolean} [privateConfidential] P&amp;C
         * @param {string} [executedDate] 約定日 (ex. 2022-01-01)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealSummaries(lastId?: string, organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, publicNegotiationId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, privateConfidential?: boolean, executedDate?: string, options?: any): AxiosPromise<Array<DealSummary>> {
            return localVarFp.getDealSummaries(lastId, organizationName, areaId, hourTypeId, productTypeId, publicNegotiationId, startDeliveryYearMonth, endDeliveryYearMonth, privateConfidential, executedDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 約定の取引ステップを取得する
         * @param {string} dealId 約定ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealTradingSteps(dealId: string, options?: any): AxiosPromise<TradingSteps> {
            return localVarFp.getDealTradingSteps(dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 約定を変更する
         * @param {string} dealId 変更対象の約定ID
         * @param {UpdateDeal} updateDeal 約定変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal(dealId: string, updateDeal: UpdateDeal, options?: any): AxiosPromise<DealWithOrganizationRecap> {
            return localVarFp.updateDeal(dealId, updateDeal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DealsApi - object-oriented interface
 * @export
 * @class DealsApi
 * @extends {BaseAPI}
 */
export class DealsApi extends BaseAPI {
    /**
     * 
     * @summary 約定を削除する
     * @param {string} dealId 削除対象の約定ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public deleteDeal(dealId: string, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).deleteDeal(dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 約定の詳細を取得する
     * @param {string} dealId 対象の約定ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public getDeal(dealId: string, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).getDeal(dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 約定の受給サマリを取得する
     * @param {string} dealId 約定ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public getDealDeliverySummary(dealId: string, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).getDealDeliverySummary(dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 約定一覧を取得する
     * @param {string} [lastId] 取得したい約定一覧の直前のID。このID以降の約定が返される。未指定の場合は、最新の更新日時から約定一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
     * @param {string} [organizationName] 組織名
     * @param {string} [areaId] エリアID
     * @param {string} [hourTypeId] 時間帯区分
     * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
     * @param {string} [publicNegotiationId] 成約ID
     * @param {string} [startDeliveryYearMonth] 開始月
     * @param {string} [endDeliveryYearMonth] 終了月
     * @param {boolean} [privateConfidential] P&amp;C
     * @param {string} [executedDate] 約定日 (ex. 2022-01-01)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public getDealSummaries(lastId?: string, organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, publicNegotiationId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, privateConfidential?: boolean, executedDate?: string, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).getDealSummaries(lastId, organizationName, areaId, hourTypeId, productTypeId, publicNegotiationId, startDeliveryYearMonth, endDeliveryYearMonth, privateConfidential, executedDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 約定の取引ステップを取得する
     * @param {string} dealId 約定ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public getDealTradingSteps(dealId: string, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).getDealTradingSteps(dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 約定を変更する
     * @param {string} dealId 変更対象の約定ID
     * @param {UpdateDeal} updateDeal 約定変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public updateDeal(dealId: string, updateDeal: UpdateDeal, options?: AxiosRequestConfig) {
        return DealsApiFp(this.configuration).updateDeal(dealId, updateDeal, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultBrokingFeeOfOrganizationsApi - axios parameter creator
 * @export
 */
export const DefaultBrokingFeeOfOrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織毎にカスタマイズされたデフォルト仲介手数料を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomizeDefaultBrokingFeeOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getCustomizeDefaultBrokingFeeOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/customize-default-broking-fees`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 組織毎にカスタマイズされたデフォルトの仲介手数料が設定されている場合はカスタマイズされたデフォルトの仲介手数料を優先して返す。 設定されていない場合は商品区分のデフォルトの仲介手数料を返す 
         * @summary デフォルト仲介手数料一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultBrokingFeeOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getDefaultBrokingFeeOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/default-broking-fees`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デフォルト仲介手数料を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateDefaultBrokingFeeOfOrganizations} updateDefaultBrokingFeeOfOrganizations デフォルト仲介手数料変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomizeDefaultBrokingFeeOfOrganization: async (organizationId: string, updateDefaultBrokingFeeOfOrganizations: UpdateDefaultBrokingFeeOfOrganizations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateCustomizeDefaultBrokingFeeOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'updateDefaultBrokingFeeOfOrganizations' is not null or undefined
            assertParamExists('updateCustomizeDefaultBrokingFeeOfOrganization', 'updateDefaultBrokingFeeOfOrganizations', updateDefaultBrokingFeeOfOrganizations)
            const localVarPath = `/trading/organizations/{organizationId}/customize-default-broking-fees`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDefaultBrokingFeeOfOrganizations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultBrokingFeeOfOrganizationsApi - functional programming interface
 * @export
 */
export const DefaultBrokingFeeOfOrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultBrokingFeeOfOrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織毎にカスタマイズされたデフォルト仲介手数料を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomizeDefaultBrokingFeeOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultBrokingFeeOfOrganizations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomizeDefaultBrokingFeeOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 組織毎にカスタマイズされたデフォルトの仲介手数料が設定されている場合はカスタマイズされたデフォルトの仲介手数料を優先して返す。 設定されていない場合は商品区分のデフォルトの仲介手数料を返す 
         * @summary デフォルト仲介手数料一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultBrokingFeeOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefaultBrokingFees>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultBrokingFeeOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デフォルト仲介手数料を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateDefaultBrokingFeeOfOrganizations} updateDefaultBrokingFeeOfOrganizations デフォルト仲介手数料変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomizeDefaultBrokingFeeOfOrganization(organizationId: string, updateDefaultBrokingFeeOfOrganizations: UpdateDefaultBrokingFeeOfOrganizations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultBrokingFeeOfOrganizations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomizeDefaultBrokingFeeOfOrganization(organizationId, updateDefaultBrokingFeeOfOrganizations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultBrokingFeeOfOrganizationsApi - factory interface
 * @export
 */
export const DefaultBrokingFeeOfOrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultBrokingFeeOfOrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織毎にカスタマイズされたデフォルト仲介手数料を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomizeDefaultBrokingFeeOfOrganization(organizationId: string, options?: any): AxiosPromise<DefaultBrokingFeeOfOrganizations> {
            return localVarFp.getCustomizeDefaultBrokingFeeOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 組織毎にカスタマイズされたデフォルトの仲介手数料が設定されている場合はカスタマイズされたデフォルトの仲介手数料を優先して返す。 設定されていない場合は商品区分のデフォルトの仲介手数料を返す 
         * @summary デフォルト仲介手数料一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultBrokingFeeOfOrganization(organizationId: string, options?: any): AxiosPromise<Array<DefaultBrokingFees>> {
            return localVarFp.getDefaultBrokingFeeOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デフォルト仲介手数料を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateDefaultBrokingFeeOfOrganizations} updateDefaultBrokingFeeOfOrganizations デフォルト仲介手数料変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomizeDefaultBrokingFeeOfOrganization(organizationId: string, updateDefaultBrokingFeeOfOrganizations: UpdateDefaultBrokingFeeOfOrganizations, options?: any): AxiosPromise<DefaultBrokingFeeOfOrganizations> {
            return localVarFp.updateCustomizeDefaultBrokingFeeOfOrganization(organizationId, updateDefaultBrokingFeeOfOrganizations, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultBrokingFeeOfOrganizationsApi - object-oriented interface
 * @export
 * @class DefaultBrokingFeeOfOrganizationsApi
 * @extends {BaseAPI}
 */
export class DefaultBrokingFeeOfOrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary 組織毎にカスタマイズされたデフォルト仲介手数料を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultBrokingFeeOfOrganizationsApi
     */
    public getCustomizeDefaultBrokingFeeOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return DefaultBrokingFeeOfOrganizationsApiFp(this.configuration).getCustomizeDefaultBrokingFeeOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 組織毎にカスタマイズされたデフォルトの仲介手数料が設定されている場合はカスタマイズされたデフォルトの仲介手数料を優先して返す。 設定されていない場合は商品区分のデフォルトの仲介手数料を返す 
     * @summary デフォルト仲介手数料一覧を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultBrokingFeeOfOrganizationsApi
     */
    public getDefaultBrokingFeeOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return DefaultBrokingFeeOfOrganizationsApiFp(this.configuration).getDefaultBrokingFeeOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デフォルト仲介手数料を変更する
     * @param {string} organizationId 組織ID
     * @param {UpdateDefaultBrokingFeeOfOrganizations} updateDefaultBrokingFeeOfOrganizations デフォルト仲介手数料変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultBrokingFeeOfOrganizationsApi
     */
    public updateCustomizeDefaultBrokingFeeOfOrganization(organizationId: string, updateDefaultBrokingFeeOfOrganizations: UpdateDefaultBrokingFeeOfOrganizations, options?: AxiosRequestConfig) {
        return DefaultBrokingFeeOfOrganizationsApiFp(this.configuration).updateCustomizeDefaultBrokingFeeOfOrganization(organizationId, updateDefaultBrokingFeeOfOrganizations, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryYearMonthsApi - axios parameter creator
 * @export
 */
export const DeliveryYearMonthsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのデリバリー開始年月を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryYearMonths: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/delivery-year-months`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryYearMonthsApi - functional programming interface
 * @export
 */
export const DeliveryYearMonthsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryYearMonthsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべてのデリバリー開始年月を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryYearMonths(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryYearMonth>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryYearMonths(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryYearMonthsApi - factory interface
 * @export
 */
export const DeliveryYearMonthsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryYearMonthsApiFp(configuration)
    return {
        /**
         * 
         * @summary すべてのデリバリー開始年月を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryYearMonths(options?: any): AxiosPromise<Array<DeliveryYearMonth>> {
            return localVarFp.getDeliveryYearMonths(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryYearMonthsApi - object-oriented interface
 * @export
 * @class DeliveryYearMonthsApi
 * @extends {BaseAPI}
 */
export class DeliveryYearMonthsApi extends BaseAPI {
    /**
     * 
     * @summary すべてのデリバリー開始年月を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryYearMonthsApi
     */
    public getDeliveryYearMonths(options?: AxiosRequestConfig) {
        return DeliveryYearMonthsApiFp(this.configuration).getDeliveryYearMonths(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EclearApi - axios parameter creator
 * @export
 */
export const EclearApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 受給カレンダーを取得する
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalenderDates: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eclear/calendar-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 受給パターンを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryPatterns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eclear/delivery-patterns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:delivery-patterns"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 需要調達計画に必要な確認情報を取得する
         * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {string} [lastId] ページングに利用する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecaps: async (area?: string, startDate?: string, endDate?: string, lastId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eclear/recaps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:deals", "read:negotiations", "read:recaps"], configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (lastId !== undefined) {
                localVarQueryParameter['lastId'] = lastId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EclearApi - functional programming interface
 * @export
 */
export const EclearApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EclearApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 受給カレンダーを取得する
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalenderDates(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EClearCalendarDate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalenderDates(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 受給パターンを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryPatterns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EClearDeliveryPattern>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryPatterns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 需要調達計画に必要な確認情報を取得する
         * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {string} [lastId] ページングに利用する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecaps(area?: string, startDate?: string, endDate?: string, lastId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EClearRecap>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecaps(area, startDate, endDate, lastId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EclearApi - factory interface
 * @export
 */
export const EclearApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EclearApiFp(configuration)
    return {
        /**
         * 
         * @summary 受給カレンダーを取得する
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalenderDates(startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<EClearCalendarDate>> {
            return localVarFp.getCalenderDates(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 受給パターンを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryPatterns(options?: any): AxiosPromise<Array<EClearDeliveryPattern>> {
            return localVarFp.getDeliveryPatterns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 需要調達計画に必要な確認情報を取得する
         * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {string} [lastId] ページングに利用する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecaps(area?: string, startDate?: string, endDate?: string, lastId?: string, options?: any): AxiosPromise<Array<EClearRecap>> {
            return localVarFp.getRecaps(area, startDate, endDate, lastId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EclearApi - object-oriented interface
 * @export
 * @class EclearApi
 * @extends {BaseAPI}
 */
export class EclearApi extends BaseAPI {
    /**
     * 
     * @summary 受給カレンダーを取得する
     * @param {string} [startDate] 開始日
     * @param {string} [endDate] 終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EclearApi
     */
    public getCalenderDates(startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return EclearApiFp(this.configuration).getCalenderDates(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 受給パターンを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EclearApi
     */
    public getDeliveryPatterns(options?: AxiosRequestConfig) {
        return EclearApiFp(this.configuration).getDeliveryPatterns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 需要調達計画に必要な確認情報を取得する
     * @param {string} [area] 公開注文のフィルタに使う。指定されたエリアIDでフィルタされる。
     * @param {string} [startDate] 開始日
     * @param {string} [endDate] 終了日
     * @param {string} [lastId] ページングに利用する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EclearApi
     */
    public getRecaps(area?: string, startDate?: string, endDate?: string, lastId?: string, options?: AxiosRequestConfig) {
        return EclearApiFp(this.configuration).getRecaps(area, startDate, endDate, lastId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EexApi - axios parameter creator
 * @export
 */
export const EexApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織のEEXクリアリング口座一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXClearingAccountsOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getEEXClearingAccountsOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/eex-clearing-accounts`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:clearing-information"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のEEXトレーダー一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXTradersOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getEEXTradersOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/eex-traders`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:organizations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のEEXトレーディングファームを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXTradingFirmOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getEEXTradingFirmOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/eex-trading-firm`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:clearing-information"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーディングファーム一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXTradingFirms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/eex-trading-firms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:organizations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のEEXトレーディングファームを更新する
         * @param {string} organizationId 組織ID
         * @param {UpdateEEXTradingFirm} updateEEXTradingFirm 組織とEEXトレーディングファームのひも付きの更新オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEEXTradingFirmOfOrganization: async (organizationId: string, updateEEXTradingFirm: UpdateEEXTradingFirm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateEEXTradingFirmOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'updateEEXTradingFirm' is not null or undefined
            assertParamExists('updateEEXTradingFirmOfOrganization', 'updateEEXTradingFirm', updateEEXTradingFirm)
            const localVarPath = `/trading/organizations/{organizationId}/eex-trading-firm`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEEXTradingFirm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EexApi - functional programming interface
 * @export
 */
export const EexApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EexApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織のEEXクリアリング口座一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEEXClearingAccountsOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EEXClearingAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEEXClearingAccountsOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のEEXトレーダー一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEEXTradersOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EEXTrader>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEEXTradersOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のEEXトレーディングファームを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEEXTradingFirmOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EEXTradingFirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEEXTradingFirmOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーディングファーム一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEEXTradingFirms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EEXTradingFirm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEEXTradingFirms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のEEXトレーディングファームを更新する
         * @param {string} organizationId 組織ID
         * @param {UpdateEEXTradingFirm} updateEEXTradingFirm 組織とEEXトレーディングファームのひも付きの更新オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEEXTradingFirmOfOrganization(organizationId: string, updateEEXTradingFirm: UpdateEEXTradingFirm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEEXTradingFirmOfOrganization(organizationId, updateEEXTradingFirm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EexApi - factory interface
 * @export
 */
export const EexApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EexApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織のEEXクリアリング口座一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXClearingAccountsOfOrganization(organizationId: string, options?: any): AxiosPromise<Array<EEXClearingAccount>> {
            return localVarFp.getEEXClearingAccountsOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のEEXトレーダー一覧を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXTradersOfOrganization(organizationId: string, options?: any): AxiosPromise<Array<EEXTrader>> {
            return localVarFp.getEEXTradersOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のEEXトレーディングファームを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXTradingFirmOfOrganization(organizationId: string, options?: any): AxiosPromise<EEXTradingFirm> {
            return localVarFp.getEEXTradingFirmOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーディングファーム一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXTradingFirms(options?: any): AxiosPromise<Array<EEXTradingFirm>> {
            return localVarFp.getEEXTradingFirms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のEEXトレーディングファームを更新する
         * @param {string} organizationId 組織ID
         * @param {UpdateEEXTradingFirm} updateEEXTradingFirm 組織とEEXトレーディングファームのひも付きの更新オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEEXTradingFirmOfOrganization(organizationId: string, updateEEXTradingFirm: UpdateEEXTradingFirm, options?: any): AxiosPromise<void> {
            return localVarFp.updateEEXTradingFirmOfOrganization(organizationId, updateEEXTradingFirm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EexApi - object-oriented interface
 * @export
 * @class EexApi
 * @extends {BaseAPI}
 */
export class EexApi extends BaseAPI {
    /**
     * 
     * @summary 組織のEEXクリアリング口座一覧を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexApi
     */
    public getEEXClearingAccountsOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return EexApiFp(this.configuration).getEEXClearingAccountsOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のEEXトレーダー一覧を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexApi
     */
    public getEEXTradersOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return EexApiFp(this.configuration).getEEXTradersOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のEEXトレーディングファームを取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexApi
     */
    public getEEXTradingFirmOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return EexApiFp(this.configuration).getEEXTradingFirmOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーディングファーム一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexApi
     */
    public getEEXTradingFirms(options?: AxiosRequestConfig) {
        return EexApiFp(this.configuration).getEEXTradingFirms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のEEXトレーディングファームを更新する
     * @param {string} organizationId 組織ID
     * @param {UpdateEEXTradingFirm} updateEEXTradingFirm 組織とEEXトレーディングファームのひも付きの更新オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexApi
     */
    public updateEEXTradingFirmOfOrganization(organizationId: string, updateEEXTradingFirm: UpdateEEXTradingFirm, options?: AxiosRequestConfig) {
        return EexApiFp(this.configuration).updateEEXTradingFirmOfOrganization(organizationId, updateEEXTradingFirm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EexPostingsApi - axios parameter creator
 * @export
 */
export const EexPostingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary EEXポスティング一覧を取得する
         * @param {number} [pageSize] 1ページあたりの最大件数
         * @param {string} [pageToken] 次のページのトークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXPostings: async (pageSize?: number, pageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/eex-postings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:eex-postings"], configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ポスティングを依頼する
         * @param {NewEEXPosting} newEEXPosting 新規EEXトレードオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newEEXPosting: async (newEEXPosting: NewEEXPosting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newEEXPosting' is not null or undefined
            assertParamExists('newEEXPosting', 'newEEXPosting', newEEXPosting)
            const localVarPath = `/trading/eex-postings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:eex-postings"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newEEXPosting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EexPostingsApi - functional programming interface
 * @export
 */
export const EexPostingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EexPostingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary EEXポスティング一覧を取得する
         * @param {number} [pageSize] 1ページあたりの最大件数
         * @param {string} [pageToken] 次のページのトークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEEXPostings(pageSize?: number, pageToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EEXPostingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEEXPostings(pageSize, pageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ポスティングを依頼する
         * @param {NewEEXPosting} newEEXPosting 新規EEXトレードオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newEEXPosting(newEEXPosting: NewEEXPosting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newEEXPosting(newEEXPosting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EexPostingsApi - factory interface
 * @export
 */
export const EexPostingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EexPostingsApiFp(configuration)
    return {
        /**
         * 
         * @summary EEXポスティング一覧を取得する
         * @param {number} [pageSize] 1ページあたりの最大件数
         * @param {string} [pageToken] 次のページのトークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEEXPostings(pageSize?: number, pageToken?: string, options?: any): AxiosPromise<EEXPostingsResponse> {
            return localVarFp.getEEXPostings(pageSize, pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ポスティングを依頼する
         * @param {NewEEXPosting} newEEXPosting 新規EEXトレードオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newEEXPosting(newEEXPosting: NewEEXPosting, options?: any): AxiosPromise<void> {
            return localVarFp.newEEXPosting(newEEXPosting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EexPostingsApi - object-oriented interface
 * @export
 * @class EexPostingsApi
 * @extends {BaseAPI}
 */
export class EexPostingsApi extends BaseAPI {
    /**
     * 
     * @summary EEXポスティング一覧を取得する
     * @param {number} [pageSize] 1ページあたりの最大件数
     * @param {string} [pageToken] 次のページのトークン
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexPostingsApi
     */
    public getEEXPostings(pageSize?: number, pageToken?: string, options?: AxiosRequestConfig) {
        return EexPostingsApiFp(this.configuration).getEEXPostings(pageSize, pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ポスティングを依頼する
     * @param {NewEEXPosting} newEEXPosting 新規EEXトレードオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EexPostingsApi
     */
    public newEEXPosting(newEEXPosting: NewEEXPosting, options?: AxiosRequestConfig) {
        return EexPostingsApiFp(this.configuration).newEEXPosting(newEEXPosting, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FuelSurchargeTypesApi - axios parameter creator
 * @export
 */
export const FuelSurchargeTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての燃調区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuelSurchargeTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/fuel-surcharge-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FuelSurchargeTypesApi - functional programming interface
 * @export
 */
export const FuelSurchargeTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FuelSurchargeTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての燃調区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFuelSurchargeTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FuelSurchargeType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFuelSurchargeTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FuelSurchargeTypesApi - factory interface
 * @export
 */
export const FuelSurchargeTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FuelSurchargeTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての燃調区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuelSurchargeTypes(options?: any): AxiosPromise<Array<FuelSurchargeType>> {
            return localVarFp.getFuelSurchargeTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FuelSurchargeTypesApi - object-oriented interface
 * @export
 * @class FuelSurchargeTypesApi
 * @extends {BaseAPI}
 */
export class FuelSurchargeTypesApi extends BaseAPI {
    /**
     * 
     * @summary すべての燃調区分を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuelSurchargeTypesApi
     */
    public getFuelSurchargeTypes(options?: AxiosRequestConfig) {
        return FuelSurchargeTypesApiFp(this.configuration).getFuelSurchargeTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HourTypesApi - axios parameter creator
 * @export
 */
export const HourTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての時間帯区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/hour-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:hour-types"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HourTypesApi - functional programming interface
 * @export
 */
export const HourTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HourTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての時間帯区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHourTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HourType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHourTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HourTypesApi - factory interface
 * @export
 */
export const HourTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HourTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての時間帯区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHourTypes(options?: any): AxiosPromise<Array<HourType>> {
            return localVarFp.getHourTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HourTypesApi - object-oriented interface
 * @export
 * @class HourTypesApi
 * @extends {BaseAPI}
 */
export class HourTypesApi extends BaseAPI {
    /**
     * 
     * @summary すべての時間帯区分を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourTypesApi
     */
    public getHourTypes(options?: AxiosRequestConfig) {
        return HourTypesApiFp(this.configuration).getHourTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndicationStatusTypesApi - axios parameter creator
 * @export
 */
export const IndicationStatusTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての注文ステータスを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicationStatusTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/indication-status-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:indication-status-types"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndicationStatusTypesApi - functional programming interface
 * @export
 */
export const IndicationStatusTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndicationStatusTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての注文ステータスを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndicationStatusTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicationStatusTypeSpec>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicationStatusTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndicationStatusTypesApi - factory interface
 * @export
 */
export const IndicationStatusTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndicationStatusTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての注文ステータスを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicationStatusTypes(options?: any): AxiosPromise<Array<IndicationStatusTypeSpec>> {
            return localVarFp.getIndicationStatusTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndicationStatusTypesApi - object-oriented interface
 * @export
 * @class IndicationStatusTypesApi
 * @extends {BaseAPI}
 */
export class IndicationStatusTypesApi extends BaseAPI {
    /**
     * 
     * @summary すべての注文ステータスを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationStatusTypesApi
     */
    public getIndicationStatusTypes(options?: AxiosRequestConfig) {
        return IndicationStatusTypesApiFp(this.configuration).getIndicationStatusTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndicationsApi - axios parameter creator
 * @export
 */
export const IndicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 削除対象の注文ID
         * @param {string} [memo] 自由記入欄
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndication: async (indicationId: string, memo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('deleteIndication', 'indicationId', indicationId)
            const localVarPath = `/trading/indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (memo !== undefined) {
                localVarQueryParameter['memo'] = memo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ブローカーの新規注文、注文詳細画面で選択可能な選択肢を得る
         * @param {StandardIndicationSelectableInputsRequest} standardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStandardIndicationSelectableInputs: async (standardIndicationSelectableInputsRequest: StandardIndicationSelectableInputsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardIndicationSelectableInputsRequest' is not null or undefined
            assertParamExists('generateStandardIndicationSelectableInputs', 'standardIndicationSelectableInputsRequest', standardIndicationSelectableInputsRequest)
            const localVarPath = `/trading/indications/standard-selectable-inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(standardIndicationSelectableInputsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 呪文からブローカーの新規注文の入力値及び選択可能な選択肢を得る
         * @param {StandardIndicationSelectableInputsSpellRequest} standardIndicationSelectableInputsSpellRequest ユーザーが入力している呪文のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStandardIndicationSelectableInputsBySpell: async (standardIndicationSelectableInputsSpellRequest: StandardIndicationSelectableInputsSpellRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardIndicationSelectableInputsSpellRequest' is not null or undefined
            assertParamExists('generateStandardIndicationSelectableInputsBySpell', 'standardIndicationSelectableInputsSpellRequest', standardIndicationSelectableInputsSpellRequest)
            const localVarPath = `/trading/indications/standard-selectable-inputs/spell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(standardIndicationSelectableInputsSpellRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndication: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('getIndication', 'indicationId', indicationId)
            const localVarPath = `/trading/indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文の受給サマリを取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicationDeliverySummary: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('getIndicationDeliverySummary', 'indicationId', indicationId)
            const localVarPath = `/trading/indications/{indicationId}/delivery-summary`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文の取引ステップを取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicationTradingSteps: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('getIndicationTradingSteps', 'indicationId', indicationId)
            const localVarPath = `/trading/indications/{indicationId}/trading-steps`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文一覧を取得する
         * @param {string} [organizationName] 組織名・略称
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分ID
         * @param {string} [productTypeId] 商品区分ID
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted'} [status] 注文ステータス
         * @param {'ask' | 'bid'} [position] ポジション
         * @param {boolean} [nonStandard] 非標準商品のみ
         * @param {string} [productId] 商品ID
         * @param {string} [deliveryTermsId] 受給条件ID
         * @param {string} [exchangeDeliveryTermsId] 交換側の受給条件ID
         * @param {string} [fuelSurchargeTypeId] 燃調区分ID
         * @param {string} [lastId] 取得したい注文一覧の直前のID。このID以降の注文が返される。未指定の場合は、最新の更新日時から注文一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {string} [fromDate] 注文更新日
         * @param {boolean} [excludeTbd] TBDの注文を含むかのフラグ、trueの場合はTBDを除外する
         * @param {boolean} [isDailyPowerMarket] DailyPowerMarket市場の注文かどうか
         * @param {'normal' | 'spread' | 'swing-option'} [productSubType] 商品の種類
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndications: async (organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, status?: 'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted', position?: 'ask' | 'bid', nonStandard?: boolean, productId?: string, deliveryTermsId?: string, exchangeDeliveryTermsId?: string, fuelSurchargeTypeId?: string, lastId?: string, fromDate?: string, excludeTbd?: boolean, isDailyPowerMarket?: boolean, productSubType?: 'normal' | 'spread' | 'swing-option', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:indications"], configuration)

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (hourTypeId !== undefined) {
                localVarQueryParameter['hourTypeId'] = hourTypeId;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['productTypeId'] = productTypeId;
            }

            if (startDeliveryYearMonth !== undefined) {
                localVarQueryParameter['startDeliveryYearMonth'] = startDeliveryYearMonth;
            }

            if (endDeliveryYearMonth !== undefined) {
                localVarQueryParameter['endDeliveryYearMonth'] = endDeliveryYearMonth;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (position !== undefined) {
                localVarQueryParameter['position'] = position;
            }

            if (nonStandard !== undefined) {
                localVarQueryParameter['nonStandard'] = nonStandard;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (deliveryTermsId !== undefined) {
                localVarQueryParameter['deliveryTermsId'] = deliveryTermsId;
            }

            if (exchangeDeliveryTermsId !== undefined) {
                localVarQueryParameter['exchangeDeliveryTermsId'] = exchangeDeliveryTermsId;
            }

            if (fuelSurchargeTypeId !== undefined) {
                localVarQueryParameter['fuelSurchargeTypeId'] = fuelSurchargeTypeId;
            }

            if (lastId !== undefined) {
                localVarQueryParameter['lastId'] = lastId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (excludeTbd !== undefined) {
                localVarQueryParameter['excludeTbd'] = excludeTbd;
            }

            if (isDailyPowerMarket !== undefined) {
                localVarQueryParameter['isDailyPowerMarket'] = isDailyPowerMarket;
            }

            if (productSubType !== undefined) {
                localVarQueryParameter['productSubType'] = productSubType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非標準商品の注文を新規作成する
         * @param {NewNonStandardIndication} newNonStandardIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newNonStandardIndication: async (newNonStandardIndication: NewNonStandardIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newNonStandardIndication' is not null or undefined
            assertParamExists('newNonStandardIndication', 'newNonStandardIndication', newNonStandardIndication)
            const localVarPath = `/trading/indications/non-standard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newNonStandardIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 標準商品の注文を新規作成する
         * @param {NewStandardIndication} newStandardIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newStandardIndication: async (newStandardIndication: NewStandardIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newStandardIndication' is not null or undefined
            assertParamExists('newStandardIndication', 'newStandardIndication', newStandardIndication)
            const localVarPath = `/trading/indications/standard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newStandardIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DailyPowerMarketの有効な注文をトレーダーから不可視にする
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingDailyPowerMarketIndicationsMakeInvisiblePost: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('tradingDailyPowerMarketIndicationsMakeInvisiblePost', 'body', body)
            const localVarPath = `/trading/daily-power-market/indications/make-invisible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を期限切れにする
         * @param {PubSubMessage} pubSubMessage 期限切れのイベント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingIndicationsExpirePost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('tradingIndicationsExpirePost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/trading/indications/expire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を変更する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateIndication} updateIndication 注文変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndication: async (indicationId: string, updateIndication: UpdateIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('updateIndication', 'indicationId', indicationId)
            // verify required parameter 'updateIndication' is not null or undefined
            assertParamExists('updateIndication', 'updateIndication', updateIndication)
            const localVarPath = `/trading/indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 非標準商品の注文を更新する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateNonStandardIndication} updateNonStandardIndication 注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNonStandardIndication: async (indicationId: string, updateNonStandardIndication: UpdateNonStandardIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('updateNonStandardIndication', 'indicationId', indicationId)
            // verify required parameter 'updateNonStandardIndication' is not null or undefined
            assertParamExists('updateNonStandardIndication', 'updateNonStandardIndication', updateNonStandardIndication)
            const localVarPath = `/trading/indications/non-standard/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNonStandardIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 標準商品の注文を更新する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateStandardIndication} updateStandardIndication 注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardIndication: async (indicationId: string, updateStandardIndication: UpdateStandardIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('updateStandardIndication', 'indicationId', indicationId)
            // verify required parameter 'updateStandardIndication' is not null or undefined
            assertParamExists('updateStandardIndication', 'updateStandardIndication', updateStandardIndication)
            const localVarPath = `/trading/indications/standard/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStandardIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 複数の標準商品の注文を変更する
         * @param {UpdateStandardIndicationsRequest} updateStandardIndicationsRequest 変更する注文一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardIndications: async (updateStandardIndicationsRequest: UpdateStandardIndicationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStandardIndicationsRequest' is not null or undefined
            assertParamExists('updateStandardIndications', 'updateStandardIndicationsRequest', updateStandardIndicationsRequest)
            const localVarPath = `/trading/indications/standard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStandardIndicationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndicationsApi - functional programming interface
 * @export
 */
export const IndicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 削除対象の注文ID
         * @param {string} [memo] 自由記入欄
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIndication(indicationId: string, memo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIndication(indicationId, memo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ブローカーの新規注文、注文詳細画面で選択可能な選択肢を得る
         * @param {StandardIndicationSelectableInputsRequest} standardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateStandardIndicationSelectableInputs(standardIndicationSelectableInputsRequest: StandardIndicationSelectableInputsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandardIndicationSelectableInputs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateStandardIndicationSelectableInputs(standardIndicationSelectableInputsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 呪文からブローカーの新規注文の入力値及び選択可能な選択肢を得る
         * @param {StandardIndicationSelectableInputsSpellRequest} standardIndicationSelectableInputsSpellRequest ユーザーが入力している呪文のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateStandardIndicationSelectableInputsBySpell(standardIndicationSelectableInputsSpellRequest: StandardIndicationSelectableInputsSpellRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandardIndicationSelectableInputs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateStandardIndicationSelectableInputsBySpell(standardIndicationSelectableInputsSpellRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndication(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Indication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndication(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文の受給サマリを取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndicationDeliverySummary(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliverySummaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicationDeliverySummary(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文の取引ステップを取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndicationTradingSteps(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingSteps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicationTradingSteps(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文一覧を取得する
         * @param {string} [organizationName] 組織名・略称
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分ID
         * @param {string} [productTypeId] 商品区分ID
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted'} [status] 注文ステータス
         * @param {'ask' | 'bid'} [position] ポジション
         * @param {boolean} [nonStandard] 非標準商品のみ
         * @param {string} [productId] 商品ID
         * @param {string} [deliveryTermsId] 受給条件ID
         * @param {string} [exchangeDeliveryTermsId] 交換側の受給条件ID
         * @param {string} [fuelSurchargeTypeId] 燃調区分ID
         * @param {string} [lastId] 取得したい注文一覧の直前のID。このID以降の注文が返される。未指定の場合は、最新の更新日時から注文一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {string} [fromDate] 注文更新日
         * @param {boolean} [excludeTbd] TBDの注文を含むかのフラグ、trueの場合はTBDを除外する
         * @param {boolean} [isDailyPowerMarket] DailyPowerMarket市場の注文かどうか
         * @param {'normal' | 'spread' | 'swing-option'} [productSubType] 商品の種類
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndications(organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, status?: 'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted', position?: 'ask' | 'bid', nonStandard?: boolean, productId?: string, deliveryTermsId?: string, exchangeDeliveryTermsId?: string, fuelSurchargeTypeId?: string, lastId?: string, fromDate?: string, excludeTbd?: boolean, isDailyPowerMarket?: boolean, productSubType?: 'normal' | 'spread' | 'swing-option', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Indication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndications(organizationName, areaId, hourTypeId, productTypeId, startDeliveryYearMonth, endDeliveryYearMonth, status, position, nonStandard, productId, deliveryTermsId, exchangeDeliveryTermsId, fuelSurchargeTypeId, lastId, fromDate, excludeTbd, isDailyPowerMarket, productSubType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非標準商品の注文を新規作成する
         * @param {NewNonStandardIndication} newNonStandardIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newNonStandardIndication(newNonStandardIndication: NewNonStandardIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Indication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newNonStandardIndication(newNonStandardIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 標準商品の注文を新規作成する
         * @param {NewStandardIndication} newStandardIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newStandardIndication(newStandardIndication: NewStandardIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Indication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newStandardIndication(newStandardIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DailyPowerMarketの有効な注文をトレーダーから不可視にする
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingDailyPowerMarketIndicationsMakeInvisiblePost(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingDailyPowerMarketIndicationsMakeInvisiblePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を期限切れにする
         * @param {PubSubMessage} pubSubMessage 期限切れのイベント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingIndicationsExpirePost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingIndicationsExpirePost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を変更する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateIndication} updateIndication 注文変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIndication(indicationId: string, updateIndication: UpdateIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Indication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIndication(indicationId, updateIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 非標準商品の注文を更新する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateNonStandardIndication} updateNonStandardIndication 注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNonStandardIndication(indicationId: string, updateNonStandardIndication: UpdateNonStandardIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Indication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNonStandardIndication(indicationId, updateNonStandardIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 標準商品の注文を更新する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateStandardIndication} updateStandardIndication 注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStandardIndication(indicationId: string, updateStandardIndication: UpdateStandardIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Indication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStandardIndication(indicationId, updateStandardIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 複数の標準商品の注文を変更する
         * @param {UpdateStandardIndicationsRequest} updateStandardIndicationsRequest 変更する注文一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStandardIndications(updateStandardIndicationsRequest: UpdateStandardIndicationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatedIndicationInBulk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStandardIndications(updateStandardIndicationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndicationsApi - factory interface
 * @export
 */
export const IndicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 削除対象の注文ID
         * @param {string} [memo] 自由記入欄
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIndication(indicationId: string, memo?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIndication(indicationId, memo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ブローカーの新規注文、注文詳細画面で選択可能な選択肢を得る
         * @param {StandardIndicationSelectableInputsRequest} standardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStandardIndicationSelectableInputs(standardIndicationSelectableInputsRequest: StandardIndicationSelectableInputsRequest, options?: any): AxiosPromise<StandardIndicationSelectableInputs> {
            return localVarFp.generateStandardIndicationSelectableInputs(standardIndicationSelectableInputsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 呪文からブローカーの新規注文の入力値及び選択可能な選択肢を得る
         * @param {StandardIndicationSelectableInputsSpellRequest} standardIndicationSelectableInputsSpellRequest ユーザーが入力している呪文のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStandardIndicationSelectableInputsBySpell(standardIndicationSelectableInputsSpellRequest: StandardIndicationSelectableInputsSpellRequest, options?: any): AxiosPromise<StandardIndicationSelectableInputs> {
            return localVarFp.generateStandardIndicationSelectableInputsBySpell(standardIndicationSelectableInputsSpellRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndication(indicationId: string, options?: any): AxiosPromise<Indication> {
            return localVarFp.getIndication(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文の受給サマリを取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicationDeliverySummary(indicationId: string, options?: any): AxiosPromise<DeliverySummaries> {
            return localVarFp.getIndicationDeliverySummary(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文の取引ステップを取得する
         * @param {string} indicationId 注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicationTradingSteps(indicationId: string, options?: any): AxiosPromise<TradingSteps> {
            return localVarFp.getIndicationTradingSteps(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文一覧を取得する
         * @param {string} [organizationName] 組織名・略称
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分ID
         * @param {string} [productTypeId] 商品区分ID
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted'} [status] 注文ステータス
         * @param {'ask' | 'bid'} [position] ポジション
         * @param {boolean} [nonStandard] 非標準商品のみ
         * @param {string} [productId] 商品ID
         * @param {string} [deliveryTermsId] 受給条件ID
         * @param {string} [exchangeDeliveryTermsId] 交換側の受給条件ID
         * @param {string} [fuelSurchargeTypeId] 燃調区分ID
         * @param {string} [lastId] 取得したい注文一覧の直前のID。このID以降の注文が返される。未指定の場合は、最新の更新日時から注文一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {string} [fromDate] 注文更新日
         * @param {boolean} [excludeTbd] TBDの注文を含むかのフラグ、trueの場合はTBDを除外する
         * @param {boolean} [isDailyPowerMarket] DailyPowerMarket市場の注文かどうか
         * @param {'normal' | 'spread' | 'swing-option'} [productSubType] 商品の種類
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndications(organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, status?: 'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted', position?: 'ask' | 'bid', nonStandard?: boolean, productId?: string, deliveryTermsId?: string, exchangeDeliveryTermsId?: string, fuelSurchargeTypeId?: string, lastId?: string, fromDate?: string, excludeTbd?: boolean, isDailyPowerMarket?: boolean, productSubType?: 'normal' | 'spread' | 'swing-option', options?: any): AxiosPromise<Array<Indication>> {
            return localVarFp.getIndications(organizationName, areaId, hourTypeId, productTypeId, startDeliveryYearMonth, endDeliveryYearMonth, status, position, nonStandard, productId, deliveryTermsId, exchangeDeliveryTermsId, fuelSurchargeTypeId, lastId, fromDate, excludeTbd, isDailyPowerMarket, productSubType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非標準商品の注文を新規作成する
         * @param {NewNonStandardIndication} newNonStandardIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newNonStandardIndication(newNonStandardIndication: NewNonStandardIndication, options?: any): AxiosPromise<Indication> {
            return localVarFp.newNonStandardIndication(newNonStandardIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 標準商品の注文を新規作成する
         * @param {NewStandardIndication} newStandardIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newStandardIndication(newStandardIndication: NewStandardIndication, options?: any): AxiosPromise<Indication> {
            return localVarFp.newStandardIndication(newStandardIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DailyPowerMarketの有効な注文をトレーダーから不可視にする
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingDailyPowerMarketIndicationsMakeInvisiblePost(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.tradingDailyPowerMarketIndicationsMakeInvisiblePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を期限切れにする
         * @param {PubSubMessage} pubSubMessage 期限切れのイベント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingIndicationsExpirePost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.tradingIndicationsExpirePost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を変更する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateIndication} updateIndication 注文変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndication(indicationId: string, updateIndication: UpdateIndication, options?: any): AxiosPromise<Indication> {
            return localVarFp.updateIndication(indicationId, updateIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 非標準商品の注文を更新する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateNonStandardIndication} updateNonStandardIndication 注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNonStandardIndication(indicationId: string, updateNonStandardIndication: UpdateNonStandardIndication, options?: any): AxiosPromise<Indication> {
            return localVarFp.updateNonStandardIndication(indicationId, updateNonStandardIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 標準商品の注文を更新する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateStandardIndication} updateStandardIndication 注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardIndication(indicationId: string, updateStandardIndication: UpdateStandardIndication, options?: any): AxiosPromise<Indication> {
            return localVarFp.updateStandardIndication(indicationId, updateStandardIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 複数の標準商品の注文を変更する
         * @param {UpdateStandardIndicationsRequest} updateStandardIndicationsRequest 変更する注文一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardIndications(updateStandardIndicationsRequest: UpdateStandardIndicationsRequest, options?: any): AxiosPromise<UpdatedIndicationInBulk> {
            return localVarFp.updateStandardIndications(updateStandardIndicationsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndicationsApi - object-oriented interface
 * @export
 * @class IndicationsApi
 * @extends {BaseAPI}
 */
export class IndicationsApi extends BaseAPI {
    /**
     * 
     * @summary 注文を削除する
     * @param {string} indicationId 削除対象の注文ID
     * @param {string} [memo] 自由記入欄
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public deleteIndication(indicationId: string, memo?: string, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).deleteIndication(indicationId, memo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ブローカーの新規注文、注文詳細画面で選択可能な選択肢を得る
     * @param {StandardIndicationSelectableInputsRequest} standardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public generateStandardIndicationSelectableInputs(standardIndicationSelectableInputsRequest: StandardIndicationSelectableInputsRequest, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).generateStandardIndicationSelectableInputs(standardIndicationSelectableInputsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 呪文からブローカーの新規注文の入力値及び選択可能な選択肢を得る
     * @param {StandardIndicationSelectableInputsSpellRequest} standardIndicationSelectableInputsSpellRequest ユーザーが入力している呪文のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public generateStandardIndicationSelectableInputsBySpell(standardIndicationSelectableInputsSpellRequest: StandardIndicationSelectableInputsSpellRequest, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).generateStandardIndicationSelectableInputsBySpell(standardIndicationSelectableInputsSpellRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を取得する
     * @param {string} indicationId 注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public getIndication(indicationId: string, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).getIndication(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文の受給サマリを取得する
     * @param {string} indicationId 注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public getIndicationDeliverySummary(indicationId: string, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).getIndicationDeliverySummary(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文の取引ステップを取得する
     * @param {string} indicationId 注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public getIndicationTradingSteps(indicationId: string, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).getIndicationTradingSteps(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文一覧を取得する
     * @param {string} [organizationName] 組織名・略称
     * @param {string} [areaId] エリアID
     * @param {string} [hourTypeId] 時間帯区分ID
     * @param {string} [productTypeId] 商品区分ID
     * @param {string} [startDeliveryYearMonth] 開始月
     * @param {string} [endDeliveryYearMonth] 終了月
     * @param {'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted'} [status] 注文ステータス
     * @param {'ask' | 'bid'} [position] ポジション
     * @param {boolean} [nonStandard] 非標準商品のみ
     * @param {string} [productId] 商品ID
     * @param {string} [deliveryTermsId] 受給条件ID
     * @param {string} [exchangeDeliveryTermsId] 交換側の受給条件ID
     * @param {string} [fuelSurchargeTypeId] 燃調区分ID
     * @param {string} [lastId] 取得したい注文一覧の直前のID。このID以降の注文が返される。未指定の場合は、最新の更新日時から注文一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。
     * @param {string} [fromDate] 注文更新日
     * @param {boolean} [excludeTbd] TBDの注文を含むかのフラグ、trueの場合はTBDを除外する
     * @param {boolean} [isDailyPowerMarket] DailyPowerMarket市場の注文かどうか
     * @param {'normal' | 'spread' | 'swing-option'} [productSubType] 商品の種類
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public getIndications(organizationName?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, status?: 'unconfirmed' | 'active' | 'negotiating' | 'done' | 'inactive' | 'expired' | 'deleted', position?: 'ask' | 'bid', nonStandard?: boolean, productId?: string, deliveryTermsId?: string, exchangeDeliveryTermsId?: string, fuelSurchargeTypeId?: string, lastId?: string, fromDate?: string, excludeTbd?: boolean, isDailyPowerMarket?: boolean, productSubType?: 'normal' | 'spread' | 'swing-option', options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).getIndications(organizationName, areaId, hourTypeId, productTypeId, startDeliveryYearMonth, endDeliveryYearMonth, status, position, nonStandard, productId, deliveryTermsId, exchangeDeliveryTermsId, fuelSurchargeTypeId, lastId, fromDate, excludeTbd, isDailyPowerMarket, productSubType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非標準商品の注文を新規作成する
     * @param {NewNonStandardIndication} newNonStandardIndication 新規注文オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public newNonStandardIndication(newNonStandardIndication: NewNonStandardIndication, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).newNonStandardIndication(newNonStandardIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 標準商品の注文を新規作成する
     * @param {NewStandardIndication} newStandardIndication 新規注文オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public newStandardIndication(newStandardIndication: NewStandardIndication, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).newStandardIndication(newStandardIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DailyPowerMarketの有効な注文をトレーダーから不可視にする
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public tradingDailyPowerMarketIndicationsMakeInvisiblePost(body: object, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).tradingDailyPowerMarketIndicationsMakeInvisiblePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を期限切れにする
     * @param {PubSubMessage} pubSubMessage 期限切れのイベント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public tradingIndicationsExpirePost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).tradingIndicationsExpirePost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を変更する
     * @param {string} indicationId 変更対象の注文ID
     * @param {UpdateIndication} updateIndication 注文変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public updateIndication(indicationId: string, updateIndication: UpdateIndication, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).updateIndication(indicationId, updateIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 非標準商品の注文を更新する
     * @param {string} indicationId 変更対象の注文ID
     * @param {UpdateNonStandardIndication} updateNonStandardIndication 注文オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public updateNonStandardIndication(indicationId: string, updateNonStandardIndication: UpdateNonStandardIndication, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).updateNonStandardIndication(indicationId, updateNonStandardIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 標準商品の注文を更新する
     * @param {string} indicationId 変更対象の注文ID
     * @param {UpdateStandardIndication} updateStandardIndication 注文オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public updateStandardIndication(indicationId: string, updateStandardIndication: UpdateStandardIndication, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).updateStandardIndication(indicationId, updateStandardIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 複数の標準商品の注文を変更する
     * @param {UpdateStandardIndicationsRequest} updateStandardIndicationsRequest 変更する注文一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicationsApi
     */
    public updateStandardIndications(updateStandardIndicationsRequest: UpdateStandardIndicationsRequest, options?: AxiosRequestConfig) {
        return IndicationsApiFp(this.configuration).updateStandardIndications(updateStandardIndicationsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InformationsApi - axios parameter creator
 * @export
 */
export const InformationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 有効なお知らせを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveInformations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/information/active-informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/information/informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを新規作成する
         * @param {NewInformation} newInformation 新規お知らせオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newInformation: async (newInformation: NewInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newInformation' is not null or undefined
            assertParamExists('newInformation', 'newInformation', newInformation)
            const localVarPath = `/information/informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを削除する
         * @param {string} informationId お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInformation: async (informationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'informationId' is not null or undefined
            assertParamExists('removeInformation', 'informationId', informationId)
            const localVarPath = `/information/informations/{informationId}`
                .replace(`{${"informationId"}}`, encodeURIComponent(String(informationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary お知らせを変更する
         * @param {string} informationId お知らせID
         * @param {UpdateInformation} updateInformation お知らせ更新オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInformation: async (informationId: string, updateInformation: UpdateInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'informationId' is not null or undefined
            assertParamExists('updateInformation', 'informationId', informationId)
            // verify required parameter 'updateInformation' is not null or undefined
            assertParamExists('updateInformation', 'updateInformation', updateInformation)
            const localVarPath = `/information/informations/{informationId}`
                .replace(`{${"informationId"}}`, encodeURIComponent(String(informationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InformationsApi - functional programming interface
 * @export
 */
export const InformationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InformationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 有効なお知らせを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveInformations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActiveInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveInformations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInformations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Information>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInformations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを新規作成する
         * @param {NewInformation} newInformation 新規お知らせオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newInformation(newInformation: NewInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Information>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newInformation(newInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを削除する
         * @param {string} informationId お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeInformation(informationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeInformation(informationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary お知らせを変更する
         * @param {string} informationId お知らせID
         * @param {UpdateInformation} updateInformation お知らせ更新オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInformation(informationId: string, updateInformation: UpdateInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Information>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInformation(informationId, updateInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InformationsApi - factory interface
 * @export
 */
export const InformationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InformationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 有効なお知らせを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveInformations(options?: any): AxiosPromise<Array<ActiveInformation>> {
            return localVarFp.getActiveInformations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformations(options?: any): AxiosPromise<Array<Information>> {
            return localVarFp.getInformations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを新規作成する
         * @param {NewInformation} newInformation 新規お知らせオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newInformation(newInformation: NewInformation, options?: any): AxiosPromise<Information> {
            return localVarFp.newInformation(newInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを削除する
         * @param {string} informationId お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInformation(informationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeInformation(informationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary お知らせを変更する
         * @param {string} informationId お知らせID
         * @param {UpdateInformation} updateInformation お知らせ更新オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInformation(informationId: string, updateInformation: UpdateInformation, options?: any): AxiosPromise<Information> {
            return localVarFp.updateInformation(informationId, updateInformation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InformationsApi - object-oriented interface
 * @export
 * @class InformationsApi
 * @extends {BaseAPI}
 */
export class InformationsApi extends BaseAPI {
    /**
     * 
     * @summary 有効なお知らせを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationsApi
     */
    public getActiveInformations(options?: AxiosRequestConfig) {
        return InformationsApiFp(this.configuration).getActiveInformations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationsApi
     */
    public getInformations(options?: AxiosRequestConfig) {
        return InformationsApiFp(this.configuration).getInformations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを新規作成する
     * @param {NewInformation} newInformation 新規お知らせオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationsApi
     */
    public newInformation(newInformation: NewInformation, options?: AxiosRequestConfig) {
        return InformationsApiFp(this.configuration).newInformation(newInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを削除する
     * @param {string} informationId お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationsApi
     */
    public removeInformation(informationId: string, options?: AxiosRequestConfig) {
        return InformationsApiFp(this.configuration).removeInformation(informationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary お知らせを変更する
     * @param {string} informationId お知らせID
     * @param {UpdateInformation} updateInformation お知らせ更新オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationsApi
     */
    public updateInformation(informationId: string, updateInformation: UpdateInformation, options?: AxiosRequestConfig) {
        return InformationsApiFp(this.configuration).updateInformation(informationId, updateInformation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocalizedReportsApi - axios parameter creator
 * @export
 */
export const LocalizedReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary トレーダー向けのレポートを取得する
         * @param {string} reportId 取得したいレポートのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizedReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getLocalizedReport', 'reportId', reportId)
            const localVarPath = `/report/localized-reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:localized-reports"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーダー向けのレポートのダウンロードURLを作成して返す
         * @param {string} reportId ダウンロードしたいレポートのID
         * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizedReportUrl: async (reportId: string, reportContentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getLocalizedReportUrl', 'reportId', reportId)
            // verify required parameter 'reportContentId' is not null or undefined
            assertParamExists('getLocalizedReportUrl', 'reportContentId', reportContentId)
            const localVarPath = `/report/localized-reports/{reportId}/contents/{reportContentId}/attachments`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"reportContentId"}}`, encodeURIComponent(String(reportContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:localized-reports"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 返されるレポートの件数は30件
         * @summary トレーダー向けのレポートリストを取得する
         * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizedReports: async (lastReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/localized-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:localized-reports"], configuration)

            if (lastReportId !== undefined) {
                localVarQueryParameter['lastReportId'] = lastReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalizedReportsApi - functional programming interface
 * @export
 */
export const LocalizedReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalizedReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary トレーダー向けのレポートを取得する
         * @param {string} reportId 取得したいレポートのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalizedReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalizedReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalizedReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーダー向けのレポートのダウンロードURLを作成して返す
         * @param {string} reportId ダウンロードしたいレポートのID
         * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalizedReportUrl(reportId: string, reportContentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocalizedReportUrl200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalizedReportUrl(reportId, reportContentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 返されるレポートの件数は30件
         * @summary トレーダー向けのレポートリストを取得する
         * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalizedReports(lastReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocalizedReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalizedReports(lastReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalizedReportsApi - factory interface
 * @export
 */
export const LocalizedReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalizedReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary トレーダー向けのレポートを取得する
         * @param {string} reportId 取得したいレポートのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizedReport(reportId: string, options?: any): AxiosPromise<LocalizedReport> {
            return localVarFp.getLocalizedReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーダー向けのレポートのダウンロードURLを作成して返す
         * @param {string} reportId ダウンロードしたいレポートのID
         * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizedReportUrl(reportId: string, reportContentId: string, options?: any): AxiosPromise<GetLocalizedReportUrl200Response> {
            return localVarFp.getLocalizedReportUrl(reportId, reportContentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 返されるレポートの件数は30件
         * @summary トレーダー向けのレポートリストを取得する
         * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizedReports(lastReportId?: string, options?: any): AxiosPromise<Array<LocalizedReport>> {
            return localVarFp.getLocalizedReports(lastReportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalizedReportsApi - object-oriented interface
 * @export
 * @class LocalizedReportsApi
 * @extends {BaseAPI}
 */
export class LocalizedReportsApi extends BaseAPI {
    /**
     * 
     * @summary トレーダー向けのレポートを取得する
     * @param {string} reportId 取得したいレポートのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizedReportsApi
     */
    public getLocalizedReport(reportId: string, options?: AxiosRequestConfig) {
        return LocalizedReportsApiFp(this.configuration).getLocalizedReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーダー向けのレポートのダウンロードURLを作成して返す
     * @param {string} reportId ダウンロードしたいレポートのID
     * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizedReportsApi
     */
    public getLocalizedReportUrl(reportId: string, reportContentId: string, options?: AxiosRequestConfig) {
        return LocalizedReportsApiFp(this.configuration).getLocalizedReportUrl(reportId, reportContentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 返されるレポートの件数は30件
     * @summary トレーダー向けのレポートリストを取得する
     * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizedReportsApi
     */
    public getLocalizedReports(lastReportId?: string, options?: AxiosRequestConfig) {
        return LocalizedReportsApiFp(this.configuration).getLocalizedReports(lastReportId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MembersApi - axios parameter creator
 * @export
 */
export const MembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織のメンバーを取得する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember: async (organizationId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getMember', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMember', 'userId', userId)
            const localVarPath = `/iam/organizations/{organizationId}/members/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:members"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のメンバーを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getMembersOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/iam/organizations/{organizationId}/members`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:members"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織にメンバーを招待する
         * @param {string} organizationId 組織ID
         * @param {NewMember} newMember 新規メンバーオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMemberOfOrganization: async (organizationId: string, newMember: NewMember, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('newMemberOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'newMember' is not null or undefined
            assertParamExists('newMemberOfOrganization', 'newMember', newMember)
            const localVarPath = `/iam/organizations/{organizationId}/members`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:members"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のメンバーを削除する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberOfOrganization: async (organizationId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('removeMemberOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeMemberOfOrganization', 'userId', userId)
            const localVarPath = `/iam/organizations/{organizationId}/members/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:members"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のメンバーを変更する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember: async (organizationId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateMember', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMember', 'userId', userId)
            const localVarPath = `/iam/organizations/{organizationId}/members/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:members"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メンバが持つeSquareのAPP設定情報を更新する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {UpdateESquareAppSetting} updateESquareAppSetting メンバーが持つeSquareのAPP設定情報オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberAppSetting: async (organizationId: string, userId: string, updateESquareAppSetting: UpdateESquareAppSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateMemberAppSetting', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMemberAppSetting', 'userId', userId)
            // verify required parameter 'updateESquareAppSetting' is not null or undefined
            assertParamExists('updateMemberAppSetting', 'updateESquareAppSetting', updateESquareAppSetting)
            const localVarPath = `/iam/organizations/{organizationId}/members/{userId}/app-setting`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:members"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateESquareAppSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーが自身のeClear確認キーを検証する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOwnEClearKeyword: async (organizationId: string, userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('validateOwnEClearKeyword', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('validateOwnEClearKeyword', 'userId', userId)
            // verify required parameter 'validateEClearKeywordRequest' is not null or undefined
            assertParamExists('validateOwnEClearKeyword', 'validateEClearKeywordRequest', validateEClearKeywordRequest)
            const localVarPath = `/iam/organizations/{organizationId}/members/{userId}/eclear-keyword/validate`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:users"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEClearKeywordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembersApi - functional programming interface
 * @export
 */
export const MembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織のメンバーを取得する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMember(organizationId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMember(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のメンバーを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織にメンバーを招待する
         * @param {string} organizationId 組織ID
         * @param {NewMember} newMember 新規メンバーオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newMemberOfOrganization(organizationId: string, newMember: NewMember, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newMemberOfOrganization(organizationId, newMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のメンバーを削除する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMemberOfOrganization(organizationId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMemberOfOrganization(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のメンバーを変更する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember(organizationId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メンバが持つeSquareのAPP設定情報を更新する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {UpdateESquareAppSetting} updateESquareAppSetting メンバーが持つeSquareのAPP設定情報オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberAppSetting(organizationId: string, userId: string, updateESquareAppSetting: UpdateESquareAppSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberAppSetting(organizationId, userId, updateESquareAppSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーが自身のeClear確認キーを検証する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOwnEClearKeyword(organizationId: string, userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateEClearKeywordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateOwnEClearKeyword(organizationId, userId, validateEClearKeywordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MembersApi - factory interface
 * @export
 */
export const MembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembersApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織のメンバーを取得する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember(organizationId: string, userId: string, options?: any): AxiosPromise<MemberProfile> {
            return localVarFp.getMember(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のメンバーを取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersOfOrganization(organizationId: string, options?: any): AxiosPromise<Array<MemberProfile>> {
            return localVarFp.getMembersOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織にメンバーを招待する
         * @param {string} organizationId 組織ID
         * @param {NewMember} newMember 新規メンバーオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMemberOfOrganization(organizationId: string, newMember: NewMember, options?: any): AxiosPromise<MemberProfile> {
            return localVarFp.newMemberOfOrganization(organizationId, newMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のメンバーを削除する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberOfOrganization(organizationId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeMemberOfOrganization(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のメンバーを変更する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember(organizationId: string, userId: string, options?: any): AxiosPromise<MemberProfile> {
            return localVarFp.updateMember(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メンバが持つeSquareのAPP設定情報を更新する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {UpdateESquareAppSetting} updateESquareAppSetting メンバーが持つeSquareのAPP設定情報オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberAppSetting(organizationId: string, userId: string, updateESquareAppSetting: UpdateESquareAppSetting, options?: any): AxiosPromise<void> {
            return localVarFp.updateMemberAppSetting(organizationId, userId, updateESquareAppSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーが自身のeClear確認キーを検証する
         * @param {string} organizationId 組織ID
         * @param {string} userId ユーザーID
         * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOwnEClearKeyword(organizationId: string, userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options?: any): AxiosPromise<ValidateEClearKeywordResponse> {
            return localVarFp.validateOwnEClearKeyword(organizationId, userId, validateEClearKeywordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembersApi - object-oriented interface
 * @export
 * @class MembersApi
 * @extends {BaseAPI}
 */
export class MembersApi extends BaseAPI {
    /**
     * 
     * @summary 組織のメンバーを取得する
     * @param {string} organizationId 組織ID
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public getMember(organizationId: string, userId: string, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).getMember(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のメンバーを取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public getMembersOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).getMembersOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織にメンバーを招待する
     * @param {string} organizationId 組織ID
     * @param {NewMember} newMember 新規メンバーオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public newMemberOfOrganization(organizationId: string, newMember: NewMember, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).newMemberOfOrganization(organizationId, newMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のメンバーを削除する
     * @param {string} organizationId 組織ID
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public removeMemberOfOrganization(organizationId: string, userId: string, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).removeMemberOfOrganization(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のメンバーを変更する
     * @param {string} organizationId 組織ID
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMember(organizationId: string, userId: string, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMember(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メンバが持つeSquareのAPP設定情報を更新する
     * @param {string} organizationId 組織ID
     * @param {string} userId ユーザーID
     * @param {UpdateESquareAppSetting} updateESquareAppSetting メンバーが持つeSquareのAPP設定情報オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMemberAppSetting(organizationId: string, userId: string, updateESquareAppSetting: UpdateESquareAppSetting, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMemberAppSetting(organizationId, userId, updateESquareAppSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーが自身のeClear確認キーを検証する
     * @param {string} organizationId 組織ID
     * @param {string} userId ユーザーID
     * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public validateOwnEClearKeyword(organizationId: string, userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options?: AxiosRequestConfig) {
        return MembersApiFp(this.configuration).validateOwnEClearKeyword(organizationId, userId, validateEClearKeywordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MonthlyTradingStatisticsApi - axios parameter creator
 * @export
 */
export const MonthlyTradingStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 実績統計を取得する。社内ツール向けで外部には非公開。
         * @param {string} [from] 統計取得開始日
         * @param {string} [to] 統計取得終了日
         * @param {string} [productTypeId] 商品区分Id
         * @param {string} [organizationId] 組織Id
         * @param {'cashIn' | 'execution'} [termType] 統計取得期間の指定方法(キャッシュイン時期または約定時期)。パラメータの指定がない場合は、executionがデフォルト値となる。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyTradingStatistics: async (from?: string, to?: string, productTypeId?: string, organizationId?: string, termType?: 'cashIn' | 'execution', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/monthly-trading-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:monthly-trading-statistics"], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['productTypeId'] = productTypeId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (termType !== undefined) {
                localVarQueryParameter['termType'] = termType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonthlyTradingStatisticsApi - functional programming interface
 * @export
 */
export const MonthlyTradingStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonthlyTradingStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 実績統計を取得する。社内ツール向けで外部には非公開。
         * @param {string} [from] 統計取得開始日
         * @param {string} [to] 統計取得終了日
         * @param {string} [productTypeId] 商品区分Id
         * @param {string} [organizationId] 組織Id
         * @param {'cashIn' | 'execution'} [termType] 統計取得期間の指定方法(キャッシュイン時期または約定時期)。パラメータの指定がない場合は、executionがデフォルト値となる。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyTradingStatistics(from?: string, to?: string, productTypeId?: string, organizationId?: string, termType?: 'cashIn' | 'execution', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthlyTradingStatistics>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyTradingStatistics(from, to, productTypeId, organizationId, termType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonthlyTradingStatisticsApi - factory interface
 * @export
 */
export const MonthlyTradingStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonthlyTradingStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary 実績統計を取得する。社内ツール向けで外部には非公開。
         * @param {string} [from] 統計取得開始日
         * @param {string} [to] 統計取得終了日
         * @param {string} [productTypeId] 商品区分Id
         * @param {string} [organizationId] 組織Id
         * @param {'cashIn' | 'execution'} [termType] 統計取得期間の指定方法(キャッシュイン時期または約定時期)。パラメータの指定がない場合は、executionがデフォルト値となる。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyTradingStatistics(from?: string, to?: string, productTypeId?: string, organizationId?: string, termType?: 'cashIn' | 'execution', options?: any): AxiosPromise<Array<MonthlyTradingStatistics>> {
            return localVarFp.getMonthlyTradingStatistics(from, to, productTypeId, organizationId, termType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonthlyTradingStatisticsApi - object-oriented interface
 * @export
 * @class MonthlyTradingStatisticsApi
 * @extends {BaseAPI}
 */
export class MonthlyTradingStatisticsApi extends BaseAPI {
    /**
     * 
     * @summary 実績統計を取得する。社内ツール向けで外部には非公開。
     * @param {string} [from] 統計取得開始日
     * @param {string} [to] 統計取得終了日
     * @param {string} [productTypeId] 商品区分Id
     * @param {string} [organizationId] 組織Id
     * @param {'cashIn' | 'execution'} [termType] 統計取得期間の指定方法(キャッシュイン時期または約定時期)。パラメータの指定がない場合は、executionがデフォルト値となる。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonthlyTradingStatisticsApi
     */
    public getMonthlyTradingStatistics(from?: string, to?: string, productTypeId?: string, organizationId?: string, termType?: 'cashIn' | 'execution', options?: AxiosRequestConfig) {
        return MonthlyTradingStatisticsApiFp(this.configuration).getMonthlyTradingStatistics(from, to, productTypeId, organizationId, termType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyDealsApi - axios parameter creator
 * @export
 */
export const MyDealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての自社の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDeals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/my-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:my-deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyDealsApi - functional programming interface
 * @export
 */
export const MyDealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyDealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての自社の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDeals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MyDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyDeals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyDealsApi - factory interface
 * @export
 */
export const MyDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyDealsApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての自社の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDeals(options?: any): AxiosPromise<Array<MyDeal>> {
            return localVarFp.getMyDeals(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyDealsApi - object-oriented interface
 * @export
 * @class MyDealsApi
 * @extends {BaseAPI}
 */
export class MyDealsApi extends BaseAPI {
    /**
     * 
     * @summary すべての自社の約定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyDealsApi
     */
    public getMyDeals(options?: AxiosRequestConfig) {
        return MyDealsApiFp(this.configuration).getMyDeals(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyIndicationsApi - axios parameter creator
 * @export
 */
export const MyIndicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 注文を有効化する
         * @param {string} indicationId 有効化対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateMyIndication: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('activateMyIndication', 'indicationId', indicationId)
            const localVarPath = `/trading/my-indications/{indicationId}/active`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を無効化する
         * @param {string} indicationId 無効化対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateMyIndication: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('deactivateMyIndication', 'indicationId', indicationId)
            const localVarPath = `/trading/my-indications/{indicationId}/active`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 削除対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyIndication: async (indicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('deleteMyIndication', 'indicationId', indicationId)
            const localVarPath = `/trading/my-indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーダーの新規注文、注文詳細入力画面で選択可能な選択肢を得る
         * @param {MyStandardIndicationSelectableInputsRequest} myStandardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMyStandardIndicationSelectableInputs: async (myStandardIndicationSelectableInputsRequest: MyStandardIndicationSelectableInputsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'myStandardIndicationSelectableInputsRequest' is not null or undefined
            assertParamExists('generateMyStandardIndicationSelectableInputs', 'myStandardIndicationSelectableInputsRequest', myStandardIndicationSelectableInputsRequest)
            const localVarPath = `/trading/my-indications/standard-selectable-inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(myStandardIndicationSelectableInputsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーダーがすべての自社の注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/my-indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:my-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary トレーダーが自社の注文を新規作成する
         * @param {NewMyIndication} newMyIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMyIndication: async (newMyIndication: NewMyIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newMyIndication' is not null or undefined
            assertParamExists('newMyIndication', 'newMyIndication', newMyIndication)
            const localVarPath = `/trading/my-indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMyIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自社の注文を変更する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateMyIndication} updateMyIndication 注文変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyIndication: async (indicationId: string, updateMyIndication: UpdateMyIndication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicationId' is not null or undefined
            assertParamExists('updateMyIndication', 'indicationId', indicationId)
            // verify required parameter 'updateMyIndication' is not null or undefined
            assertParamExists('updateMyIndication', 'updateMyIndication', updateMyIndication)
            const localVarPath = `/trading/my-indications/{indicationId}`
                .replace(`{${"indicationId"}}`, encodeURIComponent(String(indicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:my-indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyIndication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyIndicationsApi - functional programming interface
 * @export
 */
export const MyIndicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyIndicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 注文を有効化する
         * @param {string} indicationId 有効化対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateMyIndication(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyIndication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateMyIndication(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を無効化する
         * @param {string} indicationId 無効化対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateMyIndication(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyIndication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateMyIndication(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 削除対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMyIndication(indicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyIndication(indicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーダーの新規注文、注文詳細入力画面で選択可能な選択肢を得る
         * @param {MyStandardIndicationSelectableInputsRequest} myStandardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMyStandardIndicationSelectableInputs(myStandardIndicationSelectableInputsRequest: MyStandardIndicationSelectableInputsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyStandardIndicationSelectableInputs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMyStandardIndicationSelectableInputs(myStandardIndicationSelectableInputsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーダーがすべての自社の注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyIndications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MyIndication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyIndications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary トレーダーが自社の注文を新規作成する
         * @param {NewMyIndication} newMyIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newMyIndication(newMyIndication: NewMyIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyIndication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newMyIndication(newMyIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自社の注文を変更する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateMyIndication} updateMyIndication 注文変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyIndication(indicationId: string, updateMyIndication: UpdateMyIndication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyIndication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyIndication(indicationId, updateMyIndication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyIndicationsApi - factory interface
 * @export
 */
export const MyIndicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyIndicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 注文を有効化する
         * @param {string} indicationId 有効化対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateMyIndication(indicationId: string, options?: any): AxiosPromise<MyIndication> {
            return localVarFp.activateMyIndication(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を無効化する
         * @param {string} indicationId 無効化対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateMyIndication(indicationId: string, options?: any): AxiosPromise<MyIndication> {
            return localVarFp.deactivateMyIndication(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 注文を削除する
         * @param {string} indicationId 削除対象の注文ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyIndication(indicationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMyIndication(indicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーダーの新規注文、注文詳細入力画面で選択可能な選択肢を得る
         * @param {MyStandardIndicationSelectableInputsRequest} myStandardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMyStandardIndicationSelectableInputs(myStandardIndicationSelectableInputsRequest: MyStandardIndicationSelectableInputsRequest, options?: any): AxiosPromise<MyStandardIndicationSelectableInputs> {
            return localVarFp.generateMyStandardIndicationSelectableInputs(myStandardIndicationSelectableInputsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーダーがすべての自社の注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyIndications(options?: any): AxiosPromise<Array<MyIndication>> {
            return localVarFp.getMyIndications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary トレーダーが自社の注文を新規作成する
         * @param {NewMyIndication} newMyIndication 新規注文オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMyIndication(newMyIndication: NewMyIndication, options?: any): AxiosPromise<MyIndication> {
            return localVarFp.newMyIndication(newMyIndication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自社の注文を変更する
         * @param {string} indicationId 変更対象の注文ID
         * @param {UpdateMyIndication} updateMyIndication 注文変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyIndication(indicationId: string, updateMyIndication: UpdateMyIndication, options?: any): AxiosPromise<MyIndication> {
            return localVarFp.updateMyIndication(indicationId, updateMyIndication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyIndicationsApi - object-oriented interface
 * @export
 * @class MyIndicationsApi
 * @extends {BaseAPI}
 */
export class MyIndicationsApi extends BaseAPI {
    /**
     * 
     * @summary 注文を有効化する
     * @param {string} indicationId 有効化対象の注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public activateMyIndication(indicationId: string, options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).activateMyIndication(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を無効化する
     * @param {string} indicationId 無効化対象の注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public deactivateMyIndication(indicationId: string, options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).deactivateMyIndication(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 注文を削除する
     * @param {string} indicationId 削除対象の注文ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public deleteMyIndication(indicationId: string, options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).deleteMyIndication(indicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーダーの新規注文、注文詳細入力画面で選択可能な選択肢を得る
     * @param {MyStandardIndicationSelectableInputsRequest} myStandardIndicationSelectableInputsRequest ユーザーが入力している注文内容のリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public generateMyStandardIndicationSelectableInputs(myStandardIndicationSelectableInputsRequest: MyStandardIndicationSelectableInputsRequest, options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).generateMyStandardIndicationSelectableInputs(myStandardIndicationSelectableInputsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーダーがすべての自社の注文を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public getMyIndications(options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).getMyIndications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary トレーダーが自社の注文を新規作成する
     * @param {NewMyIndication} newMyIndication 新規注文オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public newMyIndication(newMyIndication: NewMyIndication, options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).newMyIndication(newMyIndication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自社の注文を変更する
     * @param {string} indicationId 変更対象の注文ID
     * @param {UpdateMyIndication} updateMyIndication 注文変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyIndicationsApi
     */
    public updateMyIndication(indicationId: string, updateMyIndication: UpdateMyIndication, options?: AxiosRequestConfig) {
        return MyIndicationsApiFp(this.configuration).updateMyIndication(indicationId, updateMyIndication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NegotiationsApi - axios parameter creator
 * @export
 */
export const NegotiationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 交渉を削除する
         * @param {string} negotiationId 削除対象の交渉ID
         * @param {string} [memo] 自由記入欄
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNegotiation: async (negotiationId: string, memo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('deleteNegotiation', 'negotiationId', negotiationId)
            const localVarPath = `/trading/negotiations/{negotiationId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:negotiations"], configuration)

            if (memo !== undefined) {
                localVarQueryParameter['memo'] = memo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉を取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiation: async (negotiationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('getNegotiation', 'negotiationId', negotiationId)
            const localVarPath = `/trading/negotiations/{negotiationId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:negotiations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉の受給サマリを取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiationDeliverySummary: async (negotiationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('getNegotiationDeliverySummary', 'negotiationId', negotiationId)
            const localVarPath = `/trading/negotiations/{negotiationId}/delivery-summary`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:negotiations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉の確認書(Recaps)を取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiationRecaps: async (negotiationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('getNegotiationRecaps', 'negotiationId', negotiationId)
            const localVarPath = `/trading/negotiations/{negotiationId}/recaps`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:negotiations", "read:recaps"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉の取引ステップを取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiationTradingSteps: async (negotiationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('getNegotiationTradingSteps', 'negotiationId', negotiationId)
            const localVarPath = `/trading/negotiations/{negotiationId}/trading-steps`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:negotiations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉一覧を取得する
         * @param {string} [lastId] 取得したい交渉一覧の直前のID。このID以降の交渉が返される。未指定の場合は、最新の更新日時から交渉一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。更新日時の降順でソートされる。
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分
         * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
         * @param {string} [organizationName] 組織名
         * @param {string} [publicNegotiationId] 成約ID
         * @param {'under-negotiation' | 'executed' | 'broken' | 'deleted'} [status] 交渉ステータス
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiations: async (lastId?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, organizationName?: string, publicNegotiationId?: string, status?: 'under-negotiation' | 'executed' | 'broken' | 'deleted', startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/negotiations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:negotiations"], configuration)

            if (lastId !== undefined) {
                localVarQueryParameter['lastId'] = lastId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (hourTypeId !== undefined) {
                localVarQueryParameter['hourTypeId'] = hourTypeId;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['productTypeId'] = productTypeId;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }

            if (publicNegotiationId !== undefined) {
                localVarQueryParameter['publicNegotiationId'] = publicNegotiationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (startDeliveryYearMonth !== undefined) {
                localVarQueryParameter['startDeliveryYearMonth'] = startDeliveryYearMonth;
            }

            if (endDeliveryYearMonth !== undefined) {
                localVarQueryParameter['endDeliveryYearMonth'] = endDeliveryYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉を新規作成する
         * @param {NewNegotiation} newNegotiation 新規交渉オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newNegotiation: async (newNegotiation: NewNegotiation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newNegotiation' is not null or undefined
            assertParamExists('newNegotiation', 'newNegotiation', newNegotiation)
            const localVarPath = `/trading/negotiations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:negotiations"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newNegotiation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 交渉を変更する
         * @param {string} negotiationId 変更対象の交渉ID
         * @param {UpdateNegotiation} updateNegotiation 交渉変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNegotiation: async (negotiationId: string, updateNegotiation: UpdateNegotiation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negotiationId' is not null or undefined
            assertParamExists('updateNegotiation', 'negotiationId', negotiationId)
            // verify required parameter 'updateNegotiation' is not null or undefined
            assertParamExists('updateNegotiation', 'updateNegotiation', updateNegotiation)
            const localVarPath = `/trading/negotiations/{negotiationId}`
                .replace(`{${"negotiationId"}}`, encodeURIComponent(String(negotiationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:negotiations"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNegotiation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NegotiationsApi - functional programming interface
 * @export
 */
export const NegotiationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NegotiationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 交渉を削除する
         * @param {string} negotiationId 削除対象の交渉ID
         * @param {string} [memo] 自由記入欄
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNegotiation(negotiationId: string, memo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNegotiation(negotiationId, memo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉を取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNegotiation(negotiationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Negotiation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNegotiation(negotiationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉の受給サマリを取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNegotiationDeliverySummary(negotiationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliverySummaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNegotiationDeliverySummary(negotiationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉の確認書(Recaps)を取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNegotiationRecaps(negotiationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Recap>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNegotiationRecaps(negotiationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉の取引ステップを取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNegotiationTradingSteps(negotiationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradingSteps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNegotiationTradingSteps(negotiationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉一覧を取得する
         * @param {string} [lastId] 取得したい交渉一覧の直前のID。このID以降の交渉が返される。未指定の場合は、最新の更新日時から交渉一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。更新日時の降順でソートされる。
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分
         * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
         * @param {string} [organizationName] 組織名
         * @param {string} [publicNegotiationId] 成約ID
         * @param {'under-negotiation' | 'executed' | 'broken' | 'deleted'} [status] 交渉ステータス
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNegotiations(lastId?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, organizationName?: string, publicNegotiationId?: string, status?: 'under-negotiation' | 'executed' | 'broken' | 'deleted', startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Negotiation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNegotiations(lastId, areaId, hourTypeId, productTypeId, organizationName, publicNegotiationId, status, startDeliveryYearMonth, endDeliveryYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉を新規作成する
         * @param {NewNegotiation} newNegotiation 新規交渉オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newNegotiation(newNegotiation: NewNegotiation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Negotiation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newNegotiation(newNegotiation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 交渉を変更する
         * @param {string} negotiationId 変更対象の交渉ID
         * @param {UpdateNegotiation} updateNegotiation 交渉変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNegotiation(negotiationId: string, updateNegotiation: UpdateNegotiation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Negotiation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNegotiation(negotiationId, updateNegotiation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NegotiationsApi - factory interface
 * @export
 */
export const NegotiationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NegotiationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 交渉を削除する
         * @param {string} negotiationId 削除対象の交渉ID
         * @param {string} [memo] 自由記入欄
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNegotiation(negotiationId: string, memo?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNegotiation(negotiationId, memo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉を取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiation(negotiationId: string, options?: any): AxiosPromise<Negotiation> {
            return localVarFp.getNegotiation(negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉の受給サマリを取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiationDeliverySummary(negotiationId: string, options?: any): AxiosPromise<DeliverySummaries> {
            return localVarFp.getNegotiationDeliverySummary(negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉の確認書(Recaps)を取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiationRecaps(negotiationId: string, options?: any): AxiosPromise<Array<Recap>> {
            return localVarFp.getNegotiationRecaps(negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉の取引ステップを取得する
         * @param {string} negotiationId 交渉ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiationTradingSteps(negotiationId: string, options?: any): AxiosPromise<TradingSteps> {
            return localVarFp.getNegotiationTradingSteps(negotiationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉一覧を取得する
         * @param {string} [lastId] 取得したい交渉一覧の直前のID。このID以降の交渉が返される。未指定の場合は、最新の更新日時から交渉一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。更新日時の降順でソートされる。
         * @param {string} [areaId] エリアID
         * @param {string} [hourTypeId] 時間帯区分
         * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
         * @param {string} [organizationName] 組織名
         * @param {string} [publicNegotiationId] 成約ID
         * @param {'under-negotiation' | 'executed' | 'broken' | 'deleted'} [status] 交渉ステータス
         * @param {string} [startDeliveryYearMonth] 開始月
         * @param {string} [endDeliveryYearMonth] 終了月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegotiations(lastId?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, organizationName?: string, publicNegotiationId?: string, status?: 'under-negotiation' | 'executed' | 'broken' | 'deleted', startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, options?: any): AxiosPromise<Array<Negotiation>> {
            return localVarFp.getNegotiations(lastId, areaId, hourTypeId, productTypeId, organizationName, publicNegotiationId, status, startDeliveryYearMonth, endDeliveryYearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉を新規作成する
         * @param {NewNegotiation} newNegotiation 新規交渉オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newNegotiation(newNegotiation: NewNegotiation, options?: any): AxiosPromise<Negotiation> {
            return localVarFp.newNegotiation(newNegotiation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 交渉を変更する
         * @param {string} negotiationId 変更対象の交渉ID
         * @param {UpdateNegotiation} updateNegotiation 交渉変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNegotiation(negotiationId: string, updateNegotiation: UpdateNegotiation, options?: any): AxiosPromise<Negotiation> {
            return localVarFp.updateNegotiation(negotiationId, updateNegotiation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NegotiationsApi - object-oriented interface
 * @export
 * @class NegotiationsApi
 * @extends {BaseAPI}
 */
export class NegotiationsApi extends BaseAPI {
    /**
     * 
     * @summary 交渉を削除する
     * @param {string} negotiationId 削除対象の交渉ID
     * @param {string} [memo] 自由記入欄
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public deleteNegotiation(negotiationId: string, memo?: string, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).deleteNegotiation(negotiationId, memo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉を取得する
     * @param {string} negotiationId 交渉ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public getNegotiation(negotiationId: string, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).getNegotiation(negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉の受給サマリを取得する
     * @param {string} negotiationId 交渉ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public getNegotiationDeliverySummary(negotiationId: string, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).getNegotiationDeliverySummary(negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉の確認書(Recaps)を取得する
     * @param {string} negotiationId 交渉ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public getNegotiationRecaps(negotiationId: string, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).getNegotiationRecaps(negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉の取引ステップを取得する
     * @param {string} negotiationId 交渉ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public getNegotiationTradingSteps(negotiationId: string, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).getNegotiationTradingSteps(negotiationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉一覧を取得する
     * @param {string} [lastId] 取得したい交渉一覧の直前のID。このID以降の交渉が返される。未指定の場合は、最新の更新日時から交渉一覧が返される。指定されたID以降のデータが無い場合は、空の配列を返す。更新日時の降順でソートされる。
     * @param {string} [areaId] エリアID
     * @param {string} [hourTypeId] 時間帯区分
     * @param {string} [productTypeId] 取得したい約定一覧の商品区分のID
     * @param {string} [organizationName] 組織名
     * @param {string} [publicNegotiationId] 成約ID
     * @param {'under-negotiation' | 'executed' | 'broken' | 'deleted'} [status] 交渉ステータス
     * @param {string} [startDeliveryYearMonth] 開始月
     * @param {string} [endDeliveryYearMonth] 終了月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public getNegotiations(lastId?: string, areaId?: string, hourTypeId?: string, productTypeId?: string, organizationName?: string, publicNegotiationId?: string, status?: 'under-negotiation' | 'executed' | 'broken' | 'deleted', startDeliveryYearMonth?: string, endDeliveryYearMonth?: string, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).getNegotiations(lastId, areaId, hourTypeId, productTypeId, organizationName, publicNegotiationId, status, startDeliveryYearMonth, endDeliveryYearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉を新規作成する
     * @param {NewNegotiation} newNegotiation 新規交渉オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public newNegotiation(newNegotiation: NewNegotiation, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).newNegotiation(newNegotiation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 交渉を変更する
     * @param {string} negotiationId 変更対象の交渉ID
     * @param {UpdateNegotiation} updateNegotiation 交渉変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegotiationsApi
     */
    public updateNegotiation(negotiationId: string, updateNegotiation: UpdateNegotiation, options?: AxiosRequestConfig) {
        return NegotiationsApiFp(this.configuration).updateNegotiation(negotiationId, updateNegotiation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OcctoMastersApi - axios parameter creator
 * @export
 */
export const OcctoMastersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 広域情報のBGを削除する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBalancingGroup: async (organizationId: string, balancingGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteBalancingGroup', 'organizationId', organizationId)
            // verify required parameter 'balancingGroupId' is not null or undefined
            assertParamExists('deleteBalancingGroup', 'balancingGroupId', balancingGroupId)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/balancing-groups/{balancingGroupId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"balancingGroupId"}}`, encodeURIComponent(String(balancingGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:occto-masters"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報の計画提出者を削除する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplyPlanSubmitter: async (organizationId: string, supplyPlanSubmitterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteSupplyPlanSubmitter', 'organizationId', organizationId)
            // verify required parameter 'supplyPlanSubmitterId' is not null or undefined
            assertParamExists('deleteSupplyPlanSubmitter', 'supplyPlanSubmitterId', supplyPlanSubmitterId)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/supply-plan-submitters/{supplyPlanSubmitterId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"supplyPlanSubmitterId"}}`, encodeURIComponent(String(supplyPlanSubmitterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:occto-masters"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報のBGをIDで取得する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalancingGroup: async (organizationId: string, balancingGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getBalancingGroup', 'organizationId', organizationId)
            // verify required parameter 'balancingGroupId' is not null or undefined
            assertParamExists('getBalancingGroup', 'balancingGroupId', balancingGroupId)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/balancing-groups/{balancingGroupId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"balancingGroupId"}}`, encodeURIComponent(String(balancingGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:occto-masters"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の広域情報をすべて取得する
         * @param {string} organizationId 組織ID
         * @param {string} [areaId] 取得対象のエリアID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcctoMaster: async (organizationId: string, areaId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOcctoMaster', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:occto-masters"], configuration)

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報の計画提出者をIDで取得する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyPlanSubmitter: async (organizationId: string, supplyPlanSubmitterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getSupplyPlanSubmitter', 'organizationId', organizationId)
            // verify required parameter 'supplyPlanSubmitterId' is not null or undefined
            assertParamExists('getSupplyPlanSubmitter', 'supplyPlanSubmitterId', supplyPlanSubmitterId)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/supply-plan-submitters/{supplyPlanSubmitterId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"supplyPlanSubmitterId"}}`, encodeURIComponent(String(supplyPlanSubmitterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:occto-masters"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報のBGを新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestBalancingGroup} requestBalancingGroup 新規BGオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newBalancingGroup: async (organizationId: string, requestBalancingGroup: RequestBalancingGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('newBalancingGroup', 'organizationId', organizationId)
            // verify required parameter 'requestBalancingGroup' is not null or undefined
            assertParamExists('newBalancingGroup', 'requestBalancingGroup', requestBalancingGroup)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/balancing-groups`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:occto-masters"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBalancingGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報の計画提出者を新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 新規計画提出者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSupplyPlanSubmitter: async (organizationId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('newSupplyPlanSubmitter', 'organizationId', organizationId)
            // verify required parameter 'requestSupplyPlanSubmitter' is not null or undefined
            assertParamExists('newSupplyPlanSubmitter', 'requestSupplyPlanSubmitter', requestSupplyPlanSubmitter)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/supply-plan-submitters`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:occto-masters"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSupplyPlanSubmitter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報のBGを変更する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {RequestBalancingGroup} requestBalancingGroup BGオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBalancingGroup: async (organizationId: string, balancingGroupId: string, requestBalancingGroup: RequestBalancingGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateBalancingGroup', 'organizationId', organizationId)
            // verify required parameter 'balancingGroupId' is not null or undefined
            assertParamExists('updateBalancingGroup', 'balancingGroupId', balancingGroupId)
            // verify required parameter 'requestBalancingGroup' is not null or undefined
            assertParamExists('updateBalancingGroup', 'requestBalancingGroup', requestBalancingGroup)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/balancing-groups/{balancingGroupId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"balancingGroupId"}}`, encodeURIComponent(String(balancingGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:occto-masters"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBalancingGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 広域情報の計画提出者を更新する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 計画提出者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplyPlanSubmitter: async (organizationId: string, supplyPlanSubmitterId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateSupplyPlanSubmitter', 'organizationId', organizationId)
            // verify required parameter 'supplyPlanSubmitterId' is not null or undefined
            assertParamExists('updateSupplyPlanSubmitter', 'supplyPlanSubmitterId', supplyPlanSubmitterId)
            // verify required parameter 'requestSupplyPlanSubmitter' is not null or undefined
            assertParamExists('updateSupplyPlanSubmitter', 'requestSupplyPlanSubmitter', requestSupplyPlanSubmitter)
            const localVarPath = `/trading/organizations/{organizationId}/occto-master/supply-plan-submitters/{supplyPlanSubmitterId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"supplyPlanSubmitterId"}}`, encodeURIComponent(String(supplyPlanSubmitterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:occto-masters"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSupplyPlanSubmitter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OcctoMastersApi - functional programming interface
 * @export
 */
export const OcctoMastersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OcctoMastersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 広域情報のBGを削除する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBalancingGroup(organizationId: string, balancingGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBalancingGroup(organizationId, balancingGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報の計画提出者を削除する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報のBGをIDで取得する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalancingGroup(organizationId: string, balancingGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalancingGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBalancingGroup(organizationId, balancingGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の広域情報をすべて取得する
         * @param {string} organizationId 組織ID
         * @param {string} [areaId] 取得対象のエリアID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOcctoMaster(organizationId: string, areaId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OcctoMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOcctoMaster(organizationId, areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報の計画提出者をIDで取得する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplyPlanSubmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報のBGを新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestBalancingGroup} requestBalancingGroup 新規BGオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newBalancingGroup(organizationId: string, requestBalancingGroup: RequestBalancingGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalancingGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newBalancingGroup(organizationId, requestBalancingGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報の計画提出者を新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 新規計画提出者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newSupplyPlanSubmitter(organizationId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplyPlanSubmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newSupplyPlanSubmitter(organizationId, requestSupplyPlanSubmitter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報のBGを変更する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {RequestBalancingGroup} requestBalancingGroup BGオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBalancingGroup(organizationId: string, balancingGroupId: string, requestBalancingGroup: RequestBalancingGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalancingGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBalancingGroup(organizationId, balancingGroupId, requestBalancingGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 広域情報の計画提出者を更新する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 計画提出者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplyPlanSubmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, requestSupplyPlanSubmitter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OcctoMastersApi - factory interface
 * @export
 */
export const OcctoMastersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OcctoMastersApiFp(configuration)
    return {
        /**
         * 
         * @summary 広域情報のBGを削除する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBalancingGroup(organizationId: string, balancingGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBalancingGroup(organizationId, balancingGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報の計画提出者を削除する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報のBGをIDで取得する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalancingGroup(organizationId: string, balancingGroupId: string, options?: any): AxiosPromise<BalancingGroup> {
            return localVarFp.getBalancingGroup(organizationId, balancingGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の広域情報をすべて取得する
         * @param {string} organizationId 組織ID
         * @param {string} [areaId] 取得対象のエリアID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcctoMaster(organizationId: string, areaId?: string, options?: any): AxiosPromise<OcctoMaster> {
            return localVarFp.getOcctoMaster(organizationId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報の計画提出者をIDで取得する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, options?: any): AxiosPromise<SupplyPlanSubmitter> {
            return localVarFp.getSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報のBGを新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestBalancingGroup} requestBalancingGroup 新規BGオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newBalancingGroup(organizationId: string, requestBalancingGroup: RequestBalancingGroup, options?: any): AxiosPromise<BalancingGroup> {
            return localVarFp.newBalancingGroup(organizationId, requestBalancingGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報の計画提出者を新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 新規計画提出者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newSupplyPlanSubmitter(organizationId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options?: any): AxiosPromise<SupplyPlanSubmitter> {
            return localVarFp.newSupplyPlanSubmitter(organizationId, requestSupplyPlanSubmitter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報のBGを変更する
         * @param {string} organizationId 組織ID
         * @param {string} balancingGroupId 対象のBGID
         * @param {RequestBalancingGroup} requestBalancingGroup BGオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBalancingGroup(organizationId: string, balancingGroupId: string, requestBalancingGroup: RequestBalancingGroup, options?: any): AxiosPromise<BalancingGroup> {
            return localVarFp.updateBalancingGroup(organizationId, balancingGroupId, requestBalancingGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 広域情報の計画提出者を更新する
         * @param {string} organizationId 組織ID
         * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
         * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 計画提出者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options?: any): AxiosPromise<SupplyPlanSubmitter> {
            return localVarFp.updateSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, requestSupplyPlanSubmitter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OcctoMastersApi - object-oriented interface
 * @export
 * @class OcctoMastersApi
 * @extends {BaseAPI}
 */
export class OcctoMastersApi extends BaseAPI {
    /**
     * 
     * @summary 広域情報のBGを削除する
     * @param {string} organizationId 組織ID
     * @param {string} balancingGroupId 対象のBGID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public deleteBalancingGroup(organizationId: string, balancingGroupId: string, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).deleteBalancingGroup(organizationId, balancingGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報の計画提出者を削除する
     * @param {string} organizationId 組織ID
     * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public deleteSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).deleteSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報のBGをIDで取得する
     * @param {string} organizationId 組織ID
     * @param {string} balancingGroupId 対象のBGID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public getBalancingGroup(organizationId: string, balancingGroupId: string, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).getBalancingGroup(organizationId, balancingGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の広域情報をすべて取得する
     * @param {string} organizationId 組織ID
     * @param {string} [areaId] 取得対象のエリアID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public getOcctoMaster(organizationId: string, areaId?: string, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).getOcctoMaster(organizationId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報の計画提出者をIDで取得する
     * @param {string} organizationId 組織ID
     * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public getSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).getSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報のBGを新規作成する
     * @param {string} organizationId 組織ID
     * @param {RequestBalancingGroup} requestBalancingGroup 新規BGオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public newBalancingGroup(organizationId: string, requestBalancingGroup: RequestBalancingGroup, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).newBalancingGroup(organizationId, requestBalancingGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報の計画提出者を新規作成する
     * @param {string} organizationId 組織ID
     * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 新規計画提出者オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public newSupplyPlanSubmitter(organizationId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).newSupplyPlanSubmitter(organizationId, requestSupplyPlanSubmitter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報のBGを変更する
     * @param {string} organizationId 組織ID
     * @param {string} balancingGroupId 対象のBGID
     * @param {RequestBalancingGroup} requestBalancingGroup BGオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public updateBalancingGroup(organizationId: string, balancingGroupId: string, requestBalancingGroup: RequestBalancingGroup, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).updateBalancingGroup(organizationId, balancingGroupId, requestBalancingGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 広域情報の計画提出者を更新する
     * @param {string} organizationId 組織ID
     * @param {string} supplyPlanSubmitterId 対象の計画提出者ID
     * @param {RequestSupplyPlanSubmitter} requestSupplyPlanSubmitter 計画提出者オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcctoMastersApi
     */
    public updateSupplyPlanSubmitter(organizationId: string, supplyPlanSubmitterId: string, requestSupplyPlanSubmitter: RequestSupplyPlanSubmitter, options?: AxiosRequestConfig) {
        return OcctoMastersApiFp(this.configuration).updateSupplyPlanSubmitter(organizationId, supplyPlanSubmitterId, requestSupplyPlanSubmitter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderBooksApi - axios parameter creator
 * @export
 */
export const OrderBooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary トレーダー向けの注文一覧
         * @param {string} [areaIds] エリアID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [startDeliveryYearMonths] YYYY-MM形式の開始月。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [endDeliveryYearMonths] YYYY-MM形式の終了月。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [hourTypeIds] 時間帯区分ID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [productTypeIds] 商品区分ID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderBooks: async (areaIds?: string, startDeliveryYearMonths?: string, endDeliveryYearMonths?: string, hourTypeIds?: string, productTypeIds?: string, isSpread?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/order-books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (areaIds !== undefined) {
                localVarQueryParameter['areaIds'] = areaIds;
            }

            if (startDeliveryYearMonths !== undefined) {
                localVarQueryParameter['startDeliveryYearMonths'] = startDeliveryYearMonths;
            }

            if (endDeliveryYearMonths !== undefined) {
                localVarQueryParameter['endDeliveryYearMonths'] = endDeliveryYearMonths;
            }

            if (hourTypeIds !== undefined) {
                localVarQueryParameter['hourTypeIds'] = hourTypeIds;
            }

            if (productTypeIds !== undefined) {
                localVarQueryParameter['productTypeIds'] = productTypeIds;
            }

            if (isSpread !== undefined) {
                localVarQueryParameter['isSpread'] = isSpread;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderBooksApi - functional programming interface
 * @export
 */
export const OrderBooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderBooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary トレーダー向けの注文一覧
         * @param {string} [areaIds] エリアID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [startDeliveryYearMonths] YYYY-MM形式の開始月。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [endDeliveryYearMonths] YYYY-MM形式の終了月。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [hourTypeIds] 時間帯区分ID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [productTypeIds] 商品区分ID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderBooks(areaIds?: string, startDeliveryYearMonths?: string, endDeliveryYearMonths?: string, hourTypeIds?: string, productTypeIds?: string, isSpread?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderBook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderBooks(areaIds, startDeliveryYearMonths, endDeliveryYearMonths, hourTypeIds, productTypeIds, isSpread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderBooksApi - factory interface
 * @export
 */
export const OrderBooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderBooksApiFp(configuration)
    return {
        /**
         * 
         * @summary トレーダー向けの注文一覧
         * @param {string} [areaIds] エリアID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [startDeliveryYearMonths] YYYY-MM形式の開始月。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [endDeliveryYearMonths] YYYY-MM形式の終了月。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [hourTypeIds] 時間帯区分ID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {string} [productTypeIds] 商品区分ID。\&quot;,\&quot;区切りで複数指定が可能。
         * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderBooks(areaIds?: string, startDeliveryYearMonths?: string, endDeliveryYearMonths?: string, hourTypeIds?: string, productTypeIds?: string, isSpread?: boolean, options?: any): AxiosPromise<Array<OrderBook>> {
            return localVarFp.getOrderBooks(areaIds, startDeliveryYearMonths, endDeliveryYearMonths, hourTypeIds, productTypeIds, isSpread, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderBooksApi - object-oriented interface
 * @export
 * @class OrderBooksApi
 * @extends {BaseAPI}
 */
export class OrderBooksApi extends BaseAPI {
    /**
     * 
     * @summary トレーダー向けの注文一覧
     * @param {string} [areaIds] エリアID。\&quot;,\&quot;区切りで複数指定が可能。
     * @param {string} [startDeliveryYearMonths] YYYY-MM形式の開始月。\&quot;,\&quot;区切りで複数指定が可能。
     * @param {string} [endDeliveryYearMonths] YYYY-MM形式の終了月。\&quot;,\&quot;区切りで複数指定が可能。
     * @param {string} [hourTypeIds] 時間帯区分ID。\&quot;,\&quot;区切りで複数指定が可能。
     * @param {string} [productTypeIds] 商品区分ID。\&quot;,\&quot;区切りで複数指定が可能。
     * @param {boolean} [isSpread] スプレッドデータのみを取得するかどうか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderBooksApi
     */
    public getOrderBooks(areaIds?: string, startDeliveryYearMonths?: string, endDeliveryYearMonths?: string, hourTypeIds?: string, productTypeIds?: string, isSpread?: boolean, options?: AxiosRequestConfig) {
        return OrderBooksApiFp(this.configuration).getOrderBooks(areaIds, startDeliveryYearMonths, endDeliveryYearMonths, hourTypeIds, productTypeIds, isSpread, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationChannelMembersApi - axios parameter creator
 * @export
 */
export const OrganizationChannelMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての組織チャンネルメンバーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationChannels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/organization-channel-members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:organization-channel-members"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationChannelMembersApi - functional programming interface
 * @export
 */
export const OrganizationChannelMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationChannelMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての組織チャンネルメンバーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationChannels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationChannelMember>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationChannels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationChannelMembersApi - factory interface
 * @export
 */
export const OrganizationChannelMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationChannelMembersApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての組織チャンネルメンバーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationChannels(options?: any): AxiosPromise<Array<OrganizationChannelMember>> {
            return localVarFp.organizationChannels(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationChannelMembersApi - object-oriented interface
 * @export
 * @class OrganizationChannelMembersApi
 * @extends {BaseAPI}
 */
export class OrganizationChannelMembersApi extends BaseAPI {
    /**
     * 
     * @summary すべての組織チャンネルメンバーを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationChannelMembersApi
     */
    public organizationChannels(options?: AxiosRequestConfig) {
        return OrganizationChannelMembersApiFp(this.configuration).organizationChannels(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationEmailAddressApi - axios parameter creator
 * @export
 */
export const OrganizationEmailAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織メールアドレスを削除する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationEmailAddress: async (organizationId: string, organizationEmailAddressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganizationEmailAddress', 'organizationId', organizationId)
            // verify required parameter 'organizationEmailAddressId' is not null or undefined
            assertParamExists('deleteOrganizationEmailAddress', 'organizationEmailAddressId', organizationEmailAddressId)
            const localVarPath = `/iam/organizations/{organizationId}/organization-email-addresses/{organizationEmailAddressId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organizationEmailAddressId"}}`, encodeURIComponent(String(organizationEmailAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織メールアドレスを取得する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationEmailAddress: async (organizationId: string, organizationEmailAddressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationEmailAddress', 'organizationId', organizationId)
            // verify required parameter 'organizationEmailAddressId' is not null or undefined
            assertParamExists('getOrganizationEmailAddress', 'organizationEmailAddressId', organizationEmailAddressId)
            const localVarPath = `/iam/organizations/{organizationId}/organization-email-addresses/{organizationEmailAddressId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organizationEmailAddressId"}}`, encodeURIComponent(String(organizationEmailAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織メールアドレスをすべて取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationEmailAddresses: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationEmailAddresses', 'organizationId', organizationId)
            const localVarPath = `/iam/organizations/{organizationId}/organization-email-addresses`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織メールアドレスを新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 新規組織メールアドレスオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrganizationEmailAddress: async (organizationId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('newOrganizationEmailAddress', 'organizationId', organizationId)
            // verify required parameter 'requestOrganizationEmailAddress' is not null or undefined
            assertParamExists('newOrganizationEmailAddress', 'requestOrganizationEmailAddress', requestOrganizationEmailAddress)
            const localVarPath = `/iam/organizations/{organizationId}/organization-email-addresses`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestOrganizationEmailAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織メールアドレス関連情報を変更する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 組織メールアドレス変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationEmailAddress: async (organizationId: string, organizationEmailAddressId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationEmailAddress', 'organizationId', organizationId)
            // verify required parameter 'organizationEmailAddressId' is not null or undefined
            assertParamExists('updateOrganizationEmailAddress', 'organizationEmailAddressId', organizationEmailAddressId)
            // verify required parameter 'requestOrganizationEmailAddress' is not null or undefined
            assertParamExists('updateOrganizationEmailAddress', 'requestOrganizationEmailAddress', requestOrganizationEmailAddress)
            const localVarPath = `/iam/organizations/{organizationId}/organization-email-addresses/{organizationEmailAddressId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organizationEmailAddressId"}}`, encodeURIComponent(String(organizationEmailAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestOrganizationEmailAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationEmailAddressApi - functional programming interface
 * @export
 */
export const OrganizationEmailAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationEmailAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織メールアドレスを削除する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationEmailAddress(organizationId, organizationEmailAddressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織メールアドレスを取得する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationEmailAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationEmailAddress(organizationId, organizationEmailAddressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織メールアドレスをすべて取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationEmailAddresses(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationEmailAddress>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationEmailAddresses(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織メールアドレスを新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 新規組織メールアドレスオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newOrganizationEmailAddress(organizationId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationEmailAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newOrganizationEmailAddress(organizationId, requestOrganizationEmailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織メールアドレス関連情報を変更する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 組織メールアドレス変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationEmailAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationEmailAddress(organizationId, organizationEmailAddressId, requestOrganizationEmailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationEmailAddressApi - factory interface
 * @export
 */
export const OrganizationEmailAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationEmailAddressApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織メールアドレスを削除する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganizationEmailAddress(organizationId, organizationEmailAddressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織メールアドレスを取得する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, options?: any): AxiosPromise<OrganizationEmailAddress> {
            return localVarFp.getOrganizationEmailAddress(organizationId, organizationEmailAddressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織メールアドレスをすべて取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationEmailAddresses(organizationId: string, options?: any): AxiosPromise<Array<OrganizationEmailAddress>> {
            return localVarFp.getOrganizationEmailAddresses(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織メールアドレスを新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 新規組織メールアドレスオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrganizationEmailAddress(organizationId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options?: any): AxiosPromise<OrganizationEmailAddress> {
            return localVarFp.newOrganizationEmailAddress(organizationId, requestOrganizationEmailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織メールアドレス関連情報を変更する
         * @param {string} organizationId 組織ID
         * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
         * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 組織メールアドレス変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options?: any): AxiosPromise<OrganizationEmailAddress> {
            return localVarFp.updateOrganizationEmailAddress(organizationId, organizationEmailAddressId, requestOrganizationEmailAddress, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationEmailAddressApi - object-oriented interface
 * @export
 * @class OrganizationEmailAddressApi
 * @extends {BaseAPI}
 */
export class OrganizationEmailAddressApi extends BaseAPI {
    /**
     * 
     * @summary 組織メールアドレスを削除する
     * @param {string} organizationId 組織ID
     * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationEmailAddressApi
     */
    public deleteOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, options?: AxiosRequestConfig) {
        return OrganizationEmailAddressApiFp(this.configuration).deleteOrganizationEmailAddress(organizationId, organizationEmailAddressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織メールアドレスを取得する
     * @param {string} organizationId 組織ID
     * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationEmailAddressApi
     */
    public getOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, options?: AxiosRequestConfig) {
        return OrganizationEmailAddressApiFp(this.configuration).getOrganizationEmailAddress(organizationId, organizationEmailAddressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織メールアドレスをすべて取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationEmailAddressApi
     */
    public getOrganizationEmailAddresses(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationEmailAddressApiFp(this.configuration).getOrganizationEmailAddresses(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織メールアドレスを新規作成する
     * @param {string} organizationId 組織ID
     * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 新規組織メールアドレスオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationEmailAddressApi
     */
    public newOrganizationEmailAddress(organizationId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options?: AxiosRequestConfig) {
        return OrganizationEmailAddressApiFp(this.configuration).newOrganizationEmailAddress(organizationId, requestOrganizationEmailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織メールアドレス関連情報を変更する
     * @param {string} organizationId 組織ID
     * @param {string} organizationEmailAddressId 対象の組織メールアドレスID
     * @param {RequestOrganizationEmailAddress} requestOrganizationEmailAddress 組織メールアドレス変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationEmailAddressApi
     */
    public updateOrganizationEmailAddress(organizationId: string, organizationEmailAddressId: string, requestOrganizationEmailAddress: RequestOrganizationEmailAddress, options?: AxiosRequestConfig) {
        return OrganizationEmailAddressApiFp(this.configuration).updateOrganizationEmailAddress(organizationId, organizationEmailAddressId, requestOrganizationEmailAddress, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織をIDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationById: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationById', 'organizationId', organizationId)
            const localVarPath = `/iam/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:organizations"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のプロファイルをIDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationProfileById: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationProfileById', 'organizationId', organizationId)
            const localVarPath = `/iam/organizations/{organizationId}/profile`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織を取得する
         * @param {Array<string>} [ids] 組織IDのリスト
         * @param {OrganizationType} [organizationType] 組織の種類
         * @param {string} [organizationName] 組織の名称、または略称。部分一致で検索される。アルファベットの場合は大文字小文字は区別されない
         * @param {boolean} [isEClearContracted] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {boolean} [isEClearCreditSleever] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {boolean} [isEsquareEnabled] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (ids?: Array<string>, organizationType?: OrganizationType, organizationName?: string, isEClearContracted?: boolean, isEClearCreditSleever?: boolean, isEsquareEnabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/iam/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:organizations"], configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (organizationType !== undefined) {
                localVarQueryParameter['organizationType'] = organizationType;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }

            if (isEClearContracted !== undefined) {
                localVarQueryParameter['isEClearContracted'] = isEClearContracted;
            }

            if (isEClearCreditSleever !== undefined) {
                localVarQueryParameter['isEClearCreditSleever'] = isEClearCreditSleever;
            }

            if (isEsquareEnabled !== undefined) {
                localVarQueryParameter['isEsquareEnabled'] = isEsquareEnabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の写真を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPictureOfOrganization: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getPictureOfOrganization', 'organizationId', organizationId)
            const localVarPath = `/iam/organizations/{organizationId}/picture`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織を新規作成する
         * @param {NewOrganization} newOrganization 新規組織オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrganization: async (newOrganization: NewOrganization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newOrganization' is not null or undefined
            assertParamExists('newOrganization', 'newOrganization', newOrganization)
            const localVarPath = `/iam/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:organizations"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateOrganization} updateOrganization 組織のオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (organizationId: string, updateOrganization: UpdateOrganization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganization', 'organizationId', organizationId)
            // verify required parameter 'updateOrganization' is not null or undefined
            assertParamExists('updateOrganization', 'updateOrganization', updateOrganization)
            const localVarPath = `/iam/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織をIDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationById(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationById(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のプロファイルをIDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationProfileById(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationProfileById(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織を取得する
         * @param {Array<string>} [ids] 組織IDのリスト
         * @param {OrganizationType} [organizationType] 組織の種類
         * @param {string} [organizationName] 組織の名称、または略称。部分一致で検索される。アルファベットの場合は大文字小文字は区別されない
         * @param {boolean} [isEClearContracted] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {boolean} [isEClearCreditSleever] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {boolean} [isEsquareEnabled] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(ids?: Array<string>, organizationType?: OrganizationType, organizationName?: string, isEClearContracted?: boolean, isEClearCreditSleever?: boolean, isEsquareEnabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(ids, organizationType, organizationName, isEClearContracted, isEClearCreditSleever, isEsquareEnabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の写真を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPictureOfOrganization(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPictureOfOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織を新規作成する
         * @param {NewOrganization} newOrganization 新規組織オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newOrganization(newOrganization: NewOrganization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newOrganization(newOrganization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateOrganization} updateOrganization 組織のオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(organizationId: string, updateOrganization: UpdateOrganization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(organizationId, updateOrganization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織をIDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationById(organizationId: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.getOrganizationById(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のプロファイルをIDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationProfileById(organizationId: string, options?: any): AxiosPromise<OrganizationProfile> {
            return localVarFp.getOrganizationProfileById(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織を取得する
         * @param {Array<string>} [ids] 組織IDのリスト
         * @param {OrganizationType} [organizationType] 組織の種類
         * @param {string} [organizationName] 組織の名称、または略称。部分一致で検索される。アルファベットの場合は大文字小文字は区別されない
         * @param {boolean} [isEClearContracted] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {boolean} [isEClearCreditSleever] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {boolean} [isEsquareEnabled] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(ids?: Array<string>, organizationType?: OrganizationType, organizationName?: string, isEClearContracted?: boolean, isEClearCreditSleever?: boolean, isEsquareEnabled?: boolean, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.getOrganizations(ids, organizationType, organizationName, isEClearContracted, isEClearCreditSleever, isEsquareEnabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の写真を取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPictureOfOrganization(organizationId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getPictureOfOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織を新規作成する
         * @param {NewOrganization} newOrganization 新規組織オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrganization(newOrganization: NewOrganization, options?: any): AxiosPromise<Organization> {
            return localVarFp.newOrganization(newOrganization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織を変更する
         * @param {string} organizationId 組織ID
         * @param {UpdateOrganization} updateOrganization 組織のオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(organizationId: string, updateOrganization: UpdateOrganization, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganization(organizationId, updateOrganization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary 組織をIDで取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationById(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationById(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のプロファイルをIDで取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationProfileById(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationProfileById(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織を取得する
     * @param {Array<string>} [ids] 組織IDのリスト
     * @param {OrganizationType} [organizationType] 組織の種類
     * @param {string} [organizationName] 組織の名称、または略称。部分一致で検索される。アルファベットの場合は大文字小文字は区別されない
     * @param {boolean} [isEClearContracted] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
     * @param {boolean} [isEClearCreditSleever] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
     * @param {boolean} [isEsquareEnabled] Organizationの同名のプロパティ。trueであればtrueのみ、falseであればfalseのみ、未指定の場合は当該フラグを考慮しない
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizations(ids?: Array<string>, organizationType?: OrganizationType, organizationName?: string, isEClearContracted?: boolean, isEClearCreditSleever?: boolean, isEsquareEnabled?: boolean, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizations(ids, organizationType, organizationName, isEClearContracted, isEClearCreditSleever, isEsquareEnabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の写真を取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getPictureOfOrganization(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getPictureOfOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織を新規作成する
     * @param {NewOrganization} newOrganization 新規組織オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public newOrganization(newOrganization: NewOrganization, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).newOrganization(newOrganization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織を変更する
     * @param {string} organizationId 組織ID
     * @param {UpdateOrganization} updateOrganization 組織のオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganization(organizationId: string, updateOrganization: UpdateOrganization, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganization(organizationId, updateOrganization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductTypeDateNamesApi - axios parameter creator
 * @export
 */
export const ProductTypeDateNamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 商品区分に関連する日付の名称を返す
         * @param {string} productTypeId 商品区分ID
         * @param {string} date 名称を取得したい日付
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateNameOfWeekOrDay: async (productTypeId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeId' is not null or undefined
            assertParamExists('getDateNameOfWeekOrDay', 'productTypeId', productTypeId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getDateNameOfWeekOrDay', 'date', date)
            const localVarPath = `/trading/product-type-date-names/{productTypeId}/week-or-day/{date}`
                .replace(`{${"productTypeId"}}`, encodeURIComponent(String(productTypeId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:product-type-date-names"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 日付のマスタを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/product-type-date-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:product-type-date-names"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductTypeDateNamesApi - functional programming interface
 * @export
 */
export const ProductTypeDateNamesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductTypeDateNamesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 商品区分に関連する日付の名称を返す
         * @param {string} productTypeId 商品区分ID
         * @param {string} date 名称を取得したい日付
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDateNameOfWeekOrDay(productTypeId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTypeDateName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDateNameOfWeekOrDay(productTypeId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 日付のマスタを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDateNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductTypeDateName>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDateNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductTypeDateNamesApi - factory interface
 * @export
 */
export const ProductTypeDateNamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductTypeDateNamesApiFp(configuration)
    return {
        /**
         * 
         * @summary 商品区分に関連する日付の名称を返す
         * @param {string} productTypeId 商品区分ID
         * @param {string} date 名称を取得したい日付
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateNameOfWeekOrDay(productTypeId: string, date: string, options?: any): AxiosPromise<ProductTypeDateName> {
            return localVarFp.getDateNameOfWeekOrDay(productTypeId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 日付のマスタを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateNames(options?: any): AxiosPromise<Array<ProductTypeDateName>> {
            return localVarFp.getDateNames(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductTypeDateNamesApi - object-oriented interface
 * @export
 * @class ProductTypeDateNamesApi
 * @extends {BaseAPI}
 */
export class ProductTypeDateNamesApi extends BaseAPI {
    /**
     * 
     * @summary 商品区分に関連する日付の名称を返す
     * @param {string} productTypeId 商品区分ID
     * @param {string} date 名称を取得したい日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductTypeDateNamesApi
     */
    public getDateNameOfWeekOrDay(productTypeId: string, date: string, options?: AxiosRequestConfig) {
        return ProductTypeDateNamesApiFp(this.configuration).getDateNameOfWeekOrDay(productTypeId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 日付のマスタを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductTypeDateNamesApi
     */
    public getDateNames(options?: AxiosRequestConfig) {
        return ProductTypeDateNamesApiFp(this.configuration).getDateNames(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductTypesApi - axios parameter creator
 * @export
 */
export const ProductTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての商品区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/product-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:product-types"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductTypesApi - functional programming interface
 * @export
 */
export const ProductTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての商品区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductTypesApi - factory interface
 * @export
 */
export const ProductTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての商品区分を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTypes(options?: any): AxiosPromise<Array<ProductType>> {
            return localVarFp.getProductTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductTypesApi - object-oriented interface
 * @export
 * @class ProductTypesApi
 * @extends {BaseAPI}
 */
export class ProductTypesApi extends BaseAPI {
    /**
     * 
     * @summary すべての商品区分を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductTypesApi
     */
    public getProductTypes(options?: AxiosRequestConfig) {
        return ProductTypesApiFp(this.configuration).getProductTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 商品をIDで取得する
         * @param {string} productId 取得対象の商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductById', 'productId', productId)
            const localVarPath = `/trading/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:products"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 商品を商品区分、エリア、時間帯区分、デリバリー期間から取得する
         * @param {string} productTypeId 取得対象の商品区分ID
         * @param {string} areaId 取得対象のエリアID
         * @param {string} hourTypeId 取得対象の時間帯区分ID
         * @param {string} startDeliveryYearMonthId 取得対象のデリバリー開始年月ID
         * @param {string} endDeliveryYearMonthId 取得対象のデリバリー終了年月ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (productTypeId: string, areaId: string, hourTypeId: string, startDeliveryYearMonthId: string, endDeliveryYearMonthId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeId' is not null or undefined
            assertParamExists('getProducts', 'productTypeId', productTypeId)
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('getProducts', 'areaId', areaId)
            // verify required parameter 'hourTypeId' is not null or undefined
            assertParamExists('getProducts', 'hourTypeId', hourTypeId)
            // verify required parameter 'startDeliveryYearMonthId' is not null or undefined
            assertParamExists('getProducts', 'startDeliveryYearMonthId', startDeliveryYearMonthId)
            // verify required parameter 'endDeliveryYearMonthId' is not null or undefined
            assertParamExists('getProducts', 'endDeliveryYearMonthId', endDeliveryYearMonthId)
            const localVarPath = `/trading/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:products"], configuration)

            if (productTypeId !== undefined) {
                localVarQueryParameter['productTypeId'] = productTypeId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (hourTypeId !== undefined) {
                localVarQueryParameter['hourTypeId'] = hourTypeId;
            }

            if (startDeliveryYearMonthId !== undefined) {
                localVarQueryParameter['startDeliveryYearMonthId'] = startDeliveryYearMonthId;
            }

            if (endDeliveryYearMonthId !== undefined) {
                localVarQueryParameter['endDeliveryYearMonthId'] = endDeliveryYearMonthId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 商品を新規作成する
         * @param {NewProductOld} newProductOld 商品オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newProduct: async (newProductOld: NewProductOld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newProductOld' is not null or undefined
            assertParamExists('newProduct', 'newProductOld', newProductOld)
            const localVarPath = `/trading/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:products"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProductOld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 商品をIDで取得する
         * @param {string} productId 取得対象の商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 商品を商品区分、エリア、時間帯区分、デリバリー期間から取得する
         * @param {string} productTypeId 取得対象の商品区分ID
         * @param {string} areaId 取得対象のエリアID
         * @param {string} hourTypeId 取得対象の時間帯区分ID
         * @param {string} startDeliveryYearMonthId 取得対象のデリバリー開始年月ID
         * @param {string} endDeliveryYearMonthId 取得対象のデリバリー終了年月ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(productTypeId: string, areaId: string, hourTypeId: string, startDeliveryYearMonthId: string, endDeliveryYearMonthId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(productTypeId, areaId, hourTypeId, startDeliveryYearMonthId, endDeliveryYearMonthId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 商品を新規作成する
         * @param {NewProductOld} newProductOld 商品オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newProduct(newProductOld: NewProductOld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newProduct(newProductOld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary 商品をIDで取得する
         * @param {string} productId 取得対象の商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.getProductById(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 商品を商品区分、エリア、時間帯区分、デリバリー期間から取得する
         * @param {string} productTypeId 取得対象の商品区分ID
         * @param {string} areaId 取得対象のエリアID
         * @param {string} hourTypeId 取得対象の時間帯区分ID
         * @param {string} startDeliveryYearMonthId 取得対象のデリバリー開始年月ID
         * @param {string} endDeliveryYearMonthId 取得対象のデリバリー終了年月ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(productTypeId: string, areaId: string, hourTypeId: string, startDeliveryYearMonthId: string, endDeliveryYearMonthId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.getProducts(productTypeId, areaId, hourTypeId, startDeliveryYearMonthId, endDeliveryYearMonthId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 商品を新規作成する
         * @param {NewProductOld} newProductOld 商品オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newProduct(newProductOld: NewProductOld, options?: any): AxiosPromise<Product> {
            return localVarFp.newProduct(newProductOld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary 商品をIDで取得する
     * @param {string} productId 取得対象の商品ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductById(productId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductById(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 商品を商品区分、エリア、時間帯区分、デリバリー期間から取得する
     * @param {string} productTypeId 取得対象の商品区分ID
     * @param {string} areaId 取得対象のエリアID
     * @param {string} hourTypeId 取得対象の時間帯区分ID
     * @param {string} startDeliveryYearMonthId 取得対象のデリバリー開始年月ID
     * @param {string} endDeliveryYearMonthId 取得対象のデリバリー終了年月ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProducts(productTypeId: string, areaId: string, hourTypeId: string, startDeliveryYearMonthId: string, endDeliveryYearMonthId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProducts(productTypeId, areaId, hourTypeId, startDeliveryYearMonthId, endDeliveryYearMonthId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 商品を新規作成する
     * @param {NewProductOld} newProductOld 商品オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public newProduct(newProductOld: NewProductOld, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).newProduct(newProductOld, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicDealsApi - axios parameter creator
 * @export
 */
export const PublicDealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての公開可能な直近約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicLastDeals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/public-last-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary すべての公開可能な直近約定をCSVで取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicLastDealsCsv: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/public-last-deals/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公開可能な直近1週間分の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicRecentDeals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/public-recent-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-deals"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicDealsApi - functional programming interface
 * @export
 */
export const PublicDealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicDealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての公開可能な直近約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicLastDeals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicLastDeals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary すべての公開可能な直近約定をCSVで取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicLastDealsCsv(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicLastDealsCsv(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公開可能な直近1週間分の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicRecentDeals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicRecentDeals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicDealsApi - factory interface
 * @export
 */
export const PublicDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicDealsApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての公開可能な直近約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicLastDeals(options?: any): AxiosPromise<Array<PublicDeal>> {
            return localVarFp.getPublicLastDeals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary すべての公開可能な直近約定をCSVで取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicLastDealsCsv(options?: any): AxiosPromise<string> {
            return localVarFp.getPublicLastDealsCsv(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公開可能な直近1週間分の約定を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicRecentDeals(options?: any): AxiosPromise<Array<PublicDeal>> {
            return localVarFp.getPublicRecentDeals(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicDealsApi - object-oriented interface
 * @export
 * @class PublicDealsApi
 * @extends {BaseAPI}
 */
export class PublicDealsApi extends BaseAPI {
    /**
     * 
     * @summary すべての公開可能な直近約定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDealsApi
     */
    public getPublicLastDeals(options?: AxiosRequestConfig) {
        return PublicDealsApiFp(this.configuration).getPublicLastDeals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary すべての公開可能な直近約定をCSVで取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDealsApi
     */
    public getPublicLastDealsCsv(options?: AxiosRequestConfig) {
        return PublicDealsApiFp(this.configuration).getPublicLastDealsCsv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公開可能な直近1週間分の約定を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDealsApi
     */
    public getPublicRecentDeals(options?: AxiosRequestConfig) {
        return PublicDealsApiFp(this.configuration).getPublicRecentDeals(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicIndicationsApi - axios parameter creator
 * @export
 */
export const PublicIndicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべての公開注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicIndications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/public-indications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary すべての公開注文をCSVで取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicIndicationsCsv: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/public-indications/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-indications"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicIndicationsApi - functional programming interface
 * @export
 */
export const PublicIndicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicIndicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary すべての公開注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicIndications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicIndication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicIndications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary すべての公開注文をCSVで取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicIndicationsCsv(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicIndicationsCsv(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicIndicationsApi - factory interface
 * @export
 */
export const PublicIndicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicIndicationsApiFp(configuration)
    return {
        /**
         * 
         * @summary すべての公開注文を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicIndications(options?: any): AxiosPromise<Array<PublicIndication>> {
            return localVarFp.getPublicIndications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary すべての公開注文をCSVで取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicIndicationsCsv(options?: any): AxiosPromise<string> {
            return localVarFp.getPublicIndicationsCsv(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicIndicationsApi - object-oriented interface
 * @export
 * @class PublicIndicationsApi
 * @extends {BaseAPI}
 */
export class PublicIndicationsApi extends BaseAPI {
    /**
     * 
     * @summary すべての公開注文を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicIndicationsApi
     */
    public getPublicIndications(options?: AxiosRequestConfig) {
        return PublicIndicationsApiFp(this.configuration).getPublicIndications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary すべての公開注文をCSVで取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicIndicationsApi
     */
    public getPublicIndicationsCsv(options?: AxiosRequestConfig) {
        return PublicIndicationsApiFp(this.configuration).getPublicIndicationsCsv(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicOrganizationProfilesApi - axios parameter creator
 * @export
 */
export const PublicOrganizationProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 公開組織プロファイルを取得する
         * @param {string} [id] 組織ID。1件のみ取得する場合はこちらのパラメータを利用する
         * @param {Array<string>} [ids] 組織IDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {number} [page] ページ番号
         * @param {number} [perPage] １ページのユーザー件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOrganizationProfiles: async (id?: string, ids?: Array<string>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/iam/public-organization-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicOrganizationProfilesApi - functional programming interface
 * @export
 */
export const PublicOrganizationProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicOrganizationProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 公開組織プロファイルを取得する
         * @param {string} [id] 組織ID。1件のみ取得する場合はこちらのパラメータを利用する
         * @param {Array<string>} [ids] 組織IDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {number} [page] ページ番号
         * @param {number} [perPage] １ページのユーザー件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicOrganizationProfiles(id?: string, ids?: Array<string>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicOrganizationProfiles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicOrganizationProfiles(id, ids, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicOrganizationProfilesApi - factory interface
 * @export
 */
export const PublicOrganizationProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicOrganizationProfilesApiFp(configuration)
    return {
        /**
         * 
         * @summary 公開組織プロファイルを取得する
         * @param {string} [id] 組織ID。1件のみ取得する場合はこちらのパラメータを利用する
         * @param {Array<string>} [ids] 組織IDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {number} [page] ページ番号
         * @param {number} [perPage] １ページのユーザー件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOrganizationProfiles(id?: string, ids?: Array<string>, page?: number, perPage?: number, options?: any): AxiosPromise<PublicOrganizationProfiles> {
            return localVarFp.getPublicOrganizationProfiles(id, ids, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicOrganizationProfilesApi - object-oriented interface
 * @export
 * @class PublicOrganizationProfilesApi
 * @extends {BaseAPI}
 */
export class PublicOrganizationProfilesApi extends BaseAPI {
    /**
     * 
     * @summary 公開組織プロファイルを取得する
     * @param {string} [id] 組織ID。1件のみ取得する場合はこちらのパラメータを利用する
     * @param {Array<string>} [ids] 組織IDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
     * @param {number} [page] ページ番号
     * @param {number} [perPage] １ページのユーザー件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicOrganizationProfilesApi
     */
    public getPublicOrganizationProfiles(id?: string, ids?: Array<string>, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return PublicOrganizationProfilesApiFp(this.configuration).getPublicOrganizationProfiles(id, ids, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicUserProfilesApi - axios parameter creator
 * @export
 */
export const PublicUserProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 公開ユーザープロファイルを取得する
         * @param {string} [id] ユーザーID。1件のみ取得する場合はこちらのパラメータを利用する
         * @param {Array<string>} [ids] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {'trader' | 'broker' | 'system-admin'} [userType] ユーザータイプ
         * @param {'invited' | 'active'} [status] ステータス
         * @param {number} [page] ページ番号
         * @param {number} [perPage] １ページのユーザー件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserProfiles: async (id?: string, ids?: Array<string>, userType?: 'trader' | 'broker' | 'system-admin', status?: 'invited' | 'active', page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/iam/public-user-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:public-user-profiles"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicUserProfilesApi - functional programming interface
 * @export
 */
export const PublicUserProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicUserProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 公開ユーザープロファイルを取得する
         * @param {string} [id] ユーザーID。1件のみ取得する場合はこちらのパラメータを利用する
         * @param {Array<string>} [ids] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {'trader' | 'broker' | 'system-admin'} [userType] ユーザータイプ
         * @param {'invited' | 'active'} [status] ステータス
         * @param {number} [page] ページ番号
         * @param {number} [perPage] １ページのユーザー件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicUserProfiles(id?: string, ids?: Array<string>, userType?: 'trader' | 'broker' | 'system-admin', status?: 'invited' | 'active', page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicUserProfiles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicUserProfiles(id, ids, userType, status, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicUserProfilesApi - factory interface
 * @export
 */
export const PublicUserProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicUserProfilesApiFp(configuration)
    return {
        /**
         * 
         * @summary 公開ユーザープロファイルを取得する
         * @param {string} [id] ユーザーID。1件のみ取得する場合はこちらのパラメータを利用する
         * @param {Array<string>} [ids] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {'trader' | 'broker' | 'system-admin'} [userType] ユーザータイプ
         * @param {'invited' | 'active'} [status] ステータス
         * @param {number} [page] ページ番号
         * @param {number} [perPage] １ページのユーザー件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserProfiles(id?: string, ids?: Array<string>, userType?: 'trader' | 'broker' | 'system-admin', status?: 'invited' | 'active', page?: number, perPage?: number, options?: any): AxiosPromise<PublicUserProfiles> {
            return localVarFp.getPublicUserProfiles(id, ids, userType, status, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicUserProfilesApi - object-oriented interface
 * @export
 * @class PublicUserProfilesApi
 * @extends {BaseAPI}
 */
export class PublicUserProfilesApi extends BaseAPI {
    /**
     * 
     * @summary 公開ユーザープロファイルを取得する
     * @param {string} [id] ユーザーID。1件のみ取得する場合はこちらのパラメータを利用する
     * @param {Array<string>} [ids] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
     * @param {'trader' | 'broker' | 'system-admin'} [userType] ユーザータイプ
     * @param {'invited' | 'active'} [status] ステータス
     * @param {number} [page] ページ番号
     * @param {number} [perPage] １ページのユーザー件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicUserProfilesApi
     */
    public getPublicUserProfiles(id?: string, ids?: Array<string>, userType?: 'trader' | 'broker' | 'system-admin', status?: 'invited' | 'active', page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return PublicUserProfilesApiFp(this.configuration).getPublicUserProfiles(id, ids, userType, status, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecapReceiversApi - axios parameter creator
 * @export
 */
export const RecapReceiversApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 確認書担当者を削除する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecapReceiver: async (organizationId: string, recapReceiverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteRecapReceiver', 'organizationId', organizationId)
            // verify required parameter 'recapReceiverId' is not null or undefined
            assertParamExists('deleteRecapReceiver', 'recapReceiverId', recapReceiverId)
            const localVarPath = `/trading/organizations/{organizationId}/recap-receivers/{recapReceiverId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recapReceiverId"}}`, encodeURIComponent(String(recapReceiverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:recap-receivers"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の確認書担当者を取得する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapReceiver: async (organizationId: string, recapReceiverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getRecapReceiver', 'organizationId', organizationId)
            // verify required parameter 'recapReceiverId' is not null or undefined
            assertParamExists('getRecapReceiver', 'recapReceiverId', recapReceiverId)
            const localVarPath = `/trading/organizations/{organizationId}/recap-receivers/{recapReceiverId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recapReceiverId"}}`, encodeURIComponent(String(recapReceiverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:recap-receivers"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の確認書担当者一覧を組織IDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapReceivers: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getRecapReceivers', 'organizationId', organizationId)
            const localVarPath = `/trading/organizations/{organizationId}/recap-receivers`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:recap-receivers"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 確認書担当者を新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestRecapReceiver} requestRecapReceiver 新規確認書担当者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newRecapReceiver: async (organizationId: string, requestRecapReceiver: RequestRecapReceiver, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('newRecapReceiver', 'organizationId', organizationId)
            // verify required parameter 'requestRecapReceiver' is not null or undefined
            assertParamExists('newRecapReceiver', 'requestRecapReceiver', requestRecapReceiver)
            const localVarPath = `/trading/organizations/{organizationId}/recap-receivers`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestRecapReceiver, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 確認書担当者を変更する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {RequestRecapReceiver} requestRecapReceiver 確認書担当者変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecapReceiver: async (organizationId: string, recapReceiverId: string, requestRecapReceiver: RequestRecapReceiver, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateRecapReceiver', 'organizationId', organizationId)
            // verify required parameter 'recapReceiverId' is not null or undefined
            assertParamExists('updateRecapReceiver', 'recapReceiverId', recapReceiverId)
            // verify required parameter 'requestRecapReceiver' is not null or undefined
            assertParamExists('updateRecapReceiver', 'requestRecapReceiver', requestRecapReceiver)
            const localVarPath = `/trading/organizations/{organizationId}/recap-receivers/{recapReceiverId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"recapReceiverId"}}`, encodeURIComponent(String(recapReceiverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:recap-receivers"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestRecapReceiver, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecapReceiversApi - functional programming interface
 * @export
 */
export const RecapReceiversApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecapReceiversApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 確認書担当者を削除する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecapReceiver(organizationId: string, recapReceiverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecapReceiver(organizationId, recapReceiverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の確認書担当者を取得する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecapReceiver(organizationId: string, recapReceiverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecapReceiver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecapReceiver(organizationId, recapReceiverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の確認書担当者一覧を組織IDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecapReceivers(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecapReceiver>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecapReceivers(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 確認書担当者を新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestRecapReceiver} requestRecapReceiver 新規確認書担当者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newRecapReceiver(organizationId: string, requestRecapReceiver: RequestRecapReceiver, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecapReceiver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newRecapReceiver(organizationId, requestRecapReceiver, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 確認書担当者を変更する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {RequestRecapReceiver} requestRecapReceiver 確認書担当者変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecapReceiver(organizationId: string, recapReceiverId: string, requestRecapReceiver: RequestRecapReceiver, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecapReceiver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecapReceiver(organizationId, recapReceiverId, requestRecapReceiver, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecapReceiversApi - factory interface
 * @export
 */
export const RecapReceiversApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecapReceiversApiFp(configuration)
    return {
        /**
         * 
         * @summary 確認書担当者を削除する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecapReceiver(organizationId: string, recapReceiverId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRecapReceiver(organizationId, recapReceiverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の確認書担当者を取得する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapReceiver(organizationId: string, recapReceiverId: string, options?: any): AxiosPromise<RecapReceiver> {
            return localVarFp.getRecapReceiver(organizationId, recapReceiverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の確認書担当者一覧を組織IDで取得する
         * @param {string} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapReceivers(organizationId: string, options?: any): AxiosPromise<Array<RecapReceiver>> {
            return localVarFp.getRecapReceivers(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 確認書担当者を新規作成する
         * @param {string} organizationId 組織ID
         * @param {RequestRecapReceiver} requestRecapReceiver 新規確認書担当者オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newRecapReceiver(organizationId: string, requestRecapReceiver: RequestRecapReceiver, options?: any): AxiosPromise<RecapReceiver> {
            return localVarFp.newRecapReceiver(organizationId, requestRecapReceiver, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 確認書担当者を変更する
         * @param {string} organizationId 組織ID
         * @param {string} recapReceiverId 確認書担当者ID
         * @param {RequestRecapReceiver} requestRecapReceiver 確認書担当者変更オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecapReceiver(organizationId: string, recapReceiverId: string, requestRecapReceiver: RequestRecapReceiver, options?: any): AxiosPromise<RecapReceiver> {
            return localVarFp.updateRecapReceiver(organizationId, recapReceiverId, requestRecapReceiver, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecapReceiversApi - object-oriented interface
 * @export
 * @class RecapReceiversApi
 * @extends {BaseAPI}
 */
export class RecapReceiversApi extends BaseAPI {
    /**
     * 
     * @summary 確認書担当者を削除する
     * @param {string} organizationId 組織ID
     * @param {string} recapReceiverId 確認書担当者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapReceiversApi
     */
    public deleteRecapReceiver(organizationId: string, recapReceiverId: string, options?: AxiosRequestConfig) {
        return RecapReceiversApiFp(this.configuration).deleteRecapReceiver(organizationId, recapReceiverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の確認書担当者を取得する
     * @param {string} organizationId 組織ID
     * @param {string} recapReceiverId 確認書担当者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapReceiversApi
     */
    public getRecapReceiver(organizationId: string, recapReceiverId: string, options?: AxiosRequestConfig) {
        return RecapReceiversApiFp(this.configuration).getRecapReceiver(organizationId, recapReceiverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の確認書担当者一覧を組織IDで取得する
     * @param {string} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapReceiversApi
     */
    public getRecapReceivers(organizationId: string, options?: AxiosRequestConfig) {
        return RecapReceiversApiFp(this.configuration).getRecapReceivers(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 確認書担当者を新規作成する
     * @param {string} organizationId 組織ID
     * @param {RequestRecapReceiver} requestRecapReceiver 新規確認書担当者オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapReceiversApi
     */
    public newRecapReceiver(organizationId: string, requestRecapReceiver: RequestRecapReceiver, options?: AxiosRequestConfig) {
        return RecapReceiversApiFp(this.configuration).newRecapReceiver(organizationId, requestRecapReceiver, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 確認書担当者を変更する
     * @param {string} organizationId 組織ID
     * @param {string} recapReceiverId 確認書担当者ID
     * @param {RequestRecapReceiver} requestRecapReceiver 確認書担当者変更オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapReceiversApi
     */
    public updateRecapReceiver(organizationId: string, recapReceiverId: string, requestRecapReceiver: RequestRecapReceiver, options?: AxiosRequestConfig) {
        return RecapReceiversApiFp(this.configuration).updateRecapReceiver(organizationId, recapReceiverId, requestRecapReceiver, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary レポートを作成する
         * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
         * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
         * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにjaのデータを設定した場合は必須** 
         * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにenのデータを設定した場合は必須** 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportTypes' is not null or undefined
            assertParamExists('createReport', 'reportTypes', reportTypes)
            // verify required parameter 'reportContents' is not null or undefined
            assertParamExists('createReport', 'reportContents', reportContents)
            const localVarPath = `/report/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:reports"], configuration)


            if (reportTypes !== undefined) { 
                localVarFormParams.append('reportTypes', reportTypes as any);
            }
    
            if (reportContents !== undefined) { 
                localVarFormParams.append('reportContents', reportContents as any);
            }
    
            if (attachmentFileJa !== undefined) { 
                localVarFormParams.append('attachmentFileJa', attachmentFileJa as any);
            }
    
            if (attachmentFileEn !== undefined) { 
                localVarFormParams.append('attachmentFileEn', attachmentFileEn as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートを削除する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            const localVarPath = `/report/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:reports"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ブローカーおよびシステム管理者向けのレポートを取得する
         * @param {string} reportId 取得したいレポートのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/report/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:reports"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ブローカーおよびシステム管理者向けのレポートのダウンロードURLを作成して返す
         * @param {string} reportId ダウンロードしたいレポートのID
         * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportUrl: async (reportId: string, reportContentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportUrl', 'reportId', reportId)
            // verify required parameter 'reportContentId' is not null or undefined
            assertParamExists('getReportUrl', 'reportContentId', reportContentId)
            const localVarPath = `/report/reports/{reportId}/contents/{reportContentId}/attachments`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"reportContentId"}}`, encodeURIComponent(String(reportContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:reports"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 返されるレポートの件数は30件
         * @summary ブローカーおよびシステム管理者向けのレポートリストを取得する
         * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports: async (lastReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:reports"], configuration)

            if (lastReportId !== undefined) {
                localVarQueryParameter['lastReportId'] = lastReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レポートを更新する
         * @param {string} reportId 
         * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
         * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
         * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
         * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (reportId: string, reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReport', 'reportId', reportId)
            // verify required parameter 'reportTypes' is not null or undefined
            assertParamExists('updateReport', 'reportTypes', reportTypes)
            // verify required parameter 'reportContents' is not null or undefined
            assertParamExists('updateReport', 'reportContents', reportContents)
            const localVarPath = `/report/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:reports"], configuration)


            if (reportTypes !== undefined) { 
                localVarFormParams.append('reportTypes', reportTypes as any);
            }
    
            if (reportContents !== undefined) { 
                localVarFormParams.append('reportContents', reportContents as any);
            }
    
            if (attachmentFileJa !== undefined) { 
                localVarFormParams.append('attachmentFileJa', attachmentFileJa as any);
            }
    
            if (attachmentFileEn !== undefined) { 
                localVarFormParams.append('attachmentFileEn', attachmentFileEn as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary レポートを作成する
         * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
         * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
         * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにjaのデータを設定した場合は必須** 
         * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにenのデータを設定した場合は必須** 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(reportTypes, reportContents, attachmentFileJa, attachmentFileEn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートを削除する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ブローカーおよびシステム管理者向けのレポートを取得する
         * @param {string} reportId 取得したいレポートのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ブローカーおよびシステム管理者向けのレポートのダウンロードURLを作成して返す
         * @param {string} reportId ダウンロードしたいレポートのID
         * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportUrl(reportId: string, reportContentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocalizedReportUrl200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportUrl(reportId, reportContentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 返されるレポートの件数は30件
         * @summary ブローカーおよびシステム管理者向けのレポートリストを取得する
         * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReports(lastReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(lastReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レポートを更新する
         * @param {string} reportId 
         * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
         * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
         * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
         * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(reportId: string, reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(reportId, reportTypes, reportContents, attachmentFileJa, attachmentFileEn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary レポートを作成する
         * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
         * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
         * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにjaのデータを設定した場合は必須** 
         * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにenのデータを設定した場合は必須** 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options?: any): AxiosPromise<Report> {
            return localVarFp.createReport(reportTypes, reportContents, attachmentFileJa, attachmentFileEn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートを削除する
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ブローカーおよびシステム管理者向けのレポートを取得する
         * @param {string} reportId 取得したいレポートのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ブローカーおよびシステム管理者向けのレポートのダウンロードURLを作成して返す
         * @param {string} reportId ダウンロードしたいレポートのID
         * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportUrl(reportId: string, reportContentId: string, options?: any): AxiosPromise<GetLocalizedReportUrl200Response> {
            return localVarFp.getReportUrl(reportId, reportContentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 返されるレポートの件数は30件
         * @summary ブローカーおよびシステム管理者向けのレポートリストを取得する
         * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports(lastReportId?: string, options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.getReports(lastReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レポートを更新する
         * @param {string} reportId 
         * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
         * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
         * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
         * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(reportId: string, reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options?: any): AxiosPromise<Report> {
            return localVarFp.updateReport(reportId, reportTypes, reportContents, attachmentFileJa, attachmentFileEn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary レポートを作成する
     * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
     * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,       \\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
     * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにjaのデータを設定した場合は必須** 
     * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **reportContentsにenのデータを設定した場合は必須** 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public createReport(reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).createReport(reportTypes, reportContents, attachmentFileJa, attachmentFileEn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートを削除する
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReport(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ブローカーおよびシステム管理者向けのレポートを取得する
     * @param {string} reportId 取得したいレポートのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReport(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ブローカーおよびシステム管理者向けのレポートのダウンロードURLを作成して返す
     * @param {string} reportId ダウンロードしたいレポートのID
     * @param {string} reportContentId ダウンロードしたいレポートが持つコンテンツのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportUrl(reportId: string, reportContentId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportUrl(reportId, reportContentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 返されるレポートの件数は30件
     * @summary ブローカーおよびシステム管理者向けのレポートリストを取得する
     * @param {string} [lastReportId] 取得したいレポートリストの直前のID。このID以降のレポートが返される。未指定の場合は、最も新しい公開日のレポートリストが返される。指定されたID以降のデータが無い場合は、空の配列を返す。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReports(lastReportId?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReports(lastReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レポートを更新する
     * @param {string} reportId 
     * @param {string} reportTypes ## レポートの種類。powerやgas、または両方の値が含まれる配列を JSON.stringify() で文字列に変換したもの  ### Example - \\\&quot;[\\\&#39;power\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;gas\\\&#39;]\\\&quot; - \\\&quot;[\\\&#39;power\\\&#39;, \\\&#39;gas\\\&#39;]\\\&quot; 
     * @param {string} reportContents ## レポートコンテンツのオブジェクトを JSON.stringify() で文字列に変換したもの  ### Example:    {     \\\\\\\&quot;ja\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;タイトル\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;詳細\\\\\\\&quot;     },     \\\\\\\&quot;en\\\\\\\&quot;: {       \\\\\\\&quot;title\\\\\\\&quot;:\\\\\\\&quot;title\\\\\\\&quot;,\\\\\\\&quot;description\\\\\\\&quot;:\\\\\\\&quot;detail\\\\\\\&quot;     }   }  ### プロパティ  |プロパティ|説明|例|必須| |--|--|--|--| |title|レポートコンテンツのタイトル|\\\&quot;タイトル\\\&quot;|○| |description|レポートコンテンツの詳細|\\\&quot;詳細\\\&quot;|○| 
     * @param {File} [attachmentFileJa] ## レポートコンテンツの言語がjaの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
     * @param {File} [attachmentFileEn] ## レポートコンテンツの言語がenの添付ファイルのデータ  このデータのファイル名が添付ファイルの名前になる  **添付ファイルの更新が不要な場合はbodyに設定しない** 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateReport(reportId: string, reportTypes: string, reportContents: string, attachmentFileJa?: File, attachmentFileEn?: File, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).updateReport(reportId, reportTypes, reportContents, attachmentFileJa, attachmentFileEn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecialCalendarDatesApi - axios parameter creator
 * @export
 */
export const SpecialCalendarDatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 特殊カレンダー日一覧を取得する
         * @param {string} [calendarTypeId] カレンダー区分ID
         * @param {string} [from] 取得開始日
         * @param {string} [to] 取得終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialCalendarDates: async (calendarTypeId?: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/special-calendar-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:special-calendar-dates"], configuration)

            if (calendarTypeId !== undefined) {
                localVarQueryParameter['calendarTypeId'] = calendarTypeId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialCalendarDatesApi - functional programming interface
 * @export
 */
export const SpecialCalendarDatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialCalendarDatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 特殊カレンダー日一覧を取得する
         * @param {string} [calendarTypeId] カレンダー区分ID
         * @param {string} [from] 取得開始日
         * @param {string} [to] 取得終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialCalendarDates(calendarTypeId?: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialCalendarDate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialCalendarDates(calendarTypeId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecialCalendarDatesApi - factory interface
 * @export
 */
export const SpecialCalendarDatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialCalendarDatesApiFp(configuration)
    return {
        /**
         * 
         * @summary 特殊カレンダー日一覧を取得する
         * @param {string} [calendarTypeId] カレンダー区分ID
         * @param {string} [from] 取得開始日
         * @param {string} [to] 取得終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialCalendarDates(calendarTypeId?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<SpecialCalendarDate>> {
            return localVarFp.getSpecialCalendarDates(calendarTypeId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecialCalendarDatesApi - object-oriented interface
 * @export
 * @class SpecialCalendarDatesApi
 * @extends {BaseAPI}
 */
export class SpecialCalendarDatesApi extends BaseAPI {
    /**
     * 
     * @summary 特殊カレンダー日一覧を取得する
     * @param {string} [calendarTypeId] カレンダー区分ID
     * @param {string} [from] 取得開始日
     * @param {string} [to] 取得終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialCalendarDatesApi
     */
    public getSpecialCalendarDates(calendarTypeId?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return SpecialCalendarDatesApiFp(this.configuration).getSpecialCalendarDates(calendarTypeId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StarredChannelsApi - axios parameter creator
 * @export
 */
export const StarredChannelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザーのスター付きチャンネルを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStarredChannels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/starred-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:starred-channels"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary スター付きチャンネルを登録する
         * @param {StarredChannel} starredChannel 登録するチャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newStarredChannel: async (starredChannel: StarredChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'starredChannel' is not null or undefined
            assertParamExists('newStarredChannel', 'starredChannel', starredChannel)
            const localVarPath = `/chat/starred-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:starred-channels"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(starredChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary スター付きチャンネルを削除する
         * @param {string} channelId スター付きのチャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStarredChannel: async (channelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('removeStarredChannel', 'channelId', channelId)
            const localVarPath = `/chat/starred-channels/{channelId}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["delete:starred-channels"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StarredChannelsApi - functional programming interface
 * @export
 */
export const StarredChannelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StarredChannelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザーのスター付きチャンネルを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStarredChannels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStarredChannels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary スター付きチャンネルを登録する
         * @param {StarredChannel} starredChannel 登録するチャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newStarredChannel(starredChannel: StarredChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newStarredChannel(starredChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary スター付きチャンネルを削除する
         * @param {string} channelId スター付きのチャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeStarredChannel(channelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeStarredChannel(channelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StarredChannelsApi - factory interface
 * @export
 */
export const StarredChannelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StarredChannelsApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザーのスター付きチャンネルを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStarredChannels(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getStarredChannels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary スター付きチャンネルを登録する
         * @param {StarredChannel} starredChannel 登録するチャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newStarredChannel(starredChannel: StarredChannel, options?: any): AxiosPromise<void> {
            return localVarFp.newStarredChannel(starredChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary スター付きチャンネルを削除する
         * @param {string} channelId スター付きのチャンネルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStarredChannel(channelId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeStarredChannel(channelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StarredChannelsApi - object-oriented interface
 * @export
 * @class StarredChannelsApi
 * @extends {BaseAPI}
 */
export class StarredChannelsApi extends BaseAPI {
    /**
     * 
     * @summary ユーザーのスター付きチャンネルを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarredChannelsApi
     */
    public getStarredChannels(options?: AxiosRequestConfig) {
        return StarredChannelsApiFp(this.configuration).getStarredChannels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary スター付きチャンネルを登録する
     * @param {StarredChannel} starredChannel 登録するチャンネルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarredChannelsApi
     */
    public newStarredChannel(starredChannel: StarredChannel, options?: AxiosRequestConfig) {
        return StarredChannelsApiFp(this.configuration).newStarredChannel(starredChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary スター付きチャンネルを削除する
     * @param {string} channelId スター付きのチャンネルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarredChannelsApi
     */
    public removeStarredChannel(channelId: string, options?: AxiosRequestConfig) {
        return StarredChannelsApiFp(this.configuration).removeStarredChannel(channelId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscribersApi - axios parameter creator
 * @export
 */
export const SubscribersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary chatに関連するメッセージのサブスクライバ
         * @param {PubSubMessage} pubSubMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscriberPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscriberPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscriber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BrokerCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage BrokerCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatBrokerCreatedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatBrokerCreatedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/broker-created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OrganizationChannelCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage OrganizationChannelCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatOrganizationChannelCreatedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatOrganizationChannelCreatedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/organization-channel-created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OrganizationCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage OrganizationCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatOrganizationCreatedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatOrganizationCreatedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/organization-created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TraderCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage TraderCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatTraderCreatedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatTraderCreatedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/trader-created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UserCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatUserCreatedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatUserCreatedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/user-created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UserDeletedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserDeletedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatUserDeletedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatUserDeletedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/user-deleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UserUpdatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserUpdatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatUserUpdatedPost: async (pubSubMessage: PubSubMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubMessage' is not null or undefined
            assertParamExists('chatSubscribersChatUserUpdatedPost', 'pubSubMessage', pubSubMessage)
            const localVarPath = `/chat/subscribers/chat/user-updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscribersApi - functional programming interface
 * @export
 */
export const SubscribersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscribersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary chatに関連するメッセージのサブスクライバ
         * @param {PubSubMessage} pubSubMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscriberPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscriberPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BrokerCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage BrokerCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatBrokerCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatBrokerCreatedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OrganizationChannelCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage OrganizationChannelCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatOrganizationChannelCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatOrganizationChannelCreatedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OrganizationCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage OrganizationCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatOrganizationCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatOrganizationCreatedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TraderCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage TraderCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatTraderCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatTraderCreatedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UserCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatUserCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatUserCreatedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UserDeletedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserDeletedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatUserDeletedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatUserDeletedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UserUpdatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserUpdatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatSubscribersChatUserUpdatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatSubscribersChatUserUpdatedPost(pubSubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscribersApi - factory interface
 * @export
 */
export const SubscribersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscribersApiFp(configuration)
    return {
        /**
         * 
         * @summary chatに関連するメッセージのサブスクライバ
         * @param {PubSubMessage} pubSubMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscriberPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscriberPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BrokerCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage BrokerCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatBrokerCreatedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatBrokerCreatedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OrganizationChannelCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage OrganizationChannelCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatOrganizationChannelCreatedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatOrganizationChannelCreatedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OrganizationCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage OrganizationCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatOrganizationCreatedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatOrganizationCreatedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TraderCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage TraderCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatTraderCreatedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatTraderCreatedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UserCreatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserCreatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatUserCreatedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatUserCreatedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UserDeletedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserDeletedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatUserDeletedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatUserDeletedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UserUpdatedEventのサブスクライバ
         * @param {PubSubMessage} pubSubMessage UserUpdatedEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatSubscribersChatUserUpdatedPost(pubSubMessage: PubSubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.chatSubscribersChatUserUpdatedPost(pubSubMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscribersApi - object-oriented interface
 * @export
 * @class SubscribersApi
 * @extends {BaseAPI}
 */
export class SubscribersApi extends BaseAPI {
    /**
     * 
     * @summary chatに関連するメッセージのサブスクライバ
     * @param {PubSubMessage} pubSubMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscriberPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscriberPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BrokerCreatedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage BrokerCreatedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatBrokerCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatBrokerCreatedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OrganizationChannelCreatedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage OrganizationChannelCreatedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatOrganizationChannelCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatOrganizationChannelCreatedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OrganizationCreatedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage OrganizationCreatedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatOrganizationCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatOrganizationCreatedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TraderCreatedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage TraderCreatedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatTraderCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatTraderCreatedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UserCreatedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage UserCreatedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatUserCreatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatUserCreatedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UserDeletedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage UserDeletedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatUserDeletedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatUserDeletedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UserUpdatedEventのサブスクライバ
     * @param {PubSubMessage} pubSubMessage UserUpdatedEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public chatSubscribersChatUserUpdatedPost(pubSubMessage: PubSubMessage, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).chatSubscribersChatUserUpdatedPost(pubSubMessage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザーをチャンネルに参加させるタスク
         * @param {AddUserToChannelTask} addUserToChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksAddUserToChannelPost: async (addUserToChannelTask: AddUserToChannelTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addUserToChannelTask' is not null or undefined
            assertParamExists('chatTasksAddUserToChannelPost', 'addUserToChannelTask', addUserToChannelTask)
            const localVarPath = `/chat/tasks/add-user-to-channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToChannelTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メッセージが追加イベントのタスク
         * @param {MessageAdded} messageAdded タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksMessageAddedPost: async (messageAdded: MessageAdded, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageAdded' is not null or undefined
            assertParamExists('chatTasksMessageAddedPost', 'messageAdded', messageAdded)
            const localVarPath = `/chat/tasks/message-added`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageAdded, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DMチャンネルを作成し、ユーザーを参加させるタスク
         * @param {NewDMChannelTask} newDMChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksNewDmChannelPost: async (newDMChannelTask: NewDMChannelTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newDMChannelTask' is not null or undefined
            assertParamExists('chatTasksNewDmChannelPost', 'newDMChannelTask', newDMChannelTask)
            const localVarPath = `/chat/tasks/new-dm-channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newDMChannelTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーをチャンネルから退出させるタスク
         * @param {RemoveUserFromChannelTask} removeUserFromChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksRemoveUserFromChannelPost: async (removeUserFromChannelTask: RemoveUserFromChannelTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeUserFromChannelTask' is not null or undefined
            assertParamExists('chatTasksRemoveUserFromChannelPost', 'removeUserFromChannelTask', removeUserFromChannelTask)
            const localVarPath = `/chat/tasks/remove-user-from-channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserFromChannelTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チャットの一斉送信の処理を行うタスク
         * @param {SaveBroadcastMessageIndex} saveBroadcastMessageIndex タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksSaveBroadcastMessageIndexPost: async (saveBroadcastMessageIndex: SaveBroadcastMessageIndex, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveBroadcastMessageIndex' is not null or undefined
            assertParamExists('chatTasksSaveBroadcastMessageIndexPost', 'saveBroadcastMessageIndex', saveBroadcastMessageIndex)
            const localVarPath = `/chat/tasks/save-broadcast-message-index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveBroadcastMessageIndex, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 最後に読んだメッセージのindexを更新するタスク
         * @param {UpdateLastReadMessageIndexTask} updateLastReadMessageIndexTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksUpdateLastReadMessageIndexPost: async (updateLastReadMessageIndexTask: UpdateLastReadMessageIndexTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLastReadMessageIndexTask' is not null or undefined
            assertParamExists('chatTasksUpdateLastReadMessageIndexPost', 'updateLastReadMessageIndexTask', updateLastReadMessageIndexTask)
            const localVarPath = `/chat/tasks/update-last-read-message-index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLastReadMessageIndexTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザーをチャンネルに参加させるタスク
         * @param {AddUserToChannelTask} addUserToChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTasksAddUserToChannelPost(addUserToChannelTask: AddUserToChannelTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTasksAddUserToChannelPost(addUserToChannelTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メッセージが追加イベントのタスク
         * @param {MessageAdded} messageAdded タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTasksMessageAddedPost(messageAdded: MessageAdded, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTasksMessageAddedPost(messageAdded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DMチャンネルを作成し、ユーザーを参加させるタスク
         * @param {NewDMChannelTask} newDMChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTasksNewDmChannelPost(newDMChannelTask: NewDMChannelTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTasksNewDmChannelPost(newDMChannelTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーをチャンネルから退出させるタスク
         * @param {RemoveUserFromChannelTask} removeUserFromChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTasksRemoveUserFromChannelPost(removeUserFromChannelTask: RemoveUserFromChannelTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTasksRemoveUserFromChannelPost(removeUserFromChannelTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チャットの一斉送信の処理を行うタスク
         * @param {SaveBroadcastMessageIndex} saveBroadcastMessageIndex タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTasksSaveBroadcastMessageIndexPost(saveBroadcastMessageIndex: SaveBroadcastMessageIndex, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTasksSaveBroadcastMessageIndexPost(saveBroadcastMessageIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 最後に読んだメッセージのindexを更新するタスク
         * @param {UpdateLastReadMessageIndexTask} updateLastReadMessageIndexTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTasksUpdateLastReadMessageIndexPost(updateLastReadMessageIndexTask: UpdateLastReadMessageIndexTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTasksUpdateLastReadMessageIndexPost(updateLastReadMessageIndexTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザーをチャンネルに参加させるタスク
         * @param {AddUserToChannelTask} addUserToChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksAddUserToChannelPost(addUserToChannelTask: AddUserToChannelTask, options?: any): AxiosPromise<void> {
            return localVarFp.chatTasksAddUserToChannelPost(addUserToChannelTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メッセージが追加イベントのタスク
         * @param {MessageAdded} messageAdded タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksMessageAddedPost(messageAdded: MessageAdded, options?: any): AxiosPromise<void> {
            return localVarFp.chatTasksMessageAddedPost(messageAdded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DMチャンネルを作成し、ユーザーを参加させるタスク
         * @param {NewDMChannelTask} newDMChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksNewDmChannelPost(newDMChannelTask: NewDMChannelTask, options?: any): AxiosPromise<void> {
            return localVarFp.chatTasksNewDmChannelPost(newDMChannelTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーをチャンネルから退出させるタスク
         * @param {RemoveUserFromChannelTask} removeUserFromChannelTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksRemoveUserFromChannelPost(removeUserFromChannelTask: RemoveUserFromChannelTask, options?: any): AxiosPromise<void> {
            return localVarFp.chatTasksRemoveUserFromChannelPost(removeUserFromChannelTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チャットの一斉送信の処理を行うタスク
         * @param {SaveBroadcastMessageIndex} saveBroadcastMessageIndex タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksSaveBroadcastMessageIndexPost(saveBroadcastMessageIndex: SaveBroadcastMessageIndex, options?: any): AxiosPromise<void> {
            return localVarFp.chatTasksSaveBroadcastMessageIndexPost(saveBroadcastMessageIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 最後に読んだメッセージのindexを更新するタスク
         * @param {UpdateLastReadMessageIndexTask} updateLastReadMessageIndexTask タスクのペイロード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTasksUpdateLastReadMessageIndexPost(updateLastReadMessageIndexTask: UpdateLastReadMessageIndexTask, options?: any): AxiosPromise<void> {
            return localVarFp.chatTasksUpdateLastReadMessageIndexPost(updateLastReadMessageIndexTask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary ユーザーをチャンネルに参加させるタスク
     * @param {AddUserToChannelTask} addUserToChannelTask タスクのペイロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public chatTasksAddUserToChannelPost(addUserToChannelTask: AddUserToChannelTask, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).chatTasksAddUserToChannelPost(addUserToChannelTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メッセージが追加イベントのタスク
     * @param {MessageAdded} messageAdded タスクのペイロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public chatTasksMessageAddedPost(messageAdded: MessageAdded, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).chatTasksMessageAddedPost(messageAdded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DMチャンネルを作成し、ユーザーを参加させるタスク
     * @param {NewDMChannelTask} newDMChannelTask タスクのペイロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public chatTasksNewDmChannelPost(newDMChannelTask: NewDMChannelTask, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).chatTasksNewDmChannelPost(newDMChannelTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーをチャンネルから退出させるタスク
     * @param {RemoveUserFromChannelTask} removeUserFromChannelTask タスクのペイロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public chatTasksRemoveUserFromChannelPost(removeUserFromChannelTask: RemoveUserFromChannelTask, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).chatTasksRemoveUserFromChannelPost(removeUserFromChannelTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チャットの一斉送信の処理を行うタスク
     * @param {SaveBroadcastMessageIndex} saveBroadcastMessageIndex タスクのペイロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public chatTasksSaveBroadcastMessageIndexPost(saveBroadcastMessageIndex: SaveBroadcastMessageIndex, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).chatTasksSaveBroadcastMessageIndexPost(saveBroadcastMessageIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 最後に読んだメッセージのindexを更新するタスク
     * @param {UpdateLastReadMessageIndexTask} updateLastReadMessageIndexTask タスクのペイロード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public chatTasksUpdateLastReadMessageIndexPost(updateLastReadMessageIndexTask: UpdateLastReadMessageIndexTask, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).chatTasksUpdateLastReadMessageIndexPost(updateLastReadMessageIndexTask, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TradesApi - axios parameter creator
 * @export
 */
export const TradesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 取引を新規作成する
         * @param {NewTrade} newTrade 新規取引オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTrade: async (newTrade: NewTrade, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newTrade' is not null or undefined
            assertParamExists('newTrade', 'newTrade', newTrade)
            const localVarPath = `/trading/trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["create:indications"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTrade, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradesApi - functional programming interface
 * @export
 */
export const TradesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 取引を新規作成する
         * @param {NewTrade} newTrade 新規取引オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newTrade(newTrade: NewTrade, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewTradeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newTrade(newTrade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TradesApi - factory interface
 * @export
 */
export const TradesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradesApiFp(configuration)
    return {
        /**
         * 
         * @summary 取引を新規作成する
         * @param {NewTrade} newTrade 新規取引オブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTrade(newTrade: NewTrade, options?: any): AxiosPromise<NewTradeResult> {
            return localVarFp.newTrade(newTrade, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradesApi - object-oriented interface
 * @export
 * @class TradesApi
 * @extends {BaseAPI}
 */
export class TradesApi extends BaseAPI {
    /**
     * 
     * @summary 取引を新規作成する
     * @param {NewTrade} newTrade 新規取引オブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradesApi
     */
    public newTrade(newTrade: NewTrade, options?: AxiosRequestConfig) {
        return TradesApiFp(this.configuration).newTrade(newTrade, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザーのステータスをactiveにする
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('activateUser', 'userId', userId)
            const localVarPath = `/iam/users/{userId}/active`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーの写真を削除する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePictureOfUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deletePictureOfUser', 'userId', userId)
            const localVarPath = `/iam/users/{userId}/picture`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:user-profiles"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーの写真を取得する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPictureOfUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPictureOfUser', 'userId', userId)
            const localVarPath = `/iam/users/{userId}/picture`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:user-profiles"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザープロファイルをIDで取得する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserProfileById', 'userId', userId)
            const localVarPath = `/iam/users/{userId}/profile`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:user-profiles"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを取得する
         * @param {string} [userId] ユーザーID
         * @param {Array<string>} [userIds] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {string} [organizationId] 組織Id
         * @param {string} [userType] ユーザーの種類
         * @param {string} [status] ユーザーのステータス
         * @param {string} [isTrader] eClearのトレーダーか?
         * @param {string} [isEClearContracted] eClear契約締結済みか?
         * @param {string} [receiveIndicationEmail] 注文に関するメールを受信するか?
         * @param {string} [receiveLiveMarketEmail] ライブマーケット(至近のマーケット状況の共有)に関するメールを受け取るか?
         * @param {string} [receiveDerivativeTradingRecapEmail] デリバティブ取引の確認書メールを受信するか?
         * @param {string} [receiveSpotTradingRecapEmail] 現物取引の確認書メールを受信するか?
         * @param {string} [receiveReportEmail] レポートメールを受信するか?
         * @param {string} [receiveInvoiceEmail] 請求書メールを受信するか?
         * @param {string} [receiveDailyDealSummaryEmail] 日次取引サマリーメールを受け取るか?
         * @param {number} [page] ページ番号
         * @param {number} [perPage] ページ内ユーザ数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (userId?: string, userIds?: Array<string>, organizationId?: string, userType?: string, status?: string, isTrader?: string, isEClearContracted?: string, receiveIndicationEmail?: string, receiveLiveMarketEmail?: string, receiveDerivativeTradingRecapEmail?: string, receiveSpotTradingRecapEmail?: string, receiveReportEmail?: string, receiveInvoiceEmail?: string, receiveDailyDealSummaryEmail?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/iam/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:users"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isTrader !== undefined) {
                localVarQueryParameter['isTrader'] = isTrader;
            }

            if (isEClearContracted !== undefined) {
                localVarQueryParameter['isEClearContracted'] = isEClearContracted;
            }

            if (receiveIndicationEmail !== undefined) {
                localVarQueryParameter['receiveIndicationEmail'] = receiveIndicationEmail;
            }

            if (receiveLiveMarketEmail !== undefined) {
                localVarQueryParameter['receiveLiveMarketEmail'] = receiveLiveMarketEmail;
            }

            if (receiveDerivativeTradingRecapEmail !== undefined) {
                localVarQueryParameter['receiveDerivativeTradingRecapEmail'] = receiveDerivativeTradingRecapEmail;
            }

            if (receiveSpotTradingRecapEmail !== undefined) {
                localVarQueryParameter['receiveSpotTradingRecapEmail'] = receiveSpotTradingRecapEmail;
            }

            if (receiveReportEmail !== undefined) {
                localVarQueryParameter['receiveReportEmail'] = receiveReportEmail;
            }

            if (receiveInvoiceEmail !== undefined) {
                localVarQueryParameter['receiveInvoiceEmail'] = receiveInvoiceEmail;
            }

            if (receiveDailyDealSummaryEmail !== undefined) {
                localVarQueryParameter['receiveDailyDealSummaryEmail'] = receiveDailyDealSummaryEmail;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーの写真を変更する
         * @param {string} userId ユーザーID
         * @param {File} body 写真のバイナリデータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePictureOfUser: async (userId: string, body: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePictureOfUser', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePictureOfUser', 'body', body)
            const localVarPath = `/iam/users/{userId}/picture`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:user-profiles"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary システム管理者またはブローカーがユーザーの情報を変更する。プロダクトのUserSettingに加えてAppSettingも変更できる。
         * @param {string} userId ユーザーID
         * @param {UpdateUser} updateUser ユーザーのオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUser: UpdateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('updateUser', 'updateUser', updateUser)
            const localVarPath = `/iam/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザープロファイルを変更する
         * @param {string} userId ユーザーID
         * @param {UpdateUserProfile} updateUserProfile foo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileById: async (userId: string, updateUserProfile: UpdateUserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserProfileById', 'userId', userId)
            // verify required parameter 'updateUserProfile' is not null or undefined
            assertParamExists('updateUserProfileById', 'updateUserProfile', updateUserProfile)
            const localVarPath = `/iam/users/{userId}/profile`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["update:user-profiles"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーのeClear確認キーを検証する
         * @param {string} userId ユーザーID
         * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEClearKeyword: async (userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('validateEClearKeyword', 'userId', userId)
            // verify required parameter 'validateEClearKeywordRequest' is not null or undefined
            assertParamExists('validateEClearKeyword', 'validateEClearKeywordRequest', validateEClearKeywordRequest)
            const localVarPath = `/iam/users/{userId}/eclear-keyword/validate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", ["read:users"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEClearKeywordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザーのステータスをactiveにする
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーの写真を削除する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePictureOfUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePictureOfUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーの写真を取得する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPictureOfUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPictureOfUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザープロファイルをIDで取得する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfileById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfileById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを取得する
         * @param {string} [userId] ユーザーID
         * @param {Array<string>} [userIds] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {string} [organizationId] 組織Id
         * @param {string} [userType] ユーザーの種類
         * @param {string} [status] ユーザーのステータス
         * @param {string} [isTrader] eClearのトレーダーか?
         * @param {string} [isEClearContracted] eClear契約締結済みか?
         * @param {string} [receiveIndicationEmail] 注文に関するメールを受信するか?
         * @param {string} [receiveLiveMarketEmail] ライブマーケット(至近のマーケット状況の共有)に関するメールを受け取るか?
         * @param {string} [receiveDerivativeTradingRecapEmail] デリバティブ取引の確認書メールを受信するか?
         * @param {string} [receiveSpotTradingRecapEmail] 現物取引の確認書メールを受信するか?
         * @param {string} [receiveReportEmail] レポートメールを受信するか?
         * @param {string} [receiveInvoiceEmail] 請求書メールを受信するか?
         * @param {string} [receiveDailyDealSummaryEmail] 日次取引サマリーメールを受け取るか?
         * @param {number} [page] ページ番号
         * @param {number} [perPage] ページ内ユーザ数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(userId?: string, userIds?: Array<string>, organizationId?: string, userType?: string, status?: string, isTrader?: string, isEClearContracted?: string, receiveIndicationEmail?: string, receiveLiveMarketEmail?: string, receiveDerivativeTradingRecapEmail?: string, receiveSpotTradingRecapEmail?: string, receiveReportEmail?: string, receiveInvoiceEmail?: string, receiveDailyDealSummaryEmail?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(userId, userIds, organizationId, userType, status, isTrader, isEClearContracted, receiveIndicationEmail, receiveLiveMarketEmail, receiveDerivativeTradingRecapEmail, receiveSpotTradingRecapEmail, receiveReportEmail, receiveInvoiceEmail, receiveDailyDealSummaryEmail, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーの写真を変更する
         * @param {string} userId ユーザーID
         * @param {File} body 写真のバイナリデータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePictureOfUser(userId: string, body: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePictureOfUser(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary システム管理者またはブローカーがユーザーの情報を変更する。プロダクトのUserSettingに加えてAppSettingも変更できる。
         * @param {string} userId ユーザーID
         * @param {UpdateUser} updateUser ユーザーのオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUser: UpdateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザープロファイルを変更する
         * @param {string} userId ユーザーID
         * @param {UpdateUserProfile} updateUserProfile foo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileById(userId: string, updateUserProfile: UpdateUserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileById(userId, updateUserProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーのeClear確認キーを検証する
         * @param {string} userId ユーザーID
         * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEClearKeyword(userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateEClearKeywordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEClearKeyword(userId, validateEClearKeywordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザーのステータスをactiveにする
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(userId: string, options?: any): AxiosPromise<UserProfile> {
            return localVarFp.activateUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーの写真を削除する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePictureOfUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePictureOfUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーの写真を取得する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPictureOfUser(userId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getPictureOfUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザープロファイルをIDで取得する
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileById(userId: string, options?: any): AxiosPromise<UserProfile> {
            return localVarFp.getUserProfileById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを取得する
         * @param {string} [userId] ユーザーID
         * @param {Array<string>} [userIds] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
         * @param {string} [organizationId] 組織Id
         * @param {string} [userType] ユーザーの種類
         * @param {string} [status] ユーザーのステータス
         * @param {string} [isTrader] eClearのトレーダーか?
         * @param {string} [isEClearContracted] eClear契約締結済みか?
         * @param {string} [receiveIndicationEmail] 注文に関するメールを受信するか?
         * @param {string} [receiveLiveMarketEmail] ライブマーケット(至近のマーケット状況の共有)に関するメールを受け取るか?
         * @param {string} [receiveDerivativeTradingRecapEmail] デリバティブ取引の確認書メールを受信するか?
         * @param {string} [receiveSpotTradingRecapEmail] 現物取引の確認書メールを受信するか?
         * @param {string} [receiveReportEmail] レポートメールを受信するか?
         * @param {string} [receiveInvoiceEmail] 請求書メールを受信するか?
         * @param {string} [receiveDailyDealSummaryEmail] 日次取引サマリーメールを受け取るか?
         * @param {number} [page] ページ番号
         * @param {number} [perPage] ページ内ユーザ数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(userId?: string, userIds?: Array<string>, organizationId?: string, userType?: string, status?: string, isTrader?: string, isEClearContracted?: string, receiveIndicationEmail?: string, receiveLiveMarketEmail?: string, receiveDerivativeTradingRecapEmail?: string, receiveSpotTradingRecapEmail?: string, receiveReportEmail?: string, receiveInvoiceEmail?: string, receiveDailyDealSummaryEmail?: string, page?: number, perPage?: number, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(userId, userIds, organizationId, userType, status, isTrader, isEClearContracted, receiveIndicationEmail, receiveLiveMarketEmail, receiveDerivativeTradingRecapEmail, receiveSpotTradingRecapEmail, receiveReportEmail, receiveInvoiceEmail, receiveDailyDealSummaryEmail, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーの写真を変更する
         * @param {string} userId ユーザーID
         * @param {File} body 写真のバイナリデータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePictureOfUser(userId: string, body: File, options?: any): AxiosPromise<void> {
            return localVarFp.updatePictureOfUser(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary システム管理者またはブローカーがユーザーの情報を変更する。プロダクトのUserSettingに加えてAppSettingも変更できる。
         * @param {string} userId ユーザーID
         * @param {UpdateUser} updateUser ユーザーのオブジェクト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUser: UpdateUser, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userId, updateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザープロファイルを変更する
         * @param {string} userId ユーザーID
         * @param {UpdateUserProfile} updateUserProfile foo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileById(userId: string, updateUserProfile: UpdateUserProfile, options?: any): AxiosPromise<UserProfile> {
            return localVarFp.updateUserProfileById(userId, updateUserProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーのeClear確認キーを検証する
         * @param {string} userId ユーザーID
         * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEClearKeyword(userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options?: any): AxiosPromise<ValidateEClearKeywordResponse> {
            return localVarFp.validateEClearKeyword(userId, validateEClearKeywordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary ユーザーのステータスをactiveにする
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public activateUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).activateUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーの写真を削除する
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deletePictureOfUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deletePictureOfUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーの写真を取得する
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPictureOfUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPictureOfUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザープロファイルをIDで取得する
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserProfileById(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserProfileById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを取得する
     * @param {string} [userId] ユーザーID
     * @param {Array<string>} [userIds] ユーザーIDのリスト。2件以上取得する場合はこちらのパラメータを利用する。クエリの長さの制限があるので、20ぐらいまで指定できる
     * @param {string} [organizationId] 組織Id
     * @param {string} [userType] ユーザーの種類
     * @param {string} [status] ユーザーのステータス
     * @param {string} [isTrader] eClearのトレーダーか?
     * @param {string} [isEClearContracted] eClear契約締結済みか?
     * @param {string} [receiveIndicationEmail] 注文に関するメールを受信するか?
     * @param {string} [receiveLiveMarketEmail] ライブマーケット(至近のマーケット状況の共有)に関するメールを受け取るか?
     * @param {string} [receiveDerivativeTradingRecapEmail] デリバティブ取引の確認書メールを受信するか?
     * @param {string} [receiveSpotTradingRecapEmail] 現物取引の確認書メールを受信するか?
     * @param {string} [receiveReportEmail] レポートメールを受信するか?
     * @param {string} [receiveInvoiceEmail] 請求書メールを受信するか?
     * @param {string} [receiveDailyDealSummaryEmail] 日次取引サマリーメールを受け取るか?
     * @param {number} [page] ページ番号
     * @param {number} [perPage] ページ内ユーザ数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(userId?: string, userIds?: Array<string>, organizationId?: string, userType?: string, status?: string, isTrader?: string, isEClearContracted?: string, receiveIndicationEmail?: string, receiveLiveMarketEmail?: string, receiveDerivativeTradingRecapEmail?: string, receiveSpotTradingRecapEmail?: string, receiveReportEmail?: string, receiveInvoiceEmail?: string, receiveDailyDealSummaryEmail?: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(userId, userIds, organizationId, userType, status, isTrader, isEClearContracted, receiveIndicationEmail, receiveLiveMarketEmail, receiveDerivativeTradingRecapEmail, receiveSpotTradingRecapEmail, receiveReportEmail, receiveInvoiceEmail, receiveDailyDealSummaryEmail, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーの写真を変更する
     * @param {string} userId ユーザーID
     * @param {File} body 写真のバイナリデータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updatePictureOfUser(userId: string, body: File, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updatePictureOfUser(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary システム管理者またはブローカーがユーザーの情報を変更する。プロダクトのUserSettingに加えてAppSettingも変更できる。
     * @param {string} userId ユーザーID
     * @param {UpdateUser} updateUser ユーザーのオブジェクト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, updateUser: UpdateUser, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userId, updateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザープロファイルを変更する
     * @param {string} userId ユーザーID
     * @param {UpdateUserProfile} updateUserProfile foo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserProfileById(userId: string, updateUserProfile: UpdateUserProfile, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserProfileById(userId, updateUserProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーのeClear確認キーを検証する
     * @param {string} userId ユーザーID
     * @param {ValidateEClearKeywordRequest} validateEClearKeywordRequest 検証する確認キー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validateEClearKeyword(userId: string, validateEClearKeywordRequest: ValidateEClearKeywordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).validateEClearKeyword(userId, validateEClearKeywordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhookApi - axios parameter creator
 * @export
 */
export const WebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary TwilioのPostHook用エンドポイント
         * @param {PostHookPayload} postHookPayload WebHook payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTwilioConversationsPostHookPost: async (postHookPayload: PostHookPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postHookPayload' is not null or undefined
            assertParamExists('chatTwilioConversationsPostHookPost', 'postHookPayload', postHookPayload)
            const localVarPath = `/chat/twilio/conversations/post-hook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postHookPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookApi - functional programming interface
 * @export
 */
export const WebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary TwilioのPostHook用エンドポイント
         * @param {PostHookPayload} postHookPayload WebHook payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatTwilioConversationsPostHookPost(postHookPayload: PostHookPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatTwilioConversationsPostHookPost(postHookPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhookApi - factory interface
 * @export
 */
export const WebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary TwilioのPostHook用エンドポイント
         * @param {PostHookPayload} postHookPayload WebHook payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatTwilioConversationsPostHookPost(postHookPayload: PostHookPayload, options?: any): AxiosPromise<void> {
            return localVarFp.chatTwilioConversationsPostHookPost(postHookPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
export class WebhookApi extends BaseAPI {
    /**
     * 
     * @summary TwilioのPostHook用エンドポイント
     * @param {PostHookPayload} postHookPayload WebHook payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public chatTwilioConversationsPostHookPost(postHookPayload: PostHookPayload, options?: AxiosRequestConfig) {
        return WebhookApiFp(this.configuration).chatTwilioConversationsPostHookPost(postHookPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


