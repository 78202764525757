import { TOCOMBrokerValue } from '@/api/generated/api'
export class TocomBroker implements TOCOMBrokerValue {
  name!: string

  offFloorTradingCode!: string

  get displayName(): string {
    return `${this.name} (${this.offFloorTradingCode})`
  }
}
