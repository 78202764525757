import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-218f9a90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broking-order-item__item" }
const _hoisted_2 = ["checked", "disabled"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_type = _resolveComponent("product-type")!
  const _component_broking_indication_status = _resolveComponent("broking-indication-status")!
  const _component_order_price = _resolveComponent("order-price")!
  const _component_action_dropdown = _resolveComponent("action-dropdown")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createBlock(_component_base_tooltip, {
    label: _ctx.tooltipLabel,
    class: "broking-order-item"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_product_type, {
          name: _ctx.order.productType.name
        }, null, 8, ["name"]),
        _createElementVNode("label", {
          class: _normalizeClass(["broking-order-item__organization-name-wrapper", {
          'broking-order-item____organization-name-wrapper--not-open-to-market': !_ctx.order.openToMarket,
        }]),
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("input", {
            checked: _ctx.selected,
            type: "checkbox",
            class: "broking-order-book-detail-item__checkbox checkbox",
            disabled: _ctx.order.executedAt,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRecordSelect && _ctx.onRecordSelect(...args)))
          }, null, 40, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(["broking-order-item__organization-name", {
            'broking-order-item__organization-name--not-open-to-market': !_ctx.order.openToMarket,
          }]),
            title: _ctx.order.organizationName
          }, _toDisplayString(_ctx.displayOrganizationName(_ctx.order)), 11, _hoisted_3)
        ], 2),
        _createElementVNode("div", {
          class: "broking-order-item__created-participant-name",
          title: _ctx.order.createdParticipantName
        }, _toDisplayString(_ctx.order.createdParticipantName), 9, _hoisted_4),
        _createElementVNode("div", null, [
          (_ctx.order.status)
            ? (_openBlock(), _createBlock(_component_broking_indication_status, {
                key: 0,
                status: _ctx.order.status
              }, null, 8, ["status"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5))
        ]),
        _createVNode(_component_order_price, {
          order: _ctx.order,
          "color-reversed": _ctx.colorReversed,
          "position-reversed": _ctx.positionReversed
        }, null, 8, ["order", "color-reversed", "position-reversed"]),
        (!_ctx.order.isLast)
          ? (_openBlock(), _createBlock(_component_action_dropdown, {
              key: 0,
              id: _ctx.order.indicationId,
              class: "broking-order-item__action-dropdown",
              actions: _ctx.actions,
              "is-active": _ctx.isActionDropdownActive,
              "is-right": "",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isActionDropdownActive = !_ctx.isActionDropdownActive), ["stop"])),
              onClickOutside: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isActionDropdownActive = false)),
              onToDetail: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onOpenDetailClick(_ctx.order.indicationId))),
              onCopyIndication: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCopyIndication(_ctx.order.indicationId)))
            }, null, 8, ["id", "actions", "is-active"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["label"]))
}