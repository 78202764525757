import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-242cfbc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "localized-report-list-item__inner" }
const _hoisted_2 = { class: "localized-report-list-item__header" }
const _hoisted_3 = { class: "localized-report-list-item__date" }
const _hoisted_4 = ["datetime"]
const _hoisted_5 = { class: "localized-report-list-item__summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading = _resolveComponent("heading")!
  const _component_sentences = _resolveComponent("sentences")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _component_base_box = _resolveComponent("base-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: "localized-report-list-item",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onItemClick && _ctx.onItemClick(...args)))
  }, [
    _createVNode(_component_base_box, { spacing: "0" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_heading, {
              type: "header4",
              class: "localized-report-list-item__title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.report.reportContent.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_sentences, { type: "text-small" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_3, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('reports.label.publishedAt')) + ":", 1),
                  _createElementVNode("time", {
                    datetime: _ctx.report.publishedAt
                  }, _toDisplayString(_ctx.report.displayPublishedAt()), 9, _hoisted_4)
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", {
              class: "localized-report-list-item__download",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDownloadClick && _ctx.onDownloadClick(...args)), ["stop"]))
            }, [
              _createVNode(_component_base_tooltip, {
                label: _ctx.report.reportContent.attachmentName,
                direction: "left"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_icon, {
                    "icon-name": "file-download",
                    class: "localized-report-list-item__download__icon"
                  })
                ]),
                _: 1
              }, 8, ["label"])
            ])
          ]),
          _createVNode(_component_sentences, { type: "text-medium" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.report.reportContent.removedMarkdownSyntaxDescription()), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}