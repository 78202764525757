import { Mutations } from 'vuex-smart-module'

import { MultiLocaleName } from '@/models/common/MultiLocaleName'
import { GlobalState } from '@/store/modules/common/global/GlobalState'

export const SET_IS_MENU_COLLAPSED = 'SET_IS_MENU_COLLAPSED'
export const SET_IS_INITIALIZING = 'SET_IS_INITIALIZING'
export const SET_ORGANIZATION_PROFILE = 'SET_ORGANIZATION_PROFILE'

export class GlobalMutations extends Mutations<GlobalState> {
  [SET_IS_MENU_COLLAPSED](payload: { isMenuCollapsed: boolean }) {
    this.state.isMenuCollapsed = payload.isMenuCollapsed
  }

  [SET_IS_INITIALIZING](payload: { isInitializing: boolean }) {
    this.state.isInitializing = payload.isInitializing
  }

  [SET_ORGANIZATION_PROFILE](payload: {
    organizationName: MultiLocaleName
    organizationPicture: string
  }) {
    this.state.organizationName = payload.organizationName
    this.state.organizationPicture = payload.organizationPicture
  }
}
