import {
  DeliveryUnit as DeliveryUnitEnum,
  OrganizationType,
} from '@/api/generated'
import { InputOption } from '@/components/common/interface/InputOption'
import { i18n } from '@/i18n'

export class DeliveryUnitOptions {
  private options: InputOption[] = [
    {
      value: DeliveryUnitEnum.Month,
      label: i18n.t('trading.label.deliveryUnitType.month').toString(),
    },
    {
      value: DeliveryUnitEnum.WeekOrDay,
      label: i18n.t('trading.label.deliveryUnitType.weekOrDay').toString(),
    },
    {
      value: DeliveryUnitEnum.NonStandard,
      label: i18n.t('trading.label.deliveryUnitType.nonStandard').toString(),
    },
  ]

  getOptions(type: OrganizationType): InputOption[] {
    return type === OrganizationType.Broker
      ? this.options
      : this.options.filter(
          option => option.value !== DeliveryUnitEnum.NonStandard,
        )
  }

  label(deliveryUnit: DeliveryUnitEnum): string {
    const option = this.options.find(option => option.value === deliveryUnit)
    return option ? option.label : ''
  }
}
