import sanitizeHtml from 'sanitize-html'

import { ReportContent as IReportContent, Locale } from '@/api/generated'
import marked from '@/plugins/marked'

export class ReportContent implements IReportContent {
  id!: string
  locale!: Locale
  title!: string
  description!: string
  attachmentName!: string

  removedMarkdownSyntaxDescription(): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(marked(this.description), 'text/html')
    return doc.documentElement.innerText
  }

  descriptionHtml(): string {
    return sanitizeHtml(marked(this.description))
  }
}
