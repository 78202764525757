
import { Field } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { HeaderLevel } from '@/components/common/constants/TypographyLevel'
import { InputOption } from '@/components/common/interface/InputOption'
import OrganizationSelector from '@/components/iam/OrganizationSelector.vue'
import AccountSelector from '@/components/trading/AccountSelector.vue'
import DeliveryDateForm from '@/components/trading/DeliveryDateForm.vue'
import FieldBrokingFee from '@/components/trading/FieldBrokingFee.vue'
import TraderSelector from '@/components/trading/TraderSelector.vue'
import { EEXPostingFormInputMode } from '@/components/trading/constants/EEXPostingFormInputMode'
import { DeliveryDateFormProps } from '@/components/trading/interface/DeliveryDateFormProps'
import { EEXPostingProps } from '@/components/trading/interface/EEXPostingProps'
import { moment } from '@/utils/date'

export default defineComponent({
  name: 'EEXPostingForm',
  components: {
    AccountSelector,
    BaseBox,
    BaseIcon,
    BaseInputText,
    BaseLabel,
    BaseSingleSelect,
    BaseTooltip,
    DeliveryDateForm,
    Field,
    FieldBrokingFee,
    Heading,
    OrganizationSelector,
    TraderSelector,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: EEXPostingFormInputMode) => {
        return [
          EEXPostingFormInputMode.NEW,
          EEXPostingFormInputMode.CONFIRM,
          EEXPostingFormInputMode.REPOST,
        ].includes(value)
      },
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    formValue: {
      type: Object as PropType<EEXPostingProps>,
      required: true,
    },
  },
  emits: ['form-input'],
  computed: {
    formInputEventName: () => 'form-input',
    headerLevel3: () => HeaderLevel.Lv3,
    deliveryDateFormValue(): DeliveryDateFormProps {
      return { ...this.formValue }
    },
    inputMode(): InputMode {
      return [
        EEXPostingFormInputMode.NEW,
        EEXPostingFormInputMode.REPOST,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    isReadonly(): boolean {
      return this.inputMode === InputMode.READONLY
    },
    needToUseDeliveryDateForm(): boolean {
      return [
        EEXPostingFormInputMode.NEW,
        EEXPostingFormInputMode.REPOST,
        EEXPostingFormInputMode.CONFIRM,
      ].includes(this.mode)
    },
    readonly(): number {
      return InputMode.READONLY
    },
    productCodeInputOptions(): InputOption[] {
      return [
        { value: 'TABF', label: 'Tokyo Area Base Future' },
        { value: 'TAPF', label: 'Tokyo Area Peak Future' },
        { value: 'KABF', label: 'Kansai Area Base Future' },
        { value: 'KAPF', label: 'Kansai Area Peak Future' },
      ]
    },
    selectedProductCodeAsInputOption(): InputOption | undefined {
      return this.productCodeInputOptions.find(
        ({ value }) => this.formValue.productCode === value,
      )
    },
    startDeliveryMaxDate(): Date | undefined {
      return this.formValue.endDeliveryDate
        ? moment(this.formValue.endDeliveryDate).toDate()
        : undefined
    },
    endDeliveryMinDate(): Date | undefined {
      return this.formValue.startDeliveryDate
        ? moment(this.formValue.startDeliveryDate).toDate()
        : undefined
    },
  },
  methods: {
    onPriceInput(event: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        price: event,
      })
    },
    onQuantityInput(event: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        quantity: event,
      })
    },
    onProductCodeInput(event: InputOption | null) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        productCode: event?.value ?? undefined,
      })
    },
  },
})
