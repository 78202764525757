import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d5f77b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eclear-organization__content" }
const _hoisted_2 = { class: "eclear-organization__link" }
const _hoisted_3 = { class: "eclear-organization__header" }
const _hoisted_4 = { class: "eclear-organization__delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_organization_headline = _resolveComponent("organization-headline")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_eclear_member_list = _resolveComponent("eclear-member-list")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!
  const _component_eclear_trading_member_selector_modal = _resolveComponent("eclear-trading-member-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_broker_page = _resolveComponent("broker-page")!

  return (_openBlock(), _createBlock(_component_broker_page, {
    title: _ctx.$t(_ctx.titleKey),
    "active-menu-item": _ctx.activeMenuItem,
    class: "eclear-organization"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
        loading: _withCtx(() => [
          _createVNode(_component_centered_loading_content, { type: "grayscale" })
        ]),
        error: _withCtx(() => [
          _createVNode(_component_base_alert, { type: _ctx.errorType }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.message.notFound', {
              name: _ctx.$t('iam.label.organization'),
            })), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        ideal: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_organization_headline, {
              "organization-name": _ctx.organizationProfile.name,
              "picture-url": _ctx.organizationProfile.picture
            }, null, 8, ["organization-name", "picture-url"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_base_link, {
                path: _ctx.ecloudOrganizationUrl,
                "is-target-blank": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('iam.label.linkToGlobalOrganization')) + " ", 1),
                  _createVNode(_component_base_icon, {
                    class: "members__icon",
                    "icon-name": "external-link-alt"
                  })
                ]),
                _: 1
              }, 8, ["path"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_base_button, {
                color: "primary",
                onClick: _ctx.onAddMemberButtonClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('iam.label.grantEclearTradingAuthorityToMember')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_eclear_member_list, {
                members: _ctx.eclearMembers,
                onDelete: _ctx.onDeleteMember
              }, null, 8, ["members", "onDelete"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["ui-stack"]),
      _createVNode(_component_base_modal, {
        name: "add-member",
        title: _ctx.$t('iam.label.addEclearTradingMember'),
        width: 650,
        height: 240,
        overflow: "visible"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_eclear_trading_member_selector_modal, {
            members: _ctx.nonEclearMembers,
            submitting: _ctx.submitting,
            onSelect: _ctx.onAddMemberSelect,
            onCancel: _ctx.onAddMemberCancel
          }, null, 8, ["members", "submitting", "onSelect", "onCancel"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_confirmation_dialog, {
        name: "delete-member",
        title: _ctx.$t('iam.label.deleteEclearTradingMember'),
        "confirm-button-label": _ctx.$t('common.label.delete'),
        "confirm-button-color": "danger",
        width: 650,
        loading: _ctx.submitting,
        onConfirm: _ctx.onConfirmDeleteMember,
        onCancel: _ctx.onCancelDeleteMember
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('iam.message.deleteEclearTradingMemberMessage')), 1)
        ]),
        _: 1
      }, 8, ["title", "confirm-button-label", "loading", "onConfirm", "onCancel"])
    ]),
    _: 1
  }, 8, ["title", "active-menu-item"]))
}