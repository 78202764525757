import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4eff4d78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-text-area" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = {
  key: 0,
  class: "control"
}
const _hoisted_4 = ["value", "rows"]
const _hoisted_5 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_6 = {
  key: 2,
  class: "base-text-area__value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_field = _resolveComponent("field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_label, {
        label: _ctx.labelString,
        help: _ctx.help,
        class: "base-text-area__label"
      }, null, 8, ["label", "help"]),
      (_ctx.inputable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_field, {
              name: _ctx.name,
              value: _ctx.value,
              rules: _ctx.rules,
              "validate-on-input": "",
              "validate-on-change": "",
              "validate-on-mount": ""
            }, {
              default: _withCtx(({ field, meta }) => [
                _createElementVNode("textarea", _mergeProps({ class: "textarea base-text-area__input" }, field, {
                  class: {
              'is-danger': (meta.touched || meta.dirty) && !meta.valid,
            },
                  value: _ctx.value,
                  rows: _ctx.rows,
                  onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
                }), null, 16, _hoisted_4),
                _createVNode(_component_error_message, { name: _ctx.name }, {
                  default: _withCtx(({ message }) => [
                    (meta.touched || meta.dirty)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(message), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["name"])
              ]),
              _: 1
            }, 8, ["name", "value", "rules"])
          ]))
        : (_ctx.editable)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "base-text-area__value--editable",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.value), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.value), 1)
            ]))
    ])
  ]))
}