import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73d95715"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel-banner__handle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_banner = _resolveComponent("base-banner")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_slide = _resolveComponent("slide")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", {
    class: "carousel-banner",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHover = false))
  }, [
    _createVNode(_component_carousel, {
      class: "carousel-banner__carousel",
      "wrap-around": "",
      "item-to-scroll": "0.3",
      autoplay: _ctx.autoplay,
      "pause-autoplay-on-hover": ""
    }, {
      addons: _withCtx(({ slidesCount }) => [
        (slidesCount > 1)
          ? (_openBlock(), _createBlock(_component_pagination, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.informations, (info, index) => {
          return (_openBlock(), _createBlock(_component_slide, {
            key: index,
            class: "carousel-banner__content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_banner, {
                class: "carousel-banner__information",
                title: info.title,
                message: info.message,
                type: info.informationType
              }, null, 8, ["title", "message", "type"]),
              _createElementVNode("div", _hoisted_1, [
                (_ctx.isHover)
                  ? (_openBlock(), _createBlock(_component_base_icon, {
                      key: 0,
                      class: "carousel-banner__icon",
                      "icon-name": "times",
                      onClick: ($event: any) => (_ctx.onClick(info.informationId))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["autoplay"])
  ], 32))
}