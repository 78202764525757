import { Mutations } from 'vuex-smart-module'

import { Locale } from '@/api/generated'
import { WelcomeState } from '@/store/modules/iam/welcome/WelcomeState'

export const SET_USER_LOCALE = 'SET_USER_LOCALE'
export const SET_USER_NAME = 'SET_USER_NAME'
export const SET_SUBMITTING = 'SET_SUBMITTING'

export class WelcomeMutations extends Mutations<WelcomeState> {
  [SET_USER_LOCALE](payload: { locale: Locale }) {
    this.state.locale = payload.locale
  }

  [SET_USER_NAME](payload: { userName: string }) {
    this.state.userName = payload.userName
  }

  [SET_SUBMITTING](payload: { submitting: boolean }) {
    this.state.submitting = payload.submitting
  }
}
