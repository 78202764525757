import { Module } from 'vuex-smart-module'

import { ProductActions } from '@/store/modules/trading/product/ProductActions'
import { ProductGetters } from '@/store/modules/trading/product/ProductGetters'
import { ProductMutations } from '@/store/modules/trading/product/ProductMutations'
import { ProductState } from '@/store/modules/trading/product/ProductState'

export const product = new Module({
  state: ProductState,
  getters: ProductGetters,
  mutations: ProductMutations,
  actions: ProductActions,
})

export type ProductModule = typeof product
