export class Message {
  messageId: string
  userId: string
  dateTime: Date
  message: string
  index: number

  constructor(params: {
    messageId: string
    userId: string
    dateTime: Date
    message: string
    index: number
  }) {
    this.messageId = params.messageId
    this.userId = params.userId
    this.dateTime = params.dateTime
    this.message = params.message
    this.index = params.index
  }
}
