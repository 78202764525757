
import { defineComponent, PropType } from 'vue'

import ChannelDM from '@/components/chat/ChannelDM.vue'
import ChannelOrganization from '@/components/chat/ChannelOrganization.vue'
import CreateMessageButton from '@/components/chat/CreateMessageButton.vue'
import { ChannelListProps } from '@/components/chat/interface/ChannelListProps'
import Angle from '@/components/common/Angle.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import Heading from '@/components/common/Typography/Heading.vue'

export default defineComponent({
  name: 'ChannelList',
  components: {
    Angle,
    BaseIcon,
    BaseTooltip,
    ChannelDM,
    ChannelOrganization,
    CreateMessageButton,
    Heading,
  },
  props: {
    unreadChannelList: {
      type: Object as PropType<ChannelListProps>,
      default: () => ({
        organizationChannels: [],
        dMChannels: [],
        starredOrganizationChannels: [],
        starredDMChannels: [],
      }),
    },
    readChannelList: {
      type: Object as PropType<ChannelListProps>,
      default: () => ({
        organizationChannels: [],
        dMChannels: [],
        starredOrganizationChannels: [],
        starredDMChannels: [],
      }),
    },
    displaysSelected: {
      type: Boolean,
      default: true,
    },
    displayCreateMessageButton: {
      type: Boolean,
      default: false,
    },
    displayOpenDMListButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['channel-select', 'create-message-button-click', 'open-dm-channel'],
  data() {
    return {
      unreadStarredChannelsOpened: true,
      unreadOrganizationChannelsOpened: true,
      unreadDMChannelsOpened: true,
      readStarredChannelsOpened: true,
      readOrganizationChannelsOpened: true,
      readDMChannelsOpened: true,
    }
  },
  computed: {
    hasUnreadChannels(): boolean {
      return (
        this.hasUnreadStarredChannels ||
        this.hasUnreadOrganizationChannels ||
        this.hasUnreadDMChannels
      )
    },
    hasReadChannels(): boolean {
      return (
        this.hasReadStarredChannels ||
        this.hasReadOrganizationChannels ||
        this.hasReadDMChannels
      )
    },
    hasUnreadStarredChannels(): boolean {
      const organizationChannels = this.unreadChannelList
        .starredOrganizationChannels
        ? this.unreadChannelList.starredOrganizationChannels
        : []
      const dMChannels = this.unreadChannelList.starredDMChannels
        ? this.unreadChannelList.starredDMChannels
        : []
      return [...organizationChannels, ...dMChannels].length > 0
    },
    hasUnreadOrganizationChannels(): boolean {
      return this.unreadChannelList.organizationChannels
        ? this.unreadChannelList.organizationChannels.length > 0
        : false
    },
    hasUnreadDMChannels(): boolean {
      return this.unreadChannelList.dMChannels
        ? this.unreadChannelList.dMChannels.length > 0
        : false
    },
    hasReadStarredChannels(): boolean {
      const organizationChannels = this.readChannelList
        .starredOrganizationChannels
        ? this.readChannelList.starredOrganizationChannels
        : []
      const dMChannels = this.readChannelList.starredDMChannels
        ? this.readChannelList.starredDMChannels
        : []
      return [...organizationChannels, ...dMChannels].length > 0
    },
    hasReadOrganizationChannels(): boolean {
      return this.readChannelList.organizationChannels
        ? this.readChannelList.organizationChannels.length > 0
        : false
    },
    hasReadDMChannels(): boolean {
      return this.readChannelList.dMChannels
        ? this.readChannelList.dMChannels.length > 0
        : false
    },
  },
  methods: {
    onClick(event: Event) {
      this.$emit('channel-select', event)
    },
    onCreateMessageButtonClick() {
      this.$emit('create-message-button-click')
    },
    onClickOpenDMChannelButton() {
      this.$emit('open-dm-channel')
    },
    toggleUnreadStarredChannels() {
      this.unreadStarredChannelsOpened = !this.unreadStarredChannelsOpened
    },
    toggleUnreadOrganizationChannels() {
      this.unreadOrganizationChannelsOpened = !this
        .unreadOrganizationChannelsOpened
    },
    toggleUnreadDMChannels() {
      this.unreadDMChannelsOpened = !this.unreadDMChannelsOpened
    },
    toggleReadStarredChannels() {
      this.readStarredChannelsOpened = !this.readStarredChannelsOpened
    },
    toggleReadOrganizationChannels() {
      this.readOrganizationChannelsOpened = !this.readOrganizationChannelsOpened
    },
    toggleReadDMChannels() {
      this.readDMChannelsOpened = !this.readDMChannelsOpened
    },
  },
})
