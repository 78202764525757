
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'

export default defineComponent({
  name: 'MemberListHeader',
  components: {
    BaseIcon,
    BaseTooltip,
  },
})
