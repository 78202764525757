import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb2fc43e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_tooltip, {
      label: _ctx.information,
      direction: _ctx.direction
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_icon, {
          "icon-name": "info-circle",
          class: "public-information__icon"
        })
      ]),
      _: 1
    }, 8, ["label", "direction"])
  ]))
}