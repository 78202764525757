import { RouteRecordRaw } from 'vue-router'

import Indication from '@/views/trading/Indication.vue'
import IndicationNew from '@/views/trading/IndicationNew.vue'
import Indications from '@/views/trading/Indications.vue'

/**
 * パス: /indications/...
 */
export const indicationsRoutes: RouteRecordRaw[] = [
  {
    path: '/indications',
    name: 'Indications',
    component: Indications,
  },
  {
    path: '/indications/new',
    name: 'IndicationNew',
    component: IndicationNew,
  },
  {
    path: '/indications/:id',
    name: 'Indication',
    component: Indication,
    props: route => ({ id: route.params.id }),
  },
]
