
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import OrganizationEmailAddressList from '@/components/iam/OrganizationEmailAddressList.vue'
import OrganizationEmailAddressListHeader from '@/components/iam/OrganizationEmailAddressListHeader.vue'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { OrganizationEmailAddressModule } from '@/store/modules/iam/organizationEmailAddress'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { isBrokerApp } from '@/utils/env'
import { setNotification } from '@/utils/utils'

const { mapState: userProfileMapState } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: organizationEmailAddressMapActions,
} = createNamespacedHelpers(
  'organizationEmailAddress',
) as OrganizationEmailAddressModule

export default defineComponent({
  name: 'OrganizationEmailAddresses',
  components: {
    BaseAlert,
    BaseButton,
    CenteredLoadingContent,
    ConfirmationDialog,
    OrganizationEmailAddressList,
    OrganizationEmailAddressListHeader,
    UiStackSelector,
  },
  data(): {
    deleteModalName: string
    uiStack: UiStack
    errorType: AlertType
    organizationEmailAddresses: OrganizationEmailAddress[]
    deleteSubmitting: boolean
    selected: OrganizationEmailAddress | undefined
  } {
    return {
      deleteModalName: 'delete-organization-email-address',
      uiStack: UiStack.Loading,
      errorType: AlertType.Error,
      organizationEmailAddresses: [],
      deleteSubmitting: false,
      selected: undefined,
    }
  },
  computed: {
    ...userProfileMapState(['organizationId']),
    _organizationId(): string {
      return (isBrokerApp && this.$attrs.organizationId) || this.organizationId
    },
    organizationUrl(): string {
      return isBrokerApp && this.$attrs.organizationId
        ? `/organizations/${this.$attrs.organizationId}/email-setting`
        : '/email-setting'
    },
    nameOfToDelete(): string | undefined {
      return this.selected?.name
    },
  },
  async created() {
    await this.loadOrganizationEmailAddresses()
  },
  methods: {
    fetchOrganizationEmailAddresses: organizationEmailAddressMapActions([
      'fetchOrganizationEmailAddresses',
    ]).fetchOrganizationEmailAddresses as (
      organizationId: string,
    ) => Promise<OrganizationEmailAddress[]>,
    ...organizationEmailAddressMapActions(['deleteOrganizationEmailAddress']),
    async loadOrganizationEmailAddresses() {
      this.uiStack = UiStack.Loading
      await this.fetchOrganizationEmailAddresses(this._organizationId)
        .then(organizationEmailAddresses => {
          this.organizationEmailAddresses = organizationEmailAddresses
          this.uiStack =
            this.organizationEmailAddresses.length === 0
              ? UiStack.Blank
              : UiStack.Ideal
        })
        .catch(_ => {
          this.uiStack = UiStack.Error
        })
    },
    onButtonClick() {
      this.$router.push(
        `${this.organizationUrl}/organization-email-addresses/new`,
      )
    },
    onDelete(organizationEmailAddress: OrganizationEmailAddress) {
      this.selected = organizationEmailAddress
      this.$vfm.open(this.deleteModalName)
    },
    onDeleteCancel() {
      this.deleteSubmitting = false
      this.$vfm.close(this.deleteModalName)
      this.selected = undefined
    },
    async onDeleteConfirm() {
      if (!this.selected) {
        return
      }
      this.deleteSubmitting = true
      await this.deleteOrganizationEmailAddress({
        organizationId: this._organizationId,
        organizationEmailAddressId: this.selected.id,
      })
        .then(() => {
          setNotification(
            this.$t(
              'iam.message.successDeleteOrganizationEmailAddress',
            ).toString(),
          )
          this.selected = undefined
          this.$vfm.close(this.deleteModalName)
          this.loadOrganizationEmailAddresses()
        })
        .catch(e => {
          setNotification(
            this.$t(
              'iam.message.failDeleteOrganizationEmailAddress',
            ).toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
  },
})
