import { Type } from 'class-transformer'

import { PublicIndication as IPublicIndication } from '@/api/generated'
import { BaseIndication } from '@/models/trading/BaseIndication'
import { Product } from '@/models/trading/Product'
import { PublicIndicationOfProduct } from '@/models/trading/PublicIndicationOfProduct'

export class PublicIndication extends BaseIndication
  implements IPublicIndication {
  @Type(() => Product)
  products!: Product[]

  toIndicationOfProduct(product: Product): PublicIndicationOfProduct {
    const indicationOfProduct = new PublicIndicationOfProduct()
    indicationOfProduct.product = product
    indicationOfProduct.position = this.position
    indicationOfProduct.price = this.price
    indicationOfProduct.unitPrice = this.unitPrice
    indicationOfProduct.volume = this.volume
    indicationOfProduct.fuelSurchargeType = this.fuelSurchargeType
    indicationOfProduct.publicInformation = this.publicInformation
    return indicationOfProduct
  }
}
