import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46840e54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deal-with-organization-recap-form" }
const _hoisted_2 = { class: "deal-with-organization-recap-form__heading" }
const _hoisted_3 = { class: "deal-with-organization-recap-form__deal-condition-list" }
const _hoisted_4 = { class: "deal-with-organization-recap-form__input" }
const _hoisted_5 = { class: "deal-with-organization-recap-form__input" }
const _hoisted_6 = { class: "deal-with-organization-recap-form__input" }
const _hoisted_7 = {
  key: 0,
  class: "deal-with-organization-recap-form__input"
}
const _hoisted_8 = {
  key: 0,
  class: "deal-with-organization-recap-form__input"
}
const _hoisted_9 = { class: "deal-with-organization-recap-form__input" }
const _hoisted_10 = {
  key: 0,
  class: "deal-with-organization-recap-form__input"
}
const _hoisted_11 = { class: "deal-with-organization-recap-form__input" }
const _hoisted_12 = { class: "deal-with-organization-recap-form__input" }
const _hoisted_13 = {
  key: 1,
  class: "deal-with-organization-recap-form__input"
}
const _hoisted_14 = {
  key: 2,
  class: "deal-with-organization-recap-form__input"
}
const _hoisted_15 = { class: "deal-with-organization-recap-form__template" }
const _hoisted_16 = { class: "deal-with-organization-recap-form__organization-recap-form-list" }
const _hoisted_17 = {
  key: 0,
  class: "deal-with-organization-recap-form__recap-form"
}
const _hoisted_18 = {
  key: 0,
  class: "deal-with-organization-recap-form__recap-status-label"
}
const _hoisted_19 = {
  key: 0,
  class: "deal-with-organization-recap-form__recap-form"
}
const _hoisted_20 = { class: "deal-with-organization-recap-form__recap-form-right" }
const _hoisted_21 = {
  key: 0,
  class: "deal-with-organization-recap-form__recap-status-label"
}
const _hoisted_22 = { key: 1 }
const _hoisted_23 = {
  key: 0,
  class: "deal-with-organization-recap-form__recap-form-select-note-option"
}
const _hoisted_24 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dataset = _resolveComponent("base-dataset")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_datetimepicker = _resolveComponent("base-datetimepicker")!
  const _component_base_input_single_checkbox = _resolveComponent("base-input-single-checkbox")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_text_area = _resolveComponent("base-text-area")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_organization_recap_form = _resolveComponent("organization-recap-form")!
  const _component_deal_recap_status = _resolveComponent("deal-recap-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_box, { class: "deal-with-organization-recap-form__box" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('trading.label.dealCondition')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_dataset, {
              class: "deal-with-organization-recap-form__dataset",
              data: _ctx.dealWithOrganizationRecap.deal.publicNegotiationId,
              label: _ctx.$t('trading.label.negotiationId')
            }, null, 8, ["data", "label"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_base_label, {
              label: `${_ctx.$t('trading.label.executedAt')}*`
            }, null, 8, ["label"]),
            _createVNode(_component_base_datetimepicker, {
              locale: _ctx.locale,
              value: _ctx.executedAt,
              rules: "required",
              required: "",
              onInput: _ctx.onExecutedAtClick
            }, null, 8, ["locale", "value", "onInput"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_base_label, {
              label: _ctx.$t('trading.label.privateConfidentialOfDealDetail')
            }, null, 8, ["label"]),
            _createVNode(_component_base_input_single_checkbox, {
              label: _ctx.$t('trading.label.private'),
              value: _ctx.formValue.privateConfidential,
              onInput: _cache[0] || (_cache[0] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  privateConfidential: event,
                })
            )
            }, null, 8, ["label", "value"])
          ]),
          (!_ctx.dealWithOrganizationRecap.isSpread)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_base_label, {
                  label: _ctx.$t('trading.label.openingSettingsOfVolume')
                }, null, 8, ["label"]),
                _createVNode(_component_base_input_single_checkbox, {
                  label: _ctx.$t('trading.label.private'),
                  value: _ctx.formValue.maskVolume,
                  onInput: _cache[1] || (_cache[1] = 
              event =>
                _ctx.$emit(_ctx.formInputEventName, {
                  ..._ctx.formValue,
                  maskVolume: event,
                })
            )
                }, null, 8, ["label", "value"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.spreadBasePrice)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_base_dataset, {
                class: "deal-with-organization-recap-form__dataset",
                data: 
            _ctx.$t('trading.label.unitPriceWithUnit', {
              unitPrice: _ctx.spreadBasePrice,
            })
          ,
                label: _ctx.$t('trading.label.spreadBasePrice')
              }, null, 8, ["data", "label"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_base_dataset, {
            class: "deal-with-organization-recap-form__dataset",
            data: 
            _ctx.$t('trading.label.unitPriceWithUnit', {
              unitPrice: _ctx.displayUnitPrices,
            })
          ,
            label: 
            _ctx.$t(
              `trading.label.${
                _ctx.dealWithOrganizationRecap.isSpread
                  ? 'spreadPrice'
                  : 'unitPriceShort'
              }`,
            )
          
          }, null, 8, ["data", "label"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_base_box, { class: "deal-with-organization-recap-form__box" }, {
      default: _withCtx(() => [
        (_ctx.isCfdAndIsNotAreaSpread)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_base_single_select, {
                label: `${_ctx.$t('trading.label.settlementUnitPriceArea')}`,
                name: "settlementUnitPriceArea",
                placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.settlementUnitPriceArea'),
            })
          ,
                value: _ctx.formValue.settlementUnitPriceAreaInputOption,
                options: _ctx.noSpreadAreaInputOptions,
                rules: "required",
                required: "",
                onInput: _ctx.onSettlementUnitPriceAreaInput
              }, null, 8, ["label", "placeholder", "value", "options", "onInput"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_base_text_area, {
            label: `${_ctx.$t('trading.label.restrictions')}`,
            value: _ctx.formValue.restrictions,
            rules: _ctx.maxLengthOfTextArea,
            onInput: _ctx.onRestrictionsInput
          }, null, 8, ["label", "value", "rules", "onInput"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_base_text_area, {
            label: _ctx.$t('trading.label.notes'),
            value: _ctx.formValue.note,
            rules: _ctx.maxLengthOfTextArea,
            onInput: _ctx.onNoteInput
          }, null, 8, ["label", "value", "rules", "onInput"])
        ]),
        (_ctx.notIncludingTocomOrEex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_base_text_area, {
                label: _ctx.$t('trading.label.remarks'),
                value: _ctx.formValue.remarks,
                rules: _ctx.maxLengthOfTextArea,
                onInput: _ctx.onRemarksInput
              }, null, 8, ["label", "value", "rules", "onInput"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.notIncludingTocomOrEex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_base_text_area, {
                label: _ctx.$t('trading.label.paymentDueInformation'),
                value: _ctx.formValue.paymentDueInformation,
                rules: _ctx.maxLengthOfTextArea,
                onInput: _ctx.onPaymentDueInformationInput
              }, null, 8, ["label", "value", "rules", "onInput"]),
              _createElementVNode("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentDueInformationTemplateList, (template, index) => {
                  return (_openBlock(), _createBlock(_component_base_button, {
                    key: index,
                    size: "small",
                    onClick: ($event: any) => (_ctx.onPaymentDueInformationInput(template.message))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(template.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", null, [
        _createVNode(_component_organization_recap_form, {
          "base-organization-recap": 
            _ctx.dealWithOrganizationRecap.recapOfAskOrganization
          ,
          "exchange-organization-recap": 
            _ctx.dealWithOrganizationRecap.recapOfExchangeAskOrganization
          ,
          "name-prefix": "ask",
          areas: _ctx.areas,
          locale: _ctx.locale,
          position: _ctx.ask,
          "form-values": _ctx.formValue.recapsOfAskOrganizationFormValues,
          "is-spread": _ctx.dealWithOrganizationRecap.isSpread,
          "is-area-spread": _ctx.dealWithOrganizationRecap.isAreaSpread,
          onFormInput: _cache[2] || (_cache[2] = 
            event => {
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                recapsOfAskOrganizationFormValues: event,
              })
            }
          )
        }, null, 8, ["base-organization-recap", "exchange-organization-recap", "areas", "locale", "position", "form-values", "is-spread", "is-area-spread"]),
        (_ctx.enableRecap)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              (
              _ctx.recapInfo &&
                _ctx.getRecapStatus(
                  _ctx.dealWithOrganizationRecap.recapOfBidOrganization
                    .organizationId,
                )
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createTextVNode(_toDisplayString(_ctx.$t('trading.label.recap')) + ": ", 1),
                    _createVNode(_component_deal_recap_status, {
                      size: "large",
                      status: 
                _ctx.getRecapStatus(
                  _ctx.dealWithOrganizationRecap.recapOfAskOrganization
                    .organizationId,
                )
              
                    }, null, 8, ["status"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_organization_recap_form, {
          "base-organization-recap": 
            _ctx.dealWithOrganizationRecap.recapOfBidOrganization
          ,
          "exchange-organization-recap": 
            _ctx.dealWithOrganizationRecap.recapOfExchangeBidOrganization
          ,
          "name-prefix": "bid",
          areas: _ctx.areas,
          locale: _ctx.locale,
          position: _ctx.bid,
          "form-values": _ctx.formValue.recapsOfBidOrganizationFormValues,
          "is-spread": _ctx.dealWithOrganizationRecap.isSpread,
          "is-area-spread": _ctx.dealWithOrganizationRecap.isAreaSpread,
          onFormInput: _cache[3] || (_cache[3] = 
            event => {
              _ctx.$emit(_ctx.formInputEventName, {
                ..._ctx.formValue,
                recapsOfBidOrganizationFormValues: event,
              })
            }
          )
        }, null, 8, ["base-organization-recap", "exchange-organization-recap", "areas", "locale", "position", "form-values", "is-spread", "is-area-spread"]),
        (_ctx.enableRecap)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                (
                _ctx.recapInfo &&
                  _ctx.getRecapStatus(
                    _ctx.dealWithOrganizationRecap.recapOfBidOrganization
                      .organizationId,
                  )
              )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createTextVNode(_toDisplayString(_ctx.$t('trading.label.recap')) + ": ", 1),
                      _createVNode(_component_deal_recap_status, {
                        size: "large",
                        status: 
                  _ctx.getRecapStatus(
                    _ctx.dealWithOrganizationRecap.recapOfBidOrganization
                      .organizationId,
                  )
                
                      }, null, 8, ["status"])
                    ]))
                  : _createCommentVNode("", true),
                (
                _ctx.hasRecapStatus(
                  _ctx.dealWithOrganizationRecap.recapOfBidOrganization
                    .organizationId,
                  _ctx.RecapStatus.REJECT,
                ) ||
                  !_ctx.recapInfo ||
                  !_ctx.recapInfo.details?.length
              )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createVNode(_component_base_label, {
                        label: _ctx.$t('trading.label.recapCreateOptionNote')
                      }, null, 8, ["label"]),
                      (_ctx.recapCreatedOptions)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createVNode(_component_base_input_single_checkbox, {
                              label: _ctx.$t('trading.label.product'),
                              value: _ctx.recapCreatedOptions.forceNoteProduct,
                              onInput: _cache[4] || (_cache[4] = 
                    event =>
                      _ctx.$emit(_ctx.recapCreateOptionsEventName, {
                        ..._ctx.recapCreatedOptions,
                        forceNoteProduct: event,
                      })
                  )
                            }, null, 8, ["label", "value"]),
                            _createVNode(_component_base_input_single_checkbox, {
                              label: _ctx.$t('trading.label.deliveryPeriod'),
                              value: _ctx.recapCreatedOptions.forceNoteDeliveryPeriod,
                              onInput: _cache[5] || (_cache[5] = 
                    event =>
                      _ctx.$emit(_ctx.recapCreateOptionsEventName, {
                        ..._ctx.recapCreatedOptions,
                        forceNoteDeliveryPeriod: event,
                      })
                  )
                            }, null, 8, ["label", "value"]),
                            _createVNode(_component_base_input_single_checkbox, {
                              label: _ctx.$t('trading.label.unitPriceShort'),
                              value: _ctx.recapCreatedOptions.forceNoteUnitPrice,
                              onInput: _cache[6] || (_cache[6] = 
                    event =>
                      _ctx.$emit(_ctx.recapCreateOptionsEventName, {
                        ..._ctx.recapCreatedOptions,
                        forceNoteUnitPrice: event,
                      })
                  )
                            }, null, 8, ["label", "value"]),
                            _createVNode(_component_base_input_single_checkbox, {
                              label: _ctx.$t('trading.label.volumeShort'),
                              value: _ctx.recapCreatedOptions.forceNoteVolume,
                              onInput: _cache[7] || (_cache[7] = 
                    event =>
                      _ctx.$emit(_ctx.recapCreateOptionsEventName, {
                        ..._ctx.recapCreatedOptions,
                        forceNoteVolume: event,
                      })
                  )
                            }, null, 8, ["label", "value"]),
                            _createVNode(_component_base_input_single_checkbox, {
                              label: _ctx.$t('trading.label.totalDeliveryVolumes'),
                              value: _ctx.recapCreatedOptions.forceNoteTotalContractElectricity,
                              onInput: _cache[8] || (_cache[8] = 
                    event =>
                      _ctx.$emit(_ctx.recapCreateOptionsEventName, {
                        ..._ctx.recapCreatedOptions,
                        forceNoteTotalContractElectricity: event,
                      })
                  )
                            }, null, 8, ["label", "value"])
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_base_label, {
                        label: _ctx.$t('trading.label.recapCreateOptionAnnex')
                      }, null, 8, ["label"]),
                      (_ctx.recapCreatedOptions)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createVNode(_component_base_input_single_checkbox, {
                              label: _ctx.$t('trading.label.recapCreateOptionAnnexExclude'),
                              value: _ctx.recapCreatedOptions.excludeAnnex,
                              onInput: _cache[9] || (_cache[9] = 
                    event =>
                      _ctx.$emit(_ctx.recapCreateOptionsEventName, {
                        ..._ctx.recapCreatedOptions,
                        excludeAnnex: event,
                      })
                  )
                            }, null, 8, ["label", "value"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (
                _ctx.hasRecapStatus(
                  _ctx.dealWithOrganizationRecap.recapOfBidOrganization
                    .organizationId,
                  _ctx.RecapStatus.REJECT,
                )
              )
                  ? (_openBlock(), _createBlock(_component_base_button, {
                      key: 2,
                      onClick: _cache[10] || (_cache[10] = () => _ctx.$emit(_ctx.reCreateRecapEventName))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('trading.label.reCreateRecap')), 1)
                      ]),
                      _: 1
                    }))
                  : (!_ctx.recapInfo || !_ctx.recapInfo.details?.length)
                    ? (_openBlock(), _createBlock(_component_base_button, {
                        key: 3,
                        color: "secondary",
                        onClick: _cache[11] || (_cache[11] = () => _ctx.$emit(_ctx.createRecapEventName))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('trading.label.createRecap')), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_base_button, {
                        key: 4,
                        color: "secondary",
                        onClick: _cache[12] || (_cache[12] = () => _ctx.$emit(_ctx.confirmRecapEventName))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('trading.label.confirmRecap')), 1)
                        ]),
                        _: 1
                      }))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_base_box, { class: "deal-with-organization-recap-form__box" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_label, {
          label: _ctx.$t('trading.label.enecCurveSettings'),
          help: _ctx.$t('trading.message.enecCurveSettingsHelpText')
        }, null, 8, ["label", "help"]),
        _createVNode(_component_base_input_single_checkbox, {
          label: _ctx.$t('trading.label.excludedEnecCurve'),
          value: _ctx.formValue.excludedEnecCurve,
          onInput: _cache[13] || (_cache[13] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              excludedEnecCurve: event,
            })
        )
        }, null, 8, ["label", "value"])
      ]),
      _: 1
    })
  ]))
}