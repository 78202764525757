
import { defineComponent, PropType } from 'vue'

import { IndicationStatusType as IndicationStatusTypeEnum } from '@/api/generated'
import ActionDropdown from '@/components/common/ActionDropdown.vue'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import BrokingIndicationStatus from '@/components/trading/BrokingIndicationStatus.vue'
import { IndicationStatusType } from '@/models/trading/IndicationStatusType'

export default defineComponent({
  name: 'BrokingIndicationStatusChanger',
  components: { ActionDropdown, BrokingIndicationStatus },
  props: {
    id: {
      type: String,
      required: true,
    },
    indicationId: {
      type: String,
      default: undefined,
    },
    status: {
      type: String as PropType<IndicationStatusTypeEnum>,
      default: undefined,
    },
    statusTypes: {
      type: Array as PropType<IndicationStatusType[]>,
      required: true,
    },
    isBulked: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isEclearIndication: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dispatch-direct-event', 'activate', 'deactivate'],
  data(): {
    isActionDropdownActive: boolean
    actions: ActionDropdownItem[]
  } {
    return {
      isActionDropdownActive: false,
      actions: [],
    }
  },
  methods: {
    onStatusClick() {
      if (this.isSubmitting) {
        return
      }
      this.actions = []
      // deal の場合は後続処理を行わない
      if (this.indicationId === undefined && !this.isBulked) {
        return
      }
      const currentStatus = this.statusTypes.find(
        status => status.id === this.status,
      )
      // currentStatus が見つからないなんてそんなことないはずだけど
      if (!currentStatus) {
        return
      }
      const changeableStatuses = currentStatus.changeableStatuses
      const actions: ActionDropdownItem[] = []
      if (
        changeableStatuses.includes(IndicationStatusTypeEnum.Active) &&
        currentStatus.id !== IndicationStatusTypeEnum.Active
      ) {
        actions.push({
          label: this.$t('trading.label.activate').toString(),
          eventName: 'activate',
          disabled: this.isEclearIndication,
        })
      }
      if (
        changeableStatuses.includes(IndicationStatusTypeEnum.Inactive) &&
        currentStatus.id !== IndicationStatusTypeEnum.Inactive
      ) {
        actions.push({
          label: this.$t('trading.label.deactivate').toString(),
          eventName: 'deactivate',
          disabled: this.isEclearIndication,
        })
      }
      if (actions.length > 0) {
        this.actions = actions
        this.isActionDropdownActive = true
        this.$emit('dispatch-direct-event')
      }
    },
    onActivate() {
      this.$emit('activate', this.indicationId)
      this.isActionDropdownActive = false
    },
    onDeactivate() {
      this.$emit('deactivate', this.indicationId)
      this.isActionDropdownActive = false
    },
  },
})
