import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "organization-email-address-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_organization_email_address_list_item = _resolveComponent("organization-email-address-list-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationEmailAddresses, (organizationEmailAddress, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_organization_email_address_list_item, {
          "organization-email-address": organizationEmailAddress,
          "parent-path": _ctx.parentPath,
          onDeleteClick: ($event: any) => (_ctx.onDeleteClick(organizationEmailAddress))
        }, null, 8, ["organization-email-address", "parent-path", "onDeleteClick"])
      ]))
    }), 128))
  ]))
}