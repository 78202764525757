
import { defineComponent, PropType } from 'vue'

import BaseChatWindow from '@/components/chat/BaseChatWindow.vue'
import ChannelList from '@/components/chat/ChannelList.vue'
import { ChannelListProps } from '@/components/chat/interface/ChannelListProps'
import BaseTag from '@/components/common/BaseTag.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'

export default defineComponent({
  name: 'ChannelListWindow',
  components: {
    BaseChatWindow,
    BaseTag,
    CenteredLoadingContent,
    ChannelList,
  },
  props: {
    unreadChannelList: {
      type: Object as PropType<ChannelListProps>,
      default: () => ({ organizationChannels: [], dMChannels: [] }),
    },
    readChannelList: {
      type: Object as PropType<ChannelListProps>,
      default: () => ({ organizationChannels: [], dMChannels: [] }),
    },
    isOpened: {
      type: Boolean,
      default: true,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
    totalNumberOfUnreadMessage: {
      type: Number,
      default: 0,
    },
    displayCreateMessageButton: {
      type: Boolean,
      default: false,
    },
    displayOpenDMListButton: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 500,
    },
    isFetchingChannelList: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'title-bar-click',
    'channel-select',
    'create-message-button-click',
    'open-dm-channel',
  ],
  computed: {
    isChannelListEmpty(): boolean {
      return (
        this.unreadChannelList.dMChannels?.length === 0 &&
        this.unreadChannelList.organizationChannels?.length === 0 &&
        this.readChannelList.dMChannels?.length === 0 &&
        this.readChannelList.organizationChannels?.length === 0
      )
    },
  },
  methods: {
    onTitleBarClick() {
      this.$emit('title-bar-click')
    },
    onChannelSelect(event: Event) {
      this.$emit('channel-select', event)
    },
    onCreateMessageButtonClick() {
      this.$emit('create-message-button-click')
    },
    onOpenDMChannel() {
      this.$emit('open-dm-channel')
    },
  },
})
