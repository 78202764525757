
import { defineComponent, PropType } from 'vue'

import { RecipientListOption } from '@/components/chat/interface/RecipientListProps'
import StatusToggleButton from '@/components/common/StatusToggleButton.vue'

export default defineComponent({
  name: 'RecipientList',
  components: {
    StatusToggleButton,
  },
  props: {
    options: {
      type: Array as PropType<RecipientListOption[]>,
      required: true,
    },
    togglable: {
      type: Boolean,
      default: false,
    },
    activeAllLabel: {
      type: String,
      default: '',
    },
    deActiveAllLabel: {
      type: String,
      default: '',
    },
    checkedValues: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['selected-list-change'],
  data() {
    return {
      values: this.checkedValues,
    }
  },
  computed: {
    allInactive(): boolean {
      return this.values.length === 0
    },
  },
  methods: {
    onChange() {
      this.$emit('selected-list-change', this.values)
    },
    onActivateAll() {
      this.options.forEach(option => {
        this.values.push(option.value)
      })
      this.$emit('selected-list-change', this.values)
    },
    onDeactivateAll() {
      this.values.splice(0, this.values.length)
      this.$emit('selected-list-change', this.values)
    },
  },
})
