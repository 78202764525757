import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1148cb0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trading-company-name-form" }
const _hoisted_2 = { class: "trading-company-name-form__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.tradingCompanyNameJa').toString(),
        name: "tradingCompanyNameJa",
        value: _ctx.formValue.tradingCompanyName.ja,
        rules: "required",
        required: "",
        help: _ctx.$t('iam.message.tradingCompanyNameHelpMessage').toString(),
        onInput: _cache[0] || (_cache[0] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              tradingCompanyName: {
                ja: event,
                en: _ctx.formValue.tradingCompanyName.en,
              },
            })
        )
      }, null, 8, ["label", "value", "help"]),
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.tradingCompanyNameEn').toString(),
        name: "tradingCompanyNameEn",
        value: _ctx.formValue.tradingCompanyName.en,
        rules: "required",
        required: "",
        onInput: _cache[1] || (_cache[1] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              tradingCompanyName: {
                ja: _ctx.formValue.tradingCompanyName.ja,
                en: event,
              },
            })
        )
      }, null, 8, ["label", "value"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.abbreviation').toString(),
        name: "abbreviation",
        value: _ctx.formValue.abbreviation,
        mode: _ctx.readonly
      }, null, 8, ["label", "value", "mode"])
    ])
  ]))
}