import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ec417ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization" }
const _hoisted_2 = {
  key: 0,
  class: "organization__link"
}
const _hoisted_3 = { class: "organization__content" }
const _hoisted_4 = { class: "organization__form" }
const _hoisted_5 = { class: "organization__buttons" }
const _hoisted_6 = { class: "organization__form" }
const _hoisted_7 = { class: "organization__buttons" }
const _hoisted_8 = { class: "organization__form" }
const _hoisted_9 = { class: "organization__buttons" }
const _hoisted_10 = { class: "organization__form" }
const _hoisted_11 = { class: "organization__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_base_alert = _resolveComponent("base-alert")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_trading_company_name_form = _resolveComponent("trading-company-name-form")!
  const _component_base_box = _resolveComponent("base-box")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!
  const _component_business_info_form = _resolveComponent("business-info-form")!
  const _component_brokerage_fee_payment_configuration_form = _resolveComponent("brokerage-fee-payment-configuration-form")!
  const _component_default_broking_fee_of_organization_form = _resolveComponent("default-broking-fee-of-organization-form")!
  const _component_ui_stack_selector = _resolveComponent("ui-stack-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_stack_selector, { "ui-stack": _ctx.uiStack }, {
      loading: _withCtx(() => [
        _createVNode(_component_centered_loading_content, { type: "grayscale" })
      ]),
      error: _withCtx(() => [
        _createVNode(_component_base_alert, { type: _ctx.errorType }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('iam.message.failInitializeOrganization')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]),
      ideal: _withCtx(() => [
        (_ctx.isAdminPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_base_link, {
                path: _ctx.ecloudOrganizationUrl,
                "is-target-blank": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('iam.label.linkToGlobalOrganization')) + " ", 1),
                  _createVNode(_component_base_icon, {
                    class: "members__icon",
                    "icon-name": "external-link-alt"
                  })
                ]),
                _: 1
              }, 8, ["path"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_validation_form, null, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_base_box, { class: "organization__box" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_trading_company_name_form, {
                      "form-value": _ctx.tradingCompanyNameFormValue,
                      onFormInput: _ctx.onTradingCompanyNameFormInput
                    }, null, 8, ["form-value", "onFormInput"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_base_button, {
                    class: "organization__cancel-button",
                    onClick: _ctx.onCancelClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    class: "organization__save-button",
                    color: "primary",
                    disabled: Object.keys(errors).length > 0,
                    loading: _ctx.tradingCompanyNameSubmitting,
                    onClick: _ctx.onUpdateTradingCompanyNameClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "loading", "onClick"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_validation_form, null, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_base_box, { class: "organization__box" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_business_info_form, {
                      "broker-ids-input-options": 
                    _ctx.assignedBrokerOfOrganizationFormOption
                  ,
                      "form-value": _ctx.businessInfoFormValue,
                      "sales-tax-included-in-invoice-input-options": 
                    _ctx.salesTaxIncludedInInvoiceInputOptions
                  ,
                      onFormInput: _ctx.onBusinessInfoFormInput
                    }, null, 8, ["broker-ids-input-options", "form-value", "sales-tax-included-in-invoice-input-options", "onFormInput"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_base_button, {
                    class: "organization__cancel-button",
                    onClick: _ctx.onCancelClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    class: "organization__save-button",
                    color: "primary",
                    disabled: Object.keys(errors).length > 0,
                    loading: _ctx.businessInfoSubmitting,
                    onClick: _ctx.onUpdateBusinessInfoClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "loading", "onClick"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_validation_form, null, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_base_box, { class: "organization__box" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_brokerage_fee_payment_configuration_form, {
                      "form-value": _ctx.brokerageFeePaymentConfigurationFormValue,
                      "timing-input-options": _ctx.timingInputOptions,
                      onFormInput: _ctx.onBrokerageFeePaymentConfigurationFormInput
                    }, null, 8, ["form-value", "timing-input-options", "onFormInput"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_base_button, {
                    class: "organization__cancel-button",
                    onClick: _ctx.onCancelClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    class: "organization__save-button",
                    color: "primary",
                    disabled: Object.keys(errors).length > 0,
                    loading: _ctx.brokerageFeePaymentConfigurationSubmitting,
                    onClick: _ctx.onUpdateBrokerageFeePaymentConfigurationClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "loading", "onClick"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_validation_form, null, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_base_box, { class: "organization__box" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_default_broking_fee_of_organization_form, {
                      "product-type-input-options": _ctx.productTypeInputOptions,
                      "form-value": _ctx.defaultBrokingFeeOfOrganizationsFormValue,
                      "timing-input-options": _ctx.timingInputOptions,
                      "last-updated-participant-name": 
                    _ctx.defaultBrokingFeeOfOrganizationLastUpdatedParticipantName
                  ,
                      "updated-at": _ctx.defaultBrokingFeeOfOrganizationUpdatedAt,
                      onFormInput: _ctx.onDefaultBrokingFeeOfOrganizationFormInput
                    }, null, 8, ["product-type-input-options", "form-value", "timing-input-options", "last-updated-participant-name", "updated-at", "onFormInput"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_base_button, {
                    class: "organization__cancel-button",
                    onClick: _ctx.onCancelClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_button, {
                    class: "organization__save-button",
                    color: "primary",
                    disabled: Object.keys(errors).length > 0,
                    loading: _ctx.defaultBrokingFeeOfOrganizationSubmitting,
                    onClick: _ctx.onUpdateDefaultBrokingFeeOfOrganizationClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.label.save')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "loading", "onClick"])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["ui-stack"])
  ]))
}