import moment from 'moment/moment'

import { i18n } from '@/i18n'
import { MemberProfile } from '@/models/iam/MemberProfile'
import { Organization } from '@/models/iam/Organization'
import { OrganizationEmailAddress } from '@/models/iam/OrganizationEmailAddress'
import { ContractCondition } from '@/models/trading/ContractCondition'
import { Deal } from '@/models/trading/Deal'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import {
  DeliverySummaries as DeliverySummariesModel,
  DeliverySummaryWithCalendarTypeName,
} from '@/models/trading/DeliverySummaries'
import { DeliveryTerms } from '@/models/trading/DeliveryTerms'
import { OrganizationRecap } from '@/models/trading/OrganizationRecap'
import { TocomBroker } from '@/models/trading/TocomBroker'
import {
  convertDate,
  convertDisplayDeliveryPeriod,
  convertDisplayFee,
  convertRecapDetail,
  convertTerms,
  convertWithNone,
  filterReceiveMemberEmails,
  filterReceiveOrganizationMemberEmails,
  FooterMail,
  FooterPhoneNumber,
  forceShowNote,
  MAX_DIGIT,
  RECAP_ORDER_ASK,
  RECAP_ORDER_ASK_SPREAD,
  RECAP_ORDER_BID,
  RECAP_ORDER_BID_SPREAD,
  RecapEndpointType,
  SERVICE,
} from '@/store/modules/trading/recap/helper'
import {
  RecapCreatedOption,
  RecapDetail,
  RecapStatus,
  TocomForBuyer,
  TocomForSeller,
} from '@/store/modules/trading/recap/interface'

const convertDisplayClearingMember = (tocomBroker?: TocomBroker): string => {
  if (!tocomBroker) {
    return ''
  }
  return `${tocomBroker.name}${
    tocomBroker.offFloorTradingCode
      ? `(${tocomBroker?.offFloorTradingCode})`
      : ''
  }`
}

export const convertTocomProductName = (
  deliveryTerms: DeliveryTerms,
  exchangeDeliveryTerms?: DeliveryTerms,
): string => {
  if (deliveryTerms.deliveryPeriods.length !== 1) {
    return ''
  }
  let area = ''

  if (
    exchangeDeliveryTerms &&
    deliveryTerms.area.id !== exchangeDeliveryTerms.area.id
  ) {
    area = '東西スプレッド'
  } else {
    switch (deliveryTerms.area.translationOf('en')) {
      case 'Tokyo':
        area = '東エリア'
        break
      case 'Kansai':
        area = '西エリア'
        break
    }
  }

  let product = ''
  const baseHourType = deliveryTerms.deliveryPeriods[0].deliveryPattern.hourType
  const exchangeHourType =
    exchangeDeliveryTerms?.deliveryPeriods[0].deliveryPattern.hourType
  if (
    exchangeDeliveryTerms &&
    baseHourType &&
    exchangeHourType &&
    baseHourType.id !== exchangeHourType.id
  ) {
    product = 'ピークベーススプレッド'
  } else {
    switch (baseHourType?.translationOf('en')) {
      case 'Base':
        product = 'ベースロード電力'
        break
      case 'Middle8-20':
        product = '日中ロード電力'
        break
    }
  }
  return `TOCOM - ${area} - ${product}`
}

const getRecapTitle = (data: DealWithOrganizationRecap): string => {
  const { deal } = data
  const executionDate = moment(deal.executedAt).format('YYYYMMDD')
  const productName = convertTocomProductName(
    deal.product.deliveryTerms,
    deal.product.exchangeDeliveryTerms,
  )
  const terms = convertTerms(deal.product)

  return `【Recap】From enechain) ${executionDate}_${productName} - ${terms} - ${deal.publicNegotiationId}`
}

// 英語固定
const unitPrice = (price: number): string => {
  return `${price.toLocaleString(undefined, {
    maximumFractionDigits: MAX_DIGIT,
  })} JPY/kWh`
}

const convertTocomForSellerDataDetail = (
  userName: string,
  seller: string,
  sellerClearingMember: string,
  buyerClearingMember: string,
  deal: Deal,
  deliveryTerms: DeliveryTerms,
  contractCondition: ContractCondition,
  organizationRecap: OrganizationRecap,
  recapCreatedOptions: RecapCreatedOption,
  deliverySummaryWithCalendarTypeName?: DeliverySummaryWithCalendarTypeName,
): TocomForSeller => {
  return {
    publicationDate: {
      text: convertDate(new Date().toDateString()),
    },
    organizationName: {
      text: `${organizationRecap.organizationName.translation()} ${i18n.t(
        'common.label.dear',
      )}`,
    },
    dealDetails: {
      items: {
        executionDate: {
          text: [convertDate(deal.executedAt)],
        },
        transactionId: {
          text: [organizationRecap.publicRecapId],
        },
        seller: {
          text: [seller],
        },
        sellersClearingMember: {
          text: [sellerClearingMember],
        },
        buyersClearingMember: {
          text: [buyerClearingMember],
        },
        product: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteProduct,
              convertTocomProductName(deliveryTerms),
              'en',
            ),
          ],
        },
        deliveryPeriod: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteDeliveryPeriod,
              convertDisplayDeliveryPeriod(deal.product, deliveryTerms),
              'en',
            ),
          ],
        },
        unitPrice: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteUnitPrice,
              unitPrice(
                organizationRecap.unitPrices.length > 0
                  ? organizationRecap.unitPrices[0]
                  : 0,
              ),
              'en',
            ),
          ],
        },
        quantity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteVolume,
              organizationRecap.volumes.length > 0
                ? `${organizationRecap.volumes[0]} ${i18n.t(
                    'trading.label.volumeUnit',
                  )}`
                : '',
              'en',
            ),
          ],
        },
        totalContractElectricity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteTotalContractElectricity,
              deliverySummaryWithCalendarTypeName?.deliverySummary
                .totalDeliveryVolumes
                ? `${(
                    deliverySummaryWithCalendarTypeName?.deliverySummary
                      .totalDeliveryVolumes * 1000
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: MAX_DIGIT,
                  })} ${i18n.t('trading.label.kwh')}`
                : '',
              'en',
            ),
          ],
        },
        brokerageUnitPrice: {
          text: [convertDisplayFee(organizationRecap.brokingFee, 'en')],
        },
        note: {
          text: [convertWithNone(contractCondition.note, 'en')],
        },
      },
    },
    footer: {
      footer: {
        from: userName,
        mail: FooterMail.ENECHAIN,
        phone: FooterPhoneNumber.ENECHAIN,
      },
    },
  }
}

const convertTocomForBuyerDataDetail = (
  userName: string,
  buyer: string,
  sellerClearingMember: string,
  buyerClearingMember: string,
  deal: Deal,
  deliveryTerms: DeliveryTerms,
  contractCondition: ContractCondition,
  organizationRecap: OrganizationRecap,
  recapCreatedOptions: RecapCreatedOption,
  deliverySummaryWithCalendarTypeName?: DeliverySummaryWithCalendarTypeName,
): TocomForBuyer => {
  return {
    publicationDate: {
      text: convertDate(new Date().toDateString()),
    },
    organizationName: {
      text: `${organizationRecap.organizationName.translation()} ${i18n.t(
        'common.label.dear',
      )}`,
    },
    dealDetails: {
      items: {
        executionDate: {
          text: [convertDate(deal.executedAt)],
        },
        transactionId: {
          text: [organizationRecap.publicRecapId],
        },
        buyer: {
          text: [buyer],
        },
        buyersClearingMember: {
          text: [buyerClearingMember],
        },
        sellersClearingMember: {
          text: [sellerClearingMember],
        },
        product: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteProduct,
              convertTocomProductName(deliveryTerms),
              'en',
            ),
          ],
        },
        deliveryPeriod: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteDeliveryPeriod,
              convertDisplayDeliveryPeriod(deal.product, deliveryTerms),
              'en',
            ),
          ],
        },
        unitPrice: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteUnitPrice,
              unitPrice(
                organizationRecap.unitPrices.length > 0
                  ? organizationRecap.unitPrices[0]
                  : 0,
              ),
              'en',
            ),
          ],
        },
        quantity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteVolume,
              organizationRecap.volumes.length > 0
                ? `${organizationRecap.volumes[0]} ${i18n.t(
                    'trading.label.volumeUnit',
                  )}`
                : '',
              'en',
            ),
          ],
        },
        totalContractElectricity: {
          text: [
            forceShowNote(
              recapCreatedOptions.forceNoteTotalContractElectricity,
              deliverySummaryWithCalendarTypeName?.deliverySummary
                .totalDeliveryVolumes
                ? `${(
                    deliverySummaryWithCalendarTypeName?.deliverySummary
                      .totalDeliveryVolumes * 1000
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: MAX_DIGIT,
                  })} ${i18n.t('trading.label.kwh')}`
                : '',
              'en',
            ),
          ],
        },
        brokerageUnitPrice: {
          text: [convertDisplayFee(organizationRecap.brokingFee, 'en')],
        },
        note: {
          text: [convertWithNone(contractCondition.note, 'en')],
        },
      },
    },
    footer: {
      footer: {
        from: userName,
        mail: FooterMail.ENECHAIN,
        phone: FooterPhoneNumber.ENECHAIN,
      },
    },
  }
}

export const convertSwapTocomData = (
  userName: string,
  data: DealWithOrganizationRecap,
  summary: DeliverySummariesModel,
  askOrganizationMembers: MemberProfile[],
  bidOrganizationMembers: MemberProfile[],
  userOrganizationMembers: MemberProfile[],
  askOrganization: Organization,
  bidOrganization: Organization,
  askOrganizationEmailAddresses: OrganizationEmailAddress[],
  bidOrganizationEmailAddresses: OrganizationEmailAddress[],
  userOrganizationEmailAddresses: OrganizationEmailAddress[],
  recapCreatedOptions: RecapCreatedOption,
): RecapDetail[] => {
  const {
    deal,
    contractCondition,
    recapOfAskOrganization,
    recapOfBidOrganization,
    recapOfExchangeAskOrganization,
    recapOfExchangeBidOrganization,
  } = data
  const base = {
    name: getRecapTitle(data),
    status: RecapStatus.NONE,
    service: SERVICE,
  }

  const recapDetails: RecapDetail[] = [
    convertRecapDetail(
      {
        ...base,
        templateEndpoint: RecapEndpointType.TOCOM_FOR_SELLER,
        identifier: recapOfAskOrganization.publicRecapId,
        organization: recapOfAskOrganization.organizationId,
        organizationName: data.recapOfAskOrganization.organizationName.translationOf(
          askOrganization.locale || 'en',
        ),
        orderNumber: RECAP_ORDER_ASK,
        language: askOrganization.locale,
        userMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfAskOrganization.product.productType,
              askOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfAskOrganization.product.productType,
              askOrganizationEmailAddresses,
            ),
          ],
          cc: [FooterMail.ENECHAIN],
          bcc: [],
        },
        operatorMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfAskOrganization.product.productType,
              userOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfAskOrganization.product.productType,
              userOrganizationEmailAddresses,
            ),
          ],
          cc: [],
          bcc: [],
        },
      },
      convertTocomForSellerDataDetail(
        userName,
        recapOfAskOrganization.organizationName.translation(),
        convertDisplayClearingMember(recapOfAskOrganization.tocomBroker),
        convertDisplayClearingMember(recapOfBidOrganization.tocomBroker),
        deal,
        deal.product.deliveryTerms,
        contractCondition,
        recapOfAskOrganization,
        recapCreatedOptions,
        summary.base,
      ),
    ),
    convertRecapDetail(
      {
        ...base,
        templateEndpoint: RecapEndpointType.TOCOM_FOR_BUYER,
        identifier: recapOfBidOrganization.publicRecapId,
        organization: recapOfBidOrganization.organizationId,
        organizationName: data.recapOfBidOrganization.organizationName.translationOf(
          bidOrganization.locale || 'en',
        ),
        orderNumber: RECAP_ORDER_BID,
        language: bidOrganization.locale,
        userMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfBidOrganization.product.productType,
              bidOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfBidOrganization.product.productType,
              bidOrganizationEmailAddresses,
            ),
          ],
          cc: [FooterMail.ENECHAIN],
          bcc: [],
        },
        operatorMail: {
          to: [
            ...filterReceiveMemberEmails(
              data.recapOfBidOrganization.product.productType,
              userOrganizationMembers,
            ),
            ...filterReceiveOrganizationMemberEmails(
              data.recapOfBidOrganization.product.productType,
              userOrganizationEmailAddresses,
            ),
          ],
          cc: [],
          bcc: [],
        },
      },
      convertTocomForBuyerDataDetail(
        userName,
        recapOfBidOrganization.organizationName.translation(),
        convertDisplayClearingMember(recapOfAskOrganization.tocomBroker),
        convertDisplayClearingMember(recapOfBidOrganization.tocomBroker),
        deal,
        deal.product.deliveryTerms,
        contractCondition,
        recapOfBidOrganization,
        recapCreatedOptions,
        summary.base,
      ),
    ),
  ]

  if (
    deal.product.exchangeDeliveryTerms &&
    recapOfExchangeAskOrganization &&
    recapOfExchangeBidOrganization
  ) {
    recapDetails.push(
      convertRecapDetail(
        {
          ...base,
          templateEndpoint: RecapEndpointType.TOCOM_FOR_BUYER,
          identifier: recapOfExchangeAskOrganization.publicRecapId,
          organization: recapOfExchangeAskOrganization.organizationId,
          organizationName: recapOfExchangeAskOrganization.organizationName.translationOf(
            askOrganization.locale || 'en',
          ),
          orderNumber: RECAP_ORDER_ASK_SPREAD,
          language: askOrganization.locale,
          userMail: {
            to: [],
            cc: [],
            bcc: [],
          },
          operatorMail: {
            to: [],
            cc: [],
            bcc: [],
          },
        },
        convertTocomForBuyerDataDetail(
          userName,
          recapOfExchangeAskOrganization.organizationName.translation(),
          convertDisplayClearingMember(
            recapOfExchangeBidOrganization.tocomBroker,
          ),
          convertDisplayClearingMember(
            recapOfExchangeAskOrganization.tocomBroker,
          ),
          deal,
          deal.product.exchangeDeliveryTerms,
          contractCondition,
          recapOfExchangeAskOrganization,
          recapCreatedOptions,
          summary.exchange,
        ),
      ),
    )

    recapDetails.push(
      convertRecapDetail(
        {
          ...base,
          templateEndpoint: RecapEndpointType.TOCOM_FOR_SELLER,
          identifier: recapOfExchangeBidOrganization.publicRecapId,
          organization: recapOfExchangeBidOrganization.organizationId,
          organizationName: recapOfExchangeBidOrganization.organizationName.translationOf(
            bidOrganization.locale || 'en',
          ),
          orderNumber: RECAP_ORDER_BID_SPREAD,
          language: bidOrganization.locale,
          userMail: {
            to: [],
            cc: [],
            bcc: [],
          },
          operatorMail: {
            to: [],
            cc: [],
            bcc: [],
          },
        },
        convertTocomForSellerDataDetail(
          userName,
          recapOfExchangeBidOrganization.organizationName.translation(),
          convertDisplayClearingMember(
            recapOfExchangeBidOrganization.tocomBroker,
          ),
          convertDisplayClearingMember(
            recapOfExchangeAskOrganization.tocomBroker,
          ),
          deal,
          deal.product.exchangeDeliveryTerms,
          contractCondition,
          recapOfExchangeBidOrganization,
          recapCreatedOptions,
          summary.exchange,
        ),
      ),
    )
  }

  return recapDetails
}
