import {
  IndicationStatusType as IndicationStatusTypeEnum,
  IndicationStatusTypeSpec,
} from '@/api/generated'
import { EntityAttribute } from '@/models/common/EntityAttribute'

export class IndicationStatusType extends EntityAttribute
  implements IndicationStatusTypeSpec {
  id!: IndicationStatusTypeEnum
  changeableStatuses!: IndicationStatusTypeEnum[]
}
