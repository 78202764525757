import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f0734d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-occto-master" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'selected-occto-master__container--selectable': _ctx.isSelectable,
      }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.occtoMasterType')) + ": " + _toDisplayString(_ctx.selectedOcctoMaster.displayOcctoMasterTypeName), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trading.label.companyPrefix')) + ": " + _toDisplayString(_ctx.selectedOcctoMaster.companyPrefix), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.selectedOcctoMaster.displayCodeLabel) + ": " + _toDisplayString(_ctx.selectedOcctoMaster.code), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.selectedOcctoMaster.displayNameLabel) + ": " + _toDisplayString(_ctx.selectedOcctoMaster.name), 1)
    ], 2)
  ]))
}