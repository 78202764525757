import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-232e6baf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-indication-new" }
const _hoisted_2 = {
  key: 0,
  class: "my-indication-new__loading"
}
const _hoisted_3 = { class: "my-indication-new__wrapper" }
const _hoisted_4 = { class: "my-indication-new__annotation" }
const _hoisted_5 = { class: "my-indication-new__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_centered_loading_content = _resolveComponent("centered-loading-content")!
  const _component_my_indication_form = _resolveComponent("my-indication-form")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_validation_form = _resolveComponent("validation-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.selectableInputs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_centered_loading_content, {
            type: "grayscale",
            "is-relative": ""
          })
        ]))
      : (_openBlock(), _createBlock(_component_validation_form, { key: 1 }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_my_indication_form, {
                "form-value": _ctx.formValue,
                mode: _ctx.mode,
                locale: _ctx.userProfile.locale,
                "position-reversed": 
              _ctx.services?.esquare?.userSetting?.positionReversed
            ,
                "selectable-inputs": _ctx.selectableInputs,
                "is-editing": _ctx.isEditing,
                "opened-spread-form-types": _ctx.openedSpreadFormTypes,
                "product-types": _ctx.productTypes,
                "date-names": _ctx.dateNames,
                onFormInput: _ctx.onFormInput,
                onTextInput: _ctx.onTextInput,
                onStandardDeliveryTermsFormOpen: _ctx.onStandardDeliveryTermsFormOpen,
                onStandardDeliveryTermsFormClose: 
              _ctx.onStandardDeliveryTermsFormClose
            
              }, null, 8, ["form-value", "mode", "locale", "position-reversed", "selectable-inputs", "is-editing", "opened-spread-form-types", "product-types", "date-names", "onFormInput", "onTextInput", "onStandardDeliveryTermsFormOpen", "onStandardDeliveryTermsFormClose"]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('trading.message.newIndicationAnnotation')), 1),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.isEditing)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_base_button, { onClick: _ctx.onCancelClick }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('common.label.cancel')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_base_button, {
                        disabled: _ctx.isInvalid || Object.keys(errors).length > 0,
                        color: "primary",
                        onClick: _ctx.onConfirmClick
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('common.label.confirm')), 1)
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_base_button, {
                        loading: _ctx.isSubmitting,
                        color: "primary",
                        onClick: _ctx.onCreateIndicationConfirmClick
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('trading.label.order')), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "onClick"]),
                      _createVNode(_component_base_button, { onClick: _ctx.onConfirmCancelClick }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('common.label.back')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ], 64))
              ])
            ])
          ]),
          _: 1
        }))
  ]))
}