import {
  required as requiredOriginal,
  between as betweenOriginal,
  numeric as numericOriginal,
  min as minOriginal,
  max as maxOriginal,
  email as emailOriginal,
} from '@vee-validate/rules'

import { InputOption } from '@/components/common/interface/InputOption'

export const email = emailOriginal
export const between = betweenOriginal
export const integer = numericOriginal
export const max = maxOriginal
export const min = minOriginal
export const required = requiredOriginal

export const requiredArray = (values: unknown[]) => {
  return values instanceof Array && values?.length > 0
}

export const numeric = (value: string | number) => {
  return /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(String(value))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const scale = (value: number | string, params: string[]) => {
  const precision = Number(params[0])
  const decimal = String(Number(value)).split('.')[1]
  if (!decimal) {
    return true
  }
  const scale = decimal.length
  return scale < precision
}

// vee-validate に size というファイルサイズの validator があったが 1K = 1024 で計算してるので自作の方を使う
const K = 1000
export const maxFileSize = (file: File | undefined, params: string[]) => {
  if (!file) {
    return true
  }
  const maxBytes = Number(params[0]) * K * K
  return maxBytes >= file.size
}

/**
 * @deprecated
 * TODO: not-on-of という組み込みに置き換える
 */
export const blacklist = (value: string, blacklist: string[]) => {
  if (!blacklist) {
    return false
  }
  return !blacklist.includes(value)
}

/**
 * @deprecated
 * TODO: on-of という組み込みに置き換える
 */
export const whitelist = (value: string, whitelist: string[]) => {
  // 第2引数は Record 型ではないが any[] で定義すると型エラーになる。要調査
  if (!whitelist) {
    return false
  }
  return whitelist.includes(value)
}

export const whitelistForInputOption = (
  { value }: InputOption,
  whitelist: string[],
) => {
  // 第2引数は Record 型ではないが any[] で定義すると型エラーになる。要調査
  if (!whitelist) {
    return false
  }
  return whitelist.includes(value)
}
