import { Type } from 'class-transformer'

import { MultiLocaleName } from '@/models/common/MultiLocaleName'

export class OrganizationProfile {
  @Type(() => MultiLocaleName)
  name!: MultiLocaleName

  picture!: string
}
