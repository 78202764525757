import { Conversation, User as TwilioUser } from '@twilio/conversations'

import { Channel } from '@/models/chat/Channel'
import { ChannelType } from '@/models/chat/ChannelType'

export class DMChannel extends Channel {
  userInstance: TwilioUser

  constructor(params: {
    channelId: string
    conversationInstance: Conversation
    profileId: string
    userInstance: TwilioUser
  }) {
    super(params)
    this.userInstance = params.userInstance
  }

  get channelType(): ChannelType {
    return 'DM'
  }
}
