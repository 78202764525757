import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_picture = _resolveComponent("base-picture")!
  const _component_message_header = _resolveComponent("message-header")!
  const _component_message_body = _resolveComponent("message-body")!

  return (_openBlock(), _createElementBlock("div", {
    class: "message",
    style: _normalizeStyle(_ctx.styleVars)
  }, [
    _createVNode(_component_base_picture, {
      class: "message__picture",
      url: _ctx.picture,
      width: _ctx.pictureWidth
    }, null, 8, ["url", "width"]),
    _createVNode(_component_message_header, {
      class: "message__header",
      name: _ctx.name,
      "date-time": _ctx.dateTime,
      size: _ctx.size
    }, null, 8, ["name", "date-time", "size"]),
    _createVNode(_component_message_body, {
      class: "message__body",
      message: _ctx.message,
      size: _ctx.size
    }, null, 8, ["message", "size"])
  ], 4))
}