
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'

export default defineComponent({
  name: 'CopyToClipboardIcon',
  components: {
    BaseIcon,
    BaseTooltip,
  },
  emits: ['copy-clipboard'],
  data() {
    return {
      helpCopy: this.$t('trading.message.copyClipboard'),
    }
  },
  methods: {
    onCopyClick() {
      this.helpCopy = this.$t('trading.message.copied')
      this.$emit('copy-clipboard')
    },
    resetHelpCopy() {
      this.helpCopy = this.$t('trading.message.copyClipboard')
    },
  },
})
