
import { defineComponent } from 'vue'

import BaseIcon from '@/components/common/BaseIcon.vue'

export default defineComponent({
  name: 'EnableIcon',
  components: {
    BaseIcon,
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
  },
})
