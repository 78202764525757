import { Type } from 'class-transformer'

import { ClearingInformation as IClearingInformation } from '@/api/generated/api'
import { ClearingInformationParticipantSummary } from '@/models/iam/ClearingInformationParticipantSummary'
import { TocomClearingInformationItem } from '@/models/iam/TocomClearingInformationItem'

export class ClearingInformation implements IClearingInformation {
  eexClearingFirmNames!: string[]

  @Type(() => TocomClearingInformationItem)
  tocomClearingInformation!: TocomClearingInformationItem[]

  @Type(() => ClearingInformationParticipantSummary)
  participantSummary!: ClearingInformationParticipantSummary
}
